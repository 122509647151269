import {
  GET_CHANGE_LEADER_DISMISS_REASONS,
  GET_CHANGE_LEADER_DISMISS_REASONS_FAIL,
  GET_CHANGE_LEADER_DISMISS_REASONS_SUCCESS,
  PUT_CHANGE_LEADER,
  PUT_CHANGE_LEADER_FAIL,
  PUT_CHANGE_LEADER_SUCCESS,
  SELECTED_DISMISS_REASON,
  SELECTED_DISMISS_REASON_RESET,
} from './actionTypes';

const initialState = {
  comment: null,
  errorGetChangeLeaderDismissReason: '',
  errorPutChangeLeader: '',
  getChangeLeaderDismissReason: [],
  loadingChangeLeader: false,
  loadingChangeLeaderDismissReason: false,
  putChangeLeader: undefined,
  selectedDismissReason: undefined,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_CHANGE_LEADER_DISMISS_REASONS:
      return {
        ...state,
        loadingChangeLeaderDismissReason: true,
      };

    case GET_CHANGE_LEADER_DISMISS_REASONS_FAIL:
      return {
        ...state,
        errorGetChangeLeaderDismissReason: payload.error,
        loadingChangeLeaderDismissReason: false,
      };

    case GET_CHANGE_LEADER_DISMISS_REASONS_SUCCESS:
      return {
        ...state,
        getChangeLeaderDismissReason: payload.response,
        loadingChangeLeaderDismissReason: false,
      };
    case PUT_CHANGE_LEADER:
      return {
        ...state,
        loadingChangeLeader: true,
      };

    case PUT_CHANGE_LEADER_FAIL:
      return {
        ...state,
        errorPutChangeLeader: payload.error,
        loadingChangeLeader: false,
      };

    case PUT_CHANGE_LEADER_SUCCESS:
      return {
        ...state,
        putChangeLeader: payload.response,
        loadingChangeLeader: false,
      };

    case SELECTED_DISMISS_REASON:
      return {
        ...state,
        selectedDismissReason: parseInt(payload.id, 10),
      };

    case SELECTED_DISMISS_REASON_RESET:
      return {
        ...state,
        selectedDismissReason: undefined,
      };

    default:
      return state;
  }
};
