import {
  FILE_DOWNLOAD,
  FILE_DOWNLOAD_FAIL,
  FILE_DOWNLOAD_RESET,
  FILE_DOWNLOAD_SUCCESS,
} from './actionTypes';

const initialState = {
  fileError: null,
  fileId: null,
  fileLoading: false,
  result: {},
};

export default function reducer(state = initialState, { type, payload }) {
  switch(type) {
    case FILE_DOWNLOAD:
      return { fileLoading: true, fileId: payload.id };

    case FILE_DOWNLOAD_FAIL:
      return { fileError: payload.error, fileLoading: false };

    case FILE_DOWNLOAD_RESET:
      return {
        fileError: null,
        fileId: null,
        fileLoading: false,
        result: {},
      }

    case FILE_DOWNLOAD_SUCCESS: {
      return { result: {...payload.response}, fileLoading: false  };
    }

    default:
      return state;
  }
}
