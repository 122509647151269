// @packages
import React from 'react';
import NavMenu from 'smu-app-components/NavMenu';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { selectOpenMenu } from 'smu-app-components/MainHeader/selectors';
import { setHeaderMenuOpen } from 'smu-app-components/MainHeader/actions';

// @app
import Link from 'betterme-components/Link';
import { handleServerError } from 'betterme-components/api';
import { selectUser } from 'betterme-components/Authorization/selectors';

// @own
import './styles.scss';
import { getItemTarget, getItemPathName, isHomeBME } from './helpers';

function NavMenuContainer({ openMenu, setHeaderMenuOpen, location, userID }) {
  function handleClickItem(e, item) {
    if (item?.items?.length && !openMenu) {
      setHeaderMenuOpen({ openMenu: true });
    }
  }

  function handleClickLink(item) {
    const itemPathname = getItemPathName(item);
    if (location.pathname === itemPathname) window.scrollTo(0, 0);
  }

  function renderLink(children, item) {
    return (
      <Link
        className="bme-nav-menu__link"
        href={item?.url}
        onClick={() => handleClickLink(item)}
        target={getItemTarget(item)}
      >
        {children}
      </Link>
    );
  }

  function normaliceItem(item) {
    if (isHomeBME(item?.deeplink)) {
      return {
        ...item,
        deeplink: item.deeplink.replace(/\/home/i, '/myprofile'),
      };
    }
    return item;
  }

  return (
    <NavMenu
      className="bme-nav-menu"
      collapsed={!openMenu}
      menuVersion={2}
      normaliceItem={normaliceItem}
      onClickItem={handleClickItem}
      onError={handleServerError}
      renderLink={renderLink}
      unifiedMenu
      userUID={String(userID)}
    />
  );
}

NavMenuContainer.propTypes = {
  openMenu: PropTypes.bool,
  setHeaderMenuOpen: PropTypes.func,
  userID: PropTypes.number,
};

function mapStateToProps(state) {
  return {
    openMenu: selectOpenMenu(state),
    userID: selectUser(state)?.id,
  };
}

export default connect(mapStateToProps, { setHeaderMenuOpen })(
  withRouter(NavMenuContainer)
);
