// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import AvatarMedia from 'smu-ui-components/AvatarMedia';

// @own
import './styles.scss';

const HeaderCard = ({
  className,
  date,
  useLink,
  userAvatarProps,
}) => (
  <div className={cn('header-card', className)}>
    <AvatarMedia
      className="header-card__avatar"
      useLink={useLink}
      {...userAvatarProps}
    />
    <span className="header-card__date">{date}</span>
  </div>
);

HeaderCard.defaultProps = {
  useLink: true,
};

HeaderCard.propTypes = {
  className: PropTypes.string,
  date: PropTypes.string.isRequired,
  useLink: PropTypes.bool,
  userAvatarProps: PropTypes.object.isRequired,
};

export default HeaderCard;
