// @packages
import React from 'react';
import PropTypes from 'prop-types';
import ResultsSummaryWidgetAPP from 'smu-app-components/Assessment360/containers/widgets/ResultsSummary';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

// @app
import ContentCardTemplate from 'components/templates/ContentCardTemplate';
import { selectCommunityId } from 'betterme-components/Authorization/selectors';

// @own
import messages from './messages';

const ResultsSummaryWidget = ({
  className,
  communityId,
  intl: { formatMessage },
  isOwner,
  onToggleClick,
  open,
  userId,
}) => {
  return (
    <ContentCardTemplate
      onToggleClick={onToggleClick}
      open={open}
      title={formatMessage(messages.title)}
    >
      <ContentCardTemplate.Body>
        <ResultsSummaryWidgetAPP
          className={className}
          communityId={communityId}
          isOwner={isOwner}
          userId={userId}
        />
      </ContentCardTemplate.Body>
    </ContentCardTemplate>
  );
};

ResultsSummaryWidget.propTypes = {
  className: PropTypes.string,
  communityId: PropTypes.number.isRequired,
  intl: PropTypes.object,
  isOwner: PropTypes.bool,
  onToggleClick: PropTypes.func,
  open: PropTypes.bool,
  userId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  communityId: selectCommunityId(state),
});

export default injectIntl(connect(mapStateToProps)(ResultsSummaryWidget));
