import {
  GET_PROMOTIONS_REPORT,
  GET_PROMOTIONS_REPORT_FAIL,
  GET_PROMOTIONS_REPORT_SUCCESS,
  RESET_MY_TEAM_FILTERS,
  SET_MY_TEAM_FILTERS,
} from './actionTypes';

const initialState = {
  myTeamFilters: {
    assignee: null,
    endDate: null,
    startDate: null,
    status: null,
    title: '',
    requestStatus: null,
    actions: null,
  },
  errorPromotionReport: '',
  loadingReport: false,
  loaded: false,
  promotionsReport: undefined,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_MY_TEAM_FILTERS:
      return {
        myTeamFilters: {
          ...state.myTeamFilters,
          ...payload,
        },
        loaded: true,
      };
    case RESET_MY_TEAM_FILTERS:
      return initialState;
    case GET_PROMOTIONS_REPORT:
      return { ...state, loadingReport: true, error: '' };
    case GET_PROMOTIONS_REPORT_FAIL:
      return { ...state, loadingReport: false, errorPromotionsReport: payload.error };
    case GET_PROMOTIONS_REPORT_SUCCESS:
      return { ...state, loadingReport: false, promotionsReport: payload.response };

    default:
      return state;
  }
}
