// @packages
import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

// @app
import Title from 'components/atoms/Title';
import TextParserContainer from 'betterme-components/TextParser';

// @own
import './styles.scss';

const SuggestedPromotion = ({
  className,
  promotion: {
    comment,
    level,
    promoted,
  },
  titles: {
    currentSeniority,
    justification,
    promoteTo,
    suggestedPromotion,
  },
}) => (
  <div className={cn('suggested-promotion', className)}>
    <Title
      className="suggested-promotion__title"
      size={17}
    >
      {suggestedPromotion}
    </Title>
    <div className="suggested-promotion__promotion">
      <div className="suggested-promotion__promotion-seniority">
        <Title
          className="suggested-promotion__promotion-seniority-title"
          gray
          size={15}
        >
          {currentSeniority}
        </Title>
        <p className="suggested-promotion__promotion-seniority-aging">
          {promoted?.seniority.name}
        </p>
      </div>
      <div className="suggested-promotion__promotion-promote-to">
        <Title 
          className="suggested-promotion__promotion-promote-to-title"
          size={15}
        >
          {promoteTo}
        </Title>
        <p className="suggested-promotion__promotion-promote-to-seniority">
          {level?.name}
        </p>
      </div>
    </div>
    <div className="suggested-promotion__justification">
      <Title
        className="suggested-promotion__justification-title"
        gray
        size={15}
      >
        {justification}
      </Title>
      <p className="suggested-promotion__justification-text">
        <TextParserContainer>{comment}</TextParserContainer>
      </p>
    </div>
  </div>
);

SuggestedPromotion.propTypes = {
  className: PropTypes.string,
  promotion: PropTypes.object.isRequired,
  titles: PropTypes.array.isRequired,
};

export default SuggestedPromotion;
