import { defineMessages } from 'react-intl';

export default defineMessages({
  LeadershipRemoval: {
    id: 'Betterme.LeadershipRemovalModal.LeadershipRemoval',
    defaultMessage: 'Leadership Removal',
  },
  FeedbackSent: {
    id: 'Betterme.LeadershipRemovalModal.FeedbackSent',
    defaultMessage: 'Your Feedback to {collaborator} has been sent successfully!',
  },
  EvaluationSent: {
    id: 'Betterme.LeadershipRemovalModal.EvaluationSent',
    defaultMessage: 'Your Evaluation to {collaborator} has been sent successfully!',
  },
  ContinuousEvaluationSent: {
    id: 'Betterme.LeadershipRemovalModal.ContinuousEvaluationSent',
    defaultMessage: 'Your Continuous Evaluation to {collaborator} has been sent successfully!',
  },
  DeleteYourselfCareer: {
    id: 'Betterme.LeadershipRemovalModal.DeleteYourselfCareer2',
    defaultMessage: 'Are you sure you want to remove yourself as Career Mentor of {collaborator}?',
  },
  DeleteYourselfAccount: {
    id: 'Betterme.LeadershipRemovalModal.DeleteYourselfAccount2',
    defaultMessage: 'Are you sure you want to remove yourself as Experience Leader of {collaborator}?',
  },
  DeleteYourselfOtherLeader: {
    id: 'Betterme.LeadershipRemovalModal.DeleteYourselfOtherLeader',
    defaultMessage: 'Are you sure you want to remove yourself as Leader of {collaborator}?',
  },
  DeleteYourselfMain: {
    id: 'Betterme.LeadershipRemovalModal.DeleteYourselfMain',
    defaultMessage: 'Are you sure you want to remove yourself as Main Leader of {collaborator}?',
  },
  DeleteYourselfSecondary: {
    id: 'Betterme.LeadershipRemovalModal.DeleteYourselfSecondary',
    defaultMessage: 'Are you sure you want to remove yourself as Secondary Leader of {collaborator}?',
  },
  RemoveLeaderToastSuccess: {
    id: 'Betterme.RemoveLeaderModal.ToastSuccess',
    defaultMessage: 'The action was successfully done',
  },
});
