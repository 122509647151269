import { userMessages } from 'common/messages';

export const LEADER_MATCHES_STRUCTURE = {
  account: {
    name: userMessages.UserAccount,
  },
  area: {
    name: userMessages.UserArea,
  },
  job: {
    name: userMessages.UserJob,
  },
  office: {
    name: userMessages.UserOffice,
  },
  project: {
    name: userMessages.UserProject,
  },
  seniority: {
    name: userMessages.UserSeniority,
  },
  customfield1: {
    name: userMessages.UserCustomfield1,
  },
  customfield4: {
    name: userMessages.UserCustomfield4,
  },
  customfield6: {
    name: userMessages.UserCustomfield6,
  },
  customfield9: {
    name: userMessages.UserCustomfield9,
  },
  customfield11: {
    name: userMessages.UserCustomfield11,
  },
};
export const MAIN = 'main';
export const REQUEST_ID_GET_SUGGESTED_LEADERS = 'REQUEST_ID_GET_SUGGESTED_LEADERS';
export const SECONDARY = 'secondary';
