export const ADD_TO_MY_TEAM_REQUEST_AS_MAIN = 'ADD_TO_MY_TEAM_REQUEST_AS_MAIN';
export const ADD_TO_MY_TEAM_REQUEST_AS_SECONDARY = 'ADD_TO_MY_TEAM_REQUEST_AS_SECONDARY';
export const CHANGE_LEADER_REQUEST_AS_MAIN = 'CHANGE_LEADER_REQUEST_AS_MAIN';
export const CHANGE_LEADER_REQUEST_AS_SECONDARY = 'CHANGE_LEADER_REQUEST_AS_SECONDARY';
export const REMOVE_LEADER_REQUEST_AS_MAIN = 'REMOVE_LEADER_REQUEST_AS_MAIN';
export const REMOVE_LEADER_REQUEST_AS_SECONDARY = 'REMOVE_LEADER_REQUEST_AS_SECONDARY';
export const REMOVE_MYSELF_AS_MAIN_LEADER = 'REMOVE_MYSELF_AS_MAIN_LEADER';
export const REMOVE_MYSELF_AS_SECONDARY_LEADER = 'REMOVE_MYSELF_AS_SECONDARY_LEADER';
export const REQUEST_ID_GET_SUGGESTED_LEADERS = 'REQUEST_ID_GET_SUGGESTED_LEADERS';
export const SECONDARY = 'SECONDARY';
