import {
  GET_TEAM_INFORMATION_WA,
  GET_TEAM_INFORMATION_WA_FAIL,
  GET_TEAM_INFORMATION_WA_SUCCESS,
} from './actionTypes';

const initialState = {
  teamInfoErrorWA: '',
  teamInfoLoadingWA: false,
  teamInformationWA: {},
};

export default function teamInformationWA(state = initialState, { type, payload }) {
  switch (type) {
    case GET_TEAM_INFORMATION_WA:
      return {
        ...state,
        teamInfoLoadingWA: true,
      }

    case GET_TEAM_INFORMATION_WA_FAIL:
      return {
        ...state,
        teamInfoErrorWA: payload.error,
        teamInfoLoadingWA: false,
      }

    case GET_TEAM_INFORMATION_WA_SUCCESS:
      return {
        ...state,
        teamInfoLoadingWA: false,
        teamInformationWA: payload?.response?.collaborators,
      }

    default:
      return state;
  }
}
