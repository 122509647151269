// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @own
import RatingCountItem from './RatingCountItem';
import './styles.scss';

const RatingCountList = ({ className, ratedCompetencies }) => {

  return (
    <div className={cn('rating-count-list', className)}>
      {ratedCompetencies
        .map(rating => <RatingCountItem
          className="rating-count-list__item"
          key={rating.name}
          {...rating}
        />)}
    </div>
  );
};

RatingCountList.propTypes = {
  className: PropTypes.string,
  ratedCompetencies: PropTypes.array.isRequired,
};

export default (RatingCountList);
