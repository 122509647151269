import { defineMessages } from 'react-intl';

export default defineMessages({
  FeedbackDashboardFeedbackReceivedClearButton: {
    id: 'Betterme.Dashboard.FeedbackReceivedClearButton',
    defaultMessage: 'Clean filter',
  },
  FeedbackDashboardViewDetails: {
    id: 'Betterme.Dashboard.ViewDetails',
    defaultMessage: 'View details',
  },
  FeedbackDashboardFeedbackUnratedTitle: {
    id: 'Betterme.Dashboard.FeedbackUnratedTitle',
    defaultMessage: 'Competencies never rated',
  },
  FeedbackDashboardFeedbackUnratedSubtitle: {
    id: 'Betterme.Dashboard.UnratedSubtitle',
    defaultMessage: 'Choose one or more competencies to request feedback from your colleagues',
  },
  FeedbackDashboardRequestModalPlaceholder: {
    id: 'Betterme.Dashboard.RequestModalPlaceholder',
    defaultMessage: 'Select colleagues by their name or email',
  },
  FeedbackDashboardGoalsSubtitle: {
    id: 'Betterme.Dashboard.GoalsSubtitle',
    defaultMessage: 'Overall Progress (Weighted)',
  },
  FeedbackDashboardGoalsChartTitle: {
    id: 'Betterme.Dashboard.GoalsChartTitle',
    defaultMessage: 'Overall Progress',
  },
  FeedbackDashboardGoalsChartTitleInfo: {
    id: 'Betterme.Dashboard.GoalsChartTitleInfo',
    defaultMessage: '(Weighted)',
  },
  FeedbackDashboardGoalsProgressTitle: {
    id: 'Betterme.Dashboard.GoalsProgressTitle',
    defaultMessage: 'Goals Progress',
  },
  FeedbackDashboardGoalsCompleted: {
    id: 'Betterme.Dashboard.GoalsCompleted',
    defaultMessage: 'Goals completed',
  },
  FeedbackDashboardGoalsProgressLabelText: {
    id: 'Betterme.Dashboard.GoalsProgressLabelText',
    defaultMessage: 'Completed',
  },
  FeedbackDashboardGoalsChartAchieved: {
    id: 'Betterme.Dashboard.GoalsChartAchieved',
    defaultMessage: 'Achieved',
  },
  FeedbackDashboardGoalsChartRemaining: {
    id: 'Betterme.Dashboard.GoalsChartRemaining',
    defaultMessage: 'Remaining',
  },
  FeedbackDashboardUnratedLabel: {
    id: 'Betterme.Dashboard.UnratedLabel',
    defaultMessage: 'Unrated',
  },
  FeedbackDashboardLastEvaluationTitle: {
    id: 'Betterme.Dashboard.LastEvaluationTitle',
    defaultMessage: 'Last Evaluation',
  },
  FeedbackDashboardDateFilterTitle: {
    id: 'Betterme.Dashboard.DateFilterTitle',
    defaultMessage: '{username}\'s dashboard',
  },
  FeedbackDashboardDNASubtitle: {
    id: 'Betterme.Dashboard.DNASubtitle',
    defaultMessage: '{username}\'s DNA',
  },
  DashboardSuggestionsTitle: {
    id: 'Betterme.Dashboard.SuggestionsTitle',
    defaultMessage: 'Suggestions',
  },
  DashboardSuggestionsSubtitle: {
    id: 'Betterme.Dashboard.SuggestionsSubtitle',
    defaultMessage: 'Suggested actions for {username}',
  },
  DashboardSuggestionsEmptyMessage: {
    id: 'Betterme.Dashboard.SuggestionsEmptyMessage',
    defaultMessage: '{username} hasn\'t received any Suggestions for this period yet',
  },
  DashboardFeedbackForLeadersSummaryReport: {
    id: 'Betterme.Dashboard.SummaryReport',
    defaultMessage: 'Summary Report'
  },
  DashboardFeedbackForLeadersHigherRates: {
    id: 'Betterme.Dashboard.HigherRates',
    defaultMessage: 'Higher Rates',
  },
  DashboardFeedbackForLeadersLowerRates: {
    id: 'Betterme.Dashboard.LowerRates',
    defaultMessage: 'Lower Rates',
  },
  DashboardFeedbackForLeadersTotal: {
    id: 'Betterme.Dashboard.Total',
    defaultMessage: 'Total',
  },
  DashboardFeedbackForLeadersUserEmpty: {
    id: 'Betterme.Dashboard.FeedbackForLeadersUserEmpty',
    defaultMessage: 'You haven\'t received at least 3 feedback to see your results',
  },
  DashboardFeedbackForLeadersEmpty: {
    id: 'Betterme.Dashboard.FeedbackForLeadersEmpty',
    defaultMessage: '{username} hasn\'t received at least 3 feedback to see their results',
  },
  DashboardFeedbackForLeadersAnnualAverage: {
    id: 'Betterme.Dashboard.FeedbackForLeadersAnnualAverage',
    defaultMessage: 'Annual Average',
  },
  DashboardFeedbackForLeadersPreviousPeriod: {
    id: 'Betterme.Dashboard.FeedbackForLeadersPreviousPeriod',
    defaultMessage: 'Previous Period',
  },
  DashboardSkillsAndCompetenciesEmptyState: {
    id: 'Betterme.Dashboard.SkillsAndCompetenciesEmptyState',
    defaultMessage: 'This information is not available at the moment',
  },
  DashboardSkillsAndCompetenciesEmptyStateTitle: {
    id: 'Betterme.Dashboard.SkillsAndCompetenciesEmptyStateTitle',
    defaultMessage: 'Oops! Something went wrong',
  },
  DashboardDetailEmptyStateTitle: {
    id: 'Betterme.DashboardDetail.EmptyStateTitle',
    defaultMessage: 'Currently, there is no visible activity in this section for your community.',
  },
  DashboardPerformanceChartTitle: {
    id: 'Betterme.Dashboard.DashboardPerformanceChartTitle',
    defaultMessage: 'Performance',
  },
  DashboardPerformanceChartEmptyState: {
    id: 'Betterme.Dashboard.DashboardPerformanceChartEmptyState',
    defaultMessage: 'No evaluations have been created for this user yet.',
  }
});
