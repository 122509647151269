// @packages
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// @app
import UserAvatar from 'smu-ui-components/UserAvatar';

// @own
import FeedbackRequestAction from '../FeedbackRequestAction';
import './styles.scss';

const FeedbackRequestItem = ({
  actionText,
  item,
  onClick,
}) => {
  const dateFeedbackRequest = moment(item.creationTime);
  const dateFromNow = dateFeedbackRequest.fromNow();
  const dateFormat = dateFeedbackRequest.format('YY-MM-DD');

  return (
    <li className="feedback-request-item">
      <div className="feedback-request-item__user-info">
        <div className="feedback-request-item__avatar">
          <UserAvatar profileImageCode={item.sender.profileImageCode} />
        </div>
        <div className="feedback-request-item__info">
          <strong className="feedback-request-item__info-name">
            {`${item.sender.firstName} ${item.sender.lastName}`}
          </strong>
          <time className="feedback-request-item__info-date" dateTime={dateFormat}>
            {dateFromNow}
          </time>
        </div>
      </div>
      <FeedbackRequestAction
        actionText={actionText}
        className="feedback-request-item__action"
        onClick={onClick}
      />
    </li>
  );
};

FeedbackRequestItem.propTypes = {
  item: PropTypes.shape({
    comment: PropTypes.string,
    creationTime: PropTypes.number.isRequired,
    sender: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      profileImageCode: PropTypes.string,
    }).isRequired,
    topics: PropTypes.array,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default FeedbackRequestItem;
