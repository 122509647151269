// @packages
import React, { useEffect } from 'react';
import * as actionsFullScreenModal from 'smu-app-components/FullScreenModal/actions';
import * as actionsRootModal from 'smu-app-components/RootModal/actions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, pick } from 'lodash';
import { injectIntl } from 'react-intl';
import { push } from 'react-router-redux';

// @app
import {
  selectCollaboratorsByLeader,
  selectConfigurations,
  selectSessionLoadingRefreshUserInfo,
  selectUserDraftEvaluation,
  selectUserDraftEvaluationLoading,
  selectUserInfo,
} from 'betterme-components/Authorization/selectors';
import { getDraftSelfEvaluation } from 'betterme-components/Authorization/actions';
import {
  selectAssessmentCollaborators,
  selectAssessmentCollaboratorsLoading,
} from 'betterme-components/services/Assessment/selectors';
import { setDataCreateEvaluation } from '../Evaluations/actions';
import {
  EVALUATION_CREATE,
  EVALUATION_DRAFT_DELETE_MODAL,
  FEEDBACK_FOR_LEADERS_INFO_MODAL,
  FEEDBACK_FOR_LEADERS_SELECTION_MODAL,
  GOAL_DETAIL_VIEW_MODAL,
  NEW_HIRE_EXPIRED_SELF_VALIDATION,
  PE_OFF_NEW_HIRE_EXPIRED,
  PROFILE_MORE_INFO,
} from '../constants/modalTypes';
import {
  evaluationsMessages,
  feedbackMessages,
  feedbackForLeadersMessages,
  goalsMessages,
  userMessages,
} from 'common/messages';
import {
  assessmentHomePageLocation,
  getEvaluationsLocation,
  getMyGoalsLocation,
  getNewFeedbackRequestLocation,
  getNewFeedbackRequestOnBehalfLocation,
  getNewFeedbackSendLocation,
} from 'betterme-components/routes/urls';
import { formatDate } from 'utils/formatDate';
import Sidenav from '../Sidenav';

// @own
import {
  trackCreateGoal,
  trackCreateSelfEvaluation,
  trackRequestFeedback,
  trackRequestFeedbackOnBehalf,
  trackSendFeedback,
} from './analytics';

const MySidenav = ({
  allowsEvaluationDrafts,
  allowsEvaluations,
  allowsFeedbackForLeaders,
  allowsFeedbackRequest,
  allowsGlbFeatures,
  allowsGoals,
  allowsGoalsCreationOnBehalfOfLeader,
  allowsRequestedOnBehalf,
  allowsSelfEvaluations,
  allowsSelfRetrospectiveCreation,
  allowsSelfRetrospectiveDraft,
  assessmentCollaboratorsLoading,
  collaboratorsByLeader,
  communityId,
  getDraftSelfEvaluation,
  goToAssessmentHomePage,
  intl: { formatMessage },
  loadingRefreshUserInfo,
  location,
  openFullscreenModal,
  openModal,
  setDataCreateEvaluation,
  user,
  userDraftEvaluation,
  userDraftEvaluationLoading,
}) => {
  const {
    access: {
      newWordingForLeaders,
      otherLeaders,
    },
    hasCollaborators,
    leaders,
    newHireSelfEvalAvailable,
  } = user;
  const isActiveSelfEvaluation = allowsEvaluations && (allowsSelfEvaluations || allowsEvaluationDrafts);
  const isActiveSelfRetrospective = allowsEvaluations && (allowsSelfRetrospectiveCreation || allowsSelfRetrospectiveDraft);
  const showOtherLeaders = otherLeaders;
  const { query } = location;
  const isFeedbackForLeaders = query?.feedbackForLeaders;
  const isLoading = loadingRefreshUserInfo
    || userDraftEvaluationLoading
    || assessmentCollaboratorsLoading;
  const showSelfEvaluationAction = newHireSelfEvalAvailable || isActiveSelfEvaluation || isActiveSelfRetrospective;
  function getLabelSelfEvaluation() {
    if (isActiveSelfRetrospective && !newHireSelfEvalAvailable) {
      return userDraftEvaluation
        ? formatMessage(evaluationsMessages.SelfRetrospectiveEdit)
        : formatMessage(evaluationsMessages.SelfRetrospectiveCreate);
    } else {
      return userDraftEvaluation
        ? formatMessage(evaluationsMessages.EvaluationsEditSelfEvaluation)
        : formatMessage(evaluationsMessages.EvaluationsCreateSelfEvaluation);
    }
  }

  function handleCreateGoalsClick() {
    trackCreateGoal();
    openFullscreenModal({
      modalType: GOAL_DETAIL_VIEW_MODAL,
      modalProps: {
        user,
        type: 'CREATE',
        isMyGoals: true,
      }
    });
  }

  function handleConvertDraft(dataCreateEvaluation) {
    const dataEvaluation = {
      ...dataCreateEvaluation,
      score: null,
      type: 'SELF-EVALUATION',
    };
    setDataCreateEvaluation(dataEvaluation);
    openModal({
      modalType: EVALUATION_CREATE,
      modalProps: {
        draftId: get(userDraftEvaluation, 'id'),
        isNewHire: user.newHireSelfEvalAvailable,
        isSelfEvaluation: true,
      },
    });
  }

  function handleCreateSelfEvaluationClick() {
    trackCreateSelfEvaluation();
    setDataCreateEvaluation({
      evaluee: user,
      ...pick(userDraftEvaluation, ['title', 'dateTimeFrom', 'dateTimeTo', 'type', 'attachments']),
    });
    if (userDraftEvaluation?.type === 'NH-SELF-EVALUATION'
      && !allowsSelfEvaluations
      && !user.newHireSelfEvalAvailable) {
      openModal({
        modalType: PE_OFF_NEW_HIRE_EXPIRED,
        modalProps: {
          draftId: get(userDraftEvaluation, 'id'),
          onDelete: handleDraftDelete,
          isSelfEvaluation: true,
        },
      });
    } else
      if (userDraftEvaluation?.type === 'NH-SELF-EVALUATION'
        && !user.newHireSelfEvalAvailable) {
        openModal({
          modalType: NEW_HIRE_EXPIRED_SELF_VALIDATION,
          modalProps: {
            draftId: get(userDraftEvaluation, 'id'),
            isNewHire: user?.newHireSelfEvalAvailable,
            isSelfEvaluation: true,
            onConvert: () => handleConvertDraft(user),
            onDelete: handleDraftDelete,
          },
        });
      } else {
        openModal({
          modalType: EVALUATION_CREATE,
          modalProps: {
            draftId: get(userDraftEvaluation, 'id'),
            isNewHire: user?.newHireSelfEvalAvailable,
            isSelfEvaluation: isActiveSelfEvaluation,
            isSelfRetrospective: isActiveSelfRetrospective,
          },
        });
      }
  }

  function handleDraftDelete() {
    const draftId = get(userDraftEvaluation, 'id');
    openModal({
      modalType: EVALUATION_DRAFT_DELETE_MODAL,
      modalProps: { draftId },
    });
  }

  function handleViewPersonalInformation() {
    const {
      account,
      area,
      endAssignment,
      firstName,
      job,
      lastName,
      office,
      profileImageCode,
      project,
      seniority,
    } = user;

    const userProps = [
      {
        label: formatMessage(userMessages.UserProject),
        value: project,
      },
      {
        label: formatMessage(userMessages.UserEndAssignment),
        value: formatDate(endAssignment),
      },
      {
        label: formatMessage(userMessages.UserOffice),
        value: office,
      },
      {
        label: formatMessage(userMessages.UserArea),
        value: area,
      },
      {
        label: formatMessage(userMessages.UserAccount),
        value: account,
      },
    ];
    openModal({
      modalType: PROFILE_MORE_INFO,
      modalProps: {
        firstName,
        job,
        lastName,
        profileImageCode,
        title: formatMessage(userMessages.UserPersonalInformation),
        userProps: userProps.filter(item => !!item.value),
        seniority: seniority,
      },
    });
  }

  function handleFeedbackForLeadersSelectionModal() {
    openModal({
      modalType: FEEDBACK_FOR_LEADERS_SELECTION_MODAL,
    });
  }

  function handleFeedbackForLeadersInfoModal() {
    openModal({
      modalType: FEEDBACK_FOR_LEADERS_INFO_MODAL,
      modalProps: {
        onAccept: handleFeedbackForLeadersSelectionModal,
        allowsGlbFeatures,
      },
    });
  }

  const actionsList = [
    {
      label: getLabelSelfEvaluation(),
      onClick: handleCreateSelfEvaluationClick,
      show: showSelfEvaluationAction,
      to: getEvaluationsLocation(),
    },
    {
      label: formatMessage(feedbackMessages.FeedbackSendFeedback),
      onClick: trackSendFeedback,
      show: true,
      to: getNewFeedbackSendLocation(),
    },
    {
      label: formatMessage(feedbackMessages.FeedbackRequestFeedback),
      onClick: trackRequestFeedback,
      show: allowsFeedbackRequest,
      to: getNewFeedbackRequestLocation(),
    },
    {
      label: formatMessage(feedbackMessages.FeedbackRequestFeedbackOnBehalf),
      onClick: trackRequestFeedbackOnBehalf,
      show: allowsRequestedOnBehalf && hasCollaborators,
      to: getNewFeedbackRequestOnBehalfLocation(),
    },
    {
      label: formatMessage(goalsMessages.GoalsCreate),
      onClick: handleCreateGoalsClick,
      show: allowsGoals && allowsGoalsCreationOnBehalfOfLeader && leaders,
      to: getMyGoalsLocation(),
    },
    {
      label: formatMessage(feedbackForLeadersMessages.FeedbackforLeaders),
      onClick: handleFeedbackForLeadersInfoModal,
      show: allowsFeedbackForLeaders,
      type: 'bluebe',
    },
  ];

  const optionsMenu = [
    {
      label: formatMessage(userMessages.UserMoreInfo),
      onClick: handleViewPersonalInformation,
      show: true,
    },
  ];

  useEffect(() => {
    if (isFeedbackForLeaders) {
      handleFeedbackForLeadersInfoModal();
    };
  }, []);

  useEffect(() => {
    getDraftSelfEvaluation({
      evaluator: user?.identification,
      evaluee: user?.identification,
      isCollaborator: false,
      isNewHire: user?.newHireSelfEvalAvailable,
    })
  }, []);

  return (
    <Sidenav
      actionsList={actionsList.filter(action => action.show)}
      allowsGlbFeatures={allowsGlbFeatures}
      onClickAction={{
        handleFeedbackForLeaders: handleFeedbackForLeadersInfoModal,
        handlePotentialAssessment: () => goToAssessmentHomePage(user),
      }}
      collaboratorsByLeader={collaboratorsByLeader}
      collaboratorsListTitle={formatMessage(userMessages.UserMyTeam)}
      enableChangeProfileImage
      hasCollaborators={hasCollaborators}
      loading={isLoading}
      newWordingForLeaders={newWordingForLeaders}
      onClickFeedbackForLeader={handleFeedbackForLeadersInfoModal}
      optionsMenu={optionsMenu.filter(action => action.show)}
      owner
      showLeaderWidget
      showLeadershipButton
      showOtherLeaders={showOtherLeaders}
      showSeniority
      user={user}
    />
  );
};

MySidenav.propTypes = {
  allowsEvaluations: PropTypes.bool.isRequired,
  allowsFeedbackForLeaders: PropTypes.bool.isRequired,
  allowsFeedbackRequest: PropTypes.bool.isRequired,
  allowsGoals: PropTypes.bool.isRequired,
  allowsGoalsCreationOnBehalfOfLeader: PropTypes.bool.isRequired,
  allowsRequestedOnBehalf: PropTypes.bool.isRequired,
  allowsSelfEvaluations: PropTypes.bool.isRequired,
  collaboratorsByLeader: PropTypes.object.isRequired,
  communityId: PropTypes.number,
};

const goToAssessmentHomePage = (user) => push(assessmentHomePageLocation(user));

const mapDispatchToProps = {
  ...actionsFullScreenModal,
  ...actionsRootModal,
  getDraftSelfEvaluation,
  goToAssessmentHomePage,
  setDataCreateEvaluation,
};

const mapStateToProps = (state) => {
  return {
    allowsEvaluationDrafts: selectConfigurations('allowsEvaluationDrafts')(state),
    allowsEvaluations: selectConfigurations('allowsEvaluations')(state),
    allowsFeedbackForLeaders: selectConfigurations('allowsFeedbackForLeaders')(state),
    allowsFeedbackRequest: selectConfigurations('allowsFeedbackRequest')(state),
    allowsGlbFeatures: selectConfigurations('allowsGlbFeatures')(state),
    allowsGoals: selectConfigurations('allowsGoals')(state),
    allowsGoalsCreationOnBehalfOfLeader: selectConfigurations('allowsGoalsCreationOnBehalfOfLeader')(state),
    allowsPotentialAssessment: selectConfigurations('allowsPotentialAssessment')(state),
    allowsRequestedOnBehalf: selectConfigurations('allowsRequestedOnBehalf')(state),
    allowsSelfEvaluations: selectConfigurations('allowsSelfEvaluations')(state),
    allowsSelfRetrospectiveCreation: selectConfigurations('allowsSelfRetrospectiveCreation')(state),
    allowsSelfRetrospectiveDraft: selectConfigurations('allowsSelfRetrospectiveDraft')(state),
    assessmentCollaborators: selectAssessmentCollaborators(state),
    assessmentCollaboratorsLoading: selectAssessmentCollaboratorsLoading(state),
    collaboratorsByLeader: selectCollaboratorsByLeader(state),
    feedbackForLeadersAccess: selectConfigurations('feedbackForLeader')(state),
    loadingRefreshUserInfo: selectSessionLoadingRefreshUserInfo(state),
    user: selectUserInfo(state),
    userDraftEvaluation: selectUserDraftEvaluation(state),
    userDraftEvaluationLoading: selectUserDraftEvaluationLoading(state),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(MySidenav));
