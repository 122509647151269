// @packages
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { isEmpty } from 'lodash';

// @app
import { goalsMessages } from 'common/messages';
import Main from '../../Main';
import {
  getSummaryGoals,
  resetSummaryGoals,
} from '../../Goals/actions';
import {
  selectLoadingSummaryGoals,
  selectSummaryGoals,
} from '../../Goals/selectors';
import MyTeamGoals from '../../Goals/MyTeamGoals';
import GoalsCreate from '../../RightColumn/GoalsCreate';

// @own
import * as actions from '../actions';
import GoalsStatusCounter from '../GoalsStatusCounter';

class TeamGoalsTab extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.getSummaryGoals();
  }

  componentDidUpdate(prevProps) {
    const {
      myTeamFilters: {
        assignee: prevAssignee,
        endDate: prevEndDate,
        startDate: prevStartDate,
        status: prevStatus,
        title: prevTitle,
      }
    } = prevProps;
    const {
      myTeamFilters: {
        assignee: currentAssignee,
        endDate: currentEndDate,
        startDate: currentStartDate,
        status: currentStatus,
        title: currentTitle,
      }
    } = this.props;
    const prevAssigneeId = prevAssignee && prevAssignee.id;
    const currentAssigneeId = currentAssignee && currentAssignee.id;
    const hasAssigneeChanged = prevAssigneeId !== currentAssigneeId;
    const hasDateChanged = (prevEndDate !== currentEndDate) || (prevStartDate !== currentStartDate);
    const hasTitleChanged = prevTitle !== currentTitle;
    const hasStatusChanged = prevStatus !== currentStatus;

    if (hasDateChanged || hasAssigneeChanged || hasTitleChanged || hasStatusChanged) {
      this.getSummaryGoals();
    }
  }

  componentWillUnmount() {
    const { resetSummaryGoals } = this.props;

    resetSummaryGoals();
  }

  getSummaryGoals() {
    const { getSummaryGoals, user } = this.props;
    const {
      myTeamFilters: {
        assignee,
        endDate,
        startDate,
      }
    } = this.props;
    const isCollaborator = !isEmpty(assignee);
    const userId = isCollaborator ? assignee.id : user.id;
    const from = startDate ? startDate.valueOf() : undefined;
    const to = endDate ? endDate.valueOf() : undefined;

    getSummaryGoals(userId, from, to);
  }

  render() {
    const {
      intl: { formatMessage },
      loadingGoalsStatusCounter,
      myTeamFilters,
      params,
      summaryGoals,
      user,
    } = this.props;

    const { assignee } = myTeamFilters;
    const isCollaborator = !isEmpty(assignee);
    const userSummaryGoals = isCollaborator
      ? summaryGoals.assignedTo
      : summaryGoals.team;

    const pieChartConfig = {
      size: 110,
      strokeSize: 16,
    };

    return (
      <Fragment>
        <Main
          Sidenav={
            <GoalsStatusCounter
              loading={loadingGoalsStatusCounter}
              newStyle
              pieChartConfig={pieChartConfig}
              summaryGoals={userSummaryGoals}
              title={formatMessage(goalsMessages.GoalsGoals)}
            />
          }
          Main={
            <MyTeamGoals
              myTeamFilters={myTeamFilters}
              newStyle
              params={params}
              user={user}
            />
          }
          RightColumn={
            <GoalsCreate user={user} />
          }
        />
      </Fragment>
    );
  }
}

TeamGoalsTab.propTypes = {
  loadingGoalsStatusCounter: PropTypes.bool,
  summaryGoals: PropTypes.shape({
    assignedTo: PropTypes.object,
    team: PropTypes.object,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  loadingGoalsStatusCounter: selectLoadingSummaryGoals(state),
  summaryGoals: selectSummaryGoals(state),
});

const mapDispatchToProps = {
  ...actions,
  getSummaryGoals,
  resetSummaryGoals,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TeamGoalsTab));
