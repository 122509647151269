export const groupByRateId = (ratedTopicsList) => {
  const result = {};
  if (ratedTopicsList) {
    ratedTopicsList.forEach((item) => {
      const rateId = item.rateId;
      const competencyId = item.competency.id;
      if (!(rateId in result)) {
        result[rateId] = [];
      }
      result[rateId].push(competencyId);
    });
  }
  return Object.entries(result).map(([ratingId, topicIds]) => ({ ratingId: Number(ratingId), topicIds }));
};
