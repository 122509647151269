// @packages
import { takeLatest, call, put } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';

// @app
import { deleteFile, uploadFile } from './api';

// @own
import {
  fileDeleteFail,
  fileDeleteSuccess,
  fileUploadFail,
  fileUploadSuccess,
} from './actions';
import { FILE_GOAL_DELETE, FILE_GOAL_UPLOAD } from './actionTypes';

function* fileUploadWorker({ payload: { file } }) {
  try {
    const response = yield call(uploadFile, file);
    yield put(fileUploadSuccess(response));
  } catch(error) {
    yield put(fileUploadFail(error.message));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

function* fileDeleteWorker({ payload: { id } }) {
  try {
    yield call(deleteFile, id);
    yield put(fileDeleteSuccess(id));
  } catch(error) {
    yield put(fileDeleteFail(error.message));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

export default function* filesGoalWatcher() {
  yield takeLatest(FILE_GOAL_UPLOAD, fileUploadWorker);
  yield takeLatest(FILE_GOAL_DELETE, fileDeleteWorker);
}
