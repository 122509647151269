import { defineMessages } from 'react-intl';

export default defineMessages({
  AssessmentResultCardTitle: {
    id: 'Betterme.PotentialAssessment.AssessmentResultCardTitle',
    defaultMessage: 'Potential Assessment Result',
  },
  AssessmentResultCardTitleDescription: {
    id: 'Betterme.PotentialAssessment.AssessmentResultCardTitleDescription',
    defaultMessage: 'Keep in mind that this level is based on your responses and is not the definitive result. The final score will be determined by averaging all completed assessments by leaders.',
  },
  AssessmentResultCardFirstBarDescription: {
    id: 'Betterme.PotentialAssessment.AssessmentResultCardFirstBarDescription',
    defaultMessage: 'This is a suggested potential level based on the behavioral assessment.',
  },
  AssessmentResultCardRadioButtonsDescription: {
    id: 'Betterme.PotentialAssessment.AssessmentResultCardRadioButtonsDescription',
    defaultMessage: "If you believe the Glober is at a different potential level, select the 'Modify' option and the corresponding alternative.",
  },
  AssessmentResultCardLeaveAsIs: {
    id: 'Betterme.PotentialAssessment.AssessmentResultCardLeaveAsIs',
    defaultMessage: 'Leave as is',
  },
  AssessmentResultCardModify: {
    id: 'Betterme.PotentialAssessment.AssessmentResultCardModify',
    defaultMessage: 'Modify',
  },
  AssessmentResultCardDetailsDescription: {
    id: 'Betterme.PotentialAssessment.AssessmentResultCardDetailsDescriptio',
    defaultMessage: "Which specific aspects are you considering when determining the Glober's potential?",
  },
  AssessmentResultCardDetailsPlaceholder: {
    id: 'Betterme.PotentialAssessment.AssessmentResultCardDetailsPlaceholder',
    defaultMessage: 'You can add any aditional thoughts',
  },
});
