// @package
import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Segment from 'smu-ui-components/Segment';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

// @app
import UserDetails from 'components/containers/UserDetails';
import SelfEvaluationList from 'components/containers/WidgetSelfEvaluationList';
import GoalOverviewWidget from 'components/containers/WidgetGoalOverview';
import FeedbackForLeaders from 'components/containers/WidgetFeedbackForLeaders';
import StarmeUpDNAWidget from 'components/containers/WidgetDNA';
import MyGrowthWidget from 'components/containers/WidgetMyGrowth';
import formatScore from 'utils/formatScore';
import { selectGoalsFinalScore } from 'betterme-components/Evaluations/selectors';
import { evaluationsMessages } from 'common/messages';
import {
  selectCommunityId,
  selectConfigurations,
} from 'betterme-components/Authorization/selectors';
import { selectFeedbackForLeadersSummaryResult } from 'betterme-components/services/FeedbackForLeaders/selectors';
import { getFeedbackForLeaders } from 'betterme-components/services/FeedbackForLeaders/actions';
import { commonMessages } from 'common/messages';
import { selectSelfEvaluationList } from 'betterme-components/services/SelfEvaluationWidget/selectors';

// @own
import {
  NH_EVALUATION,
  NH_SELF_EVALUATION,
  SELF_EVALUATION,
  SELF_RETROSPECTIVE,
  TECHNICAL_ASSESSMENT,
} from 'betterme-components/Evaluations/constants';

const EvaluationWidgets = ({
  allowsFeedbackForLeadersResult,
  allowsGoals,
  allowsSmuDna,
  communityId,
  dateTimeFrom,
  dateTimeTo,
  dontShowWidgets,
  evaluationType,
  evaluee,
  feedbackForLeadersSummaryResult,
  getFeedbackForLeaders,
  goalsFinalScore,
  intl: { formatMessage },
  loading,
}) => {
  const isSelfRetrospective = evaluationType === SELF_RETROSPECTIVE;
  const shouldRenderFFL = !isSelfRetrospective
    && !(evaluationType === SELF_EVALUATION)
    && !(evaluationType === NH_EVALUATION)
    && !(evaluationType === NH_SELF_EVALUATION)
    && (evaluee?.hasCollaborators || !isEmpty(feedbackForLeadersSummaryResult))
    && allowsFeedbackForLeadersResult;
  const canRequestResults = !(evaluationType === TECHNICAL_ASSESSMENT)
    && !dontShowWidgets
    && allowsFeedbackForLeadersResult
    && evaluee?.hasCollaborators;
  const initialOpenValues = {
    feedbackForLeadersOpen: false,
    goalsOpen: false,
    userSelfEvaluationOpen: false,
    valuesOpen: true,
    myGrowth: true,
  };
  const isSelfEvaluation = evaluationType === SELF_RETROSPECTIVE
    || (evaluationType === SELF_EVALUATION)
    || (evaluationType === NH_SELF_EVALUATION);

  let [openValues, setOpenValues] = useState(initialOpenValues);

  useEffect(() => {
    if ((evaluee?.id !== undefined)) {
      if (canRequestResults) {
        getFeedbackForLeaders(evaluee?.id);
      }
    }
  }, [evaluee]);

  const handleToggleWidget = (widgetName) => {
    const newOpenValues = {
      ...openValues,
      [widgetName]: !openValues[widgetName],
    };
    setOpenValues({ ...newOpenValues });
  };

  return (
    <Segment
      loading={loading}
      loadingType="betterme"
      withChildren
    >
      {!isSelfRetrospective && (
        <UserDetails type={evaluationType} {...evaluee} />
      )}

      {!dontShowWidgets && (
        <>
          {!isSelfEvaluation && (
            <SelfEvaluationList
              communityId={communityId}
              onToggleClick={() => handleToggleWidget('userSelfEvaluationOpen')}
              open={openValues.userSelfEvaluationOpen}
              user={evaluee}
            />
          )}
          {shouldRenderFFL && (
            <FeedbackForLeaders
              user={evaluee}
              onToggleClick={() => handleToggleWidget('feedbackForLeadersOpen')}
              identification={evaluee?.identification}
              open={openValues.feedbackForLeadersOpen}
              title={formatMessage(commonMessages.FeedbackForLeaders)}
            />
          )}
          {(allowsGoals && !(evaluationType === TECHNICAL_ASSESSMENT)) && (
            <GoalOverviewWidget
              defaultFilterOpen={false}
              dateTimeTo={dateTimeTo}
              dateTimeFrom={dateTimeFrom}
              user={evaluee}
              onToggleClick={() => handleToggleWidget('goalsOpen')}
              title={formatMessage(commonMessages.Goals)}
              open={openValues.goalsOpen}
            />
          )}
          {(!isNil(goalsFinalScore.value)) && (
            <div className="evaluations-create-form__goals-final-score">
              <span className="evaluations-create-form__goals-final-score-text">
                {`${formatMessage(evaluationsMessages.EvaluationsGoalsFinalScore)}:`}
              </span>
              {formatScore(goalsFinalScore)}
            </div>
          )}
        </>
      )}
      {allowsSmuDna && (evaluee?.id !== undefined) && (
        <StarmeUpDNAWidget
          dateTimeFrom={dateTimeFrom}
          dateTimeTo={dateTimeTo}
          user={evaluee}
          onToggleClick={() => handleToggleWidget('valuesOpen')}
          title={formatMessage(commonMessages.Values)}
          open={openValues.valuesOpen}
        />
      )}
      {false && ( // TODO QA - Enable for QA
        <MyGrowthWidget
          title={formatMessage(commonMessages.MyGrowth)}
          onToggleClick={() => handleToggleWidget('myGrowth')}
          open={openValues.myGrowth}
        />
      )}
    </Segment>
  );
};

const mapStateToProps = (state) => ({
  allowsEvaluations: selectConfigurations('allowsEvaluations')(state),
  allowsFeedbackForLeadersResult: selectConfigurations('allowsFeedbackForLeadersResult')(state),
  allowsGoals: selectConfigurations('allowsGoals')(state),
  allowsSelfRetrospectiveCreation: selectConfigurations('allowsSelfRetrospectiveCreation')(state),
  allowsSelfRetrospectiveDraft: selectConfigurations('allowsSelfRetrospectiveDraft')(state),
  allowsSmuDna: selectConfigurations('allowsSmuDna')(state),
  communityId: selectCommunityId(state),
  feedbackForLeadersSummaryResult: selectFeedbackForLeadersSummaryResult(state),
  goalsFinalScore: selectGoalsFinalScore(state),
  selfEvaluationItems: selectSelfEvaluationList(state),
});

const mapDispatchToProps = {
  getFeedbackForLeaders,
};

EvaluationWidgets.propTypes = {
  allowsGoals: PropTypes.bool,
  allowsSelfRetrospectiveCreation: PropTypes.bool,
  allowsSelfRetrospectiveDraft: PropTypes.bool,
  allowsSmuDna: PropTypes.bool,
  dateTimeFrom: PropTypes.number,
  dateTimeTo: PropTypes.number,
  evaluationType: PropTypes.string,
  evaluee: PropTypes.object,
  feedbackForLeadersSummaryResult: PropTypes.array,
  getFeedbackForLeaders: PropTypes.func,
  getLastSelfEvaluation: PropTypes.func,
  goalsFinalScor: PropTypes.array,
  intl: PropTypes.object,
  selfEvaluationItems: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(EvaluationWidgets));