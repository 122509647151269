// @app
import { handleServerError } from 'betterme-components/api';
import { apiInstanceOsMember } from 'betterme-components/utils/requestHelper';

// @own

export function getOsApplicationsApi(params = {}) {
  return apiInstanceOsMember({
    data: {},
    headers: { 'Content-Type': 'application/json' },
    params,
    url: '/api/v1/community/member/applications',
  })
    .then(({ data }) => data)
    .catch(handleServerError);
}
  