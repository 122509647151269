import { get, filter } from 'lodash';
import { createSelector } from 'reselect';
import { selectConfigurations } from 'betterme-components/Authorization/selectors';
import { selectScores, selectTopics } from 'betterme-components/services/Layout/selectors';
import { selectRatedTopics } from 'betterme-components/Dashboard/selectors';

export const selectClosedGoals = ({ evaluations }) =>
  get(evaluations, 'closedGoals');

export const selectEvaluationRecentlyCreated = ({ evaluations }) =>
  get(evaluations, 'evaluationRecentlyCreated');

export const selectLastEvaluation = ({ evaluations }) =>
  get(evaluations, 'lastEvaluation');

export const selectSelfEvaluationList = ({ evaluations }) =>
  get(evaluations, 'lastSelfEvaluation');

export const selectEvaluations = ({ evaluations }) =>
  get(evaluations, 'evaluations');

export const selectTeamEvaluations = ({ evaluations }) =>
  get(evaluations, 'teamEvaluations');

export const selectLoading = ({ evaluations }) =>
  get(evaluations, 'loading');

export const selectLoadingCreate = ({ evaluations }) =>
  get(evaluations, 'loadingCreate');

export const selectLoadingCreateDraft = ({ evaluations }) =>
  get(evaluations, 'loadingCreateDraft');

export const selectLoadingDeleteDraft = ({ evaluations }) =>
  get(evaluations, 'loadingDeleteDraft');

export const selectLoadingClosedGoals = ({ evaluations }) =>
  get(evaluations, 'loadingClosedGoals');

export const selectLoadingEvaluationsDrafts = ({ evaluations }) =>
  get(evaluations, 'loadingEvaluationsDrafts');

export const selectLoadingLastEvaluation = ({ evaluations }) =>
  get(evaluations, 'loadingLastEvaluation');

export const selectLoadingLastSelfEvaluation = ({ evaluations }) =>
  get(evaluations, 'loadingLastSelfEvaluation');

export const selectLoadingSuggestedActions = ({ evaluations }) =>
  get(evaluations, 'loadingSuggestedActions');

export const selectLoadingSubmitDraft = ({ evaluations }) =>
  get(evaluations, 'loadingSubmitDraft');

export const selectLoadingUpdateDraft = ({ evaluations }) =>
  get(evaluations, 'loadingUpdateDraft');

export const selectLoadingTeamEvaluations = ({ evaluations }) =>
  get(evaluations, 'modalLoading');

export const selectDataCreateEvaluation = ({ evaluations }) =>
  get(evaluations, 'dataCreateEvaluation');

export const selectSuggestedActions = ({ evaluations }) =>
  get(evaluations, 'suggestedActions');

export const selectEvaluationsDrafts = ({ evaluations }) =>
  get(evaluations, 'evaluationsDrafts');

export const selectLoadingEvaluationFiles = ({ evaluations }) =>
  get(evaluations, 'loadingEvaluationFiles');

export const selectEvaluationsSkills = ({ evaluations }) =>
  get(evaluations, 'skills');

export const selectEvaluationsDeletedSkills = ({ evaluations }) =>
  get(evaluations, 'deletedSkills');

export const selectActiveCompetencies = createSelector(
  selectTopics,
  topics => filter(topics, { active: true, skill: false })
);

export const selectActiveCompetenciesWithRatesCount = createSelector(
  selectActiveCompetencies,
  selectRatedTopics,
  (competencies, ratedTopics) => (
    competencies?.map(competency => {
      const competencyWithRates = ratedTopics.find(topic => competency.id === topic.id);

      return {
        ...competency,
        total: competencyWithRates?.total,
        ratings: competencyWithRates?.ratings,
      };
    })
  )
);

export const selectOptionsScores = createSelector(
  selectConfigurations('continuousGoalScores'),
  selectScores,
  (isGoalScoreContinous, scores) => !isGoalScoreContinous ?
    scores?.map(({ max, name, description }) => ({ value: max, label: name, description })) :
    undefined,
);

export const selectRangeScores = createSelector(
  selectConfigurations('continuousGoalScores'),
  selectScores,
  (isGoalScoreContinous, scores) => scores && isGoalScoreContinous ?
    { min: scores[0]?.min, max: scores[scores?.length - 1]?.max } :
    undefined,
);

export const selectGoalsFinalScore = ({ evaluations }) =>
  get(evaluations, 'goalsFinalScore');

export const selectCustomScores = ({ evaluations }) =>
  get(evaluations, 'customScores');

export const selectNineBoxList = createSelector(
  selectDataCreateEvaluation,
  selectScores,
  (dataCreateEvaluation, scores) => {
    const { score: evaluationScore } = dataCreateEvaluation;
    return get(
      scores.find((score) => score.min <= evaluationScore && evaluationScore <= score.max),
      'nineBoxScores',
      [],
    );
  }
);

export const selectCanCreateBehaviourRetrospective = ({ evaluations }) =>
  get(evaluations, 'canCreateBehaviourRetrospective');

export const selectLastEvaluationRounded = createSelector(
  selectLastEvaluation,
  (lastEvaluation) => {
    if (lastEvaluation && lastEvaluation.score.value % 1 === 0) {
      lastEvaluation.score.value = Math.round(lastEvaluation.score.value);
    }
    return lastEvaluation;
  },
);
