// @packages
import React, { useEffect } from 'react';
import Container from 'smu-layout-components/Container';
import GenericEmptyState from 'smu-app-components/GenericEmptyState';
import PropTypes from 'prop-types';
import cn from 'classnames';
import TechnicalRetrospectivesLandingPage from 'smu-app-components/TechnicalRetrospective';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

// @app
import {
  ENABLED_PENDING_TASK_HOME,
} from 'betterme-components/services/CommunityConfigs/constants';
import {
  getCommunityConfigValueBool,
} from 'betterme-components/services/CommunityConfigs/selectors';

// @own
import messages from './messages';

const TechnicalRetrospectivesLayout = ({
  enabledPendingTasksHome,
  intl: { formatMessage },
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <Container
      centered
      className="!mt-10"
    >
      {enabledPendingTasksHome ?
        <TechnicalRetrospectivesLandingPage className="!mb-10" shadow={false} />
        :
        <div
          className={cn('flex items-center justify-center',
            'space-y-4 bg-white h-[calc(100vh-140px)] rounded-[10px]')}
        >
          <GenericEmptyState
            description={formatMessage(messages.emptyDescription)}
            image="padlock"
            title={formatMessage(messages.emptyTitle)}
          />
        </div>
      }
    </Container >
  );
};

TechnicalRetrospectivesLayout.propTypes = {
  router: PropTypes.object,
  enabledPendingTasksHome: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  enabledPendingTasksHome: getCommunityConfigValueBool(state, ENABLED_PENDING_TASK_HOME),
});

export default connect(mapStateToProps)(injectIntl(TechnicalRetrospectivesLayout));