// @packages
import React from 'react';
import PropTypes from 'prop-types';
import Highchart from 'smu-analytics-components/charts/Highchart';
import cn from 'classnames';
import Link from 'smu-ui-components/Link';
import { injectIntl } from 'react-intl';

// @app
import CardSimple from 'common/CardSimple';
import { actionsMessages } from 'common/messages';

// @own
import './styles.scss';
import {
  trackDashFeedbackReceivedViewMore,
} from '../analytics';

const StackedBarChart = ({
  activeTab,
  config,
  currentPage,
  data,
  detailsPath,
  feedbackReceivedLink,
  intl: { formatMessage },
  loading,
  messagesEmptyStates,
  onChangePage,
  ratings,
  showRequestFeedback,
  totalPages,
  trackEventEmptyState,
}) => {
  const showBarchart = totalPages.length > 0 && totalPages[0].totalElements > 0;
  const totalRatings = ratings.reduce((acc, { count }) => count + acc, 0);

  return (
    <div className="stacked-barchart">
      <CardSimple className="stacked-barchart__card" loading={loading}>
        {showBarchart ? (
          !loading &&
          data && (
            <div className="stacked-barchart__chart">
              <Highchart {...config} data={data} />
              {totalPages.length > 1 && (
                <ul className="stacked-barchart__pagination">
                  {totalPages.map((page, index) => (
                    <li
                      className={cn('stacked-barchart__pagination-item', {
                        'stacked-barchart__pagination-item--active':
                          currentPage === page.number,
                      })}
                      key={index}
                      onClick={() => onChangePage(page.number)}
                    >
                      {page.number + 1}
                    </li>
                  ))}
                </ul>
              )}
              {totalRatings > 0 && (
                <div className="stacked-barchart__actions">
                  <Link
                    className="stacked-barchart__actions-more"
                    color="black"
                    onClick={trackDashFeedbackReceivedViewMore}
                    size="small"
                    to={detailsPath.feedbackReceived.pathname+`${'?activeTab='+activeTab}`}
                    type="button"
                  >
                    {formatMessage(actionsMessages.ActionsViewMore)}
                  </Link>
                </div>
              )}
            </div>
          )
        ) : (
          <div className="stacked-barchart__empty">
            <strong className="stacked-barchart__empty-message">
              {messagesEmptyStates.main}
            </strong>
            {showRequestFeedback && (
              <Link
                className="stacked-barchart__empty-request"
                onClick={trackEventEmptyState}
                to={feedbackReceivedLink+`${'?'+activeTab}`}
              >
                {messagesEmptyStates.suggestion}
              </Link>
            )}
          </div>
        )}
      </CardSimple>
    </div>
  );
};

StackedBarChart.propTypes = {
  config: PropTypes.object.isRequired,
  currentPage: PropTypes.number.isRequired,
  dashboardFeedback: PropTypes.shape({
    ratings: PropTypes.array.isRequired,
  }).isRequired,
  data: PropTypes.object.isRequired,
  detailsPath: PropTypes.shape({
    feedbackReceived: PropTypes.object.isRequired,
  }).isRequired,
  loading: PropTypes.bool,
  messagesEmptyStates: PropTypes.object.isRequired,
  onChangePage: PropTypes.func,
  showRequestFeedback: PropTypes.bool,
  totalPages: PropTypes.array.isRequired,
  trackEventEmptyState: PropTypes.func.isRequired,
};

export default injectIntl(StackedBarChart);
