// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @own
import './styles.scss';

const CollaboratorsCount = ({
  className,
  number,
  text,
}) => (
  <div className={cn('collaborators-count', className)}>
    <span className="collaborators-count__number">{number}</span>
    <span className="collaborators-count__text">{text}</span>
  </div>
);

CollaboratorsCount.propTypes = {
  className: PropTypes.string,
  number: PropTypes.number,
  text: PropTypes.string
};

export default CollaboratorsCount;
