// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import UserAvatar from 'smu-ui-components/UserAvatar';

import { buildImageUrl } from 'smu-utils/image';
import Divider from 'smu-ui-components/Divider';
import Segment from 'smu-ui-components/Segment';
// @app
import OptionsMenu from 'common/OptionsMenu';
import UserAvatarList from 'common/UserAvatarList';
import GoalFooterEmptyState from 'components/molecules/FooterCard';
import ActionButtons from 'components/molecules/ActionButtons';

// @own
import './styles.scss';
import GoalFooter from '../GoalFooter';
import TitleNWeight from '../TitleNWeight';
import {
  CLOSED_STATUS,
  OPENED_STATUS,
  PENDING_APPROVAL,
} from '../constants';

const GoalFeedItem = ({
  allowedEdition,
  assignees,
  assignment,
  attachments,
  dueDate,
  evaluator,
  id,
  loading,
  messages,
  newStyle,
  onClick,
  onClickApproval,
  onClickClose,
  onClickDelete,
  onClickDisapproval,
  period,
  progress,
  startDate,
  status,
  title,
  typeGoals,
  user,
  weighting,
}) => {
  const isMyTeamGoals = typeGoals === 'MY_TEAM_GOALS';
  const isMyGoals = typeGoals === 'MY_GOALS';
  const isGoalOpened = status === OPENED_STATUS;
  const isMyGoalEditAvaible = isMyGoals && evaluator && isGoalOpened;
  const showPendingStatusActions = isMyTeamGoals && status === PENDING_APPROVAL;
  const showOpenedStatusActions = isMyTeamGoals && isGoalOpened;
  const showClosedStatusActions = isMyTeamGoals && status === CLOSED_STATUS;
  const avatarClassName = 'goal-feed-item__header-avatar';
  const avatarHeader = (assignees.length > 1) ?
    <UserAvatarList className={avatarClassName} users={assignees} maxDisplay={4} size={50} /> :
    <UserAvatar
      avatarSize={40}
      avatarSrc={buildImageUrl({
        code: assignees[0]?.profileImageCode,
        height: 40,
        width: 40,
      })}
      className="mr-2"
      description={assignees[0]?.job}
      legacy={false}
      name={`${assignees[0]?.firstName} ${assignees[0]?.lastName}`}
    />;

  const optionsMenu = [
    {
      label: messages.approve,
      onClick: () => onClickApproval(id, assignees, period, weighting),
      show: showPendingStatusActions,
    },
    {
      label: messages.duplicate,
      onClick: () => onClick('ACTION_DUPLICATE'),
      show: showOpenedStatusActions || showClosedStatusActions,
    },
    {
      label: messages.edit,
      onClick: () => onClick('ACTION_EDIT'),
      show: showOpenedStatusActions || showPendingStatusActions || isMyGoalEditAvaible,
    },
    {
      label: messages.delete,
      onClick: () => onClickDisapproval(id),
      show: showPendingStatusActions,
    },
    {
      label: messages.delete,
      onClick: () => onClickDelete(id),
      show: showOpenedStatusActions,
    },
    {
      label: messages.close,
      onClick: () => onClickClose(progress, id),
      show: showOpenedStatusActions,
    },
  ];

  const isEvaluator = evaluator?.id === user?.id;
  const areShownPendingStatusActions = showPendingStatusActions && !!evaluator && isEvaluator;
  const optionsMenuFiltered = optionsMenu.filter(action => action.show);

  return (
    <Segment
      className={cn("goal-feed-item", {
        'rounded-lg overflow-hidden bg-white': newStyle
      })} 
      loading={loading}
      loadingType="betterme"
    >
      <div className="goal-feed-item__content" onClick={() => onClick('ACTION_VIEW_DETAILS')}>
        <div className="goal-feed-item__header">
          {(allowedEdition && (optionsMenuFiltered.length > 0)) && (
            <div className="goal-feed-item__options-menu">
              <OptionsMenu
                options={optionsMenuFiltered}
                position="bottom right"
              />
            </div>
          )}
          <TitleNWeight
            assignment={typeGoals === 'MY_GOALS' ? assignment : null}
            attachments={attachments}
            dueDate={dueDate}
            messages={messages}
            period={period}
            startDate={startDate}
            status={status}
            title={title}
            weighting={weighting}
          />
        </div>
        <Divider nomargin />
        {typeGoals === 'MY_TEAM_GOALS' && (
          <div>
            <div className="goal-feed-item__body">
              {avatarHeader}
            </div>
            <Divider nomargin />
          </div>
        )}
        {evaluator ? (
          <GoalFooter
            actionMessage={messages.evaluatedBy}
            className="goal-feed-item__footer"
            user={evaluator}
          />
        ) : (
          <GoalFooterEmptyState
            className="goal-feed-item__footer"
            sentBy={messages.emptyStateEvaluator}
          />
        )}
      </div>
      {areShownPendingStatusActions && (
        <div className="goal-feed-item__actions">
          <ActionButtons
            buttons={[
              {
                label: messages.delete,
                onClick: () => onClickDisapproval(id),
                variant: 'outline',
              },
              {
                label: messages.approve,
                onClick: () => onClickApproval(id, assignees, period, weighting),
              },
            ]}
          />
        </div>
      )}
    </Segment>
  );
};

GoalFeedItem.propTypes = { // TODO specified shapes
  allowedEdition: PropTypes.bool,
  assignees: PropTypes.array,
  assignment: PropTypes.string,
  dueDate: PropTypes.number,
  evaluator: PropTypes.object,
  loading: PropTypes.bool,
  messages: PropTypes.object,
  newStyle: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onClickApproval: PropTypes.func,
  onClickDelete: PropTypes.func,
  onClickDisapproval: PropTypes.func,
  status: PropTypes.string,
  title: PropTypes.string,
  typeGoals: PropTypes.oneOf(['MY_GOALS', 'MY_TEAM_GOALS']),
};

export default GoalFeedItem;
