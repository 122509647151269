// @packages
import React, { useCallback, useMemo } from 'react';
import HTMLParser from 'smu-ui-components/HTMLParser';
import cn from 'classnames';
import twemoji from 'twemoji';

// @own
import TextParserUrlContainer from './Url';

const TextParserContainer = ({
  children,
  className,
  emojiProps,
  parseUrls,
  presetUrls,
  urlProps,
  users,
  ...rest
}) => {
  const regexComponents = [];

  const customHTML = useMemo(() => {
    const htmlWithTwemoji = twemoji.parse(children || '', {
      className: 'align-sub h-4 inline-block mr-1 w-4',
      size: 16,
      ...emojiProps,
    });
    const sanityHtml = htmlWithTwemoji.replace(/(\r\n|\r|\n)/g, '<br>')?.trim();
    return sanityHtml;
  }, [children, emojiProps]);

  const LinkComponent = useCallback(
    (props) => <TextParserUrlContainer {...props} {...urlProps} />,
    [urlProps]
  );

  if (parseUrls) {
    regexComponents.push({
      component: LinkComponent,
      pattern: presetUrls,
    });
  }

  return (
    <HTMLParser
      className={cn('smu-text-parser', className)}
      html={customHTML}
      regexComponents={regexComponents}
      tagComponents={{
        a: LinkComponent,
      }}
      whiteList={{
        a: ['href', 'title'],
        img: ['src', 'alt', 'title', 'class'],
        b: [],
        br: [],
        em: [],
        ins: [],
        p: [],
        strong: [],
        u: [],
      }}
      {...rest}
    />
  );
};

TextParserContainer.defaultProps = {
  children: '',
  parseUrls: true,
  presetUrls:
    /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#()?&//=]*)/gi,
  urlProps: {
    externalRedirect: true,
  },
};

export default TextParserContainer;
