// @packages
import React from 'react';
import PropTypes from 'prop-types';
import Segment from 'smu-ui-components/Segment';
import cn from 'classnames';

// @app
import BreadcrumbHeader from 'components/organisms/BreadcrumbHeader';

// @own
import './styles.scss';

const HeadingSection = ({
  children,
  className,
  headerValues,
  loading,
  navigateBack,
  noMargin,
}) => (
  <Segment
    className={cn('heading-section', className)}
    loading={loading}
    loadingType="betterme"
  >
    <BreadcrumbHeader
      breadCrumbsProps={{
        active: 1,
        onClick: navigateBack,
        size: 'big',
        values: headerValues,
      }}
    />
    <div className={cn('heading-section__content', {
      'heading-section__content--no-margin': noMargin,
    })}>
      {children}
    </div>
  </Segment>
);

HeadingSection.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  headerValues: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  navigateBack: PropTypes.func.isRequired,
  noMargin: PropTypes.bool,
};

export default HeadingSection;
