// @packages
import React from 'react';
import Container from 'smu-layout-components/Container';
import GenericErrorState from 'smu-app-components/GenericErrorState';
import PropTypes from 'prop-types';
import Sticky from 'react-stickynode';
import SurveysFeed from 'smu-app-components/SurveysFeed';
import { connect } from 'react-redux';

// @app
import UserProfile from 'betterme-components/UserProfile';
import { ENABLED_TAKEPART_SURVEY } from 'betterme-components/services/CommunityConfigs/constants';
import { getCommunityConfigValueBool } from 'betterme-components/services/CommunityConfigs/selectors';
import { selectCommunityId } from 'betterme-components/Authorization/selectors';

function QuestionnairesLayout({ communityId, enabledTakePartSurvey, location }) {
  const tab = location.query?.tab;
  const filter = location.query?.filter;

  if (!enabledTakePartSurvey) {
    return (
      <Container
        className="flex-1 flex flex-col items-center justify-center"
        centered
      >
        <GenericErrorState image="padlock" statusCode={403} />
      </Container>
    );
  }

  return (
    <Container centered compact className="!pt-4 !pb-10 ui-components">
      <div className="flex">
        <div className="px-2 w-1/3">
          <Sticky top={80} innerZ={10}>
            <UserProfile owner showPanel={false} />
          </Sticky>
        </div>
        <div className="px-2 w-2/3">
          <SurveysFeed
            className="w-full"
            communityId={communityId}
            status={tab}
            userStatus={filter}
          />
        </div>
      </div>
    </Container>
  );
}

QuestionnairesLayout.propTypes = {
  communityId: PropTypes.number,
  enabledTakePartSurvey: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  communityId: selectCommunityId(state),
  enabledTakePartSurvey: getCommunityConfigValueBool(
    state,
    ENABLED_TAKEPART_SURVEY
  ),
});

export default connect(mapStateToProps)(QuestionnairesLayout);
