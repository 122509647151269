import { defineMessages } from 'react-intl';

export default defineMessages({
  GoalValidationReview: {
    id: 'Betterme.CreateGoalValidationModal.GoalValidationReview',
    defaultMessage: 'Review',
  },
  GoalValidationYesContinue: {
    id: 'Betterme.CreateGoalValidationModal.GoalValidationYesContinue',
    defaultMessage: 'Yes, continue',
  },
  GoalValidationMessages: {
    id: 'Betterme.CreateGoalValidationModal.GoalValidationMessages',
    defaultMessage: 'Congrats, on your way towards great accomplishments! Make sure to address this goal to the correct leader before sending.',
  },
  GoalValidationtitle: {
    id: 'Betterme.CreateGoalValidationModal.GoalValidationTitle',
    defaultMessage: 'Do you want to proceed?',
  },
});
