// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Divider from 'smu-ui-components/Divider';

// @own
import './styles.scss';
import InsightDetail from './InsightDetail';
import messages from '../messages';

const InsightsDetails = ({
  insight,
  intl: { formatMessage },
}) => {
  const {
    answers,
    question,
  } = insight;



  return (
    <div className="insights-details">
      <div className='insights-details__header'>
        <h1 className='insights-details__header-title'>
          {question.name}
        </h1>
        <div className='insights-details__header-total'>
          <span className='insights-details__header-total-responses'>
            {formatMessage(messages.FeedbackForLeadersReceivedTotalResponses)}:
            <strong className='insights-details__header-black'>
              {answers.length}
            </strong>
          </span>
        </div>
      </div>
      <Divider className='insights-details__divider-top'/>

      {answers.map((answer) => (
        <InsightDetail
          answer={answer}
        />
      ))}
    </div>
  );
};

InsightsDetails.propTypes = {
  insight: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
}

export default injectIntl(InsightsDetails);
