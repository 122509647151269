// @packages
import React, { useEffect } from 'react';
import Datepicker from 'smu-ui-components/Datepicker';
import Dropdown from 'smu-ui-components/Dropdown';
import Icon from 'smu-ui-components/IconV2';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { isEmpty } from 'lodash';

// @app
import Title from 'components/atoms/Title';
import { getDropdownRangeList } from 'utils/getDropdownRangeList';
import { setDataCreateEvaluation } from 'betterme-components/Evaluations/actions';
import { selectDataCreateEvaluation } from 'betterme-components/Evaluations/selectors';
import { selectEvaluationDetail } from 'betterme-components/EvaluationDetail/selectors';
import { setPerformanceRetrospective } from 'betterme-components/services/PerformanceRetrospective/actions';
import { setSelfRetrospective } from 'betterme-components/services/SelfRetrospective/actions';
import { selectPerformanceRetrospective } from 'betterme-components/services/PerformanceRetrospective/selectors';
import { selectSelfRetrospective } from 'betterme-components/services/SelfRetrospective/selectors';
import {
  selectConfigurations,
  selectEvaluationPeriod,
} from 'betterme-components/Authorization/selectors';
import {
  evaluationsMessages,
  placeholdersMessages,
} from 'common/messages';

const EvaluationCreationDateRange = ({
  allowsGlbFeatures,
  allowsPerformanceRetrospectiveCreation,
  allowsPerformanceRetrospectiveDraft,
  createPRData,
  createSRData,
  dataCreateEvaluation,
  evaluationDetail,
  evaluationPeriod,
  evaluationType,
  intl: { formatMessage },
  setDataCreateEvaluation,
  setPerformanceRetrospective,
  setSelfRetrospective,
}) => {

  const dateTimeFrom = dataCreateEvaluation?.dateTimeFrom;
  const dateTimeTo = dataCreateEvaluation?.dateTimeTo;
  const startTime = evaluationPeriod?.startTime;
  const endTime = evaluationPeriod?.endTime;
  const todayUTC = moment.utc();
  const initialStartDate = dateTimeFrom
    ? moment.utc(dateTimeFrom)
    : moment.utc(startTime);
  const validateEndPeriod = todayUTC.isBefore(endTime, 'month')
    ? todayUTC
    : moment.utc(endTime);
  const startPeriod = moment.utc(startTime);
  const endPeriod = todayUTC.isBetween(startTime, endTime, 'month', '[]')
    ? validateEndPeriod
    : moment.utc(endTime);
  const initialStartPeriod = initialStartDate.startOf('month').valueOf();
  const initialEndPeriod = (dateTimeTo && endPeriod.isAfter(dateTimeTo, 'month'))
    ? moment.utc(dateTimeTo).endOf('month').millisecond(0).valueOf()
    : endPeriod.endOf('month').millisecond(0).valueOf();
  const startPeriodList = getDropdownRangeList(startPeriod, endPeriod, false, initialEndPeriod);
  const endPeriodList = getDropdownRangeList(startPeriod, endPeriod, true, initialStartPeriod);
  const titleText = (allowsPerformanceRetrospectiveCreation || allowsPerformanceRetrospectiveDraft)
    ? formatMessage(evaluationsMessages.EvaluationsDatenRange)
    : formatMessage(evaluationsMessages.EvaluationsEvaluationRange);

  const handleChangeRangeDate = (selectedDateRange) => {
    let updatedDateTimeFrom;
    let updatedDateTimeTo;

    // If date is selected by the user, use it to update the date range
    if (selectedDateRange) {
      updatedDateTimeFrom = selectedDateRange.startDate;
      updatedDateTimeTo = selectedDateRange.endDate;
    }
    // If there is an evaluation detail, use the date range from it
    else if (!isEmpty(evaluationDetail)) {
      updatedDateTimeFrom = evaluationDetail.dateTimeFrom;
      updatedDateTimeTo = evaluationDetail.dateTimeTo;
    } else {
      // Otherwise, use the current evaluation period date range as default values
      updatedDateTimeFrom = initialStartPeriod;
      updatedDateTimeTo = initialEndPeriod;

      // If the evaluation type is self-evaluation or evaluation, don't use default values
      if (evaluationType === 'SELF-EVALUATION' || evaluationType === 'EVALUATION') {
        updatedDateTimeFrom = null;
        updatedDateTimeTo = null;
      };
    };

    setDataCreateEvaluation({
      dateTimeFrom: updatedDateTimeFrom,
      dateTimeTo: updatedDateTimeTo
    });

    if (evaluationType === 'SELF_RETROSPECTIVE') {
      setSelfRetrospective({
        ...createSRData,
        dateTimeFrom: updatedDateTimeFrom,
        dateTimeTo: updatedDateTimeTo
      });
    };

    if (evaluationType === 'PERFORMANCE_RETROSPECTIVE') {
      setPerformanceRetrospective({
        ...createPRData,
        dateTimeFrom: updatedDateTimeFrom,
        dateTimeTo: updatedDateTimeTo
      });
    };

  };

  function getOutsideDateRangeStartDate(day) {
    const utcDay = day.utc();
    const rangeStart = moment.utc(startTime);
    const rangeEnd = moment.utc(endTime);
    const outsideDateRangeStartDate = utcDay.isBefore(rangeStart, 'day')
      || utcDay.isSameOrAfter(rangeEnd, 'day')
      || utcDay.isSameOrAfter(moment.utc(dateTimeTo), 'day');
    return outsideDateRangeStartDate;
  };


  function getOutsideDateRangeDueDate(day) {
    const utcDay = day.utc();
    const rangeStart = moment.utc(startTime);
    const rangeEnd = moment.utc(endTime);
    const outsideDateRangeDueDate = utcDay.isAfter(rangeEnd, 'day')
      || utcDay.isSameOrBefore(rangeStart, 'day')
      || utcDay.isSameOrBefore(moment.utc(dateTimeFrom));
    return outsideDateRangeDueDate;
  };

  const dateRangeData = [
    {
      label: formatMessage(placeholdersMessages.PlaceholdersFrom),
      onChange: (value) =>
        handleChangeRangeDate({
          startDate: moment.utc(value),
          endDate: moment.utc(initialEndPeriod)
        }),
      options: startPeriodList,
      showIcon: true,
      value: initialStartPeriod,
    },
    {
      label: formatMessage(placeholdersMessages.PlaceholdersTo),
      onChange: (value) =>
        handleChangeRangeDate({
          startDate: moment.utc(initialStartPeriod),
          endDate: moment.utc(value)
        }),
      options: endPeriodList,
      showIcon: false,
      value: initialEndPeriod,
    },
  ];

  useEffect(() => {
    handleChangeRangeDate();
  }, [evaluationDetail, evaluationType]);

  return (
    <div className="mb-6">
      <Title className="text-black" size={16}>
        {titleText}
      </Title>
      {allowsGlbFeatures ? (
        <div className="m-0 flex flex-row content-center">
          {dateRangeData?.map(({ label, onChange, options, showIcon, value }) => (
            <>
              <div className="basis-1/2">
                <p className="m-0 mb-2">{label}</p>
                <Dropdown
                  onChange={(value) => onChange(value)}
                  options={options}
                  padding
                  value={value}
                />
              </div>
              {showIcon && (
                <Icon
                  className="flex-none mx-4 mt-10"
                  icon="Icon-arrow-right"
                  size={16}
                />
              )}
            </>
          ))}
        </div>
      ) : (
        <div className="flex justify-between">
          <Datepicker
            key="from"
            className="w-48"
            initialVisibleMonth={() =>
              (dateTimeFrom && moment.utc(dateTimeFrom)) || moment.utc(startTime)
            }
            isOutsideRange={(day) => getOutsideDateRangeStartDate(day, todayUTC)}
            onChange={(value) => handleChangeRangeDate({
              startDate: value,
              endDate: dateTimeTo && moment.utc(dateTimeTo),
            })}
            placeholder={formatMessage(placeholdersMessages.PlaceholdersDate)}
            styleInput
            value={dateTimeFrom && moment.utc(dateTimeFrom)}
          />
          <Datepicker
            key="to"
            className="w-48"
            initialVisibleMonth={() =>
              (dateTimeTo && moment.utc(dateTimeTo)) || moment.utc(endTime)
            }
            isOutsideRange={(day) => getOutsideDateRangeDueDate(day, todayUTC)}
            onChange={(value) => handleChangeRangeDate({
              startDate: dateTimeFrom && moment.utc(dateTimeFrom),
              endDate: value,
            })}
            placeholder={formatMessage(placeholdersMessages.PlaceholdersDate)}
            styleInput
            value={dateTimeTo && moment.utc(dateTimeTo)}
          />
        </div>
      )}
    </div>
  );
};

EvaluationCreationDateRange.propTypes = {
  allowsGlbFeatures: PropTypes.bool.isRequired,
  dataCreateEvaluation: PropTypes.object.isRequired,
  evaluationDetail: PropTypes.object.isRequired,
  evaluationPeriod: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  setDataCreateEvaluation: PropTypes.func.isRequired,
  setPerformanceRetrospective: PropTypes.func.isRequired,
  setSelfRetrospective: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  allowsGlbFeatures: selectConfigurations('allowsGlbFeatures')(state),
  allowsPerformanceRetrospectiveCreation: selectConfigurations('allowsPerformanceRetrospectiveCreation')(state),
  allowsPerformanceRetrospectiveDraft: selectConfigurations('allowsPerformanceRetrospectiveDraft')(state),
  createPRData: selectPerformanceRetrospective(state),
  createSRData: selectSelfRetrospective(state),
  dataCreateEvaluation: selectDataCreateEvaluation(state),
  evaluationDetail: selectEvaluationDetail(state),
  evaluationPeriod: selectEvaluationPeriod(state),
});

const mapDispatchToProps = {
  setDataCreateEvaluation,
  setPerformanceRetrospective,
  setSelfRetrospective,
};

export default connect(mapStateToProps, mapDispatchToProps)
  (injectIntl(EvaluationCreationDateRange));
