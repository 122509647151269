import { trackEvent } from 'smu-utils/gtm';
const EVALUATIONS = 'Evaluations';

export const trackEvaluationsFeedbackOverviewDataToggle = (value, evaluationType) => {
  trackEvent({
    category: EVALUATIONS,
    action: 'Form_Feedback_Toggle',
    label: value ? 'Enable' : 'Disable',
    type: evaluationType,
  });
};

export const trackEvaluationsViewAllFeedbacks = (type) => {
  trackEvent({
    category: EVALUATIONS,
    action: 'Form_View_All_Feedbacks',
    type,
  });
};
