// @packages
import PropTypes from 'prop-types';
import React from 'react';

// @app
import TagItem from 'common/TagItem';

// @own
import './styles.scss';
import { trackChipsHover } from './analytics';
import CompetencyDescriptionFeatureDiscovery from './CompetencyDescriptionFeatureDiscovery';

const CompetencyItem = ({
  className,
  competency,
  handleClick,
  isDragging,
  selected,
  variant,
  withFeatureDiscovery,
}) => {
  const getTagItemType = () => {
    if (selected) {
      return 'primary';
    } else if (competency.skill) {
      return 'secondary';
    } else {
      return undefined;
    }
  };

  const competencyNode = (
    <TagItem
      className={className}
      description={competency.description}
      isDragging={isDragging}
      name={competency.name}
      onClick={handleClick}
      onDescriptionOpen={() => trackChipsHover(competency.name)}
      type={getTagItemType()}
      variant={variant}
    />
  );

  return withFeatureDiscovery ? (
    <CompetencyDescriptionFeatureDiscovery>
      {competencyNode}
    </CompetencyDescriptionFeatureDiscovery>
  ) : competencyNode;
};

CompetencyItem.propTypes = {
  className: PropTypes.string,
  competency: PropTypes.shape({
    description: PropTypes.string,
    name: PropTypes.string.isRequired,
  }),
  handleClick: PropTypes.func,
  isDragging: PropTypes.bool,
  nomarginbottom: PropTypes.bool,
  selected: PropTypes.bool,
  withFeatureDiscovery: PropTypes.bool,
};

export default CompetencyItem;
