// @packages
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import { takeLatest, call, put } from 'redux-saga/effects';
import FileSave from 'file-saver';

// @app
import {
  getFeedbackForLeaderReport,
  getFeedbackForLeadersReceived,
} from 'betterme-components/api';
import getMimeType from 'utils/getMimeType';

// @own
import {
  GET_FEEDBACK_FOR_LEADERS_RECEIVED,
  GET_FEEDBACK_FOR_LEADERS_REPORT,
} from './actionTypes';
import {
  getFeedbackForLeadersReceivedFail,
  getFeedbackForLeadersReceivedSuccess,
  getFeedbackForLeadersReportFail,
  getFeedbackForLeadersReportSuccess,
} from './actions';

export function* getFeedbackForLeadersReceivedWorker({ payload: { params } }) {
  try {
    const response = yield call(getFeedbackForLeadersReceived, params);
    yield put(getFeedbackForLeadersReceivedSuccess(response));
  } catch (error) {
    yield put(getFeedbackForLeadersReceivedFail(error.message));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

export function* getFeedbackForLeadersReportWorker({ payload: { fileType, half, year } }) {
  const mimeType = getMimeType(fileType);
  const params = {
    fileType, 
    half,
    year,
  };

  try {
    const response = yield call(getFeedbackForLeaderReport, params);
    const blob = new Blob([response], { 'type': mimeType });
    FileSave.saveAs(blob, `feedback_for_leaders_report.${fileType}`);
    yield put(getFeedbackForLeadersReportSuccess(URL.createObjectURL(blob)));
  } catch (error) {
    yield put(getFeedbackForLeadersReportFail(error.message));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

export default function* feedbacksForLeadersReceivedWatcher() {
  yield takeLatest(GET_FEEDBACK_FOR_LEADERS_RECEIVED, getFeedbackForLeadersReceivedWorker);
  yield takeLatest(GET_FEEDBACK_FOR_LEADERS_REPORT, getFeedbackForLeadersReportWorker);
}
