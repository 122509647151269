import {
  GET_TEAM_PROMOTIONS_REQUESTS_FAIL,
  GET_TEAM_PROMOTIONS_REQUESTS_SUCCESS,
  GET_TEAM_PROMOTIONS_REQUESTS,
} from './actionTypes';

export function getTeamPromotionsRequests(pageNumber, requestStatus = "", actions = "") {
  return {
    type: GET_TEAM_PROMOTIONS_REQUESTS,
    payload: {
      pageNumber,
      requestStatus,
      actions,
    },
  };
}

export function getTeamPromotionsRequestsFail(error) {
  return {
    type: GET_TEAM_PROMOTIONS_REQUESTS_FAIL,
    payload: { error },
  };
}

export function getTeamPromotionsRequestsSuccess(response, page) {
  return {
    type: GET_TEAM_PROMOTIONS_REQUESTS_SUCCESS,
    payload: { response, page },
  };
}