// @packages
import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'smu-ui-components/Icon';

// @app
import ActionButtons from 'components/molecules/ActionButtons';

// @own
import './styles.scss';

const ConfirmationModal = ({
  acceptMessage,
  cancelMessage,
  children,
  className,
  closeModal,
  disableAccept,
  loading,
  lowestRaitingModal,
  onAccept,
  onCancel,
  title,
}) => {
  const invertedButtons = lowestRaitingModal && cancelMessage && acceptMessage;
  return (
    <div
      className={cn('confirmation-modal', className, {
        'confirmation-modal--loading': loading,
      })}
    >
      {(title || closeModal) && (
        <div className="confirmation-modal__title">
          {title}
          {closeModal && (
            <Icon
              className="simple-modal__close-icon"
              icon="close"
              onClick={closeModal}
            />
          )}
        </div>
      )}
      {children}
      <div className="confirmation-modal__buttons-wrapper">
        <ActionButtons
          buttons={[
            {
              disabled: invertedButtons && disableAccept,
              label: invertedButtons ? acceptMessage : cancelMessage,
              onClick: invertedButtons ? onAccept : onCancel,
              variant: "outline",
            },
            {
              disabled: !invertedButtons && disableAccept,
              label: invertedButtons ? cancelMessage : acceptMessage,
              onClick: invertedButtons ? onCancel : onAccept,
            },
          ]}
        />
      </div>
    </div>
  );
};

ConfirmationModal.defaultProps = {
  loading: false,
  disableAccept: false,
};

ConfirmationModal.propTypes = {
  acceptMessage: PropTypes.node.isRequired,
  cancelMessage: PropTypes.node,
  closeModal: PropTypes.func,
  loading: PropTypes.bool,
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  title: PropTypes.node.isRequired,
};

export default ConfirmationModal;
export const centerModalClass = 'confirmation-modal__wrapper';
export const modalStyles = { maxWidth: '600px' };
