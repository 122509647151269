import {
  LEADERSHIP_REQUEST,
  LEADERSHIP_REQUEST_FAIL,
  LEADERSHIP_REQUEST_SUCCESS,
} from './actionTypes';

const initialState = {
  changeLeaderRequestError: '',
  loadingChangeLeaderRequest: false,
  changeLeaderRequest: undefined,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case LEADERSHIP_REQUEST:
      return {
        ...state,
        ...payload,
        loadingChangeLeaderRequest: true,
      };
    case LEADERSHIP_REQUEST_FAIL:
      return {
        ...state,
        changeLeaderRequestError: payload.error,
        loadingChangeLeaderRequest: false,
      };
    case LEADERSHIP_REQUEST_SUCCESS:
      return {
        ...state,
        changeLeaderRequest: payload.response,
        loadingChangeLeaderRequest: false,
      };
    default:
      return state;
  }
}
