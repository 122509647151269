import {
  ADD_LEADER_REQUEST,
  ADD_LEADER_REQUEST_FAIL,
  ADD_LEADER_REQUEST_SUCCESS,
} from './actionTypes';

const initialState = {
  errorAddLeaderRequest: '',
  loadingAddLeaderRequest: false,
  addLeaderRequest: undefined,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case ADD_LEADER_REQUEST:
      return {
        ...state,
        loadingAddLeaderRequest: true
      };
    case ADD_LEADER_REQUEST_FAIL: 
      return {
        ...state,
        loadingAddLeaderRequest: false,
        errorAddLeaderRequest: payload.error,
      };
    case ADD_LEADER_REQUEST_SUCCESS:
      return {
        ...state,
        loadingAddLeaderRequest: false,
        addLeaderRequest: payload.response,
      };
    default:
      return state;
  };
};
