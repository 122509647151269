// @packages
import { createSelector } from 'reselect';
import { get, orderBy, pick, uniqBy } from 'lodash';

// @app
import { selectRatings } from 'betterme-components/services/Layout/selectors';

export const selectSkillsRatedDomain = (state) => state.skillsRated;

export const selectRatedSkills = createSelector(
  selectSkillsRatedDomain,
  (state) => uniqBy(state.ratedSkills, 'id')
);

export const selectSkills = createSelector(
  selectRatedSkills,
  (ratedSkills) => ratedSkills.map(ratedSkill => pick(ratedSkill, ['description', 'id', 'name']))
);

export const selectCommunityRatingsSkills = createSelector(
  selectRatings,
  (ratings) => orderBy(ratings, ['ordinality'], ['desc'])
);

export const selectRatedSkillsName = createSelector(
  selectRatedSkills,
  (ratedSkills) => ratedSkills.map(ratedSkill => ratedSkill.name)
);

export const selectSkillsStacked = createSelector(
  selectRatedSkills,
  selectCommunityRatingsSkills,
  (ratedSkills, communityRatingsSkills) => {
    const skillsStacked = communityRatingsSkills.map(rating => {
      const ratingInfo = pick(rating, ['color', 'id', 'name']);
      const data = ratedSkills.map(ratedSkill => {
        const ratings = ratedSkill.ratings.find(({ id }) => id === rating.id);
        const count = get(ratings, 'count', 0);
        return count;
      });
      return { ...ratingInfo, data, showInLegend: false };
    });
    return skillsStacked;
  }
);

export const selectSelectedSkillId = createSelector(
  selectSkillsRatedDomain,
  (state) => state.selectedSkillId,
);

export const selectSelectedSkill = createSelector(
  selectRatedSkills,
  selectSelectedSkillId,
  (ratedSkills, selectedSkillId) =>
    ratedSkills.find(ratedSkill => ratedSkill.id === selectedSkillId)
);

export const selectSelectedRatingSkills = createSelector(
  selectSkillsRatedDomain,
  (state) => state.selectedRatingSkill,
);

export const selectSkillSummary = createSelector(
  selectSelectedSkill,
  selectCommunityRatingsSkills,
  (selectedSkill, communityRatingsSkills) => {
    if (!selectedSkill) {
      return [];
    }

    const { ratings } = selectedSkill;

    const ratingsSummary = communityRatingsSkills.map((rating) => {
      const skillRating = ratings.find(({ id }) => id === rating.id);
      const count = get(skillRating, 'count', 0);
      const ratingInfo = pick(rating, ['color', 'id', 'imageCode', 'name']);
      return { ...ratingInfo, count };
    });

    return ratingsSummary;
  }
);

export const selectSkillsDetails = createSelector(
  selectSelectedSkill,
  selectCommunityRatingsSkills,
  (selectedSkill, communityRatingsSkills) => {
    if (!selectedSkill) {
      return [];
    }

    const { ratings } = selectedSkill;
    const ratedSkillsDetails = communityRatingsSkills.map((rating) => {
      const ratingWithFeedbacks = ratings.find(({ id }) => id === rating.id);
      let userFeedbacks;

      if (ratingWithFeedbacks) {
        const { feedback } = ratingWithFeedbacks;
        const userList = uniqBy(feedback, 'senderId')
          .map(user =>
            pick(user, ['job', 'profileImageCode', 'senderId', 'senderIdentification', 'senderName'])
          );
        userFeedbacks = userList.map((user) => {
          const feedbackListRaw = feedback.filter(({ senderId }) => senderId === user.senderId);
          const feedbackList = feedbackListRaw.map(feedbackRaw =>
            pick(feedbackRaw, ['audioDuration', 'audioUrl', 'comment', 'creationTime', 'id']));
          return { user, feedbackList };
        });
      } else {
        userFeedbacks = [];
      }

      return { ratingId: rating.id, userFeedbacks };
    });

    return ratedSkillsDetails;
  }
);

export const selectRatedSkillDetails = createSelector(
  selectSkillsDetails,
  selectSelectedRatingSkills,
  (ratedSkillsDetails, selectedRating) => {
    const ratingUserFeedbacks = ratedSkillsDetails
      .find(skillDetails => skillDetails.ratingId === selectedRating);
    return get(ratingUserFeedbacks, 'userFeedbacks');
  }
);

export const selectSkillsRatedLoading = createSelector(
  selectSkillsRatedDomain,
  (state) => state.loading,
);

export const selectSkillsRatedOrder = createSelector(
  selectSkillsRatedDomain,
  (state) => state.order,
);

export const selectPageSkills = createSelector(
  selectSkillsRatedDomain,
  (state) => state.number,
);

export const selectTotalPageSkills = createSelector(
  selectSkillsRatedDomain,
  (state) => state.totalPages,
);

export const selectRatedSearchSkills = createSelector(
  selectSkillsRatedDomain,
  (state) => state.ratedSearchSkills,
);