export default function getMimeType(fileType) {
  switch (fileType) {

    case 'csv':
      return 'text/csv';

    case 'xls':
      return 'application/vnd.ms-excel';

    case 'xlsx':
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    
    default:
      return '';
  }
};
