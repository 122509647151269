import { get } from 'lodash';
import { createSelector } from 'reselect';
import { selectConfigurations } from '../Authorization/selectors';

export const selectProfile = ({ profile }) => profile;

export const selectProfileAccess = ({ profile }) =>
  get(profile, 'access');

export const selectLoadingGetProfile = ({ profile }) =>
  get(profile, 'loadingGetProfile');

export const selectProfileCollaboratorsByLeader = ({ profile }) =>
  get(profile, 'collaborators', []);

export const selectProfileInfo = createSelector(
  selectProfile,
  (profile) => {
    if (profile && profile.id) {
      const { leader, evaluators } = profile;
      const leaders = [];
      const mainAndSecondayLeader = evaluators?.find(({ id }) => id === leader?.id);
      const secondaryLeaders = mainAndSecondayLeader
        ? evaluators.filter(({ id }) => id !== leader.id)
        : evaluators;

      if (leader) {
        leaders.push(leader);
      }

      if (secondaryLeaders) {
        leaders.push(...secondaryLeaders);
      }

      return {
        ...profile,
        evaluators: secondaryLeaders,
        leaders,
        mainAndSecondayLeader,
      };
    }

    return null;
  });

