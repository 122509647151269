// @packages
import React from 'react';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import PropTypes from 'prop-types';

// @app
import { promotionsMessages } from 'common/messages';

// @own
import './styles.scss';

const PositionsHistory = ({ intl: { formatMessage }, positionsHistory }) => (
  <div className="positions-history">
    <table className="positions-history__table">
      <thead className="positions-history__header">
        <tr className="positions-history__row">
          <th className="positions-history__heading">
            {formatMessage(promotionsMessages.PromotionsPosition)}
          </th>
          <th className="positions-history__heading">
            {formatMessage(promotionsMessages.PromotionsPeriod)}
          </th>
        </tr>
      </thead>
      <tbody className="positions-history__rows">
        {positionsHistory?.map(({ positionName, startDate, endDate }) => (
          <tr className="positions-history__row">
            <td className="positions-history__position">
              <span className="positions-history__job">
                {positionName}
              </span>
            </td>
            <td className="positions-history__date">
              {
                `${moment(startDate).utc().format('MMMM YYYY')}
                -
                ${endDate
                    ? moment(endDate).utc().format('MMMM YYYY')
                    : formatMessage(promotionsMessages.PromotionsPresent)}`
              }
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

PositionsHistory.propTypes = {
  intl: PropTypes.object.isRequired,
  positionsHistory: PropTypes.array.isRequired,
}

export default injectIntl(PositionsHistory);