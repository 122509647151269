import {
  GET_OS_APPLICATIONS,
  GET_OS_APPLICATIONS_FAIL,
  GET_OS_APPLICATIONS_SUCCESS,
} from './actionTypes';

const defaultState = {
  errorMessage: null,
  fetching: false,
  osApplications: [],
};

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case GET_OS_APPLICATIONS:
      return {
        ...state,
        fetching: true,
      };
    case GET_OS_APPLICATIONS_FAIL:
      return {
        ...state,
        errorMessage: payload.errorMessage,
        fetching: false,
      };
    case GET_OS_APPLICATIONS_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        fetching: false,
        osApplications: payload.osApplications.result,
      };
    default:
      return state;
  }
};
