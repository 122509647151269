// @packages
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { injectIntl } from 'react-intl';
import { get, isEmpty } from 'lodash';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import TextRevealer from 'smu-ui-components/TextRevealer';
import Divider from 'smu-ui-components/Divider';
import AvatarMedia from 'smu-ui-components/AvatarMedia';
import Segment from 'smu-ui-components/Segment';
import Button from 'smu-ui-components/Button';
import * as actionsRootModal from 'smu-app-components/RootModal/actions';
import * as actionsFullScreenModal from 'smu-app-components/FullScreenModal/actions';

// @app
import Breadcrumbs from 'common/Breadcrumbs';
import DownloadFile from 'betterme-components/components/containers/DownloadFile';
import {
  GOAL_DETAIL_VIEW_MODAL,
  GOAL_DETAIL_ACTION_CLOSE_MODAL,
  GOAL_DETAIL_ACTION_DELETE_MODAL,
} from 'betterme-components/constants/modalTypes';
import {
  actionsMessages,
  commonMessages,
  goalsMessages,
  promotionsMessages,
  sectionsMessages,
} from 'common/messages';
import GoalScales from 'common/GoalScales';
import { selectConfigurations } from 'betterme-components/Authorization/selectors';
import { selectScores } from 'betterme-components/services/Layout/selectors';
import isScaleDiscrete from 'utils/isScaleDiscrete';
import GoalFooterEmptyState from 'components/molecules/FooterCard';
import {
  fileClear,
  fileDelete,
  fileUpload,
} from 'betterme-components/services/FileGoals/actions';
import {
  selectFiles,
  selectFilesLoading,
} from 'betterme-components/services/FileGoals/selectors';
import {
  EXCEEDED_SIZE,
  MAX_FILE_SIZE,
  MAX_FILES_GOAL_UPDATE_PROGRESS,
  NO_SIZE,
  SUPPORTED_FORMATS,
  WRONG_FORMAT,
} from 'betterme-components/constants/fileValidations';
import { runValidations } from 'utils/filesAttach';

//TODO GoalsDetails scale type should be calculated from details > scale and not from community configs

// @own
import './styles.scss';
import * as actions from '../actions';
import GoalAddAssessment from '../GoalAddAssessment';
import GoalAssessment from '../GoalAssessment';
import GoalScoreSummary from '../GoalScoreSummary';
import GoalAddAssessmentFeatDiscovery from '../GoalAddAssessmentFeatDiscovery';
import GoalFooter from '../GoalFooter';
import TitleNWeight from '../TitleNWeight';
import AvatarMediaList from '../AvatarMediaList';
import GoalActions from '../GoalActions';
import { UPDATE_BUTTON } from '../constants';
import messages from '../messages';
import {
  trackGoalsDuplicateClick,
  trackGoalsEditClick,
  trackGoalsDeleteClick,
  trackGoalsCloseClick,
} from '../analytics';

class MyTeamGoalDetail extends Component {
  constructor(props) {
    super(props);
    const { intl: { formatMessage } } = this.props;

    this.messagesMyTeamGoalsDetail = {
      btnAddAssessment: formatMessage(messages.FeedbackGoalsBtnAddAssessment),
      btnCancel: formatMessage(actionsMessages.ActionsCancel),
      btnReadLess: formatMessage(actionsMessages.ActionsReadLess),
      btnReadMore: formatMessage(actionsMessages.ActionsReadMore),
      btnUpdateAssessment: formatMessage(
        messages.FeedbackGoalsBtnUpdateAssessmentProgress
      ),
      by: formatMessage(messages.FeedbackGoalsBy),
      closed: formatMessage(goalsMessages.GoalsClosed),
      description: formatMessage(sectionsMessages.SectionsDescription),
      dueDate: formatMessage(sectionsMessages.SectionsDueDate),
      evaluatedBy: formatMessage(messages.FeedbackGoalsEvaluatedBy),
      expired: formatMessage(messages.FeedbackGoalsExpired),
      goalsText: formatMessage(goalsMessages.GoalsGoals),
      individual: formatMessage(messages.FeedbackGoalsIndividual),
      opened: formatMessage(goalsMessages.GoalsOpened),
      orgObjectives: formatMessage(goalsMessages.GoalsOrgObjectives),
      participants: formatMessage(messages.FeedbackGoalsParticipants),
      pending: formatMessage(goalsMessages.GoalsPending),
      period: formatMessage(commonMessages.Period),
      placeholderComment: formatMessage(
        messages.FeedbackGoalsPlaceholderComment
      ),
      profile: formatMessage(messages.FeedbackGoalsProfile),
      profileTeamGoals: formatMessage(messages.FeedbackGoalsProfileTeamGoals),
      progress: formatMessage(goalsMessages.GoalsProgress),
      scales: formatMessage(messages.FeedbackGoalsScales),
      shared: formatMessage(messages.FeedbackGoalsShared),
      teamGoalsTitle: formatMessage(messages.FeedbackGoalsTeamGoalsTitle),
      weighting: formatMessage(goalsMessages.GoalsWeight),
    };

    this.state = {
      editingProgress: false,
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleDuplicate = this.handleDuplicate.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleFileAttach = this.handleFileAttach.bind(this);
    this.handleFileDelete = this.handleFileDelete.bind(this);
  }

  componentDidMount() {
    const {
      getTeamGoalDetail,
      params: { goalid },
      session: { user },
    } = this.props;

    if (goalid) {
      getTeamGoalDetail(goalid, user.id);
    }
    fileClear();
  }

  componentWillReceiveProps(nextProps) {
    const {
      getTeamGoalDetail,
      params: { goalid },
      session: { user },
      toggleEditProgress,
    } = this.props;
    const {
      params: { goalid: nextGoalid },
      session: { user: nextUser },
    } = nextProps;

    if (goalid !== nextGoalid || user.id !== nextUser.id) {
      getTeamGoalDetail(nextGoalid, nextUser.id);
      toggleEditProgress(false);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      teamGoalDetail: { id: prevId },
    } = prevProps;
    const {
      teamGoalDetail: { id: currentId, status: currentStatus },
      location,
    } = this.props;
    const typeAction = get(location, 'state.typeAction');

    if (prevId !== currentId) {
      if (
        typeAction === 'ACTION_EDIT' ||
        (currentStatus && currentStatus === 'PENDING_APPROVAL')
      ) {
        this.handleEdit();
      }

      if (typeAction === 'ACTION_DUPLICATE') {
        this.handleDuplicate();
      }
    }
  }

  componentWillUnmount() {
    const { resetTeamGoalDetail, toggleEditProgress, fileClear } = this.props;
    resetTeamGoalDetail();
    toggleEditProgress(false);
    fileClear();
  }

  onGoalFormClick(type, dataGoal) {
    const {
      location,
      openFullscreenModal,
      session: { user },
    } = this.props;
    const typeAction = get(location, 'state.typeAction');

    openFullscreenModal({
      modalType: GOAL_DETAIL_VIEW_MODAL,
      modalProps: { user, type, dataGoal, typeAction },
    });
  }

  handleCancel() {
    const { fileClear, toggleEditProgress } = this.props;
    toggleEditProgress(false);
    fileClear();
  }

  handleSave(id, data) {
    const { createGoalAssessment, session: { user }, fileClear } = this.props;
    createGoalAssessment(id, data, user.id);
    fileClear();
  }

  handleClose() {
    const {
      closeGoal,
      teamGoalDetail: { assessments, id: goalId },
      intl: { formatMessage },
      isGoalScoreContinous,
      isPercentage,
      openModal,
      scores,
    } = this.props;

    const options = isGoalScoreContinous
      ? undefined
      : scores.map(({ max, name }) => ({ value: max, label: name }));
    const range = isGoalScoreContinous
      ? { min: scores[0].min, max: scores[scores.length - 1].max }
      : undefined;

    const lastAssessment = get(assessments, '0');
    const defaultValue = (lastAssessment && !isPercentage)
      ? this.roundScoreValue(lastAssessment.value)
      : '';

    trackGoalsCloseClick();
    openModal({
      modalType: GOAL_DETAIL_ACTION_CLOSE_MODAL,
      modalProps: {
        acceptMessage: formatMessage(
          messages.FeedbackGoalsDetailModalCloseAccept
        ),
        cancelMessage: formatMessage(actionsMessages.ActionsCancel),
        defaultValue,
        goalId,
        isNumeric: isGoalScoreContinous,
        onAccept: closeGoal,
        messages: {
          checkbox: formatMessage(
            messages.FeedbackGoalsDetailModalCloseCheckbox
          ),
          comment: formatMessage(messages.FeedbackGoalsDetailModalCloseComment),
          dropdown: formatMessage(
            messages.FeedbackGoalsDetailModalCloseDropdown
          ),
          lastAssessmentWarning: formatMessage(
            messages.FeedbackGoalsDetailModalCloseWarning
          ),
          numberSelector: formatMessage(
            messages.FeedbackGoalsDetailModalCloseNumberSelector
          ),
        },
        options,
        range,
        title: formatMessage(messages.FeedbackGoalsDetailModalCloseTitle),
      },
    });
  }

  handleDelete() {
    const {
      deleteGoal,
      teamGoalDetail,
      intl: { formatMessage },
      openModal,
    } = this.props;
    trackGoalsDeleteClick();
    openModal({
      modalType: GOAL_DETAIL_ACTION_DELETE_MODAL,
      modalProps: {
        acceptMessage: formatMessage(actionsMessages.ActionsContinue),
        cancelMessage: formatMessage(actionsMessages.ActionsCancel),
        goalId: teamGoalDetail.id,
        onAccept: deleteGoal,
        title: formatMessage(messages.FeedbackGoalsDetailModalDeleteTitle),
      },
    });
  }

  handleDuplicate() {
    const { teamGoalDetail } = this.props;
    trackGoalsDuplicateClick();
    this.onGoalFormClick('DUPLICATE', teamGoalDetail);
  }

  handleEdit() {
    const { teamGoalDetail } = this.props;
    trackGoalsEditClick();
    this.onGoalFormClick('EDIT', teamGoalDetail);
  }

  isGoalClosed() {
    const { teamGoalDetail: { status } } = this.props;
    return status === 'CLOSED';
  }

  isGoalPendingApproval() {
    const { teamGoalDetail: { status } } = this.props;
    return status === 'PENDING_APPROVAL';
  }

  roundScoreValue(value) {
    const { isGoalScoreContinous, scores } = this.props;

    const boundedValue = Math.min(
      Math.max(scores[0].min, value),
      scores[scores.length - 1].max
    );
    return isGoalScoreContinous ? boundedValue : Math.floor(boundedValue);
  }

  handleFileAttach(e) {
    const { target } = e;
    const { files: listFiles } = target;
    const {
      addToastMessage,
      intl: { formatMessage },
      fileUpload,
    } = this.props;
    const errorMessages = {
      [EXCEEDED_SIZE]: formatMessage(
        promotionsMessages.PromotionsErrorExceededSize,
        {
          size: MAX_FILE_SIZE,
        }
      ),
      [NO_SIZE]: formatMessage(promotionsMessages.PromotionsErrorNoSize),
      [WRONG_FORMAT]: formatMessage(
        promotionsMessages.PromotionsErrorInvalidFormat,
        {
          formats: SUPPORTED_FORMATS,
        }
      ),
    };

    if (listFiles.length) {
      const file = listFiles[0];
      const error = runValidations(file, MAX_FILE_SIZE, SUPPORTED_FORMATS);

      if (!error) {
        fileUpload(file);
        target.value = null;
      } else {
        addToastMessage({
          message: errorMessages[error],
          timeout: 3000,
        });
        target.value = null;
      }
    }
  }

  handleFileDelete(fileId) {
    const { closeModal, fileDelete } = this.props;

    fileDelete(fileId);
    closeModal();
  }

  render() {
    const {
      editingProgress,
      fetching,
      fetchingSent,
      files,
      filesLoading,
      goToPath,
      intl: { formatMessage },
      isGoalScoreContinous,
      isPercentage,
      loadingApproval,
      location,
      selfUserId,
      session: { user },
      teamGoalDetail,
      teamGoalDetail: {
        attachments,
        id,
        title,
        status,
        weighting,
        dueDate,
        assignment,
        description,
        assignees,
        evaluator,
        scales,
        progress,
        period,
        assessments,
        summary,
        objectives,
      },
      toggleEditProgress,
    } = this.props;

    if (isEmpty(teamGoalDetail)) {
      return null;
    }
    const userIdentification = get(location, 'state.params.userid', null);
    const allowedEdition = selfUserId
      ? evaluator?.id === selfUserId
      : evaluator?.id === user.id;
    const initProgress = progress || 0;
    const initAssessments = assessments || [];
    const urlGoBackTo = userIdentification
      ? `/profile/${userIdentification}/goals/myteamgoals`
      : '/myteam/goals';
    const breadcrumbsList = userIdentification
      ? [
          this.messagesMyTeamGoalsDetail.profileTeamGoals,
          title,
        ]
      : [
          this.messagesMyTeamGoalsDetail.teamGoalsTitle,
          title,
        ];
    const actionList = [
      {
        icon: 'action-copy',
        onClick: this.handleDuplicate,
        title: formatMessage(messages.FeedbackGoalsDuplicate),
        disabled: this.isGoalPendingApproval(),
      },
      {
        icon: 'action-edit',
        onClick: this.handleEdit,
        title: formatMessage(actionsMessages.ActionsEdit),
        disabled: this.isGoalClosed(),
      },
      {
        icon: 'action-delete',
        onClick: () => this.handleDelete(),
        title: formatMessage(actionsMessages.ActionsDelete),
        disabled: this.isGoalClosed(),
      },
      {
        icon: 'action-close',
        onClick: () => this.handleClose(),
        title: formatMessage(messages.FeedbackGoalsDetailActionClose),
        disabled: this.isGoalClosed() || this.isGoalPendingApproval(),
      },
    ];
    const allowAddAssessments = allowedEdition
      && (status === 'OPENED')
      && (status !== 'PENDING_APPROVAL');

    return (
      <div className="my-team-goal-detail">
        <Breadcrumbs
          active={1}
          onClick={() => goToPath(urlGoBackTo)}
          values={breadcrumbsList}
        />
        <h1 className="my-team-goal-detail__main-title">
          {this.messagesMyTeamGoalsDetail.progress}
        </h1>
        <div className="my-team-goal-detail__info">
          <Segment loading={fetching || loadingApproval} loadingType="betterme">
            <div className="my-team-goal-detail__header">
              <TitleNWeight
                assignment={assignment}
                dueDate={dueDate}
                messages={this.messagesMyTeamGoalsDetail}
                orgObjectives={objectives}
                period={period}
                status={status}
                title={title}
                weighting={weighting}
              />
            </div>
            <Divider nomargin />
            <div className="my-team-goal-detail__description">
              <h2 className="my-team-goal-detail__section-title">
                {this.messagesMyTeamGoalsDetail.description}
              </h2>
              <TextRevealer
                moreButtonLabel={this.messagesMyTeamGoalsDetail.btnReadMore}
                lessButtonLabel={this.messagesMyTeamGoalsDetail.btnReadLess}
                maxHeight={100}
              >
                <pre className="my-team-goal-detail__description-text">
                  {description}
                </pre>
              </TextRevealer>
            </div>
            <div className="my-team-goal-detail__download-file-wrapper">
              <DownloadFile
                attachments={attachments}
                className="my-team-goal-detail__download-file"
              />
            </div>
            <Divider nomargin />
            <div className="my-team-goal-detail__participants">
              <h2 className="my-team-goal-detail__section-title">
                {this.messagesMyTeamGoalsDetail.participants}
              </h2>
              {(assignment === 'SHARED') ? (
                <AvatarMediaList users={assignees} type="small" />
              ) : (
                <AvatarMedia
                  className="my-team-goal-detail__header-avatar"
                  {...assignees[0]}
                  useLink={false}
                  title
                />
              )}
            </div>

            <Divider nomargin />
            <div className="my-team-goal-detail__progress">
              <div className="my-team-goal-detail__progress-header">
                <h2 className="my-team-goal-detail___progress-header-title">
                  {this.messagesMyTeamGoalsDetail.progress}
                </h2>
                <div className="my-team-goal-detail__actions">
                  {allowAddAssessments && (
                    <GoalAddAssessmentFeatDiscovery element={UPDATE_BUTTON}>
                      <Button
                        className="my-team-goal-detail__actions-progress"
                        classic
                        disabled={editingProgress}
                        onClick={
                          !editingProgress
                            ? () => toggleEditProgress(true)
                            : undefined
                        }
                        noMargin
                      >
                        {this.messagesMyTeamGoalsDetail.btnUpdateAssessment}
                      </Button>
                    </GoalAddAssessmentFeatDiscovery>
                  )}
                  {!isPercentage && (
                    <GoalScales
                      className="my-team-goal-detail__actions-info"
                      scales={scales}
                      messages={this.messagesMyTeamGoalsDetail}
                    />
                  )}
                </div>
              </div>
              <GoalAddAssessment
                editingProgress={allowAddAssessments && editingProgress}
                fetching={fetchingSent}
                files={{
                  data: files,
                  disabled: files.length >= MAX_FILES_GOAL_UPDATE_PROGRESS,
                  loading: filesLoading,
                  onChange: this.handleFileAttach,
                  onDelete: this.handleFileDelete,
                  supportedFormats: SUPPORTED_FORMATS,
                }}
                isGoalScoreContinous={!isScaleDiscrete(scales)}
                isPercentage={isPercentage}
                messages={this.messagesMyTeamGoalsDetail}
                onCancel={this.handleCancel}
                onUpdate={(data) => this.handleSave(id, data)}
                progress={initProgress}
                scores={scales}
                withFeatureDiscovery={allowAddAssessments}
              />
              {summary && (
                <div className="my-team-goal-detail__goal-summary">
                  <GoalScoreSummary
                    className="my-team-goal-detail__goal-score-summary"
                    comment={summary.comment}
                    name={summary.message}
                    score={summary.score}
                    showScore={isGoalScoreContinous}
                    title={formatMessage(
                      messages.FeedbackGoalsDetailSummaryScoreMessage
                    )}
                  />
                  <Divider
                    className="my-team-goal-detail__summary-divider"
                    nomargin
                  />
                </div>
              )}
              {(initAssessments.length > 0) && (
                <GoalAssessment
                  assessments={initAssessments}
                  isPercentage={isPercentage}
                  messages={this.messagesMyTeamGoalsDetail}
                  scales={scales}
                />
              )}
            </div>
            <Divider nomargin />
            {evaluator ? (
              <GoalFooter
                actionMessage={this.messagesMyTeamGoalsDetail.evaluatedBy}
                className="goal-feed-item__footer"
                user={evaluator}
              />
            ) : (
              <GoalFooterEmptyState
                className="goal-feed-item__footer"
                sentBy={formatMessage(messages.GoalsEmptyStateEvaluator)}
              />
            )}
          </Segment>
        </div>
        {allowedEdition && (
          <GoalActions actionList={actionList} />
        )}
      </div>
    );
  }
}

MyTeamGoalDetail.propTypes = {
  closeGoal: PropTypes.func.isRequired,
  createGoalAssessment: PropTypes.func.isRequired,
  deleteGoal: PropTypes.func.isRequired,
  editingProgress: PropTypes.bool.isRequired,
  evaluablesGoalDetail: PropTypes.object,
  fetching: PropTypes.bool.isRequired,
  fileClear: PropTypes.func.isRequired,
  fileDelete: PropTypes.func.isRequired,
  fileUpload: PropTypes.func.isRequired,
  files: PropTypes.arrayOf(PropTypes.object),
  filesLoading: PropTypes.bool,
  fetchingSent: PropTypes.bool.isRequired,
  loadingApproval: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      params: PropTypes.shape({
        userid: PropTypes.string,
      }),
    }),
  }),
  getTeamGoalDetail: PropTypes.func,
  goToPath: PropTypes.func,
  intl: PropTypes.object.isRequired,
  isGoalScoreContinous: PropTypes.bool.isRequired,
  openFullscreenModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  params: PropTypes.shape({
    goalid: PropTypes.string.isRequired,
  }).isRequired,
  resetTeamGoalDetail: PropTypes.func.isRequired,
  scores: PropTypes.array.isRequired,
  selfUserId: PropTypes.number,
  session: PropTypes.object,
  teamGoalDetail: PropTypes.object,
  toggleEditProgress: PropTypes.func.isRequired,
};

//TODO: check '...goals' destrucuring and compare with MyGoalDetail component. 
const mapStateToProps = (state) => {
  const { goals } = state;
  return {
    ...goals,
    files: selectFiles(state),
    filesLoading: selectFilesLoading(state),
    isGoalScoreContinous: selectConfigurations('continuousGoalScores')(state),
    isPercentage: selectConfigurations('allowsPercentageProgress')(state),
    scores: selectScores(state),
  };
};
const goToPath = (path) => push(path);
const mapDispatchToProps = {
  ...actions,
  ...actionsFullScreenModal,
  ...actionsRootModal,
  addToastMessage,
  fileClear,
  fileDelete,
  fileUpload,
  goToPath,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(MyTeamGoalDetail));
