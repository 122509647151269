// @packages
import React from 'react';
import PropTypes from 'prop-types';
import Segment from 'smu-ui-components/Segment';
import withRootModal from 'smu-app-components/RootModal/withRootModal';
import { injectIntl } from 'react-intl';

// @app
import { commonMessages, evaluationsMessages } from 'common/messages';

// @own
import messages from './messages';
import TeamInformationUI from './TeamInformationUI';

const TeamInformation = ({
  allowsGlbFeatures,
  collaboratorsByLeader,
  collaboratorsListTitle,
  goToLeadershipAdmin,
  hasCollaborators,
  intl: { formatMessage },
  loading,
  newWordingForLeaders,
  showChangeLeader,
  showLeaderWidget,
  showLeadershipButton,
  showOtherLeaders,
  user,
}) => {
  const { leaders, mainAndSecondayLeader, otherLeaders } = user;
  const teamInformationMessages = {
    asMainAndSecondaryLeaderTitle: newWordingForLeaders
      ? formatMessage(commonMessages.AsCareerAndExperienceLeader)
      : formatMessage(evaluationsMessages.EvaluationsMainAndSecondaryLeader),
    asMainLeaderTitle: newWordingForLeaders
      ? formatMessage(commonMessages.AsCareerLeader)
      : formatMessage(evaluationsMessages.EvaluationsMainLeader),
    asSecondaryLeaderTitle: newWordingForLeaders
      ? formatMessage(commonMessages.AsExperienceLeader)
      : formatMessage(evaluationsMessages.EvaluationsSecondaryLeader),
    changeLeaderAdminLeadership: formatMessage(messages.ChangeLeaderAdminLeadership),
    changeLeaderFeatureDiscovery: formatMessage(messages.ChangeLeaderFeatureDiscovery),
    collaboratorsListTitle: collaboratorsListTitle,
    emptyStateText: formatMessage(messages.CollaboratorsListEmptyState),
    emptyStateLeadersView: formatMessage(messages.CollaboratorsListEmptyStateView, {
      username: `${user.firstName} ${user.lastName}`,
    }),
    leadersListEmptyState: formatMessage(messages.LeadersListEmptyState),
    leadersListLeadershipRequests: formatMessage(messages.LeadersListLeadershipRequests),
    otherLeadersTitle: formatMessage(messages.LeadersListOtherLeaders),
  };
  const {
    asMainAndSecondary,
    asMain,
    asSecondary,
  } = collaboratorsByLeader;
  const collaboratorsAsMainAndSecondaryLeader = asMainAndSecondary || [];
  const collaboratorsAsMainLeader = asMain || [];
  const collaboratorsAsSecondaryLeaders = asSecondary || [];

  return (
    <Segment loading={loading} loadingType="betterme">
      <TeamInformationUI
        allowsGlbFeatures={allowsGlbFeatures}
        collaboratorsAsMainAndSecondaryLeader={collaboratorsAsMainAndSecondaryLeader}
        collaboratorsAsMainLeader={collaboratorsAsMainLeader}
        collaboratorsAsSecondaryLeaders={collaboratorsAsSecondaryLeaders}
        collaboratorsByLeader={collaboratorsByLeader}
        goToLeadershipAdmin={goToLeadershipAdmin}
        hasCollaborators={hasCollaborators}
        mainAndSecondayLeader={mainAndSecondayLeader}
        messages={teamInformationMessages}
        newWordingForLeaders={newWordingForLeaders}
        showChangeLeader={showChangeLeader}
        showLeadersPanel={showLeaderWidget}
        showLeadershipButton={showLeadershipButton}
        showOtherLeaders={showOtherLeaders}
        user={user}
      />
    </Segment>
  );
};

TeamInformation.propTypes = {
  collaboratorsByLeader: PropTypes.object.isRequired,
  goToLeadershipAdmin: PropTypes.func,
  intl: PropTypes.object.isRequired,
  showLeaderWidget: PropTypes.bool,
  showLeadershipButton: PropTypes.bool.isRequired,
  showOtherLeaders: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    leaders: PropTypes.array,
    otherLeaders: PropTypes.array,
  }).isRequired,
};

export default withRootModal(injectIntl(TeamInformation));
