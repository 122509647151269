// @packages
import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import GenericEmptyState from 'smu-ui-components/GenericEmptyState';

// @app
import { feedbackMessages } from 'common/messages';

// @own
import messages from '../messages';

const texts = {
  team: {
    RECEIVED: {
      title: messages.FeedbackFeedEmptyStateTeamReceivedTitle,
      text: messages.FeedbackFeedEmptyStateTeamReceivedText,
    },
  },
  own: {
    RECEIVED: {
      title: feedbackMessages.FeedbackNoFeedbacks,
      text: feedbackMessages.FeedbackAskColleagues,
    },
    SENT: {
      title: messages.FeedbackFeedEmptyStateOwnSentTitle,
      text: feedbackMessages.FeedbackGiveColleagues,
    }
  },
  profile: {
    RECEIVED: {
      title: messages.FeedbackFeedEmptyStateReceivedTitle,
      text: messages.FeedbackFeedEmptyStateReceivedText,
    },
    SENT: {
      title: messages.FeedbackFeedEmptyStateSentTitle,
      text: messages.FeedbackFeedEmptyStateSentText,
    }
  },
};

const FeedEmptyState = ({
  intl: { formatMessage },
  type,
  userName,
  view,
}) => {
  const name = view === 'profile' ? `${userName} ` : '';
  const emptyStateTitle = `${name}${formatMessage(texts[view][type].title)}`;
  const emptyStateText = formatMessage(texts[view][type].text);

  return (
    <GenericEmptyState
      className="px-5 py-20 bg-white"
      description={emptyStateText}
      image="listCheck"
      limitTextWidth={false}
      size="large"
      title={emptyStateTitle}
    />
  );
};

FeedEmptyState.defaultProps = {
  view: 'own',
};

FeedEmptyState.propTypes = {
  newStyle: PropTypes.bool,
  type: PropTypes.oneOf(['RECEIVED', 'SENT']).isRequired,
  view: PropTypes.oneOf(['team', 'own', 'profile']),
};

export default injectIntl(FeedEmptyState);
