/* eslint no-useless-escape:0 */
export const ANOTHER = 'ANOTHER';
export const AUTH_MESSAGES = ['INVALID_USER', '!INVALID_USER!', 'NO_RIGHTS', '!NO_RIGHTS!'];
export const BAD_REQUEST_ERROR_MESSAGE = 'Bad Request';
export const CLOUDFRONT_KEY = 'cloud.cloudfront_images';
export const COMMUNITY_ID_COOKIE_NAME = 'SMU_communityId';
export const DEFAULT_TOAST_TIMEOUT = 5000;
export const emailRegex = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g;
export const FAIL = 'FAIL';
export const HTTP_SERVER_ERROR = 'Network Error';
export const INVALID_TOKEN = 'Invalid token';
export const SPECIFIC_ERORS = [
  'You already assigned a star during this period to:',
];
export const TODAY = 'TODAY';
export const UNAUTHORIZED_ERROR = 401;
export const UNAUTHORIZED_ERROR_MESSAGE = 'NO_RIGHTS';
export const YESTERDAY = 'YESTERDAY';
