import {
  GET_DASHBOARD_SUGGESTIONS,
  GET_DASHBOARD_SUGGESTIONS_FAIL,
  GET_DASHBOARD_SUGGESTIONS_SUCCESS,
} from './actionTypes';

const initialState = {
  loadingDashboardSuggestions: false,
  suggestedActions: [],
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_DASHBOARD_SUGGESTIONS:
      return { ...state, loadingDashboardSuggestions: true  };
    case GET_DASHBOARD_SUGGESTIONS_FAIL:
      return { ...state, loadingDashboardSuggestions: false };
    case GET_DASHBOARD_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        suggestedActions: payload.response,
        loadingDashboardSuggestions: false,
      };

    default:
      return state;
  }
}
