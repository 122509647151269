// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Button from 'smu-ui-components/ButtonV2';

// @own
import './styles.scss';

const ActionButtons = ({ buttons, className }) => (
  <div className={cn('action-buttons', className)}>
    {buttons.map((button, index) => (
      !button.hide && (
        <Button
          {...button}
          className={cn('action-buttons__button', button.className)}
          color="blue"
          enabledUnification
          key={index}
        >
          {button.label}
        </Button>
      )
    ))}
  </div>
);

ActionButtons.propTypes = {
  buttons: PropTypes.array.isRequired,
  className: PropTypes.string,
}

export default ActionButtons;
