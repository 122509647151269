// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { push, goBack } from 'react-router-redux';

// @app
import { base64Decode } from 'smu-utils/base64';
import BreadcrumbHeader from 'components/organisms/BreadcrumbHeader';
import { getProfileLocation } from 'betterme-components/routes/urls';
import { ProvideForm } from 'betterme-components/hooks/form';

// @own
import Form from './Form';
import messages from './messages';
import './styles.scss';

function EngagementIndicatorForm({
  goToProfile,
  intl: { formatMessage },
  location,
  ...rest
}) {
  const breadCrumbsValues = [
    formatMessage(messages.EngagementIndicatorFormBack),
    formatMessage(messages.EngagementIndicatorFormTitle),
  ];

  const handleClose = () => {
    const { state: { user } } = location; 
    goToProfile(user.identification);
  }

  return (
    <ProvideForm>
      <div className="engagement-indicator-intervention">
        <BreadcrumbHeader
          breadCrumbsProps={{
            active: 1,
            onClick: handleClose,
            values: breadCrumbsValues,
          }}
          className="engagement-indicator-intervention__header"
          fixed
        />
        <Form
          className="engagement-indicator-intervention__form"
          handleClose={handleClose}
          location={location}
          {...rest}
        />
      </div>
    </ProvideForm>
  );
}

EngagementIndicatorForm.propTypes = {
  goToProfile: PropTypes.func.isRequired,
  intl: PropTypes.object,
  location: PropTypes.object,
};

export default connect(null, {
  goToProfile: (id) => push(getProfileLocation(id)),
})(injectIntl(EngagementIndicatorForm));
