import {
  CREARE_TECHNICAL_ASSESSMENT,
  CREARE_TECHNICAL_ASSESSMENT_FAIL,
  CREARE_TECHNICAL_ASSESSMENT_SUCCESS,
} from './actionTypes';

export function createTechnicalAssessment(payload) {
  return {
    payload,
    type: CREARE_TECHNICAL_ASSESSMENT,
  }
};

export function setCreateTechnicalAssessmentFail(error) {
  return {
    type: CREARE_TECHNICAL_ASSESSMENT_FAIL,
    payload: { error },
  };
}

export function setCreateTechnicalAssessmentSuccess() {
  return {
    type: CREARE_TECHNICAL_ASSESSMENT_SUCCESS,
  }
};
