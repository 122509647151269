import { defineMessages } from 'react-intl';

export default defineMessages({
  AppErrorTitle: {
    id: 'Betterme.AppError.Title',
    defaultMessage: "We'll be back soon.",
  },
  AppErrorDescription: {
    id: 'Betterme.AppError.Description',
    defaultMessage: "For the moment, BetterMe is under maintenance. Please, try again later.",
  },
});
