// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'smu-ui-components/IconV2';
import Segment from 'smu-ui-components/Segment';

// @own
import './styles.scss';
import ContentCardBody from './ContentCardBody';
import ContentCardFooter from './ContentCardFooter';

const ContentCardTemplate = ({
  actions,
  children,
  className,
  contentPage,
  loading,
  onToggleClick,
  open,
  subtitle,
  title,
  ...props
}) => {
  const contentCardClass = cn('content-card-teamplate', className, {
    'content-card-teamplate--content-page': contentPage,
  });
  const contentCardHeaderClass = cn('content-card-teamplate__header', {
    'content-card-teamplate__header--content-page': contentPage,
  });
  const isOpen = open === undefined || open;
  const toggleIcon = open ? 'angle-up' : 'angle-down';

  return (
    <Segment
      {...props}
      className={contentCardClass}
      loading={loading}
      loadingType="betterme"
    >
      <div className={contentCardHeaderClass}>
        <h3 className="content-card-teamplate__header-title">{title}</h3>
        {subtitle && (
          <h4 className="content-card-teamplate__header-subtitle">{subtitle}</h4>
        )}
        <div className="content-card-teamplate__header-actions">
          {actions}
          {open !== undefined && (
            <Icon
              className="content-card-teamplate__header-toggle-icon"
              icon={toggleIcon}
              onClick={onToggleClick}
              size="medium"
            />
          )}
        </div>
      </div>
      {isOpen && children}
    </Segment>
  );
};

ContentCardTemplate.Body = ContentCardBody;
ContentCardTemplate.Footer = ContentCardFooter;

ContentCardTemplate.propTypes = {
  actions: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  contentPage: PropTypes.bool,
  loading: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default ContentCardTemplate;
