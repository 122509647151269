// @packages
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import cn from 'classnames';
import Collapsible from 'smu-ui-components/Collapsible';
import Segment from 'smu-ui-components/Segment';

// @app
import Title from 'components/atoms/Title';
import EvaluationsHeader from 'components/molecules/EvaluationsHeader';
import { commonMessages, evaluationsMessages } from 'common/messages';

// @own
import TeamEvaluationsItem from '../TeamEvaluationsItem';
import messages from '../messages';
import './styles.scss';

const TeamEvaluations = ({
  allowsEvaluationDrafts,
  allowsEvaluationsCreation,
  allowsNewHireEvaluationCreation,
  allowsNewHireEvaluationDraft,
  allowsPeriodEvaluation,
  allowsPerformanceRetrospectiveCreation,
  allowsPerformanceRetrospectiveDraft,
  asMainAndSecondaryLeader,
  asMainLeader,
  asSecondaryLeader,
  canCreateBehaviourRetrospective,
  filterConfig,
  intl: { formatMessage },
  loadingEvaluationsDrafts,
  statusMessages,
  newStyle,
  newWordingForLeaders,
  onCreateClick,
  onHistoryClick,
  selfUserId,
}) => {
  const [mainOpen, setMainOpen] = useState(true);
  const [mainSecondaryOpen, setMainSecondaryOpen] = useState(true);
  const [secondaryOpen, setSecondaryOpen] = useState(true);

  function filterEvaluations(collaborators, filterConfig) {
    const {
      status: {
        draft, foreignDraft, notStarted, submitted, submittedByMe, submittedByOther
      },
    } = statusMessages;

    const statusesToFilter = [];

    if (filterConfig[submitted]) statusesToFilter.push('SUBMITTED');
    if (filterConfig[submittedByOther]) statusesToFilter.push('SUBMITTED_BY_OTHER');
    if (filterConfig[submittedByMe]) statusesToFilter.push('SUBMITTED_BY_ME');
    if (filterConfig[draft]) statusesToFilter.push('DRAFT');
    if (filterConfig[foreignDraft]) statusesToFilter.push('FOREIGN_DRAFT');
    if (filterConfig[notStarted]) statusesToFilter.push(undefined);

    if (statusesToFilter.length === 0) {
      return collaborators;
    }

    return collaborators?.filter((collaborator) => {
      return statusesToFilter.includes(collaborator?.evaluation?.status);
    });
  }

  const mainAndSecondaryLeaderTitle = newWordingForLeaders
    ? formatMessage(commonMessages.AsCareerAndExperienceLeader)
    : formatMessage(evaluationsMessages.EvaluationsMainAndSecondaryLeader);
  const mainLeaderTitle = newWordingForLeaders
    ? formatMessage(evaluationsMessages.EvaluationsCareerLeader)
    : formatMessage(evaluationsMessages.EvaluationsMainLeader);
  const secondaryLeaderTitle = newWordingForLeaders
    ? formatMessage(evaluationsMessages.EvaluationsAccountLeader)
    : formatMessage(evaluationsMessages.EvaluationsSecondaryLeader);

  const filteredCollaboratorsAsMainAndSecondary = filterEvaluations(asMainAndSecondaryLeader, filterConfig);
  const filteredCollaboratorsAsMain = filterEvaluations(asMainLeader, filterConfig);
  const filteredCollaboratorsAsSecondary = filterEvaluations(asSecondaryLeader, filterConfig);
  const headerItems = [
    {
      name: formatMessage(messages.EvaluationsHeaderTeamMember),
      className: 'team-evaluations__header--members',
    },
    {
      name: formatMessage(messages.EvaluationsHeaderCurrentStatus),
      className: 'team-evaluations__header--status',
    },
    {
      name: formatMessage(messages.EvaluationsHeaderCreatedBy),
      className: 'team-evaluations__header--created-by',
    },
    {
      name: formatMessage(messages.EvaluationsHeaderActions),
      className: 'team-evaluations__header--actions',
    },
  ];
  const communityPermissions = {
    allowsEvaluationsCreation,
    allowsEvaluationDrafts,
    allowsNewHireEvaluationCreation,
    allowsNewHireEvaluationDraft,
    allowsPeriodEvaluation,
    allowsPerformanceRetrospectiveCreation,
    allowsPerformanceRetrospectiveDraft,
  };

  useEffect(() => {
    if (asMainAndSecondaryLeader?.length > 5) setMainSecondaryOpen(false);
    if (asMainLeader?.length > 5) setMainOpen(false);
    if (asSecondaryLeader?.length > 5) setSecondaryOpen(false);
  }, [asMainAndSecondaryLeader, asMainLeader, asSecondaryLeader]);

  return (
    <Segment
      className={cn('team-evaluations', { 'team-evaluations--new-style': newStyle })}
      loading={loadingEvaluationsDrafts}
      loadingType="betterme"
    >
      {asMainAndSecondaryLeader?.length > 0 && (
        <Collapsible
          className="team-evaluations__collaborators-list"
          expanded={mainSecondaryOpen}
          onClick={() => setMainSecondaryOpen(!mainSecondaryOpen)}
          title={mainAndSecondaryLeaderTitle}
          variant="table"
          withIcon
        >
          <EvaluationsHeader
            className="team-evaluations__header"
            headerItems={headerItems}
          />
          {filteredCollaboratorsAsMainAndSecondary?.length > 0 ? (
            <ul className="team-evaluations__list">
              {filteredCollaboratorsAsMainAndSecondary.map((collaborator) => (
                <TeamEvaluationsItem
                  {...collaborator}
                  {...communityPermissions}
                  canCreateBehaviourRetrospective={canCreateBehaviourRetrospective}
                  className="team-evaluations__item"
                  enabledLeader
                  key={collaborator.id}
                  onCreateClick={onCreateClick}
                  onHistoryClick={onHistoryClick}
                  selfUserId={selfUserId}
                />
              ))}
            </ul>
          ) : (
            <div className="team-evaluations__empty-state">
              <Title
                black
                className="team-evaluations__empty-state--centered"
                size={12}
              >
                {formatMessage(evaluationsMessages.EvaluationsEmptyStateText)}
              </Title>
            </div>
          )}
        </Collapsible>
      )}
      {asMainLeader?.length > 0 && (
        <Collapsible
          className="team-evaluations__collaborators-list"
          expanded={mainOpen}
          onClick={() => setMainOpen(!mainOpen)}
          title={mainLeaderTitle}
          variant="table"
          withIcon
        >
          <EvaluationsHeader
            className="team-evaluations__header"
            headerItems={headerItems}
          />
          {filteredCollaboratorsAsMain?.length > 0 ? (
            <ul className="team-evaluations__list">
              {filteredCollaboratorsAsMain.map((collaborator) => (
                <TeamEvaluationsItem
                  {...collaborator}
                  {...communityPermissions}
                  canCreateBehaviourRetrospective={canCreateBehaviourRetrospective}
                  className="team-evaluations__item"
                  key={collaborator.id}
                  onCreateClick={onCreateClick}
                  onHistoryClick={onHistoryClick}
                  selfUserId={selfUserId}
                />
              ))}
            </ul>
          ) : (
            <div className="team-evaluations__empty-state">
              <Title
                black
                className="team-evaluations__empty-state--centered"
                size={12}
              >
                {formatMessage(evaluationsMessages.EvaluationsEmptyStateText)}
              </Title>
            </div>
          )}
        </Collapsible>
      )}
      {asSecondaryLeader?.length > 0 && (
        <Collapsible
          className="team-evaluations__collaborators-list"
          expanded={secondaryOpen}
          onClick={() => setSecondaryOpen(!secondaryOpen)}
          title={secondaryLeaderTitle}
          variant="table"
          withIcon
        >
          <EvaluationsHeader
            className="team-evaluations__header"
            headerItems={headerItems}
          />
          {filteredCollaboratorsAsSecondary?.length ? (
            <ul className="team-evaluations__list">
              {filteredCollaboratorsAsSecondary.map((collaborator) => (
                <TeamEvaluationsItem
                  {...collaborator}
                  {...communityPermissions}
                  canCreateBehaviourRetrospective={canCreateBehaviourRetrospective}
                  className="team-evaluations__item"
                  enabledLeader
                  key={collaborator?.id}
                  onCreateClick={onCreateClick}
                  onHistoryClick={onHistoryClick}
                  selfUserId={selfUserId}
                />
              ))}
            </ul>
          ) : (
            <div className="team-evaluations__empty-state">
              <Title
                black
                className="team-evaluations__empty-state--centered"
                size={12}
              >
                {formatMessage(evaluationsMessages.EvaluationsEmptyStateText)}
              </Title>
            </div>
          )}
        </Collapsible>
      )}
    </Segment>
  );
}

TeamEvaluations.propTypes = {
  allowsContinuousEvaluation: PropTypes.bool,
  allowsEvaluationDrafts: PropTypes.bool,
  allowsEvaluationsCreation: PropTypes.bool,
  allowsPeriodEvaluation: PropTypes.bool,
  collaborators: PropTypes.array.isRequired,
  newStyle: PropTypes.bool,
  newWordingForLeaders: PropTypes.bool.isRequired,
  onCreateClick: PropTypes.func,
  onHistoryClick: PropTypes.func,
  selfUserId: PropTypes.number,
};

export default injectIntl(TeamEvaluations);
