// @packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

// @app
import ContentCardTemplate from 'components/templates/ContentCardTemplate';
import {
  OPENED_STATUS,
  CLOSED_STATUS,
} from 'betterme-components/Goals/constants';
import {
  selectAssignedGoals,
  selectLoadingMyGoals,
} from 'betterme-components/Goals/selectors';
import { getAssignedGoals } from 'betterme-components/Goals/actions';
import {
  actionsMessages,
  commonMessages,
  goalsMessages,
  placeholdersMessages,
  sectionsMessages,
} from 'common/messages';

// @own
import GoalOverview from 'components/organisms/GoalOverview';

const GoalOverviewWidget = ({
  dateTimeFrom,
  dateTimeTo,
  defaultFilterOpen,
  getAssignedGoals,
  intl: { formatMessage },
  onToggleClick,
  open,
  overviewGoals,
  overviewGoalsLoading,
  user,
}) => {
  const [intervalDates, setIntervalDates] = useState({});
  const from = dateTimeFrom ?? intervalDates.dateTimeFrom
  const to = dateTimeTo ?? intervalDates.dateTimeTo
  const goalsFilters =  (value) => ({
    status: value,
    dueDateFrom: from?.valueOf(),
    dueDateTo: to?.valueOf(),
  });

  const handleGoalFilterChange = (value) => {
    if (user?.id) {
      getAssignedGoals(user?.id, goalsFilters(value));
    };
  };

  const dropdownOptions = [
    {
      label: formatMessage(goalsMessages.GoalsInProgress),
      value: OPENED_STATUS,
    },
    {
      label: formatMessage(goalsMessages.GoalsClosedGoals),
      value: CLOSED_STATUS,
    },
  ];

  const messages = {
    dueDate: formatMessage(sectionsMessages.SectionsDueDate),
    score: formatMessage(commonMessages.Score),
    weight: formatMessage(goalsMessages.GoalsWeight),
    placeholderNoResults: formatMessage(
      placeholdersMessages.PlaceholdersNoResults
    ),
    viewMore: formatMessage(actionsMessages.ActionsViewMore),
  };

  const initialDropdownValue = defaultFilterOpen
    ? OPENED_STATUS
    : CLOSED_STATUS;

  useEffect(() => {
    setIntervalDates({
      dateTimeFrom: dateTimeFrom,
      dateTimeTo: dateTimeTo,
    });

    if (user?.id) {
      getAssignedGoals(user?.id, goalsFilters(CLOSED_STATUS));
    };
  }, [user]);

  return (
    <ContentCardTemplate
      title={formatMessage(goalsMessages.GoalsOverview)}
      open={open}
      onToggleClick={onToggleClick}
    >
      <ContentCardTemplate.Body>
        <GoalOverview
          defaultFilterOpen={defaultFilterOpen}
          dropdownOptions={dropdownOptions}
          goals={overviewGoals}
          initialDropdownValue={initialDropdownValue}
          loading={overviewGoalsLoading}
          messages={messages}
          onFilterChange={handleGoalFilterChange}
        />
      </ContentCardTemplate.Body>
    </ContentCardTemplate>
  );
};

GoalOverviewWidget.propTypes = {
  defaultFilterOpen: PropTypes.bool,
  goals: PropTypes.array,
  intl: PropTypes.object,
  loading: PropTypes.bool,
  onFilterChange: PropTypes.func,
  onToggleClick: PropTypes.func,
  open: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  overviewGoals: selectAssignedGoals(state),
  overviewGoalsLoading: selectLoadingMyGoals(state),
});

const mapDispatchToProps = {
  getAssignedGoals,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(GoalOverviewWidget));
