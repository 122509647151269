import {
  CREATE_FEEDBACKS_FOR_LEADERS,
  CREATE_FEEDBACKS_FOR_LEADERS_FAIL,
  CREATE_FEEDBACKS_FOR_LEADERS_SUCCESS,
  GET_QUESTIONS_FEEDBACKS_FOR_LEADERS,
  GET_QUESTIONS_FEEDBACKS_FOR_LEADERS_FAIL,
  GET_QUESTIONS_FEEDBACKS_FOR_LEADERS_SUCCESS,
  GET_RATINGS_FEEDBACKS_FOR_LEADERS,
  GET_RATINGS_FEEDBACKS_FOR_LEADERS_FAIL,
  GET_RATINGS_FEEDBACKS_FOR_LEADERS_SUCCESS,
  RESET_FEEDBACKS_FOR_LEADERS,
  SET_ANSWER_COMPETENCY,
  SET_ANSWER_INSIGHT,
} from './actionTypes';

export function createFeedbackForLeaders(data, messages, user) {
  return {
    type: CREATE_FEEDBACKS_FOR_LEADERS,
    payload: { data },
    messages,
    user,
  }
}

export function createFeedbackForLeadersFail(error) {
  return {
    type: CREATE_FEEDBACKS_FOR_LEADERS_FAIL,
    payload: { error },
  }
}

export function createFeedbackForLeadersSuccess(response) {
  return {
    type: CREATE_FEEDBACKS_FOR_LEADERS_SUCCESS,
    payload: { response },
  }
}

export function getQuestionsFeedbackForLeaders(params) {
  return {
    type: GET_QUESTIONS_FEEDBACKS_FOR_LEADERS,
    payload: { params },
  }
}

export function getQuestionsFeedbackForLeadersFail(error) {
  return {
    type: GET_QUESTIONS_FEEDBACKS_FOR_LEADERS_FAIL,
    payload: { error },
  }
}

export function getQuestionsFeedbackForLeadersSuccess(response) {
  return {
    type: GET_QUESTIONS_FEEDBACKS_FOR_LEADERS_SUCCESS,
    payload: { response },
  }
}

export function getRatingsFeedbackForLeaders(params) {
  return {
    type: GET_RATINGS_FEEDBACKS_FOR_LEADERS,
    payload: { params },
  }
}

export function getRatingsFeedbackForLeadersFail(error) {
  return {
    type: GET_RATINGS_FEEDBACKS_FOR_LEADERS_FAIL,
    payload: { error },
  }
}

export function getRatingsFeedbackForLeadersSuccess(response) {
  return {
    type: GET_RATINGS_FEEDBACKS_FOR_LEADERS_SUCCESS,
    payload: { response },
  }
}

export function resetFeedbackForLeaders() {
  return {
    type: RESET_FEEDBACKS_FOR_LEADERS,
  }
}

export function setAnswerCompetency(question) {
  return {
    type: SET_ANSWER_COMPETENCY,
    payload: { question },
  }
}

export function setAnswerInsight(question) {
  return {
    type: SET_ANSWER_INSIGHT,
    payload: { question },
  }
}
