import { defineMessages } from 'react-intl';

export default defineMessages({
  PerformanceRetrospectiveDetailEvaluations: {
    id: 'Betterme.PerformanceRetrospectiveDetail.MyEvaluations',
    defaultMessage: 'My Evaluations',
  },
  PerformanceRetrospectiveDetailMyTeamEvaluations: {
    id: 'Betterme.PerformanceRetrospectiveDetail.MyTeamEvaluations',
    defaultMessage: 'My Team\'s Evaluations',
  },
  PerformanceRetrospectiveDetailTitle: {
    id: 'Betterme.PerformanceRetrospectiveDetail.Title',
    defaultMessage: 'PERFORMANCE RETROSPECTIVE',
  },
  PerformanceRetrospectiveDetailEvaluationOf: {
    id: 'Betterme.PerformanceRetrospectiveDetail.EvaluationOf',
    defaultMessage: 'Performance Retrospective of',
  },
  PerformanceRetrospectiveDetailScoreTitle: {
    id: 'Betterme.PerformanceRetrospectiveDetail.ScoreTitle',
    defaultMessage: 'PERFORMANCE SCORE',
  },
  PerformanceRetrospectiveDetailSkillsTitle: {
    id: 'Betterme.PerformanceRetrospectiveDetail.SkillsTitle',
    defaultMessage: 'Technical Skills',
  },
  PerformanceRetrospectiveDetailWrittenBy: {
    id: 'Betterme.PerformanceRetrospectiveDetail.WrittenBy',
    defaultMessage: 'Written by',
  },
  PerformanceRetrospectiveDetailSentBy: {
    id: 'Betterme.PerformanceRetrospectiveDetail.SentBy',
    defaultMessage: 'Sent by',
  },
  PerformanceRetrospectiveDetailSentByUsers: {
    id: 'Betterme.PerformanceRetrospectiveDetail.SentByUsers',
    defaultMessage: 'Sent by <b>{evaluator}</b> and <b>{coEvaluator}</b>',
  },
  PerformanceRetrospectiveDetailBehavioralTitle: {
    id: 'Betterme.PerformanceRetrospectiveDetail.BehavioralTitle',
    defaultMessage: 'Behavioral Assessment',
  },
  PerformanceRetrospectiveDetailBehavioralSubtitle: {
    id: 'Betterme.PerformanceRetrospectiveDetail.BehavioralSubtitle',
    defaultMessage: 'The frequency of each behavior shows the level of consistency and development observed during the analyzed period.',
  },
  PerformanceRetrospectiveDetailReplyTitle: {
    id: 'Betterme.PerformanceRetrospectiveDetail.ReplyTitle',
    defaultMessage: 'PERFORMANCE RETROSPECTIVE REPLY',
  },
  PerformanceRetrospectiveDetailLabelAgree: {
    id: 'Betterme.PerformanceRetrospectiveDetail.LabelAgree',
    defaultMessage: 'I agree with the Performance Retrospective',
  },
  PerformanceRetrospectiveDetailLabelDisagree: {
    id: 'Betterme.PerformanceRetrospectiveDetail.LabelDisagree',
    defaultMessage: 'I disagree with the Performance Retrospective',
  },
  PerformanceRetrospectiveDetailPlaceholderComment: {
    id: 'Betterme.PerformanceRetrospectiveDetail.PlaceholderComment',
    defaultMessage: 'Leave a comment about the Performance Retrospective',
  },
  PerformanceRetrospectiveDetailMeaningfulConversation: {
    id: 'Betterme.PerformanceRetrospectiveDetail.MeaningfulConversation',
    defaultMessage: 'Meaningful Conversation',
  },
  PerformanceRetrospectiveDetailMeaningfulConversationText: {
    id: 'Betterme.PerformanceRetrospectiveDetail.MeaningfulConversationText',
    defaultMessage: 'I felt the conversation with my leader was clear, caring and kind and it allowed me to discover/define career or development opportunities.',
  },
  PerformanceRetrospectiveDetailClear: {
    id: 'Betterme.PerformanceRetrospectiveDetail.Clear',
    defaultMessage: 'Clear',
  },
  PerformanceRetrospectiveDetailSend: {
    id: 'Betterme.PerformanceRetrospectiveDetail.Send',
    defaultMessage: 'Send',
  },
  PerformanceRetrospectiveDetailMeaningfulConversationFirstOption: {
    id: 'Betterme.PerformanceRetrospectiveDetail.MeaningfulConversationFirstOption',
    defaultMessage: 'Agree',
  },
  PerformanceRetrospectiveDetailMeaningfulConversationSecondOption: {
    id: 'Betterme.PerformanceRetrospectiveDetail.MeaningfulConversationSecondOption',
    defaultMessage: 'Disagree',
  },
  PerformanceRetrospectiveDetailMeaningfulConversationThirdOption: {
    id: 'Betterme.PerformanceRetrospectiveDetail.MeaningfulConversationThirdOption',
    defaultMessage: 'I didn\'t have one',
  },
});

