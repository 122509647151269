// @packages
import React from 'react';
import PropTypes from 'prop-types';
import RoundedTag from 'smu-ui-components/RoundedTag';
import Icon from 'smu-ui-components/Icon';

// @own
import './styles.scss';

const SkillItem = ({
  id,
  name,
  onClick,
}) => {
  return (
    <RoundedTag className="skill-item" onClick={() => onClick(id)}>
      <div className="skill-item__name">{name}</div>
        <Icon
          className="skill-item__icon"
          icon="more"
          size="medium"
          color="black"
        />
    </RoundedTag>
  );
};

SkillItem.defaultProps = {
  onClick: () => undefined,
};

SkillItem.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default SkillItem;
