// @packages
import get from 'lodash/get';

export const selectPerformanceRetrospective = ({ performanceRetrospective }) =>
  get(performanceRetrospective, 'performanceRetrospectiveData');

export const selectPerformanceRetrospectiveLoading = ({ performanceRetrospective }) =>
  get(performanceRetrospective, 'performanceRetrospectiveDataLoading');

export const selectPerformanceRetrospectiveBehaviours = ({ performanceRetrospective }) =>
  get(performanceRetrospective, 'performanceRetrospectiveBehaviours');
