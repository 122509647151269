import { createSelector } from 'reselect';
import { get, isNil, maxBy, some } from 'lodash';

//TODO only define selectors that belongs to "goals" domain
export const selectAssignedGoals = ({ goals }) => get(goals, 'assignedGoals');
export const selectCommunityConfigurations = state => state.session.communityConfigurations;
export const selectEditingProgress = ({ goals }) => get(goals, 'editingProgress');
export const selectFetchingSent = ({ goals }) => get(goals, 'fetchingSent');
export const selectLoadingApprovalId = ({ goals }) => get(goals, 'loadingApprovalId');
export const selectLoadingClose = ({ goals }) => get(goals, 'loadingClose');
export const selectLoadingDelete = ({ goals }) => get(goals, 'loadingDelete');
export const selectLoadingDisapprovalId = ({ goals }) => get(goals, 'loadingDisapprovalId');
export const selectLoadingMyGoals = ({ goals }) => get(goals, 'fetching');
export const selectLoadingPeriods = ({ goals }) => get(goals, 'fetchingPeriod');
export const selectLoadingSummaryGoals = ({ goals }) => get(goals, 'loadingSummaryGoals');
export const selectLocationBeforeTransitions = state => state.routing.locationBeforeTransitions;
export const selectMyGoalsFilters = ({ goals }) => get(goals, 'myGoalsFilters');
export const selectOrgObjectives = ({ goals }) => get(goals, 'organizationalObjectives');
export const selectPeriods = ({ goals }) => get(goals, 'period');
export const selectSummaryGoals = ({ goals }) => get(goals, 'summaryGoals');
export const selectTeamGoalDetail = state => state.goals.teamGoalDetail;
export const selectTeamGoalsFilters = ({ goals }) => get(goals, 'teamGoalsFilters');
export const selectUser = state => state.session.user;

const selectUsersSelectedTotalWeight = ({ goals }) => get(goals, 'usersSelectedTotalWeight');
const isFilterEmpty = (value) => !(isNil(value) || value === '');

export const selectAvailableWeight = createSelector(
  selectUsersSelectedTotalWeight,
  usersSelectedTotalWeight => {
    const maxWeight = get(
      maxBy(usersSelectedTotalWeight, user => user.totalWeight),
      'totalWeight',
      0
    );
    const availableWeight = 100 - maxWeight;

    return Math.max(availableWeight, 0);
  }
);

export const selectMyFiltersApplied = createSelector(
  selectMyGoalsFilters,
  (goalsFilters) => some(goalsFilters, isFilterEmpty)
);

export const selectTeamFiltersApplied = createSelector(
  selectTeamGoalsFilters,
  (goalsFilters) => some(goalsFilters, isFilterEmpty)
);
