// @packages
import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'smu-ui-components/Popup';

// @own
import './styles.scss';

const InfoIcon = ({
  children,
  className,
  padding,
  position,
  ...props
}) => {
  const iconNode = (
    <span
      {...props}
      className={cn('info-icon', className)}
    >
      i
    </span>
  );
  return children ? (
    <Popup
      hideOnScroll
      inverted
      position={position}
      trigger={iconNode}
    >
      <div className={cn('info-icon__content', {
        'info-icon__content--padding': padding,
      })}>
        {children}
      </div>
    </Popup>

  ) : iconNode;
};

InfoIcon.defaultProps = {
  position: 'right center'
};

InfoIcon.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  padding: PropTypes.bool,
  position: PropTypes.string,

};

export default InfoIcon;
