// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import AvatarMedia from 'smu-ui-components/AvatarMedia';
import Segment from 'smu-ui-components/Segment';

// @app
import PersonalDataItemsList from 'components/organisms/PersonalDataItemsList';
import ActionButtons from 'components/molecules/ActionButtons';

// @own
import './styles.scss';

const UserInfoCard = ({
  className,
  highlighted,
  leaderType,
  loading,
  messages: {
    account: accountMessage,
    area: areaMessage,
    office: officeMessage,
    project: projectMessage,
    remove: removeMessage,
    replace: replaceMessage,
  },
  onRemoveClick,
  onReplaceClick,
  showActionButtons,
  showRemoveButton,
  showReplaceButton,
  type,
  user,
}) => {
  const items = [
    {
      title: projectMessage,
      description: user?.project,
    },
    {
      title: areaMessage,
      description: user?.area,
    },
    {
      title: accountMessage,
      description: user?.account,
    },
    {
      title: officeMessage,
      description: user?.office,
    },
  ];
  const actionButtons = [
    {
      label: removeMessage,
      onClick: () => onRemoveClick(leaderType),
      size: 'medium',
      variant: 'outline',
      show: showRemoveButton ?? true,
    },
    {
      label: replaceMessage,
      onClick: () => onReplaceClick(type),
      size: 'medium',
      show: showReplaceButton ?? true,
    },
  ];

  const actionButtonsFiltered = actionButtons.filter((button) => button.show);

  return (
    <Segment
      className={cn(
        'user-info-card',
        {'user-info-card--highlighted': highlighted},
        className,
      )}
      loading={loading}
      loadingType="betterme"
    >
      <div className="user-info-card__heading">
        <AvatarMedia className="user-info-card__avatar" {...user} />
        {showActionButtons && (
          <ActionButtons
            buttons={actionButtonsFiltered}
            className="user-info-card__action-buttons"
          />
        )}
      </div>
      <PersonalDataItemsList
        className="user-info-card__info-items"
        display="column"
        items={items}
        itemsPerRow={2}
        variant="gray"
      />
    </Segment>
  );
};

UserInfoCard.defaultProps = {
  onRemoveClick: () => {},
  onReplaceClick: () => {},
};

UserInfoCard.propTypes = {
  className: PropTypes.string,
  highlighted: PropTypes.bool,
  leaderType: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  messages: PropTypes.objectOf(PropTypes.string),
  onRemoveClick: PropTypes.func,
  onReplaceClick: PropTypes.func,
  showActionButtons: PropTypes.bool,
  type: PropTypes.string.isRequired,
  user: PropTypes.object,
};

export default UserInfoCard;
