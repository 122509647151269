// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @own
import './styles.scss';

const EmptyStateCardDescription = ({
  children,
  className,
}) => {
  return (
    <div className={cn('empty-state-card-description', className)}>
      {children}
    </div>
  );
};

EmptyStateCardDescription.propTypes = {
  children: PropTypes.node.isRequired,
};

export default EmptyStateCardDescription;
