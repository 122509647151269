import { defineMessages } from 'react-intl';

export default defineMessages({
  FeedbackRequestInfo: {
    id: 'Betterme.FeedbackRequest.Info',
    defaultMessage: 'Has sent you the following Feedback request'
  },
  FeedbackRequestPlaceholder: {
    id: 'Betterme.FeedbackRequest.Placeholder',
    defaultMessage: 'You don\'t have any pending Feedback requests'
  },
  FeedbackRequestMore: {
    id: 'Betterme.FeedbackRequest.More',
    defaultMessage: 'and more...'
  },
  FeedbackRequestActionView: {
    id: 'Betterme.FeedbackRequest.ActionView',
    defaultMessage: 'View'
  },
  FeedbackRequestDismissModalTitle: {
    id: 'Betterme.FeedbackRequest.DismissModal.Title',
    defaultMessage: 'I couldn\'t send you this feedback because...'
  },
  FeedbackRequestDismissModalAccept: {
    id: 'Betterme.FeedbackRequest.DismissModal.Accept',
    defaultMessage: 'Accept'
  },
  FeedbackRequestViewAll: {
    id: 'Betterme.FeedbackRequest.ViewAll',
    defaultMessage: 'View all'
  },
  FeedbackRequestDismissModalSuccess: {
    id: 'Betterme.FeedbackRequest.DismissModal.Success',
    defaultMessage: 'Your message was sent successfully!'
  },
  FeedbackRequestDismissModalFail: {
    id: 'Betterme.FeedbackRequest.DismissModal.Fail',
    defaultMessage: 'We couldn’t send your message.'
  },
  FeedbackRequestDismissFeatureDiscoveryView: {
    id: 'Betterme.FeedbackRequest.FeatureDiscoveryView',
    defaultMessage: 'Click on \"view all\" and access to the inbox of all your Feedback requests.'
  },
  FeedbackRequestInboxTabsTopicsTitle: {
    id: 'Betterme.FeedbackRequestInboxTabs.TopicsTitle',
    defaultMessage: 'Requested competencies for this Feedback'
  },
  FeedbackRequestInboxTabsAnswer: {
    id: 'Betterme.FeedbackRequestInboxTabs.Answer',
    defaultMessage: 'Answer'
  },
  FeedbackRequestInboxTabsDismiss: {
    id: 'Betterme.FeedbackRequestInboxTabs.Dismiss',
    defaultMessage: 'Dismiss'
  },
  FeedbackRequestInboxTabsDecline: {
    id: 'Betterme.FeedbackRequestInboxTabs.Decline',
    defaultMessage: 'Decline'
  },
  FeedbackRequestInboxTabsReceived: {
    id: 'Betterme.FeedbackRequestInboxTabs.Received',
    defaultMessage: 'Waiting for my Feedback'
  },
  FeedbackRequestInboxTabsSent: {
    id: 'Betterme.FeedbackRequestInboxTabs.Sent',
    defaultMessage: 'Requested by me'
  },
  FeedbackRequestInboxTabsCancelSuccess: {
    id: 'Betterme.FeedbackRequestInboxTabs.CancelSuccess',
    defaultMessage: 'Your Feedback request was cancelled successfully'
  },
  FeedbackRequestInboxEmptyStateReceivedTitle: {
    id: 'Betterme.FeedbackRequestInboxEmptyState.ReceivedTitle',
    defaultMessage: 'You don\'t have any Feedback requests waiting for your answer'
  },
  FeedbackRequestInboxEmptyStateReceivedText: {
    id: 'Betterme.FeedbackRequestInboxEmptyState.ReceivedText',
    defaultMessage: 'Send your colleagues Feedback that helps glowing their careers'
  },
  FeedbackRequestInboxEmptyStateSentTitle: {
    id: 'Betterme.FeedbackRequestInboxEmptyState.SentTitle',
    defaultMessage: 'You don\'t have any Feedback requests pending to be answered'
  },
  FeedbackRequestInboxEmptyStateSentText: {
    id: 'Betterme.FeedbackRequestInboxEmptyState.SentText',
    defaultMessage: 'Ask your colleagues for Feedback that helps your career grow'
  },
});
