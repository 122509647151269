// @packages
import React from 'react';
import PropTypes from 'prop-types';

// @own
import './styles.scss';
import CompetencyItem from '../../CompetencyItem';

const CompetencyDragPreview = ({ competency }) => {
  return <CompetencyItem competency={competency} className="competency-item-drag-preview" />;
};

CompetencyDragPreview.propTypes = {
  competency: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
};

export default CompetencyDragPreview;
