import React from "react";
import { useUserToBePromoted } from 'smu-services/betterme/api/promotions/peer/suggestion/get';

export const withSuggestedPromotionHOC = (Component) => {
  return function WithHook(props) {
    const { user, selectedUser, allowsFeedbackPeerPromotionSuggestion } = props;
    const userID = user
      ? user.id :
      selectedUser.length === 1 ? selectedUser[0].id
        : null;

    const {
      data,
      error,
      isLoading,
      isValidating,
      isError,
    } = useUserToBePromoted({ promoteID: userID, allowsFeedbackPeerPromotionSuggestion });

    return <Component {...props} swrData={{
      data,
      error,
      errorMessage: error?.message,
      isLoading,
      isValidating,
      isError
    }} />;
  };
};

export default withSuggestedPromotionHOC;
