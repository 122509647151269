import {
  CHANGE_SELECTED_USER,
  DELETE_SELECTED_USER,
  GET_USERS_LIST,
  GET_USERS_LIST_FAIL,
  GET_USERS_LIST_SUCCESS,
  RESET_SELECTED_USER,
  RESET_USERS_LIST,
  SET_SELECTED_USER,
  SET_SELECTED_USER_LIST,
} from './actionTypes';

const initialState = {
  selectedUser: [],
  usersList: [],
  usersListLoading: false,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_USERS_LIST:
      return {
        ...state,
        usersListLoading: true,
      };
    case GET_USERS_LIST_SUCCESS:
      return {
        ...state,
        usersList: payload,
        usersListLoading: false,
      };
    case GET_USERS_LIST_FAIL:
      return {
        ...state,
        usersList: payload.error,
        usersListLoading: false,
      };
    case RESET_USERS_LIST:
      return {
        ...state,
        usersList: [],
      };
    case SET_SELECTED_USER:
      return {
        ...state,
        selectedUser: [payload],
      };
    case SET_SELECTED_USER_LIST:
      return {
        ...state,
        selectedUser: [...state?.selectedUser, payload],
      };
    case RESET_SELECTED_USER:
      return {
        ...state,
        selectedUser: [],
      }
    case DELETE_SELECTED_USER:
      return {
        ...state,
        selectedUser: state?.selectedUser?.filter(u => u?.id !== payload),
      }
    case CHANGE_SELECTED_USER:
      return {
        ...state,
        selectedUser: payload?.selectedUser,
      }
    default:
      return state;
  }
}