// @packages
import React, { useEffect, useState } from 'react';
import AvatarMedia from 'smu-ui-components/AvatarMedia';
import Button from 'smu-ui-components/ButtonV2';
import DataTable from 'smu-ui-components/DataTable';
import DebounceInput from 'smu-ui-components/DebounceInput';
import Link from 'smu-ui-components/Link';
import LottieSpinner from 'smu-ui-components/LottieSpinner';
import PropTypes from 'prop-types';
import Segment from 'smu-ui-components/Segment';
import SelectV2 from 'smu-ui-components/SelectV2';
import UserAvatarList from 'smu-ui-components/UserAvatarList';
import GenericEmptyState from 'smu-ui-components/GenericEmptyState';
import isEmpty from 'lodash/isEmpty';
import { actionRequestInit } from 'smu-utils/reduxRequests/actions';
import { connect } from 'react-redux';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import {
  selectRequesting,
  selectResult,
  selectResultProp,
} from 'smu-utils/reduxRequests/selectors';

// @app
import Title from 'components/atoms/Title';
import { getManageLeadersAdminLocation } from 'betterme-components/routes/urls';
import {
  apiGetFocalUsers,
  apiGetUserRolesAndPermissions,
} from 'betterme-components/services/ManageLeaders/api';
import { commonMessages } from 'common/messages';

// @own
import './styles.scss';
import messages from './messages';
import {
  ADMIN_SEGMENT,
  ALL,
  PAGE_SIZE,
  REQUEST_ID_GET_FOCAL_USERS_TABLE,
  REQUEST_ID_GET_USER_ROLES_AND_PERMISSIONS,
  SEGMENT,
  WITHOUT_LEADERS,
  WITHOUT_MAIN_LEADER,
  WITHOUT_SECONDARY_LEADER,
} from './constants';

function ManageLeaders({
  actionRequestInit,
  focalsUsers,
  intl: { formatMessage },
  requesting,
  requestingRolesAndPermissions,
  rolesAndPermissions,
  totalElements,
}) {
  const [filters, setFilters] = useState({
    leadershipRelationType: 'ALL',
    page: 0,
    searchString: '',
    size: PAGE_SIZE,
  });
  const [request, setRequest] = useState(0);

  const isAccountFocal = rolesAndPermissions?.permissions?.includes('OS:SECONDARYLEADERS:ALL');
  const isCareerFocal = rolesAndPermissions?.permissions?.includes('OS:MAINLEADERS:ALL');
  const hasAccess = isCareerFocal || isAccountFocal;
  const hasSegments = rolesAndPermissions?.permissions?.some(
    permission => permission?.startsWith(ADMIN_SEGMENT) && permission?.includes(SEGMENT)
  );
  const segmentWithoutUsers = !focalsUsers?.length && (request === 0);

  const emptyStateMessages = {
    segmentTitle: (
      <FormattedHTMLMessage
        id={messages.FocalsAdminSegmentEmptyStateTitle.id}
        defaultMessage={messages.FocalsAdminSegmentEmptyStateTitle.defaultMessage}
      />
    ),
    segmentDescription: (
      <FormattedHTMLMessage
        id={messages.FocalsAdminSegmentEmptyStateDescription.id}
        defaultMessage={messages.FocalsAdminSegmentEmptyStateDescription.defaultMessage}
      />
    ),
    accessTitle: (
      <FormattedHTMLMessage
        id={messages.FocalsAdminEmptyStateTitle.id}
        defaultMessage={messages.FocalsAdminEmptyStateTitle.defaultMessage}
      />
    ),
    accessDescription: (
      <FormattedHTMLMessage
        id={messages.FocalsAdminEmptyStateDescription.id}
        defaultMessage={messages.FocalsAdminEmptyStateDescription.defaultMessage}
      />
    ),
  };

  function onPageChange(page) {
    setFilters({ ...filters, page });
    setRequest(request + 1);
  };

  function onChangeSearch(value) {
    setFilters({
      ...filters,
      page: 0,
      searchString: value,
    });
    setRequest(request + 1);
  };

  function handleFilterByLeaderType(value) {
    setFilters({
      ...filters,
      page: 0,
      leadershipRelationType: value,
    });
    setRequest(request + 1);
  };

  function getFocalUsers(params) {
    actionRequestInit({
      id: REQUEST_ID_GET_FOCAL_USERS_TABLE,
      api: apiGetFocalUsers,
      params,
    });
  };

  function getUserRolesAndPermissions() {
    actionRequestInit({
      id: REQUEST_ID_GET_USER_ROLES_AND_PERMISSIONS,
      api: apiGetUserRolesAndPermissions,
      cache: true,
    });
  };

  function buildTableEmptyState() {
    if (!requesting) {
      return (
        // Pending to define image
        <GenericEmptyState
          className="bg-white pb-[100px]"
          description={segmentWithoutUsers
            ? formatMessage(messages.FocalsAdminSegmentWithoutUsersDescription)
            : formatMessage(messages.FocalsAdminUserNotFoundDescription)
          }
          image="list"
          limitTextWidth={false}
          size="large"
          title={segmentWithoutUsers
            ? formatMessage(messages.FocalsAdminSegmentWithoutUsersTitle)
            : formatMessage(messages.FocalsAdminUserNotFoundTitle)
          }
        />
      );
    };
  };

  const fields = [
    {
      customView: (user) => (
        <AvatarMedia
          className="manage-leaders__table-user"
          useLink={false}
          {...user}
        />
      ),
      headerClassName: 'manage-leaders__table',
      viewName: formatMessage(messages.UserField),
    },
    {
      customView: ({ leaders }) => {
        let careerLeader = leaders?.filter(leader => leader?.mainLeader)?.[0];
        return (
          !isEmpty(careerLeader)
            ? (
              <AvatarMedia
                className="manage-leaders__table-user"
                profileImageCode={careerLeader?.image || 1}
                useLink={false}
                {...careerLeader}
              />
            )
            : formatMessage(messages.WithoutLeader)
        )
      },
      headerClassName: 'manage-leaders__table',
      viewName: formatMessage(commonMessages.CareerLeader),
    },
    {
      customView: ({ leaders }) => {
        let accountLeaders = leaders?.filter(leader => !leader?.mainLeader);
        return (
          !isEmpty(accountLeaders)
            ? (
              <UserAvatarList
                enablePopup
                maxDisplay={4}
                onAvatarClick={() => {}}
                size={40}
                users={accountLeaders?.map(accountLeader => ({
                  ...accountLeader,
                  profileImageCode: accountLeader?.image || 1,
                }))}
              />
            ) : formatMessage(messages.WithoutLeader)
        )
      },
      headerClassName: 'manage-leaders__table',
      viewName: formatMessage(commonMessages.ExperienceLeaders),
    },
    {
      customView: ({ id }) => (
        <div className="manage-leaders__table-action">
          <Link to={getManageLeadersAdminLocation(id)}>
            <Button size="medium">
              {formatMessage(messages.ActionManage)}
            </Button>
          </Link>
        </div>
      ),
      headerClassName: 'manage-leaders__table',
      viewName: formatMessage(messages.ActionField),
    }
  ];

  const SELECT_OPTIONS = [
    {
      label: formatMessage(messages.FocalsAdminFilterAll),
      value: ALL,
    },
    {
      label: formatMessage(messages.FocalsAdminFilterWithoutAnyLeader),
      value: WITHOUT_LEADERS,
    },
    {
      label: formatMessage(messages.FocalsAdminFilterWithoutCareerMentor),
      value: WITHOUT_MAIN_LEADER,
    },
    {
      label: formatMessage(messages.FocalsAdminFilterWithoutExperienceLeader),
      value: WITHOUT_SECONDARY_LEADER,
    },
  ];

  useEffect(() => {
    getUserRolesAndPermissions();
  }, []);

  useEffect(() => {
    if (hasAccess && hasSegments) {
      getFocalUsers(filters);
    }
  }, [filters, hasAccess, hasSegments]);

  if (requestingRolesAndPermissions) {
    return (
      <div className="manage-leaders__loading">
        <LottieSpinner loadingType="betterme" />
      </div>
    );
  }

  if (!hasAccess) {
    return (
      <GenericEmptyState
        className="rounded-[3px] mx-10 mt-10 bg-white h-[calc(100vh-140px)]"
        description={emptyStateMessages.accessDescription}
        image="padlock"
        limitTextWidth={false}
        size="large"
        title={emptyStateMessages.accessTitle}
      />
    );
  };

  if (!hasSegments) {
    return (
      <GenericEmptyState
        className="rounded-[3px] mx-10 mt-10 bg-white h-[calc(100vh-140px)]"
        description={emptyStateMessages.segmentDescription}
        image="padlock"
        limitTextWidth={false}
        size="large"
        title={emptyStateMessages.segmentTitle}
      />
    );
  };

  return (
    <div className="manage-leaders">
      <div className="manage-leaders__container">
        <div className="manage-leaders__header">
          <Title
            className="manage-leaders__header-title"
            black
            level={4}
            type="primary"
          >
            {formatMessage(messages.Filter)}
          </Title>
          <DebounceInput
            className="manage-leaders__search"
            disabled={segmentWithoutUsers}
            icon="search"
            iconPosition="right"
            onChange={onChangeSearch}
            placeholder={formatMessage(messages.SearchPlaceHolder)}
            type="text"
          />
        </div>
        <div className="manage-leaders__filters">
          <Title
            className="manage-leaders__filters-title"
            black
            level={4}
            type="primary"
          >
            {formatMessage(messages.FocalsAdminFilterMembers)}
          </Title>
          <SelectV2
            className="manage-leaders__filters-select"
            isClearable={false}
            isSearchable={false}
            options={SELECT_OPTIONS}
            onChange={handleFilterByLeaderType}
            size="small"
            value={filters?.leadershipRelationType}
          />
        </div>
        <div className="manage-leaders__content">
          <Segment loading={requesting} loadingType="betterme">
            <DataTable
              data={focalsUsers}
              enableCheckbox={false}
              fields={fields}
              onPageChange={onPageChange}
              pageNumber={filters?.page + 1}
              pageSize={PAGE_SIZE}
              placeholder={buildTableEmptyState()}
              totalItems={totalElements}
            />
          </Segment>
        </div>
      </div>
    </div>
  )
}

ManageLeaders.propTypes = {
  focalsUsers: PropTypes.array.isRequired,
  intl: PropTypes.object,
  requesting: PropTypes.bool,
};

const mapDispatchToProps = {
  actionRequestInit,
};

const mapSateToProps = (state) => ({
  focalsUsers: selectResultProp(state, REQUEST_ID_GET_FOCAL_USERS_TABLE, 'result', []),
  requesting: selectRequesting(state, REQUEST_ID_GET_FOCAL_USERS_TABLE),
  requestingRolesAndPermissions: selectRequesting(state, REQUEST_ID_GET_USER_ROLES_AND_PERMISSIONS),
  rolesAndPermissions: selectResult(state, REQUEST_ID_GET_USER_ROLES_AND_PERMISSIONS),
  totalElements: selectResultProp(state, REQUEST_ID_GET_FOCAL_USERS_TABLE, 'page.totalElements', 0),
});

export default connect(mapSateToProps, mapDispatchToProps)(injectIntl(ManageLeaders));
