// @packages
import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import Icon from 'smu-ui-components/Icon';

// @own
import './styles.scss';

const Breadcrumbs = ({
  active,
  className,
  onClick,
  size,
  values,
}) => {
  const itemList = values.map((value, index) => {
    const breadcrumbsItemClass = cn('breadcrumbs__item', {
      'breadcrumbs__item--active': active === index
    });

    return (
      <li
        onClick={index < active ? onClick : undefined}
        className={breadcrumbsItemClass}
        key={index}
      >
        {value}
      </li>
    );

  });

  return (
    <div className={cn('breadcrumbs', className, {
      'breadcrumbs--big': size === 'big',
    })}>
      <Icon icon="angle-left" onClick={onClick} />
      <ul className="breadcrumbs__list">
        {itemList}
      </ul>
    </div>
  );
};

Breadcrumbs.defaultProps = {
  size: 'medium',
};

Breadcrumbs.propTypes = {
  active: PropTypes.number,
  className: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['medium', 'big']),
  values: PropTypes.array.isRequired,
};

export default Breadcrumbs;
