// @packages
import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import { injectIntl } from 'react-intl';

// @app
import { commonMessages, evaluationsMessages, sectionsMessages } from 'common/messages';

// @own
import EvaluationListRow from '../EvaluationListRow';
import './styles.scss';

const EvaluationListHeader = ({
  className,
  intl: { formatMessage },
  isByScore,
  showGlobalScore,
  showGoalsFinalScore,
}) => {
  return (
    <EvaluationListRow
      className={cn('evaluation-list-header', className)}
      customScore={showGlobalScore
        ? formatMessage(evaluationsMessages.EvaluationsGlobalScore)
        : undefined
      }
      evaluator={formatMessage(commonMessages.Evaluator)}
      evaluee={formatMessage(commonMessages.Collaborator)}
      goalScore={showGoalsFinalScore
        ? formatMessage(evaluationsMessages.EvaluationsGoalsFinalScore)
        : undefined
      }
      isByScore={isByScore}
      isHeading
      score={formatMessage(commonMessages.Score)}
      submissionDate={formatMessage(evaluationsMessages.EvaluationsSubmittedDate)}
      title={formatMessage(sectionsMessages.SectionsTitle)}
      type={formatMessage(evaluationsMessages.EvaluationsType)}
    />
  );
};

EvaluationListHeader.propTypes = {
  className: PropTypes.string,
  intl: PropTypes.object.isRequired,
  isByScore: PropTypes.bool,
  showGlobalScore: PropTypes.bool,
  showGoalsFinalScore: PropTypes.bool,
};

export default injectIntl(EvaluationListHeader);
