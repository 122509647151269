// @packages
import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

// @app
import getGoalProgress from 'betterme-components/utils/getGoalProgress';

// @own
import './styles.scss';

const TinyProgressBar = ({
  className,
  progress,
  scales,
}) => {
  const progressPercentage = getGoalProgress(progress, scales);
  return (
    <div className={cn('tiny-progress-bar', className)}>
      <div className="tiny-progress-bar__progress">{`${progress || 0}`}</div>
      <div className="tiny-progress-bar__bar">
        <div className="tiny-progress-bar__progress-bar" style={{ width: `${progressPercentage || 0}%` }}/>
      </div>
    </div>
  );
};

TinyProgressBar.propTypes = {
  className: PropTypes.string,
  progress: PropTypes.number,
  scales: PropTypes.array.isRequired,
};


export default TinyProgressBar;
