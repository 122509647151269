// @packages
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import GenericEmptyState from 'smu-ui-components/GenericEmptyState';
import { base64Decode } from 'smu-utils/base64';
import { connect } from 'react-redux';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import { openModal } from 'smu-app-components/RootModal/actions';
import { push } from 'react-router-redux';

// @app
import AssessmentForm from 'components/templates/AssessmentFormTemplate';
import FullScreenSection from 'components/templates/FullScreenSection';
import SendEvaluationConfirmationModal from 'components/containers/SendEvaluationConfirmationModal';
import { SEND_EVALUATION_CONFIRMATION_MODAL } from 'betterme-components/constants/modalTypes';
import { assessmentHomePageLocation } from 'betterme-components/routes/urls';
import { getUserInfo, resetUserInfo } from 'betterme-components/services/UserInformation/actions';
import { handleSliceQuestions } from 'utils/assessmentHelper';
import { resetUserCarousel } from 'betterme-components/services/Carousel/actions';
import { potentialAssessmentMessages } from 'common/messages';
import { selectuserInfo, selectuserInfoLoading } from 'betterme-components/services/UserInformation/selectors';
import {
  getAssessmentQuestions,
  getAssessmentRatings,
  getAssessmentScore,
  getAssessmentTier,
  resetAssessment,
  sendAssessmentResults,
  sendAssessmentSummary,
} from 'betterme-components/services/Assessment/actions';
import {
  selectAssessmentQuestions,
  selectAssessmentQuestionsLoading,
  selectAssessmentRatings,
  selectAssessmentRatingsLoading,
  selectAssessmentScore,
  selectAssessmentScoreLoading,
  selectAssessmentTier,
  selectAssessmentTierLoading,
  selectSendAssessmentResultsLoading,
  selectSendAssessmentSummaryLoading,
} from 'betterme-components/services/Assessment/selectors';

// @own
import './styles.scss';
import messages from './messages';
import {
  COMMENT_QUESTION_MAX_LENGTH,
  NO_TIER,
  OPEN,
  QUESTION_SLICED_SIZE,
  RATE,
  SEGMENTS,
  TABLE_HEADER_COL_SPAN,
} from './constants';

const Assessment = ({
  assessmentQuestions,
  assessmentQuestionsLoading,
  assessmentRatings,
  assessmentRatingsLoading,
  assessmentScore,
  assessmentScoreLoading,
  assessmentTier,
  assessmentTierLoading,
  getAssessmentQuestions,
  getAssessmentRatings,
  getAssessmentScore,
  getAssessmentTier,
  getUserInfo,
  goToAssessmentHomePage,
  intl: { formatMessage },
  openModal,
  params: { userId },
  resetAssessment,
  resetUserCarousel,
  resetUserInfo,
  sendAssessmentResults,
  sendAssessmentResultsLoading,
  sendAssessmentSummary,
  sendAssessmentSummaryLoading,
  userInfo,
  userInfoLoading,
}) => {
  const [assessmentBehaviourQuestions, setAssessmentBehaviourQuestions] = useState([]);
  const [summaryQuestions, setSummaryQuestions] = useState([]);
  const [tab, setTab] = useState(0);
  const [isTabCompleted, setIsTabCompleted] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);
  const [isSummaryAnswered, setIsSummaryAnswered] = useState(false);
  const [isSummarySended, setIsSummarySended] = useState(false);
  const [isAssessmentCompleted, setIsAssessmentCompleted] = useState(false);
  const [resultAnswers, setResultAnswers] = useState({});
  const [modifyScore, setModifyScore] = useState(false);


  useEffect(() => {
    getUserInfo(base64Decode(userId));
  }, [getUserInfo, userId]);

  useEffect(() => {
    if (!isEmpty(assessmentBehaviourQuestions)) {
      const slicedQuestions = handleSliceQuestions(assessmentBehaviourQuestions, QUESTION_SLICED_SIZE);
      const isTabCompleted = slicedQuestions[tab].every(
        ({ answer }) => answer
      );

      setIsTabCompleted(isTabCompleted);
    }
  }, [assessmentBehaviourQuestions, tab]);

  useEffect(() => {
    if (!isEmpty(assessmentQuestions)) {
      setAssessmentBehaviourQuestions(
        assessmentQuestions?.filter(
          (value) => (value?.questionType === RATE)
        ));
      setSummaryQuestions(
        assessmentQuestions?.filter(
          (value) => (value?.questionType === OPEN)
        )
      );
    }
  }, [assessmentQuestions]);

  useEffect(() => {
    resetUserCarousel();
    if (!userInfoLoading && userInfo?.id) {
      getAssessmentTier(userInfo?.id);
      getAssessmentScore({ collaboratorId: userInfo?.id });
    }
  }, [userInfo]);

  useEffect(() => {
    if (!assessmentScoreLoading && assessmentScore?.suggestedScore > 0) {
      setIsSummarySended(true);
      setIsSummaryAnswered(true);
      setResultAnswers(assessmentScore);
    }
  }, [assessmentScoreLoading]);

  useEffect(() => {
    if (isSummarySended
      && !sendAssessmentSummaryLoading
      && assessmentBehaviourQuestions?.length > 0) {
      getAssessmentScore({ collaboratorId: userInfo?.id });
    }
  }, [isSummarySended, sendAssessmentSummaryLoading]);

  useEffect(() => {
    if (resultAnswers?.scoreRanges && assessmentTier?.type) {
      const segmentProps = SEGMENTS.find(element => element.name === assessmentTier?.type);
      const rangesWithDescription = resultAnswers?.scoreRanges?.map(range => {
        const description = formatMessage(
          potentialAssessmentMessages[`PotentialAssessment${segmentProps?.segment}FinalScore${range?.scoreTier}`]
        );
        return { ...range, description };
      });
      const suggestedScoreRange = rangesWithDescription.find(range =>
        range.min <= resultAnswers?.suggestedScore && resultAnswers?.suggestedScore <= range.max);

      const validScoreRanges = rangesWithDescription.map(range => {
        return { ...range, disabledOption: range.name === suggestedScoreRange?.name };
      });

      setResultAnswers(prevData => ({
        ...prevData,
        scoreRanges: rangesWithDescription,
        validScoreRanges,
      }));
    }
  }, [resultAnswers?.id, assessmentTier?.type, assessmentScoreLoading]);

  useEffect(() => {
    return () => {
      resetAssessment();
      resetUserInfo();
    };
  }, []);

  useEffect(() => {
    if (!assessmentTierLoading && (assessmentTier?.type)) {
      getAssessmentQuestions(assessmentTier)
      getAssessmentRatings(assessmentTier)
    }
  }, [assessmentTier]);

  useEffect(() => {
    if (isSummarySended && assessmentScore?.comment !== null) {
      setIsAssessmentCompleted(true)
    }
  }, [assessmentScore?.comment, isSummarySended]);

  useEffect(() => {
    if (isSummarySended && !isAssessmentCompleted) {
      if (resultAnswers?.comment?.length > 0 && resultAnswers?.selectedScore) {
        setIsTabCompleted(true);
      } else {
        setIsTabCompleted(false);
      }
    }
  }, [resultAnswers?.comment, resultAnswers?.selectedScore, isSummarySended, isAssessmentCompleted]);

  const sendSummary = () => {
    const hasOpenQuestions = !isEmpty(summaryQuestions);
    const ratedQuestions = hasOpenQuestions
      ? [...assessmentBehaviourQuestions, ...summaryQuestions]
      : [...assessmentBehaviourQuestions];
    const assessmentAnswers =
      ratedQuestions.map(
        ({ answer, id, questionType }) => {
          const isAnswerKey = (questionType === OPEN) ? 'answer' : 'ratingId';
          return (
            { [isAnswerKey]: answer, questionId: id }
          );
        }
      );

    sendAssessmentSummary({
      answers: assessmentAnswers,
      collaborator: userInfo,
    });
  }

  const sendResults = () => {
    sendAssessmentResults({
      data: {
        comment: resultAnswers?.comment,
        selectedScore: resultAnswers?.selectedScore,
      },
      id: resultAnswers?.id,
      successMessage: formatMessage(messages.PotentialAssessmentSuccessMessage),
    });
  }

  const handleBack = () => {
    if (isSummarySended) {
      setIsSummarySended(false);
      setInfoOpen(true)
      return;
    }
    if (tab !== 0) {
      setTab(tab - 1);
      return;
    }
    handlegGoBack();
  }

  const handleContinue = () => {
    const isLastTab = isSummarySended
      || !(tab < ((assessmentBehaviourQuestions.length / 2) - 1));

    if (!isLastTab) {
      setTab(tab + 1)
      return;
    }

    const areQuestionsAnswered = assessmentBehaviourQuestions
      .every(({ answer }) => answer);

    if (areQuestionsAnswered && !isSummaryAnswered) {
      setIsSummaryAnswered(true);
      setInfoOpen(true);
      return;
    }

    if (isSummaryAnswered && !isSummarySended) {
      setIsSummarySended(true);
      sendSummary();
      setIsTabCompleted(false);
      setInfoOpen(false);
      return;
    }

    if (isTabCompleted) {
      handleConfirmationModal();
      return;
    }
  }

  const handleAssessmentQuestions = ({ answer, id }) => {
    const newAssessmentBehaviourQuestions = assessmentBehaviourQuestions?.map((question) =>
      (question?.id === id)
        ? { ...question, answer }
        : question
    );

    setAssessmentBehaviourQuestions(newAssessmentBehaviourQuestions);
  };

  const handleSummaryQuestion = ({ answer, id }) => {
    const newAssessmentsummaryQuestionss = summaryQuestions?.map((question) =>
      (question?.id === id)
        ? { ...question, answer }
        : question
    );

    setSummaryQuestions(newAssessmentsummaryQuestionss);
  };

  const handleResultAnswers = (name, value) => {
    if (name === 'comment') {
      setResultAnswers(prevData => ({
        ...prevData,
        comment: value
      }));
    }

    if (name === 'selectedScore') {
      const selectedScoreRange = resultAnswers?.scoreRanges?.find(range =>
        range.min <= value && value <= range.max);

      setResultAnswers(prevData => ({
        ...prevData,
        selectedScoreName: selectedScoreRange?.name,
        selectedScore: selectedScoreRange?.id,
      }));
    }
  };

  function handleConfirmationModal() {
    openModal({
      modalType: SEND_EVALUATION_CONFIRMATION_MODAL,
      modalProps: {
        isPotentialAssessment: true,
        type: resultAnswers?.selectedScoreName,
        sendEvaluation: sendResults,
      },
    });
  };

  function handlegGoBack() {
    resetUserInfo();
    goToAssessmentHomePage();
  }

  function handleChangeRadio(event) {
    const { value } = event.target;
    if (value === "true") {
      setResultAnswers(prevData => ({
        ...prevData,
        selectedScore: undefined,
        selectedScoreName: undefined,
      }));
    } else {
      setResultAnswers(prevData => ({
        ...prevData,
        selectedScore: resultAnswers?.suggestedScore,
        selectedScoreName: resultAnswers?.suggestedScoreName,
      }));
    }
    setModifyScore(value === "true");
  };

  const isLoading = (userInfoLoading
    || assessmentTierLoading
    || assessmentQuestionsLoading
    || assessmentRatingsLoading
    || sendAssessmentSummaryLoading
    || sendAssessmentResultsLoading
    || assessmentScoreLoading
  );
  const noTier = assessmentTier?.type === NO_TIER || assessmentTier?.type === null;
  const noPermission = assessmentTier?.type === undefined
    || !userInfo?.access?.potentialAssessment
    || !userInfo?.access?.potentialAssessmentResult;
  const hasReachedEndOfQuestions = !(tab < ((assessmentBehaviourQuestions.length / 2) - 1));
  const summaryTexts = {
    answer: formatMessage(messages.PotentialAssessmentAnswer),
    behaviour: formatMessage(messages.PotentialAssessmentBehaviours),
    description: formatMessage(messages.PotentialAssessmentReview),
    optionalText: formatMessage(messages.PotentialAssessmentOptional),
    placeholder: formatMessage(messages.PotentialAssessmentAdditionalThoughts),
    sectionTitle: formatMessage(messages.PotentialAssessmentSummary),
    textAreaTitle: formatMessage(messages.PotentialAssessmentExtraDetails),
  };
  const leftColumnTexts = {
    behaviours: formatMessage(messages.PotentialAssessmentBehaviours),
    behavioursDescription: formatMessage(messages.PotentialAssessmentBehavioursDescription),
    results: formatMessage(messages.PotentialAssessmentResults),
    resultsDescription: formatMessage(messages.PotentialAssessmentResultsDescription),
    summary: formatMessage(messages.PotentialAssessmentSummary),
    summaryDescription: formatMessage(messages.PotentialAssessmentSummaryDescription),
  };
  const evaluationDate = moment(assessmentScore?.creationDateTime).format('MMMM, YYYY.');
  const headerData = {
    disclaimer: formatMessage(messages.PotentialAssessmentDisclaimerForLeaders),
    evaluationDate: evaluationDate.charAt(0).toUpperCase() + evaluationDate.slice(1),
    title: formatMessage(messages.PotentialAssessmentTitle),
  }
  const actionButtons = [
    {
      color: 'assessment-outline',
      label: formatMessage(messages.PotentialAssessmentGoBackButton),
      onClick: handleBack,
      show: !isSummaryAnswered || isAssessmentCompleted,
    },
    {
      color: 'assessment-blue',
      disabled: (!isTabCompleted || isLoading),
      label: isSummaryAnswered
        ? formatMessage(messages.PotentialAssessmentSendAssessment)
        : (
          hasReachedEndOfQuestions
            ? formatMessage(messages.PotentialAssessmentFinishButton)
            : formatMessage(messages.PotentialAssessmentNextButton)
        ),
      onClick: handleContinue,
      show: true
    },
  ];
  const availableButtons = actionButtons.filter((button) => button.show);
  const emptyStateDescription = (
    <FormattedHTMLMessage
      defaultMessage={messages.PotentialAssessmentEmptyDescription.defaultMessage}
      id={messages.PotentialAssessmentEmptyDescription.id}
      values={{
        goTo: '/behaviour-assessment',
        onclick: resetUserInfo,
      }}
    />
  );
  const permissionErrorTitle = (
    <FormattedHTMLMessage
      defaultMessage={messages.PotentialAssessmentPermissionErrorTitle.defaultMessage}
      id={messages.PotentialAssessmentPermissionErrorTitle.id}
    />
  );
  const permissionErrorDescription = (
    <FormattedHTMLMessage
      defaultMessage={messages.PotentialAssessmentPermissionErrorDescription.defaultMessage}
      id={messages.PotentialAssessmentPermissionErrorDescription.id}
      values={{
        goTo: '/behaviour-assessment',
        onclick: resetUserInfo,
      }}
    />
  );
  const potentialCompletedTitle = (
    <FormattedHTMLMessage
      defaultMessage={messages.PotentialAssessmentCompletedStateTitle.defaultMessage}
      id={messages.PotentialAssessmentCompletedStateTitle.id}
    />
  );
  const potentialCompletedDescription = (
    <FormattedHTMLMessage
      defaultMessage={messages.PotentialAssessmentCompletedStateDescription.defaultMessage}
      id={messages.PotentialAssessmentCompletedStateDescription.id}
    />
  );


  return (
    <FullScreenSection
      actionButtons={availableButtons}
      className="assessment"
      headerValues={[
        formatMessage(messages.PotentialAssessmentBreadcrumb),
        formatMessage(messages.PotentialAssessmentSection),
      ]}
      loading={isLoading}
      navigateBack={() => handlegGoBack()}
      showButtons={!noTier}
    >
      {(!noTier || !noPermission) && (
        !!assessmentBehaviourQuestions?.length && !isAssessmentCompleted && (
          <AssessmentForm
            assessmentBehaviourQuestions={assessmentBehaviourQuestions}
            className="assessment__form"
            commentQuestionMaxLength={COMMENT_QUESTION_MAX_LENGTH}
            currentTab={tab}
            formatMessage={formatMessage}
            handleAssessmentQuestions={handleAssessmentQuestions}
            handleChangeRadio={handleChangeRadio}
            handleResultAnswers={handleResultAnswers}
            handleSummaryQuestion={handleSummaryQuestion}
            headerData={headerData}
            infoOpen={infoOpen}
            isSummaryAnswered={isSummaryAnswered}
            isSummarySended={isSummarySended}
            leftColumnTexts={leftColumnTexts}
            messages={messages}
            modifyScore={modifyScore}
            options={!isEmpty(assessmentRatings) ? assessmentRatings : []}
            questionSlicedSize={2}
            questionsSlicedSize={QUESTION_SLICED_SIZE}
            resultAnswers={resultAnswers}
            setInfoOpen={setInfoOpen}
            summaryQuestions={summaryQuestions}
            summaryTexts={summaryTexts}
            tableHeaderColSpan={TABLE_HEADER_COL_SPAN}
            userInfo={userInfo}
          />
        )
      )
      }
      {(noTier) && !(assessmentBehaviourQuestions?.length > 0) && (
        <GenericEmptyState
          className="px-5 bg-white h-full"
          description={emptyStateDescription}
          image="listCheck"
          limitTextWidth={false}
          size="large"
          title={formatMessage(messages.PotentialAssessmentEmptyTitle)}
        />
      )}
      {(noPermission) && !(assessmentBehaviourQuestions?.length > 0) && (!isLoading) && (
        <GenericEmptyState
          className="px-5 bg-white h-full"
          description={permissionErrorDescription}
          image="padlock"
          limitTextWidth={false}
          size="large"
          title={permissionErrorTitle}
        />
      )}
      {(isAssessmentCompleted && !isLoading) && (
        <GenericEmptyState
          className="px-5 bg-white h-full"
          description={potentialCompletedDescription}
          image="listCheck"
          limitTextWidth={false}
          size="large"
          title={potentialCompletedTitle}
        />
      )}
      <SendEvaluationConfirmationModal />
    </FullScreenSection>
  );
};

const goToAssessmentHomePage = (useridentification) => push(assessmentHomePageLocation(useridentification));

Assessment.propTypes = {
  assessmentQuestions: PropTypes.arrayOf(PropTypes.shape({
    category: PropTypes.string,
    name: PropTypes.string,
    questionId: PropTypes.any,
  })),
  assessmentRatings: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })),
  getAssessmentQuestions: PropTypes.func,
  goToAssessmentHomePage: PropTypes.func,
  intl: PropTypes.object,
  resetAssessment: PropTypes.func,
  sendAssessmentResults: PropTypes.func,
  sendAssessmentSummary: PropTypes.func,
  userCarousel: PropTypes.string,
};

const mapStateToProps = (state) => ({
  assessmentQuestions: selectAssessmentQuestions(state),
  assessmentQuestionsLoading: selectAssessmentQuestionsLoading(state),
  assessmentRatings: selectAssessmentRatings(state),
  assessmentRatingsLoading: selectAssessmentRatingsLoading(state),
  assessmentScore: selectAssessmentScore(state),
  assessmentScoreLoading: selectAssessmentScoreLoading(state),
  assessmentTier: selectAssessmentTier(state),
  assessmentTierLoading: selectAssessmentTierLoading(state),
  sendAssessmentResultsLoading: selectSendAssessmentResultsLoading(state),
  sendAssessmentSummaryLoading: selectSendAssessmentSummaryLoading(state),
  userInfo: selectuserInfo(state),
  userInfoLoading: selectuserInfoLoading(state),
});

const mapDispatchToProps = {
  getAssessmentQuestions,
  getAssessmentRatings,
  getAssessmentScore,
  getAssessmentTier,
  getUserInfo,
  goToAssessmentHomePage,
  openModal,
  resetAssessment,
  resetUserCarousel,
  resetUserInfo,
  sendAssessmentResults,
  sendAssessmentSummary,
};

export default connect(mapStateToProps, mapDispatchToProps)
  (injectIntl(Assessment));
