import { defineMessages } from 'react-intl';

export default defineMessages({
  YouveBeenRemoved: {
    id: 'Betterme.RemoveLeaderWellDoneModal.YouveBeenRemoved',
    defaultMessage: 'You\'ve been removed',
  },
  GoToMyProfile: {
    id: 'Betterme.RemoveLeaderWellDoneModal.GoToMyProfile',
    defaultMessage: 'Go to my profile',
  },
  WeNotifyThemToo: {
    id: 'Betterme.RemoveLeaderWellDoneModal.WeNotifyThemToo',
    defaultMessage: '{username} is not your team member anymore. We\'ll notify them too. You will see this changes in some minutes',
  },
});
