// @packages
import React, { useEffect } from 'react';
import Skills from 'smu-app-components/Skills';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import filter from 'lodash/filter';
import some from 'lodash/some';
import isEmpty from 'lodash/isEmpty';
import cn from 'classnames';

// @app
import CoEvaluationSwitch from 'components/molecules/CoEvaluationSwitch';
import CommentBox from 'components/molecules/CommentBox';
import EmptyStateBox from 'components/atoms/EmptyStateBox';
import RatedTopics from 'components/organisms/RatedTopics';
import Title from 'components/atoms/Title';
import { evaluationAddSkill, evaluationDeletedSkill } from 'betterme-components/Evaluations/actions';
import { selectEvaluationsSkills } from 'betterme-components/Evaluations/selectors';

// @own
import messages from './messages';
import {
  getRatedTopicsByCompetency,
  normalizeChangeSkill,
  normalizeChangeSkills,
  normalizeValueSkills,
} from './helpers';

const SkillsComponent = ({
  data,
  evaluationAddSkill,
  evaluationDeletedSkill,
  hasCoEvaluator,
  inputSkills,
  intl: { formatMessage },
  onSwitchChange,
  selfRetrospective,
  setData,
  showCoevaluatorSkills,
  skills,
  technicalInput,
  user,
}) => {
  const userId = user?.id;
  const userName = `${user?.firstName} ${user?.lastName}`;
  const ratedTopicsByCompetency = getRatedTopicsByCompetency(data?.ratedTopics);
  const hasSkills = () => isEmpty(skills);
  const hasInputSkills = () => isEmpty(inputSkills);
  const subtitle = selfRetrospective
    ? formatMessage(messages.AddSkillsSectionSelfSkills)
    : technicalInput
      ? formatMessage(messages.AddSkillsSectionTechnicalSkills)
      : formatMessage(messages.AddSkillsSectionHardSkills);
  const emptyStateNode = (
    <EmptyStateBox
      text={formatMessage(messages.AddSkillsSectionEmptySkills)}
    />
  );


  function onAddSkill(skill) {
    const newSkill = normalizeChangeSkill(skill);
    evaluationAddSkill(newSkill);
  }

  function onAddSuggestions(suggestions) {
    const hasSkills = data?.ratedTopics?.some(topic => topic?.competency?.skill);

    if (!hasSkills) {
      const newSkills = normalizeChangeSkills(suggestions);
      newSkills.forEach(skill => evaluationAddSkill(skill));
    }
  }

  function onChangeSkill(skill) {
    const rateId = skill?.rating;
    const skillId = skill?.id;

    let newData = {
      ...data,
      ratedTopics: [
        ...filter(data?.ratedTopics,
          ({ competency: { id } }) => id !== skillId),
        { competency: skill, rateId },
      ]
    }
    return setData({ ...newData });
  }

  function onRemoveSkill(id) {
    evaluationDeletedSkill(id);
    if (some(data?.ratedTopics, ({ competency: ratedTopic }) => (ratedTopic.id === id))) {
      const newData = {
        ...data,
        ratedTopics: filter(data?.ratedTopics, ({ competency }) => competency?.id !== id),
      }
      return setData({ ...newData });
    }
  }

  useEffect(() => {
    if (data?.draftId) {
      const draftSkills = data?.ratedTopics
        ?.filter(topic => topic?.competency?.skill)
        ?.map(topic => topic?.competency);
      evaluationAddSkill(draftSkills);
    };
  }, [data.draftId]);

  return (
    <CommentBox title={formatMessage(messages.AddSkillsTitle)} >
      <div className='px-5 py-6'>
        <p>{subtitle}</p>
        {hasCoEvaluator && (
          <Title
            className={'text-black mt-6'}
            size={14}
          >
            {formatMessage(messages.AddSkillsSectionYourResponse)}
          </Title>
        )}
        {hasSkills() && (emptyStateNode)}
        <Skills
          className='pt-6'
          onAddSkill={onAddSkill}
          onAddSuggestions={onAddSuggestions}
          onChangeSkill={onChangeSkill}
          onRemoveSkill={onRemoveSkill}
          userId={userId}
          userName={userName}
          value={normalizeValueSkills(skills, ratedTopicsByCompetency)}
        />
        {hasCoEvaluator && (
          <div className='pt-6'>
            <CoEvaluationSwitch
              onSwitchChange={(event) => onSwitchChange(event)}
              subtitle={formatMessage(messages.AddSkillsSectionTechnicalSuggestionSubtitle)}
              switchDisabled={false}
              switchEnabled={showCoevaluatorSkills}
              switchId={'SKILLS_SWITCH'}
              switchText={formatMessage(messages.AddSkillsSectionSwitch)}
              title={formatMessage(messages.AddSkillsSectionTechnicalSuggestion)}
            />
            {hasInputSkills() ? (emptyStateNode) : (
              <RatedTopics
                className={cn('opacity-100', {
                  'opacity-50': !showCoevaluatorSkills,
                })}
                noSkillStyle
                ratedTopicsList={inputSkills}
              />
            )}
          </div>
        )}
      </div>
    </CommentBox>
  );
}

const mapSateToProps = (state) => ({
  skills: selectEvaluationsSkills(state),
});

const mapDispatchToProps = {
  evaluationAddSkill,
  evaluationDeletedSkill,
};

export default connect(
  mapSateToProps,
  mapDispatchToProps
)(injectIntl(SkillsComponent));
