export const CLEAN_NOTIFICATIONS_HIS = 'CLEAN_NOTIFICATIONS_HIS';
export const MARK_ALL_NOTIFICATION_HISTORY_AS_READ = 'MARK_ALL_NOTIFICATION_HISTORY_AS_READ';
export const MARK_ALL_NOTIFICATION_HISTORY_AS_READ_FAIL = 'MARK_ALL_NOTIFICATION_HISTORY_AS_READ_FAIL';
export const MARK_ALL_NOTIFICATION_HISTORY_AS_READ_SUCCESS = 'MARK_ALL_NOTIFICATION_HISTORY_AS_READ_SUCCESS';
export const REQUEST_NOTIFICATIONS_HIS = 'REQUEST_NOTIFICATIONS_HIS';
export const REQUEST_NOTIFICATIONS_HIS_FAILED = 'REQUEST_NOTIFICATIONS_HIS_FAILED';
export const REQUEST_NOTIFICATIONS_HIS_SUCCESS = 'REQUEST_NOTIFICATIONS_HIS_SUCCESS';
export const SET_READ_NOTIFICATION_HISTORY = 'SET_READ_NOTIFICATION_HISTORY';
export const SET_READ_NOTIFICATION_HISTORY_FAIL = 'SET_READ_NOTIFICATION_HISTORY_FAIL';
export const SET_READ_NOTIFICATION_HISTORY_SUCCESS = 'SET_READ_NOTIFICATION_HISTORY_SUCCESS';
