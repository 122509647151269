// @own
import {
  GET_TIER_TYPE,
  GET_TIER_TYPE_SUCCESS,
  GET_TIER_TYPE_FAIL,
  RESET_TIER_TYPE,
  TIER_TYPE_ERROR_MESSAGE,
} from './actionTypes';

const initialState = {
  tierType: null,
  tierTypeError: '',
  tierTypeLoading: false,
  tierTypeErrorMessage: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case RESET_TIER_TYPE:
      return {
        ...state,
        tierType: null,
      };

    case GET_TIER_TYPE:
      return {
        ...state,
        tierTypeLoading: true,
      };

    case GET_TIER_TYPE_FAIL:
      return {
        ...state,
        tierType: payload.error,
        tierTypeLoading: false,
      };

    case GET_TIER_TYPE_SUCCESS:
      return {
        ...state,
        tierType: payload,
        tierTypeLoading: false,
      };

    case TIER_TYPE_ERROR_MESSAGE:
      return {
        ...state,
        tierTypeErrorMessage: payload,
      }

    default:
      return state;
  }
};
