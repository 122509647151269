// @packages
import React from 'react';
import Button from 'smu-ui-components/ButtonV2';
import Panel from 'smu-ui-components/Panel';
import PropTypes from 'prop-types';
import Skeleton from 'smu-ui-components/Skeleton';

// @app
import Link from 'betterme-components/Link';

// @own
import './styles.scss';
import useCTA from 'components/containers/CallToActions/useCTA';

const WidgetCallToAction = ({
  actionText,
  isLoading,
  link,
  onClickAction,
  primaryText,
  secondaryText,
  type,
}) => {
  const LinkComponent = link ? Link : 'div';
  const { isClicked, onClick } = useCTA(type);
  const handleClickAction = (...p) => {
    if (!isClicked) onClick();
    if (onClickAction) onClickAction(...p);
  };

  return (
    <Panel className="widget-call-to-action">
      {isLoading ? (
        <div className="widget-call-to-action__loader">
          <Skeleton
            animation="wave"
            className="widget-call-to-action__loader-title"
            height={16}
            width="65%"
          />
          <Skeleton animation="wave" height={38} width="100%" />
        </div>
      ) : (
        <>
          <div className="widget-call-to-action__title">
            {primaryText && (
              <span className="widget-call-to-action__primary-text">
                {primaryText}
              </span>
            )}
            {secondaryText && (
              <b className="widget-call-to-action__secondary-text">
                {secondaryText}
              </b>
            )}
          </div>
          <LinkComponent className="widget-call-to-action__link" href={link}>
            <Button
              className="widget-call-to-action__action"
              color="btm-blue-button"
              enabledUnification
                onClick={handleClickAction}
            >
              {actionText}
                {!isClicked && (
                <span className="widget-call-to-action__warning">!</span>
              )}
            </Button>
          </LinkComponent>
        </>
      )}
    </Panel>
  );
};

WidgetCallToAction.propTypes = {
  actionText: PropTypes.string,
  isLoading: PropTypes.bool,
  link: PropTypes.string,
  onClickAction: PropTypes.func,
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
  showWarning: PropTypes.bool,
};

export default WidgetCallToAction;