// @packages
import React from 'react';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import PropTypes from 'prop-types';
import RootModal from 'smu-app-components/RootModal';

// @app
import GenericModal from 'components/templates/GenericModalTemplate';
import InfoCard from 'components/molecules/InfoCard';
import { centerModalClass } from 'betterme-components/ConfirmationModal';
import { EVALUATION_DRAFT_SUBMIT_MODAL } from 'betterme-components/constants/modalTypes';
import {
  SIZE_FIFTY,
  SIZE_THIRTY_SIX,
} from 'components/common/constants';
import { actionsMessages } from 'common/messages';

// @own
import messages from './messages';

const EvaluationDraftAlertSubmit = ({
  close,
  intl: { formatMessage },
  isPerformanceRetrospective,
  isSelfRetrospective,
  loading,
  onAccept,
  onCancel,
  showCocreatedMessage,
}) => {
  const actionButtons = [
    {
      label: formatMessage(actionsMessages.ActionsCancel),
      onClick: () => { onCancel(); close(); },
      variant: 'outline'
    },
    {
      label: formatMessage(messages.SubmitIt),
      onClick: handleSubmit,
    },
  ];

  const performanceRetrospectiveTitle = showCocreatedMessage
    ? messages.CoCreatedPRNotRepliedYetTitle
    : messages.SendPerformanceRetrospective;

  const performanceRetrospectiveText = showCocreatedMessage
    ? messages.CoCreatedPRNotRepliedYet
    : messages.SendPerformanceRetrospectiveSubmitDraft;

  const titleText = getTitle();
  const messageText = getMessage();

  const titleTextNode = (
    <FormattedHTMLMessage
      defaultMessage={titleText.defaultMessage}
      id={titleText.id}
    />
  );

  const messageTextNode = (
    <FormattedHTMLMessage
      defaultMessage={messageText.defaultMessage}
      id={messageText.id}
    />
  );

  const modalInfo = {
    title: titleTextNode,
    message: messageTextNode,
  };

  function handleSubmit() {
    onAccept('SUBMITTED');
    close();
  }

  function getTitle() {
    if (isPerformanceRetrospective) {
      return performanceRetrospectiveTitle;
    } else if (isSelfRetrospective) {
      return messages.SendSelfRetrospective;
    } else {
      return messages.SendEvaluation;
    }
  }

  function getMessage() {
    if (isPerformanceRetrospective) {
      return performanceRetrospectiveText;
    } else if (isSelfRetrospective) {
      return messages.SendSelfRetrospectiveSubmitDraft;
    } else {
      return messages.FeedbackEvaluationsEvaluationCreateAlertSubmitDraft;
    }
  }

  return (
    <GenericModal
      actionButtons={actionButtons}
      header={
        <InfoCard
          icon="info"
          iconSize={SIZE_FIFTY}
          message={modalInfo.message}
          title={modalInfo.title}
          titleSize={SIZE_THIRTY_SIX}
        />
      }
      loading={loading}
      onClose={close}
      success
    />
  );
};

EvaluationDraftAlertSubmit.propTypes = {
  onCancel: () => { },
};

EvaluationDraftAlertSubmit.propTypes = {
  intl: PropTypes.object,
  isPerformanceRetrospective: PropTypes.bool,
  isSelfRetrospective: PropTypes.bool,
  loading: PropTypes.bool,
};

const modalStyles = {
  width: '610px',
};

export default RootModal(
  injectIntl(EvaluationDraftAlertSubmit),
  EVALUATION_DRAFT_SUBMIT_MODAL,
  modalStyles,
  centerModalClass,
);
