import { defineMessages } from 'react-intl';

export default defineMessages({
  MyGrowthWidgetHeading: {
    id: 'Betterme.MyGrowthWidget.Heading',
    defaultMessage: 'My Growth',
  },
  MyGrowthWidgetTitle: {
    id: 'Betterme.MyGrowthWidget.Title',
    defaultMessage: 'My Growth Missions',
  },
  MyGrowthWidgetButtonText: {
    id: 'Betterme.MyGrowthWidget.ButtonText',
    defaultMessage: 'Check the progress',
  },
});
