// @packages
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import CardDateTime from 'smu-ui-components/Cards/components/DateTime';
import cn from 'classnames';
import Divider from 'smu-ui-components/Divider';
import FeedFooter from 'smu-ui-components/FeedFooter';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import orderBy from 'lodash/orderBy'
import TextRevealer from 'smu-ui-components/TextRevealer';
import UserAvatar from 'smu-ui-components/UserAvatar';

// @app
import AudioComment from 'common/AudioComment';
import DownloadFile from 'betterme-components/components/containers/DownloadFile';
import TagItem from 'common/TagItem';
import TextParserContainer from 'betterme-components/TextParser';
import Title from 'components/atoms/Title';
import { actionsMessages, feedbackMessages } from 'common/messages';

// @own
import './styles.scss';
import {
  trackFeedbackCommentIconClick,
  trackFeedbackCompetenciesHover,
  trackFeedbackUserAvatarNameClick,
} from '../analytics';
import Comments from '../Comments';
import FeedHeader from '../FeedHeader';
import messages from '../messages';

class Item extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: this.props.commentExpanded,
    };

    this.handleCommentsOpen = this.handleCommentsOpen.bind(this);
    this.handleMessagesCounterClick = this.handleMessagesCounterClick.bind(this);
  }

  componentWillMount() {
    this.handleCommentsOpen();
  }

  componentWillReceiveProps(nextProps) {
    const { commentExpanded: nextIsCommentExpanded, id: nextId } = nextProps;
    const { onMessagesCounterClick, id } = this.props;

    if (nextId !== id) {
      this.setState({ expanded: nextIsCommentExpanded }, () => {
        this.state.expanded && onMessagesCounterClick(nextId);
      });
    }
  }

  handleCommentsOpen() {
    const { id, onMessagesCounterClick } = this.props;
    const { expanded } = this.state;
    if (expanded) {
      onMessagesCounterClick(id);
    }
  }

  handleMessagesCounterClick() {
    const { expanded } = this.state;
    const { typeOf } = this.props;
    this.setState({ expanded: !expanded }, this.handleCommentsOpen);
    trackFeedbackCommentIconClick(typeOf);
  }

  getFeedFooterProps() {
    const {
      comments,
      intl: { formatMessage },
      onClickUserInfo,
      selfUser,
      sender,
      totalMessages,
      typeOf,
      user,
    } = this.props;

    const userToDisplay = (sender && !isEmpty(sender)) ? sender : user;
    const currentTotalMessages = comments ? comments.length : totalMessages;
    const feedbackType = typeOf === 'SENT' ? 'requested_by' : 'fdbk_user';
    return {
      counters: [{
        icon: 'comment',
        onClick: this.handleMessagesCounterClick,
        value: currentTotalMessages
      }],
      fullNamePrefixText: (typeOf === 'SENT') ?
        formatMessage(messages.FeedbackFeedMenuItemSentBy) :
        formatMessage(messages.FeedbackFeedMenuItemReceivedFrom),
      fullName: (typeOf === 'SENT') && (userToDisplay?.id === selfUser.id) ?
        formatMessage(messages.FeedbackFeedMenuItemMe) :
        `${userToDisplay?.firstName} ${userToDisplay?.lastName}`,
      profileImageCode: (typeOf === 'SENT') ? null : userToDisplay?.profileImageCode,
      typeOf: (this.state.expanded) ? 'light' : 'dark',
      onUserInfoClick: () => {
        onClickUserInfo();
        trackFeedbackUserAvatarNameClick(typeOf, feedbackType);
      },
    };
  }

  getCommentsProps() {
    const { id, comments: itemsMessages, sendingMessage, sendComment, typeOf } = this.props;
    const { expanded } = this.state;
    return {
      comments: itemsMessages,
      expanded,
      feedbackId: id,
      loading: sendingMessage,
      sendComment,
      typeOf,
    };
  }

  renderRatedTopics() {
    const { ratedTopics = [] } = this.props;
    const ratedTopicOrdered = orderBy(ratedTopics, 'ordinality');

    return ratedTopicOrdered.map((ratedTopic, index) => {
      return (
        <div key={ratedTopic.id}>
          <div className="feed-item__rate">
            <h3
              className="feed-item__rate-title"
              style={{ color: ratedTopic.color }}
            >
              <span className={`feed-item__rate-title-content ${(index === 0) ? 'feed-item__rate-title-small-content' : ''}`}>
                <span className="feed-item__rate-image-wrapper" style={{ backgroundColor: ratedTopic.color }}>
                  {(!!ratedTopic.imageCode) && (
                    <UserAvatar
                      noBackground
                      className="feed-item__rate-image"
                      profileImageCode={ratedTopic.imageCode}
                      height={22}
                      width={22}
                    />
                  )}
                </span>
                <span>{ratedTopic.name}</span>
              </span>
            </h3>
            {this.renderTopics(ratedTopic.topics)}
          </div>
          <Divider nomarginbottom />
        </div>
      );
    });
  }

  renderTopics(topics) {
    const { typeOf } = this.props;
    return topics.map(topic => (
      <TagItem
        className={cn("feed-item__tag", {
          "feed-item__tag-skill": topic.skill,
        })}
        description={topic.description}
        onDescriptionOpen={() => trackFeedbackCompetenciesHover(typeOf, topic)}
        key={topic.id}
        name={topic.name}
      />
    ));
  }

  renderComment() {
    const {
      audio,
      comment,
      intl: { formatMessage },
      typeOf,
    } = this.props;
    const textRevealerProps = {
      lessButtonLabel: this.renderCommentButtonLabel('less'),
      maxHeight: 145,
      moreButtonLabel: this.renderCommentButtonLabel('more'),
    };
    let commentNode = null;

    if (comment) {
      commentNode = (
        <div className="feed-item__comment">
          <Title className="feed-item__comment-title">
            {formatMessage(messages.FeedbackFeedItemTitleComment)}
          </Title>
          <TextRevealer {...textRevealerProps}>
            <pre className="feed-item__comment-text">
              <TextParserContainer>{comment}</TextParserContainer>
            </pre>
          </TextRevealer>
        </div>
      );
    } else if (audio) {
      commentNode = (
        <div className="feed-item__comment">
          <Title className="feed-item__comment-title">
            {formatMessage(messages.FeedbackFeedItemTitleComment)}
          </Title>
          <AudioComment
            barWidth={460}
            trackEvent
            typeOf={typeOf}
            url={audio.url}
          />
        </div>
      );
    }

    return commentNode;
  }

  renderCommentButtonLabel(buttonType) {
    const { intl: { formatMessage } } = this.props;
    const message = (buttonType === 'less') ? actionsMessages.ActionsReadLess : actionsMessages.ActionsReadMore;
    return <span className="feed-item__comment-toggle">{formatMessage(message)}</span>;
  }

  render() {
    const {
      attachments,
      creationTime,
      intl: { formatMessage },
      isTeamDashboard,
      newStyle,
      recipient,
      requestedBy,
      typeOf,
    } = this.props;
    const isSent = typeOf === 'SENT';
    const showRecipient = isSent
      || isTeamDashboard
      || (!isSent && requestedBy);

    return (
      <div className={cn("feed-item", {
        'feed-item--new-style': newStyle,
      })}>
        <div className='feed-item__date'>
          {newStyle ? (
            <CardDateTime>{moment(creationTime).fromNow()}</CardDateTime>
          ) : (
            <p>{moment(creationTime).fromNow()}</p>
          )}
        </div>
        {(showRecipient) && <FeedHeader newStyle={newStyle} {...recipient} />}
        {(isSent) && <Divider nomargin />}
        {this.renderRatedTopics()}
        {this.renderComment()}
        <div className="feed-item__download-file-wrapper">
          <DownloadFile attachments={attachments} className="feed-item__download-file"/>
        </div>
        {requestedBy && (
          <div className="feed-item__requested-by">
            <div className="feed-item__requested-by-content">
              <p className="feed-item__requested-by-user">
                {formatMessage(feedbackMessages.FeedbackFeedbackRequestedBy)} <strong>{requestedBy}</strong>.
              </p>
            </div>
          </div>
        )}
        <Divider className="feed-item__footer-divider" nomargin />
        <FeedFooter {...this.getFeedFooterProps()} />
        <Comments {...this.getCommentsProps()} />
      </div>
    );
  }
}

Item.defaulProps = {
  commentExpanded: false
};

Item.propTypes = {
  comment: PropTypes.string,
  commentExpanded: PropTypes.bool,
  creationTime: PropTypes.number,
  id: PropTypes.number,
  isTeamDashboard: PropTypes.bool,
  messages: PropTypes.array,
  newStyle: PropTypes.bool,
  onClickUserInfo: PropTypes.func,
  onMessagesCounterClick: PropTypes.func,
  ratedTopics: PropTypes.array,
  recipient: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    profileImageCode: PropTypes.string,
  }),
  requestedBy: PropTypes.string,
  sendComment: PropTypes.func,
  sender: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    profileImageCode: PropTypes.string,
  }),
  sendingMessage: PropTypes.bool,
  totalMessages: PropTypes.number,
  typeOf: PropTypes.oneOf(['RECEIVED', 'SENT']),
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    profileImageCode: PropTypes.string,
  }),
};

export default injectIntl(Item);
