// @packages
import React from 'react';
import Button from 'smu-ui-components/ButtonV2';
import Icon from 'smu-ui-components/IconV2';
import PropTypes from 'prop-types';

// @own
import './styles.scss';

const MyGrowth = ({
  buttonText,
  onButtonClick,
  title,
}) => {
  return (
    <div className="my-growth">
      <span className="my-growth__title">{title}</span>
      <Button
        className="my-growth__button"
        endIcon={
          <Icon
            color="black"
            icon="external-link"
            size="extra-small"
          />
        }
        variant="outline"
      >
        {buttonText}
      </Button>
    </div>
  );
};

MyGrowth.propTypes = {
  buttonText: PropTypes.string,
  onButtonClick: PropTypes.func,
  title: PropTypes.string,
};

export default MyGrowth;
