// @packages
import cn from 'classnames';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';

// @app
import TinyProgressBar from 'components/atoms/TinyProgressBar';
import formatScore from 'betterme-components/utils/formatScore';
import { OPENED_STATUS, CLOSED_STATUS } from 'betterme-components/Goals/constants';

// @own
import './styles.scss';

const GoalOverviewItem = ({
  className,
  dueDate,
  progress,
  scales,
  status,
  summary,
  title,
  weighting,
}) => (
  <li className={cn('goal-overview-item', className)}>
      <div className="goal-overview-item__info">
        <div className="goal-overview-item__title">{title}</div>
        {status === OPENED_STATUS && (
          <TinyProgressBar
            className="goal-overview-item__progress-bar"
            progress={progress}
            scales={scales}
          />
        )}
      </div>
      <div className="goal-overview-item__weight">{`${weighting}%`}</div>
      <div className="goal-overview-item__score-date">
        {status === OPENED_STATUS ? (
          moment(dueDate).utc().format('L')
        ) : (
          formatScore(summary)
        )}
      </div>
    </li>
);

GoalOverviewItem.propTypes = {
  className: PropTypes.string,
  dueDate: PropTypes.number,
  progress: PropTypes.number,
  status: PropTypes.oneOf([
    OPENED_STATUS,
    CLOSED_STATUS
  ]).isRequired,
  summary: PropTypes.shape({
    message: PropTypes.string,
    score: PropTypes.number,
  }),
  title: PropTypes.string.isRequired,
  weighting: PropTypes.number.isRequired,
};

export default GoalOverviewItem;
