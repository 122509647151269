// @packages
import React, { useEffect } from 'react';
import cn from 'classnames';
import Button from 'smu-ui-components/ButtonV2';
import Icon from 'smu-ui-components/IconV2';
import Popup from 'smu-ui-components/Popup';
import PropTypes from 'prop-types';
import Segment from 'smu-ui-components/Segment';
import isEmpty from 'lodash/isEmpty';
import { actionRequestInit, actionRequestDestroy } from 'smu-utils/reduxRequests/actions';
import { add as showToast } from 'smu-app-components/ToastNotifications/actions';
import { base64Decode } from 'smu-utils/base64';
import { connect } from 'react-redux';
import { getUserLanguage } from 'smu-utils/language';
import { injectIntl } from 'react-intl';
import { openModal } from 'smu-app-components/RootModal/actions';
import { push } from 'react-router-redux';
import { selectResult } from 'smu-utils/reduxRequests/selectors';

// @app
import BreadcrumbHeader from 'components/organisms/BreadcrumbHeader';
import { userMessages } from 'common/messages';
import LeadershipAdminUserInfo from 'components/organisms/LeadershipAdminUserInfo';
import UserInfoCard from 'components/organisms/UserInfoCard';
import Title from 'components/atoms/Title';
import {
  selectLeadershipAdminLoadingUser,
  selectLeadershipAdminUser,
} from 'betterme-components/services/LeadershipAdmin/selectors';
import { selectUser } from 'betterme-components/Authorization/selectors';
import { getLeadershipAdminUserProfile } from 'betterme-components/services/LeadershipAdmin/actions';
import { getProfileLocation, getLeaderSubstitutionLocation } from 'betterme-components/routes/urls';
import AddLeaderBox from 'components/atoms/AddLeaderBox';
import {
  LEADERSHIP_REQUEST_MODAL,
  REMOVE_LEADER_MODAL,
} from 'betterme-components/constants/modalTypes';
import * as changeLeaderActions from 'betterme-components/services/ChangeLeaderRequest/actions';
import EvaluationRequiredModal from 'components/containers/EvaluationRequiredModal';
import FeedbackRequiredModal from 'components/containers/FeedbackRequiredModal';
import LeaderSuggestionRequiredModal from 'components/containers/LeaderSuggestionRequiredModal';
import LeadershipRequestModal from 'components/containers/LeadershipRequestModal';
import RemoveLeaderModal from 'components/containers/RemoveLeaderModal';
import { selectConfigurations } from 'betterme-components/Authorization/selectors';
import {
  trackAdminLeadershipAddMeAs,
  trackAdminLeadershipRemove,
  trackAdminLeadershipReplace,
} from 'betterme-components/services/LeadershipAdmin/analytics';
import { ACCOUNT_LEADER_ANALYTICS, CAREER_LEADER_ANALYTICS } from 'components/common/constants';
import { apiGetSuggestedLeaders } from 'betterme-components/services/LeadershipSubstitution/api';

// @own
import './styles.scss';
import messages from './messages';
import {
  ADD_TO_MY_TEAM_REQUEST_AS_MAIN,
  ADD_TO_MY_TEAM_REQUEST_AS_SECONDARY,
  CHANGE_LEADER_REQUEST_AS_MAIN,
  CHANGE_LEADER_REQUEST_AS_SECONDARY,
  REMOVE_LEADER_REQUEST_AS_MAIN,
  REMOVE_LEADER_REQUEST_AS_SECONDARY,
  REMOVE_MYSELF_AS_MAIN_LEADER,
  REMOVE_MYSELF_AS_SECONDARY_LEADER,
  REQUEST_ID_GET_SUGGESTED_LEADERS,
  SECONDARY,
} from './constants';
import { MAIN_LEADER, SECONDARY_LEADER } from 'components/common/constants';

const LeadershipAdmin = ({
  actionRequestDestroy,
  actionRequestInit,
  allowsAddAsAccountLeader,
  allowsAddToMyTeam,
  allowsRemoveAsAccountLeader,
  allowsReplaceAsAccountLeader,
  allowsSuggestAccountLeaders,
  collaborator,
  getLeadershipAdminUserProfile,
  goToLeaderSubstitution,
  goToUserProfile,
  intl: { formatMessage },
  loadingCollaborator,
  openModal,
  params: { userId },
  suggestions,
  user,
}) => {
  const locale = getUserLanguage();
  const breadcrumbMessage = locale === 'es'
    ? `${formatMessage(userMessages.UserProfile)} ${collaborator?.firstName || ''}`
    : `${collaborator?.firstName || ''} ${formatMessage(userMessages.UserProfile)}`;
  const { access: { newWordingForLeaders } } = user;
  const mainLeader = collaborator?.leader;
  const secondaryLeaders = collaborator?.evaluators;
  const mainLeaderText = newWordingForLeaders
    ? formatMessage(messages.LeadershipAdminCareerLeader)
    : formatMessage(messages.LeadershipAdminMainLeader);
  const secondaryLeaderTitleText = newWordingForLeaders
    ? formatMessage(messages.LeadershipAdminOtherLeaders)
    : formatMessage(messages.LeadershipAdminSecondaryLeaders);
  const secondaryLeaderText = newWordingForLeaders
    ? formatMessage(messages.LeadershipAdminAccountLeader)
    : formatMessage(messages.LeadershipAdminSecondaryLeader);
  const toastMessages = {
    fail: formatMessage(messages.LeadershipAdminChangeLeaderToastFail),
    success: formatMessage(messages.LeadershipAdminChangeLeaderToastSuccess),
  };
  const accountAddLeaderBoxMessage = allowsAddAsAccountLeader
    ? `${formatMessage(messages.LeadershipAdminAddMeAs)} ${secondaryLeaderText}`
    : (
      <div className="leadership-admin__account-leader-box">
        <p className="leadership-admin__account-leader-box-title">
          {formatMessage(messages.LeadershipAdminAccountLeaderBoxTitle)}
        </p>
        <span className="leadership-admin__account-leader-box-description">
          {formatMessage(messages.LeadershipAdminAccountLeaderBoxDescription)}
        </span>
      </div>
    );
  const hasSecondaryLeaders = secondaryLeaders?.length > 0;
  const showSecondaryLeadersSection = (hasSecondaryLeaders || (!hasSecondaryLeaders && allowsAddToMyTeam));
  const isSuggestedLeader = suggestions?.leaderSuggestions?.some(
    ({ leader }) => leader?.id === user?.id,
  );
  const loggedUserIsSecondaryLeader = secondaryLeaders?.some(leader => leader?.id === user?.id);
  const showAddMeAsSecondaryButton = (allowsAddToMyTeam && allowsAddAsAccountLeader && !loggedUserIsSecondaryLeader);
  const showAddMeAsSecondaryButtonEnabled = (showAddMeAsSecondaryButton) && (
    (allowsSuggestAccountLeaders && isSuggestedLeader)
    || !allowsSuggestAccountLeaders
  )
  const showAddMeAsSecondaryButtonDisabled = showAddMeAsSecondaryButton
    && (allowsSuggestAccountLeaders && !isSuggestedLeader);

  const {
    account,
    area,
    office,
    project,
    studio,
  } = collaborator;

  const items = [
    {
      title: formatMessage(userMessages.UserProject),
      description: project,
    },
    {
      title: formatMessage(userMessages.UserAccount),
      description: account,
    },
    {
      title: formatMessage(userMessages.UserStudio),
      description: studio,
    },
    {
      title: formatMessage(userMessages.UserArea),
      description: area,
    },
    {
      title: formatMessage(userMessages.UserOffice),
      description: office,
    },
  ];

  function handleOpenModal(modalType, requestType, leaderType, reasonsType) {
    openModal({
      modalType,
      modalProps: { type: requestType, leaderType, reasonsType },
    });
  }

  function handleRemove(modalType, requestType, leaderType, reasonsType) {
    handleOpenModal(modalType, requestType, leaderType, reasonsType);
    trackAdminLeadershipRemove(leaderType);
  }

  function handleReplace(identification, requestType, leaderType) {
    goToLeaderSubstitution(identification, requestType);
    trackAdminLeadershipReplace(leaderType);
  }

  function handleAddMeAs(modalType, requestType, leaderType) {
    handleOpenModal(modalType, requestType, leaderType);
    trackAdminLeadershipAddMeAs(leaderType);
  }

  function getSuggestedLeaders() {
    actionRequestInit({
      api: apiGetSuggestedLeaders,
      onError: (error) => showToast({
        message: error.message,
        timeout: 5000,
      }),
      id: REQUEST_ID_GET_SUGGESTED_LEADERS,
      params: {
        leaderType: SECONDARY,
        memberId: collaborator?.id,
      },
    })
  };

  useEffect(() => {
    if (!isEmpty(collaborator) && allowsSuggestAccountLeaders) {
      getSuggestedLeaders();
      return () => actionRequestDestroy(REQUEST_ID_GET_SUGGESTED_LEADERS);
    }
  }, [collaborator]);

  useEffect(() => {
    getLeadershipAdminUserProfile(userId);
  }, []);

  return (
    <Segment
      className="leadership-admin"
      loading={loadingCollaborator}
      loadingType="betterme"
    >
      <BreadcrumbHeader
        breadCrumbsProps={{
          active: 1,
          onClick: () => goToUserProfile(base64Decode(userId)),
          size: 'big',
          values: [
            breadcrumbMessage,
            formatMessage(messages.LeadershipAdminLeadershipAdmin),
          ],
        }}
        className="okr-form__header"
      />
      <LeadershipAdminUserInfo
        items={items}
        user={collaborator}
      />
      <div className="leadership-admin__container">
        <div className="leadership-admin__main-content">
          <Title
            className={cn('leadership-admin__section-title', 'text-black')}
            size={14}
          >
            {mainLeaderText}
          </Title>
          {mainLeader ? (
            <UserInfoCard
              className="leadership-admin__user-info-card"
              highlighted={mainLeader.id === user.id}
              messages={{
                account: formatMessage(userMessages.UserAccount),
                area: formatMessage(userMessages.UserArea),
                office: formatMessage(userMessages.UserOffice),
                project: formatMessage(userMessages.UserProject),
                remove: formatMessage(messages.LeadershipAdminRemoveText),
                replace: formatMessage(messages.LeadershipAdminReplaceText),
              }}
              onRemoveClick={() => handleRemove(
                REMOVE_LEADER_MODAL,
                REMOVE_LEADER_REQUEST_AS_MAIN,
                CAREER_LEADER_ANALYTICS,
                REMOVE_MYSELF_AS_MAIN_LEADER,
              )}
              onReplaceClick={() => handleReplace(
                base64Decode(userId),
                MAIN_LEADER,
                ACCOUNT_LEADER_ANALYTICS,
              )}
              showActionButtons={mainLeader.id === user.id}
              type={CHANGE_LEADER_REQUEST_AS_MAIN}
              user={mainLeader}
            />
          ) : (
            <AddLeaderBox
              className="leadership-admin__add-leader-box"
              onClick={() => handleAddMeAs(
                LEADERSHIP_REQUEST_MODAL,
                ADD_TO_MY_TEAM_REQUEST_AS_MAIN,
                CAREER_LEADER_ANALYTICS,
              )}
              text={`${formatMessage(messages.LeadershipAdminAddMeAs)} ${mainLeaderText}`}
            />
          )}
          {showSecondaryLeadersSection && (
            <div className="leadership-admin__secondary-leaders">
              <Title
                className={cn('leadership-admin__section-title', 'text-black')}
                size={14}
              >
                {secondaryLeaderTitleText}
              </Title>
              {hasSecondaryLeaders ? (
                <>
                  {(showAddMeAsSecondaryButtonEnabled) && (
                    <Button
                      className="leadership-admin__add-leader-button"
                      endIcon={
                        <Icon
                          className="leadership-admin__add-leader-button-icon"
                          icon="add-circular"
                        />
                      }
                      variant="link"
                      onClick={() => handleOpenModal(LEADERSHIP_REQUEST_MODAL, ADD_TO_MY_TEAM_REQUEST_AS_SECONDARY)}
                    >
                      {`${formatMessage(messages.LeadershipAdminAddMeAs)} ${secondaryLeaderText}`}
                    </Button>
                  )}
                  {(showAddMeAsSecondaryButtonDisabled) && (
                    <Popup
                      content={formatMessage(messages.LeadershipAdminAddLeaderPopup)}
                      hideOnScroll
                      inverted
                      position="top center"
                      trigger={
                        <span className="leadership-admin__popup">
                          <Button
                            className="leadership-admin__add-leader-button"
                            endIcon={
                              <Icon
                                className="leadership-admin__add-leader-button-icon"
                                icon="add-circular"
                              />}
                            variant="link"
                            disabled
                          >
                            {`${formatMessage(messages.LeadershipAdminAddMeAs)} ${secondaryLeaderText}`}
                          </Button>
                        </span>
                      }
                    />
                  )}
                  {secondaryLeaders.map((leader, index) => (
                    <UserInfoCard
                      className="leadership-admin__user-info-card"
                      highlighted={leader.id === user.id}
                      key={index}
                      messages={{
                        account: formatMessage(userMessages.UserAccount),
                        area: formatMessage(userMessages.UserArea),
                        office: formatMessage(userMessages.UserOffice),
                        project: formatMessage(userMessages.UserProject),
                        remove: formatMessage(messages.LeadershipAdminRemoveText),
                        replace: formatMessage(messages.LeadershipAdminReplaceText),
                      }}
                      onRemoveClick={() => handleRemove(
                        REMOVE_LEADER_MODAL,
                        REMOVE_LEADER_REQUEST_AS_SECONDARY,
                        ACCOUNT_LEADER_ANALYTICS,
                        REMOVE_MYSELF_AS_SECONDARY_LEADER,
                      )}
                      onReplaceClick={() => handleReplace(
                        base64Decode(userId),
                        SECONDARY_LEADER,
                        CAREER_LEADER_ANALYTICS,
                      )}
                      showActionButtons={leader.id === user.id}
                      showRemoveButton={allowsRemoveAsAccountLeader}
                      showReplaceButton={allowsReplaceAsAccountLeader}
                      type={CHANGE_LEADER_REQUEST_AS_SECONDARY}
                      user={leader}
                    />
                  ))}
                </>
              ) : (
                <AddLeaderBox
                  className="leadership-admin__add-leader-box"
                  disabled={!showAddMeAsSecondaryButtonEnabled}
                  onClick={() => {
                    showAddMeAsSecondaryButtonEnabled && (
                      handleAddMeAs(
                        LEADERSHIP_REQUEST_MODAL,
                        ADD_TO_MY_TEAM_REQUEST_AS_SECONDARY,
                        ACCOUNT_LEADER_ANALYTICS,
                      )
                    )
                  }}
                  showIcon={allowsAddAsAccountLeader}
                  text={accountAddLeaderBoxMessage}
                  popupText={formatMessage(
                    messages.LeadershipAdminAddLeaderPopup
                  )}
                  withPopup={showAddMeAsSecondaryButtonDisabled}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <RemoveLeaderModal
        collaborator={collaborator}
        newWordingForLeaders={newWordingForLeaders}
      />
      <LeadershipRequestModal
        collaborator={collaborator}
        leaderId={user?.id}
        suggestionId={suggestions?.suggestionId}
        messages={toastMessages}
      />
      <FeedbackRequiredModal />
      <LeaderSuggestionRequiredModal />
      <EvaluationRequiredModal />
    </Segment>
  );
};

const goToUserProfile = identification => push(getProfileLocation(identification));
const goToLeaderSubstitution = (identification, leaderType) => push(getLeaderSubstitutionLocation(identification, leaderType));

const mapDispatchToProps = {
  ...changeLeaderActions,
  actionRequestDestroy,
  actionRequestInit,
  getLeadershipAdminUserProfile,
  goToLeaderSubstitution,
  goToUserProfile,
  openModal,
  showToast,
};

const mapStateToProps = state => ({
  allowsAddAsAccountLeader: selectConfigurations('allowsAddAsAccountLeader')(state),
  allowsAddToMyTeam: selectConfigurations('allowsAddToMyTeam')(state),
  allowsRemoveAsAccountLeader: selectConfigurations('allowsRemoveAsAccountLeader')(state),
  allowsReplaceAsAccountLeader: selectConfigurations('allowsReplaceAsAccountLeader')(state),
  allowsSuggestAccountLeaders: selectConfigurations('allowsSuggestAccountLeaders')(state),
  collaborator: selectLeadershipAdminUser(state),
  loadingCollaborator: selectLeadershipAdminLoadingUser(state),
  suggestions: selectResult(state, REQUEST_ID_GET_SUGGESTED_LEADERS),
  user: selectUser(state),
});

LeadershipAdmin.defaultProps = {
  changeLeaderUserList: [],
}

LeadershipAdmin.propTypes = {
  actionRequestDestroy: PropTypes.func.isRequired,
  actionRequestInit: PropTypes.func.isRequired,
  allowsAddToMyTeam: PropTypes.bool,
  collaborator: PropTypes.object,
  getLeadershipAdminUserProfile: PropTypes.func.isRequired,
  goToLeaderSubstitution: PropTypes.func.isRequired,
  goToUserProfile: PropTypes.func.isRequired,
  intl: PropTypes.object,
  loadingCollaborator: PropTypes.bool,
  openModal: PropTypes.func,
  params: PropTypes.object,
  updateUserLeader: PropTypes.func.isRequired,
  user: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LeadershipAdmin));
