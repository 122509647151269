// @packages
import React from 'react';
import PropTypes from 'prop-types';

//@own
import './styles.scss';
import CompetenciesCard from 'betterme-components/SendFeedbackForLeaders/CompetenciesCard';

const FormCompetenciesItem = ({
  answersCompetencies,
  onChange,
  questions,
  ratings,
  totalQuestions,
}) => (
  <div className="form-competencies-item">
    {questions.map(question => (
      <div className="form-competencies-item__question">
        <CompetenciesCard
          competenciesDescription={question.name}
          onChange={(value, valueId) => onChange({
            id: question.id,
            questionType: question.questionType,
            required: question.required,
            value,
            valueId,
          })}
          questionNumber={question.number}
          ratings={ratings}
          totalQuestions={totalQuestions}
          answer={answersCompetencies[question.id]}
        />
      </div>
    ))}
  </div>
);

FormCompetenciesItem.propTypes = {
  answersCompetencies: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  questions: PropTypes.array.isRequired,
  ratings: PropTypes.array.isRequired,
  totalQuestions: PropTypes.number.isRequired,
}

export default FormCompetenciesItem;
