// @packages
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { injectIntl } from 'react-intl';
import { isEqual, trimStart } from 'lodash';
import Filters from 'smu-ui-components/Filters';
import Segment from 'smu-ui-components/Segment';

// @app
import { getDropdownPeriodList } from 'utils/getDropdownPeriodList';
import {
  actionsMessages,
  commonMessages,
  goalsMessages,
  placeholdersMessages,
  sectionsMessages,
} from 'common/messages';
import { selectConfigurations } from '../../Authorization/selectors';
import GoalFeedItem from '../GoalFeedItem';
import GoalsEmptyState from '../GoalsEmptyState';
import messages from '../messages';

// @own
import './styles.scss';
import * as actions from '../actions';
import {
  INITIAL_FILTERS,
  MY_GOALS,
  OPENED_STATUS,
  SHARED,
} from '../constants';
import {
  selectMyFiltersApplied,
  selectMyGoalsFilters,
} from '../selectors';

class MyGoals extends Component {
  constructor(props) {
    super(props);

    const {
      intl: { formatMessage },
      myGoalsFilters,
    } = this.props;
    this.state = {
      filters: myGoalsFilters,
    };

    this.handleOnClick = this.handleOnClick.bind(this);
    this.handlerApplyFilters = this.handlerApplyFilters.bind(this);
    this.handlerChangeFilterDropdown = this.handlerChangeFilterDropdown.bind(this);
    this.handlerChangeFilterText = this.handlerChangeFilterText.bind(this);
    this.handlerClearFilters = this.handlerClearFilters.bind(this);
    this.messagesMyGoals = {
      assigned: formatMessage(messages.FeedbackGoalsAssigned),
      assignedToMe: formatMessage(messages.FeedbackGoalsAssignedToMe),
      attachedFiles: formatMessage(commonMessages.AttachedFiles),
      closed: formatMessage(goalsMessages.GoalsClosed),
      dueDate: formatMessage(sectionsMessages.SectionsDueDate),
      edit: formatMessage(actionsMessages.ActionsEdit),
      emptyStateEvaluator: formatMessage(messages.GoalsEmptyStateEvaluator),
      evaluatedBy: formatMessage(messages.FeedbackGoalsEvaluatedBy),
      individual: formatMessage(messages.FeedbackGoalsIndividual),
      opened: formatMessage(goalsMessages.GoalsOpened),
      pending: formatMessage(goalsMessages.GoalsPending),
      period: formatMessage(commonMessages.Period),
      progress: formatMessage(goalsMessages.GoalsProgress),
      shared: formatMessage(messages.FeedbackGoalsShared),
      startDate: formatMessage(sectionsMessages.SectionsStartDate),
      weighting: formatMessage(goalsMessages.GoalsWeight),
    };
    this.messagesFilters = {
      apply: formatMessage(actionsMessages.ActionsApply),
      cancel: formatMessage(actionsMessages.ActionsCancel),
      clear: formatMessage(actionsMessages.ActionsClear),
      title: formatMessage(messages.FeedbackGoalsFiltersTitle),
    };
  }

  componentDidMount() {
    const {
      getAssignedGoals,
      getPeriods,
      myGoalsFilters,
      session: { user },
    } = this.props;
    const filters = this.getApiFilters(myGoalsFilters);

    if (user.id) {
      getAssignedGoals(user.id, filters);
    }

    getPeriods();
  }

  componentWillReceiveProps(nextProps) {
    const { session: { user }, getAssignedGoals } = this.props;
    const { session: { user: nextUser } } = nextProps;

    if (user.id !== nextUser.id) {
      getAssignedGoals(nextUser.id);
    }
  }

  componentDidUpdate(prevProps) {
    const { myGoalsFilters: prevTeamGoalsFilters } = prevProps;
    const {
      getAssignedGoals,
      myGoalsFilters: currentMyGoalsFilters,
      session: { user },
    } = this.props;
    const { filters: currentStateFilters } = this.state;

    if (!isEqual(prevTeamGoalsFilters, currentMyGoalsFilters)) {
      getAssignedGoals(user.id, this.getApiFilters(currentMyGoalsFilters));
      this.setState({ filters: { ...currentStateFilters, ...currentMyGoalsFilters } });
    }
  }

  componentWillUnmount() {
    this.props.resetAssignedGoal();
  }

  getApiFilters(filters) {
    const {
      filterPeriodId,
      filterStatus,
      filterText,
      filterUser,
    } = filters;

    return {
      title: filterText || null,
      status: filterStatus || null,
      assignee: filterUser || null,
      periodId: filterPeriodId || null,
    };
  }

  getPeriodList() {
    const { goals: { period } } = this.props;
    return getDropdownPeriodList(period, true);
  }

  handlerChangeFilterText(event) {
    const { filters } = this.state;
    const { value } = event.target;
    const cleanValue = trimStart(value);

    this.setState({
      filters: {
        ...filters,
        filterText: cleanValue
      }
    });
  }

  handlerChangeFilterDropdown(name, value) {
    const { filters } = this.state;

    this.setState({
      filters: {
        ...filters,
        [name]: value
      }
    });
  }

  handlerClearFilters(applyFilters = false) {
    const { setMyGoalFilters } = this.props;

    this.setState({
      filters: INITIAL_FILTERS,
    });


    if (applyFilters) {
      setMyGoalFilters(INITIAL_FILTERS);
    }
  }

  handlerApplyFilters() {
    const { setMyGoalFilters } = this.props;
    const { filters } = this.state;

    setMyGoalFilters(filters);
  }

  handleOnClick(goal, typeAction) {
    const {
      goToPath,
      params,
      user,
    } = this.props;
    const pathMyGoals = params.userid ? `/profile/${params.userid}/goals/mygoals/` : '/goals/mygoals/';

    goToPath({
      pathname: `${pathMyGoals}${goal.id}`,
      state: { user, params, typeAction },
    });
  }

  renderGoalsAssignedList() {
    const {
      allowsCollaboratorEditMyGoals,
      goals: { assignedGoals },
      params,
      session: { user },
    } = this.props;

    if (assignedGoals.length !== 0) {
      return assignedGoals.map((goal, index) => {
        const { evaluator, status, assignment } = goal;
        const isAllowedEdition = allowsCollaboratorEditMyGoals
          && evaluator
          && assignment !== SHARED
          && status === OPENED_STATUS;
        return (
          <GoalFeedItem
            allowedEdition={isAllowedEdition}
            key={index}
            onClick={(typeAction) => this.handleOnClick(goal, typeAction)}
            typeGoals={MY_GOALS}
            messages={this.messagesMyGoals}
            {...goal}
          />
        );
      });
    } else {
      return (
        <GoalsEmptyState own={!params.userid} userName={user.firstName} />
      );
    }
  }

  render() {
    const {
      filtersApplied,
      goals: { fetching },
      intl: { formatMessage },
      params,
      session: { user },
    } = this.props;
    const { filters: filtersState } = this.state;
    const {
      filterPeriodId,
      filterStatus,
      filterText,
      filterUser,
    } = filtersState;
    const goalsAssignedList = this.renderGoalsAssignedList();
    const optionsLeaders = user.leader ?
      [{
        value: user.leader ? user.leader.identification : '',
        label: user.leader ? user.leader : '',
      }] : [];
    const tabs = [
      {
        name: params.userid ?
          this.messagesMyGoals.assigned :
          this.messagesMyGoals.assignedToMe,
        enabled: true,
      }
    ];
    const filters = [
      {
        type: 'text',
        onChange: this.handlerChangeFilterText,
        placeholder: formatMessage(messages.FeedbackGoalsFiltersPlaceholderText),
        value: filterText,
      },
      {
        type: 'dropdown',
        onChange: value => this.handlerChangeFilterDropdown('filterUser', value),
        placeholder: formatMessage(messages.FeedbackGoalsFiltersPlaceholderLeader),
        value: filterUser,
        paddingInput: true,
        options: optionsLeaders,
      },
      {
        type: 'dropdown',
        onChange: value => this.handlerChangeFilterDropdown('filterStatus', value),
        placeholder: formatMessage(messages.FeedbackGoalsFiltersPlaceholderStatus),
        value: filterStatus,
        padding: true,
        options: [
          {
            value: 'CLOSED',
            label: formatMessage(goalsMessages.GoalsClosed),
          },
          {
            value: 'OPENED',
            label: formatMessage(goalsMessages.GoalsOpened),
          },
          {
            value: 'PENDING_APPROVAL',
            label: formatMessage(goalsMessages.GoalsPending),
          },
        ],
      },
      {
        type: 'dropdown',
        onChange: value => this.handlerChangeFilterDropdown('filterPeriodId', value),
        placeholder: formatMessage(placeholdersMessages.PlaceholdersSelectPeriod),
        value: filterPeriodId,
        padding: true,
        options: this.getPeriodList(),
      }
    ];

    return (
      <div className="my-goals">
        <div className="my-goals__fixed-menu">
          <Filters
            tabs={tabs}
            filters={filters}
            handlerApplyFilters={this.handlerApplyFilters}
            handlerClearFilters={this.handlerClearFilters}
            filtersApplied={filtersApplied}
            messages={this.messagesFilters}
          />
        </div>
        <div className="my-goals__list">
          <Segment loading={fetching} loadingType="betterme" withChildren={false}>
            {goalsAssignedList}
          </Segment>
        </div>
      </div>
    );
  }
}

MyGoals.propTypes = {
  filtersApplied: PropTypes.bool.isRequired,
  getAssignedGoals: PropTypes.func.isRequired,
  getPeriods: PropTypes.func.isRequired,
  goToPath: PropTypes.func.isRequired,
  goals: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  myGoalsFilters: PropTypes.shape({
    filterPeriodId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    filterStatus: PropTypes.string,
    filterText: PropTypes.string,
    filterUser: PropTypes.string,
  }).isRequired,
  params: PropTypes.object,
  resetAssignedGoal: PropTypes.func.isRequired,
  session: PropTypes.object.isRequired,
  setMyGoalFilters: PropTypes.func.isRequired,
};

const goToPath = (path) => push(path);
const mapStateToProps = (state) => {
  const {
    goals,
  } = state;

  return {
    allowsCollaboratorEditMyGoals: selectConfigurations('allowsCollaboratorEditMyGoals')(state),
    filtersApplied: selectMyFiltersApplied(state),
    goals,
    myGoalsFilters: selectMyGoalsFilters(state),
  };
};
const mapDispatchToProps = {
  ...actions,
  goToPath
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MyGoals));
