import { defineMessages } from 'react-intl';

export default defineMessages({
  Filter: {
    id: 'Betterme.Focals.Filters',
    defaultMessage: 'Filters',
  },
  SearchPlaceHolder: {
    id: 'Betterme.Focals.SearchPlaceHolder',
    defaultMessage: 'Search User',
  },
  ActionManage: {
    id: 'Betterme.Focals.Action.Manage',
    defaultMessage: 'Manage',
  },
  WithoutLeader: {
    id: 'Betterme.Focals.WithoutLeader',
    defaultMessage: 'Without Leader'
  },
  UserField: {
    id: 'Betterme.Focals.UserField',
    defaultMessage: 'User'
  },
  CareerLeaderField: {
    id: 'Betterme.Focals.CareerLeaderField2',
    defaultMessage: 'Career Mentor',
  },
  AccountLeaderField: {
    id: 'Betterme.Focals.AccountLeaderField2',
    defaultMessage: 'Experience Leaders',
  },
  ActionField: {
    id: 'Betterme.Focals.ActionField',
    defaultMessage: 'Action',
  },
  FocalsAdminEmptyStateTitle: {
    id: 'Betterme.FocalsAdmin.EmptyStateTitle',
    defaultMessage: 'It doesn’t seem you have <br /> access to this page.'
  },
  FocalsAdminEmptyStateDescription: {
    id: 'Betterme.FocalsAdmin.EmptyStateDescription',
    defaultMessage: 'If you believe you should have permission, <br /> please contact your Administrator.',
  },
  FocalsAdminSegmentEmptyStateTitle: {
    id: 'Betterme.FocalsAdmin.SegmentEmptyStateTitle',
    defaultMessage: 'It doesn’t seem you have a <br /> segment assigned.'
  },
  FocalsAdminSegmentEmptyStateDescription: {
    id: 'Betterme.FocalsAdmin.SegmentEmptyStateDescription',
    defaultMessage: 'If you believe you should, <br /> please contact your Administrator.',
  },
  FocalsAdminSegmentWithoutUsersTitle: {
    id: 'Betterme.FocalsAdmin.SegmentWithoutUsersTitle',
    defaultMessage: 'No users were found in this segment',
  },
  FocalsAdminSegmentWithoutUsersDescription: {
    id: 'Betterme.FocalsAdmin.SegmentWithoutUsersDescription',
    defaultMessage: 'If you believe this is an error, contact your Administrator.',
  },
  FocalsAdminUserNotFoundTitle: {
    id: 'Betterme.FocalsAdmin.UserNotFoundTitle',
    defaultMessage: 'No users were found',
  },
  FocalsAdminUserNotFoundDescription: {
    id: 'Betterme.FocalsAdmin.UserNotFoundDescription',
    defaultMessage: 'Try adjusting your search to find what you are looking for.',
  },
  FocalsAdminFilterMembers: {
    id: 'Betterme.FocalsAdmin.FilterMember',
    defaultMessage: 'Members',
  },
  FocalsAdminFilterAll: {
    id: 'Betterme.FocalsAdmin.FilterAll',
    defaultMessage: 'All',
  },
  FocalsAdminFilterWithoutAnyLeader: {
    id: 'Betterme.FocalsAdmin.FilterWithoutAnyLeader',
    defaultMessage: 'Without any Leader',
  },
  FocalsAdminFilterWithoutCareerMentor: {
    id: 'Betterme.FocalsAdmin.FilterWithoutCareerMentor2',
    defaultMessage: 'Without Career Mentor',
  },
  FocalsAdminFilterWithoutExperienceLeader: {
    id: 'Betterme.FocalsAdmin.FilterWithoutExperienceLeader2',
    defaultMessage: 'Without Experience Leader',
  },
});
