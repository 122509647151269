// @packages
import React from 'react';
import AvatarMedia from 'smu-ui-components/AvatarMedia';
import Button from 'smu-ui-components/ButtonV2';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'smu-ui-components/IconV2';
import IconButton from 'smu-ui-components/IconButton';

// @own
import './styles.scss';

const UserSelectCard = ({
  buttonText,
  className,
  handleCloseCard,
  job,
  onSendRequest,
  user,
  withActions,
}) => {
  const userFirstName = user?.firstName || user?.firstname;
  const userLastName = user?.lastName || user?.lastname;

  return (
    <div className={cn('user-select-card', className)}>
      <div className="user-select-card__content">
        <AvatarMedia
          className="user-select-card__avatar"
          firstName={userFirstName}
          identification={user?.identification}
          job={job}
          lastName={userLastName}
          profileImageCode={user.profileImageCode}
          target="_blank"
        />
        <div className="user-select-card__actions">
          {withActions && (
            buttonText ? (
              <Button
                className="user-select-card__actions-button"
                onClick={(event) => onSendRequest(event, user)}
                size="medium"
                variant="outline"
              >
                {buttonText}
              </Button>
            ) : (
              <IconButton
                className="user-select-card__actions-icon"
                icon="x-close"
                onClick={() => handleCloseCard()}
                size={18}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

UserSelectCard.propTypes = {
  buttonText: PropTypes.string,
  className: PropTypes.string,
  handleCloseCard: PropTypes.func,
  job: PropTypes.string,
  onSendRequest: PropTypes.func,
  user: PropTypes.object,
  withActions: PropTypes.bool,
};

export default UserSelectCard;
