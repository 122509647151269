// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import InputRadio from 'smu-ui-components/InputRadio';

// @own
import './styles.scss';

const Reasons = ({
  className,
  onChange,
  optionsId,
  reasons,
  selectedReason,
}) => (
  <div className={cn('reason', className, {
    'reason--selected': selectedReason
  })}>
    {reasons && reasons?.map(reason =>
      <InputRadio
        checked={selectedReason === reason.id}
        className="reason__reasons"
        disabled={reason?.disabled}
        idName={`${optionsId || ''}_${reason?.id}`}
        key={`${optionsId || ''}_${reason?.id}`}
        label={reason?.name}
        name={optionsId || reason?.id}
        onChange={onChange}
        type="default"
        value={reason?.id}
      />
    )}
  </div>
);

Reasons.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  reasons: PropTypes.array.isRequired,
  selectedReason: PropTypes.any,
  optionsId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

Reasons.default = {
  reasons: [],
  optionsId: '',
};

export default Reasons;
