// @packages
import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

// @own
import './styles.scss';

const DataList = ({
  className,
  items,
}) => (
  <div className={cn('data-list', className)}>
    <ul className="data-list__data">
      {items.map((item, index) =>
          <li className="data-list__data-item" key={index}>
            {item.label && (
              <strong className="data-list__data-item-label font-openSans">
                {item.label}:
              </strong>
            )}
            <span className="data-list__data-item-value font-openSans">
              {item.value}
            </span>
          </li>
        )
      }
    </ul>
  </div>
);

DataList.defaultProps = {
  items: [],
};

DataList.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.node,
    ]).isRequired,
  })).isRequired,
};

export default DataList;
