// @packages
import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import UserAvatar from 'smu-ui-components/UserAvatar';
import RoundedTag from 'smu-ui-components/RoundedTag';

// @app
import DescriptionTooltip from 'common/DescriptionTooltip';

// @own
import './styles.scss';

const RatedTopics = ({
  className,
  noSkillStyle,
  ratedTopicsList,
}) => {
  const renderTopics = topics => (
    topics?.map((topic) => !isEmpty(topic) && (
      <RoundedTag
        key={topic?.id}
        className={cn('feed-item__rounded-tag', {
          'feed-item__rounded-tag--skill': topic?.skill && !noSkillStyle,
          'feed-item__rounded-tag--ignored': topic.ignoredRate,
        })}
      >
        <DescriptionTooltip
          className="feed-item__rounded-tag--tooltip"
          description={topic?.description || topic?.name}
        >
          {topic?.name}
        </DescriptionTooltip>
      </RoundedTag>
    ))
  );

  return (
    <div className={cn('rated-topics', className)}>
      {ratedTopicsList?.map((ratedTopic) => {
        const hasRatedTopics = ratedTopic?.topics?.length > 0;

        return (
          hasRatedTopics && (
            <div
              className="rated-topics__item"
              key={ratedTopic?.id}
            >
              <div className="rated-topics__item-title">
                <span className="rated-topics__item-title-content">
                  <span
                    className="rated-topics__item-title-content-image"
                    style={{ backgroundColor: ratedTopic?.color }}
                  >
                    {(!!ratedTopic.imageCode) &&
                      <UserAvatar
                        className="rated-topics__item-content-image-avatar"
                        height={22}
                        noBackground
                        profileImageCode={ratedTopic?.imageCode}
                        width={22}
                      />
                    }
                  </span>
                  <p
                    className="rated-topics__item-title-content-name"
                    style={{ color: ratedTopic?.color }}
                  >
                    {ratedTopic?.name}
                  </p>
                </span>
              </div>
              {renderTopics(ratedTopic?.topics)}
            </div>
          )
        );
      })}
    </div>
  );
};

RatedTopics.propTypes = {
  className: PropTypes.string,
  noSkillStyle: PropTypes.bool,
  ratedTopicsList: PropTypes.array.isRequired,
};

export default RatedTopics;
