// @packages
import React from 'react';
import PropTypes from 'prop-types';
import RootModal from 'smu-app-components/RootModal';
import SuccessCard from 'smu-ui-components/SuccessCard';
import { injectIntl } from 'react-intl';

// @app
import { CHANGE_LEADER_SUCCESS_MODAL } from 'betterme-components/constants/modalTypes';
import { promotionsMessages } from 'common/messages'

// @own
import messages from '../messages';

const ChangeLeaderSuccessModal = ({
  close,
  intl: { formatMessage },
  user,
}) => (
  <SuccessCard
    className="change-leader-success-modal"
    footerMessage={formatMessage(messages.ChangeLeadersRequestFooterMessage, {
      collaborator: user,
    })}
    message={formatMessage(promotionsMessages.PromotionsWellDone)}
    onCloseClick={close}
    solid
  />
);

ChangeLeaderSuccessModal.propTypes = {
  close: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const modalStyles = { maxWidth: '600px' };
const centerModalClass = 'confirmation-modal__wrapper';

export default RootModal(
  injectIntl(ChangeLeaderSuccessModal),
  CHANGE_LEADER_SUCCESS_MODAL,
  modalStyles,
  centerModalClass
);
