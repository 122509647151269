import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { getUserLanguage } from 'smu-utils/language';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import { push } from 'react-router-redux';

// @app
import EmptyState from 'components/molecules/EmptyState';
import Assessment from 'components/templates/PotentialAssessment';
import FullScreenSection from 'components/templates/FullScreenSection';
import { userMessages } from 'common/messages';
import {
  selectCommunityConfigurations,
  selectConfigurations,
} from 'betterme-components/Authorization/selectors';
import { selectUserCarousel } from 'betterme-components/services/Carousel/selectors.js';
import { setUserCarousel } from 'betterme-components/services/Carousel/actions';
import {
  assessmentLocation,
  getMyProfileLocation,
  getProfileLocation,
} from 'betterme-components/routes/urls';
import { resetTier } from 'betterme-components/services/Assessment/actions';
import {
  selectAssessmentCollaborators,
  selectAssessmentCollaboratorsLoading,
} from 'betterme-components/services/Assessment/selectors';
import { getPotentialAssessmentExternalURL } from 'betterme-components/routes/urls';
import EmptyStateImage from 'betterme-components/assets/empty-states/feedback-empty-state.svg';

// @own
import './styles.scss';
import messages from './messages';
import {
  trackGoToForm,
  trackLearnMore,
  trackLetsGO,
} from './analytics';

const AssessmentHomePage = ({
  allowsPotentialAssessment,
  assessment,
  assessmentCollaborators,
  assessmentCollaboratorsLoading,
  communityConfig,
  goToCollaboratorProfile,
  goToMyProfile,
  intl: { formatMessage },
  location,
  resetTier,
  session: { user },
  setUserCarousel,
  userCarousel,
}) => {
  const userFirstName = location?.state?.user?.firstName;
  const [isUserSelected, setIsUserSelected] = useState(false);
  const userId = location?.state?.user?.id;
  const locationUser = location?.state?.user;
  const myProfile = locationUser?.id === user?.id || isEmpty(location?.state?.user);
  const locale = getUserLanguage();
  const breadcrumbMessage = locale === 'es'
    ? `${formatMessage(userMessages.UserProfile)} de ${userFirstName}`
    : `${userFirstName}’s ${formatMessage(userMessages.UserProfile)}`;
  const isMyProfile = myProfile
    ? formatMessage(userMessages.UserMyProfile)
    : breadcrumbMessage;
  const youGuessedItFormatted = (
    <FormattedHTMLMessage
      id={messages.PotentialAssessmentYouGuessedIt.id}
      defaultMessage={messages.PotentialAssessmentYouGuessedIt.defaultMessage}
    />
  );
  const linkTextFormatted = (
    <FormattedHTMLMessage
      id={messages.PotentialAssementLinkText.id}
      defaultMessage={messages.PotentialAssementLinkText.defaultMessage}
    />
  );
  const description = {
    carouselDescription: formatMessage(messages.PotentialAssementChooseMember),
    linkText: linkTextFormatted,
    rateBehaviours: formatMessage(messages.PotentialAssessmentRateBehaviours),
    youGuessedIt: youGuessedItFormatted,
  };
  const analytics = { trackGoToForm, trackLearnMore };
  const emptyStateMessages = {
    title: (
      <FormattedHTMLMessage
        id={messages.PotentialAssessmentEmptyStateTitle.id}
        defaultMessage={messages.PotentialAssessmentEmptyStateTitle.defaultMessage}
      />
    ),
    description: (
      <FormattedHTMLMessage
        id={messages.PotentialAssessmentEmptyStateDescription.id}
        defaultMessage={messages.PotentialAssessmentEmptyStateDescription.defaultMessage}
      />
    ),
  };

  function handleNavigateBack() {
    if (myProfile) {
      goToMyProfile();
    } else {
      goToCollaboratorProfile(locationUser?.identification);
    }
  }

  function goToAssessment() {
    assessment(userCarousel?.identification);
    resetTier();
    trackLetsGO();
  };

  useEffect(() => {
    if (!myProfile) {
      if (userCarousel && userCarousel.status) {
        setIsUserSelected(userCarousel.status === 'ENABLED_FOR_EVALUATE' || 'IN_PROGRESS')
      } else if (userCarousel) {
        const collaborator = assessmentCollaborators.find(collaborator => collaborator.email === userCarousel);
        setIsUserSelected(collaborator?.status === 'ENABLED_FOR_EVALUATE' || 'IN_PROGRESS')
      }
    }
  }, [userCarousel])



  const actionButtons = [
    {
      color: "assessment-blue",
      disabled: !isUserSelected,
      label: formatMessage(messages.PotentialAssessmentButton),
      onClick: () => goToAssessment(),
    },
  ];

  const showPotentialAssessment = allowsPotentialAssessment
    && (assessmentCollaborators.length > 0);

  const googleFormLink = communityConfig?.potentialAssessment?.requestUrl;

  return (
    <>
      {showPotentialAssessment ? (
        <FullScreenSection
          className="assessment-home-page"
          actionButtons={actionButtons}
          headerValues={[
            isMyProfile,
            formatMessage(messages.PotentialAssessmentTitle),
          ]}
          loading={assessmentCollaboratorsLoading}
          navigateBack={() => handleNavigateBack()}
          showButtons
        >
          <Assessment
            analytics={analytics}
            className="assessment-home-page__content"
            collaborators={assessmentCollaborators}
            description={description}
            externalURL={getPotentialAssessmentExternalURL()}
            externalURLText={formatMessage(messages.PotentialAssessmentLink)}
            googleFormLink={googleFormLink}
            title={formatMessage(messages.PotentialAssessmentTitle)}
            userId={userId}
            setIsUserSelected={setIsUserSelected}
          />
        </FullScreenSection>
      ) : (
        <div className="assessment-home-page__empty-state">
          <EmptyState
            image={EmptyStateImage}
            title={emptyStateMessages.title}
            description={emptyStateMessages.description}
            direction="column"
          />
        </div>
      )}
    </>
  );
};

AssessmentHomePage.propTypes = {
  assessment: PropTypes.func,
  intl: PropTypes.object,
};

const mapStateToProps = (state) => ({
  allowsPotentialAssessment: selectConfigurations('allowsPotentialAssessment')(state),
  assessmentCollaborators: selectAssessmentCollaborators(state),
  assessmentCollaboratorsLoading: selectAssessmentCollaboratorsLoading(state),
  communityConfig: selectCommunityConfigurations(state),
  userCarousel: selectUserCarousel(state),
});

const mapDispatchToProps = {
  assessment: (userIdentification) => push(assessmentLocation(userIdentification)),
  goToCollaboratorProfile: (identification) => push(getProfileLocation(identification)),
  goToMyProfile: () => push(getMyProfileLocation()),
  setUserCarousel,
  resetTier,
};

export default connect(mapStateToProps, mapDispatchToProps)
  (injectIntl(AssessmentHomePage));
