// @packages
import { takeLatest, call, put } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';

// @app
import {
  createFeedbackRequestCancel,
  createFeedbackRequestDismiss,
  getFeedbackRequestDismissReasons,
  getFeedbacksRequest as getFeedbacksRequestApi,
  getFeedbacksRequestsReceived,
  getFeedbacksRequestsSent,
} from '../api';

// @own
import {
  createFeedbackRequestCancelFail,
  createFeedbackRequestCancelSuccess,
  createFeedbackRequestDismissFail,
  createFeedbackRequestDismissSuccess,
  getFeedbackRequestReceived,
  getFeedbackRequestReceivedFail,
  getFeedbackRequestReceivedSuccess,
  getFeedbackRequestSent,
  getFeedbackRequestSentFail,
  getFeedbackRequestSentSuccess,
  getFeedbacksRequestFail,
  getFeedbacksRequestSuccess,
  getRequestDismissReasonsFail,
  getRequestDismissReasonsSuccess,
} from './actions';
import {
  CREATE_FEEDBACK_REQUEST_CANCEL,
  CREATE_FEEDBACK_REQUEST_DISMISS,
  GET_FEEDBACKS_REQUEST,
  GET_FEEDBACKS_REQUESTS_RECEIVED,
  GET_FEEDBACKS_REQUESTS_SENT,
  GET_REQUEST_DISMISS_REASONS,
} from './actionTypes';

function* createFeedbackRequestCancelWorker({ payload }) {
  const {
    messages: { success, fail },
    requestId,
    userId,
  } = payload;

  try {
    const response = yield call(createFeedbackRequestCancel, requestId);
    yield put(createFeedbackRequestCancelSuccess(response));
    if (success) {
      yield put(addToastMessage({ timeout: 3000, message: success }));
    }
    yield put(getFeedbackRequestSent(userId));
  } catch (error) {
    yield put(createFeedbackRequestCancelFail(error.message));
    yield put(addToastMessage({ timeout: 3000, message: error.message || fail }));
  }
}

function* createFeedbackRequestDismissWorker({ payload }) {
  const {
    messages: { success, fail },
    reasonId,
    requestId,
    userId,
    refreshParams,
  } = payload;
  try {
    const response = yield call(createFeedbackRequestDismiss, requestId, { reasonId });
    yield put(createFeedbackRequestDismissSuccess(response));
    if (success) {
      yield put(addToastMessage({ timeout: 3000, message: success }));
    }
    yield put(getFeedbackRequestReceived(userId, refreshParams));
  } catch (error) {
    yield put(createFeedbackRequestDismissFail(error.message));
    yield put(addToastMessage({ timeout: 3000, message: error.message || fail }));
  }
}

function* getFeedbackRequestWorker({ payload }) {
  try {
    const response = yield call(getFeedbacksRequestApi, payload.params);
    yield put(getFeedbacksRequestSuccess(response));
  } catch (error) {
    yield put(getFeedbacksRequestFail(error.message));
  }
}

function* getRequestDismissReasonsWorker() {
  try {
    const { result } = yield call(getFeedbackRequestDismissReasons);
    yield put(getRequestDismissReasonsSuccess(result));
  } catch (error) {
    yield put(getRequestDismissReasonsFail(error.message));
  }
}

function* getFeedbacksRequestsReceivedWorker({ payload }) {
  const params = {
    pageSize: 20,
    replied: false,
    ...payload
  };
  try {
    const response = yield call(getFeedbacksRequestsReceived, params);
    yield put(getFeedbackRequestReceivedSuccess(response));
  } catch (error) {
    yield put(getFeedbackRequestReceivedFail(error.message));
  }
}

function* getFeedbacksRequestsSentWorker({ payload }) {
  const params = {
    pageSize: 20,
    replied: false,
    ...payload
  };
  try {
    const response = yield call(getFeedbacksRequestsSent, params);
    yield put(getFeedbackRequestSentSuccess(response));
  } catch (error) {
    yield put(getFeedbackRequestSentFail(error.message));
  }
}

export default function* feedbackRequestWatcher() {
  yield takeLatest(CREATE_FEEDBACK_REQUEST_CANCEL, createFeedbackRequestCancelWorker);
  yield takeLatest(CREATE_FEEDBACK_REQUEST_DISMISS, createFeedbackRequestDismissWorker);
  yield takeLatest(GET_FEEDBACKS_REQUEST, getFeedbackRequestWorker);
  yield takeLatest(GET_FEEDBACKS_REQUESTS_RECEIVED, getFeedbacksRequestsReceivedWorker);
  yield takeLatest(GET_FEEDBACKS_REQUESTS_SENT, getFeedbacksRequestsSentWorker);
  yield takeLatest(GET_REQUEST_DISMISS_REASONS, getRequestDismissReasonsWorker);
}
