// @packages
import { push } from 'react-router-redux';
import { takeLatest, call, put } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import { closeModal } from 'smu-app-components/RootModal/actions';

// @app
import { searchUsers } from 'betterme-components/api';
import { removeLeader } from 'betterme-components/services/RemoveLeader/actions';
import { removeLocalStoredItem } from 'utils/localStorage';
import { ACTION_TYPE_REQUIRED } from 'components/common/constants';

// @own
import {
  getLeadersFail,
  getLeadersSuccess,
  resetChangeLeaderRequestState,
  updateUserLeaderFail,
  updateUserLeaderSuccess,
} from './actions';
import { GET_LEADERS, UPDATE_USER_LEADER } from './actionTypes';
import { sendLeadershipRequest } from './api';
import { REMOVE_LEADER_SUGGEST_LEADER } from './constants';

function* getLeadersWorker({ payload }) {
  const { query } = payload;
  const pageSize = 20;

  try {
    const response = yield call(searchUsers, query, pageSize);
    yield put(getLeadersSuccess(response));
  } catch (error) {
    yield put(getLeadersFail(error.message));
    yield put(addToastMessage({ message: error.message, timeout: 3000 }));
  }
}

function* updateUserLeaderWorker({ payload }) {
  const {
    actionTypeRequeried,
    collaborator,
    comment,
    leader,
    successMessage,
    suggestionId,
    to,
    toastMessages: { success, fail },
    type,
  } = payload;
  const data = {
    comment,
    type,
    suggestionId,
  };

  try {
    const response = yield call(sendLeadershipRequest, leader, collaborator.id, data);
    yield put(updateUserLeaderSuccess(response));
    yield put(resetChangeLeaderRequestState());
    yield put(closeModal());
    yield put(addToastMessage({ message: success, timeout: 3000 }));
    if (actionTypeRequeried?.actionType === REMOVE_LEADER_SUGGEST_LEADER) {
      yield put(removeLeader({
        collaborator,
        leaderType: actionTypeRequeried?.reasonType,
        type: actionTypeRequeried?.leaderType,
        reasonId: actionTypeRequeried?.reasonId,
        successMessage,
      }));
      removeLocalStoredItem(ACTION_TYPE_REQUIRED);
    };
    yield put(push(to));
  } catch (error) {
    yield put(updateUserLeaderFail(error.message));
    yield put(resetChangeLeaderRequestState());
    yield put(addToastMessage({ message: error.message || fail, timeout: 3000 }));
  }
}

export default function* teamInformationWatcher() {
  yield takeLatest(GET_LEADERS, getLeadersWorker);
  yield takeLatest(UPDATE_USER_LEADER, updateUserLeaderWorker);
}
