export default function milisecondsToMMSS(miliseconds) {
  if (!miliseconds) {
    return '-- : --';
  }

  let truncatedMoment = Math.trunc(miliseconds / 1000);
  let minutes = Math.floor(truncatedMoment % 3600 / 60);
  let seconds = Math.floor(truncatedMoment % 3600 % 60);

  return ('0' + minutes).slice(-2) + ":" + ('0' + seconds).slice(-2);
}
