export const getLocalCTA = () => {
  try {
    const data = localStorage?.getItem('callToActions');
    const parse = data ? JSON.parse(data) : {};
    return parse;
  } catch {
    return {};
  }
};

export const getIsClickedCTA = (key) => {
  try {
    const data = getLocalCTA();
    return !!data?.[key]?.clicked;
  } catch {
    return false;
  }
};

export const updateIsClickedCTA = (key) => {
  try {
    const data = getLocalCTA();
    data[key] = {
      clicked: true,
    };
    localStorage.setItem('callToActions', JSON.stringify(data));
    return true;
  } catch {
    return false;
  }
};
