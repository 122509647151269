import {
  CREATE_SUGGESTED_ACTIONS,
  CREATE_SUGGESTED_ACTIONS_FAIL,
  CREATE_SUGGESTED_ACTIONS_SUCCESS,
  GET_SUGGESTED_ACTIONS,
  GET_SUGGESTED_ACTIONS_FAIL,
  GET_SUGGESTED_ACTIONS_SUCCESS,
} from './actionTypes';

const initialState = {
  error: undefined,
  loadingSuggestions: false,
  suggestedActions: [],
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case CREATE_SUGGESTED_ACTIONS:
      return {  ...state, loadingCreation: true };
    case CREATE_SUGGESTED_ACTIONS_FAIL:
      return {  ...state, loadingCreation: false };
    case CREATE_SUGGESTED_ACTIONS_SUCCESS:
      return {  ...state, loadingCreation: false };
    case GET_SUGGESTED_ACTIONS:
      return { ...state, loadingSuggestions: true, error: undefined };
      case GET_SUGGESTED_ACTIONS_FAIL:
      return { ...state, loadingSuggestions: false, error: payload.error };
    case GET_SUGGESTED_ACTIONS_SUCCESS:
      return { ...state, loadingSuggestions: false, suggestedActions: payload.response };
    default:
      return state;
  }
}
