import {
  REQUEST_FEED,
  REQUEST_FEED_SUCCESS,
  REQUEST_FEED_FAIL,
  REQUEST_COMMENTS_SUCCESS,
  SEND_COMMENT,
  SEND_COMMENT_SUCCESS,
  SEND_COMMENT_FAIL
} from './actionTypes';

const initialState = {
  error: '',
  feedbacks: [],
  fetching: false,
  page: {},
  request: null,
};

function feedbackReducer(state, { type, payload }) {
  switch (type) {
    case REQUEST_COMMENTS_SUCCESS:
      return { ...state, comments: payload };
    case SEND_COMMENT:
      return { ...state, sendingMessage: true };
    case SEND_COMMENT_SUCCESS:
    case SEND_COMMENT_FAIL:
      return { ...state, sendingMessage: false };
    default:
      return state;
  }
}

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case REQUEST_FEED:
      return { ...state, fetching: true, request: payload };
    case REQUEST_FEED_SUCCESS: {
      const { result, page, pageNumber } = payload;
      return {
        ...state,
        fetching: false,
        feedbacks: pageNumber ? [...state.feedbacks, ...result] : result,
        page,
      };
    }
    case REQUEST_FEED_FAIL:
      return { ...state, fetching: false, feedbacks: [] };
    case REQUEST_COMMENTS_SUCCESS:
    case SEND_COMMENT:
    case SEND_COMMENT_SUCCESS:
    case SEND_COMMENT_FAIL:
      return { ...state, feedbacks: state.feedbacks.map(feedback => (feedback.id === payload.feedbackId) ? feedbackReducer(feedback, { type, payload: payload.messages }) : feedback) };
    default:
      return state;
  }
}
