// @packages
import React from 'react';
import Dropdown from 'smu-ui-components/Dropdown';
import PropTypes from 'prop-types';

// @app
import { OPENED_STATUS } from 'betterme-components/Goals/constants';

// @own
import './styles.scss';

const GoalOverviewFilters = ({
  dropdownOptions,
  dropdownValue,
  dueDate,
  onChange,
  score,
  weight,
}) => (
  <div className="goal-overview__filters">
    <div className="goal-overview__dropdown-wrapper">
      <Dropdown
        className="goal-overview__dropdown"
        onChange={(value) => onChange(value)}
        options={dropdownOptions}
        value={dropdownValue}
        simple
      />
    </div>
    <div className="goal-overview__weight-col goal-overview__col">{weight}</div>
    <div className="goal-overview__score-col goal-overview__col">
      {dropdownValue === OPENED_STATUS ? dueDate : score}
    </div>
  </div>
);

GoalOverviewFilters.propTypes = {
  dropdownOptions: PropTypes.array,
  dropdownValue: PropTypes.string,
  dueDate: PropTypes.string,
  onChange: PropTypes.func,
  score: PropTypes.number,
  weight: PropTypes.number,
};

export default GoalOverviewFilters;
