// @packages
import { takeLatest, call, put } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import { push } from 'react-router-redux';
import { base64Decode } from 'smu-utils/base64';

// @app
import {
  CREATE_PROMOTION_REQUEST,
  DELETE_PROMOTIONS_FILE,
  GET_POSITIONS_HISTORY,
  GET_PROMOTION_COLLABORATOR,
  GET_PROMOTION_SUGGESTED_VALIDATORS,
  GET_PROMOTIONS_LEVEL,
  GET_USER_PROMOTION_REQUESTS,
  UPLOAD_PROMOTIONS_FILE,
} from './actionTypes';
import {
  createPromotionRequestFail,
  createPromotionRequestSuccess,
  deleteFileFail,
  deleteFileSuccess,
  getPositionsHistory,
  getPositionsHistoryFail,
  getPositionsHistorySuccess,
  getPromotionCollaboratorFail,
  getPromotionCollaboratorSuccess,
  getPromotionSuggestedValidatorsFail,
  getPromotionSuggestedValidatorsSuccess,
  getPromotionsLevel,
  getPromotionsLevelFail,
  getPromotionsLevelSuccess,
  getUserPromotionRequestsFail,
  getUserPromotionRequestsSuccess,
  uploadPromotionsFileFail,
  uploadPromotionsFileSuccess,
} from './actions';
import {
  createPromotionRequest,
  deleteFile,
  getProfile,
  getPositionsHistory as getPositionsHistoryService,
  getPromotionsLevel as getPromotionsLevelService,
  getUserPromotionRequests,
  getPromotionSuggestedValidators,
  uploadFile,
} from '../api';
import {
  getMyTeamPromotionsLocation,
  getNotificationHistoryLocation,
} from 'betterme-components/routes/urls';

function* getPromotionsLevelWorker({ payload: { userId, message } }) {
  try {
    const response = yield call(getPromotionsLevelService, { userId });
    if (response.length === 0 && message) {
      yield put(addToastMessage({ message, timeout: 8000 }));
    }
    yield put(getPromotionsLevelSuccess(response));
  } catch (error) {
    yield put(getPromotionsLevelFail(error.message));
    yield put(addToastMessage({ message: error.message, timeout: 3000 }));
  }
}

function* getPositionsHistoryWorker({ payload: { userId } }) {
  try {
    const response = yield call(getPositionsHistoryService, { userId });
    yield put(getPositionsHistorySuccess(response));
  } catch (error) {
    yield put(getPositionsHistoryFail(error.message));
    yield put(addToastMessage({ message: error.message, timeout: 3000 }));
  }
}

function* uploadPromotionsFileWorker({ payload: { file } }) {
  try {
    const response = yield call(uploadFile, file);
    yield put(uploadPromotionsFileSuccess(response.result));
  } catch (error) {
    yield put(uploadPromotionsFileFail(error.message));
    yield put(addToastMessage({ message: error.message, timeout: 3000 }));
  }
}

function* deleteFileWorker({ payload: { id } }) {
  try {
    yield call(deleteFile, id);
    yield put(deleteFileSuccess(id));
  } catch (error) {
    yield put(deleteFileFail(error.message));
    yield put(addToastMessage({ message: error.message, timeout: 3000 }));
  }
}

function* createPromotionRequestWorker({ payload }) {
  const goToMyTeamPromotions = () => push(getMyTeamPromotionsLocation());
  const goToMyNotificationHistory = () => push(getNotificationHistoryLocation());
  const {
    data,
    messages: toastMessage,
    promotionStatus,
  } = payload;

  try {
    yield call(createPromotionRequest, data);
    yield put(createPromotionRequestSuccess());
    yield put(addToastMessage({ message: toastMessage, timeout: 3000 }));
    if (promotionStatus === 'MANAGER_REVIEW') {
      yield put(goToMyNotificationHistory());
    } else {
      yield put(goToMyTeamPromotions());
    }
  } catch (error) {
    yield put(createPromotionRequestFail(error.message));
    yield put(addToastMessage({ message: error.message, timeout: 3000 }));
  }
}

function* getPromotionCollaboratorWorker({ payload: { identification, messages } }) {
  try {
    const response = yield call(getProfile, base64Decode(identification));
    yield put(getPromotionCollaboratorSuccess(response));
    yield put(getPromotionsLevel(response.id, messages.lastLevel));
    yield put(getPositionsHistory(response.id));
  } catch (error) {
    yield put(getPromotionCollaboratorFail(error.message));
    yield put(addToastMessage({ message: error.message, timeout: 3000 }));
  }
}

function* getUserPromotionRequestsWorker({ payload: { data } }) {
  try {
    const response = yield call(getUserPromotionRequests, data);
    yield put(getUserPromotionRequestsSuccess(response.result));
  } catch (error) {
    yield put(getUserPromotionRequestsFail(error.message));
    yield put(addToastMessage({ message: error.message, timeout: 3000 }));
  }
}

function* getPromotionSuggestedValidatorsWorker({ payload: { data } }) {
  try {
    const response = yield call(getPromotionSuggestedValidators, data);
    yield put(getPromotionSuggestedValidatorsSuccess(response.result));
  } catch (error) {
    yield put(getPromotionSuggestedValidatorsFail(error.message));
    yield put(addToastMessage({ message: error.message, timeout: 3000 }));
  }
}

export default function* suggestPromotionWatcher() {
  yield takeLatest(GET_PROMOTIONS_LEVEL, getPromotionsLevelWorker);
  yield takeLatest(GET_POSITIONS_HISTORY, getPositionsHistoryWorker);
  yield takeLatest(UPLOAD_PROMOTIONS_FILE, uploadPromotionsFileWorker);
  yield takeLatest(DELETE_PROMOTIONS_FILE, deleteFileWorker);
  yield takeLatest(CREATE_PROMOTION_REQUEST, createPromotionRequestWorker);
  yield takeLatest(GET_PROMOTION_COLLABORATOR, getPromotionCollaboratorWorker);
  yield takeLatest(GET_USER_PROMOTION_REQUESTS, getUserPromotionRequestsWorker);
  yield takeLatest(GET_PROMOTION_SUGGESTED_VALIDATORS, getPromotionSuggestedValidatorsWorker);
}
