// @packages
import React from 'react';

// @app
import MyTeamApp from 'betterme-components/MyTeam';
import MyTeamMenu from 'betterme-components/MyTeamMenu';
import TeamFeedbacksTab from 'betterme-components/Team/TeamFeedbacksTab';

// @own
import MyTeamLayout from '../';

function MyTeamFeedbacksLayout(props) {
  return (
    <MyTeamLayout>
      <MyTeamMenu className="py-8 flex-1" path="/myteam/feedbacks">
        <MyTeamApp {...props}>
          <TeamFeedbacksTab />
        </MyTeamApp>
      </MyTeamMenu>
    </MyTeamLayout>
  );
}

export default MyTeamFeedbacksLayout;
