// @own
import {
  ASSESSMENT_EDIT_ANSWER,
  ASSESSMENT_SEND_ANSWER,
  GET_ASSESSMENT_COLLABORATORS,
  GET_ASSESSMENT_COLLABORATORS_FAIL,
  GET_ASSESSMENT_COLLABORATORS_SUCCESS,
  GET_ASSESSMENT_QUESTIONS,
  GET_ASSESSMENT_QUESTIONS_FAIL,
  GET_ASSESSMENT_QUESTIONS_SUCCESS,
  GET_ASSESSMENT_RATINGS,
  GET_ASSESSMENT_RATINGS_FAIL,
  GET_ASSESSMENT_RATINGS_SUCCESS,
  GET_ASSESSMENT_SCORE,
  GET_ASSESSMENT_SCORE_FAIL,
  GET_ASSESSMENT_SCORE_SUCCESS,
  GET_ASSESSMENT_TIER,
  GET_ASSESSMENT_TIER_FAIL,
  GET_ASSESSMENT_TIER_SUCCESS,
  RESET_ASSESSMENT,
  RESET_TIER,
  SEND_ASSESSMENT_RESULTS,
  SEND_ASSESSMENT_RESULTS_FAIL,
  SEND_ASSESSMENT_RESULTS_SUCCESS,
  SEND_ASSESSMENT_SUMMARY,
  SEND_ASSESSMENT_SUMMARY_FAIL,
  SEND_ASSESSMENT_SUMMARY_SUCCESS,
} from './actionTypes';

export function getAssessmentRatings(payload) {
  return {
    type: GET_ASSESSMENT_RATINGS,
    payload,
  };
}

export function getAssessmentRatingsFail(error) {
  return {
    type: GET_ASSESSMENT_RATINGS_FAIL,
    payload: { error },
  };
}

export function getAssessmentRatingsSuccess(response) {
  return {
    type: GET_ASSESSMENT_RATINGS_SUCCESS,
    payload: { response },
  };
}

export function getAssessmentTier(payload) {
  return {
    type: GET_ASSESSMENT_TIER,
    payload,
  };
}

export function getAssessmentTierFail(error) {
  return {
    type: GET_ASSESSMENT_TIER_FAIL,
    payload: { error },
  };
}

export function getAssessmentTierSuccess(response) {
  return {
    type: GET_ASSESSMENT_TIER_SUCCESS,
    payload: { response },
  };
}

export function getAssessmentQuestions(payload) {
  return {
    type: GET_ASSESSMENT_QUESTIONS,
    payload,
  };
}

export function getAssessmentQuestionsSuccess(response) {
  return {
    type: GET_ASSESSMENT_QUESTIONS_SUCCESS,
    payload: response,
  };
}

export function getAssessmentQuestionsFail(error) {
  return {
    type: GET_ASSESSMENT_QUESTIONS_FAIL,
    payload: { error },
  };
}


export function setAssessmentAnswers(payload) {
  return {
    type: ASSESSMENT_EDIT_ANSWER,
    payload,
  }
}

export function sendAssessmentAnswers(payload) {
  return {
    type: ASSESSMENT_SEND_ANSWER,
    payload,
  }
}

export function resetTier(payload) {
  return {
    type: RESET_TIER,
    payload,
  }
}

export function resetAssessment(payload) {
  return {
    type: RESET_ASSESSMENT,
    payload,
  }
}

export function getAssessmentCollaborators() {
  return {
    type: GET_ASSESSMENT_COLLABORATORS,
  }
}

export function getAssessmentCollaboratorsFail(error) {
  return {
    type: GET_ASSESSMENT_COLLABORATORS_FAIL,
    payload: { error },
  };
}

export function getAssessmentCollaboratorsSuccess(response) {
  return {
    type: GET_ASSESSMENT_COLLABORATORS_SUCCESS,
    payload: response,
  }
}

export function sendAssessmentSummary(payload) {
  return {
    type: SEND_ASSESSMENT_SUMMARY,
    payload,
  };
}

export function sendAssessmentSummaryFail(error) {
  return {
    type: SEND_ASSESSMENT_SUMMARY_FAIL,
    payload: { error },
  };
}

export function sendAssessmentSummarySuccess(response) {
  return {
    type: SEND_ASSESSMENT_SUMMARY_SUCCESS,
    payload: { response },
  };
}

export function sendAssessmentResults(payload) {
  return {
    type: SEND_ASSESSMENT_RESULTS,
    payload,
  };
}

export function sendAssessmentResultsFail(error) {
  return {
    type: SEND_ASSESSMENT_RESULTS_FAIL,
    payload: { error },
  };
}

export function sendAssessmentResultsSuccess(response) {
  return {
    type: SEND_ASSESSMENT_RESULTS_SUCCESS,
    payload: { response },
  };
}

export function getAssessmentScore(payload) {
  return {
    type: GET_ASSESSMENT_SCORE,
    payload,
  };
}

export function getAssessmentScoreFail(error) {
  return {
    type: GET_ASSESSMENT_SCORE_FAIL,
    payload: { error },
  };
}

export function getAssessmentScoreSuccess(response) {
  return {
    type: GET_ASSESSMENT_SCORE_SUCCESS,
    payload: { response },
  };
}
