import { defineMessages } from 'react-intl';

export default defineMessages({
  BehaviourSectionTitle: {
    id: 'Betterme.BehaviourSection.Title',
    defaultMessage: 'Behavioral Assessment',
  },
  BehaviourSectionDescription: {
    id: 'Betterme.BehaviourSection.Description',
    defaultMessage: 'Please complete the frequency level you’ve observed for the behaviors, taking into account their consistency and development during the analyzed period.',
  },
  BehaviourSectionSelect: {
    id: 'Betterme.BehaviourSection.Select',
    defaultMessage: 'Select frequency',
  },
  BehaviourSectionBehaviour: {
    id: 'Betterme.BehaviourSection.Behaviour',
    defaultMessage: 'Behavior',
  },
  BehaviourSectionResponse: {
    id: 'Betterme.BehaviourSection.Response',
    defaultMessage: 'Response',
  },
  PerformanceRetrospectiveNew: {
    id: 'Betterme.Evaluations.CoCreatorNew',
    defaultMessage: 'New',
  },
  BehavioralAssessmentSlideOneTitle: {
    id: 'Betterme.PerformanceRetrospective.BehavioralAssessmentSlideOneTitle',
    defaultMessage: 'Check it out!',
  },
  BehavioralAssessmentSlideTwoTitle: {
    id: 'Betterme.PerformanceRetrospective.BehavioralAssessmentSlideTwoTitle',
    defaultMessage: 'Performance Behaviors',
  },
  BehavioralAssessmentSlideOneText: {
    id: 'Betterme.PerformanceRetrospective.BehavioralAssessmentSlideOneText',
    defaultMessage: 'These behaviors, aligned with our Talent Manifesto competencies, establish general expectations for an effective performance at Globant',
  },
  BehavioralAssessmentSlideTwoText: {
    id: 'Betterme.PerformanceRetrospective.BehavioralAssessmentSlideTwoText',
    defaultMessage: 'The behaviors are tailored to different tiers of seniority, taking into account the complexity that can be effectively managed within each tier. Enter <a style={style} href={link} target="_blank">here</a> for more details.',
  },
  BehavioralAssessmentSuggestedBy: {
    id: 'Betterme.Evaluations.CoEvaluatorScore',
    defaultMessage: 'Suggested by {username}',
  },
  BehaviourSectionSelfRetrospectiveDescription: {
    id: 'Betterme.BehaviourSection.SelfRetrospective.Description',
    defaultMessage: 'In order to guide the Performance Retrospective for the established period and role, please complete the frequency level you have observed for each one, based on the consistency and maturity of the behavior.',
  },
  BehavioralAssessmentSelfRetrospectiveSlideOneText: {
    id: 'Betterme.BehaviourSection.SelfRetrospective.SlideOneText',
    defaultMessage: 'These behaviors, aligned with our Talent Manifesto competencies, establish general expectations for an effective performance at Globant.',
  },
  BehavioralAssessmentSelfRetrospectiveSlideTwoText: {
    id: 'Betterme.BehaviourSection.SelfRetrospective.SlideTwoText',
    defaultMessage: 'The behaviours are tailored to different tiers of seniority, taking into account the complexity that can be effectively managed within each tier. Enter <a style={style} href={link} target="_blank">here</a> for more details.',
  },
  BehaviourSectionMismatch: {
    id: 'Betterme.BehaviourSection.Mismatch',
    defaultMessage: 'Mismatch in behavioral score',
  },
});
