import { defineMessages } from 'react-intl';

export default defineMessages({
  q1: {
    id: 'Betterme.SuggestedPromotions.Q1',
    defaultMessage: "Please provide the technical skills your colleague has demonstrated that make you believe they are prepared for the next seniority level",
  },
  q2: {
    id: 'Betterme.SuggestedPromotions.Q2',
    defaultMessage: `Please highlight some actions or behaviors your colleague has shown that make you believe they have overachieved expectations for their seniority level and are ready for the next step`,
  },
  comments: {
    id: 'Betterme.SuggestedPromotions.comments',
    defaultMessage: "Comments",
  },
  discoveryTrigger: {
    id: 'Betterme.SuggestedPromotions.discoveryTrigger',
    defaultMessage: "New",
  },
  discoveryTitle: {
    id: 'Betterme.SuggestedPromotions.discoveryTitle',
    defaultMessage: "Suggest a promotion",
  },
  discoveryText: {
    id: 'Betterme.SuggestedPromotions.discoveryText',
    defaultMessage: "Now you can help boost your colleague's career by suggesting a promotion for them. Your contribution is valuable to leaders.",
  },
  seniorityLevel: {
    id: 'Betterme.SuggestedPromotions.seniorityLevel',
    defaultMessage: "{name}'s current seniority level: {currentSeniority}",
  },
  checkBoxSuggested: {
    id: 'Betterme.SuggestedPromotions.checkBoxSuggested',
    defaultMessage: "Would you like to suggest a promotion for this POD member?",
  },
  warningMessage: {
    id: 'Betterme.SuggestedPromotions.warningMessage',
    defaultMessage: "Only the current Experience Leaders will receive your suggestion",
  },
  promotionMessage: {
    id: 'Betterme.SuggestedPromotions.promotionMessage',
    defaultMessage: "Please answer all the questions below in order to suggest a promotion",
  },
});