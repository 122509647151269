import {
  GET_PROMOTION_REQUESTS_HISTORY,
  GET_PROMOTION_REQUESTS_HISTORY_FAIL,
  GET_PROMOTION_REQUESTS_HISTORY_SUCCESS,
  RESET_PROMOTION_REQUESTS_HISTORY,
} from './actionTypes';

const initialState = {
  error: null,
  loadingPromotionRequestsHistory: false,
  page: {
    number: 0,
    numberOfElements: 0,
    size: 0,
    totalElements: 0,
    totalPages: 0,
  },
  promotionRequestsHistory: [],
};

export default function reducer(state = initialState, { type, payload }) {
  switch(type) {
    case GET_PROMOTION_REQUESTS_HISTORY:
      return { ...state, loadingPromotionRequestsHistory: true };

    case GET_PROMOTION_REQUESTS_HISTORY_FAIL:
        return {
          ...state,
          error: payload.error,
          loadingPromotionRequestsHistory: false,
        }

    case GET_PROMOTION_REQUESTS_HISTORY_SUCCESS:
      return {
        ...state,
        loadingPromotionRequestsHistory: false,
        page: { ...payload.page },
        promotionRequestsHistory: [...payload.response],
      }
    case RESET_PROMOTION_REQUESTS_HISTORY:
      return { ...initialState };

    default:
      return state;
  }
}
