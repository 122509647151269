import {
  GET_USER_PROMOTION_REQUESTS_GROUP,
  GET_USER_PROMOTION_REQUESTS_GROUP_FAIL,
  GET_USER_PROMOTION_REQUESTS_GROUP_SUCCESS,
} from './actionTypes';

export function getUserPromotionRequestsGroup(promotionParentId, ordering) {
  return {
    type: GET_USER_PROMOTION_REQUESTS_GROUP,
    payload: { promotionParentId, ordering },
  };
}

export function getUserPromotionRequestsGroupFail(error) {
  return {
    type: GET_USER_PROMOTION_REQUESTS_GROUP_FAIL,
    payload: { error },
  };
}

export function getUserPromotionRequestsGroupSuccess(response) {
  return {
    type: GET_USER_PROMOTION_REQUESTS_GROUP_SUCCESS,
    payload: { response },
  };
}
