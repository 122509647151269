// @packages
import React from 'react';
import { Link } from 'react-router';
import { getWebUrl } from 'smu-utils/globalRequestHelper';

// @app
import { getModalOnboardingLocation } from 'betterme-components/routes/urls';

export const getUserMenuLinks = ({
  enabledStarMeUp,
  intercomEnabled,
  livingValuesTexts,
  logout,
  onboardingEnabled,
  openModalSuggestions,
}) => {
  const SMU_URL = getWebUrl();

  const renderLink = ({ href, ...rest }, children) => (
    <Link to={href} {...rest}>
      {children}
    </Link>
  );

  return {
    settings: {
      href: '/settings/account',
      renderLink,
      show: true,
    },
    livingValues: {
      href: `${SMU_URL}/home?modal=initiatives`,
      show: false,
      children: livingValuesTexts?.menuTitle,
    },
    quickTour: {
      href: `${getModalOnboardingLocation()?.pathname}${
        getModalOnboardingLocation().search
      }`,
      renderLink,
      show: enabledStarMeUp && onboardingEnabled,
    },
    whatsNew: {
      ...intercomEnabled
        ? {
          onClick: () => window.Intercom && window.Intercom('showSpace', 'news'),
        } : {
          href: 'https://news.intercom.com/starmeupnews',
          target: '_blank',
        },
    },
    suggestions: {
      onClick: openModalSuggestions,
    },
    helpCenter: {
      ...intercomEnabled
      ? {
        href: undefined,
        onClick: () => window.Intercom && window.Intercom('showSpace', 'help'),
      } : {
        target: '_blank',
        url: ({ language }) => (
          `https://customerportal.starmeup.com/${language === 'es' ? 'es' : 'en'}`
        ),
      },
    },
    logout: {
      onClick: logout,
    },
  };
};

export const getHeaderLink = ({
  enabledPerformance,
  enabledStarMeUp,
  enabledSurveysOnly,
}) => {
  if (enabledStarMeUp) {
    return getWebUrl();
  }

  if (!enabledPerformance && enabledSurveysOnly) {
    return '/questionnaires';
  }

  return '/myprofile';
};
