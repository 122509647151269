// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'smu-ui-components/IconV2';
import Link from 'smu-ui-components/Link';

// @own
import './styles.scss';

const RateInfo = ({
  className,
  description,
  icon,
  link,
  title,
}) => {
  const iconComponent = () => (
    <Icon
      className="rate-info__icon"
      color="icon-black"
      icon={icon}
      size="small"
    />
  );
  const descriptionComponent = description => link ?
    <Link className="rate-info__description rate-info__description--link" to={link} >
      {description}
    </Link> :
    <div className="rate-info__description" to={link}>
      {description}
    </div>;

  return (
    <div className={cn('rate-info', className)}>
      <div className="rate-info__title">
        {title}
        {icon && link ? (
          <Link to={link}>
            {iconComponent()}
          </Link>
        ) : icon && (
          iconComponent()
        )}
      </div>
      {descriptionComponent(description)}
    </div>
  );
};

RateInfo.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.string,
  link: PropTypes.object,
  onIconClick: PropTypes.func,
  title: PropTypes.string,
  underlineDescription: PropTypes.bool,
};

export default RateInfo;
