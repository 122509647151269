import { defineMessages } from 'react-intl';

export default defineMessages({
  FeedbackEvaluationsTabsReceived: {
    id: 'Betterme.Evaluations.TabsReceived',
    defaultMessage: 'My Evaluations',
  },
  FeedbackEvaluationsTabsTeam: {
    id: 'Betterme.Evaluations.TabsTeam',
    defaultMessage: 'Team Evaluations',
  },
  FeedbackEvaluationsTeamEvaluationsHistoryTitle: {
    id: 'Betterme.Evaluations.TeamEvaluationsHistoryTitle',
    defaultMessage: 'Completed Evaluations',
  },
  FeedbackEvaluationsTeamEvaluationsItemHistory: {
    id: 'Betterme.Evaluations.TeamEvaluationsItemHistory',
    defaultMessage: 'View History',
  },
  FeedbackEvaluationsTeamEvaluationsItemEdit: {
    id: 'Betterme.Evaluations.TeamEvaluationsItemEdit',
    defaultMessage: 'Edit draft',
  },
  FeedbackEvaluationsEvaluationCreateWeightValidationMsg: {
    id: 'Betterme.Evaluations.EvaluationCreateWeightValidationMsg',
    defaultMessage: 'Goals included in the Evaluation range are outdated',
  },
  FeedbackEvaluationsEvaluationCreateWeightValidationAction: {
    id: 'Betterme.Evaluations.EvaluationCreateWeightValidationAction',
    defaultMessage: 'Go to Goals',
  },
  FeedbackEvaluationsEvaluationCreatePeriodValidationAction: {
    id: 'Betterme.Evaluations.EvaluationCreatePeriodValidationAction',
    defaultMessage: 'There\'s no period assigned to the Community. Contact the Administrator.',
  },
  FeedbackEvaluationsEvaluationCreateAssignedTitle: {
    id: 'Betterme.Evaluations.EvaluationCreateAssignedTitle',
    defaultMessage: 'Colleague being evaluated',
  },
  FeedbackEvaluationsEvaluationCreateInfoTitle: {
    id: 'Betterme.Evaluations.EvaluationCreateInfoTitle',
    defaultMessage: 'Contextual information about this period',
  },
  FeedbackEvaluationsEvaluationCreatePlaceholderScores: {
    id: 'Betterme.Evaluations.EvaluationCreatePlaceholderScores',
    defaultMessage: 'Choose an overall score for the Evaluation',
  },
  FeedbackEvaluationsEvaluationCreatePlaceholderCustomScore: {
    id: 'Betterme.Evaluations.EvaluationCreatePlaceholderCustomScore',
    defaultMessage: 'Select a score',
  },
  FeedbackEvaluationsEvaluationCreatePlaceholderComment: {
    id: 'Betterme.Evaluations.EvaluationCreatePlaceholderComment',
    defaultMessage: 'Leave a message about their performance for this period',
  },
  FeedbackEvaluationsEvaluationCreatePlaceholderSelfComment: {
    id: 'Betterme.Evaluations.EvaluationCreatePlaceholderSelfComment',
    defaultMessage: 'Leave a message about your performance for this period',
  },
  FeedbackEvaluationsEvaluationCreateFinalScore: {
    id: 'Betterme.Evaluations.EvaluationCreateFinalScore',
    defaultMessage: 'Goals Final Score:',
  },
  FeedbackEvaluationsEvaluationFeedbackForLeaders: {
    id: 'Betterme.Evaluations.FeedbackForLeadersTitle',
    defaultMessage: 'Feedback for leaders',
  },
  FeedbackEvaluationsEvaluationCreateNineBoxDisclaimer: {
    id: 'Betterme.Evaluations.EvaluationCreateNineBoxDisclaimer',
    defaultMessage: '{username} won\'t see these results',
  },
  FeedbackEvaluationsEvaluationCreateSuggestedScoreDisclaimerBold: {
    id: 'Betterme.Evaluations.EvaluationCreateSuggestedScoreDisclaimerBold',
    defaultMessage: '{username} won\'t see these suggestions, these are visible only for the evaluator.',
  },
  FeedbackEvaluationsEvaluationCreateSuggestedScoreDisclaimer: {
    id: 'Betterme.Evaluations.EvaluationCreateSuggestedScoreDisclaimer',
    defaultMessage: 'You must suggest a performance score for this period because your lead position',
  },
  FeedbackEvaluationsEvaluationCreateSuggestedScoreCollapsibleTitle: {
    id: 'Betterme.Evaluations.EvaluationCreateSuggestedScoreCollapsibleTitle',
    defaultMessage: 'Suggested Score',
  },
  FeedbackEvaluationsEvaluationCreateSuggestedScoreTitle: {
    id: 'Betterme.Evaluations.EvaluationCreateSuggestedScoreTitle',
    defaultMessage: 'Score Suggested',
  },
  FeedbackEvaluationsEvaluationCreateSuggestedScorePlaceholder: {
    id: 'Betterme.Evaluations.EvaluationCreateSuggestedScorePlaceholder',
    defaultMessage: 'Select suggested score for the evaluation',
  },
  FeedbackEvaluationsEvaluationCreateNineBoxTitle: {
    id: 'Betterme.Evaluations.EvaluationCreateNineBoxTitle',
    defaultMessage: '9 Box score',
  },
  FeedbackEvaluationsEvaluationCreateNineBoxPlaceholder: {
    id: 'Betterme.Evaluations.EvaluationCreateNineBoxPlaceholder',
    defaultMessage: 'Choose a 9 Box score for the Evaluation.',
  },
  FeedbackEvaluationsEvaluationCreateNineBoxCommentPlaceholder: {
    id: 'Betterme.Evaluations.EvaluationCreateNineBoxCommentPlaceholder',
    defaultMessage: 'Leave a comment about the selected 9 Box score',
  },
  FeedbackEvaluationsEvaluationCreateActionsDisclaimer: {
    id: 'Betterme.Evaluations.EvaluationCreateActionsDisclaimer',
    defaultMessage: '{username} won\'t see these suggestions',
  },
  FeedbackEvaluationsEvaluationCreateActionsCommentPlaceholder: {
    id: 'Betterme.Evaluations.EvaluationCreateActionsCommentPlaceholder',
    defaultMessage: 'Leave a comment about the suggested actions',
  },
  FeedbackEvaluationsEvaluationCreateEvaluationPeriod: {
    id: 'Betterme.Evaluations.EvaluationCreateEvaluationPeriod',
    defaultMessage: 'Evaluation Period',
  },
  FeedbackEvaluationsEvaluationCreateEvaluationRange: {
    id: 'Betterme.Evaluations.EvaluationCreateEvaluationRange',
    defaultMessage: 'Evaluation Range',
  },
  FeedbackEvaluationsEvaluationCreateSelfText: {
    id: 'Betterme.Evaluations.EvaluationCreateSelfText',
    defaultMessage: 'The period to create self-evaluations is already open.',
  },
  FeedbackEvaluationsEvaluationCreateSelfButton: {
    id: 'Betterme.Evaluations.EvaluationCreateSelfButton',
    defaultMessage: 'Create self-evaluation',
  },
  FeedbackEvaluationsEvaluationCreateSelfDiscovery: {
    id: 'Betterme.Evaluations.EvaluationCreateSelfDiscovery',
    defaultMessage: 'Create your self-evaluation. After that you will see it in My Evaluations tab.',
  },
  FeedbackEvaluationsEvaluationSuccessMessage: {
    id: 'Betterme.Evaluations.EvaluationSuccessMessage',
    defaultMessage: 'Well done!',
  },
  FeedbackEvaluationsEvaluationSuccessFooterMessage: {
    id: 'Betterme.Evaluations.EvaluationSuccessFooterMessage',
    defaultMessage: 'Your self-evaluation was created successfully!',
  },
  FeedbackEvaluationsEvaluationSuccessFooterMessageTeam: {
    id: 'Betterme.Evaluations.EvaluationSuccessFooterMessageTeam',
    defaultMessage: 'Your Evaluation to {username} was created successfully!',
  },
  FeedbackEvaluationsEvaluationSuccessFooterMessageTeamContinuous: {
    id: 'Betterme.Evaluations.EvaluationSuccessFooterMessageTeamContinuous',
    defaultMessage: 'Your Continuous Evaluation to **{username}** was created successfully!',
  },
  FeedbackEvaluationsEvaluationCreateDeleteDraft: {
    id: 'Betterme.Evaluations.EvaluationCreateDeleteDraft',
    defaultMessage: 'Delete draft',
  },
  FeedbackEvaluationsEvaluationCreateSaveDraft: {
    id: 'Betterme.Evaluations.EvaluationCreateSaveDraft',
    defaultMessage: 'Save draft',
  },
  FeedbackEvaluationsEvaluationCreateSubmitDraft: {
    id: 'Betterme.Evaluations.EvaluationCreateSubmitDraft',
    defaultMessage: 'Submit',
  },
  FeedbackEvaluationsEvaluationCreateDraftSaved: {
    id: 'Betterme.Evaluations.EvaluationCreateDraftSaved',
    defaultMessage: 'The draft was saved successfully',
  },
  FeedbackEvaluationsEvaluationCreateDraftDeleted: {
    id: 'Betterme.Evaluations.EvaluationCreateDraftDeleted',
    defaultMessage: 'The draft was deleted successfully',
  },
  FeedbackEvaluationsEvaluationCreateAlertDeleteDraft: {
    id: 'Betterme.Evaluations.EvaluationCreateAlertDeleteDraft',
    defaultMessage: 'Are you sure you want to delete this draft definitely?',
  },
  FeedbackEvaluationsEvaluationNHAlertTitle: {
    id: 'Betterme.Evaluations.EvaluationNHAlertTitle',
    defaultMessage:  'First Self-Evaluation',
  },
  FeedbackEvaluationsEvaluationNHAlertInfo: {
    id: 'Betterme.Evaluations.EvaluationNHAlertInfo',
    defaultMessage: 'We are sorry, but the period to create the first evaluation to the Glober has expired',
  },
  FeedbackEvaluationsEvaluationCreateSelfEvaluationTitle: {
    id: 'Betterme.Evaluations.EvaluationCreateSelfEvaluationTitle',
    defaultMessage: 'User self-evaluation',
  },
  FeedbackEvaluationsEvaluationCreateSelfEvaluationViewDetail: {
    id: 'Betterme.Evaluations.EvaluationCreateSelfEvaluationViewDetail',
    defaultMessage: 'View Detail',
  },
  FeedbackLowestRatingDescriptionEvaluation: {
    id: 'Betterme.LowestRating.DescriptionEvaluation',
    defaultMessage: 'Identifying opportunities for improvement is really important for your collaborator\'s career.',
  },
  FeedbackLowestRatingDescriptionSelfEvaluation: {
    id: 'Betterme.LowestRating.DescriptionSelfEvaluation',
    defaultMessage: 'Identifying opportunities for improvement is really important for your career.',
  },
  FeedbackEvaluationsEvaluationPromotionCreation: {
    id: 'Betterme.Evaluations.PromotionCreation',
    defaultMessage: 'Your promotion request has been created successfully.',
  },
  FeedbackEvaluationsEvaluationPromotionCreationAction: {
    id: 'Betterme.Evaluations.PromotionCreationAction',
    defaultMessage: 'CHECK IT OUT!',
  },
  FeedbackEvaluationsEvaluationSubmitted: {
    id: 'Betterme.Evaluations.Submitted',
    defaultMessage: 'Submitted',
  },
  FeedbackEvaluationsEvaluationForeignDraft: {
    id: 'Betterme.Evaluations.ForeignDraft',
    defaultMessage: 'Foreign Draft',
  },
  FeedbackEvaluationsEvaluationDraft: {
    id: 'Betterme.Evaluations.Draft',
    defaultMessage: 'Draft',
  },
  FeedbackEvaluationsEvaluationEvaluationSubmitted: {
    id: 'Betterme.Evaluations.EvaluationSubmitted',
    defaultMessage: 'Evaluation Submitted',
  },
  FeedbackEvaluationsEvaluationEvaluationSubmittedSubtitle: {
    id: 'Betterme.Evaluations.SubmittedSubtitle',
    defaultMessage: 'Another leader has already submitted an evaluation',
  },
  FeedbackEvaluationsEvaluationEvaluationLeaders: {
    id: 'Betterme.Evaluations.Leaders',
    defaultMessage: 'Leaders',
  },
  FeedbackEvaluationsEvaluationEvaluationAll: {
    id: 'Betterme.Evaluations.All',
    defaultMessage: 'All',
  },
  FeedbackEvaluationsEvaluationAttachFileOverall: {
    id: 'Betterme.Evaluations.AttachFileOverallCustomText',
    defaultMessage: 'Referring to the overall score',
  },
  FeedbackEvaluationsEvaluationAttachFileSuggestion: {
    id: 'Betterme.Evaluations.AttachFileSuggestionCustomText',
    defaultMessage: 'Referring to the suggestion',
  },
  FeedbackEvaluationsEvaluationEvaluationCommentTitle: {
    id: 'Betterme.Evaluations.CommentTitle',
    defaultMessage: 'Leave a comment',
  },
  FeedbackEvaluationsEvaluationEvaluationCommentSummary: {
    id: 'Betterme.Evaluations.CommentSummary',
    defaultMessage: 'Summarize your feedback in one sentence',
  },
  FeedbackEvaluationsEvaluationEvaluationCommentDescription: {
    id: 'Betterme.Evaluations.CommentDescription',
    defaultMessage: 'Expand your feedback and don\'t forget to mention something about each competency evaluated',
  },
  EvaluationsHeaderTeamMember: {
    id: 'Betterme.Evaluations.HeaderTeamMember',
    defaultMessage: 'Team Member',
  },
  EvaluationsHeaderCurrentStatus: {
    id: 'Betterme.Evaluations.HeaderCurrentStatus',
    defaultMessage: 'Current Status',
  },
  EvaluationsHeaderCreatedBy: {
    id: 'Betterme.Evaluations.HeaderCreatedBy',
    defaultMessage: 'Created By',
  },
  EvaluationsHeaderActions: {
    id: 'Betterme.Evaluations.HeaderActions',
    defaultMessage: 'Actions',
  },
  EvaluationsHeaderCreationDate:{
    id: 'Betterme.Evaluations.HeaderCreationDate',
    defaultMessage: 'Creation Date',
  },
  EvaluationsHeaderSubmittedDate: {
    id: 'Betterme.Evaluations.HeaderSubmittedDate',
    defaultMessage: 'Submitted Date',
  },
  EvaluationsTypeNotAvaible: {
    id: 'Betterme.Evaluations.TypeNotAvaible',
    defaultMessage: 'This type of evaluation is not available for this collaborator.',
  },
  EvaluationsTypeError: {
    id: 'Betterme.Evaluations.TypeError',
    defaultMessage: 'Oops! Something went wrong. Please try again or contact your StarMeUp administrator.',
  },
  EvaluationsSelfRetrospectiveTypeNotAvaible: {
    id: 'Betterme.Evaluations.SelfRetrospective.TypeNotAvaible',
    defaultMessage: 'This type of evaluation is not available for you.',
  },
  EvaluationsSkillsTitle: {
    id: 'Betterme.Evaluations.Skills.Title',
    defaultMessage: 'Skills',
  },
  FeedbackEvaluationsEvaluationSubmittedByMe: {
    id: 'Betterme.Evaluations.SubmittedByMe',
    defaultMessage: 'Submitted by me',
  },
  FeedbackEvaluationsEvaluationSubmittedByOther: {
    id: 'Betterme.Evaluations.SubmittedByOther',
    defaultMessage: 'Submitted by other',
  },
});
