import get from 'lodash/get';

export const selectAssessmentRatings = ({ assessment }) => {
  return get(assessment, 'assessmentRatings');
}

export const selectAssessmentRatingsLoading = ({ assessment }) => {
  return get(assessment, 'assessmentRatingsLoading');
}

export const selectAssessmentQuestions = ({ assessment }) => {
  return get(assessment, 'assessmentQuestions');
}

export const selectAssessmentQuestionsLoading = ({ assessment }) => {
  return get(assessment, 'assessmentQuestionsLoading');
}

export const selectAssessmentCollaborators = ({ assessment }) => {
  return get(assessment, 'assessmentCollaborators');
}

export const selectAssessmentCollaboratorsLoading = ({ assessment }) => {
  return get(assessment, 'assessmentCollaboratorsLoading');
}

export const selectAssessmentTier = ({ assessment }) => {
  return get(assessment, 'assessmentTier');
}

export const selectAssessmentTierLoading = ({ assessment }) => {
  return get(assessment, 'assessmentTierLoading');
}

export const selectSendAssessmentSummaryLoading = ({ assessment }) => {
  return get(assessment, 'sendAssessmentSummaryLoading');
}

export const selectSendAssessmentResultsLoading = ({ assessment }) => {
  return get(assessment, 'sendAssessmentResultsLoading');
}

export const selectAssessmentScore = ({ assessment }) => {
  const assessmentScore = { ...get(assessment, 'assessmentScore') } || {};
  if (assessmentScore.suggestedScore) {
    const interactionKeyToName = {
      'fs.result1': 'Foundational',
      'fs.result2': 'Intermediate',
      'fs.result3': 'Advanced',
      'fs.result4': 'High',
    };
    assessmentScore.scoreRanges = assessmentScore.scoreRanges
      ?.filter(range => range.interactionKey?.startsWith("fs.result"))
      ?.map(range => {
        let name = interactionKeyToName[range.interactionKey] || '';
        let id = range.interactionKey?.replace(/\D/g, "");
        return {
          min: range.min,
          max: range.max,
          id: range.finalValue,
          scoreTier: id,
          name: name
        };
      })
      ?.sort((a, b) => a.id - b.id);
    const suggestedScoreRange = assessmentScore.scoreRanges
      ?.find(range => range.min <= assessmentScore.suggestedScore
        && assessmentScore.suggestedScore <= range.max);

    assessmentScore.selectedScore = assessmentScore.suggestedScore;
    assessmentScore.suggestedScoreName = suggestedScoreRange?.name;
    assessmentScore.selectedScoreName = suggestedScoreRange?.name;
  }

  return assessmentScore;
}

export const selectAssessmentScoreLoading = ({ assessment }) => {
  return get(assessment, 'assessmentScoreLoading');
}
