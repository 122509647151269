import { get, orderBy } from 'lodash';
import { createSelector } from 'reselect';

const selectTopics = ({ sendRequestFeedback }) =>
  orderBy(get(sendRequestFeedback, 'competencies', []), ['name'], ['asc']).filter(competency => !competency.skill);
const selectRatedCompetencies = ({ sendRequestFeedback }) => get(sendRequestFeedback, 'ratedCompetencies', []);
export const selectLoadingRequestFeedback = ({ sendRequestFeedback }) => get(sendRequestFeedback, 'sending');
export const selectSkills = ({ sendRequestFeedback: { skills, type } }) =>
  orderBy(get(skills, type, []), ['name'], ['asc']);
export const selectCancellation = ({ sendRequestFeedback }) => get(sendRequestFeedback, 'requestFeedbackInfo.cancellation');
export const selectDismissal = ({ sendRequestFeedback }) => get(sendRequestFeedback, 'requestFeedbackInfo.dismissal');
export const selectFeedback = ({ sendRequestFeedback }) => get(sendRequestFeedback, 'requestFeedbackInfo.feedback');
export const selectLoadingReply = ({ sendRequestFeedback }) => get(sendRequestFeedback, 'loadingReply');
export const selectSelectedCollaborators = ({ sendRequestFeedback }) => get(sendRequestFeedback, 'selectedCollaborators', []);
export const selectSelectedUsers = ({ sendRequestFeedback }) => get(sendRequestFeedback, 'selectedUsers', []);
export const selectUserList = ({ sendRequestFeedback }) => get(sendRequestFeedback, 'userList', []);
export const selectType = ({ sendRequestFeedback }) => get(sendRequestFeedback, 'type');

export const selectCompetencies = createSelector(
  selectTopics,
  selectSkills,
  (topics, skills) => {
      return topics
        ?.concat(skills)
        .filter(competency => competency.active);
    }
);

export const selectUnselectedCompetencies = createSelector(
  selectCompetencies,
  selectRatedCompetencies,
  (competencies, ratedCompetencies) =>  {
    const ratedCompetenciesId = ratedCompetencies.map(({ competency }) => competency.id);
    return competencies?.filter(competency => !ratedCompetenciesId.includes(competency.id)) || [];
  }
);

export const selectEvaluationsDeletedSkills = ({ sendRequestFeedback }) =>
  get(sendRequestFeedback, 'deletedSkills');
