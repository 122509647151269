// @packages
import React from 'react';
import PropTypes from 'prop-types';
import RootModal from 'smu-app-components/RootModal';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { push } from 'react-router-redux';

// @app
import GenericModal from 'components/templates/GenericModalTemplate';
import InfoCard from 'components/molecules/InfoCard';
import LeadershipRemovalBody from 'components/organisms/LeadershipRemovalBody';
import {
  SIZE_FIFTY,
  SIZE_THIRTY_SIX,
} from 'components/common/constants';
import { LEADERSHIP_REMOVAL_MODAL } from 'betterme-components/constants/modalTypes';
import { actionsMessages } from 'common/messages';
import { centerModalClass } from 'betterme-components/ConfirmationModal';
import { getLeadershipAdminLocation } from 'betterme-components/routes/urls';
import { removeLeader } from 'betterme-components/services/RemoveLeader/actions';
import { selectLoading } from 'betterme-components/services/RemoveLeader/selectors';
import { removeLocalStoredItem } from 'utils/localStorage';
import { selectConfigurations } from 'betterme-components/Authorization/selectors';
import {
  CONTINUOUS_EVALUATION
} from 'betterme-components/Evaluations/constants';

// @own
import {
  ACTION_TYPE_REQUIRED,
  REMOVE_LEADER_SEND_EVALUATION,
  REMOVE_MYSELF_AS_MAIN_LEADER,
} from './constants';
import messages from './messages';

const LeadershipRemovalModal = ({
  actionRequired,
  allowsContinuousEvaluation,
  closeModal,
  collaborator,
  evaluationType,
  goToLeadershipAdmin,
  intl: { formatMessage },
  removeLeader,
  user,
}) => {
  const { access: { newWordingForLeaders } } = user;
  function handleContinue() {
    closeModal();
    removeLeader({
      collaborator,
      leaderType: actionRequired?.reasonType,
      type: actionRequired?.leaderType,
      reasonId: actionRequired?.reasonId,
      successMessage: formatMessage(messages.RemoveLeaderToastSuccess),
    });
  };

  function handleCancel() {
    closeModal();
    goToLeadershipAdmin(collaborator?.identification);
    removeLocalStoredItem(ACTION_TYPE_REQUIRED);
  };

  const actionButtons = [
    {
      label: formatMessage(actionsMessages.ActionsCancel),
      onClick: handleCancel,
      variant: 'outline',
    },
    {
      label: formatMessage(actionsMessages.ActionsContinue),
      onClick: handleContinue,
    },
  ];
  const collaboratorName = `${collaborator?.firstName} ${collaborator?.lastName}`;
  const isMainLeader = actionRequired?.reasonType === REMOVE_MYSELF_AS_MAIN_LEADER;
  const mainLeaderText = newWordingForLeaders
    ? formatMessage(messages.DeleteYourselfCareer, { collaborator: collaboratorName })
    : formatMessage(messages.DeleteYourselfMain, { collaborator: collaboratorName });
  const secondaryLeaderText = newWordingForLeaders
    ? formatMessage(messages.DeleteYourselfExperience, { collaborator: collaboratorName })
    : formatMessage(messages.DeleteYourselfSecondary, { collaborator: collaboratorName });
  const leaderTypeText = isMainLeader ? mainLeaderText : secondaryLeaderText;
  const LeadershipRemovalImage = {
    height: SIZE_FIFTY,
    profileImageCode: collaborator?.profileImageCode,
    width: SIZE_FIFTY,
  };
  const evalutionText = allowsContinuousEvaluation && evaluationType === CONTINUOUS_EVALUATION
    ? formatMessage(messages.ContinuousEvaluationSent, { collaborator: collaboratorName })
    : formatMessage(messages.EvaluationSent, { collaborator: collaboratorName });
  const actionTypeRequired = actionRequired?.actionType === REMOVE_LEADER_SEND_EVALUATION;
  const feedbackText = formatMessage(messages.FeedbackSent, { collaborator: collaboratorName });
  const actionTypeText = actionTypeRequired ? evalutionText : feedbackText;

  return (
    <GenericModal
      actionButtons={actionButtons}
      content={
        <LeadershipRemovalBody
          actionConfirmation={actionTypeText}
          removalConfirmation={leaderTypeText}
          user={LeadershipRemovalImage}
        />
      }
      header={
        <InfoCard
          icon="info"
          iconSize={SIZE_FIFTY}
          title={formatMessage(messages.LeadershipRemoval)}
          titleSize={SIZE_THIRTY_SIX}
        />
      }
      success
      onClose={handleCancel}
    />
  );
};

LeadershipRemovalModal.propTypes = {
  actionRequired: PropTypes.object.isRequired,
  allowsContinuousEvaluation: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  collaborator: PropTypes.object.isRequired,
  goToLeadershipAdmin: PropTypes.func.isRequired,
  intl: PropTypes.func.isRequired,
  removeLeader: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const modalStyles = {
  width: '610px',
};

const mapStateToProps = (state) => ({
  removeLeaderLoading: selectLoading(state),
  allowsContinuousEvaluation: selectConfigurations('allowsContinuousEvaluation')(state),
})

const mapDispatchToProps = {
  goToLeadershipAdmin: (identification) => push(getLeadershipAdminLocation(identification)),
  removeLeader,
};

export default RootModal(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(LeadershipRemovalModal)),
  LEADERSHIP_REMOVAL_MODAL,
  modalStyles,
  centerModalClass,
);
