// @packages
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from 'smu-ui-components/ButtonV2';
import { injectIntl } from 'react-intl';

// @app
import InfiniteScroll from 'common/InfiniteScrollLoad';

// @own
import NotificationHistoryItem from './NotificationHistoryItem';
import messages from './messages';
import './styles.scss';

const NotificationHistoryList = ({
  intl: { formatMessage },
  loadMore,
  markAllNotificationsAsRead,
  notifications,
}) => (
  <Fragment>
    <div className="notifications-history__list">
      <div className="notifications-history__info">
        <Button variant="link" size="medium" onClick={markAllNotificationsAsRead}>
          {formatMessage(messages.notificationsMarkAllAsRead)}
        </Button>
      </div>
      <InfiniteScroll
        dataLength={notifications.length}
        hasMore={notifications.length}
        loadingType="starmeup"
        next={loadMore}
      >
        {notifications.map((n, index) =>
          <NotificationHistoryItem
            key={`${n.id}-${index}`}
            notification={n}
          />)}
      </InfiniteScroll>
    </div>
  </Fragment>
);

NotificationHistoryList.propTypes = {
  handleMarkAllAsRead: PropTypes.func,
  loadMore: PropTypes.func,
  markAllNotificationsAsRead: PropTypes.func,
  notifications: PropTypes.array,
};

export default injectIntl(NotificationHistoryList);
