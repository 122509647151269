// @packages
import React, { Component, useCallback } from 'react';
import PropTypes from 'prop-types';
import ActionButtons from 'components/molecules/ActionButtons';
import Collapsible from 'smu-ui-components/Collapsible';
import InputArea from 'smu-ui-components/InputArea';
import Segment from 'smu-ui-components/Segment';

// @app
import ContinuousProgressBar from 'common/ContinuousProgressBar';
import DiscreteProgressBar from 'smu-ui-components/DiscreteProgressBar';
import UploadFile from 'betterme-components/components/containers/UploadFile';

// @own
import GoalAddAssessmentFeatDiscovery from '../GoalAddAssessmentFeatDiscovery';
import {
  BAR_CONTINOUS,
  BAR_DISCRETE,
  BAR_PERCENTAGE,
} from '../constants';
import './styles.scss';

const UploadFileComponent = ({
  className,
  disabled,
  files,
  loading,
  onChange,
  onDeleteAccept,
  supportedFormats,
}) => {
  const Component = useCallback(() => (
    <UploadFile
      className={className}
      disabled={disabled}
      files={files}
      loading={loading}
      onChange={onChange}
      onDeleteAccept={onDeleteAccept}
      supportedFormats={supportedFormats}
    />
  ), [
    files,
    loading,
    onChange,
    supportedFormats,
  ]);

  return <Component />;
};

class GoalAddAssessment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newProgress: props.progress,
      comment: '',
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  getBarType() {
    const { isGoalScoreContinous, isPercentage } = this.props;
    if (isPercentage) {
      return BAR_PERCENTAGE;
    } else if (isGoalScoreContinous) {
      return BAR_CONTINOUS;
    }

    return BAR_DISCRETE;
  }

  handleCancel(event) {
    const { onCancel, progress } = this.props;
    this.setState({ newProgress: progress, comment: '' });
    onCancel(event);
  }

  handleUpdate() {
    const { onUpdate, files: { data: files } } = this.props;
    const { comment, newProgress } = this.state;
    const attachments = files?.map((file) => file.id);
    this.setState({ comment: '' });
    onUpdate({ comment, value: newProgress, files: attachments });
  }

  render() {
    const {
      editingProgress,
      fetching,
      files: {
        data: files,
        disabled: filesDisabled,
        loading: filesLoading,
        onChange: onFileChange,
        onDelete: onFileDelete,
        supportedFormats: filesSupportedFormats,
      },
      isGoalScoreContinous,
      isPercentage,
      messages,
      scores,
      withFeatureDiscovery,
    } = this.props;
    const { newProgress, comment } = this.state;
    const disabled = fetching || !newProgress || comment.trim() === '';
    const showContinousBar = isGoalScoreContinous || isPercentage;
    return (
      <div className="goal-add-assessments">
        <GoalAddAssessmentFeatDiscovery
          element={this.getBarType()}
          disabled={!withFeatureDiscovery}
        >
          {showContinousBar ? (
            <ContinuousProgressBar
              editing={editingProgress}
              isPercentage={isPercentage}
              onChange={progressValue => this.setState({ newProgress: progressValue })}
              progress={newProgress}
              scores={scores}
            />
          ) : (
            <DiscreteProgressBar
              editing={editingProgress}
              onChange={progressValue => this.setState({ newProgress: progressValue })}
              progress={newProgress}
              scores={scores}
              emptyColor="#F0F2F5"
              backgroundColor="#FFFFFF"
            />
          )}
        </GoalAddAssessmentFeatDiscovery>
        <Collapsible expanded={editingProgress}>
          <Segment loadingType="betterme">
            <div className="goal-add-assessments__form">
              <div className="goal-add-assessments__comment">
                <InputArea
                  name="comment"
                  className="goal-add-assessments__comment-textarea"
                  placeholder={messages.placeholderComment}
                  onChange={(event) =>
                    this.setState({ comment: event.target.value })
                  }
                  value={comment}
                  maxLength={1200}
                />
                <UploadFileComponent
                  className="goal-add-assessments__upload-files"
                  disabled={filesDisabled}
                  files={files}
                  loading={filesLoading}
                  onChange={onFileChange}
                  onDeleteAccept={onFileDelete}
                  supportedFormats={filesSupportedFormats}
                />
              </div>
            </div>
            <div className="goal-add-assessments__actions">
              <ActionButtons
                buttons={[
                  {
                    label: messages.btnCancel,
                    onClick: this.handleCancel,
                    variant: "outline",
                  },
                  {
                    disabled: disabled,
                    label: messages.btnAddAssessment,
                    onClick: this.handleUpdate,
                  },
                ]}
              />
            </div>
          </Segment>
        </Collapsible>
      </div>
    );
  }
}

GoalAddAssessment.propTypes = {
  editingProgress: PropTypes.bool.isRequired,
  fetching: PropTypes.bool,
  files: PropTypes.object,
  handleSave: PropTypes.func,
  isGoalScoreContinous: PropTypes.bool.isRequired,
  isPercentage: PropTypes.bool,
  messages: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  progress: PropTypes.number.isRequired,
  scores: PropTypes.arrayOf(
    PropTypes.shape({
      min: PropTypes.number.isRequired,
      max: PropTypes.number.isRequired,
    }).isRequired,
  ).isRequired,
};

export default GoalAddAssessment;
