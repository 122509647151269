import {
  CREATE_INPUT,
  CREATE_INPUT_FAIL,
  CREATE_INPUT_SUCCESS,
  SET_INPUT,
  RESET_INPUT,
} from './actionTypes';

export function createInput(payload) {
  return {
    payload,
    type: CREATE_INPUT,
  }
};

export function createInputFail(error) {
  return {
    type: CREATE_INPUT_FAIL,
    payload: { error },
  };
}

export function createInputSuccess() {
  return {
    type: CREATE_INPUT_SUCCESS,
  }
};

export function setInput(payload) {
  return {
    type: SET_INPUT,
    payload,
  };
}

export function resetInput() {
  return {
    type: RESET_INPUT,
  };
}
