import { takeEvery, call, put } from 'redux-saga/effects';
import { getFeedback } from '../api';
import {
  GET_FEEDBACK,
  GET_FEEDBACK_SUCCESS,
  GET_FEEDBACK_FAIL
} from './actionTypes';

function* fetchFeedbackWorker({ payload }) {
  try {
    const data = yield call(getFeedback, payload);
    data.user = data.sender;
    delete data.sender;
    yield put({ type: GET_FEEDBACK_SUCCESS, payload: data });
  } catch ({status}) {
    yield put({ type: GET_FEEDBACK_FAIL, payload: status});
  }
}

export default function* fetchFeedbackWatcher() {
  yield takeEvery(GET_FEEDBACK, fetchFeedbackWorker);
}
