// @packages
import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import RootModal from 'smu-app-components/RootModal';

// @app
import GenericModal from 'components/templates/GenericModalTemplate';
import InfoCard from 'components/molecules/InfoCard';
import { centerModalClass } from 'betterme-components/ConfirmationModal';
import { CREATE_PROMOTION_REQUEST_MODAL } from 'betterme-components/constants/modalTypes';
import {
  SIZE_FIFTY,
  SIZE_THIRTY_SIX,
} from 'components/common/constants';

// @own
import { actionsMessages, promotionsMessages } from 'common/messages';

const RequestPromotionModal = ({
  close,
  intl: { formatMessage },
  loading,
  onAccept,
  collaboratorFullName
}) => {

  function handleSubmit() {
    onAccept();
    close();
  }

  const actionButtons = [
    {
      label: formatMessage(actionsMessages.ActionsCancel),
      onClick: () => close(),
      variant: 'outline'
    },
    {
      label: formatMessage(actionsMessages.ActionsContinue),
      onClick: handleSubmit,
    },
  ];

  return (
    <GenericModal
      actionButtons={actionButtons}
      header={
        <InfoCard
          icon="info"
          iconSize={SIZE_FIFTY}
          message={formatMessage(promotionsMessages?.PromotionsRequestModal, {
            collaboratorFullName: collaboratorFullName,
          })}
          title={formatMessage(promotionsMessages?.PromotionsRequestModalTitle)}
          titleSize={SIZE_THIRTY_SIX}
        />
      }
      loading={loading}
      onClose={close}
      success
    />
  );
};

RequestPromotionModal.propTypes = {
  loading: PropTypes.bool,
  intl: PropTypes.object,
  close: PropTypes.func.isRequired,
  collaboratorName: PropTypes.string.isRequired,
  goToNotifications: PropTypes.func,
};

const modalStyles = {
  width: '610px',
};

export default RootModal(
  injectIntl(RequestPromotionModal),
  CREATE_PROMOTION_REQUEST_MODAL,
  modalStyles,
  centerModalClass,
);
