import {
  GET_USER_PROMOTION_REQUESTS_GROUP,
  GET_USER_PROMOTION_REQUESTS_GROUP_FAIL,
  GET_USER_PROMOTION_REQUESTS_GROUP_SUCCESS,
} from './actionTypes';

const initialState = {
  error: null,
  loadingPromotionRequestsGroup: false,
  promotionRequestsGroup: [],
  promotionProcessAllowed: false,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_USER_PROMOTION_REQUESTS_GROUP:
      return { ...state, loadingPromotionRequestsGroup: true };

    case GET_USER_PROMOTION_REQUESTS_GROUP_FAIL:
      return {
        ...state,
        error: payload.error,
        loadingPromotionRequestsGroup: false,
        promotionProcessAllowed: false,
      };

    case GET_USER_PROMOTION_REQUESTS_GROUP_SUCCESS:
      return {
        ...state,
        loadingPromotionRequestsGroup: false,
        promotionRequestsGroup: [...payload.response],
        promotionProcessAllowed: true,
      };

    default:
      return state;
  }
}
