// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import GenericEmptyState from 'smu-ui-components/GenericEmptyState';


// @own
import './styles.scss';
import messages from '../messages';

const AccessDeniedEmptyState = ({
  intl: { formatMessage },
  user,
}) => (
  <GenericEmptyState
    className="px-5 py-20 bg-white access-denied-empty-state"
    description={formatMessage(messages.FeedbackProfileEmptyStateDescription, { username: user.firstName },)}
    image="list"
    limitTextWidth={false}
    size="large"
    title={formatMessage(messages.FeedbackProfileEmptyStateTitle)}
  />
);

AccessDeniedEmptyState.propTypes = {
  intl: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
  goToSendRequestFeedback: (userId, user) => push({
    pathname: `/newfeedback/${userId}`,
    state: { user, defaultRequest: true },
  }),
  goToSendFeedback: (userId, user) => push({
    pathname: `/newfeedback/${userId}`,
    state: { user },
  }),
};

export default connect(null, mapDispatchToProps)(injectIntl(AccessDeniedEmptyState));
