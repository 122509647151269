import {
  CREATE_REPORT_BUG,
  CREATE_REPORT_BUG_FAIL,
  CREATE_REPORT_BUG_SUCCESS,
} from './actionTypes';

const initialState = {
  loading: false,
};

export default function reducer(state = initialState, { type }) {
  switch (type) {
    case CREATE_REPORT_BUG:
      return { ...state, loading: true };
    case CREATE_REPORT_BUG_FAIL:
      return { ...state, loading: false };
    case CREATE_REPORT_BUG_SUCCESS:
      return { ...state, loading: false };
    default:
      return state;
  }
}
