// @packages
import { takeLatest, call, put } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';

// @app
import { getLeadersByPeriod } from 'betterme-components/api';

// @own
import { GET_LEADERS_BY_PERIOD } from './actionTypes';
import {
  getLeadersByPeriodFail,
  getLeadersByPeriodSuccess,
} from './actions';

function* getLeadersByPeriodWorker() {
  try {
    const response = yield call(getLeadersByPeriod);
    yield put(getLeadersByPeriodSuccess(response));
  } catch(error) {
    yield put(getLeadersByPeriodFail(error.message));
    yield put(addToastMessage({ timeout: 3000, message: error.message }));
  }
}

export default function* getLeadersByPeriodWatcher() {
  yield takeLatest(GET_LEADERS_BY_PERIOD, getLeadersByPeriodWorker);
}
