import { get } from 'lodash';

const reducerDomain = 'changeLeaderRequest';

export const selectComment = state => get(state, [reducerDomain, 'comment']);
export const selectSelectedLeader = state => get(state, [reducerDomain, 'selectedLeader']);
export const selectUserList = state => get(state, [reducerDomain, 'userList']);
export const selectUserListLoading = state => get(state, [reducerDomain, 'userListLoading']);
export const selectLoadingUpdateUserLeader = state => get(state, [reducerDomain, 'loadingUpdateUserLeader']);
export const selectCloseUserCard = state => get(state, [reducerDomain, 'closeUserCard']);
