// @packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Collapsible from 'smu-ui-components/Collapsible';
import Button from 'smu-ui-components/ButtonV2';

// @app
import UserDetailsInfo from 'betterme-components/components/molecules/UserDetailsInfo';
import { trackEvaluationsFormViewMore } from 'betterme-components/Evaluations/analytics';

// @own
import './styles.scss';

const UserDetails = ({
  availableUserPropList,
  hiddenUserPropList,
  onShowDetailsClick,
  positionAgingMessageformat,
  seniorityAgingMessageformat,
  type,
  userDetailsInfoPropList: {
    firstName,
    lastName,
    profileImageCode,
  },
  viewLessMessage,
  viewMoreMessage,
}) => {
  let [showDetails, setShowDetails] = useState(false);
  const handleShowDetails = () => {
    onShowDetailsClick(showDetails);
    setShowDetails(!showDetails);
    if (showDetails) trackEvaluationsFormViewMore(type);
  };

  return (
    <div className="user-details">
      <UserDetailsInfo
        fullName={`${firstName} ${lastName}`}
        profileImageCode={profileImageCode}
      />
      <>
        <div className="user-details__primary-details">
          {!!availableUserPropList.length && (
            <div className="user-details__details-row">
              {availableUserPropList[0][0] && (
                <div className="user-details__details-cell">
                  <h4>{availableUserPropList[0][0].label}</h4>
                  <p>{availableUserPropList[0][0].value}</p>
                  <p className="user-details__aging">
                    {positionAgingMessageformat()}
                  </p>
                </div>
              )}
              {availableUserPropList[0][1] && (
                <div className="user-details__details-cell">
                  <h4>{availableUserPropList[0][1].label}</h4>
                  <p>{availableUserPropList[0][1].value}</p>
                  <p className="user-details__aging">
                    {seniorityAgingMessageformat()}
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
        {!!hiddenUserPropList.length && (
          <>
            <Collapsible
              baseHeight={500}
              className="user-details__secondary-details"
              expanded={showDetails}
            >
              <div className="user-details__secondary-details-content">
                {hiddenUserPropList.map((propArr) => (
                  <div className="user-details__details-row">
                    {propArr.map((prop, index) => (
                      <div className="user-details__details-cell" key={index}>
                        <h4>{prop.label}</h4>
                        <p>{prop.value}</p>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </Collapsible>
            <div className="user-details__details-button-container">
              <Button
                className="user-details__details-button"
                onClick={handleShowDetails}
              >
                {showDetails ? viewLessMessage : viewMoreMessage}
              </Button>
            </div>
          </>
        )}
      </>
    </div>
  );
};

UserDetails.defaultProps = {
  onShowDetailsClick: () => {},
};

UserDetails.propTypes = {
  aviableUserPropList: PropTypes.array,
  hiddenUserPropList: PropTypes.array,
  onShowDetailsClick: PropTypes.func,
  positionAgingMessageformat: PropTypes.string,
  seniorityAgingMessageformat: PropTypes.string,
  userDetailsInfoPropList: PropTypes.object,
  viewLessMessage: PropTypes.string,
  viewMoreMessage: PropTypes.string,
};

export default UserDetails;
