// @packages
import React, { useEffect } from 'react';
import Grid from 'smu-layout-components/Grid';
import LottieSpinner from 'smu-ui-components/LottieSpinner';
import { base64Decode } from 'smu-utils/base64';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

// @app
import { selectConfigurations, selectCommunityId } from '../Authorization/selectors';
import { getProfileLocation } from '../routes/urls';

// @own
import './styles.scss';
import * as actions from './actions';
import ProfileSidenav from './ProfileSidenav';
import AccessDeniedEmptyState from './AccessDeniedEmptyState';
import {
  selectLoadingGetProfile,
  selectProfileCollaboratorsByLeader,
  selectProfileInfo,
} from './selectors';

function Profile({
  allowsAddToMyTeam,
  allowsEngagementIndicator,
  allowsEvaluationDrafts,
  allowsEvaluations,
  allowsEvaluationsCreation,
  allowsFeedbackForLeaders,
  allowsFeedbackRequest,
  allowsGoals,
  allowsLeaderWidgetInColleague,
  allowsNewHireEvaluationCreation,
  allowsNewHireEvaluationDraft,
  allowsPromotions,
  allowsRequestedOnBehalf,
  allowsSuggestions,
  children,
  collaboratorsByLeader,
  communityId,
  getProfile,
  goToHome,
  goToProfile,
  loadingGetProfile,
  location,
  params,
  profile,
  resetProfile,
  session,
  session: { user },
}) {
  const { user: sessionUser } = session;
  const { pathname } = location;
  const { userid } = params;
  const selfUserId = sessionUser ? sessionUser.id : 0;
  const profileId = profile ? profile.id : 0;
  const isDetail = pathname?.includes('assignedgoals')
    || pathname?.includes('feedbackreceived')
    || pathname?.includes('feedback-for-leaders-received')
    || pathname?.includes('potential-assessment-results');
  const allowsNewHireEvaluation = (allowsNewHireEvaluationCreation || allowsNewHireEvaluationDraft)
  const isNewHire = profile
    ? (allowsNewHireEvaluation && profile.newHireEvalAvailable)
    : undefined;
  const isMyLeader = sessionUser
    ? sessionUser?.leaders?.some(leader => leader?.id === profileId)
    : undefined;
  const isCollaborator = profile
    ? profile?.leaders?.some(leader => leader?.id === selfUserId)
    : undefined;
  const isMainCollaborator = (profile && profile?.leader)
    ? profile?.leader?.id === selfUserId
    : undefined;


  useEffect(() => {
    getProfile(base64Decode(params?.userid));
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (userid) {
      getProfile(base64Decode(userid));
    }
  }, [userid]);

  useEffect(() => {
    if (userid && base64Decode(userid) === user?.identification) {
      goToHome();
    }
  }, [userid, user?.identification]);

  useEffect(() => {
    return () => {
      resetProfile();
    };
  }, []);


  if (!profile) {
    return (
      <LottieSpinner
        loadingType="betterme"
        width={60}
        height={60}
      />
    );
  }
  return (
    <main className="profile">
      {!isDetail ? (
        <div className="profile__content">
          <Grid>
            <Grid.Col desktop={3}>
              <ProfileSidenav
                allowsAddToMyTeam={allowsAddToMyTeam}
                allowsEngagementIndicator={allowsEngagementIndicator}
                allowsEvaluationDrafts={allowsEvaluationDrafts}
                allowsEvaluations={allowsEvaluations}
                allowsEvaluationsCreation={allowsEvaluationsCreation}
                allowsFeedbackForLeaders={allowsFeedbackForLeaders}
                allowsFeedbackRequest={allowsFeedbackRequest}
                allowsGoals={allowsGoals}
                allowsLeaderWidgetInColleague={allowsLeaderWidgetInColleague}
                allowsPromotions={allowsPromotions}
                allowsRequestedOnBehalf={allowsRequestedOnBehalf}
                allowsSuggestions={allowsSuggestions}
                collaboratorsByLeader={collaboratorsByLeader}
                communityId={communityId}
                isCollaborator={isCollaborator}
                isMainCollaborator={isMainCollaborator}
                isMyLeader={isMyLeader}
                isNewHire={isNewHire}
                loading={loadingGetProfile}
                onUserAvatarClick={goToProfile}
                sessionUser={session.user}
                user={profile}
              />
            </Grid.Col>
            <Grid.Col desktop={9}>
              {children
                ? React.Children.map(
                  children,
                  (child) => React.cloneElement(child, {
                    isCollaborator,
                    isMainCollaborator,
                    location,
                    params,
                    user: profile,
                  })
                )
                : (
                  <AccessDeniedEmptyState
                    user={profile}
                    userId={userid}
                  />
                )
              }
            </Grid.Col>
          </Grid>
        </div>
      ) : (
        children
          ? React.Children.map(children, (child) => React.cloneElement(child, { location, user: profile, params }))
          : (
            <AccessDeniedEmptyState
              user={profile}
              userId={userid}
            />
          )
      )}
    </main>
  );
}

const mapStateToProps = (state) => ({
  allowsAddToMyTeam: selectConfigurations('allowsAddToMyTeam')(state),
  allowsEngagementIndicator: selectConfigurations('allowsEngagementIndicator')(state),
  allowsEvaluationDrafts: selectConfigurations('allowsEvaluationDrafts')(state),
  allowsEvaluations: selectConfigurations('allowsEvaluations')(state),
  allowsEvaluationsCreation: selectConfigurations('allowsEvaluationsCreation')(state),
  allowsFeedbackForLeaders: selectConfigurations('allowsFeedbackForLeaders')(state),
  allowsFeedbackRequest: selectConfigurations('allowsFeedbackRequest')(state),
  allowsGoals: selectConfigurations('allowsGoals')(state),
  allowsLeaderWidgetInColleague: selectConfigurations('allowsLeaderWidgetInColleague')(state),
  allowsNewHireEvaluationCreation: selectConfigurations('allowsNewHireEvaluationCreation')(state),
  allowsNewHireEvaluationDraft: selectConfigurations('allowsNewHireEvaluationDraft')(state),
  allowsPromotions: selectConfigurations('allowsPromotions')(state),
  allowsRequestedOnBehalf: selectConfigurations('allowsRequestedOnBehalf')(state),
  allowsSuggestions: selectConfigurations('allowsSuggestions')(state),
  collaboratorsByLeader: selectProfileCollaboratorsByLeader(state),
  communityId: selectCommunityId(state),
  loadingGetProfile: selectLoadingGetProfile(state),
  profile: selectProfileInfo(state),
});

const mapDispatchToProps = {
  goToHome: () => push('/myprofile'),
  goToProfile: (userIdentification) => push(getProfileLocation(userIdentification)),
  ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
