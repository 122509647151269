// @packages
import { takeLatest, call, put } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';

// @own
import { GET_SELF_EVALUATION_LIST } from './actionTypes';
import { getSelfEvaluationsList } from './api';
import {
  getSelfEvaluationsListFail,
  getSelfEvaluationsListSuccess,
  resetSelfEvaluationsList,
} from './actions';

function* getSelfEvaluationListWorker({ payload }) {
  try {
    yield put(resetSelfEvaluationsList());
    const response = yield call(getSelfEvaluationsList, payload);
    yield put(getSelfEvaluationsListSuccess(response));
  } catch (error) {
    yield put(getSelfEvaluationsListFail(error.message));
    yield put(addToastMessage({
      message: error.message,
      timeout: 3000,
    }));
  }
}

export default function* searchBarWatcher() {
  yield takeLatest(GET_SELF_EVALUATION_LIST, getSelfEvaluationListWorker);
}
