import {
  CREATE_FEEDBACKS_FOR_LEADERS,
  CREATE_FEEDBACKS_FOR_LEADERS_FAIL,
  CREATE_FEEDBACKS_FOR_LEADERS_SUCCESS,
  GET_QUESTIONS_FEEDBACKS_FOR_LEADERS,
  GET_QUESTIONS_FEEDBACKS_FOR_LEADERS_FAIL,
  GET_QUESTIONS_FEEDBACKS_FOR_LEADERS_SUCCESS,
  GET_RATINGS_FEEDBACKS_FOR_LEADERS,
  GET_RATINGS_FEEDBACKS_FOR_LEADERS_FAIL,
  GET_RATINGS_FEEDBACKS_FOR_LEADERS_SUCCESS,
  RESET_FEEDBACKS_FOR_LEADERS,
  SET_ANSWER_COMPETENCY,
  SET_ANSWER_INSIGHT,
} from './actionTypes';

const initialState = {
  answersCompetencies: {},
  answersInsights: {},
  error: '',
  loadingCreateFeedbackForLeaders: false,
  loadingQuestionsFeedbackForLeaders: false,
  loadingRatingsFeedbackForLeaders: false,
  questionsFeedbackForLeaders: [],
  ratingsFeedbackForLeaders: [],
};

const getQuestionsFiltered = (questions, category) => (
  questions
    .filter(question => question.category === category)
    .reduce((acc, value) => ({
      ...acc,
      [value.id]: value,
    }), {})
);

const getAnswersInsights = (answersInsights, question) => ({
  ...answersInsights,
  [question.id]: {
    ...answersInsights[question.id],
    validated: question.validated,
    value: question.value,
    valueId: question.valueId,
  },
});

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case CREATE_FEEDBACKS_FOR_LEADERS:
      return { ...state, loadingCreateFeedbackForLeaders: true };

    case CREATE_FEEDBACKS_FOR_LEADERS_FAIL:
      return {
        ...state,
        error: payload.error,
        loadingCreateFeedbackForLeaders: false,
      };

    case CREATE_FEEDBACKS_FOR_LEADERS_SUCCESS: {
      const { response } = payload;
      return {
        ...state,
        error: '',
        loadingCreateFeedbackForLeaders: false,
      };
    }
    case GET_QUESTIONS_FEEDBACKS_FOR_LEADERS:
      return { ...state, loadingQuestionsFeedbackForLeaders: true };

    case GET_QUESTIONS_FEEDBACKS_FOR_LEADERS_FAIL:
      return {
        ...state,
        error: payload.error,
        loadingQuestionsFeedbackForLeaders: false,
      };

    case GET_QUESTIONS_FEEDBACKS_FOR_LEADERS_SUCCESS: {
      const { response } = payload;
      return {
        ...state,
        error: '',
        loadingQuestionsFeedbackForLeaders: false,
        questionsFeedbackForLeaders: response,
        answersInsights: getQuestionsFiltered(response, 'Insight'),
      };
    }

    case GET_RATINGS_FEEDBACKS_FOR_LEADERS:
      return { ...state, loadingRatingsFeedbackForLeaders: true };

    case GET_RATINGS_FEEDBACKS_FOR_LEADERS_FAIL:
      return {
        ...state,
        error: payload.error,
        loadingRatingsFeedbackForLeaders: false,
      };

    case GET_RATINGS_FEEDBACKS_FOR_LEADERS_SUCCESS:
      return {
        ...state,
        error: '',
        loadingRatingsFeedbackForLeaders: false,
        ratingsFeedbackForLeaders: payload.response,
      };

    case RESET_FEEDBACKS_FOR_LEADERS:
      return initialState;

    case SET_ANSWER_COMPETENCY: {
      const { question } = payload;

      return {
        ...state,
        answersCompetencies: {
          ...state.answersCompetencies,
          [question.id]: {
            ...question,
          },
        }
      };
    }

    case SET_ANSWER_INSIGHT: {
      const { question } = payload;

      return {
        ...state,
        answersInsights: getAnswersInsights(state.answersInsights, question),
      };
    }

    default:
      return state;
  }
}
