// @packages
import React, { useEffect } from 'react';
import { orderBy } from 'lodash';
import Segment from 'smu-ui-components/Segment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { openModal } from 'smu-app-components/RootModal/actions';
import GenericEmptyState from 'smu-ui-components/GenericEmptyState';
import Link from 'smu-ui-components/Link';

// @app
import * as actions from 'betterme-components/services/LeadershipRequestReceived/actions';
import ChangeLeaderCard from 'components/organisms/ChangeLeaderCard';
import ChangeLeaderCardDisabled from 'components/organisms/ChangeLeaderCardDisabled';
import InfiniteScrollLoad from 'common/InfiniteScrollLoad';
import ReplaceLeaderReceived from 'components/organisms/ReplaceLeaderReceived';
import { formatDateV2 } from 'utils/formatDateV2';
import { gotIt } from 'betterme-components/services/GotIt/actions';
import { putChangeLeader } from 'betterme-components/services/ChangeLeaderRequestAcceptDecline/actions';
import {
  selectGetLeadershipRequestsPage,
  selectGetLeadershipRequestsReceived,
  selectLoadingLeadershipRequestsReceived,
} from 'betterme-components/services/LeadershipRequestReceived/selectors';
import {
  ADD_TO_MY_TEAM_REQUEST_AS_MAIN,
  APPROVED,
  CANCELED,
  CHANGE_LEADER_REQUEST_AS_MAIN,
  CHANGE_LEADER_REQUEST_AS_SECONDARY,
  LEADER_SUBSTITUTION,
  NEW_LEADER,
  RECEIVED,
} from 'components/pages/LeadershipRequestSection/constants';
import {
  ADD_LEADER_REQUEST_MODAL,
  LEADERSHIP_REQUEST_ACCEPT_MODAL,
  REASONS_DIMISS_MODAL,
} from 'betterme-components/constants/modalTypes';
import {
  trackLeadershipRequestAccept,
  trackLeadershipRequestDecline,
} from 'betterme-components/services/LeadershipRequest/analytics';
import { ACCOUNT_LEADER_ANALYTICS, CAREER_LEADER_ANALYTICS } from 'components/common/constants';

const LeadershipRequestReceived = ({
  buttonsText: {
    acceptText,
    cancelText,
    gotItText,
  },
  commentTitle,
  disclaimer,
  emptyState: {
    emptyStateText,
    emptyStateTitle,
    navigateTo,
    navigateToText,
  },
  footer: { sentBy },
  getLeadershipRequestsReceived,
  gotIt,
  leadersTitle: {
    currentLeader,
    newAccountLeader,
    newCareerLeader,
    newMainLeader,
    newSecondaryLeader,
  },
  loading,
  leaderEmptyState: {
    leaderEmptyStateText,
    leaderEmptyStateImage,
  },
  messages: {
    accountLeaderMsg,
    careerLeaderMsg,
    mainLeaderMsg,
    secondaryLeaderMsg,
  },
  newWordingForLeaders,
  openModal,
  page: { number, totalPages },
  putChangeLeader,
  requests,
  requestTitle: {
    accountLeader,
    careerLeader,
    leaderSubstitution,
    mainLeader,
    secondaryLeader,
  },
  user,
}) => {
  const hasLeadershipRequestReceived = requests.length > 0;
  const hasMoreLeadershipRequestReceived = number + 1 < totalPages;
  const {
    identification,
  } = user;

  useEffect(() => {
    getLeadershipRequestsReceived(RECEIVED, { pageNumber: number });
  }, []);

  function handleGetMoreLeadershipRequests() {
    getLeadershipRequestsReceived(RECEIVED, { pageNumber: number + 1 });
  };

  function handleAccept(collaborator, requestId) {
    const request = requests.filter(request => request.requestId === requestId);
    const trackData = {
      label: request.type === (CHANGE_LEADER_REQUEST_AS_MAIN || CHANGE_LEADER_REQUEST_AS_SECONDARY)
        ? LEADER_SUBSTITUTION
        : NEW_LEADER,
      type: request.type === (CHANGE_LEADER_REQUEST_AS_MAIN || ADD_TO_MY_TEAM_REQUEST_AS_MAIN)
        ? CAREER_LEADER_ANALYTICS
        : ACCOUNT_LEADER_ANALYTICS,
    };

    const modalType = LEADERSHIP_REQUEST_ACCEPT_MODAL;
    const modalProps = { collaborator };

    putChangeLeader(requestId, APPROVED, null, null, identification, null, modalType, modalProps);
    trackLeadershipRequestAccept(trackData);
  };

  function handleDecline(collaborator, requestId) {
    const request = requests.filter(r => r.requestId === requestId);
    const trackData = {
      label: request.type === (CHANGE_LEADER_REQUEST_AS_MAIN || CHANGE_LEADER_REQUEST_AS_SECONDARY)
        ? LEADER_SUBSTITUTION
        : NEW_LEADER,
      type: request.type === (CHANGE_LEADER_REQUEST_AS_MAIN || ADD_TO_MY_TEAM_REQUEST_AS_MAIN)
        ? CAREER_LEADER_ANALYTICS
        : ACCOUNT_LEADER_ANALYTICS,
    };

    openModal({
      modalType: REASONS_DIMISS_MODAL,
      modalProps: {
        collaborator,
        leaderType: trackData.type,
        requestId,
      }
    });

    trackLeadershipRequestDecline(trackData);
  };

  function handleAcceptLeader(requestId, isMainRequest, requester) {
    const leader = `${requester?.firstName} ${requester?.lastName}`;
    const modalProps = {
      isMainRequest,
      leader,
      newWordingForLeaders,
    };
    const modalType = ADD_LEADER_REQUEST_MODAL;

    putChangeLeader(requestId, APPROVED, null, null, identification, null, modalType, modalProps);
  };

  function handleDeclineLeader(requestId) {
    putChangeLeader(requestId, CANCELED);
  };

  const orderedRequests = orderBy(requests, ['status'], ['asc']);

  return (
    <Segment loading={loading} loadingType="betterme">
      {hasLeadershipRequestReceived ? (
        <InfiniteScrollLoad
          dataLength={requests.length}
          hasMore={hasMoreLeadershipRequestReceived}
          loadingType="betterme"
          next={handleGetMoreLeadershipRequests}
          scrollableTarget="root"
        >
          {orderedRequests?.map((request, index) => {
            const {
              collaborator,
              isRequesterCurrentLeader,
              requester,
              requestId,
              type,
              sentDate,
            } = request;
            const requesterUserName = `${requester?.firstName} ${requester?.lastName}`;
            const collaboratorName = `${collaborator?.firstName} ${collaborator?.lastName}`;
            const date = formatDateV2(sentDate, 'monthDay');
            const isMainLeader = type === CHANGE_LEADER_REQUEST_AS_MAIN;
            const isSecondaryLeader = type === CHANGE_LEADER_REQUEST_AS_SECONDARY;
            const isMainRequest = type === ADD_TO_MY_TEAM_REQUEST_AS_MAIN;
            const isChangeLeaderRequest = isMainLeader || isSecondaryLeader;
            const leaderEnabled = requester?.enabled;
            const mainLeaderTitle = newWordingForLeaders ? newCareerLeader : newMainLeader;
            const secondaryLeaderTitle = newWordingForLeaders ? newAccountLeader : newSecondaryLeader;
            const newLeaderTitle = isMainLeader ? mainLeaderTitle : secondaryLeaderTitle;
            const newLeaderRequestTitle = isMainRequest ? mainLeaderTitle : secondaryLeaderTitle;
            const mainLeaderRequest = newWordingForLeaders ? careerLeader : mainLeader;
            const secondaryLeaderRequest = newWordingForLeaders ? accountLeader : secondaryLeader;
            const newRequestTitle = isMainRequest ? mainLeaderRequest : secondaryLeaderRequest;
            const mainLeaderMessage = newWordingForLeaders ? careerLeaderMsg : mainLeaderMsg;
            const secondaryLeaderMessage = newWordingForLeaders ? accountLeaderMsg : secondaryLeaderMsg;
            const typeMessage = isMainRequest ? mainLeaderMessage : secondaryLeaderMessage;
            const isAssignedRequest = request?.status === 'ASSIGNED_CREATED';
            const leadershipRequestGotIt = [
              {
                label: gotItText,
                onClick: () => gotIt(requestId),
              },
            ];
            const leadershipRequestActionButtons = [
              {
                label: cancelText,
                onClick: () => handleDecline(collaboratorName, requestId),
                variant: 'outline',
              },
              {
                label: acceptText,
                onClick: () => handleAccept(collaboratorName, requestId),
              },
            ];
            const addToMyTeamActionButtons = [
              {
                label: cancelText,
                onClick: () => handleDeclineLeader(requestId),
                variant: 'outline',
              },
              {
                label: acceptText,
                onClick: () => handleAcceptLeader(requestId, isMainRequest, requester),
              },
            ];

            return (
              <>
                {isAssignedRequest && (
                  <ChangeLeaderCardDisabled
                    actionButtons={leadershipRequestGotIt}
                    approverCard={{
                      approverHighlight: true,
                      approverTitle: newLeaderTitle,
                    }}
                    buttonsDisabled={isRequesterCurrentLeader}
                    commentTitle={commentTitle}
                    disclaimer={disclaimer}
                    emptyStateText={leaderEmptyStateText}
                    footer={{
                      sentBy,
                      sentByName: requesterUserName,
                    }}
                    key={index}
                    leaderEnabled={leaderEnabled}
                    request={request}
                    requesterCard={{
                      requesterHighlight: true,
                      requesterTitle: currentLeader,
                    }}
                    sentDate={date}
                    title={leaderSubstitution}
                  />
                )}
                {!isAssignedRequest && (isChangeLeaderRequest ? (
                  <ChangeLeaderCard
                    actionButtons={leadershipRequestActionButtons}
                    approverCard={{
                      approverHighlight: true,
                      approverTitle: newLeaderTitle,
                    }}
                    buttonsEnabled={true}
                    commentTitle={commentTitle}
                    emptyState={{
                      emptyStateText: leaderEmptyStateText,
                      emptyStateTitle: currentLeader,
                      emptyStateImage: leaderEmptyStateImage,
                    }}
                    footer={{
                      sentBy,
                      sentByName: requesterUserName,
                    }}
                    key={index}
                    leaderEnabled={leaderEnabled}
                    request={request}
                    requesterCard={{
                      requesterTitle: currentLeader,
                    }}
                    sentDate={date}
                    title={leaderSubstitution}
                  />
                ) : (
                  <ReplaceLeaderReceived
                    actionButtons={addToMyTeamActionButtons}
                    buttonsEnabled={true}
                    leaderTitle={newLeaderRequestTitle}
                    request={request}
                    sentDate={date}
                    title={newRequestTitle}
                    message={`${requesterUserName} ${typeMessage}`}
                  />
                ))}
              </>
            );
          })}
        </InfiniteScrollLoad>
      ) : (
        <div className="bg-white text-center px-5 py-20">
          <GenericEmptyState
            className=""
            description={emptyStateText}
            image="listCheck"
            limitTextWidth={false}
            size="large"
            title={emptyStateTitle}
          />

          <Link
            className="block mt-5 !underline"
            to={navigateTo}
          >
            {navigateToText}
          </Link>
        </div>
      )}
    </Segment>
  );
};

LeadershipRequestReceived.defaultProps = {
  requests: [],
};

LeadershipRequestReceived.propTypes = {
  addLeaderRequest: PropTypes.func.isRequired,
  buttonsText: PropTypes.arrayOf(PropTypes.shape({
    acceptText: PropTypes.string.isRequired,
    cancelText: PropTypes.string.isRequired,
    gotItText: PropTypes.string.isRequired,
  })),
  commentTitle: PropTypes.string.isRequired,
  disclaimer: PropTypes.string.isRequired,
  emptyState: PropTypes.arrayOf(PropTypes.shape({
    emptyStateText: PropTypes.string.isRequired,
    emptyStateTitle: PropTypes.string.isRequired,
    navigateTo: PropTypes.func.isRequired,
    navigateToText: PropTypes.string.isRequired,
  })),
  footer: PropTypes.arrayOf(PropTypes.shape({
    sentBy: PropTypes.string.isRequired
  })),
  getLeadershipRequestsReceived: PropTypes.func.isRequired,
  leadersTitle: PropTypes.arrayOf(PropTypes.shape({
    currentLeader: PropTypes.string.isRequired,
    newAccountLeader: PropTypes.string.isRequired,
    newCareerLeader: PropTypes.string.isRequired,
    newMainLeader: PropTypes.string.isRequired,
    newSecondaryLeader: PropTypes.string.isRequired,
  })),
  leaderEmptyState: PropTypes.arrayOf(PropTypes.shape({
    leaderEmptyStateText: PropTypes.string.isRequired,
    leaderEmptyStateImage: PropTypes.string.isRequired,
  })),
  loading: PropTypes.bool.isRequired,
  messages: PropTypes.arrayOf(PropTypes.shape({
    accountLeaderMsg: PropTypes.string.isRequired,
    careerLeaderMsg: PropTypes.string.isRequired,
    mainLeaderMsg: PropTypes.string.isRequired,
    secondaryLeaderMsg: PropTypes.string.isRequired,
  })),
  newWordingForLeaders: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  page: PropTypes.arrayOf(PropTypes.shape({
    number: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
  })),
  putChangeLeader: PropTypes.func.isRequired,
  requests: PropTypes.array.isRequired,
  requestTitle: PropTypes.arrayOf(PropTypes.shape({
    accountLeader: PropTypes.string.isRequired,
    careerLeader: PropTypes.string.isRequired,
    leaderSubstitution: PropTypes.string.isRequired,
    mainLeader: PropTypes.string.isRequired,
    secondaryLeader: PropTypes.string.isRequired,
  })),
  user: PropTypes.object.isRequired,
};

const mapSateToProps = (state) => ({
  loading: selectLoadingLeadershipRequestsReceived(state),
  page: selectGetLeadershipRequestsPage(state),
  requests: selectGetLeadershipRequestsReceived(state),
});

const mapDispatchToProps = {
  ...actions,
  gotIt,
  putChangeLeader,
  openModal,
};

export default connect(
  mapSateToProps,
  mapDispatchToProps
)(LeadershipRequestReceived);
