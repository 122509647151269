// @packages
import React from 'react';
import PropTypes from 'prop-types';

// @own
import './styles.scss';

const ChartTooltip = ({ payload, total }) => {
  if (!payload || payload.length === 0 || payload.length > 1) {
    return null;
  }
  const { value } = payload[0];
  const percentage = value * 100 / total;

  return (
    <div className="chart-tooltip">
      {`${Math.round(percentage)}%`}
    </div>
  );
};

ChartTooltip.propTypes = {
  payload: PropTypes.array,
  total: PropTypes.number.isRequired,
};

export default ChartTooltip;
