import {
  GET_LEADER_MODAL_INFO,
  GET_LEADER_MODAL_INFO_FAIL,
  GET_LEADER_MODAL_INFO_SUCCESS,
} from './actionTypes';

export function getLeaderRequestModalInfo(requestId) {
  return {
    type: GET_LEADER_MODAL_INFO,
    payload: { requestId },
  };
}

export function getLeaderRequestModalInfoFail(error) {
  return {
    type: GET_LEADER_MODAL_INFO_FAIL,
    payload: { error },
  };
}

export function getLeaderRequestModalInfoSuccess(payload) {
  return {
    type: GET_LEADER_MODAL_INFO_SUCCESS,
    payload
  };
}
