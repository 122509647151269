// @packages
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

// @app
import { selectRatings } from 'betterme-components/services/Layout/selectors';
import TeamFeedbacks from '../../TeamFeedbacks';
import Main from '../../Main';

// @own
import * as actions from '../actions';
import {
  trackBoxOpportunitySendFeedbackEmpty,
  trackBoxOpportunitySendFeedbackLink,
  trackBoxStrengthsSendFeedbackEmpty,
} from '../analytics';
import {
  selectFeedbacksSummaryTeam,
  selectLoadingOpportunities,
  selectLoadingStrenghts,
  selectLoadingSummaryTeam,
  selectOpportunities,
  selectOpportunitiesRating,
  selectStrengths,
  selectStrengthsRating,
} from '../selectors';
import CompetencyBoxes from '../CompetencyBoxes';
import TeamFeedbackReceived from '../TeamFeedbackReceived';

class TeamFeedbacksTab extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.getFeedbacksSummaryTeam();
    this.getTeamOpportunities();
    this.getTeamStrengths();
  }

  componentDidUpdate(prevProps) {
    const {
      myTeamFilters: {
        assignee: prevAssignee,
        endDate: prevEndDate,
        startDate: prevStartDate,
      }
    } = prevProps;
    const {
      myTeamFilters: {
        assignee: currentAssignee,
        endDate: currentEndDate,
        startDate: currentStartDate,
      }
    } = this.props;
    const prevAssigneeId = prevAssignee && prevAssignee.id;
    const currentAssigneeId = currentAssignee && currentAssignee.id;
    const hasAssigneeChanged = prevAssigneeId !== currentAssigneeId;
    const hasDateChanged = (prevEndDate !== currentEndDate) || (prevStartDate !== currentStartDate);

    if (hasDateChanged || hasAssigneeChanged) {
      this.getFeedbacksSummaryTeam();
      this.getTeamOpportunities();
      this.getTeamStrengths();
    }
  }

  componentWillUnmount() {
    const { resetTeamDashboardState } = this.props;

    resetTeamDashboardState();
  }

  getTeamOpportunities() {
    const { getTeamOpportunities, user } = this.props;
    const { myTeamFilters: { endDate, startDate } } = this.props;
    const from = startDate ? startDate.valueOf() : undefined;
    const to = endDate ? endDate.valueOf() : undefined;

    getTeamOpportunities(user.id, from, to);
  }

  getTeamStrengths() {
    const { getTeamStrengths, user } = this.props;
    const { myTeamFilters: { endDate, startDate } } = this.props;
    const from = startDate ? startDate.valueOf() : undefined;
    const to = endDate ? endDate.valueOf() : undefined;

    getTeamStrengths(user.id, from, to);
  }

  getFeedbacksSummaryTeam() {
    const { getFeedbacksSummaryTeam, user } = this.props;
    const { myTeamFilters: { endDate, startDate } } = this.props;
    const from = startDate ? startDate.valueOf() : undefined;
    const to = endDate ? endDate.valueOf() : undefined;

    getFeedbacksSummaryTeam(user.id, from, to);
  }

  render() {
    const {
      feedbacksSummaryTeam,
      intl: { formatMessage },
      loadingOpportunities,
      loadingStrenghts,
      loadingSummaryTeam,
      myTeamFilters,
      opportunities,
      opportunitiesRating,
      ratings,
      strengths,
      strengthsRating,
    } = this.props;

    const {
      assignee,
      endDate,
      startDate,
    } = myTeamFilters;

    return (
      <Fragment>
        <Main
          Sidenav={
            <CompetencyBoxes
              intl={{ formatMessage }}
              overallOpportunities={{
                loading: loadingOpportunities,
                newStyle: true,
                opportunities,
                rating: opportunitiesRating,
                ratings,
                trackEmptyLink: trackBoxOpportunitySendFeedbackEmpty,
                trackLink: trackBoxOpportunitySendFeedbackLink,
              }}
              overallStrenghts={{
                loading: loadingStrenghts,
                rating: strengthsRating,
                ratings,
                strengths,
                newStyle: true,
                trackEmptyLink: trackBoxStrengthsSendFeedbackEmpty,
              }}
            />
          }
          Main={
            <TeamFeedbacks
              assignee={assignee}
              from={startDate}
              newStyle
              to={endDate}
              view="team"
            />
          }
          RightColumn={
            <TeamFeedbackReceived
              collaboratorsList={feedbacksSummaryTeam}
              loading={loadingSummaryTeam}
              newStyle
            />
          }
        />
      </Fragment>
    );
  }
}

TeamFeedbacksTab.propTypes = {
  feedbacksSummaryTeam: PropTypes.array.isRequired,
  getFeedbacksSummaryTeam: PropTypes.func.isRequired,
  getTeamOpportunities: PropTypes.func.isRequired,
  getTeamStrengths: PropTypes.func.isRequired,
  loadingOpportunities: PropTypes.bool,
  loadingStrenghts: PropTypes.bool,
  loadingSummaryTeam: PropTypes.bool,
  opportunities: PropTypes.array.isRequired,
  opportunitiesRating: PropTypes.object,
  ratings: PropTypes.array.isRequired,
  strengths: PropTypes.array.isRequired,
  strengthsRating: PropTypes.object,
};

const mapStateToProps = (state) => ({
  feedbacksSummaryTeam: selectFeedbacksSummaryTeam(state),
  loadingOpportunities: selectLoadingOpportunities(state),
  loadingStrenghts: selectLoadingStrenghts(state),
  loadingSummaryTeam: selectLoadingSummaryTeam(state),
  opportunities: selectOpportunities(state),
  opportunitiesRating: selectOpportunitiesRating(state),
  ratings: selectRatings(state),
  strengths: selectStrengths(state),
  strengthsRating: selectStrengthsRating(state),
});

export default connect(mapStateToProps, actions)(injectIntl(TeamFeedbacksTab));
