// @packages
import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

// @app
import ProgressSlider from 'common/ProgressSlider';

// @own
import './styles.scss';

function mapBarProgressToScore(progress, scores) {
  if (progress === 0) {
    return 0;
  }

  const rangeMin = scores[0].min;
  const rangeMax = scores[scores.length - 1].max;
  const progressToScore = rangeMin + progress / 100 * (rangeMax - rangeMin);

  return Number(progressToScore.toFixed(2));
}

function mapScoreToBarProgress(progress, scores) {
  if (progress === 0) {
    return 0;
  }

  const rangeMin = scores[0].min;
  const rangeMax = scores[scores.length - 1].max;
  const percentage = 100 * (progress - rangeMin) / (rangeMax - rangeMin);

  return percentage;
}

function handleKey(type, { progress, onChange, isPercentage, scores }) {
  const direction = type === 'increment' ? 1 : -1;
  const magnitude = isPercentage ? 1 : 0.01;
  const step = magnitude * direction;
  const rangeMin = isPercentage ? 0 : scores[0].min;
  const rangeMax = isPercentage ? 100 : scores[scores.length - 1].max;
  const newProgress = Math.min(Math.max(step + progress, rangeMin), rangeMax);

  onChange(newProgress);
}

const ContinuousProgressBar = ({
  className,
  editing,
  isPercentage,
  onChange,
  progress,
  scores,
  ...props
}) => {
  const popupContent = isPercentage
    ? `${Math.round(progress)}%`
    : `${progress.toFixed(2)}`;
  const handleKeyArgs = { progress, onChange, isPercentage, scores };
  const handleKeyDecrement = () => handleKey('decrement', handleKeyArgs);
  const handleKeyIncrement = () => handleKey('increment', handleKeyArgs);

  return (
    <div className={cn('continuous-progress-bar', className)}>
      <ProgressSlider
        {...props}
        editing={editing}
        popupContent={popupContent}
        onChange={isPercentage
          ? (value) => onChange(Math.round(value))
          : (value) => onChange(mapBarProgressToScore(value, scores))
        }
        progress={isPercentage
          ? progress
          : mapScoreToBarProgress(progress, scores)
        }
        showPopup={!editing}
        onKeyDecrement={handleKeyDecrement}
        onKeyIncrement={handleKeyIncrement}
      />
    </div>
  );
};

ContinuousProgressBar.propTypes = {
  editing: PropTypes.bool,
  isPercentage: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  progress: PropTypes.number.isRequired,
  scores: PropTypes.array.isRequired,
};

export default ContinuousProgressBar;
