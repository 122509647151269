export function handleCommentChange(event, dataEvaluation, setDataCreateEvaluation, setComment) {
  setComment(event.target.value);
  let newDataEvaluation = {
    ...dataEvaluation,
    comment: event.target.value,
  };
  setDataCreateEvaluation({ ...newDataEvaluation });
};

export function handleScoreChange({
  value,
  dataEvaluation,
  setDataCreateEvaluation,
  setScore,
  setCoEvaluatorScoreMessagge,
  trackEvaluationsSelectedScore,
  coCreatedScore,
  hasTechnicalInput,
}) {
  const isTechnicalSuggestion = hasTechnicalInput && coCreatedScore === value;
  setScore(parseInt(value, 10));

  let newDataEvaluation = {
    ...dataEvaluation,
    score: value,
  };

  if (hasTechnicalInput) {
    if (value !== coCreatedScore) {
      setCoEvaluatorScoreMessagge(true);
    } else {
      setCoEvaluatorScoreMessagge(false);
    }
    if (trackEvaluationsSelectedScore) trackEvaluationsSelectedScore(value, dataEvaluation?.type, isTechnicalSuggestion);
  }

  setDataCreateEvaluation({ ...newDataEvaluation });
};
