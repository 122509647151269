// @packages
import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

// @own
import './styles.scss';
import EvaluationListEmptyState from './EvaluationListEmptyState';
import EvaluationListHeader from './EvaluationListHeader';
import EvaluationListItem from './EvaluationListItem';

const EvaluationList = ({
  className,
  evaluations,
  headerClassName,
  isByScore,
  isGoalScoreContinous,
  onItemClick,
  own,
  showGlobalScore,
  showGoalsFinalScore,
  userName,
}) => (
  <ul className={cn('evaluation-list', className)}>
    {evaluations.length > 0 ? (
      <React.Fragment>
        <EvaluationListHeader
          className={headerClassName}
          isByScore={isByScore}
          showGlobalScore={showGlobalScore}
          showGoalsFinalScore={showGoalsFinalScore}
        />
        {evaluations.map((evaluation) => (
          <EvaluationListItem
            {...evaluation}
            className="evaluation-list__item"
            isByScore={isByScore}
            isGoalScoreContinous={isGoalScoreContinous}
            key={evaluation.id}
            onClick={() => onItemClick(evaluation)}
            showGlobalScore={showGlobalScore}
            showGoalsFinalScore={showGoalsFinalScore}
          />
        ))}
      </React.Fragment>
    ) : (
      <EvaluationListEmptyState own={own} userName={userName} />
    )}
  </ul>
);


EvaluationList.defaultProps = {
  onItemClick: () => undefined,
};

EvaluationList.propTypes = {
  className: PropTypes.string,
  evaluations: PropTypes.array.isRequired,
  headerClassName: PropTypes.string,
  isByScore: PropTypes.bool,
  isGoalScoreContinous: PropTypes.bool.isRequired,
  onItemClick: PropTypes.func,
  own: PropTypes.bool,
  showGlobalScore: PropTypes.bool,
  showGoalsFinalScore: PropTypes.bool,
  userName: PropTypes.string,
};

export default EvaluationList;
