import { defineMessages } from 'react-intl';

export default defineMessages({
  EvaluationsEvaluations: {
    id: 'Betterme.Messages.Evaluations.Evaluations.All',
    defaultMessage: 'My Team\'s Evaluations',
  },
  EvaluationsEvaluationsGlc: {
    id: 'Betterme.Messages.Evaluations.Evaluations.Glc',
    defaultMessage: 'My Team\'s Evaluations',
  },
  EvaluationsContinuousEvaluation: {
    id: 'Betterme.Messages.Evaluations.ContinuousEvaluation.All',
    defaultMessage: 'Continuous evaluation',
  },
  EvaluationsContinuousEvaluationGlc: {
    id: 'Betterme.Messages.Evaluations.ContinuousEvaluation.Glc',
    defaultMessage: 'Continuous evaluation',
  },
  EvaluationsEvaluation: {
    id: 'Betterme.Messages.Evaluations.Evaluation.All',
    defaultMessage: 'Evaluation',
  },
  EvaluationsEvaluationGlc: {
    id: 'Betterme.Messages.Evaluations.Evaluation.Glc',
    defaultMessage: 'Evaluation',
  },
  EvaluationsSelfEvaluation: {
    id: 'Betterme.Messages.Evaluations.SelfEvaluation.All',
    defaultMessage: 'Self-evaluation',
  },
  EvaluationsSelfEvaluationGlc: {
    id: 'Betterme.Messages.Evaluations.SelfEvaluation.Glc',
    defaultMessage: 'Self-evaluation',
  },
  EvaluationsNHEvaluation: {
    id: 'Betterme.Messages.Evaluations.NHEvaluation.All',
    defaultMessage: 'NH-Evaluation',
  },
  EvaluationsNHEvaluationGlc: {
    id: 'Betterme.Messages.Evaluations.NHEvaluation.Glc',
    defaultMessage: 'NH-Evaluation',
  },
  EvaluationsNHSelfEvaluation: {
    id: 'Betterme.Messages.Evaluations.NHSelfEvaluation.All',
    defaultMessage: 'NH-Self-eval',
  },
  EvaluationsNHSelfEvaluationGlc: {
    id: 'Betterme.Messages.Evaluations.NHSelfEvaluation.Glc',
    defaultMessage: 'NH-Self-eval',
  },
  EvaluationsSelfEvaluations: {
    id: 'Betterme.Messages.Evaluations.SelfEvaluations.All',
    defaultMessage: 'Self-evaluations',
  },
  EvaluationsSelfEvaluationsGlc: {
    id: 'Betterme.Messages.Evaluations.SelfEvaluations.Glc',
    defaultMessage: 'Self-evaluations',
  },
  EvaluationsCreate: {
    id: 'Betterme.Messages.Evaluations.Create.All',
    defaultMessage: 'Create evaluation',
  },
  EvaluationsCreateGlc: {
    id: 'Betterme.Messages.Evaluations.Create.Glc',
    defaultMessage: 'Create evaluation',
  },
  EvaluationsCreatePerformance: {
    id: 'Betterme.Messages.Evaluations.CreatePerformance',
    defaultMessage: 'Create Performance Retrospective',
  },
  EvaluationsEdit: {
    id: 'Betterme.Messages.Evaluations.Edit.All',
    defaultMessage: 'Edit evaluation',
  },
  EvaluationsEditGlc: {
    id: 'Betterme.Messages.Evaluations.Edit.Glc',
    defaultMessage: 'Edit evaluation',
  },
  EvaluationsEditPerformance: {
    id: 'Betterme.Messages.Evaluations.EditPerformance',
    defaultMessage: 'Edit Performance Retrospective',
  },
  EvaluationsCreateContinuous: {
    id: 'Betterme.Messages.Evaluations.CreateContinuous.All',
    defaultMessage: 'Create Continuous Evaluation',
  },
  EvaluationsCreateContinuousGlc: {
    id: 'Betterme.Messages.Evaluations.CreateContinuous.Glc',
    defaultMessage: 'Create Continuous Evaluation',
  },
  EvaluationsEditContinuous: {
    id: 'Betterme.Messages.Evaluations.EditContinuous.All',
    defaultMessage: 'Edit Continuous Evaluation',
  },
  EvaluationsEditContinuousGlc: {
    id: 'Betterme.Messages.Evaluations.EditContinuous.Glc',
    defaultMessage: 'Edit Continuous Evaluation',
  },
  EvaluationsCreateNH: {
    id: 'Betterme.Messages.Evaluations.CreateNH.All',
    defaultMessage: 'Create New Hire evaluation',
  },
  EvaluationsCreateNHGlc: {
    id: 'Betterme.Messages.Evaluations.CreateNH.Glc',
    defaultMessage: 'Create New Hire evaluation',
  },
  EvaluationsEditNH: {
    id: 'Betterme.Messages.Evaluations.EditNH.All',
    defaultMessage: 'Edit New Hire evaluation',
  },
  EvaluationsEditNHGlc: {
    id: 'Betterme.Messages.Evaluations.EditNH.Glc',
    defaultMessage: 'Edit New Hire evaluation',
  },
  EvaluationsCreateSelfEvaluation: {
    id: 'Betterme.Messages.Evaluations.CreateSelfEvaluation.All',
    defaultMessage: 'Create Self-evaluation',
  },
  EvaluationsCreateSelfEvaluationGlc: {
    id: 'Betterme.Messages.Evaluations.CreateSelfEvaluation.Glc',
    defaultMessage: 'Create Self-evaluation',
  },
  EvaluationsEditSelfEvaluation: {
    id: 'Betterme.Messages.Evaluations.EditSelfEvaluation.All',
    defaultMessage: 'Edit Self-evaluation',
  },
  EvaluationsEditSelfEvaluationGlc: {
    id: 'Betterme.Messages.Evaluations.EditSelfEvaluation.Glc',
    defaultMessage: 'Edit Self-evaluation',
  },
  EvaluationsCreateNHSelfEvaluation: {
    id: 'Betterme.Messages.Evaluations.CreateNHSelfEvaluation.All',
    defaultMessage: 'Create your first Self-evaluation',
  },
  EvaluationsCreateNHSelfEvaluationGlc: {
    id: 'Betterme.Messages.Evaluations.CreateNHSelfEvaluation.Glc',
    defaultMessage: 'Create your first Self-evaluation',
  },
  EvaluationsEditNHSelfEvaluation: {
    id: 'Betterme.Messages.Evaluations.EditNHSelfEvaluation.All',
    defaultMessage: 'Edit your first Self-evaluation',
  },
  EvaluationsEditNHSelfEvaluationGlc: {
    id: 'Betterme.Messages.Evaluations.EditNHSelfEvaluation.Glc',
    defaultMessage: 'Edit your first Self-evaluation',
  },
  EvaluationsMyEvaluations: {
    id: 'Betterme.Messages.Evaluations.MyEvaluations.All',
    defaultMessage: 'My Evaluations',
  },
  EvaluationsMyEvaluationsGlc: {
    id: 'Betterme.Messages.Evaluations.MyEvaluations.Glc',
    defaultMessage: 'My Evaluations',
  },
  EvaluationsMyTeamEvaluations: {
    id: 'Betterme.Messages.Evaluations.MyTeamEvaluations.All',
    defaultMessage: 'My team\'s evaluations',
  },
  EvaluationsMyTeamEvaluationsGlc: {
    id: 'Betterme.Messages.Evaluations.MyTeamEvaluations.Glc',
    defaultMessage: 'My team\'s evaluations',
  },
  EvaluationsLastEvaluation: {
    id: 'Betterme.Messages.Evaluations.LastEvaluation.All',
    defaultMessage: 'Last Evaluation',
  },
  EvaluationsLastEvaluationGlc: {
    id: 'Betterme.Messages.Evaluations.LastEvaluation.Glc',
    defaultMessage: 'Last Evaluation',
  },
  EvaluationsEvaluationRange: {
    id: 'Betterme.Messages.Evaluations.EvaluationRange.All',
    defaultMessage: 'Evaluation Range',
  },
  EvaluationsDatenRange: {
    id: 'Betterme.Messages.Evaluations.DateRange',
    defaultMessage: 'Date Range',
  },
  EvaluationsEvaluationRangeGlc: {
    id: 'Betterme.Messages.Evaluations.EvaluationRange.Glc',
    defaultMessage: 'Evaluation Range',
  },
  EvaluationsPlaceholderScores: {
    id: 'Betterme.Messages.Evaluations.PlaceholderScores.All',
    defaultMessage: 'Choose an overall score for the Evaluation',
  },
  EvaluationsPlaceholderScoresGlc: {
    id: 'Betterme.Messages.Evaluations.PlaceholderScores.Glc',
    defaultMessage: 'Choose an overall score for the Evaluation',
  },
  EvaluationsCompletedEvaluations: {
    id: 'Betterme.Evaluations.CompletedEvaluations.All',
    defaultMessage: 'Completed Evaluations',
  },
  EvaluationsCompletedEvaluationsGlc: {
    id: 'Betterme.Evaluations.CompletedEvaluations.Glc',
    defaultMessage: 'Completed Evaluations',
  },
  EvaluationsEmptyStateOwnReceivedTitle: {
    id: 'Betterme.Evaluations.EmptyStateOwnReceivedTitle.All',
    defaultMessage: 'You haven\'t received any Evaluations for this period yet',
  },
  EvaluationsEmptyStateOwnReceivedTitleGlc: {
    id: 'Betterme.Evaluations.EmptyStateOwnReceivedTitle.Glc',
    defaultMessage: 'You haven\'t received any Evaluations for this period yet',
  },
  EvaluationsEmptyStateOtherReceivedTitle: {
    id: 'Betterme.Evaluations.EmptyStateOtherReceivedTitle.All',
    defaultMessage: 'hasn\'t received any Evaluations for this period yet',
  },
  EvaluationsEmptyStateOtherReceivedTitleGlc: {
    id: 'Betterme.Evaluations.EmptyStateOtherReceivedTitle.Glc',
    defaultMessage: 'hasn\'t received any Evaluations for this period yet',
  },
  EvaluationsEmptyStateUserReceivedTitle: {
    id: 'Betterme.Evaluations.EmptyStateUserReceivedTitle.All',
    defaultMessage: '{username} hasn\'t received any Evaluations for this period yet',
  },
  EvaluationsEmptyStateUserReceivedTitleGlc: {
    id: 'Betterme.Evaluations.EmptyStateUserReceivedTitle.Glc',
    defaultMessage: '{username} hasn\'t received any Evaluations for this period yet',
  },
  EvaluationsNineBoxPlaceholder: {
    id: 'Betterme.Evaluations.NineBoxPlaceholder.All',
    defaultMessage: 'Choose a 9 Box score for the Evaluation',
  },
  EvaluationsNineBoxPlaceholderGlc: {
    id: 'Betterme.Evaluations.NineBoxPlaceholder.Glc',
    defaultMessage: 'Choose a 9 Box score for the Evaluation',
  },
  EvaluationsTypeEvaluation: {
    id: 'Betterme.Evaluations.TypeEvaluation.All',
    defaultMessage: 'Evaluation Type',
  },
  EvaluationsTypeEvaluationGlc: {
    id: 'Betterme.Evaluations.TypeEvaluation.Glc',
    defaultMessage: 'Evaluation Type',
  },
  EvaluationsTypeEvaluationPlaceholder: {
    id: 'Betterme.Evaluations.TypeEvaluationPlaceholder.All',
    defaultMessage: 'Select Evaluation Type',
  },
  EvaluationsTypeEvaluationPlaceholderGlc: {
    id: 'Betterme.Evaluations.TypeEvaluationPlaceholder.Glc',
    defaultMessage: 'Select Evaluation Type',
  },
  EvaluationsPeriodEvaluation: {
    id: 'Betterme.Evaluations.PeriodEvaluation.All',
    defaultMessage: 'Period Evaluation',
  },
  EvaluationsPeriodEvaluationGlc: {
    id: 'Betterme.Evaluations.PeriodEvaluation.Glc',
    defaultMessage: 'Period Evaluation',
  },
  EvaluationsOverallScore: {
    id: 'Betterme.Messages.Evaluations.OverallScore',
    defaultMessage: 'Overall score',
  },
  EvaluationsGeneralRemarks: {
    id: 'Betterme.Messages.Evaluations.GeneralRemarks',
    defaultMessage: 'General Remarks',
  },
  EvaluationsGeneralRemarksSubtitle: {
    id: 'Betterme.Messages.Evaluations.GeneralRemarksSubtitle',
    defaultMessage: 'Please, tell us more about your first self evaluation, every input will help us to improve.',
  },
  EvaluationsNineBoxScore: {
    id: 'Betterme.Messages.Evaluations.NineBoxScore',
    defaultMessage: '9 box score',
  },
  EvaluationsGoalsFinalScore: {
    id: 'Betterme.Messages.Evaluations.GoalsFinalScore',
    defaultMessage: 'Goals Score',
  },
  EvaluationsSubmittedDate: {
    id: 'Betterme.Messages.Evaluations.SubmittedDate',
    defaultMessage: 'Submitted Date',
  },
  EvaluationsType: {
    id: 'Betterme.Messages.Evaluations.Type',
    defaultMessage: 'Type',
  },
  EvaluationsSuggestedActions: {
    id: 'Betterme.Messages.Evaluations.SuggestedActions',
    defaultMessage: 'Suggested Actions',
  },
  EvaluationsCreated: {
    id: 'Betterme.Messages.Evaluations.Created',
    defaultMessage: 'Created',
  },
  EvaluationsPending: {
    id: 'Betterme.Messages.Evaluations.Pending',
    defaultMessage: 'Pending',
  },
  EvaluationsGlobalScore: {
    id: 'Betterme.Messages.Evaluations.GlobalScore',
    defaultMessage: 'Global Score',
  },
  EvaluationsCareerLeader: {
    id: 'Betterme.Messages.Evaluations.CareerLeader2',
    defaultMessage: 'As Career Mentor',
  },
  EvaluationsAccountLeader: {
    id: 'Betterme.Messages.Evaluations.AccountLeader2',
    defaultMessage: 'As Experience Leader',
  },
  EvaluationsOtherLeaders: {
    id: 'Betterme.Messages.Evaluations.OtherLeaders2',
    defaultMessage: 'As Experience Leaders',
  },
  EvaluationsCareerAndAccountLeader: {
    id: 'Betterme.Messages.Evaluations.CareerAndAccountLeader2',
    defaultMessage: 'As Career Mentor & Experience Leader',
  },
  EvaluationsCareerAndOtherLeaders: {
    id: 'Betterme.Messages.Evaluations.CareerAndOtherLeaders2',
    defaultMessage: 'As Career Mentor & Experience Leaders',
  },
  EvaluationsMainLeader: {
    id: 'Betterme.Messages.Evaluations.MainLeader',
    defaultMessage: 'As Main Leader',
  },
  EvaluationsSecondaryLeader: {
    id: 'Betterme.Messages.Evaluations.SecondaryLeader',
    defaultMessage: 'As Secondary Leader',
  },
  EvaluationsMainAndSecondaryLeader: {
    id: 'Betterme.Messages.Evaluations.MainAndSecondaryLeader',
    defaultMessage: 'As Main & Secondary Leader',
  },
  EvaluationsTalentReview: {
    id: 'Betterme.Messages.Evaluations.TalentReview',
    defaultMessage: 'Talent review',
  },
  EvaluationsPotentialBox: {
    id: 'Betterme.Messages.Evaluations.PotentialBox',
    defaultMessage: 'Potential box',
  },
  EvaluationsFilterByTitle: {
    id: 'Betterme.Evaluations.FilterByTitle',
    defaultMessage: 'Filter by',
  },
  EvaluationsFilterBySubtitle: {
    id: 'Betterme.Evaluations.Subtitle.All',
    defaultMessage: 'Current Evaluation Status',
  },
  EvaluationsFilterBySubtitleGlc: {
    id: 'Betterme.Evaluations.Subtitle.Glc',
    defaultMessage: 'Current Evaluation Status',
  },
  EvaluationsFilterByStatusSubmited: {
    id: 'Betterme.Evaluations.StatusSubmited',
    defaultMessage: 'Submitted',
  },
  EvaluationsFilterByStatusDraft: {
    id: 'Betterme.Evaluations.StatusDraft',
    defaultMessage: 'Draft',
  },
  EvaluationsFilterByStatusForeignDraft: {
    id: 'Betterme.Evaluations.StatusForeignDraft',
    defaultMessage: 'Foreign Draft',
  },
  EvaluationsFilterByStatusNotStarted: {
    id: 'Betterme.Evaluations.StatusNotStarted.All',
    defaultMessage: 'Evaluation not started',
  },
  EvaluationsFilterByStatusNotStartedGlc: {
    id: 'Betterme.Evaluations.StatusNotStarted.Glc',
    defaultMessage: 'Evaluation not started',
  },
  EvaluationsFilterByApply: {
    id: 'Betterme.Evaluations.Apply',
    defaultMessage: 'Apply',
  },
  EvaluationsEmptyStateText: {
    id: 'Betterme.Evaluations.EmptyStateText',
    defaultMessage: 'There are no collaborators to show here',
  },
  EvaluationsCurrentStatus: {
    id: 'Betterme.Evaluations.CurrentStatus',
    defaultMessage: 'Current Evaluations Status',
  },
  EvaluationsRightNow: {
    id: 'Betterme.Evaluations.RightNow',
    defaultMessage: 'The evaluations you’re working on right now.',
  },
  EvaluationsViewAll: {
    id: 'Betterme.Evaluations.ViewAll',
    defaultMessage: 'View all',
  },
  PerformanceRetrospective: {
    id: 'Betterme.Evaluations.PerformanceRetrospective',
    defaultMessage: 'Performance Retrospective',
  },
  SelfRetrospectiveCreate: {
    id: 'Betterme.Messages.SelfRetrospective.Create',
    defaultMessage: 'Create Self-Retrospective',
  },
  SelfRetrospectiveEdit: {
    id: 'Betterme.Messages.SelfRetrospective.Edit',
    defaultMessage: 'Edit Self-Retrospective',
  },
  SelfRetrospective: {
    id: 'Betterme.Evaluations.SelfRetrospective',
    defaultMessage: 'Self-Retrospective',
  },
  EvaluationsFilterByStatusSubmitedByMe: {
    id: 'Betterme.Evaluations.StatusSubmitedByMe',
    defaultMessage: 'Submitted by Me',
  },
  EvaluationsFilterByStatusSubmitedByOther: {
    id: 'Betterme.Evaluations.StatusSubmitedByOther',
    defaultMessage: 'Submitted by Other',
  },
});
