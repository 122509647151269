// @packages
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';

// @own
import { getTierType } from './api';
import { GET_TIER_TYPE } from './actionTypes';
import {
  getTierTypeFail,
  getTierTypeSuccess,
  tierErrorMessage,
} from './actions';

function* getTierTypeWorker({ payload: { userId, evaluationType}}) {

  try {
    const response = yield call(getTierType, userId, { type: evaluationType });
    yield put(tierErrorMessage(false));
    yield put(getTierTypeSuccess(response));
  } catch (error) {
    yield put(getTierTypeFail(error.message));
    yield put(tierErrorMessage(true));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

export default function* getTierTypeWatcher() {
  yield takeLatest(GET_TIER_TYPE, getTierTypeWorker);
}
