import {
  GET_SKILLS_RATED,
  GET_SKILLS_RATED_FAIL,
  GET_SKILLS_RATED_SUCCESS,
  GET_SEARCH_SKILLS_RATED,
  GET_SEARCH_SKILLS_RATED_FAIL,
  GET_SEARCH_SKILLS_RATED_SUCCESS,
  SELECT_SKILL,
  SELECT_RATING_SKILL,
  SORT_SKILLS_RATED,
} from './actionTypes';

export function getSkillsRated(userId, params) {
  return {
    type: GET_SKILLS_RATED,
    payload: { userId, params },
  };
}

export function getSkillsRatedFail(error) {
  return {
    type: GET_SKILLS_RATED_FAIL,
    payload: { error },
  };
}

export function getSkillsRatedSuccess(response) {
  return {
    type: GET_SKILLS_RATED_SUCCESS,
    payload: { response },
  };
}

export function getSearchSkillsRated(userId, params) {
  return {
    type: GET_SEARCH_SKILLS_RATED,
    payload: { userId, params },
  };
}

export function getSearchSkillsRatedFail(error) {
  return {
    type: GET_SEARCH_SKILLS_RATED_FAIL,
    payload: { error },
  };
}

export function getSearchSkillsRatedSuccess(response) {
  return {
    type: GET_SEARCH_SKILLS_RATED_SUCCESS,
    payload: { response },
  };
}

export function selectSkill(skillId) {
  return {
    type: SELECT_SKILL,
    payload: { skillId }
  };
}

export function selectRatingSkill(ratingId) {
  return {
    type: SELECT_RATING_SKILL,
    payload: { ratingId }
  };
}

export function sortSkillsRated(order) {
  return {
    type: SORT_SKILLS_RATED,
    payload: { order },
  };
}
