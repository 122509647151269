import { defineMessages } from 'react-intl';

export default defineMessages({
  SuggestedActionsSuggestActions: {
    id: 'Betterme.Messages.SuggestedActions.SuggestActions',
    defaultMessage: 'Suggest Actions',
  },
  SuggestActionsEmptySuggestions: {
    id: 'Betterme.Messages.SuggestedActions.EmptySuggestions',
    defaultMessage: 'There are no suggested actions available.'
  },
  SuggestActionsPromotionButton: {
    id: 'Betterme.Messages.SuggestedActions.RequestPromotion.Button',
    defaultMessage: 'Request Promotion',
  },
  SuggestActionsPromotionHighTooltipTitle: {
    id: 'Betterme.Messages.SuggestedActions.High.TooltipTitle',
    defaultMessage: 'Ready to be promoted',
  },
  SuggestActionsPromotionHighTooltipDescription: {
    id: 'Betterme.Messages.SuggestedActions.High.TooltipDescription',
    defaultMessage: 'Promotion is recommended while all requirements are fulfilled. <a href="https://sites.google.com/globant.com/leadershipcommunity/developing-my-team/readiness-promotions?authuser=0" target="_blank"><u>More info here</u></a>',
  },
  SuggestActionsPromotionMediumTooltipTitle: {
    id: 'Betterme.Messages.SuggestedActions.Medium.TooltipTitle',
    defaultMessage: 'Promotion has to be assessed',
  },
  SuggestActionsPromotionMediumTooltipDescription: {
    id: 'Betterme.Messages.SuggestedActions.Medium.TooltipDescription',
    defaultMessage: 'Promotion is suggestable while some requirements are fulfilled. <a href="https://sites.google.com/globant.com/leadershipcommunity/developing-my-team/readiness-promotions?authuser=0" target="_blank"><u>More info here</u></a>',
  },
  SuggestActionsPromotionLowTooltipTitle: {
    id: 'Betterme.Messages.SuggestedActions.Low.TooltipTitle',
    defaultMessage: 'Promotion doesn\'t apply',
  },
  SuggestActionsPromotionLowTooltipDescription: {
    id: 'Betterme.Messages.SuggestedActions.Low.TooltipDescription',
    defaultMessage: 'Promotion is not available because one or more requirements are not fulfilled.',
  }
});
