// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import UserAvatar from 'smu-ui-components/UserAvatar';

// @own
import './styles.scss';

const UserDetailsInfo = ({
  className,
  fullName,
  job,
  profileImageCode,
  seniority,
  size,
}) => (
  <div className={cn('user-details-info', className)}>
    <UserAvatar
      bordered
      height={size}
      profileImageCode={profileImageCode}
      width={size}
    />
    <div className="user-details-info__content">
      <h3 className="user-details-info__name">
        {fullName}
      </h3>
      {job && (
        <span className="user-details-info__job">
          {job}
        </span>
      )}
      {seniority && (
        <span className="user-details-info__seniority">
          {seniority}
        </span>
      )}
    </div>
  </div>
);

UserDetailsInfo.defaultProps = {
  size: 70,
};

UserDetailsInfo.propTypes = {
  className: PropTypes.string,
  fullName: PropTypes.string.isRequired,
  job: PropTypes.string,
  profileImageCode: PropTypes.string.isRequired,
  seniority: PropTypes.string,
  size: PropTypes.number,
};

export default UserDetailsInfo;
