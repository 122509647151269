// @package
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import Segment from 'smu-ui-components/Segment';
import { openModal } from 'smu-app-components/RootModal/actions';

// @app
import { DELETE_FILE_MODAL } from 'betterme-components/constants/modalTypes';
import {
  fileDownload,
  fileDownloadReset,
} from 'betterme-components/services/DownloadFile/actions';
import { selectFile } from 'betterme-components/services/DownloadFile/selectors';
import DeleteFileModal from 'common/DeleteFileModal';
import UploadFile from 'betterme-components/components/organisms/UploadFile';
import promotionsMessages  from 'common/messages/promotions';

const UploadFileContainer = ({
  className,
  customText,
  disabled,
  file,
  fileDownload,
  fileDownloadReset,
  files,
  intl: { formatMessage },
  loading,
  onChange,
  onDeleteAccept,
  openModal,
  renderTitleText,
  supportedFormats,
}) => {
  const handleDelete = (fileId) => {
    openModal({
      modalType: DELETE_FILE_MODAL,
      modalProps: {
        fileId,
        onAccept: onDeleteAccept,
      },
    });
  };

  return (
    <Segment loading={loading} loadingType="betterme" withChildren>
      <UploadFile
        className={className}
        customText={customText}
        disabled={disabled}
        file={file}
        fileDownload={fileDownload}
        fileDownloadReset={fileDownloadReset}
        files={files}
        handleDelete={handleDelete}
        loading={loading}
        onChange={onChange}
        onDeleteAccept={onDeleteAccept}
        promotionsAttachedFilesMessages={formatMessage(promotionsMessages.PromotionsAttachedFiles)}
        promotionsSupportedFormatsMessages={formatMessage(promotionsMessages.PromotionsSupportedFormats)}
        renderTitleText={renderTitleText}
        supportedFormats={supportedFormats}
        textRemoveFile={formatMessage(promotionsMessages.PromotionsActionsRemoveFile)}
      />
      <DeleteFileModal />
    </Segment>
  );
};

UploadFileContainer.defaultProps = {
  className: '',
};

UploadFileContainer.propTypes = {
  className: PropTypes.string,
  customText: PropTypes.string,
  disabled: PropTypes.bool,
  file: PropTypes.object,
  fileDownload: PropTypes.func,
  fileDownloadReset: PropTypes.func,
  files: PropTypes.array,
  intl: PropTypes.object,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onDeleteAccept: PropTypes.func,
  openModal: PropTypes.func,
  supportedFormats: PropTypes.array,
};

const mapStateToProps = state => ({
  file: selectFile(state),
});

const mapDispatchToProps = {
  fileDownload,
  fileDownloadReset,
  openModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(UploadFileContainer));
