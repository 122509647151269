import {
  GET_FEEDBACK_FOR_LEADERS_RECEIVED,
  GET_FEEDBACK_FOR_LEADERS_RECEIVED_FAIL,
  GET_FEEDBACK_FOR_LEADERS_RECEIVED_SUCCESS,
  GET_FEEDBACK_FOR_LEADERS_REPORT,
  GET_FEEDBACK_FOR_LEADERS_REPORT_FAIL,
  GET_FEEDBACK_FOR_LEADERS_REPORT_SUCCESS,
} from './actionTypes';

export function getFeedbackForLeadersReceived(params) {
  return {
    type: GET_FEEDBACK_FOR_LEADERS_RECEIVED,
    payload: { params },
  };
}

export function getFeedbackForLeadersReceivedFail(error) {
  return {
    type: GET_FEEDBACK_FOR_LEADERS_RECEIVED_FAIL,
    payload: { error },
  };
}

export function getFeedbackForLeadersReceivedSuccess(response) {
  return {
    type: GET_FEEDBACK_FOR_LEADERS_RECEIVED_SUCCESS,
    payload: { response },
  };
}

export function getFeedbackForLeadersReport(payload) {
  return {
    type: GET_FEEDBACK_FOR_LEADERS_REPORT,
    payload,
  };
}

export function getFeedbackForLeadersReportFail(error) {
  return {
    type: GET_FEEDBACK_FOR_LEADERS_REPORT_FAIL,
    payload: { error },
  };
}

export function getFeedbackForLeadersReportSuccess(response) {
  return {
    type: GET_FEEDBACK_FOR_LEADERS_REPORT_SUCCESS,
    payload: { response },
  };
}
