// @packages
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Button from 'smu-ui-components/ButtonV2';
import Icon from 'smu-ui-components/IconV2';
import Segment from 'smu-ui-components/Segment';

// @own
import './styles.scss';

const CardsCarrousel = ({
  items,
  itemsMargin,
  loading,
}) => {
  const scrollerRef = useRef();
  const contentRef = useRef();
  const [sections, setSections] = useState(1);
  const [actualSection, setActualSection] = useState(1);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [itemsPerSection, setItemsPerSection] = useState(0);
  const [scrollAvailable, setScrollAvailable] = useState(true);

  function toScroll(newScroll, newSection) {
    if (scrollerRef.current) {
      scrollerRef.current.scroll({
        left: newScroll,
        behavior: 'smooth',
      });
    }

    setScrollPosition(newScroll);
    setActualSection(newSection);
  }

  function disableScroll() {
    setScrollAvailable(false);
    const timer = setTimeout(() => setScrollAvailable(true), 500);
    return () => clearTimeout(timer);
  }

  function scrollRight() {
    const widthChild = contentRef?.current?.firstChild?.offsetWidth;
    const scrollWidth = (widthChild * itemsPerSection) + (itemsMargin * itemsPerSection);
    const newScroll = actualSection < sections ? (
      actualSection === sections - 1 ? (
          contentRef?.current?.scrollWidth - scrollWidth
      ) : (
        scrollPosition +
        (widthChild * itemsPerSection) +
        (itemsMargin * itemsPerSection)
      )
    ) : scrollPosition;
    const newSection = actualSection < sections
    ? actualSection + 1
    : actualSection;

    disableScroll();
    toScroll(newScroll, newSection);
  }

  function scrollLeft() {
    const widthChild = contentRef?.current?.firstChild?.offsetWidth;
    const newScroll = actualSection !== 2 ? (
      scrollPosition -
      ((widthChild * itemsPerSection) +
      (itemsMargin * itemsPerSection))
    ) : 0;

    const newSection = actualSection !== 1 ? (
      actualSection - 1
    ) : actualSection;

    disableScroll();
    toScroll(newScroll, newSection);
  }

  useEffect(() => {
    if(!loading) {
      if (scrollerRef.current && contentRef.current) {
        const totalSections = Math.ceil(
          contentRef?.current?.clientWidth / scrollerRef?.current?.clientWidth
        );
        const widthChild = contentRef?.current?.firstChild?.offsetWidth + itemsMargin;
        const perSection = Math.floor(scrollerRef?.current?.offsetWidth / widthChild);
  
        setItemsPerSection(perSection);
        setSections(totalSections);
      }
    }
  }, [scrollerRef, contentRef, loading]);

  return (
    <Segment
      className="cards-carrousel"
      loading={loading}
      loadingType="betterme"
    >
      <Button
        className={cn(
          'cards-carrousel__side-button',
          'cards-carrousel__side-button--left',
          {'cards-carrousel__side-button--hidden': actualSection === 1 || items.length <= itemsPerSection },
        )}
        size="small"
        variant="outline"
        onClick={scrollAvailable ? scrollLeft : () => {}}
      >
        <Icon
          className="cards-carrousel__chevron-icon"
          color="gray-dark"
          icon="chevron-left"
          size="small"
        />
      </Button>
      <div className="cards-carrousel__scroller" ref={scrollerRef}>
        <div className="cards-carrousel__items" ref={contentRef}>
          {items.map(item =>
            <div className="cards-carrousel__item" style={{
              marginRight: itemsMargin,
            }}>
              {item}
            </div>
          )}
        </div>
      </div>
      <Button
        className={cn(
          'cards-carrousel__side-button',
          'cards-carrousel__side-button--right',
          { 'cards-carrousel__side-button--hidden': actualSection === sections || items.length <= 3 },
        )}
        size="small"
        variant="outline"
        onClick={scrollAvailable ? scrollRight : () => {}}
      >
        <Icon
          className="cards-carrousel__chevron-icon"
          color="gray-dark"
          icon="chevron-right"
          size="small"
        />
      </Button>
    </Segment>
  );
};

CardsCarrousel.defaultProps = {
  itemsMargin: 15,
  loading: false,
};

CardsCarrousel.propTypes = {
  items: PropTypes.array.isRequired,
  itemsMargin: PropTypes.number,
  loading: PropTypes.bool,
};

export default CardsCarrousel;
