// @packages
import React, { useMemo } from 'react';
import Card from 'smu-ui-components/Card';
import Panel from 'smu-ui-components/Panel';
import PropTypes from 'prop-types';
import UserProfileOther from 'smu-app-components/UserProfile/Other';
import cn from 'classnames';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { useMembersById } from 'smu-services/os-member/api/v1/community/members/[id]';
import { useRemainingStars } from 'smu-services/starmeup-api/v2/stellar/user/remaining/stars';

// @app
import {
  ENABLED_ANNIVERSARIES,
  ENABLED_BETTERME,
  ENABLED_BIRTHDAYS,
  ENABLED_ONE_ON_ONE,
  ENABLED_ONE_ON_ONE_REQUEST,
  ENABLED_STARMEUP,
  ENABLED_SURVEYS_ONLY,
} from 'betterme-components/services/CommunityConfigs/constants';
import { getCommunityConfigValueBool } from 'betterme-components/services/CommunityConfigs/selectors';

// @own
import { getContainerProps, helperActionsProps } from './helpers';

const UserProfileOtherContainer = ({
  actionsList,
  className,
  enabledAnniversaries,
  enabledBetterme,
  enabledBirthdays,
  enabledOneOnOne,
  enabledOneOnOneRequest,
  enabledStarmeup,
  enabledSurveysOnly,
  intl: { formatMessage },
  showPanel,
  showStars,
  userId,
  ...rest
}) => {
  const Container = showPanel ? Panel : Card;

  const containerProps = useMemo(
    () => getContainerProps({ showPanel }),
    [showPanel]
  );

  const remainingStars = useRemainingStars({ shouldFetch: enabledStarmeup });
  const member = useMembersById({ userId });

  const actionsProps = helperActionsProps({
    actionsList,
    enabledBetterme,
    enabledOneOnOne,
    enabledOneOnOneRequest,
    enabledStarmeup,
    formatMessage,
    member,
    remainingStars,
  });

  return (
    <Container className={cn('bme-user-profile', className)} {...containerProps}>
      <UserProfileOther
        actionsProps={actionsProps}
        bettemeUrl=""
        enabledAnniversaries={enabledAnniversaries}
        enabledBetterme={enabledBetterme}
        enabledBirthdays={enabledBirthdays}
        enabledOneOnOne={enabledOneOnOne}
        enabledOneOnOneRequest={enabledOneOnOneRequest}
        enabledStarmeup={enabledStarmeup}
        enabledSurveysOnly={enabledSurveysOnly}
        showCulturalLevels={enabledStarmeup}
        showQualifiers={false}
        showStars={false}
        userId={userId}
        {...rest}
      />
    </Container>
  );
};

UserProfileOtherContainer.defaultProps = {
  actionsList: [],
  enabledAnniversaries: false,
  enabledBetterme: false,
  enabledBirthdays: false,
  enabledOneOnOne: false,
  enabledOneOnOneRequest: false,
  enabledStarmeup: false,
  enabledSurveysOnly: false,
  showPanel: true,
};

UserProfileOtherContainer.propTypes = {
  actionsList: PropTypes.array,
  className: PropTypes.string,
  enabledAnniversaries: PropTypes.bool,
  enabledBetterme: PropTypes.bool,
  enabledBirthdays: PropTypes.bool,
  enabledOneOnOne: PropTypes.bool,
  enabledOneOnOneRequest: PropTypes.bool,
  enabledStarmeup: PropTypes.bool,
  enabledSurveysOnly: PropTypes.bool,
  intl: PropTypes.object,
  showPanel: PropTypes.bool,
  userId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  enabledAnniversaries: getCommunityConfigValueBool(state, ENABLED_ANNIVERSARIES),
  enabledBetterme: getCommunityConfigValueBool(state, ENABLED_BETTERME),
  enabledBirthdays: getCommunityConfigValueBool(state, ENABLED_BIRTHDAYS),
  enabledOneOnOne: getCommunityConfigValueBool(state, ENABLED_ONE_ON_ONE),
  enabledOneOnOneRequest: getCommunityConfigValueBool(state, ENABLED_ONE_ON_ONE_REQUEST),
  enabledStarmeup: getCommunityConfigValueBool(state, ENABLED_STARMEUP),
  enabledSurveysOnly: getCommunityConfigValueBool(state, ENABLED_SURVEYS_ONLY),
});

export default connect(mapStateToProps)(injectIntl(UserProfileOtherContainer));
