import {
  AUTHENTICATE_USER_FAIL,
  AUTHENTICATE_USER_SUCCESS,
  AUTHENTICATE_USER,
  GET_DRAFT_SELF_EVALUATION_FAIL,
  GET_DRAFT_SELF_EVALUATION_SUCCESS,
  GET_DRAFT_SELF_EVALUATION,
  GET_FEEDBACK_FOR_LEADERS_RATINGS_FAIL,
  GET_FEEDBACK_FOR_LEADERS_RATINGS_SUCCESS,
  GET_FEEDBACK_FOR_LEADERS_RATINGS,
  GET_USER_INFO_SUCCESS,
  GET_USER_TEAM_INFO_FAIL,
  GET_USER_TEAM_INFO_SUCCESS,
  GET_USER_TEAM_INFO,
  REFRESH_USER_INFO_SUCCESS,
  REFRESH_USER_INFO,
  RESET_DRAFT_SELF_EVALUATION,
  SET_COMMUNITY_INFO,
  UPDATE_PROFILE_IMAGE_FAIL,
  UPDATE_PROFILE_IMAGE_SUCCESS,
  UPDATE_PROFILE_IMAGE,
  UPDATE_USER_INFO_FAIL,
  UPDATE_USER_INFO_SUCCESS,
  UPDATE_USER_INFO,
} from './actionTypes';

const initialState = {
  communityConfigurations: null,
  errorFeedbackForLeadersRatings: '',
  feedbackForLeadersRatings: [],
  loading: false,
  loadingFeedbackForLeadersRatings: false,
  loadingProfile: false,
  loadingRefreshUserInfo: false,
  loadingUserTeamInfo: false,
  user: null,
  userDraftEvaluation: null,
  userDraftEvaluationLoading: false,
};

export function userReducer(state, { type, payload }) {
  switch (type) {
    case UPDATE_USER_INFO_SUCCESS: {
      return { ...state, profileImageCode: payload.response?.profileImageCode };
    }
    default:
      return state;
  }
}

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case AUTHENTICATE_USER:
      return { ...state, loading: true };

    case AUTHENTICATE_USER_FAIL:
      return { ...state, loading: false };

    case AUTHENTICATE_USER_SUCCESS:
      return { ...state, loading: false };

    case GET_USER_INFO_SUCCESS: {
      const { communityConfigurations, user } = payload;
      return {
        ...state,
        communityConfigurations,
        user,
      };
    }

    case GET_DRAFT_SELF_EVALUATION:
      return { ...state, userDraftEvaluationLoading: true };

    case GET_DRAFT_SELF_EVALUATION_FAIL:
      return { ...state, userDraftEvaluationLoading: false };

    case GET_DRAFT_SELF_EVALUATION_SUCCESS:
      const { response } = payload;
      return {
        ...state,
        userDraftEvaluationLoading: false,
        userDraftEvaluation: response.length > 0 ? response[0] : null,
      };
    case RESET_DRAFT_SELF_EVALUATION:
      return {
        ...state,
        userDraftEvaluationLoading: false,
        userDraftEvaluation: null,
      };

    case GET_USER_TEAM_INFO:
      return { ...state, loadingUserTeamInfo: true };

    case GET_USER_TEAM_INFO_FAIL:
      return { ...state, loadingUserTeamInfo: false };

    case GET_USER_TEAM_INFO_SUCCESS:
      return {
        ...state,
        loadingUserTeamInfo: false,
        user: payload.response,
      };

    case UPDATE_PROFILE_IMAGE:
    case UPDATE_USER_INFO:
      return { ...state, loadingProfile: true };

    case UPDATE_PROFILE_IMAGE_FAIL:
    case UPDATE_USER_INFO_FAIL:
      return { ...state, loadingProfile: false };

    case UPDATE_PROFILE_IMAGE_SUCCESS:
      return { ...state, loadingProfile: false };

    case UPDATE_USER_INFO_SUCCESS:
      return {
        ...state,
        loadingProfile: false,
        user: userReducer(state.user, { type, payload }),
      };

    case REFRESH_USER_INFO:
      return { ...state, loadingRefreshUserInfo: true };

    case REFRESH_USER_INFO_SUCCESS:
      return { ...state, loadingRefreshUserInfo: false };

    case SET_COMMUNITY_INFO: {
      const { scores, ratings, topics } = payload;
      return {
        ...state, communityConfigurations: {
          ...state.communityConfigurations,
          scores,
          ratings,
          topics,
        }
      };
    }

    case GET_FEEDBACK_FOR_LEADERS_RATINGS:
      return { ...state, loadingFeedbackForLeadersRatings: true };

    case GET_FEEDBACK_FOR_LEADERS_RATINGS_FAIL:
      return {
        ...state,
        errorFeedbackForLeadersRatings: payload.error,
        loadingFeedbackForLeadersRatings: false,
      };

    case GET_FEEDBACK_FOR_LEADERS_RATINGS_SUCCESS:
      return {
        ...state,
        errorFeedbackForLeadersRatings: '',
        feedbackForLeadersRatings: payload.response,
        loadingFeedbackForLeadersRatings: false,
      };

    default:
      return state;
  }
}
