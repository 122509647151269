// @packages
import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'smu-ui-components/Checkbox';

// @app
import DescriptionTooltip from 'common/DescriptionTooltip';

// @own
import './styles.scss';

const OrgObjetivesSelector = ({ objectives, onSelect, values }) => {
  return (
    <ul className="org-objectives-selector">
      {objectives.map(objective => {
        const { id, name, description } = objective;
        const selected = values.some(value => value === id);
        return (
          <li
            key={id}
            className="org-objectives-selector__objective-list-item"
          >
            <DescriptionTooltip
              description={description || name}
            >
              <label className="org-objectives-selector__objective-wrapper">
                <Checkbox
                  className="org-objectives__checkbox"
                  onChange={() => onSelect(id)}
                  checked={selected}
                />
                <span className="org-objectives-selector__name">{name}</span>
              </label>
            </DescriptionTooltip>
          </li>
        );
      })}
    </ul>
  );
};

OrgObjetivesSelector.defaultProps = {
  values: [],
};

OrgObjetivesSelector.propTypes = {
  objectives: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
  })).isRequired,
  onSelect: PropTypes.func,
  values: PropTypes.array,
};

export default OrgObjetivesSelector;
