// @packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import RootModal from 'smu-app-components/RootModal';
import { injectIntl } from 'react-intl';

// @app
import GenericModalAssessment from 'components/templates/GenericModalAssessment';
import { centerModalClass } from 'betterme-components/ConfirmationModal';
import { POTENTIAL_ASSESSMENT_EDIT_MODAL } from 'betterme-components/constants/modalTypes';
import Reasons from 'components/molecules/Reasons';

// @own
import './styles.scss';
import messages from './messages';

const AssessmentEditModal = ({
  closeModal,
  intl: { formatMessage },
  loading,
  onClickSaveChanges,
  options,
  question,
  type,
}) => {
  const [newResponse, setNewResponse] = useState(question);

  function handleSaveChanges() {
    closeModal();
    onClickSaveChanges(newResponse);
  }

  function handleCancel() {
    closeModal();
  }

  function handleClose() {
    closeModal();
  }

  const actionButtons = [
    {
      label: formatMessage(messages.PotentialAssessmentDiscardButton),
      onClick: handleCancel,
      color: 'assessment-outline',
    },
    {
      label: formatMessage(messages.PotentialAssessmentSaveChangesButton),
      onClick: () => handleSaveChanges(),
      color: 'assessment-blue',
    },
  ];

  const changeResponseValue = (event) => {
    const { value } = event.target;
    setNewResponse({
      ...question,
      answer: +value,
    })
  }

  const { answer, name, questionId, } = newResponse;

  return (
    <GenericModalAssessment
      actionButtons={actionButtons}
      header={(
        <div className='assessment-edit-modal__header' >
          {formatMessage(messages.PotentialAssessmentEditAnswerTitle)}
        </div>
      )}
      content={(
        <div className='assessment-edit-modal__content'>
          <div className="assessment-edit-modal__content-question-description">
            {`‘${name}’`}
          </div>
          <div className="assessment-edit-modal__content-question-options">
            <Reasons
              className="assessment-edit-modal__content-question-options-items"
              onChange={changeResponseValue}
              reasons={options}
              optionsId={questionId}
              selectedReason={answer}
            />
          </div>
        </div>
      )}
      loading={loading}
      onClose={() => handleClose()}
    />
  );
};

AssessmentEditModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  onClickSaveChanges: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  question: PropTypes.object.isRequired,
};

const modalStyles = {
  width: '500px',
  borderRadius: '8px',
  overflow: 'hidden',
};

export default RootModal(injectIntl(AssessmentEditModal),
  POTENTIAL_ASSESSMENT_EDIT_MODAL,
  modalStyles,
  centerModalClass,
);
