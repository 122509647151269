// @app
import { runValidations } from 'utils/filesAttach';
import { trackEvaluationsAttachFile } from 'betterme-components/Evaluations/analytics';
import {
  EXCEEDED_SIZE,
  MAX_FILE_SIZE,
  NO_SIZE,
  SUPPORTED_FORMATS,
  WRONG_FORMAT,
} from 'betterme-components/constants/fileValidations';

export function handleAttachFile({
  addToastMessage,
  evaluationType,
  event,
  promotionsMessages,
  uploadEvaluationFile,
}) {
  const { target } = event;
  const { files: listFiles } = target;
  const {
    promotionsErrorExceededSize,
    promotionsErrorInvalidFormat,
    promotionsErrorNoSize,
  } = promotionsMessages;
  const errorMessages = {
    [EXCEEDED_SIZE]: promotionsErrorExceededSize,
    [NO_SIZE]: promotionsErrorNoSize,
    [WRONG_FORMAT]: promotionsErrorInvalidFormat,
  };
  if (listFiles?.length) {
    const file = listFiles[0];
    const error = runValidations(file, MAX_FILE_SIZE, SUPPORTED_FORMATS);
    if (!error) {
      uploadEvaluationFile(file);
      trackEvaluationsAttachFile('Success', evaluationType);
    } else {
      addToastMessage({
        message: errorMessages[error],
        timeout: 3000,
      });
      trackEvaluationsAttachFile('Error', evaluationType);
    }
    target.value = null;
  }
};

export function handleDeleteFile(fileId, deleteEvaluationFile, closeModal) {
  deleteEvaluationFile(fileId);
  closeModal();
};
