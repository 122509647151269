// @packages
import { get } from 'lodash';

const reducerDomain = 'files';

export const selectFiles = (state) =>
  get(state, [reducerDomain, 'files']);

export const selectFilesLoading = (state) =>
  get(state, [reducerDomain, 'filesLoading']);
