// @packages
import cn from 'classnames';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';

// @app
import AudioComment from 'common/AudioComment';
import TextParserContainer from 'betterme-components/TextParser';

// @own
import './styles.scss';

const FeedbackReceivedComment = ({
  audioUrl,
  className,
  comment,
  creationTime,
}) => {
  return (
    <div className={cn('feedback-received-comment', className)}>
      <div>
        <div className="feedback-received-comment__creation">
          {moment(creationTime).format('L')}
        </div>
        {audioUrl ? (
          <AudioComment barWidth={595} url={audioUrl} />
        ) : (
          <pre className="feedback-received-comment__comment">
            <TextParserContainer>{comment}</TextParserContainer>
          </pre>
        )}
      </div>
    </div>
  );
};

FeedbackReceivedComment.propTypes = {
  audioUrl: PropTypes.string,
  className: PropTypes.string,
  comment: PropTypes.string,
  creationTime: PropTypes.number.isRequired,
};

export default FeedbackReceivedComment;
