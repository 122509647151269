// @packages
import { get } from 'lodash';

const reducerDomain = 'feedbackView';

export const selectFeedbackErrorStatus = (state) =>
  get(state, [reducerDomain, 'errorStatus']);

export const selectFeedbackDetail = (state) =>
  get(state, [reducerDomain, 'feedback']);

export const selectFeedbackFetching = (state) =>
  get(state, [reducerDomain, 'fetching']);
