import { createSelector } from 'reselect';

const selectFeedbackRequestDomain = (state) => state.requestFeedbackR;

export const selectFeedbacksRequests = createSelector(
  selectFeedbackRequestDomain,
  (state) => state.feedbacksRequests,
);

export const selectLoadingFeedbacksRequests = createSelector(
  selectFeedbackRequestDomain,
  (state) => state.loadingFeedbackRequests,
);

export const selectFeedbackRequestReasons = createSelector(
  selectFeedbackRequestDomain,
  (state) => state.reasons,
);

export const selectLoadingCancelId = createSelector(
  selectFeedbackRequestDomain,
  (state) => state.loadingCancelId,
);

export const selectSelectedReason = createSelector(
  selectFeedbackRequestDomain,
  (state) => state.selectedReason,
);

export const selectLoadingReasons = createSelector(
  selectFeedbackRequestDomain,
  (state) => state.loadingReasons,
);

export const selectFeedbackRequestPage = createSelector(
  selectFeedbackRequestDomain,
  (state) => state.feedbackRequestPage,
);

export const selectActiveTab = createSelector(
  selectFeedbackRequestDomain,
  (state) => state.activeTab,
);

export const selectFeedbacksRequestReceived = createSelector(
  selectFeedbackRequestDomain,
  (state) => state.feedbacksRequestReceived,
);

export const selectFeedbacksRequestSent = createSelector(
  selectFeedbackRequestDomain,
  (state) => state.feedbacksRequestSent,
);

export const selectTotalElements = createSelector(
  selectFeedbackRequestPage,
  (page) => page.totalElements,
);

export const selectPageSize = createSelector(
  selectFeedbackRequestPage,
  (page) => page.size,
);
