import { trackEvent } from 'smu-utils/gtm';
const EVALUATIONS = 'Evaluations';

export const trackEvaluationsCompetenciesFilter = (query, evaluationType) => {
  trackEvent({
    category: EVALUATIONS,
    action: 'Form_Competencies_Filter',
    label: query,
    type: evaluationType,
  });
};

export const trackEvaluationsSelectedScore = (value, type, isTechnicalSuggestion) => {
  trackEvent({
    category: EVALUATIONS,
    action: 'Form_Overall_Score',
    label: value,
    type,
    int_type: isTechnicalSuggestion
      ? 'Suggested_By_TechEvaluator'
      : 'Not_Suggested_By_TechEvaluator',
  });
};

export const trackEvaluationsActionsSuggested = (value, type) => {
  trackEvent({
    category: EVALUATIONS,
    action: 'Form_Suggested_Actions',
    label: value,
    type,
  });
};

export const trackEvaluationsDeleteDraft = (type) => {
  trackEvent({
    category: EVALUATIONS,
    action: 'Form_Delete_Draft',
    type,
  });
};

export const trackEvaluationsDeleteDraftCancel = (type) => {
  trackEvent({
    category: EVALUATIONS,
    action: 'Form_Delete_Cancel',
    type,
  });
};

export const trackEvaluationsDeleteDraftSuccess = (type) => {
  trackEvent({
    category: EVALUATIONS,
    action: 'Form_Delete_Success',
    type,
  });
};

export const trackEvaluationsSaveAsDraft = (type) => {
  trackEvent({
    category: EVALUATIONS,
    action: 'Form_Save_Draft',
    type,
  });
};
