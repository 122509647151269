// @packages
import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Image from 'smu-ui-components/Image';
import Segment from 'smu-ui-components/Segment';
import Highchart from 'smu-analytics-components/charts/Highchart';

// @own
import './styles.scss';
import Header from '../Header';
import { SERIES_CONFIG, CHART_CONFIG } from './constants';
import messages from '../messages';

const AllCompetencies = ({
  chartCompetencies,
  competenciesRates,
  intl: { formatMessage },
  loading,
  ratings,
  totalFeedbacksForLeaders,
  totalRate,
}) => {
  const hasCompetencies = !loading && competenciesRates;
  const filteredRatings = ratings && ratings.slice(1);
  const series = filteredRatings?.map((rating, ratingIndex) => {
    const data = competenciesRates?.map(rates => rates[ratingIndex]);
    const { id, color, name } = rating;
    return {
      ...SERIES_CONFIG,
      color,
      data,
      id,
      name,
    };
  });
  const options = {
    ...CHART_CONFIG,
    xAxis: {
      categories: chartCompetencies,
    }
  };

  return (
    <Segment
      className="all-competencies"
      loading={loading}
      loadingType="betterme"
    >
      <Header
        totalRate={totalRate}
        responses={totalFeedbacksForLeaders}
        title={formatMessage(messages.FeedbackForLeadersReceivedAllCompetencies)}
      />
      <div className="all-competencies__content">
        <div className="all-competencies__rates">
          {filteredRatings && filteredRatings.map((rate, key) => (
            <div className="all-competencies__rate">
              <Image
                alt={rate.name}
                code={rate.oppositeImageCode}
                height={42}
                key={key}
                scale={1}
                width={42}
              />
              <div className="all-competencies__rate-name">
                {rate.name}
              </div>
            </div>
          ))}
        </div>
        <div className="all-competencies__charts">
          {hasCompetencies && <Highchart options={options} data={{ series }} />}
        </div>
      </div>
    </Segment>
  );
};

AllCompetencies.propTypes = {
  competenciesRates: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  ratings: PropTypes.array.isRequired,
  totalFeedbacksForLeaders: PropTypes.number.isRequired,
  totalRating: PropTypes.number.isRequired,
};

export default injectIntl(AllCompetencies);
