// @packages
import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import IconV2 from 'smu-ui-components/IconV2';

// @own
import './styles.scss';

const File = ({
  actionIcon,
  className,
  fileName,
  id,
  onActionClick,
  noPadding,
  noPaddingHorizontal,
  noPaddingVertical,
}) => (
  <div className={cn(className, 'file', {
    'file--no-padding': noPadding,
    'file--no-padding-horizontal': noPaddingHorizontal,
    'file--no-padding-vertical': noPaddingVertical,
  })}>
    <div className="file__name">
      { fileName }
    </div>
    <div className="file__icon">
      <IconV2
        icon={actionIcon}
        size="large"
        onClick={() => onActionClick && onActionClick(id)}
      />
    </div>
  </div>
);

File.propTypes = {
  actionIcon: PropTypes.string.isRequired,
  className: PropTypes.string,
  fileName: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  onActionClick: PropTypes.func,
  noPadding: PropTypes.bool,
  noPaddingHorizontal: PropTypes.bool,
  noPaddingVertical: PropTypes.bool,
}

export default File;
