// @packages
import React from 'react';
import PropTypes from 'prop-types';
import Typography from 'smu-ui-components/Typography';
import cn from 'classnames';

// @own
import './styles.scss';

const SectionInfo = ({ className, subtitle, title }) => (
  <section className={cn('section-info flex flex-col', className)}>
    <Typography className="text-blue font-openSans text-base font-bold">
      {title}
    </Typography>
    {subtitle && (
      <Typography className="font-rubik text-sm text-haiti">
        {subtitle}
      </Typography>
    )}
  </section>
);

SectionInfo.propTypes = {
  className: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default SectionInfo;
