// @app
import { getDiffBetweenDates } from './getDiffBetweenDates';
import { commonMessages } from 'common/messages';

export function formatOrgEntryDate(date) {
  const dateBirthday = getDiffBetweenDates(date);
  const [days, months, years] = [
    dateBirthday.days(),
    dateBirthday.months(),
    dateBirthday.years(),
  ];
  const hasYears = years !== 0;
  const hasMonths = months !== 0;
  const hasDays = days >= 0 && !hasYears && !hasMonths;
  const timeObj = []
  if (hasYears) {
    timeObj.push({
      value: years,
      key: years > 1 ? commonMessages.Years : commonMessages.Year,
    })
  }
  if (hasMonths) {
    timeObj.push({
      value: months,
      key: months > 1 ? commonMessages.Months : commonMessages.Month
    })
  }
  if (hasDays) {
    timeObj.push({
      value: days,
      key: days > 1 ? commonMessages.Days : commonMessages.Day,
    })
  };

  return timeObj;
}
