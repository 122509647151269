// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

// @app
import FeedbackForLeadersReceived from 'betterme-components/FeedbackForLeadersReceived';

const MyFeedbackForLeadersReceived = ({
  location,
  params: { half },
  session: { user },
}) => (
  <FeedbackForLeadersReceived
    half={half}
    location={location}
    user={user}
  />
);

MyFeedbackForLeadersReceived.propTypes = {
  intl: PropTypes.object.isRequired,
}

export default injectIntl(MyFeedbackForLeadersReceived);
