import {
  GET_EVALUATION_DETAIL,
  GET_EVALUATION_DETAIL_FAIL,
  GET_EVALUATION_DETAIL_SUCCESS,
  RESET_EVALUATION_DETAIL,
} from './actionTypes';

export function getEvaluationDetail(evaluationId) {
  return {
    type: GET_EVALUATION_DETAIL,
    payload: { evaluationId },
  };
}

export function getEvaluationDetailFail(error) {
  return {
    type: GET_EVALUATION_DETAIL_FAIL,
    payload: { error },
  };
}

export function getEvaluationDetailSuccess(response) {
  return {
    type: GET_EVALUATION_DETAIL_SUCCESS,
    payload: { response },
  };
}

export function resetEvaluationDetail() {
  return {
    type: RESET_EVALUATION_DETAIL,
  };
}
