import { apiInstanceTP } from 'utils/requestHelper';
import { handleServerError } from 'betterme-components/api';

export const getEngagementForm  = ({ userId }) => {
  return apiInstanceTP({
    url: `/api/v1/community/me/forms/members/${userId}`,
  })
    .then(({ data }) => data)
    .catch(handleServerError);
};

export const putEngagementForm  = (data) => {
  return apiInstanceTP({
    method: 'post',
    url: '/api/v1/community/me/forms',
    data, 
  })
    .then(({ data }) => data)
    .catch(handleServerError);
};
