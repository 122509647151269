// @packages
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { injectIntl } from 'react-intl';
import { get, isEmpty } from 'lodash';
import TextRevealer from 'smu-ui-components/TextRevealer';
import Divider from 'smu-ui-components/Divider';
import Button from 'smu-ui-components/Button';
import { closeModal } from 'smu-app-components/RootModal/actions';
import * as actionsRootModal from 'smu-app-components/RootModal/actions';
import * as actionsFullScreenModal from 'smu-app-components/FullScreenModal/actions';

// @app
import Breadcrumbs from 'common/Breadcrumbs';
import DownloadFile from 'betterme-components/components/containers/DownloadFile';
import {
  actionsMessages,
  commonMessages,
  goalsMessages,
  promotionsMessages,
  sectionsMessages,
} from 'common/messages';
import GoalScales from 'common/GoalScales';
import isScaleDiscrete from 'utils/isScaleDiscrete';
import GoalFooterEmptyState from 'components/molecules/FooterCard';
import TextParserContainer from 'betterme-components/TextParser';
import {
  fileClear,
  fileDelete,
  fileUpload,
} from 'betterme-components/services/FileGoals/actions';
import { selectFiles, selectFilesLoading } from 'betterme-components/services/FileGoals/selectors';
import {
  EXCEEDED_SIZE,
  MAX_FILE_SIZE,
  MAX_FILES_GOAL_UPDATE_PROGRESS,
  NO_SIZE,
  SUPPORTED_FORMATS,
  WRONG_FORMAT,
} from 'betterme-components/constants/fileValidations';
import { GOAL_DETAIL_VIEW_MODAL } from 'betterme-components/constants/modalTypes';
import { runValidations } from 'utils/filesAttach';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';

//TODO GoalsDetails scale type should be calculated from details > scale and not from community configs

// @own
import './styles.scss';
import * as actions from '../actions';
import AvatarMediaList from '../AvatarMediaList';
import GoalActions from '../GoalActions';
import GoalAddAssessment from '../GoalAddAssessment';
import GoalAddAssessmentFeatDiscovery from '../GoalAddAssessmentFeatDiscovery';
import GoalAssessment from '../GoalAssessment';
import GoalFooter from '../GoalFooter';
import GoalScoreSummary from '../GoalScoreSummary';
import TitleNWeight from '../TitleNWeight';
import messages from '../messages';
import { UPDATE_BUTTON, SHARED } from '../constants';
import { selectConfigurations } from '../../Authorization/selectors';
import { trackGoalsEditClick } from '../analytics';

class MyGoalsDetail extends Component {
  constructor(props) {
    super(props);

    const { intl } = this.props;
    this.messagesMyGoalsDetail = {
      goalsText: intl.formatMessage(goalsMessages.GoalsGoals),
      myGoalsTitle: intl.formatMessage(messages.FeedbackGoalsMyGoalsTitle),
      weighting: intl.formatMessage(goalsMessages.GoalsWeight),
      orgObjectives: intl.formatMessage(goalsMessages.GoalsOrgObjectives),
      individual: intl.formatMessage(messages.FeedbackGoalsIndividual),
      shared: intl.formatMessage(messages.FeedbackGoalsShared),
      opened: intl.formatMessage(goalsMessages.GoalsOpened),
      closed: intl.formatMessage(goalsMessages.GoalsClosed),
      pending: intl.formatMessage(goalsMessages.GoalsPending),
      dueDate: intl.formatMessage(sectionsMessages.SectionsDueDate),
      evaluatedBy: intl.formatMessage(messages.FeedbackGoalsEvaluatedBy),
      by: intl.formatMessage(messages.FeedbackGoalsBy),
      progress: intl.formatMessage(goalsMessages.GoalsProgress),
      description: intl.formatMessage(sectionsMessages.SectionsDescription),
      participants: intl.formatMessage(messages.FeedbackGoalsParticipants),
      scales: intl.formatMessage(messages.FeedbackGoalsScales),
      period: intl.formatMessage(commonMessages.Period),
      profile: intl.formatMessage(messages.FeedbackGoalsProfile),
      profileGoals: intl.formatMessage(goalsMessages.GoalsGoals),
      btnReadMore: intl.formatMessage(actionsMessages.ActionsReadMore),
      btnReadLess: intl.formatMessage(actionsMessages.ActionsReadLess),
      btnCancel: intl.formatMessage(actionsMessages.ActionsCancel),
      btnAddAssessment: intl.formatMessage(messages.FeedbackGoalsBtnAddAssessment),
      btnUpdateAssessment: intl.formatMessage(messages.FeedbackGoalsBtnUpdateAssessmentProgress),
      placeholderComment: intl.formatMessage(messages.FeedbackGoalsPlaceholderComment),
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleFileAttach = this.handleFileAttach.bind(this);
    this.handleFileDelete = this.handleFileDelete.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    const { session: { user }, params: { goalid }, getAssignedGoalDetail } = this.props;

    if (goalid) {
      getAssignedGoalDetail(goalid, user.id);
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      getAssignedGoalDetail,
      params: { goalid },
      session: { user },
      toggleEditProgress,
    } = this.props;
    const {
      params: { goalid: nextGoalid },
      session: { user: nextUser },
    } = nextProps;

    if (goalid !== nextGoalid || user.id !== nextUser.id) {
      getAssignedGoalDetail(nextGoalid, nextUser.id);
      toggleEditProgress(false);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      goals: { assignedGoalDetail: { id: prevId } }
    } = prevProps;

    const {
      goals: { assignedGoalDetail: { id: currentId, status: currentStatus } },
      location,
    } = this.props;

    const typeAction = get(location, 'state.typeAction');

    if (prevId !== currentId) {
      const allowsEdit = typeAction === 'ACTION_EDIT';
      if (allowsEdit) {
        this.handleEdit();
      }
    }
  }

  componentWillUnmount() {
    const { toggleEditProgress, resetAssignedGoalDetail, fileClear } = this.props;

    resetAssignedGoalDetail();
    toggleEditProgress(false);
    fileClear();
  }

  handleEdit() {
    const { goals: { assignedGoalDetail } } = this.props;
    trackGoalsEditClick();
    this.onGoalFormClick('EDIT', assignedGoalDetail);
  }

  onGoalFormClick(type, dataGoal) {
    const {
      openFullscreenModal,
      session: { user },
      location,
    } = this.props;
    const typeAction = get(location, 'state.typeAction');

    openFullscreenModal({
      modalType: GOAL_DETAIL_VIEW_MODAL,
      modalProps: {
        dataGoal,
        isMyGoals: true,
        type,
        typeAction,
        user,
      },
    });
  }

  handleSave(id, data, isMyGoals) {
    const {
      createGoalAssessment,
      fileClear,
      session: { user },
    } = this.props;
    createGoalAssessment(id, data, user.id, isMyGoals);
    fileClear();
  }

  handleCancel() {
    const { toggleEditProgress, fileClear } = this.props;
    toggleEditProgress(false);
    fileClear();
  }

  handleFileAttach(e) {
    const { target } = e;
    const { files: listFiles } = target;
    const {
      addToastMessage,
      intl: { formatMessage },
      fileUpload,
    } = this.props;
    const errorMessages = {
      [EXCEEDED_SIZE]: formatMessage(promotionsMessages.PromotionsErrorExceededSize, {
        size: MAX_FILE_SIZE
      }),
      [NO_SIZE]: formatMessage(promotionsMessages.PromotionsErrorNoSize),
      [WRONG_FORMAT]: formatMessage(promotionsMessages.PromotionsErrorInvalidFormat, {
        formats: SUPPORTED_FORMATS,
      }),
    }

    if (listFiles.length) {
      const file = listFiles[0];
      const error = runValidations(file, MAX_FILE_SIZE, SUPPORTED_FORMATS);

      if (!error) {
        fileUpload(file);
        target.value = null;
      } else {
        addToastMessage({
          message: errorMessages[error],
          timeout: 3000,
        });
        target.value = null;
      }
    }
  }

  handleFileDelete(fileId) {
    const { closeModal, fileDelete } = this.props;

    fileDelete(fileId);
    closeModal();
  }

  isGoalOpen() {
    const { goals: { assignedGoalDetail: { status } } } = this.props;
    return status === 'OPENED';
  }

  render() {
    const {
      allowsCollaboratorEditMyGoals,
      files,
      filesLoading,
      goToPath,
      location: { state },
      goals: {
        assignedGoalDetail: {
          assessments,
          assignees,
          assignment,
          attachments,
          description,
          dueDate,
          evaluator,
          fetchingSent,
          id,
          objectives,
          progress,
          period,
          scales,
          status,
          summary,
          title,
          weighting,
        },
        assignedGoalDetail,
        editingProgress,
      },
      goalsCreationOnBehalfOfLeader,
      isPercentage,
      intl: { formatMessage },
      toggleEditProgress,
    } = this.props;

    if (isEmpty(assignedGoalDetail)) {
      return null;
    }

    const userIdentification = state && state.params ? state.params.userid : null;
    const initProgress = progress || 0;
    const initAssessments = assessments || [];
    const urlGoBackTo = userIdentification ? `/profile/${userIdentification}/goals/mygoals` : '/goals/mygoals';
    const breadcrumbsList = userIdentification ?
      [
        this.messagesMyGoalsDetail.goalsText,
        title,
      ] :
      [
        this.messagesMyGoalsDetail.myGoalsTitle,
        title,
      ];
    const actionList = [
      {
        icon: 'action-edit',
        onClick: this.handleEdit,
        title: formatMessage(actionsMessages.ActionsEdit),
      }
    ];
    const allowsActionsButtons = allowsCollaboratorEditMyGoals
      && evaluator
      && assignment !== SHARED
      && this.isGoalOpen();
    const allowAddAssessments =
      goalsCreationOnBehalfOfLeader
      && this.isGoalOpen()
      && assignment === 'INDIVIDUAL';

    return (
      <div className="my-goal-detail">
        <Breadcrumbs
          active={1}
          onClick={() => goToPath(urlGoBackTo)}
          values={breadcrumbsList}
        />
        <h1 className="my-goal-detail__main-title">{this.messagesMyGoalsDetail.progress}</h1>
        <div className="my-goal-detail__info">
          <div className="my-goal-detail__header">
            <TitleNWeight
              title={title}
              status={status}
              assignment={assignment}
              dueDate={dueDate}
              weighting={weighting}
              messages={this.messagesMyGoalsDetail}
              orgObjectives={objectives}
              period={period}
            />
          </div>
          <div className="my-goal-detail__description">
            <h2 className="my-goal-detail__section-title">{this.messagesMyGoalsDetail.description}</h2>
            <TextRevealer
              moreButtonLabel={this.messagesMyGoalsDetail.btnReadMore}
              lessButtonLabel={this.messagesMyGoalsDetail.btnReadLess}
              maxHeight={100}
            >
                <pre className="my-goal-detail__description-text">
                  <TextParserContainer>
                    {description.trim()}
                  </TextParserContainer>
                </pre>
            </TextRevealer>
          </div>
          <div className="my-goal-detail__download-file-wrapper">
            <DownloadFile
              attachments={attachments}
              className="my-goal-detail__download-file"
            />
          </div>
          <Divider nomargin />
          {assignment === SHARED &&
            <div className="my-goal-detail__participants">
              <h2 className="my-goal-detail__section-title">{this.messagesMyGoalsDetail.participants}</h2>
              <AvatarMediaList
                users={assignees}
                type="small"
              />
            </div>
          }
          <Divider nomargin />
          <div className="my-goal-detail__progress">
            <div className="my-goal-detail__progress-header">
              <h2 className="my-goal-detail__progress-header-title">
                {this.messagesMyGoalsDetail.progress}
              </h2>
              <div className="my-goal-detail__actions">
                {allowAddAssessments && (
                  <GoalAddAssessmentFeatDiscovery
                    element={UPDATE_BUTTON}
                  >
                    <Button
                      classic
                      disabled={editingProgress}
                      onClick={!editingProgress
                        ? () => toggleEditProgress(true)
                        : undefined
                      }
                      noMargin
                    >
                      {this.messagesMyGoalsDetail.btnUpdateAssessment}
                    </Button>
                  </GoalAddAssessmentFeatDiscovery>
                )}
                {!isPercentage && (
                  <GoalScales
                    className="my-goal-detail__actions-info"
                    scales={scales}
                    messages={this.messagesMyGoalsDetail}
                  />
                )}
              </div>
            </div>
            <GoalAddAssessment
              editingProgress={allowAddAssessments && editingProgress}
              fetching={fetchingSent}
              files={{
                data: files,
                disabled: files.length >= MAX_FILES_GOAL_UPDATE_PROGRESS,
                loading: filesLoading,
                onChange: this.handleFileAttach,
                onDelete: this.handleFileDelete,
                supportedFormats: SUPPORTED_FORMATS,
              }}
              isGoalScoreContinous={!isScaleDiscrete(scales)}
              isPercentage={isPercentage}
              messages={this.messagesMyGoalsDetail}
              onCancel={() => this.handleCancel()}
              onUpdate={(data) => this.handleSave(id, data, true)}
              progress={initProgress}
              scores={scales}
              withFeatureDiscovery={allowAddAssessments}
            />
            {summary &&
              <div className="my-goal-detail__goal-summary">
                <GoalScoreSummary
                  className="my-goal-detail__goal-score-summary"
                  comment={summary.comment}
                  name={summary.message}
                  notApplyMessage={formatMessage(messages.FeedbackGoalsDetailModalCloseCheckbox)}
                  score={summary.score}
                  scoreMessage={formatMessage(messages.FeedbackGoalsDetailSummaryScoreMessage)}
                />
                <Divider nomargin className="my-goal-detail__summary-divider" />
              </div>
            }

            {initAssessments.length > 0 &&
              <GoalAssessment
                assessments={initAssessments}
                isPercentage={isPercentage}
                messages={this.messagesMyGoalsDetail}
                scales={scales}
              />
            }
          </div>
          <Divider nomargin />
          {evaluator ? (
            <GoalFooter
              actionMessage={this.messagesMyGoalsDetail.evaluatedBy}
              className="goal-feed-item__footer"
              messages={this.messagesMyGoalsDetail}
              user={evaluator}
            />
          ) : (
            <GoalFooterEmptyState
              className="goal-feed-item__footer"
              sentBy={formatMessage(messages.GoalsEmptyStateEvaluator)}
            />
          )}
        </div>
        {allowsActionsButtons && (
          <GoalActions actionList={actionList} />
        )}
      </div>
    );
  }
}

MyGoalsDetail.propTypes = {
  assignedGoalDetail: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
  createGoalAssessment: PropTypes.func.isRequired,
  fileClear: PropTypes.func.isRequired,
  fileDelete: PropTypes.func.isRequired,
  files: PropTypes.arrayOf(PropTypes.object),
  filesLoading: PropTypes.bool,
  fileUpload: PropTypes.func.isRequired,
  getAssignedGoalDetail: PropTypes.func,
  goals: PropTypes.object.isRequired,
  goalsCreationOnBehalfOfLeader: PropTypes.bool,
  goToPath: PropTypes.func,
  location: PropTypes.shape({
    state: PropTypes.shape({
      params: PropTypes.shape({
        userid: PropTypes.string,
      }),
    }),
  }),
  isGoalScoreContinous: PropTypes.bool,
  intl: PropTypes.object.isRequired,
  params: PropTypes.shape({
    goalid: PropTypes.string.isRequired,
  }),
  resetAssignedGoalDetail: PropTypes.func.isRequired,
  session: PropTypes.object,
  toggleEditProgress: PropTypes.func.isRequired,
};

const goToPath = (path) => push(path);
const mapDispatchToProps = {
  ...actions,
  ...actionsFullScreenModal,
  ...actionsRootModal,
  addToastMessage,
  closeModal,
  fileClear,
  fileDelete,
  fileUpload,
  goToPath,
};
const mapStateToProps = (state) => {
  const { goals } = state;
  return {
    allowsCollaboratorEditMyGoals: selectConfigurations('allowsCollaboratorEditMyGoals')(state),
    files: selectFiles(state),
    filesLoading: selectFilesLoading(state),
    goals,
    goalsCreationOnBehalfOfLeader: selectConfigurations('allowsGoalsCreationOnBehalfOfLeader')(state),
    isGoalScoreContinous: selectConfigurations('continuousGoalScores')(state),
    isPercentage: selectConfigurations('allowsPercentageProgress')(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MyGoalsDetail));
