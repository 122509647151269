// @packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import Segment from 'smu-ui-components/Segment';

// @app
import { trackEvent } from 'smu-utils/gtm';

// @own
import './styles.scss';
import { cleanNotifications, getNotifications, markAllNotificationsAsRead } from './actions';
import messages from './messages';
import NotificationHistoryList from './NotificationHistoryList';

const NotificationsHistory = ({
  allNotifications,
  cleanNotifications,
  fetching,
  getNotifications,
  intl: {
    formatMessage,
  },
  markAllNotificationsAsRead,
}) => {
  const [page, setPage] = useState(0);

  const handleMarkAllNotificationsAsRead = () => {
    trackEvent({
      category: 'Notifications',
      action: 'All_Read',
    });
    markAllNotificationsAsRead();
  };

  const getNotificationsList = (page = 0) => {
    getNotifications({ page });
  };

  const loadMoreNotifications = () => {
    const newPage = page + 1;
    setPage(newPage);
    if (!fetching) {
      getNotificationsList(newPage);
    }
  };

  useEffect(() => {
    getNotificationsList();

    return () => {
      cleanNotifications();
    };
  }, []);

  return (
    <Segment
      className="notifications-history"
      loading={fetching}
      loadingType="starmeup"
      withChildren={false}
    >
      <div className="notifications-history__text-decoration">
        {formatMessage(messages.notificationsTitle)}
      </div>
      { allNotifications.length &&
        <NotificationHistoryList
          loadMore={loadMoreNotifications}
          markAllNotificationsAsRead={handleMarkAllNotificationsAsRead}
          notifications={allNotifications}
        />
      }
    </Segment>
  );
};


NotificationsHistory.propTypes = {
  cleanNotifications: PropTypes.func.isRequired,
  fetching: PropTypes.bool,
  getNotifications: PropTypes.func.isRequired,
  intl: PropTypes.object,
  markAllNotificationsAsRead: PropTypes.func.isRequired,
};

const mapStateToProps = ({ notificationsHistory }) => {
  const allNotifications = notificationsHistory.list;
  const { fetching } = notificationsHistory;

  return {
    allNotifications,
    fetching,
  };
};

const actions = {
  cleanNotifications,
  getNotifications,
  markAllNotificationsAsRead,
};

export default connect(mapStateToProps, actions)(injectIntl(NotificationsHistory));
