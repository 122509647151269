// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { orderBy } from 'lodash';
import { Popup } from 'semantic-ui-react';

// @own
import './styles.scss';

const GoalScales = ({
  className,
  messages,
  scales,
}) => {
  const orderedScales = orderBy(scales, scale => scale.min);

  return (
    <Popup
      hideOnScroll
      inverted
      position="right center"
      trigger={<span className="goal-scales__icon">i</span>}
    >
      <div className={cn('goal-scales', className)}>
        <h3 className="goal-scales__title">{messages.scales}</h3>

        <ul className="goal-scales__list">
          {orderedScales.map((scale, index) => {
            const scaleValue = scale.min === scale.max ? scale.min : `${scale.min} - ${scale.max}`;

            return (
              <li className="goal-scales__item" key={index}>
                <strong className="goal-scales__item-value">{scaleValue}</strong>
                <div className="goal-scales__item-description">
                  <span className="goal-scales__item-name">{scale.name}</span>
                  {scale &&
                    <p className="goal-scales__item-detail">{scale.details}</p>
                  }
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </Popup>
  );
};

GoalScales.propTypes = {
  className: PropTypes.string,
  scales: PropTypes.array.isRequired,
  messages: PropTypes.shape({
    scales: PropTypes.string.isRequired,
  }).isRequired,
};

export default GoalScales;
