// @packages
import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import InputText from 'smu-ui-components/InputText';

// @app
import CardFooter from 'common/CardFooter';
import DescriptionTooltip from 'common/DescriptionTooltip';
import TextParserContainer from 'betterme-components/TextParser';

const EvaluationDetailScore = ({
  actionMessage,
  className,
  coCreated,
  comment,
  evaluator,
  isMyEvaluation,
  isNHSelfEvaluation,
  score,
  scoreDescription,
  showtooltip,
  title,
}) => (
  <div className={cn('border-t border-solid border-gray-gallery p-5', className)}>
    {title && (
      <h3 className="text-shark font-bold text-base mb-[10px] uppercase">
        {title}
      </h3>
    )}
    {score && (
      !isNHSelfEvaluation && (
        showtooltip ? (
          <DescriptionTooltip description={scoreDescription || score}>
            <InputText
              className="mb-[10px]"
              readOnly
              value={score}
            />
          </DescriptionTooltip>
        ) : (
          <InputText
            className="mb-[10px]"
            readOnly
            value={score}
          />
        )
      ))}
    {comment && (
      <div className="border border-solid border-gray-gallery last:mt-5">
        <div
          className={
            `bg-white rounded-[3px] box-border text-shark font-roboto text-sm h-[165px] m-0 
          overflow-auto pb-0 pt-[25px] p-[25px]`
          }
        >
          <pre className="text-gray font-roboto text-sm m-0 break-words whitespace-pre-line">
            <TextParserContainer>{comment}</TextParserContainer>
          </pre>
        </div>
      </div>
    )}
    {
      coCreated?.coCreatedData && coCreated?.hasTechnicalInput && (
        isMyEvaluation ? coCreated?.showCoCreatedComment : coCreated?.hasTechnicalInput) && (
        <>
          <CardFooter
            className={
              `items-center bg-white-lotion border-t-0 border-b border-x border-solid
              border-gray-gallery text-shark flex font-roboto text-xs h-12 pl-5`
            }
            actionMessage={actionMessage}
            user={evaluator}
          />
          <div className={cn('mt-5', { 'opacity-50': !coCreated?.showCoCreatedComment })}
          >
            <div className="border border-solid border-gray-gallery last:mt-5">
              <div
                className={
                  `bg-white rounded-[3px] box-border text-shark font-roboto text-sm h-[165px]
                  m-0 overflow-auto pb-0 pt-[25px] p-[25px]`
                }
              >
                <pre className="text-gray font-roboto text-sm m-0 break-words whitespace-pre-line">
                  <TextParserContainer>
                    {coCreated?.coCreatedData?.comment}
                  </TextParserContainer>
                </pre>
              </div>
              <CardFooter
                className={
                  `items-center bg-white-lotion border-t-0 border-b border-x border-solid
                  border-gray-gallery text-shark flex font-roboto text-xs h-12 pl-5`
                }
                actionMessage={actionMessage}
                user={coCreated?.coCreatedData?.evaluator}
              />
            </div>
          </div>
        </>
      )
    }
  </div>
);

EvaluationDetailScore.propTypes = {
  className: PropTypes.string,
  comment: PropTypes.string,
  isNHSelfEvaluation: PropTypes.bool,
  score: PropTypes.shape({
    message: PropTypes.string,
    value: PropTypes.number,
  }),
  scoreDescription: PropTypes.string,
  showtooltip: PropTypes.bool,
  title: PropTypes.string,
};

export default EvaluationDetailScore;
