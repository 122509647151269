// @packages
import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Link from 'smu-ui-components/Link';
import Segment from 'smu-ui-components/Segment';

// @app
import {
  actionsMessages,
  commonMessages,
  feedbackForLeadersMessages,
  feedbackMessages,
} from 'common/messages';
import messages from 'betterme-components/Dashboard/messages';

// @own
import './styles.scss';
import RateInfo from './RateInfo';
import StackedChartsSummary from './StackedChartsSummary';
import FeedbackForLeadersResultsEmpty from './FeedbackForLeadersResultsEmpty';

const FeedbackForLeadersResults = ({
  annualAverage,
  descriptionCollaborators,
  goToFeedbackReceived,
  half,
  higherRates,
  intl: { formatMessage },
  loading,
  lowerRates,
  period,
  previousPeriod,
  totalFeedbacksForLeaders,
  totalRate,
  user,
}) => {
  const totalDescription = (totalFeedbacksForLeaders && descriptionCollaborators) ?
    `${totalFeedbacksForLeaders} ${descriptionCollaborators}`
    : '';
  const canViewResults = (higherRates !== undefined && lowerRates !== undefined) || loading;
  const hasHigherRates = higherRates?.length > 0;
  const hasLowerRates = lowerRates?.length > 0;
  const leftChartsTitle = hasHigherRates
    ? formatMessage(messages.DashboardFeedbackForLeadersHigherRates)
    : formatMessage(messages.DashboardFeedbackForLeadersLowerRates)
    ;
  const rightChartsTitle = (hasLowerRates && hasHigherRates)
    ? formatMessage(messages.DashboardFeedbackForLeadersLowerRates)
    : '';

  const isSecondHalf = half === 2;
  const showPreviousPeriod = isSecondHalf && annualAverage && previousPeriod;

  function getLeftCharts() {
    if (hasHigherRates && hasLowerRates) {
      return higherRates;
    } else if (hasHigherRates && !hasLowerRates) {
      return higherRates.slice(0, 2);
    } else if (!hasHigherRates && hasLowerRates) {
      return lowerRates.slice(0,2);
    } else {
      return [];
    }
  }

  function getRightCharts() {
    if (hasHigherRates && hasLowerRates) {
      return lowerRates;
    } else if (hasHigherRates && !hasLowerRates) {
      return higherRates.slice(2);
    } else if (!hasHigherRates && hasLowerRates) {
      return lowerRates.slice(2);
    } else {
      return [];
    }
  }

  let leftCharts = getLeftCharts();
  let rightCharts = getRightCharts();

  return (
    <Segment loading={loading} loadingType="betterme" withChildren={false}>
        <div className="feedback-for-leaders-result">
          {canViewResults ? (
            <>
              <div className="feedback-for-leaders-result__rate">
                <div className="feedback-for-leaders-result__header">
                  <h3 className="feedback-for-leaders-result__header-title">
                    {formatMessage(feedbackForLeadersMessages.FeedbackforLeaders)}
                  </h3>
                  <h4 className="feedback-for-leaders-result__header-subtitle">
                    {formatMessage(messages.DashboardFeedbackForLeadersSummaryReport)}
                  </h4>
                </div>
                <div className="feedback-for-leaders-result__total-rate">
                  <div className="feedback-for-leaders-result__total-rate-right">
                    <div className="feedback-for-leaders-result__total-rate-average">
                      {totalRate}
                    </div>
                    <div className="feedback-for-leaders-result__total-rate-text">
                      {formatMessage(feedbackMessages.FeedbackRated)}
                    </div>
                  </div>
                </div>
                <div className="feedback-for-leaders-result__info-summary">
                  <div className="feedback-for-leaders-result__info-summary-top">
                    <RateInfo
                      className="feedback-for-leaders-result__rate-info"
                      description={period?.title}
                      title={formatMessage(commonMessages.Period)}
                    />
                    <RateInfo
                      className="feedback-for-leaders-result__rate-info"
                      description={totalDescription}
                      title={formatMessage(messages.DashboardFeedbackForLeadersTotal)}
                      />
                  </div>
                  {showPreviousPeriod && (
                    <div className="feedback-for-leaders-result__info-summary-bottom">
                      <RateInfo
                        className="feedback-for-leaders-result__rate-info"
                        description={previousPeriod?.totalRate}
                        icon="external-link"
                        link={goToFeedbackReceived(1)}
                        title={formatMessage(messages.DashboardFeedbackForLeadersPreviousPeriod)}
                      />
                      <RateInfo
                        className="feedback-for-leaders-result__rate-info"
                        description={annualAverage}
                        title={formatMessage(messages.DashboardFeedbackForLeadersAnnualAverage)}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="feedback-for-leaders-result__charts">
                <StackedChartsSummary
                  className="feedback-for-leaders-result__summary-charts"
                  rates={leftCharts}
                  title={leftChartsTitle}
                />
                <StackedChartsSummary
                  className="feedback-for-leaders-result__summary-charts"
                  rates={rightCharts}
                  title={rightChartsTitle}
                />
                <div className="feedback-for-leaders-result__action-button">
                <Link
                  color="black"
                  size="small"
                  to={goToFeedbackReceived(half)}
                  type="button"
                >
                    {formatMessage(actionsMessages.ActionsViewMore)}
                  </Link>
                </div>
              </div>
            </>
          ) : (
            <FeedbackForLeadersResultsEmpty user={user} />
          )}
        </div>
    </Segment>
  );
};

FeedbackForLeadersResults.defaultProps = {
  higherRates: undefined,
  loading: false,
  lowerRates: undefined,
};

FeedbackForLeadersResults.propTypes = {
  descriptionCollaborators: PropTypes.string.isRequired,
  goToFeedbackReceived: PropTypes.string,
  higherRates: PropTypes.array,
  intl: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  lowerRates: PropTypes.array,
  period: PropTypes.string.isRequired,
  totalFeedbacksForLeaders: PropTypes.number.isRequired,
  totalRate: PropTypes.string.isRequired,
};

export default injectIntl(FeedbackForLeadersResults);
