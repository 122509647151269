// @packages
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import RootModal from 'smu-app-components/RootModal';
import Icon from 'smu-ui-components/IconV2';
import Segment from 'smu-ui-components/Segment';

// @app
import PositionsHistory from 'common/PositionsHistory';
import Title from 'components/atoms/Title';
import { promotionsMessages } from 'common/messages';
import { PROMOTION_REQUEST_POSITIONS_HISTORY_MODAL } from '../constants/modalTypes';
import { getPositionsHistory } from '../SendSuggestPromotion/actions';
import {
  selectLoadingPositionsHistory,
  selectPositionsHistory,
} from '../SendSuggestPromotion/selectors';
import { modalStyles, centerModalClass } from '../ConfirmationModal';

//  @own
import './styles.scss';

const PositionsHistoryModal = ({
  closeModal,
  collaborator,
  getPositionsHistory,
  intl: { formatMessage },
  loadingPositionsHistory,
  positionsHistory,
}) => {
  useEffect(() => {
    getPositionsHistory(collaborator.id);
  }, []);

  return (
    <div className="positions-history-modal">
      <div className="positions-history-modal__header">
        <div className="positions-history-modal__heading">
          <Title className="positions-history-modal__title">
            {formatMessage(promotionsMessages.PromotionsPositionsHistory)}
          </Title>
          <div className="positions-history-modal__subtitle">
            {formatMessage(promotionsMessages.PromotionsAll)}
          </div>
        </div>
        <Icon
          className="positions-history-modal__close-icon"
          color="iconPrimary"
          icon="close"
          size="small"
          onClick={closeModal}
        />
      </div>
      <Segment loading={loadingPositionsHistory} loadingType="betterme">
        {positionsHistory && <PositionsHistory positionsHistory={positionsHistory} />}
      </Segment>
    </div>
  );
};

const mapStateToProps = state => ({
  loadingPositionsHistory: selectLoadingPositionsHistory(state),
  positionsHistory: selectPositionsHistory(state),
});

const withRootModalComponent = RootModal(
  connect(mapStateToProps, { getPositionsHistory })(injectIntl(PositionsHistoryModal)),
  PROMOTION_REQUEST_POSITIONS_HISTORY_MODAL,
  modalStyles,
  centerModalClass,
);


export default withRootModalComponent;
