// @packages
import { call, put, takeLatest } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';

// @app
import { getFeedbackForLeaders } from './api';

// @own
import {
  GET_FEEDBACK_FOR_LEADERS_SUMMARY,
} from './actionTypes';
import {
  getFeedbackForLeadersFail,
  getFeedbackForLeadersSuccess,
} from './actions';

function* getFeedbackForLeadersWorker({ payload }) {
  const { userId } = payload;
  try {
    const response = yield call(getFeedbackForLeaders, { userId });
    yield put(getFeedbackForLeadersSuccess(response));
  } catch({ message }) {
    yield put(getFeedbackForLeadersFail(message));
    yield put(addToastMessage({ type: 'error', message, timeout: 3000 }));
  }
}

export default function* feedbackForLeadersWatcher() {
  yield takeLatest(GET_FEEDBACK_FOR_LEADERS_SUMMARY, getFeedbackForLeadersWorker);
}
