// @packages
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RootModal from 'smu-app-components/RootModal';

// @app
import GenericModal from 'components/templates/GenericModalTemplate';
import InfoCard from 'components/molecules/InfoCard';
import { centerModalClass } from 'betterme-components/ConfirmationModal';
import messages from 'components/pages/LeadershipRequestSection/messages';
import { actionsMessages } from 'common/messages';
import * as actions from 'betterme-components/services/ChangeLeaderRequestAcceptDecline/actions';
import { LEADERSHIP_REQUEST_CANCEL_MODAL } from 'betterme-components/constants/modalTypes';
import { CANCELED } from 'components/common/constants';

const LeadershipRequestCancelModal = ({
  closeModal,
  intl: { formatMessage },
  putChangeLeader,
  requestId,
}) => {

  function handleCancelRequest() {
    const status = CANCELED;
    putChangeLeader(requestId, status);
    closeModal();
  };

  const actionButtons = [
    {
      label: formatMessage(actionsMessages.ActionsCancel),
      onClick: closeModal,
      variant: 'outline',
    },
    {
      label: formatMessage(actionsMessages.ActionsContinue),
      onClick: handleCancelRequest,
    },
  ];

  return (
      <GenericModal
        actionButtons={actionButtons}
        header={
          <InfoCard
            icon="info"
            iconSize={50}
            message={formatMessage(messages.LeadershipRequestCancelMessageModal)}
            title={formatMessage(messages.LeadershipRequestCancelTitleModal)}
            titleSize={36}
          />
        }
        success
        onClose={closeModal}
      />
  )
};

LeadershipRequestCancelModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  intl: PropTypes.object,
  putChangeLeader: PropTypes.func.isRequired,
  requestId: PropTypes.number.isRequired,
};

const mapDispatchToProps = {
  ...actions,
};

const modalStyles = {
  width: '610px',
};

export default RootModal(
  injectIntl(connect(null, mapDispatchToProps)(LeadershipRequestCancelModal)),
  LEADERSHIP_REQUEST_CANCEL_MODAL,
  modalStyles,
  centerModalClass,
);
