import { get } from 'lodash';

const reducerDomain = 'promotionRequestsHistory';

export const selectPromotionRequestsHistoryResult = state =>
  get(state, [reducerDomain, 'promotionRequestsHistory']);

export const selectLoadingPromotionRequestsHistory = state =>
  get(state, [reducerDomain, 'loadingPromotionRequestsHistory']);

export const selectPagination = state =>
  get(state, [reducerDomain, 'page']);
