import { defineMessages } from 'react-intl';

export default defineMessages({
  FeedbackForLeadersReceivedTitle: {
    id: 'Messages.FeedbackForLeadersReceived.Title',
    defaultMessage: 'Feedback For Leaders',
  },
  FeedbackForLeadersReceivedSection: {
    id: 'Betterme.FeedbackForLeadersReceived.Section',
    defaultMessage: 'Personal Dashboard',
  },
  FeedbackForLeadersReceivedResults: {
    id: 'Betterme.FeedbackForLeadersReceived.Results',
    defaultMessage: 'See Results',
  },
  FeedbackForLeadersReceivedTotalResponses: {
    id: 'Betterme.FeedbackForLeadersReceived.TotalResponses',
    defaultMessage: 'Total Responses',
  },
  FeedbackForLeadersReceivedTotalRate: {
    id: 'Betterme.FeedbackForLeadersReceived.TotalRate',
    defaultMessage: 'Total Rate',
  },
  FeedbackForLeadersReceivedRates: {
    id: 'Betterme.FeedbackForLeadersReceived.Rates',
    defaultMessage: 'Rated',
  },
  FeedbackForLeadersReceivedAllCompetencies: {
    id: 'Betterme.FeedbackForLeadersReceived.AllCompetencies',
    defaultMessage: 'All competencies',
  },
  FeedbackForLeadersReceivedCompetencies: {
    id: 'Betterme.FeedbackForLeadersReceived.Competencies',
    defaultMessage: 'Competencies',
  },
  FeedbackForLeadersReceivedInsights: {
    id: 'Betterme.FeedbackForLeadersReceived.Insights',
    defaultMessage: 'Insights',
  },
});
