import { replace } from 'react-router-redux';
import { all, takeLatest, takeEvery, take, call, put, select } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import { closeModal, openModal } from 'smu-app-components/RootModal/actions';
import { closeFullscreenModal } from 'smu-app-components/FullScreenModal/actions';
import { isEmpty } from 'lodash';
import {
  selectConfigurations,
  selectUserInfo,
} from '../Authorization/selectors';
import { selectMyTeamFilters } from '../MyTeam/selectors';
import {
  GOAL_DETAIL_SUCCESS_CLOSE_MODAL,
  GOAL_CREATE_WEIGHT_WARNING,
  GOAL_DETAIL_SUCCESS_CONFIRM_CREATE_MODAL,
} from '../constants/modalTypes';
import { DUPLICATE } from 'betterme-components/constants/goal';
import {
  selectTeamGoalDetail,
  selectUser,
  selectLocationBeforeTransitions,
} from './selectors';
import {
  updateApprovalGoal,
  closeGoal,
  deleteGoal,
  disapprovalGoal,
  getSummaryGoals,
  getAssignedGoals,
  getAssignedGoalDetail,
  getOrganizationalObjectives,
  getTeamGoals,
  getTeamGoalDetail,
  getPeriods,
  getUserTeam,
  createGoalAssessment,
  createGoal,
  updateGoal,
} from '../api';
import {
  APPROVAL_GOAL,
  CLOSE_GOAL,
  CREATE_GOAL,
  CREATE_GOAL_ASSESSMENT,
  DELETE_GOAL,
  DISAPPROVAL_GOAL,
  GET_ASSIGNED_GOALS,
  GET_ASSIGNED_GOAL_DETAIL,
  GET_ORGANIZATIONAL_OBJECTIVES,
  GET_PERIODS,
  GET_SUMMARY_GOALS,
  GET_TEAM_GOALS,
  GET_TEAM_GOAL_DETAIL,
  GET_USER_TOTAL_WEIGHT,
  GOAL_WEIGHT_VALIDATION_CONTINUE,
  UPDATE_APPROVAL_GOAL,
  UPDATE_GOAL,
} from './actionTypes';
import {
  closeGoalFail,
  closeGoalSuccess,
  createGoalAssessmentFailed,
  createGoalAssessmentSuccess,
  createGoalFailed,
  createGoalSuccess,
  deleteGoalFail,
  deleteGoalSuccess,
  disapprovalGoalFail,
  disapprovalGoalSuccess,
  getAssignedGoalDetail as getAssignedGoalDetailAction,
  getAssignedGoalDetailFailed,
  getAssignedGoalDetailSuccess,
  getAssignedGoals as getAssignedGoalsAction,
  getAssignedGoalsFailed,
  getAssignedGoalsSuccess,
  getOrganizationalObjectivesFail,
  getOrganizationalObjectivesSuccess,
  getPeriodsFailed,
  getPeriodsSuccess,
  getSummaryGoals as getSummaryGoalsAction,
  getSummaryGoalsFailed,
  getSummaryGoalsSuccess,
  getTeamGoalDetail as getTeamGoalDetailAction,
  getTeamGoalDetailFailed,
  getTeamGoalDetailSuccess,
  getTeamGoals as getTeamGoalsAction,
  getTeamGoalsFailed,
  getTeamGoalsSuccess,
  getUserTotalWeightFail,
  getUserTotalWeightSuccess,
  goalWeightValidationError,
  toggleEditProgress,
  updateApprovalGoal as updateApprovalGoalAction,
  updateApprovalGoalFail,
  updateApprovalGoalSuccess,
  updateGoalFailed,
  updateGoalSuccess,
} from './actions';
import { trackGoalsSendCreate } from './analytics';

function* getSummaryGoalsWorker({ payload }) {
  try {
    const response = yield call(getSummaryGoals, payload);
    yield put(getSummaryGoalsSuccess(response));
  } catch (error) {
    yield put(getSummaryGoalsFailed(error));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

function* getAssignedGoalsWorker({ payload: { userId, filters } }) {
  try {
    const data = {
      userId,
      ...filters,
    };
    const response = yield call(getAssignedGoals, data);
    yield put(getAssignedGoalsSuccess(response));
  } catch (error) {
    yield put(getAssignedGoalsFailed(error));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

function* getAssignedGoalDetailWorker({ payload: { id, userId } }) {
  try {
    const data = {
      userId
    };
    const response = yield call(getAssignedGoalDetail, id, data);
    yield put(getAssignedGoalDetailSuccess(response));
  } catch (error) {
    yield put(getAssignedGoalDetailFailed(error));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

function* getOrganizationalObjectivesWorker() {
  try {
    const response = yield call(getOrganizationalObjectives, { active: true });
    yield put(getOrganizationalObjectivesSuccess(response));
  } catch (error) {
    yield put(getOrganizationalObjectivesFail(error.message));
  }
}

function* getTeamGoalsWorker({ payload: { leaderId, filters } }) {
  try {
    const data = {
      leaderId,
      ...filters,
    };
    const response = yield call(getTeamGoals, data);
    yield put(getTeamGoalsSuccess(response));
  } catch (error) {
    yield put(getTeamGoalsFailed(error));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

function* getTeamGoalDetailWorker({ payload: { id, userId } }) {
  try {
    const data = {
      userId
    };
    const response = yield call(getTeamGoalDetail, id, data);
    yield put(getTeamGoalDetailSuccess(response));
  } catch (error) {
    yield put(getTeamGoalDetailFailed(error));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

function* getPeriodsWorker({ payload: { available } }) {
  try {
    const params = {
      available,
    };
    const response = yield call(getPeriods, params);
    yield put(getPeriodsSuccess(response));
  } catch (error) {
    yield put(getPeriodsFailed(error));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

function* createGoalAssessmentWorker({ payload: { id, data, isMyGoals }, fromUserId }) {
  try {
    if (isMyGoals) {
      const response = yield call(createGoalAssessment, id, data);
      yield put(createGoalAssessmentSuccess(response));
      yield call(trackGoalsSendCreate);
      yield put(getAssignedGoalDetailAction(id, fromUserId));
      yield put(toggleEditProgress(false));
    } else {
      let { id: idTeamGoalDetail } = yield select(selectTeamGoalDetail);
      const response = yield call(createGoalAssessment, id, data);
      yield put(createGoalAssessmentSuccess(response));
      yield call(trackGoalsSendCreate);
      yield put(getTeamGoalDetailAction(idTeamGoalDetail, fromUserId));
      yield put(toggleEditProgress(false));
    }
  } catch (error) {
    yield put(createGoalAssessmentFailed(error));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

function* createGoalWorker({ payload: { data }, goalActionsInfo }) {
  try {
    let { id: idUser } = yield select(selectUser);
    let {
      assignee,
      endDate,
      startDate,
      status,
      title,
    } = yield select(selectMyTeamFilters);
    let userId = isEmpty(assignee) ? idUser : assignee.id;
    let from = startDate ? startDate.valueOf() : undefined;
    let to = endDate ? endDate.valueOf() : undefined;
    let filterGoals = {
      assignee: assignee && assignee.identification,
      dateFrom: from,
      dateTo: to,
      status,
      title,
    };
    const {
      actionGoalType,
      goalCreateSuccessToastMessage,
      isMyGoals,
    } = goalActionsInfo;
    const response = yield call(createGoal, data);
    const isDuplicate = !isMyGoals && actionGoalType === DUPLICATE;
    const isOneGoal = !(response.length > 1);
    yield put(createGoalSuccess(response));
    if (isDuplicate && isOneGoal) {
      yield put(replace(`/myteam/goals/${response[0]?.id}`));
    }
    if (isDuplicate && !isOneGoal) {
      yield put(replace('/myteam/goals'));
    }
    yield put(closeFullscreenModal());
    yield put(openModal({
      modalType: GOAL_DETAIL_SUCCESS_CONFIRM_CREATE_MODAL,
      modalProps: { isMyGoals }
    }));
    if (isMyGoals) {
      yield put(getAssignedGoalsAction(idUser));
    } else {
      yield put(getTeamGoalsAction(idUser, filterGoals));
      yield put(getSummaryGoalsAction(userId, from, to));
    }
    if (goalCreateSuccessToastMessage) {
      yield put(addToastMessage({ message: goalCreateSuccessToastMessage, timeout: 3000 }));
    }
  } catch (error) {
    yield put(createGoalFailed(error));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

function* updateGoalWorker({ payload: { id, data }, fromUserId, status, isMyGoal }) {
  try {
    let { id: idTeamGoalDetail } = yield select(selectTeamGoalDetail);
    const response = yield call(updateGoal, id, data);
    yield put(updateGoalSuccess(response));
    if (status === 'PENDING_APPROVAL') {
      yield put(updateApprovalGoalAction(id, fromUserId));
    } else {
      yield put(closeFullscreenModal());
      if (!isMyGoal) {
        yield put(getTeamGoalDetailAction(idTeamGoalDetail, fromUserId));
      }
      const { pathname } = yield select(selectLocationBeforeTransitions);
      const goalsFeedUrl = pathname.includes('myteam') ? '/myteam/goals' : '/goals/mygoals';
      yield put(replace(goalsFeedUrl));
    }
  } catch (error) {
    yield put(updateGoalFailed(error));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

function* updateApprovalGoalWorker({ payload, fromUserId }) {
  const {
    id,
    messages: { success, fail },
  } = payload;
  const { id: idUser } = yield select(selectUser);
  let {
    assignee,
    endDate,
    startDate,
    status,
    title,
  } = yield select(selectMyTeamFilters);
  let userId = isEmpty(assignee) ? idUser : assignee.id;
  let from = startDate ? startDate.valueOf() : undefined;
  let to = endDate ? endDate.valueOf() : undefined;
  let filterGoals = {
    assignee: assignee && assignee.identification,
    dateFrom: from,
    dateTo: to,
    status,
    title,
  };

  try {
    let { id: idTeamGoalDetail } = yield select(selectTeamGoalDetail);
    const response = yield call(updateApprovalGoal, id);
    yield put(updateApprovalGoalSuccess(response));
    yield put(closeFullscreenModal());
    if (success) {
      yield put(addToastMessage({ timeout: 3000, message: success }));
    }
    if (fromUserId) {
      yield put(getTeamGoalDetailAction(idTeamGoalDetail, fromUserId));
    } else {
      yield put(getTeamGoalsAction(idUser, filterGoals));
      yield put(getSummaryGoalsAction(userId, from, to));
    }
  } catch (error) {
    yield put(updateApprovalGoalFail(error));
    yield put(addToastMessage({ timeout: 3000, message: error.message || fail }));
  }
}

function* deleteGoalWorker({ payload, isListGoals }) {
  const {
    id,
    messages: { success, fail },
  } = payload;
  const { id: idUser } = yield select(selectUser);
  let {
    assignee,
    endDate,
    startDate,
    status,
    title,
  } = yield select(selectMyTeamFilters);
  let userId = isEmpty(assignee) ? idUser : assignee.id;
  let from = startDate ? startDate.valueOf() : undefined;
  let to = endDate ? endDate.valueOf() : undefined;
  let filterGoals = {
    assignee: assignee && assignee.identification,
    dateFrom: from,
    dateTo: to,
    status,
    title,
  };

  try {
    const response = yield call(deleteGoal, id);
    yield put(deleteGoalSuccess(response));
    yield put(closeModal());
    yield put(closeFullscreenModal());
    if (success) {
      yield put(addToastMessage({ timeout: 3000, message: success }));
    }
    if (isListGoals) {
      yield put(getTeamGoalsAction(idUser, filterGoals));
      yield put(getSummaryGoalsAction(userId, from, to));
    } else {
      const { pathname } = yield select(selectLocationBeforeTransitions);
      const goalsFeedUrl = pathname.includes('myteam') ? '/myteam/goals' : '/goals/mygoals';
      yield put(replace(goalsFeedUrl));
    }
  } catch (error) {
    yield put(deleteGoalFail(error));
    yield put(closeModal());
    yield put(closeFullscreenModal());
    yield put(addToastMessage({ timeout: 3000, message: error.message || fail }));
  }
}

function* disapprovalGoalWorker({ payload, isListGoals }) {
  const {
    id,
    messages: { success, fail },
  } = payload;
  const { id: idUser } = yield select(selectUser);
  let {
    assignee,
    endDate,
    startDate,
    status,
    title,
  } = yield select(selectMyTeamFilters);
  let userId = isEmpty(assignee) ? idUser : assignee.id;
  let from = startDate ? startDate.valueOf() : undefined;
  let to = endDate ? endDate.valueOf() : undefined;
  let filterGoals = {
    assignee: assignee && assignee.identification,
    dateFrom: from,
    dateTo: to,
    status,
    title,
  };

  try {
    const response = yield call(disapprovalGoal, id);
    yield put(disapprovalGoalSuccess(response));
    yield put(closeModal());
    yield put(closeFullscreenModal());
    if (success) {
      yield put(addToastMessage({ timeout: 3000, message: success }));
    }
    if (isListGoals) {
      yield put(getTeamGoalsAction(idUser, filterGoals));
      yield put(getSummaryGoalsAction(userId, from, to));
    } else {
      const { pathname } = yield select(selectLocationBeforeTransitions);
      const goalsFeedUrl = pathname.includes('myteam') ? '/myteam/goals' : '/goals/mygoals';
      yield put(replace(goalsFeedUrl));
    }
  } catch (error) {
    yield put(disapprovalGoalFail(error));
    yield put(closeModal());
    yield put(closeFullscreenModal());
    yield put(addToastMessage({ timeout: 3000, message: error.message || fail }));
  }
}

function* closeGoalWorker({ payload, isListGoals }) {
  const { id: goalId, ...data } = payload;
  const { id: idUser } = yield select(selectUser);
  let {
    assignee,
    endDate,
    startDate,
    status,
    title,
  } = yield select(selectMyTeamFilters);
  let userId = isEmpty(assignee) ? idUser : assignee.id;
  let from = startDate ? startDate.valueOf() : undefined;
  let to = endDate ? endDate.valueOf() : undefined;
  let filterGoals = {
    assignee: assignee && assignee.identification,
    dateFrom: from,
    dateTo: to,
    status,
    title,
  };

  try {
    const response = yield call(closeGoal, goalId, data);
    yield put(closeModal());
    yield put(closeGoalSuccess(response));
    yield put(openModal({ modalType: GOAL_DETAIL_SUCCESS_CLOSE_MODAL }));
    if (isListGoals) {
      yield put(getTeamGoalsAction(idUser, filterGoals));
      yield put(getSummaryGoalsAction(userId, from, to));
    } else {
      yield put(getTeamGoalDetailAction(goalId, userId));
    }
  } catch (error) {
    yield put(closeGoalFail(error));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

function* checkGoalsTotalWeightWorker(goalData) {
  const GOAL_WARNING_WEIGHT = 100;
  const allowsGoalCreationWithSumValidation = yield select(selectConfigurations('allowsGoalCreationWithSumValidation'));
  const {
    identification: selfIdentification,
    id: selfId,
    leaders,
  } = yield select(selectUserInfo);
  const { payload: { data: { assignees, periodId, weighting, evaluatorId } }, type } = goalData;
  const leaderIdentification = leaders?.find(
    leader => (leader?.id === evaluatorId)
  )?.identification;
  const identification = (assignees[0] === selfId) ? leaderIdentification : selfIdentification;
  let currentWeight = parseInt(weighting, 10);
  if (type === UPDATE_GOAL) {
    const { weighting: goalWeight } = yield select(selectTeamGoalDetail);
    currentWeight = currentWeight - goalWeight;
  }
  try {
    const { collaborators } = yield call(getUserTeam, identification);
    const params = {
      periodId,
      status: 'OPENED',
    };
    const goalsList = yield all(assignees.map(assignee =>
      call(getAssignedGoals, { userId: assignee, ...params })));

    const assigneesGoalsList = goalsList.map((goalList, index) => ({
      goalWeightingSum: goalList.reduce((totalWeight, { weighting }) => weighting + totalWeight, 0),
      user: collaborators.find(({ collaborator: { id } }) => id === assignees[index]).collaborator,
    }));
    const assigneesWarn = assigneesGoalsList.filter(({ goalWeightingSum }) =>
      goalWeightingSum + currentWeight > GOAL_WARNING_WEIGHT);
    const assigneesWarnList = assigneesWarn.map(({ user }) => user);

    if (assigneesWarnList.length > 0) {
      const modalType = GOAL_CREATE_WEIGHT_WARNING;
      const modalProps = {
        assignees: assigneesWarnList,
        allowsGoalCreationWithSumValidation,
      };
      yield put(openModal({ modalType, modalProps }));
      yield take(GOAL_WEIGHT_VALIDATION_CONTINUE);
      yield put(closeModal());
    }
    if (type === CREATE_GOAL) {
      yield call(createGoalWorker, goalData);
    } else if (type === UPDATE_GOAL) {
      yield call(updateGoalWorker, goalData);
    } else if (type === APPROVAL_GOAL) {
      yield call(updateApprovalGoalWorker, goalData);
    }
  } catch (error) {
    yield put(closeModal());
    yield put(goalWeightValidationError(error.message));
  }
}

function* getUserTotalWeightWorker({ payload }) {
  const { messages: { fail }, periodId, user } = payload;
  const params = {
    periodId,
    status: 'OPENED',
  };
  try {
    const goalList = yield call(getAssignedGoals, { userId: user.id, ...params });
    const totalGoalWeight =
      goalList.reduce((totalWeight, { weighting }) => weighting + totalWeight, 0);
    yield put(getUserTotalWeightSuccess(user, totalGoalWeight));
  } catch (error) {
    yield put(getUserTotalWeightFail(error.message));
    const errorMessage = `${user.firstName} ${user.lastName} ${fail}`;
    yield put(addToastMessage({ timeout: 3000, message: errorMessage }));
  }
}

export default function* goalsWatcher() {
  yield takeLatest(UPDATE_APPROVAL_GOAL, updateApprovalGoalWorker);
  yield takeLatest(CLOSE_GOAL, closeGoalWorker);
  yield takeLatest(DELETE_GOAL, deleteGoalWorker);
  yield takeLatest(DISAPPROVAL_GOAL, disapprovalGoalWorker);
  yield takeLatest(GET_SUMMARY_GOALS, getSummaryGoalsWorker);
  yield takeLatest(GET_ASSIGNED_GOALS, getAssignedGoalsWorker);
  yield takeLatest(GET_ASSIGNED_GOAL_DETAIL, getAssignedGoalDetailWorker);
  yield takeLatest(GET_ORGANIZATIONAL_OBJECTIVES, getOrganizationalObjectivesWorker);
  yield takeLatest(GET_TEAM_GOALS, getTeamGoalsWorker);
  yield takeLatest(GET_TEAM_GOAL_DETAIL, getTeamGoalDetailWorker);
  yield takeLatest(GET_PERIODS, getPeriodsWorker);
  yield takeEvery(GET_USER_TOTAL_WEIGHT, getUserTotalWeightWorker);
  yield takeLatest(CREATE_GOAL_ASSESSMENT, createGoalAssessmentWorker);
  yield takeLatest([CREATE_GOAL, UPDATE_GOAL, APPROVAL_GOAL], checkGoalsTotalWeightWorker);
}
