// @packages
import React from 'react';

// @app
import MyTeamApp from 'betterme-components/MyTeam';
import MyTeamMenu from 'betterme-components/MyTeamMenu';
import TeamGoalsTab from 'betterme-components/Team/TeamGoalsTab';

// @own
import MyTeamLayout from '../';

function MyTeamGoalsLayout(props) {
  return (
    <MyTeamLayout>
      <MyTeamMenu className="py-8 flex-1" path="/myteam/goals">
        <MyTeamApp {...props}>
          <TeamGoalsTab />
        </MyTeamApp>
      </MyTeamMenu>
    </MyTeamLayout>
  );
}

export default MyTeamGoalsLayout;
