// @packages
import { get } from 'lodash';

const reducerDomain = 'teamDashboard';

export const selectEvaluationsCount = (state) =>
  get(state, [reducerDomain, 'evaluationsCount']);

export const selectEvaluationsScores = (state) =>
  get(state, [reducerDomain, 'evaluationsScores']);

export const selectEvaluationsTeam = (state) =>
  get(state, [reducerDomain, 'evaluationsTeam']);

export const selectFeedbacksSummaryTeam = (state) =>
  get(state, [reducerDomain, 'feedbacksSummaryTeam']);

export const selectLoadingSummaryTeam = (state) =>
  get(state, [reducerDomain, 'loadingSummaryTeam']);

export const selectLoadingOpportunities = (state) =>
  get(state, [reducerDomain, 'loadingOpportunities']);

export const selectLoadingTeamEvaluations = (state) =>
  get(state, [reducerDomain, 'loadingTeamEvaluations']);

export const selectLoadingStrenghts = (state) =>
  get(state, [reducerDomain, 'loadingStrenghts']);

export const selectLoadingValueKudos = (state) =>
  get(state, [reducerDomain, 'loadingValueKudos']);

export const selectOpportunities = (state) =>
  get(state, [reducerDomain, 'opportunities']);

export const selectOpportunitiesRating = (state) =>
  get(state, [reducerDomain, 'opportunitiesRating']);

export const selectStrengths = (state) =>
  get(state, [reducerDomain, 'strengths']);

export const selectStrengthsRating = (state) =>
  get(state, [reducerDomain, 'strengthsRating']);

export const selectTeamMembers = (state) =>
  get(state, [reducerDomain, 'teamMembers']);

export const selectValueKudos = (state) =>
  get(state, [reducerDomain, 'valueKudos']);
