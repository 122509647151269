import {
  CREATE_SAVE_EVALUATION,
  CREATE_SAVE_EVALUATION_FAIL,
  CREATE_SAVE_EVALUATION_SUCCESS,
} from './actionTypes';

export function createSaveEvaluation(payload) {
  return {
    type: CREATE_SAVE_EVALUATION,
    payload,
  }
};

export function createSaveEvaluationFail(error) {
  return {
    type: CREATE_SAVE_EVALUATION_FAIL,
    payload: { error },
  };
};

export function createSaveEvaluationSuccess(response) {
  return {
    type: CREATE_SAVE_EVALUATION_SUCCESS,
    payload: { response },
  };
};
