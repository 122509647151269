// @packages
import React from 'react';
import PropTypes from 'prop-types';
import WidgetManagerAPP from 'smu-app-components/Assessment360/containers/widgets/WidgetManager';
import { connect } from 'react-redux';

// @app
import { selectCommunityId } from 'betterme-components/Authorization/selectors';

const WidgetManager = ({ className, communityId, isOwner, userId }) => {
  return (
    <WidgetManagerAPP
      className={className}
      communityId={communityId}
      isOwner={isOwner}
      userId={userId}
    />
  );
};

WidgetManager.propTypes = {
  className: PropTypes.string,
  communityId: PropTypes.number.isRequired,
  isOwner: PropTypes.bool,
  userId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  communityId: selectCommunityId(state),
});

export default connect(mapStateToProps)(WidgetManager);
