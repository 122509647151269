import { get } from 'lodash';

const reducerDomain = 'file';

export const selectFile = state =>
  get(state, [reducerDomain, 'result'], {});

export const selectFileLoading = state =>
  get(state, [reducerDomain, 'fileLoading']);

export const selectFileId = state =>
  get(state, [reducerDomain, 'fileId']);
