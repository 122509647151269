// @packages
import React from 'react';
import PropTypes from 'prop-types';

// @own
import UserProfileOwner from './Owner';
import UserProfileOther from './Other';

const UserProfile = ({
  actionsList,
  enableChangeProfileImage,
  owner,
  userId,
  ...rest
}) => {
  return owner ? (
    <UserProfileOwner
      actionsList={actionsList}
      enableChangeProfileImage={enableChangeProfileImage}
      {...rest}
    />
  ) : (
    <UserProfileOther actionsList={actionsList} userId={userId} {...rest} />
  );
};

UserProfile.defaultProps = {
  actionsList: [],
  enableChangeProfileImage: true,
  owner: false,
};

UserProfile.propTypes = {
  actionsList: PropTypes.array,
  enableChangeProfileImage: PropTypes.bool,
  owner: PropTypes.bool,
  userId: PropTypes.number,
};

export default UserProfile;
