import { takeLatest, call, put, select } from "redux-saga/effects";
import { add as addToastMessage } from "smu-app-components/ToastNotifications/actions";
import { openModal, closeModal } from "smu-app-components/RootModal/actions";
import { refreshUserInfo } from "../Authorization/actions";
import { selectUser } from "../Authorization/selectors";
import { getProfile } from "../Profile/actions";
import { LEADER_CONFIRMATION_SUCCESS_MODAL } from "../constants/modalTypes";
import { ADD_LEADER_ACTION, REQUEST_LEADER } from "./actionsTypes";
import {
  addLeaderFail,
  addLeaderSuccess,
  requestLeaderFail,
  requestLeaderSuccess
} from "./actions";
import { addLeaderAction, requestLeader } from "../api";

function* addLeaderWorker({ collaborator }) {
  const leader = yield select(selectUser);

  try {
    const response = yield call(addLeaderAction, { collaborator, leader });
    yield put(addLeaderSuccess(response));
    yield put(
      openModal({
        modalType: LEADER_CONFIRMATION_SUCCESS_MODAL,
        modalProps: { action: "add", user: collaborator }
      })
    );
    yield put(getProfile(collaborator.identification));
    yield put(refreshUserInfo(leader.identification));
  } catch (error) {
    yield put(addLeaderFail(error.message));
    yield put(closeModal());
    yield put(
      addToastMessage({ type: "error", message: error.message, timeout: 3000 })
    );
  }
}

function* requestLeaderWorker({ payload: { leader, requestId, accept, message } }) {
  const user = yield select(selectUser);
  const data = {
    status: accept ? 'APPROVED' : 'REJECTED',
  };

  try {
    const response = yield call(requestLeader, leader.id, requestId, data);
    yield put(requestLeaderSuccess(response));
    if (accept) {
      yield put(
        openModal({
          modalType: LEADER_CONFIRMATION_SUCCESS_MODAL,
          modalProps: { action: "request", user: leader }
        })
      );
      yield put(refreshUserInfo(user.identification));
    } else {
      yield put(closeModal());
      yield put(
        addToastMessage({ type: "error", message, timeout: 3000 })
      );
    }
  } catch (error) {
    yield put(requestLeaderFail(error.message));
    yield put(closeModal());
    yield put(
      addToastMessage({ type: "error", message: error.message, timeout: 3000 })
    );
  }
}

export default function* leaderWatcher() {
  yield takeLatest(ADD_LEADER_ACTION, addLeaderWorker);
  yield takeLatest(REQUEST_LEADER, requestLeaderWorker);
}
