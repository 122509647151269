// @packages
import { takeLatest, call, put } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import { closeModal } from 'smu-app-components/RootModal/actions';

// @app
import { trackAdminLeadershipAddMeSuccess } from 'betterme-components/services/LeadershipAdmin/analytics';
import { resetChangeLeaderRequestState } from 'betterme-components/services/ChangeLeaderRequest/actions';
import { ADD_TO_MY_TEAM_REQUEST_AS_SECONDARY } from 'components/pages/LeadershipAdmin/constants';

// @own
import { ADD_TO_MY_TEAM } from './actionTypes';
import {
  addToMyTeamFail,
  addToMyTeamSuccess,
} from './actions';
import { sendLeadershipRequest } from './api';

function* leadershipRequestWorker({ payload }) {
  const {
    collaboratorId,
    leaderId,
    leaderType,
    messages: { fail, success },
    type,
    suggestionId,
  } = payload;
  const requestPayload = type === ADD_TO_MY_TEAM_REQUEST_AS_SECONDARY
    ? { suggestionId, type }
    : { type };

  try {
    yield call(sendLeadershipRequest, leaderId, collaboratorId, requestPayload);
    yield put(addToMyTeamSuccess());
    yield put(resetChangeLeaderRequestState());
    yield put(addToastMessage({ message: success, timeout: 3000 }));
    yield call(trackAdminLeadershipAddMeSuccess, leaderType);
    yield put(closeModal());
  } catch (error) {
    const message = error?.message || fail;
    yield put(addToMyTeamFail(message));
    yield put(addToastMessage({ type: 'error', message, timeout: 3000 }));
  }
}

export default function* leadershipRequestWatcher() {
  yield takeLatest(ADD_TO_MY_TEAM, leadershipRequestWorker);
}
