import {
  GET_LEADERSHIP_REQUESTS_RECEIVED,
  GET_LEADERSHIP_REQUESTS_RECEIVED_FAIL,
  GET_LEADERSHIP_REQUESTS_RECEIVED_SUCCESS,
} from './actionTypes';

export function getLeadershipRequestsReceived(type, params) {
  return {
    type: GET_LEADERSHIP_REQUESTS_RECEIVED,
    payload: { type, params },
  }
};

export function getLeadershipRequestsReceivedFail(error) {
  return {
    type: GET_LEADERSHIP_REQUESTS_RECEIVED_FAIL,
    payload: { error },
  };
};

export function getLeadershipRequestsReceivedSuccess(response) {
  return {
    type: GET_LEADERSHIP_REQUESTS_RECEIVED_SUCCESS,
    payload: { response },
  };
};
