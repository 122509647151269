// @packages
import React, {
  useState,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import AvatarMedia from 'smu-ui-components/AvatarMedia';
import cn from 'classnames';
import Select from 'smu-ui-components/SelectV2';

// @app
import useDebounce from 'betterme-components/hooks/useDebounce';
import {
  selectCloseUserCard,
  selectUserListLoading,
  selectSelectedLeader,
  selectUserList,
} from 'betterme-components/services/ChangeLeaderRequest/selectors';
import {
  closeUserCard,
  getLeaders,
  selectLeader,
} from 'betterme-components/services/ChangeLeaderRequest/actions';
import UserSelectCard from 'betterme-components/components/molecules/UserSelectCard';

const SelectSearchUser = ({
  className,
  closeUserCard,
  delay,
  getLeaders,
  intl,
  isCloseUserCard,
  onChange,
  placeholder,
  selectLeader,
  selectedLeader,
  userList,
  userListLoading,
  ...rest
}) => {
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, delay);
  const isLoading = debouncedSearchValue && userListLoading;

  const options = useMemo(() => {
    const users = debouncedSearchValue ? userList : [];
    return users?.map((user) => ({
      label: `${user?.firstName} ${user?.lastName}`,
      user,
      value: user.id,
    }));
  }, [debouncedSearchValue, userList]);

  const handleSearchChange = (value) => {
    getLeaders(value);
    setSearchValue(value || '');
  };

  const handleChange = (value) => {
    const user = options?.find((option) => option?.user?.id === value)?.user;
    selectLeader(user);
  };

  const renderOptionLabel = useCallback(({ user }) => (
    <AvatarMedia
      firstName={user?.firstName}
      identification={user?.identification}
      job={user?.job}
      lastName={user?.lastName}
      profileImageCode={user?.profileImageCode}
      useLink={false}
    />
  ), []);

  const handleCloseCard = () => {
    closeUserCard(true);
    selectLeader({});
  }

  const removeCard = () => {
    const removeCard = !isCloseUserCard && !isEmpty(selectedLeader);
    if (isEmpty(selectLeader)) closeUserCard(false);
    return removeCard;
  }

  return (
    <div className={cn('searching', className)}>
      <Select
        filterOptionsEnabled={false}
        isLoading={isLoading}
        onChange={handleChange}
        onSearchChange={handleSearchChange}
        options={options}
        placeholder={placeholder}
        renderOptionLabel={renderOptionLabel}
        size="large"
        {...rest}
      />
      {removeCard() && (
        <UserSelectCard
          className="searching__selected-card"
          handleCloseCard={handleCloseCard}
          user={selectedLeader}
          withActions
        />
      )}
    </div>
  );
};

SelectSearchUser.propTypes = {
  delay: PropTypes.number,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
  onChange: PropTypes.func,
};

SelectSearchUser.defaultProps = {
  delay: 500,
};

const mapStateToProps = (state) => ({
  isCloseUserCard: selectCloseUserCard(state),
  selectedLeader: selectSelectedLeader(state),
  userList: selectUserList(state),
  userListLoading: selectUserListLoading(state),
});

const mapDispatchToProps = {
  getLeaders,
  selectLeader,
  closeUserCard,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(SelectSearchUser));
