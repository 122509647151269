// @packages
import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

// @app
import DescriptionTooltip from 'common/DescriptionTooltip';
import TextParserContainer from 'betterme-components/TextParser';

// @own
import './styles.scss';

const SuggestedActions = ({
  className,
  comment,
  noTitle,
  suggestedActions,
  title,
}) => {
  return (
    <div className={cn('suggested-actions', className)}>
      {noTitle && (
        <h3 className="suggested-actions__title">
          {title}
        </h3>
      )}
      <ul className={cn('suggested-actions__list',
        { 'suggested-actions__list--no-title': !noTitle },
      )}>
        {suggestedActions.map(suggestedAction => (
          <li
            className="suggested-actions__item"
            key={suggestedAction.id}
          >
            <DescriptionTooltip
              description={suggestedAction.description || suggestedAction.name}
            >
              {suggestedAction.name}
            </DescriptionTooltip>
          </li>
        ))}
      </ul>
      {comment && (
        <div className="suggested-actions__comment">
          <pre className="suggested-actions__text">
            <TextParserContainer>{comment}</TextParserContainer>
          </pre>
        </div>
      )}
    </div>
  );
};

SuggestedActions.propTypes = {
  className: PropTypes.string,
  comment: PropTypes.string,
  suggestedActions: PropTypes.array,
  title: PropTypes.string.isRequired,
};

export default SuggestedActions;
