import {
  CREATE_PROMOTION_REQUEST,
  CREATE_PROMOTION_REQUEST_FAIL,
  CREATE_PROMOTION_REQUEST_SUCCESS,
  DELETE_PROMOTIONS_FILE,
  DELETE_PROMOTIONS_FILE_FAIL,
  DELETE_PROMOTIONS_FILE_SUCCESS,
  GET_POSITIONS_HISTORY,
  GET_POSITIONS_HISTORY_FAIL,
  GET_POSITIONS_HISTORY_SUCCESS,
  GET_PROMOTIONS_LEVEL,
  GET_PROMOTIONS_LEVEL_FAIL,
  GET_PROMOTIONS_LEVEL_SUCCESS,
  GET_PROMOTION_COLLABORATOR,
  GET_PROMOTION_COLLABORATOR_FAIL,
  GET_PROMOTION_COLLABORATOR_SUCCESS,
  GET_PROMOTION_SUGGESTED_VALIDATORS,
  GET_PROMOTION_SUGGESTED_VALIDATORS_FAIL,
  GET_PROMOTION_SUGGESTED_VALIDATORS_SUCCESS,
  GET_USER_PROMOTION_REQUESTS,
  GET_USER_PROMOTION_REQUESTS_FAIL,
  GET_USER_PROMOTION_REQUESTS_SUCCESS,
  RESET_SUGGEST_PROMOTION,
  RESET_PROMOTIONS_FILES,
  SET_PROMOTION_FILES,
  UPLOAD_PROMOTIONS_FILE,
  UPLOAD_PROMOTIONS_FILE_FAIL,
  UPLOAD_PROMOTIONS_FILE_SUCCESS,
} from './actionTypes';

const initialState = {
  collaborator: {},
  files: [],
  filesError: null,
  loadingFiles: false,
  loadingPositionsHistory: false,
  loadingPreviousRequests: false,
  loadingPromotionsLevel: false,
  loadingSubmit: false,
  loadingSuggestedValidators: false,
  loadingUser: false,
  positionsHistory: [],
  previousRequests: [],
  promotionProcessAllowed: false,
  promotionsLevel: [],
  suggestedValidators: [],
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_POSITIONS_HISTORY:
      return { ...state, loadingPositionsHistory: true };

    case GET_POSITIONS_HISTORY_FAIL:
      return { ...state, loadingPositionsHistory: false };

    case GET_POSITIONS_HISTORY_SUCCESS:
      return { ...state, loadingPositionsHistory: false, positionsHistory: payload.response };

    case GET_PROMOTIONS_LEVEL:
      return { ...state, loadingPromotionsLevel: true };

    case GET_PROMOTIONS_LEVEL_FAIL:
      return { ...state, loadingPromotionsLevel: false };

    case GET_PROMOTIONS_LEVEL_SUCCESS:
      return { ...state, loadingPromotionsLevel: false, promotionsLevel: payload.response };

    case GET_USER_PROMOTION_REQUESTS:
      return { ...state, loadingPreviousRequests: true };

    case GET_USER_PROMOTION_REQUESTS_FAIL:
      return { ...state, loadingPreviousRequests: false, promotionProcessAllowed: false };

    case GET_USER_PROMOTION_REQUESTS_SUCCESS:
      return { ...state, loadingPreviousRequests: false, previousRequests: payload.response, promotionProcessAllowed: true };

    case UPLOAD_PROMOTIONS_FILE:
    case DELETE_PROMOTIONS_FILE:
      return { ...state, loadingFiles: true };

    case UPLOAD_PROMOTIONS_FILE_FAIL:
    case DELETE_PROMOTIONS_FILE_FAIL:
      return { ...state, loadingFiles: false, filesError: payload.error };

    case UPLOAD_PROMOTIONS_FILE_SUCCESS:
      return { ...state, loadingFiles: false, files: [...state.files, payload.response] };

    case DELETE_PROMOTIONS_FILE_SUCCESS:
      return { ...state, loadingFiles: false, files: state.files.filter(file => file.id !== payload.id) }

    case CREATE_PROMOTION_REQUEST:
      return { ...state, loadingSubmit: true }

    case CREATE_PROMOTION_REQUEST_FAIL:
    case CREATE_PROMOTION_REQUEST_SUCCESS:
      return { ...state, loadingSubmit: false }

    case GET_PROMOTION_COLLABORATOR:
      return { ...state, loadingUser: true }

    case GET_PROMOTION_COLLABORATOR_FAIL:
      return { ...state, loadingUser: false }

    case GET_PROMOTION_COLLABORATOR_SUCCESS:
      return { ...state, loadingUser: false, collaborator: payload.response }

    case SET_PROMOTION_FILES:
      return { ...state, loadingUser: false, files: payload.files }

    case RESET_SUGGEST_PROMOTION:
      return {
        ...state,
        collaborator: {},
        files: [],
        previousRequests: [],
        positionsHistory: [],
        promotionsLevel: [],
      }
  
    case RESET_PROMOTIONS_FILES:
      return {
        ...state,
        files: [],
      };

    case GET_PROMOTION_SUGGESTED_VALIDATORS:
      return { ...state, suggestedValidators: payload.response, loadingSuggestedValidators: true }

    case GET_PROMOTION_SUGGESTED_VALIDATORS_FAIL:
      return { ...state, loadingSuggestedValidators: false }

    case GET_PROMOTION_SUGGESTED_VALIDATORS_SUCCESS:
      return { ...state, loadingSuggestedValidators: false, suggestedValidators: payload.response }

    default:
      return state;
  }
}
