// @packages
import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

//@app
import UserAvatar from 'smu-ui-components/UserAvatar';
import Stepper from 'common/Stepper';
import StepV2 from 'common/StepV2';

// @own
import './styles.scss';

function LeftColumn({
  className,
  steps,
  userInfo,
 }) {
  return (
    <div className={cn('assessment-column', className)}>
      <div className="assessment-column__user">
        <UserAvatar
          className="assessment-column__user-image"
          profileImageCode={userInfo?.profileImageCode}
          width={90}
          height={90}
        />
        <div className="assessment-column__user-info">
          <div className="assessment-column__user-name">
            {userInfo?.firstName} {userInfo?.lastName}
          </div>
          <div className="assessment-column__user-job">
            {userInfo?.job}
          </div>
        </div>
      </div>
      <div className="assessment-column__steps">
        <div className="assessment-column__steps-container">
          <Stepper
            className="assessment-column__stepper"
            vertical
          >
            {steps?.map(step => (
              <StepV2
                className="assessment-column__step"
                description={step?.description}
                finished={step?.finished}
                childSteps ={step?.childSteps}
                disabled={!step?.isActive}
                title={step?.name}
                currentStep ={step?.currentStep}
              />
            ))}
          </Stepper>
        </div>
      </div>
    </div>
  );
};

LeftColumn.defaultProps = {
  userInfo: {},
  isSummaryAnswered: false,
};

LeftColumn.propTypes = {
  className: PropTypes.string,
  isSummaryAnswered: PropTypes.bool,
  texts: PropTypes.string,
  userInfo: PropTypes.object,
};

export default LeftColumn;
