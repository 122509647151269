// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Link from 'smu-ui-components/Link';

// @app
import CardSimple from 'common/CardSimple';
import DashboardPieChart from 'common/DashboardPieChart';
import { actionsMessages, goalsMessages } from 'common/messages';

// @own
import './styles.scss';

const GoalsStatusCounter = ({
  inline,
  intl: { formatMessage },
  link,
  newStyle,
  pieChartConfig,
  loading,
  summaryGoals: {
    closed,
    opened,
    pending,
    total,
  },
  title,
  withoutCard,
}) => {
  const pieChartData = [
    {
      color: '#4B4EFF',
      count: opened,
      name: formatMessage(goalsMessages.GoalsOpened),
    },
    {
      color: '#B0B1FF',
      count: closed,
      name: formatMessage(goalsMessages.GoalsClosedPlural),
    },
    {
      color: '#E3E3FF',
      count: pending,
      name: formatMessage(goalsMessages.GoalsPendingPlural),
    },
  ];
  const pieChartCounter = {
    label: formatMessage(goalsMessages.GoalsGoals),
    total: total,
  };

  return (
    <CardSimple
      className="goals-status-counter"
      loading={loading}
      lowercaseSubtitle
      newStyle={newStyle}
      noMargin
      noPaddingTop={withoutCard}
      title={title}
      withoutCard={withoutCard}
    >
      <DashboardPieChart
        inline={inline}
        pieChartConfig={pieChartConfig}
        pieChartCounter={pieChartCounter}
        pieChartData={pieChartData}
      />
      {link && (
        <div className="goals-status-counter__actions">
          <Link
            color="black"
            onClick={link.onClick}
            size="small"
            to={link.to}
            type="button"
          >
            {formatMessage(actionsMessages.ActionsViewMore)}
          </Link>
        </div>
      )}
    </CardSimple>
  );
};

GoalsStatusCounter.defaultProps = {
  newStyle: false,
  pieChartConfig: {
    size: 145,
    strokeSize: 20,
  },
  summaryGoals: {
    opened: 0,
    closed: 0,
    pending: 0,
    total: 0,
  },
};

GoalsStatusCounter.propTypes = {
  inline: PropTypes.bool,
  intl: PropTypes.object.isRequired,
  link: PropTypes.shape({
    to: PropTypes.object,
    onClick: PropTypes.func,
  }),
  loading: PropTypes.bool,
  newStyle: PropTypes.bool,
  pieChartConfig: PropTypes.shape({
    size: PropTypes.number,
    strokeSize: PropTypes.number,
  }),
  summaryGoals: PropTypes.shape({
    closed: PropTypes.number,
    opened: PropTypes.number,
    pending: PropTypes.number,
    total: PropTypes.number,
  }),
  title: PropTypes.string,
  withoutCard: PropTypes.bool,
};

export default injectIntl(GoalsStatusCounter);
