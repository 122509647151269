import {
  ADD_TO_MY_TEAM,
  ADD_TO_MY_TEAM_FAIL,
  ADD_TO_MY_TEAM_SUCCESS,
} from './actionTypes';

export function addToMyTeam({ leaderId, collaboratorId, type, messages, leaderType, suggestionId }) {
  return {
    type: ADD_TO_MY_TEAM,
    payload: {
      collaboratorId,
      leaderId,
      leaderType,
      messages,
      suggestionId,
      type,
    }
  };
}

export function addToMyTeamFail(error) {
  return {
    type: ADD_TO_MY_TEAM_FAIL,
    payload: { error },
  };
}

export function addToMyTeamSuccess() {
  return {
    type: ADD_TO_MY_TEAM_SUCCESS,
  };
}
