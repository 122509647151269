// @packages
import PropTypes from 'prop-types';
import React from 'react';
import RootModal from 'smu-app-components/RootModal';
import SuccessCard from 'smu-ui-components/SuccessCard';
import { injectIntl } from 'react-intl';

// @app
import { GOAL_DETAIL_SUCCESS_CLOSE_MODAL } from '../../constants/modalTypes';

// @own
import messages from '../messages';

const SuccessfulGoalsCloseModal = ({ close, intl: { formatMessage } }) => {
  return (<SuccessCard
    className="successful-goal-close-modal__success-card"
    footerMessage={formatMessage(messages.FeedbackGoalsDetailSuccessModalCloseFooterMessage)}
    message={formatMessage(messages.FeedbackGoalsDetailSuccessModalCloseMessage)}
    onCloseClick={close}
  />);
};

SuccessfulGoalsCloseModal.propTypes = {
  close: PropTypes.func,
};

const modalStyles = { maxWidth: '600px' };
const withRootModalComponent = RootModal(
  injectIntl(SuccessfulGoalsCloseModal),
  GOAL_DETAIL_SUCCESS_CLOSE_MODAL,
  modalStyles,
  'modal__wrapper',
);

export default withRootModalComponent;
