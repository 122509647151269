// @pakages
import React, { useEffect } from 'react';
import Proptypes from 'prop-types';
import InputText from 'smu-ui-components/InputText';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

// @app
import Title from 'components/atoms/Title';
import { setDataCreateEvaluation } from 'betterme-components/Evaluations/actions';
import { setPerformanceRetrospective } from 'betterme-components/services/PerformanceRetrospective/actions';
import { setSelfRetrospective } from 'betterme-components/services/SelfRetrospective/actions';
import { selectDataCreateEvaluation } from 'betterme-components/Evaluations/selectors';
import { selectEvaluationDetail } from 'betterme-components/EvaluationDetail/selectors';
import { selectPerformanceRetrospective } from 'betterme-components/services/PerformanceRetrospective/selectors';
import { selectSelfRetrospective } from 'betterme-components/services/SelfRetrospective/selectors';
import { sectionsMessages } from 'common/messages';

const EvaluationCreationSetTitle = ({
  createPRData,
  createSRData,
  dataCreateEvaluation,
  evaluationDetail,
  evaluationType,
  intl: { formatMessage },
  setDataCreateEvaluation,
  setPerformanceRetrospective,
  setSelfRetrospective,
}) => {
  useEffect(() => {
    if (evaluationDetail?.id) {
      setDataCreateEvaluation({
        title: evaluationDetail?.title,
      });

      switch (evaluationType) {
        case 'SELF_RETROSPECTIVE':
          setSelfRetrospective({
            title: evaluationDetail?.title,
          });
          break;

        case 'PERFORMANCE_RETROSPECTIVE':
          setPerformanceRetrospective({
            title: evaluationDetail?.title,
          });
          break;

        default:
          break;
      }
    }
  }, [evaluationDetail]);

  function handleChangeText(name, event) {
    let value = event.target.value;
    setDataCreateEvaluation({ [name]: value });

    switch (evaluationType) {
      case 'SELF_RETROSPECTIVE':
        setSelfRetrospective({
          ...createSRData,
          [name]: value,
        });
        break;
      case 'PERFORMANCE_RETROSPECTIVE':
        setPerformanceRetrospective({
          ...createPRData,
          [name]: value,
        });
        break;
      default:
        break;
    }
  }

  const draftId = evaluationDetail?.draftId;
  const isSubmitted = evaluationDetail?.status === 'SUBMITTED';
  const titleValue = draftId && !isSubmitted
    ? evaluationDetail?.title
    : dataCreateEvaluation?.title;

  return (
    <div className="mb-6">
      <Title className="text-black" size={16}>
        {formatMessage(sectionsMessages.SectionsTitle)}
      </Title>
      <InputText
        value={titleValue}
        onChange={(event) => handleChangeText('title', event)}
        maxLength={30}
      />
    </div>
  );
}

EvaluationCreationSetTitle.propTypes = {
  dataCreateEvaluation: Proptypes.object.isRequired,
  evaluationDetail: Proptypes.object.isRequired,
  evaluationType: Proptypes.string,
  intl: Proptypes.object.isRequired,
  setDataCreateEvaluation: Proptypes.bool.isRequired,
  setPerformanceRetrospective: Proptypes.bool.isRequired,
  setSelfRetrospective: Proptypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  createPRData: selectPerformanceRetrospective(state),
  createSRData: selectSelfRetrospective(state),
  dataCreateEvaluation: selectDataCreateEvaluation(state),
  evaluationDetail: selectEvaluationDetail(state),
});

const mapDispatchToProps = {
  setDataCreateEvaluation,
  setPerformanceRetrospective,
  setSelfRetrospective,
};

export default connect(mapStateToProps, mapDispatchToProps)
  (injectIntl(EvaluationCreationSetTitle));
