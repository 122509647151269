import {
  GET_PASSWORD_PROPERTIES,
  GET_PASSWORD_PROPERTIES_FAIL,
  GET_PASSWORD_PROPERTIES_SUCCESS,
  POST_CHANGE_PASSWORD,
  POST_CHANGE_PASSWORD_FAIL,
  POST_CHANGE_PASSWORD_SUCCESS,
} from './actionTypes';

export function getPasswordProperties() {
  return {
    type: GET_PASSWORD_PROPERTIES,
  }
};

export function getPasswordPropertiesFail(error) {
  return {
    type: GET_PASSWORD_PROPERTIES_FAIL,
    payload: { error },
  };
};

export function getPasswordPropertiesSuccess(response) {
  return {
    type: GET_PASSWORD_PROPERTIES_SUCCESS,
    payload: { response },
  };
};

export function postChangePassword({ identification, password }) {
  return {
    type: POST_CHANGE_PASSWORD,
    payload: { identification, password },
  };
}

export function postChangePasswordFail(error) {
  return {
    type: POST_CHANGE_PASSWORD_FAIL,
    payload: { error },
  };
}

export function postChangePasswordSuccess(response) {
  return {
    type: POST_CHANGE_PASSWORD_SUCCESS,
    payload: { response },
  };
}
