// @packages
import React, {
	useEffect,
	useState,
	useMemo,
	useCallback,
} from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import AvatarMedia from 'smu-ui-components/AvatarMedia';
import Select from 'smu-app-components/Select';

// @app
import Title from 'components/atoms/Title';
import useDebounce from 'betterme-components/hooks/useDebounce';
import UserSelectCard from 'betterme-components/components/molecules/UserSelectCard';
import {
	deleteSelectedUser,
	getUsersList,
	resetSelectedUser,
	resetUsersList,
	setSelectedUser,
	setSelectedUserList,
} from 'betterme-components/services/SearchBar/actions';
import {
	selectSelectedUser,
	selectUsersList,
	selectUsersListLoading,
} from 'betterme-components/services/SearchBar/selectors';

const SearchBar = ({
	allowMultipleSelection,
	delay,
	deleteSelectedUser,
	getUsersList,
	optionalCardTitle,
	optionalTitle,
	placeholder,
	resetUsersList,
	resetSelectedUser,
	setSelectedUser,
	setSelectedUserList,
	selectedUser,
	usersList,
	usersListLoading,
	...rest
}) => {
	const [searchValue, setSearchValue] = useState('');
	const debouncedSearchValue = useDebounce(searchValue, delay);
	const isLoading = debouncedSearchValue && usersListLoading;

	const options = useMemo(() => {
		const users = debouncedSearchValue ? usersList : [];
		return users?.map((user) => ({
			label: `${user?.firstName} ${user?.lastName}`,
			user,
			value: user.id,
		}));
	}, [debouncedSearchValue, usersList]);

	const handleSearchChange = (value) => {
		setSearchValue(value);
		if (!isEmpty(value) && (value?.length > 2)) getUsersList({
			query: value,
			selectedUsers: selectedUser,
		});
	};

	const handleChange = (value) => {
		const user = options?.find((o) => o?.user?.id === value)?.user;
		const duplicatedUser = selectedUser?.some(u => u?.id === user?.id);

		if (allowMultipleSelection) {
			if (!duplicatedUser) setSelectedUserList(user);
		} else {
			setSelectedUser(user);
		}
		resetUsersList();
	};

	const renderOptionLabel = useCallback(({ user }) => (
		<AvatarMedia {...user} useLink={false} />
	), []);

	function handleCloseCard(userId) {
		deleteSelectedUser(userId);
	};

	useEffect(() => {
		return () => {
			resetSelectedUser();
			resetUsersList();
		}
	}, []);

	return (
		<>
			{optionalTitle && (
				<Title className="text-black" size={16}>
					{optionalTitle}
				</Title >
			)}
			<Select
				disabled={selectedUser?.length >= 10}
				filterOptionsEnabled={false}
				isLoading={isLoading}
				onChange={handleChange}
				onSearchChange={handleSearchChange}
				options={options}
				placeholder={placeholder}
				renderOptionLabel={renderOptionLabel}
				showSearchIcon
				size="large"
			/>
			{selectedUser?.map(user =>
				<>
					{optionalCardTitle && (
						<Title className="text-black mt-5" size={14}>
							{optionalCardTitle}
						</Title >
					)}
					{!isEmpty(selectedUser) && (
						<UserSelectCard
							handleCloseCard={() => handleCloseCard(user?.id)}
							key={user?.id}
							user={user}
							withActions
							{...rest}
						/>
					)}
				</>
			)}
		</>
	);
}

SearchBar.defaultProps = {
	delay: 500,
	allowMultipleSelection: false,
};

const mapStateToProps = (state) => ({
	selectedUser: selectSelectedUser(state),
	usersList: selectUsersList(state),
	usersListLoading: selectUsersListLoading(state),
});

const mapDispatchToProps = {
	deleteSelectedUser,
	getUsersList,
	resetUsersList,
	resetSelectedUser,
	setSelectedUser,
	setSelectedUserList,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
