// @packages
import { get } from 'lodash';

const reducerDomain = 'leadersByPeriod';

export const selectLeadersByPeriod = (state) =>
  get(state, [reducerDomain, 'leadersByPeriod']);

export const selectLoadingLeadersByPeriod = (state) =>
  get(state, [reducerDomain, 'loadingLeadersByPeriod']);

export const selectErrorLeadersByPeriod = (state) =>
  get(state, [reducerDomain, 'errorLeadersByPeriod']);
