import { defineMessages } from 'react-intl';

export default defineMessages({
  PotentialAssessmentTitle: {
    id: 'Betterme.PotentialAssessment.Title',
    defaultMessage: 'Potential Assessment',
  },
  PotentialAssessmentConfirmationModalDescription: {
    id: 'Betterme.PotentialAssessment.ConfirmationModalDescription',
    defaultMessage: 'Potential Assessment for <b>{assessmentUserName}</b> was sent successfully. Once available, you can see the result in your Team Member\'s Dashboard',
  },
  PotentialAssessmentConfirmationModalAcceptButton: {
    id: 'Betterme.PotentialAssessment.ConfirmationModalAcceptButton',
    defaultMessage: 'Return to Potential Assessment',
  },
});
