import {
  GOT_IT,
  GOT_IT_FAIL,
  GOT_IT_SUCCESS,
} from './actionTypes';

export function gotIt(requestId) {
  return {
    type: GOT_IT,
    payload: { requestId },
  }
};

export function gotItFail(error) {
  return {
    type: GOT_IT_FAIL,
    payload: { error },
  };
};

export function gotItSuccess(response) {
  return {
    type: GOT_IT_SUCCESS,
    payload: { response },
  };
};