/* eslint-disable no-restricted-globals */
// @packages
import cn from 'classnames';
import AvatarMedia from 'smu-ui-components/AvatarMedia';
import GenericEmptyState from 'smu-ui-components/GenericEmptyState';
import IconButton from 'smu-ui-components/IconButton';
import InputArea from 'smu-ui-components/InputArea';
import Nav from 'smu-ui-components/NavHeader/Nav';
import NavHeader from 'smu-ui-components/NavHeader';
import NavItem from 'smu-ui-components/NavHeader/Nav/NavItem';
import Paper from 'smu-ui-components/Paper';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import Segment from 'smu-ui-components/Segment';
import Skills from 'smu-app-components/Skills';
import Tooltip from 'smu-labs/Tooltip';
import Typography from 'smu-ui-components/Typography';
import UserAvatar from 'smu-ui-components/UserAvatar';
import filter from 'lodash/filter';
import get from 'lodash/get';
import some from 'lodash/some';
import uniqBy from 'lodash/uniqBy';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import { base64Decode } from 'smu-utils/base64';
import { closeModal, openModal } from 'smu-app-components/RootModal/actions';
import { connect } from 'react-redux';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import { push } from 'react-router-redux';

// @app
import AIWriteForMe from 'betterme-components/AI/WriteForMe';
import ActionButtons from 'components/molecules/ActionButtons';
import CompetenciesRateSelector from './CompetenciesRateSelector';
import CompetenciesSelector from '../CompetencySelector';
import FeedbackConfirmationModal from './FeedbackConfirmationModal';
import RequestComment from './RequestComment';
import SectionInfo from './SectionInfo';
import {
  SKILL_SELECTOR_MODAL,
  VALIDATION_LOWEST_RATING,
} from '../constants/modalTypes';
import {
  selectAllCollaborators,
  selectAllowsSkills,
  selectConfigurations,
  selectUser,
} from '../Authorization/selectors';
import {
  selectLowestRating,
  selectRatings,
  selectTopics,
} from 'betterme-components/services/Layout/selectors';
import {
  actionsMessages,
  commonMessages,
  feedbackMessages,
  placeholdersMessages,
} from 'common/messages';
import { getFeedbackFeedLocation, getNewFeedbackLocation } from '../routes/urls';
import UploadFile from 'components/containers/UploadFile';
import {
  EXCEEDED_SIZE,
  MAX_FILE_SIZE,
  MAX_FILES,
  NO_SIZE,
  SUPPORTED_FORMATS,
  WRONG_FORMAT,
} from 'betterme-components/constants/fileValidations';
import { runValidations } from 'utils/filesAttach';
import { fileDelete, fileUpload, fileClear } from 'betterme-components/services/FileFeedback/actions';
import { selectFiles, selectFilesLoading } from 'betterme-components/services/FileFeedback/selectors';
import { promotionsMessages } from 'common/messages';
import { getLeadershipAdminLocation } from 'betterme-components/routes/urls';
import { getLocalStoredItem, removeLocalStoredItem } from 'utils/localStorage';
import { ACTION_TYPE_REQUIRED } from 'components/common/constants';
import { getCommunityConfigValueBool } from 'betterme-components/services/CommunityConfigs/selectors';
import { BME_FEEDBACK_TEXT_SUGGESTION } from 'betterme-components/services/CommunityConfigs/constants';
import { selectSelectedUser } from 'betterme-components/services/SearchBar/selectors';
import { resetSelectedUser } from 'betterme-components/services/SearchBar/actions';
import { selectSessionUserId } from 'betterme-components/Authorization/selectors';

// @own
import * as actions from './actions';
import { REQUEST, SEND } from './constants';
import { getProfile } from '../Profile/actions';
import {
  trackCancelFeedbackClick,
  trackCancelRequestFeedbackClick,
  trackCancelRequestFeedbackOnBehalfClick,
  trackReplyFeedbackAddFile,
  trackReplyFeedbackDeleteFile,
  trackRequestFeedbackAddSkill,
  trackRequestFeedbackClick,
  trackRequestFeedbackOnBehalfClick,
  trackRequestFeedbackTabClick,
  trackSendFeedbackAddSkill,
  trackSendFeedbackClick,
} from './analytics';
import messages from './messages';
import {
  selectCancellation,
  selectCompetencies,
  selectDismissal,
  selectFeedback,
  selectLoadingReply,
  selectSelectedCollaborators,
  selectSkills,
  selectType,
  selectUnselectedCompetencies,
  selectUserList,
} from './selectors';
import { selectProfileInfo, selectLoadingGetProfile } from '../Profile/selectors';
import './styles.scss';
import RequestFeedbackEmptyState from './RequestFeedbackEmptyState';
import UserSelector from './UserSelector';
import SuggestedPromotions from '../components/containers/SuggestedPromotions';
import withSuggestedPromotionHOC from '../HOC/withSuggestedPromotionHOC';
import {
  canSubmitCompetencies,
  getSelectedCompetenciesToSubmit,
  getSkillUserId,
  getSkillsActionType,
  getSuggestionParams,
  getUserHeaderProps,
  normalizeChangeSkills,
  normalizeValueSkills,
  transformAnswers,
} from './helpers';

const AttachFileComponent = ({
  className,
  disabled,
  files,
  loading,
  onChange,
  onDeleteAccept,
  supportedFormats,
}) => {
  const Component = useCallback(() => (
    <UploadFile
      className={className}
      disabled={disabled}
      files={files}
      loading={loading}
      onChange={onChange}
      onDeleteAccept={onDeleteAccept}
      renderTitleText={(text) => (
        <Typography className="font-openSans text-base font-bold text-blue normal-case">
          {text}
        </Typography>
      )}
      supportedFormats={supportedFormats}
    />
  ), [
    className,
    disabled,
    files,
    loading,
    onChange,
    onDeleteAccept,
    supportedFormats,
  ]);

  return <Component />;
};
class SendRequestFeedback extends React.Component {

  constructor(props) {
    super(props);
    const {
      defaultRequest,
      defaultRequestOnBehalf,
      location,
    } = props;
    const { state, query } = location;
    const activeTab = query?.tab;
    const stateDefaultRequest = get(state, 'defaultRequest', false);
    this.state = {
      collaboratorsSelected: [],
      requestComment: '',
      sendComment: '',
      withGPT: false,
      showPromotion: false,
      type: activeTab || (
        (defaultRequest || defaultRequestOnBehalf || stateDefaultRequest)
          ? REQUEST
          : SEND
      ),
      promotionAnswers: {
        answers: null,
        isSomeEmpty: true,
      },

    };

    this.handleAddSkill = this.handleAddSkill.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.handleFeedbackTypeSelect = this.handleFeedbackTypeSelect.bind(this);
    this.handleSelectCompetency = this.handleSelectCompetency.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFileAttach = this.handleFileAttach.bind(this);
    this.handleFileDelete = this.handleFileDelete.bind(this);
    this.handleShowSuggestedPromotions = this.handleShowSuggestedPromotions.bind(this);
    this.handleGetPromotionAnswers = this.handleGetPromotionAnswers.bind(this);
    this.handleBreadcrumbsClick = this.handleBreadcrumbsClick.bind(this);
    this.handleChangeSkills = this.handleChangeSkills.bind(this);
  }

  componentDidMount() {
    //TODO start consuming topics from api/communities/{communityId}/topics
    const {
      changeFeedbackType,
      defaultRequest,
      defaultRequestOnBehalf,
      getProfile,
      getRequestFeedbackInfo,
      initCompetencies,
      location: { state },
      params: {
        userId,
        requestId: urlRequestId,
      },
      reply,
      topics,
    } = this.props;
    const stateDefaultRequest = get(state, 'defaultRequest', false);

    initCompetencies(topics);

    if (defaultRequest || defaultRequestOnBehalf || stateDefaultRequest) {
      changeFeedbackType();
    }

    if (state) {
      this.initData(state);
    } else if (reply) {
      getRequestFeedbackInfo(urlRequestId);
    }

    if (userId && !state) {
      getProfile(base64Decode(userId));
    }

    scrollTo(0, 0);
  }

  componentWillReceiveProps(nextProps) {
    const {
      getRequestFeedbackInfo,
      location: {
        state: currentState,
        pathname: currentPathname,
      },
      profile,
      params: { requestId: currentRequestId },
    } = this.props;
    const {
      location: {
        state: nextState,
        pathname: nextPathname,
      },
      profile: nextProfile,
      params: { requestId: nextRequestId },
    } = nextProps;
    const currentUser = get(currentState, 'user', {});
    const nextUser = get(nextState, 'user', {});

    if (
      currentPathname !== nextPathname
      && nextUser.id
      && currentUser.id !== nextUser.id
    ) {
      this.initData(nextState);
    }

    if (
      currentRequestId &&
      nextRequestId &&
      currentRequestId !== nextRequestId
    ) {
      getRequestFeedbackInfo(nextRequestId);
    }

    if (nextProfile && !profile) {
      const location = getNewFeedbackLocation(nextProfile, false);
      this.initData(location.state);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { type: prevType } = prevState;
    const { type: currentType } = this.state;

    const {
      topics: prevTopics,
    } = prevProps;
    const {
      changeFeedbackType,
      topics: currentTopics,
      initCompetencies,
    } = this.props;

    if (prevType !== currentType) {
      changeFeedbackType();
    }

    if (!prevTopics && currentTopics) {
      initCompetencies(currentTopics);
    }
  }

  componentWillUnmount() {
    const {
      cleanSendFeedbackRequestState,
      fileClear,
      resetSelectedUser,
    } = this.props;

    cleanSendFeedbackRequestState();
    fileClear();
    resetSelectedUser();
  }

  handleChangeSkills(skills) {
    const { ratedCompetencies, updateCompetencies } = this.props;
    const changedSkills = normalizeChangeSkills(skills);
    const onlyCompetencies = ratedCompetencies.filter(o => !o?.competency?.skill);
    const allCompetencies = [...onlyCompetencies, ...changedSkills];
    const uniqCompetencies = uniqBy(allCompetencies, (c) => c?.competency?.id);
    updateCompetencies(uniqCompetencies);
  }

  handleShowSuggestedPromotions() {
    this.setState({ showPromotion: !Boolean(this.state.showPromotion) })
  }

  handleGetPromotionAnswers(promotionAnswers) {
    const newState = {
      isSomeEmpty: promotionAnswers.isSomeEmpty,
      answers: transformAnswers(promotionAnswers.data),
    };
    this.setState({
      promotionAnswers: newState
    })
  }

  handleSubmit() {
    const {
      requestComment,
      sendComment,
      type,
      withGPT,
    } = this.state;
    const {
      allowsGlbFeatures,
      createRequestFeedbackOnBehalf,
      defaultRequestOnBehalf,
      files,
      intl: { formatMessage },
      lowestRating,
      openModal,
      ratedCompetencies,
      reply,
      replyRequestFeedback,
      requestFeedback,
      requestId,
      selectedCollaborators,
      selectedUser,
      selfUser,
      sendFeedback,
      user,
    } = this.props;
    const comment = (type === SEND)
      ? sendComment
      : requestComment;
    const { showPromotion, promotionAnswers } = this.state;
    const actionRequired = getLocalStoredItem(ACTION_TYPE_REQUIRED);
    const promotionAnswersToBeSended = !showPromotion ? null : promotionAnswers.answers;
    const selectedCompetencies = getSelectedCompetenciesToSubmit(this.props);

    if (this.canSubmit()) {
      switch (type) {
        case SEND: {
          const recipientIds = user ? [user.id] : selectedUser?.map(({ id }) => id);
          const hasLowestRating = ratedCompetencies.some(item => item?.rateId === lowestRating?.id);
          const filesIds = files.map(file => file.id);
          if (allowsGlbFeatures && !hasLowestRating) {
            openModal({
              modalType: VALIDATION_LOWEST_RATING,
              modalProps: {
                texts: {
                  accept: formatMessage(actionsMessages.ActionsSendAnyway),
                  cancel: formatMessage(actionsMessages.ActionsAddImprovements),
                  description: formatMessage(messages.SendRequestFeedbackLowestRatingDescriptionFeedback)
                },
                ratingBackgroundColor: lowestRating?.color,
                ratingName: lowestRating?.name,
                ratingImageCode: lowestRating?.imageCode,
                onAccept: reply ? replyRequestFeedback : sendFeedback,
                acceptParams: reply
                  ? { requestId, ratedCompetencies, comment, files: filesIds, suggestPromotion: promotionAnswersToBeSended }
                  : { recipientIds, ratedCompetencies, comment, files: filesIds, actionRequired, user, selfUser, suggestPromotion: promotionAnswersToBeSended },
              }
            });
          } else {
            if (reply) {
              replyRequestFeedback({ requestId, ratedCompetencies, comment, files: filesIds, suggestPromotion: promotionAnswersToBeSended });
            } else {
              sendFeedback({ recipientIds, ratedCompetencies, comment, files: filesIds, actionRequired, user, selfUser, suggestPromotion: promotionAnswersToBeSended });
            }
          }

          trackSendFeedbackClick(withGPT);
          break;
        }
        case REQUEST: {
          const recipientIds = user ? [user.id] : selectedUser?.map(({ id }) => id);

          if (defaultRequestOnBehalf) {
            const evalueeIds = selectedCollaborators.map(({ id }) => id);

            createRequestFeedbackOnBehalf({
              comment,
              evalueeIds,
              recipientIds,
              selectedCompetencies,
            });

            trackRequestFeedbackOnBehalfClick();
          } else {
            requestFeedback({
              comment,
              recipientIds,
              selectedCompetencies,
            });

            trackRequestFeedbackClick();
          }
          break;
        }
        default: return ({});
      }
    }
  }

  canSubmit() {
    const { requestComment, sendComment, type } = this.state;
    const {
      defaultRequestOnBehalf,
      selectedCollaborators,
      selectedUser,
      user,
    } = this.props;
    const comment = (type === SEND) ? sendComment : requestComment;
    const trimmedComment = comment.trim();
    const showPromotionSelected = this.state.showPromotion;
    const isSomeEmpty = this.state.promotionAnswers.isSomeEmpty;
    const isUserSelected = user || selectedUser?.length > 0;
    const isCollaboratorSelected = selectedCollaborators?.length > 0;
    const hasUsersSelected = defaultRequestOnBehalf
      ? isUserSelected && isCollaboratorSelected
      : isUserSelected;
    const isEnabledSubmitCompetencies = canSubmitCompetencies(this.props);

    if(!isEnabledSubmitCompetencies) {
      return isEnabledSubmitCompetencies;
    }

    if (type === SEND) {
      if (showPromotionSelected) {
        return isUserSelected && trimmedComment && !isSomeEmpty;
      }
      return isUserSelected && trimmedComment;
    } else if (type === REQUEST) {
      return hasUsersSelected && trimmedComment;
    }
  }

  getRates() {
    const { ratings } = this.props;
    return ratings || [];
  }

  getEmptyStateTitle(cancellation, feedback, dismissal) {
    const {
      intl: { formatMessage },
      user,
    } = this.props;

    if (cancellation) {
      return formatMessage(
        messages.SendRequestFeedbackRequestRequestEmptyStateCancellationTitle,
        { username: `${get(user, 'firstName')}` },
      );
    } else if (feedback) {
      return formatMessage(
        messages.SendRequestFeedbackRequestRequestEmptyStateFeedbackTitle,
        { username: `${get(user, 'firstName')}` },
      );
    } else if (dismissal) {
      return formatMessage(
        messages.SendRequestFeedbackRequestRequestEmptyStateDismissalTitle,
        { username: `${get(user, 'firstName')}` },
      );
    }
  }

  getComboboxUserList() {
    const {
      allCollaborators,
      selectedCollaborators,
      selectedUser,
    } = this.props;

    const isNotUserSelected = user =>
      !some(selectedCollaborators, selectedUser => selectedUser.id === user.id) &&
      !some(selectedUser, selectedUser => selectedUser.id === user.id);

    return filter(allCollaborators, isNotUserSelected);
  }

  handleAddSkill(skills) {
    const {
      addSkills,
      removeCompetency,
    } = this.props;
    const { type } = this.state;
    const trackAddSkill = type === SEND ? trackSendFeedbackAddSkill : trackRequestFeedbackAddSkill;
    addSkills(skills, type);
    trackAddSkill();
    removeCompetency(skills);
  }

  handleCancel() {
    const {
      defaultRequestOnBehalf,
      exitSendRequestFeedback,
      goToLeadershipAdmin,
      user,
    } = this.props;
    const { type, withGPT } = this.state;
    const actionRequired = getLocalStoredItem(ACTION_TYPE_REQUIRED);

    if (type === SEND) {
      trackCancelFeedbackClick(withGPT);
    } else {
      if (defaultRequestOnBehalf) {
        trackCancelRequestFeedbackOnBehalfClick();
      } else {
        trackCancelRequestFeedbackClick();
      }
    }

    exitSendRequestFeedback();

    if (actionRequired?.actionType === 'REMOVE_LEADER_SEND_FEEDBACK') {
      goToLeadershipAdmin(user?.identification);
      removeLocalStoredItem(ACTION_TYPE_REQUIRED);
    }
  }

  handleFeedbackTypeSelect(_event, type) {
    const {
      communityConfigurations: { configurations: { allowsFeedbackRequest } },
      intl: { formatMessage },
      feedbackError
    } = this.props;
    if (type === SEND || (type === REQUEST && allowsFeedbackRequest)) {
      this.setState({ type });
    } else {
      feedbackError(formatMessage(messages.SendRequestFeedbackRequestDisableError));
    }
    if (type === REQUEST) {
      trackRequestFeedbackTabClick();
    }
  }

  handleCommentChange(event, withGPT = false) {
    const { type } = this.state;
    const { value } = event.target;
    const newState = (type === SEND) ? { sendComment: value } : { requestComment: value };
    this.setState({
      ...newState,
      withGPT,
    });
  }

  handleSelectCompetency(competency) {
    const { selectedCompetencies, unselectCompetency, selectCompetency } = this.props;
    const competencySelected = selectedCompetencies.find((selectedCompetency) => selectedCompetency.id === competency.id);
    if (competencySelected) {
      unselectCompetency(competency);
    } else {
      selectCompetency(competency);
    }
  }

  handleBreadcrumbsClick() {
    const { goToPath } = this.props;
    goToPath(getFeedbackFeedLocation());
  }

  initData(routeState) {
    const {
      comment,
      initCompetency,
      requestId,
      requestedBy,
      user,
    } = routeState;
    const {
      defaultRequestOnBehalf,
      initMandatoryCompetencies,
      initReplyRequestFeedbackData,
      initUser,
      reply,
    } = this.props;

    if (reply && initCompetency && initCompetency.length > 0) {
      initMandatoryCompetencies(initCompetency);
    }

    if (reply && requestId) {
      initReplyRequestFeedbackData(requestId, requestedBy, comment);
    }

    if (user) {
      if (defaultRequestOnBehalf) {
        const { addFeedbackCollaborator } = this.props;
        addFeedbackCollaborator(user);
      } else {
        initUser(user);
      }
    }

  }

  handleFileAttach(e) {
    const { target } = e;
    const { files: listFiles } = target;
    const {
      addToastMessage,
      intl: { formatMessage },
      fileUpload,
    } = this.props;
    const errorMessages = {
      [EXCEEDED_SIZE]: formatMessage(promotionsMessages.PromotionsErrorExceededSize, {
        size: MAX_FILE_SIZE
      }),
      [NO_SIZE]: formatMessage(promotionsMessages.PromotionsErrorNoSize),
      [WRONG_FORMAT]: formatMessage(promotionsMessages.PromotionsErrorInvalidFormat, {
        formats: SUPPORTED_FORMATS,
      }),
    }

    if (listFiles.length) {
      const file = listFiles[0];
      const error = runValidations(file, MAX_FILE_SIZE, SUPPORTED_FORMATS);

      if (!error) {
        fileUpload(file);
        target.value = null;
      } else {
        addToastMessage({
          message: errorMessages[error],
          timeout: 3000,
        });
        target.value = null;
      }
    }

    trackReplyFeedbackAddFile();
  }

  handleFileDelete(fileId) {
    const { closeModal, fileDelete } = this.props;

    fileDelete(fileId);
    closeModal();
    trackReplyFeedbackDeleteFile();
  }

  render() {
    const {
      allowsFeedbackPeerPromotionSuggestion,
      allowsFeedbackWithMultipleRecipients,
      allowsRequestedOnBehalf,
      allowsRequestedOnBehalfVisibility,
      allowsSkills,
      cancellation,
      competencies,
      confirmationType,
      defaultRequestOnBehalf,
      dismissal,
      enabledFeedbackTextSuggestions,
      exitSendRequestFeedback,
      feedback,
      fetchingUser,
      files,
      filesLoading,
      intl: { formatMessage },
      loadingReply,
      mandatoryCompetencies,
      ratedCompetencies,
      ratings,
      reply,
      replyRequestComment,
      requestedBy,
      requestId,
      selectedCollaborators,
      selectedCompetencies,
      selectedUser,
      sending,
      sessionUserId,
      skills,
      swrData,
      type,
      unselectedCompetencies,
      user,
    } = this.props;
    const {
      requestComment,
      sendComment,
    } = this.state;

    const selectedUserToSuggested = user ? user : selectedUser?.length === 1 && selectedUser[0];
    const suggestedPromoData = swrData?.data;
    const renderSuggestedPromo = type === SEND
      && selectedUserToSuggested
      && allowsFeedbackPeerPromotionSuggestion
      && suggestedPromoData?.allowsPromotionSuggestion;
    const showEmptyState = cancellation || feedback || dismissal;
    const replyFeedbackNotFound = !requestId && reply;
    const emptyStateTitle = type === SEND ?
      this.getEmptyStateTitle(cancellation, feedback, dismissal) :
      null;
    const emptyStateLink = (type === SEND && feedback) ?
      {
        text: formatMessage(messages.SendRequestFeedbackRequestRequestEmptyStateLinkText),
        to: `/feedback/${feedback.id}`,
      } :
      null;
    const comment = type === SEND ? sendComment : requestComment;
    const placeholderComment = type === SEND ?
      formatMessage(messages.SendRequestFeedbackCommentPlaceholderSend) :
      formatMessage(messages.SendRequestFeedbackCommentPlaceholderRequest);

    const showSelectorCollaborators = defaultRequestOnBehalf && allowsRequestedOnBehalf;
    const showRequestedBy = reply && allowsRequestedOnBehalfVisibility && requestedBy;

    const mainUser = user || selectedUser?.[0];
    const userId = mainUser?.id;
    const username = mainUser?.firstName;
    const skillUserId = getSkillUserId({
      selectedUser,
      selectedCollaborators,
      sessionUserId,
      showSelectorCollaborators,
      type,
      userId,
    });
    const suggestionParams = getSuggestionParams({
      comment,
      ratedCompetencies,
      ratings,
      selectedUser,
      userId,
    });

    const sendRequestFeedbackNode = (
      <React.Fragment>
        <FeedbackConfirmationModal
          confirmationType={confirmationType}
          users={user ? [user] : selectedUser}
          collaborators={selectedCollaborators}
          onModalClose={exitSendRequestFeedback}
        />
        <div className="send-request-feedback__content space-y-4 flex flex-col w-full p-6 max-w-3xl mx-auto">
          <NavHeader shadow>
            <div className="flex items-center space-x-4">
              <Tooltip
                content={formatMessage(
                  messages.SendRequestFeedbackBackToFeedbacks
                )}
              >
                <IconButton
                  className="p-2"
                  icon="arrow-alt-left"
                  onClick={this.handleBreadcrumbsClick}
                />
              </Tooltip>
              {user && (
                <UserAvatar legacy={false} {...getUserHeaderProps(user)} />
              )}
            </div>
            <Nav>
              {!showSelectorCollaborators && (
                <NavItem
                  active={type === SEND}
                  onClick={() => this.handleFeedbackTypeSelect(null, SEND)}
                >
                  {formatMessage(feedbackMessages.FeedbackSendFeedback)}
                </NavItem>
              )}
              {!reply && (
                <NavItem
                  active={type === REQUEST}
                  onClick={() =>
                    !reply && this.handleFeedbackTypeSelect(null, REQUEST)
                  }
                >
                  {formatMessage(
                    showSelectorCollaborators
                      ? messages.SendRequestFeedbackRequestUserSelectorCollaboratorTitle
                      : feedbackMessages.FeedbackRequestFeedback
                  )}
                </NavItem>
              )}
            </Nav>
          </NavHeader>
          {!user && !reply && !fetchingUser && (
            <UserSelector
              allowMultipleSelection={
                allowsFeedbackWithMultipleRecipients || type === REQUEST
              }
              showSelectorCollaborators={showSelectorCollaborators}
              type={type}
            />
          )}
          {type === SEND && (
            <Paper className="p-6" shadow>
              <div className="send-request-feedback__competencies-send">
                <SectionInfo
                  className="send-request-feedback__section-info"
                  subtitle={formatMessage(
                    messages.SendRequestFeedbackRateSelectorSubtitle
                  )}
                  title={formatMessage(feedbackMessages.FeedbackCompetencies)}
                />
                {replyRequestComment && user && (
                  <RequestComment
                    className="send-request-feedback__request-comment"
                    name={formatMessage(
                      messages.SendRequestFeedbackRequestCommentName,
                      { name: user.firstName }
                    )}
                    comment={replyRequestComment}
                  />
                )}
                <CompetenciesRateSelector
                  competencies={unselectedCompetencies?.filter(o => !o?.skill)}
                  mandatoryCompetencies={mandatoryCompetencies?.filter(o => !o?.skill)}
                  onCompetencyDrop={this.props.addCompetency}
                  onRateBoxItemClick={this.props.removeCompetency}
                  ratedCompetencies={ratedCompetencies?.filter(o => !o?.competency?.skill)}
                  rates={this.getRates()}
                />
              </div>
            </Paper>
          )}
          {type === REQUEST && (
            <Paper className="p-6" shadow>
              <div className="send-request-feedback__competencies-request">
                <SectionInfo
                  className="send-request-feedback__section-info"
                  subtitle={formatMessage(
                    messages.SendRequestFeedbackCompetenciesSelectorSubtitle
                  )}
                  title={formatMessage(feedbackMessages.FeedbackCompetencies)}
                />
                <CompetenciesSelector
                  competencies={competencies}
                  mandatoryCompetencies={skills}
                  onCompetencySelect={this.handleSelectCompetency}
                  values={selectedCompetencies}
                />
              </div>
            </Paper>
          )}
          {allowsSkills && (
            <Paper className="p-6" shadow>
              <Skills
                actionType={getSkillsActionType(type, showSelectorCollaborators)}
                key={`skills-${type}-${skillUserId}`}
                maxSuggestions={reply ? 0 : 2}
                onChange={this.handleChangeSkills}
                showMainTitle
                skillItemProps={{
                  showSelector: type === SEND
                }}
                userId={skillUserId}
                value={normalizeValueSkills(ratedCompetencies)}
              />
            </Paper>
          )}
          <Paper className="p-6" shadow>
            <div className="send-request-feedback__comment-wrapper">
              <SectionInfo
                className="send-request-feedback__section-info"
                title={formatMessage(commonMessages.Comment)}
                subtitle={placeholderComment}
              />
              <div className='relative w-full'>
                {enabledFeedbackTextSuggestions && type !== REQUEST && (
                  <AIWriteForMe
                    disabled={
                      !ratedCompetencies?.length || !userId
                    }
                    onChange={(e) => this.handleCommentChange(e, true)}
                    suggestionParams={suggestionParams}
                    username={username}
                    value={comment}
                  />
                )}
                <InputArea
                  autoResize
                  className="send-request-feedback__comment"
                  maxLength={3000}
                  onChange={this.handleCommentChange}
                  placeholder={formatMessage(
                    placeholdersMessages.PlaceholdersLeaveComment
                  )}
                  value={comment}
                />
              </div>
            </div>
          </Paper>
          {type === SEND && (
            <Paper className="" shadow>
              <AttachFileComponent
                className="send-request-feedback__attach-file"
                disabled={files?.length >= MAX_FILES}
                files={files}
                loading={filesLoading}
                onChange={(e) => this.handleFileAttach(e)}
                onDeleteAccept={this.handleFileDelete}
                supportedFormats={SUPPORTED_FORMATS}
              />
            </Paper>
          )}
          {renderSuggestedPromo && (
            <SuggestedPromotions
              currentSeniority={suggestedPromoData?.currentSeniority}
              user={user || selectedUser[0]}
              showPromotion={this.state.showPromotion}
              handleShowPromotion={this.handleShowSuggestedPromotions}
              getPromotionAnswers={this.handleGetPromotionAnswers} />
          )}
          <Paper
            className={cn('p-6 flex items-center', {
              'justify-between': showRequestedBy,
              'justify-end': !showRequestedBy,
            })}
            shadow
          >
            {showRequestedBy && (
              <div className="flex flex-col space-y-2">
                <SectionInfo
                  title={formatMessage(
                    feedbackMessages.FeedbackFeedbackRequestedBy
                  )}
                />
                <AvatarMedia {...requestedBy} />
              </div>
            )}
            <ActionButtons
              buttons={[
                {
                  label: formatMessage(actionsMessages.ActionsCancel),
                  onClick: this.handleCancel,
                  variant: "outline",
                },
                {
                  disabled: !this.canSubmit(),
                  label: (type === SEND)
                    ? formatMessage(actionsMessages.ActionsSend)
                    : formatMessage(messages.SendRequestFeedbackActionsRequestButton),
                  onClick: this.handleSubmit,
                },
              ]}
            />
          </Paper>
        </div>
      </React.Fragment>
    );

    const emptyStateNode = (
      <RequestFeedbackEmptyState
        reasonText={get(dismissal, 'reason.name', '')}
        title={emptyStateTitle}
        link={emptyStateLink}
      />
    );

    const emptyStateDescription = (
      <FormattedHTMLMessage
        defaultMessage={messages.SendRequestFeedbackRequestEmptyStateFeedbackNotFoundDescription.defaultMessage}
        id={messages.SendRequestFeedbackRequestEmptyStateFeedbackNotFoundDescription.id}
        values={{
          xxx1: '/myprofile',
        }}
      />
    );

    const genericEmptyState = (
      <GenericEmptyState
        className="[&_a]:!underline [&_a]:!text-black flex items-center justify-center flex-col rounded mx-10 mt-10 bg-white h-[calc(100vh-140px)]"
        image="clock"
        title={formatMessage(messages.SendRequestFeedbackRequestEmptyStateFeedbackNotFoundTitle)}
        description={emptyStateDescription}
        limitTextWidth={false}
        size="large"
      />
    )

    return (
      <Segment
        loading={sending || loadingReply || fetchingUser}
        className={cn('send-request-feedback', {
          'send-request-feedback--no-background': showEmptyState || loadingReply,
        })}
        loadingType="betterme"
        withChildren={loadingReply ? false : undefined}
      >
        {showEmptyState ? emptyStateNode : (replyFeedbackNotFound ? genericEmptyState : sendRequestFeedbackNode)}
      </Segment>
    );
  }
}

const competencyShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
});

const userShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
});

SendRequestFeedback.propTypes = {
  addCompetency: PropTypes.func.isRequired,
  addSkills: PropTypes.func.isRequired,
  allCollaborators: PropTypes.array,
  allowsGlbFeatures: PropTypes.bool,
  allowsRequestedOnBehalf: PropTypes.bool,
  allowsRequestedOnBehalfVisibility: PropTypes.bool,
  allowsSkills: PropTypes.bool,
  changeFeedbackType: PropTypes.func.isRequired,
  cleanRatedCompetencies: PropTypes.func.isRequired,
  cleanSendFeedbackRequestState: PropTypes.func.isRequired,
  communityConfigurations: PropTypes.shape({
    configurations: PropTypes.shape({
      ratings: PropTypes.arrayOf(PropTypes.shape({
        color: PropTypes.string,
        id: PropTypes.number.isRequired,
        imageCode: PropTypes.string,
        name: PropTypes.string.isRequired,
        ordinality: PropTypes.number.isRequired,
      })),
    }),
    topics: PropTypes.arrayOf(competencyShape),
  }).isRequired,
  competencies: PropTypes.arrayOf(competencyShape).isRequired,
  confirmationType: PropTypes.string,
  defaultRequest: PropTypes.bool,
  enabledFeedbackTextSuggestions: PropTypes.bool,
  exitSendRequestFeedback: PropTypes.func.isRequired,
  fetchingUser: PropTypes.bool,
  fileClear: PropTypes.func.isRequired,
  fileDelete: PropTypes.func.isRequired,
  files: PropTypes.array,
  filesLoading: PropTypes.bool,
  fileUpload: PropTypes.func.isRequired,
  getProfile: PropTypes.func.isRequired,
  getRequestFeedbackInfo: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  initCompetencies: PropTypes.func.isRequired,
  initMandatoryCompetencies: PropTypes.func.isRequired,
  initReplyRequestFeedbackData: PropTypes.func.isRequired,
  initUser: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  location: PropTypes.object,
  lowestRating: PropTypes.object,
  mandatoryCompetencies: PropTypes.arrayOf(competencyShape),
  params: PropTypes.object,
  profile: PropTypes.object,
  ratedCompetencies: PropTypes.arrayOf(PropTypes.shape({
    competency: competencyShape,
    rateId: PropTypes.number,
  })),
  removeCompetency: PropTypes.func.isRequired,
  reply: PropTypes.bool,
  replyRequestComment: PropTypes.string,
  replyRequestFeedback: PropTypes.func.isRequired,
  requestFeedback: PropTypes.func.isRequired,
  requestId: PropTypes.number,
  selectCompetency: PropTypes.func.isRequired,
  selectFeedbackUser: PropTypes.func.isRequired,
  selectedCollaborators: PropTypes.arrayOf(userShape).isRequired,
  selectedCompetencies: PropTypes.array,
  selfUser: PropTypes.object,
  sendFeedback: PropTypes.func.isRequired,
  sending: PropTypes.bool,
  sessionUserId: PropTypes.number,
  skills: PropTypes.array,
  type: PropTypes.string.isRequired,
  unselectCompetency: PropTypes.func.isRequired,
  unselectFeedbackUser: PropTypes.func.isRequired,
  unselectedCompetencies: PropTypes.array,
  user: userShape,
  userList: PropTypes.arrayOf(userShape).isRequired,
};

const mapStateToProps = (state) => {
  const {
    sendRequestFeedback: {
      confirmationType,
      error,
      mandatoryCompetencies,
      ratedCompetencies,
      replyRequestComment,
      requestId,
      requestedBy,
      selectedCompetencies,
      sending,
      user,
    },
    session: { communityConfigurations },
  } = state;
  return {
    allCollaborators: selectAllCollaborators(state),
    allowsFeedbackWithMultipleRecipients:
      selectConfigurations('allowsFeedbackWithMultipleRecipients')(state),
    allowsGlbFeatures: selectConfigurations('allowsGlbFeatures')(state),
    allowsRequestedOnBehalf:
      selectConfigurations('allowsRequestedOnBehalf')(state),
    allowsRequestedOnBehalfVisibility:
      selectConfigurations('allowsRequestedOnBehalfVisibility')(state),
    allowsFeedbackPeerPromotionSuggestion: selectConfigurations("allowsFeedbackPeerPromotionSuggestion")(state),
    allowsSkills: selectAllowsSkills(state),
    cancellation: selectCancellation(state),
    communityConfigurations,
    competencies: selectCompetencies(state),
    confirmationType,
    dismissal: selectDismissal(state),
    enabledFeedbackTextSuggestions: getCommunityConfigValueBool(state, BME_FEEDBACK_TEXT_SUGGESTION),
    error,
    feedback: selectFeedback(state),
    fetchingUser: selectLoadingGetProfile(state),
    files: selectFiles(state),
    filesLoading: selectFilesLoading(state),
    loadingReply: selectLoadingReply(state),
    lowestRating: selectLowestRating(state),
    mandatoryCompetencies,
    profile: selectProfileInfo(state),
    ratedCompetencies,
    ratings: selectRatings(state),
    replyRequestComment,
    requestId,
    requestedBy,
    selectedCollaborators: selectSelectedCollaborators(state),
    selectedCompetencies,
    selectedUser: selectSelectedUser(state),
    selfUser: selectUser(state),
    sending,
    sessionUserId: selectSessionUserId(state),
    skills: selectSkills(state),
    topics: selectTopics(state),
    type: selectType(state),
    unselectedCompetencies: selectUnselectedCompetencies(state),
    user,
    userList: selectUserList(state),
  };
};

const openSkillModal = () => openModal({ modalType: SKILL_SELECTOR_MODAL });
const exitSendRequestFeedback = () => push(getFeedbackFeedLocation());
const goToLeadershipAdmin = (identification) => push(getLeadershipAdminLocation(identification));
const goToPath = (path) => push(path);

const mapDispatchToProps = {
  addToastMessage,
  closeModal,
  exitSendRequestFeedback,
  fileClear,
  fileDelete,
  fileUpload,
  getProfile,
  goToLeadershipAdmin,
  goToPath,
  openModal,
  openSkillModal,
  resetSelectedUser,
  ...actions,
};

const SendRequestFeedbackHOC = withSuggestedPromotionHOC(SendRequestFeedback);
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SendRequestFeedbackHOC));
