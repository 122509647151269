// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

const Title = ({
  children,
  className,
  level,
  nomargin,
  size,
}) => {
  const HeaderTag = `h${level}`;
  return (
    <HeaderTag
      className={twMerge(
        'font-semibold text-loblolly font-montserrat uppercase mb-2.5',
        className,
        nomargin && 'm-0',
      )}
      style={size && {
        fontSize: `${size}px`
      }}
    >
      {children}
    </HeaderTag>
  );
};

Title.defaultProps = {
  level: 3,
};

Title.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  level: PropTypes.number,
  nomargin: PropTypes.bool,
};

export default Title;
