import { trackEvent } from 'smu-utils/gtm';
const MY_PROFILE = 'My_Profile';
const USER_DASHBOARD = 'User_Dashboard';

export const trackDownloadDashboardReport = () => {
  trackEvent({
    category: MY_PROFILE,
    action: 'Download_Dashboard_Report',
  });
};

export const trackFilterFeedbackDashboard = () => {
  trackEvent({
    category: MY_PROFILE,
    action: 'Filter_Feedback_Dashboard',
  });
};

export const trackFeedbackViewDetailClick = () => {
  trackEvent({
    category: MY_PROFILE,
    action: 'Feedback_View_Detail_Click',
  });
};

export const trackFilterFeedbackViewDetail = () => {
  trackEvent({
    category: MY_PROFILE,
    action: 'Filter_Feedback_View_Detail',
  });
};

export const trackSortFeedbackViewDetail = () => {
  trackEvent({
    category: MY_PROFILE,
    action: 'Sort_Feedback_View_Detail',
  });
};

export const trackFeedbackViewDetailAvatarClick = () => {
  trackEvent({
    category: MY_PROFILE,
    action: 'Feedback_View_Detail_Avatar_Click',
  });
};

export const trackFeedbackViewDetailShowingClick = () => {
  trackEvent({
    category: MY_PROFILE,
    action: 'Feedback_View_Detail_Showing_Click',
  });
};

export const trackRequestFeedbackDashboard = () => {
  trackEvent({
    category: MY_PROFILE,
    action: 'Request_Feedback_Dashboard',
  });
};

export const trackFilterGoalDashboard = () => {
  trackEvent({
    category: MY_PROFILE,
    action: 'Filter_Goal_Dashboard',
  });
};

export const trackFilterGoalStatusDashboard = () => {
  trackEvent({
    category: MY_PROFILE,
    action: 'Filter_Goal_Status_Dashboard',
  });
};

export const trackDateFilterDropdown = (option) => {
  trackEvent({
    category: USER_DASHBOARD,
    action: 'TimeFrameFilter_SelectPeriod',
    label: option,
  });
};

export const trackDateFilterCalendar = (date) => {
  trackEvent({
    category: USER_DASHBOARD,
    action: 'TimeFrameFilter_SelectDateRange',
    label: date,
  });
};

export const trackDashFeedbackReceivedViewMore = () => {
  trackEvent({
    category: USER_DASHBOARD,
    action: 'PieChart_ViewMore',
  });
};

export const trackDashGoalsViewMore = () => {
  trackEvent({
    category: 'User_Dashboard',
    action: 'GoalWidget_ViewMore'
  });
};

export const trackPieChartRequestFeedbackEmpty = () => {
  trackEvent({
    'category': USER_DASHBOARD,
    'action': 'PieChart_RequestFeedback_EmptyState',
  });
};

export const trackBoxStackedBarRequestFeedbackEmpty = () => {
  trackEvent({
    'category': USER_DASHBOARD,
    'action': 'StackedBar_RequestFeedback_EmptyState',
  });
};

export const trackDashPotentialAssessmentResultsViewMore = ({ score, type }) => {
  trackEvent({
    category: 'Potential_Assessment',
    action: 'Results',
    label: `View_More_${score}`,
    type: type ? type.toLowerCase() : undefined,
  });
};

export const trackDashPotentialAssessmentResultsEmptyState = (type) => {
  trackEvent({
    category: 'Potential_Assessment',
    action: 'Results',
    label: 'Empty_State',
    type: type ? type.toLowerCase() : undefined,
  });
};
