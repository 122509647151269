import { defineMessages } from 'react-intl';

export default defineMessages({
  EngagementIndicatorFormCancel: {
    defaultMessage: "Cancel",
    id: 'BetterMe.EngagementIndicatorForm.cancel',
  },
  EngagementIndicatorFormCollaboratorTitle: {
    defaultMessage: "COLLABORATOR",
    id: 'BetterMe.EngagementIndicatorForm.collaboratorTitle',
  },
  EngagementIndicatorFormDisconnecting: {
    defaultMessage: 'COFFEE TIME!',
    id: 'BetterMe.EngagementIndicatorForm.disconnecting',
  },
  EngagementIndicatorFormExplanation: {
    defaultMessage: "We suggest you have a significant conversation related to Glober's interest, feelings, career expectations, development and motivations.\n\n**IMPORTANT:** After having the conversation, please don't forget to record all the information since this could drive an array of actions.",
    id: 'BetterMe.EngagementIndicatorForm.explanation',
  },
  EngagementIndicatorFormModalFooterText: {
    defaultMessage: 'The form was successfully sent!',
    id: 'BetterMe.EngagementIndicatorForm.modalFooterText',
  },
  EngagementIndicatorFormModalSuccessTitle: {
    defaultMessage: 'Thank you!',
    id: 'BetterMe.EngagementIndicatorForm.modalSuccessTitle',
  },
  EngagementIndicatorFormSubmit: {
    defaultMessage: "Submit",
    id: 'BetterMe.EngagementIndicatorForm.submit',
  },
  ['form.disconnecting.title.didYouHaveConversation']: {
    defaultMessage: 'Did you have a conversation?',
    id: 'form.disconnecting.title.didYouHaveConversation',
  },
  ['form.disconnecting.title.didYouHaveConversation-answer-yes']: {
    defaultMessage: 'Yes',
    id: 'form.disconnecting.title.didYouHaveConversation-answer-yes',
  },
  ['form.disconnecting.title.didYouHaveConversation-answer-no']: {
    defaultMessage: 'No',
    id: 'form.disconnecting.title.didYouHaveConversation-answer-no',
  },
  ['form.disconnecting.title.didCollaboratorMentionAnyConcern']: {
    defaultMessage: 'Did the collaborator mention any specific concerns?',
    id: 'form.disconnecting.title.didCollaboratorMentionAnyConcern',
  },
  ['form.disconnecting.title.didCollaboratorMentionAnyConcern-answer-no']: {
    defaultMessage: 'No concern',
    id: 'form.disconnecting.title.didCollaboratorMentionAnyConcern-answer-no',
  },
  ['form.disconnecting.title.didCollaboratorMentionAnyConcern-answer-yes']: {
    defaultMessage: 'Yes, one or several',
    id: 'form.disconnecting.title.didCollaboratorMentionAnyConcern-answer-yes',
  },
  ['form.disconnecting.title.didCollaboratorMentionAnyConcern-answer-yes-option-salary']: {
    defaultMessage: 'Salary',
    id: 'form.disconnecting.title.didCollaboratorMentionAnyConcern-answer-yes-option-salary',
  },
  ['form.disconnecting.title.didCollaboratorMentionAnyConcern-answer-yes-option-careerPath']: {
    defaultMessage: 'Career Path',
    id: 'form.disconnecting.title.didCollaboratorMentionAnyConcern-answer-yes-option-careerPath',
  },
  ['form.disconnecting.title.didCollaboratorMentionAnyConcern-answer-yes-option-promotion']: {
    defaultMessage: 'Promotion',
    id: 'form.disconnecting.title.didCollaboratorMentionAnyConcern-answer-yes-option-promotion',
  },
  ['form.disconnecting.title.didCollaboratorMentionAnyConcern-answer-yes-option-changeProject']: {
    defaultMessage: 'Change of project',
    id: 'form.disconnecting.title.didCollaboratorMentionAnyConcern-answer-yes-option-changeProject',
  },
  ['form.disconnecting.title.didCollaboratorMentionAnyConcern-answer-yes-option-mobility']: {
    defaultMessage: 'Mobility',
    id: 'form.disconnecting.title.didCollaboratorMentionAnyConcern-answer-yes-option-mobility',
  },
  ['form.disconnecting.title.didCollaboratorMentionAnyConcern-answer-yes-option-personalReasons']: {
    defaultMessage: 'Personal Reasons',
    id: 'form.disconnecting.title.didCollaboratorMentionAnyConcern-answer-yes-option-personalReasons',
  },
  ['form.disconnecting.title.didCollaboratorMentionAnyConcern-answer-yes-option-other']: {
    defaultMessage: 'Other',
    id: 'form.disconnecting.title.didCollaboratorMentionAnyConcern-answer-yes-option-other',
  },
  ['form.disconnecting.hint.didCollaboratorMentionAnyConcern-answer-yes-option-other-input-writeHere']: {
    defaultMessage: 'Write here',
    id: 'form.disconnecting.hint.didCollaboratorMentionAnyConcern-answer-yes-option-other-input-writeHere',
  },
  ['form.disconnecting.title.didCollaboratorMentionAnyConcern-answer-yes-option-other-input-writeHere']: {
    defaultMessage: 'Write here',
    id: 'form.disconnecting.title.didCollaboratorMentionAnyConcern-answer-yes-option-other-input-writeHere',
  },
  ['form.disconnecting.title.doYouHaveAnyComments']: {
    defaultMessage: 'Do you have any additional comments?',
    id: 'form.disconnecting.title.doYouHaveAnyComments',
  },
  ['form.disconnecting.hint.doYouHaveAnyComments-input-writeHere']: {
    defaultMessage: 'Write here',
    id: 'form.disconnecting.hint.doYouHaveAnyComments-input-writeHere',
  },
  ['form.disconnecting.title.doYouThinkTheCollaboratorIsAtRiskToLeaveTheCompany']: {
    defaultMessage: 'Do you think the collaborator is at risk of leaving the company?',
    id: 'form.disconnecting.title.doYouThinkTheCollaboratorIsAtRiskToLeaveTheCompany',
  },
  ['form.disconnecting.title.doYouThinkTheCollaboratorIsAtRiskToLeaveTheCompany-answer-yes']: {
    defaultMessage: 'Yes',
    id: 'form.disconnecting.title.doYouThinkTheCollaboratorIsAtRiskToLeaveTheCompany-answer-yes',
  },
  ['form.disconnecting.title.doYouThinkTheCollaboratorIsAtRiskToLeaveTheCompany-answer-no']: {
    defaultMessage: 'No',
    id: 'form.disconnecting.title.doYouThinkTheCollaboratorIsAtRiskToLeaveTheCompany-answer-no',
  },
  ['form.disconnecting.title.doYouThinkTheCollaboratorIsAtRiskToLeaveTheCompany-answer-donotknow']: {
    defaultMessage: "I don't know",
    id: 'form.disconnecting.title.doYouThinkTheCollaboratorIsAtRiskToLeaveTheCompany-answer-donotknow',
  },
});
