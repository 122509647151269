// @packages
import React from 'react';
import PropTypes from 'prop-types';

// @own
import CompetencyInfoBoxUI from './CompetencyInfoBoxUI';
import CompetencyInfoBoxDescription from './CompetencyInfoBoxDescription';

const CompetencyInfoBox = ({
  className,
  color,
  competencies,
  description,
  empty: {
    description: emptyDescription,
    info: emptyInfo,
    link: emptyLink,
  },
  inverted,
  link,
  loading,
  stacked,
  title,
  ...rest
}) => {
  const showEmptyState = competencies.length === 0;

  function getInfo() {
    if (showEmptyState) {
      return emptyInfo;
    }

    if (!stacked) {
      return competencies.map(({ name }) => name).join(', ');
    }

    return null;
  }

  function getLink() {
    if (showEmptyState) {
      return emptyLink;
    }

    return link;
  }

  return (
    <CompetencyInfoBoxUI
      className={className}
      color={color}
      description={<CompetencyInfoBoxDescription
        competencies={competencies}
        description={description}
        emptyDescription={emptyDescription}
        showEmptyState={showEmptyState}
        stacked={stacked}
      />}
      info={getInfo()}
      inverted={inverted}
      link={getLink()}
      loading={loading}
      stacked={stacked}
      title={title}
      {...rest}
    />
  );
};

CompetencyInfoBox.defaultProps = {
  link: {},
  empty: {},
};

const linkPropTypes = PropTypes.shape({
  label: PropTypes.node,
  onClick: PropTypes.func,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
});

CompetencyInfoBox.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  competencies: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
  })).isRequired,
  description: PropTypes.node,
  empty: PropTypes.shape({
    description: PropTypes.string,
    info: PropTypes.string,
    link: linkPropTypes,
  }),
  inverted: PropTypes.bool,
  link: linkPropTypes,
  loading: PropTypes.bool,
  stacked: PropTypes.bool,
  title: PropTypes.string,
};

export default CompetencyInfoBox;
