import {
  GET_FEEDBACKS_SUMMARY_TEAM_FAIL,
  GET_FEEDBACKS_SUMMARY_TEAM_SUCCESS,
  GET_FEEDBACKS_SUMMARY_TEAM,
  GET_STARMEUP_KUDOS_INFO_FAIL,
  GET_STARMEUP_KUDOS_INFO_SUCCESS,
  GET_STARMEUP_KUDOS_INFO,
  GET_TEAM_EVALUATIONS_FAIL,
  GET_TEAM_EVALUATIONS_SUCCESS,
  GET_TEAM_EVALUATIONS,
  GET_TEAM_MEMBER_FEEDBACK_RECEIVED_FAIL,
  GET_TEAM_MEMBER_FEEDBACK_RECEIVED_SUCCESS,
  GET_TEAM_MEMBER_FEEDBACK_RECEIVED,
  GET_TEAM_MEMBER_FEEDBACKS_SUMMARY_FAIL,
  GET_TEAM_MEMBER_FEEDBACKS_SUMMARY_SUCCESS,
  GET_TEAM_MEMBER_FEEDBACKS_SUMMARY,
  GET_TEAM_MEMBER_OPPORTUNITIES_FAIL,
  GET_TEAM_MEMBER_OPPORTUNITIES_SUCCESS,
  GET_TEAM_MEMBER_OPPORTUNITIES,
  GET_TEAM_MEMBER_STARMEUP_STARS_SUMMARY_FAIL,
  GET_TEAM_MEMBER_STARMEUP_STARS_SUMMARY_SUCCESS,
  GET_TEAM_MEMBER_STARMEUP_STARS_SUMMARY,
  GET_TEAM_MEMBER_STRENGHTS_FAIL,
  GET_TEAM_MEMBER_STRENGHTS_SUCCESS,
  GET_TEAM_MEMBER_STRENGHTS,
  GET_TEAM_MEMBER_SUMMARY_GOALS_FAIL,
  GET_TEAM_MEMBER_SUMMARY_GOALS_SUCCESS,
  GET_TEAM_MEMBER_SUMMARY_GOALS,
  GET_TEAM_OPPORTUNITIES_FAIL,
  GET_TEAM_OPPORTUNITIES_SUCCESS,
  GET_TEAM_OPPORTUNITIES,
  GET_TEAM_STRENGHTS_FAIL,
  GET_TEAM_STRENGHTS_SUCCESS,
  GET_TEAM_STRENGHTS,
  RESET_TEAM_DASHBOARD_STATE,
} from './actionTypes';

export function getFeedbacksSummaryTeam(userId, from, to) {
  return {
    type: GET_FEEDBACKS_SUMMARY_TEAM,
    payload: { userId, creationTimeFrom: from, creationTimeTo: to },
  };
}

export function getFeedbacksSummaryTeamFail(error) {
  return {
    type: GET_FEEDBACKS_SUMMARY_TEAM_FAIL,
    payload: { error },
  };
}

export function getFeedbacksSummaryTeamSuccess(response) {
  return {
    type: GET_FEEDBACKS_SUMMARY_TEAM_SUCCESS,
    payload: { response },
  };
}

export function getStarmeupKudosInfo() {
  return {
    type: GET_STARMEUP_KUDOS_INFO,
  };
}

export function getStarmeupKudosInfoFail(error) {
  return {
    type: GET_STARMEUP_KUDOS_INFO_FAIL,
    payload: { error },
  };
}

export function getStarmeupKudosInfoSuccess(response) {
  return {
    type: GET_STARMEUP_KUDOS_INFO_SUCCESS,
    payload: { response },
  };
}

export function getTeamEvaluations() {
  return {
    type: GET_TEAM_EVALUATIONS,
  };
}

export function getTeamEvaluationsFail(error) {
  return {
    type: GET_TEAM_EVALUATIONS_FAIL,
    payload: { error },
  };
}

export function getTeamEvaluationsSuccess(response) {
  return {
    type: GET_TEAM_EVALUATIONS_SUCCESS,
    payload: { response },
  };
}

export function getTeamMemberStarmeupSummary(userId, from, to) {
  return {
    type: GET_TEAM_MEMBER_STARMEUP_STARS_SUMMARY,
    payload: { fromDate: from, toDate: to, userId },
  };
}

export function getTeamMemberStarmeupSummaryFail(error, userId) {
  return {
    type: GET_TEAM_MEMBER_STARMEUP_STARS_SUMMARY_FAIL,
    payload: { error, userId },
  };
}

export function getTeamMemberStarmeupSummarySuccess(userId, kudoStats, summary, totalStars) {
  return {
    type: GET_TEAM_MEMBER_STARMEUP_STARS_SUMMARY_SUCCESS,
    payload: { kudoStats, summary, totalStars, userId },
  };
}

export function getTeamMemberFeedbackReceived(userId, from, to) {
  return {
    type: GET_TEAM_MEMBER_FEEDBACK_RECEIVED,
    payload: { userId, creationTimeFrom: from, creationTimeTo: to },
  };
}

export function getTeamMemberFeedbackReceivedFail(error, userId) {
  return {
    type: GET_TEAM_MEMBER_FEEDBACK_RECEIVED_FAIL,
    payload: { error, userId },
  };
}

export function getTeamMemberFeedbackReceivedSuccess(userId, response) {
  return {
    type: GET_TEAM_MEMBER_FEEDBACK_RECEIVED_SUCCESS,
    payload: { response, userId },
  };
}

export function getTeamMemberOpportunities(userId, from, to) {
  return {
    type: GET_TEAM_MEMBER_OPPORTUNITIES,
    payload: { userId, creationTimeFrom: from, creationTimeTo: to },
  };
}

export function getTeamMemberOpportunitiesFail(error, userId) {
  return {
    type: GET_TEAM_MEMBER_OPPORTUNITIES_FAIL,
    payload: { error, userId },
  };
}

export function getTeamMemberOpportunitiesSuccess(userId, response) {
  return {
    type: GET_TEAM_MEMBER_OPPORTUNITIES_SUCCESS,
    payload: { response, userId },
  };
}

export function getTeamMemberStrenghts(userId, from, to) {
  return {
    type: GET_TEAM_MEMBER_STRENGHTS,
    payload: { userId, creationTimeFrom: from, creationTimeTo: to },
  };
}

export function getTeamMemberStrenghtsFail(error, userId) {
  return {
    type: GET_TEAM_MEMBER_STRENGHTS_FAIL,
    payload: { error, userId },
  };
}

export function getTeamMemberStrenghtsSuccess(userId, response) {
  return {
    type: GET_TEAM_MEMBER_STRENGHTS_SUCCESS,
    payload: { response, userId },
  };
}

export function getTeamOpportunities(userId, from, to) {
  return {
    type: GET_TEAM_OPPORTUNITIES,
    payload: { userId, creationTimeFrom: from, creationTimeTo: to },
  };
}

export function getTeamOpportunitiesFail(error) {
  return {
    type: GET_TEAM_OPPORTUNITIES_FAIL,
    payload: { error },
  };
}

export function getTeamOpportunitiesSuccess(response) {
  return {
    type: GET_TEAM_OPPORTUNITIES_SUCCESS,
    payload: { response },
  };
}

export function getTeamStrengths(userId, from, to) {
  return {
    type: GET_TEAM_STRENGHTS,
    payload: { userId, creationTimeFrom: from, creationTimeTo: to },
  };
}

export function getTeamStrengthsFail(error) {
  return {
    type: GET_TEAM_STRENGHTS_FAIL,
    payload: { error },
  };
}

export function getTeamStrengthsSuccess(response) {
  return {
    type: GET_TEAM_STRENGHTS_SUCCESS,
    payload: { response },
  };
}

export function resetTeamDashboardState() {
  return {
    type: RESET_TEAM_DASHBOARD_STATE,
  };
}

export function getTeamMemberSummaryGoals(userId, from, to) {
  return {
    type: GET_TEAM_MEMBER_SUMMARY_GOALS,
    payload: { userId, dueDateFrom: from, dueDateTo: to },
  };
}

export function getTeamMemberSummaryGoalsFail(error, userId) {
  return {
    type: GET_TEAM_MEMBER_SUMMARY_GOALS_FAIL,
    payload: { error, userId },
  };
}

export function getTeamMemberSummaryGoalsSuccess(userId, response) {
  return {
    type: GET_TEAM_MEMBER_SUMMARY_GOALS_SUCCESS,
    payload: { userId, response },
  };
}

export function getTeamMemberFeedbacksSummary(userId, from, to) {
  return {
    type: GET_TEAM_MEMBER_FEEDBACKS_SUMMARY,
    payload: { userId, creationTimeFrom: from, creationTimeTo: to },
  };
}

export function getTeamMemberFeedbacksSummaryFail(error, userId) {
  return {
    type: GET_TEAM_MEMBER_FEEDBACKS_SUMMARY_FAIL,
    payload: { error, userId },
  };
}

export function getTeamMemberFeedbacksSummarySuccess(userId, response) {
  return {
    type: GET_TEAM_MEMBER_FEEDBACKS_SUMMARY_SUCCESS,
    payload: { userId, response },
  };
}
