import { defineMessages } from 'react-intl';

export default defineMessages({
  FeedbackForLeadersSelectionModalChooseLeader: {
    id: 'Betterme.FeedbackForLeaders.SelectionModal.ChooseLeader',
    defaultMessage: 'Choose the leader you want to provide feedback to',
  },
  FeedbackForLeadersSelectionModalAnonymous: {
    id: 'Betterme.FeedbackForLeaders.SelectionModal.Anonymous',
    defaultMessage: 'This feedback will be completely anonymous',
  },
  FeedbackForLeadersSelectionModalCurrentLeaders: {
    id: 'Betterme.FeedbackForLeaders.SelectionModal.CurrentLeaders',
    defaultMessage: 'Current Leaders',
  },
  FeedbackForLeadersSelectionModalPreviousLeaders: {
    id: 'Betterme.FeedbackForLeaders.SelectionModal.PreviousLeaders',
    defaultMessage: 'Previous Leaders',
  },
  FeedbackForLeadersSelectionModalCantFindLeader: {
    id: 'Betterme.FeedbackForLeaders.SelectionModal.CantFindLeader',
    defaultMessage: "Can't find a leader or a mentor?",
  },
  FeedbackForLeadersSelectionModalClickHere: {
    id: 'Betterme.FeedbackForLeaders.SelectionModal.ClickHere',
    defaultMessage: 'Click Here',
  },
  FeedbackForLeadersSelectionModalCompleteThisForm: {
    id: 'Betterme.FeedbackForLeaders.SelectionModal.CompleteThisForm',
    defaultMessage: 'and complete this form.',
  },
  FeedbackForLeadersSelectionModalEvaluated: {
    id: 'Betterme.FeedbackForLeaders.SelectionModal.Evaluated',
    defaultMessage: 'Submitted',
  },
  FeedbackForLeadersSelectionModalEmptyState: {
    id: 'Betterme.FeedbackForLeaders.SelectionModal.EmptyState',
    defaultMessage: "You don't have leaders assigned yet",
  },
  FeedbackForLeadersSelectionModalDontHaveLeader: {
    id: 'Betterme.FeedbackForLeaders.SelectionModal.DontHaveLeader',
    defaultMessage: 'If you don’t have a leader assigned, please <a href={goTo} target="_blank">Click here</a> and complete this form.',
  },
});
