// @packages
import React from 'react';
import CollaboratorList from 'smu-app-components/MyTeam/Dashboard/CollaboratorsList';
import Dashboard from 'smu-layout-components/Dashboard';
import DashboardItem from 'smu-layout-components/DashboardItem';
import EngagementDetectorOverall from 'smu-app-components/MyTeam/Dashboard/EngagementDetectorOverall';
import EvaluationStatus from 'smu-app-components/MyTeam/Dashboard/EvaluationStatus';
import GoalStatus from 'smu-app-components/MyTeam/Dashboard/GoalStatus';
import Insights from 'smu-app-components/MyTeam/Dashboard/Insights';
import PendingTasks from 'smu-app-components/MyTeam/Dashboard/PendingTasks';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// @app
import DashboardTab from 'betterme-components/Team/TeamDashboardTab';
import MyTeamApp from 'betterme-components/MyTeam';
import MyTeamMenu from 'betterme-components/MyTeamMenu';
import {
  ENABLED_DISENGAGEMENT_INDICATOR,
  ENABLED_MYTEAM_INSIGHTS,
  ENABLED_MY_TEAM,
  ENABLED_PENDING_TASKS,
  ENABLED_STARMEUP,
  ENABLED_YIR,
} from 'betterme-components/services/CommunityConfigs/constants';
import { getCommunityConfigValueBool } from 'betterme-components/services/CommunityConfigs/selectors';
import {
  selectAllowsEvaluations,
  selectAllowsGoals,
  selectConfigurations,
  selectDefaultPeriod,
  selectEvaluationPeriod,
  selectUserInfo,
} from 'betterme-components/Authorization/selectors';

// @own
import MyTeamLayout from '../';

function MyTeamDashboardLayout({
  allowsAddToMyTeam,
  allowsGlbFeatures,
  className,
  defaultPeriod,
  enabledDisengagementIndicator,
  enabledEvaluations,
  enabledGoals,
  enabledMyTeam,
  enabledMyTeamInsights,
  enabledPendingTasks,
  enabledStarMeUp,
  enabledYIR,
  evaluationPeriod,
  userInfo,
  ...rest
}) {
  const dateFrom = evaluationPeriod?.startTime || defaultPeriod?.startTime;
  const dateTo = evaluationPeriod?.endTime || defaultPeriod?.endTime;
  const enablersLength = [
    enabledPendingTasks,
    enabledDisengagementIndicator,
    enabledGoals,
    enabledEvaluations,
  ].filter((c) => c).length;
  const multipleEnablers = enablersLength > 1;
  const columns = multipleEnablers ? 4 : 3;
  const insightsSpan = multipleEnablers ? 4 : 2;
  const slidesPerView = multipleEnablers ? 3 : 2;

  const buildUserProfileUrl = (user) =>
    `/profile/${window.btoa(user.identification)}/dashboard`;

  return (
    <MyTeamLayout>
      <MyTeamMenu className="py-8 flex-1" path="/myteam/dashboard">
        {enabledMyTeam ? (
          <Dashboard className="mt-5" columns={columns} spacing={5}>
            {enabledMyTeamInsights && (
              <DashboardItem span={insightsSpan}>
                <Insights
                  enabledYIR={enabledYIR}
                  swiperProps={{
                    slidesPerGroup: 1,
                    slidesPerView,
                  }}
                  username={userInfo.firstName}
                />
              </DashboardItem>
            )}
            {enabledPendingTasks && (
              <DashboardItem>
                <PendingTasks />
              </DashboardItem>
            )}
            {enabledDisengagementIndicator && (
              <DashboardItem>
                <EngagementDetectorOverall />
              </DashboardItem>
            )}
            {enabledGoals && (
              <DashboardItem>
                <GoalStatus
                  dateFrom={dateFrom}
                  dateTo={dateTo}
                  enableEmptyState
                  showEmptyImage={false}
                  userId={userInfo?.id}
                />
              </DashboardItem>
            )}
            {enabledEvaluations && (
              <DashboardItem>
                <EvaluationStatus enableEmptyState showEmptyImage={false} />
              </DashboardItem>
            )}
            <DashboardItem span={4}>
              <CollaboratorList
                buildUserProfileUrl={buildUserProfileUrl}
                enabledLeadershipMentor={allowsGlbFeatures}
                showAddPeopleToMyTeam={allowsAddToMyTeam}
                showLeadershipRequests
                showTitle
              />
            </DashboardItem>
          </Dashboard>
        ) : (
          <MyTeamApp {...rest}>
            <DashboardTab />
          </MyTeamApp>
        )}
      </MyTeamMenu>
    </MyTeamLayout>
  );
}

MyTeamDashboardLayout.propTypes = {
  allowsAddToMyTeam: PropTypes.bool,
  allowsGlbFeatures: PropTypes.bool,
  defaultPeriod: PropTypes.object,
  enabledDisengagementIndicator: PropTypes.bool,
  enabledEvaluations: PropTypes.bool,
  enabledGoals: PropTypes.bool,
  enabledMyTeam: PropTypes.bool,
  enabledMyTeamInsights: PropTypes.bool,
  enabledPendingTasks: PropTypes.bool,
  enabledStarMeUp: PropTypes.bool,
  enabledYIR: PropTypes.bool,
  evaluationPeriod: PropTypes.object,
  userInfo: PropTypes.object,
};

const mapStateToProps = (state) => ({
  allowsAddToMyTeam: selectConfigurations('allowsAddToMyTeam')(state),
  allowsGlbFeatures: selectConfigurations('allowsGlbFeatures')(state),
  defaultPeriod: selectDefaultPeriod(state),
  enabledDisengagementIndicator: getCommunityConfigValueBool(
    state,
    ENABLED_DISENGAGEMENT_INDICATOR
  ),
  enabledEvaluations: selectAllowsEvaluations(state),
  enabledGoals: selectAllowsGoals(state),
  enabledMyTeam: getCommunityConfigValueBool(state, ENABLED_MY_TEAM),
  enabledMyTeamInsights: getCommunityConfigValueBool(
    state,
    ENABLED_MYTEAM_INSIGHTS
  ),
  enabledPendingTasks: getCommunityConfigValueBool(
    state,
    ENABLED_PENDING_TASKS
  ),
  enabledStarMeUp: getCommunityConfigValueBool(state, ENABLED_STARMEUP),
  enabledYIR: getCommunityConfigValueBool(state, ENABLED_YIR),
  evaluationPeriod: selectEvaluationPeriod(state),
  userInfo: selectUserInfo(state),
});

export default connect(mapStateToProps)(MyTeamDashboardLayout);
