// @packages
import React from 'react';
import RootModal from 'smu-app-components/RootModal';
import SuccessCard from 'smu-ui-components/SuccessCard';

// @app
import { CONFIRMATION_CREATE_SUCCESS_MODAL } from 'betterme-components/constants/modalTypes';

// @own
import './styles.scss';

const ConfirmationCreateSuccessModal = ({
  close: closeModal,
  contentMessage,
  footerMessage,
  onClose,
  user,
}) => {
  const handleClose = () => {
    onClose();
    closeModal();
  };

  return (
    <SuccessCard
      className="confirmation-create-success-modal"
      footerMessage={footerMessage}
      message={contentMessage}
      onCloseClick={handleClose}
      solid
      users={[user]}
    />
  );
};

const modalStyles = { maxWidth: '600px' };
const withRootModalComponent = RootModal(
  ConfirmationCreateSuccessModal,
  CONFIRMATION_CREATE_SUCCESS_MODAL,
  modalStyles,
  'modal__wrapper',
);

export default withRootModalComponent;
