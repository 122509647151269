import { call, put, takeLatest } from 'redux-saga/effects';

import { getOsApplicationsFail, getOsApplicationsSuccess } from './actions';

import { GET_OS_APPLICATIONS } from './actionTypes';

import { getOsApplicationsApi } from './api';

function* getOsApplicationsWorker({ payload }) {
  try {
    const response = yield call(getOsApplicationsApi, payload?.params);
    yield put(getOsApplicationsSuccess({ osApplications: response }));
  } catch (error) {
    yield put(getOsApplicationsFail(error));
  }
}

export default function* getOsApplicationsWatcher() {
  yield takeLatest(GET_OS_APPLICATIONS, getOsApplicationsWorker);
}
