// @package
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { chunk, flow, filter } from 'lodash/fp';
import moment from 'moment';
import Segment from 'smu-ui-components/Segment';

// @app
import { formatDate } from 'utils/formatDate';
import { formatOrgEntryDate } from 'utils/formatOrgEntryDate';
import { userMessages, actionsMessages } from 'common/messages';
import UserDetails from 'betterme-components/components/organisms/UserDetails';

const UserDetailsContainer = ({
  account,
  area,
  currentJob,
  currentSeniority,
  endAssignment,
  firstName,
  intl: { formatMessage },
  job,
  lastName,
  loading,
  office,
  onShowDetailsClick,
  profileImageCode,
  project,
  seniority,
  type,
}) => {
  const userPropsList = [
    {
      label: formatMessage(userMessages.UserPosition),
      value: job,
    },
    {
      label: formatMessage(userMessages.UserSeniority),
      value: seniority,
    },
    {
      label: formatMessage(userMessages.UserAccount),
      value: account,
    },
    {
      label: formatMessage(userMessages.UserEndAssignment),
      value: formatDate(endAssignment),
    },
    {
      label: formatMessage(userMessages.UserProject),
      value: project,
    },
    {
      label: formatMessage(userMessages.UserArea),
      value: area,
    },
    {
      label: formatMessage(userMessages.UserOffice),
      value: office,
    },
  ];

  const viewLessMessage = formatMessage(actionsMessages.ActionsViewLess);
  const viewMoreMessage = formatMessage(actionsMessages.ActionsViewMore);

  const arrayPositionAging = currentJob ? formatOrgEntryDate(currentJob.startDate) : '';
  const arraySeniorityAging = currentSeniority ? formatOrgEntryDate(currentSeniority.startDate) : '';
  const getAvailableUserPropList = flow([filter((i) => !!i.value), chunk(2)]);
  const availableUserPropList = getAvailableUserPropList(userPropsList);
  const hiddenUserPropList = availableUserPropList.filter((prop, index) => index > 0);

  const positionAgingMessageformat = () => {
    let positionAging = '';
    if (currentJob?.startDate) {
      const sinceText = formatMessage(userMessages.UserAging);
      const positionDateFrom = moment(currentJob.startDate).format('MMM YYYY');
      const positionAgingDate = arrayPositionAging.map((item) => item?.value ? `${item.value} ${formatMessage(item.key)}` : "").join(" ");
      positionAging = `${sinceText} ${positionDateFrom} (${positionAgingDate})`;
      return positionAging;
    };
    return positionAging;
  };

  const seniorityAgingMessageformat = () => {
    let seniorityAging = '';
    if (currentSeniority?.startDate) {
      const sinceText = formatMessage(userMessages.UserAging);
      const seniorityDateFrom = moment(currentSeniority.startDate).format('MMM YYYY');
      const seniorityAgingDate = arraySeniorityAging.map((item) => item?.value ? `${item.value} ${formatMessage(item.key)}` : "").join(" ");
      seniorityAging = `${sinceText} ${seniorityDateFrom} (${seniorityAgingDate})`;
      return seniorityAging;
    };
    return seniorityAging;
  };

  return (
    <Segment loading={loading} loadingType="betterme" withChildren>
      <UserDetails
        availableUserPropList={availableUserPropList}
        hiddenUserPropList={hiddenUserPropList}
        onShowDetailsClick={onShowDetailsClick}
        positionAgingMessageformat={positionAgingMessageformat}
        seniorityAgingMessageformat={seniorityAgingMessageformat}
        type={type}
        userDetailsInfoPropList={{
          firstName,
          lastName,
          profileImageCode,
        }}
        viewLessMessage={viewLessMessage}
        viewMoreMessage={viewMoreMessage}
      />
    </Segment>
  );
};

UserDetailsContainer.propTypes = {
  account: PropTypes.string,
  area: PropTypes.string,
  currentJob: PropTypes.array,
  currentSeniority: PropTypes.array,
  endAssignment: PropTypes.string,
  firstName: PropTypes.string,
  intl: PropTypes.object,
  job: PropTypes.string,
  lastName: PropTypes.string,
  loading: PropTypes.bool,
  office: PropTypes.string,
  onShowDetailsClick: PropTypes.func,
  profileImageCode: PropTypes.string,
  project: PropTypes.string,
  seniority: PropTypes.string,
};

export default injectIntl(UserDetailsContainer);
