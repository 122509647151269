import { defineMessages } from 'react-intl';

export default defineMessages({
  TellUsAboutModalNamePlaceholder: {
    id: 'Betterme.TellUsAboutModal.NamePlaceholder',
    defaultMessage: 'Your name',
  },
  TellUsAboutModalCommentPlaceholder: {
    id: 'Betterme.TellUsAboutModal.CommentPlaceholder',
    defaultMessage: 'Did you just find an error? Please let us know and our team will fix it as fast as possible.',
  },
});
