// @packages
import React from 'react';
import Divider from 'smu-ui-components/Divider';
import TextParserContainer from 'betterme-components/TextParser';
import UserAvatar from 'smu-ui-components/UserAvatar';
import moment from 'moment';

// @app

// @own
import './styles.scss';

const Item = ({ comment, position }) => {
  const { author, text } = comment;

  return (
    <div className="comment-item">
      <Divider nomargintop />
      <div className="comment-item__header">
        <div className={`comment-item__avatar--${position}`}>
          <UserAvatar profileImageCode={author.profileImageCode} width={28} height={28} />
          <span className="comment-item__author-name">{ author.firstName + ' ' + author.lastName }</span>
          <small className={`comment-item__time--left`}>
            {moment(comment.creationTime).fromNow()}
          </small>
        </div>
      </div>
      <div className="comment-item__line">
        <div className={`comment-item__text--left`}>
          <TextParserContainer>{text}</TextParserContainer>
        </div>
      </div>
    </div>
  );
};

export default Item;
