import { defineMessages } from 'react-intl';

export default defineMessages({
  LeadershipRequestSectionTitle: {
    id: 'Betterme.LeadershipRequest.LeadershipRequest',
    defaultMessage: 'Leadership requests',
  },
  LeadershipRequestMyTeamDashboard: {
    id: 'Betterme.LeadershipRequest.MyTeamDashboard',
    defaultMessage: 'My team\'s dashboard',
  },
  LeadershipRequestMyProfile: {
    id: 'Betterme.LeadershipRequest.MyProfile',
    defaultMessage: 'My profile',
  },
  LeadershipRequestSent: {
    id: 'Betterme.LeadershipRequest.Sent',
    defaultMessage: 'Sent',
  },
  LeadershipRequestReceived: {
    id: 'Betterme.LeadershipRequest.Received',
    defaultMessage: 'Received',
  },
  LeadershipRequestReceivedTabTitle: {
    id: 'Betterme.LeadershipRequest.ReceivedTab.Title',
    defaultMessage: 'You haven’t requests for now',
  },
  LeadershipRequestReceivedTabDescription: {
    id: 'Betterme.LeadershipRequest.ReceivedTab.Description',
    defaultMessage: 'When someone asks you to be a leader, we’ll drop you an email.',
  },
  LeadershipRequestSentTabTitle: {
    id: 'Betterme.LeadershipRequest.SentTab.Title',
    defaultMessage: 'You haven’t sent any requests yet',
  },
  LeadershipRequestSentTabDescription: {
    id: 'Betterme.LeadershipRequest.SentTab.Description',
    defaultMessage: 'When you do, we’ll drop an email to your suggested leader.',
  },
  LeadershipRequestGoToMyTeamDashboard: {
    id: 'Betterme.LeadershipRequest.GoToMyTeamDashboard',
    defaultMessage: 'Go to My Team Dashboard',
  },
  LeadershipRequestGoToMyProfile: {
    id: 'Betterme.LeadershipRequest.GoToMyProfile',
    defaultMessage: 'Go to My Profile',
  },
  ToastMessageSuccess: {
    id: 'Betterme.LeadershipRequest.ToastMessageSuccess',
    defaultMessage: 'Your answer was sent successfully!',
  },
  LeadershipRequestLeaderSubstitution: {
    id: 'Betterme.LeadershipRequest.LeaderSubstitution',
    defaultMessage: 'Leader Substitution',
  },
  LeadershipRequestCurrentLeader: {
    id: 'Betterme.LeadershipRequest.CurrentLeader',
    defaultMessage: 'Current Leader',
  },
  LeadershipRequestNewAccount: {
    id: 'Betterme.LeadershipRequest.NewAccount2',
    defaultMessage: 'New Experience Leader',
  },
  LeadershipRequestNewOtherLeader: {
    id: 'Betterme.LeadershipRequest.NewOtherLeaders',
    defaultMessage: 'New Leaders',
  },
  LeadershipRequestNewCareer: {
    id: 'Betterme.LeadershipRequest.NewCareer2',
    defaultMessage: 'New Career Mentor',
  },
  LeadershipRequestNewMain: {
    id: 'Betterme.LeadershipRequest.NewMain',
    defaultMessage: 'New Main Leader',
  },
  LeadershipRequestNewSecondary: {
    id: 'Betterme.LeadershipRequest.NewSecondary',
    defaultMessage: 'New Secondary Leader',
  },
  LeadershipRequestNewLeader: {
    id: 'Betterme.LeadershipRequest.NewLeader',
    defaultMessage: 'New Leader',
  },
  LeadershipRequestMe: {
    id: 'Betterme.LeadershipRequest.Me',
    defaultMessage: 'Me',
  },
  LeadershipRequestSuccessModal: {
    id: 'Betterme.LeadershipRequest.SuccessModal',
    defaultMessage: '{username} is your new teammate. We\'ll notify them too.',
  },
  LeadershipRequestCancelTitleModal: {
    id: 'Betterme.LeadershipRequest.CancelTitleModal',
    defaultMessage: 'Cancel request',
  }, 
  LeadershipRequestCancelMessageModal: {
    id: 'Betterme.LeadershipRequest.CancelMessageModal',
    defaultMessage: 'Are you sure you want to cancel this leadership request?',
  },
  LeadershipRequestAccountRequest: {
    id: 'Betterme.LeadershipRequest.AccountRequest2',
    defaultMessage: 'requested to be your Experience Leader.',
  },
  LeadershipRequestCareerRequest: {
    id: 'Betterme.LeadershipRequest.CareerRequest2',
    defaultMessage: 'requested to be your Career Mentor.',
  },
  LeadershipRequestOtherLeaderRequest: {
    id: 'Betterme.LeadershipRequest.OtherLeaderRequest',
    defaultMessage: 'requested to be your Leader.',
  },
  LeadershipRequestMainRequest: {
    id: 'Betterme.LeadershipRequest.MainRequest',
    defaultMessage: 'requested to be your Main Leader',
  },
  LeadershipRequestSecondaryRequest: {
    id: 'Betterme.LeadershipRequest.SecondaryRequest',
    defaultMessage: 'requested to be your Secondary Leader',
  },
  LeadershipRequestAccountRequestTitle: {
    id: 'Betterme.LeadershipRequest.AccountRequestTitle2',
    defaultMessage: 'Experience Leader Request',
  },
  LeadershipRequestCareerRequestTitle: {
    id: 'Betterme.LeadershipRequest.CareerRequestTitle2',
    defaultMessage: 'Career Mentor Request',
  },
  LeadershipRequestMainRequestTitle: {
    id: 'Betterme.LeadershipRequest.MainRequestTitle',
    defaultMessage: 'Main Leader Request',
  },
  LeadershipRequestSecondaryRequestTitle: {
    id: 'Betterme.LeadershipRequest.SecondaryRequestTitle',
    defaultMessage: 'Secondary Leader Request',
  },
  LeadershipRequestAccountRequestModal: {
    id: 'Betterme.LeadershipRequest.AccountRequestModal2',
    defaultMessage: 'is your new Experience Leader.',
  },
  LeadershipRequestCareerRequestModal: {
    id: 'Betterme.LeadershipRequest.CareerRequestModal2',
    defaultMessage: 'is your new Career Mentor.',
  },
  LeadershipRequestExperienceRequestModal: {
    id: 'Betterme.LeadershipRequest.OtherLeaderRequestModal2',
    defaultMessage: 'is your new Experience Leader.',
  },
  LeadershipRequestMainRequestModal: {
    id: 'Betterme.LeadershipRequest.MainRequestModal',
    defaultMessage: 'is your new Main Leader',
  },
  LeadershipRequestSecondaryRequestModal: {
    id: 'Betterme.LeadershipRequest.SecondaryRequestModal',
    defaultMessage: 'is your new Secondary Leader',
  },
  LeadershipRequestAutomaticallyAccepted: {
    id: 'Betterme.LeadershipRequest.AutomaticallyAccepted',
    defaultMessage: 'This request has been accepted automatically.',
  },
  LeadershipRequestEmptyState: {
    id: 'Betterme.LeadershipRequest.EmptyState',
    defaultMessage: 'Nobody yet',
  },
});
