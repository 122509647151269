// @packages
import React from 'react';
import Dropdown from 'smu-ui-components/Dropdown';
import PropTypes from 'prop-types';

const FilterDropdown = ({
  config: { ratings, availableFilters },
  ...props
}) => {

  return (<Dropdown
    {...props}
    options={ratings.map(({ name, id }) => (
      { label: `${name}`, value: id, disabled: !availableFilters.includes(id) }
    ))}
    simple
  />);
};

FilterDropdown.propTypes = {
  config: PropTypes.shape({
    availableFilters: PropTypes.array.isRequired,
    ratings: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })).isRequired,
  }),
};

export default FilterDropdown;
