// @packages
import PropTypes from 'prop-types';
import React from 'react';
import RootModal from 'smu-app-components/RootModal';
import Segment from 'smu-ui-components/Segment';

// @app
import { TEAM_EVALUATIONS_HISTORY } from '../../constants/modalTypes';
import SimpleModal from 'common/SimpleModal';
import EvaluationList from '../../EvaluationList';

// @own
import './styles.scss';

const TeamEvaluationsHistory = ({
  close,
  isByScore,
  isGoalScoreContinous,
  loading,
  onClose,
  onItemClick,
  showGlobalScore,
  showGoalsFinalScore,
  teamEvaluations,
  title,
  userName,
}) => {
  const textFooter = `${teamEvaluations.length} ${title}`;
  const handleCloseModal = () => {
    onClose();
    close();
  };

  return (
    <SimpleModal
      className="team-evaluations-history"
      closeModal={handleCloseModal}
      title={title}
    >
      <Segment
        loading={loading}
        loadingType="betterme"
        withChildren={false}
      >
        <EvaluationList
          evaluations={teamEvaluations}
          headerClassName="team-evaluations-history__list-header"
          isByScore={isByScore}
          isGoalScoreContinous={isGoalScoreContinous}
          onItemClick={onItemClick}
          showGlobalScore={showGlobalScore}
          showGoalsFinalScore={showGoalsFinalScore}
          userName={userName}
        />
        <div className="team-evaluations-history__footer">
          {textFooter}
        </div>
      </Segment>
    </SimpleModal>
  );
};

TeamEvaluationsHistory.propTypes = {
  close: PropTypes.func.isRequired,
  isByScore: PropTypes.bool,
  isGoalScoreContinous: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  teamEvaluations: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

const modalStyles = { top: '50%' };

export default RootModal(
  TeamEvaluationsHistory,
  TEAM_EVALUATIONS_HISTORY,
  modalStyles,
  'simple-modal__wrapper'
);
