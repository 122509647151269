// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Combobox from 'smu-ui-components/Combobox';

// @own
import messages from '../messages';

const SkillSearchBox = ({
  className,
  disabled,
  intl: { formatMessage },
  onChange,
  onInputChange,
  skills,
}) => {
  return (
    <Combobox
      className={className}
      disableFilter
      disabled={disabled}
      icon={{ name: 'search', size: 'semi-small' }}
      onChange={onChange}
      onInputChange={onInputChange}
      options={skills.map(({ name, id }) => ({ value: id, label: name }))}
      padding
      placeholder={formatMessage(messages.SkillSelectorSearchPlaceholder)}
      value=""
    />
  );
};

SkillSearchBox.propTypes = {
  className: PropTypes.string,
  intl: PropTypes.object,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  skills: PropTypes.array,
};

export default injectIntl(SkillSearchBox);
