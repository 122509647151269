import {
  get,
  minBy,
} from 'lodash';
import { createSelector } from 'reselect';

const reducerDomain = 'communityInformation';

export const selectScores = (state) =>
  get(state, [reducerDomain, 'scores'], []);

export const selectTopics = (state) =>
  get(state, [reducerDomain, 'topics'], []);

export const selectRatings = (state) =>
  get(state, [reducerDomain, 'ratings'], []);

export const selectLowestRating = createSelector(
  selectRatings,
  (ratings) => {
    const orderedRatings = minBy(ratings, 'value');
    return orderedRatings;
  }
); 