// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const ContentCardFooter = ({ children, className }) => (
  <div className={cn('content-card-teamplate__footer', className)}>{children}</div>
);

ContentCardFooter.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default ContentCardFooter;
