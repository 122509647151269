/* eslint-disable react-hooks/exhaustive-deps */
// @packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import { base64Decode } from 'smu-utils/base64';
import { connect } from 'react-redux';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import { openModal, closeModal } from 'smu-app-components/RootModal/actions';
import { push } from 'react-router-redux';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';

// @app
import CoCreatedBox from 'components/templates/CoCreatedBox';
import CompetenciesSection from 'components/containers/CompetenciesSection';
import Evaluation from 'components/templates/EvaluationsTemplate';
import EvaluationDraftAlertDelete from 'betterme-components/Evaluations/EvaluationDraftAlertDelete';
import EvaluationDraftAlertSubmit from 'components/containers/EvaluationDraftAlertSubmit';
import EvaluationWidgets from 'components/containers/EvaluationWidgets';
import EvaluationsButtons from 'components/organisms/EvaluationButtons';
import ScoreSelector from 'components/templates/ScoreSelector';
import SendEvaluationConfirmationModal from 'components/containers/SendEvaluationConfirmationModal';
import SuggestedActions from 'components/containers/SuggestedActions';
import TalentMatrix from 'betterme-components/TalentMatrix';
import Title from 'components/atoms/Title';
import ValidationLowestRatingModal from 'betterme-components/ValidationLowestRatingModal';
import { selectuserInfo } from 'betterme-components/services/UserInformation/selectors';
import { selectConfigurations } from 'betterme-components/Authorization/selectors';
import { selectRatings, selectLowestRating } from 'betterme-components/services/Layout/selectors';
import { getTeamEvaluationsLocation } from 'betterme-components/routes/urls';
import {
  isValidTalentMatrixDimensions,
  getTalentMatrixBody,
} from 'betterme-components/TalentMatrix/helpers';
import {
  selectDataCreateEvaluation,
  selectLoadingDeleteDraft,
  selectLoadingEvaluationFiles,
  selectOptionsScores,
  selectSuggestedActions,
} from 'betterme-components/Evaluations/selectors';
import {
  deleteDraftEvaluation,
  deleteEvaluationFile,
  evaluationAddSkill,
  evaluationCleanSkills,
  resetDataCreateEvaluation,
  setDataCreateEvaluation,
  uploadEvaluationFile,
} from 'betterme-components/Evaluations/actions';
import { requestRatedTopics, resetRatedTopics } from 'betterme-components/Dashboard/actions';
import { getProfile } from 'betterme-components/Profile/actions';
import { selectProfile } from 'betterme-components/Profile/selectors';
import { MAX_FILES, MAX_FILE_SIZE, SUPPORTED_FORMATS } from 'betterme-components/constants/fileValidations';
import {
  trackEvaluationsAddImprovements,
  trackEvaluationsSendAnyway,
} from 'betterme-components/Evaluations/analytics';
import {
  EVALUATION_DRAFT_DELETE_MODAL,
  EVALUATION_DRAFT_SUBMIT_MODAL,
  SEND_EVALUATION_CONFIRMATION_MODAL,
  VALIDATION_LOWEST_RATING,
} from 'betterme-components/constants/modalTypes';
import {
  actionsMessages,
  evaluationsMessages,
  promotionsMessages,
} from 'common/messages';
import wellDoneMessages from 'betterme-components/Evaluations/messages';
import { createSaveEvaluation } from 'betterme-components/services/EvaluationsCreateSave/actions';
import { formatPeriodDates } from 'utils/formatPeriodDates';
import { handleAttachFile, handleDeleteFile } from 'utils/attachDeleteEvaluationsFilesHelper';
import { handleCommentChange, handleScoreChange } from 'utils/handleScoreCommentHelper';
import { handleCompetencySelect, handleRemoveCompetency } from 'utils/handleCompetenciesHelper';
import { handleSuggestedActionSelect } from 'utils/handleSuggestedActionsHelper';
import { isEnabledTalentMatrixByLeader } from 'betterme-components/TalentMatrix/selectors';
import { removeLocalStoredItem } from 'utils/localStorage';
import { resetEvaluationDetail, getEvaluationDetail } from 'betterme-components/EvaluationDetail/actions';
import { resetSelectLeader } from 'betterme-components/services/ChangeLeaderRequest/actions';
import { selectCreateSaveEvaluationLoading } from 'betterme-components/services/EvaluationsCreateSave/selectors';
import { selectEvaluationDetail, selectIsFetching } from 'betterme-components/EvaluationDetail/selectors';
import { selectEvaluationPeriod } from 'betterme-components/Authorization/selectors';
import { selectSelectedLeader } from 'betterme-components/services/ChangeLeaderRequest/selectors';
import { selectUpdateSubmitEvaluationLoading } from 'betterme-components/services/EvaluationsUpdateSubmit/selectors';
import { updateSubmitEvaluation } from 'betterme-components/services/EvaluationsUpdateSubmit/actions';

// @own
import './styles.scss';
import messages from './messages';
import {
  ACTION_TYPE_REQUIRED,
  COCREATED_EVALUATION,
  CREATE_EVALUATION,
  SUBMIT_DRAFT,
  COCREATED_LINK,
} from './constants';
import {
  trackEvaluationsActionsSuggested,
  trackEvaluationsCompetenciesFilter,
  trackEvaluationsSelectedScore,
  trackEvaluationsDeleteDraft,
  trackEvaluationsDeleteDraftCancel,
  trackEvaluationsDeleteDraftSuccess,
  trackEvaluationsSaveAsDraft,
} from './analytics';

const PeriodEvaluation = ({
  addToastMessage,
  allowsEvaluationCreation,
  allowsEvaluationDrafts,
  allowsRatedTopicsEvaluationsCreation,
  allowsSkills,
  allowsSuggestedActions,
  blockScoreEditionAndDraftDeletion,
  coEvaluationDetail,
  createSaveEvaluation,
  createSaveEvaluationLoading,
  dataEvaluation,
  deleteDraftEvaluation,
  deleteEvaluationFile,
  enableTalentMatrix,
  evaluationAddSkill,
  evaluationCleanSkills,
  evaluationPeriod,
  getEvaluationDetail,
  getProfile,
  goToEvaluationsTeam,
  intl: { formatMessage },
  isFetchingEvaluationDetail,
  loadingCreate,
  loadingDeleteDraft,
  loadingEvaluationsFiles,
  loadingSubmitDraft,
  loadingUpdateDraft,
  lowestRating,
  openModal,
  optionsScores,
  params: { userId, evaluationId },
  ratings,
  requestRatedTopics,
  resetDataCreateEvaluation,
  resetEvaluationDetail,
  resetRatedTopics,
  resetSelectLeader,
  selectedLeader,
  session: { user },
  setDataCreateEvaluation,
  updateSubmitEvaluation,
  updateSubmitEvaluationLoading,
  uploadEvaluationFile,
}) => {
  const coCreatedData = coEvaluationDetail?.coCreated;
  const [coEvaluatorScoreMessagge, setCoEvaluatorScoreMessagge] = useState(false);
  const [comment, setComment] = useState('');
  const [feedbackOverviewDropdownValue, setFeedbackOverviewDropdownValue] = useState('ALL');
  const [feedbackOverviewEnabled, setFeedbackOverviewEnabled] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [score, setScore] = useState('');
  const [showCoevaluatorScore, setShowCoevaluatorScore] = useState(coCreatedData?.flagComment);
  const [showCoevaluatorSkills, setShowCoevaluatorSkills] = useState(coCreatedData?.flagSkills);
  const [suggestedActionsComment, setSuggestedActionsComment] = useState('');
  const [talentMatrixDimensions, setTalentMatrixDimensions] = useState([]);
  const dateTimeFrom = dataEvaluation?.dateTimeFrom;
  const dateTimeTo = dataEvaluation?.dateTimeTo;
  const hasDraft = dataEvaluation?.draftId;
  const coEvaluator = coCreatedData?.evaluator;
  const hasCoEvaluator = !isEmpty(coEvaluator);
  const userIdentification = base64Decode(userId);
  const isLoading = loadingCreate || loadingDeleteDraft || loadingSubmitDraft || loadingUpdateDraft;
  const headerPeriod = formatPeriodDates(dateTimeFrom, dateTimeTo);
  const hasLowestRating = dataEvaluation?.ratedTopics.some(item => item?.rateId === lowestRating?.id);
  const openLowestRatingModal = !hasLowestRating && allowsRatedTopicsEvaluationsCreation;
  const coCreatedScore = coCreatedData?.score;
  const coEvaluatorData = coCreatedData?.evaluator;
  const hasTechnicalInput = coCreatedData?.status === 'SUBMITTED';
  const coEvaluatorRatedSkills = coCreatedData?.ratedTopics?.map(
    topic => {
      const filteredTopics = topic?.topics?.filter((t) => t?.skill)
      return { ...topic, topics: filteredTopics };
    }
  );
  const coEvaluatorRatedTopics = coCreatedData?.ratedTopics?.map(
    topic => {
      const filteredTopics = topic?.topics?.filter((t) => !t?.skill)
      return { ...topic, topics: filteredTopics };
    }
  );
  const coEvaluatorName = `${coEvaluatorData?.firstName} ${coEvaluatorData?.lastName}`;
  const suggestedBy = formatMessage(messages.PeriodEvaluationCoEvaluatorScore,
    { username: coEvaluatorName });
  const coCreatedScoreOptions = optionsScores?.map(score =>
    score?.value === coCreatedScore
      ? { ...score, additionalText: `| ${suggestedBy}` }
      : score
  );
  const scoreMessage = (
    <FormattedHTMLMessage
      defaultMessage={messages.PeriodEvaluationCoEvaluatorScoreMessage.defaultMessage}
      id={messages.PeriodEvaluationCoEvaluatorScoreMessage.id}
    />
  );
  const evaluationBreadcrumbTitle = formatMessage(evaluationsMessages.EvaluationsMyTeamEvaluations);
  const evaluationTitle = hasDraft
    ? formatMessage(evaluationsMessages.EvaluationsEdit)
    : formatMessage(evaluationsMessages.EvaluationsCreate);
  const mainActionText = hasDraft ? SUBMIT_DRAFT : CREATE_EVALUATION;
  const toastMessageSuccess = formatMessage(messages.PeriodEvaluationDraftSaved);
  const toastMessageCoEvaluator = formatMessage(messages.PeriodEvaluationCoEvaluatorDraftSaved)
  const actionButtonTexts = {
    slideOneText: formatMessage(actionsMessages.ActionsSkip),
    slideOneTextAlternative: formatMessage(actionsMessages.ActionsGotIt),
    slideTwoText: formatMessage(actionsMessages.ActionsContinue),
    slideTwoTextAlternative: formatMessage(actionsMessages.ActionsBack),
  };
  const galleryItems = [
    {
      title: formatMessage(messages.PeriodEvaluationCoCreatorSlideOneTitle),
      text: formatMessage(messages.PeriodEvaluationCoCreatorSlideOneText),
    },
    {
      title: formatMessage(messages.PeriodEvaluationCoCreatorSlideTwoTitle),
      text: formatMessage(messages.PeriodEvaluationCoCreatorSlideTwoText),
    },
  ];
  const searchSectionReason = (
    <FormattedHTMLMessage
      defaultMessage={messages.PeriodEvaluationCoCreatorReason.defaultMessage}
      id={messages.PeriodEvaluationCoCreatorReason.id}
      values={{
        href: COCREATED_LINK,
        target: '_blank',
      }}
    />
  );
  const searchSectionDisclaimer = hasCoEvaluator
    ? formatMessage(messages.PeriodEvaluationDeleteCoevaluator)
    : formatMessage(messages.PeriodEvaluationCoCreatorDraft);
  const searchSectionText = hasCoEvaluator
    ? formatMessage(messages.PeriodEvaluationSelectedCoEvaluator)
    : formatMessage(messages.PeriodEvaluationCoCreatorEvaluator);
  const flagCommentValue = showCoevaluatorScore === null
    ? false
    : showCoevaluatorScore;
  const flagSkillsValue = showCoevaluatorSkills === null
    ? false
    : showCoevaluatorSkills;
  const dataCreateEvaluation = {
    comment: dataEvaluation?.comment,
    dateTimeFrom,
    dateTimeTo,
    draftId: dataEvaluation?.draftId,
    evalueeId: dataEvaluation?.evaluee?.id,
    files: dataEvaluation?.attachments.map(file => file?.id),
    flagComment: hasTechnicalInput ? flagCommentValue : null,
    flagSkills: hasTechnicalInput ? flagSkillsValue : null,
    promotion: null,
    ratedTopics: dataEvaluation?.ratedTopics,
    score: dataEvaluation?.score,
    suggestedActionsComment: dataEvaluation?.suggestedActionsComment,
    suggestedActionsIds: dataEvaluation?.suggestedActionsIds,
    title: dataEvaluation?.title,
    type: dataEvaluation?.type,
  };
  const isBlockScoreEditionAndDraftDeletion = hasDraft && blockScoreEditionAndDraftDeletion;
  const isNotBlockScoreEditionAndDraftDeletion = hasDraft && !blockScoreEditionAndDraftDeletion;
  const isValidTalentMatrix = isValidTalentMatrixDimensions(talentMatrixDimensions);

  function handleCoEvaluatorSkills() {
    setShowCoevaluatorSkills(!showCoevaluatorSkills);
  }

  function handleBackTo() {
    goToEvaluationsTeam();
    resetDataCreateEvaluation();
    resetSelectLeader();
    resetEvaluationDetail();
  }

  function handleCoEvaluatorScore() {
    setShowCoevaluatorScore(!showCoevaluatorScore);
  }

  function handleSuggestedActionsCommentChange(value) {
    setSuggestedActionsComment(value);
    let newDataEvaluation = {
      ...dataEvaluation,
      suggestedActionsComment: value,
    };
    setDataCreateEvaluation({ ...newDataEvaluation });
  };

  function handleCreateOrSave(evaluationStatus) {
    const data = {
      ...dataCreateEvaluation,
      coEvaluatorId: coEvaluator?.id || selectedLeader?.id || null,
      status: evaluationStatus,
      talentMatrix: getTalentMatrixBody(talentMatrixDimensions),
    };
    const message = evaluationStatus === 'SUBMITTED'
      ? formatMessage(wellDoneMessages.FeedbackEvaluationsEvaluationSuccessFooterMessageTeam,
        { username: dataEvaluation?.evaluee?.firstName })
      : !isEmpty(selectedLeader)
        ? toastMessageCoEvaluator
        : toastMessageSuccess;
    trackEvaluationsSaveAsDraft(dataEvaluation?.type);
    createSaveEvaluation({
      data,
      dataEvaluee: dataEvaluation?.evaluee,
      dataEvaluator: {
        id: user?.id,
        identification: user?.identification,
      },
      evaluationType: dataEvaluation?.type,
      message,
      evaluationPeriod,
    });
    evaluationCleanSkills();
    resetSelectLeader();
  };

  function handleUpdateSubmitDraft(evaluationStatus) {
    updateSubmitEvaluation({
      data: {
        ...dataCreateEvaluation,
        coEvaluatorId: selectedLeader?.id || coEvaluator?.id || null,
        status: evaluationStatus,
        talentMatrix: getTalentMatrixBody(talentMatrixDimensions),
      },
      dataEvaluee: dataEvaluation?.evaluee,
      dataEvaluator: {
        id: user?.id,
        identification: user?.identification,
      },
      evaluationId: dataEvaluation?.draftId,
      evaluationType: dataEvaluation?.type,
      message: evaluationStatus === 'SUBMITTED'
        ? formatMessage(wellDoneMessages.FeedbackEvaluationsEvaluationSuccessFooterMessageTeam,
          { username: dataEvaluation?.evaluee?.firstName })
        : !isEmpty(selectedLeader)
          ? toastMessageCoEvaluator
          : toastMessageSuccess,
      evaluationPeriod,      
    });
    resetSelectLeader();
  };

  function handleDeleteDraft() {
    trackEvaluationsDeleteDraftSuccess(dataEvaluation?.type);
    deleteDraftEvaluation({
      id: dataEvaluation?.draftId,
      messages: { success: formatMessage(messages.PeriodEvaluationDraftDeleted) },
      typeEvaluation: dataEvaluation?.type,
    });
    closeModal();
  };

  function handleDeleteDraftCancel() {
    trackEvaluationsDeleteDraftCancel(dataEvaluation?.type);
    removeLocalStoredItem(ACTION_TYPE_REQUIRED);
  };

  function handleSubmitDraftCancel() {
    trackEvaluationsDeleteDraftCancel(dataEvaluation?.type);
    removeLocalStoredItem(ACTION_TYPE_REQUIRED);
  };

  function handleConfirmationModal() {
    const isTechnicalEvaluator = !isEmpty(selectedLeader);
    openModal({
      modalType: SEND_EVALUATION_CONFIRMATION_MODAL,
      modalProps: {
        isTechnicalEvaluator,
        sendEvaluation: handleCreateOrSave,
      },
    });
  };

  function handleEvaluationDraftSubmitModal() {
    const showCocreatedMessage = !(!coEvaluationDetail?.coCreated
      || coEvaluationDetail?.coCreated?.status === 'SUBMITTED');

    openModal({
      modalType: EVALUATION_DRAFT_SUBMIT_MODAL,
      modalProps: {
        showCocreatedMessage,
        loading: loadingSubmitDraft || loadingUpdateDraft,
        onAccept: handleUpdateSubmitDraft,
        onCancel: handleSubmitDraftCancel,
      }
    });
  };

  function isValidate() {
    const isValidate = (dataEvaluation?.ratedTopics?.length > 2)
      && (!allowsSuggestedActions || dataEvaluation?.suggestedActionsIds.length > 0)
      && (comment || dataEvaluation?.comment)
      && dataEvaluation?.evaluee
      && (score || dataEvaluation?.score)
      && (!enableTalentMatrix || isValidTalentMatrix);

    return isValidate;
  };

  function isValidDraft() {
    if (hasCoEvaluator || !isEmpty(selectedLeader)) {
      return false;
    } else {
      return !isValidate();
    }
  };

  function handleLowestRatingModal() {
    if (openLowestRatingModal) {
      openModal({
        modalType: VALIDATION_LOWEST_RATING,
        modalProps: {
          onCancel: trackEvaluationsAddImprovements,
          onAccept: () => {
            trackEvaluationsSendAnyway();
            createOrSubmitEvaluation();
          },
          ratingBackgroundColor: lowestRating.color,
          ratingImageCode: lowestRating.imageCode,
          ratingName: lowestRating.name,
          texts: {
            accept: formatMessage(actionsMessages.ActionsSendAnyway),
            cancel: formatMessage(actionsMessages.ActionsAddImprovements),
            description: formatMessage(messages.PeriodEvaluationLowesRating),
          },
        }
      })
    } else {
      createOrSubmitEvaluation();
    };
  };

  function handleDeleteDraftModal() {
    trackEvaluationsDeleteDraft(dataEvaluation?.type);
    openModal({
      modalType: EVALUATION_DRAFT_DELETE_MODAL,
      modalProps: {
        loadingDeleteDraft: loadingDeleteDraft,
        onAccept: handleDeleteDraft,
        onCancel: handleDeleteDraftCancel,
      }
    });
  };

  function getRatedTopics(feedbackSourceFilter) {
    if (dateTimeFrom || dateTimeTo) {
      requestRatedTopics(
        dataEvaluation?.evaluee.id,
        dateTimeFrom.valueOf(),
        dateTimeTo.valueOf(),
        feedbackSourceFilter,
      );
    }
  };

  function createOrSubmitEvaluation() {
    if (hasDraft) {
      handleEvaluationDraftSubmitModal();
    } else {
      handleConfirmationModal();
    }
  };

  function saveOrUpdateDraft() {
    if (hasDraft) {
      handleUpdateSubmitDraft('DRAFT');
    } else {
      handleCreateOrSave('DRAFT');
    }
  };

  const handleFeedbackOverviewDropdownChange = (value) => {
    setFeedbackOverviewDropdownValue(value);
    trackEvaluationsCompetenciesFilter(value, dataEvaluation?.type)
  };

  useEffect(() => {
    if (evaluationId) {
      getEvaluationDetail(evaluationId);
      return;
    }

    if (dateTimeFrom) {
      getProfile(dataEvaluation?.evaluee.identification);
    } else {
      goToEvaluationsTeam();
    }
  }, [evaluationId]);

  useEffect(() => {
    if (evaluationId && !isEmpty(dataEvaluation?.evaluee)) {
      getProfile(dataEvaluation?.evaluee?.identification);
    }
  }, [evaluationId, dataEvaluation?.evaluee]);

  useEffect(() => {
    if (dataEvaluation?.draftId) {
      const draftSkills = dataEvaluation?.ratedTopics
        .filter(topic => topic.competency.skill)
        .map(topic => topic.competency);
      evaluationAddSkill(draftSkills);
      setDataCreateEvaluation({
        ...dataEvaluation,
        dateTimeFrom,
        dateTimeTo,
      });
    }
    return () => {
      evaluationCleanSkills();
    };
  }, [dataEvaluation?.draftId]);

  useEffect(() => {
    if (feedbackOverviewDropdownValue) {
      getRatedTopics(feedbackOverviewDropdownValue);
    }
    else {
      getRatedTopics();
    };
    return () => resetRatedTopics();
  }, [feedbackOverviewDropdownValue]);

  useEffect(() => {
    if (isLoading)
      setRequesting(true);
  }, [isLoading]);

  useEffect(() => {
    const coCreatedData = coEvaluationDetail?.coCreated;
    if (coCreatedData) {
      setShowCoevaluatorScore(coCreatedData?.flagComment);
      setShowCoevaluatorSkills(coCreatedData?.flagSkills);
    }
  }, [coEvaluationDetail]);

  useEffect(() => {
    if (dataEvaluation?.talentMatrix) {
      const initialDimensions = dataEvaluation?.talentMatrix?.dimensions;
      setTalentMatrixDimensions(initialDimensions);
    }
  }, [dataEvaluation?.talentMatrix]);

  useEffect(() => {
    return () => {
      resetDataCreateEvaluation();
      resetEvaluationDetail();
      resetSelectLeader();
    }
  }, []);

  return (
    <div className="period-evaluation">
      <Evaluation
        className="period-evaluation__content"
        headerValues={[
          evaluationBreadcrumbTitle,
          dataEvaluation?.title,
        ]}
        date={headerPeriod}
        mainContentLoading={requesting
          || isFetchingEvaluationDetail
          || updateSubmitEvaluationLoading
          || createSaveEvaluationLoading
        }
        navigateBack={handleBackTo}
        sideContent={!isEmpty(dataEvaluation?.evaluee) && (
          <EvaluationWidgets
            dateTimeFrom={dataEvaluation?.dateTimeFrom}
            dateTimeTo={dataEvaluation?.dateTimeTo}
            evaluationType={dataEvaluation?.type}
            evaluee={dataEvaluation?.evaluee}
          />
        )}
      >
        <Title
          black
          className="period-evaluation__title"
          level={1}
          size={22}
        >
          {evaluationTitle}
        </Title>
        <CoCreatedBox
          actionButtonTexts={actionButtonTexts}
          className="period-evaluation__co-created"
          discoveryType={COCREATED_EVALUATION}
          galleryItems={galleryItems}
          hasCoEvaluator={hasCoEvaluator}
          labelTitle={formatMessage(messages.PeriodEvaluationCoCreatorNew)}
          placeholder={formatMessage(messages.PeriodEvaluationCoCreatorSearchBar)}
          searchSectionDisclaimer={searchSectionDisclaimer}
          searchSectionReason={searchSectionReason}
          searchSectionText={searchSectionText}
          showClarification={isEmpty(selectedLeader)}
          showFeatureDiscovery
          title={formatMessage(messages.PeriodEvaluationCoCreatorTitle)}
          user={coEvaluator}
        />
        {allowsRatedTopicsEvaluationsCreation && (
          <CompetenciesSection
            className="period-evaluation__competencies"
            coEvaluator={{
              hasCoEvaluator: hasCoEvaluator,
              emptyState: formatMessage(messages.PeriodEvaluationCompetenciesEmpty),
              disclaimer: formatMessage(messages.PeriodEvaluationTechnicalSuggestionMessage),
              title: formatMessage(messages.PeriodEvaluationTechnicalSuggestion),
            }}
            coEvaluatorRatedTopics={orderBy(coEvaluatorRatedTopics, 'ordinality')}
            evaluationType={dataEvaluation?.type}
            feedbackOverviewDropdownValue={feedbackOverviewDropdownValue}
            feedbackOverviewEnabled={feedbackOverviewEnabled}
            onCompetencyRemove={(competency) => handleRemoveCompetency(competency, dataEvaluation, setDataCreateEvaluation)}
            onCompetencySelect={(competency, rate) => handleCompetencySelect(competency, rate, dataEvaluation, setDataCreateEvaluation)}
            onFeedbackOverviewDropdownChange={handleFeedbackOverviewDropdownChange}
            ratedTopics={dataEvaluation?.ratedTopics}
            rates={ratings}
            setFeedbackOverviewEnabled={setFeedbackOverviewEnabled}
            user={userIdentification}
          />
        )}
        {enableTalentMatrix && (
          <TalentMatrix
            className="mb-5"
            evaluee={dataEvaluation?.evaluee}
            onChange={setTalentMatrixDimensions}
            value={talentMatrixDimensions}
          />
        )}

        <ScoreSelector
          attachFiles={{
            customText: `(${formatMessage(messages.PeriodEvaluationOverallScoreFiles)})`,
            files: dataEvaluation?.attachments,
            filesLoading: loadingEvaluationsFiles,
            filesMaxLenght: MAX_FILES,
            onChange: (event) => handleAttachFile({
              addToastMessage,
              evaluationType: dataEvaluation?.type,
              event,
              promotionsMessages: {
                promotionsErrorExceededSize: formatMessage(promotionsMessages.PromotionsErrorExceededSize, {
                  size: MAX_FILE_SIZE
                }),
                promotionsErrorNoSize: formatMessage(promotionsMessages.PromotionsErrorNoSize),
                promotionsErrorInvalidFormat: formatMessage(promotionsMessages.PromotionsErrorInvalidFormat, {
                  formats: SUPPORTED_FORMATS,
                }),
              },
              uploadEvaluationFile,
            }),
            onDeleteAccept: (id) => handleDeleteFile(id, deleteEvaluationFile, closeModal),
            supportedFormats: SUPPORTED_FORMATS,
          }}
          className="period-evaluation__score"
          coEvaluator={{
            coCreatedData: coEvaluationDetail?.coCreated,
            emptyState: formatMessage(messages.PeriodEvaluationOverallScoreEmpty),
            onSwitchChange: handleCoEvaluatorScore,
            scoreMessage,
            showCoevaluatorScore,
            showScoreMessage: coEvaluatorScoreMessagge,
            switchEnabled: showCoevaluatorScore,
            switchSubtitle: formatMessage(messages.PeriodEvaluationTechnicalSuggestionSubtitle),
            switchText: formatMessage(messages.PeriodEvaluationSwitch),
            switchTitle: formatMessage(messages.PeriodEvaluationTechnicalSuggestion),
          }}
          hasCoevaluator={hasCoEvaluator}
          hasTooltip
          scoreSelector={{
            disabled: isBlockScoreEditionAndDraftDeletion,
            onScoreChange: (value) => handleScoreChange({
              value,
              dataEvaluation,
              setDataCreateEvaluation,
              setScore,
              setCoEvaluatorScoreMessagge,
              trackEvaluationsSelectedScore,
              coCreatedScore,
              hasTechnicalInput,
            }),
            scoreOptions: coCreatedScoreOptions,
            scorePlaceholder: formatMessage(messages.PeriodEvaluationScorePlaceholder),
            scoreValue: dataEvaluation?.score,
          }}
          textArea={{
            comment: dataEvaluation?.comment,
            placeholder: formatMessage(messages.PeriodEvaluationCommentPlaceholder),
            onTextChange: (event) =>
            handleCommentChange(event, dataEvaluation, setDataCreateEvaluation, setComment),
          }}
          title={formatMessage(evaluationsMessages.EvaluationsOverallScore)}
        />
        <div>
          {allowsSuggestedActions && (
            <SuggestedActions
              className="period-evaluation__suggested-actions"
              promotionMessage={promotionMessage}
              suggestedAction={{
                onSuggestedActionsChange: (suggestedAction) => handleSuggestedActionSelect(
                  suggestedAction,
                  dataEvaluation,
                  setDataCreateEvaluation,
                  trackEvaluationsActionsSuggested,
                ),
                onSuggestedActionsCommentChange: handleSuggestedActionsCommentChange,
                suggeestedActionsIds: dataEvaluation?.suggestedActionsIds,
                suggestedActionsComment: dataEvaluation?.suggestedActionsComment || suggestedActionsComment,
              }}
              user={dataEvaluation?.evaluee}
            />
          )}
        </div>
        <EvaluationsButtons
          className="period-evaluation__action-buttons"
          deleteDraft={{
            deleteDraft: handleDeleteDraftModal,
            isDeleteDraftVisible: isNotBlockScoreEditionAndDraftDeletion,
          }}
          saveDraft={{
            isDraftDisabled: isValidDraft(),
            isDraftVisible: allowsEvaluationDrafts,
            saveDraft: saveOrUpdateDraft,
          }}
          sendEvaluation={{
            isCreationVisible: allowsEvaluationCreation,
            isSendEvaluationDisabled: !isValidate(),
            sendEvaluation: handleLowestRatingModal,
          }}
          type={mainActionText}
        />
      </Evaluation>
      <EvaluationDraftAlertDelete />
      <EvaluationDraftAlertSubmit />
      <SendEvaluationConfirmationModal />
      <ValidationLowestRatingModal />
    </div>
  )
};

PeriodEvaluation.propTypes = {
  allowsEvaluationCreation: PropTypes.bool,
  allowsEvaluationDrafts: PropTypes.bool,
  allowsRatedTopicsEvaluationsCreation: PropTypes.bool,
  allowsSuggestedActions: PropTypes.bool,
  blockScoreEditionAndDraftDeletion: PropTypes.bool,
  dataEvaluation: PropTypes.object,
  deleteDraftEvaluation: PropTypes.func,
  deleteEvaluationFile: PropTypes.func,
  enableTalentMatrix: PropTypes.bool,
  evaluationCleanSkills: PropTypes.func,
  getProfile: PropTypes.func,
  goToEvaluationsTeam: PropTypes.func,
  intl: PropTypes.func,
  loadingDeleteDraft: PropTypes.bool,
  loadingEvaluationsFiles: PropTypes.bool,
  location: PropTypes.object,
  lowestRating: PropTypes.object,
  openModal: PropTypes.func,
  optionsScores: PropTypes.object,
  params: PropTypes.string,
  profile: PropTypes.object,
  ratings: PropTypes.object,
  requestRatedTopics: PropTypes.func,
  resetDataCreateEvaluation: PropTypes.func,
  resetRatedTopics: PropTypes.func,
  session: PropTypes.object,
  setDataCreateEvaluation: PropTypes.func,
  talentMatrixEnabled: PropTypes.bool,
  uploadEvaluationFile: PropTypes.func,
};

const goToEvaluationsTeam = () => push(getTeamEvaluationsLocation());

const mapDispatchToProps = {
  addToastMessage,
  getProfile,
  setDataCreateEvaluation,
  createSaveEvaluation,
  evaluationAddSkill,
  evaluationCleanSkills,
  closeModal,
  deleteDraftEvaluation,
  deleteEvaluationFile,
  getEvaluationDetail,
  goToEvaluationsTeam,
  requestRatedTopics,
  resetDataCreateEvaluation,
  resetRatedTopics,
  updateSubmitEvaluation,
  uploadEvaluationFile,
  openModal,
  resetSelectLeader,
  resetEvaluationDetail,
};

//TODO: Separate this logic
const getDataEvaluation = (state, props) => {
  const createEvaluationData = selectDataCreateEvaluation(state);
  const { evaluationId } = props.params;

  const isCoevaluationFromNotification = evaluationId
    && !isEmpty(selectEvaluationDetail(state))
    && !createEvaluationData.handleDataEvaluationAsigment;

  const evaluationDetail = selectEvaluationDetail(state);

  if (isCoevaluationFromNotification) {
    const suggestedActionsIds = selectSuggestedActions(state)
      .filter(({ name }) => evaluationDetail?.selectedSuggestedActions?.suggestedActions
        .some(action => action.name === name))
      .map(({ id }) => id);

    const ratedTopics = evaluationDetail?.ratedTopics?.reduce((acc, ratedTopic) => {
      const competenciesWithRate = ratedTopic.topics.map(competency =>
        ({ competency, rateId: ratedTopic.id }));
      return [...acc, ...competenciesWithRate];
    }, []);

    const newDataEvaluation = {
      ...createEvaluationData,
      ...evaluationDetail,
      customScore: evaluationDetail?.customScore?.value,
      draftId: evaluationDetail?.id,
      handleDataEvaluationAsigment: true,
      nineBoxComment: evaluationDetail?.selectedNineBox?.comment,
      nineBoxScore: evaluationDetail?.selectedNineBox?.nineBoxScore?.id,
      ratedTopics,
      score: evaluationDetail?.score?.value || 0,
      suggestedActionsComment: evaluationDetail?.selectedSuggestedActions?.comment,
      suggestedActionsIds,
    };

    return newDataEvaluation;
  }

  return {
    ...createEvaluationData,
    talentMatrix: evaluationDetail?.talentMatrix,
  };
}

const mapSateToProps = (state, props) => ({
  allowsEvaluationCreation: selectConfigurations('allowsPeriodEvaluationCreation')(state),
  allowsEvaluationDrafts: selectConfigurations('allowsPeriodEvaluationDraft')(state),
  allowsRatedTopicsEvaluationsCreation: selectConfigurations('allowsRatedTopicsEvaluationsCreation')(state),
  allowsSkills: selectConfigurations('allowsSkills')(state),
  allowsSuggestedActions: selectConfigurations('allowsEvaluationsSuggestedActions')(state),
  blockScoreEditionAndDraftDeletion: selectConfigurations('blockScoreEditionAndDraftDeletion')(state),
  coEvaluationDetail: selectEvaluationDetail(state),
  createSaveEvaluationLoading: selectCreateSaveEvaluationLoading(state),
  dataEvaluation: getDataEvaluation(state, props),
  enableTalentMatrix: isEnabledTalentMatrixByLeader(state, base64Decode(props.params.userId)),
  evaluationPeriod: selectEvaluationPeriod(state),
  isFetchingEvaluationDetail: selectIsFetching(state),
  loadingDeleteDraft: selectLoadingDeleteDraft(state),
  loadingEvaluationsFiles: selectLoadingEvaluationFiles(state),
  lowestRating: selectLowestRating(state),
  optionsScores: selectOptionsScores(state),
  profile: selectProfile(state),
  ratings: selectRatings(state),
  selectedLeader: selectSelectedLeader(state),
  suggestedActions: selectSuggestedActions(state),
  updateSubmitEvaluationLoading: selectUpdateSubmitEvaluationLoading(state),
  userInfo: selectuserInfo(state),
});

export default connect(
  mapSateToProps,
  mapDispatchToProps
)(injectIntl(PeriodEvaluation));
