// @packages
import { useEffect } from 'react';
import PropTypes from 'prop-types';

function QuestionnaireHome({ params, router }) {
  const { questionnaireId } = params;

  useEffect(() => {
    router.push(`/questionnaire/${questionnaireId}`);
  }, [questionnaireId, router]);

  return null;
}

QuestionnaireHome.propTypes = {
  params: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};

export default QuestionnaireHome;
