import { takeLatest, call, put, select } from 'redux-saga/effects';
import {
  getTeamFeedbacks,
  requestComments,
  sendComment,
} from '../api';
import { selectLastRequest } from './selectors';
import {
  GET_TEAM_FEEDBACKS,
  GET_TEAM_FEEDBACKS_COMMENTS,
  POST_TEAM_FEEDBACKS_COMMENTS,
} from './actionTypes';
import {
  getTeamFeedbacksFail,
  getTeamFeedbacksSuccess,
  getTeamFeedbacksComments,
  getTeamFeedbacksCommentsFail,
  getTeamFeedbacksCommentsSuccess,
  postTeamFeedbacksCommentsFail,
  postTeamFeedbacksCommentsSuccess,
} from './actions';

function* getTeamFeedbacksWorker({ payload }) {
  try {
    if (!payload) {
      payload = yield select(selectLastRequest);
    }

    const params = {
      creationTimeFrom: payload.creationTimeFrom,
      creationTimeTo: payload.creationTimeTo,
      ordering: payload.ordering,
      pageNumber: payload.pageNumber,
      pageSize: 20,
      recipientId: payload.recipientId,
    };

    const response = yield call(getTeamFeedbacks, params);
    yield put(getTeamFeedbacksSuccess({ ...response, pageNumber: payload.pageNumber }));
  } catch (error) {
    yield put(getTeamFeedbacksFail(error));
  }
}

function* getTeamFeedbacksCommentsWorker({ payload }) {
  const { feedbackId } = payload;
  try {
    const response = yield call(requestComments, feedbackId);
    yield put(getTeamFeedbacksCommentsSuccess(response, feedbackId));
  } catch (error) {
    yield put(getTeamFeedbacksCommentsFail(error, feedbackId));
  }
}

function* postTeamFeedbacksCommentsWorker({ payload }) {
  try {
    const { feedbackId, comment } = payload;
    const data = { message: comment };

    const response = yield call(sendComment, feedbackId, data);
    yield put(postTeamFeedbacksCommentsSuccess(response, feedbackId));  
    yield put(getTeamFeedbacksComments(feedbackId));
  } catch (error) {
    yield put(postTeamFeedbacksCommentsFail(error));
  }
}

export default function* teamFeedbacksWatcher() {
  yield takeLatest(GET_TEAM_FEEDBACKS, getTeamFeedbacksWorker);
  yield takeLatest(GET_TEAM_FEEDBACKS_COMMENTS, getTeamFeedbacksCommentsWorker);
  yield takeLatest(POST_TEAM_FEEDBACKS_COMMENTS, postTeamFeedbacksCommentsWorker);
}
