// @packages
import React from 'react';
import PropTypes from 'prop-types';
import RootModal from 'smu-app-components/RootModal';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

// @app
import { SIZE_FIFTY, SIZE_THIRTY_SIX } from 'components/common/constants';
import { actionsMessages } from 'common/messages';
import { centerModalClass } from 'betterme-components/ConfirmationModal';
import { ADD_ME_HERE_MODAL } from 'betterme-components/constants/modalTypes';
import { addToMyTeam } from 'betterme-components/services/LeadershipRequest/actions';
import { selectLoading } from 'betterme-components/services/LeadershipRequest/selectors';
import GenericModal from 'components/templates/GenericModalTemplate';
import InfoCard from 'components/molecules/InfoCard';

// @own
import AddMemberMessages from './messages';

const AddMeHereModal = ({
  addToMyTeam,
  closeModal,
  collaborator,
  intl: { formatMessage },
  leaderId,
  leaderType,
  loading,
  messages,
  type,
  suggestionId,
}) => {
  const actionButtons = [
    {
      label: formatMessage(actionsMessages.ActionsCancel),
      onClick: handleCancel,
      variant: 'outline',
    },
    {
      label: formatMessage(actionsMessages.ActionsYesAddMe),
      onClick: () => addToMyTeam({
        collaboratorId: collaborator?.id,
        leaderId,
        leaderType,
        messages,
        type,
        suggestionId,
      }),
    },
  ];
  const collaboratorFullName = `${collaborator.firstName} ${collaborator.lastName}`;

  function handleCancel() {
    closeModal();
  }

  return (
    <GenericModal
      actionButtons={actionButtons}
      header={
        <InfoCard
          icon="info"
          iconSize={SIZE_FIFTY}
          title={
            formatMessage(AddMemberMessages.AddMemberTitleHeading)
          }
          titleSize={SIZE_THIRTY_SIX}
          message={formatMessage(AddMemberMessages.AddMemberMessage,
            { fullName: collaboratorFullName },
          )}
        />
      }
      loading={loading}
      success
      onClose={closeModal}
    />
  );
};

AddMeHereModal.defaultProps = {
  messages: { fail: null , success: null },
}

AddMeHereModal.propTypes = {
  addToMyTeam: PropTypes.func,
  closeModal: PropTypes.func,
  collaborator: PropTypes.object,
  intl: PropTypes.object,
  leaderId: PropTypes.number,
  leaderType: PropTypes.string,
  loading: PropTypes.bool,
  messages: PropTypes.object,
  type: PropTypes.string,
};

const modalStyles = {
  width: '610px',
};

const mapStateToProps = state => ({
  loading: selectLoading(state),
});

const mapDispatchToProps = { addToMyTeam };

export default RootModal(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(AddMeHereModal)),
  ADD_ME_HERE_MODAL,
  modalStyles,
  centerModalClass,
);
