import { trackEvent } from 'smu-utils/gtm';
export const EVALUATIONS = 'Evaluations';
export const SKILLS = 'Skills';
export const FEEDBACK = 'Feedback';

export const trackPopUpAdd = (skillId) => {
  trackEvent({
    action: 'PopUp_Add_Skill',
    category: FEEDBACK,
    label: skillId,
    type: 'fdbk_awaiting',
  });
};

export const trackPopUpCancel = () => {
  trackEvent({
    category: FEEDBACK,
    action: 'PopUp_Add_Skill',
    label: 'Cancel',
    type: 'fdbk_awaiting',
  });
};
