// @packages
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { DragLayer } from 'react-dnd';

// @own
import CompetencyDragPreview from '../CompetencyDragPreview';
import './styles.scss';

function collect(monitor) {
  return {
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging()
  };
}

function getItemStyles(currentOffset) {
  if (!currentOffset) {
    return {
      display: 'none'
    };
  }

  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform: transform,
    WebkitTransform: transform
  };
}

class CustomDragLayer extends Component {

  render() {
    const { currentOffset, isDragging, item } = this.props;

    return (
      isDragging && !!item.competency &&
        <div className="custom-drag-layer">
          <div className="custom-drag-layer__item-preview-wrapper" style={getItemStyles(currentOffset)} >
            <CompetencyDragPreview competency={item.competency}/>
          </div>
        </div>
    );
  }

}

CustomDragLayer.propTypes = {
  currentOffset: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  isDragging: PropTypes.bool,
  item: PropTypes.shape({
    competency: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
  }),
};

export default DragLayer(collect)(CustomDragLayer);
