import { trackEvent } from 'smu-utils/gtm';


const eventCategory = 'Potential_Assessment';
const eventAction = 'Homepage';

export const trackLearnMore = () => {
  trackEvent({
    category: eventCategory,
    action: eventAction,
    label: 'Learn_More',
  });
};

export const trackGoToForm = () => {
  trackEvent({
    category: eventCategory,
    action: eventAction,
    label: 'Go_To_Form',
  });
};

export const trackSelectUser = (unSelectedUser) => {
  trackEvent({
    category: eventCategory,
    action: eventAction,
    label: unSelectedUser,
  });
};

export const trackLetsGO = () => {
  trackEvent({
    category: eventCategory,
    action: eventAction,
    label: 'Lets_Go',
  });
};
