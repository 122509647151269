import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import { takeLatest, call, put } from 'redux-saga/effects';
import {
  getRatedTopics
} from '../../api';
import { GET_TOPICS_RATED } from './actionTypes';
import {
  getTopicsRatedFail,
  getTopicsRatedSuccess,
} from './actions';

export function* getTopicsRatedWorker({ payload }) {
  const { userId, params } = payload;
  try {
    const response = yield call(getRatedTopics, { userId, ...params });
    yield put(getTopicsRatedSuccess(response));
    return { response };
  } catch (error) {
    yield put(getTopicsRatedFail(error.message));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

export default function* topicsRatedWatcher() {
  yield takeLatest(GET_TOPICS_RATED, getTopicsRatedWorker);
}
