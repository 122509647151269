// @packages
import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

// @app
import { evaluationsMessages } from 'common/messages';
import { ellipsis } from 'utils/ellipsis';
import { formatDate } from 'utils/formatDate';
import formatScore from 'utils/formatScore';
import {
  PERFORMANCE_RETROSPECTIVE,
  EVALUATION,
  NH_EVALUATION,
  NH_SELF_EVALUATION,
  PERIOD_EVALUATION,
  SELF_EVALUATION,
} from 'betterme-components/Evaluations/constants';
import EvaluationListRow from 'betterme-components/EvaluationList/EvaluationListRow';

// @own
import './styles.scss';
import { SELF_RETROSPECTIVE } from 'betterme-components/Evaluations/constants'; 
const EvaluationListItem = ({
  className,
  customScore,
  evaluator,
  evaluee,
  goalScore,
  intl: { formatMessage },
  isByScore,
  onClick,
  score,
  showGlobalScore,
  showGoalsFinalScore,
  submissionTime,
  title,
  type,
}) => {
  const submissionDateParsed = formatDate(submissionTime);
  const evaluatorName = `${evaluator.firstName} ${evaluator.lastName || ''}`;
  const typesEvaluationConfigs = {
    [PERFORMANCE_RETROSPECTIVE]: {
      color: 'tag-continuous-evaluation',
      text: formatMessage(evaluationsMessages.PerformanceRetrospective),
    },
    [EVALUATION]: {
      color: 'tag-evaluation',
      text: formatMessage(evaluationsMessages.EvaluationsEvaluation),
    },
    [PERIOD_EVALUATION]: {
      color: 'tag-evaluation',
      text: formatMessage(evaluationsMessages.EvaluationsEvaluation),
    },
    [NH_EVALUATION]: {
      color: 'tag-nh-evaluation',
      text: formatMessage(evaluationsMessages.EvaluationsNHEvaluation),
    },
    [NH_SELF_EVALUATION]: {
      color: 'tag-self-evaluation',
      text: formatMessage(evaluationsMessages.EvaluationsNHSelfEvaluation),
    },
    [PERIOD_EVALUATION]: {
      color: 'tag-evaluation',
      text: formatMessage(evaluationsMessages.EvaluationsEvaluation),
    },
    [SELF_EVALUATION]: {
      color: 'tag-self-evaluation',
      text: formatMessage(evaluationsMessages.EvaluationsSelfEvaluation),
    },
    [SELF_RETROSPECTIVE]: {
      color: 'tag-self-evaluation',
      text: formatMessage(evaluationsMessages.SelfRetrospective),
    },
  };

  return (
    <EvaluationListRow
      className={cn('evaluation-list-item', className)}
      customScore={showGlobalScore
        ? ellipsis(formatScore(customScore), 28)
        : undefined
      }
      evaluator={evaluatorName}
      evaluee={evaluee}
      goalScore={showGoalsFinalScore
        ? ellipsis(formatScore(goalScore), 28)
        : undefined
      }
      isByScore={isByScore}
      onClick={onClick}
      score={showGoalsFinalScore
        ? ellipsis(formatScore(score), 28)
        : formatScore(score)
      }
      submissionDate={submissionDateParsed}
      title={title}
      type={typesEvaluationConfigs[type]}
    />
  );
};

EvaluationListItem.propTypes = {
  className: PropTypes.string,
  evaluator: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string,
  }).isRequired,
  dateTimeFrom: PropTypes.number.isRequired,
  dateTimeTo: PropTypes.number.isRequired,
  intl: PropTypes.object.isRequired,
  isByScore: PropTypes.bool,
  isGoalScoreContinous:PropTypes.bool.isRequired,
  score: PropTypes.shape({
    message: PropTypes.string,
    score: PropTypes.number,
  }).isRequired,
  showGlobalScore: PropTypes.bool,
  showGoalsFinalScore: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

export default injectIntl(EvaluationListItem);
