// @packages
import { get } from 'lodash';

const reducerDomain = 'leadershipRequestsReceived';

export const selectGetLeadershipRequestsPage = (state) =>
  get(state, [reducerDomain, 'leadershipRequestsPage']);

export const selectGetLeadershipRequestsReceived = (state) =>
  get(state, [reducerDomain, 'leadershipRequestsReceived']);

export const selectLoadingLeadershipRequestsReceived = (state) =>
  get(state, [reducerDomain, 'leadershipRequestsReceivedLoading']);
