// @packages
import React from 'react';
import PropTypes from 'prop-types';

// @app
import CompetencyInfoBox from 'common/CompetencyInfoBox';
import { feedbackMessages } from 'common/messages';

const DashboardStrengths = ({
  intl: { formatMessage },
  empty,
  loading,
  rating,
  strengths,
}) => {

  return (
    <CompetencyInfoBox
      color={rating.color}
      competencies={strengths}
      empty={empty}
      loading={loading}
      title={formatMessage(feedbackMessages.FeedbackTopStrengths)}
    />
  );
};

DashboardStrengths.propTypes = {
  empty: PropTypes.shape({
    description: PropTypes.string,
    info: PropTypes.string.isRequired,
    link: PropTypes.object.isRequired,
    onClick: PropTypes.func,
  }).isRequired,
  intl: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  noRequestFeedback: PropTypes.bool,
  rating: PropTypes.shape({
    color: PropTypes.string,
  }).isRequired,
  strengths: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
  })),
};

export default DashboardStrengths;
