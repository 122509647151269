import {
  GET_COMMUNITY_RATINGS,
  GET_COMMUNITY_RATINGS_FAIL,
  GET_COMMUNITY_RATINGS_SUCCESS,
  GET_COMMUNITY_SCORES,
  GET_COMMUNITY_SCORES_FAIL,
  GET_COMMUNITY_SCORES_SUCCESS,
  GET_COMMUNITY_TOPICS,
  GET_COMMUNITY_TOPICS_FAIL,
  GET_COMMUNITY_TOPICS_SUCCESS,
} from './actionTypes';

export function getCommunityRatings() {
  return {
    type: GET_COMMUNITY_RATINGS,
  }
}

export function getCommunityRatingsSuccess(ratings) {
  return {
    type: GET_COMMUNITY_RATINGS_SUCCESS,
    payload: { ratings },
  };
}

export function getCommunityRatingsFail(error) {
  return {
    type: GET_COMMUNITY_RATINGS_FAIL,
    payload: { error },
  };
}

export function getCommunityScores() {
  return {
    type: GET_COMMUNITY_SCORES,
  }
}

export function getCommunityScoresSuccess(response) {
  const { scores } = response;
  return {
    type: GET_COMMUNITY_SCORES_SUCCESS,
    payload: { scores },
  };
}

export function getCommunityScoresFail(error) {
  return {
    type: GET_COMMUNITY_SCORES_FAIL,
    payload: { error },
  };
}

export function getCommunityTopics() {
  return {
    type: GET_COMMUNITY_TOPICS,
  }
}

export function getCommunityTopicsSuccess(topics) {
  return {
    type: GET_COMMUNITY_TOPICS_SUCCESS,
    payload: { topics },
  };
}

export function getCommunityTopicsFail(error) {
  return {
    type: GET_COMMUNITY_TOPICS_FAIL,
    payload: { error },
  };
}
