// @packages
import React, { useCallback, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import cn from 'classnames';
import InputArea from 'smu-ui-components/InputArea';
import SelectV2 from 'smu-ui-components/SelectV2';

// @app
import { commonMessages } from 'common/messages';
import AgingDate from 'common/AgingDate';
import UploadFile from 'betterme-components/components/containers/UploadFile';

// @own
import './styles.scss';

const SuggestPromotionForm = ({
  attachFileValues: {
    customText: attachFileCustomText,
    files: attachFileFiles,
    loading: attachFileLoading,
    maxFiles: attachFileMaxFiles,
    onChange: attachFileOnChange,
    onDeleteAccept: attachFileOnDeleteAccept,
    supportedFormats: attachFileSupportedFormats,
  },
  className,
  intl: { formatMessage },
  justificationValues: {
    onChange: justificationOnChange,
    placeholder: justificationPlaceholder,
    showJustification: justificationShowJustification,
    title: justificationTitle,
    value: justificationToValue,
  },
  seniorityValues: {
    currentSeniority: seniorityCurrentSeniority,
    promoteOnChange: seniorityPromoteOnChange,
    promoteToOptions: seniorityPromoteToOptions,
    promoteToTitle: seniorityPromoteToiTitle,
    promoteToValue: seniorityPromoteToValue,
    title: seniorityTitle,
  },
  parentRequest,
}) => {
  const AttachFileComponent = useCallback(() => (
    <UploadFile
      customText={attachFileCustomText}
      disabled={attachFileFiles.length >= attachFileMaxFiles}
      files={attachFileFiles}
      loading={attachFileLoading}
      onChange={attachFileOnChange}
      onDeleteAccept={attachFileOnDeleteAccept}
      supportedFormats={attachFileSupportedFormats}
    />
  ), [
    attachFileFiles,
    attachFileLoading,
    attachFileOnChange,
    attachFileSupportedFormats,
    parentRequest,
  ]);

  const getDataLevel = (level, index, array) => {
    let levelName = level.name;

    if (array.length === 2 && index === 0) {
      levelName = `${levelName} (${formatMessage(commonMessages.Suggested)})`;
    }

    return { label: levelName, value: level.id }
  };

  useEffect(() => {
    if (!seniorityPromoteToValue && seniorityPromoteToOptions.length === 2) {
      seniorityPromoteOnChange(seniorityPromoteToOptions?.[0].id);
    }
  }, [seniorityPromoteToOptions]);

  useEffect(() => {
    if (parentRequest) {
      seniorityPromoteOnChange(parentRequest?.level?.id);
    }
  }, [parentRequest]);

  return (
    <div className={cn('suggest-promotion-form', className)}>
      <div className="suggest-promotion-form__info">
        <div className="suggest-promotion-form__info-current-seniority">
          <span className="suggest-promotion-form__info-title font-montserrat">
            {seniorityTitle}
          </span>
          {seniorityCurrentSeniority && (
            <div className="suggest-promotion-form__info-seniority">
              <span className="suggest-promotion-form__info-seniority-name">
                {seniorityCurrentSeniority.name}
              </span>
              {/* {seniorityCurrentSeniority.dateFromNow && (
                <span className="suggest-promotion-form__info-seniority-aging">
                  <AgingDate date={seniorityCurrentSeniority.dateFromNow}/>
                </span>
              )} */}
            </div>
          )}
        </div>
        <div className="suggest-promotion-form__info-promote-to">
          <span className="suggest-promotion-form__info-title font-montserrat">
            {seniorityPromoteToiTitle}
          </span>
          {parentRequest ? (
            <div className="suggest-promotion-form__info-original-seniority">
              {parentRequest?.level?.name}
            </div>
          ) : (
            <SelectV2
              className="suggest-promotion-form__info-selector"
              onChange={seniorityPromoteOnChange}
              options={seniorityPromoteToOptions.map(getDataLevel)}
              size="small"
              value={seniorityPromoteToValue}
            />
          )}
        </div>
      </div>

      {justificationShowJustification && (
        <div className="suggest-promotion-form__justification">
          <div className="suggest-promotion-form__justification-header">
            <h4 className="suggest-promotion-form__justification-title">
              {justificationTitle}
            </h4>
          </div>
          <InputArea
            className="suggest-promotion-form__justification-input"
            placeholder={justificationPlaceholder}
            maxLength={1200}
            onChange={justificationOnChange}
            value={justificationToValue}
          />
        </div>
      )}
      {justificationShowJustification && (
        <div className="suggest-promotion-form__attach-file">
          <AttachFileComponent />
        </div>
      )}

    </div>
  );
};

SuggestPromotionForm.propTypes = {
  className: PropTypes.string,
  seniorityValues: PropTypes.arrayOf(PropTypes.shape({
    currentSeniority: PropTypes.shape({
      name: PropTypes.string.isRequired,
      dateFromNow: PropTypes.string.isRequired,
    }),
    promoteOnChange: PropTypes.func.isRequired,
    promoteToOptions: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    })),
    promoteToTitle: PropTypes.string.isRequired,
    promoteToValue: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
  })),
  justificationValues: PropTypes.arrayOf(PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    showJustification: PropTypes.bool,
    title: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  })),
  attachFileValues: PropTypes.arrayOf(PropTypes.shape({
    files: PropTypes.array,
    loading: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
  })),
};

export default injectIntl(SuggestPromotionForm);