// @app
import { handleServerError } from 'betterme-components/api';
import { adminApiInstance, dataServiceApiInstance } from 'betterme-components/utils/requestHelper';

export function apiGetSuggestedLeaders({ memberId, leaderType, pageSize = 20 }) {
  return dataServiceApiInstance({
    url: `v1/suggestions/members/${memberId}/leaders?leaderType=${leaderType}`,
    params: {
      pageSize,
    }
  })
    .then(({ data }) => data.result)
    .catch(handleServerError);
};

export function patchLeadershipAdminLeaders(params) {
  const { communityId, memberId , data } = params;
  return adminApiInstance
    .patch(`/v2/community/${communityId}/members/${memberId}/leaders`, data)
    .then(({ data }) => data)
    .catch(handleServerError);
}
