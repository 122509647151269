// @packages
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import { closeFullscreenModal } from 'smu-app-components/FullScreenModal/actions';
import { openModal } from 'smu-app-components/RootModal/actions';
import { push } from 'react-router-redux';
import { takeLatest, call, put, select } from 'redux-saga/effects';

// @app
import { selectPromotionsFiles } from 'betterme-components/SendSuggestPromotion/selectors';
import { resetPromotionsFiles } from 'betterme-components/SendSuggestPromotion/actions';
import { LEADERSHIP_REMOVAL_MODAL } from 'betterme-components/constants/modalTypes';
import { selectMyTeamFilters } from 'betterme-components/MyTeam/selectors';
import { CLOSED_STATUS } from 'betterme-components/Goals/constants';
import { selectConfigurations, selectUser } from 'betterme-components/Authorization/selectors';
import {
  getDraftSelfEvaluation as getDraftSelfEvaluationAction,
  getUserTeamInfo as getUserTeamInfoAction,
} from 'betterme-components/Authorization/actions';
import { getTeamEvaluations as getTeamEvaluationsAction } from 'betterme-components/Team/actions';
import {
  createDraftEvaluation,
  createEvaluation,
  deleteEvaluation,
  getAllEvaluations,
  getAssignedGoals,
  getCustomScores,
  getEvaluations,
  getSelfEvaluations,
  getGoalFinalScore,
  getLastEvaluation,
  getSuggestedActions,
  submitDraftEvaluation,
  updateDraftEvaluation,
  uploadFile,
} from 'betterme-components/api';
import { getLocalStoredItem, removeLocalStoredItem } from 'utils/localStorage';
import { ACTION_TYPE_REQUIRED } from 'components/common/constants';
import { evaluationsTeam } from 'betterme-components/services/TeamEvaluations/actions';
import { getTeamEvaluationsLocation, getMyProfileLocation } from 'betterme-components/routes/urls';
import { resetEvaluationDetail } from 'betterme-components/EvaluationDetail/actions';
import { resetDraftSelfEvaluation } from 'betterme-components/Authorization/actions';

// @own
import {
  CREATE_DRAFT_EVALUATION,
  CREATE_EVALUATION,
  DELETE_DRAFT_EVALUATION,
  GET_CLOSED_ASSIGNED_GOALS,
  GET_CUSTOM_SCORES,
  GET_GOALS_FINAL_SCORE,
  GET_LAST_EVALUATION,
  GET_LAST_SELF_EVALUATION,
  GET_SUGGESTED_ACTIONS,
  GET_TEAM_ALL_EVALUATIONS,
  REQUEST_EVALUATIONS,
  REQUEST_TEAM_EVALUATIONS,
  SUBMIT_DRAFT_EVALUATION,
  UPDATE_DRAFT_EVALUATION,
  UPLOAD_EVALUATION_FILE,
  DELETE_EVALUATION_FILE,
} from './actionTypes';
import {
  createDraftEvaluationFail,
  createDraftEvaluationSuccess,
  createEvaluationFail,
  createEvaluationSuccess,
  deleteDraftEvaluationFail,
  deleteDraftEvaluationSuccess,
  deleteEvaluationFileSuccess,
  deleteEvaluationFileFail,
  getClosedAssignedGoalsFail,
  getClosedAssignedGoalsSuccess,
  getCustomScoresFail,
  getCustomScoresSuccess,
  getGoalsFinalScoreFail,
  getGoalsFinalScoreSuccess,
  getLastEvaluationFail,
  getLastEvaluationSuccess,
  getLastSelfEvaluationFail,
  getLastSelfEvaluationSuccess,
  getSuggestedActionsFail,
  getSuggestedActionsSuccess,
  getTeamAllEvaluationsFail,
  getTeamAllEvaluationsSuccess,
  requestEvaluations as requestEvaluationsAction,
  requestEvaluationsFail,
  requestEvaluationsSuccess,
  requestTeamEvaluationsFail,
  requestTeamEvaluationsSuccess,
  resetDataCreateEvaluation,
  submitDraftEvaluationFail,
  submitDraftEvaluationSuccess,
  updateDraftEvaluationFail,
  updateDraftEvaluationSuccess,
  uploadEvaluationFileFail,
  uploadEvaluationFileSuccess,
} from './actions';
import {
  PERFORMANCE_RETROSPECTIVE,
  PERIOD_EVALUATION,
  REMOVE_LEADER_SEND_EVALUATION,
  SELF_EVALUATION,
  SELF_RETROSPECTIVE,
} from './constants';

const parseRatedCompetencies = (competenciesByRate, ratedCompetency, _index, ratedCompetencies) => {
  const rateId = ratedCompetency.rateId;
  const rateIdExist = competenciesByRate.find((rateInfo) => rateInfo.ratingId === rateId);
  if (!rateIdExist) {
    const filteredCompetencies = ratedCompetencies.filter((competencyInfo) => competencyInfo.rateId === rateId);
    const topicIds = filteredCompetencies.map((competencyInfo) => competencyInfo.competency.id);
    const competencyByRate = { ratingId: rateId, topicIds };
    competenciesByRate = [...competenciesByRate, competencyByRate];
  }
  return competenciesByRate;
};

const parseEvaluationBody = (data, files = [], allowsRatedTopicsEvaluationsCreation) => {
  const {
    dateTimeFrom,
    dateTimeTo,
    evaluee,
    promotion,
    ratedTopics,
    ...otherData
  } = data;

  return {
    ...otherData,
    draftId: undefined,
    evalueeId: evaluee?.id,
    promotion: promotion ? {
      ...promotion,
      promotedId: evaluee?.id,
      files: files.map(file => file.id),
    } : null,
    dateTimeFrom: dateTimeFrom?.valueOf(),
    dateTimeTo: dateTimeTo?.valueOf(),
    ratedTopics: allowsRatedTopicsEvaluationsCreation
      ? ratedTopics.reduce(parseRatedCompetencies, [])
      : null,
  };
};

function* getCustomScoresWorker() {
  try {
    const response = yield call(getCustomScores);
    yield put(getCustomScoresSuccess(response));
  } catch ({ message }) {
    yield put(getCustomScoresFail(message));
  }
}

function* getClosedAssignedGoalsWorker({ payload: { userId, filters } }) {
  try {
    const data = {
      userId,
      ...filters,
      status: CLOSED_STATUS,
    };
    const response = yield call(getAssignedGoals, data);
    yield put(getClosedAssignedGoalsSuccess(response));
  } catch (error) {
    yield put(getClosedAssignedGoalsFail(error));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

function* getGoalsFinalScoreWorker({ payload }) {
  const { userId, from, to } = payload;
  try {
    const response = yield call(getGoalFinalScore, userId, from, to);
    yield put(getGoalsFinalScoreSuccess(response));
  } catch (error) {
    yield put(getGoalsFinalScoreFail(error.message));
  }
}

function* createDraftEvaluationWorker({ payload: { data, messages }, typeEvaluation }) {
  let { identification: userIdentification } = yield select(selectUser);
  const files = yield select(selectPromotionsFiles);
  const { success } = messages;
  const allowsRatedTopicsEvaluationsCreation =
    yield select(selectConfigurations('allowsRatedTopicsEvaluationsCreation'));
  const parseData = parseEvaluationBody(data, files, allowsRatedTopicsEvaluationsCreation);

  try {
    const response = yield call(createDraftEvaluation, parseData);
    yield put(createDraftEvaluationSuccess(response));
    yield put(resetDataCreateEvaluation());
    yield put(resetEvaluationDetail());
    if (typeEvaluation === SELF_EVALUATION) {
      yield put(closeFullscreenModal());
      yield put(getDraftSelfEvaluationAction({
        evaluator: userIdentification,
        evaluee: userIdentification,
      }));
    } else {
      yield put(evaluationsTeam(userIdentification));
      if (typeEvaluation === PERIOD_EVALUATION) {
        yield put(push(getTeamEvaluationsLocation()));
        yield put(resetPromotionsFiles());
      } else {
        yield put(closeFullscreenModal());
      }
    }
    yield put(addToastMessage({ message: success, timeout: 3000 }));
  } catch (error) {
    yield put(createDraftEvaluationFail(error.message));
    yield put(addToastMessage({ message: error.message, timeout: 3000 }));
  }
}

function* createEvaluationWorker({ payload: { data }, meta }) {
  const files = yield select(selectPromotionsFiles);
  const allowsRatedTopicsEvaluationsCreation =
    yield select(selectConfigurations('allowsRatedTopicsEvaluationsCreation'));
  const user = yield select(selectUser);
  const {
    identification: userIdentification,
    id: userId,
  } = user;
  const actionTypeRequired = getLocalStoredItem(ACTION_TYPE_REQUIRED);
  let {
    endDate,
    startDate,
  } = yield select(selectMyTeamFilters);
  let from = startDate ? startDate?.valueOf() : undefined;
  let to = endDate ? endDate?.valueOf() : undefined;
  const { isSelfEvaluation } = meta;
  const {
    dateTimeFrom,
    dateTimeTo,
    evaluee,
    promotion,
    ratedTopics,
    type,
  } = data;
  const parseData = {
    ...data,
    evalueeId: evaluee?.id,
    dateTimeFrom: dateTimeFrom?.valueOf(),
    dateTimeTo: dateTimeTo?.valueOf(),
    ratedTopics: allowsRatedTopicsEvaluationsCreation
      ? ratedTopics?.reduce(parseRatedCompetencies, [])
      : null,
    promotion: promotion ? {
      ...promotion,
      promotedId: evaluee?.id,
      files: files ? files?.map(file => file.id) : [],
    } : null,
  };
  const isActionTypeRequired = (evaluee?.id === actionTypeRequired?.collaboratorId)
    && (actionTypeRequired?.actionType === REMOVE_LEADER_SEND_EVALUATION);
  try {
    const response = yield call(createEvaluation, parseData);
    yield put(createEvaluationSuccess(response));
    yield put(resetDataCreateEvaluation());
    yield put(resetEvaluationDetail());
    if (isSelfEvaluation) {
      yield put(closeFullscreenModal());
      yield call(
        requestEvaluationsWorker,
        requestEvaluationsAction(evaluee.id),
      );
    } else {
      if (isActionTypeRequired) {
        yield put(openModal({
          modalType: LEADERSHIP_REMOVAL_MODAL,
          modalProps: {
            actionRequired: actionTypeRequired,
            collaborator: evaluee,
            evaluationType: type,
            user,
          },
        }));
        yield put(getUserTeamInfoAction(userIdentification));
      }
      yield put(evaluationsTeam(userIdentification));
      yield put(getTeamEvaluationsAction(userId, from, to));
      if (type === PERIOD_EVALUATION) {
        yield put(push(getTeamEvaluationsLocation()));
        yield put(resetPromotionsFiles());
      } else {
        yield put(closeFullscreenModal());
      }
    }
    yield put(addToastMessage({ type: 'error', message: 'message', timeout: 3000 }));
  } catch (error) {
    yield put(createEvaluationFail(error));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

function* deleteDraftEvaluationWorker({ payload, typeEvaluation }) {
  const { identification: userIdentification } = yield select(selectUser);
  const selfEvalutionData = {
    evaluator: userIdentification,
    evaluee: userIdentification,
  };

  try {
    const { id, messages: { success } } = payload;
    const response = yield call(deleteEvaluation, id);

    yield put(deleteDraftEvaluationSuccess(response));
    yield put(resetDataCreateEvaluation());
    yield put(resetEvaluationDetail());

    switch (typeEvaluation) {
      case SELF_EVALUATION:
        yield put(closeFullscreenModal());
        yield put(getDraftSelfEvaluationAction(selfEvalutionData));
        break;

      case SELF_RETROSPECTIVE:
        yield put(push(getMyProfileLocation()));
        break;

      case PERIOD_EVALUATION:
      case PERFORMANCE_RETROSPECTIVE:
        yield put(push(getTeamEvaluationsLocation()));
        yield put(evaluationsTeam(userIdentification));
        break;

      case 'EVALUATION':
        yield put(closeFullscreenModal());
        yield put(evaluationsTeam(userIdentification));
        break;

      default:
        break;
    }

    yield put(resetDraftSelfEvaluation());
    yield put(addToastMessage({ message: success, timeout: 3000 }));

  } catch (error) {
    yield put(deleteDraftEvaluationFail(error.message));
    yield put(addToastMessage({ message: error.message, timeout: 3000 }));
  }
}

function* requestEvaluationsWorker({ payload }) {
  try {
    const response = yield call(getEvaluations, payload);
    yield put(requestEvaluationsSuccess(response));
  } catch (error) {
    yield put(requestEvaluationsFail(error.message));
  }
}

function* requestAllEvaluationsWorker({ payload }) {
  try {
    const response = yield call(getAllEvaluations, payload);
    yield put(getTeamAllEvaluationsSuccess(response));
  } catch (error) {
    yield put(getTeamAllEvaluationsFail(error.message));
  }
}

function* requestTeamEvaluationsWorker({ payload }) {
  try {
    const response = yield call(getEvaluations, payload);
    yield put(requestTeamEvaluationsSuccess(response));
  } catch (error) {
    yield put(requestTeamEvaluationsFail(error.message));
  }
}

function* submitDraftEvaluationWorker({ payload, messages, typeEvaluation, isFromNotification }) {
  const user = yield select(selectUser);
  const {
    identification: userIdentification,
    id: userId,
  } = user;
  const files = yield select(selectPromotionsFiles);
  const isSelfEvaluation = typeEvaluation === SELF_EVALUATION;
  let {
    endDate,
    startDate,
  } = yield select(selectMyTeamFilters);
  let from = startDate ? startDate.valueOf() : undefined;
  let to = endDate ? endDate.valueOf() : undefined;
  const actionTypeRequired = getLocalStoredItem(ACTION_TYPE_REQUIRED);

  try {
    const {
      data,
      data: {
        draftId,
        evaluee,
        flagComment,
        flagSkills,
        type,
      },
    } = payload;
    const allowsRatedTopicsEvaluationsCreation =
      yield select(selectConfigurations('allowsRatedTopicsEvaluationsCreation'));
    const parsedData = parseEvaluationBody(data, files, allowsRatedTopicsEvaluationsCreation);
    const isActionTypeRequired = (evaluee?.id === actionTypeRequired?.collaboratorId)
      && (actionTypeRequired?.actionType === REMOVE_LEADER_SEND_EVALUATION);
    const coEvalData = { flagComment, flagSkills };
    yield call(updateDraftEvaluation, draftId, parsedData);
    const response = yield call(submitDraftEvaluation, draftId, coEvalData);
    yield put(submitDraftEvaluationSuccess(response));
    yield put(resetDataCreateEvaluation());
    if (isSelfEvaluation) {
      yield put(closeFullscreenModal());
      yield put(getDraftSelfEvaluationAction({
        evaluator: userIdentification,
        evaluee: userIdentification,
      }));
      yield call(requestEvaluationsWorker,
        requestEvaluationsAction(evaluee.id),
      );
    } else {
      if (isActionTypeRequired) {
        yield put(openModal({
          modalType: LEADERSHIP_REMOVAL_MODAL,
          modalProps: {
            actionRequired: actionTypeRequired,
            collaborator: evaluee,
            evaluationType: typeEvaluation,
            user,
          },
        }));
        yield put(getUserTeamInfoAction(userIdentification));
      } else {
        yield put(evaluationsTeam(userIdentification));
        yield put(getTeamEvaluationsAction(userId, from, to));
        if (type === PERIOD_EVALUATION) {
          yield put(push(getTeamEvaluationsLocation()));
          yield put(resetPromotionsFiles());
        } else {
          yield put(closeFullscreenModal());
        }
      }
    }
    yield put(addToastMessage({ message: 'message', timeout: 3000 }));
    removeLocalStoredItem(ACTION_TYPE_REQUIRED);
    resetEvaluationDetail();
  } catch (error) {
    yield put(submitDraftEvaluationFail(error.message));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
    if (isFromNotification) yield put(push(getTeamEvaluationsLocation()));
  }
}

function* updateDraftEvaluationWorker({ payload, typeEvaluation }) {
  let { identification: userIdentification } = yield select(selectUser);
  const files = yield select(selectPromotionsFiles);
  const {
    data,
    data: { draftId },
    messages,
  } = payload;
  const { success } = messages;
  const allowsRatedTopicsEvaluationsCreation =
    yield select(selectConfigurations('allowsRatedTopicsEvaluationsCreation'));
  const parseData = parseEvaluationBody(data, files, allowsRatedTopicsEvaluationsCreation);

  try {
    const response = yield call(updateDraftEvaluation, draftId, parseData);
    yield put(updateDraftEvaluationSuccess(response));
    yield put(resetDataCreateEvaluation());
    yield put(resetEvaluationDetail());
    if (typeEvaluation === SELF_EVALUATION) {
      yield put(closeFullscreenModal());
      yield put(getDraftSelfEvaluationAction({
        evaluator: userIdentification,
        evaluee: userIdentification,
      }));
    } else {
      if (typeEvaluation === PERIOD_EVALUATION) {
        yield put(resetPromotionsFiles());
        yield put(push(getTeamEvaluationsLocation()));
      } else {
        yield put(evaluationsTeam(userIdentification));
        yield put(closeFullscreenModal());
      }
    };
    yield put(addToastMessage({ message: success, timeout: 3000 }));
  } catch (error) {
    yield put(updateDraftEvaluationFail(error.message));
    yield put(addToastMessage({ message: error.message, timeout: 3000 }));
  }
}

function* getLastEvaluationWorker({ payload }) {
  const { evalueeId } = payload;
  const params = { evalueeId };

  try {
    const response = yield call(getLastEvaluation, params);
    yield put(getLastEvaluationSuccess(response));
  } catch (error) {
    yield put(getLastEvaluationFail(error.message));
  }
}

function* getLastSelfEvaluationWorker({ payload }) {
  try {
    const response = yield call(getSelfEvaluations, payload);
    yield put(getLastSelfEvaluationSuccess(response));
  } catch (error) {
    yield put(getLastSelfEvaluationFail(error.message));
  }
}

function* getSuggestedActionsWorker({ payload }) {
  const { isNewHire } = payload;
  const params = {
    activeNewHire: isNewHire || null,
    active: !isNewHire || null,
    pageSize: 20,
  };

  try {
    const response = yield call(getSuggestedActions, params);
    yield put(getSuggestedActionsSuccess(response));
  } catch (error) {
    yield put(getSuggestedActionsFail(error.message));
  }
}

function* uploadEvaluationFileWorker({ payload: { file } }) {
  try {
    const response = yield call(uploadFile, file);
    yield put(uploadEvaluationFileSuccess(response.result));
  } catch (error) {
    yield put(uploadEvaluationFileFail(error.message));
    yield put(addToastMessage({ message: error.message, timeout: 3000 }));
  }
}

function* deleteEvaluationFileWorker({ payload: { id } }) {
  try {
    yield put(deleteEvaluationFileSuccess(id));
  } catch (error) {
    yield put(deleteEvaluationFileFail(error.message));
    yield put(addToastMessage({ message: error.message, timeout: 3000 }));
  }
}

export default function* evaluationsWatcher() {
  yield takeLatest(CREATE_DRAFT_EVALUATION, createDraftEvaluationWorker);
  yield takeLatest(CREATE_EVALUATION, createEvaluationWorker);
  yield takeLatest(DELETE_DRAFT_EVALUATION, deleteDraftEvaluationWorker);
  yield takeLatest(GET_CLOSED_ASSIGNED_GOALS, getClosedAssignedGoalsWorker);
  yield takeLatest(GET_CUSTOM_SCORES, getCustomScoresWorker);
  yield takeLatest(GET_GOALS_FINAL_SCORE, getGoalsFinalScoreWorker);
  yield takeLatest(GET_LAST_EVALUATION, getLastEvaluationWorker);
  yield takeLatest(GET_LAST_SELF_EVALUATION, getLastSelfEvaluationWorker);
  yield takeLatest(GET_SUGGESTED_ACTIONS, getSuggestedActionsWorker);
  yield takeLatest(GET_TEAM_ALL_EVALUATIONS, requestAllEvaluationsWorker);
  yield takeLatest(REQUEST_EVALUATIONS, requestEvaluationsWorker);
  yield takeLatest(REQUEST_TEAM_EVALUATIONS, requestTeamEvaluationsWorker);
  yield takeLatest(SUBMIT_DRAFT_EVALUATION, submitDraftEvaluationWorker);
  yield takeLatest(UPDATE_DRAFT_EVALUATION, updateDraftEvaluationWorker);
  yield takeLatest(UPLOAD_EVALUATION_FILE, uploadEvaluationFileWorker);
  yield takeLatest(DELETE_EVALUATION_FILE, deleteEvaluationFileWorker);
}
