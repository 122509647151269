// @packages
import React, { isValidElement, useEffect } from 'react';
import cn from 'classnames';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import RootModal from 'smu-app-components/RootModal';

// @app
import ConfirmationModal, {
  centerModalClass,
  modalStyles,
} from 'betterme-components/ConfirmationModal';
import { ENGAGEMENT_INDICATOR_MODAL } from 'betterme-components/constants/modalTypes';
import actionsMessages from 'betterme-components/common/messages/actions';

// @own
import { trackEngagementIndicatorModal } from '../analytics';
import './styles.scss';

const EngagementIndicatorModal = ({
  acceptAction,
  close,
  description,
  intl: { formatMessage },
  noPadding,
  openModalAction,
  title,
  type,
}) => {
  
  useEffect(() => {
    trackEngagementIndicatorModal(openModalAction, type);
  }, []);

  return (
    <ConfirmationModal
      acceptMessage={formatMessage(actionsMessages.ActionsAccept)}
      className="engagement-indicator-modal"
      closeModal={close}
      onAccept={() => {
        trackEngagementIndicatorModal(acceptAction, type);
        close();
      }}
      title={title}
    >
      <div
        className={cn('engagement-indicator-modal__content', {
          'engagement-indicator-modal__content--no-padding': noPadding,
        })}
      >
        {isValidElement(description)
          ? React.cloneElement(description, { closeModal: close })
          : description}
      </div>
    </ConfirmationModal>
  );
};

EngagementIndicatorModal.defaultProps = {
  noPadding: false,
};

EngagementIndicatorModal.propTypes = {
  close: PropTypes.func.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  intl: PropTypes.object.isRequired,
  noPadding: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

export default RootModal(
  injectIntl(EngagementIndicatorModal),
  ENGAGEMENT_INDICATOR_MODAL,
  modalStyles,
  centerModalClass
);
