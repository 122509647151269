// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import { connect } from 'react-redux';
import Segment from 'smu-ui-components/Segment';
import GenericErrorState from 'smu-app-components/GenericErrorState';
import { useQuestions } from 'smu-services/betterme/api/questions/get';
import { useRates } from 'smu-services/betterme/api/rates/get';
// @app
import BehaviourQuestion from 'components/molecules/BehaviourQuestionNew'
import CommentBox from 'components/molecules/CommentBox';
import { selectIsFetching } from 'betterme-components/EvaluationDetail/selectors';
import { selectTierType } from 'betterme-components/services/GetTierType/selectors';
import { actionsMessages } from 'common/messages';
import DiscoveryLabel from 'components/molecules/DiscoveryLabel';

// @own
import messages from './messages';
import {
  BEHAVIORAL_ASSESSMENT,
  BEHAVIOUR_PERFORMANCE_RETROSPECTIVE_LINK,
  BEHAVIOUR_SELF_RETROSPECTIVE_LINK,
} from './constants';

const BehaviourSection = ({
  behavioursData,
  className,
  coEvaluatorName,
  draftLoading,
  intl: { formatMessage },
  isSelfRetrospective,
  ratedQuestions,
  ratesData,
  setBehaviours,
  tierType,
}) => {

  const {
    error: errorSurveyQuestions,
    isLoading: isLoadingSurveyQuestions,
    mutate: mutateSurveyQuestions,
  } = useQuestions(tierType);

  const {
    error: errorSurveyRates,
    isLoading: isLoadingSurveyRates,
    mutate: mutateSurveyRates,
  } = useRates(tierType);

  function getSurveyData() {
    mutateSurveyQuestions();
    mutateSurveyRates();
  };

  const galleryItems = [
    {
      title: formatMessage(messages.BehavioralAssessmentSlideOneTitle),
      text: formatMessage(messages.BehavioralAssessmentSlideOneText),
    },
    {
      title: formatMessage(messages.BehavioralAssessmentSlideTwoTitle),
      text: <FormattedHTMLMessage
        defaultMessage={messages.BehavioralAssessmentSlideTwoText.defaultMessage}
        id={messages.BehavioralAssessmentSlideTwoText.id}
        values={{
          link: BEHAVIOUR_PERFORMANCE_RETROSPECTIVE_LINK,
          style: "text-decoration:underline;color:white;",
        }}
      />
    },
  ];

  const galleryItemsSelfRetrospective = [
    {
      title: formatMessage(messages.BehavioralAssessmentSlideOneTitle),
      text: formatMessage(messages.BehavioralAssessmentSelfRetrospectiveSlideOneText),
    },
    {
      title: formatMessage(messages.BehavioralAssessmentSlideTwoTitle),
      text: <FormattedHTMLMessage
        defaultMessage={messages.BehavioralAssessmentSelfRetrospectiveSlideTwoText.defaultMessage}
        id={messages.BehavioralAssessmentSelfRetrospectiveSlideTwoText.id}
        values={{
          link: BEHAVIOUR_SELF_RETROSPECTIVE_LINK,
          style: "text-decoration:underline;color:white;",
        }}
      />
    },
  ];

  const descriptionText = isSelfRetrospective
    ? formatMessage(messages.BehaviourSectionSelfRetrospectiveDescription)
    : formatMessage(messages.BehaviourSectionDescription);

  const galleryItemsText = isSelfRetrospective
    ? galleryItemsSelfRetrospective
    : galleryItems;

  const actionButtonTexts = {
    slideOneText: formatMessage(actionsMessages.ActionsSkip),
    slideOneTextAlternative: formatMessage(actionsMessages.ActionsGotIt),
    slideTwoText: formatMessage(actionsMessages.ActionsContinue),
    slideTwoTextAlternative: formatMessage(actionsMessages.ActionsBack),
  };

  const behaviourTitle = (
    <span className="flex items-center">
      <p className="m-0 text-shark">
        {formatMessage(messages.BehaviourSectionTitle)}
      </p>
      <DiscoveryLabel
        actionButtonTexts={actionButtonTexts}
        discoveryType={BEHAVIORAL_ASSESSMENT}
        galleryItems={galleryItemsText}
        labelText={formatMessage(messages.PerformanceRetrospectiveNew)}
        showFeatureDiscovery
      />
    </span>
  );

  const isLoading = isLoadingSurveyQuestions || isLoadingSurveyRates || draftLoading;

  const handleRateBehaviours = ({ questionId, ratingId }) => {
    let isUpdated = false;
    const updatedBehaviours = behavioursData?.map((behaviour) => {
      if (behaviour?.questionId === questionId) {
        isUpdated = true;
        return {
          ...behaviour,
          ratingId,
        };
      }
      return behaviour;
    });

    if (!isUpdated) {
      updatedBehaviours.push({ questionId, ratingId });
    }

    setBehaviours(updatedBehaviours);
  };

  return (
    <Segment
      className="m-0"
      loading={isLoadingSurveyQuestions || isLoadingSurveyRates}
      loadingType="betterme"
    >
      <CommentBox
        className={className}
        title={behaviourTitle}
      >
        {(!errorSurveyQuestions && !errorSurveyRates) ? (
          <>
            <p className="text-sm m-0 p-6 font-roboto text-shark">
              {descriptionText}
            </p>

            <div className="flex items-center justify-between mx-6 font-montserrat font-semibold text-blue">
              <h4 className="m-0 text-sm">
                {formatMessage(messages.BehaviourSectionBehaviour)}
              </h4>
              <h4 className="m-0 mr-36 text-sm">
                {formatMessage(messages.BehaviourSectionResponse)}
              </h4>
            </div>

            {ratedQuestions?.map((question) => (
              <BehaviourQuestion
                behaviourOptions={ratesData}
                coEvaluatorName={coEvaluatorName}
                key={question?.id}
                onChange={handleRateBehaviours}
                placeholder={formatMessage(messages.BehaviourSectionSelect)}
                question={question}
                suggestedByMessage={messages.BehaviourSectionSuggestedBy}
              />
            )
            )}
          </>
        ) : (
          <>
            {isLoading ? (
              <div className="h-64"></div>
            ) : (
              <GenericErrorState
                action={{
                  onClick: getSurveyData,
                }}
                className="py-10"
                image="clock"
                limitTextWidth={false}
                size="large"
              />
            )
            }
          </>
        )}
      </CommentBox>
    </Segment>
  );
}

BehaviourSection.propTypes = {
  behavioursData: PropTypes.array.isRequired,
  className: PropTypes.string,
  coEvaluatorName: PropTypes.string,
  getTierType: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  isSelfRetrospective: PropTypes.bool.isRequired,
  ratedQuestions: PropTypes.array.isRequired,
  ratesData: PropTypes.array.isRequired,
  setBehaviours: PropTypes.func.isRequired,
  surveyQuestions: PropTypes.object.isRequired,
  tierType: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  draftLoading: selectIsFetching(state),
  tierType: selectTierType(state),
});

export default connect(mapStateToProps)(injectIntl(BehaviourSection));
