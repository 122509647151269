// @packages
import FullScreenModal from 'smu-app-components/FullScreenModal';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Segment from 'smu-ui-components/Segment';
import TextWrapper from 'smu-ui-components/TextWrapper';


// @app
import BreadcrumbHeader from 'components/organisms/BreadcrumbHeader';
import Card from 'common/Card';
import CardFooter from 'common/CardFooter';
import DescriptionTooltip from 'common/DescriptionTooltip';
import TextParserContainer from 'betterme-components/TextParser';
import { SUGGEST_ACTIONS_LIST_MODAL } from '../constants/modalTypes';
import { commonMessages } from 'common/messages';
import { formatDate } from 'utils/formatDate';
import { selectUserInfo } from '../Authorization/selectors';

// @own
import './styles.scss';
import messages from './messages';
import * as actions from './actions';
import {
  selectSuggestedActionsList,
  selectSuggestedActionsListLoading,
} from './selectors';

class SuggestedActionsList extends Component {
  constructor() {
    super();

    this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidMount() {
    const {
      from,
      getSuggestedActionsList,
      to,
      user,
    } = this.props;

    getSuggestedActionsList({
      creationTimeFrom: from,
      creationTimeTo: to,
      recipientId: user.id,
    });
  }

  handleCancel() {
    this.props.close();
  }

  render() {
    const {
      intl: { formatMessage },
      loadingList,
      selfUserInfo,
      suggestedActionsList,
      user,
    } = this.props;

    return (
      <div className="suggested-actions-list">
        <BreadcrumbHeader
          breadCrumbsProps={{
            active: 1,
            onClick: this.handleCancel,
            size: 'big',
            values: [
              `${user.firstName} ${user.lastName}`,
              formatMessage(messages.SuggestedActionsListBreadcrumbTitle),
            ],
          }}
          fixed
        />
        <Segment
          className="suggested-actions-list__content"
          loading={loadingList}
          loadingType="betterme"
        >
          {suggestedActionsList.length > 0 && (
            suggestedActionsList.map(suggestedAction => {
              const { sender } = suggestedAction;
              const customNameSender = selfUserInfo.id === sender.id
                ? formatMessage(commonMessages.Me)
                : null;

              return (
                <Card
                  className="suggested-actions-list__item"
                  key={suggestedAction.id}
                >
                  <Card.Header>
                    <span className="suggested-actions-list__item-date">
                      {formatDate(suggestedAction.creationTime)}
                    </span>
                  </Card.Header>

                  <Card.Content fullPadding>
                    <ul className="suggested-actions-list__item-actions">
                      {suggestedAction.suggestedActions.map((action, index) => (
                        <li
                          className="suggested-actions-list__item-action"
                          key={index}
                        >
                          <DescriptionTooltip
                            description={action.description || action.name}
                          >
                            <TextWrapper>
                              {action.name}
                            </TextWrapper>
                          </DescriptionTooltip>
                        </li>
                      ))}
                    </ul>

                    <TextWrapper
                      as="pre"
                      className="suggested-actions-list__item-description"
                    >
                      <TextParserContainer>{suggestedAction.comment}</TextParserContainer>  
                    </TextWrapper>
                  </Card.Content>

                  <CardFooter
                    className="suggested-actions-list__item-footer"
                    actionMessage={formatMessage(commonMessages.SendBy)}
                    user={suggestedAction.sender}
                    customName={customNameSender}
                  />
                </Card>
              );
            })
          )}
        </Segment>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    suggestedActionsList: selectSuggestedActionsList(state),
    loadingList: selectSuggestedActionsListLoading(state),
    selfUserInfo: selectUserInfo(state),
  };
}

SuggestedActionsList.propTypes = {
  close: PropTypes.func.isRequired,
  from: PropTypes.number,
  getSuggestedActionsList: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  loadingList: PropTypes.bool,
  suggestedActionsList: PropTypes.array.isRequired,
  to: PropTypes.number,
  user: PropTypes.object.isRequired,
};

export default FullScreenModal(
  connect(mapStateToProps, actions)(injectIntl(SuggestedActionsList)),
  SUGGEST_ACTIONS_LIST_MODAL,
);
