// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

// @app
import { goalsMessages } from 'common/messages';

// @own
import AssignedGoals from '../../AssignedGoals';
import messages from '../messages';

const ProfileAssignedGoals = ({
  intl: { formatMessage },
  location,
  user,
}) => {
  const navigateBackTo = location.pathname.replace('/assignedgoals', '');
  const messagesEmptyStates = {
    openGoals: {
      main: formatMessage(goalsMessages.GoalsNoInProgress),
      suggestion: formatMessage(goalsMessages.GoalsAssignHim),
    },
    closedGoals: {
      main: formatMessage(goalsMessages.GoalsNoClosed),
      suggestion: formatMessage(messages.FeedbackProfileAssignedGoalsClosedSuggestion),
    },
  };

  return (
    <AssignedGoals
      location={location}
      messagesEmptyStates={messagesEmptyStates}
      navigateBackLabel={`${user.firstName} ${user.lastName}`}
      navigateBackTo={navigateBackTo}
      user={user}
    />
  );
};

ProfileAssignedGoals.propTypes = {
  user: PropTypes.object,
};

export default injectIntl(ProfileAssignedGoals);
