// @packages
import { toString } from 'lodash';

const formatScore = (score) => {
  if (!score) {
    return '';
  }

  const { value, score: scoreValue, message } = score;
  const numericValue = toString(value) || toString(scoreValue);

  if (numericValue && message) {
    return `${numericValue} - ${message}`;
  }

};

export default formatScore;
