import { defineMessages } from 'react-intl';

export default defineMessages({
  CompetenciesSectionAll: {
    id: 'Betterme.CompetenciesSection.All',
    defaultMessage: 'All',
  },
  CompetenciesSectionLeaders: {
    id: 'Betterme.CompetenciesSection.Leaders',
    defaultMessage: 'Leaders',
  },
  CompetenciesSectionCompetencies: {
    id: 'Betterme.CompetenciesSection.Competencies',
    defaultMessage: 'Globant Talent Manifesto\'s competencies',
  },
  CompetenciesSectionFeedbackOverview: {
    id: 'Betterme.CompetenciesSection.FeedbackOverview',
    defaultMessage: 'Feedback Overview',
  },
  CompetenciesSectionViewFeedback: {
    id: 'Betterme.CompetenciesSection.ViewFeedback',
    defaultMessage: 'View all Feedback',
  },
});
