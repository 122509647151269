import {
  GET_PROMOTION_REQUESTS_HISTORY,
  GET_PROMOTION_REQUESTS_HISTORY_FAIL,
  GET_PROMOTION_REQUESTS_HISTORY_SUCCESS,
  RESET_PROMOTION_REQUESTS_HISTORY,
} from './actionTypes';

export function getPromotionRequestsHistory(userId, pageNumber) {
  return {
    type: GET_PROMOTION_REQUESTS_HISTORY,
    payload: { userId, pageNumber },
  }
}

export function getPromotionRequestsHistoryFail(error) {
  return {
    type: GET_PROMOTION_REQUESTS_HISTORY_FAIL,
    payload: { error },
  }
}

export function getPromotionRequestsHistorySuccess(response, page) {
  return {
    type: GET_PROMOTION_REQUESTS_HISTORY_SUCCESS,
    payload: { response, page },
  }
}

export function resetPromotionRequestsHistory() {
  return {
    type: RESET_PROMOTION_REQUESTS_HISTORY,
  }
}
