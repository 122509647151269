// @packages
import React from 'react';
import ModalOneOnOneRequest from 'smu-app-components/OneOnOne/Request/Modal';
import PropTypes from 'prop-types';
import UtilsProvider from 'smu-app-components/UtilsProvider';
import { connect } from 'react-redux';

// @app
import { ENABLED_ONE_ON_ONE } from 'betterme-components/services/CommunityConfigs/constants';
import { getCommunityConfigValueBool } from 'betterme-components/services/CommunityConfigs/selectors';

const ModalOneOnOneRequestContainer = ({ enabledOneOnOne, ...props}) => {
  const utils = {
    mainFeatureIsEnabled: enabledOneOnOne,
  };

  return (
    <UtilsProvider utils={utils}>
      <ModalOneOnOneRequest {...props} />
    </UtilsProvider>
  );
};

ModalOneOnOneRequestContainer.propTypes = {
  enabledOneOnOne: PropTypes.string,
};

const mapStateToProps = (state) => ({
  enabledOneOnOne: getCommunityConfigValueBool(state, ENABLED_ONE_ON_ONE),
});

export default connect(mapStateToProps)(ModalOneOnOneRequestContainer);
