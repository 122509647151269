// @packages
import React from 'react';
import Icon from 'smu-ui-components/IconV2';
import cn from 'classnames';
import Segment from 'smu-ui-components/Segment';
import Popup from 'smu-ui-components/Popup';

// @app
import CardIllustration from 'betterme-components/assets/Card-Illustration.svg';
import CommentBox from 'components/molecules/CommentBox';
import SelectSearchUser from 'components/containers/SelectSearchUser';
import UserSelectCard from 'components/molecules/UserSelectCard';

// @own
import './styles.scss';

const CoCreatorSection = ({
  className,
  hasCoEvaluator,
  infoText,
  isLoading,
  placeholder,
  searchSectionDisclaimer,
  searchSectionReason,
  searchSectionText,
  showClarification,
  title,
  user,
}) => {

  const labelNode = (
    <Icon
      className="mx-2 mb-1"
      icon="info"
      color="btm-blue-button"
      size="small"
    />
  );

  return (
    <CommentBox
      className={cn('co-creator-section', className, {
        'co-creator-section--small': hasCoEvaluator,
      })}
      title={
        <span className="co-creator-section__popup">
          {title}
          <Popup
            hideOnScroll
            inverted
            position="bottom center"
            trigger={(
              <span>
                {labelNode}
              </span>
            )}
          >
            {infoText}
          </Popup>
        </span>
      }
    >
      <Segment
        loading={isLoading}
        loadingType="betterme">
        <div className="co-creator-section__search">
          <p className="co-creator-section__search-description">
            {searchSectionText}
          </p>
          {hasCoEvaluator ? (
            <UserSelectCard
              user={user}
            />
          ) : (
            <>
              <SelectSearchUser
                className="co-creator-section__search-searching"
                placeholder={placeholder}
              />
              {showClarification && (
                <div className="co-creator-section__search-clarification">
                  <img
                    alt={searchSectionReason}
                    className="co-creator-section__search-clarification-image"
                    src={CardIllustration}
                  />
                  <p className="co-creator-section__search-clarification-text">
                    {searchSectionReason}
                  </p>
                </div>
              )}
            </>
          )}
          <div className="co-creator-section__search-disclaimer">
            <Icon
              className="co-creator-section__search-disclaimer-icon"
              icon={hasCoEvaluator ? 'info' : 'discket'}
              size={16}
            />
            <p className="co-creator-section__search-disclaimer-text">
              {searchSectionDisclaimer}
            </p>
          </div>
        </div>
      </Segment>
    </CommentBox>
  );
}

export default CoCreatorSection;
