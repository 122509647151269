import { takeLatest, call, put } from 'redux-saga/effects';
import {
  getRatedSkills
} from '../../api';
import { GET_SKILLS_RATED, GET_SEARCH_SKILLS_RATED } from './actionTypes';
import {
  getSkillsRatedFail,
  getSkillsRatedSuccess,
  getSearchSkillsRatedFail,
  getSearchSkillsRatedSuccess,
} from './actions';

export function* getSkillsRatedWorker({ payload }) {
  const { userId, params } = payload;
  try {
    const response = yield call(getRatedSkills, { userId, ...params });
    yield put(getSkillsRatedSuccess(response));
    return { response };
  } catch (error) {
    yield put(getSkillsRatedFail(error.message));
    return { error };
  }
}

export function* getSearchSkillsRatedWorker({ payload }) {
  const { userId, params } = payload;
  try {
    const response = yield call(getRatedSkills, { userId, ...params });
    yield put(getSearchSkillsRatedSuccess(response));
    return { response };
  } catch (error) {
    yield put(getSearchSkillsRatedFail(error.message));
    return { error };
  }
}

export default function* skillsRatedWatcher() {
  yield takeLatest(GET_SKILLS_RATED, getSkillsRatedWorker);
  yield takeLatest(GET_SEARCH_SKILLS_RATED, getSearchSkillsRatedWorker);
}
