// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const ContentCardFooter = ({ children, className }) => {

  return (
    <div className={cn('content-card__footer', className)}>
      {children}
    </div>
  );
};

ContentCardFooter.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default ContentCardFooter;
