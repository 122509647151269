// @own
import './styles.scss';

// @packages
import React, { useCallback, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import AvatarMedia from 'smu-ui-components/AvatarMedia';
import Select from 'smu-ui-components/SelectV2';
import cn from 'classnames';

// @app
import UserSelectCard from 'betterme-components/components/molecules/UserSelectCard';

const ValidatorSelector = ({
  className,
  description,
  emptyMessage,
  onChange,
  placeholder,
  suggestedValidators,
  title,
}) => {

  const [selectedLeader, selectLeader] = useState({});
  const [isCloseUserCard, closeUserCard] = useState(true);

  const options = suggestedValidators?.map((user) => ({
    label: `${user?.firstName} ${user?.lastName}`,
    user,
    value: user.id,
  }));

  const handleChange = (value) => {
    const user = options?.find((option) => option?.user?.id === value)?.user;
    selectLeader(user);
    onChange(user)
    if (isEmpty(selectedLeader)) closeUserCard(false);
  };

  const renderOptionLabel = useCallback(({ user }) => (
    <AvatarMedia
      firstName={user?.firstName}
      identification={user?.identification}
      job={user?.job}
      lastName={user?.lastName}
      profileImageCode={user?.profileImageCode}
      useLink={false}
    />
  ), []);

  const handleCloseCard = () => {
    closeUserCard(true);
    selectLeader({});
    onChange({});
  }

  return (
    <div className={cn("validator-selector", className)}>
      <div className="validator-selector__head">
        <h4 className="validator-selector__head-title">
          {title}
        </h4>
        <p className="validator-selector__head-description">
          {description}
        </p>
      </div>
      <div className="validator-selector__search">
        <Select
          filterOptionsEnabled={false}
          isSearchable
          noOptionsMessage={emptyMessage}
          onChange={handleChange}
          options={options}
          placeholder={placeholder}
          renderOptionLabel={renderOptionLabel}
          size="large"
        />
        {!isCloseUserCard && (
          <UserSelectCard
            handleCloseCard={handleCloseCard}
            user={selectedLeader}
            withActions
          />
        )}
      </div>
    </div >
  );
}

export default ValidatorSelector;
