// @packages
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';

// @own
import { getSurveyQuestions, getSurveyRates } from './api';
import { GET_SURVEY_QUESTIONS, GET_SURVEY_RATES } from './actionTypes';
import {
  getSurveyQuestionsSuccess,
  getSurveyQuestionsFail,
  getSurveyRatesFail,
  getSurveyRatesSuccess,
  setDraftBehaviors,
} from './actions';

function* getSurveyQuestionsWorker({ payload }) {
  const { tierType, draftData } = payload;
  try {
    const response = yield call(getSurveyQuestions, tierType);
    if (draftData) {
      yield put(setDraftBehaviors({ draftData, surveyQuestions: response }));
    }
    yield put(getSurveyQuestionsSuccess(response));
  } catch (error) {
    yield put(getSurveyQuestionsFail(error.message));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

function* getSurveyRatesWorker({ payload }) {
  try {
    const response = yield call(getSurveyRates, payload);
    yield put(getSurveyRatesSuccess(response));
  } catch (error) {
    yield put(getSurveyRatesFail(error.message));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

export default function* getSurveyQuestionsAndRatesWatcher() {
  yield takeLatest(GET_SURVEY_QUESTIONS, getSurveyQuestionsWorker);
  yield takeLatest(GET_SURVEY_RATES, getSurveyRatesWorker);
}
