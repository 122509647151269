// @packages
import React, { Component } from 'react';
import Collapsible from 'smu-ui-components/Collapsible';
import Dropdown from 'smu-ui-components/Dropdown';
import FullScreenModal from 'smu-app-components/FullScreenModal';
import Icon from 'smu-ui-components/Icon';
import InputArea from 'smu-ui-components/InputArea';
import PropTypes from 'prop-types';
import Segment from 'smu-ui-components/Segment';
import Select from 'smu-ui-components/SelectV2';
import Skills from 'smu-app-components/Skills';
import Switch from 'smu-ui-components/Switch';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { openModal, closeModal } from 'smu-app-components/RootModal/actions';
import { push } from 'react-router-redux';

// @app
import ButtonLink from 'components/molecules/ButtonLink';
import CompetenciesRateSelector from 'components/organisms/CompetenciesRateSelector';
import ContentCard from 'components/templates/ContentCardTemplate';
import DescriptionTooltip from 'common/DescriptionTooltip';
import TalentMatrix from 'betterme-components/TalentMatrix';
import Title from 'components/atoms/Title';
import UserDetails from 'betterme-components/components/containers/UserDetails';
import {
  EXCEEDED_SIZE,
  MAX_FILE_SIZE,
  MAX_FILES,
  NO_SIZE,
  SUPPORTED_FORMATS,
  WRONG_FORMAT,
} from 'betterme-components/constants/fileValidations';
import {
  actionsMessages,
  commonMessages,
  evaluationsMessages,
  feedbackMessages,
  promotionsMessages,
  suggestedActionsMessages,
} from 'common/messages';
import formatScore from 'utils/formatScore';
import getMessageType from 'utils/getMessageType';
import { formatPeriodDates } from 'utils/formatPeriodDates';
import { runValidations } from 'utils/filesAttach';
import {
  getEvaluationsLocation,
  getMyFeedbackReceivedLocation,
  getProfileFeedbackReceivedLocation,
  getTeamEvaluationsLocation,
} from 'betterme-components/routes/urls';
import { getProfile, resetProfile } from 'betterme-components/Profile/actions';
import {
  selectLoadingGetProfile,
  selectProfile,
  selectProfileAccess,
} from 'betterme-components/Profile/selectors';
import Assessment360ResultsSummaryWidget from 'betterme-components/Assessment360/ResultsSummaryWidget';
import FeedbackOverview from 'components/containers/WidgetFeedbackOverview';
import GoalOverviewWidget from 'components/containers/WidgetGoalOverview';
import FeedbackForLeaders from 'betterme-components/components/containers/WidgetFeedbackForLeaders';
import SelfEvaluationListWidget from 'components/containers/WidgetSelfEvaluationList';
import {
  getFeedbackForLeaders,
  resetFeedbackForLeaders,
} from 'betterme-components/services/FeedbackForLeaders/actions';
import {
  deleteFile,
  getPromotionsLevel,
  uploadPromotionsFile,
  resetSuggestPromotion,
} from 'betterme-components/SendSuggestPromotion/actions';
import {
  selectFeedbackForLeadersSummaryResult,
  selectFeedbackForLeadersEvaluations,
} from 'betterme-components/services/FeedbackForLeaders/selectors';
import {
  selectLoadingPromotionsFiles,
  selectPromotionsFiles,
  selectPromotionsLevel,
} from 'betterme-components/SendSuggestPromotion/selectors';
import {
  EVALUATION_CREATE_FORM,
  EVALUATION_DRAFT_DELETE_MODAL,
  EVALUATION_DRAFT_SUBMIT_MODAL,
  SEND_EVALUATION_CONFIRMATION_MODAL,
  VALIDATION_LOWEST_RATING,
} from 'betterme-components/constants/modalTypes';
import { getAssignedGoals } from 'betterme-components/Goals/actions';
import { requestRatedTopics, resetRatedTopics } from 'betterme-components/Dashboard/actions';
import { selectAssignedGoals, selectLoadingMyGoals } from 'betterme-components/Goals/selectors';
import { CLOSED_STATUS } from 'betterme-components/Goals/constants';
import { selectLoading, selectRatedTopics } from 'betterme-components/Dashboard/selectors';
import {
  selectConfigurations,
  selectCustomScoreDefinition,
  selectEvaluationPeriod,
  selectPromotionConfig,
  selectScoresDescription,
  selectShowTeamEvaluationsGoalFinalScore,
} from 'betterme-components/Authorization/selectors';
import ActionButtons from 'components/molecules/ActionButtons';
import BreadcrumbHeader from 'components/organisms/BreadcrumbHeader';
import CommentBox from 'components/molecules/CommentBox';
import SendEvaluationConfirmationModal from 'components/containers/SendEvaluationConfirmationModal';
import StarmeUpDNAWidget from 'betterme-components/components/containers/StarmeUpDNAWidget';
import SuggestedAction from 'components/atoms/SuggestedAction';
import { ACTION_TYPE_REQUIRED } from 'components/common/constants';
import { ENABLED_ASSESSMENT360, ENABLED_OLD_FFL } from 'betterme-components/services/CommunityConfigs/constants';
import { formatBoldText } from 'utils/formatBoldText';
import { getCommunityConfigValueBool } from 'betterme-components/services/CommunityConfigs/selectors';
import { isEnabledTalentMatrixByLeader } from 'betterme-components/TalentMatrix/selectors';
import { isValidTalentMatrixDimensions } from 'betterme-components/TalentMatrix/helpers';
import { removeLocalStoredItem } from 'utils/localStorage';
import { selectCreateSaveEvaluationLoading } from 'betterme-components/services/EvaluationsCreateSave/selectors';
import { selectLowestRating } from 'betterme-components/services/Layout/selectors';
import { selectUpdateSubmitEvaluationLoading } from 'betterme-components/services/EvaluationsUpdateSubmit/selectors';

// @own
import './styles.scss';
import {
  trackEvaluationsActionsSuggested,
  trackEvaluationsAddImprovements,
  trackEvaluationsAddSkill,
  trackEvaluationsAttachFile,
  trackEvaluationsCompetenciesFilter,
  trackEvaluationsCreationClose,
  trackEvaluationsCreationSend,
  trackEvaluationsDeleteDraft,
  trackEvaluationsFeedbackOverviewDataToggle,
  trackEvaluationsFormViewMore,
  trackEvaluationsSelectPromoteTo,
  trackEvaluationsSelectedScore,
  trackEvaluationsSelectedTalent,
  trackEvaluationsSendAnyway,
  trackEvaluationsSubmitWithImprovements,
  trackEvaluationsSubmitWithoutImprovements,
  trackEvaluationsViewAllFeedbacks,
  trackExpandFeedbackClick,
  trackExpandGoalsClick,
} from '../analytics';
import messages from '../messages';
import {
  deleteEvaluationFile,
  evaluationAddDeletedSkill,
  evaluationAddSkill,
  evaluationChangeSkill,
  evaluationCleanDeletedSkills,
  evaluationCleanSkills,
  evaluationDeletedSkill,
  getCustomScores,
  getGoalsFinalScore,
  resetDataCreateEvaluation,
  resetLastSelfEvaluation,
  uploadEvaluationFile,
} from '../actions';
import {
  selectActiveCompetenciesWithRatesCount,
  selectCustomScores,
  selectEvaluationsDeletedSkills,
  selectEvaluationsSkills,
  selectGoalsFinalScore,
  selectLoadingCreate,
  selectLoadingCreateDraft,
  selectLoadingEvaluationFiles,
  selectLoadingLastSelfEvaluation,
  selectLoadingUpdateDraft,
  selectNineBoxList,
  selectSuggestedActions,
} from '../selectors';
import {
  ALL,
  LEADERS,
  CONTINUOUS_EVALUATION,
  EVALUATION,
  EVALUATIONS_FILE,
  NH_EVALUATION,
  NH_SELF_EVALUATION,
  PROMOTIONS_FILE,
  SELF_EVALUATION,
} from '../constants';
import EvaluationScoreSelector from '../EvaluationScoreSelector';
import {
  getRatedTopicsByCompetency,
  normalizeChangeSkill,
  normalizeChangeSkills,
  normalizeValueSkills,
} from './helpers';

class EvaluationCreateForm extends Component {
  constructor() {
    super();
    this.state = {
      as360ResultsSummaryOpen: false,
      feedbackForLeadersOpen: false,
      feedbackOpen: true,
      feedbackOverviewEnabled: true,
      feedbackOverviewFilter: ALL,
      goalsFilter: CLOSED_STATUS,
      goalsOpen: false,
      potentialBoxOpen: true,
      promoteValue: null,
      suggestedScoreOpen: true,
      userSelfEvaluationOpen: false,
      valuesOpen: true,
    };

    this.getDraftSkills = this.getDraftSkills.bind(this);
    this.getFeedbackForLeaders = this.getFeedbackForLeaders.bind(this);
    this.handleAddSkill = this.handleAddSkill.bind(this);
    this.handleCalificationChange = this.handleCalificationChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangePromotion = this.handleChangePromotion.bind(this);
    this.handleChangeSkill = this.handleChangeSkill.bind(this);
    this.handleAddSkill = this.handleAddSkill.bind(this);
    this.handleRemoveSkill = this.handleRemoveSkill.bind(this);
    this.handleAddSuggestionsSkill = this.handleAddSuggestionsSkill.bind(this);
    this.handleCreateEvalOrSaveDraft = this.handleCreateEvalOrSaveDraft.bind(this);
    this.handleCreateEvaluation = this.handleCreateEvaluation.bind(this);
    this.handleDeleteFile = this.handleDeleteFile.bind(this);
    this.handleDraftDelete = this.handleDraftDelete.bind(this);
    this.handleFeedbackOverviewFilter = this.handleFeedbackOverviewFilter.bind(this);
    this.handleFileAttach = this.handleFileAttach.bind(this);
    this.handleGoalFilterChange = this.handleGoalFilterChange.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleSaveOrUpdateDraft = this.handleSaveOrUpdateDraft.bind(this);
    this.handleSendEvaluationConfirmationModal = this.handleSendEvaluationConfirmationModal.bind(this);
    this.handleShowDetailsClick = this.handleShowDetailsClick.bind(this);
    this.handleToggleCard = this.handleToggleCard.bind(this);
    this.handleToggleFeedbackOverviewSwitch = this.handleToggleFeedbackOverviewSwitch.bind(this);
  }

  componentDidMount() {
    const {
      allowsGoals,
      evaluationAddSkill,
      getAssignedGoals,
      getCustomScores,
      getGoalsFinalScore,
      getProfile,
      selfEvaluation,
      showTeamEvaluationsGoalFinalScore,
      values: {
        dateTimeFrom,
        dateTimeTo,
        evaluee,
      },
    } = this.props;
    const draftSkills = this.getDraftSkills();

    if (selfEvaluation) {
      this.getRatedTopics();
    } else {
      getProfile(evaluee?.identification);

    }

    if (allowsGoals) {
      const goalsFilters = {
        status: CLOSED_STATUS,
        dueDateFrom: dateTimeFrom?.valueOf(),
        dueDateTo: dateTimeTo?.valueOf(),
      };

      getAssignedGoals(
        evaluee?.id,
        goalsFilters,
      );

      if (showTeamEvaluationsGoalFinalScore) {
        getGoalsFinalScore(
          evaluee?.id,
          goalsFilters?.dueDateFrom,
          goalsFilters?.dueDateTo,
        );
      }
    }

    if (this.isCustomScoreActivated()) {
      getCustomScores();
    }

    evaluationAddSkill(draftSkills);
  }

  componentDidUpdate(prevProps) {
    const {
      goalsFinalScore: { value: prevGoalsFinalScore },
      nineBoxList: prevNineboxList,
      profile: prevProfile,
      profileAccess: prevProfileAccess,
      values: {
        nineBoxComment: prevNineBoxComment,
        nineBoxScore: prevNineBoxScore,
        draftId: prevDraftId,
      },
    } = prevProps;
    const {
      allowsFeedbackForLeadersResult,
      allowsPromotions,
      goalsFinalScore: { value: currentGoalsFinalScore },
      nineBoxList: currentNineboxList,
      onCalificationChange,
      onChange,
      onChangeText,
      profile: currentProfile,
      profileAccess: currentProfileAccess,
    } = this.props;

    if (
      !prevProfileAccess && currentProfileAccess
      && currentProfileAccess.feedback
    ) {
      this.getRatedTopics();
    }

    if (
      (prevNineBoxComment || prevNineBoxScore)
      && (prevNineboxList !== currentNineboxList)
    ) {
      onChange('nineBoxScore', '');
      onChangeText('nineBoxComment', { target: { value: '' } });
    }
    if (
      ((!prevDraftId) && this.isGoalFinalScoreForced())
      && !prevGoalsFinalScore
      && currentGoalsFinalScore
    ) {
      onCalificationChange('score', currentGoalsFinalScore);
    }

    if (prevProfile?.id !== currentProfile?.id) {
      if (allowsFeedbackForLeadersResult) {
        this.getFeedbackForLeaders();
      }
    }

    if (allowsPromotions && prevProfile?.id !== currentProfile?.id) {
      this.getPromotionsLevel();
    }
  }

  componentWillUnmount() {
    const {
      evaluationCleanSkills,
      resetDataCreateEvaluation,
      resetEvaluationDetail,
      resetFeedbackForLeaders,
      resetLastSelfEvaluation,
      resetProfile,
      resetRatedTopics,
      resetSuggestPromotion,
    } = this.props;

    evaluationCleanSkills();
    resetDataCreateEvaluation();
    resetEvaluationDetail();
    resetFeedbackForLeaders();
    resetLastSelfEvaluation();
    resetProfile();
    resetRatedTopics();
    resetSuggestPromotion();
  }

  getDraftSkills() {
    const { values: { ratedTopics } } = this.props;
    return ratedTopics
      ?.filter(topic => topic.competency.skill)
      ?.map(topic => topic.competency);
  }

  getCustomScoreOptions() {
    const { customScores } = this.props;
    return customScores.map(customScore => ({
      label: customScore.name,
      value: customScore.max,
    }));
  }

  getLabelList(name, description) {
    return (
      <DescriptionTooltip description={description || name}>
        {name}
      </DescriptionTooltip>
    );
  }

  getNineBoxList() {
    const { nineBoxList } = this.props;
    return nineBoxList.map(({
      description,
      id,
      name,
    }) => ({
      dataUser: name,
      label: this.getLabelList(name, description),
      value: id,
    }));
  }

  getRatedTopics(feedbackSourceFilter) {
    const {
      requestRatedTopics,
      values: { evaluee, dateTimeFrom, dateTimeTo }
    } = this.props;

    requestRatedTopics(
      evaluee.id,
      dateTimeFrom.valueOf(),
      dateTimeTo.valueOf(),
      feedbackSourceFilter,
    );
  }

  getFeedbackForLeaders() {
    const {
      allowsFeedbackForLeadersResult,
      getFeedbackForLeaders,
      profile,
    } = this.props;

    if (allowsFeedbackForLeadersResult) {
      getFeedbackForLeaders(profile.id);
    };
  }

  getPromotionsLevel() {
    const {
      getPromotionsLevel,
      profile,
    } = this.props;

    getPromotionsLevel(profile.id);
  }

  //TODO: check message flow  
  getMessagesSuccess(type) {
    const {
      intl: { formatMessage },
      values: { evaluee },
    } = this.props;

    switch (type) {
      case SELF_EVALUATION:
        return ({
          message: formatMessage(messages.FeedbackEvaluationsEvaluationSuccessMessage),
          footerMessage: formatMessage(
            messages.FeedbackEvaluationsEvaluationSuccessFooterMessage
          ),
          actionMessage: formatMessage(actionsMessages.ActionsContinue),
        });

      case EVALUATION:
        return ({
          message: formatMessage(messages.FeedbackEvaluationsEvaluationSuccessMessage),
          footerMessage: formatBoldText(formatMessage(
            messages.FeedbackEvaluationsEvaluationSuccessFooterMessageTeam,
            { username: evaluee.firstName }
          )),
          actionMessage: formatMessage(actionsMessages.ActionsContinue),
        });

      case CONTINUOUS_EVALUATION:
        return ({
          message: formatMessage(messages.FeedbackEvaluationsEvaluationSuccessMessage),
          footerMessage: formatBoldText(formatMessage(
            messages.FeedbackEvaluationsEvaluationSuccessFooterMessageTeamContinuous,
            { username: evaluee.firstName }
          )),
          actionMessage: formatMessage(actionsMessages.ActionsContinue),
        });

      default:
        return ({});
    }

  }

  handleCreateEvaluation(enableSuggestPromotion) {
    const {
      goToEvaluations,
      goToEvaluationsTeam,
      goalsFinalScore,
      onCreateEvaluation,
      selfEvaluation,
      values: {
        promotion,
      },
    } = this.props;

    const isValidatePromotion = enableSuggestPromotion && !!promotion?.levelId;
    const evaluationTypeMessages = selfEvaluation
      ? this.getMessagesSuccess(SELF_EVALUATION)
      : this.getMessagesSuccess(EVALUATION);
    const successMessages = evaluationTypeMessages;

    if (!selfEvaluation && this.isGoalFinalScoreForced()) {
      onCreateEvaluation(goalsFinalScore.value, isValidatePromotion, successMessages);
    } else {
      onCreateEvaluation(null, isValidatePromotion, successMessages)
    }

    trackEvaluationsCreationSend();
    if (selfEvaluation) {
      goToEvaluations();
    } else {
      goToEvaluationsTeam();
    }
  }

  handleGoalFilterChange(value) {
    const {
      getAssignedGoals,
      values: { evaluee, dateTimeFrom, dateTimeTo },
    } = this.props;
    const goalsFilters = {
      status: value,
      dueDateFrom: dateTimeFrom.valueOf(),
      dueDateTo: dateTimeTo.valueOf(),
    };

    getAssignedGoals(evaluee.id, goalsFilters);
  }

  handleModalClose() {
    const {
      close,
      goToEvaluations,
      goToEvaluationsTeam,
      selfEvaluation,
    } = this.props;
    trackEvaluationsCreationClose();
    close();
    removeLocalStoredItem(ACTION_TYPE_REQUIRED);
    if (selfEvaluation) {
      goToEvaluations();
    } else {
      goToEvaluationsTeam();
    }
  }

  handleShowDetailsClick(isOpenUserDetails) {
    const { values: { type } } = this.props;

    if (!isOpenUserDetails) {
      trackEvaluationsFormViewMore(type);
    }
  }

  handleSaveOrUpdateDraft(enableSuggestPromotion) {
    const {
      goToEvaluations,
      goToEvaluationsTeam,
      goalsFinalScore,
      onCreateDraft,
      onUpdateDraft,
      selfEvaluation,
      values: { promotion },
    } = this.props;

    const isValidatePromotion = enableSuggestPromotion && !!promotion?.levelId;
    const forcedScore = (!selfEvaluation && this.isGoalFinalScoreForced())
      ? goalsFinalScore.value
      : undefined;

    if (this.isEditingDraft()) {
      onUpdateDraft(forcedScore, isValidatePromotion);
    } else {
      onCreateDraft(forcedScore, isValidatePromotion);
    }
    removeLocalStoredItem(ACTION_TYPE_REQUIRED);
    if (selfEvaluation) {
      goToEvaluations();
    } else {
      goToEvaluationsTeam();
    }
  }

  handleValidationLowestRatingModal(onAccept) {
    const {
      intl: { formatMessage },
      lowestRating,
      openModal,
      selfEvaluation,
    } = this.props;

    openModal({
      modalType: VALIDATION_LOWEST_RATING,
      modalProps: {
        onCancel: trackEvaluationsAddImprovements,
        onAccept: () => {
          trackEvaluationsSendAnyway();
          onAccept();
        },
        ratingBackgroundColor: lowestRating?.color,
        ratingImageCode: lowestRating?.imageCode,
        ratingName: lowestRating?.name,
        texts: {
          accept: formatMessage(actionsMessages.ActionsSendAnyway),
          cancel: formatMessage(actionsMessages.ActionsAddImprovements),
          description: selfEvaluation
            ? formatMessage(messages.FeedbackLowestRatingDescriptionSelfEvaluation)
            : formatMessage(messages.FeedbackLowestRatingDescriptionEvaluation),
        },
      }
    });
  }

  handleDraftDelete() {
    const {
      openModal,
      values: { draftId, type },
    } = this.props;
    trackEvaluationsDeleteDraft(type);
    openModal({
      modalType: EVALUATION_DRAFT_DELETE_MODAL,
      modalProps: { draftId }
    });
  }

  handleEvaluationDraftSubmitModal(goalsFinalScore, isValidatePromotion) {
    const {
      openModal,
      selfEvaluation,
      values: { type }
    } = this.props;
    const evaluationTypeMessages = selfEvaluation
      ? this.getMessagesSuccess(SELF_EVALUATION)
      : this.getMessagesSuccess(EVALUATION);
    const successMessages = type === CONTINUOUS_EVALUATION
      ? this.getMessagesSuccess(CONTINUOUS_EVALUATION)
      : evaluationTypeMessages;

    openModal({
      modalType: EVALUATION_DRAFT_SUBMIT_MODAL,
      modalProps: { goalsFinalScore, isValidatePromotion, successMessages }
    });
  }

  handleSendEvaluationConfirmationModal(enableSuggestPromotion) {
    const { openModal } = this.props;
    openModal({
      modalType: SEND_EVALUATION_CONFIRMATION_MODAL,
    });
  }

  handleCreateEvalOrSaveDraft(enableSuggestPromotion) {
    const {
      allowsEvaluationsCreation,
      allowsGlbFeatures,
      allowsRatedTopicsEvaluationsCreation,
      allowsSelfEvaluations,
      goalsFinalScore,
      lowestRating,
      selfEvaluation,
      values: {
        promotion,
        ratedTopics,
      },
    } = this.props;

    const isEvaluationCreation = !selfEvaluation && allowsEvaluationsCreation;
    const isSelfEvaluationCreation = selfEvaluation && allowsSelfEvaluations;
    const hasLowestRating = ratedTopics?.some(item => item?.rateId === lowestRating?.id);
    const isValidatePromotion = enableSuggestPromotion && !!promotion?.levelId;
    const forcedScore = (!isSelfEvaluationCreation && this.isGoalFinalScoreForced())
      ? goalsFinalScore.value
      : undefined;

    if (this.isEditingDraft()) {
      if (isEvaluationCreation || isSelfEvaluationCreation) {
        if (allowsGlbFeatures && allowsRatedTopicsEvaluationsCreation && !hasLowestRating) {
          this.handleValidationLowestRatingModal(
            () => this.handleEvaluationDraftSubmitModal(forcedScore, isValidatePromotion)
          );
        } else {
          this.handleEvaluationDraftSubmitModal(forcedScore, isValidatePromotion);
        }
      } else {
        this.handleEvaluationDraftSubmitModal(forcedScore, isValidatePromotion);
      }
    } else {
      if (allowsGlbFeatures && allowsRatedTopicsEvaluationsCreation && !hasLowestRating) {
        trackEvaluationsSubmitWithoutImprovements();
        this.handleValidationLowestRatingModal(() => this.handleCreateEvaluation(enableSuggestPromotion));
      } else {
        trackEvaluationsSubmitWithImprovements();
        this.handleSendEvaluationConfirmationModal(enableSuggestPromotion);
      }
    }
  }

  handleSuggestedActionSelect(suggestedAction) {
    const {
      onChange,
      values: {
        suggestedActionsIds,
        type,
      },
    } = this.props;
    const { id, name } = suggestedAction;
    let newSuggestedActionsIds;

    if (suggestedActionsIds.includes(id)) {
      newSuggestedActionsIds = suggestedActionsIds.filter(suggestedActionsIds =>
        suggestedActionsIds !== id);
    } else {
      trackEvaluationsActionsSuggested(name, type);
      newSuggestedActionsIds = [...suggestedActionsIds, id];
    }

    onChange('suggestedActionsIds', newSuggestedActionsIds);
  }

  handleToggleCard(cardState) {
    let trackToggle = null;
    const updater = (prevState) => ({ [cardState]: !prevState[cardState] });

    switch (cardState) {
      case 'feedbackOpen':
        trackToggle = trackExpandFeedbackClick;
        break;
      case 'goalsOpen':
        trackToggle = trackExpandGoalsClick;
        break;
      default:
        break;
    }

    this.setState(updater, trackToggle);
  }

  handleToggleFeedbackOverviewSwitch() {
    const { values: { type } } = this.props;
    this.setState(prevState => {
      const newValue = !prevState.feedbackOverviewEnabled;
      trackEvaluationsFeedbackOverviewDataToggle(newValue, type);

      return ({ feedbackOverviewEnabled: newValue })
    });
  }


  allowsEvaluationsNineBox() {
    const { allowsEvaluationsNineBox, selfEvaluation } = this.props;

    return allowsEvaluationsNineBox && !selfEvaluation;
  }

  allowsSuggestedActions() {
    const { allowsEvaluationsSuggestedActions, selfEvaluation } = this.props;

    return allowsEvaluationsSuggestedActions && !selfEvaluation;
  }

  handleFileAttach(event, type) {
    const { target } = event;
    const { files: listFiles } = target;
    const {
      addToastMessage,
      intl: { formatMessage },
      uploadEvaluationFile,
      uploadPromotionsFile,
      values: { type: evaluationType },
    } = this.props;
    const errorMessages = {
      [EXCEEDED_SIZE]: formatMessage(promotionsMessages.PromotionsErrorExceededSize, {
        size: MAX_FILE_SIZE
      }),
      [NO_SIZE]: formatMessage(promotionsMessages.PromotionsErrorNoSize),
      [WRONG_FORMAT]: formatMessage(promotionsMessages.PromotionsErrorInvalidFormat, {
        formats: SUPPORTED_FORMATS,
      }),
    }

    if (listFiles.length) {
      const file = listFiles[0];
      const error = runValidations(file, MAX_FILE_SIZE, SUPPORTED_FORMATS);

      if (!error) {
        if (type === PROMOTIONS_FILE) {
          uploadPromotionsFile(file);
        } else {
          uploadEvaluationFile(file);
        }
        trackEvaluationsAttachFile('Success', evaluationType);

      } else {
        addToastMessage({
          message: errorMessages[error],
          timeout: 3000,
        });
        trackEvaluationsAttachFile('Error', evaluationType);
      }
      target.value = null;
    }
  }

  handleDeleteFile(fileId, type) {
    const {
      closeModal,
      deleteEvaluationFile,
      deleteFile,
    } = this.props;

    if (type === PROMOTIONS_FILE) {
      deleteFile(fileId);
    } else {
      deleteEvaluationFile(fileId);
    }
    closeModal();
  }

  handleAddSkill(skill) {
    const { evaluationAddSkill } = this.props;
    const newSkill = normalizeChangeSkill(skill);
    trackEvaluationsAddSkill(newSkill?.name);
    evaluationAddSkill(newSkill);
  }

  handleChangeSkill(skill) {
    const { evaluationChangeSkill } = this.props;
    evaluationChangeSkill({
      rateId: skill?.rating,
      skillId: skill?.id,
    });
  }

  handleRemoveSkill(id) {
    const { evaluationDeletedSkill } = this.props;
    evaluationDeletedSkill(id);
  }

  handleAddSuggestionsSkill(suggestions) {
    const { evaluationAddSkill, values } = this.props;
    const hasSkills = values?.ratedTopics?.some(topic => topic?.competency?.skill);

    if (!hasSkills) {
      const newSkills = normalizeChangeSkills(suggestions);
      newSkills.forEach(skill => evaluationAddSkill(skill));
    }
  }

  handleFeedbackOverviewFilter(value) {
    const { values: { type } } = this.props;
    const currentValue = value === ALL ? null : value;
    trackEvaluationsCompetenciesFilter(value, type);
    this.setState({ feedbackOverviewFilter: value });
    this.getRatedTopics(currentValue);
  }

  handleCalificationChange(value, score) {
    const { onCalificationChange, values } = this.props;
    onCalificationChange('score', value, score);
    trackEvaluationsSelectedScore(value, values?.type);
  }

  handleChange(type, value, data, score) {
    const { onChange } = this.props;
    onChange(type, value, score);
    trackEvaluationsSelectedTalent(data);
  }

  handleChangePromotion(levelId, promoteValue) {
    const { onChangePromotion } = this.props;
    onChangePromotion(levelId, promoteValue);
    trackEvaluationsSelectPromoteTo(promoteValue);
  }

  isCustomScoreActivated() {
    const { customScoreDefinition, selfEvaluation } = this.props;
    return !!customScoreDefinition && !!customScoreDefinition?.isEnabled && !selfEvaluation;
  }

  isEditingDraft() {
    const { values: { draftId } } = this.props;

    return !!draftId;
  }

  isGoalFinalScoreForced() {
    const { allowsGoalSumEvaluationsScore, goalsFinalScore } = this.props;
    const { value } = goalsFinalScore;
    return allowsGoalSumEvaluationsScore && !isNil(value);
  }

  isGoalFinalScoreForcedDisabled() {
    const { selfEvaluation } = this.props;

    return (selfEvaluation
      ? !selfEvaluation
      : this.isGoalFinalScoreForced()
    );
  }

  isValidTalentMatrix() {
    const { enabledTalentMatrix, values } = this.props;
    return isValidTalentMatrixDimensions(
      values?.talentMatrix?.dimensions,
      enabledTalentMatrix
    );
  }

  render() {
    const {
      activeCompetencies,
      allowsEvaluationDrafts,
      allowsEvaluationsCreation,
      allowsFeedbackForCollaboratorsResult,
      allowsFeedbackForLeadersResult,
      allowsFeedbackForPeerResult,
      allowsGlbFeatures,
      allowsGoals,
      allowsNewHireEvaluationCreation,
      allowsNewHireEvaluationDraft,
      allowsNewHireSelfEvaluationCreation,
      allowsNewHireSelfEvaluationDraft,
      allowsRatedTopicsEvaluationsCreation,
      allowsSelfEvaluations,
      allowsSkills,
      allowsSmuDna,
      assessment360IsEnabled,
      communityId,
      createSaveEvaluationLoading,
      customScoreDefinition,
      enabledTalentMatrix,
      evaluationTitle,
      goalsFinalScore,
      loadingCreate,
      loadingCreateDraft,
      loadingEvaluationFiles,
      loadingGetProfile,
      loadingLastSelfEvaluation,
      loadingRatedTopics,
      loadingUpdateDraft,
      oldFFLIsEnabled,
      onAddCompetency,
      onChange,
      onChangeText,
      onRemoveCompetency,
      optionsScores,
      overviewRatedTopics,
      updateSubmitEvaluationLoading,
      profile,
      rangeScores,
      selfEvaluation,
      skills,
      values: {
        attachments,
        comment,
        customScore,
        dateTimeFrom,
        dateTimeTo,
        evaluee,
        nineBoxComment,
        nineBoxScore,
        ratedTopics,
        score,
        suggestedActionsComment,
        suggestedActionsIds,
        talentMatrix,
        title,
        type,
      },
      feedbackForLeadersSummaryResult,
      intl: { formatMessage },
      ratings,
      scoresDescription,
      suggestedActions,
      userId,
      user,
    } = this.props;
    const {
      as360ResultsSummaryOpen,
      feedbackForLeadersOpen,
      feedbackOpen,
      feedbackOverviewEnabled,
      feedbackOverviewFilter,
      goalsOpen,
      potentialBoxOpen,
      userSelfEvaluationOpen,
      valuesOpen,
    } = this.state;
    const {
      currentSeniority: profileCurrentSeniority,
      currentJob: profileCurrentJob,
      endAssignment: profileEndAssignment,
      hasCollaborators,
      identification: profileIdentification,
      seniority: profileSeniority,
    } = profile;
    const isNewHire = (profile?.newHireEvalAvailable || user?.newHireSelfEvalAvailable);
    const newHireEval = !selfEvaluation && profile?.newHireEvalAvailable
    const newHireSelfEval = selfEvaluation && user?.newHireSelfEvalAvailable;
    const isNewHireSelfType = type === NH_SELF_EVALUATION;
    const isNewHireType = type === NH_EVALUATION;
    const isContinuousEvaluationType = type === CONTINUOUS_EVALUATION;
    const showNewHireSelfForm = (isNewHire && selfEvaluation) || (this.isEditingDraft() && isNewHireSelfType);
    const showNewHireForm = (isNewHire && !selfEvaluation) || (this.isEditingDraft() && isNewHireType);
    const isEvaluationCreation = !selfEvaluation && allowsEvaluationsCreation;
    const isSelfEvaluationCreation = selfEvaluation && allowsSelfEvaluations;
    const showSaveDraftEvaluation = allowsEvaluationDrafts && isEvaluationCreation;
    const showSaveDraftSelfEvaluation = allowsEvaluationDrafts && isSelfEvaluationCreation;
    const allowsEvaluationsNineBox = this.allowsEvaluationsNineBox();
    const allowsSuggestedActions = this.allowsSuggestedActions();
    const overallScore = this.isGoalFinalScoreForced() ? goalsFinalScore.value : score;
    const topicsAddedAndRated = ratedTopics?.filter(topic => topic?.rateId);
    const isValidate = !!comment.trimStart()
      && dateTimeFrom
      && dateTimeTo
      && evaluee
      && (showNewHireSelfForm
        || showNewHireForm
        || (overallScore !== undefined || overallScore !== null || overallScore !== '')
      )
      && title
      && ((!allowsEvaluationsNineBox || showNewHireForm) || nineBoxScore)
      && ((!allowsEvaluationsNineBox || showNewHireForm) || nineBoxComment)
      && (!this.isCustomScoreActivated() || customScore)
      && (!allowsSuggestedActions || suggestedActionsIds.length > 0)
      && ((!allowsSuggestedActions) || suggestedActionsComment)
      && (!allowsRatedTopicsEvaluationsCreation || (topicsAddedAndRated?.length > 2));
    let newHireFormValidation;
    if (isNewHireSelfType) {
      newHireFormValidation = (!allowsRatedTopicsEvaluationsCreation || (topicsAddedAndRated?.length > 2))
        && !isEmpty(comment);
    } else if (isNewHireType && allowsGlbFeatures) {
      newHireFormValidation = (!allowsRatedTopicsEvaluationsCreation || (topicsAddedAndRated?.length > 2))
        && (!allowsSuggestedActions || suggestedActionsIds.length > 0);
    } else if (isNewHireType && !allowsGlbFeatures) {
      newHireFormValidation = (!allowsRatedTopicsEvaluationsCreation || (topicsAddedAndRated?.length > 2))
        && (!allowsSuggestedActions || suggestedActionsIds.length > 0)
        && suggestedActionsComment;
    }
    const sendEvaluationEnabled = (newHireEval || newHireSelfEval) ? newHireFormValidation : isValidate;
    const nineBoxList = this.getNineBoxList();
    const descriptionPlaceholder = selfEvaluation
      ? formatMessage(messages.FeedbackEvaluationsEvaluationCreatePlaceholderSelfComment)
      : formatMessage(messages.FeedbackEvaluationsEvaluationCreatePlaceholderComment);
    const titleEvaluationBox = isNewHire
      ? formatMessage(evaluationsMessages.EvaluationsGeneralRemarks)
      : formatMessage(evaluationsMessages.EvaluationsPotentialBox);
    const getMessage = getMessageType(communityId);
    const sectionBreadcrumb = getMessage('EvaluationsEvaluations');
    const titleEvaluationsSelfEvaluations = getMessage('EvaluationsMyEvaluations');
    const placeholderEvaluationScore = getMessage('EvaluationsPlaceholderScores');
    const placeholderEvaluationsNineBox = getMessage('EvaluationsNineBoxPlaceholder');
    let labelPrimaryAction;
    const showFeedbackForLeaders = !selfEvaluation
      && !showNewHireSelfForm && !showNewHireForm
      && (hasCollaborators || !isEmpty(feedbackForLeadersSummaryResult))
      && allowsFeedbackForLeadersResult
      && oldFFLIsEnabled;
    const showAs360ResultsSummaryWidget = !selfEvaluation
      && assessment360IsEnabled
      && [
        allowsFeedbackForLeadersResult,
        allowsFeedbackForCollaboratorsResult,
        allowsFeedbackForPeerResult,
      ].some(Boolean);
    const tooltipLabeledOptionsScore = optionsScores ? optionsScores.map(({ value, label }, key) => ({
      dataUser: label,
      label: this.getLabelList(label, scoresDescription[key]?.description),
      value,
    })) : null;
    const showEvaluationPeriod = !showNewHireSelfForm && !showNewHireForm;
    const headerPeriod = showEvaluationPeriod && formatPeriodDates(dateTimeFrom, dateTimeTo);
    const ratedTopicsByCompetency = getRatedTopicsByCompetency(ratedTopics);

    if (this.isEditingDraft()) {
      labelPrimaryAction = formatMessage(messages.FeedbackEvaluationsEvaluationCreateSubmitDraft);
    } else {
      labelPrimaryAction = formatMessage(actionsMessages.ActionsCreate);
    }
    const isEmptySuggestions = suggestedActions.length === 0;
    const newHireEvaluationTitle = (isNewHireType || isNewHireSelfType)
      ? formatMessage(evaluationsMessages.EvaluationsGeneralRemarks)
      : formatMessage(evaluationsMessages.EvaluationsOverallScore);
    const evaluationsTitle = isContinuousEvaluationType
      ? formatMessage(messages.FeedbackEvaluationsEvaluationEvaluationCommentTitle)
      : newHireEvaluationTitle;
    const isSelfEvaluation = userId === evaluee?.id;
    const isNewHireDraft = isSelfEvaluation
      ? allowsNewHireSelfEvaluationDraft
      : allowsNewHireEvaluationDraft;
    const isNewHireCreation = isSelfEvaluation
      ? allowsNewHireSelfEvaluationCreation
      : allowsNewHireEvaluationCreation;
    const goToIconNode = (
      <Icon
        color="black"
        icon="external-link"
        size="extra-small"
      />
    );
    const isSelfEvaluationFeedback = selfEvaluation
      ? getMyFeedbackReceivedLocation
      : getProfileFeedbackReceivedLocation(evaluee?.identification);
    const feedbackFilterOptions = [
      {
        value: ALL,
        label: formatMessage(messages.FeedbackEvaluationsEvaluationEvaluationAll),
      },
      {
        value: LEADERS,
        label: formatMessage(messages.FeedbackEvaluationsEvaluationEvaluationLeaders),
      },
    ];
    const attachFilesOverallCustomText = `
      (${formatMessage(messages.FeedbackEvaluationsEvaluationAttachFileOverall)})
    `;

    const sendEvaluationButtons = [
      {
        isVisible: this.isEditingDraft(),
        label: formatMessage(messages.FeedbackEvaluationsEvaluationCreateDeleteDraft),
        onClick: () => this.handleDraftDelete(),
        variant: 'link',
      },
      {
        disabled: !sendEvaluationEnabled,
        isVisible: (newHireEval || newHireSelfEval)
          ? isNewHireDraft
          : (showSaveDraftEvaluation || showSaveDraftSelfEvaluation),
        label: formatMessage(messages.FeedbackEvaluationsEvaluationCreateSaveDraft),
        onClick: () => this.handleSaveOrUpdateDraft(),
        variant: 'outline',
      },
      {
        disabled: !sendEvaluationEnabled || !this.isValidTalentMatrix(),
        isVisible: (newHireEval || newHireSelfEval)
          ? isNewHireCreation
          : allowsEvaluationsCreation,
        label: labelPrimaryAction,
        onClick: () => this.handleCreateEvalOrSaveDraft(),
      },
    ];
    const actionButtons = sendEvaluationButtons.filter(button => button?.isVisible);
    const evaluationTypeTitle = selfEvaluation ? titleEvaluationsSelfEvaluations : sectionBreadcrumb;

    return (
      <Segment
        className="evaluations-create-form"
        loading={updateSubmitEvaluationLoading || createSaveEvaluationLoading}
        loadingType="betterme"
      >
        <BreadcrumbHeader
          breadCrumbsProps={{
            active: 1,
            onClick: this.handleModalClose,
            values: [formatMessage(evaluationTypeTitle), title],
          }}
          date={headerPeriod}
        />
        <div className="evaluations-create-form__content">
          <div className="evaluations-create-form__main-column">
            <Segment className="evaluations-create-form">
              <Title
                black
                className="evaluations-create-form__section-title"
                size={22}
              >
                {evaluationTitle}
              </Title>
              {allowsRatedTopicsEvaluationsCreation && (
                <div className="evaluations-create-form__subsection">
                  <div className="evaluations-create-form__subsection-header">
                    <h4 className="evaluations-create-form__subsection-title">
                      {formatMessage(feedbackMessages.FeedbackCompetencies)}
                    </h4>
                  </div>
                  <Segment
                    loading={loadingRatedTopics}
                    loadingType="betterme"
                    withChildren
                  >
                    <div className="evaluations-create-form__feedback-overview">
                      <div className="evaluations-create-form__feedback-overview-data">
                        <div className="evaluations-create-form__feedback-overview-data-switch">
                          <Switch
                            checked={feedbackOverviewEnabled}
                            disabled={loadingRatedTopics}
                            onChange={this.handleToggleFeedbackOverviewSwitch}
                            size="medium"
                            type="betterme"
                          />
                        </div>
                        <div className="evaluations-create-form__feedback-overview-data-text">
                          {formatMessage(feedbackMessages.FeedbackOverview)}
                        </div>
                      </div>
                      <Select
                        className="evaluations-create-form__feedback-overview-filter"
                        disabled={!feedbackOverviewEnabled}
                        isClearable={false}
                        onChange={(value) => this.handleFeedbackOverviewFilter(value)}
                        options={feedbackFilterOptions}
                        placeholder={formatMessage(messages.FeedbackEvaluationsEvaluationEvaluationAll)}
                        size="small"
                        value={feedbackOverviewFilter}
                      />
                    </div>
                  </Segment>

                  <div className="evaluations-create-form__rate-selector">
                    <CompetenciesRateSelector
                      className="evaluations-create-form__rate-selector"
                      competencies={activeCompetencies}
                      onCompetencyAdd={onAddCompetency}
                      onCompetencyRemove={onRemoveCompetency}
                      ratedTopics={ratedTopicsByCompetency}
                      rates={ratings}
                      showOverview={feedbackOverviewEnabled}
                      useAsync
                    />
                  </div>
                  {allowsSkills && (
                    <div className="evaluations-create-form__subsection">
                      <CommentBox
                        className="evaluations-create-form__comment-box w-full !mb-0"
                        title={formatMessage(messages.EvaluationsSkillsTitle)}
                      >
                        <Skills
                          onAddSkill={this.handleAddSkill}
                          onAddSuggestions={this.handleAddSuggestionsSkill}
                          onChangeSkill={this.handleChangeSkill}
                          onRemoveSkill={this.handleRemoveSkill}
                          userId={evaluee?.id}
                          userName={`${evaluee?.firstName} ${evaluee?.lastName}`}
                          value={normalizeValueSkills(skills, ratedTopicsByCompetency)}
                        />
                      </CommentBox>
                    </div>
                  )}
                  <ButtonLink
                    icon={goToIconNode}
                    label={formatMessage(feedbackMessages.FeedbackViewAllFeedbacks)}
                    onClick={() => trackEvaluationsViewAllFeedbacks(type)}
                    size="medium"
                    target="_blank"
                    to={isSelfEvaluationFeedback}
                    variant="outline"
                  />
                </div>
              )}
              {!isNewHireType && this.isCustomScoreActivated() && (
                <div className="evaluations-create-form__subsection">
                  <div className="evaluations-create-form__subsection-header">
                    <h4 className="evaluations-create-form__subsection-title">
                      {customScoreDefinition.name}
                    </h4>
                  </div>
                  <EvaluationScoreSelector
                    dropdownPlaceholder={
                      formatMessage(messages.FeedbackEvaluationsEvaluationCreatePlaceholderCustomScore)
                    }
                    onScoreChange={value => onChange('customScore', value)}
                    optionsScores={this.getCustomScoreOptions()}
                    score={customScore}
                  />
                </div>
              )}
              {!isNewHireType && (
                <CommentBox className="evaluations-create-form__comment-box" title={evaluationsTitle} >
                  {isNewHireSelfType && (
                    <p className="evaluations-create-form__comment-box-title">
                      {formatMessage(evaluationsMessages.EvaluationsGeneralRemarksSubtitle)}
                    </p>
                  )}
                  <EvaluationScoreSelector
                    attachFileValues={{
                      customText: attachFilesOverallCustomText,
                      files: attachments,
                      loading: loadingEvaluationFiles,
                      maxFiles: MAX_FILES,
                      onChange: e => this.handleFileAttach(e, EVALUATIONS_FILE),
                      onDeleteAccept: id => this.handleDeleteFile(id, EVALUATIONS_FILE),
                      supportedFormats: SUPPORTED_FORMATS,
                    }}
                    comment={comment}
                    disabled={this.isGoalFinalScoreForcedDisabled()}
                    dropdownPlaceholder={!this.isGoalFinalScoreForced()
                      ? formatMessage(placeholderEvaluationScore)
                      : undefined
                    }
                    evaluationType={type}
                    isContinuousEvaluation={isContinuousEvaluationType}
                    isNewHire={isNewHireType || isNewHireSelfType}
                    isSelfNewHire={showNewHireSelfForm}
                    max={get(rangeScores, 'max')}
                    min={get(rangeScores, 'min')}
                    onScoreChange={(value) => this.handleCalificationChange(value, score)}
                    onTextChange={(event) => onChangeText('comment', event)}
                    optionsScores={tooltipLabeledOptionsScore}
                    score={score}
                    textAreaPlaceholder={descriptionPlaceholder}
                    trackEvaluationsSelectedScore={trackEvaluationsSelectedScore}
                    withAttachFiles
                  />
                </CommentBox>
              )}
            </Segment>
            {enabledTalentMatrix && (
              <TalentMatrix
                className="mb-8"
                evaluee={evaluee}
                onChange={(dimensions) => onChange('talentMatrix', {
                  dimensions,
                  type: 'TALENT_MATRIX_V1'
                })}
                value={talentMatrix?.dimensions}
              />
            )}
            {(isNewHireType ? allowsSuggestedActions : (allowsEvaluationsNineBox || allowsSuggestedActions)) && (
              <Segment className="evaluations-create-form__section">
                <Collapsible
                  className="evaluations-create-form__potential-box"
                  baseHeight={850}
                  expanded={potentialBoxOpen}
                  onClick={() => this.handleToggleCard('potentialBoxOpen')}
                  title={titleEvaluationBox}
                  variant="box"
                  withIcon
                >
                  {potentialBoxOpen && !showNewHireForm && allowsEvaluationsNineBox && (
                    <div className="evaluations-create-form__subsection">
                      <div className="evaluations-create-form__subsection-header">
                        <h4 className="evaluations-create-form__subsection-title">
                          {formatMessage(evaluationsMessages.EvaluationsNineBoxScore)}
                        </h4>
                        <div className="evaluations-create-form__disclaimer">
                          <Icon
                            className="evaluations-create-form__disclaimer-icon"
                            color="orange"
                            icon="expired-icon"
                            size={14}
                          />
                          <span className="evaluations-create-form__disclaimer-text">
                            {formatMessage(messages.FeedbackEvaluationsEvaluationCreateNineBoxDisclaimer, {
                              username: evaluee.firstName,
                            })}
                          </span>
                        </div>
                      </div>
                      {/* TODO analize if it's necessary to replace with EvaluationScoreSelector*/}
                      <div className="evaluations-create-form__dropdown">
                        <Dropdown
                          className="evaluations-create-form__dropdown-content"
                          disabled={nineBoxList.length === 0}
                          onChange={(value, data) => this.handleChange('nineBoxScore', value, data, score)}
                          options={nineBoxList}
                          padding
                          placeholder={
                            nineBoxScore
                              ? undefined
                              : formatMessage(placeholderEvaluationsNineBox)
                          }
                          value={nineBoxScore || ''}
                        />
                      </div>
                      {(!allowsGlbFeatures) && (
                        <InputArea
                          disabled={nineBoxList.length === 0}
                          maxLength={1200}
                          onChange={(event) => onChangeText('nineBoxComment', event)}
                          placeholder={formatMessage(messages.FeedbackEvaluationsEvaluationCreateNineBoxCommentPlaceholder)}
                          value={nineBoxComment || ''}
                        />
                      )}
                    </div>
                  )}
                  {potentialBoxOpen && allowsSuggestedActions && (
                    <div className="evaluations-create-form__subsection">
                      <div className="evaluations-create-form__subsection-header">
                        <h4 className="evaluations-create-form__subsection-title">
                          {formatMessage(evaluationsMessages.EvaluationsSuggestedActions)}
                        </h4>
                        <div className="evaluations-create-form__disclaimer">
                          <Icon
                            className="evaluations-create-form__disclaimer-icon"
                            color="orange"
                            icon="expired-icon"
                            size={14}
                          />
                          <span className="evaluations-create-form__disclaimer-text">
                            {formatMessage(messages.FeedbackEvaluationsEvaluationCreateActionsDisclaimer, {
                              username: evaluee.firstName,
                            })}
                          </span>
                        </div>
                      </div>
                      {isEmptySuggestions ? (
                        <p className="suggest-actions-form__empty-suggestions-label">
                          {formatMessage(suggestedActionsMessages.SuggestActionsEmptySuggestions)}
                        </p>
                      ) : (
                        <ul className="evaluations-create-form__suggested-actions-list">
                          {suggestedActions.map(suggestedAction => {
                            return (
                              <li
                                className="evaluations-create-form__suggested-action-wrapper"
                                key={suggestedAction.id}
                              >
                                <SuggestedAction
                                  {...suggestedAction}
                                  selected={suggestedActionsIds.includes(suggestedAction.id)}
                                  onChange={
                                    () => this.handleSuggestedActionSelect(suggestedAction)
                                  }
                                />
                              </li>
                            )
                          })}
                        </ul>
                      )}
                      <InputArea
                        disabled={isEmptySuggestions}
                        maxLength={1200}
                        onChange={(event) => onChangeText('suggestedActionsComment', event)}
                        placeholder={formatMessage(
                          messages.FeedbackEvaluationsEvaluationCreateActionsCommentPlaceholder
                        )}
                        value={suggestedActionsComment || ''}
                      />
                    </div>
                  )}
                </Collapsible>
              </Segment>
            )}
            <Segment
              className="evaluations-create-form__actions"
              loading={loadingCreate || loadingCreateDraft || loadingUpdateDraft}
              loadingType="betterme"
            >
              <ActionButtons buttons={actionButtons} />
            </Segment>
          </div>
          <div className="evaluations-create-form__side-column">
            {!selfEvaluation && (
              <UserDetails
                {...evaluee}
                className="evaluations-create-form__side-column--user"
                currentJob={profileCurrentJob}
                currentSeniority={profileCurrentSeniority}
                endAssignment={profileEndAssignment}
                loading={loadingGetProfile}
                onShowDetailsClick={this.handleShowDetailsClick}
                seniority={profileSeniority}
              />
            )}
            <Segment loadingType="betterme" loading={loadingLastSelfEvaluation}>
              {overviewRatedTopics.length > 0 && (
                <FeedbackOverview
                  onToggleClick={() => this.handleToggleCard('feedbackOpen')}
                  open={feedbackOpen}
                  ratedTopics={overviewRatedTopics}
                  title={formatMessage(feedbackMessages.FeedbackOverview)}
                />
              )}
              {!selfEvaluation && (
                <SelfEvaluationListWidget
                  communityId={communityId}
                  onToggleClick={() => this.handleToggleCard('userSelfEvaluationOpen')}
                  open={userSelfEvaluationOpen}
                  user={evaluee}
                />
              )}
              {showFeedbackForLeaders && (
                <FeedbackForLeaders
                  identification={profileIdentification}
                  onToggleClick={() => this.handleToggleCard('feedbackForLeadersOpen')}
                  open={feedbackForLeadersOpen}
                  user={evaluee}
                />
              )}
              {showAs360ResultsSummaryWidget && (
                <Assessment360ResultsSummaryWidget
                  onToggleClick={() => this.handleToggleCard('as360ResultsSummaryOpen')}
                  open={as360ResultsSummaryOpen}
                  userId={evaluee.id}
                />
              )}
              {allowsGoals && (
                <div className="evaluations-create-form__goals">
                  <GoalOverviewWidget
                    defaultFilterOpen={false}
                    dateTimeTo={dateTimeTo}
                    dateTimeFrom={dateTimeFrom}
                    onToggleClick={() => this.handleToggleCard('goalsOpen')}
                    open={goalsOpen}
                    user={evaluee}
                  />
                  {!isNil(goalsFinalScore.value) && (
                    <div className="evaluations-create-form__goals-final-score">
                      <span className="evaluations-create-form__goals-final-score-text">
                        {`${formatMessage(evaluationsMessages.EvaluationsGoalsFinalScore)}:`}
                      </span>
                      {formatScore(goalsFinalScore)}
                    </div>
                  )}
                </div>
              )}
              {allowsSmuDna && (
                <div className="evaluations-create-form__values">
                  <ContentCard
                    className="evaluations-create-form__values-collapsible"
                    title={formatMessage(commonMessages.Values)}
                    open={valuesOpen}
                    onToggleClick={() => this.handleToggleCard('valuesOpen')}
                  >
                    <ContentCard.Body>
                      <StarmeUpDNAWidget
                        className="evaluations-create-form__smu-dna-widget"
                        endDate={dateTimeTo}
                        small
                        startDate={dateTimeFrom}
                        user={evaluee}
                      />
                    </ContentCard.Body>
                  </ContentCard>
                </div>
              )}
            </Segment>
          </div>
        </div>
        <SendEvaluationConfirmationModal
          modalProps={{ sendEvaluation: this.handleCreateEvaluation }}
        />
      </Segment>
    );
  }
}

EvaluationCreateForm.propTypes = {
  addToastMessage: PropTypes.func,
  allowsFeedbackForCollaboratorsResult: PropTypes.bool,
  allowsFeedbackForLeadersResult: PropTypes.bool,
  allowsFeedbackForPeerResult: PropTypes.bool,
  allowsGlbFeatures: PropTypes.bool,
  allowsGoalSumEvaluationsScore: PropTypes.bool,
  allowsGoals: PropTypes.bool,
  allowsPromotions: PropTypes.bool,
  allowsRatedTopicsEvaluationsCreation: PropTypes.bool,
  allowsSelfEvaluations: PropTypes.bool,
  allowsSmuDna: PropTypes.bool,
  assessment360IsEnabled: PropTypes.bool,
  close: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  communityId: PropTypes.number.isRequired,
  createSaveEvaluationLoading: PropTypes.bool,
  customScoreDefinition: PropTypes.object,
  customScores: PropTypes.array,
  deleteEvaluationFile: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired,
  deletedSkills: PropTypes.array,
  enabledTalentMatrix: PropTypes.bool,
  evaluationChangeSkill: PropTypes.func.isRequired,
  evaluationDeletedSkill: PropTypes.func.isRequired,
  evaluationTitle: PropTypes.string.isRequired,
  feedbackForLeadersSummaryResult: PropTypes.array,
  getAssignedGoals: PropTypes.func.isRequired,
  getProfile: PropTypes.func.isRequired,
  getPromotionsLevel: PropTypes.func.isRequired,
  goalsFinalScore: PropTypes.object,
  isNewHire: PropTypes.bool.isRequired,
  lastSelfEvaluation: PropTypes.object,
  loadingCreate: PropTypes.bool,
  loadingCreateDraft: PropTypes.bool,
  loadingEvaluationFiles: PropTypes.bool,
  loadingFiles: PropTypes.bool,
  loadingGetProfile: PropTypes.bool,
  loadingLastSelfEvaluation: PropTypes.bool,
  loadingUpdateDraft: PropTypes.bool,
  lowestRating: PropTypes.object,
  nineBoxList: PropTypes.array,
  oldFFLIsEnabled: PropTypes.bool,
  onAddCompetency: PropTypes.func.isRequired,
  onAddSkill: PropTypes.func,
  onCalificationChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeText: PropTypes.func.isRequired,
  onCreateEvaluation: PropTypes.func.isRequired,
  onRemoveCompetency: PropTypes.func.isRequired,
  onRemoveSkill: PropTypes.func,
  onSuggestedActionSelect: PropTypes.func,
  onSuggestedActionsCommentChange: PropTypes.func,
  onUpdateDraft: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  optionsScores: PropTypes.array,
  overviewGoals: PropTypes.array,
  overviewGoalsLoading: PropTypes.bool,
  overviewRatedTopics: PropTypes.array,
  profile: PropTypes.object,
  profileAccess: PropTypes.shape({
    feedback: PropTypes.bool.isRequired,
  }),
  promotionConfig: PropTypes.object,
  promotionFiles: PropTypes.array,
  promotionsLevel: PropTypes.array,
  rangeScores: PropTypes.object,
  resetLastSelfEvaluation: PropTypes.func.isRequired,
  resetProfile: PropTypes.func.isRequired,
  uploadPromotionsFile: PropTypes.func,
  values: PropTypes.object.isRequired,
};

const modalStyles = { maxWidth: '350px' };

const mapStateToProps = (state, ownProps) => ({
  activeCompetencies: selectActiveCompetenciesWithRatesCount(state),
  allowsEvaluationDrafts: selectConfigurations('allowsEvaluationDrafts')(state),
  allowsEvaluations: selectConfigurations('allowsEvaluations')(state),
  allowsEvaluationsCreation: selectConfigurations('allowsEvaluationsCreation')(state),
  allowsEvaluationsNineBox: selectConfigurations('allowsEvaluationsNineBox')(state),
  allowsEvaluationsSuggestedActions: selectConfigurations('allowsEvaluationsSuggestedActions')(state),
  allowsFeedbackForCollaboratorsResult: selectConfigurations('allowsFeedbackForCollaboratorsResult')(state),
  allowsFeedbackForLeadersResult: selectConfigurations('allowsFeedbackForLeadersResult')(state),
  allowsFeedbackForPeerResult: selectConfigurations('allowsFeedbackForPeerResult')(state),
  allowsGlbFeatures: selectConfigurations('allowsGlbFeatures')(state),
  allowsGoalSumEvaluationsScore: selectConfigurations('allowsGoalSumEvaluationsScore')(state),
  allowsGoals: selectConfigurations('allowsGoals')(state),
  allowsNewHireEvaluationCreation: selectConfigurations('allowsNewHireEvaluationCreation')(state),
  allowsNewHireEvaluationDraft: selectConfigurations('allowsNewHireEvaluationDraft')(state),
  allowsNewHireSelfEvaluationCreation: selectConfigurations('allowsNewHireSelfEvaluationCreation')(state),
  allowsNewHireSelfEvaluationDraft: selectConfigurations('allowsNewHireSelfEvaluationDraft')(state),
  allowsPromotions: selectConfigurations('allowsPromotions')(state),
  allowsRatedTopicsEvaluationsCreation: selectConfigurations('allowsRatedTopicsEvaluationsCreation')(state),
  allowsSelfEvaluations: selectConfigurations('allowsSelfEvaluations')(state),
  allowsSkills: selectConfigurations('allowsSkills')(state),
  allowsSmuDna: selectConfigurations('allowsSmuDna')(state),
  assessment360IsEnabled: getCommunityConfigValueBool(state, ENABLED_ASSESSMENT360),
  createSaveEvaluationLoading: selectCreateSaveEvaluationLoading(state),
  customScoreDefinition: selectCustomScoreDefinition(state),
  customScores: selectCustomScores(state),
  deletedSkills: selectEvaluationsDeletedSkills(state),
  enabledTalentMatrix: isEnabledTalentMatrixByLeader(state, ownProps?.values?.evaluee?.identification),
  evaluationPeriod: selectEvaluationPeriod(state),
  feedbackForLeadersEvaluations: selectFeedbackForLeadersEvaluations(state),
  feedbackForLeadersSummaryResult: selectFeedbackForLeadersSummaryResult(state),
  goalsFinalScore: selectGoalsFinalScore(state),
  loadingCreate: selectLoadingCreate(state),
  loadingCreateDraft: selectLoadingCreateDraft(state),
  loadingEvaluationFiles: selectLoadingEvaluationFiles(state),
  loadingFiles: selectLoadingPromotionsFiles(state),
  loadingGetProfile: selectLoadingGetProfile(state),
  loadingLastSelfEvaluation: selectLoadingLastSelfEvaluation(state),
  loadingRatedTopics: selectLoading(state),
  loadingUpdateDraft: selectLoadingUpdateDraft(state),
  lowestRating: selectLowestRating(state),
  nineBoxList: selectNineBoxList(state),
  oldFFLIsEnabled: getCommunityConfigValueBool(state, ENABLED_OLD_FFL),
  overviewGoals: selectAssignedGoals(state),
  overviewGoalsLoading: selectLoadingMyGoals(state),
  overviewRatedTopics: selectRatedTopics(state),
  profile: selectProfile(state),
  profileAccess: selectProfileAccess(state),
  promotionConfig: selectPromotionConfig(state),
  promotionFiles: selectPromotionsFiles(state),
  promotionsLevel: selectPromotionsLevel(state),
  scoresDescription: selectScoresDescription(state),
  showTeamEvaluationsGoalFinalScore: selectShowTeamEvaluationsGoalFinalScore(state),
  skills: selectEvaluationsSkills(state),
  suggestedActions: selectSuggestedActions(state),
  updateSubmitEvaluationLoading: selectUpdateSubmitEvaluationLoading(state),
});

const mapDispatchToProps = {
  addToastMessage,
  closeModal,
  deleteEvaluationFile,
  deleteFile,
  evaluationAddDeletedSkill,
  evaluationAddSkill,
  evaluationChangeSkill,
  evaluationCleanDeletedSkills,
  evaluationCleanSkills,
  evaluationDeletedSkill,
  getAssignedGoals,
  getCustomScores,
  getFeedbackForLeaders,
  getGoalsFinalScore,
  getProfile,
  getPromotionsLevel,
  goToEvaluations: () => push(getEvaluationsLocation(null)),
  goToEvaluationsTeam: () => push(getTeamEvaluationsLocation(null)),
  openModal,
  requestRatedTopics,
  resetDataCreateEvaluation,
  resetFeedbackForLeaders,
  resetLastSelfEvaluation,
  resetProfile,
  resetRatedTopics,
  resetSuggestPromotion,
  uploadEvaluationFile,
  uploadPromotionsFile,
};

export default FullScreenModal(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(EvaluationCreateForm)),
  EVALUATION_CREATE_FORM,
  modalStyles,
  'simple-modal__wrapper',
);
