import { takeLatest, call, put } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import { getPromotionRequestsHistory } from '../api';
import {
  getPromotionRequestsHistoryFail,
  getPromotionRequestsHistorySuccess,
} from './actions';
import { GET_PROMOTION_REQUESTS_HISTORY } from './actionTypes';

function* getPromotionRequestsWorker({ payload: { userId, pageNumber } }) {
  try {
    const { result, page } = yield call(getPromotionRequestsHistory, { userId, pageNumber });
    yield put(getPromotionRequestsHistorySuccess(result, page));
  } catch(error) {
    yield put(getPromotionRequestsHistoryFail(error.message));
    yield put(addToastMessage({ message: error.message, timeout: 3000 }));
  }
}

export default function* getPromotionRequestsHistoryWatcher() {
  yield takeLatest(GET_PROMOTION_REQUESTS_HISTORY, getPromotionRequestsWorker);
}
