// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Button from 'smu-ui-components/Button';
import Icon from 'smu-ui-components/Icon';
import Segment from 'smu-ui-components/Segment';
import Typography from 'smu-ui-components/Typography';

// @own
import './styles.scss';

const CardSimple = ({
  children,
  className,
  gray,
  listOrder,
  loading,
  lowercaseSubtitle,
  newStyle,
  noMargin,
  noPaddingTop,
  onOrderClick,
  subtitle,
  title,
  withChildren,
  withoutCard,
}) => {
  const isSortAsc = listOrder === 'asc';

  return (
    <Segment
      className={cn('card-simple', className, {
        'card-simple--gray': gray,
        'card-simple--card': !withoutCard && !newStyle,
        'card-simple--no-margin': noMargin,
        'card-simple--no-padding-top': noPaddingTop,
        'card-simple--new-style': newStyle,
      })}
      loading={loading}
      newStyle
      loadingType="betterme"
      withChildren={withChildren}
    >
      <div className="card-simple__header">
        <Typography component="h3" className="text-sm m-0 capitalize text-blue" variant="title2">
          {title}
        </Typography>
        {subtitle && (
          <Typography component="h4" className={cn("font-normal text-xs m-0 text-loblolly", {
            'normal-case': lowercaseSubtitle,
          })} variant="body1">
            {title}
          </Typography>
        )}
        {(onOrderClick) && (
          <div className="card-simple__buttons">
            <Button
              className={cn('card-simple__button', {
                'card-simple__button--disabled': isSortAsc,
              })}
              color={isSortAsc ? 'black' : null}
              onClick={!isSortAsc ? () => onOrderClick(listOrder) : null}
              size="narrow"
            >
              <Icon
                className="rating-details__order-icon"
                color={isSortAsc ? 'white' : null}
                icon="sort-asc"
              />
            </Button>
            <Button
              className={cn('card-simple__button', {
                'card-simple__button--disabled': !isSortAsc,
              })}
              color={!isSortAsc ? 'black' : null}
              onClick={isSortAsc ? () => onOrderClick(listOrder) : null}
              size="narrow"
            >
              <Icon
                className="rating-details__order-icon"
                color={!isSortAsc ? 'white' : null}
                icon="sort-desc"
              />
            </Button>
          </div>
        )}
      </div>
      {children}
    </Segment>
  );
};

CardSimple.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  listOrder: PropTypes.string,
  loading: PropTypes.bool,
  lowercaseSubtitle: PropTypes.bool,
  newStyle: PropTypes.bool,
  onOrderClick: PropTypes.func,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  withChildren: PropTypes.bool,
  withoutCard: PropTypes.bool,
};

export default CardSimple;
