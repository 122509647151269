// @packages
import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

// @own
import './styles.scss';

const CardActions = ({
  children,
  className,
}) => {
  return (
    <div className={cn('card-actions', className)}>
      {children}
    </div>
  );
};

CardActions.defaultProps = {
  className: '',
};

CardActions.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default CardActions;
