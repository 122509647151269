// @packages
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RootModal from 'smu-app-components/RootModal';
import Segment from 'smu-ui-components/Segment';

// @app
import GenericModal from 'components/templates/GenericModalTemplate';
import Title from 'components/atoms/Title';
import ChooseReason from 'components/organisms/ChooseReason';
import { centerModalClass } from 'betterme-components/ConfirmationModal';
import {
  actionsMessages,
  commonMessages,
  placeholdersMessages,
  userMessages,
} from 'common/messages';
import messages from 'components/pages/LeadershipRequestSection/messages';
import {
  selectGetChangeLeaderDismissReason,
  selectLoadingGetChangeLeaderDismissReason,
  selectSelectedDismissReason,
} from 'betterme-components/services/ChangeLeaderRequestAcceptDecline/selectors';
import * as actions from 'betterme-components/services/ChangeLeaderRequestAcceptDecline/actions';
import { REASONS_DIMISS_MODAL } from 'betterme-components/constants/modalTypes';
import { CHANGE_LEADER_REQUEST_DECLINE, SIZE_SIXTEEN, REJECTED } from 'components/common/constants';
import { trackLeadershipRequestDeclineReasonSend } from 'betterme-components/services/LeadershipRequest/analytics';

const ReasonsDismissModal = ({
  closeModal,
  collaborator,
  getChangeLeaderDismissReason,
  intl: { formatMessage },
  leaderType,
  loadingDismissReasons,
  putChangeLeader,
  reasons,
  requestId,
  resetDismissReason,
  selectedDismissReason,
  selectedReason,
}) => {
  const [comment, setComment] = useState('');
  useEffect(() => {
    getChangeLeaderDismissReason(CHANGE_LEADER_REQUEST_DECLINE);
  }, []);

  const otherReason = reasons.filter(reason => reason.isCommentRequired);
  const hasComment = comment.length > 0;
  const hasOtherReason = otherReason[0]?.id === selectedReason;
  const hasSelectedReason = !!selectedReason;
  const hasMandatoryComment = hasSelectedReason !== hasOtherReason || hasComment;
  const enabledSend = hasSelectedReason && hasMandatoryComment;

  function handleCommentChange(event) {
    setComment(event.target.value);
  };

  function handleReasonChange(event) {
    const { value } = event.target;
    selectedDismissReason(value);
  };

  function handleDismissRequest() {
    const status = REJECTED;
    const reasonId = selectedReason;
    const reason = reasons.filter(r => r.id !== reasonId)[0]?.name;
    const message = formatMessage(messages.ToastMessageSuccess);
    const trackData = {
      label: reason,
      type: leaderType,
    };

    putChangeLeader(requestId, status, reasonId, comment, null, message);
    closeModal();
    trackLeadershipRequestDeclineReasonSend(trackData);
    resetDismissReason();
  };

  function handleClose() {
    closeModal();
    resetDismissReason();
  };

  const actionButtons = [
    {
      label: formatMessage(actionsMessages.ActionsCancel),
      onClick: handleClose,
      variant: 'outline',
    },
    {
      disabled: !enabledSend,
      label: formatMessage(actionsMessages.ActionsSend),
      onClick: handleDismissRequest,
    },
  ];

  return (
    <Segment loading={loadingDismissReasons} loadingType="betterme">
      <GenericModal
        actionButtons={actionButtons}
        content={
          <ChooseReason
            commentPlaceholder={formatMessage(placeholdersMessages.PlaceholdersOtherReason)}
            commentValue={comment}
            disabledTextBox={hasOtherReason}
            onReasonChange={(event) => handleReasonChange(event)}
            onCommentChange={(event) => handleCommentChange(event)}
            reasons={reasons}
            selectedReason={selectedReason}
            title={formatMessage(commonMessages.Comment)}
          />
        }
        header={
          <Title className="text-black" size={SIZE_SIXTEEN}>
            {formatMessage(userMessages.CantBeLeader, 
              { username: collaborator }
            )}
          </Title>
        }
        onClose={handleClose}
      />
    </Segment>
  )
};

ReasonsDismissModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  collaborator: PropTypes.string.isRequired,
  getChangeLeaderDismissReason: PropTypes.func.isRequired,
  intl: PropTypes.object,
  reasons: PropTypes.array.isRequired,
  loadingDismissReasons: PropTypes.bool.isRequired,
  putChangeLeader: PropTypes.func.isRequired,
  requestId: PropTypes.number.isRequired,
  resetDismissReason: PropTypes.func.isRequired,
  selectedDismissReason: PropTypes.object.isRequired,
  selectedReason: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  reasons: selectGetChangeLeaderDismissReason(state),
  selectedReason: selectSelectedDismissReason(state),
  loadingDismissReasons: selectLoadingGetChangeLeaderDismissReason(state),
});

const mapDispatchToProps = {
  ...actions,
};

const modalStyles = {
  width: '610px',
};

export default RootModal(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(ReasonsDismissModal)),
  REASONS_DIMISS_MODAL,
  modalStyles,
  centerModalClass,
);
