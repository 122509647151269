import { defineMessages } from 'react-intl';

export default defineMessages({
  FeedbackFeedMenuItemReceivedFrom: {
    id: 'Betterme.Feed.Menu.Item.ReceivedFrom',
    defaultMessage: 'Received from',
  },
  FeedbackFeedMenuItemSentBy: {
    id: 'Betterme.Feed.Menu.Item.SentBy',
    defaultMessage: 'Sent by',
  },
  FeedbackFeedMenuItemMe: {
    id: 'Betterme.Feed.Menu.Item.Me',
    defaultMessage: 'Me',
  },
  FeedbackFeedMenuItemReceived: {
    id: 'Betterme.Feed.Menu.Item.Received',
    defaultMessage: 'Received',
  },
  FeedbackFeedMenuItemSent: {
    id: 'Betterme.Feed.Menu.Item.Sent',
    defaultMessage: 'Sent',
  },
  FeedbackFeedMenuItemSearchInput: {
    id: 'Betterme.Feed.Menu.Item.SearchInput',
    defaultMessage: 'Search Input',
  },
  FeedbackFeedMenuItemDatePicker: {
    id: 'Betterme.Feed.Menu.Item.DatePicker',
    defaultMessage: 'Dates pickers',
  },
  FeedbackFeedItemTitleRate: {
    id: 'Betterme.Feed.Menu.Item.Rate',
    defaultMessage: 'Rating',
  },
  FeedbackFeedItemTitleComment: {
    id: 'Betterme.Feed.Menu.Item.Comment',
    defaultMessage: 'Comment',
  },
  FeedbackFeedEmptyStateOwnSentTitle: {
    id: 'Betterme.Feed.EmptyState.OwnSentTitle',
    defaultMessage: 'You haven\'t sent any Feedback for this period yet'
  },
  FeedbackFeedEmptyStateReceivedTitle: {
    id: 'Betterme.Feed.EmptyState.ReceivedTitle',
    defaultMessage: 'hasn\'t received any Feedback from you for this period yet'
  },
  FeedbackFeedEmptyStateReceivedText: {
    id: 'Betterme.Feed.EmptyState.ReceivedText',
    defaultMessage: 'Give them input on their performance to help them grow'
  },
  FeedbackFeedEmptyStateTeamReceivedTitle: {
    id: 'Betterme.Feed.EmptyState.TeamReceivedTitle',
    defaultMessage: 'Your team hasn\'t received any feedback for this period yet'
  },
  FeedbackFeedEmptyStateTeamReceivedText: {
    id: 'Betterme.Feed.EmptyState.TeamReceivedText',
    defaultMessage: 'Give them input on their performance to help them grow'
  },
  FeedbackFeedEmptyStateSentTitle: {
    id: 'Betterme.Feed.EmptyState.SentTitle',
    defaultMessage: 'hasn\'t sent any Feedback to you yet'
  },
  FeedbackFeedEmptyStateSentText: {
    id: 'Betterme.Feed.EmptyState.SentText',
    defaultMessage: 'Ask them for input on your performance to help your career grow'
  },
  FeedbackFeedFloatButtonFeatureDiscovery: {
    id: 'Betterme.Feed.FloatButton.FeatureDiscovery',
    defaultMessage: 'Hover on this button to send and request Feedback quickly.',
  },
  FeedbackFeedEmptyStateTitle: {
    id: 'Betterme.Feed.EmptyState.EmptyStateTitle',
    defaultMessage: 'This feedback has been deleted.',
  },
  FeedbackFeedEmptyStateDescription: {
    id: 'Betterme.Feed.EmptyState.EmptyStateDescription',
    defaultMessage: 'But you can always <a class="feedback-view__empty-state--link" href={link} target="_blank">Request Feedback</a></br> in order to keep honing your skills.',
  },
});
