import scoreIcon1 from 'betterme-components/assets/potential-assessment-results/bulb_icon_1.svg';
import scoreIcon2 from 'betterme-components/assets/potential-assessment-results/bulb_icon_2.svg';
import scoreIcon3 from 'betterme-components/assets/potential-assessment-results/bulb_icon_3.svg';
import scoreIcon4 from 'betterme-components/assets/potential-assessment-results/bulb_icon_4.svg';


export const MORE_INFO_LINK = 'https://sites.google.com/globant.com/leadershipcommunity/developing-my-team/potential-development#h.jsvgd37pkvmd';
export const SCORE_PROPS = [
    {
        scoreValue: 1,
        scoreColor: '#00D8FF',
        scoreImage: scoreIcon1,
    },
    {
        scoreValue: 2,
        scoreColor: '#229AFF',
        scoreImage: scoreIcon2,
    },
    {
        scoreValue: 3,
        scoreColor: '#435CFF',
        scoreImage: scoreIcon3,
    },
    {
        scoreValue: 4,
        scoreColor: '#651FFF',
        scoreImage: scoreIcon4,
    },
];

export const SEGMENTS = [
    {
        segment: 'Segment1',
        name: 'RISING_STAR',
    },
    {
        segment: 'Segment2',
        name: 'RISING_LEADERSHIP',
    },
    {
        segment: 'Segment3',
        name: 'SUPERNOVA_LEADERSHIP',
    },
    {
        segment: 'Segment4',
        name: 'EXECUTIVE_LEADERSHIP',
    },
];

