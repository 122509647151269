// @packages
import React from 'react';
import PropTypes from 'prop-types';
import RootModal from 'smu-app-components/RootModal';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

// @app
import GenericModalAssessment from 'components/templates/GenericModalAssessment';
import { commonMessages } from 'common/messages';
import InfoCard from 'components/molecules/InfoCard';
import { centerModalClass } from 'betterme-components/ConfirmationModal';
import { SEND_ASSESSMENT_CONFIRMATION_MODAL } from 'betterme-components/constants/modalTypes';
import { assessmentHomePageLocation } from 'betterme-components/routes/urls';

// @own
import messages from './messages';

const SendAssessmentConfirmationModal = ({
  assessmentUserName,
  closeModal,
  goToBehaviourAssessment,
  intl: { formatMessage },
  type,
}) => {
  const potentiaAssessmentName = formatMessage(messages.PotentialAssessmentTitle);

  const handleAccept = (eventLabel) => {
    goToBehaviourAssessment();
    closeModal();
  }

  const actionButtons = [
    {
      color: 'assessment-blue',
      label: formatMessage(
        messages.PotentialAssessmentConfirmationModalAcceptButton,
        { potentiaAssessmentName }
      ),
      onClick: () => handleAccept('Return'),
    },
  ];

  return (
    <GenericModalAssessment
      actionButtons={actionButtons}
      content={
        <InfoCard
          icon="check-icon"
          message={
            <FormattedHTMLMessage
              defaultMessage={messages.PotentialAssessmentConfirmationModalDescription.defaultMessage}
              id={messages.PotentialAssessmentConfirmationModalDescription.id}
              values={{
                potentiaAssessmentName,
                assessmentUserName,
              }}
            />
          }
          secondary
          title={formatMessage(commonMessages.WellDone)}
        />
      }
      onClose={() => handleAccept('Close')}
    />
  );
};

SendAssessmentConfirmationModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  intl: PropTypes.object,
  onAccept: PropTypes.func,
  potentiaAssessmentName: PropTypes.string,
  potentialAssessmentUserName: PropTypes.string,
};

const modalStyles = {
  width: '600px',
  borderRadius: '8px',
  overflow: 'hidden',
};

const mapDispatchToProps = {
  goToBehaviourAssessment: () => push(assessmentHomePageLocation()),
};

export default RootModal(
  injectIntl(connect(null, mapDispatchToProps)
    (SendAssessmentConfirmationModal)),
  SEND_ASSESSMENT_CONFIRMATION_MODAL,
  modalStyles,
  centerModalClass,
);
