// @packages
import React, { useState } from 'react';
import AvatarMedia from 'smu-ui-components/AvatarMedia';
import Label from 'smu-ui-components/Label';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';

// @app
import Title from 'components/atoms/Title';
import {
  actionsMessages,
  commonMessages,
  userMessages,
} from 'common/messages';

// @own
import './styles.scss';
import LeadersListDiscovery from './Discovery';
import UsersListInfo from '../UsersListInfo';
import messages from '../messages';
import {
  CATEGORIZED_LEADER_LIST,
  MIN_LENGTH,
  SIZE,
  SUBSIZE,
  USER_LENGTH,
} from '../constants';

const LeadersList = ({
  allowsGlbFeatures,
  evaluators,
  intl: { formatMessage },
  mainAndSecondayLeader,
  mainLeader,
  newWordingForLeaders,
}) => {
  const [open, setOpen] = useState(false);
  const titleWhitNewWording = mainAndSecondayLeader
    ? formatMessage(commonMessages.CareerAndExperienceLeader)
    : formatMessage(commonMessages.CareerLeader);
  const titleWhitOutNewWording = mainAndSecondayLeader
    ? formatMessage(messages.LeadersListMainAndSecondaryLeader)
    : formatMessage(messages.LeadersListMainLeader);
  const mainLeaderTitle = newWordingForLeaders
    ? titleWhitNewWording
    : titleWhitOutNewWording;
  const otherAccountLeaders = mainAndSecondayLeader
    ? formatMessage(commonMessages.OtherExperienceLeaders)
    : formatMessage(commonMessages.ExperienceLeaders);
  const otherSecondaryLeaders = mainAndSecondayLeader
    ? formatMessage(messages.LeadersListOtherSecondaryLeaders)
    : formatMessage(messages.LeadersListSecondaryLeader);
  const secondaryLeaderTitle = newWordingForLeaders
    ? otherAccountLeaders
    : otherSecondaryLeaders;
  const galleryItems = [
    {
      text: (
        <FormattedHTMLMessage
          defaultMessage={messages.LeaderListDiscoverySlideTextOne.defaultMessage}
          id={messages.LeaderListDiscoverySlideTextOne.id}
        />
      ),
    },
    {
      text: (
        <FormattedHTMLMessage
          defaultMessage={messages.LeaderListDiscoverySlideTextTwo.defaultMessage}
          id={messages.LeaderListDiscoverySlideTextTwo.id}
        />
      ),
    },
  ];

  const actionButtonTexts = {
    slideOneText: formatMessage(actionsMessages.ActionsSkip),
    slideOneTextAlternative: formatMessage(actionsMessages.ActionsGotIt),
    slideTwoText: formatMessage(actionsMessages.ActionsContinue),
    slideTwoTextAlternative: formatMessage(actionsMessages.ActionsBack),
  };

  return (
    <div className="categorized-leaders-list">
      <div className="categorized-leaders-list__title-container">
        <div className="categorized-leaders-list__discovery-container">
          <Title size={SIZE}>
            {formatMessage(userMessages.UserLeaders)}
          </Title>
          {allowsGlbFeatures && (
            <LeadersListDiscovery
              actionButtonTexts={actionButtonTexts}
              galleryItems={galleryItems}
              handleOpen={setOpen}
              open={open}
              title={formatMessage(messages.LeaderListDiscoveryTitle)}
              type={CATEGORIZED_LEADER_LIST}
            >
              <div
                className="categorized-leaders-list__label-discovery"
                onClick={() => setOpen(true)}
              >
                <Label
                  color="btm-blue-button"
                  size="small"
                  text={formatMessage(messages.LeaderListDiscoveryNew)}
                />
              </div>
            </LeadersListDiscovery>
          )}
        </div>
      </div>
      {mainLeader || evaluators?.length > 0 ? (
        <div className="categorized-leaders-list__categories-container">
          <div className="categorized-leaders-list__main-leader">
            <Title className="text-raven" size={SUBSIZE}>
              {mainLeaderTitle}
            </Title>
            {mainLeader ? (
              <div className="categorized-leaders-list__main-leader-details">
                <AvatarMedia
                  className="categorized-leaders-list__leader-avatar categorized-leaders-list__leader-avatar--main"
                  {...mainLeader}
                />
              </div>
            ) : (
              <div className="categorized-leaders-list__empty-state">
                {formatMessage(messages.LeadersListEmptyState)}
              </div>
            )}
          </div>
          <div className="categorized-leaders-list__secondary-leaders">
            <Title className="text-raven" size={SUBSIZE}>
              {secondaryLeaderTitle}
            </Title>
            {evaluators?.length > MIN_LENGTH ? (
              <ul className="categorized-leaders-list__secondary-leaders-list">
                {evaluators?.length > USER_LENGTH ? (
                  evaluators?.map((leader, index) => (
                    <UsersListInfo
                      key={leader.id}
                      position={evaluators.length - index}
                      {...leader}
                    />
                  ))
                ) : (
                  <AvatarMedia
                    className="categorized-leaders-list__leader-avatar"
                    {...evaluators[0]}
                  />
                )}
              </ul>
            ) : (
              <div className="categorized-leaders-list__empty-state">
                {formatMessage(messages.LeadersListEmptyStatePlural)}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="categorized-leaders-list__empty-state">
          <p>{formatMessage(messages.LeadersListEmptyStatePlural)}</p>
        </div>
      )}
    </div>
  );
};

LeadersList.propTypes = {
  evaluators: PropTypes.array,
  handleChangeLeaderClick: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  mainLeader: PropTypes.object.isRequired,
  showOtherLeaders: PropTypes.bool,
};

LeadersList.defaultProps = {
  leaders: [],
};

export default injectIntl(LeadersList);
