export const isValidURL = (value) => {
  const base =
    '^(https?:\\/\\/)?((([a-zA-Z\\d]([a-zA-Z\\d-]{0,61}[a-zA-Z\\d])*\\.)+[a-zA-Z]{2,13})';
  const ipV4 = '|((\\d{1,3}\\.){3}\\d{1,3})';
  const localhost = '|localhost)';
  const port = '(\\:\\d{1,5})?';
  const path = '(\\/[a-zA-Z\\&\\d%_.~+-:@]*)*';
  const query = '(\\?[a-zA-Z\\&\\d%_.,~+-:@=;&]*)?';
  const fragment = '(\\#[-a-zA-Z&\\d_]*)?$';
  return new RegExp(
    base + ipV4 + localhost + port + path + query + fragment
  ).test(value);
};

export function objectToGetParams(object) {
  const params = Object.entries(object)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`
    );

  return params?.length ? `?${params.join('&')}` : '';
}

export function getRootURL(context = window.location) {
  const { host, protocol } = context;

  return `${protocol}//${host}`.toLowerCase();
}

export function isCurrentDomain(url) {
  const rootUrl = getRootURL();
  const parser = document.createElement('a');
  parser.href = url;
  return rootUrl === getRootURL(parser);
}
