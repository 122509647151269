// @packages
import React from 'react';
import { injectIntl } from 'react-intl';

// @app
import ActionButtons from 'components/molecules/ActionButtons';
import { actionsMessages } from 'common/messages';

const EvaluationsButtons = ({
  saveDraft: {
    isDraftVisible,
    isDraftDisabled,
    saveDraft,
  },
  deleteDraft: {
    isDeleteDraftVisible,
    deleteDraft,
  },
  sendEvaluation: {
    isCreationVisible,
    isSendEvaluationDisabled,
    sendEvaluation,
  },
  intl: { formatMessage },
  type,
}) => {
  function getLabelAction(type) {
    let labelPrimaryAction;
    switch (type) {
      case 'submitDraft':
        return labelPrimaryAction = formatMessage(actionsMessages.ActionsSubmit)

      case 'createEvaluation':
        return labelPrimaryAction = formatMessage(actionsMessages.ActionsCreate);

      default:
        return labelPrimaryAction;
    }
  };
  
  const sendEvaluationButtons = [
    {
      isVisible: isDeleteDraftVisible,
      label: formatMessage(actionsMessages.ActionsDeleteDraft),
      onClick: deleteDraft,
      variant: 'link',
    },
    {
      disabled:isDraftDisabled,
      isVisible: isDraftVisible,
      label: formatMessage(actionsMessages.ActionsSaveDraft),
      onClick: saveDraft,
      variant: 'outline',
    },
    {
      disabled: isSendEvaluationDisabled,
      isVisible: isCreationVisible,
      label: getLabelAction(type),
      onClick: sendEvaluation,
    },
  ];
  const actionButtons = sendEvaluationButtons.filter(button => button?.isVisible);
  return (
    <ActionButtons buttons={actionButtons} />
  );
};

export default injectIntl(EvaluationsButtons);
