import { trackEvent } from 'smu-utils/gtm';
const HELP = 'Help';

export const trackHelpClose = () => {
  trackEvent({
    category: HELP,
    action: 'Close',
  });
};

export const trackHelpSend = () => {
  trackEvent({
    category: HELP,
    action: 'Send',
  });
};
