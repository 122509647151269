import { handleServerError } from 'betterme-components/api';
import { apiInstance } from 'betterme-components/utils/requestHelper';

export function uploadFile(file) {
  const formData = new FormData();
  formData.append('file', file, file.name);

  return apiInstance
    .post('/api/files', formData)
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function deleteFile(fileId) {
  return apiInstance.delete(`/api/files/${fileId}`)
    .then(({ data }) => data)
    .catch(handleServerError);
}
