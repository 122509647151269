import { partial } from 'lodash';
import { all, fork } from 'redux-saga/effects';

import actionRequiredSaga from 'betterme-components/services/ReasonsActions/sagas';
import addLeaderRequestSaga from './services/AddToMyTeam/sagas';
import adminPassword from './services/AdminPassword/sagas';
import appSaga from './sagas';
import applicationsSagas from 'betterme-components/services/Applications/sagas'
import assessmentSagas from 'betterme-components/services/Assessment/sagas';
import authorizeSaga from './Authorization/sagas';
import awatingResultCounter from './MyDashboard/FeedbackForLeadersReport/sagas';
import changeLeaderRequest from 'betterme-components/services/ChangeLeaderRequest/sagas';
import changeLeaderRequestModal from 'betterme-components/ChangeLeaderRequestModal/sagas';
import communityConfigs from 'betterme-components/services/CommunityConfigs/sagas';
import communityInformation from 'betterme-components/services/Layout/sagas';
import dashboardSaga from './Dashboard/sagas';
import dashboardSuggestionsSaga from './Dashboard/DashboardSuggestions/sagas';
import evaluationDetailSaga from './EvaluationDetail/sagas';
import evaluationsSaga from './Evaluations/sagas';
import evaluationsTeamSaga from './services/TeamEvaluations/sagas';
import feedbackForLeaders from 'betterme-components/services/FeedbackForLeaders/sagas';
import feedbackForLeadersReceivedSaga from './FeedbackForLeadersReceived/sagas';
import feedbackReceivedSaga from './FeedbackReceived/sagas';
import feedbackSaga from './SendRequestFeedback/sagas';
import feedbackViewSaga from './FeedbackView/sagas';
import file from 'common/File/sagas';
import fileFeedback from './services/FileFeedback/sagas';
import fileGoal from './services/FileGoals/sagas';
import getChangeLeaderDismissReasonSaga from 'betterme-components/services/ChangeLeaderRequestAcceptDecline/sagas';
import goalDetail from 'common/GoalDetail/sagas';
import goalsAssigned from 'common/GoalsAssigned/sagas';
import goalsSaga from './Goals/sagas';
import gotItSaga from 'betterme-components/services/GotIt/sagas';
import layoutSaga from 'common/Layout/sagas';
import leaderActionsSaga from './LeadershipRequest/sagas';
import leadersByPeriod from './FeedbackForLeadersSelectionModal/sagas';
import leadershipAdmin from './services/LeadershipAdmin/sagas';
import leadershipRequest from './services/LeadershipRequest/sagas';
import leadershipRequestReceivedSaga from 'betterme-components/services/LeadershipRequestReceived/sagas';
import leadershipRequestSentSaga from 'betterme-components/services/LeadershipRequestSent/sagas';
import myFeedbacksSaga from './MyFeedbacks/sagas';
import notificationsFeedbackSaga from './Notifications/sagas';
import notificationsHistory from 'betterme-components/NotificationsHistory/sagas';
import performanceRetrospectiveDetailSaga from './services/PerformanceRetrospectiveDetail/sagas';
import profileSaga from './Profile/sagas';
import promotionRequestsHistory from './PromotionsHistoryTable/sagas';
import promotionsReport from './MyTeam/sagas';
import potentialAssessmentResults from './services/PotentialAssessmentResults/sagas'
import removeLeaderModal from 'betterme-components/services/RemoveLeader/sagas';
import requestFeedbackSaga from './FeedbackRequest/sagas';
import requests from 'smu-utils/reduxRequests/sagas';
import sendFeedbackForLeaders from './SendFeedbackForLeaders/sagas';
import skillSelectorSaga from './SkillSelectorModal/sagas';
import skillsRated from 'common/SkillsRated/sagas';
import starmeUpDNAWidgetSaga from 'betterme-components/services/StarmeUpDNAWidget/sagas';
import suggestActionsSaga from './SuggestActions/sagas';
import suggestPromotionSaga from './SendSuggestPromotion/sagas';
import suggestedActionsListSaga from './SuggestedActionsList/sagas';
import teamDashboardSaga from './Team/sagas';
import teamFeedbacksSaga from './TeamFeedbacks/sagas';
import teamPromotions from './Team/TeamPromotionsTab/sagas';
import teaminfoWASaga from './Team/Team-Service-Workaround/sagas';
import technicalAssessment from 'betterme-components/services/TechnicalAssessment/sagas';
import tellUsAboutModalSaga from './TellUsAboutModal/sagas';
import topicsRated from 'common/TopicsRated/sagas';
import userInfoSaga from 'betterme-components/services/UserInformation/sagas';
import userPromotionRequestsGroup from './PromotionsHistoryScreen/sagas';
import userSaga from './common/User/sagas';
import updateSubmitEvaluation from 'betterme-components/services/EvaluationsUpdateSubmit/sagas';
import createSaveEvaluation from 'betterme-components/services/EvaluationsCreateSave/sagas';
import getTierType from 'betterme-components/services/GetTierType/sagas';
import getSurveyQuestionsAndRates from 'betterme-components/services/GetSurveyAndRates/sagas';
import uploadFiles from 'betterme-components/services/UploadFiles/sagas';
import inputData from 'betterme-components/services/PerformanceRetrospectiveInput/sagas';
import searchBar from 'betterme-components/services/SearchBar/sagas';
import selfEvaluationList from 'betterme-components/services/SelfEvaluationWidget/sagas';
import periodByType from 'betterme-components/services/GetPeriodByType/sagas';

import * as intlSagas from './IntlProvider/sagas';
import * as notificationsSaga from 'smu-app-components/Notifications/sagas';
import * as notificationsV2Saga from 'smu-app-components/NotificationsV2/sagas';
import * as searchActions from 'smu-app-components/Search/actions';
import searchSaga from 'smu-app-components/Search/sagas';
import starmeUpWidgetContainerSaga from 'smu-app-components/StarmeUpWidgetContainer/sagas';
import stellarSaga from 'smu-app-components/StellarWidget/sagas';
import suggestionsSaga from 'smu-app-components/Suggestions/sagas';
import toastNotificationsSaga from 'smu-app-components/ToastNotifications/sagas';

export default function* rootSaga() {
  yield all([
    ...Object.values(intlSagas),
    ...Object.values(notificationsFeedbackSaga),
    ...Object.values(notificationsHistory),
    ...Object.values(notificationsSaga),
    ...Object.values(notificationsV2Saga),
    ...Object.values(starmeUpDNAWidgetSaga),
    actionRequiredSaga,
    addLeaderRequestSaga,
    adminPassword,
    appSaga,
    applicationsSagas,
    assessmentSagas,
    authorizeSaga,
    awatingResultCounter,
    changeLeaderRequest,
    changeLeaderRequestModal,
    communityConfigs,
    communityInformation,
    createSaveEvaluation,
    dashboardSaga,
    dashboardSuggestionsSaga,
    evaluationDetailSaga,
    evaluationsSaga,
    evaluationsTeamSaga,
    feedbackForLeaders,
    feedbackForLeadersReceivedSaga,
    feedbackReceivedSaga,
    feedbackSaga,
    feedbackViewSaga,
    file,
    fileFeedback,
    fileGoal,
    getChangeLeaderDismissReasonSaga,
    getSurveyQuestionsAndRates,
    getTierType,
    goalDetail,
    goalsAssigned,
    goalsSaga,
    gotItSaga,
    inputData,
    layoutSaga,
    leaderActionsSaga,
    leadersByPeriod,
    leadershipAdmin,
    leadershipRequest,
    leadershipRequestReceivedSaga,
    leadershipRequestSentSaga,
    myFeedbacksSaga,
    partial(searchSaga, searchActions),
    performanceRetrospectiveDetailSaga,
    periodByType,
    profileSaga,
    promotionRequestsHistory,
    promotionsReport,
    potentialAssessmentResults,
    removeLeaderModal,
    requestFeedbackSaga,
    requests,
    searchBar,
    selfEvaluationList,
    sendFeedbackForLeaders,
    skillSelectorSaga,
    skillsRated,
    starmeUpWidgetContainerSaga,
    stellarSaga,
    suggestActionsSaga,
    suggestPromotionSaga,
    suggestedActionsListSaga,
    suggestionsSaga,
    teamDashboardSaga,
    teamFeedbacksSaga,
    teamPromotions,
    teaminfoWASaga,
    technicalAssessment,
    tellUsAboutModalSaga,
    toastNotificationsSaga,
    topicsRated,
    userInfoSaga,
    userPromotionRequestsGroup,
    updateSubmitEvaluation,
    uploadFiles,
    userSaga,
  ].map(fork));
}
