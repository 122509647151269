// @packages
import cn from 'classnames';
import { orderBy } from 'lodash';
import React from 'react';
import Link from 'smu-ui-components/Link';
import PropTypes from 'prop-types';


// @app
import PieChart from 'common/PieChart';

// @own
import './styles.scss';
import RatingCountList from './RatingCountList';

const FeedbackReceivedPie = ({
  className,
  emptyLinkLabel,
  emptyMessage,
  linkTo,
  onLinkClick,
  pieLabel,
  pieSize,
  pieStrokeSize,
  ratings,
  showLink,
  small,
}) => {
  const orderedRatings = orderBy(ratings, ['ordinality'], ['desc']);
  const totalRatings = orderedRatings.reduce((acc, { count }) => acc + count, 0);

  return (
    <div className={cn('feedback-received-pie', className, {
      'feedback-received-pie--small': small,
    })}>
      {(totalRatings > 0 || !emptyMessage) ? (
        <React.Fragment>
          <PieChart
            className="feedback-received-pie__pie"
            data={orderedRatings}
            size={pieSize}
            strokeSize={pieStrokeSize}
            tooltip
          >
            <div className="feedback-received-pie__label-total">
              {totalRatings}
            </div>
            <span className="feedback-received-pie__label-text">
              {pieLabel}
            </span>
          </PieChart>
          <RatingCountList
            className="feedback-received-pie__rating-list"
            ratedCompetencies={orderedRatings}
          />
        </React.Fragment>
      ) : (
        <div className="feedback-received-pie__empty">
          <strong className="feedback-received-pie__empty-message">
            {emptyMessage}
          </strong>
          {emptyLinkLabel && showLink && (
            <Link
              className="feedback-received-pie__empty-request"
              onClick={onLinkClick}
              to={linkTo}
            >
              {emptyLinkLabel}
            </Link>
          )}
        </div>
      )}
    </div>
  );
};

FeedbackReceivedPie.defaultProps = {
  pieSize: 158,
  pieStrokeSize: 22,
  pieLabel: 'ratings',
};

FeedbackReceivedPie.propTypes = {
  className: PropTypes.string,
  emptyLinkLabel: PropTypes.node,
  emptyMessage: PropTypes.string,
  linkTo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  onLinkClick: PropTypes.func,
  pieLabel: PropTypes.string,
  pieSize: PropTypes.number,
  pieStrokeSize: PropTypes.number,
  ratings: PropTypes.arrayOf(PropTypes.shape({
    count: PropTypes.number,
  })).isRequired,
  small: PropTypes.bool,
};

export default FeedbackReceivedPie;
