// @packages
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SelectUsers from 'smu-app-components/Selects/Users';
import { connect } from 'react-redux';

// @app
import {
  changeSelectedUser,
  resetSelectedUser,
} from 'betterme-components/services/SearchBar/actions';
import { selectSelectedUser } from 'betterme-components/services/SearchBar/selectors';
import { selectSelectedCollaborators } from 'betterme-components/SendRequestFeedback/selectors';

function SelectColleagues({
  allowMultipleSelection,
  changeSelectedUser,
  resetSelectedUser,
  selectedUser,
  selectedUsers,
}) {
  const excludedUsers = selectedUsers.map((u) => u?.id);
  const colleaguesIds = allowMultipleSelection
    ? selectedUser?.map((u) => u?.id)
    : selectedUser?.[0]?.id;

  const handleChangeColleagues = (_, users) => {
    if (allowMultipleSelection) {
      changeSelectedUser(users);
    } else {
      changeSelectedUser(users ? [users] : []);
    }
  };

  useEffect(() => {
    return () => {
      resetSelectedUser();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SelectUsers
      className="mt-2"
      excludedUsers={excludedUsers}
      isClearable
      isMultiple={allowMultipleSelection}
      onChange={handleChangeColleagues}
      value={colleaguesIds}
    />
  );
}

SelectColleagues.propTypes = {
  allowMultipleSelection: PropTypes.bool,
  changeSelectedUser: PropTypes.func,
  resetSelectedUser: PropTypes.func,
  selectedUser: PropTypes.object,
  selectedUsers: PropTypes.array,
};

const mapStateToProps = (state) => ({
  selectedUser: selectSelectedUser(state),
  selectedUsers: selectSelectedCollaborators(state),
});

const mapDispatchToProps = {
  changeSelectedUser,
  resetSelectedUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectColleagues);
