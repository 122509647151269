// @packages
import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Icon from 'smu-ui-components/Icon';

// @own
import './styles.scss';
import messages from '../messages';
import GoalProgressItem from './GoalProgressItem';

const GoalProgressList = ({
  className,
  goalsData,
  goalsProgressData: {
    closed,
    total,
  },
  intl: { formatMessage },
}) => {

  return (
    <div className={cn('goal-progress-list', className)}>
      {goalsData
        .map((progress, index) => <GoalProgressItem
          achieved={index === 0}
          className="goal-progress-list__item"
          key={index}
          progress={progress}
        />)}
      <div className="goal-progress-list__completed">
        <Icon icon="play" color="grey" size={8} />
        <strong className="goal-progress-list__completed-values">{closed}/{total}</strong>
        {formatMessage(messages.FeedbackDashboardGoalsCompleted)}
      </div>
    </div>
  );
};

GoalProgressList.propTypes = {
  className: PropTypes.string,
  goalsData: PropTypes.arrayOf(PropTypes.number).isRequired,
  goalsProgressData: PropTypes.shape({
    closed: PropTypes.number,
    total: PropTypes.number,
  }).isRequired,
};

export default injectIntl(GoalProgressList);
