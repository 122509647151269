// @packages
import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Image from 'smu-ui-components/Image';
import TextWrapper from 'smu-ui-components/TextWrapper';

// @app
import PieChart from 'common/PieChart';

// @own
import './styles.scss';

const FeedbackReceivedSummary = ({
  className,
  name,
  ratings,
  isSkillDetail,
}) => {
  const totalRatings = ratings.reduce((acc, { count }) => acc + count, 0);
  return (
    <div className={cn('feedback-received-summary', className)}>
      <TextWrapper
        as="h4"
        className={cn('feedback-received-summary__name', {
          'feedback-received-summary__name-skill': isSkillDetail
        })}
        ellipsis
      >
        {name}
      </TextWrapper>
      <ul className="feedback-received-summary__ratings">
        {ratings.map((rating) => {
          const style = { color: rating.color };
          return (
            <li
              className="feedback-received-summary__rating"
              key={rating.id}
              style={style}
            >
              <div className="feedback-received-summary__rating-image-wrapper">
                <Image className="feedback-received-summary__rating-image"
                  code={rating.imageCode || 'default'}
                  height={20}
                  style={{ backgroundColor: rating.color }}
                  width={20}
                />
              </div>
              <div className="feedback-received-summary__rating-count"
                style={{ color: rating.color }}
              >
                {rating.count}
              </div>
            </li>
          );
        })}
      </ul>
      <PieChart
        className="feedback-received-summary__pie-chart"
        data={ratings}
        size={105}
        strokeSize={18}
      >
        <span className="feedback-received-summary__pie-label">{totalRatings}</span>
      </PieChart>
    </div>
  );
};

FeedbackReceivedSummary.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  ratings: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    imageCode: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  }))
};

export default FeedbackReceivedSummary;
