// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import cn from 'classnames';

// @app
import { formatOrgEntryDate } from 'utils/formatOrgEntryDate';

const AgingDate = ({
	className,
	date,
	intl: { formatMessage },
}) => {
	const arrayAgingDate = date && formatOrgEntryDate(date);
	const currentSeniorityAgingDate =
		arrayAgingDate?.map((item) => `${item.value} ${formatMessage(item.key)}`).join(" ");
	return (
		<span className={cn('aging-date', className)}>{currentSeniorityAgingDate}</span>
	)
}

AgingDate.propTypes = {
	className: PropTypes.string,
	date: PropTypes.number.isRequired,
	intl: PropTypes.object.isRequired,
}

export default injectIntl(AgingDate);
