// @packages
import { createSelector } from 'reselect';
import { get, orderBy, pick, uniqBy } from 'lodash';

// @app
import { selectRatings } from 'betterme-components/services/Layout/selectors';

export const selectTopicsRatedDomain = (state) => state.topicsRated;

export const selectRatedTopics = createSelector(
  selectTopicsRatedDomain,
  (state) => state.ratedTopics,
);

export const selectRatedCompetencies = createSelector(
  selectRatedTopics,
  (ratedTopics) => ratedTopics.map(ratedTopic => pick(ratedTopic, ['description', 'id', 'name']))
);

export const selectCommunityRatings = createSelector(
  selectRatings,
  (ratings) => orderBy(ratings, ['ordinality'], ['desc'])
);

export const selectRatedCompetenciesName = createSelector(
  selectRatedTopics,
  (ratedTopics) => ratedTopics.map(ratedTopic => ratedTopic.name)
);

export const selectCompetenciesStacked = createSelector(
  selectRatedTopics,
  selectCommunityRatings,
  (ratedTopics, communityRatings) => {
    const competenciesStacked = communityRatings.map(rating => {
      const ratingInfo = pick(rating, ['color', 'id', 'name']);
      const data = ratedTopics.map(ratedTopic => {
        const ratings = ratedTopic.ratings.find(({ id }) => id === rating.id);
        const count = get(ratings, 'count', 0);
        return count;
      });
      return { ...ratingInfo, data, showInLegend: false };
    });
    return competenciesStacked;
  }
);

export const selectSelectedCompetencyId = createSelector(
  selectTopicsRatedDomain,
  (state) => state.selectedCompetencyId,
);

export const selectSelectedCompetency = createSelector(
  selectRatedTopics,
  selectSelectedCompetencyId,
  (ratedTopics, selectedCompetencyId) =>
    ratedTopics.find(ratedTopic => ratedTopic.id === selectedCompetencyId)
);

export const selectSelectedRating = createSelector(
  selectTopicsRatedDomain,
  (state) => state.selectedRating,
);

export const selectCompetencySummary = createSelector(
  selectSelectedCompetency,
  selectCommunityRatings,
  (selectedCompetency, communityRatings) => {
    if (!selectedCompetency) {
      return [];
    }

    const { ratings } = selectedCompetency;

    const ratingsSummary = communityRatings.map((rating) => {
      const topicRating = ratings.find(({ id }) => id === rating.id);
      const count = get(topicRating, 'count', 0);
      const ratingInfo = pick(rating, ['color', 'id', 'imageCode', 'name']);
      return { ...ratingInfo, count };
    });

    return ratingsSummary;
  }
);

export const selectRatedCompetenciesDetails = createSelector(
  selectSelectedCompetency,
  selectCommunityRatings,
  (selectedCompetency, communityRatings) => {
    if (!selectedCompetency) {
      return [];
    }

    const { ratings } = selectedCompetency;
    const ratedCompetenciesDetails = communityRatings.map((rating) => {
      const ratingWithFeedbacks = ratings.find(({ id }) => id === rating.id);
      let userFeedbacks;

      if (ratingWithFeedbacks) {
        const { feedback } = ratingWithFeedbacks;
        const userList = uniqBy(feedback, 'senderId')
          .map(user =>
            pick(user, ['job', 'profileImageCode', 'senderId', 'senderIdentification', 'senderName'])
          );
        userFeedbacks = userList.map((user) => {
          const feedbackListRaw = feedback.filter(({ senderId }) => senderId === user.senderId);
          const feedbackList = feedbackListRaw.map(feedbackRaw =>
            pick(feedbackRaw, ['audioDuration', 'audioUrl', 'comment', 'creationTime', 'id']));
          return { user, feedbackList };
        });
      } else {
        userFeedbacks = [];
      }

      return { ratingId: rating.id, userFeedbacks };
    });

    return ratedCompetenciesDetails;
  }
);

export const selectRatedCompetencyDetails = createSelector(
  selectRatedCompetenciesDetails,
  selectSelectedRating,
  (ratedCompetenciesDetails, selectedRating) => {
    const ratingUserFeedbacks = ratedCompetenciesDetails
      .find(competecyDetails => competecyDetails.ratingId === selectedRating);
    return get(ratingUserFeedbacks, 'userFeedbacks');
  }
);

export const selectTopicsRatedLoading = createSelector(
  selectTopicsRatedDomain,
  (state) => state.loading,
);

export const selectTopicsRatedOrder = createSelector(
  selectTopicsRatedDomain,
  (state) => state.order,
);
