import {
  INSERT_FILES,
  RESET_FILES,
  DELETE_FILES_FAIL,
  DELETE_FILES_SUCCESS,
  DELETE_FILES,
  DRAFT_FILES,
  UPLOAD_FILES_FAIL,
  UPLOAD_FILES_SUCCESS,
  UPLOAD_FILES,
} from './actionTypes';

const initialState = {
  files: [],
  filesError: '',
  filesLoading: false,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case DELETE_FILES:
    case UPLOAD_FILES:
      return {
        ...state,
        filesLoading: true,
      };

    case DELETE_FILES_FAIL:
    case UPLOAD_FILES_FAIL:
      return {
        ...state,
        filesError: payload.error,
        filesLoading: false,
      };

    case DRAFT_FILES:
      return {
        ...state,
        files: state?.files?.filter((f) => f.id !== payload),
      };

    case DELETE_FILES_SUCCESS:
      return {
        ...state,
        files: state?.files?.filter(f => f.id !== payload.id),
        filesLoading: false,
      };

    case UPLOAD_FILES_SUCCESS:
      return {
        ...state,
        files: [...state?.files, payload?.file],
        filesLoading: false,
      };

    case RESET_FILES:
      return initialState;

    case INSERT_FILES:
      return {
        ...state,
        files: payload,
        filesLoading: false,
      };

    default:
      return state;
  }
}
