// @package
import React from 'react';
import { injectIntl } from 'react-intl';

// @app
import Incognito from 'betterme-components/assets/incognito.png';
import { feedbackForLeadersMessages } from 'betterme-components/common/messages';

// @own
import './styles.scss';

const AnonymousEvaluation = ({
  intl: { formatMessage },
}) => (
  <div className="anonymous-evaluation">
    <img className="anonymous-evaluation__image" src={Incognito} />
    <div className="anonymous-evaluation__text">
      {formatMessage(feedbackForLeadersMessages.FeedbackForLeadersAnonymousMessage)}
    </div>
  </div>
);

export default injectIntl(AnonymousEvaluation);
