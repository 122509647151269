// @packages
import React from 'react';
import Card from 'smu-ui-components/Card';
import CardTitle from 'smu-ui-components/Card/CardTitle';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

// @app
import SelectCollaborators from 'betterme-components/SendRequestFeedback/SelectCollaborators';
import SelectColleagues from 'betterme-components/SendRequestFeedback/SelectColleagues';
import { REQUEST_ON_BEHALF } from 'betterme-components/SendRequestFeedback/constants';

// @own
import { TYPE_MESSAGES } from './constants';

const UserSelector = ({
  allowMultipleSelection,
  intl: { formatMessage },
  showSelectorCollaborators,
  type,
}) => {
  const messageType = showSelectorCollaborators ? REQUEST_ON_BEHALF : type;

  return (
    <>
      {showSelectorCollaborators && (
        <Card className="flex flex-col p-6" shadow>
          <CardTitle>
            {formatMessage(TYPE_MESSAGES[messageType].collaboratorsTitle)}
          </CardTitle>
          <SelectCollaborators className="mt-2" />
        </Card>
      )}
      <Card className="flex flex-col p-6" shadow>
        <CardTitle>
          {formatMessage(TYPE_MESSAGES[messageType].colleaguesTitle)}
        </CardTitle>
        <SelectColleagues
          allowMultipleSelection={allowMultipleSelection}
          className="mt-2"
        />
      </Card>
    </>
  );
};

UserSelector.propTypes = {
  allowMultipleSelection: PropTypes.bool,
  showSelectorCollaborators: PropTypes.bool,
  type: PropTypes.string.isRequired,
};

export default injectIntl(UserSelector);
