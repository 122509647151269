// @packages
import trim from 'lodash/trim';

/**
 * Get rated topics by competency
 * @param {Array} ratedTopics
 * @returns {Object}
 */
export const getRatedTopicsByCompetency = (ratedTopics) => {
  return ratedTopics?.reduce((acc, cur) => {
    acc[cur.competency.id] = cur.rateId;
    return acc;
  }, {});
};

/**
 * Normalize competency
 * @param {Object} competency
 * @returns {Object}
 */
export const normalizeChangeSkill = (skill) => {
  return {
    description: trim(skill?.description),
    id: skill?.id,
    name: trim(skill?.name),
    skill: true,
    suggested: skill?.suggested,
  };
};

/**
 * Normalize change skills
 * @param {Array} skills
 * @returns {Array}
 */
export const normalizeChangeSkills = (skills) => {
  const output = skills?.map(normalizeChangeSkill);
  return output;
};

/**
 * Normalize value skills
 * @param {Array} skills
 * @param {Object} rated
 * @returns {Array}
 */
export const normalizeValueSkills = (skills, rated) => {
  return skills?.map((s) => ({
    description: s?.description,
    id: s?.id,
    name: s?.name,
    rating: rated?.[s?.id],
    suggested: s?.suggested,
  }));
};
