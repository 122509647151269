// @packages
import React from 'react';
import PropTypes from 'prop-types';
import Segment from 'smu-ui-components/Segment';

// @app
import CallToActionsContainer from 'components/containers/CallToActions';
import TeamInformation from 'betterme-components/TeamInformation';
import UserProfile from 'betterme-components/UserProfile';

// @own
import './styles.scss';

const Sidenav = ({
  actionsList,
  allowsGlbFeatures,
  collaboratorsByLeader,
  collaboratorsListTitle,
  enableChangeProfileImage,
  goToLeadershipAdmin,
  hasCollaborators,
  loading,
  newWordingForLeaders,
  onClickAction,
  owner,
  showChangeLeader,
  showLeaderWidget,
  showLeadershipButton,
  showOtherLeaders,
  user,
}) => (
  <Segment className="sidenav" loading={loading} loadingType="betterme">
    <div className="sidenav__content">
      <UserProfile
        actionsList={actionsList}
        enableChangeProfileImage={enableChangeProfileImage}
        owner={owner}
        userId={user?.id}
      />
      <CallToActionsContainer onClickAction={onClickAction} />
      <TeamInformation
        allowsGlbFeatures={allowsGlbFeatures}
        collaboratorsByLeader={collaboratorsByLeader}
        collaboratorsListTitle={collaboratorsListTitle}
        goToLeadershipAdmin={goToLeadershipAdmin}
        hasCollaborators={hasCollaborators}
        newWordingForLeaders={newWordingForLeaders}
        showChangeLeader={showChangeLeader}
        showLeaderWidget={showLeaderWidget}
        showLeadershipButton={showLeadershipButton}
        showOtherLeaders={showOtherLeaders}
        user={user}
      />
    </div>
  </Segment>
);

Sidenav.defaultProps = {
  actionsList: [],
};

Sidenav.propTypes = {
  actionsList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    })
  ),
  allowsGlbFeatures: PropTypes.bool,
  collaboratorsByLeader: PropTypes.object.isRequired,
  collaboratorsListTitle: PropTypes.string,
  enableChangeProfileImage: PropTypes.bool,
  goToLeadershipAdmin: PropTypes.func,
  hasCollaborators: PropTypes.bool,
  loading: PropTypes.bool,
  newWordingForLeaders: PropTypes.bool,
  onClickAction: PropTypes.func,
  owner: PropTypes.bool,
  showChangeLeader: PropTypes.bool,
  showLeaderWidget: PropTypes.bool,
  showLeadershipButton: PropTypes.bool,
  user: PropTypes.object.isRequired,
};

export default Sidenav;
