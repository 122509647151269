// @packages
import React from 'react';
import Page404 from 'smu-layout-components/Page404';
import { Route, IndexRedirect, Redirect } from 'react-router';

// @app
import AddMember from 'components/pages/AddMember';
import App from './App';
import Assessment from 'components/pages/Assessment';
import AssessmentHomePage from 'components/pages/AssessmentHomePage';
import Authorization from './Authorization';
import EngagementIndicatorForm from './Profile/EngagementIndicatorForm';
import EvaluationDetail from './EvaluationDetail';
import ExternalLinkLayout from './Layouts/ExternalLink';
import FeedbackRequestsInbox from './FeedbackRequest/FeedbackRequestsInbox';
import FeedbackView from './FeedbackView';
import GoalsRouteWrapper from './Goals/GoalsRouteWrapper';
import Home from './Home';
import Layout from './Layout';
import LayoutAs360Create from './Layouts/Assessment360/Create';
import LayoutAs360Results from './Layouts/Assessment360/Results';
import LeadershipAdmin from 'components/pages/LeadershipAdmin';
import LeadershipRequestsSection from './components/pages/LeadershipRequestSection';
import LeadershipSubstitution from 'components/pages/LeadershipSubstitution';
import ManageLeaders from 'components/pages/ManageLeaders';
import ManageLeadersAdmin from 'components/pages/ManageLeadersAdmin';
import ManageLeadersSubstitution from 'components/pages/ManageLeadersSubstitution';
import Me from './Me';
import MyAssignedGoals from './MyAssignedGoals';
import MyDashboard from './MyDashboard';
import MyEvaluations from './MyEvaluations';
import MyFeedbackForLeadersReceived from './MyFeedbackForLeadersReceived';
import MyFeedbackReceived from './MyFeedbackReceived';
import MyFeedbacks from './MyFeedbacks';
import MyGoalDetail from './Goals/MyGoalDetail';
import MyGoals from './Goals/MyGoals';
import MySidenav from './MySidenav';
import MyTeam from './MyTeam';
import MyTeamDashboardLayout from './Layouts/MyTeam/Dashboard';
import MyTeamEvaluationsLayout from './Layouts/MyTeam/Evaluations';
import MyTeamEvaluationsTalentMatrixLayout from './Layouts/MyTeam/Evaluations/TalentMatrix';
import MyTeamFeedbacksLayout from './Layouts/MyTeam/Feedbacks';
import MyTeamGoalDetail from './Goals/MyTeamGoalDetail';
import MyTeamGoalsLayout from './Layouts/MyTeam/Goals';
import MyTeamPromotionsLayout from './Layouts/MyTeam/Promotions';
import NotificationHistory from 'betterme-components/NotificationsHistory';
import OneOnOneDetailLayout from './Layouts/OneOnOne/Detail';
import OneOnOneHomeLayout from './Layouts/OneOnOne/Home';
import PerformanceRetrospective from 'components/pages/PerformanceRetrospective';
import PerformanceRetrospectiveDetail from 'components/pages/PerformanceRetrospectiveDetail';
import PerformanceRetrospectiveInput from 'components/pages/PerformanceRetrospectiveInput';
import PeriodEvaluation from 'components/pages/PeriodEvaluation';
import PotentialAssessmentResults from 'components/pages/PotentialAssessmentResults';
import Profile from './Profile';
import ProfileAssignedGoals from './Profile/ProfileAssignedGoals';
import ProfileDashboard from './Profile/ProfileDashboard';
import ProfileEvaluations from './Profile/ProfileEvaluations';
import ProfileEvaluationsDetail from './Profile/ProfileEvaluationsDetail';
import ProfileFeedback from './Profile/Feedback';
import ProfileFeedbackForLeadersReceived from './Profile/ProfileFeedbackForLeadersReceived';
import ProfileFeedbackReceived from './Profile/ProfileFeedbackReceived';
import PromotionsHistoryScreen from './PromotionsHistoryScreen';
import Questionnaire from './Layouts/Questionnaire';
import QuestionnaireHome from './Layouts/QuestionnaireHome';
import QuestionnaireResults from './Layouts/QuestionnaireResults';
import Questionnaires from './Layouts/Questionnaires';
import RedirectLayout from './Layouts/Redirect';
import RightColumn from './RightColumn';
import SelfRetrospective from 'components/pages/SelfRetrospective';
import SelfRetrospectiveDetail from './SelfRetrospectiveDetail';
import SendFeedbackForLeaders from './SendFeedbackForLeaders';
import SendRequestFeedback from './SendRequestFeedback';
import SendSuggestPromotion from './SendSuggestPromotion';
import TechnicalRetrospectivesLayout from './Layouts/TechnicalRetrospectives';
import UserSettingsPage from 'components/pages/UserSettings';
import appLogo from './assets/Better-me-logo-black.svg';
import { authorizeRoutes } from './Authorization/authorizeRoutes';

const createRoutes = (store) => (
  <Route path="/" component={App}>
    <IndexRedirect to="/myprofile" />
    <Route component={Authorization(Layout)} onEnter={authorizeRoutes(store)}>
      <Redirect from="team" to="myteam" />
      <Route path="/home" component={Home} />
      <Route path="/home/questionnaire/:questionnaireId" component={QuestionnaireHome} />
      <Route path="/me" component={Me} />
      <Route path="feedback" components={{ mainContent: MyFeedbacks, Sidenav: MySidenav, RightColumn: RightColumn }} />
      <Route path="feedback/:feedbackid" components={{ mainContent: FeedbackView, Sidenav: MySidenav, RightColumn: RightColumn }} />
      <Route path="feedbackrequests" component={FeedbackRequestsInbox} />
      <Route path="replyfeedback/:requestId" component={(props) => <SendRequestFeedback {...props} reply />} />
      <Route path="newfeedback/:userId" component={SendRequestFeedback} />
      <Route path="newfeedbackrequest/:userId" component={(props) => <SendRequestFeedback {...props} defaultRequest />} />
      <Route path="newfeedbacksend" component={(props) => <SendRequestFeedback {...props} />} />
      <Route path="newfeedbackrequest" component={(props) => <SendRequestFeedback {...props} defaultRequest />} />
      <Route path="newfeedbackonbehalfrequest" component={(props) => <SendRequestFeedback {...props} defaultRequestOnBehalf />} />
      <Route path="newfeedbackonbehalfrequest/:userId" component={(props) => <SendRequestFeedback {...props} defaultRequestOnBehalf />} />
      <Route path="goals" component={GoalsRouteWrapper}>
        <IndexRedirect to="mygoals" />
        <Route path="mygoals" components={{ mainContent: MyGoals, Sidenav: MySidenav, RightColumn: RightColumn }} />
        <Route path="mygoals/:goalid" components={{ mainContent: MyGoalDetail }} />
      </Route>
      <Route path="myprofile" components={{ mainContent: MyDashboard, Sidenav: MySidenav }} />
      <Route path="myprofile/assignedgoals" component={MyAssignedGoals} />
      <Route path="myprofile/feedback-for-leaders-received/:half" component={MyFeedbackForLeadersReceived} />
      <Route path="myprofile/feedbackreceived" component={MyFeedbackReceived} />
      <Route path="myteam" component={MyTeam}>
        <IndexRedirect to="dashboard" />
        <Route path="goals/:goalid" components={{ mainContent: MyTeamGoalDetail }} />
      </Route>
      <Route path="myteam/dashboard" component={MyTeamDashboardLayout} />
      <Route path="myteam/feedbacks" component={MyTeamFeedbacksLayout} />
      <Route path="myteam/goals" component={MyTeamGoalsLayout} />
      <Route path="myteam/evaluations" component={MyTeamEvaluationsLayout} />
      <Route path="myteam/evaluations/talent-matrix" component={MyTeamEvaluationsTalentMatrixLayout} />
      <Route path="myteam/evaluations/:evaluationid" component={EvaluationDetail} />
      <Route path="myteam/promotions" component={MyTeamPromotionsLayout} />
      <Route path="myteam/promotions/:promotionid" component={PromotionsHistoryScreen} />
      <Route path="engagement/:userId" component={EngagementIndicatorForm} />
      <Route path="evaluations" components={{ mainContent: MyEvaluations, Sidenav: MySidenav, RightColumn: RightColumn }} />
      <Route path="evaluations/:evaluationid" component={EvaluationDetail} />
      <Route path="profile/:userid" component={Profile}>
        <IndexRedirect to="dashboard" />
        <Route path="feedback" component={ProfileFeedback} />
        <Route path="dashboard" component={ProfileDashboard} />
        <Route path="dashboard/assignedgoals" component={ProfileAssignedGoals} />
        <Route path="dashboard/feedback-for-leaders-received/:half" component={ProfileFeedbackForLeadersReceived} />
        <Route path="dashboard/feedback-for-leaders-received/:year/:half" component={ProfileFeedbackForLeadersReceived} />
        <Route path="dashboard/feedbackreceived" component={ProfileFeedbackReceived} />
        <Route path="dashboard/potential-assessment-results" component={PotentialAssessmentResults} />
        <Route path="evaluations" component={ProfileEvaluations} />
        <Route path="evaluations/:evaluationid" component={ProfileEvaluationsDetail} />
      </Route>
      <Route path="leadership-admin/:userId" component={LeadershipAdmin} />
      <Route path="manage-leaders" component={ManageLeaders} />
      <Route path="manage-leaders/leadership-admin/:userId" component={ManageLeadersAdmin} />
      <Route path="manage-leaders/:identification/leaders-substitution" component={ManageLeadersSubstitution} />
      <Route path="add-team-member" component={AddMember} />
      <Route path="leadership-admin/:userId/leaders-substitution" component={LeadershipSubstitution} />
      <Route path="new-promotion/:userId" component={SendSuggestPromotion} />
      <Route path="promotion-validate-request/:userId" component={SendSuggestPromotion} />
      <Route path="new-feedback-for-leaders/:userId" component={SendFeedbackForLeaders} />
      <Route path="/leadership-request" component={LeadershipRequestsSection} />
      <Route path="/behaviour-assessment" component={AssessmentHomePage} />
      <Route path="/behaviour-assessment/:userId" component={Assessment} />
      <Route path="/period-evaluation/:userId(/:evaluationId)" component={PeriodEvaluation} />
      <Route path="/notifications" component={NotificationHistory} />
      <Route path="1on1" component={OneOnOneHomeLayout} />
      <Route path="1on1/detail/:id" component={OneOnOneDetailLayout} />
      <Route path="performance-retrospective-detail/:evaluationid" component={PerformanceRetrospectiveDetail} />
      <Route path="myteam/performance-retrospective-detail/:evaluationid" component={PerformanceRetrospectiveDetail} />
      <Route path="/self-retrospective/:userIdentification(/:evaluationId)" component={SelfRetrospective} />
      <Route path="/performance-retrospective(/:evaluationId)" component={PerformanceRetrospective} />
      <Route path="/performance-retrospective-input/:evaluationId" component={PerformanceRetrospectiveInput} />
      <Route path="evaluations/self-retrospective/:evaluationid" component={SelfRetrospectiveDetail} />
      <Route path="/settings/account" component={UserSettingsPage} />
      <Route path="technical-retrospective" component={TechnicalRetrospectivesLayout} />
      <Route path="/questionnaire/:questionnaireId" component={Questionnaire} />
      <Route path="/questionnaire/:questionnaireId/results" component={QuestionnaireResults} />
      <Route path="/questionnaires" component={Questionnaires} />
      <Route path="/assessment360/create/:type/:userId" component={LayoutAs360Create} />
      <Route path="/assessment360/results/:type/:userId" component={LayoutAs360Results} />
    </Route>
    <Route path="/externalLink" component={ExternalLinkLayout} />
    <Route path="/redirect" component={RedirectLayout} />
    <Route path="*" component={() => <Page404 logo={appLogo} />} />
  </Route>
);

export default createRoutes;
