// @packages
import React from 'react';

// @own
import './styles.scss';
import getDocumentCoords from './domCoords';

class ProgressBar extends React.Component {
  constructor() {
    super();
    this.progressBarOnDrag = this.progressBarOnDrag.bind(this);
    this.sliderOnClick = this.sliderOnClick.bind(this);
  }

  progressBarOnDrag(e) {
    const { barWidth, onChange, onPlayButtonChange } = this.props;

    let shiftX = e.pageX - getDocumentCoords(this.playhead).left;
    onPlayButtonChange('pause');
    let move;
    let ratio;

    let mouseUpFn = function () {
      onChange(ratio);
      onPlayButtonChange('play');
      document.removeEventListener('mousemove', mouseMoveFn);
      document.removeEventListener('mouseup', mouseUpFn);
    }.bind(this);

    let mouseMoveFn = e => {
      move = e.pageX - getDocumentCoords(this.slider).left - shiftX;
      ratio = move / barWidth;
      if (ratio <= 0) {
        ratio = 0;
      } else if (ratio >= 1) {
        ratio = 1;
      }
      this.playhead.style.marginLeft = ratio * barWidth + 'px';
      this.elapsed.style.width = ratio * barWidth + 'px';
    };

    document.addEventListener('mousemove', mouseMoveFn);
    document.addEventListener('mouseup', mouseUpFn);
  }

  sliderOnClick(e) {
    const { barWidth, onChange, onPlayButtonChange } = this.props;

    if (e.target === this.playhead) {
      return;
    }
    let move = e.pageX - getDocumentCoords(this.slider).left - 7;// 7 === playhead width divided by 2 (see suite-audioProgressbar__playhead class)
    let ratio = move / barWidth;

    if (ratio <= 0) {
      ratio = 0;
    } else if (ratio >= 1) {
      ratio = 1;
    }
    onChange(ratio);
    onPlayButtonChange('pause');
  }



  render() {
    const { barWidth, pointInBar } = this.props;
    const progressMargin = pointInBar * barWidth;

    return (
      <div className="suite-audioProgressbar">
        <div onMouseDown={this.sliderOnClick} className="suite-audioProgressbar__timeline-effect">
          <div
            className="suite-audioProgressbar__timeline"
            ref={s => this.slider = s}
            style={{ width: `${barWidth + 14}px` }}// 14 === playhead width (see suite-audioProgressbar__playhead class)
          >
            <div
              className="suite-audioProgressbar__elapsed"
              style={{ width: `${progressMargin}px` }}
              ref={el => this.elapsed = el}
            >
              <div
                className="suite-audioProgressbar__playhead"
                style={{ marginLeft: `${progressMargin}px` }}
                ref={ph => this.playhead = ph}
                onMouseDown={this.progressBarOnDrag}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProgressBar;