// @packages
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RootModal from 'smu-app-components/RootModal';
import Segment from 'smu-ui-components/Segment';
import MessageBox from 'smu-ui-components/MessageBox';
import get from 'lodash/get';
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { push } from 'react-router-redux';

// @app
import ActionButtons from 'components/molecules/ActionButtons';
import EvaluationCreationDateRange from 'components/containers/EvaluationCreationDateRange';
import EvaluationCreationSelectType from 'components/containers/EvaluationCreationSelectType';
import EvaluationCreationSetTitle from 'components/containers/EvaluationCreationSetTitle'
import SimpleModal from 'common/SimpleModal';
import { CLOSED_STATUS } from 'betterme-components/Goals/constants';
import { ACTION_TYPE_REQUIRED, DATA } from 'components/common/constants';
import { EVALUATION_CREATE } from 'betterme-components/constants/modalTypes';
import { actionsMessages, evaluationsMessages } from 'common/messages';
import { setPerformanceRetrospective } from 'betterme-components/services/PerformanceRetrospective/actions';
import { formatPeriodDates } from 'utils/formatPeriodDates';
import { getLocalStoredItem, removeLocalStoredItem, setLocalStoredItem } from 'utils/localStorage';
import {
  getLeadershipAdminLocation,
  getPerformanceRetrospectiveLocation,
  getSelfRetrospectiveLocation,
  getTeamEvaluationsLocation,
} from 'betterme-components/routes/urls';
import { resetEvaluationDetail } from 'betterme-components/EvaluationDetail/actions';
import { selectConfigurations, selectUserDraftEvaluation } from 'betterme-components/Authorization/selectors';
import { selectDataCreateEvaluation } from 'betterme-components/Evaluations/selectors';
import { setRangeDate } from 'utils/setRangeDate';
import { getEvaluationDraftData } from 'utils/getEvaluationDraftData';
import { getTierType } from 'betterme-components/services/GetTierType/actions';
import { getAssignedGoals } from 'betterme-components/Goals/actions';
import { selectTierType, selectTierTypeLoading, selectTierTypeErrorMessage, } from 'betterme-components/services/GetTierType/selectors';
import { selectPerformanceRetrospective } from 'betterme-components/services/PerformanceRetrospective/selectors';
import { selectSelfRetrospective } from 'betterme-components/services/SelfRetrospective/selectors';

// @own
import messages from '../messages';
import './styles.scss';
import {
  trackEvaluationsCreateCancel,
  trackEvaluationsEditCancel,
} from '../analytics';
import {
  EVALUATION,
  NH_EVALUATION,
  NH_SELF_EVALUATION,
  PERFORMANCE_RETROSPECTIVE,
  SELF_EVALUATION,
  SELF_RETROSPECTIVE,
} from '../constants';

class EvaluationCreate extends Component {
  constructor() {
    super();

    this.getTitle = this.getTitle.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleCreateForm = this.handleCreateForm.bind(this);
  }

  componentDidMount() {
    const {
      allowsEvaluationsCreation,
      allowsEvaluationsSuggestedActions,
      allowsGlbFeatures,
      allowsGoals,
      allowsNewHireEvaluationCreation,
      allowsNewHireEvaluationDraft,
      allowsPerformanceRetrospectiveCreation,
      allowsPerformanceRetrospectiveDraft,
      allowsPeriodEvaluation,
      canCreateBehaviourRetrospective,
      canCreateBehaviourRetrospectiveAction,
      draftId,
      evaluationPeriod,
      getAssignedGoals,
      getEvaluationDetail,
      getSuggestedActions,
      getTierType,
      isNewHire,
      isSelfRetrospective,
      locationQuery,
      onChangeRangeDatepicker,
      onChangeSelect,
      values,
      user,
      userDraftEvaluation,
    } = this.props;
    const { type: evaluationType } = values;
    const { evaluee: { id: evalueeId } } = values;
    const isSelfEvaluation = values?.evaluee?.id === user?.id;
    const allowsNewHireEvaluation = allowsNewHireEvaluationCreation || allowsNewHireEvaluationDraft;
    const isNewHireType = evaluationType === NH_SELF_EVALUATION
      || evaluationType === NH_EVALUATION;
    const isNewHireEvaluationType = isNewHire && !isSelfEvaluation;
    const isNewHireSelfEvaluationType = isNewHire && isSelfEvaluation;
    const isEvaluationType = (((allowsEvaluationsCreation || allowsPeriodEvaluation)
      && !allowsGlbFeatures
      && !allowsPerformanceRetrospectiveCreation)
      && !isNewHire);
    const isPerformanceRetrospective = (allowsPerformanceRetrospectiveCreation
      || allowsPerformanceRetrospectiveDraft)
      && !allowsPeriodEvaluation;
    const showNewHireForm = ((isNewHire && allowsNewHireEvaluation) || (!!draftId && isNewHireType));
    const isModalOpenedByUrl = locationQuery?.openedByUrl;

    if ((isModalOpenedByUrl && userDraftEvaluation?.id) || draftId) {
      getEvaluationDetail((isModalOpenedByUrl && userDraftEvaluation?.id) || draftId);
    } else if (allowsGlbFeatures) {
      setRangeDate(evaluationPeriod, onChangeRangeDatepicker, values);
    } else {
      onChangeRangeDatepicker({
        startDate: moment.utc(evaluationPeriod.startTime),
        endDate: moment.utc(evaluationPeriod.endTime),
      });
    }

    if (locationQuery?.performanceRetrospective) {
      const queryValueString = typeof locationQuery?.performanceRetrospective === 'string';
      const locationQueryValue = queryValueString
        ? (locationQuery?.performanceRetrospective === 'false' ? false : true)
        : locationQuery?.performanceRetrospective;
      canCreateBehaviourRetrospectiveAction(locationQueryValue);
    }

    if (allowsEvaluationsSuggestedActions) {
      getSuggestedActions({ isNewHire: showNewHireForm });
    }

    if (isNewHireSelfEvaluationType) {
      onChangeSelect('type', NH_SELF_EVALUATION);
    }
    else if (isNewHireEvaluationType) {
      onChangeSelect('type', NH_EVALUATION);
    }
    else if (isSelfEvaluation && !isSelfRetrospective) {
      onChangeSelect('type', SELF_EVALUATION);
    }
    else if (isSelfRetrospective) {
      onChangeSelect('type', SELF_RETROSPECTIVE);
    }
    else if (isEvaluationType) {
      onChangeSelect('type', EVALUATION);
    }
    else if (isPerformanceRetrospective) {
      onChangeSelect('type', PERFORMANCE_RETROSPECTIVE);
    }

    if ((!isNewHire) && canCreateBehaviourRetrospective
      && (allowsPerformanceRetrospectiveCreation
        || allowsPerformanceRetrospectiveDraft)) {
      getTierType({
        userId: evalueeId,
        evaluationType: PERFORMANCE_RETROSPECTIVE,
      });
    }

    if ((!isNewHire) && isSelfRetrospective) {
      getTierType({
        userId: evalueeId,
        evaluationType: SELF_RETROSPECTIVE,
      });

      if (allowsGoals) {
        const goalsFilters = {
          status: CLOSED_STATUS,
          dueDateFrom: evaluationPeriod.startTime,
          dueDateTo: evaluationPeriod.endTime,
        };
        getAssignedGoals(evalueeId, goalsFilters);
      };
    }
  }

  componentDidUpdate(prevProps) {
    const {
      loadingEvalDetail: wasLoadingEvalDetail,
    } = prevProps;
    const {
      createPRData,
      createSRData,
      evaluationDetail,
      loadingEvalDetail: isLoadingEvalDetail,
      setDataCreateEvaluation,
      setPromotionFiles,
      suggestedActions,
      tierType,
      values,
    } = this.props;
    const files = get(evaluationDetail, 'promotion.attachments', []);
    const getEvaluationData = getEvaluationDraftData(
      evaluationDetail,
      suggestedActions,
    );
    const isPerformanceRetrospective = values?.type === PERFORMANCE_RETROSPECTIVE;
    const isSelfRetrospective = values?.type === SELF_RETROSPECTIVE;

    if (wasLoadingEvalDetail && !isLoadingEvalDetail) {
      setDataCreateEvaluation(getEvaluationData);
      setPromotionFiles(files);
    }

    if (isPerformanceRetrospective || isSelfRetrospective) {
      const setStartDate = isPerformanceRetrospective
        ? createPRData?.dateTimeFrom
        : createSRData?.dateTimeFrom;
      const setEndDate = isPerformanceRetrospective
        ? createPRData?.dateTimeTo
        : createSRData?.dateTimeTo;
      setLocalStoredItem(DATA, {
        endDate: setEndDate,
        title: values?.title,
        type: tierType?.type,
        startDate: setStartDate,
      });
    }
  }

  handleCloseModal() {
    const {
      close,
      draftId,
      goToEvaluationsTeam,
      goToLeadershipAdmin,
      onClose,
      resetDataCreateEvaluation,
      resetEvaluationDetail,
      values: { evaluee, type },
    } = this.props;

    const isSelfEvaluation = (
      type === SELF_EVALUATION
      || type === NH_SELF_EVALUATION
      || type === SELF_RETROSPECTIVE
    );
    const actionRequired = getLocalStoredItem(ACTION_TYPE_REQUIRED);

    if (!draftId) {
      trackEvaluationsCreateCancel();
    } else {
      trackEvaluationsEditCancel();
    }
    resetEvaluationDetail();
    resetDataCreateEvaluation();
    onClose();
    close();
    removeLocalStoredItem(ACTION_TYPE_REQUIRED);
    removeLocalStoredItem(DATA);

    if (actionRequired) {
      goToLeadershipAdmin(evaluee?.identification);
    } else {
      if (isSelfEvaluation) {
        close();
      } else {
        goToEvaluationsTeam();
      }
    }
  }

  handleCreateForm() {
    const {
      allowsNewHireEvaluationCreation,
      allowsNewHireEvaluationDraft,
      close,
      dataCreateEvaluation,
      draftId,
      goToPerformanceRetrosPective,
      goToSelfRetrosPective,
      isNewHire,
      locationQuery,
      onCreateForm,
      userDraftEvaluation,
      values: { evaluee },
    } = this.props;
    const allowsNewHireEvaluation = allowsNewHireEvaluationCreation || allowsNewHireEvaluationDraft;
    const applyNewHire = isNewHire && allowsNewHireEvaluation;
    const evaluationTitle = this.getTitle();
    const identification = evaluee?.identification;
    const performanceRetrospectiveType = dataCreateEvaluation?.type === PERFORMANCE_RETROSPECTIVE;
    const selfRetrospectiveType = dataCreateEvaluation?.type === SELF_RETROSPECTIVE;
    const isModalOpenedByUrl = locationQuery?.openedByUrl;
    const evaluationId = (isModalOpenedByUrl && userDraftEvaluation?.id) || draftId;

    if (performanceRetrospectiveType) {
      const performanceRetrospectiveParams = evaluationId
        ? ({
          userIdentification: identification,
          evaluationId: (isModalOpenedByUrl && userDraftEvaluation?.id) || dataCreateEvaluation?.draftId,
        })
        : ({ userIdentification: identification });
      goToPerformanceRetrosPective(performanceRetrospectiveParams);
    } else if (selfRetrospectiveType) {
      const selfRetrospectiveParams = evaluationId
        ? ({
          userIdentification: identification,
          evaluationId: (isModalOpenedByUrl && userDraftEvaluation?.id) || dataCreateEvaluation?.draftId,
        })
        : ({ userIdentification: identification });
      goToSelfRetrosPective(selfRetrospectiveParams);
    } else {
      onCreateForm(applyNewHire, evaluationTitle, (evaluationId));
    };
    close();
  }

  getTitle() {
    const {
      allowsNewHireEvaluationCreation,
      allowsNewHireEvaluationDraft,
      allowsPerformanceRetrospectiveCreation,
      allowsPerformanceRetrospectiveDraft,
      draftId,
      intl: { formatMessage },
      isNewHire,
      isSelfRetrospective,
      locationQuery,
      userDraftEvaluation,
      values: { type: valuesType },
    } = this.props;
    const isSelfEvaluation = (
      valuesType === SELF_EVALUATION
      || valuesType === NH_SELF_EVALUATION
      || valuesType === SELF_RETROSPECTIVE
    );
    const allowsNewHireEvaluation = allowsNewHireEvaluationCreation || allowsNewHireEvaluationDraft;
    const isNewHireType = (valuesType === NH_SELF_EVALUATION || valuesType === NH_EVALUATION);
    const applyNewHire = (isNewHire && allowsNewHireEvaluation) || isNewHireType;
    const isModalOpenedByUrl = locationQuery?.openedByUrl;
    const hasDraft = (isModalOpenedByUrl && userDraftEvaluation?.id) || draftId;
    const titlePerformanceRetrospective = hasDraft
      ? formatMessage(evaluationsMessages.EvaluationsEditPerformance)
      : formatMessage(evaluationsMessages.EvaluationsCreatePerformance);
    const titleCreatePeriodEvaluation = hasDraft
      ? formatMessage(evaluationsMessages.EvaluationsEdit)
      : formatMessage(evaluationsMessages.EvaluationsCreate);
    const titleCreateEvaluation = (allowsPerformanceRetrospectiveCreation
      || allowsPerformanceRetrospectiveDraft)
      ? titlePerformanceRetrospective
      : titleCreatePeriodEvaluation;
    const titleCreateSelfEvaluation = hasDraft
      ? formatMessage(evaluationsMessages.EvaluationsEditSelfEvaluation)
      : formatMessage(evaluationsMessages.EvaluationsCreateSelfEvaluation);
    const titleCreateSelfRetrospective = hasDraft
      ? formatMessage(evaluationsMessages.SelfRetrospectiveEdit)
      : formatMessage(evaluationsMessages.SelfRetrospectiveCreate);
    const titleCreateNHEvaluation = hasDraft
      ? formatMessage(evaluationsMessages.EvaluationsEditNH)
      : formatMessage(evaluationsMessages.EvaluationsCreateNH);
    const titleCreateNHSelfEvaluation = hasDraft
      ? formatMessage(evaluationsMessages.EvaluationsEditNHSelfEvaluation)
      : formatMessage(evaluationsMessages.EvaluationsCreateNHSelfEvaluation);
    const titleTypeEvaluation = titleCreateEvaluation;

    if (isSelfRetrospective && !applyNewHire) {
      return titleCreateSelfRetrospective;
    }

    if (isSelfEvaluation) {
      return applyNewHire
        ? titleCreateNHSelfEvaluation
        : titleCreateSelfEvaluation;
    } else {
      return applyNewHire
        ? titleCreateNHEvaluation
        : titleTypeEvaluation;
    }
  }

  render() {
    const {
      allowsNewHireEvaluationCreation,
      allowsNewHireEvaluationDraft,
      allowsNewHireSelfEvaluationCreation,
      allowsNewHireSelfEvaluationDraft,
      draftId,
      evaluationPeriod: { startTime, endTime },
      intl: { formatMessage },
      invalidGoalWeightingSum,
      isNewHire,
      isSelfRetrospective,
      loadingClosedGoals,
      loadingEvalDetail,
      loadingSuggestedActions,
      locationQuery,
      tierTypeLoading,
      tierType,
      values: {
        dateTimeFrom,
        dateTimeTo,
        title: valuesTitle,
        type: valuesType,
      },
      tierTypeErrorMessage,
      userDraftEvaluation,
    } = this.props;
    const allowsNewHireEvaluation = (allowsNewHireEvaluationCreation || allowsNewHireEvaluationDraft);
    const allowsNewHireSelfEvaluation = (allowsNewHireSelfEvaluationCreation || allowsNewHireSelfEvaluationDraft);
    const isNewHireType = valuesType === NH_SELF_EVALUATION || valuesType === NH_EVALUATION;
    const showNewHireForm = ((isNewHire && (allowsNewHireEvaluation || allowsNewHireSelfEvaluation)) || (!!draftId && isNewHireType));
    const isSelfEvaluation = valuesType === SELF_EVALUATION;
    const isLoading = loadingClosedGoals || loadingEvalDetail || loadingSuggestedActions || tierTypeLoading;
    const hasTierType = !!tierType?.type;
    const noTier = tierType?.type === null;
    const showErrorMessage = !isLoading && (!hasTierType || noTier || tierTypeErrorMessage)
      && (valuesType === PERFORMANCE_RETROSPECTIVE || valuesType === SELF_RETROSPECTIVE);
    const notAvaibleMessagePR = tierTypeErrorMessage
      ? formatMessage(messages.EvaluationsTypeError)
      : formatMessage(messages.EvaluationsTypeNotAvaible);
    const notAvaibleMessageSR = tierTypeErrorMessage
      ? formatMessage(messages.EvaluationsTypeError)
      : formatMessage(messages.EvaluationsSelfRetrospectiveTypeNotAvaible);
    const typeNotAvaibleMessage = isSelfRetrospective
      ? notAvaibleMessageSR
      : notAvaibleMessagePR;
    const isValidate = valuesTitle
      && valuesType
      && dateTimeFrom
      && dateTimeTo
      && !invalidGoalWeightingSum
      && !showErrorMessage;
    const modalSubtitle = !showNewHireForm && formatPeriodDates(startTime, endTime);
    const isModalOpenedByUrl = locationQuery?.openedByUrl;
    const hasDraft = (isModalOpenedByUrl && userDraftEvaluation?.id) || draftId;
    const actionButtons = [
      {
        label: formatMessage(actionsMessages.ActionsCancel),
        onClick: this.handleCloseModal,
        variant: "outline",
      },
      {
        disabled: !isValidate,
        label: hasDraft
          ? formatMessage(actionsMessages.ActionsEdit)
          : formatMessage(actionsMessages.ActionsCreate),
        onClick: this.handleCreateForm,
      },
    ];

    return (
      <SimpleModal
        className="evaluations-create"
        closeModal={this.handleCloseModal}
        title={this.getTitle()}
        subtitle={modalSubtitle}
      >
        <Segment
          className="m-6"
          loading={isLoading}
          loadingType="betterme"
        >
          {(showErrorMessage) && (
            <MessageBox
              className="score-selector-template__message-box"
              type="warning"
            >
              {typeNotAvaibleMessage}
            </MessageBox>
          )}

          {!isSelfEvaluation && !isNewHire && (
            <EvaluationCreationSelectType />
          )}

          <EvaluationCreationSetTitle
            evaluationType={valuesType}
          />

          {!isNewHire && (
            <EvaluationCreationDateRange
              evaluationType={valuesType}
            />
          )}

          <ActionButtons buttons={actionButtons} />
        </Segment>
      </SimpleModal>
    );
  }
}

EvaluationCreate.propTypes = {
  allowsEvaluationsSuggestedActions: PropTypes.bool.isRequired,
  allowsGlbFeatures: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  communityId: PropTypes.number.isRequired,
  draftId: PropTypes.number,
  evaluationDetail: PropTypes.object,
  evaluationPeriod: PropTypes.object,
  getEvaluationDetail: PropTypes.func.isRequired,
  getSuggestedActions: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  invalidGoalWeightingSum: PropTypes.bool,
  isNewHire: PropTypes.bool.isRequired,
  isSelfEvaluation: PropTypes.bool.isRequired,
  loadingClosedGoals: PropTypes.bool,
  loadingEvalDetail: PropTypes.bool,
  onChangeRangeDatepicker: PropTypes.func.isRequired,
  onChangeSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreateForm: PropTypes.func.isRequired,
  setDataCreateEvaluation: PropTypes.func.isRequired,
  suggestedActions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })),
  values: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  allowsEvaluationDrafts: selectConfigurations('allowsEvaluationDrafts')(state),
  allowsGoals: selectConfigurations('allowsGoals')(state),
  allowsPerformanceRetrospectiveCreation: selectConfigurations('allowsPerformanceRetrospectiveCreation')(state),
  allowsPerformanceRetrospectiveDraft: selectConfigurations('allowsPerformanceRetrospectiveDraft')(state),
  allowsPeriodEvaluation: selectConfigurations('allowsPeriodEvaluation')(state),
  allowsNewHireEvaluationCreation: selectConfigurations('allowsNewHireEvaluationCreation')(state),
  allowsNewHireEvaluationDraft: selectConfigurations('allowsNewHireEvaluationDraft')(state),
  createPRData: selectPerformanceRetrospective(state),
  createSRData: selectSelfRetrospective(state),
  dataCreateEvaluation: selectDataCreateEvaluation(state),
  tierType: selectTierType(state),
  tierTypeErrorMessage: selectTierTypeErrorMessage(state),
  tierTypeLoading: selectTierTypeLoading(state),
  userDraftEvaluation: selectUserDraftEvaluation(state),
});

const mapDispatchToProps = {
  getAssignedGoals,
  getTierType,
  goToEvaluationsTeam: () => push(getTeamEvaluationsLocation(null)),
  goToLeadershipAdmin: (identification) => push(getLeadershipAdminLocation(identification)),
  goToPerformanceRetrosPective: (params) =>
    push(getPerformanceRetrospectiveLocation(params)),
  goToSelfRetrosPective: (userIdentification) =>
    push(getSelfRetrospectiveLocation(userIdentification)),
  resetEvaluationDetail,
  setPerformanceRetrospective,
};

const modalStyles = {
  top: '40%',
  maxWidth: '450px',
};

export default RootModal(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(EvaluationCreate)),
  EVALUATION_CREATE,
  modalStyles,
  'simple-modal__wrapper'
);
