// @packages
import React from 'react';
import PropTypes from 'prop-types';
import Survey from 'smu-app-components/Assessment360/pages/Survey';
import { TYPE_BY_ALIAS } from 'smu-app-components/Assessment360/constants';
import { connect } from 'react-redux';

// @app
import { selectCommunityId } from 'betterme-components/Authorization/selectors';

function LayoutAs360Create({ communityId, params }) {
  const type = TYPE_BY_ALIAS[params.type];

  return (
    <Survey
      className="layout-as-360-create"
      communityId={communityId}
      key={`${type}-${params.userId}`}
      type={type}
      userId={params.userId}
    />
  );
}

LayoutAs360Create.propTypes = {
  /**
   * Community ID
   */
  communityId: PropTypes.number.isRequired,
  /**
   * Route params
   */
  params: PropTypes.shape({
    type: PropTypes.string.isRequired,
    userId: PropTypes.number.isRequired,
  }),
};

const mapStateToProps = (state) => ({
  communityId: selectCommunityId(state),
});

export default connect(mapStateToProps)(LayoutAs360Create);
