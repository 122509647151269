import {
  GET_DASHBOARD_SUGGESTIONS,
  GET_DASHBOARD_SUGGESTIONS_FAIL,
  GET_DASHBOARD_SUGGESTIONS_SUCCESS,
} from './actionTypes';

export function getDashboardSuggestions(userId, from, to) {
  return {
    type: GET_DASHBOARD_SUGGESTIONS,
    payload: { userId, creationTimeFrom: from, creationTimeTo: to },
  };
}

export function getDashboardSuggestionsFail(error) {
  return {
    type: GET_DASHBOARD_SUGGESTIONS_FAIL,
    payload: { error },
  };
}

export function getDashboardSuggestionsSuccess(response) {
  return {
    type: GET_DASHBOARD_SUGGESTIONS_SUCCESS,
    payload: { response },
  };
}
