// @own
import {
  GET_POTENTIAL_ASSESSMENT_RESULTS,
  GET_POTENTIAL_ASSESSMENT_RESULTS_FAIL,
  GET_POTENTIAL_ASSESSMENT_RESULTS_SUCCESS,
  GET_POTENTIAL_TYPE,
  GET_POTENTIAL_TYPE_FAIL,
  GET_POTENTIAL_TYPE_SUCCESS,
  RESET_POTENTIAL_ASSESSMENT_RESULTS,
} from './actionTypes';

export function getPotentialType(userId) {
  return {
    type: GET_POTENTIAL_TYPE,
    payload: userId,
  };
}

export function getPotentialTypeSuccess(response) {
  return {
    type: GET_POTENTIAL_TYPE_SUCCESS,
    payload: response,
  };
}

export function getPotentialTypeFail(error) {
  return {
    type: GET_POTENTIAL_TYPE_FAIL,
    payload: { error },
  };
}

export function getPotentialAssessmentResults(params) {
  return {
    type: GET_POTENTIAL_ASSESSMENT_RESULTS,
    payload: params,
  };
}

export function getPotentialAssessmentResultsSuccess(response) {
  return {
    type: GET_POTENTIAL_ASSESSMENT_RESULTS_SUCCESS,
    payload: response,
  };
}

export function getPotentialAssessmentResultsFail(error) {
  return {
    type: GET_POTENTIAL_ASSESSMENT_RESULTS_FAIL,
    payload: { error }
  };
}

export function resetPotentialAssessmentResults() {
  return {
    type: RESET_POTENTIAL_ASSESSMENT_RESULTS,
  };
}
