// @packages
import React from 'react';
import Sound from 'react-sound';
import Icon from 'smu-ui-components/Icon';

// @app
import { trackFeedbackAudioCommentInteractions } from '../../Feed/analytics';

// @own
import './styles.scss';
import milisecondsToMMSS from './secondsToMMSS';
import ProgressBar from './ProgressBar';

class AudioComment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      playStatus: Sound.status.PAUSED,
      position: 0,
      duration: null,
    };

    this.handlePlayPositionChange = this.handlePlayPositionChange.bind(this);
    this.handlePlayButton = this.handlePlayButton.bind(this);
    this.handleTogglePlayClick = this.handleTogglePlayClick.bind(this);
  }

  handleTogglePlayClick() {
    const { trackEvent, typeOf } = this.props;
    const { playStatus } = this.state;

    this.setState(prevState => {
      const pause = Sound.status.PAUSED;
      const play = Sound.status.PLAYING;
      const stop = Sound.status.STOPPED;
      const nextPlayStatus = (prevState.playStatus === pause || prevState.playStatus === stop) ? play : pause;
      return { playStatus: nextPlayStatus };
    });

    if (trackEvent) {
      trackFeedbackAudioCommentInteractions(typeOf, playStatus);
    };
  }

  handlePlayPositionChange(relativePosition) {
    this.setState({
      position: relativePosition * this.state.duration,
    });
  }

  handlePlayButton(playOrPause) {
    if (playOrPause === 'pause') {
      this.setState({ playStatus: Sound.status.PAUSED });
    } else if (playOrPause === 'play') {
      this.setState({ playStatus: Sound.status.PLAYING });
    }
  }

  render() {
    const pause = Sound.status.PAUSED;
    const { url, barWidth } = this.props;
    const { position, duration, playStatus } = this.state;
    const audioEnded = playStatus === Sound.status.STOPPED;
    const audioPaused = playStatus === pause;
    const timerRender = position === 0 ? (milisecondsToMMSS(duration)) : milisecondsToMMSS(position);

    return (
      <div className="suite-audiocomment">
        <Icon
          icon={(audioEnded || audioPaused) ? 'play' : 'pause'}
          onClick={this.handleTogglePlayClick}
          className="suite-audiocomment__play"
        />

        <ProgressBar
          pointInBar={position / duration}
          className="suite-audiocomment__bar"
          barWidth={barWidth}
          onChange={this.handlePlayPositionChange}
          onPlayButtonChange={this.handlePlayButton}
        />

        <span className="suite-audiocomment__time">{timerRender}</span>

        <Sound
          url={url}
          playStatus={playStatus}
          position={position}
          onLoad={audio => this.setState({ duration: audio.duration })}
          onPlaying={({ position }) => {(Math.trunc(position) === duration) ? this.setState({playStatus: Sound.status.STOPPED,position:0}) : this.setState({ position });}}
          volume={100}
          autoLoad={true}
        />
      </div>
    );
  }
}

export default AudioComment;