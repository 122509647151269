import { defineMessages } from 'react-intl';

export default defineMessages({
  PerformanceRetrospectiveTitle: {
    id: 'Betterme.PerformanceRetrospectiveInput.Title',
    defaultMessage: 'Technical Retrospective',
  },
  PerformanceRetrospectiveSecondaryTitle: {
    id: 'Betterme.PerformanceRetrospectiveInput.SecondaryTitle',
    defaultMessage: 'Assessment Co-Creator',
  },
  PerformanceRetrospectiveCoEvaluatorSearch: {
    id: 'Betterme.PerformanceRetrospective.CoEvaluatorSearch',
    defaultMessage: 'Search Co-evaluator',
  },
  PerformanceRetrospectiveAttachFile: {
    id: 'Betterme.PerformanceRetrospective.AttachFile',
    defaultMessage: '(Referring to the performance score)',
  },
  PerformanceRetrospectiveRate: {
    id: 'Betterme.PerformanceRetrospective.Score',
    defaultMessage: 'Performance Score',
  },
  PerformanceRetrospectiveSelectRate: {
    id: 'Betterme.PerformanceRetrospective.SelectRate',
    defaultMessage: 'Choose a performance score for the assessment (Required)',
  },
  PerformanceRetrospectivePlaceholder: {
    id: 'Betterme.PerformanceRetrospective.Placeholder',
    defaultMessage: 'Provide feedback to the Glober on their strengths and areas for improvement, contributing to team goals and ongoing talent development. (Required)',
  },
  PerformanceRetrospectiveCoCreatorSlideOneTitle: {
    id: 'Betterme.Evaluations.CoCreatorSlideOneTitle',
    defaultMessage: 'Let’s work together',
  },
  PerformanceRetrospectiveCoCreatorSlideTwoTitle: {
    id: 'Betterme.Evaluations.CoCreatorSlideTwoTitle',
    defaultMessage: 'Visibility',
  },
  PerformanceRetrospectiveInputCoCreatorSlideOneText: {
    id: 'Betterme.PerformanceRetrospectiveInput.CoCreatorSlideOneText',
    defaultMessage: 'The Glober Experience Leader would greatly value your input in providing complementary insights on the Glober’s performance, particularly in relation to their technical or specialty skills.',
  },
  PerformanceRetrospectiveInputCoCreatorSlideTwoText: {
    id: 'Betterme.PerformanceRetrospectiveInput.CoCreatorSlideTwoText',
    defaultMessage: 'Once you complete their assessment, you will be able to see their input in each section. The "Technichal skills" and comment box will be visible to the Glober unless the Experience Leader disabled those sections of the assessment. We recommend conducting a final review with the co-creator before submitting the assessment. Once submitted, the Glober will receive a notification and will be able to access the Performance Retrospective.',
  },
  NotificationsTitle: {
    id: 'Betterme.TechnicalAssessment.NotificationsTitle',
    defaultMessage: 'Notifications',
  },
  PerformanceRetrospectiveCoEvaluatorTitle: {
    id: 'Betterme.PerformanceRetrospective.CoEvaluatortITitle',
    defaultMessage: 'Co-Evaluator',
  },
  PerformanceRetrospectiveCoEvaluatorSubtitle: {
    id: 'Betterme.PerformanceRetrospective.CoEvaluatorInputSubtitle',
    defaultMessage: 'You have been invited to provide a complementary Performance Retrospective by',
  },
  PerformanceRetrospectiveCoEvaluatorKeepInMind: {
    id: 'Betterme.PerformanceRetrospective.CoEvaluatorInputKeepInMind',
    defaultMessage: 'Keep in mind: Your input may be included in the Performance Retrospective as long as both co-creators agree.',
  },
  PerformanceRetrospectiveSuccessMessage: {
    id: 'Betterme.PerformanceRetrospective.SuccessMessage',
    defaultMessage: 'Your suggestion was successfully sent',
  },
});
