// @package
import React from 'react';
import PropTypes from 'prop-types';
import FeatureDiscoveryComponent from 'smu-app-components/FeatureDiscoveryV2';

// @own
import discoveryTypesAndOrder from './constsDiscoveryTypesAndGroups';

const FeatureDiscovery = ({
  children,
  ...props
}) => (
  <FeatureDiscoveryComponent
    {...props}
    color="btm"
    discoveryTypesAndOrder={discoveryTypesAndOrder}
    target={children}
    viewportOffset={{
      top: 65,
    }}
  />
);

FeatureDiscovery.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  group: PropTypes.string,
  position: PropTypes.string,
  text: PropTypes.string,
  timeout: PropTypes.number,
  type: PropTypes.string,
};

export default FeatureDiscovery;
