import { defineMessages } from 'react-intl';

export default defineMessages({
  FeedbackEvaluationDetailTitle: {
    id: 'Betterme.EvaluationDetail.Title',
    defaultMessage: 'Evaluation',
  },
  FeedbackEvaluationDetailSendBy: {
    id: 'Betterme.EvaluationDetail.SendBy',
    defaultMessage: 'Sent by',
  },
  FeedbackEvaluationDetailWrittenBy: {
    id: 'Betterme.EvaluationDetail.WrittenBy',
    defaultMessage: 'Written by',
  },
  FeedbackEvaluationDetailSendByUsers: {
    id: 'Betterme.EvaluationDetail.SendByUsers',
    defaultMessage: 'Sent by <b>{evaluator}</b> and <b>{coevaluator}</b>',
  },
  FeedbackEvaluationDetailClear: {
    id: 'Betterme.EvaluationDetail.Clear',
    defaultMessage: 'Clear',
  },
  FeedbackEvaluationDetailAnswer: {
    id: 'Betterme.EvaluationDetail.Answer',
    defaultMessage: 'Answer',
  },
  FeedbackEvaluationDetailSend: {
    id: 'Betterme.EvaluationDetail.Send',
    defaultMessage: 'Send',
  },
  FeedbackEvaluationDetailPlaceholderComment: {
    id: 'Betterme.EvaluationDetail.PlaceholderComment',
    defaultMessage: 'Leave a comment about your Evaluation',
  },
  FeedbackEvaluationDetailLabelAgree: {
    id: 'Betterme.EvaluationDetail.LabelAgree',
    defaultMessage: 'I agree with my Evaluation',
  },
  FeedbackEvaluationDetailReplyTitle: {
    id: 'Betterme.EvaluationDetail.ReplyTitle',
    defaultMessage: 'Reply',
  },
  FeedbackEvaluationDetailEvaluationReplyTitle: {
    id: 'Betterme.EvaluationDetail.FeedbackEvaluationReplyTitle',
    defaultMessage: 'Evaluation Reply',
  },
  FeedbackEvaluationDetailLabelDisagree: {
    id: 'Betterme.EvaluationDetail.LabelDisagree',
    defaultMessage: 'I disagree with my Evaluation',
  },
  FeedbackEvaluationDetailContinuousEvaluation: {
    id: 'Betterme.EvaluationDetail.ContinuousEvaluation',
    defaultMessage: 'Continuous Evaluation',
  },
  FeedbackEvaluationDetailComment: {
    id: 'Betterme.EvaluationDetail.Comment',
    defaultMessage: 'Comment',
  },
  FeedbackEvaluationDetailCompetencies: {
    id: 'Betterme.EvaluationDetail.Competencies',
    defaultMessage: 'Competencies',
  },
  FeedbackEvaluationDetailSuggestedScore: {
    id: 'Betterme.EvaluationDetail.SuggestedScore',
    defaultMessage: 'Suggested Score',
  },
  FeedbackEvaluationDetailWarning: {
    id: 'Betterme.EvaluationDetail.Warning',
    defaultMessage: '{username} won\'t see these suggestions, these are visible only for the evaluator.',
  },
  FeedbackEvaluationDetailEvalueeEvaluationMainText: {
    id: 'Betterme.EvaluationDetail.EvalueeEvaluationMainText',
    defaultMessage: 'Evaluation of',
  },
  FeedbackEvaluationDetailEvalueeSelfEvaluationMainText: {
    id: 'Betterme.EvaluationDetail.EvalueeSelfEvaluationMainText',
    defaultMessage: 'Self-evaluation of',
  },
  FeedbackEvaluationDetailSuggestPromotion: {
    id: 'Betterme.EvaluationDetail.SuggestPromotion',
    defaultMessage: 'Suggested Promotion',
  },
  FeedbackEvaluationDetailCurrentSeniority: {
    id: 'Betterme.EvaluationDetail.Seniority',
    defaultMessage: 'Current Seniority',
  },
  FeedbackEvaluationDetailPromoteTo: {
    id: 'Betterme.EvaluationDetail.PromoteTo',
    defaultMessage: 'Promote to',
  },
  FeedbackEvaluationDetailJustification: {
    id: 'Betterme.EvaluationDetail.Justification',
    defaultMessage: 'Justification',
  },
  FeedbackEvaluationDetailEvaluations: {
    id: 'Betterme.EvaluationDetail.Evaluations',
    defaultMessage: 'My Evaluations',
  },
  FeedbackEvaluationDetailMyTeamEvaluations: {
    id: 'Betterme.EvaluationDetail.MyTeamEvaluations',
    defaultMessage: 'My Team\'s Evaluations',
  },
  EvaluationDetailMeaningfulConversation: {
    id: 'Betterme.EvaluationDetail.MeaningfulConversation',
    defaultMessage: 'Meaningful Conversation',
  },
  EvaluationDetailMeaningfulConversationText: {
    id: 'Betterme.EvaluationDetail.MeaningfulConversationText',
    defaultMessage: 'I felt the conversation with my leader was clear, caring and kind and it allowed me to discover/define career or development opportunities.',
  },
  EvaluationDetailMeaningfulConversationFirstOption: {
    id: 'Betterme.EvaluationDetail.MeaningfulConversationFirstOption',
    defaultMessage: 'Agree',
  },
  EvaluationDetailMeaningfulConversationSecondOption: {
    id: 'Betterme.EvaluationDetail.MeaningfulConversationSecondOption',
    defaultMessage: 'Disagree',
  },
  EvaluationDetailMeaningfulConversationThirdOption: {
    id: 'Betterme.EvaluationDetail.MeaningfulConversationThirdOption',
    defaultMessage: 'I didn\'t have one',
  },
  EvaluationsDetailEvaluationSentBy: {
    id: 'Betterme.EvaluationDetail.ActionsEvaluaionSentBy',
    defaultMessage: 'Sent by <b>{evaluator}</b> and <b>{coEvaluator}</b>',
  },
});
