/* eslint-disable react-hooks/exhaustive-deps */
// @packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { injectIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { base64Decode } from 'smu-utils/base64';
import { openModal, closeModal } from 'smu-app-components/RootModal/actions';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import { useQuestions } from 'smu-services/betterme/api/questions/get';
import { useRates } from 'smu-services/betterme/api/rates/get';

// @app
import SkillsComponent from 'components/containers/SkillsComponent';
import BehaviourSection from 'components/containers/BehaviourSectionNew';
import Evaluation from 'components/templates/EvaluationsTemplate';
import EvaluationDraftAlertDelete from 'betterme-components/Evaluations/EvaluationDraftAlertDelete';
import EvaluationDraftAlertSubmit from 'components/containers/EvaluationDraftAlertSubmit';
import EvaluationWidgets from 'components/containers/EvaluationWidgets';
import EvaluationsButtons from 'components/organisms/EvaluationButtons';
import ScoreSelector from 'components/templates/ScoreSelector';
import SendEvaluationConfirmationModal from 'components/containers/SendEvaluationConfirmationModal';
import Title from 'components/atoms/Title';
import { MAX_FILES, MAX_FILE_SIZE, SUPPORTED_FORMATS } from 'betterme-components/constants/fileValidations';
import {
  resetSelfRetrospective,
  resetSelfRetrospectiveBehaviours,
  setSelfRetrospective,
  setSelfRetrospectiveBehaviours,
} from 'betterme-components/services/SelfRetrospective/actions';
import {
  selectSelfRetrospective,
  selectSelfRetrospectiveBehaviours,
} from 'betterme-components/services/SelfRetrospective/selectors';
import { evaluationsMessages, promotionsMessages } from 'common/messages';
import { resetEvaluationDetail, getEvaluationDetail } from 'betterme-components/EvaluationDetail/actions';
import { resetSelectLeader } from 'betterme-components/services/ChangeLeaderRequest/actions';
import { getProfile } from 'betterme-components/Profile/actions';
import { getEvaluationsLocation } from 'betterme-components/routes/urls';
import { handleAttachFile, handleDeleteFile } from 'utils/attachDeleteEvaluationsFilesHelper';
import { handleCommentChange, handleScoreChange } from 'utils/handleScoreCommentHelper';
import { selectEvaluationDetail, selectIsFetching } from 'betterme-components/EvaluationDetail/selectors';
import { selectEvaluationPeriod, selectConfigurations } from 'betterme-components/Authorization/selectors';
import { selectProfile, selectLoadingGetProfile } from 'betterme-components/Profile/selectors';
import { selectRatings } from 'betterme-components/services/Layout/selectors';
import { createSaveEvaluation } from 'betterme-components/services/EvaluationsCreateSave/actions';
import { selectCreateSaveEvaluationLoading } from 'betterme-components/services/EvaluationsCreateSave/selectors';
import { selectUpdateSubmitEvaluationLoading } from 'betterme-components/services/EvaluationsUpdateSubmit/selectors';
import { updateSubmitEvaluation } from 'betterme-components/services/EvaluationsUpdateSubmit/actions';
import { selectTierType } from 'betterme-components/services/GetTierType/selectors';
import { selectFiles, selectFilesLoading } from 'betterme-components/services/UploadFiles/selectors';
import { getLocalStoredItem, removeLocalStoredItem } from 'utils/localStorage';
import { getTierType } from 'betterme-components/services/GetTierType/actions';
import { draftDataSelfRetrospective } from 'utils/draftDataSelfRetrospective';
import { formatPeriodDates } from 'utils/formatPeriodDates';
import {
  deleteDraftEvaluation,
  evaluationCleanSkills,
  resetDataCreateEvaluation,
} from 'betterme-components/Evaluations/actions';
import {
  selectEvaluationsSkills,
  selectLoadingDeleteDraft,
  selectOptionsScores,
} from 'betterme-components/Evaluations/selectors';
import {
  draftFiles,
  deleteFiles,
  insertFiles,
  resetFiles,
  uploadFiles,
} from 'betterme-components/services/UploadFiles/actions';
import {
  EVALUATION_DRAFT_DELETE_MODAL,
  EVALUATION_DRAFT_SUBMIT_MODAL,
  SEND_EVALUATION_CONFIRMATION_MODAL,
} from 'betterme-components/constants/modalTypes';

// @own
import './styles.scss';
import {
  CREATE_EVALUATION,
  SELF_RETROSPECTIVE,
  SUBMIT_DRAFT,
} from './constants';
import messages from './messages';

const SelfRetrospective = ({
  addToastMessage,
  allowsSelfRetrospectiveCreation,
  allowsSelfRetrospectiveDraft,
  behavioursData,
  closeModal,
  createSaveEvaluation,
  createSaveEvaluationLoading,
  creationData,
  deleteDraftEvaluation,
  deleteFiles,
  draftFiles,
  draftInfo,
  draftInfoLoading,
  evaluationCleanSkills,
  evaluationPeriod,
  files,
  getEvaluationDetail,
  getProfile,
  getTierType,
  goToMyEvaluation,
  insertFiles,
  intl: { formatMessage },
  loadingDeleteDraft,
  loadingFiles,
  loadingProfile,
  openModal,
  optionsScores,
  params,
  profile: evalueeProfile,
  resetDataCreateEvaluation,
  resetEvaluationDetail,
  resetFiles,
  resetSelectLeader,
  resetSelfRetrospective,
  resetSelfRetrospectiveBehaviours,
  session,
  setSelfRetrospective,
  setSelfRetrospectiveBehaviours,
  tierType,
  updateSubmitEvaluation,
  updateSubmitEvaluationLoading,
  uploadFiles,
}) => {
  const [surveyQuestions, setSurveyQuestions] = useState([]);
  const [comment, setComment] = useState('');
  const [score, setScore] = useState('');
  const [surveyRates, setSurveyRates] = useState([]);
  const [ratedQuestions, setRatedQuestions] = useState([]);

  const coEvaluatorName = `${draftInfo?.coCreated?.evaluator?.firstName} ${draftInfo?.coCreated?.evaluator?.lastName}`;
  const endTime = evaluationPeriod?.endTime;
  const startTime = evaluationPeriod?.startTime;
  const evaluationId = params?.evaluationId;
  const hasDraft = !!evaluationId;
  const mainActionText = hasDraft ? SUBMIT_DRAFT : CREATE_EVALUATION;
  const userIdentification = base64Decode(params?.userIdentification);
  const localData = getLocalStoredItem('DATA');
  const hasTitle = !!creationData?.title;
  const toastMessageSuccess = formatMessage(messages.PeriodEvaluationDraftSaved);
  const setEvaluationTitle = creationData?.title || draftInfo?.title;
  const setEvaluationDateFrom = (hasDraft && !hasTitle)
    ? draftInfo?.dateTimeFrom
    : creationData?.dateTimeFrom || startTime;
  const setEvaluationDateTo = (hasDraft && !hasTitle)
    ? draftInfo?.dateTimeTo
    : creationData?.dateTimeTo || endTime;
  const evaluationDateFrom = creationData?.dateTimeFrom;
  const evaluationDateTo = creationData?.dateTimeTo;
  const headerPeriod = formatPeriodDates(
    (creationData?.dateTimeFrom || draftInfo?.dateTimeFrom),
    (creationData?.dateTimeTo || draftInfo?.dateTimeTo),
  );
  const {
    data: dataQuestions,
    error: errorSurveyQuestions,
    selectQuestions,
    selectRatedQuestions,
  } = useQuestions(tierType);

  const {
    data: dataRates,
    error: errorSurveyRates,
    selectModifiedRates,
  } = useRates(tierType);

  useEffect(() => {
    if (!hasDraft && !hasTitle) {
      goToMyEvaluation();
    }
  }, []);

  useEffect(() => {
    if (dataQuestions) {
      setRatedQuestions(selectRatedQuestions(draftInfo));
      setSurveyQuestions(selectQuestions());
    }
  }, [dataQuestions]);

  useEffect(() => {
    if (dataRates) {
      setSurveyRates(selectModifiedRates());
    }
  }, [dataRates]);

  useEffect(() => {
    getProfile(userIdentification);
  }, [getProfile, userIdentification]);

  useEffect(() => {
    if (isEmpty(draftInfo) && !isNil(evaluationId)) {
      getEvaluationDetail(evaluationId);
    }
  }, [evaluationId]);

  useEffect(() => {
    const draftInfoData = draftDataSelfRetrospective(draftInfo);
    if (!isNil(evaluationId) && !isEmpty(draftInfoData)) {
      setSelfRetrospective({
        ...creationData,
        ...draftInfoData,
        dateTimeFrom: setEvaluationDateFrom,
        dateTimeTo: setEvaluationDateTo,
        title: setEvaluationTitle,
      });
      insertFiles(draftInfo?.attachments || []);
      setBehavioursData();
    } else {
      let newDataEvaluation = {
        ...creationData,
        comment: comment || '',
        dateTimeFrom: evaluationDateFrom || startTime,
        dateTimeTo: evaluationDateTo || endTime,
        evalueeId: evalueeProfile?.id,
        promotion: null,
        score: score || 0,
        title: setEvaluationTitle,
        type: SELF_RETROSPECTIVE,
      };
      setSelfRetrospective({ ...newDataEvaluation });
    }
  }, [draftInfo, ratedQuestions]);

  useEffect(() => {
    if (!tierType && evalueeProfile?.id) {
      getTierType({
        userId: evalueeProfile?.id,
        evaluationType: SELF_RETROSPECTIVE,
      });
    }
  }, [evalueeProfile, tierType]);

  useEffect(() => {
    return () => {
      evaluationCleanSkills();
      resetDataCreateEvaluation();
      resetEvaluationDetail();
      resetFiles();
      resetSelectLeader();
      resetSelfRetrospective();
      resetSelfRetrospectiveBehaviours();
    }
  }, []);

  function setBehavioursData() {
    const behaviorsDraft = ratedQuestions?.reduce((accumulator, behaviour) => {
      if (behaviour?.previousRate) {
        accumulator.push({
          questionId: behaviour?.id,
          ratingId: behaviour?.previousRate,
        });
      }
      return accumulator;
    }, []);
    setSelfRetrospectiveBehaviours(behaviorsDraft);
  }

  function handleDeleteDraftModal() {
    openModal({
      modalType: EVALUATION_DRAFT_DELETE_MODAL,
      modalProps: {
        loadingDeleteDraft: false,
        onAccept: handleDeleteDraft,
        onCancel: () => { },
      }
    });
  };

  function handleEvaluationDraftSubmitModal() {
    openModal({
      modalType: EVALUATION_DRAFT_SUBMIT_MODAL,
      modalProps: {
        isSelfRetrospective: true,
        loading: updateSubmitEvaluationLoading,
        onAccept: handleUpdateSubmitDraft,
        onCancel: () => { },
      }
    });
  };

  function handleConfirmationModal() {
    openModal({
      modalType: SEND_EVALUATION_CONFIRMATION_MODAL,
      modalProps: {
        isSelfRetrospective: true,
        sendEvaluation: handleCreateOrSave,
      },
    });
  };

  function createOrSubmitEvaluation() {
    if (hasDraft) {
      handleEvaluationDraftSubmitModal();
    } else {
      handleConfirmationModal();
    }
  };

  function handleBackTo() {
    goToMyEvaluation();
    evaluationCleanSkills();
    removeLocalStoredItem('DATA');
    resetDataCreateEvaluation();
    resetEvaluationDetail();
    resetFiles();
    resetSelectLeader();
    resetSelfRetrospective();
    resetSelfRetrospectiveBehaviours();
  };

  function handleCreateOrSave(evaluationStatus) {
    const data = {
      ...creationData,
      behaviors: behavioursData,
      evalueeId: evalueeProfile?.id,
      files: files?.map(file => file?.id) || [],
      surveyType: tierType?.type || localData?.type,
      status: evaluationStatus,
    };
    const message = evaluationStatus === 'SUBMITTED'
      ? formatMessage(messages.SelfRetrospectiveSuccessFooterMessageTeam,
        { username: evalueeProfile?.firstName })
      : toastMessageSuccess;

    createSaveEvaluation({
      data,
      dataEvaluee: {
        identification: evalueeProfile?.identification,
      },
      dataEvaluator: {
        id: session?.user?.id,
        identification: session?.user?.identification,
      },
      evaluationType: SELF_RETROSPECTIVE,
      message,
      evaluationPeriod: {
        startTime: evaluationDateFrom,
        endTime: evaluationDateTo,
      },
    });
    evaluationCleanSkills();
    resetSelectLeader();
  };

  function handleUpdateSubmitDraft(evaluationStatus) {
    updateSubmitEvaluation({
      data: {
        ...creationData,
        behaviors: behavioursData,
        evalueeId: evalueeProfile?.id,
        files: files?.map(file => file?.id) || [],
        status: evaluationStatus,
      },
      dataEvaluee: {
        identification: evalueeProfile?.identification,
      },
      dataEvaluator: {
        id: session?.user?.id,
        identification: session?.user?.identification,
      },
      evaluationId: evaluationId,
      evaluationType: SELF_RETROSPECTIVE,
      message: evaluationStatus === 'SUBMITTED'
        ? formatMessage(messages.SelfRetrospectiveSuccessFooterMessageTeam,
          { username: evalueeProfile.firstName })
        : toastMessageSuccess,
      evaluationPeriod: {
        startTime: evaluationDateFrom,
        endTime: evaluationDateTo,
      },
    });
    resetSelectLeader();
  };

  function handleDeleteDraft() {
    deleteDraftEvaluation({
      id: evaluationId,
      messages: { success: formatMessage(messages.PeriodEvaluationDraftDeleted) },
      typeEvaluation: SELF_RETROSPECTIVE,
    });
    closeModal();
  };

  function saveOrUpdateDraft() {
    if (hasDraft) {
      handleUpdateSubmitDraft('DRAFT');
    } else {
      handleCreateOrSave('DRAFT');
    }
  };

  function handleDraftFiles(id) {
    if (hasDraft) {
      draftFiles(id, draftFiles, closeModal);
    } else {
      handleDeleteFile(id, deleteFiles, closeModal);
    }
  }

  function isValidate() {
    const isValidate = (!errorSurveyQuestions && !errorSurveyRates)
      && (behavioursData?.length === surveyQuestions?.length)
      && (!isEmpty(comment) || !isEmpty(creationData?.comment))
      && (score || creationData?.score)
      && (!isEmpty(setEvaluationTitle) || !isEmpty(creationData?.title));
    return isValidate;
  };

  return (
    <Evaluation
      className="performance-retrospective"
      date={headerPeriod}
      headerValues={[
        formatMessage(evaluationsMessages.EvaluationsMyEvaluations),
        setEvaluationTitle,
      ]}
      mainContentLoading={draftInfoLoading
        || updateSubmitEvaluationLoading
        || createSaveEvaluationLoading
        || loadingDeleteDraft
        || loadingProfile}
      navigateBack={handleBackTo}
      sideContent={
        <EvaluationWidgets
          dateTimeFrom={startTime}
          dateTimeTo={endTime}
          evaluee={evalueeProfile}
          evaluationType={SELF_RETROSPECTIVE}
        />
      }
    >

      <Title
        className="mb-6"
        label={1}
        size={22}
      >
        <span className="text-black">
          {formatMessage(messages.SelfRetrospectiveTitle)}
        </span>
      </Title>

      <BehaviourSection
        behavioursData={behavioursData}
        className="mb-6"
        coEvaluatorName={coEvaluatorName}
        isSelfRetrospective
        ratedQuestions={ratedQuestions}
        ratesData={surveyRates}
        setBehaviours={setSelfRetrospectiveBehaviours}
      />

      <SkillsComponent
        data={creationData}
        selfRetrospective
        setData={setSelfRetrospective}
        user={evalueeProfile}
      />

      <ScoreSelector
        attachFiles={{
          customText: formatMessage(messages.SelfRetrospectiveAttachFile),
          files,
          filesLoading: loadingFiles,
          filesMaxLenght: MAX_FILES,
          onChange: (event) => handleAttachFile({
            addToastMessage,
            evaluationType: creationData?.type,
            event,
            promotionsMessages: {
              promotionsErrorExceededSize: formatMessage(promotionsMessages.PromotionsErrorExceededSize, {
                size: MAX_FILE_SIZE,
              }),
              promotionsErrorNoSize: formatMessage(promotionsMessages.PromotionsErrorNoSize),
              promotionsErrorInvalidFormat: formatMessage(promotionsMessages.PromotionsErrorInvalidFormat, {
                formats: SUPPORTED_FORMATS,
              }),
            },
            uploadEvaluationFile: uploadFiles,
          }),
          onDeleteAccept: (id) => handleDraftFiles(id),
          supportedFormats: SUPPORTED_FORMATS,
        }}
        className="period-evaluation__score"
        hasTooltip
        scoreSelector={{
          disabled: false,
          onScoreChange: (value) => handleScoreChange({
            value,
            dataEvaluation: creationData,
            setDataCreateEvaluation: setSelfRetrospective,
            setScore,
          }),
          scoreOptions: optionsScores,
          scorePlaceholder: formatMessage(messages.SelfRetrospectiveSelectRate),
          scoreValue: creationData?.score || score,
        }}
        textArea={{
          comment: creationData?.comment || comment,
          placeholder: formatMessage(messages.SelfRetrospectivePlaceholder),
          onTextChange: (event) =>
            handleCommentChange(event, creationData, setSelfRetrospective, setComment),
        }}
        title={formatMessage(messages.SelfRetrospectiveRate)}
      />

      <EvaluationsButtons
        deleteDraft={{
          deleteDraft: handleDeleteDraftModal,
          isDeleteDraftVisible: hasDraft,
        }}
        saveDraft={{
          isDraftDisabled: !isValidate(),
          isDraftVisible: allowsSelfRetrospectiveDraft,
          saveDraft: saveOrUpdateDraft,
        }}
        sendEvaluation={{
          isCreationVisible: allowsSelfRetrospectiveCreation,
          isSendEvaluationDisabled: !isValidate(),
          sendEvaluation: createOrSubmitEvaluation,
        }}
        type={mainActionText}
      />
      <EvaluationDraftAlertDelete />
      <EvaluationDraftAlertSubmit />
      <SendEvaluationConfirmationModal />
    </Evaluation>
  );
}

SelfRetrospective.propTypes = {
  creationData: PropTypes.object.isRequired,
  evaluationPeriod: PropTypes.object.isRequired,
  getProfile: PropTypes.func.isRequired,
  goToMyEvaluation: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  ratings: PropTypes.object.isRequired,
  setDataCreateEvaluation: PropTypes.func.isRequired,
};

const mapSateToProps = (state) => ({
  allowsSelfRetrospectiveCreation: selectConfigurations('allowsSelfRetrospectiveCreation')(state),
  allowsSelfRetrospectiveDraft: selectConfigurations('allowsSelfRetrospectiveDraft')(state),
  behavioursData: selectSelfRetrospectiveBehaviours(state),
  createSaveEvaluationLoading: selectCreateSaveEvaluationLoading(state),
  creationData: selectSelfRetrospective(state),
  draftInfo: selectEvaluationDetail(state),
  draftInfoLoading: selectIsFetching(state),
  evaluationPeriod: selectEvaluationPeriod(state),
  files: selectFiles(state),
  loadingDeleteDraft: selectLoadingDeleteDraft(state),
  loadingFiles: selectFilesLoading(state),
  loadingProfile: selectLoadingGetProfile(state),
  optionsScores: selectOptionsScores(state),
  profile: selectProfile(state),
  ratings: selectRatings(state),
  skills: selectEvaluationsSkills(state),
  tierType: selectTierType(state),
  updateSubmitEvaluationLoading: selectUpdateSubmitEvaluationLoading(state),
});

const mapDispatchToProps = {
  addToastMessage,
  closeModal,
  createSaveEvaluation,
  deleteDraftEvaluation,
  deleteFiles,
  draftFiles,
  evaluationCleanSkills,
  getEvaluationDetail,
  getProfile,
  getTierType,
  goToMyEvaluation: () => push(getEvaluationsLocation()),
  insertFiles,
  openModal,
  resetDataCreateEvaluation,
  resetEvaluationDetail,
  resetFiles,
  resetSelectLeader,
  resetSelfRetrospective,
  resetSelfRetrospectiveBehaviours,
  setSelfRetrospective,
  setSelfRetrospectiveBehaviours,
  updateSubmitEvaluation,
  uploadFiles,
};

export default connect(
  mapSateToProps,
  mapDispatchToProps
)(injectIntl(SelfRetrospective));
