// @packages
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { injectIntl } from 'react-intl';
import { isNil, isEmpty } from 'lodash';
import { closeModal, openModal } from 'smu-app-components/RootModal/actions';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import Segment from 'smu-ui-components/Segment';
import Divider from 'smu-ui-components/Divider';
import GenericEmptyState from 'smu-ui-components/GenericEmptyState';
import Collapsible from 'smu-ui-components/Collapsible';
import UserAvatarList from 'smu-ui-components/UserAvatarList';
import scrollToComponent from 'react-scroll-to-component';

// @app
import {
  actionsMessages,
  commonMessages,
  evaluationsMessages,
  feedbackMessages,
  promotionsMessages,
  userMessages,
} from 'common/messages';
import ActionButtons from 'components/molecules/ActionButtons';
import BreadcrumbHeader from 'components/organisms/BreadcrumbHeader';
import ValidatorSelector from './ValidatorSelector';
import ContentCard from 'common/ContentCard';
import DeleteFileModal from 'common/DeleteFileModal';
import PositionsHistory from 'common/PositionsHistory';
import SuggestPromotionForm from 'common/SuggestPromotionForm';
import UserDetails from 'components/containers/UserDetails';
import { formatDateV2 } from 'utils/formatDateV2';

import {
  EXCEEDED_SIZE,
  MAX_FILE_SIZE,
  MAX_FILES,
  NO_SIZE,
  SUPPORTED_FORMATS,
  WRONG_FORMAT,
} from 'betterme-components/constants/fileValidations';
import { runValidations } from 'utils/filesAttach';
import formatScore from 'utils/formatScore';
import getMessageType from 'utils/getMessageType';
import {
  ACCEPT,
  ACCEPTED_BY_MANAGER,
  ASSIGN_MANAGER,
  CREATE,
  DECLINE,
  LEADER_REVIEW,
  MAIN_LEADER,
  MANAGER,
  MANAGER_REVIEW,
  SECONDARY_LEADER,
  TALENT_REVIEW,
} from 'betterme-components/constants/promotions';
import { ORDERING_ASC } from 'betterme-components/constants/filterTypes';
import {
  getMyProfileLocation,
  getMyTeamPromotionsLocation,
  getNotificationHistoryLocation,
} from 'betterme-components/routes/urls';
import { requestRatedTopics } from 'betterme-components/Dashboard/actions';
import { getAssignedGoals } from 'betterme-components/Goals/actions';
import { getGoalsFinalScore } from 'betterme-components/Evaluations/actions';
import { getFeedbackForLeaders } from 'betterme-components/services/FeedbackForLeaders/actions';
import {
  selectAllCollaborators,
  selectCollaboratorsAsMainAndSecondary,
  selectCollaboratorsAsSecondary,
  selectCommunityId,
  selectConfigurations,
  selectEvaluationPeriod,
  selectUserInfo,
} from 'betterme-components/Authorization/selectors';
import {
  selectGoalsFinalScore,
  selectLoadingLastSelfEvaluation,
} from 'betterme-components/Evaluations/selectors';
import {
  selectAssignedGoals,
  selectLoadingMyGoals,
} from 'betterme-components/Goals/selectors';
import {
  selectFeedbackForLeadersSummaryResult,
  selectFeedbackForLeadersEvaluations,
} from 'betterme-components/services/FeedbackForLeaders/selectors';
import { selectRatedTopics, selectLoading } from 'betterme-components/Dashboard/selectors';
import FeedbackOverview from 'betterme-components/FeedbackOverview';
import SelfEvaluationListWidget from 'components/containers/WidgetSelfEvaluationList';
import GoalOverviewWidget from 'components/containers/WidgetGoalOverview';
import { CLOSED_STATUS } from 'betterme-components/Goals/constants';
import FeedbackForLeaders from 'betterme-components/components/containers/WidgetFeedbackForLeaders';
import PreviousRequest from 'betterme-components/PreviousPromotionRequest';
import { ACCEPT_DECLINE_PROMOTION_MODAL } from 'betterme-components/constants/modalTypes';
import AcceptDeclinePromotion from 'components/containers/AcceptDeclinePromotionModal';

// @own
import './styles.scss';
import * as actions from './actions';
import {
  selectLoadingPositionsHistory,
  selectLoadingPromotionsFiles,
  selectLoadingPromotionsLevel,
  selectLoadingSubmit,
  selectLoadingUser,
  selectLoadingUserPreviousRequests,
  selectPositionsHistory,
  selectPromotionCollaborator,
  selectPromotionProcessAllowed,
  selectPromotionSuggestedValidators,
  selectPromotionsFiles,
  selectPromotionsLevel,
  selectUserPreviousRequestsFiltered,
} from './selectors';

const SuggestPromotion = ({
  addToastMessage,
  allCollaborators,
  allowsFeedbackForLeadersResult,
  allowsGoals,
  allowsTeamEvaluationsGoalScore,
  closeModal,
  collaborator,
  collaboratorsAsMainAndSecondary,
  collaboratorsAsSecondary,
  communityId,
  createPromotionRequest,
  deleteFile,
  evaluationPeriod: { startTime, endTime },
  feedbackForLeadersSummaryResult,
  files,
  getAssignedGoals,
  getFeedbackForLeaders,
  getGoalsFinalScore,
  getPromotionCollaborator,
  getPromotionSuggestedValidators,
  getUserPromotionRequests,
  goToMyNotificationHistory,
  goToMyProfile,
  goToMyTeamPromotions,
  goalsFinalScore,
  intl: { formatMessage },
  loadingFiles,
  loadingLastSelfEvaluation,
  loadingOverviewGoals,
  loadingPositionsHistory,
  loadingPreviousRequests,
  loadingPromotionsLevel,
  loadingRatedTopics,
  loadingSubmit,
  loadingUser,
  openModal,
  overviewGoals,
  overviewRatedTopics,
  params: { userId },
  positionsHistory,
  promotionProcessAllowed,
  promotionsLevel,
  requestRatedTopics,
  resetSuggestPromotion,
  suggestedValidators,
  uploadPromotionsFile,
  userInfo,
  userPreviousRequestsFiltered,
}) => {
  const [feedbackForLeadersOpen, setFeedbackForLeadersOpen] = useState(false);
  const [feedbackOverviewOpen, setFeedbackOverviewOpen] = useState(false);
  const [goalsOverviewOpen, setGoalsOverviewOpen] = useState(false);
  const [historyOpen, setHistoryOpen] = useState(true);
  const [justification, setJustification] = useState('');
  const [previousRequestOpen, setPreviousRequestOpen] = useState(true);
  const [promoteValue, setPromoteValue] = useState(null);
  const [userSelfEvaluationOpen, setUserSelfEvaluationOpen] = useState(false);
  const [promotionValidator, setPromotionValidator] = useState({});
  const [hasValidatorAlreadyResponded, setHasValidatorAlreadyResponded] = useState(false);

  const scrollRef = useRef(null);

  useEffect(() => {
    const lastSeniorityMsg = formatMessage(promotionsMessages.PromotionsNoMoreSeniority);
    if (userId) {
      getPromotionCollaborator({
        identification: userId,
        messages: {
          lastLevel: lastSeniorityMsg,
        }
      });
    }

    scrollToComponent(scrollRef.current, {
      align: 'top',
      offset: -window.innerHeight,
    });

  }, []);

  useEffect(() => {
    const collaboratorId = collaborator.id;

    if (collaboratorId) {
      getUserPromotionRequests({
        userId: collaboratorId,
        ordering: ORDERING_ASC,
        status: `${ASSIGN_MANAGER}, ${LEADER_REVIEW}, ${MANAGER_REVIEW}, ${TALENT_REVIEW}`,
      });


      if (showAddValidator) {
        getPromotionSuggestedValidators(collaborator?.identification);
      };

      const isCollaborator = allCollaborators.some((collaboratorId) =>
        collaborator?.id === collaboratorId
      );

      if (isCollaborator) {
        if (collaborator.access?.feedback) {
          getRatedTopics();
        }


        if (allowsGoals) {
          const goalsFilters = {
            status: CLOSED_STATUS,
            dueDateFrom: startTime,
            dueDateTo: endTime,
          };

          getAssignedGoals(
            collaboratorId,
            goalsFilters,
          );

          if (allowsTeamEvaluationsGoalScore) {
            getGoalsFinalScore(
              collaboratorId,
              goalsFilters.dueDateFrom,
              goalsFilters.dueDateTo,
            );
          }
        };

        if (allowsFeedbackForLeadersResult) {
          getFeedbackForLeaders(collaboratorId);
        };
      }
    }
  }, [collaborator, userInfo]);

  useEffect(() => {
    return () => {
      resetSuggestPromotion();
    }
  }, [])

  useEffect(() => {
    const validatorHasResponded = userPreviousRequestsFiltered.some((request) =>
      request?.requester?.id === userInfo?.id && request?.requesterType === MANAGER
    );
    if (validatorHasResponded) {
      setHasValidatorAlreadyResponded(true);
    };
  }, [userPreviousRequestsFiltered])

  useEffect(() => {
    const selectedLevel = userPreviousRequestsFiltered?.[0]?.level;
    if (selectedLevel) {
      setPromoteValue(selectedLevel?.id);
    } else {
      setPromoteValue(promotionsLevel?.[0]?.id);
    }
  }, [promotionsLevel, userPreviousRequestsFiltered]);

  const hasPreviousRequests = userPreviousRequestsFiltered.length > 0;
  const promotionStatus = userPreviousRequestsFiltered?.[0]?.status;
  const promotionRequesterType = userPreviousRequestsFiltered?.[0]?.requesterType;

  function isCollaborator(collaboratorId) {
    return allCollaborators.some((collaborator) =>
      collaborator?.id === collaboratorId
    )
  };

  function isCollaboratorAsMainAndSecondary(collaboratorId) {
    return collaboratorsAsMainAndSecondary.some((collaborator) =>
      collaborator?.id === collaboratorId
    )
  };

  function isCollaboratorAsSecondary(collaboratorId) {
    return collaboratorsAsSecondary.some((collaborator) =>
      collaborator?.id === collaboratorId
    )
  };

  function handleDeleteFile(fileId) {
    deleteFile(fileId);
    closeModal();
  }

  function handleTextChange(event) {
    setJustification(event.target.value);
  }

  function handleSelectPromotionLevel(promoteValue) {
    setPromoteValue(promoteValue);
  }

  const successToastMessage = {
    [ACCEPTED_BY_MANAGER]: formatMessage(promotionsMessages.PromotionsAcceptedByValidatorMessageToast,
      { username: collaborator?.firstName }),
    [ACCEPT]: formatMessage(promotionsMessages.PromotionsSuccessMessageToast),
    [ASSIGN_MANAGER]: formatMessage(promotionsMessages.PromotionsAssignValidatorMessageToast,
      {
        username: collaborator?.firstName,
        validatorName: promotionValidator?.firstName,
      }),
    [CREATE]: formatMessage(promotionsMessages.PromotionsSuccessMessageRequest,
      { username: collaborator?.firstName }),
    [DECLINE]: formatMessage(promotionsMessages.PromotionsDeclineMessageToast,
      { username: collaborator?.firstName }),
  };

  const handleRequestStatus = (currentAction) => {
    const validatorMessages = promotionStatus === ASSIGN_MANAGER
      ? ASSIGN_MANAGER
      : promotionStatus === MANAGER_REVIEW
        ? ACCEPTED_BY_MANAGER
        : ACCEPT;
    const acceptDeclineStatus = currentAction === DECLINE ? DECLINE : validatorMessages;
    const requestStatus = currentAction === CREATE ? CREATE : acceptDeclineStatus;
    return requestStatus;
  }

  function handleAction(currentAction, isWithValidator) {
    const withValidator = isWithValidator ? Number(promotionValidator.id) : null;
    const action = currentAction !== CREATE
      ? currentAction
      : null;
    const managerId = (currentAction === CREATE || currentAction === ACCEPT)
      ? withValidator
      : null;
    const requestStatus = handleRequestStatus(currentAction);
    const data = {
      action,
      comment: justification,
      files: files.map(file => file.id),
      levelId: promoteValue,
      promotedId: Number(collaborator.id),
      managerId,
    };

    createPromotionRequest({
      data,
      messages: successToastMessage[requestStatus],
      promotionStatus,
    });
  }

  function handleFileAttach(e) {
    const { target } = e;
    const { files: listFiles } = target;
    const errorMessages = {
      [EXCEEDED_SIZE]: formatMessage(promotionsMessages.PromotionsErrorExceededSize, {
        size: MAX_FILE_SIZE
      }),
      [NO_SIZE]: formatMessage(promotionsMessages.PromotionsErrorNoSize),
      [WRONG_FORMAT]: formatMessage(promotionsMessages.PromotionsErrorInvalidFormat, {
        formats: SUPPORTED_FORMATS,
      }),
    }

    if (listFiles.length) {
      const file = listFiles[0];
      const error = runValidations(file, MAX_FILE_SIZE, SUPPORTED_FORMATS);

      if (!error) {
        uploadPromotionsFile(file);
        target.value = null;
      } else {
        addToastMessage({
          message: errorMessages[error],
          timeout: 3000,
        });
        target.value = null;
      }
    }
  }

  function handleGoalFilterChange(value) {
    const goalsFilters = {
      dueDateFrom: startTime,
      dueDateTo: endTime,
      status: value,
    };

    getAssignedGoals(collaborator.id, goalsFilters);
  }

  function getRatedTopics() {
    requestRatedTopics(
      collaborator.id,
      startTime,
      endTime,
    );
  }

  function getSectionTitle() {
    if (!hasPreviousRequests) {
      return formatMessage(promotionsMessages.PromotionsRequestPromotionTitle);
    } else if (promotionStatus === LEADER_REVIEW) {
      return formatMessage(promotionsMessages.PromotionsAcceptRequestTitle);
    } else if (promotionStatus === MANAGER_REVIEW) {
      return formatMessage(promotionsMessages.PromotionsAcceptRequestValidatorTitle);
    } else if (promotionStatus === ASSIGN_MANAGER) {
      return formatMessage(promotionsMessages.PromotionsAssignValidator);
    }
  }

  function getLabelSecondaryButton() {
    if (!hasPreviousRequests) {
      return formatMessage(actionsMessages.ActionsCancel);
    } else if (promotionStatus === LEADER_REVIEW) {
      return formatMessage(actionsMessages.ActionsDecline);
    } else if (promotionStatus === MANAGER_REVIEW || promotionStatus === TALENT_REVIEW) {
      return formatMessage(actionsMessages.ActionsDecline);
    } else if (promotionStatus === ASSIGN_MANAGER) {
      return formatMessage(actionsMessages.ActionsCancel);
    }
  }

  function getLabelPrimaryButton() {
    if (!hasPreviousRequests) {
      return formatMessage(actionsMessages.ActionsRequest);
    } else if (promotionStatus === LEADER_REVIEW) {
      return formatMessage(actionsMessages.ActionsAccept);
    } else if (promotionStatus === MANAGER_REVIEW || promotionStatus === TALENT_REVIEW) {
      return formatMessage(actionsMessages.ActionsAccept);
    } else if (promotionStatus === ASSIGN_MANAGER) {
      return formatMessage(actionsMessages.ActionsAssign);
    }
  }

  function getCardTitle(key) {
    if (key === 0) {
      return formatMessage(promotionsMessages.PromotionsRequestedBy);
    } else {
      return formatMessage(promotionsMessages.PromotionsValidatedBy);
    }
  }

  function getBreadcrumbBack() {
    if (!promotionProcessAllowed) {
      return formatMessage(userMessages.UserMyProfile);
    } else if (promotionStatus === MANAGER_REVIEW || promotionStatus === TALENT_REVIEW) {
      return formatMessage(commonMessages.EvaluationsNotifications);
    } else {
      return formatMessage(promotionsMessages.PromotionsMyTeamsPromotion);
    }
  }

  function getBreadcrumbBackAction() {
    if (!promotionProcessAllowed) {
      return goToMyProfile;
    } else if (promotionStatus === MANAGER_REVIEW || promotionStatus === TALENT_REVIEW) {
      return goToMyNotificationHistory;
    } else {
      return goToMyTeamPromotions;
    }
  }

  const {
    currentSeniority,
    firstName,
    lastName,
    hasCollaborators,
  } = collaborator;
  const userFullName = firstName ? `${firstName} ${lastName}` : '';
  const originalRequestAuthor = hasPreviousRequests &&
    `${userPreviousRequestsFiltered?.[0]?.requester?.firstName}
    ${userPreviousRequestsFiltered?.[0]?.requester?.lastName}`;
  const hasPositionsHistory = positionsHistory.length > 0;
  const getMessage = getMessageType(communityId);
  const titleEvaluationsSelfEvaluations = getMessage('EvaluationsSelfEvaluations');
  const showFeedbackForLeaders = (hasCollaborators || !isEmpty(feedbackForLeadersSummaryResult)) && allowsFeedbackForLeadersResult;
  const previousRequesters = userPreviousRequestsFiltered.map(({ requester }) => requester);
  const isFirstElement = index => index === 0;
  const sectionTitle = getSectionTitle();
  const labelSecondaryButton = getLabelSecondaryButton();
  const labelPrimaryButton = getLabelPrimaryButton();
  const isMyCollaborator = isCollaborator(collaborator.id);
  const showJustification = promotionStatus !== ASSIGN_MANAGER;
  const allowsAddValidator = hasPreviousRequests
    && promotionRequesterType === SECONDARY_LEADER
    && isCollaboratorAsSecondary(collaborator.id);
  const allowsAddValidatorInApprove = hasPreviousRequests
    && promotionStatus === LEADER_REVIEW
    && promotionRequesterType === MAIN_LEADER
    && (isCollaboratorAsMainAndSecondary(collaborator.id) || isCollaboratorAsSecondary(collaborator.id));
  const allowsAddValidatorInCreate = !hasPreviousRequests
    && (isCollaboratorAsMainAndSecondary(collaborator.id) || isCollaboratorAsSecondary(collaborator.id));
  const allowsAddValidatorInReAssign = promotionStatus === ASSIGN_MANAGER;
  const showAddValidator = allowsAddValidator
    || allowsAddValidatorInApprove
    || allowsAddValidatorInCreate
    || allowsAddValidatorInReAssign;
  const canDecline = () => (!!justification);
  const canSubmit = (submitType, isWithValidator) => {
    const validateJustification = !showJustification || !!justification;
    const validateCreatePromotion = !!promoteValue && validateJustification;
    const validateAcceptPromotion = validateJustification;
    return (
      (submitType === CREATE
        ? validateCreatePromotion
        : validateAcceptPromotion
      ) && (
        !isWithValidator || !!promotionValidator.id
      )
    );
  };

  const date = userPreviousRequestsFiltered?.length ?
    formatDateV2(userPreviousRequestsFiltered[0].creationDate, 'monthYearFormat') : '';

  function handleAcceptDeclineModal(actionType) {
    if (promotionStatus === ASSIGN_MANAGER) {
      handleAction(ACCEPT, showAddValidator);
    } else {
      openModal({
        modalType: ACCEPT_DECLINE_PROMOTION_MODAL,
        modalProps: {
          acceptPromotion: () => handleAction(actionType, showAddValidator),
          actionType,
          declinePromotion: () => handleAction(DECLINE, showAddValidator),
        }
      });
    }
  }

  function validatorSelectorHandler(leader) {
    setPromotionValidator(leader);
  };

  return (
    <Segment
      className="send-suggest-promotion"
      loading={loadingUser || loadingPromotionsLevel}
      loadingType="betterme"
    >
      <BreadcrumbHeader
        breadCrumbsProps={{
          active: 1,
          onClick: getBreadcrumbBackAction(),
          showEvaluationPeriod: true,
          size: 'big',
          values: [
            getBreadcrumbBack(),
            userFullName,
          ],
        }}
        noFixed
        date={date}
      />
      {(!hasValidatorAlreadyResponded && promotionProcessAllowed) && (
        <>
          <div
            className="send-suggest-promotion__content"
            ref={scrollRef}
          >
            <div className="send-suggest-promotion__main-column">
              <h3 className="send-suggest-promotion__main-title">
                {sectionTitle}
              </h3>
              {hasPreviousRequests && (
                <div className="send-suggest-promotion__requesters">
                  <UserAvatarList
                    className="send-suggest-promotion__requesters-list"
                    maxDisplay={4}
                    size={35}
                    users={previousRequesters}
                    userAvatarClassName={() => "send-suggest-promotion__requester-avatar"} />
                  <div className="send-suggest-promotion__requesters-text">
                    {formatMessage(promotionsMessages.PromotionsCreatedBy)}
                    <span className="send-suggest-promotion__requesters-original-requester">
                      {originalRequestAuthor}
                    </span>
                  </div>
                </div>
              )}
              <Divider />

              <SuggestPromotionForm
                attachFileValues={{
                  files: files,
                  loading: loadingFiles,
                  maxFiles: MAX_FILES,
                  onChange: handleFileAttach,
                  onDeleteAccept: handleDeleteFile,
                  supportedFormats: SUPPORTED_FORMATS,
                }}
                justificationValues={{
                  onChange: handleTextChange,
                  placeholder: formatMessage(promotionsMessages.PromotionsJustificationPlaceholder),
                  showJustification,
                  title: formatMessage(promotionsMessages.PromotionsWhyPromote),
                  value: justification,
                }}
                seniorityValues={{
                  currentSeniority: {
                    name: currentSeniority && currentSeniority.name,
                    dateFromNow: currentSeniority && currentSeniority.startDate,
                  },
                  promoteOnChange: handleSelectPromotionLevel,
                  promoteToOptions: promotionsLevel,
                  promoteToTitle: formatMessage(promotionsMessages.PromotionsPromoteTo),
                  promoteToValue: promoteValue,
                  title: formatMessage(promotionsMessages.PromotionsCurrentSeniority),
                }}
                parentRequest={hasPreviousRequests && userPreviousRequestsFiltered[0]} />

              {showAddValidator && (
                <ValidatorSelector
                  description={formatMessage(promotionsMessages.PromotionsAddValidatorDescription)}
                  emptyMessage={formatMessage(promotionsMessages.PromotionsNoValidatorSuggestions)}
                  onChange={validatorSelectorHandler}
                  placeholder={formatMessage(promotionsMessages.PromotionsAddValidatorMessage)}
                  suggestedValidators={suggestedValidators}
                  title={formatMessage(promotionsMessages.PromotionsAssignValidator)}
                />
              )}

              <Segment
                className="send-suggest-promotion__previous-requests"
                loading={loadingPreviousRequests}
                loadingType="betterme"
              >
                {hasPreviousRequests && (
                  <Collapsible
                    baseHeight={5}
                    expanded={previousRequestOpen}
                    onClick={() => setPreviousRequestOpen(!previousRequestOpen)}
                    title={formatMessage(promotionsMessages.PromotionsPreviousRequests)}
                    withIcon
                  >
                    {userPreviousRequestsFiltered.map((request, key) => (
                      <PreviousRequest
                        {...request}
                        cardTitle={getCardTitle(key)}
                        highlightRequester={isFirstElement(key)}
                        key={key} />
                    ))}
                  </Collapsible>
                )}
              </Segment>
              <Segment
                className="send-suggest-promotion__actions"
                loading={loadingSubmit}
                loadingType="betterme"
              >
                <ActionButtons
                  buttons={[
                    {
                      disabled: hasPreviousRequests && !canDecline(),
                      hide: allowsAddValidator,
                      label: labelSecondaryButton,
                      onClick: hasPreviousRequests
                        ? () => handleAcceptDeclineModal(DECLINE)
                        : goToMyTeamPromotions,
                      variant: 'outline',
                    },
                    {
                      disabled: hasPreviousRequests
                        ? !canSubmit(ACCEPT, showAddValidator)
                        : !canSubmit(CREATE, showAddValidator),
                      label: labelPrimaryButton,
                      onClick: () => handleAcceptDeclineModal(hasPreviousRequests ? ACCEPT : CREATE),
                    },
                  ]} />
              </Segment>
            </div>
            <div className="send-suggest-promotion__side-column">
              <UserDetails
                {...collaborator}
                className="send-suggest-promotion__user-details"
                loading={loadingPositionsHistory} />
              {isMyCollaborator && (
                <>
                  <Segment
                    className="send-suggest-promotion__positions-history"
                    loading={loadingPositionsHistory}
                    loadingType="betterme"
                  >
                    {hasPositionsHistory && (
                      <ContentCard
                        title={formatMessage(promotionsMessages.PromotionsPositionsHistory)}
                        open={historyOpen}
                        onToggleClick={() => setHistoryOpen(!historyOpen)}
                      >
                        <ContentCard.Body>
                          <PositionsHistory positionsHistory={positionsHistory} />
                        </ContentCard.Body>
                      </ContentCard>
                    )}
                  </Segment>

                  <Segment
                    className="send-suggest-promotion__feedback-overview"
                    loading={loadingRatedTopics}
                    loadingType="betterme"
                  >
                    {overviewRatedTopics.length > 0 && (
                      <ContentCard
                        onToggleClick={() => setFeedbackOverviewOpen(!feedbackOverviewOpen)}
                        open={feedbackOverviewOpen}
                        title={formatMessage(feedbackMessages.FeedbackOverview)}
                      >
                        <ContentCard.Body>
                          <FeedbackOverview ratedTopics={overviewRatedTopics} />
                        </ContentCard.Body>
                      </ContentCard>
                    )}
                  </Segment>

                  <Segment
                    className="send-suggest-promotion__self-evaluation"
                    loading={loadingLastSelfEvaluation}
                    loadingType="betterme"
                  >
                    <SelfEvaluationListWidget
                      onToggleClick={() => setUserSelfEvaluationOpen(!userSelfEvaluationOpen)}
                      open={userSelfEvaluationOpen}
                      title={formatMessage(titleEvaluationsSelfEvaluations)}
                    />
                  </Segment>

                  {showFeedbackForLeaders && (
                    <FeedbackForLeaders
                      feedbackForLeadersSummaryResult={feedbackForLeadersSummaryResult}
                      identification={collaborator?.identification}
                      onToggleClick={() => setFeedbackForLeadersOpen(!feedbackForLeadersOpen)}
                      open={feedbackForLeadersOpen}
                      user={collaborator} />
                  )}

                  {allowsGoals && (
                    <>
                      <GoalOverviewWidget
                        dateTimeFrom={startTime}
                        dateTimeTo={endTime}
                        defaultFilterOpen={false}
                        goals={overviewGoals}
                        loading={loadingOverviewGoals}
                        onFilterChange={handleGoalFilterChange}
                        onToggleClick={() => setGoalsOverviewOpen(!goalsOverviewOpen)}
                        open={goalsOverviewOpen}
                        user={collaborator} />
                      {!isNil(goalsFinalScore.value) && (
                        <div className="send-suggest-promotion__goals-final-score">
                          <span className="send-suggest-promotion__goals-final-score-text">
                            {`${formatMessage(evaluationsMessages.EvaluationsGoalsFinalScore)}:`}
                          </span>
                          {formatScore(goalsFinalScore)}
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <DeleteFileModal />
          <AcceptDeclinePromotion />
        </>
      )}
      {hasValidatorAlreadyResponded &&
        <GenericEmptyState
          className="mt-[70px] h-[calc(100vh-140px)]"
          image="dartboard"
          title={formatMessage(promotionsMessages.PromotionsRequestEmptyTitle1)}
        />
      }
      {!promotionProcessAllowed &&
        <GenericEmptyState
          className="mt-[70px] h-[calc(100vh-140px)]"
          image="dartboard"
          description={formatMessage(promotionsMessages.PromotionsRequestEmptyDescription2)}
          title={formatMessage(promotionsMessages.PromotionsRequestEmptyTitle2)}
        />
      }
    </Segment >
  );
};

SuggestPromotion.propTypes = {
  allCollaborators: PropTypes.array,
  allowsGoals: PropTypes.bool.isRequired,
  allowsTeamEvaluationsGoalScore: PropTypes.bool,
  collaboratorsAsMainAndSecondary: PropTypes.array,
  collaboratorsAsSecondary: PropTypes.array,
  getAssignedGoals: PropTypes.func.isRequired,
  getGoalsFinalScore: PropTypes.func.isRequired,
  getPromotionsLevel: PropTypes.func.isRequired,
  goalsFinalScore: PropTypes.object,
  loadingOverviewGoals: PropTypes.bool,
  loadingPositionsHistory: PropTypes.bool,
  loadingPromotionsLevel: PropTypes.bool,
  overviewGoals: PropTypes.array,
  positionsHistory: PropTypes.array,
  promotionsLevel: PropTypes.array,
  userPreviousRequestsFiltered: PropTypes.array,
};

const mapStateToProps = state => ({
  allCollaborators: selectAllCollaborators(state),
  allowsFeedbackForLeadersResult: selectConfigurations('allowsFeedbackForLeadersResult')(state),
  allowsGoals: selectConfigurations('allowsGoals')(state),
  allowsTeamEvaluationsGoalScore: selectConfigurations('allowsTeamEvaluationsGoalScore')(state),
  collaborator: selectPromotionCollaborator(state),
  collaboratorsAsMainAndSecondary: selectCollaboratorsAsMainAndSecondary(state),
  collaboratorsAsSecondary: selectCollaboratorsAsSecondary(state),
  communityId: selectCommunityId(state),
  evaluationPeriod: selectEvaluationPeriod(state),
  feedbackForLeadersEvaluations: selectFeedbackForLeadersEvaluations(state),
  feedbackForLeadersSummaryResult: selectFeedbackForLeadersSummaryResult(state),
  files: selectPromotionsFiles(state),
  goalsFinalScore: selectGoalsFinalScore(state),
  loadingFiles: selectLoadingPromotionsFiles(state),
  loadingLastSelfEvaluation: selectLoadingLastSelfEvaluation(state),
  loadingOverviewGoals: selectLoadingMyGoals(state),
  loadingPositionsHistory: selectLoadingPositionsHistory(state),
  loadingPreviousRequests: selectLoadingUserPreviousRequests(state),
  loadingPromotionsLevel: selectLoadingPromotionsLevel(state),
  loadingRatedTopics: selectLoading(state),
  loadingSubmit: selectLoadingSubmit(state),
  loadingUser: selectLoadingUser(state),
  overviewGoals: selectAssignedGoals(state),
  overviewRatedTopics: selectRatedTopics(state),
  positionsHistory: selectPositionsHistory(state),
  promotionProcessAllowed: selectPromotionProcessAllowed(state),
  promotionsLevel: selectPromotionsLevel(state),
  suggestedValidators: selectPromotionSuggestedValidators(state),
  userInfo: selectUserInfo(state),
  userPreviousRequestsFiltered: selectUserPreviousRequestsFiltered(state),
});

const goToMyNotificationHistory = () => push(getNotificationHistoryLocation());
const goToMyProfile = () => push(getMyProfileLocation());
const goToMyTeamPromotions = () => push(getMyTeamPromotionsLocation());
const goToPath = (path) => push(path);

const mapDispatchToProps = {
  ...actions,
  addToastMessage,
  closeModal,
  getAssignedGoals,
  getFeedbackForLeaders,
  getGoalsFinalScore,
  goToMyNotificationHistory,
  goToMyProfile,
  goToMyTeamPromotions,
  goToPath,
  openModal,
  requestRatedTopics,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SuggestPromotion));
