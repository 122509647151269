// @packages
import PropTypes from 'prop-types';
import React from 'react';
import Segment from 'smu-ui-components/Segment';

// @own
import '../styles.scss';
import PromotionsTable from './PromotionsTable';

const TeamPromotionsTab = ({ myTeamFilters, user }) => {
  return (
    <div className="team-promotions">
      <Segment
        loadingType="betterme"
        className="team-promotions__content"
      >
        <PromotionsTable 
          user={user}
          myTeamFilters={myTeamFilters}
          newStyle
        />
      </Segment>
    </div>
  );
}

TeamPromotionsTab.propTypes = {
  myTeamFilters: PropTypes.object.isRequired,
};

export default TeamPromotionsTab;
