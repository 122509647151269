// @packages
import React from 'react';
import { injectIntl } from 'react-intl';

// @app
import FeedbackReceived from '../FeedbackReceived';
import { userMessages } from 'common/messages';

const MyFeedbackReceived = ({
  intl: { formatMessage },
  location,
  session: { user },
}) => {
  return (<FeedbackReceived
    location={location}
    navigateBackLabel={formatMessage(userMessages.UserMyProfile)}
    navigateBackTo="/myprofile"
    user={user}
  />);
};

export default injectIntl(MyFeedbackReceived);
