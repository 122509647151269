// @packages
import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Icon from 'smu-ui-components/IconV2';

// @own
import './styles.scss';

const MessageBox = ({
  canBeClosed,
  className,
  closeIconSize,
  deeplink, //to be implemented with clickable messages.
  icon,
  iconColor,
  iconSize,
  message,
  onClose,
  variant,
}) => (
  <div className={cn('message-box', className, {
    [`message-box--variant-${variant}`]: variant,
  })}>
    <div className="message-box__wrapper">
      {icon && (
        <Icon
          className="message-box__icon"
          color={iconColor}
          icon={icon}
          size={iconSize}
        />
      )}
      <div className={cn('message-box__text', {
        'message-box__text--without-icon': !icon,
        })}
      >
        {message}
      </div>
      {canBeClosed && (<Icon
        className="message-box__close-icon"
        icon="close-sec"
        color="black"
        size={closeIconSize}
        onClick={onClose}
      />
      )}
    </div>
  </div>
);

MessageBox.propTypes = {
  canBeClosed: PropTypes.bool,
  className: PropTypes.string,
  closeIconSize: PropTypes.string,
  deeplink: PropTypes.string,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  iconSize: PropTypes.number,
  message: PropTypes.string,
  onClosed: PropTypes.func,
  variant: PropTypes.oneOf([
    'alert',
    'informative',
    'success',
    'warning',
  ]),
};

MessageBox.defaultProps = {
  variant: 'informative',
};

export default MessageBox;
