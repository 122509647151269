// @packages
import { base64Decode } from 'smu-utils/base64';

// @app
import { adminApiInstance } from 'betterme-components/utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';

export function apiGetFocalUsers(params) {
  const { size, page, searchString, leadershipRelationType } = params;
  return adminApiInstance({
    url: 'v2/community/members',
    params: {
      direction: 'ASC',
      findOnlyEnabled: true,
      findOnlyInSmu: false,
      leadershipRelationType: leadershipRelationType === 'ALL' ? '' : leadershipRelationType,
      orderBy: 'firstName',
      page,
      searchString: searchString || null,
      size,
    }
  })
    .then(({ data }) => data)
    .catch(errorHandler);
};

export function apiGetLeadershipAdminUser(params) {
  const { id } = params;
  return adminApiInstance({
    url: `v2/community/members/${base64Decode(id)}`
  })
    .then(({ data }) => data?.result)
    .catch(errorHandler);
};

export function apiGetUserRolesAndPermissions() {
  return adminApiInstance({
    url: 'v2/community/user',
  })
    .then(({ data }) => data?.result);
};
