import { trackEvent } from 'smu-utils/gtm';
import { REQUEST_FEEDBACK } from '../SendRequestFeedback/analytics';
const FEEDBACK_REQUEST_INBOX = 'Feedback_Request_Inbox';

export const trackListClick = () => {
  trackEvent({
    category: REQUEST_FEEDBACK,
    action: 'List_Click',
  });
};

export const trackViewClick = () => {
  trackEvent({
    category: FEEDBACK_REQUEST_INBOX,
    action: 'View'
  });
}

export const trackModalUserNameClick = (feedbackType) => {
  trackEvent({
    category: 'Feedback_Request_Modal',
    action: 'Profile_Icon',
    label: feedbackType,
    type: 'fdbk_requested',
  });
};

export const trackViewAllClick = () => {
  trackEvent({
    category: FEEDBACK_REQUEST_INBOX,
    action: 'View_All_Inbox',
  });
};

export const trackModalAnswerButton = () => {
  trackEvent({
    category: 'Feedback_Request_Modal',
    action: 'Answer',
    type: 'fdbk_requested',
  });
}

export const trackModalDeclineButton = () => {
  trackEvent({
    category: 'Feedback_Request_Modal',
    action: 'Decline',
    type: 'fdbk_requested',
  });
}

export const trackModalDeclineCancelButton = () => {
  trackEvent({
    category: 'Feedback_Request_Modal',
    action: 'Decline_Cancel',
    type: 'fdbk_requested',
  });
}

export const trackModalDeclineAcceptButton = (reason) => {
  trackEvent({
    category: 'Feedback_Request_Modal',
    action: 'Decline_Send',
    type: 'fdbk_requested',
    label: reason,
  });
}

export const trackCloseModalClick = () => {
  trackEvent({
    category: 'Feedback_Request_Modal',
    action: 'Close',
    type: 'fdbk_requested',
  });
}

export const trackTabAwaitingMyFeedback = () => {
  trackEvent({
    category: FEEDBACK_REQUEST_INBOX,
    action: 'AwaitingMyFeedback',
  });
};

export const trackFeedbackRequestInboxAnswer = () => {
  trackEvent({
    category: FEEDBACK_REQUEST_INBOX,
    action: 'AwaitingMyFeedback_Card_Answer',
  });
};

export const trackFeedbackRequestInboxDismiss = () => {
  trackEvent({
    category: FEEDBACK_REQUEST_INBOX,
    action: 'AwaitingMyFeedback_Card_Dismiss',
  });
};

export const trackTabRequestedByMe = () => {
  trackEvent({
    category: FEEDBACK_REQUEST_INBOX,
    action: 'RequestedByMe',
  });
};

export const trackFeedbackRequestInboxCancel = () => {
  trackEvent({
    category: FEEDBACK_REQUEST_INBOX,
    action: 'Tab_RequestedByMe_Card_Cancel',
  });
};
