export const BME_FEEDBACK_TEXT_SUGGESTION = 'BME_FEEDBACK_TEXT_SUGGESTION';
export const BME_GLOBER_PROFILE_PERFORMANCE_TYPES = 'BME_GLOBER_PROFILE_PERFORMANCE_TYPES';
export const CALENDAR_PROVIDER = 'calendar.provider';
export const ENABLED_ANNIVERSARIES = 'feature.anniversaries.enabled';
export const ENABLED_ASSESSMENT360 = 'feature.assessment360.enabled';
export const ENABLED_BETHERE_FEED = 'enabledBeThereFeed';
export const ENABLED_BETTERME = 'module.performance.enabled';
export const ENABLED_BIRTHDAYS = 'feature.birthdays.enabled';
export const ENABLED_COMMUNICATIONS = 'product.os.configuration.hasCommunication.enabled';
export const ENABLED_DISCOVERY_PEOPLE = 'enabledDiscoveryPeople';
export const ENABLED_DISENGAGEMENT_INDICATOR = 'feature.disengagementIndicator.enabled';
export const ENABLED_FEED_FILTERS = 'feature.feed.filters.enabled';
export const ENABLED_INTERCOM = 'feature.intercom.enabled';
export const ENABLED_MYTEAM_INSIGHTS = 'feature.insights.enabled';
export const ENABLED_MY_TEAM = 'module.myTeam.enabled';
export const ENABLED_OLD_FFL = 'feature.oldFfl.enabled';
export const ENABLED_ONE_ON_ONE = 'module.oneOnOne.enabled';
export const ENABLED_ONE_ON_ONE_REQUEST = 'module.oneOnOne.request.enabled';
export const ENABLED_ONE_ON_ONE_TP_AI_SUGGESTIONS = 'feature.talkingPointsSuggestions.enabled';
export const ENABLED_PENDING_TASKS = 'feature.pendingTasks.enabled';
export const ENABLED_PENDING_TASK_HOME = 'BME_SHOW_PENDING_TASK_HOME';
export const ENABLED_QUICK_ACCESS = 'product.os.configuration.hasQuickAccess.enabled';
export const ENABLED_SHARE_DNA = 'product.smu.share.dna.configuration.enabled';
export const ENABLED_STARMEUP = 'module.engagement.enabled';
export const ENABLED_SURVEYS_ONLY = 'product.survey.surveyOnly';
export const ENABLED_TAKEPART_SURVEY = 'enabledTakePartSurvey';
export const ENABLED_TALENT_MATRIX = 'module.talentMatrix.enabled'
export const ENABLED_YIR = 'feature.yearInReview.enabled';
export const MAIN_MENU = 'MAIN_MENU';
export const NEW_NOTIFICATION_SETTINGS = 'newNotificationSettings';
export const ONBOARDING_ENABLED = 'product.smu.onboarding.configuration.enabled';
export const STAR_SELECTOR_VERSION = 'starmeup.starSelector.version';
export const SURVEYS_THRESHOLD_NUMBER = 'SURVEY_RESULTS_THRESHOLD';

export const CONFIGURATIONS = [
  BME_FEEDBACK_TEXT_SUGGESTION,
  BME_GLOBER_PROFILE_PERFORMANCE_TYPES,
  CALENDAR_PROVIDER,
  ENABLED_ANNIVERSARIES,
  ENABLED_ASSESSMENT360,
  ENABLED_BETHERE_FEED,
  ENABLED_BETTERME,
  ENABLED_BIRTHDAYS,
  ENABLED_COMMUNICATIONS,
  ENABLED_DISCOVERY_PEOPLE,
  ENABLED_DISENGAGEMENT_INDICATOR,
  ENABLED_FEED_FILTERS,
  ENABLED_INTERCOM,
  ENABLED_MYTEAM_INSIGHTS,
  ENABLED_MY_TEAM,
  ENABLED_OLD_FFL,
  ENABLED_ONE_ON_ONE,
  ENABLED_ONE_ON_ONE_REQUEST,
  ENABLED_ONE_ON_ONE_TP_AI_SUGGESTIONS,
  ENABLED_PENDING_TASKS,
  ENABLED_PENDING_TASK_HOME,
  ENABLED_QUICK_ACCESS,
  ENABLED_SHARE_DNA,
  ENABLED_STARMEUP,
  ENABLED_SURVEYS_ONLY,
  ENABLED_TAKEPART_SURVEY,
  ENABLED_TALENT_MATRIX,
  ENABLED_YIR,
  MAIN_MENU,
  NEW_NOTIFICATION_SETTINGS,
  ONBOARDING_ENABLED,
  STAR_SELECTOR_VERSION,
  SURVEYS_THRESHOLD_NUMBER,
];
