
export const GET_FEEDBACKS_SUMMARY_TEAM = 'GET_FEEDBACKS_SUMMARY_TEAM';
export const GET_FEEDBACKS_SUMMARY_TEAM_FAIL = 'GET_FEEDBACKS_SUMMARY_TEAM_FAIL';
export const GET_FEEDBACKS_SUMMARY_TEAM_SUCCESS = 'GET_FEEDBACKS_SUMMARY_TEAM_SUCCESS';
export const GET_STARMEUP_KUDOS_INFO = 'GET_STARMEUP_KUDOS_INFO';
export const GET_STARMEUP_KUDOS_INFO_FAIL = 'GET_STARMEUP_KUDOS_INFO_FAIL';
export const GET_STARMEUP_KUDOS_INFO_SUCCESS = 'GET_STARMEUP_KUDOS_INFO_SUCCESS';
export const GET_TEAM_EVALUATIONS = 'GET_TEAM_EVALUATIONS';
export const GET_TEAM_EVALUATIONS_FAIL = 'GET_TEAM_EVALUATIONS_FAIL';
export const GET_TEAM_EVALUATIONS_SUCCESS = 'GET_TEAM_EVALUATIONS_SUCCESS';
export const GET_TEAM_MEMBER_FEEDBACK_RECEIVED = 'GET_TEAM_MEMBER_FEEDBACK_RECEIVED';
export const GET_TEAM_MEMBER_FEEDBACK_RECEIVED_FAIL = 'GET_TEAM_MEMBER_FEEDBACK_RECEIVED_FAIL';
export const GET_TEAM_MEMBER_FEEDBACK_RECEIVED_SUCCESS = 'GET_TEAM_MEMBER_FEEDBACK_RECEIVED_SUCCESS';
export const GET_TEAM_MEMBER_FEEDBACKS_SUMMARY = 'GET_TEAM_MEMBER_FEEDBACKS_SUMMARY';
export const GET_TEAM_MEMBER_FEEDBACKS_SUMMARY_FAIL = 'GET_TEAM_MEMBER_FEEDBACKS_SUMMARY_FAIL';
export const GET_TEAM_MEMBER_FEEDBACKS_SUMMARY_SUCCESS = 'GET_TEAM_MEMBER_FEEDBACKS_SUMMARY_SUCCESS';
export const GET_TEAM_MEMBER_OPPORTUNITIES = 'GET_TEAM_MEMBER_OPPORTUNITIES';
export const GET_TEAM_MEMBER_OPPORTUNITIES_FAIL = 'GET_TEAM_MEMBER_OPPORTUNITIES_FAIL';
export const GET_TEAM_MEMBER_OPPORTUNITIES_SUCCESS = 'GET_TEAM_MEMBER_OPPORTUNITIES_SUCCESS';
export const GET_TEAM_MEMBER_STARMEUP_STARS_SUMMARY = 'GET_TEAM_MEMBER_STARMEUP_STARS_SUMMARY';
export const GET_TEAM_MEMBER_STARMEUP_STARS_SUMMARY_FAIL = 'GET_TEAM_MEMBER_STARMEUP_STARS_SUMMARY_FAIL';
export const GET_TEAM_MEMBER_STARMEUP_STARS_SUMMARY_SUCCESS = 'GET_TEAM_MEMBER_STARMEUP_STARS_SUMMARY_SUCCESS';
export const GET_TEAM_MEMBER_STRENGHTS = 'GET_TEAM_MEMBER_STRENGHTS';
export const GET_TEAM_MEMBER_STRENGHTS_FAIL = 'GET_TEAM_MEMBER_STRENGHTS_FAIL';
export const GET_TEAM_MEMBER_STRENGHTS_SUCCESS = 'GET_TEAM_MEMBER_STRENGHTS_SUCCESS';
export const GET_TEAM_MEMBER_SUMMARY_GOALS = 'GET_TEAM_MEMBER_SUMMARY_GOALS';
export const GET_TEAM_MEMBER_SUMMARY_GOALS_FAIL = 'GET_TEAM_MEMBER_SUMMARY_GOALS_FAIL';
export const GET_TEAM_MEMBER_SUMMARY_GOALS_SUCCESS = 'GET_TEAM_MEMBER_SUMMARY_GOALS_SUCCESS';
export const GET_TEAM_OPPORTUNITIES = 'GET_TEAM_OPPORTUNITIES';
export const GET_TEAM_OPPORTUNITIES_FAIL = 'GET_TEAM_OPPORTUNITIES_FAIL';
export const GET_TEAM_OPPORTUNITIES_SUCCESS = 'GET_TEAM_OPPORTUNITIES_SUCCESS';
export const GET_TEAM_STRENGHTS = 'GET_TEAM_STRENGHTS';
export const GET_TEAM_STRENGHTS_FAIL = 'GET_TEAM_STRENGHTS_FAIL';
export const GET_TEAM_STRENGHTS_SUCCESS = 'GET_TEAM_STRENGHTS_SUCCESS';
export const RESET_TEAM_DASHBOARD_STATE = 'RESET_TEAM_DASHBOARD_STATE';