// @packages
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RootModal from 'smu-app-components/RootModal';

// @app
import GenericModal from 'components/templates/GenericModalTemplate';
import InfoCard from 'components/molecules/InfoCard';
import { centerModalClass } from 'betterme-components/ConfirmationModal';
import { CREATE_GOAL_VALIDATION } from 'betterme-components/constants/modalTypes';
import { selectFetchingSent } from 'betterme-components/Goals/selectors';

// @own
import messages from './messages';

const CreateGoalValidationModal = ({
  closeModal,
  fetchingSent,
  intl: { formatMessage },
  onAccept,
}) => {
  const actionButtons = [
    {
      label: formatMessage(messages.GoalValidationReview),
      onClick: () => closeModal(),
      variant: 'outline',
    },
    {
      label: formatMessage(messages.GoalValidationYesContinue),
      onClick: onAccept,
      variant: 'black'
    }
  ];

  return (
    <GenericModal
      actionButtons={actionButtons}
      header={
        <InfoCard
          icon="info"
          message={formatMessage(messages.GoalValidationMessages)}
          title={formatMessage(messages.GoalValidationtitle)}
        />
      }
      loading={fetchingSent}
      onClose={closeModal}
      success
    />
  );
};

CreateGoalValidationModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  intl: PropTypes.object,
  onAccept: PropTypes.func,
};

const modalStyles = {
  width: '600px',
};

const mapStateToProps = (state) => ({
  fetchingSent: selectFetchingSent(state),
});

export default RootModal(
  injectIntl(connect(mapStateToProps, null)(CreateGoalValidationModal)),
  CREATE_GOAL_VALIDATION,
  modalStyles,
  centerModalClass,
);
