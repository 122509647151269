// @packages
import React from 'react';
import PropTypes from 'prop-types';
import AvatarMedia from 'smu-ui-components/AvatarMedia';
import cn from 'classnames';

// @own
import './styles.scss';

const TeamFeedbackReceivedList = ({
  collaboratorsList,
  newStyle,
}) => (
  <ul className={cn('team-feedback-received-list', {
    'team-feedback-received-list--new-style': newStyle,
  })}>
    {collaboratorsList.map(collaborator => {
      const {
        collaborator: {
          firstName,
          id,
          identification,
          lastName,
          profileImageCode,
        },
        summary: { receivedFeedback },
      } = collaborator;

      return (
        <li
          className="team-feedback-received-list__item"
          key={id}
        >
          <AvatarMedia
            className="team-feedback-received-list__avatar"
            firstName={firstName}
            identification={identification}
            lastName={lastName}
            profileImageCode={profileImageCode}
            type="small"
            useLink
          />
          <span className="team-feedback-received-list__total">
            {receivedFeedback.total}
          </span>
        </li>
      );
    })}
  </ul>
);

TeamFeedbackReceivedList.propTypes = {
  collaboratorsList: PropTypes.array.isRequired,
  newStyle: PropTypes.bool,
};

export default TeamFeedbackReceivedList;
