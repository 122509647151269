import {
  GET_USER_INFORMATION,
  GET_USER_INFORMATION_FAIL,
  GET_USER_INFORMATION_SUCCESS,
  RESET_USER_INFORMATION,
} from './actionTypes';

export function getUserInfo(identification) {
  return {
    type: GET_USER_INFORMATION,
    payload: { identification },
  }
};

export function getUserInfoFail(error) {
  return {
    type: GET_USER_INFORMATION_FAIL,
    payload: { error },
  };
}

export function getUserInfoSuccess(response) {
  return {
    type: GET_USER_INFORMATION_SUCCESS,
    payload: { response },
  };
}

export function resetUserInfo() {
  return {
    type: RESET_USER_INFORMATION,
  };
}
