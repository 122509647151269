import {
  ADD_COMPETENCY_CREATE_EVALUATION,
  CAN_CREATE_BEHAVIOUR_RETROSPECTIVE,
  CLEAN_TEAM_EVALUATION_STATE,
  CREATE_DRAFT_EVALUATION,
  CREATE_DRAFT_EVALUATION_FAIL,
  CREATE_DRAFT_EVALUATION_SUCCESS,
  CREATE_EVALUATION,
  CREATE_EVALUATION_FAIL,
  CREATE_EVALUATION_SUCCESS,
  DELETE_DRAFT_EVALUATION,
  DELETE_DRAFT_EVALUATION_FAIL,
  DELETE_DRAFT_EVALUATION_SUCCESS,
  DELETE_EVALUATION_FILE,
  DELETE_EVALUATION_FILE_FAIL,
  DELETE_EVALUATION_FILE_SUCCESS,
  EVALUATION_ADD_DELETED_SKILL,
  EVALUATION_ADD_SKILL,
  EVALUATION_ADD_SKILLS,
  EVALUATION_CHANGE_SKILL,
  EVALUATION_CLEAN_DELETED_SKILLS,
  EVALUATION_CLEAN_SKILLS,
  EVALUATION_DELETED_SKILL,
  GET_CLOSED_ASSIGNED_GOALS,
  GET_CLOSED_ASSIGNED_GOALS_FAIL,
  GET_CLOSED_ASSIGNED_GOALS_SUCCESS,
  GET_CUSTOM_SCORES,
  GET_CUSTOM_SCORES_FAIL,
  GET_CUSTOM_SCORES_SUCCESS,
  GET_GOALS_FINAL_SCORE,
  GET_GOALS_FINAL_SCORE_FAIL,
  GET_GOALS_FINAL_SCORE_SUCCESS,
  GET_LAST_EVALUATION,
  GET_LAST_EVALUATION_FAIL,
  GET_LAST_EVALUATION_SUCCESS,
  GET_LAST_SELF_EVALUATION,
  GET_LAST_SELF_EVALUATION_FAIL,
  GET_LAST_SELF_EVALUATION_SUCCESS,
  GET_SUGGESTED_ACTIONS,
  GET_SUGGESTED_ACTIONS_FAIL,
  GET_SUGGESTED_ACTIONS_SUCCESS,
  GET_TEAM_ALL_EVALUATIONS,
  GET_TEAM_ALL_EVALUATIONS_FAIL,
  GET_TEAM_ALL_EVALUATIONS_SUCCESS,
  REMOVE_COMPETENCY_CREATE_EVALUATION,
  REQUEST_EVALUATIONS,
  REQUEST_EVALUATIONS_FAIL,
  REQUEST_EVALUATIONS_SUCCESS,
  REQUEST_TEAM_EVALUATIONS,
  REQUEST_TEAM_EVALUATIONS_FAIL,
  REQUEST_TEAM_EVALUATIONS_SUCCESS,
  RESET_DATA_CREATE_EVALUATION,
  RESET_EVALUATION_RECENTLY_CREATED,
  RESET_LAST_EVALUATION,
  RESET_LAST_SELF_EVALUATION,
  SET_DATA_CREATE_EVALUATION,
  SET_EVALUATION_RECENTLY_CREATED,
  SUBMIT_DRAFT_EVALUATION,
  SUBMIT_DRAFT_EVALUATION_FAIL,
  SUBMIT_DRAFT_EVALUATION_SUCCESS,
  UPDATE_DRAFT_EVALUATION,
  UPDATE_DRAFT_EVALUATION_FAIL,
  UPDATE_DRAFT_EVALUATION_SUCCESS,
  UPLOAD_EVALUATION_FILE,
  UPLOAD_EVALUATION_FILE_FAIL,
  UPLOAD_EVALUATION_FILE_SUCCESS,
} from './actionTypes';

export function getCustomScores() {
  return {
    type: GET_CUSTOM_SCORES,
  };
}

export function getCustomScoresFail(error) {
  return {
    type: GET_CUSTOM_SCORES_FAIL,
    payload: { error },
  };
}

export function getCustomScoresSuccess(response) {
  return {
    type: GET_CUSTOM_SCORES_SUCCESS,
    payload: { response },
  };
}

export function getClosedAssignedGoals(userId, filters) {
  return {
    type: GET_CLOSED_ASSIGNED_GOALS,
    payload: { userId, filters },
  };
}

export function getClosedAssignedGoalsFail(error) {
  return {
    type: GET_CLOSED_ASSIGNED_GOALS_FAIL,
    payload: { error },
  };
}

export function getClosedAssignedGoalsSuccess(response) {
  return {
    type: GET_CLOSED_ASSIGNED_GOALS_SUCCESS,
    payload: { response }
  };
}

export function getGoalsFinalScore(userId, from, to) {
  return {
    type: GET_GOALS_FINAL_SCORE,
    payload: { userId, from, to },
  };
}

export function getGoalsFinalScoreFail(error) {
  return {
    type: GET_GOALS_FINAL_SCORE_FAIL,
    payload: { error },
  };
}

export function getGoalsFinalScoreSuccess(response) {
  return {
    type: GET_GOALS_FINAL_SCORE_SUCCESS,
    payload: { response },
  };
}

export function getTeamAllEvaluations({ leaderId, scoreId, creationTimeFrom, creationTimeTo }) {
  return {
    type: GET_TEAM_ALL_EVALUATIONS,
    payload: { leaderId, scoreId, creationTimeFrom, creationTimeTo },
  };
}

export function getTeamAllEvaluationsSuccess(response) {
  return {
    type: GET_TEAM_ALL_EVALUATIONS_SUCCESS,
    payload: { response },
  };
}

export function getTeamAllEvaluationsFail(error) {
  return {
    type: GET_TEAM_ALL_EVALUATIONS_FAIL,
    payload: { error },
  };
}

export function cleanTeamEvaluationState() {
  return {
    type: CLEAN_TEAM_EVALUATION_STATE,
  };
}

export function createDraftEvaluation({ data, messages, typeEvaluation }) {
  return {
    type: CREATE_DRAFT_EVALUATION,
    payload: { data, messages },
    typeEvaluation,
  };
}

export function createDraftEvaluationFail(error) {
  return {
    type: CREATE_DRAFT_EVALUATION_FAIL,
    payload: { error },
  };
}

export function createDraftEvaluationSuccess(response) {
  return {
    type: CREATE_DRAFT_EVALUATION_SUCCESS,
    payload: { response },
  };
}

export function createEvaluation(data, isSelfEvaluation, messages) {
  return {
    type: CREATE_EVALUATION,
    payload: { data, messages },
    meta: { isSelfEvaluation },
  };
}

export function createEvaluationFail(error) {
  return {
    type: CREATE_EVALUATION_FAIL,
    payload: { error },
  };
}

export function createEvaluationSuccess(response) {
  return {
    type: CREATE_EVALUATION_SUCCESS,
    payload: { response },
  };
}

export function deleteDraftEvaluation({ id, messages, typeEvaluation }) {
  return {
    type: DELETE_DRAFT_EVALUATION,
    payload: { id, messages },
    typeEvaluation,
  };
}

export function deleteDraftEvaluationFail(error) {
  return {
    type: DELETE_DRAFT_EVALUATION_FAIL,
    payload: { error },
  };
}

export function deleteDraftEvaluationSuccess(response) {
  return {
    type: DELETE_DRAFT_EVALUATION_SUCCESS,
    payload: { response },
  };
}

export function requestEvaluations(evalueeId) {
  return {
    type: REQUEST_EVALUATIONS,
    payload: { evalueeId },
  };
}

export function requestEvaluationsFail(error) {
  return {
    type: REQUEST_EVALUATIONS_FAIL,
    payload: { error },
  };
}

export function requestEvaluationsSuccess(response) {
  return {
    type: REQUEST_EVALUATIONS_SUCCESS,
    payload: { response },
  };
}

export function requestTeamEvaluations({ evalueeId, creationTimeFrom, creationTimeTo }) {
  return {
    type: REQUEST_TEAM_EVALUATIONS,
    payload: { evalueeId, creationTimeFrom, creationTimeTo },
  };
}

export function requestTeamEvaluationsSuccess(response) {
  return {
    type: REQUEST_TEAM_EVALUATIONS_SUCCESS,
    payload: { response },
  };
}

export function requestTeamEvaluationsFail(error) {
  return {
    type: REQUEST_TEAM_EVALUATIONS_FAIL,
    payload: { error },
  };
}

export function setDataCreateEvaluation(props) {
  return {
    type: SET_DATA_CREATE_EVALUATION,
    payload: props,
  };
}

export function submitDraftEvaluation({
  data,
  isFromNotification,
  messages,
  typeEvaluation,
}) {
  return {
    type: SUBMIT_DRAFT_EVALUATION,
    payload: { data },
    isFromNotification,
    messages,
    typeEvaluation,
  };
}

export function submitDraftEvaluationFail(error) {
  return {
    type: SUBMIT_DRAFT_EVALUATION_FAIL,
    payload: { error },
  };
}

export function submitDraftEvaluationSuccess(response) {
  return {
    type: SUBMIT_DRAFT_EVALUATION_SUCCESS,
    payload: { response },
  };
}

export function updateDraftEvaluation({ data, messages, typeEvaluation }) {
  return {
    type: UPDATE_DRAFT_EVALUATION,
    payload: { data, messages },
    typeEvaluation,
  };
}

export function updateDraftEvaluationFail(error) {
  return {
    type: UPDATE_DRAFT_EVALUATION_FAIL,
    payload: { error },
  };
}

export function updateDraftEvaluationSuccess(response) {
  return {
    type: UPDATE_DRAFT_EVALUATION_SUCCESS,
    payload: { response },
  };
}

export function resetDataCreateEvaluation() {
  return {
    type: RESET_DATA_CREATE_EVALUATION,
  };
}

export function addCompetency(competency, rateId) {
  return {
    type: ADD_COMPETENCY_CREATE_EVALUATION,
    payload: { competency, rateId },
  };
}

export function removeCompetency(competency) {
  return {
    type: REMOVE_COMPETENCY_CREATE_EVALUATION,
    payload: { competency },
  };
}

export function getLastEvaluation(evalueeId) {
  return {
    type: GET_LAST_EVALUATION,
    payload: { evalueeId },
  };
}

export function getLastEvaluationFail(error) {
  return {
    type: GET_LAST_EVALUATION_FAIL,
    payload: { error },
  };
}

export function getLastEvaluationSuccess(response) {
  return {
    type: GET_LAST_EVALUATION_SUCCESS,
    payload: { response },
  };
}

export function getLastSelfEvaluation({ evalueeId, creationTimeFrom, creationTimeTo }) {
  return {
    type: GET_LAST_SELF_EVALUATION,
    payload: { evalueeId, creationTimeFrom, creationTimeTo },
  };
}

export function getLastSelfEvaluationFail(error) {
  return {
    type: GET_LAST_SELF_EVALUATION_FAIL,
    payload: { error },
  };
}

export function getLastSelfEvaluationSuccess(response) {
  return {
    type: GET_LAST_SELF_EVALUATION_SUCCESS,
    payload: { response },
  };
}

export function getSuggestedActions({ isNewHire }) {
  return {
    type: GET_SUGGESTED_ACTIONS,
    payload: { isNewHire },
  };
}

export function getSuggestedActionsFail(error) {
  return {
    type: GET_SUGGESTED_ACTIONS_FAIL,
    payload: { error },
  };
}

export function getSuggestedActionsSuccess(response) {
  return {
    type: GET_SUGGESTED_ACTIONS_SUCCESS,
    payload: { response },
  };
}

export function resetLastEvaluation() {
  return {
    type: RESET_LAST_EVALUATION,
  };
}

export function resetLastSelfEvaluation() {
  return {
    type: RESET_LAST_SELF_EVALUATION,
  };
}

export function uploadEvaluationFile(file) {
  return {
    type: UPLOAD_EVALUATION_FILE,
    payload: { file },
  };
}

export function uploadEvaluationFileFail(error) {
  return {
    type: UPLOAD_EVALUATION_FILE_FAIL,
    payload: { error },
  };
}

export function uploadEvaluationFileSuccess(response) {
  return {
    type: UPLOAD_EVALUATION_FILE_SUCCESS,
    payload: { response },
  };
}

export function deleteEvaluationFile(id) {
  return {
    type: DELETE_EVALUATION_FILE,
    payload: { id },
  }
}

export function deleteEvaluationFileFail(error) {
  return {
    type: DELETE_EVALUATION_FILE_FAIL,
    payload: { error },
  }
}

export function deleteEvaluationFileSuccess(id) {
  return {
    type: DELETE_EVALUATION_FILE_SUCCESS,
    payload: { id },
  }
}

export function evaluationAddSkill(data) {
  if (Array.isArray(data)) {
    return {
      type: EVALUATION_ADD_SKILLS,
      payload: { skills: data },
    };
  }
  return {
    type: EVALUATION_ADD_SKILL,
    payload: { skill: data },
  };
}

export function evaluationChangeSkill({ rateId, skillId }) {
  return {
    type: EVALUATION_CHANGE_SKILL,
    payload: { rateId, skillId },
  };
}

export function evaluationDeletedSkill(skillId) {
  return {
    type: EVALUATION_DELETED_SKILL,
    payload: { skillId },
  };
}

export function evaluationAddDeletedSkill(skill) {
  return {
    type: EVALUATION_ADD_DELETED_SKILL,
    payload: { skill },
  };
}

export function evaluationCleanDeletedSkills() {
  return {
    type: EVALUATION_CLEAN_DELETED_SKILLS,
  };
}

export function evaluationCleanSkills() {
  return {
    type: EVALUATION_CLEAN_SKILLS,
  };
}

export function setEvaluationRecentlyCreated(evaluee) {
  return {
    type: SET_EVALUATION_RECENTLY_CREATED,
    payload: { evaluee },
  };
}

export function resetEvaluationRecentlyCreated() {
  return {
    type: RESET_EVALUATION_RECENTLY_CREATED,
  };
}

export function canCreateBehaviourRetrospective(payload) {
  return {
    type: CAN_CREATE_BEHAVIOUR_RETROSPECTIVE,
    payload,
  };
}
