import { defineMessages } from 'react-intl';

export default defineMessages({
  SuggestionRequired: {
    id: 'Betterme.LeaderSuggestionRequiredModal.SuggestionRequired',
    defaultMessage: 'Leader Suggestion Required',
  },
  SendSuggestion: {
    id: 'Betterme.LeaderSuggestionRequiredModal.SendSuggestion',
    defaultMessage: 'In order to remove yourself from this leadership you must have to suggest another leader for {collaborator}.',
  },
  SuggestLeader: {
    id: 'Betterme.LeaderSuggestionRequiredModal.SuggestLeader',
    defaultMessage: 'Suggest Leader',
  },
});
