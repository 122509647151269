export function handleSliceQuestions(questions, size) {
  var slicedQuestions = [];
  for (let i = 0; i <= questions.length; i += size)
    slicedQuestions.push(questions.slice(i, i + size));
  return slicedQuestions;
};

export function uniqueNumericID(items) {
  return items.map(item => {
    return {
      ...item,
      id: Math.floor(item?.id / Math.random() * 10),
    }
  });
}
