import { trackEvent } from 'smu-utils/gtm';
const USER_DASHBOARD = 'User_Dashboard';

export const trackBoxOpportunityRequestFeedback = () => {
  trackEvent({
    'category': USER_DASHBOARD,
    'action': 'BoxOpportunity_RequestFeedback_Default',
  });
};

export const trackBoxOpportunityRequestFeedbackEmpty = () => {
  trackEvent({
    'category': USER_DASHBOARD,
    'action': 'BoxOpportunity_RequestFeedback_EmptyState',
  });
};

export const trackBoxStrengthsRequestFeedbackEmpty = () => {
  trackEvent({
    'category': USER_DASHBOARD,
    'action': 'BoxStrengths_RequestFeedback_EmptyState',
  });
};
