// @packages
import React, { useState, useEffect, useRef, useMemo } from 'react';
import InputArea from 'smu-ui-components/InputArea';
import InputRadio from 'smu-ui-components/InputRadio';
import PropTypes from 'prop-types';
import Segment from 'smu-ui-components/Segment';
import UserAvatar from 'smu-ui-components/UserAvatar';
import scrollToComponent from 'react-scroll-to-component';
import { connect } from 'react-redux';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import { isEmpty, orderBy } from 'lodash';
import { push } from 'react-router-redux';

// @app
import ActionButtons from 'components/molecules/ActionButtons';
import AttachedFilesDetails from 'common/AttachedFilesDetails';
import BehavioralAssessmentDetail from 'components/organisms/BehavioralAssessmentDetail';
import BreadcrumbHeader from 'components/organisms/BreadcrumbHeader';
import ContentCard from 'common/ContentCard';
import EvaluationDetailScore from 'components/organisms/EvaluationDetailScore';
import RatedTopics from 'components/organisms/RatedTopics';
import Title from 'components/atoms/Title';
import UserAvatarList from 'common/UserAvatarList';
import formatScore from 'utils/formatScore';
import { formatPeriodDates } from 'utils/formatPeriodDates';
import { getTeamEvaluationsLocation, getEvaluationsLocation } from 'betterme-components/routes/urls'
import { selectUserInfo } from 'betterme-components/Authorization/selectors';
import { selectScores } from 'betterme-components/services/Layout/selectors';
import {
  selectBehavioralAssesmentDetailByRatings,
  selectCoCreatedTopics,
  selectDataCreateAnswerPerformanceRetrospective,
  selectIsFetching,
  selectPerformanceRetrospectiveDetail,
} from 'betterme-components/services/PerformanceRetrospectiveDetail/selectors';
import {
  createAnswerPerformanceRetrospective,
  getPerformanceRetrospectiveDetail,
  resetDataCreateAnswerPerformanceRetrospective,
  resetPerformanceRetrospectiveDetail,
  setDataCreateAnswerPerformanceRetrospective,
} from 'betterme-components/services/PerformanceRetrospectiveDetail/actions';
// @own

import messages from './messages';
import {
  AGREE,
  AVATAR_SIZE,
  DISAGREE,
  NOT_APPLY,
  OFFSET_NAV,
} from './constants';

const PerformanceRetrospectiveDetail = ({
  behavioralAssesmentDetailByRatings,
  coCreatedTopics,
  createAnswerPerformanceRetrospective,
  dataCreateAnswerPerformanceRetrospective,
  getPerformanceRetrospectiveDetail,
  goToPath,
  intl: { formatMessage },
  isLoading,
  location: { query },
  params: { evaluationid },
  performanceRetrospectiveDetail,
  resetDataCreateAnswerPerformanceRetrospective,
  resetPerformanceRetrospectiveDetail,
  scores,
  setDataCreateAnswerPerformanceRetrospective,
  userInfo,
}) => {
  const [evaluationLoaded, setEvaluationLoaded] = useState(false);

  const {
    answer,
    attachments,
    coCreated: coCreatedData,
    comment,
    dateTimeFrom,
    dateTimeTo,
    evaluator,
    evaluee,
    score,
    scoreId,
    title,
    ratedTopics,
  } = performanceRetrospectiveDetail;

  const {
    approved: answerApproved,
    comment: answerComment,
    meaningfulConversation: answerMeaningfulConversation,
  } = dataCreateAnswerPerformanceRetrospective;

  const isMyEvaluation = userInfo?.id === evaluee?.id;
  const backTitle = isMyEvaluation
    ? formatMessage(messages.PerformanceRetrospectiveDetailEvaluations)
    : formatMessage(messages.PerformanceRetrospectiveDetailMyTeamEvaluations);
  const headerPeriod = formatPeriodDates(dateTimeFrom, dateTimeTo);
  const evaluationtitle = formatMessage(messages.PerformanceRetrospectiveDetailTitle);
  const evalueeSectionMessage = formatMessage(messages.PerformanceRetrospectiveDetailEvaluationOf);
  const evalueeFullName = `${evaluee?.firstName} ${evaluee?.lastName}`;
  const hasCoCreated = !isEmpty(coCreatedData);
  const hasTechnicalInput = coCreatedData?.status === 'SUBMITTED';
  const showCoCreatedComment = coCreatedData?.flagComment;
  const showCoCreatedSkills = coCreatedData?.flagSkills;
  const ratedTopicsOrdered = orderBy(ratedTopics, ['ordinality']);
  const findScore = scores?.find(score => score?.id === scoreId);
  const scoreDescription = findScore?.description;
  const detailScoreTitle = formatMessage(messages.PerformanceRetrospectiveDetailScoreTitle);
  const answerRef = useRef(null);
  const isAnswer = !!answer;
  const shouldRenderAnswer = isMyEvaluation || isAnswer;
  const currentMeaningfulConversation = isAnswer
    ? answer.meaningfulConversation
    : answerMeaningfulConversation;
  const currentAnswerApproved = isAnswer ? answer.approved : answerApproved;
  const currentAnswerComment = isAnswer ? answer.comment : answerComment;
  const meaningfulConversation = [
    {
      label: formatMessage(messages.PerformanceRetrospectiveDetailMeaningfulConversationFirstOption),
      value: AGREE,
    },
    {
      label: formatMessage(messages.PerformanceRetrospectiveDetailMeaningfulConversationSecondOption),
      value: DISAGREE,
    },
    {
      label: formatMessage(messages.PerformanceRetrospectiveDetailMeaningfulConversationThirdOption),
      value: NOT_APPLY,
    },
  ];
  const filteredMeaningfulConversation =
    meaningfulConversation?.filter(
      conversation => conversation?.value === answer?.meaningfulConversation
    );
  const meaningfulConversationOptions = !isAnswer
    ? meaningfulConversation : filteredMeaningfulConversation;
  const isValid =
    answerComment.length
    && (answerApproved !== null)
    && (answerMeaningfulConversation !== null)
    && (answerMeaningfulConversation !== undefined);


  const evaluators = useMemo(
    () => {
      const evaluators = [evaluator];
      if (!isEmpty(coCreatedData)) {
        evaluators.push(coCreatedData?.evaluator);
      };
      return evaluators;
    }
    , [coCreatedData, evaluator]);


  useEffect(() => {
    if (evaluationid) {
      getPerformanceRetrospectiveDetail(evaluationid);
    }
  }, [evaluationid]);

  useEffect(() => {
    if (!isLoading && !isEmpty(performanceRetrospectiveDetail))
      setEvaluationLoaded(true);
  }, [performanceRetrospectiveDetail, isLoading]);

  useEffect(() => {
    return () => {
      resetDataCreateAnswerPerformanceRetrospective();
      resetPerformanceRetrospectiveDetail();
    };
  }, []);

  useEffect(() => {
    if (query.showAnswer && answerRef.current) {
      scrollToComponent(answerRef.current, {
        offset: OFFSET_NAV,
        align: 'top',
      });
    }
  }, [query]);


  function handleBreadcrumbsClick() {
    isMyEvaluation ? goToPath(getEvaluationsLocation()) : goToPath(getTeamEvaluationsLocation());
  };

  function handleClearForm() {
    resetDataCreateAnswerPerformanceRetrospective();
  };

  function handleCreateAnswerEvaluation() {
    createAnswerPerformanceRetrospective(evaluationid, dataCreateAnswerPerformanceRetrospective);
  };

  function handleChangeRadio(event) {
    const { value } = event.target;
    setDataCreateAnswerPerformanceRetrospective({ approved: value === 'true' });
  };

  function handleChangeText(name, event) {
    const { value } = event.target;
    const cleanValue = value.trimStart();
    setDataCreateAnswerPerformanceRetrospective({ [name]: cleanValue });
  }

  function handleOptionChange(event) {
    const { value } = event.target;
    setDataCreateAnswerPerformanceRetrospective({ meaningfulConversation: value });
  }
  
  return (
    <Segment
      loading={isLoading}
      loadingType="betterme"
    >
      {evaluationLoaded &&
        <div className="h-full min-h-full overflow-auto pb-[34px] relative">
          <BreadcrumbHeader
            breadCrumbsProps={{
              active: 1,
              onClick: handleBreadcrumbsClick,
              values: [backTitle, title],
            }}
            date={headerPeriod} />
          <ContentCard
            className="!my-[25px] !mx-auto max-w-[600px]"
            contentPage
            title={evaluationtitle}
          >
            <ContentCard.Body>
              <div className="bg-white">
                <div className="p-5 relative">
                  <div className="items-center text-gray flex flex-row">
                    <UserAvatar
                      height={AVATAR_SIZE}
                      profileImageCode={evaluee?.profileImageCode || evaluee?.imageCode}
                      width={AVATAR_SIZE} />
                    <div className="ml-2">
                      {evalueeSectionMessage}
                      <span className="ml-1 font-bold">
                        {evalueeFullName}
                      </span>
                    </div>
                  </div>
                </div>
                <EvaluationDetailScore
                  actionMessage={formatMessage(messages.PerformanceRetrospectiveDetailWrittenBy)}
                  className="p-5 relative"
                  coCreated={{
                    coCreatedData,
                    hasTechnicalInput,
                    showCoCreatedComment,
                  }}
                  comment={comment?.trimStart()}
                  evaluator={evaluator}
                  isMyEvaluation={isMyEvaluation}
                  score={formatScore(score)}
                  scoreDescription={scoreDescription}
                  showtooltip
                  title={detailScoreTitle}
                />
                <BehavioralAssessmentDetail
                  behaviors={behavioralAssesmentDetailByRatings}
                  subtitle={formatMessage(messages.PerformanceRetrospectiveDetailBehavioralSubtitle)}
                  title={formatMessage(messages.PerformanceRetrospectiveDetailBehavioralTitle)}
                />
                {((ratedTopicsOrdered.length > 0) || (coCreatedTopics.length > 0)) && (
                  <div className="p-5 relative">
                    <h3 className="text-shark mb-[10px] uppercase text-base font-bold">
                      {formatMessage(messages.PerformanceRetrospectiveDetailSkillsTitle)}
                    </h3>
                    <RatedTopics ratedTopicsList={
                      (hasCoCreated && hasTechnicalInput && !showCoCreatedSkills) && !isMyEvaluation
                        ? coCreatedTopics
                        : ratedTopicsOrdered
                    }
                    />
                  </div>
                )}
                <AttachedFilesDetails
                  attachments={attachments}
                  className="p-5 relative [&>h3]:text-base" />
                <div
                  className={
                    `items-center bg-whiteSmoke border-t border-solid border-gray-iron flex
                    font-roboto text-xs h-12 px-5`
                  }
                >
                  <UserAvatarList
                    maxDisplay={2}
                    showPopup
                    size={32}
                    users={evaluators}
                  />
                  <p className="ml-2 text-gray [&_b]:text-shark">
                    {isEmpty(coCreatedData) ?
                      <span>
                        {formatMessage(messages.PerformanceRetrospectiveDetailSentBy) + ' '}
                        <b>{`${evaluator?.firstName} ${evaluator?.lastName}`}</b>
                      </span> :
                      <FormattedHTMLMessage
                        defaultMessage={messages.PerformanceRetrospectiveDetailSentByUsers.defaultMessage}
                        id={messages.PerformanceRetrospectiveDetailSentByUsers.id}
                        values={{
                          evaluator:
                            `${evaluator?.firstName} ${evaluator?.lastName}`,
                          coEvaluator:
                            `${coCreatedData?.evaluator?.firstName} ${coCreatedData?.evaluator?.lastName}`,
                        }}
                      />
                    }
                  </p>
                </div>
              </div>
            </ContentCard.Body>
          </ContentCard>
          {shouldRenderAnswer && (
            <ContentCard
              className="!my-[25px] !mx-auto max-w-[600px]"
              contentPage
              loading={isLoading}
              ref={answerRef}
              title={formatMessage(messages.PerformanceRetrospectiveDetailReplyTitle)}
            >
              <ContentCard.Body>
                <div className="bg-white">
                  <div className="p-5 relative">
                    {(!isAnswer ||
                      (isAnswer && currentAnswerApproved)) && (
                        <InputRadio
                          checked={currentAnswerApproved === true}
                          disabled={isAnswer}
                          label={formatMessage(messages.PerformanceRetrospectiveDetailLabelAgree)}
                          name="evaluation"
                          onChange={(event) => handleChangeRadio(event)}
                          value={true}
                        />
                      )}
                    {(!isAnswer || (isAnswer && !currentAnswerApproved)) && (
                      <InputRadio
                        checked={currentAnswerApproved === false}
                        disabled={isAnswer}
                        label={formatMessage(messages.PerformanceRetrospectiveDetailLabelDisagree)}
                        name="evaluation"
                        onChange={(event) => handleChangeRadio(event)}
                        value={false}
                      />
                    )}
                    <InputArea
                      disabled={isAnswer}
                      maxLength={!isAnswer ? 1200 : null}
                      onChange={(event) => handleChangeText("comment", event)}
                      placeholder={formatMessage(messages.PerformanceRetrospectiveDetailPlaceholderComment)}
                      rows={7}
                      value={currentAnswerComment}
                    />
                    {
                      <div>
                        <Title
                          black
                          className="pt-5"
                          size={16}
                        >
                          {formatMessage(messages.PerformanceRetrospectiveDetailMeaningfulConversation)}
                        </Title>
                        <p className="text-gray font-roboto text-sm mb-3">
                          {formatMessage(messages.PerformanceRetrospectiveDetailMeaningfulConversationText)}
                        </p>
                        {meaningfulConversationOptions?.map((conversation) => {
                          const { label, value } = conversation;
                          return (
                            <InputRadio
                              checked={currentMeaningfulConversation === value}
                              disabled={isAnswer}
                              label={label}
                              name="conversation"
                              onChange={(event) => handleOptionChange(event)}
                              value={value}
                            />
                          );
                        })}
                      </div>
                    }
                  </div>
                  {!isAnswer && (
                    <div className="pt-0 px-5 pb-5 text-right [&>button]:ml-[10px]">
                      <ActionButtons
                        buttons={[
                          {
                            label: formatMessage(messages.PerformanceRetrospectiveDetailClear),
                            onClick: handleClearForm,
                            variant: 'outline',
                          },
                          {
                            disabled: !isValid,
                            label: formatMessage(messages.PerformanceRetrospectiveDetailSend),
                            onClick: handleCreateAnswerEvaluation,
                          },
                        ]}
                      />
                    </div>
                  )}
                </div>
              </ContentCard.Body>
            </ContentCard>
          )}
        </div>
      }
    </Segment>
  );
}

PerformanceRetrospectiveDetail.propTypes = {
  allowsEvaluationsSuggestedActions: PropTypes.bool,
  getPerformanceRetrospectiveDetail: PropTypes.func,
  goToPath: PropTypes.func,
  performanceRetrospectiveDetail: PropTypes.object,
  scoresDescription: PropTypes.array,
  userInfo: PropTypes.object,
};

const mapStateToProps = (state) => ({
  behavioralAssesmentDetailByRatings: selectBehavioralAssesmentDetailByRatings(state),
  coCreatedTopics: selectCoCreatedTopics(state),
  dataCreateAnswerPerformanceRetrospective: selectDataCreateAnswerPerformanceRetrospective(state),
  isLoading: selectIsFetching(state),
  performanceRetrospectiveDetail: selectPerformanceRetrospectiveDetail(state),
  scores: selectScores(state),
  userInfo: selectUserInfo(state),
});


const goToPath = (path) => push(path);

const mapDispatchToProps = {
  goToPath,
  createAnswerPerformanceRetrospective,
  getPerformanceRetrospectiveDetail,
  resetDataCreateAnswerPerformanceRetrospective,
  resetPerformanceRetrospectiveDetail,
  setDataCreateAnswerPerformanceRetrospective,
};

export default connect(
  mapStateToProps, mapDispatchToProps
)(injectIntl(PerformanceRetrospectiveDetail));
