// @packages
import React from 'react';
import { injectIntl } from 'react-intl';
import cn from 'classnames';
import UserAvatar from 'smu-ui-components/UserAvatar';

// @app
import Title from 'components/atoms/Title';
import { promotionsMessages } from 'common/messages';

// @own
import './styles.scss';

const RequestedBy = ({
  cardTitle,
  firstName,
  highlight,
  imageSize,
  intl: { formatMessage },
  job,
  lastName,
  profileImageCode,
}) => (
  <div className="requestedBy">
    <Title
      black
      className="requestedBy__title"
      level={2}
      size={14}
    >
      {cardTitle}
    </Title>
    <div className="requestedBy__content">
      <div className="requestedBy__left">
        <UserAvatar
          className={cn('requestedBy__avatar',
            { 'requestedBy__avatar--highlight': highlight }
          )}
          height={imageSize}
          profileImageCode={profileImageCode}
          width={imageSize}
        />
      </div>
      <div className="requestedBy__right">
        <div className="requestedBy__fullName">
          {`${firstName} ${lastName}`}
        </div>
        <div className="requestedBy__job">
          {job}
        </div>
      </div>
    </div>
  </div>
);

RequestedBy.defaultProps = {
  imageSize: 60,
};

export default injectIntl(RequestedBy);
