// @packages
import React from 'react';
import PropTypes from 'prop-types';

// @app
import { feedbackMessages } from 'common/messages';
import CompetencyInfoBox from 'common/CompetencyInfoBox';

// @own
import messages from '../messages';

const OverallStrenghts = ({
  className,
  intl: { formatMessage },
  loading,
  newStyle,
  rating,
  strengths,
  trackEmptyLink,
}) => {
  const emptyDescription = formatMessage(messages.TeamDashboardBoxEmptyDescription);
  const emptyInfo = formatMessage(messages.TeamDashboardBoxEmptyInfo);
  const emptyLink = {
    label: formatMessage(feedbackMessages.FeedbackSendFeedback),
    to: '/newfeedbacksend',
    onClick: trackEmptyLink,
  };

  return (
    <CompetencyInfoBox
      className={className}
      color={rating.color}
      competencies={strengths}
      empty={{
        description: emptyDescription,
        info: emptyInfo,
        link: emptyLink,
      }}
      loading={loading}
      newStyle={newStyle}
      stacked
      title={formatMessage(feedbackMessages.FeedbackTopStrengths)}
    />
  );
};

OverallStrenghts.defaultProps = {
  newStyle: false,
};

OverallStrenghts.propTypes = {
  className: PropTypes.string,
  intl: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  newStyle: PropTypes.bool,
  rating: PropTypes.shape({
    color: PropTypes.string,
  }).isRequired,
  strengths: PropTypes.array.isRequired,
  trackEmptyLink: PropTypes.func.isRequired,
};

export default OverallStrenghts;
