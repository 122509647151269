import {
  GET_OS_APPLICATIONS,
  GET_OS_APPLICATIONS_FAIL,
  GET_OS_APPLICATIONS_SUCCESS,
} from './actionTypes';

function getOsApplications(payload) {
  return {
    type: GET_OS_APPLICATIONS,
    payload,
  };
}

function getOsApplicationsFail(payload) {
  return {
    type: GET_OS_APPLICATIONS_FAIL,
    payload,
  };
}

function getOsApplicationsSuccess(payload) {
  return {
    type: GET_OS_APPLICATIONS_SUCCESS,
    payload,
  };
}

export { getOsApplications, getOsApplicationsFail, getOsApplicationsSuccess };
