// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'smu-ui-components/IconV2';

// @app
import Title from 'components/atoms/Title';
import {
  SIZE_FIFTY_SIX,
  SIZE_FORTY,
  SIZE_ONE_HUNDRED,
  SIZE_THIRTY_SIX,
} from 'components/common/constants';

// @own
import './styles.scss';

const InfoCard = ({
  className,
  icon,
  message,
  secondary,
  title,
}) => (
  <div className={cn('info-card', className)}>
    <div className={cn('info-card__icon-wrapper', {
      'info-card__icon-wrapper--secondary': secondary,
    })}>
      <Icon
        className={cn('info-card__icon', {
          'info-card__icon--secondary': secondary,
        })}
        icon={icon}
        size={secondary ? SIZE_ONE_HUNDRED : SIZE_FIFTY_SIX}
      />
    </div>
    <div className="info-card__texts">
      <Title
        className={cn('info-card__title', {
          'info-card__title--secondary': secondary,
        })}
        size={secondary ? SIZE_FORTY : SIZE_THIRTY_SIX}
        white={!secondary}
      >
        {title}
      </Title>
      <div
        className={cn('info-card__message', {
          'info-card__message--secondary': secondary,
        })}>
        {message}
      </div>
    </div>
  </div>
);

InfoCard.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  message: PropTypes.object,
  title: PropTypes.string,
};

export default InfoCard;
