import { defineMessages } from 'react-intl';

export default defineMessages({
  MainSearchColleagues: {
    id: 'Betterme.MainSearch.Colleagues',
    defaultMessage: 'Colleages',
  },
  MainSearchPlaceholder: {
    id: 'Betterme.MainSearch.Placeholder',
    defaultMessage: 'Search Colleages',
  },
  MainSearchActionAdvanced: {
    id: 'Betterme.MainSearch.actionAdvanced',
    defaultMessage: 'Advanced Search',
  },
  MainSearchEmptyStateSubtitle: {
    id: 'Betterme.MainSearch.emptyStateSubtitle',
    defaultMessage: 'Try the advanced search',
  },
  MainSearchEmptyStateTitle: {
    id: 'Betterme.MainSearch.emptyStateTitle',
    defaultMessage: 'Do you need to be more specific?',
  },
  MainSearchNoResultsEmptyStateTitle: {
    id: 'Betterme.MainSearch.noResultsEmptyStateTitle',
    defaultMessage: 'No Colleagues Found',
  },
  MainSearchHashtags: {
    id: 'Betterme.MainSearch.hashtags',
    defaultMessage: 'Hashtags',
  },
});
