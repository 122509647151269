import { defineMessages } from 'react-intl';

export default defineMessages({
  AcceptPromotionTitle: {
    id: 'Betterme.AcceptDeclinePromotion.AcceptModalTitle',
    defaultMessage: 'Accept Request',
  },
  AcceptPromotionMessage: {
    id: 'Betterme.AcceptDeclinePromotion.AcceptModalMessage',
    defaultMessage: 'Make sure to have had a meaningful conversation with other leaders, and checked the promotions requirements before moving ahead. Do you still want to proceed?',
  },
  CreatePromotionTitle: {
    id: 'Betterme.AcceptDeclinePromotion.CreateModalTitle',
    defaultMessage: 'You are about to create a request',
  },
  CreatePromotionMessage: {
    id: 'Betterme.AcceptDeclinePromotion.CreateModalMessage',
    defaultMessage: 'Make sure to have had a meaningful conversation with other leaders, and checked the promotions requirements before moving ahead. Do you still want to proceed?',
  },
  DeclinePromotionTitle: {
    id: 'Betterme.AcceptDeclinePromotion.DeclineModalTitle',
    defaultMessage: 'Decline request',
  },
  DeclinePromotionMessage: {
    id: 'Betterme.AcceptDeclinePromotion.DeclineModalMessage',
    defaultMessage: 'Make sure to have had a meaningful conversation with other leaders, and checked the promotions requirements before moving ahead. Do you still want to proceed?',
  },
});
