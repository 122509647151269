// @packages
import React from 'react';
import PropTypes from 'prop-types';

// @app
import { feedbackMessages } from 'common/messages';
import CompetencyInfoBox from 'common/CompetencyInfoBox';

// @own
import messages from '../messages';

const OverallOpportunities = ({
  className,
  intl: { formatMessage },
  loading,
  newStyle,
  opportunities,
  rating,
  trackEmptyLink,
  trackLink,
}) => {
  const emptyDescription = formatMessage(messages.TeamDashboardBoxEmptyDescription);
  const emptyInfo = formatMessage(messages.TeamDashboardBoxEmptyInfo);
  const emptyLink = {
    label: formatMessage(feedbackMessages.FeedbackSendFeedback),
    to: '/newfeedbacksend',
    onClick: trackEmptyLink,
  };

  const link = {
    label: formatMessage(feedbackMessages.FeedbackSendFeedback),
    to: '/newfeedbacksend',
    onClick: trackLink,
  };

  return (
    <CompetencyInfoBox
      className={className}
      color={rating.color}
      competencies={opportunities.slice(0, 1)}
      empty={{
        description: emptyDescription,
        info: emptyInfo,
        link: emptyLink,
      }}
      link={link}
      loading={loading}
      newStyle={newStyle}
      stacked
      title={formatMessage(feedbackMessages.FeedbackOpportunity)}
    />
  );
};

OverallOpportunities.defaultProps = {
  newStyle: false,
};

OverallOpportunities.propTypes = {
  className: PropTypes.string,
  intl: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  newStyle: PropTypes.bool,
  rating: PropTypes.shape({
    color: PropTypes.string,
  }).isRequired,
  opportunities: PropTypes.array.isRequired,
  trackEmptyLink: PropTypes.func.isRequired,
};

export default OverallOpportunities;
