import {
  CLEAN_NOTIFICATIONS_HIS,
  MARK_ALL_NOTIFICATION_HISTORY_AS_READ,
  MARK_ALL_NOTIFICATION_HISTORY_AS_READ_FAIL,
  MARK_ALL_NOTIFICATION_HISTORY_AS_READ_SUCCESS,
  REQUEST_NOTIFICATIONS_HIS_FAILED,
  REQUEST_NOTIFICATIONS_HIS_SUCCESS,
  SET_READ_NOTIFICATION_HISTORY,
  SET_READ_NOTIFICATION_HISTORY_FAIL,
  SET_READ_NOTIFICATION_HISTORY_SUCCESS,
} from './actionTypes';


const initialState = {
  list: [],
  fetching: true,
  error: '',
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case CLEAN_NOTIFICATIONS_HIS:
      return initialState;
    case MARK_ALL_NOTIFICATION_HISTORY_AS_READ:
      return {
        ...state,
        fetching: true,
      };
    case MARK_ALL_NOTIFICATION_HISTORY_AS_READ_FAIL:
      return {
        ...state,
        fetching: false,
        error: payload,
      };
    case MARK_ALL_NOTIFICATION_HISTORY_AS_READ_SUCCESS:
      return {
        ...state,
        fetching: false,
        list: state.list.map(el => ({
          ...el,
          isRead: true,
        })),
      };
    case REQUEST_NOTIFICATIONS_HIS_SUCCESS:
      return {
        ...state,
        list: state.list.concat(payload),
        fetching: false,
        error: '',
      };
    case REQUEST_NOTIFICATIONS_HIS_FAILED:
      return {
        ...state,
        fetching: false,
        error: payload,
      };
    case SET_READ_NOTIFICATION_HISTORY:
      return {
        ...state,
        fetching: true,
      };
    case SET_READ_NOTIFICATION_HISTORY_FAIL:
      return {
        ...state,
        fetching: false,
        error: payload,
      };
    case SET_READ_NOTIFICATION_HISTORY_SUCCESS:
      return {
        ...state,
        fetching: false,
        list: state?.list?.map(el => {
          if (el?.id === payload) {
            return {
              ...el,
              isRead: true,
            };
          }
          return el;
        }),
      };
    default:
      return state;
  }
}
