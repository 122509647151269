// @packages
import React from 'react';
import PropTypes from 'prop-types';
import RootModal from 'smu-app-components/RootModal';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { push } from 'react-router-redux';

// @app
import GenericModal from 'components/templates/GenericModalTemplate';
import InfoCard from 'components/molecules/InfoCard';
import {
  ACTION_TYPE_REQUIRED,
  SIZE_FIFTY,
  SIZE_THIRTY_SIX,
} from 'components/common/constants';
import { FEEDBACK_REQUIRED_MODAL } from 'betterme-components/constants/modalTypes';
import { actionsMessages } from 'common/messages';
import { centerModalClass } from 'betterme-components/ConfirmationModal';
import { getNewFeedbackLocation } from 'betterme-components/routes/urls';
import { selectActionRequiredLoading } from 'betterme-components/services/ReasonsActions/selectors';
import { removeLocalStoredItem } from 'utils/localStorage';

// @own
import messages from './messages';

const FeedbackRequiredModal = ({
  actionsLoading,
  closeModal,
  goToNewFeedback,
  intl: { formatMessage },
  user,
}) => {

  function handleCancel() {
    closeModal();
    removeLocalStoredItem(ACTION_TYPE_REQUIRED);
  };

  function handleAccept() {
    goToNewFeedback(user);
    closeModal();
  };

  const collaborator = `${user?.firstName} ${user?.lastName}`;

  const actionButtons = [
    {
      label: formatMessage(actionsMessages.ActionsCancel),
      onClick: handleCancel,
      variant: 'outline',
    },
    {
      label: formatMessage(actionsMessages.ActionsContinue),
      onClick: handleAccept,
    },
  ];

  return (
    <GenericModal
      actionButtons={actionButtons}
      header={
        <InfoCard
          icon="info"
          iconSize={SIZE_FIFTY}
          message={formatMessage(messages.SendFeedback, { collaborator })}
          title={formatMessage(messages.FeedbackRequired)}
          titleSize={SIZE_THIRTY_SIX}
        />
      }
      loading={actionsLoading}
      onClose={handleCancel}
      success
    />
  );
};

FeedbackRequiredModal.propTypes = {
  actionsLoading: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  goToNewFeedback: PropTypes.func.isRequired,
  intl: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const modalStyles = {
  width: '610px',
};

const mapStateToProps = (state) => ({
  actionsLoading: selectActionRequiredLoading(state),
});

const mapDispatchToProps = {
  goToNewFeedback: (user) => push(getNewFeedbackLocation(user)),
};

export default RootModal(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(FeedbackRequiredModal)),
  FEEDBACK_REQUIRED_MODAL,
  modalStyles,
  centerModalClass,
);
