// @package
import React, { useEffect } from 'react';
import Divider from 'smu-ui-components/Divider';
import Icon from 'smu-ui-components/IconV2';
import PropTypes from 'prop-types';
import Segment from 'smu-ui-components/Segment';
import Tooltip from 'smu-labs/Tooltip';
import Typography from 'smu-ui-components/Typography';
import cn from 'classnames';

// @app
import File from 'betterme-components/components/molecules/File';
import UploadFileButton from 'betterme-components/components/molecules/UploadFileButton';

// @own
import './styles.scss';

const UploadFile = ({
  className,
  customText,
  disabled,
  file,
  fileDownload,
  fileDownloadReset,
  files,
  handleDelete,
  loading,
  onChange,
  promotionsAttachedFilesMessages,
  promotionsSupportedFormatsMessages,
  renderTitleText,
  supportedFormats,
  textRemoveFile,
}) => {
  const supportedFormatsParsed = supportedFormats
    .map((format) => `"${format}"`)
    .join(', ');
  const isFileEmpty = Object.keys(file)?.length === 0;

  useEffect(() => {
    if (!isFileEmpty) {
      let downloadFileAnchor = document.createElement('a');
      downloadFileAnchor.setAttribute('download', file.name);
      downloadFileAnchor.setAttribute(
        'href',
        `data:;base64,${file.byteArrayResource}`
      );
      downloadFileAnchor.style.display = 'none';
      document.body.appendChild(downloadFileAnchor);
      downloadFileAnchor.click();
      document.body.removeChild(downloadFileAnchor);
      fileDownloadReset();
    }
  }, [file]);

  return (
    <Segment
      className={cn('upload-file', className)}
      loading={loading}
      loadingType="betterme"
    >
      <div className="upload-file__heading flex flex-col space-y-2 items-baseline">
        <div className="upload-file__title">
          <div>
            {renderTitleText
              ? renderTitleText(promotionsAttachedFilesMessages)
              : promotionsAttachedFilesMessages}
            {customText && (
              <span className="upload-file__custom-text">{customText}</span>
            )}
          </div>
          <Typography className="font-rubik text-sm text-haiti font-normal">
            {promotionsSupportedFormatsMessages} {supportedFormatsParsed}
          </Typography>
        </div>
        <div className="upload-file__button">
          <UploadFileButton
            disabled={disabled}
            onChange={onChange}
            supportedFormats={supportedFormats}
          />
        </div>
      </div>
      <div className="upload-file__files">
        {files?.map((file, key) => (
          <>
            <File
              actionIcon="delete"
              fileDownload={fileDownload}
              fileName={file?.fileName || file?.name}
              id={file?.id}
              key={key}
              onActionClick={handleDelete}
              textRemoveFile={textRemoveFile}
            />
            {key !== files?.length && <Divider nomargin />}
          </>
        ))}
      </div>
    </Segment>
  );
};

UploadFile.propTypes = {
  className: PropTypes.string,
  customText: PropTypes.string,
  disabled: PropTypes.bool,
  file: PropTypes.object,
  fileDownload: PropTypes.func,
  fileDownloadReset: PropTypes.func,
  files: PropTypes.array,
  handleDelete: PropTypes.func,
  intl: PropTypes.object,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  promotionsAttachFileMessage: PropTypes.string,
  promotionsSupportedFormatsMessage: PropTypes.string,
  renderTitleText: PropTypes.func,
  supportedFormats: PropTypes.array,
  textRemoveFile: PropTypes.string,
};

export default UploadFile;
