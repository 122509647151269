// @packages
import { takeLatest, call, put } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import { openModal } from 'smu-app-components/RootModal/actions';

// @app
import { REMOVE_LEADER_WELLDONE_MODAL } from 'betterme-components/constants/modalTypes';

// @own
import { REMOVE_LEADER } from './actionTypes';
import {
  removeLeaderFail,
  removeLeaderSuccess,
} from './actions';
import { removeLeader } from './api';

function* removeLeaderWorker({ payload }) {
  const {
    collaborator,
    leaderType,
    reasonId,
    successMessage,
    type,
  } = payload;
  const data = {
    leaderType: type,
    reasonId,
  };
  try {
    yield call(removeLeader, collaborator?.id, data);
    yield put(
      openModal({
        modalType: REMOVE_LEADER_WELLDONE_MODAL,
        modalProps: { leaderType, collaborator },
      })
    );
    yield put(removeLeaderSuccess());
    yield put(addToastMessage({ message: successMessage, timeout: 3000 }));
  } catch (error) {
    yield put(removeLeaderFail(error.message));
    yield put(
      addToastMessage({ type: 'error', message: error.message, timeout: 3000 })
    );
  }
}

export default function* removeLeaderWatcher() {
  yield takeLatest(REMOVE_LEADER, removeLeaderWorker);
}
