// @packages
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InputRadio from 'smu-ui-components/InputRadio';
import Segment from 'smu-ui-components/Segment';
import RootModal from 'smu-app-components/RootModal';

// @app
import { FEEDBACK_REQUEST_DISMISS } from '../../constants/modalTypes';
import ConfirmationModal, { centerModalClass, modalStyles }  from '../../ConfirmationModal';
import {
  trackModalDeclineCancelButton,
  trackModalDeclineAcceptButton,
} from '../analytics';

// @own
import './styles.scss';

class FeedbackRequestDismissModal extends Component {
  constructor() {
    super();

    this.handleAccept = this.handleAccept.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
  }

  componentDidMount() {
    const { getRequestDismissReasons } = this.props;
    getRequestDismissReasons();
  }

  componentWillUnmount() {
    const { selectReason } = this.props;
    selectReason();
  }

  handleAccept() {
    const {
      close,
      createFeedbackRequestDismiss,
      requestId,
      selectedReason,
      texts: { toast },
      userId,
    } = this.props;

    createFeedbackRequestDismiss(requestId, selectedReason, userId, toast);
    close();
  }

  handleRadioChange(event) {
    const { value } = event.target;
    const { selectReason } = this.props;

    selectReason(value);
  }

  render() {
    const {
      close,
      loadingReasons,
      texts: {
        accept,
        cancel,
        title,
      },
      reasons,
      selectedReason,
    } = this.props;

    return (
      <ConfirmationModal
        acceptMessage={accept}
        cancelMessage={cancel}
        className="feedback-request-dissmiss-modal"
        disableAccept={!selectedReason}
        onAccept={() => {
          this.handleAccept();
          trackModalDeclineAcceptButton(selectedReason);
        }}
        onCancel={() => {
          close();
          trackModalDeclineCancelButton();
        }}
        title={title}
      >
        {/* TODO implement <FeedbackRequestDismiss> here */}
        <Segment
          className="feedback-request-dissmiss-modal__radios"
          loading={loadingReasons}
          loadingType="betterme"
        >
          {reasons.map(reason =>
            <InputRadio
              checked={selectedReason === reason.id}
              idName={reason.id}
              key={reason.id}
              label={reason.name}
              onChange={this.handleRadioChange}
              value={reason.id}
            />
          )}
        </Segment>
      </ConfirmationModal>
    );
  }
}

FeedbackRequestDismissModal.propTypes = {
  close: PropTypes.func.isRequired,
  createFeedbackRequestDismiss: PropTypes.func.isRequired,
  getRequestDismissReasons: PropTypes.func.isRequired,
  loadingReasons: PropTypes.bool.isRequired,
  reasons: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  requestId: PropTypes.number.isRequired,
  selectReason: PropTypes.func.isRequired,
  selectedReason: PropTypes.number,
  texts: PropTypes.shape({
    accept: PropTypes.string.isRequired,
    cancel: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default RootModal(
  FeedbackRequestDismissModal,
  FEEDBACK_REQUEST_DISMISS,
  modalStyles,
  centerModalClass,
);
