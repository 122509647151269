import { trackEvent } from 'smu-utils/gtm';
const MY_PROFILE = 'My_Profile';

export const trackSendFeedback = () => {
  trackEvent({
    'category': MY_PROFILE,
    'action': 'Send_Feedback',
  });
};

export const trackRequestFeedback = () => {
  trackEvent({
    'category': MY_PROFILE,
    'action': 'Request_Feedback',
  });
};

export const trackRequestFeedbackOnBehalf = () => {
  trackEvent({
    'category': MY_PROFILE,
    'action': 'RequestOnBehalf_Button',
  });
};

export const trackCreateGoal = () => {
  trackEvent({
    'category': MY_PROFILE,
    'action': 'Create_Goal',
  });
};

export const trackCreateSelfEvaluation = () => {
  trackEvent({
    'category': MY_PROFILE,
    'action': 'Create_SelfEvaluation',
  });
};
