// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'smu-ui-components/Icon';

// @own
import './styles.scss';

const SkillSelectorTrigger = ({
  className,
  label,
  onClick,
}) => (
  <div
    className={cn('skill-selector-trigger', className)}
    onClick={onClick}
  >
    {label}
    <Icon
      className="skill-selector-trigger__icon"
      color="black"
      icon="more"
    />
  </div>
);

SkillSelectorTrigger.defaultProps = {
  onClick: () => undefined,
};

SkillSelectorTrigger.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
};

export default SkillSelectorTrigger;
