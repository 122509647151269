// @packages
import React from 'react';
import PropTypes from 'prop-types';

// @own
import './styles.scss';

const InsightDetail = ({ answer }) => {
  return (
    <div className='insight-detail'>
      {answer}
    </div>
  );
};

InsightDetail.propTypes = {
  answer: PropTypes.object.isRequired,
};

export default InsightDetail;