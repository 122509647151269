import {
  ADD_LEADER_ACTION,
  ADD_LEADER_ACTION_FAIL,
  ADD_LEADER_ACTION_SUCCESS,
  REQUEST_LEADER,
  REQUEST_LEADER_FAIL,
  REQUEST_LEADER_SUCCESS
} from "./actionsTypes";

const initialState = {
  error: null,
  fetching: false,
  response: undefined
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case ADD_LEADER_ACTION:
    case REQUEST_LEADER:
      return { ...state, ...payload, fetching: true };
    case ADD_LEADER_ACTION_FAIL:
    case ADD_LEADER_ACTION_SUCCESS:
    case REQUEST_LEADER_FAIL:
    case REQUEST_LEADER_SUCCESS:
      return { ...state, ...payload, fetching: false };
    default:
      return state;
  }
}
