// @packages
import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import RootModal from 'smu-app-components/RootModal';

// @app
import GenericModal from 'components/templates/GenericModalTemplate';
import InfoCard from 'components/molecules/InfoCard';
import { SIZE_FIFTY, SIZE_THIRTY_SIX } from 'components/common/constants';
import {
  actionsMessages,
  commonMessages,
  promotionsMessages,
} from 'common/messages';
import { DELETE_FILE_MODAL } from 'betterme-components/constants/modalTypes';

const DeleteFileModal = ({
  closeModal,
  fileId,
  intl: { formatMessage },
  loadingDeleteDraft,
  onAccept,
}) => {
  function handleAccept() {
    onAccept(fileId);
    closeModal();
  };

  const actionButton = [
    {
      label: formatMessage(actionsMessages.ActionsCancel),
      onClick: closeModal,
      variant: 'outline'
    },
    {
      label: formatMessage(actionsMessages.ActionsDelete),
      onClick: () => handleAccept(),
    },
  ];

  return(
    <GenericModal
      actionButtons={actionButton}
      header={
        <InfoCard
          icon="info"
          iconSize={SIZE_FIFTY}
          message={formatMessage(promotionsMessages.PromotionsDeleteModalMessage)}
          title={formatMessage(commonMessages.KeepInMind)}
          titleSize={SIZE_THIRTY_SIX}
        />
      }
      loading={loadingDeleteDraft}
      onClose={closeModal}
      success
    />
)
};

DeleteFileModal.propTypes = {
  close: PropTypes.func.isRequired,
  draftId: PropTypes.number.isRequired,
  intl: PropTypes.object.isRequired,
  loadingDeleteDraft: PropTypes.bool,
};

const modalStyles = { maxWidth: '600px' };
const withRootModalComponent = RootModal(
  injectIntl(DeleteFileModal),
  DELETE_FILE_MODAL,
  modalStyles,
  'modal__wrapper',
);

export default withRootModalComponent;
