// @app
import { ENABLED_TALENT_MATRIX } from 'betterme-components/services/CommunityConfigs/constants';
import { getCommunityConfigValueBool } from 'betterme-components/services/CommunityConfigs/selectors';

/**
 * Returns if the Talent Matrix feature is enabled
 * @param {object} state
 * @param {string} userIdentification
 * @returns {boolean}
 */
export function isEnabledTalentMatrix(state) {
  const isEnabledFeature = getCommunityConfigValueBool(
    state,
    ENABLED_TALENT_MATRIX
  );

  return isEnabledFeature;
}

/**
 * Returns the Talent Matrix main user.
 * @param {object} state
 * @param {string} userIdentification
 * @returns {object}
 */
export function getTalentMatrixMainUser(state, userIdentification) {
  const loggedUser = state?.session?.user;
  const asMain = loggedUser?.collaborators?.asMain;
  const asMainAndSecondary = loggedUser?.collaborators?.asMainAndSecondary;

  const isMain = asMain?.find(
    (user) => user?.identification === userIdentification
  );

  const isMainAndSecondary = asMainAndSecondary?.find(
    (user) => user?.identification === userIdentification
  );

  return isMain || isMainAndSecondary;
}

/**
 * Returns if the Talent Matrix feature is enabled for the user.
 * @param {object} state
 * @param {string} userIdentification
 * @returns {boolean}
 */
export function isEnabledTalentMatrixByLeader(state, userIdentification) {
  const isMainLeader = Boolean(
    getTalentMatrixMainUser(state, userIdentification)
  );

  const isEnabledFeature = isEnabledTalentMatrix(state);

  return isEnabledFeature && isMainLeader;
}
