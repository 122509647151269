// @packages
import moment from 'moment';

export function getCalendarYears(pastOffset = 5, futureOffset = 2) {
  const yearNow = moment().year();
  const start = yearNow - pastOffset;
  const years = [];

  for (let i = 0; i <= pastOffset + futureOffset; i++) {
    years[i] = start + i;
  }

  return years;
}
