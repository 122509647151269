import {
  GET_GOAL_DETAIL,
  GET_GOAL_DETAIL_SUCCESS,
  GET_GOAL_DETAIL_FAIL,
  GET_GOALS_PERIOD,
  GET_GOALS_PERIOD_FAIL,
  GET_GOALS_PERIOD_SUCCESS,
  SELECT_GOAL,
  RESET_GOAL_DETAIL,
} from './actionTypes';

const initialState = {
  goalDetail: {},
  goalsPeriod: {},
  loading: false,
  loadingPeriods: false,
  selectedGoalId: undefined,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_GOALS_PERIOD:
      return { ...state, loadingPeriods: true, error: false };

    case GET_GOALS_PERIOD_SUCCESS:
      return { ...state, loadingPeriods: false, goalsPeriod: payload.response };

    case GET_GOALS_PERIOD_FAIL:
      return { ...state, loadingPeriods: false, goalsPeriod: {}, error: payload.error };

    case GET_GOAL_DETAIL:
      return { ...state, loading: true, error: false };

    case GET_GOAL_DETAIL_SUCCESS:
      return { ...state, loading: false, goalDetail: payload.response };

    case GET_GOAL_DETAIL_FAIL:
      return { ...state, loading: false, goalDetail: {}, error: payload.error };

    case SELECT_GOAL:
      return { ...state, selectedGoalId: payload.goalId };

    case RESET_GOAL_DETAIL:
      return { ...state, goalDetail: {}, goalsPeriod: {} };

    default:
      return state;
  }
}
