import { defineMessages } from 'react-intl';

export default defineMessages({
  SendEvaluation: {
    id: 'Betterme.SendEvaluationConfirmationModal.SendEvaluation',
    defaultMessage: 'Send evaluation',
  },
  SendPerformanceRetrospective: {
    id: 'Betterme.SendEvaluationConfirmationModal.SendPerformanceRetrosPective',
    defaultMessage: 'Send Performance Retrospective',
  },
  SendPerformanceRetrospectiveSubmitDraft: {
    id: 'Betterme.PerformanceRetrospective.SubmitDraft',
    defaultMessage: 'Are you sure you want to submit this Performance Retrospective and send notifications?',
  },
  FeedbackEvaluationsEvaluationCreateAlertSubmitDraft: {
    id: 'Betterme.Evaluations.EvaluationCreateAlertSubmitDraft',
    defaultMessage: 'Are you sure you want to submit this Evaluation and send notifications?',
  },
  coCreatedNotRepliedYetTitle: {
    id: 'Betterme.SendEvaluationConfirmationModal.coCreatedNotRepliedYetTitle',
    defaultMessage: 'Co-evaluator hasn’t replied yet',
  },
  coCreatedNotRepliedYet: {
    id: 'Betterme.SendEvaluationConfirmationModal.coCreatedNotRepliedYet',
    defaultMessage: 'The Technical Input should be on its way. Are you sure you want to send the evaluation without a technical input?',
  },
  CoCreatedPRNotRepliedYetTitle: {
    id: 'Betterme.SendEvaluationConfirmationModal.coCreatedPRNotRepliedYetTitle',
    defaultMessage: 'Co-Creator hasn’t replied yet',
  },
  CoCreatedPRNotRepliedYet: {
    id: 'Betterme.SendEvaluationConfirmationModal.coCreatedPRNotRepliedYet',
    defaultMessage: 'The Technical Input should be on its way. Are you sure you want to send the Performance Retrospective without a technical input?',
  },
  SendSelfRetrospective: {
    id: 'Betterme.SendEvaluationConfirmationModal.SendSelfRetrosPective',
    defaultMessage: 'Send <br/> Self-Retrospective',
  },
  SendSelfRetrospectiveSubmitDraft: {
    id: 'Betterme.SelfRetrospective.SubmitDraft',
    defaultMessage: 'Are you sure you want to submit this Self-Retrospective and send notifications?',
  },
  SubmitIt: {
    id: 'Betterme.SendEvaluationConfirmationModal.SubmitIt',
    defaultMessage: 'Yes, submit it',
  },
});
