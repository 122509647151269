// @packages
import get from 'lodash/get';

// @app
import { getTalentMatrixDimensions } from 'betterme-components/TalentMatrix/helpers';

export function getEvaluationDraftData(
  evaluationDetail,
  suggestedActions,
) {
  const responseRatedTopics = get(evaluationDetail, 'ratedTopics', []);
  const ratedTopics = responseRatedTopics?.reduce((acc, ratedTopic) => {
    const competenciesWithRate = ratedTopic?.topics?.map(competency =>
      ({ competency, rateId: ratedTopic?.id }));
    return [...acc, ...competenciesWithRate];
  }, []);

  const selectedSuggestedActionsList = get(
    evaluationDetail,
    'selectedSuggestedActions.suggestedActions',
    [],
  );
  const suggestedActionsIds = suggestedActions && (suggestedActions
    ?.filter(({ name }) => selectedSuggestedActionsList?.some(action => action?.name === name))
    ?.map(({ id }) => id));

  return {
    attachments: evaluationDetail?.attachments,
    comment: evaluationDetail?.comment,
    customScore: get(evaluationDetail, 'customScore.value'),
    draftId: evaluationDetail?.id,
    dateTimeFrom: evaluationDetail?.dateTimeFrom,
    dateTimeTo: evaluationDetail?.dateTimeTo,
    evaluee: evaluationDetail?.evaluee,
    nineBoxComment: get(evaluationDetail, 'selectedNineBox.comment'),
    nineBoxScore: get(evaluationDetail, 'selectedNineBox.nineBoxScore.id'),
    ratedTopics,
    score: (evaluationDetail?.score && evaluationDetail?.score?.value),
    suggestedActionsComment: get(evaluationDetail, 'selectedSuggestedActions.comment'),
    suggestedActionsIds,
    summary: evaluationDetail?.summary,
    type: evaluationDetail?.type,
    promotion: {
      comment: get(evaluationDetail, 'promotion.comment'),
      levelId: get(evaluationDetail, 'promotion.level.id'),
      promotedId: get(evaluationDetail, 'promotion.promoted.id'),
    },
    talentMatrix: {
      dimensions: getTalentMatrixDimensions(
        evaluationDetail?.talentMatrix?.dimensions
      ),
    },
  };
}
