// @packages
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import Button from 'smu-ui-components/Button';
import Segment from 'smu-ui-components/Segment';
import withRootModal from 'smu-app-components/RootModal/withRootModal';

// @app
import FeatureDiscovery from 'common/FeatureDiscovery';
import Title from 'components/atoms/Title';
import { FEEDBACK_FEED } from 'common/FeatureDiscovery/constGroups';
import { FEEDBACK_REQUEST_INBOX_VIEW } from 'common/FeatureDiscovery/constTypes';
import { FEEDBACK_REQUEST_QUICK_VIEW } from '../constants/modalTypes';
import { feedbackMessages } from 'common/messages';
import { selectConfigurations } from '../Authorization/selectors';

// @own
import './styles.scss';
import * as actions from './actions';
import { trackViewAllClick, trackViewClick } from './analytics';
import { PAGE_SIZE } from './constants';
import messages from './messages';
import {
  selectFeedbackRequestReasons,
  selectFeedbacksRequestReceived,
  selectLoadingFeedbacksRequests,
  selectLoadingReasons,
  selectPageSize,
  selectSelectedReason,
  selectTotalElements,
} from './selectors';
import FeedbackRequestItem from './FeedbackRequestItem';
import FeedbackRequestQuickView from './FeedbackRequestQuickView';

class FeedbackRequest extends Component {
  constructor(props) {
    super(props);

    this.handleFeedbackReqItemClick = this.handleFeedbackReqItemClick.bind(this);
  }

  componentDidMount() {
    const { getFeedbackRequestReceived, user } = this.props;
    const params = { pageSize: PAGE_SIZE };
    getFeedbackRequestReceived(user.id, params);
  }

  componentWillUnmount() {
    const { closeModal } = this.props;
    closeModal();
  }

  handleFeedbackReqItemClick(request) {
    const {
      allowsRequestedOnBehalfVisibility,
      openModal,
    } = this.props;

    openModal({
      modalType: FEEDBACK_REQUEST_QUICK_VIEW,
      modalProps: {
        feedbackRequest: {
          allowsRequestedOnBehalfVisibility,
          comment: request.comment,
          creationTime: request.creationTime,
          id: request.requestId,
          requestedBy: allowsRequestedOnBehalfVisibility && request.requestedBy,
          topics: request.topics,
          user: request.sender,
        }
      }
    });
  }

  renderFeedbackRequestSegment() {
    const {
      intl: { formatMessage },
      feedbacksRequests,
      goToFeedbackRequestsInbox,
    } = this.props;

    return (
      <div>
        {feedbacksRequests.length > 0 ? (
          <ul className="feedback-request__list">
            {feedbacksRequests.map((item) =>
              <FeedbackRequestItem
                actionText={formatMessage(messages.FeedbackRequestActionView)}
                item={item}
                key={item.requestId}
                onClick={() => {
                  this.handleFeedbackReqItemClick(item);
                  trackViewClick();
                }}
              />
            )}
          </ul>
        ) : (
          <div className="feedback-request__placeholder">
            {formatMessage(messages.FeedbackRequestPlaceholder)}
          </div>
        )}
        <FeatureDiscovery
          group={FEEDBACK_FEED}
          position="left"
          text={formatMessage(messages.FeedbackRequestDismissFeatureDiscoveryView)}
          type={FEEDBACK_REQUEST_INBOX_VIEW}
        >
          <Button
            className="feedback-request__show-all"
            fluid
            noMargin
            noPadding
            type="default"
            onClick={() => {
              trackViewAllClick();
              goToFeedbackRequestsInbox();
            }}
          >
            {formatMessage(messages.FeedbackRequestViewAll)}
          </Button>
        </FeatureDiscovery>
      </div>
    );
  }

  render() {
    const {
      allowsFeedbackRequestsDismiss,
      feedbacksRequests,
      goToSendRequestFeedback,
      intl: { formatMessage },
      loadingFeedbackRequests,
      user,
    } = this.props;

    return (
      <div className="feedback-request">
        <Segment
          loading={loadingFeedbackRequests}
          loadingType="betterme"
          withChildren={feedbacksRequests.length > 0}
        >
          <Title
            className="feedback-request__title"
            nomargin
            size={16}
          >
            {formatMessage(feedbackMessages.FeedbackRequests)}
          </Title>
          {this.renderFeedbackRequestSegment()}
        </Segment>
        <FeedbackRequestQuickView
          modalProps={{
            allowsFeedbackRequestsDismiss,
            goToSendRequestFeedback,
            userId: user.id,
          }}
        />
      </div>
    );
  }
}

FeedbackRequest.propTypes = {
  allowsRequestedOnBehalfVisibility: PropTypes.bool,
  createFeedbackRequestDismiss: PropTypes.func.isRequired,
  dismissReasons: PropTypes.array.isRequired,
  feedbacksRequests: PropTypes.array.isRequired,
  getFeedbacksRequest: PropTypes.func.isRequired,
  getRequestDismissReasons: PropTypes.func.isRequired,
  goToSendRequestFeedback: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  loadingFeedbackRequests: PropTypes.bool.isRequired,
  loadingReasons: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  pageSize: PropTypes.number,
  selectReason: PropTypes.func.isRequired,
  selectedReason: PropTypes.number,
  totalElements: PropTypes.number,
};

const mapStateToProps = (state) => {
  return {
    allowsFeedbackRequestsDismiss: selectConfigurations('allowsFeedbackRequestsDismiss')(state),
    allowsRequestedOnBehalfVisibility: selectConfigurations('allowsRequestedOnBehalfVisibility')(state),
    dismissReasons: selectFeedbackRequestReasons(state),
    feedbacksRequests: selectFeedbacksRequestReceived(state),
    loadingFeedbackRequests: selectLoadingFeedbacksRequests(state),
    loadingReasons: selectLoadingReasons(state),
    pageSize: selectPageSize(state),
    selectedReason: selectSelectedReason(state),
    totalElements: selectTotalElements(state),
  };
};

const mapDispatchToProps = {
  goToFeedbackRequestsInbox: (requestId) => {
    const requestIdUrl = requestId ? `/${requestId}` : '';

    return push(`/feedbackrequests${requestIdUrl}`);
  },
  goToSendRequestFeedback: (user, requestId, requestedBy, initCompetency, comment) => {
    const pathname = `/replyfeedback/${requestId}`;
    const locationDescriptor = {
      pathname,
      state: { user, requestId, requestedBy, initCompetency, comment },
    };

    return push(locationDescriptor);
  },
  ...actions,
};

FeedbackRequest.propTypes = {
  getFeedbackRequestReceived: PropTypes.func.isRequired,
  goToFeedbackRequestsInbox: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,

};


export default connect(mapStateToProps, mapDispatchToProps)(withRootModal(injectIntl(FeedbackRequest)));
