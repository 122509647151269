/* eslint-disable */

// @packages
import React from 'react';

class HighchartsContainer extends React.Component {
  componentDidMount() {
    const p = this.props;
    const highcharts = p.highcharts || window.Highcharts;
    const constructorType = p.constructorType || 'chart';
    // Create chart
    this.chart = highcharts[constructorType](this.container, Object.assign({}, p.options));
  }

  componentWillReceiveProps() {
    this.chart.update(Object.assign({}, this.props.options), true, !(this.props.oneToOne === false))
  }

  shouldComponentUpdate() {
    const { update } = this.props;
    // Update if not specified or set to true
    return (typeof update === 'undefined') || update;
  }

  componentDidUpdate() {
    this.chart.update(Object.assign(
      {},
      this.props.options,
    ), true, !(this.props.oneToOne === false));
  }

  componentWillUnmount() {
    // Destroy chart
    if (this.chart) {
      this.chart.destroy();
    }
  }

  render() {
    const self = this
    const containerProps = this.props.containerProps || {}

    // Add ref to div props
    containerProps.ref = function (container) { self.container = container }

    // Create container for our chart
    return React.createElement('div', containerProps)
  }
}

export default HighchartsContainer;
