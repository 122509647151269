import {
  GET_TOPICS_RATED,
  GET_TOPICS_RATED_FAIL,
  GET_TOPICS_RATED_SUCCESS,
  SELECT_COMPETENCY,
  SELECT_RATING,
  SORT_TOPICS_RATED,
} from './actionTypes';

export function getTopicsRated(userId, params) {
  return {
    type: GET_TOPICS_RATED,
    payload: { userId, params },
  };
}

export function getTopicsRatedFail(error) {
  return {
    type: GET_TOPICS_RATED_FAIL,
    payload: { error },
  };
}

export function getTopicsRatedSuccess(response) {
  return {
    type: GET_TOPICS_RATED_SUCCESS,
    payload: { response },
  };
}

export function selectCompetency(competencyId) {
  return {
    type: SELECT_COMPETENCY,
    payload: { competencyId }
  };
}

export function selectRating(ratingId) {
  return {
    type: SELECT_RATING,
    payload: { ratingId }
  };
}

export function sortTopicsRated(order) {
  return {
    type: SORT_TOPICS_RATED,
    payload: { order },
  };
}
