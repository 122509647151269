// @packages
import { base64Encode } from 'smu-utils/base64';

// @app
import { MODAL_QUERY_ONBOARDING } from 'betterme-components/Onboarding/OnboardingModal/constants';
import { MODAL_QUERY_WHATS_NEW } from 'betterme-components/WhatsNewModal/constants';
import { objectToGetParams } from 'betterme-components/utils/url';

/* Public resources */
const ADD_LEADER_FORM = 'https://docs.google.com/forms/d/e/1FAIpQLSc_9TrfwYrJQAXr2MOF_gydIEEnaCMrIUHSyLyO-BlRFPpL1Q/viewform';
const LEADERSHIP_COMMUNITY_PROMOTIONS = 'https://sites.google.com/globant.com/leadershipcommunity/developing-my-team/readiness-promotions?authuser=0';
const POTENTIAL_ASSESSMENT_EXTERNAL_URL = 'https://sites.google.com/globant.com/leadershipcommunity/developing-my-team/potential-development';

const ADD_TEAM_MEMBER = '/add-team-member'
const ASSESSMENT_URL = '/behaviour-assessment';
const ENGAGEMENT_FORM_URL = '/engagement';
const FEEDBACK_FEED_URL = '/feedback';
const FEEDBACK_FOR_LEADERS_RECEIVED_URL = '/myprofile/feedback-for-leaders-received';
const LEADERSHIP_ADMIN_URL = '/leadership-admin';
const LEADERSHIP_REQUEST = '/leadership-request';
const LEADERS_SUBSTITUTION = '/leaders-substitution';
const MANAGE_LEADERS_URL = '/manage-leaders';
const MODAL_ONBOARDING_URL = `?modal=${MODAL_QUERY_ONBOARDING}`;
const MODAL_WHATS_NEW_URL = `?modal=${MODAL_QUERY_WHATS_NEW}`;
const MY_EVALUATIONS_URL = '/evaluations';
const MY_GOALS_URL = '/goals/mygoals';
const MY_PROFILE = '/myprofile';
const MY_TEAM_DASHBOARD_URL = '/myteam/dashboard';
const MY_TEAM_EVALUATIONS_URL = '/myteam/evaluations';
const MY_TEAM_FEEDBACKS_URL = '/myteam/feedbacks';
const MY_TEAM_GOALS_URL = '/myteam/goals';
const MY_TEAM_PROMOTIONS_URL = '/myteam/promotions';
const MY_TEAM_URL = '/myteam';
const NEW_FEEDBACK_FOR_LEADER_URL = '/new-feedback-for-leaders';
const NEW_FEEDBACK_ON_BEHALF_REQUEST_URL = '/newfeedbackonbehalfrequest';
const NEW_FEEDBACK_REQUEST_URL = '/newfeedbackrequest';
const NEW_FEEDBACK_SEND_URL = '/newfeedbacksend';
const NEW_FEEDBACK_URL = '/newfeedback';
const NEW_KEY_RESULT = '/okr/new-key-result';
const NEW_PROMOTION_URL = '/new-promotion';
const NOTIFICATIONS = '/notifications';
const PERFORMANCE_RETROSPECTIVE = '/performance-retrospective';
const PERIOD_EVALUATION = '/period-evaluation';
const PROFILE_URL = '/profile';
const REPLY_FEEDBACK_URL = '/replyfeedback';
const SELF_RETROSPECTIVE = '/self-retrospective';

export function getEngagementFormLocation(user) {
  const userId = user?.id;
  return {
    pathname: `${ENGAGEMENT_FORM_URL}/${userId}`,
    state: { user },
  };
}

export function getEvaluationsLocation(evaluationState) {
  return {
    pathname: MY_EVALUATIONS_URL,
    state: evaluationState,
  };
}

export function getEvaluationDetailsLocation(evaluationId, showAnswer) {
  return {
    pathname: `${MY_EVALUATIONS_URL}/${evaluationId}`,
    query: { showAnswer },
  };
}

export function getFeedbackForLeadersReceivedLocation(half) {
  return {
    pathname: `${FEEDBACK_FOR_LEADERS_RECEIVED_URL}/${half}`,
  };
}

export function getFeedbackForLeadersEvaluationDetailed(userIdentification, year, half) {
  const identification = base64Encode(userIdentification);
  return {
    pathname: `${PROFILE_URL}/${identification}/dashboard/feedback-for-leaders-received/${year}/${half}`,
  };
}

export function getMyTeamDashboardLocation(teamsDashboardState) {
  return {
    pathname: MY_TEAM_DASHBOARD_URL,
    state: teamsDashboardState,
  };
}

export function getTeamEvaluationsLocation(state = {}) {
  const query = { ...state };
  if (state?.openCreateEvaluation) {
    query.openCreateEvaluation = base64Encode(state?.openCreateEvaluation);
  }
  return {
    pathname: `${MY_TEAM_EVALUATIONS_URL}${objectToGetParams(query)}`,
  };
}

export function getTeamEvaluationDetailsLocation(evaluationId, showAnswer) {
  return {
    pathname: `${MY_TEAM_EVALUATIONS_URL}/${evaluationId}`,
    query: { showAnswer },
  };
}

export function getTeamEvaluationFeedbackForLeadersDetail(collaboratorIdentification, data, evaluationTitle) {
  return {
    pathname: `${MY_TEAM_EVALUATIONS_URL}/half/${collaboratorIdentification}`,
    state: {
      evaluationTitle,
      periods: data,
    }
  };
}

export function getFeedbackFeedLocation() {
  return {
    pathname: FEEDBACK_FEED_URL,
  };
}

export function getFeedbackDetailsLocation(feedbackId, comment) {
  return {
    pathname: `${FEEDBACK_FEED_URL}/${feedbackId}`,
    state: { comment },
  };
}

export function getMyGoalDetailsLocation(goalId) {
  return {
    pathname: `${MY_GOALS_URL}/${goalId}`,
  };
}

export function getMyGoalsLocation() {
  return {
    pathname: MY_GOALS_URL,
  };
}

export function getMyTeamLocation() {
  return {
    pathname: MY_TEAM_URL,
  };
}

export function getMyTeamGoalDetailsLocation(goalId) {
  return {
    pathname: `${MY_TEAM_GOALS_URL}/${goalId}`,
  };
}

export function getMyTeamGoalsLocation() {
  return {
    pathname: MY_TEAM_GOALS_URL,
  };
}

export function getMyTeamPromotionsLocation() {
  return {
    pathname: MY_TEAM_PROMOTIONS_URL,
  };
}

export function getMyTeamPromotionsDetailLocation(promotionId) {
  return {
    pathname: `${MY_TEAM_PROMOTIONS_URL}/${promotionId}`,
  };
}

export function getMyTeamFeedbacksLocation() {
  return {
    pathname: MY_TEAM_FEEDBACKS_URL,
  };
}

export function getNewFeedbackLocation(
  user,
  defaultRequest,
) {
  const identification = base64Encode(user.identification);
  return {
    pathname: `${NEW_FEEDBACK_URL}/${identification}`,
    state: { user, defaultRequest },
  };
}

export function getNewFeedbackRequestLocation(user) {
  const identification = user?.identification
    ? `/${base64Encode(user.identification)}`
    : '';
  return {
    pathname: `${NEW_FEEDBACK_REQUEST_URL}${identification}`,
  };
}

export function getNewFeedbackSendLocation() {
  return {
    pathname: NEW_FEEDBACK_SEND_URL,
  };
}

export function getNewFeedbackRequestOnBehalfLocation(user) {
  return {
    pathname: NEW_FEEDBACK_ON_BEHALF_REQUEST_URL,
    state: { user },
  };
}

export function getProfileLocation(userIdentification) {
  const identification = base64Encode(userIdentification);
  return {
    pathname: `${PROFILE_URL}/${identification}/dashboard`,
  };
}

export function getReplyFeedbackLocation(requestId) {
  return {
    pathname: `${REPLY_FEEDBACK_URL}/${requestId}`,
  };
}

export function getMyProfileLocation() {
  return {
    pathname: MY_PROFILE,
  };
}

export function getNewPromotionLocation(identification) {
  const userId = base64Encode(identification);
  return {
    pathname: `${NEW_PROMOTION_URL}/${userId}`,
  };
}

export function getAddLeaderFormLocation() {
  return {
    pathname: ADD_LEADER_FORM,
  };
}

export function getPotentialAssessmentExternalURL() {
  return POTENTIAL_ASSESSMENT_EXTERNAL_URL;
}

export function getLeadershipCommunityPromotions() {
  return {
    pathname: LEADERSHIP_COMMUNITY_PROMOTIONS,
  };
}

export function getNewFeedbackForLeadersLocation(identification) {
  const userId = base64Encode(identification);
  return {
    pathname: `${NEW_FEEDBACK_FOR_LEADER_URL}/${userId}`,
  };
}

export function getProfileFeedbackForLEadersReceivedLocation(userIdentification, half) {
  const identification = base64Encode(userIdentification);
  return {
    pathname: `${PROFILE_URL}/${identification}/dashboard/feedback-for-leaders-received/${half}`,
  };
}

export function getProfileFeedbackReceivedLocation(userIdentification) {
  const identification = base64Encode(userIdentification);
  return {
    pathname: `${PROFILE_URL}/${identification}/dashboard/feedbackreceived`,
  }
}

export function getMyFeedbackReceivedLocation() {
  return {
    pathname: `${MY_PROFILE}/feedbackreceived`,
  }
}

export function getNewKeyResultLocation() {
  return {
    pathname: NEW_KEY_RESULT,
  };
}

export function getLeadershipAdminLocation(identification) {
  return {
    pathname: `${LEADERSHIP_ADMIN_URL}/${base64Encode(identification)}`,
  }
}

export function getLeaderSubstitutionLocation(identification, leaderType) {
  return {
    pathname: `${LEADERSHIP_ADMIN_URL}/${base64Encode(identification)}${LEADERS_SUBSTITUTION}`,
    state: { leaderType },
  };
}

export function getManageLeadersSubstitutionLocation(
  identification,
  leaderType,
  prevSection,
  oldLeader,
) {
  return {
    pathname: `${MANAGE_LEADERS_URL}/${base64Encode(identification)}${LEADERS_SUBSTITUTION}`,
    state: { leaderType, prevSection, oldLeader },
  };
}

export function getLeadershipRequestLocation(defaultTab) {
  return {
    pathname: LEADERSHIP_REQUEST,
    query: {
      tab: defaultTab
    },
  };
}

export function getModalWhatsNewLocation() {
  return {
    pathname: window.location.pathname,
    search: MODAL_WHATS_NEW_URL,
  };
}

export function getModalOnboardingLocation() {
  return {
    pathname: window.location.pathname,
    search: MODAL_ONBOARDING_URL,
  };
}

export function getAddTeamMemberLocation() {
  return {
    pathname: ADD_TEAM_MEMBER,
  };
}

export function getManageLeadersLocation() {
  return {
    pathname: MANAGE_LEADERS_URL,
  };
}

export function getManageLeadersAdminLocation(userId) {
  const id = base64Encode(userId);
  return {
    pathname: `${MANAGE_LEADERS_URL}${LEADERSHIP_ADMIN_URL}/${id}`,
  };
};

export function getPeriodEvaluationLocation(userIdentification, state) {
  const identification = base64Encode(userIdentification);
  return {
    pathname: `${PERIOD_EVALUATION}/${identification}`,
    state: { ...state },
  };
};

export function getPerformanceRetrospectiveLocation({ userIdentification, evaluationId }) {
  const identification = base64Encode(userIdentification);
  const perfotmanceRetrospectivePath = evaluationId
    ? `${PERFORMANCE_RETROSPECTIVE}/${evaluationId}`
    : `${PERFORMANCE_RETROSPECTIVE}`;
  return {
    pathname: perfotmanceRetrospectivePath,
    query: {
      collaboratorIdentification: identification,
    },
  };
};

export function getSelfRetrospectiveLocation({ userIdentification, evaluationId }) {
  const identification = base64Encode(userIdentification);
  const perfotmanceRetrospectivePath = evaluationId
    ? `${SELF_RETROSPECTIVE}/${identification}/${evaluationId}`
    : `${SELF_RETROSPECTIVE}/${identification}`;
  return {
    pathname: perfotmanceRetrospectivePath,
  };
};

export function assessmentHomePageLocation(user) {
  return {
    pathname: ASSESSMENT_URL,
    state: { user },
  };
};

export function assessmentLocation(userIdentification) {
  const identification = base64Encode(userIdentification);
  return {
    pathname: `${ASSESSMENT_URL}/${identification}`,
  };
};

export function getNotificationHistoryLocation() {
  return {
    pathname: NOTIFICATIONS,
  };
};
