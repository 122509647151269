// @packages
import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Link from 'smu-ui-components/Link';
import LottieSpinner from 'smu-ui-components/LottieSpinner';
import TextWrapper from 'smu-ui-components/TextWrapper';

// @own
import './styles.scss';

const CompetencyInfoBoxUI = ({
  className,
  color,
  description,
  info,
  inverted,
  link: {
    label,
    to,
    onClick: onLinkClick,
  },
  loading,
  newStyle,
  stacked,
  title,
}) => {
  const boxStyle = {
    backgroundColor: !inverted && color ? color : undefined,
  };
  const titleStyle = {
    color: inverted ? color : undefined,
  };
  return (
    <div
      className={cn('competency-info-box', className, {
        'competency-info-box--loading': loading && !color,
        'competency-info-box--inverted': inverted,
        'competency-info-box--stacked': stacked,
        'competency-info-box--new-style': newStyle,
      })}
      style={boxStyle}
    >
      <div className="competency-info-box__title" style={titleStyle}>
        {title}
      </div>
        {loading ? (
          <LottieSpinner
            loadingType="betterme"
            width={40}
            height={40}
          />
        ) : (
          <React.Fragment>
            {info && (
              <div className="competency-info-box__info">
                <TextWrapper ellipsis as="p">
                  {info}
                </TextWrapper>
              </div>
            )}
            {description && (
              <TextWrapper
                as="p"
                className="competency-info-box__description"
                ellipsis
              >
                {description}
              </TextWrapper>
            )}
            {label && (
              <div className="competency-info-box__action-wrapper">
                <Link className="competency-info-box__action" onClick={onLinkClick} to={to}>
                  {label}
                </Link>
              </div>
            )}
          </React.Fragment>
        )}
    </div>
  );
};

CompetencyInfoBoxUI.propTypes = {
  action: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
  className: PropTypes.string,
  color: PropTypes.string,
  description: PropTypes.node,
  info: PropTypes.node,
  inverted: PropTypes.bool,
  link: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  newStyle: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

export default CompetencyInfoBoxUI;