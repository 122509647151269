// @packages
import PropTypes from "prop-types";
import React from "react";
import UserAvatar from "smu-ui-components/UserAvatar";
import UserAvatarList from "common/UserAvatarList";

// @app
import logo from "../../assets/hr_logo.png";

// @own
import "./styles.scss";

const AVATAR_SIZE = 32;

const CardFooter = ({
  actionMessage,
  className,
  customName,
  user,
  arrEvaluators,
}) => {
  const profileImageCode = user?.profileImageCode || user?.imageCode;
  const fullName = `${user?.firstName} ${user?.lastName}`;

  return (
    <div className={`card-footer ${className || ""}`}>
      {arrEvaluators?.length > 1 ? (
        <div style={{ display: "inline-flex" }}>
          <UserAvatarList users={arrEvaluators} maxDisplay={2} size={36} />
          <div className="card-footer__message">
            <span className="card-footer__message-action">{actionMessage}</span>
          </div>
        </div>
      ) : (
        <div>
          { profileImageCode ? (
            <UserAvatar
              profileImageCode={profileImageCode}
              height={AVATAR_SIZE}
              width={AVATAR_SIZE}
            />
            ) : (<img className="card-footer__hr-image" src={logo} />)
          }
          <div className="card-footer__message">
            <span className="card-footer__message-action">{`${actionMessage} `}</span>
            <span className="card-footer__message-user">
              {customName ? customName : fullName}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

CardFooter.propTypes = {
  actionMessage: PropTypes.string.isRequired,
  className: PropTypes.string,
  customName: PropTypes.string,
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string,
    profileImageCode: PropTypes.string,
    imageCode: PropTypes.string,
  }).isRequired,
};

export default CardFooter;
