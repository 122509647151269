// @packages
import trim from 'lodash/trim';
import { buildImageUrl } from 'smu-utils/image';

// @own
import { REQUEST, SEND } from './constants';

export const getSuggestionParams = ({
  comment,
  ratedCompetencies,
  ratings,
  selectedUsers,
  userId,
}) => {
  const competencies = ratings?.reduce((acc, cur) => {
    let c = ratedCompetencies?.filter((r) => r.rateId === cur.id);
    acc[cur.value] = c?.length
      ? c?.map((e) => e?.competency?.name).join(', ')
      : '';
    return acc;
  }, {});

  return {
    type: 'feedback',
    additionalInformation: {
      baseText: comment,
      competencies,
      to: userId,
      toUsers: selectedUsers?.map((u) => u?.id),
    },
  };
};

export const transformAnswers = (data) => {
  const output = Object.keys(data).map((key) => {
    return {
      answer: data[key],
      questionId: parseInt(key),
    };
  });
  return output;
};

export const normalizeChangeSkills = (skills) => {
  const output = skills?.map((skill) => {
    return {
      competency: {
        description: trim(skill?.description),
        id: skill?.id,
        name: trim(skill?.name),
        skill: true,
        suggested: skill?.suggested,
      },
      rateId: skill?.rating,
    };
  });
  return output;
};

export const normalizeValueSkills = (competencies) => {
  const output = competencies
    ?.filter((o) => o?.competency?.skill)
    ?.map((o) => {
      return {
        description: trim(o?.competency?.description),
        id: o?.competency?.id,
        name: trim(o?.competency?.name),
        rating: o?.rateId,
        suggested: o?.competency?.suggested,
      };
    });
  return output || [];
};

export const getUserHeaderProps = (user) => {
  return {
    avatarSrc: buildImageUrl({
      code: user?.profileImageCode,
      width: 40,
      height: 40,
    }),
    href: `/profile/${user?.id}`,
    name: `${user?.firstName} ${user?.lastName}`,
    description: user?.job,
  };
};

export const getSkillsActionType = (type, showSelectorCollaborators) => {
  if(type === REQUEST && showSelectorCollaborators) {
    return 'requestFeedbackForOthers';
  }

  if (type === REQUEST) {
    return 'requestSelfFeedback';
  }

  if (type === SEND) {
    return 'sendFeedback';
  }
};

export const getSkillUserId = ({
  selectedCollaborators,
  selectedUsers,
  sessionUserId,
  showSelectorCollaborators,
  type,
  userId,
}) => {
  if (
    type === REQUEST &&
    showSelectorCollaborators &&
    selectedCollaborators?.[0]?.id
  ) {
    return selectedCollaborators?.[0]?.id;
  }

  if (type === REQUEST && !showSelectorCollaborators) {
    return sessionUserId;
  }

  if (type === SEND && userId) {
    return userId;
  }

  if (type === SEND && selectedUsers?.length === 1) {
    return selectedUsers?.[0]?.id;
  }

  return '';
};

/**
 * Get selected competencies to submit
 * @param {Array} ratedCompetencies
 * @param {Array} selectedCompetencies
 * @param {String} type
 * @returns {Array}
 */
export const getSelectedCompetenciesToSubmit = ({
  ratedCompetencies,
  selectedCompetencies,
  type,
}) => {
  const isRequest = type === REQUEST;

  if (isRequest) {
    return [
      ...ratedCompetencies.map((c) => c?.competency),
      ...selectedCompetencies,
    ];
  }

  return ratedCompetencies.filter((c) => c?.rateId);
};

/**
 * Check if can submit competencies
 * @param {Array} ratedCompetencies
 * @param {Array} selectedCompetencies
 * @param {String} type
 * @returns {Boolean}
 */
export const canSubmitCompetencies = ({
  ratedCompetencies,
  selectedCompetencies,
  type,
}) => {
  const isSend = type === SEND;
  const onlyCompetencies = ratedCompetencies?.filter(
    (c) => !c?.competency?.skill
  );
  const onlyCompetenciesSelected = onlyCompetencies?.every((c) => c?.rateId);
  const hasSelectedCompetencies = selectedCompetencies?.length > 0;

  if (isSend) {
    return onlyCompetencies?.length > 0 && onlyCompetenciesSelected;
  }

  return hasSelectedCompetencies;
};