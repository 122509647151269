import { takeLatest, call, put } from 'redux-saga/effects';
import { GET_SKILLS } from './actionTypes';
import { getCommunitySkills } from '../api';
import { getSkillsFail, getSkillsSuccess } from './actions';

const PAGE_SIZE = 10;

function* getSkillsWorker({ payload }) {
  const { name, selectedSkills } = payload;
  const selectedSkillsId = selectedSkills.map(skill => skill.id);
  const params = { name, pageSize: PAGE_SIZE + selectedSkills.length, active: true };
  try {
    const response = yield call(getCommunitySkills, params);
    const skills = response
      .filter(skill => !selectedSkillsId.includes(skill.id))
      .slice(0, PAGE_SIZE);
    yield put(getSkillsSuccess(skills));
  } catch (error) {
    yield put(getSkillsFail(error.message));
  }
}

export default function* skillSelectorModalWatcher() {
  yield takeLatest(GET_SKILLS, getSkillsWorker);
}
