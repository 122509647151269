// @packages
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Segment from 'smu-ui-components/Segment';
import UserAvatar from 'smu-ui-components/UserAvatar';
import RootModal from 'smu-app-components/RootModal';
import { injectIntl } from 'react-intl';

// @app
import { VALIDATION_LOWEST_RATING } from '../constants/modalTypes';
import ConfirmationModal, { centerModalClass, modalStyles } from '../ConfirmationModal';
import Title from 'components/atoms/Title';

// @own
import './styles.scss';
import messages from './messages';

class ValidationLowestRatingModal extends Component {
  constructor() {
    super();
    this.handleAccept = this.handleAccept.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleAccept() {
    const {
      close,
      onAccept,
      acceptParams,
    } = this.props;
    close();
    onAccept(acceptParams);
  }

  handleCancel() {
    const { close, onCancel } = this.props;
    onCancel();
    close();
  }

  render() {
    const {
      intl: { formatMessage },
      loadingReasons,
      ratingBackgroundColor,
      ratingImageCode,
      texts: {
        accept,
        cancel,
        title,
        description,
      },
    } = this.props;

    return (
      <ConfirmationModal
        acceptMessage={accept}
        cancelMessage={cancel}
        className="validation-lowest-rating-modal"
        disableAccept={false}
        lowestRaitingModal
        onAccept={this.handleAccept}
        onCancel={this.handleCancel}
        title={title}
      >
        <Segment
          className="validation-lowest-rating-modal__content"
          loading={loadingReasons}
          loadingType="betterme"
          style={{
            backgroundColor: ratingBackgroundColor,
          }}
        >
          <div className="validation-lowest-rating-modal__icon">
            <UserAvatar
              height={52}
              noBackground
              profileImageCode={ratingImageCode}
              width={52}
            />
          </div>
          <div className="validation-lowest-rating-modal__message">
            <Title className="text-white" size={28}>{formatMessage(messages.LowestRatingMessage)}</Title>
            <small>{description}</small>
          </div>
        </Segment>
      </ConfirmationModal>
    );
  }
}

ValidationLowestRatingModal.defaultProps = {
  onCancel: () => { },
};

ValidationLowestRatingModal.propTypes = {
  close: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  loadingReasons: PropTypes.bool,
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  ratingBackgroundColor: PropTypes.string,
  ratingImageCode: PropTypes.string,
  texts: PropTypes.object,
};

export default RootModal(
  injectIntl(ValidationLowestRatingModal),
  VALIDATION_LOWEST_RATING,
  modalStyles,
  centerModalClass,
);
