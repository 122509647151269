export const APPROVAL_GOAL = 'APPROVAL_GOAL';
export const APPROVAL_GOAL_FAIL = 'APPROVAL_GOAL_FAIL';
export const APPROVAL_GOAL_SUCCESS = 'APPROVAL_GOAL_SUCCESS';
export const CLOSE_GOAL = 'CLOSE_GOAL';
export const CLOSE_GOAL_FAIL = 'CLOSE_GOAL_FAIL';
export const CLOSE_GOAL_SUCCESS = 'CLOSE_GOAL_SUCCESS';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const CREATE_GOAL = 'CREATE_GOAL';
export const CREATE_GOAL_ASSESSMENT = 'CREATE_GOAL_ASSESSMENT';
export const CREATE_GOAL_ASSESSMENT_FAILED = 'CREATE_GOAL_ASSESSMENT_FAILED';
export const CREATE_GOAL_ASSESSMENT_SUCCESS = 'CREATE_GOAL_ASSESSMENT_SUCCESS';
export const CREATE_GOAL_FAILED = 'CREATE_GOAL_FAILED';
export const CREATE_GOAL_SUCCESS = 'CREATE_GOAL_SUCCESS';
export const DELETE_GOAL = 'DELETE_GOAL';
export const DELETE_GOAL_FAIL = 'DELETE_GOAL_FAIL';
export const DELETE_GOAL_SUCCESS = 'DELETE_GOAL_SUCCESS';
export const DISAPPROVAL_GOAL = 'DISAPPROVAL_GOAL';
export const DISAPPROVAL_GOAL_FAIL = 'DISAPPROVAL_GOAL_FAIL';
export const DISAPPROVAL_GOAL_SUCCESS = 'DISAPPROVAL_GOAL_SUCCESS';
export const GET_ASSIGNED_GOALS = 'GET_ASSIGNED_GOALS';
export const GET_ASSIGNED_GOALS_FAILED = 'GET_ASSIGNED_GOALS_FAILED';
export const GET_ASSIGNED_GOALS_SUCCESS = 'GET_ASSIGNED_GOALS_SUCCESS';
export const GET_ASSIGNED_GOAL_DETAIL = 'GET_ASSIGNED_GOAL_DETAIL';
export const GET_ASSIGNED_GOAL_DETAIL_FAILED = 'GET_ASSIGNED_GOAL_DETAIL_FAILED';
export const GET_ASSIGNED_GOAL_DETAIL_SUCCESS = 'GET_ASSIGNED_GOAL_DETAIL_SUCCESS';
export const GET_ORGANIZATIONAL_OBJECTIVES = 'GET_ORGANIZATIONAL_OBJECTIVES';
export const GET_ORGANIZATIONAL_OBJECTIVES_FAIL = 'GET_ORGANIZATIONAL_OBJECTIVES_FAIL';
export const GET_ORGANIZATIONAL_OBJECTIVES_SUCCESS = 'GET_ORGANIZATIONAL_OBJECTIVES_SUCCESS';
export const GET_PERIODS = 'GET_PERIODS';
export const GET_PERIODS_FAILED = 'GET_PERIODS_FAILED';
export const GET_PERIODS_SUCCESS = 'GET_PERIODS_SUCCESS';
export const GET_SUMMARY_GOALS = 'GET_SUMMARY_GOALS';
export const GET_SUMMARY_GOALS_FAILED = 'GET_SUMMARY_GOALS_FAILED';
export const GET_SUMMARY_GOALS_SUCCESS = 'GET_SUMMARY_GOALS_SUCCESS';
export const GET_TEAM_GOALS = 'GET_TEAM_GOALS';
export const GET_TEAM_GOALS_FAILED = 'GET_TEAM_GOALS_FAILED';
export const GET_TEAM_GOALS_SUCCESS = 'GET_TEAM_GOALS_SUCCESS';
export const GET_TEAM_GOAL_DETAIL = 'GET_TEAM_GOAL_DETAIL';
export const GET_TEAM_GOAL_DETAIL_FAILED = 'GET_TEAM_GOAL_DETAIL_FAILED';
export const GET_TEAM_GOAL_DETAIL_SUCCESS = 'GET_TEAM_GOAL_DETAIL_SUCCESS';
export const GET_USER_TOTAL_WEIGHT = 'GET_USER_TOTAL_WEIGHT';
export const GET_USER_TOTAL_WEIGHT_FAIL = 'GET_USER_TOTAL_WEIGHT_FAIL';
export const GET_USER_TOTAL_WEIGHT_SUCCESS = 'GET_USER_TOTAL_WEIGHT_SUCCESS';
export const GOAL_WEIGHT_VALIDATION_CONTINUE = 'GOAL_WEIGHT_VALIDATION_CONTINUE';
export const GOAL_WEIGHT_VALIDATION_ERROR = 'GOAL_WEIGHT_VALIDATION_ERROR';
export const REMOVE_USER_TOTAL_WEIGHT = 'REMOVE_USER_TOTAL_WEIGHT';
export const RESET_ASSIGNED_GOAL = 'RESET_ASSIGNED_GOAL';
export const RESET_ASSIGNED_GOAL_DETAIL = 'RESET_ASSIGNED_GOAL_DETAIL';
export const RESET_SUMMARY_GOALS = 'RESET_SUMMARY_GOALS';
export const RESET_TEAM_GOAL = 'RESET_TEAM_GOAL';
export const RESET_TEAM_GOAL_DETAIL = 'RESET_TEAM_GOAL_DETAIL';
export const SET_MY_GOAL_FILTERS = 'SET_MY_GOAL_FILTERS';
export const SET_TEAM_GOAL_FILTERS = 'SET_TEAM_GOAL_FILTERS';
export const TOGGLE_EDIT_PROGRESS = 'TOGGLE_EDIT_PROGRESS';
export const UPDATE_APPROVAL_GOAL = 'UPDATE_APPROVAL_GOAL';
export const UPDATE_APPROVAL_GOAL_FAIL = 'UPDATE_APPROVAL_GOAL_FAIL';
export const UPDATE_APPROVAL_GOAL_SUCCESS = 'UPDATE_APPROVAL_GOAL_SUCCESS';
export const UPDATE_GOAL = 'UPDATE_GOAL';
export const UPDATE_GOAL_FAILED = 'UPDATE_GOAL_FAILED';
export const UPDATE_GOAL_SUCCESS = 'UPDATE_GOAL_SUCCESS';
