//TODO review with the assessment list on Goal Details to consider having one component
// @packages
import { get } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// @app
import DownloadFile from 'betterme-components/components/containers/DownloadFile';

// @own
import './styles.scss';

function getScaleValueName(value, scales) {
  const valueScale = scales.find(scale => scale.min <= value && value <= scale.max);

  return get(valueScale, 'name', '');
}

function getAssessments(assessments, scales) {
  return assessments.map(assessment => ({
    ...assessment,
    message: getScaleValueName(assessment.value, scales),
  }));
}

const GoalAssessmentList = ({ assessments, isPercentage, scales }) => {
  const assessmentsWithMessage = getAssessments(assessments, scales);

  return (
    <ul className="goal-assessments-list">
      {assessmentsWithMessage.map((assessment, index) => {
        const assessmentMessage = assessment.message ? ` - ${assessment.message}` : '';
        const progress = isPercentage
          ? `${assessment.value}%`
          : `${assessment.value}${assessmentMessage}`;

        return (
          <li className="goal-assessments-list__item" key={index}>
            <div className="goal-assessments-list__info">
              <span className="goal-assessments-list__info-progress">
                {progress}
              </span>
              <span className="goal-assessments-list__info-date">
                {moment(assessment.creationTime).format('MMM D')}
              </span>
            </div>
            <div className="goal-assessments-list__comment">
              <pre className="goal-assessments-list__comment-text">{assessment.comment}</pre>
            </div>
            <div className="goal-assessments-list__download-file-wrapper">
              <DownloadFile
                attachments={assessment.attachments}
                className="goal-assessments-list__download-file"
              />
            </div>
          </li>
        );
      })}
    </ul>
  );
};

GoalAssessmentList.propTypes = {
  assessments: PropTypes.array.isRequired,
};

export default GoalAssessmentList;
