// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'smu-ui-components/IconV2';

// @app
import ActionButtons from 'components/molecules/ActionButtons';
import FooterCard from 'components/molecules/FooterCard';
import HeaderCard from 'components/molecules/HeaderCard';
import LeadersCard from 'components/molecules/LeadersCard';
import LeadersCardDisabled from 'components/molecules/LeadersCardDisabled';
import Title from 'components/atoms/Title';
import { SIZE_FIFTEEN, SIZE_THIRTEEN } from 'components/common/constants';

// @own
import './styles.scss';

const ChangeLeaderCard = ({
  actionButtons,
  approverCard: { approverHighlight, approverTitle },
  buttonsEnabled,
  className,
  commentTitle,
  emptyState: {
    emptyStateText,
    emptyStateTitle,
    emptyStateImage,
  },
  footer: { sentBy, sentByName },
  leaderEnabled,
  request: {
    approver,
    collaborator,
    comment,
    requester,
  },
  requesterCard: { requesterHighlight, requesterTitle },
  sentDate,
  title,
}) => (
  <div className={cn('change-leader-card', className)}>
    <HeaderCard
      className="change-leader-card__header"
      date={sentDate}
      userAvatarProps={collaborator}
    />
    <Title
      className="change-leader-card__title"
      indigo
      size={SIZE_FIFTEEN}
    >
      {title}
    </Title>
    <div className="change-leader-card__leaders">
      {leaderEnabled ? (
        <LeadersCard
          className="change-leader-card__leaders-card"
          highlightedLeader={requesterHighlight}
          leadersTitle={requesterTitle}
          userAvatarProps={requester}
        />
      ) : (
        <LeadersCardDisabled
          className="change-leader-card__leaders-card"
          emptyStateImage={emptyStateImage}
          emptyStateText={emptyStateText}
          emptyStateTitle={emptyStateTitle}
        />
      )}
      <Icon
        className="change-leader-card__leaders-icon"
        icon="substitution"
        size={20}
      />
      <LeadersCard
        className="change-leader-card__leaders-card"
        highlightedLeader={approverHighlight}
        leadersTitle={approverTitle}
        userAvatarProps={approver}
      />
    </div>
    {comment && (
      <>
        <Title
          black
          className="change-leader-card__comment-title"
          size={SIZE_THIRTEEN}
        >
          {commentTitle}
        </Title>
        <div className="change-leader-card__comment">
          {comment}
        </div>
      </>
    )}
    <FooterCard
      className="change-leader-card__footer"
      sentBy={sentBy}
      userAvatar={requester}
      userName={sentByName}
    />
    {buttonsEnabled && (
      <ActionButtons
        buttons={actionButtons}
        className="change-leader-card__buttons"
      />
    )}
  </div>
);

ChangeLeaderCard.defaultProps = {
  actionButtons: [],
  approverHighlight: false,
  requesterHighlight: false,
};

ChangeLeaderCard.propTypes = {
  actionButtons: PropTypes.array.isRequired,
  approverCard: PropTypes.arrayOf(PropTypes.shape(
    {
      approverHighlight: PropTypes.bool,
      approverTitle: PropTypes.string,
    }
  )),
  buttonsEnabled: PropTypes.bool,
  className: PropTypes.string,
  commentTitle: PropTypes.string,
  emptyState: PropTypes.arrayOf(PropTypes.shape(
    {
      emptyStateText: PropTypes.string,
      emptyStateTitle: PropTypes.string,
      emptyStateImage: PropTypes.string,
    }
  )),
  footer: PropTypes.arrayOf(PropTypes.shape(
    {
      sentBy: PropTypes.string,
      sentByName: PropTypes.string,
    }
  )),
  leaderEnabled: PropTypes.bool,
  requesterCard: PropTypes.arrayOf(PropTypes.shape(
    {
      requesterHighlight: PropTypes.bool,
      requesterTitle: PropTypes.string,
    }
  )),
  request: PropTypes.arrayOf(PropTypes.shape(
    {
      approver: PropTypes.object.isRequired,
      collaborator: PropTypes.object.isRequired,
      comment: PropTypes.string,
      requester: PropTypes.object.isRequired,
      sentDate: PropTypes.string,
    }
  )),
  title: PropTypes.string,
};

export default ChangeLeaderCard;
