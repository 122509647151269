import {
  ADD_COMPETENCY_CREATE_EVALUATION,
  CAN_CREATE_BEHAVIOUR_RETROSPECTIVE,
  CLEAN_TEAM_EVALUATION_STATE,
  CREATE_DRAFT_EVALUATION,
  CREATE_DRAFT_EVALUATION_FAIL,
  CREATE_DRAFT_EVALUATION_SUCCESS,
  CREATE_EVALUATION,
  CREATE_EVALUATION_FAIL,
  CREATE_EVALUATION_SUCCESS,
  DELETE_DRAFT_EVALUATION,
  DELETE_DRAFT_EVALUATION_FAIL,
  DELETE_DRAFT_EVALUATION_SUCCESS,
  DELETE_EVALUATION_FILE,
  DELETE_EVALUATION_FILE_FAIL,
  DELETE_EVALUATION_FILE_SUCCESS,
  EVALUATION_ADD_DELETED_SKILL,
  EVALUATION_ADD_SKILL,
  EVALUATION_ADD_SKILLS,
  EVALUATION_CHANGE_SKILL,
  EVALUATION_CLEAN_DELETED_SKILLS,
  EVALUATION_CLEAN_SKILLS,
  EVALUATION_DELETED_SKILL,
  GET_CLOSED_ASSIGNED_GOALS,
  GET_CLOSED_ASSIGNED_GOALS_FAIL,
  GET_CLOSED_ASSIGNED_GOALS_SUCCESS,
  GET_CUSTOM_SCORES_SUCCESS,
  GET_GOALS_FINAL_SCORE,
  GET_GOALS_FINAL_SCORE_FAIL,
  GET_GOALS_FINAL_SCORE_SUCCESS,
  GET_LAST_EVALUATION,
  GET_LAST_EVALUATION_FAIL,
  GET_LAST_EVALUATION_SUCCESS,
  GET_LAST_SELF_EVALUATION,
  GET_LAST_SELF_EVALUATION_FAIL,
  GET_LAST_SELF_EVALUATION_SUCCESS,
  GET_SUGGESTED_ACTIONS,
  GET_SUGGESTED_ACTIONS_FAIL,
  GET_SUGGESTED_ACTIONS_SUCCESS,
  GET_TEAM_ALL_EVALUATIONS,
  GET_TEAM_ALL_EVALUATIONS_FAIL,
  GET_TEAM_ALL_EVALUATIONS_SUCCESS,
  REMOVE_COMPETENCY_CREATE_EVALUATION,
  REQUEST_EVALUATIONS,
  REQUEST_EVALUATIONS_FAIL,
  REQUEST_EVALUATIONS_SUCCESS,
  REQUEST_TEAM_EVALUATIONS,
  REQUEST_TEAM_EVALUATIONS_FAIL,
  REQUEST_TEAM_EVALUATIONS_SUCCESS,
  RESET_DATA_CREATE_EVALUATION,
  RESET_EVALUATION_RECENTLY_CREATED,
  RESET_LAST_EVALUATION,
  RESET_LAST_SELF_EVALUATION,
  SET_DATA_CREATE_EVALUATION,
  SET_EVALUATION_RECENTLY_CREATED,
  SUBMIT_DRAFT_EVALUATION,
  SUBMIT_DRAFT_EVALUATION_FAIL,
  SUBMIT_DRAFT_EVALUATION_SUCCESS,
  UPDATE_DRAFT_EVALUATION,
  UPDATE_DRAFT_EVALUATION_FAIL,
  UPDATE_DRAFT_EVALUATION_SUCCESS,
  UPLOAD_EVALUATION_FILE,
  UPLOAD_EVALUATION_FILE_FAIL,
  UPLOAD_EVALUATION_FILE_SUCCESS,
} from './actionTypes';

const initialState = {
  canCreateBehaviourRetrospective: false,
  closedGoals: null,
  customScores: [],
  dataCreateEvaluation: {
    attachments: [],
    coEvaluatorId: undefined,
    comment: '',
    evaluee: {},
    promotion: null,
    ratedTopics: [],
    score: 0,
    suggestedActionsComment: undefined,
    suggestedActionsIds: [],
    summary: '',
    title: '',
    type: '',
  },
  deletedSkills: [],
  error: null,
  evaluationFilesError: null,
  evaluations: [],
  evaluationsDrafts: [],
  evaluationRecentlyCreated: null,
  goalsFinalScore: {},
  lastEvaluation: null,
  lastSelfEvaluation: [],
  loadingClosedGoals: false,
  loadingCreate: false,
  loadingCreateDraft: false,
  loadingDeleteDraft: false,
  loadingEvaluationFiles: false,
  loadingEvaluationsDrafts: false,
  loadingFinalScore: false,
  loadingLastEvaluation: false,
  loadingLastSelfEvaluation: false,
  loadingSubmitDraft: false,
  loadingSuggestedActions: false,
  loadingUpdateDraft: false,
  skills: [],
  suggestedActions: [],
  teamEvaluations: [],
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case CLEAN_TEAM_EVALUATION_STATE:
      return { ...state, teamEvaluations: [] };
    case CREATE_DRAFT_EVALUATION:
      return { ...state, loadingCreateDraft: true, error: null };
    case CREATE_DRAFT_EVALUATION_FAIL:
      return { ...state, loadingCreateDraft: false, error: payload.error };
    case CREATE_DRAFT_EVALUATION_SUCCESS:
      return { ...state, loadingCreateDraft: false, error: null };
    case CREATE_EVALUATION:
      return { ...state, loadingCreate: true, error: null };
    case CREATE_EVALUATION_FAIL:
      return { ...state, loadingCreate: false, error: payload.error };
    case CREATE_EVALUATION_SUCCESS:
      return { ...state, loadingCreate: false, error: null };
    case DELETE_DRAFT_EVALUATION:
      return { ...state, loadingDeleteDraft: true, error: null };
    case DELETE_DRAFT_EVALUATION_FAIL:
      return { ...state, loadingDeleteDraft: false, error: payload.error };
    case DELETE_DRAFT_EVALUATION_SUCCESS:
      return { ...state, loadingDeleteDraft: false, error: null };
    case EVALUATION_ADD_SKILL:
      return {
        ...state,
        skills: [...state.skills, payload.skill],
        dataCreateEvaluation: {
          ...state.dataCreateEvaluation,
          ratedTopics: [
            ...state.dataCreateEvaluation.ratedTopics,
            {
              competency: payload.skill,
              rateId: null,
            }
          ],
        },
      };
    case EVALUATION_ADD_SKILLS:
      return { ...state, skills: payload.skills };
    case EVALUATION_CHANGE_SKILL:
      return {
        ...state,
        dataCreateEvaluation: {
          ...state.dataCreateEvaluation,
          ratedTopics: state.dataCreateEvaluation.ratedTopics.map((topic) => {
            if (topic?.competency?.id === payload.skillId) {
              return { ...topic, rateId: payload.rateId };
            }
            return topic;
          }),
        },
      }
    case EVALUATION_ADD_DELETED_SKILL:
      return {
        ...state,
        deletedSkills: [...state.deletedSkills, payload.skill],
      };
    case EVALUATION_CLEAN_DELETED_SKILLS:
      return { ...state, deletedSkills: [] };
    case EVALUATION_CLEAN_SKILLS:
      return { ...state, skills: [] };
    case EVALUATION_DELETED_SKILL:
      return {
        ...state,
        skills: state.skills.filter((skill) => skill.id !== payload.skillId),
        dataCreateEvaluation: {
          ...state.dataCreateEvaluation,
          ratedTopics: state.dataCreateEvaluation.ratedTopics.filter(
            (topic) => topic?.competency?.id !== payload.skillId
          ),
        },
      };
    case GET_CUSTOM_SCORES_SUCCESS:
      return { ...state, customScores: payload.response, error: null };
    case GET_CLOSED_ASSIGNED_GOALS:
      return { ...state, loadingClosedGoals: true };
    case GET_CLOSED_ASSIGNED_GOALS_FAIL:
      return {
        ...state,
        loadingClosedGoals: false,
        closedGoals: null,
        error: payload.error,
      };
    case GET_CLOSED_ASSIGNED_GOALS_SUCCESS:
      return {
        ...state,
        loadingClosedGoals: false,
        closedGoals: payload.response,
      };
    case GET_GOALS_FINAL_SCORE:
      return { ...state, loadingFinalScore: true };
    case GET_GOALS_FINAL_SCORE_FAIL:
      return { ...state, loadingFinalScore: false };
    case GET_GOALS_FINAL_SCORE_SUCCESS:
      return {
        ...state,
        loadingFinalScore: false,
        goalsFinalScore: payload.response,
      };
    case REQUEST_EVALUATIONS:
      return { ...state, loading: true };
    case REQUEST_EVALUATIONS_FAIL:
      return { ...state, loading: false };
    case REQUEST_EVALUATIONS_SUCCESS:
      return { ...state, loading: false, evaluations: payload.response };
    case REQUEST_TEAM_EVALUATIONS:
    case GET_TEAM_ALL_EVALUATIONS:
      return { ...state, modalLoading: true };
    case REQUEST_TEAM_EVALUATIONS_FAIL:
    case GET_TEAM_ALL_EVALUATIONS_FAIL:
      return { ...state, modalLoading: false };
    case REQUEST_TEAM_EVALUATIONS_SUCCESS:
    case GET_TEAM_ALL_EVALUATIONS_SUCCESS:
      return {
        ...state,
        modalLoading: false,
        teamEvaluations: payload.response,
      };
    case SET_DATA_CREATE_EVALUATION:
      return {
        ...state,
        dataCreateEvaluation: { ...state.dataCreateEvaluation, ...payload },
      };
    case ADD_COMPETENCY_CREATE_EVALUATION:
      return {
        ...state,
        dataCreateEvaluation: { ...state.dataCreateEvaluation, ...payload },
      };
    case REMOVE_COMPETENCY_CREATE_EVALUATION:
      return {
        ...state,
        dataCreateEvaluation: { ...state.dataCreateEvaluation, ...payload },
      };
    case RESET_DATA_CREATE_EVALUATION:
      return {
        ...state,
        dataCreateEvaluation: {
          attachments: [],
          coEvaluatorId: undefined,
          comment: '',
          evaluee: state.dataCreateEvaluation.evaluee,
          focusedInput: null,
          promotion: {},
          ratedTopics: [],
          score: 0,
          suggestedActionsComment: undefined,
          suggestedActionsIds: [],
          summary: '',
          title: '',
          type: '',
        },
        goalsFinalScore: {},
      };
    case GET_LAST_EVALUATION:
      return { ...state, loadingLastEvaluation: true };
    case GET_LAST_EVALUATION_FAIL:
      return { ...state, loadingLastEvaluation: false };
    case GET_LAST_EVALUATION_SUCCESS: {
      return {
        ...state,
        loadingLastEvaluation: false,
        lastEvaluation: payload.response,
      };
    }
    case GET_LAST_SELF_EVALUATION:
      return { ...state, loadingLastSelfEvaluation: true };
    case GET_LAST_SELF_EVALUATION_FAIL:
      return { ...state, loadingLastSelfEvaluation: false };
    case GET_LAST_SELF_EVALUATION_SUCCESS: {
      const lastSelfEvaluation =
        payload.response.length > 0 ? payload.response : [];
      return { ...state, loadingLastSelfEvaluation: false, lastSelfEvaluation };
    }
    case GET_SUGGESTED_ACTIONS:
      return { ...state, loadingSuggestedActions: true };
    case GET_SUGGESTED_ACTIONS_FAIL:
      return { ...state, loadingSuggestedActions: false };
    case GET_SUGGESTED_ACTIONS_SUCCESS:
      return {
        ...state,
        loadingSuggestedActions: false,
        suggestedActions: payload.response,
      };
    case RESET_LAST_EVALUATION:
      return { ...state, lastEvaluation: null };
    case RESET_LAST_SELF_EVALUATION:
      return { ...state, lastSelfEvaluation: {} };
    case SUBMIT_DRAFT_EVALUATION:
      return { ...state, loadingSubmitDraft: true, error: null };
    case SUBMIT_DRAFT_EVALUATION_FAIL:
      return { ...state, loadingSubmitDraft: false, error: payload.error };
    case SUBMIT_DRAFT_EVALUATION_SUCCESS:
      return { ...state, loadingSubmitDraft: false, error: null };
    case UPDATE_DRAFT_EVALUATION:
      return { ...state, loadingUpdateDraft: true, error: null };
    case UPDATE_DRAFT_EVALUATION_FAIL:
      return { ...state, loadingUpdateDraft: false, error: payload.error };
    case UPDATE_DRAFT_EVALUATION_SUCCESS:
      return { ...state, loadingUpdateDraft: false, error: null };
    case UPLOAD_EVALUATION_FILE:
    case DELETE_EVALUATION_FILE:
      return { ...state, loadingEvaluationFiles: true };
    case UPLOAD_EVALUATION_FILE_FAIL:
    case DELETE_EVALUATION_FILE_FAIL:
      return {
        ...state,
        loadingEvaluationFiles: false,
        evaluationFilesError: payload.error,
      };
    case UPLOAD_EVALUATION_FILE_SUCCESS:
      return {
        ...state,
        dataCreateEvaluation: {
          ...state.dataCreateEvaluation,
          attachments: [
            ...state.dataCreateEvaluation.attachments,
            payload.response,
          ],
        },
        loadingEvaluationFiles: false,
      };
    case DELETE_EVALUATION_FILE_SUCCESS:
      const {
        dataCreateEvaluation: { attachments },
      } = state;
      return {
        ...state,
        loadingEvaluationFiles: false,
        dataCreateEvaluation: {
          ...state.dataCreateEvaluation,
          attachments: attachments.filter((file) => file.id !== payload.id),
        },
      };
    case RESET_EVALUATION_RECENTLY_CREATED:
      return { ...state, evaluationRecentlyCreated: null };
    case SET_EVALUATION_RECENTLY_CREATED:
      return { ...state, evaluationRecentlyCreated: payload.evaluee };
    case CAN_CREATE_BEHAVIOUR_RETROSPECTIVE:
      return {
        ...state,
        canCreateBehaviourRetrospective: payload,
      };
    default:
      return state;
  }
}
