import {
  GET_FEEDBACK_FOR_LEADERS_RECEIVED,
  GET_FEEDBACK_FOR_LEADERS_RECEIVED_FAIL,
  GET_FEEDBACK_FOR_LEADERS_RECEIVED_SUCCESS,
  GET_FEEDBACK_FOR_LEADERS_REPORT,
  GET_FEEDBACK_FOR_LEADERS_REPORT_FAIL,
  GET_FEEDBACK_FOR_LEADERS_REPORT_SUCCESS,
} from './actionTypes';

const initialState = {
  errorFeedbackForLeadersReceived: '',
  errorFeedbackForLeadersReport: '',
  feedbackForLeadersReceived: {},
  feedbackForLeadersReport: '',
  loadingFeedbackForLeadersReceived: false,
  loadingFeedbackForLeadersReport: false,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_FEEDBACK_FOR_LEADERS_RECEIVED:
      return { ...state, loadingFeedbackForLeadersReceived: true };

    case GET_FEEDBACK_FOR_LEADERS_RECEIVED_FAIL:
      return {
        ...state,
        errorFeedbackForLeadersReceived: payload.error,
        loadingFeedbackForLeadersReceived: false,
      };

    case GET_FEEDBACK_FOR_LEADERS_RECEIVED_SUCCESS:
      return {
        ...state,
        errorFeedbackForLeadersReceived: '',
        loadingFeedbackForLeadersReceived: false,
        feedbackForLeadersReceived: payload.response,
      };

    case GET_FEEDBACK_FOR_LEADERS_REPORT:
      return { ...state, loadingFeedbackForLeadersReport: true };

    case GET_FEEDBACK_FOR_LEADERS_REPORT_FAIL:
      return {
        ...state,
        loadingFeedbackForLeadersReport: false,
        errorFeedbackForLeadersReport: payload.error,
      };

    case GET_FEEDBACK_FOR_LEADERS_REPORT_SUCCESS:
      return {
        ...state,
        loadingFeedbackForLeadersReport: false,
        feedbackForLeadersReport: payload.response,
      };

    default:
      return state;
  }
}
