// @packages
import ModalCulturalLevels from 'smu-app-components/CulturalLevels/Explanation/Modal';
import ModalNewLevel from 'smu-app-components/CulturalLevels/NewLevel/Modal';

// @app
import ModalOneOnOneFormContainer from 'betterme-components/Modals/OneOnOneForm';
import ModalOneOnOneRequestContainer from 'betterme-components/Modals/OneOnOneRequest';

// @own
import culturalLevels from './modals/culturalLevels';
import newLevel from './modals/newLevel';
import oneOnOneCreate from './modals/oneOnOneCreate';
import oneOnOneRequest from './modals/oneOnOneRequest';

export const MODAL_CULTURAL_LEVELS = 'cultural-levels';
export const MODAL_NEW_LEVEL = 'new-level';
export const MODAL_ONE_ON_ONE_CREATE = 'create-1on1';
export const MODAL_ONE_ON_ONE_REQUEST = 'request-1on1';

export const MODAL_HANDLERS = {
  [MODAL_CULTURAL_LEVELS]: culturalLevels,
  [MODAL_NEW_LEVEL]: newLevel,
  [MODAL_ONE_ON_ONE_CREATE]: oneOnOneCreate,
  [MODAL_ONE_ON_ONE_REQUEST]: oneOnOneRequest,
};

export const MODAL_COMPONENTS = {
  [MODAL_CULTURAL_LEVELS]: ModalCulturalLevels,
  [MODAL_NEW_LEVEL]: ModalNewLevel,
  [MODAL_ONE_ON_ONE_CREATE]: ModalOneOnOneFormContainer,
  [MODAL_ONE_ON_ONE_REQUEST]: ModalOneOnOneRequestContainer,
};
