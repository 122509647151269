import { defineMessages } from 'react-intl';

export default defineMessages({
  FeedbackProfileEmptyStateTitle: {
    id: 'Betterme.Profile.EmptyStateTitle',
    defaultMessage: 'Hey, it\’s great to see you here!',
  },
  FeedbackProfileEmptyStateDescription: {
    id: 'Betterme.Profile.EmptyStateDescription',
    defaultMessage: 'What a great time to ask for or send Feedback to {username}. The Feedback you exchange will show up in your feed.',
  },
  FeedbackProfileFeedbackReceivedMain: {
    id: 'Betterme.Profile.FeedbackReceivedMain',
    defaultMessage: '{username} hasn\'t received any Feedback for this period yet',
  },
  FeedbackProfileGoalsAssignedMain: {
    id: 'Betterme.Profile.GoalsAssignedMain',
    defaultMessage: '{username} hasn\'t been assigned Goals for this period yet',
  },
  FeedbackProfileLastEvaluationMain: {
    id: 'Betterme.Profile.LastEvaluationMain',
    defaultMessage: '{username} hasn\'t received any Evaluations for this period yet',
  },
  FeedbackProfileAssignedGoalsClosedSuggestion: {
    id: 'Betterme.Profile.AssignedGoals.ClosedSuggestion',
    defaultMessage: 'Review the Goals in progress on BetterMe',
  },
  FeedbackProfileEngagementEngaged: {
    id: 'Betterme.Profile.EngagementEngaged',
    defaultMessage: '{username} shows High Cultural Engagement. Click to learn more.',
  },
  FeedbackProfileEngagementEngagedTitle: {
    id: 'Betterme.Profile.EngagementEngagedTitle',
    defaultMessage: 'High Cultural Engagement',
  },
  FeedbackProfileEngagementEngagedDescription: {
    id: 'Betterme.Profile.EngagementEngagedDescription',
    defaultMessage: "High Cultural Engagement- According to the organization's interactions on StarMeup, {username} is actively connecting with other collaborators. Great!",
  },
  FeedbackProfileEngagementNoEngaged: {
    id: 'Betterme.Profile.EngagementNoEngaged',
    defaultMessage: 'Coffee Time! Click to learn more.',
  },
  FeedbackProfileEngagementNoEngagedTitle: {
    id: 'Betterme.Profile.EngagementNoEngagedTitle',
    defaultMessage: 'COFFEE TIME!',
  },
  FeedbackProfileEngagementNoEngagedDescription: {
    id: 'Betterme.Profile.EngagementNoEngagedDescription',
    defaultMessage: "We suggest you have a significant conversation related to Glober's interest, feelings, career expectations, development and motivations."
  },
  FeedbackProfileEngagementNoData: {
    id: 'Betterme.Profile.EngagementNoData',
    defaultMessage: 'We don\'t have enough data yet. Click to learn more.',
  },
  FeedbackProfileEngagementNoDataTitle: {
    id: 'Betterme.Profile.EngagementNoDataTitle',
    defaultMessage: 'Not Enough Data',
  },
  FeedbackProfileEngagementNoDataDescription: {
    id: 'Betterme.Profile.EngagementNoDataDescription',
    defaultMessage: "Not enough data - Currently there haven't been enough interactions carried out by {username} on StarMeUp in order to indicate how actively {username} is connecting with other collaborators.",
  },
  FeedbackProfileEngagementNoService: {
    id: 'Betterme.Profile.EngagementNoService',
    defaultMessage: 'Service is not available at the moment.',
  },
  FeedbackProfileEngagementNoServiceTitle: {
    id: 'Betterme.Profile.EngagementNoServiceTitle',
    defaultMessage: 'No Service',
  },
  FeedbackProfileEngagementNoServiceDescription: {
    id: 'Betterme.Profile.EngagementNoServiceDescription',
    defaultMessage: 'At the moment, the connectivity indicator is out of service. Please try again later.',
  },
  FeedbackProfileEngagementFeatureDiscovery: {
    id: 'Betterme.Profile.EngagementFeatureDiscovery',
    defaultMessage: 'Here you\'ll find your collaborator\'s current connectivity Indicator and some suggestions.',
  },
  FeedbackProfileEngagementFollowUpTitle: {
    defaultMessage: 'LEADER INTERVENTION',
    id: 'Betterme.Profile.EngagementFollowUpTitle',
  },
  FeedbackProfileEngagementFollowUpSuccessTitle: {
    defaultMessage: 'There is some information already registered by a leader.',
    id: 'Betterme.Profile.EngagementFollowUpSuccessTitle',
  },
  FeedbackProfileEngagementFollowQuestion: {
    defaultMessage: 'Do you have updates? ',
    id: 'Betterme.Profile.EngagementFollowUpQuestion',
  },
  FeedbackProfileEngagementFollowQuestionLink: {
    defaultMessage: 'Submit a new intervention',
    id: 'Betterme.Profile.EngagementFollowUpQuestionLink',
  },
  FeedbackProfileEngagementMemberListTooltip: {
    defaultMessage: 'Coffee Time! In the user profile click on the coffee cup to learn more.',
    id: 'Betterme.Profile.EngagementMemberListTooltip',
  },
});
