import {
  GET_DASHBOARD_FEEDBACK_FAIL,
  GET_DASHBOARD_FEEDBACK_FOR_LEADERS_RESULTS_FAIL,
  GET_DASHBOARD_FEEDBACK_FOR_LEADERS_RESULTS_SUCCESS,
  GET_DASHBOARD_FEEDBACK_FOR_LEADERS_RESULTS,
  GET_DASHBOARD_FEEDBACK_SUCCESS,
  GET_DASHBOARD_FEEDBACK_SUMMARY_FAIL,
  GET_DASHBOARD_FEEDBACK_SUMMARY_SUCCESS,
  GET_DASHBOARD_FEEDBACK_SUMMARY,
  GET_DASHBOARD_FEEDBACK,
  GET_DASHBOARD_GOALS_INFO_FAIL,
  GET_DASHBOARD_GOALS_INFO_SUCCESS,
  GET_DASHBOARD_GOALS_INFO,
  GET_DASHBOARD_TOPICS_OPPORTUNITIES_FAIL,
  GET_DASHBOARD_TOPICS_OPPORTUNITIES_SUCCESS,
  GET_DASHBOARD_TOPICS_OPPORTUNITIES,
  GET_DASHBOARD_TOPICS_STRENGTHS_FAIL,
  GET_DASHBOARD_TOPICS_STRENGTHS_SUCCESS,
  GET_DASHBOARD_TOPICS_STRENGTHS,
  GET_DASHBOARD_PERFORMANCE_CHART,
  GET_DASHBOARD_PERFORMANCE_CHART_FAIL,
  GET_DASHBOARD_PERFORMANCE_CHART_SUCCESS,
  REQUEST_FEEDBACK_SELECT_COMPETENCY,
  REQUEST_FEEDBACK_SELECT_USER,
  REQUEST_FEEDBACK_USERS_FAIL,
  REQUEST_FEEDBACK_USERS_SUCCESS,
  REQUEST_FEEDBACK_USERS,
  REQUEST_RATED_TOPICS_FAIL,
  REQUEST_RATED_TOPICS_SUCCESS,
  REQUEST_RATED_TOPICS,
  RESET_DASHBOARD_DATA,
  RESET_GET_DASHBOARD_FEEDBACK,
  RESET_RATED_TOPICS,
  RESET_REQUEST_FEEDBACK_DATA,
  RESET_REQUEST_FEEDBACK_USERS,
  SELECT_COMPETENCY_DETAILS,
} from './actionTypes';

export function getDashboardGoalsInfo(userId, from, to) {
  return {
    type: GET_DASHBOARD_GOALS_INFO,
    payload: { userId, from, to },
  };
}

export function getDashboardGoalsInfoFail(error) {
  return {
    type: GET_DASHBOARD_GOALS_INFO_FAIL,
    payload: { error },
  };
}

export function getDashboardGoalsInfoSuccess(response) {
  return {
    type: GET_DASHBOARD_GOALS_INFO_SUCCESS,
    payload: { response },
  };
}

export function getDashboardTopicsOpportunities(userId, params) {
  return {
    type: GET_DASHBOARD_TOPICS_OPPORTUNITIES,
    payload: { userId, ...params },
  };
}

export function getDashboardTopicsOpportunitiesFail(error) {
  return {
    type: GET_DASHBOARD_TOPICS_OPPORTUNITIES_FAIL,
    payload: { error },
  };
}

export function getDashboardTopicsOpportunitiesSuccess(response) {
  return {
    type: GET_DASHBOARD_TOPICS_OPPORTUNITIES_SUCCESS,
    payload: { response },
  };
}

export function getDashboardTopicsStrengths(userId, params) {
  return {
    type: GET_DASHBOARD_TOPICS_STRENGTHS,
    payload: { userId, ...params },
  };
}

export function getDashboardTopicsStrengthsFail(error) {
  return {
    type: GET_DASHBOARD_TOPICS_STRENGTHS_FAIL,
    payload: { error },
  };
}

export function getDashboardTopicsStrengthsSuccess(response) {
  return {
    type: GET_DASHBOARD_TOPICS_STRENGTHS_SUCCESS,
    payload: { response },
  };
}

export function getDashboardFeedback(userId, from, to, isSkill) {
  return {
    type: GET_DASHBOARD_FEEDBACK,
    payload: {
      userId,
      creationTimeFrom: from,
      creationTimeTo: to,
      isSkill,
    },
  };
}

export function getDashboardFeedbackFail(error) {
  return {
    type: GET_DASHBOARD_FEEDBACK_FAIL,
    payload: { error },
  };
}

export function getDashboardFeedbackSuccess(response) {
  return {
    type: GET_DASHBOARD_FEEDBACK_SUCCESS,
    payload: { response },
  };
}

export function getDashboardFeedbackSummary(userId, from, to) {
  return {
    type: GET_DASHBOARD_FEEDBACK_SUMMARY,
    payload: { userId, creationTimeFrom: from, creationTimeTo: to },
  };
}

export function getDashboardFeedbackSummaryFail(error, userId) {
  return {
    type: GET_DASHBOARD_FEEDBACK_SUMMARY_FAIL,
    payload: { error, userId },
  };
}

export function getDashboardFeedbackSummarySuccess(userId, response) {
  return {
    type: GET_DASHBOARD_FEEDBACK_SUMMARY_SUCCESS,
    payload: { userId, response },
  };
}

export function requestFeedbackUsers(query) {
  return {
    type: REQUEST_FEEDBACK_USERS,
    payload: { query },
  };
}

export function requestFeedbackUsersFail(error) {
  return {
    type: REQUEST_FEEDBACK_USERS_FAIL,
    payload: { error },
  };
}

export function requestFeedbackUsersSuccess(response) {
  return {
    type: REQUEST_FEEDBACK_USERS_SUCCESS,
    payload: { response },
  };
}

export function requestFeedbackSelectCompetency(competency) {
  return {
    type: REQUEST_FEEDBACK_SELECT_COMPETENCY,
    payload: { competency },
  };
}

export function requestFeedbackSelectUser(userData) {
  return {
    type: REQUEST_FEEDBACK_SELECT_USER,
    payload: { userData },
  };
}

export function resetDashboardData() {
  return {
    type: RESET_DASHBOARD_DATA,
  };
}

export function resetGetDashboardFeedback() {
  return {
    type: RESET_GET_DASHBOARD_FEEDBACK,
  };
}

export function resetRequestFeedbackUsers() {
  return {
    type: RESET_REQUEST_FEEDBACK_USERS,
  };
}

export function selectCompetencyDetails(competencyId) {
  return {
    type: SELECT_COMPETENCY_DETAILS,
    payload: { competencyId },
  };
}

export function requestRatedTopics(userId, from, to, feedbackSourceFilter) {
  return {
    type: REQUEST_RATED_TOPICS,
    payload: {
      creationTimeFrom: from,
      creationTimeTo: to,
      feedbackSourceFilter,
      userId,
    },
  };
}

export function requestRatedTopicsFail(error) {
  return {
    type: REQUEST_RATED_TOPICS_FAIL,
    payload: { error },
  };
}

export function requestRatedTopicsSuccess(response) {
  return {
    type: REQUEST_RATED_TOPICS_SUCCESS,
    payload: { response },
  };
}

export function resetRequestFeedbackData() {
  return {
    type: RESET_REQUEST_FEEDBACK_DATA,
  };
}

export function getDashboardFeedbackForLeadersResults(params) {
  return {
    type: GET_DASHBOARD_FEEDBACK_FOR_LEADERS_RESULTS,
    payload: { params },
  };
}

export function getDashboardFeedbackForLeadersResultsFail(error) {
  return {
    type: GET_DASHBOARD_FEEDBACK_FOR_LEADERS_RESULTS_FAIL,
    payload: { error },
  };
}

export function getDashboardFeedbackForLeadersResultsSuccess(response) {
  return {
    type: GET_DASHBOARD_FEEDBACK_FOR_LEADERS_RESULTS_SUCCESS,
    payload: { response },
  };
}

export function resetRatedTopics() {
  return {
    type: RESET_RATED_TOPICS,
  };
}

export function getDashboardPerformanceChart(params) {
  return {
    type: GET_DASHBOARD_PERFORMANCE_CHART,
    payload: { params },
  };
}

export function getDashboardPerformanceChartFail(error) {
  return {
    type: GET_DASHBOARD_PERFORMANCE_CHART_FAIL,
    payload: { error },
  };
}

export function getDashboardPerformanceChartSuccess(response) {
  return {
    type: GET_DASHBOARD_PERFORMANCE_CHART_SUCCESS,
    payload: { response },
  };
}

