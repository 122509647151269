/* eslint-disable */
// @packages
import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import moment from 'moment';
import { isEmpty, sortBy } from 'lodash';
import drilldown from 'highcharts/modules/drilldown';
import more from 'highcharts/highcharts-more';
import venn from 'highcharts/modules/venn';
import boost from 'highcharts/modules/boost';
// Here loads a local version of sankey to solve a bug that is not resolved in Highcharts yet
import sankey from './sankey';
import HighchartsContainer from 'common/HighchartsContainer';
import { interval, colors, formatNumber } from './constant';
// import sadface from 'assets/icons/sad-face.svg';

// @own
import './styles.scss';

drilldown(Highcharts);
sankey(Highcharts);
more(Highcharts);
venn(Highcharts);
boost(Highcharts);

class Highchart extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      coreValue,
      groups,
      filterValues,
      options,
      percentageData,
      data: { series },
      groupBy,
      venn,
    } = this.props;
    const polarChart = options.chart && options.chart.polar;
    const polarChartMax = polarChart && series && {
      yAxis: {
        visible: false,
        min: 0,
        labels: {
          enabled: false,
        },
      },
    }
    const pieChart = options.chart && options.chart.type === 'pie';
    const serieLength = series[0] && series[0].data.length > 1;
    const bubbleChart = options.chart && options.chart.type === 'bubble';
    const serieType = series && pieChart ? series[0] && series[0].data : series;
    const plotDataValue = series && series[0] && series[0].value;
    const selectedColors = !coreValue ? colors : null;
    const dateValues = { plotDataValue, groupBy };

    const coreValuesSeries = serieType && serieType.map(serie => filterValues
      .map(coreValue => (coreValue.typeStarName === serie.name) &&
      { name: coreValue.typeStarName, color: coreValue.backgroundColor, y: serie.y ? serie.y : null, data: plotDataValue !== undefined ?
        serie.data.map((point, index) => [serieLength ? interval(dateValues, index) : null, point]) : serie.data }));
    const coreValuesData = coreValuesSeries && coreValuesSeries.reduce((accumulator, currentValue) => {
      return accumulator.concat(currentValue).filter(l => l.name)},[]);
    const groupsData = groups && series.map(serie => ({name: serie.name, data: plotDataValue !== undefined ?
      serie.data.map((point, index) => [interval(dateValues, index), point]) : serie.data}));
    const initData = {
      plotOptions: {
        series: {
          marker: {
            symbol: 'circle',
          },
          animation: false,
        },
      },
      tooltip: {
        formatter: function () {
          return `${moment(this.x).format('LL')} <br><b>${this.series.name}</b>: ${formatNumber(this.y)}${groupBy === 'percentage' ? '%' : ''}`;
      }
      },
      xAxis: {
        type: 'datetime',
        labels: {
          formatter: function(){
            return moment.utc(this.value).format('ll');
          },
        },
      },
    };
    const regularTooltip = {
      tooltip: {
        formatter: function () {
          return `${(this.x || this.key) === 0 ? '' : (this.x || this.key)} <br><b>${!venn ? this.series.name : ''}</b>${!venn ? ':' : ''}
          ${formatNumber(this.y ? this.y : this.point.value)}${percentageData ? '%' : ''}`;
        },
      },
    };
    const categories = { xAxis: { categories: [plotDataValue] }};
    const dataLength = series && series.length;
    const dateOptions =  series && dataLength > 0 &&
    series[0].data.length > 1 ? initData : categories;
    const customOptions = plotDataValue !== undefined ? Object.assign({}, options, dateOptions, selectedColors) :
      bubbleChart ? Object.assign({}, options, selectedColors) :
      Object.assign({}, regularTooltip, options, selectedColors, polarChartMax);
    const config = { ...customOptions, series: groups ? groupsData :
      !isEmpty(coreValuesData) ?
      !pieChart ?
      coreValuesData :
      [{name: '', data: coreValuesData}] :
      polarChart ? sortBy(series, 'data[0]').reverse() :
      bubbleChart ? [{ data: series[0].data }] :
      series };

      return options && series ?
      dataLength > 0 ?
        <HighchartsContainer highcharts={Highcharts} options={config} /> :
          <div className="highcharts-container__empty">
            <div className="highcharts-container__empty-label">
              <div className="highcharts-container__empty-label-img">
                 {/* <img src={sadface} alt="nothing to show" /> */}
              </div>
              <p>Nothing to show here</p>
            </div>
          </div>
          : null;
  }
}

Highchart.defaultProps = {
  filterValues: [],
  data: {
    series: [],
  }
}

Highchart.propTypes = {
  data: PropTypes.object,
  filterValues: PropTypes.array,
  options: PropTypes.object,
};

export default Highchart;