// @packages
import { get } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

// @app
import CompetencyInfoBox from 'common/CompetencyInfoBox';
import { feedbackMessages } from 'common/messages';

const DashboardOpportunity = ({
  empty,
  intl: { formatMessage },
  link,
  loading,
  opportunities,
  rating,
}) => {
  const { percentage } = get(opportunities, '0', {});
  const worstRating = rating.name;
  const opportunitiesDescription = formatMessage(
    feedbackMessages.FeedbackOpportunityDescription,
    { percentage, rating: worstRating },
  );

  return (
    <CompetencyInfoBox
      color={rating.color}
      competencies={opportunities}
      empty={empty}
      description={opportunitiesDescription}
      loading={loading}
      link={link}
      title={formatMessage(feedbackMessages.FeedbackOpportunity)}
    />
  );
};

DashboardOpportunity.propTypes = {
  empty: PropTypes.shape({
    description: PropTypes.string,
    info: PropTypes.string.isRequired,
    link: PropTypes.object.isRequired,
    onClick: PropTypes.func,
  }).isRequired,
  intl: PropTypes.object.isRequired,
  link: PropTypes.shape({
    label: PropTypes.string.isRequired,
    to: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired]),
    onClick: PropTypes.func,
  }).isRequired,
  loading: PropTypes.bool,
  noRequestFeedback: PropTypes.bool,
  rating: PropTypes.shape({
    color: PropTypes.string,
  }).isRequired,
  opportunities: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
  })),
};

export default DashboardOpportunity;
