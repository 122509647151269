// @package
import React from 'react';
import { injectIntl } from 'react-intl';

// @app
import messages from 'betterme-components/Dashboard/messages';
import EmptyState from 'betterme-components/assets/FeedbackForLeadersResultsEmpty.svg';

// @own
import './styles.scss';

const FeedbackForLeadersResultsEmpty = ({
  intl: { formatMessage },
  user
}) => (
  <div className="feedback-for-leaders-results-empty">
    <div className="feedback-for-leaders-results-empty__image">
      <img src={EmptyState} />
    </div>
    <div className="feedback-for-leaders-results-empty__text">
      { user
        ? formatMessage(messages.DashboardFeedbackForLeadersEmpty, {
            username: user.firstName
          })
        : formatMessage(messages.DashboardFeedbackForLeadersUserEmpty)
      }
    </div>
  </div>
);

export default injectIntl(FeedbackForLeadersResultsEmpty);
