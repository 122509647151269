// @packages
import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

// @own
import './styles.scss';
import ProgressSliderButton from './ProgressSliderButton';

function getDocumentCoords(elem) {// TODO move to utilities
  const box = elem.getBoundingClientRect();

  const body = document.body;
  const docElem = document.documentElement;

  const scrollTop = window.pageYOffset || docElem.scrollTop || body.scrollTop;
  const scrollLeft = window.pageXOffset || docElem.scrollLeft || body.scrollLeft;

  const clientTop = docElem.clientTop || body.clientTop || 0;
  const clientLeft = docElem.clientLeft || body.clientLeft || 0;

  const top = box.top + scrollTop - clientTop;
  const left = box.left + scrollLeft - clientLeft;

  return {
    top: Math.round(top),
    left: Math.round(left)
  };
}

class ProgressSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      barWidth: null,
      barPosition: null,
      showingTooltip: props.editing,
    };

    this.buttonControlRef = React.createRef();
    this.barRef = React.createRef();

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mouseup', this.handleMouseUp);
  }

  componentWillUnmount() {
    document.removeEventListener('mouseup', this.handleMouseUp);
  }

  getPointerPercentage(pointerClientX) {
    const { barPosition, barWidth } = this.state;
    const barOriginPosition = barPosition.left;
    const mouseHorizontalPosition = pointerClientX;
    const positionVector = mouseHorizontalPosition - barOriginPosition;
    const positionVectorBounded = Math.min(Math.max(0, positionVector), barWidth);
    const positionPercentage = positionVectorBounded * 100 / barWidth;

    return positionPercentage;
  }

  handleKeyDown(event) {
    const { onKeyIncrement, onKeyDecrement } = this.props;
    const { key } = event;
    event.preventDefault();

    switch (key) {
      case 'ArrowUp':
      case 'Up':
      case 'ArrowRight':
      case 'Right':
        onKeyIncrement();
        break;
      case 'ArrowDown':
      case 'Down':
      case 'ArrowLeft':
      case 'Left':
        onKeyDecrement();
        break;
      default:
        break;
    }
  }

  handleMouseDown(event) {
    event.preventDefault();
    this.buttonControlRef.current.focus();
    this.setBarMeasures(this.barRef.current);
    document.addEventListener('mousemove', this.handleMouseMove);
  }

  handleMouseMove(event) {
    const { onChange } = this.props;
    const { clientX } = event;
    onChange(this.getPointerPercentage(clientX));
  }

  handleMouseUp() {
    document.removeEventListener('mousemove', this.handleMouseMove);
  }

  setBarMeasures(barNode) {
    this.setState({
      barWidth: barNode.offsetWidth,
      barPosition: getDocumentCoords(barNode),
    });
  }

  render() {
    const {
      editing,
      emptyColor,
      popupContent,
      progress,
      showPopup,
    } = this.props;
    const {
      showingTooltip,
    } = this.state;
    const boundedProgress = Math.min(Math.max(progress, 0), 100);

    const completedBarStyles = {
      width: `${boundedProgress}%`
    };
    const remainingBarStyles = {
      backgroundColor: `${emptyColor}`,
      width: `${100 - boundedProgress}%`
    };
    const buttonControlStyles = {
      transform: `translateX(${boundedProgress}%)`
    };

    return (
      <div
        className={cn('progress-slider', {
          'progress-slider--empty': !boundedProgress,
          'progress-slider--full': boundedProgress === 100,
          'progress-slider--editing': editing,
        })}
        ref={this.barRef}
        onMouseOut={editing
          ? undefined
          : () => this.setState({ showingTooltip: false })
        }
        onMouseOver={editing
          ? undefined
          : () => this.setState({ showingTooltip: true })
        }
        onKeyDown={this.handleKeyDown}
      >
        <div className="progress-slider__completed-bar" style={completedBarStyles} />
          <div className="progress-slider__button-control-wrapper" style={buttonControlStyles}>
            <ProgressSliderButton
              className="progress-slider__button"
              editing={editing}
              innerRef={this.buttonControlRef}
              onMouseDown={this.handleMouseDown}
              popupContent={popupContent}
              showPopup={showPopup || (editing || showingTooltip)}
            />
          </div>
        <div className="progress-slider__remaining-bar" style={remainingBarStyles} />
      </div>
    );
  }
}

ProgressSlider.defaultProps = {
  emptyColor: '#F0F2F5',
  onChange: () => undefined,
  onKeyDecrement: () => undefined,
  onKeyIncrement: () => undefined,
  progress: 0,
};

ProgressSlider.propTypes = {
  editing: PropTypes.bool,
  emptyColor: PropTypes.string,
  onChange: PropTypes.func,
  onKeyIncrement: PropTypes.func,
  onKeyDecrement: PropTypes.func,
  popupContent: PropTypes.string,
  progress: PropTypes.number,
  showPopup: PropTypes.bool,
};

export default ProgressSlider;
