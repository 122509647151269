// @packages
import parse from 'url-parse';
import { getLocalPathname } from 'smu-utils/url';

// @own
import {
  TYPE_ADMIN,
  TYPE_ADMIN_V2,
  TYPE_ANALYTICS,
  TYPE_ANALYTICS_V2,
} from './constants';

export function getItemTarget(item) {
  switch (item?.type) {
    case TYPE_ADMIN:
    case TYPE_ADMIN_V2:
    case TYPE_ANALYTICS:
    case TYPE_ANALYTICS_V2:
      return '_blank';
    default:
      return undefined;
  }
}

export function getItemPathName(item) {
  return item.url ? parse(item.url, true)?.pathname : undefined;
}

export function isHomeBME(deeplink) {
  const to = getLocalPathname(deeplink);
  return !!to && /\/home/i.test(deeplink);
}
