// @packages
import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import AvatarMedia from 'smu-ui-components/AvatarMedia';

// @own
import FeedbackReceivedComment from './FeedbackReceivedComment';
import './styles.scss';

const FeedbackReceivedComments = ({
  className,
  commentsLabel,
  feedbackList,
  user,
}) => {
  return (
    <div className={cn('feedback-received-comments', className)}>
      <div className="feedback-received-comments__header">
        <AvatarMedia
          className="feedback-received__avatar-media"
          firstName={user.senderName}
          identification={user.senderIdentification}
          job={user.job}
          profileImageCode={user.profileImageCode}
        />
        <div className="feedback-received-comments__comments-count">
          {`${feedbackList.length} ${commentsLabel}`}
        </div>
      </div>
      <ul className="feedback-received-comments__comments">
        {feedbackList.map(comment =>
          <li key={comment.id}>
            <FeedbackReceivedComment
              className="feedback-received-comments__comment"
              {...comment}
            />
          </li>
        )}
      </ul>
    </div>
  );
};

FeedbackReceivedComments.propTypes = {
  className: PropTypes.string,
  commentsLabel: PropTypes.string,
  feedbackList: PropTypes.arrayOf(PropTypes.shape({
    audioUrl: PropTypes.string,
    className: PropTypes.string,
    comment: PropTypes.string,
    creationTime: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
  })),
  user: PropTypes.shape({
    senderName: PropTypes.string,
    senderIdentification: PropTypes.string,
    job: PropTypes.string,
    profileImageCode: PropTypes.string,
  }).isRequired,
};

export default FeedbackReceivedComments;
