// @packages
import React from 'react';
import PropTypes from 'prop-types';
import UserAvatar from 'smu-ui-components/UserAvatar';
import { buildImageUrl } from 'smu-utils/image';
// @app

// @own
import './styles.scss';

const FeedHeader = ({
  firstName,
  lastName,
  newStyle,
  profileImageCode,
  job
}) => {
  return (
    <div className="feed-header">
      {newStyle ? (
        <UserAvatar
          avatarSize={60}
          avatarSrc={buildImageUrl({
            code: profileImageCode,
            height: 60,
            width: 60,
          })}
          className="mr-2"
          description={job}
          legacy={false}
          name={`${firstName} ${lastName}`}
        />
      ) : (
        <>
          <UserAvatar profileImageCode={profileImageCode} height={60} width={60} />
          <span className="feed-header__user-data">
            <p className="feed-header__user-name">{firstName} {lastName}</p>
            <small className="feed-header__job">{job}</small>
          </span>
        </>
      )}
    </div>
  );
};

FeedHeader.propTypes = {
  firstName: PropTypes.string,
  job: PropTypes.string,
  lastName: PropTypes.string,
  newStyle: PropTypes.bool,
  profileImageCode: PropTypes.string,
};

export default FeedHeader;
