import { trackEvent, trackPageview } from 'smu-utils/gtm';
const USER_DASHBOARD = 'User_Dashboard';
const OTHER_PROFILE = 'Other_Profile';
const ENGAGEMENT = 'engagement';

export const trackSendFeedback = () => {
  trackEvent({
    'category': OTHER_PROFILE,
    'action': 'Send_Feedback',
  });
};

export const trackRequestFeedback = () => {
  trackEvent({
    'category': OTHER_PROFILE,
    'action': 'Request_Feedback',
  });
};

export const trackRequestFeedbackOnBehalf = () => {
  trackEvent({
    'category': OTHER_PROFILE,
    'action': 'RequestOnBehalf_Button',
  });
};

export const trackCreateGoal = () => {
  trackEvent({
    'category': OTHER_PROFILE,
    'action': 'Create_Goal',
  });
};

export const trackSuggestedActionsViewMore = () => {
  trackEvent({
    'category': OTHER_PROFILE,
    'action': 'BoxSuggestedActions_ViewMore',
  });
};

export const trackCreateEvaluation = () => {
  trackEvent({
    'category': OTHER_PROFILE,
    'action': 'Create_Evaluation',
  });
};

export const trackCreateSuggestionClick = () => {
  trackEvent({
    category: OTHER_PROFILE,
    action: 'Create_Suggestion',
  });
};

export const trackChangeLeader = () => {
  trackEvent({
    category: OTHER_PROFILE,
    action: 'Change_Leader',
  });
};

export const trackChangeLeaderButtonAlert = () => {
  trackEvent({
    category: OTHER_PROFILE,
    action: 'Change_Leader_Button_Alert',
  });
};

export const trackAddAsCollaborator = () => {
  trackEvent({
    category: OTHER_PROFILE,
    action: 'AddAsCollaborator_Button',
  });
};

export const trackBoxOpportunitySendFeedback = () => {
  trackEvent({
    'category': USER_DASHBOARD,
    'action': 'BoxOpportunity_SendFeedback_Default',
  });
};

export const trackBoxOpportunitySendFeedbackEmpty = () => {
  trackEvent({
    'category': USER_DASHBOARD,
    'action': 'BoxOpportunity_SendFeedback_EmptyState',
  });
};

export const trackBoxStrengthsSendFeedbackEmpty = () => {
  trackEvent({
    'category': USER_DASHBOARD,
    'action': 'BoxStrengths_SendFeedback_EmptyState',
  });
};

export const trackEngagementIndicatorHover = (type) => {
  trackEvent({
    action: 'indicator_hover',
    category: ENGAGEMENT,
    label: type,
  });
}

export const trackEngagementIndicatorClick = (type) => {
  trackEvent({
    action: 'indicator_click',
    category: ENGAGEMENT,
    label: type,
  });
}

export const trackEngagementFormOpen = (action, type) => {
  trackEvent({
    action,
    category: ENGAGEMENT,
    label: type,
  });
}

export const trackEngagementFormSuccess = (action, type, questions) => {
  trackEvent({
    action,
    category: ENGAGEMENT,
    label: type,
    ...questions,
  });
}

export const trackEngagementIndicatorModal = (action, type) => {
  trackEvent({
    action,
    category: ENGAGEMENT,
    label: type,
  });
}

export const trackEngagementFormPageView = (page) => {
  trackPageview({
    pageName: page,
  });
}
