import {
  CHANGE_SELECTED_USER,
  DELETE_SELECTED_USER,
  GET_USERS_LIST,
  GET_USERS_LIST_FAIL,
  GET_USERS_LIST_SUCCESS,
  RESET_SELECTED_USER,
  RESET_USERS_LIST,
  SET_SELECTED_USER,
  SET_SELECTED_USER_LIST,
} from './actionTypes';

export function getUsersList(payload) {
  return {
    type: GET_USERS_LIST,
    payload,
  }
}

export function getUsersListFail(payload) {
  return {
    type: GET_USERS_LIST_FAIL,
    payload,
  }
}

export function getUsersListSuccess(payload) {
  return {
    type: GET_USERS_LIST_SUCCESS,
    payload,
  }
}


export function setSelectedUser(payload) {
  return {
    type: SET_SELECTED_USER,
    payload,
  }
}

export function setSelectedUserList(payload) {
  return {
    type: SET_SELECTED_USER_LIST,
    payload,
  }
}

export function resetSelectedUser(payload) {
  return {
    type: RESET_SELECTED_USER,
    payload,
  }
}

export function resetUsersList(payload) {
  return {
    type: RESET_USERS_LIST,
    payload,
  }
}

export function deleteSelectedUser(payload) {
  return {
    type: DELETE_SELECTED_USER,
    payload,
  }
}

export function changeSelectedUser(selectedUser) {
  return {
    type: CHANGE_SELECTED_USER,
    payload: { selectedUser },
  }
}
