// @packages
import React, { useEffect } from 'react';
import LottieSpinner from 'smu-ui-components/LottieSpinner';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router';

// @app
import { selectI18nRequesting } from 'betterme-components/IntlProvider/selectors';
import {
  isEnabledPerformance,
  isEnabledSurveysOnly,
} from 'betterme-components/services/CommunityConfigs/selectors';

// @own
import './styles.scss';
import {
  selectCommunityConfigurations,
  selectSessionLoading,
  selectUserInfo,
} from './selectors';
import * as actions from './actions';
import { getRedirectUrl } from './helpers';

export default function Authorization(WrappedComponent) {
  function Authorization({
    communityConfigurations,
    isEnabledPerformance,
    isEnabledSurveysOnly,
    loading,
    router,
    user,
    ...rest
  }) {
    const redirectUrl = getRedirectUrl({
      isEnabledPerformance,
      isEnabledSurveysOnly,
    });

    const showComponent =
      !isEmpty(user) && communityConfigurations && !loading && !redirectUrl;

    useEffect(() => {
      if (!loading && redirectUrl) {
        router.replace(redirectUrl);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [redirectUrl, loading]);

    return showComponent ? (
      <WrappedComponent {...rest} session={{ communityConfigurations, user }} />
    ) : (
      <div className="authorization__loading">
        <LottieSpinner
          className="authorization__spinner"
          loadingType="betterme"
        />
      </div>
    );
  }

  const mapStateToProps = (state) => ({
    communityConfigurations: selectCommunityConfigurations(state),
    isEnabledPerformance: isEnabledPerformance(state),
    isEnabledSurveysOnly: isEnabledSurveysOnly(state),
    loading: selectSessionLoading(state) || selectI18nRequesting(state),
    user: selectUserInfo(state),
  });

  Authorization.displayName = `withAuthorization(${getDisplayName(
    WrappedComponent
  )})`;

  Authorization.propTypes = {
    communityConfigurations: PropTypes.object,
    loading: PropTypes.bool,
    user: PropTypes.object,
  };

  return connect(mapStateToProps, actions)(withRouter(Authorization));
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}
