import { defineMessages } from 'react-intl';

export default defineMessages({
  ChangeLeaderTitle: {
    id: 'Betterme.ChangeLeader.Title',
    defaultMessage: 'Change leader',
  },
  ChangeLeaderPlaceholder: {
    id: 'Betterme.ChangeLeader.Placeholder',
    defaultMessage: 'Find a new leader by their name or email…',
  },
  ChangeLeaderActionChange: {
    id: 'Betterme.ChangeLeader.ActionChange',
    defaultMessage: 'Change',
  },
  ChangeLeaderAlertDescription: {
    id: 'Betterme.ChangeLeader.AlertDescription',
    defaultMessage: 'Are you sure to change this collaborator\'s leader?',
  },
  ChangeLeaderToastSuccess: {
    id: 'Betterme.ChangeLeader.ToastSuccess',
    defaultMessage: 'Your leadership request was sent successfully',
  },
  ChangeLeaderToastFail: {
    id: 'Betterme.ChangeLeader.ToastFail',
    defaultMessage: 'We couldn’t change the leader',
  },
  ChangeLeaderFeatureDiscovery: {
    id: 'Betterme.ChangeLeader.FeatureDiscovery',
    defaultMessage: 'Change the leader of your collaborator. After that they will receive a notification.',
  },
  ChangeLeaderAdminLeadership: {
    id: 'Betterme.ChangeLeader.AdminLeadership',
    defaultMessage: 'Leadership Admin',
  },
  UserListInfoTitleMain: {
    id: 'Betterme.UserListInfo.TitleMain',
    defaultMessage: 'Main',
  },
  LeadersListMainLeader: {
    id: 'Betterme.LeadersList.MainLeader',
    defaultMessage: 'Main Leader',
  },
  LeadersListMainAndSecondaryLeader: {
    id: 'Betterme.LeadersList.MainAndSecondaryLeader',
    defaultMessage: 'Main & Secondary Leader',
  },
  LeadersListCareerLeader: {
    id: 'Betterme.LeadersList.CareerLeader2',
    defaultMessage: 'Career Mentor',
  },
  LeadersListCareerAndAccountLeader: {
    id: 'Betterme.LeadersList.CareerAndAccountLeader2',
    defaultMessage: 'Career Mentor & Experience Leader',
  },
  LeadersListCareerAndOtherLeaders: {
    id: 'Betterme.LeadersList.CareerAndOtherLeader2',
    defaultMessage: 'Career Mentor & Experience Leaders',
  },
  LeadersListCareerLeaderDescription: {
    id: 'Betterme.LeadersList.CareerMentorDescription2',
    defaultMessage: 'The Career Mentor accompanies Glober’s career growth in a long-term relationship. They guide them to unleash potential and evolve as a Glober.',
  },
  LeadersListSecondaryLeader: {
    id: 'Betterme.LeadersList.SecondaryLeader',
    defaultMessage: 'Secondary Leaders',
  },
  LeadersListAccountLeader: {
    id: 'Betterme.LeadersList.AccountLeader2',
    defaultMessage: 'Experience Leaders',
  },
  LeadersListSecondaryLeaderDescription: {
    id: 'Betterme.LeadersList.ExperienceLeaderDescription2',
    defaultMessage: 'The Experience Leader is the closest leader, that belongs to the same account and assures the best daily experience to the Glober.',
  },
  LeadersListOtherLeaders: {
    id: 'Betterme.LeadersList.OtherLeaders2',
    defaultMessage: 'Experience Leaders',
  },
  LeadersListOtherLeadersDescription: {
    id: 'Betterme.LeadersList.OtherLeadersDescription2',
    defaultMessage: 'Career Mentor and/or Experience Leaders above Glober\'s current leaders',
  },
  LeadersListLeadershipRequests: {
    id: 'Betterme.LeadersList.LeadershipRequests',
    defaultMessage: 'Leadership Requests',
  },
  CollaboratorsListEmptyState: {
    id: 'Betterme.CollaboratorsList.EmptyState',
    defaultMessage: 'You don’t have any collaborators yet.',
  },
  CollaboratorsListEmptyStateView: {
    id: 'Betterme.CollaboratorsList.EmptyStateCollaboratorView',
    defaultMessage: '{username} doesn’t have any collaborators yet.',
  },
  LeaderListDiscoveryTitle: {
    id: 'Betterme.LeadersList.DiscoveryTitle',
    defaultMessage: 'Leadership structure',
  },
  LeaderListDiscoverySlideTextOne: {
    id: 'Betterme.LeadersList.DiscoverySlideTextOne2',
    defaultMessage: 'The <b>Career Mentor</b> accompanies Glober’s career growth in a long-term relationship. They guide them to unleash potential and evolve as a Glober.',
  },
  LeaderListDiscoverySlideTextTwo: {
    id: 'Betterme.LeadersList.DiscoverySlideTextTwo2',
    defaultMessage: 'The <b>Experience Leader</b> is the closest leader, that belongs to the same account and assures the best daily experience to the Glober.',
  },
  LeaderListDiscoveryNew: {
    id: 'Betterme.LeadersList.New',
    defaultMessage: 'New',
  },
  LeaderListDiscoveryBack: {
    id: 'Betterme.LeadersList.Back',
    defaultMessage: 'Back',
  },
  LeadersListEmptyState: {
    id: 'Betterme.LeadersList.EmptyState',
    defaultMessage: 'No leader assigned yet',
  },
  LeadersListEmptyStatePlural: {
    id: 'Betterme.LeadersList.EmptyStatePlural',
    defaultMessage: 'No leaders assigned yet',
  },
  LeadersListOtherAccountLeaders: {
    id: 'Betterme.LeadersList.OtherAccountLeaders',
    defaultMessage: 'Experience Leaders'
  },
  LeadersListOtherSecondaryLeaders: {
    id: 'Betterme.LeadersList.OtherSecondaryLeader',
    defaultMessage: 'Other Secondary Leaders'
  },
});
