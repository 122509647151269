// @packages
import React, { useState } from 'react';
import Label from 'smu-ui-components/Label';
import Icon from 'smu-ui-components/IconV2';
import cn from 'classnames';
import Segment from 'smu-ui-components/Segment';

// @app
import CardIllustration from 'betterme-components/assets/Card-Illustration.svg';
import CommentBox from 'components/molecules/CommentBox';
import Discovery from 'betterme-components/TeamInformation/LeadersList/Discovery';
import SelectSearchUser from 'components/containers/SelectSearchUser';
import UserSelectCard from 'components/molecules/UserSelectCard';

// @own
import './styles.scss';

const CoCreatedBox = ({
  actionButtonTexts,
  className,
  discoveryType,
  galleryItems,
  hasCoEvaluator,
  isLoading,
  labelTitle,
  placeholder,
  searchSectionDisclaimer,
  searchSectionReason,
  searchSectionText,
  showClarification,
  showFeatureDiscovery,
  title,
  user,
}) => {
  const [show, setShow] = useState(true);

  function onLabelClick(event) {
    event.stopPropagation();
    setShow(true);
  }
  const labelNode = (
    <Label
      className="ml-2"
      color="btm-blue-button"
      size="small"
      text={labelTitle}
    />
  );

  return (
    <CommentBox
      className={cn('co-created-box', className, {
        'co-created-box--small': hasCoEvaluator,
      })}
      title={
        <span className="co-created-box__discovery">
          {title}
          {showFeatureDiscovery ? (
            <Discovery
              className="co-created-box__discovery-discovery"
              handleOpen={setShow}
              open={show}
              type={discoveryType}
              galleryItems={galleryItems}
              actionButtonTexts={actionButtonTexts}

            >
              <div
                className="co-created-box__discovery-label"
                onClick={(event) => onLabelClick(event)}
              >
                {labelNode}
              </div>
            </Discovery>
          ) : (
           <>{ labelNode }</>
          )}
        </span>
      }
    >
      <Segment
        loading={isLoading}
        loadingType="betterme">
        <div className="co-created-box__search">
          <p className="co-created-box__search-description">
            {searchSectionText}
          </p>
          {hasCoEvaluator ? (
            <UserSelectCard
              user={user}
            />
          ) : (
            <>
              <SelectSearchUser
                className="co-created-box__search-searching"
                placeholder={placeholder}
              />
              {showClarification && (
                <div className="co-created-box__search-clarification">
                  <img
                    alt={searchSectionReason}
                    className="co-created-box__search-clarification-image"
                    src={CardIllustration}
                  />
                  <p className="co-created-box__search-clarification-text">
                    {searchSectionReason}
                  </p>
                </div>
              )}
            </>
          )}
          <div className="co-created-box__search-disclaimer">
            <Icon
              className="co-created-box__search-disclaimer-icon"
              icon={hasCoEvaluator ? 'info' : 'discket'}
              size={16}
            />
            <p className="co-created-box__search-disclaimer-text">
              {searchSectionDisclaimer}
            </p>
          </div>
        </div>
      </Segment>
    </CommentBox>
  );
}

export default CoCreatedBox;
