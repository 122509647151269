// @packages
import { createSelector } from 'reselect';

export const selectGoalsAssignedDomain = (state) => state.goalsAssigned;

export const selectGoalsAssigned = createSelector(
  selectGoalsAssignedDomain,
  (state) => state.goalsAssigned,
);

export const selectGoalsAssignedClosed = createSelector(
  selectGoalsAssignedDomain,
  (state) => state.goalsAssignedClosed,
);

export const selectGoalsAssignedOpened = createSelector(
  selectGoalsAssignedDomain,
  (state) => state.goalsAssignedOpened,
);

export const selectGoalsAssignedLoading = createSelector(
  selectGoalsAssignedDomain,
  (state) => state.loading,
);

export const selectGoalsAssignedClosedLoading = createSelector(
  selectGoalsAssignedDomain,
  (state) => state.loadingClosed,
);

export const selectGoalsAssignedOpenedLoading = createSelector(
  selectGoalsAssignedDomain,
  (state) => state.loadingOpened,
);
