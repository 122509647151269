export const ACCEPT = 'ACCEPT';
export const ACCEPTED_BY_MANAGER = 'ACCEPTED_BY_MANAGER';
export const APPLIED = 'APPLIED';
export const ASSIGN_MANAGER = 'ASSIGN_MANAGER';
export const CREATE = 'CREATE';
export const DECLINE = 'DECLINE';
export const DECLINED_BY_LEADER = 'DECLINED_BY_LEADER';
export const DECLINED_BY_MANAGER = 'DECLINED_BY_MANAGER';
export const DRAFT = 'DRAFT';
export const LEADER_REVIEW = 'LEADER_REVIEW';
export const MAIN_LEADER = 'MAIN_LEADER';
export const MANAGER = 'MANAGER';
export const MANAGER_REVIEW = 'MANAGER_REVIEW';
export const NOT_READY = 'Not Ready';
export const NOT_READY_FOR_PROMO = 'NOT_READY_FOR_PROMO';
export const NOT_REQUESTED = 'NOT_REQUESTED';
export const ALL = 'ALL';
export const REQUEST = 'REQUEST';
export const REVIEW = 'REVIEW';
export const PAGE_SIZE = 10;
export const PARTIALLY_READY = 'Partially Ready';
export const PENDING = 'PENDING';
export const POTENTIALLY_READY = 'Potentially Ready';
export const READINESS_INFO_LINK = 'https://sites.google.com/globant.com/leadershipcommunity/developing-my-team/readiness-promotions?authuser=0';
export const READY = 'Ready';
export const REQUESTED_SECONDARY_PROMOTION = 'REQUESTED_SECONDARY_PROMOTION';
export const SECONDARY_LEADER = 'SECONDARY_LEADER';
export const TALENT_REVIEW = 'TALENT_REVIEW';
export const TRAFFIC_LIGHT_HIGH = 'HIGH';
export const TRAFFIC_LIGHT_LOW = 'LOW';
export const TRAFFIC_LIGHT_MEDIUM = 'MEDIUM';
export const WITHOUT_NEXT_SENIORITY = 'WITHOUT_NEXT_SENIORITY';
