import { defineMessages } from 'react-intl';

export default defineMessages({
  SelfRetrospectiveTitle: {
    id: 'Betterme.SelfRetrospective.Title',
    defaultMessage: 'Create Self-Retrospective',
  },
  SelfRetrospectiveNew: {
    id: 'Betterme.Evaluations.CoCreatorNew',
    defaultMessage: 'New',
  },
  SelfRetrospectiveAttachFile: {
    id: 'Betterme.SelfRetrospective.AttachFile',
    defaultMessage: '(Referred to your Performance Score)',
  },
  SelfRetrospectiveRate: {
    id: 'Betterme.SelfRetrospective.Rate',
    defaultMessage: 'Performance Score',
  },
  SelfRetrospectiveSelectRate: {
    id: 'Betterme.SelfRetrospective.SelectRate',
    defaultMessage: 'Choose a performance score for the assessment (Required)',
  },
  SelfRetrospectivePlaceholder: {
    id: 'Betterme.SelfRetrospective.Placeholder',
    defaultMessage: 'Leave a comment about your performance score during this period (Required)',
  },
  PeriodEvaluationSuccessMessage: {
    id: 'Betterme.Evaluations.EvaluationSuccessMessage',
    defaultMessage: 'Well done!',
  },
  SelfRetrospectiveSuccessFooterMessageTeam: {
    id: 'Betterme.SelfRetrospective.SuccessFooterMessageTeam',
    defaultMessage: 'Your Self-Retrospective was created successfully',
  },
  PeriodEvaluationOverallScoreFiles: {
    id: 'Betterme.Evaluations.OverallScoreFiles',
    defaultMessage: 'Referring to the overall score',
  },
  PeriodEvaluationDraftSaved: {
    id: 'Betterme.Evaluations.DraftSaved',
    defaultMessage: 'The draft was saved successfully',
  },
  PeriodEvaluationCoEvaluatorDraftSaved: {
    id: 'Betterme.Evaluations.CoEvaluatorDraftSaved',
    defaultMessage: 'Draft was successfully saved! Notification for Co-Creator was sent',
  },
  PeriodEvaluationDraftDeleted: {
    id: 'Betterme.Evaluations.DraftDeleted',
    defaultMessage: 'The draft was deleted successfully.',
  },
});
