// @packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { injectIntl } from 'react-intl';
import ButtonMenu from 'smu-ui-components/ButtonMenu';
import Grid from 'smu-layout-components/Grid';

// @app
import BreadcrumbHeader from 'components/organisms/BreadcrumbHeader';
import { selectFeedbackForLeadersRatings } from 'betterme-components/Authorization/selectors';
import SectionListV2 from 'common/SectionListV2';
import { feedbackForLeadersMessages, placeholdersMessages } from 'common/messages';
import { getMyProfileLocation } from 'betterme-components/routes/urls';
import { selectConfigurations } from 'betterme-components/Authorization/selectors';

// @own
import './styles.scss';
import CompetenciesDetails from './CompetenciesDetails';
import InsightsDetails from './InsightsDetails';
import messages from './messages';
import AllCompetencies from './AllCompetencies';
import {
  selectFeedbackForLeadersReceived,
  selectFeedbackForLeadersReceivedChartCompetencies,
  selectFeedbackForLeadersReceivedCompetencies,
  selectFeedbackForLeadersReceivedCompetenciesList,
  selectFeedbackForLeadersReceivedInsights,
  selectFeedbackForLeadersReceivedInsightsList,
  selectLoadingFeedbackForLeadersReceived,
  selectFeedbackForLeadersReceivedCompetenciesRates,
} from './selectors';
import {
  getFeedbackForLeadersReceived,
  getFeedbackForLeadersReport,
} from './actions';
import {
  SECTION_ALL,
  SECTION_COMPETENCIES,
  SECTION_INSIGHTS,
} from './constants';
import EmptyState from './EmptyState';

const FeedbackForLeadersReceived = ({
  allowsGlbFeatures,
  chartCompetencies,
  feedbackForLeadersRatings,
  feedbackForLeadersReceived,
  feedbackForLeadersReceivedCompetencies,
  feedbackForLeadersReceivedCompetenciesList,
  feedbackForLeadersReceivedCompetenciesRates,
  feedbackForLeadersReceivedInsights,
  feedbackForLeadersReceivedInsightsList,
  getFeedbackForLeadersReceived,
  getFeedbackForLeadersReport,
  goToMyDashboard,
  half,
  intl: { formatMessage },
  isCollaborator,
  loadingFeedbackForLeadersReceived,
  navigateBackLabel,
  navigateBackTo,
  navigateTo,
  user,
  year,
}) => {
  const [newCompetenciesList, setNewCompetenciesList] = useState([]);
  const [selectedCompetenceId, setSelectedCompetenceId] = useState(0);
  const [selectedInsightId, setSelectedInsightId] = useState(0);
  const [selectedSection, setSelectedSection] = useState(SECTION_ALL);
  const allCompetenciesSection = {
    description: formatMessage(messages.FeedbackForLeadersReceivedAllCompetencies),
    displayValue: formatMessage(feedbackForLeadersMessages.FeedbackForLeadersAll),
    value: 0,
  };

  useEffect(() => {
    if (user) {
      getFeedbackForLeadersReceived({
        half,
        insightSection: true,
        topicQuestionDetailsSection: true,
        userId: user.id,
        year,
      });
    }
  }, []);

  useEffect(() => {
    setNewCompetenciesList([
      allCompetenciesSection, ...feedbackForLeadersReceivedCompetenciesList,
    ]);
  }, [feedbackForLeadersReceivedCompetenciesList]);

  const showContent = feedbackForLeadersReceived && !loadingFeedbackForLeadersReceived;

  const showDownloadReport = false && showContent && !isCollaborator && allowsGlbFeatures; //TODO: remove false when feature is ready

  const currentCompetency = feedbackForLeadersReceivedCompetencies.find(({ competency }) => (
    competency?.id === selectedCompetenceId
  ));

  const currentInsight = feedbackForLeadersReceivedInsights.find(({ question }) => (
    question?.id === selectedInsightId
  ));

  const navigateBack = {
    url: navigateBackTo
      ? () => navigateTo(navigateBackTo)
      : goToMyDashboard,
    label: navigateBackLabel || formatMessage(messages.FeedbackForLeadersReceivedSection),
  };

  function handleSelectCompetency(competencyId) {
    setSelectedInsightId(0);

    if (competencyId === 0) {
      setSelectedSection(SECTION_ALL);
    } else {
      setSelectedSection(SECTION_COMPETENCIES);
    }

    setSelectedCompetenceId(competencyId);
  }

  function handleSelectInsight(insightId) {
    setSelectedCompetenceId(null);
    setSelectedSection(SECTION_INSIGHTS);
    setSelectedInsightId(insightId);
  }

  function getButtonMenuOptions() {
    return [
      {
        text: formatMessage(placeholdersMessages.PlaceholdersCsvDoc),
        value: 'csv',
      },
      {
        text: formatMessage(placeholdersMessages.PlaceholdersXlsDoc),
        value: 'xls',
      },
      {
        text: formatMessage(placeholdersMessages.PlaceholdersXlsxDoc),
        value: 'xlsx',
      },
    ];
  }

  function handleDownloadReport(value) {
    getFeedbackForLeadersReport({
      fileType: value,
      half: feedbackForLeadersReceived?.half,
      year: feedbackForLeadersReceived?.period?.year,
    });
  }

  return (
    <>
      <BreadcrumbHeader
        breadCrumbsProps={{
          active: 1,
          onClick: navigateBack.url,
          values: [
            navigateBack.label,
            formatMessage(messages.FeedbackForLeadersReceivedTitle),
          ],
        }}
      >
        {showDownloadReport && (
          <ButtonMenu
            className="feedback-for-leaders-received__report-button"
            buttonIcon="download"
            buttonText={formatMessage(messages.FeedbackForLeadersReceivedResults)}
            onClickItem={value => handleDownloadReport(value)}
            options={getButtonMenuOptions()}
          />
        )}
      </BreadcrumbHeader>
      <div className="feedback-for-leaders-received__container">
        {showContent && (
          <Grid>
            <Grid.Col
              desktop={4}
              extraClass="feedback-for-leaders-received__side-section"
            >
              <SectionListV2
                className="feedback-for-leaders-received__side-section-list"
                listItems={newCompetenciesList}
                loading={loadingFeedbackForLeadersReceived}
                title={formatMessage(messages.FeedbackForLeadersReceivedCompetencies)}
                value={selectedCompetenceId}
                onClick={handleSelectCompetency}
              />

              <SectionListV2
                className="feedback-for-leaders-received__side-section-list"
                fitContent
                listItems={feedbackForLeadersReceivedInsightsList}
                loading={loadingFeedbackForLeadersReceived}
                title={formatMessage(messages.FeedbackForLeadersReceivedInsights)}
                value={selectedInsightId}
                onClick={handleSelectInsight}
              />
            </Grid.Col>

            <Grid.Col
              desktop={8}
              extraClass="feedback-for-leaders-received__main-section"
            >
              {selectedSection === SECTION_ALL && (
                <AllCompetencies
                  {...feedbackForLeadersReceived}
                  chartCompetencies={chartCompetencies}
                  competenciesRates={feedbackForLeadersReceivedCompetenciesRates}
                  loading={loadingFeedbackForLeadersReceived}
                  ratings={feedbackForLeadersRatings}
                />
              )}

              {(selectedSection === SECTION_COMPETENCIES) && (
                <CompetenciesDetails
                  competency={currentCompetency}
                />
              )}

              {(selectedSection === SECTION_INSIGHTS) && (
                <InsightsDetails
                  insight={currentInsight}
                />
              )}
            </Grid.Col>
          </Grid>
        )}
        {(!feedbackForLeadersReceived && !loadingFeedbackForLeadersReceived) && (
          <div className="feedback-for-leaders-received__empty-state">
            <EmptyState user={user} />
          </div>
        )}
      </div>
    </>
  );
};

FeedbackForLeadersReceived.propTypes = {
  feedbackForLeadersReceivedCompetencies: PropTypes.array.isRequired,
  feedbackForLeadersReceivedCompetenciesList: PropTypes.array.isRequired,
  feedbackForLeadersReceivedInsights: PropTypes.array.isRequired,
  feedbackForLeadersReceivedInsightsList: PropTypes.array.isRequired,
  feedbacksForLeadersReceived: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  isCollaborator: PropTypes.bool.isRequired,
  loadingFeedbackForLeadersReceived: PropTypes.bool.isRequired,
  navigateBackLabel: PropTypes.string,
  navigateBackTo: PropTypes.string,
  navigateTo: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  allowsGlbFeatures: selectConfigurations('allowsGlbFeatures')(state),
  chartCompetencies: selectFeedbackForLeadersReceivedChartCompetencies(state),
  feedbackForLeadersRatings: selectFeedbackForLeadersRatings(state),
  feedbackForLeadersReceived: selectFeedbackForLeadersReceived(state),
  feedbackForLeadersReceivedCompetencies: selectFeedbackForLeadersReceivedCompetencies(state),
  feedbackForLeadersReceivedCompetenciesList: selectFeedbackForLeadersReceivedCompetenciesList(state),
  feedbackForLeadersReceivedCompetenciesRates: selectFeedbackForLeadersReceivedCompetenciesRates(state),
  feedbackForLeadersReceivedInsights: selectFeedbackForLeadersReceivedInsights(state),
  feedbackForLeadersReceivedInsightsList: selectFeedbackForLeadersReceivedInsightsList(state),
  loadingFeedbackForLeadersReceived: selectLoadingFeedbackForLeadersReceived(state),
});

const goToMyDashboard = () => push(getMyProfileLocation());
const navigateTo = (to) => push(to);

const mapDispatchToProps = {
  getFeedbackForLeadersReceived,
  getFeedbackForLeadersReport,
  goToMyDashboard,
  navigateTo,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(FeedbackForLeadersReceived));
