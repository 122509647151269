// @packages
import React from 'react';
import PropTypes from 'prop-types';
import RootModal from 'smu-app-components/RootModal';
import SuccessCard from 'smu-ui-components/SuccessCard';
import { FormattedMessage, injectIntl } from 'react-intl';

// @app
import { LEADER_CONFIRMATION_SUCCESS_MODAL } from '../../constants/modalTypes';
import {
  LEADER_ACTION_ADD,
  LEADER_ACTION_REQUEST
} from '../../constants/leader';

// @own
import messages from '../messages';

const LeadershipRequestSuccessModal = ({
  action,
  close,
  intl: { formatMessage },
  user
}) => {
  const fullName = `${user.firstName} ${user.lastName}`;

  return (
    <SuccessCard
      className="loadership-request-success-modal"
      footerMessage={
        <FormattedMessage
          {...messages[
            action === LEADER_ACTION_ADD
              ? "LeadershipRequestComponentsSuccessText"
              : "LeadershipRequestComponentsRequestSuccessText"
          ]}
          values={{ fullName: <b>{fullName}</b> }}
        />
      }
      message={formatMessage(messages.LeadershipRequestComponentsSuccessTitle)}
      onCloseClick={close}
      solid
      users={[user]}
    />
  );
};

LeadershipRequestSuccessModal.propTypes = {
  action: PropTypes.oneOf([LEADER_ACTION_ADD, LEADER_ACTION_REQUEST])
    .isRequired,
  close: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  messages: PropTypes.shape({
    footerMessage: PropTypes.string,
    message: PropTypes.string
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

const modalStyles = { maxWidth: "600px" };
const withRootModalComponent = RootModal(
  injectIntl(LeadershipRequestSuccessModal),
  LEADER_CONFIRMATION_SUCCESS_MODAL,
  modalStyles,
  "modal__wrapper"
);

export default withRootModalComponent;
