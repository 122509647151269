import {
  GET_GOALS_ASSIGNED,
  GET_GOALS_ASSIGNED_FAIL,
  GET_GOALS_ASSIGNED_SUCCESS,
  GET_GOALS_ASSIGNED_CLOSED,
  GET_GOALS_ASSIGNED_CLOSED_FAIL,
  GET_GOALS_ASSIGNED_CLOSED_SUCCESS,
  GET_GOALS_ASSIGNED_OPENED,
  GET_GOALS_ASSIGNED_OPENED_FAIL,
  GET_GOALS_ASSIGNED_OPENED_SUCCESS,
  RESET_GOALS_ASSIGNED,
} from './actionTypes';

export function getGoalsAssigned(userId, filters) {
  return {
    type: GET_GOALS_ASSIGNED,
    payload: { userId, filters },
  };
}

export function getGoalsAssignedSuccess(response) {
  return {
    type: GET_GOALS_ASSIGNED_SUCCESS,
    payload: { response },
  };
}

export function getGoalsAssignedFail(error) {
  return {
    type: GET_GOALS_ASSIGNED_FAIL,
    payload: { error },
  };
}

export function getGoalsAssignedClosed(userId, filters) {
  return {
    type: GET_GOALS_ASSIGNED_CLOSED,
    payload: { userId, filters },
  };
}

export function getGoalsAssignedClosedSuccess(response) {
  return {
    type: GET_GOALS_ASSIGNED_CLOSED_SUCCESS,
    payload: { response },
  };
}

export function getGoalsAssignedClosedFail(error) {
  return {
    type: GET_GOALS_ASSIGNED_CLOSED_FAIL,
    payload: { error },
  };
}

export function getGoalsAssignedOpened(userId, filters) {
  return {
    type: GET_GOALS_ASSIGNED_OPENED,
    payload: { userId, filters },
  };
}

export function getGoalsAssignedOpenedSuccess(response) {
  return {
    type: GET_GOALS_ASSIGNED_OPENED_SUCCESS,
    payload: { response },
  };
}

export function getGoalsAssignedOpenedFail(error) {
  return {
    type: GET_GOALS_ASSIGNED_OPENED_FAIL,
    payload: { error },
  };
}

export function resetGoalsAssigned() {
  return {
    type: RESET_GOALS_ASSIGNED,
  };
}
