// @packages
import React from 'react';
import MyTeam from 'smu-app-components/MyTeam';
import parse from 'url-parse';

const MyTeamMenu = (props) => {
  const transformItem = (item) => {
    if (item?.deeplink) {
      const parsed = parse(item.deeplink);
      return {
        ...item,
        deeplink: parsed.pathname
      };
    }

    return item;
  };
  return <MyTeam transformItem={transformItem} {...props} />;
};

export default MyTeamMenu;
