import {
  GET_SUGGESTED_ACTIONS_LIST,
  GET_SUGGESTED_ACTIONS_LIST_FAIL,
  GET_SUGGESTED_ACTIONS_LIST_SUCCESS,
} from './actionTypes';

export function getSuggestedActionsList(params) {
  return {
    type: GET_SUGGESTED_ACTIONS_LIST,
    payload: params,
  };
}

export function getSuggestedActionsListFail(error) {
  return {
    type: GET_SUGGESTED_ACTIONS_LIST_FAIL,
    payload: { error },
  };
}

export function getSuggestedActionsListSuccess(response) {
  return {
    type: GET_SUGGESTED_ACTIONS_LIST_SUCCESS,
    payload: { response },
  };
}
