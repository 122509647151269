// @packages
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// @app
import TeamEvaluationsFilterBy from 'components/organisms/TeamEvaluationsFilterBy';
import { updateFilterConfig } from 'betterme-components/services/MyTeamEvaluations/actions';
import { selectFilterConfig } from 'betterme-components/services/MyTeamEvaluations/selectors';
import CardSimple from 'common/CardSimple';

const TeamEvaluationsFilterByContainer = ({
    filterConfig,
    messages,
    newStyle,
    updateFilterConfig,
  }) => {
  return (
    <CardSimple className="team-evaluations-filter" newStyle={newStyle}>
      <TeamEvaluationsFilterBy 
        filterConfig={filterConfig}
        messages={messages}
        newStyle={newStyle}
        onApply={updateFilterConfig}
      />
    </CardSimple>
  );
};

const mapDispatchToProps = {
  updateFilterConfig,
};

const mapStateToProps = (state) => ({
  filterConfig: selectFilterConfig(state),
});

TeamEvaluationsFilterByContainer.propTypes = {
  filterConfig: PropTypes.array,
  messages: PropTypes.array,
  newStyle: PropTypes.bool,
  updateFilterConfig: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TeamEvaluationsFilterByContainer);
