// @packages
import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import GenericEmptyState from 'smu-ui-components/GenericEmptyState';

// @app
import { goalsMessages } from 'common/messages';

// @own
import messages from '../messages';

const texts = {
  own: {
    ASSIGNED: {
      title: goalsMessages.GoalsNoAssigned,
      text: goalsMessages.GoalsAskLeader,
    },
    TEAM: {
      title: messages.FeedbackGoalsEmptyStateOwnTeamTitle,
      text: messages.FeedbackGoalsEmptyStateOwnTeamText,
    },
  },
  other: {
    ASSIGNED: {
      title: messages.FeedbackGoalsEmptyStateOtherAssignedTitle,
      text: goalsMessages.GoalsAssignHim,
    }
  },
};

const GoalsEmptyState = ({ intl: { formatMessage }, own, type, userName }) => {
  const view = own ? 'own' : 'other';
  const name = own ? '' : userName;
  const goalsEmptyStateTitle = `${name} ${formatMessage(texts[view][type].title)}`;
  const goalsEmptyStateText = formatMessage(texts[view][type].text);
  const goalsEmptyStateIcon = (own && type === 'TEAM') ? 'listCheck' : 'dartboard'

  return (
    <GenericEmptyState
      className="px-5 py-20 bg-white"
      description={goalsEmptyStateText}
      image={goalsEmptyStateIcon}
      limitTextWidth={false}
      size="large"
      title={goalsEmptyStateTitle}
    />
  );
};

GoalsEmptyState.propTypes = {
  own: PropTypes.bool,
  userName: PropTypes.string,
  type: PropTypes.oneOf(['ASSIGNED', 'TEAM']),
};

GoalsEmptyState.defaultProps = {
  type: 'ASSIGNED',
};

export default injectIntl(GoalsEmptyState);
