import {
  ACTION_REQUIRED,
  ACTION_REQUIRED_FAIL,
  ACTION_REQUIRED_SUCCESS,
} from './actionTypes';

export function actionRequired({ collaborator, leaderType, type, reasonId, successMessage }) {
  return {
    type: ACTION_REQUIRED,
    payload: {
      collaborator,
      leaderType,
      reasonId,
      successMessage,
      type,
    },
  };
}

export function actionRequiredFail(error) {
  return {
    type: ACTION_REQUIRED_FAIL,
    payload: { error },
  };
}

export function actionRequiredSuccess(response) {
  return {
    type: ACTION_REQUIRED_SUCCESS,
    payload: { response }
  };
}
