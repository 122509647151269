// @packages
import React from 'react';
import cn from 'classnames';
import Select from 'smu-ui-components/SelectV2';
import Switch from 'smu-ui-components/Switch';
import PropTypes from 'prop-types';

// @app
import ButtonLink from 'components/molecules/ButtonLink';
import CommentBox from 'components/molecules/CommentBox';
import CompetenciesRateSelector from 'components/organisms/CompetenciesRateSelector';
import EmptyStateBox from 'components/atoms/EmptyStateBox';
import RatedTopics from 'components/organisms/RatedTopics';
import Title from 'components/atoms/Title';

// @own
import './styles.scss';
import Icon from 'smu-ui-components/Icon';

const CompetenciesSectionTemplate = ({
  className,
  competenciesRate: {
    onCompetencySelect,
    competenciesAdded,
    ratedTopicsByCompetency,
    ratings,
    removeCompetency,
    coEvaluatorRatedTopics,
  },
  comptenciesTitle,
  disabledFeedbackFeatures,
  feedbackOverview: {
    feedbackDisabled,
    onFeedbackChange,
    options,
    placeholder,
    value,
  },
  feedbackOverviewText,
  goToFeedback: {
    goToFeedbackLink,
    goToFeedbackIcon,
    goToFeedbackText,
    feedbackClick,
  },
  switch: {
    onSwitchChange,
    switchDisabled,
    switchEnabled,
  },
  coEvaluator: {
    hasCoEvaluator,
    emptyState,
    disclaimer,
    title,
  },
}) => {
  let ratedTopicsByCompetencyWithRate = {};
  ratedTopicsByCompetency.forEach(rate => {
    ratedTopicsByCompetencyWithRate[rate.competency.id] = rate.rateId;
  });

  return (
    <section className={cn('competencies-section-template', className)}>
      <CommentBox
        className="competencies-section-template__competencies"
        title={comptenciesTitle}
      >
        {!disabledFeedbackFeatures && (
          <span className="competencies-section-template__filters">
            <span className="competencies-section-template__filters--switch">
              <Switch
                checked={switchEnabled}
                className="competencies-section-template__filters--switch-on-off"
                disabled={switchDisabled}
                onChange={onSwitchChange}
                size="medium"
                type="betterme"
                idName={'COMPETENCIES_SWITCH'}
              />
              <p className="competencies-section-template__filters--switch-text">
                {feedbackOverviewText}
              </p>
            </span>
            <Select
              className="competencies-section-template__filters--select"
              disabled={feedbackDisabled}
              isClearable={false}
              onChange={(selection) => onFeedbackChange(selection, value)}
              options={options}
              placeholder={placeholder}
              size="small"
              value={value}
            />
          </span>
        )}
        <CompetenciesRateSelector
          className="competencies-section-template__competencies--selector"
          competencies={competenciesAdded}
          onCompetencyAdd={onCompetencySelect}
          onCompetencyRemove={removeCompetency}
          ratedTopics={ratedTopicsByCompetencyWithRate}
          rates={ratings}
          showOverview={switchEnabled}
        />
        {!disabledFeedbackFeatures && (
          <span className="competencies-section-template__competencies--button">
            <ButtonLink
              icon={goToFeedbackIcon}
              label={goToFeedbackText}
              onClick={feedbackClick}
              target="_blank"
              to={goToFeedbackLink}
            />
          </span>
        )}
        {hasCoEvaluator && (
          <div className="competencies-section-template__co-evaluator">
            <div className="competencies-section-template__co-evaluator-header">
              <Title
                black
                className="competencies-section-template__co-evaluator-title"
                level={3}
                size={14}
              >
                {title}
              </Title>
              <span className="competencies-section-template__co-evaluator-disclaimer">
                <Icon
                  className="competencies-section-template__co-evaluator-disclaimer-icon"
                  color='iconPrimary'
                  icon='info'
                  size={16}
                />
                <p className="competencies-section-template__co-evaluator-disclaimer-text">
                  {disclaimer}
                </p>
              </span>
            </div>
            {coEvaluatorRatedTopics.length ? (
              <RatedTopics
                className="competencies-section-template__co-evaluator-rates"
                ratedTopicsList={coEvaluatorRatedTopics}
              />
            ) : (
              <EmptyStateBox
                className="competencies-section-template__co-evaluator-empty"
                text={emptyState}
              />
            )}
          </div>
        )}
      </CommentBox>
    </section>
  );
};

CompetenciesSectionTemplate.propTypes = {
  className: PropTypes.string,
  competenciesRate: PropTypes.arrayOf(PropTypes.shape({
    onCompetencySelect: PropTypes.func,
    competenciesAdded: PropTypes.func,
    ratedTopicsByCompetency: PropTypes.array,
    ratings: PropTypes.array,
    removeCompetency: PropTypes.func,
  })),
  comptenciesTitle: PropTypes.string,
  feedbackOverview: PropTypes.arrayOf(PropTypes.shape({
    feedbackDisabled: PropTypes.bool,
    onFeedbackChange: PropTypes.func,
    options: PropTypes.array,
    placeholder: PropTypes.string,
    value: PropTypes.string,
  })),
  feedbackOverviewText: PropTypes.string,
  goToFeedback: PropTypes.arrayOf(PropTypes.shape({
    goToFeedbackLink: PropTypes.string,
    goToFeedbackIcon: PropTypes.node,
    goToFeedbackText: PropTypes.string,
  })),
  switch: PropTypes.arrayOf(PropTypes.shape({
    onSwitchChange: PropTypes.func,
    switchDisabled: PropTypes.func,
    switchEnabled: PropTypes.func,
  })),
};

export default CompetenciesSectionTemplate;
