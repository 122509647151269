// @packages
import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// @own
import RatingDetails from './RatingDetails';
import CompetencyDetails from './CompetencyDetails';
import { requestRatedTopics as requestRatedTopicsAction } from '../Dashboard/actions';
import * as actions from './actions';
import {
  selectCompetenciesOrder,
  selectCompetenciesRatingList,
  selectFilterDropdownConfig,
  selectRatingFeedbacks,
  selectRatingFilter,
  selectRatingsFilterConfig,
  selectSelectedCompetencyDetails,
} from './selectors';

class FeedbackOverview extends Component {
  constructor() {
    super();

    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleOrderChange = this.handleOrderChange.bind(this);
    this.handleUserAvatarsClick = this.handleUserAvatarsClick.bind(this);
  }

  componentDidMount() {
    const {
      addRatingFilter,
      filterDropdownConfig: { availableFilters },
    } = this.props;
    const defaultFilter = availableFilters[availableFilters.length - 1];

    addRatingFilter(defaultFilter);
  }

  componentWillReceiveProps(nextProps) {
    const {
      addRatingFilter,
      filterDropdownConfig: { availableFilters: currentAvailableFilters }
    } = this.props;
    const {
      filterDropdownConfig: { availableFilters: nextAvailableFilters }
    } = nextProps;

    if (
      currentAvailableFilters
      && currentAvailableFilters !== nextAvailableFilters
    ) {
      addRatingFilter(nextAvailableFilters[0]);
    }
  }

  handleFilterChange(ratingId, isMultiple) {
    const { addRatingFilter, onFilterChange } = this.props;
    addRatingFilter(ratingId, isMultiple);
    onFilterChange(ratingId, isMultiple);
  }

  handleOrderChange() {
    const { changeCompetenciesOrder, competenciesOrder, onOrderChange } = this.props;
    changeCompetenciesOrder();
    onOrderChange(competenciesOrder);
  }

  handleUserAvatarsClick(competencyId) {
    const { selectCompetencyDetails, onUserAvatarsClick } = this.props;
    selectCompetencyDetails(competencyId);
    onUserAvatarsClick(competencyId);
  }

  render() {
    const {
      className,
      competenciesOrder,
      competenciesRatingList,
      filterDropdownConfig,
      ratingFeedbacks,
      ratingFilter,
      ratingsFilterConfig,
      removeRatingFilter,
      selectCompetencyDetails,
      selectedCompetencyDetails,
    } = this.props;

    const competencyDetails = {
      ...ratingFeedbacks,
      addRatingFilter: this.handleFilterChange,
      onBackClick: () => selectCompetencyDetails(),
      ratingFilter,
      ratingsFilterConfig,
      removeRatingFilter,
    };

    const ratingsDetails = {
      competenciesRatingList,
      filterValue: ratingFilter,
      listOrder: competenciesOrder,
      onFilterChange: this.handleFilterChange,
      onOrderChange: this.handleOrderChange,
      onUserAvatarsClick: this.handleUserAvatarsClick,
      filterDropdownConfig,
    };

    return (
      <div className={cn('feedback-overview', className)}>
        {selectedCompetencyDetails ? (
          <CompetencyDetails
            {...competencyDetails}
          />
        ) : (
          <RatingDetails
            {...ratingsDetails}
          />
        )}
      </div>
    );
  }
}

FeedbackOverview.defaultProps = {
  onFilterChange: () => undefined,
  onOrderChange: () => undefined,
  onUserAvatarsClick: () => undefined,
};

FeedbackOverview.propTypes = {
  addRatingFilter: PropTypes.func.isRequired,
  changeCompetenciesOrder: PropTypes.func.isRequired,
  className: PropTypes.string,
  competenciesOrder: PropTypes.oneOf(['asc', 'desc']),
  competenciesRatingList: PropTypes.array,
  filterDropdownConfig: PropTypes.shape({
    availableFilters: PropTypes.array.isRequired,
    ratings: PropTypes.array.isRequired,
  }),
  onFilterChange: PropTypes.func,
  onOrderChange: PropTypes.func,
  onUserAvatarsClick: PropTypes.func,
  ratedTopics: PropTypes.array.isRequired,
  ratingFeedbacks: PropTypes.object,
  ratingFilter: PropTypes.arrayOf(PropTypes.number),
  ratingsFilterConfig: PropTypes.shape({
    availableFilters: PropTypes.array.isRequired,
    ratings: PropTypes.array.isRequired,
  }),
  removeRatingFilter: PropTypes.func.isRequired,
  requestRatedTopics: PropTypes.func.isRequired,
  selectCompetencyDetails: PropTypes.func.isRequired,
  selectedCompetencyDetails: PropTypes.number,
};

const mapStateToProps = (state, ownProps) => {
  return {
    competenciesOrder: selectCompetenciesOrder(state),
    competenciesRatingList: selectCompetenciesRatingList(state, ownProps),
    filterDropdownConfig: selectFilterDropdownConfig(state, ownProps),
    ratingFeedbacks: selectRatingFeedbacks(state, ownProps),
    ratingFilter: selectRatingFilter(state),
    ratingsFilterConfig: selectRatingsFilterConfig(state, ownProps),
    selectedCompetencyDetails: selectSelectedCompetencyDetails(state),
  };
};

const componentActions = {
  ...actions,
  requestRatedTopics: requestRatedTopicsAction,
};

export default connect(mapStateToProps, componentActions)(FeedbackOverview);
