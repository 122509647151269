// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import RootModal from 'smu-app-components/RootModal';

// @app
import GenericModal from 'components/templates/GenericModalTemplate';
import InfoCard from 'components/molecules/InfoCard';
import { SIZE_FIFTY, SIZE_THIRTY_SIX } from 'components/common/constants';
import { actionsMessages } from 'common/messages';
import leadershipRequestsMessages from 'betterme-components/LeadershipRequest/messages';
import { centerModalClass } from 'betterme-components/ConfirmationModal';
import { LEADERSHIP_REQUEST_MODAL } from 'betterme-components/constants/modalTypes';
import { addToMyTeam } from 'betterme-components/services/LeadershipRequest/actions';
import { selectLoading } from 'betterme-components/services/LeadershipRequest/selectors';
import { trackAdminLeadershipAddMeCancel } from 'betterme-components/services/LeadershipAdmin/analytics';

const LeadershipRequestModal = ({
  addToMyTeam,
  closeModal,
  collaborator,
  intl: { formatMessage },
  leaderId,
  leaderType,
  loading,
  messages,
  suggestionId,
  type,
}) => {
  const actionButtons = [
    {
      label: formatMessage(actionsMessages.ActionsCancel),
      onClick: handleCancel,
      variant: 'outline',
    },
    {
      label: formatMessage(actionsMessages.ActionsYesAddMe),
      onClick: () => addToMyTeam({
        collaboratorId: collaborator?.id,
        leaderId,
        leaderType,
        messages,
        type,
        suggestionId,
      }),
    },
  ];
  const collaboratorFullName = `${collaborator.firstName} ${collaborator.lastName}`;

  function handleCancel() {
    closeModal();
    trackAdminLeadershipAddMeCancel(leaderType);
  }

  return (
    <GenericModal
      actionButtons={actionButtons}
      header={
        <InfoCard
          icon="info"
          iconSize={SIZE_FIFTY}
          title={
            formatMessage(leadershipRequestsMessages.LeadershipRequestComponentsActionTitle)
          }
          titleSize={SIZE_THIRTY_SIX}
          message={formatMessage(leadershipRequestsMessages.LeadershipRequestComponentsActionText,
            { fullName: collaboratorFullName },
          )}
        />
      }
      loading={loading}
      success
      onClose={closeModal}
    />
  );
};

LeadershipRequestModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  collaborator: PropTypes.string.isRequired,
  intl: PropTypes.object,
  leaderId: PropTypes.number.isRequired,
  leaderType: PropTypes.string,
  loading: PropTypes.bool,
  messages: PropTypes.object,
};

const modalStyles = {
  width: '610px',
};

const mapStateToProps = state => ({
  loading: selectLoading(state),
});

const mapDispatchToProps = { addToMyTeam };

export default RootModal(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(LeadershipRequestModal)),
  LEADERSHIP_REQUEST_MODAL,
  modalStyles,
  centerModalClass,
);
