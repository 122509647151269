// @packages
import React from 'react';
import Container from 'smu-layout-components/Container';
import GenericErrorState from 'smu-app-components/GenericErrorState';
import PropTypes from 'prop-types';
import QuestionnaireResults from 'smu-app-components/SurveysResult';
import { connect } from 'react-redux';

// @app
import {
  selectCommunityId,
  selectLanguageCode,
  selectUserRoles,
} from 'betterme-components/Authorization/selectors';
import {
  getCommunityConfigValue,
  getCommunityConfigValueBool,
} from 'betterme-components/services/CommunityConfigs/selectors';
import {
  ENABLED_TAKEPART_SURVEY,
  SURVEYS_THRESHOLD_NUMBER,
} from 'betterme-components/services/CommunityConfigs/constants';

function QuestionnaireResultsLayout({
  communityId,
  enabledTakePartSurvey,
  roles,
  thresholdNumber,
  userLanguage,
}) {
  const isPlatformAdmin = !!roles?.find((r) => r?.role === 'OS_PLATFORM_ADMIN');

  if (!enabledTakePartSurvey) {
    return (
      <Container
        className="flex-1 flex flex-col items-center justify-center"
        centered
      >
        <GenericErrorState image="padlock" statusCode={403} />
      </Container>
    );
  }

  return (
    <div className="smu-questionnaire-results-container h-full pb-10">
      <QuestionnaireResults
        defaultBackPath="/questionnaires?tab=CLOSED"
        isPlatformAdmin={isPlatformAdmin}
        orgId={communityId}
        thresholdNumber={thresholdNumber}
        userLanguage={userLanguage}
      />
    </div>
  );
}

QuestionnaireResultsLayout.propTypes = {
  communityId: PropTypes.number,
  enabledTakePartSurvey: PropTypes.bool,
  roles: PropTypes.array,
  thresholdNumber: PropTypes.number,
  userLanguage: PropTypes.string,
};

const mapStateToProps = (state) => ({
  communityId: selectCommunityId(state),
  enabledTakePartSurvey: getCommunityConfigValueBool(
    state,
    ENABLED_TAKEPART_SURVEY
  ),
  roles: selectUserRoles(state),
  thresholdNumber:
    getCommunityConfigValue(state, SURVEYS_THRESHOLD_NUMBER) || 4,
  userLanguage: selectLanguageCode(state),
});

export default connect(mapStateToProps)(QuestionnaireResultsLayout);
