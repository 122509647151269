import {
  LEADERSHIP_REQUEST,
  LEADERSHIP_REQUEST_FAIL,
  LEADERSHIP_REQUEST_SUCCESS,
} from './actionTypes';

export function leadershipRequest(collaborator, requestId, status, reason) {
  return {
    type: LEADERSHIP_REQUEST,
    payload: {
      collaborator,
      reason,
      requestId,
      status,
    },
  };
}

export function leadershipRequestFail(error) {
  return {
    type: LEADERSHIP_REQUEST_FAIL,
    payload: { error },
  };
}

export function leadershipRequestSuccess(response) {
  return {
    type: LEADERSHIP_REQUEST_SUCCESS,
    payload: { response },
  };
}
