// @packages
import React from 'react';
import AvatarMedia from 'smu-ui-components/AvatarMedia';
import Panel from 'smu-ui-components/Panel';
import PropTypes from 'prop-types';
import cn from 'classnames'

// @app
import Title from 'components/atoms/Title';
import UserAvatarList from "common/UserAvatarList";

// @own
import './styles.scss';

const PotentialAssessmentResultsHeader = ({
  className,
  headerData: {
    title,
    date,
    evaluators: {
      firstName,
      lastName,
      list,
      profileImageCode,
    },
    createdByText,
  }
}) => {
  return (
    <Panel className={cn('potential-assessment-results-header', className)}>
      <div>
        <Title
          className="text-black"
          level={1}
          nomargin
          size={20}
        >
          {title}
        </Title>
        <p className='potential-assessment-results-header__subtitle'>
          {date}
        </p>
      </div>
      <div className='potential-assessment-results-header__created-by'>
        <span className='potential-assessment-results-header__created-by--text'>
          {createdByText}
        </span>
        {list?.length > 1 ? (
          <UserAvatarList
            maxDisplay={4}
            showPopup
            size={40}
            users={list}
          />
        ) : (
          <AvatarMedia
            firstName={firstName}
            lastName={lastName}
            profileImageCode={profileImageCode}
            showPopup
            user={list[0]}
            useLink={false}
          />
        )}
      </div>
    </Panel>
  );
};

PotentialAssessmentResultsHeader.propTypes = {
  className: PropTypes.string,
  headerData: PropTypes.objectOf(PropTypes.shape({
    createdByText: PropTypes.string,
    date: PropTypes.string,
    evaluators: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
  })),
};

export default PotentialAssessmentResultsHeader;
