// @packages
import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

// @app
import FeatureDiscovery from 'common/FeatureDiscovery';
import { GOALS_DETAILS } from 'common/FeatureDiscovery/constGroups';
import { UPDATE_PROGRESS, PROGRESS_BAR } from 'common/FeatureDiscovery/constTypes';

// @own
import {
  BAR_CONTINOUS,
  BAR_DISCRETE,
  BAR_PERCENTAGE,
  UPDATE_BUTTON ,
} from '../constants';
import messages from '../messages';

const GoalAddAssessmentFeatDiscovery = ({
  children,
  disabled,
  element,
  intl: { formatMessage },
}) => {
  const mapElementMessage = {
    [UPDATE_BUTTON]: formatMessage(messages.FeedbackGoalsAssessmentDiscoveryButton),
    [BAR_CONTINOUS]: formatMessage(messages.FeedbackGoalsAssessmentDiscoveryBarContinous),
    [BAR_DISCRETE]: formatMessage(messages.FeedbackGoalsAssessmentDiscoveryBarDiscrete),
    [BAR_PERCENTAGE]: formatMessage(messages.FeedbackGoalsAssessmentDiscoveryBarPercentage),
  };

  return disabled ? children : (
    <FeatureDiscovery
      disabled={disabled}
      group={GOALS_DETAILS}
      position="left"
      text={mapElementMessage[element]}
      type={element === UPDATE_BUTTON
        ? UPDATE_PROGRESS
        : PROGRESS_BAR
      }
    >
      {children}
    </FeatureDiscovery>
  );
};

GoalAddAssessmentFeatDiscovery.propTypes = {
  children: PropTypes.node,
  element: PropTypes.oneOf([
    BAR_CONTINOUS,
    BAR_DISCRETE,
    BAR_PERCENTAGE,
    UPDATE_BUTTON,
  ]),
  intl: PropTypes.object.isRequired,
};

export default injectIntl(GoalAddAssessmentFeatDiscovery);
