// @packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';
import Select from 'smu-ui-components/SelectV2';
import Icon from 'smu-ui-components/IconV2';

// @own
import './styles.scss';

const BehaviourQuestion = ({
  behaviourOptions,
  behaviourQuestion,
  behaviourTopic,
  coEvaluatorRate,
  mismatchMessage,
  onChange,
  placeholder,
  previousRate,
  questionId,
  renderOptionLabel,
}) => {
  const [behaviourValue, setBehaviourValue] = useState(previousRate);
  const [showMismatchWarning, setShowMismatchWarning] = useState(false);


  useEffect(() => {
    if (previousRate) {
      setBehaviourValue(previousRate);
    }
  }, [previousRate]);

  useEffect(() => {
    if (behaviourValue && coEvaluatorRate && (behaviourValue !== coEvaluatorRate)) {
      setShowMismatchWarning(true);
    } else {
      setShowMismatchWarning(false);
    }
  }, [behaviourValue, coEvaluatorRate]);

  const handleChange = (value) => {
    setBehaviourValue(value);
    onChange({ questionId, ratingId: value });
  };

  return (
    <div className="border-t mt-6 p-6 flex items-center justify-between">
      <p className="m-0 w-96 font-roboto text-sm text-shark">
        <b>{`${behaviourTopic}: `}</b>{behaviourQuestion}
      </p>
      <Select
        className="behaviour-questions__options w-72 ml-6"
        isClearable={false}
        onChange={handleChange}
        options={behaviourOptions}
        placeholder={placeholder}
        renderOptionLabel={renderOptionLabel}
        value={behaviourValue || previousRate}
      />
      <div className="w-10 h-10 flex items-center justify-center">
        {showMismatchWarning &&
          <Popup
            hideOnScroll
            inverted
            position="bottom center"
            trigger={
              <span>
                <Icon
                  className="mx-2 mb-1 text-orange-300"
                  icon="alert-fill"
                  size="small"
                />
              </span>
            }
          >
            <span className="font-roboto text-xs">
              {mismatchMessage}
            </span>
          </Popup>
        }
      </div>
    </div>
  );
}

BehaviourQuestion.propTypes = {
  behaviourId: PropTypes.number.isRequired,
  behaviourOptions: PropTypes.array.isRequired,
  behaviourQuestion: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default BehaviourQuestion;
