// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import AvatarMedia from 'smu-ui-components/AvatarMedia';
import Icon from 'smu-ui-components/IconV2';
import Segment from 'smu-ui-components/Segment';

// @app
import PersonalDataItemsList from 'components/organisms/PersonalDataItemsList';
import ActionButtons from 'components/molecules/ActionButtons';

// @own
import './styles.scss';

const AddMemberCard = ({
  allowsGlbFeatures,
  className,
  itemsPerRow,
  loading,
  messages: {
    account: accountMessage,
    area: areaMessage,
    office: officeMessage,
    project: projectMessage,
    studio: studioMessage,
  },
  onRemoveClick,
  onAddClick,
  showActionButtons,
  showCloseButton,
  type,
  user,
}) => {
  const items = allowsGlbFeatures
    ? [
        {
          title: projectMessage,
          description: user?.project,
        },
        {
          title: studioMessage,
          description: user?.studio,
        },
        {
          title: areaMessage,
          description: user?.area,
        },
        {
          title: accountMessage,
          description: user?.account,
        },
        {
          title: officeMessage,
          description: user?.office,
        },
      ]
    : [
        {
          title: projectMessage,
          description: user?.project,
        },
        {
          title: areaMessage,
          description: user?.area,
        },
        {
          title: accountMessage,
          description: user?.account,
        },
        {
          title: officeMessage,
          description: user?.office,
        },
      ];

  const actionButtons = [
    {
      label: 'Add me here',
      onClick: () => onAddClick(type),
      size: 'medium',
    },
  ];
  return (
    <Segment
      className={cn('add-member-card', className, {
        'add-member-card--gray' : showActionButtons,
      })}
      loading={loading}
      loadingType="betterme"
    >
      <div className="add-member-card__heading">
        <AvatarMedia
          className="add-member-card__avatar"
          type="medium"
          {...user}
        />
        {showActionButtons && (
          <ActionButtons buttons={actionButtons} className="add-member-card__action-buttons" />
        )}
        {showCloseButton && (
          <Icon
            className="add-member-card__close"
            color="black"
            icon="cancel-fill"
            onClick={onRemoveClick}
            size={20}
          />
        )}
      </div>
      <PersonalDataItemsList
        className="add-member-card__info-items"
        display="row"
        items={items}
        itemsPerRow={itemsPerRow}
        variant="gray"
      />
    </Segment>
  );
};

AddMemberCard.defaultProps = {
  onRemoveClick: () => {},
  onAddClick: () => {},
};

AddMemberCard.propTypes = {
  allowsGlbFeatures: PropTypes.bool,
  className: PropTypes.string,
  itemsPerRow: PropTypes.number,
  loading: PropTypes.bool,
  messages: PropTypes.object,
  onAddClick: PropTypes.func,
  onRemoveClick: PropTypes.func,
  showActionButtons: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  type: PropTypes.string,
  user: PropTypes.object,
};

export default AddMemberCard;
