import {
  GET_TOPICS_RATED,
  GET_TOPICS_RATED_FAIL,
  GET_TOPICS_RATED_SUCCESS,
  SELECT_COMPETENCY,
  SELECT_RATING,
  SORT_TOPICS_RATED,
} from './actionTypes';

const initialState = {
  error: '',
  loading: false,
  order: 'desc',
  ratedTopics: [],
  selectedCompetencyId: undefined,
  selectedRating: undefined,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_TOPICS_RATED:
      return { ...state, loading: true };
    case GET_TOPICS_RATED_FAIL:
      return { ...state, error: payload.error, loading: false };
    case GET_TOPICS_RATED_SUCCESS:
      return { ...state, error: '', loading: false, ratedTopics: payload.response };
    case SELECT_COMPETENCY:
      return { ...state, selectedCompetencyId: payload.competencyId };
    case SELECT_RATING:
      return { ...state, selectedRating: payload.ratingId };
    case SORT_TOPICS_RATED: {
      const newOrder = (state.order === 'asc') ? 'desc' : 'asc';
      return { ...state, order: payload.order || newOrder };
    }
    default:
      return state;
  }
}
