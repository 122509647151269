import { defineMessages } from 'react-intl';

export default defineMessages({
  FeedbackGoalsProfile: {
    id: 'Betterme.Goals.Profile',
    defaultMessage: 'Profile',
  },
  FeedbackGoalsProfileTeamGoals: {
    id: 'Betterme.Goals.ProfileTeamGoals',
    defaultMessage: 'Team\'s Goals',
  },
  FeedbackGoalsMyGoalsTitle: {
    id: 'Betterme.Goals.MyGoalsTitle',
    defaultMessage: 'My Goals',
  },
  FeedbackGoalsTeamGoalsTitle: {
    id: 'Betterme.Goals.TeamGoalsTitle',
    defaultMessage: 'My Team\'s Goals',
  },
  FeedbackProfileGoalsTeamGoalsTitle: {
    id: 'Betterme.ProfileGoals.TeamGoalsTitle',
    defaultMessage: 'Team\'s Current Goals',
  },
  FeedbackProfileGoalsMyGoalsTitle: {
    id: 'Betterme.ProfileGoals.MyGoalsTitle',
    defaultMessage: 'Current Goals',
  },
  FeedbackGoalsGoalsClosedText: {
    id: 'Betterme.Goals.GoalsClosedText',
    defaultMessage: 'Closed',
  },
  FeedbackGoalsGoalsPendingText: {
    id: 'Betterme.Goals.GoalsPendingText',
    defaultMessage: 'Pending',
  },
  FeedbackGoalsColleaguesText: {
    id: 'Betterme.Goals.ColleaguesText',
    defaultMessage: 'Colleagues',
  },
  FeedbackGoalsAssigned: {
    id: 'Betterme.Goals.Assigned',
    defaultMessage: 'Assigned',
  },
  FeedbackGoalsAssignedToMe: {
    id: 'Betterme.Goals.AssignedToMe',
    defaultMessage: 'Assigned to me',
  },
  FeedbackGoalsSharedProgress: {
    id: 'Betterme.Goals.SharedProgress',
    defaultMessage: 'Shared Progress',
  },
  FeedbackGoalsIndividualProgress: {
    id: 'Betterme.Goals.IndividualProgress',
    defaultMessage: 'Individual Progress',
  },
  FeedbackGoalsEvaluatedBy: {
    id: 'Betterme.Goals.EvaluatedBy',
    defaultMessage: 'Evaluated by',
  },
  FeedbackGoalsMyProgress: {
    id: 'Betterme.Goals.MyProgress',
    defaultMessage: 'My progress',
  },
  FeedbackGoalsParticipants: {
    id: 'Betterme.Goals.Participants',
    defaultMessage: 'Assigned to:',
  },
  FeedbackGoalsIndividual: {
    id: 'Betterme.Goals.Individual',
    defaultMessage: 'Individual',
  },
  FeedbackGoalsShared: {
    id: 'Betterme.Goals.Shared',
    defaultMessage: 'Shared',
  },
  FeedbackGoalsBtnAddAssessment: {
    id: 'Betterme.Goals.BtnAddAssessment',
    defaultMessage: 'Update',
  },
  FeedbackGoalsBtnUpdateAssessmentProgress: {
    id: 'Betterme.Goals.BtnUpdateAssessmentProgress',
    defaultMessage: 'Update progress',
  },
  FeedbackGoalsPlaceholderComment: {
    id: 'Betterme.Goals.PlaceholderComment',
    defaultMessage: 'Leave a comment about the current progress',
  },
  FeedbackGoalsBy: {
    id: 'Betterme.Goals.By',
    defaultMessage: 'By',
  },
  FeedbackGoalsScales: {
    id: 'Betterme.Goals.Scales.Scales',
    defaultMessage: 'Scales',
  },
  FeedbackGoalsDetailActionClose: {
    id: 'Betterme.Goals.Close',
    defaultMessage: 'Close',
  },
  FeedbackGoalsDetailModalDeleteTitle: {
    id: 'Betterme.Goals.ModalDelete.Title',
    defaultMessage: 'Are you sure you want to delete the Goal?',
  },
  FeedbackGoalsDetailModalCloseTitle: {
    id: 'Betterme.Goals.ModalClose.Title',
    defaultMessage: 'Are you sure you want to close this Goal?',
  },
  FeedbackGoalsDetailModalCloseWarning: {
    id: 'Betterme.Goals.ModalClose.Warning',
    defaultMessage: 'Your are closing the Goal with a different score than its progress',
  },
  FeedbackGoalsDetailModalCloseCheckbox: {
    id: 'Betterme.Goals.ModalClose.Checkbox',
    defaultMessage: 'This Goal doesn\'t apply any more',
  },
  FeedbackGoalsDetailModalCloseAccept: {
    id: 'Betterme.Goals.ModalClose.Accept',
    defaultMessage: 'Close Goal',
  },
  FeedbackGoalsDetailModalCloseDropdown: {
    id: 'Betterme.Goals.ModalClose.Dropdown',
    defaultMessage: 'Choose score for the Goal',
  },
  FeedbackGoalsDetailModalCloseComment: {
    id: 'Betterme.Goals.ModalClose.Comment',
    defaultMessage: 'Leave a comment on the Goal',
  },
  FeedbackGoalsDetailModalCloseNumberSelector: {
    id: 'Betterme.Goals.ModalClose.NumberSelector',
    defaultMessage: 'Final score',
  },
  FeedbackGoalsDetailSuccessModalCloseMessage: {
    id: 'Betterme.Goals.SuccessModalClose.Message',
    defaultMessage: 'This Goal was successfully closed!',
  },
  FeedbackGoalsDetailSuccessModalMyGoalSendValidateMessage: {
    id: 'Betterme.Goals.SuccessModalSendValidateMyGoal.Message',
    defaultMessage: 'This Goal was successfully created and sent for validation!',
  },
  FeedbackGoalsDetailSuccessModalSendValidateMessage: {
    id: 'Betterme.Goals.SuccessModalSendValidate.Message',
    defaultMessage: 'This Goal was successfully created!',
  },
  FeedbackGoalsDetailSuccessModalCloseFooterMessage: {
    id: 'Betterme.Goals.SuccessModalClose.FooterMessage',
    defaultMessage: 'Keep your team motivated by assigning new Goals',
  },
  FeedbackGoalsDetailSummaryScoreMessage: {
    id: 'Betterme.Goals.Summary.ScoreMessage',
    defaultMessage: 'Rate',
  },
  FeedbackGoalsFiltersTitle: {
    id: 'Betterme.Goals.Filters.Title',
    defaultMessage: 'Set up yours filters',
  },
  FeedbackGoalsFiltersPlaceholderText: {
    id: 'Betterme.Goals.Filters.PlaceholderText',
    defaultMessage: 'Search by Goal title',
  },
  FeedbackGoalsFiltersPlaceholderAssigned: {
    id: 'Betterme.Goals.Filters.PlaceholderAssigned',
    defaultMessage: 'Select assigned',
  },
  FeedbackGoalsFiltersPlaceholderLeader: {
    id: 'Betterme.Goals.Filters.PlaceholderLeader',
    defaultMessage: 'Select leader',
  },
  FeedbackGoalsFiltersPlaceholderStatus: {
    id: 'Betterme.Goals.Filters.PlaceholderStatus',
    defaultMessage: 'Select status',
  },
  FeedbackGoalsDetailModalDetailMainTitle: {
    id: 'Betterme.Goals.ModalDetail.MainTitle',
    defaultMessage: 'Create a Goal for my team',
  },
  FeedbackGoalsDetailModalDetailMainSelfTitle: {
    id: 'Betterme.Goals.ModalDetail.MainSelfTitle',
    defaultMessage: 'Create a Goal',
  },
  FeedbackGoalsDetailModalDetailAssigneesTitle: {
    id: 'Betterme.Goals.ModalDetail.AssigneesTitle',
    defaultMessage: 'Assign to one or more participants',
  },
  FeedbackGoalsDetailModalDetailAssigneesSelfTitle: {
    id: 'Betterme.Goals.ModalDetail.AssigneesSelfTitle',
    defaultMessage: 'Assign to',
  },
  FeedbackGoalsDetailModalDetailAssigneesSearch: {
    id: 'Betterme.Goals.ModalDetail.AssigneesSearch',
    defaultMessage: 'Find colleagues by their name or email...',
  },
  FeedbackGoalsDetailModalDetailSharedToggle: {
    id: 'Betterme.Goals.ModalDetail.SharedToggle',
    defaultMessage: 'Activate shared Goal',
  },
  FeedbackGoalsDetailModalDetailGoalOrgObjectives: {
    id: 'Betterme.Goals.ModalDetail.OrgObjectives',
    defaultMessage: 'Choose the organizational objectives that you want to assign the personal Goal to',
  },
  FeedbackGoalsDetailModalDetailEvaluationPeriodTitle: {
    id: 'Betterme.Goals.ModalDetail.EvaluationPeriodTitle',
    defaultMessage: 'Evaluation period',
  },
  FeedbackGoalsDetailModalDetailDescriptionPlaceholder: {
    id: 'Betterme.Goals.ModalDetail.DescriptionPlaceholder',
    defaultMessage: 'Give your colleague a complete and clear description about this Goal',
  },
  FeedbackGoalsDetailModalDetailDescriptionSelfPlaceholder: {
    id: 'Betterme.Goals.ModalDetail.DescriptionSelfPlaceholder',
    defaultMessage: 'Give a complete and clear description about this Goal',
  },
  FeedbackGoalsDetailModalDetailWeightPlaceholder: {
    id: 'Betterme.Goals.ModalDetail.WeightPlaceholder',
    defaultMessage: '00%',
  },
  FeedbackGoalsDetailModalDetailScaleTitle: {
    id: 'Betterme.Goals.ModalDetail.ScaleTitle',
    defaultMessage: 'Goal scale',
  },
  FeedbackGoalsDetailModalDetailBtnApprove: {
    id: 'Betterme.Goals.ModalDetail.BtnApprove',
    defaultMessage: 'Approve',
  },
  FeedbackGoalsDetailModalDetailBtnValidate: {
    id: 'Betterme.Goals.ModalDetail.BtnValidate',
    defaultMessage: 'Send for approval',
  },
  FeedbackGoalsScalesDetailsLabel: {
    id: 'Betterme.Goals.Scales.DetailsLabel',
    defaultMessage: 'Leave a description',
  },
  FeedbackGoalsScalesAddScaleButtonLabel: {
    id: 'Betterme.Goals.Scales.AddScaleButtonLabel',
    defaultMessage: '+ Add more values...',
  },
  FeedbackGoalsScalesValueLabel: {
    id: 'Betterme.Goals.Scales.ValueLabel',
    defaultMessage: '50',
  },
  FeedbackGoalsScalesDescriptionLabel: {
    id: 'Betterme.Goals.Scales.DescriptionLabel',
    defaultMessage: 'A string value',
  },
  FeedbackGoalsScalesValidateRepeat: {
    id: 'Betterme.Goals.Scales.ValidateRepeat',
    defaultMessage: 'Values can not repeat',
  },
  FeedbackGoalsScalesValidateEmpty: {
    id: 'Betterme.Goals.Scales.ValidateEmpty',
    defaultMessage: 'Field can not be empty',
  },
  FeedbackGoalsScalesValidateIncorrectValue: {
    id: 'Betterme.Goals.Scales.ValidateIncorrectValue',
    defaultMessage: 'Incorrect value',
  },
  FeedbackGoalsDuplicate: {
    id: 'Betterme.Goals.Duplicate',
    defaultMessage: 'Duplicate',
  },
  FeedbackGoalWeightWarningModalTitle: {
    id: 'Betterme.Goals.WeightWarningModal.Title',
    defaultMessage: 'Goal weight',
  },
  FeedbackGoalWeightWarningModalDescription: {
    id: 'Betterme.Goals.WeightWarningModal.Description',
    defaultMessage: 'The Goals total weight for the following users can\'t be higher than 100%',
  },
  FeedbackGoalWeightWarningModalConfirmation: {
    id: 'Betterme.Goals.WeightWarningModal.Confirmation',
    defaultMessage: 'Do you want to create the Goal anyways?',
  },
  FeedbackGoalWeightWarningModalRestrictiveAcceptButton: {
    id: 'Betterme.Goals.WeightWarningModal.Restrictive.AcceptButton',
    defaultMessage: 'Accept',
  },
  FeedbackGoalWeightWarningModalRestrictiveDescription: {
    id: 'Betterme.Goals.WeightWarningModal.Restrictive.Description',
    defaultMessage: 'The Goals total weight for the following users can\'t be higher than 100%. Please review their weight.',
  },
  FeedbackGoalsEmptyStateOwnTeamTitle: {
    id: 'Betterme.Goals.EmptyState.Team.ReceivedTitle',
    defaultMessage: 'You haven\'t assigned any Goals to your team for this period yet',
  },
  FeedbackGoalsEmptyStateOwnTeamText: {
    id: 'Betterme.Goals.EmptyState.Team.ReceivedText',
    defaultMessage: 'Assign them Goals on BetterMe',
  },
  FeedbackGoalsEmptyStateOtherAssignedTitle: {
    id: 'Betterme.Goals.EmptyState.Other.ReceivedTitle',
    defaultMessage: 'hasn\'t been assigned Goals for this period yet',
  },
  FeedbackGoalsAssessmentDiscoveryButton: {
    id: 'Betterme.Goals.AssessmentDiscovery.Button',
    defaultMessage: 'Click on this button to update the Goal progress.',
  },
  FeedbackGoalsAssessmentDiscoveryBarContinous: {
    id: 'Betterme.Goals.AssessmentDiscovery.BarContinous',
    defaultMessage: 'Slide over the bar to set a progress value.',
  },
  FeedbackGoalsAssessmentDiscoveryBarDiscrete: {
    id: 'Betterme.Goals.AssessmentDiscovery.BarDiscrete',
    defaultMessage: 'Select a progress value.',
  },
  FeedbackGoalsAssessmentDiscoveryBarPercentage: {
    id: 'Betterme.Goals.AssessmentDiscovery.BarPercentage',
    defaultMessage: 'Slide over the bar to set a percentage of progress.',
  },
  FeedbackGoalsSuccessApprovalMessage: {
    id: 'Betterme.Goals.SuccessApproval.Message',
    defaultMessage: 'The Goal was approved successfully',
  },
  FeedbackGoalsSuccessDisapprovalMessage: {
    id: 'Betterme.Goals.SuccessDisapproval.Message',
    defaultMessage: 'The Goal was deleted successfully',
  },
  FeedbackGoalsExpired: {
    id: 'Betterme.Goals.Expired',
    defaultMessage: 'Expired',
  },
  GoalsCreateToastError: {
    id: 'Betterme.GoalsCreate.Toast.Error',
    defaultMessage: 'couldn\'t be added',
  },
  GoalsCreateWeightTooltipContent: {
    id: 'Betterme.GoalsCreate.WeightTooltip.Content',
    defaultMessage: 'The weight is useful for defining each goal\'s relative priority. Generally, the total weight of a collaborator\'s goals must be 100%. If it\'s a duplicated or shared goal, the maximum weight available is the same as the collaborator\'s with the lowest weight available. All goals must have a weight assigned.',
  },
  GoalsCreateWeightSubtitle: {
    id: 'Betterme.GoalsCreate.Weight.Subtitle',
    defaultMessage: '**{value}%** available for the creation of this goal.',
  },
  GoalsCreateTimeTitle: {
    id: 'Betterme.GoalsCreate.Time.Title',
    defaultMessage: 'Scope',
  },
  GoalsCreateTimeStartDate: {
    id: 'Betterme.GoalsCreate.Time.StartDate',
    defaultMessage: 'Start date',
  },
  GoalsEmptyStateEvaluator: {
    id: 'Betterme.EmptyState.Evaluator',
    defaultMessage: 'Has no evaluator anymore',
  },
  GoalsEmptyStateToastMessageMain: {
    id: 'Betterme.EmptyState.ToastMessage.Main',
    defaultMessage: 'Pending goals can be accessed by the Main Leader',
  },
  GoalsEmptyStateToastMessageCareer: {
    id: 'Betterme.EmptyState.ToastMessage.Career2',
    defaultMessage: 'Pending goals can be accessed by the Career Mentor',
  },
  GoalModalDetailAsCareerMentor: {
    id: 'Betterme.Goals.ModalDetail.AsCareerMentor',
    defaultMessage: 'As Career Mentor'
  },
  GoalModalDetailAsExperienceLeader: {
    id: 'Betterme.Goals.ModalDetail.AsExperienceLeader',
    defaultMessage: 'As Experience Leader'
  },
  GoalModalDetailAsMainLeader: {
    id: 'Betterme.Goals.ModalDetail.AsMainLeader',
    defaultMessage: 'As Main Leader'
  },
  GoalModalDetailAsSecondaryLeader: {
    id: 'Betterme.Goals.ModalDetail.AssecondaryLeader',
    defaultMessage: 'As Secondary Leader'
  },
  GoalModalDetailWillCreateThisGoal: {
    id: 'Betterme.Goals.ModalDetail.WillCreateThisGoal',
    defaultMessage: 'I will create this goal'
  },
  FeedbackGoalsDetailModalDetailOneEvaluatorTitle: {
    id: 'Betterme.Goals.ModalDetail.OneEvaluatorTitle',
    defaultMessage: 'Evaluator',
  },
  FeedbackGoalsDetailModalDetailEvaluatorTitle: {
    id: 'Betterme.Goals.ModalDetail.EvaluatorTitle',
    defaultMessage: 'Choose evaluator',
  },
  FeedbackGoalsDetailModalDetailEvaluatorPlaceholder: {
    id: 'Betterme.Goals.ModalDetail.EvaluatorPlaceholder',
    defaultMessage: 'Click here to see your options',
  },
  GoalsEmptyStateToastMessageIsNotEvaluator: {
    id: 'Betterme.EmptyState.ToastMessage.IsNotEvaluator',
    defaultMessage: 'Pending goals can be accessed by the Leader selected as approver',
  },
  GoalsCreateToastMessage: {
    id: 'Betterme.Goals.GoalsCreateToastMessage',
    defaultMessage: 'Your goal was just sent for validation',
  },
});
