import { takeLatest, call, put } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import {
  getAssignedGoalDetail,
  getPeriods,
} from '../../api';
import {
  GET_GOALS_PERIOD,
  GET_GOAL_DETAIL,
} from './actionTypes';
import {
  getGoalsPeriodFail,
  getGoalsPeriodSuccess,
  getGoalDetailFail,
  getGoalDetailSuccess,
} from './actions';

function* getGoalsPeriodWorker() {
  try {
    const params = {
      available: true,
    };
    const response = yield call(getPeriods, params);
    yield put(getGoalsPeriodSuccess(response[0]));
  } catch (error) {
    yield put(getGoalsPeriodFail(error));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

function* getGoalDetailWorker({ payload: { id, userId } }) {
  try {
    const data = {
      userId
    };
    const response = yield call(getAssignedGoalDetail, id, data);
    yield put(getGoalDetailSuccess(response));
  } catch (error) {
    yield put(getGoalDetailFail(error));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

export default function* goalsWatcher() {
  yield takeLatest(GET_GOALS_PERIOD, getGoalsPeriodWorker);
  yield takeLatest(GET_GOAL_DETAIL, getGoalDetailWorker);
}
