import {
  CREATE_REPORT_BUG,
  CREATE_REPORT_BUG_FAIL,
  CREATE_REPORT_BUG_SUCCESS,
} from './actionTypes';

export function createReportBug({
  app,
  appLanguage,
  browserInfo,
  community,
  email,
  id,
  message,
  osInfo,
  user,
}) {
  return {
    type: CREATE_REPORT_BUG,
    payload: {
      app,
      appLanguage,
      browserInfo,
      community,
      email,
      id,
      message,
      osInfo,
      user,
    },
  };
}

export function createReportBugFail(error) {
  return {
    type: CREATE_REPORT_BUG_FAIL,
    payload: { error },
  };
}

export function createReportBugSuccess() {
  return {
    type: CREATE_REPORT_BUG_SUCCESS,
  };
}
