// @packages
import React from 'react';
import Image from 'smu-ui-components/Image';

// @own
import './styles.scss';

const StarmeUpValuesBox = ({
  backgroundColor,
  imageCode,
  name,
  percentageStars,
  totalStars,
}) => {
  const imageStyles = { backgroundColor };

  return (
    <div className="starmeup-values-box">
      <div className="starmeup-values-box__image-wrapper">
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <circle cx="50%" cy="50%" r="50" fill="white" />
        </svg>
        <Image
          className="starmeup-values-box__image"
          code={imageCode}
          width={36}
          height={36}
          style={imageStyles}
        />
      </div>
      <div className="starmeup-values-box__info">
        <div className="starmeup-values-box__numbers">
          <span className="starmeup-values-box__count">
            {totalStars}
          </span>
          <span className="starmeup-values-box__percentage">
            {`(${percentageStars}%)`}
          </span>
        </div>
        <p className="starmeup-values-box__name">{name}</p>
      </div>
    </div>
  );
};

export default StarmeUpValuesBox;
