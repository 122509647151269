// @app
import { adminApiInstance } from 'betterme-components/utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';

export function patchLeadershipAdminLeaders(params) {
  const { communityId, memberId , data } = params;
  return adminApiInstance
    .patch(`/v2/community/${communityId}/members/${memberId}/leaders`, data)
    .then(({ data }) => data)
    .catch(errorHandler);
}
