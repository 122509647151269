// @own
import {
  GET_SELF_EVALUATION_LIST,
  GET_SELF_EVALUATION_LIST_FAIL,
  GET_SELF_EVALUATION_LIST_SUCCESS,
  RESET_SELF_EVALUATION_LIST,
} from './actionTypes';

const initialState = {
  selfEvaluationList: [],
  selfEvaluationListLoading: false,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_SELF_EVALUATION_LIST:
      return {
        ...state,
        selfEvaluationListLoading: true,
      };

    case GET_SELF_EVALUATION_LIST_SUCCESS:
      return {
        ...state,
        selfEvaluationList: payload,
        selfEvaluationListLoading: false,
      };

    case GET_SELF_EVALUATION_LIST_FAIL:
      return {
        ...state,
        selfEvaluationList: payload.error,
        selfEvaluationListLoading: false,
      };

    case RESET_SELF_EVALUATION_LIST:
      return {
        ...state,
        selfEvaluationList: [],
      };

    default:
      return state;
  }
}