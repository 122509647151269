// @own
import {
  SET_PERFORMANCE_RETROSPECTIVE,
  SET_PERFORMANCE_RETROSPECTIVE_FAIL,
  SET_PERFORMANCE_RETROSPECTIVE_SUCCESS,
  RESET_PERFORMANCE_RETROSPECTIVE,
  RESET_PERFORMANCE_RETROSPECTIVE_BEHAVIOURS,
  SET_PERFORMANCE_RETROSPECTIVE_BEHAVIOURS,
} from './actionTypes';

const initialState = {
  performanceRetrospectiveData: {},
  performanceRetrospectiveDataLoading: false,
  performanceRetrospectiveDataError: null,
  performanceRetrospectiveBehaviours: [],
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {

    case SET_PERFORMANCE_RETROSPECTIVE:
      return {
        ...state,
        performanceRetrospectiveData: { ...state.performanceRetrospectiveData, ...payload },
        performanceRetrospectiveDataLoading: true,
      };

    case SET_PERFORMANCE_RETROSPECTIVE_FAIL:
      return {
        ...state,
        performanceRetrospectiveDataError: payload,
        performanceRetrospectiveDataLoading: false,
      };

    case SET_PERFORMANCE_RETROSPECTIVE_SUCCESS:
      return {
        ...state,
        performanceRetrospectiveData: payload,
        performanceRetrospectiveDataLoading: false,
      };

    case RESET_PERFORMANCE_RETROSPECTIVE:
      return {
        ...state,
        performanceRetrospectiveData: {},
      };

    case SET_PERFORMANCE_RETROSPECTIVE_BEHAVIOURS:
      return {
        ...state,
        performanceRetrospectiveBehaviours: payload,
      };

    case RESET_PERFORMANCE_RETROSPECTIVE_BEHAVIOURS:
      return {
        ...state,
        performanceRetrospectiveBehaviours: [],
      };

    default:
      return state;
  }
}
