// @packages
import { get } from 'lodash';

const reducerDomain = 'createSaveEvaluation';

export const selectCreateSaveEvaluation = (state) =>
  get(state, [reducerDomain, 'createSaveEvaluation']);

export const selectCreateSaveEvaluationLoading = (state) =>
  get(state, [reducerDomain, 'createSaveEvaluationLoading']);
