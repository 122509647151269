import {
  GET_USER,
  GET_USER_FAIL,
  GET_USER_SUCCESS,
  RESET_USER,
} from './actionTypes';

export function getUser({ identification }) {
  return {
    type: GET_USER,
    payload: { identification },
  }
}

export function getUserFail(error) {
  return {
    type: GET_USER_FAIL,
    payload: { error },
  }
}

export function getUserSuccess(response) {
  return {
    type: GET_USER_SUCCESS,
    payload: { response },
  }
}

export function resetUser() {
  return {
    type: RESET_USER,
  }
}
