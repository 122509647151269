// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Segment from 'smu-ui-components/Segment';

// @app
import Title from 'components/atoms/Title';

// @own
import './styles.scss';

const SectionTitle = ({
  children,
  className,
  description,
  loading,
  loadingType,
  noborder,
  noPaddingTop,
  title,
}) => (
  <Segment
    className={cn('section-title', className, {
      'section-title--noborder': noborder,
      'section-title--nopaddingtop': noPaddingTop,
    })}
    loading={loading}
    loadingType={loadingType}
  >
    <div className="section-title__header">
      <Title
        className="section-title__title"
        black
        level={1}
        nomargin
      >
        {title}
      </Title>
      <div className="section-title__description">
        {description}
      </div>
    </div>
    <div className="section-title__content">
      {children}
    </div>
  </Segment>
);

SectionTitle.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  description: PropTypes.string,
  loading: PropTypes.bool,
  loadingType: PropTypes.string,
  noborder: PropTypes.bool,
  noPaddingTop: PropTypes.bool,
  title: PropTypes.string,
};

export default SectionTitle;
