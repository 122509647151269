// @packages
import React from 'react';
import IconV2 from 'smu-ui-components/IconV2';
import PropTypes from 'prop-types';
import UserCarousel from 'smu-ui-components/UserCarousel';
import cn from 'classnames';
import { isEmpty } from 'lodash';

// @own
import QuestionText from '../QuestionText';
import './styles.scss';

function Header({
  className,
  collaborator,
  collaboratortitle,
  engagement: {
    color,
    description,
    icon,
    text,
  },
  hasCollaborator,
}) {
  return (
    <div className={cn('engagement-form-header', className)}>
      {hasCollaborator && (
        <div className="engagement-form-header__collaborator">
          {collaboratortitle && (
            <QuestionText
              className="engagement-form-header__collaborator-title"
              text={collaboratortitle}
              type="title"
            />
          )}
          {!isEmpty(collaborator) && (
            <UserCarousel
              className="engagement-form-header__collaborator-carousel"
              disableRemove
              userList={[collaborator]}
            />
          )}
        </div>
      )}
      <div className="engagement-form-header__engagement">
        <div className="engagement-form-header__engagement-indicator">
          <span
            className={cn("engagement-form-header__engagement-icon-wrapper", {
              [`engagement-form-header__engagement-icon-wrapper--${color}`]: color,
            })}
          >
            <IconV2
              className="engagement-form-header__engagement-icon"
              color="white"
              icon={icon}
              size="medium"
            />
          </span>
          <div className={cn('engagement-form-header__engagement-text', {
            [`engagement-form-header__engagement-text--${color}`]: color,
          })}>
            {text}
          </div>
        </div>
        <QuestionText text={description} type="text"/>
      </div>
    </div>
  );
}

Header.defaultProps = {
  hasCollaborator: true,
}

Header.propTypes = {
  className: PropTypes.string,
  collaborator: PropTypes.object,
  collaboratortitle: PropTypes.string,
  engagement: PropTypes.object.isRequired,
  hasCollaborator: PropTypes.bool,
}

export default Header;
