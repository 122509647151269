// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import Link from 'smu-ui-components/Link';

// @app
import CardSimple from 'common/CardSimple';
import {
  actionsMessages,
  commonMessages,
  evaluationsMessages,
  sectionsMessages,
} from 'common/messages';
import formatScore from 'utils/formatScore';
import { formatDate } from 'utils/formatDate';
import {
  EVALUATION,
  NH_EVALUATION,
  NH_SELF_EVALUATION,
  PERFORMANCE_RETROSPECTIVE,
  PERIOD_EVALUATION,
  SELF_EVALUATION,
  SELF_RETROSPECTIVE,
} from 'betterme-components/Evaluations/constants';


const DashboardLastEvaluation = ({
  detailsPath,
  intl: { formatMessage },
  lastEvaluationDetail,
  loading,
  messagesEmptyStates,
}) => {
  const TYPE_EVALUATION_TEXT = {
    [EVALUATION]: formatMessage(evaluationsMessages.EvaluationsEvaluation),
    [NH_EVALUATION]: formatMessage(evaluationsMessages.EvaluationsNHEvaluation),
    [NH_SELF_EVALUATION]: formatMessage(evaluationsMessages.EvaluationsNHSelfEvaluation),
    [PERFORMANCE_RETROSPECTIVE]: formatMessage(evaluationsMessages.PerformanceRetrospective),
    [PERIOD_EVALUATION]: formatMessage(evaluationsMessages.EvaluationsEvaluation),
    [SELF_EVALUATION]: formatMessage(evaluationsMessages.EvaluationsSelfEvaluation),
    [SELF_RETROSPECTIVE]: formatMessage(evaluationsMessages.SelfRetrospective),
  };
  const typeEvaluation = TYPE_EVALUATION_TEXT[lastEvaluationDetail?.type];

  return (
    <CardSimple
      className="inline-block !my-[10px]"
      loading={loading}
      subtitle={formatMessage(evaluationsMessages.EvaluationsLastEvaluation)}
      title={formatMessage(evaluationsMessages.EvaluationsLastEvaluation)}
    >
      {!isEmpty(lastEvaluationDetail)
        ? (
          <div className="pt-5 px-5 pb-[15px]">
            <ul className="flex flex-row flex-wrap list-none m-0 p-0">
              <li className="font-roboto text-sm mb-5 [&>span]:$text-secondary? [&>span]:block flex-[1_50%]">
                <span>{formatMessage(evaluationsMessages.EvaluationsType)}</span>
                <strong>{typeEvaluation}</strong>
              </li>
              <li className="font-roboto text-sm mb-5 [&>span]:$text-secondary? [&>span]:block flex-[1_50%]">
                <span>{formatMessage(evaluationsMessages.EvaluationsSubmittedDate)}</span>
                <strong>{formatDate(lastEvaluationDetail?.submissionTime)}</strong>
              </li>
              <li className="font-roboto text-sm mb-5 [&>span]:$text-secondary? [&>span]:block flex-[1_50%]">
                <span>{formatMessage(sectionsMessages.SectionsTitle)}</span>
                <strong>{lastEvaluationDetail?.title}</strong>
              </li>
              <li className="font-roboto text-sm mb-5 [&>span]:$text-secondary? [&>span]:block flex-[1_50%]">
                <span>{formatMessage(commonMessages.Score)}</span>
                <strong>{formatScore(lastEvaluationDetail?.score)}</strong>
              </li>
            </ul>
            <div className="text-right">
              <Link
                color="black"
                size="small"
                to={detailsPath.evaluationDetail}
                type="button"
              >
                {formatMessage(actionsMessages.ActionsViewMore)}
              </Link>
            </div>
          </div>
        )
        : (
          <div className="text-center py-[92px] px-[37px]">
            <strong className="block font-[font-header?] text-xs mb-[2px] uppercase">
              {messagesEmptyStates.main}
            </strong>
          </div>
        )
      }
    </CardSimple>
  );
};

DashboardLastEvaluation.propTypes = {
  detailsPath: PropTypes.object.isRequired,
  intl: PropTypes.object,
  lastEvaluationDetail: PropTypes.object,
  loading: PropTypes.bool,
  messagesEmptyStates: PropTypes.object.isRequired,
};

export default injectIntl(DashboardLastEvaluation);
