import { trackEvent } from 'smu-utils/gtm';
import { REQUEST_FEEDBACK } from '../SendRequestFeedback/analytics';
const SUGGESTED_USER = 'Suggested_Users';

export const trackSuggestedUserProfileClick = (position) => {
  trackEvent({
    category: SUGGESTED_USER,
    action: 'Select_User_Profile',
    label: `${position}`
  });
};

export const trackSuggestedUserFeedbackClick = (position) => {
  trackEvent({
    category: SUGGESTED_USER,
    action: 'Select_User_Feedback',
    label: `${position}`
  });
};

export const trackListClick = () => {
  trackEvent({
    category: REQUEST_FEEDBACK,
    action: 'List_Click',
  });
};
