// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { push } from 'react-router-redux';
import RootModal from 'smu-app-components/RootModal';

// @app
import GenericModal from 'components/templates/GenericModalTemplate';
import InfoCard from 'components/molecules/InfoCard';
import { actionsMessages } from 'common/messages';
import { centerModalClass } from 'betterme-components/ConfirmationModal';
import { CHANGE_LEADER_CONFIRMATION_MODAL } from 'betterme-components/constants/modalTypes';
import { selectComment, selectLoadingUpdateUserLeader } from 'betterme-components/services/ChangeLeaderRequest/selectors';
import { getLeadershipAdminLocation } from 'betterme-components/routes/urls';
import { resetChangeLeaderRequestState } from 'betterme-components/services/ChangeLeaderRequest/actions';
import { getLocalStoredItem, removeLocalStoredItem } from 'utils/localStorage';

// @own
import messages from './messages';
import {
  ACTION_TYPE_REQUIRED,
  REMOVE_LEADER_SUGGEST_LEADER,
  REMOVE_MYSELF_AS_MAIN_LEADER,
} from './constants';

const ChangeLeaderConfirmationModal = ({
  allowsGlbFeatures,
  closeModal,
  collaborator,
  comment,
  goToLeadershipAdmin,
  intl: { formatMessage },
  isAdd,
  isFocal,
  isMainLeader,
  leader,
  loading,
  onAccept,
  onCancel,
  selectedLeader,
  type,
}) => {
  const actionTypeRequired = getLocalStoredItem(ACTION_TYPE_REQUIRED);
  const isMainRequest = actionTypeRequired?.reasonType === REMOVE_MYSELF_AS_MAIN_LEADER;
  const collaboratorFullName = `${collaborator?.firstName} ${collaborator?.lastName}`;
  const careerLeaderMessage = (isMainLeader || isMainRequest)
    ? formatMessage(messages.LeadershipAdminCareerLeaderModalMessage, {
      collaborator: collaboratorFullName,
      leader,
    })
    : formatMessage(messages.LeadershipAdminAccountLeaderModalMessage, {
      collaborator: collaboratorFullName,
      leader,
    });
  const mainLeaderMessage = (isMainLeader || isMainRequest)
    ? formatMessage(messages.LeadershipAdminMainLeaderModalMessage, {
      collaborator: collaboratorFullName,
      leader,
    })
    : formatMessage(messages.LeadershipAdminSecondaryLeaderModalMessage, {
      collaborator: collaboratorFullName,
      leader,
    });
  const newLeaderByTypeMessage = allowsGlbFeatures ? careerLeaderMessage : mainLeaderMessage;

  function handleCancel() {
    closeModal();
    if (!isFocal) {
      onCancel(type);
      if (actionTypeRequired?.actionType === REMOVE_LEADER_SUGGEST_LEADER) {
        goToLeadershipAdmin(collaborator?.identification);
        removeLocalStoredItem(ACTION_TYPE_REQUIRED);
      };
    }
  };

  const focalSection = (isFocal || isAdd);

  const acceptButtonText = focalSection
    ? formatMessage(messages.LeadershipAdminSendItModalMessage)
    : formatMessage(actionsMessages.ActionsSendRequest);

  const infoCardMessage = isAdd
    ? formatMessage(messages.LeadershipAdminSubstitutionModalMessage)
    : isFocal
    ? formatMessage(messages.LeadershipAdminSubstitutionModalReplaceDescription)
    : newLeaderByTypeMessage;

  const infoCardTitle = isAdd
    ? formatMessage(messages.LeadershipAdminSubstitutionTitleModalMessage)
    : formatMessage(messages.LeadershipAdminLeaderSubstitution);

  const actionButton = [
    {
      label: formatMessage(actionsMessages.ActionsCancel),
      onClick: handleCancel,
      variant: 'outline',
    },
    {
      label: acceptButtonText,
      onClick: () => {
        (!isAdd || !isFocal)
          ? onAccept(type, selectedLeader, comment, actionTypeRequired)
          : onAccept();
        closeModal();
      },
    },
  ];

  return (
    <GenericModal
      actionButtons={actionButton}
      header={
        <InfoCard
          icon={focalSection  ? "info" : "substitution"}
          message={infoCardMessage}
          title={infoCardTitle}
        />
      }
      loading={loading}
      success
      onClose={handleCancel}
    />
  )
};

ChangeLeaderConfirmationModal.defaultProps = {
  onCancel: () => {},
};

ChangeLeaderConfirmationModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  collaborator: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
  intl: PropTypes.object,
  isMainLeader: PropTypes.bool.isRequired,
  leader: PropTypes.string,
  loading: PropTypes.bool,
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  selectedLeader: PropTypes.object,
  type: PropTypes.string.isRequired,
  user: PropTypes.object,
};

const modalStyles = {
  width: '610px',
};

const mapStateToProps = state => ({
  comment: selectComment(state),
  loading: selectLoadingUpdateUserLeader(state)
});

const mapDispatchToProps = {
  goToLeadershipAdmin: (identification) => push(getLeadershipAdminLocation(identification)),
  resetChangeLeaderRequestState,
};

export default RootModal(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(ChangeLeaderConfirmationModal)),
  CHANGE_LEADER_CONFIRMATION_MODAL,
  modalStyles,
  centerModalClass,
);
