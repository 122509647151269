import {
  GET_AWAITING_RESULT_COUNTER,
  GET_AWAITING_RESULT_COUNTER_FAIL,
  GET_AWAITING_RESULT_COUNTER_SUCCESS,
} from './actionTypes';

const initialState = {
  error: '',
  loadingAwatingResultCounter: false,
  awatingResultCounter: 0,
};

export default function reducer(state = initialState, { type, payload }) {
  switch(type) {
    case GET_AWAITING_RESULT_COUNTER:
      return { ...state, loadingAwatingResultCounter: true };

    case GET_AWAITING_RESULT_COUNTER_FAIL:
        return {
          ...state,
          error: payload.error,
          loadingAwatingResultCounter: false,
        }

    case GET_AWAITING_RESULT_COUNTER_SUCCESS: {
      const { sentFeedbacksForLeaders } = payload.response;
      return {
        ...state,
        loadingAwatingResultCounter: false,
        awatingResultCounter: sentFeedbacksForLeaders,
      }
    }

    default:
      return state;
  }
}
