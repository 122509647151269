// @packages
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Collapsible from 'smu-ui-components/Collapsible';
import Segment from 'smu-ui-components/Segment';
import Button from 'smu-ui-components/ButtonV2';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { push } from 'react-router-redux';

// @app
import CardSimple from 'common/CardSimple';
import Title from 'components/atoms/Title';
import getMessageType from 'utils/getMessageType';
import { getSummaryGoals, resetSummaryGoals } from 'betterme-components/Goals/actions';
import {
  selectLoadingSummaryGoals,
  selectSummaryGoals,
} from '../../Goals/selectors';
import {
  commonMessages,
  evaluationsMessages,
  goalsMessages,
} from 'common/messages';
import {
  selectCollaboratorsByLeader,
  selectCommunityId,
  selectCommunityLeadershipLevels,
  selectConfigurations,
  selectEvaluationPeriod,
  selectRatingsEngagementColors,
} from 'betterme-components/Authorization/selectors';
import { selectRatings } from 'betterme-components/services/Layout/selectors';
import {
  getMyTeamFeedbacksLocation,
  getMyTeamGoalsLocation,
  getTeamEvaluationsLocation,
} from '../../routes/urls';
import profileMessages from 'betterme-components/Profile/messages';
import TeamEvaluationsStatus from 'betterme-components/components/containers/TeamEvaluationsStatusContainer';
import { getLeadershipRequestLocation, getAddTeamMemberLocation } from 'betterme-components/routes/urls';
import { canCreateBehaviourRetrospective } from 'betterme-components/Evaluations/actions';

// @own
import '../styles.scss';
import * as actions from '../actions';
import {
  trackBoxOpportunitySendFeedbackEmpty,
  trackBoxOpportunitySendFeedbackLink,
  trackBoxStrengthsSendFeedbackEmpty,
  trackFeedbacksReceivedViewMore,
  trackLeadershipRequestsProfileClick,
  trackOverallPieChartGoalsViewMore,
} from '../analytics';
import {
  selectEvaluationsCount,
  selectEvaluationsScores,
  selectEvaluationsTeam,
  selectFeedbacksSummaryTeam,
  selectLoadingOpportunities,
  selectLoadingStrenghts,
  selectLoadingSummaryTeam,
  selectLoadingTeamEvaluations,
  selectLoadingValueKudos,
  selectOpportunities,
  selectOpportunitiesRating,
  selectStrengths,
  selectStrengthsRating,
  selectTeamMembers,
  selectValueKudos,
} from '../selectors';
import CompetencyBoxes from '../CompetencyBoxes';
import GoalsStatusCounter from '../GoalsStatusCounter';
import TeamFeedbackReceived from '../TeamFeedbackReceived';
import TeamMembers from '../TeamMembers';
import { MAX_LENGTH_OPEN_LIST } from 'betterme-components/Evaluations/constants';
import { RECEIVED } from 'betterme-components/Team/constants';
import messages from '../messages';
class TeamDashboardTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mainAndSecondaryLeaderCollapsibleOpen: true,
      mainLeaderCollapsibleOpen: true,
      secondaryLeaderCollapsibleOpen: true,
    };

    this.handleCollapsibleOpenClose = this.handleCollapsibleOpenClose.bind(this);
    this.handleLeadershipRequestButton = this.handleLeadershipRequestButton.bind(this);
    this.handleLeadershipAdminButton = this.handleLeadershipAdminButton.bind(this);
  }

  componentDidMount() {
    const {
      getStarmeupKudosInfo,
      collaboratorsByLeader,
    } = this.props;
    const {
      asMainAndSecondary,
      asMain,
      asSecondary,
    } = collaboratorsByLeader;

    getStarmeupKudosInfo();
    this.getFeedbacksSummaryTeam();
    this.getSummaryGoals();
    this.getTeamEvaluations();
    this.getTeamOpportunities();
    this.getTeamStrengths();

    if (asMainAndSecondary?.length > MAX_LENGTH_OPEN_LIST) {
      this.setState({
        mainAndSecondaryLeaderCollapsibleOpen: false
      })
    }

    if (asSecondary?.length > MAX_LENGTH_OPEN_LIST) {
      this.setState({
        secondaryLeaderCollapsibleOpen: false
      })
    }

    if (asMain?.length > MAX_LENGTH_OPEN_LIST) {
      this.setState({
        mainLeaderCollapsibleOpen: false
      })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      myTeamFilters: {
        endDate: prevEndDate,
        startDate: prevStartDate,
      }
    } = prevProps;
    const {
      myTeamFilters: {
        endDate: currentEndDate,
        startDate: currentStartDate,
      }
    } = this.props;
    const hasDateChanged = (prevEndDate !== currentEndDate) || (prevStartDate !== currentStartDate);

    if (hasDateChanged) {
      this.getFeedbacksSummaryTeam();
      this.getSummaryGoals();
      this.getTeamEvaluations();
      this.getTeamOpportunities();
      this.getTeamStrengths();
    }
  }

  componentWillUnmount() {
    const { resetSummaryGoals, resetTeamDashboardState } = this.props;

    resetTeamDashboardState();
    resetSummaryGoals();
  }

  getTeamOpportunities() {
    const { getTeamOpportunities, user } = this.props;
    const { myTeamFilters: { endDate, startDate } } = this.props;
    const from = startDate ? startDate.valueOf() : undefined;
    const to = endDate ? endDate.valueOf() : undefined;

    getTeamOpportunities(user.id, from, to);
  }

  getTeamStrengths() {
    const { getTeamStrengths, user } = this.props;
    const { myTeamFilters: { endDate, startDate } } = this.props;
    const from = startDate ? startDate.valueOf() : undefined;
    const to = endDate ? endDate.valueOf() : undefined;

    getTeamStrengths(user.id, from, to);
  }

  getTeamEvaluations() {
    const { getTeamEvaluations } = this.props;
    getTeamEvaluations();
  }

  getFeedbacksSummaryTeam() {
    const { getFeedbacksSummaryTeam, user } = this.props;
    const { myTeamFilters: { endDate, startDate } } = this.props;
    const from = startDate ? startDate.valueOf() : undefined;
    const to = endDate ? endDate.valueOf() : undefined;

    getFeedbacksSummaryTeam(user.id, from, to);
  }

  getSummaryGoals() {
    const { getSummaryGoals, user } = this.props;
    const { myTeamFilters: { endDate, startDate } } = this.props;
    const from = startDate ? startDate.valueOf() : undefined;
    const to = endDate ? endDate.valueOf() : undefined;

    getSummaryGoals(user.id, from, to);
  }

  getEngagementInfo(type) {
    const { intl: { formatMessage } } = this.props;
    switch (type) {
      case 'not-engaged':
        return ({
          color: '#fd317f',
          icon: 'no-engaged',
          tooltip: formatMessage(profileMessages.FeedbackProfileEngagementMemberListTooltip),
          type: 'not-engaged'
        });

      case 'not-engaged-with-form':
        return ({
          color: '#01bc56',
          icon: 'no-engaged',
          tooltip: formatMessage(profileMessages.FeedbackProfileEngagementMemberListTooltip),
          type: 'not-engaged-with-form',
        });

      default:
        return undefined;
    }
  }

  handleCollapsibleOpenClose(collapsibleState) {
    return () =>
      this.setState(prevState => ({
        [collapsibleState]: !prevState[collapsibleState],
      }));
  }

  handleLeadershipRequestButton() {
    const { goToLeadershipRequest } = this.props;

    goToLeadershipRequest();
    trackLeadershipRequestsProfileClick();
  }

  handleLeadershipAdminButton() {
    const { goToLeadershipAdmin } = this.props;

    goToLeadershipAdmin();
  }

  render() {
    const {
      allowsAddToMyTeam,
      allowsContinuousEvaluation,
      allowsEngagementIndicator,
      allowsEvaluationDrafts,
      allowsEvaluations,
      allowsEvaluationsCreation,
      allowsFeedbackRequest,
      allowsGlbFeatures,
      allowsGoals,
      allowsNewHireEvaluationCreation,
      allowsNewHireEvaluationDraft,
      allowsPeriodEvaluation,
      allowsPerformanceRetrospectiveCreation,
      allowsPerformanceRetrospectiveDraft,
      allowsPeriodEvaluationCreation,
      allowsPeriodEvaluationDraft,
      allowsPromotions,
      allowsRequestedOnBehalf,
      allowsSmuDna,
      allowsTeamEvaluationsWidget,
      canCreateBehaviourRetrospective,
      collaboratorsByLeader,
      communityId,
      evaluationsCount,
      feedbacksSummaryTeam,
      intl: { formatMessage },
      leadershipLevels,
      loadingGoalsStatusCounter,
      loadingOpportunities,
      loadingStrenghts,
      loadingSummaryTeam,
      loadingTeamEvaluations,
      loadingValueKudos,
      myTeamFilters,
      opportunities,
      opportunitiesRating,
      ratings,
      strengths,
      strengthsRating,
      summaryGoals,
      teamMembers,
      user,
      user: {
        access: { newWordingForLeaders },
        id: userId,
      },
      valueKudos,
    } = this.props;

    const {
      mainLeaderCollapsibleOpen,
      mainAndSecondaryLeaderCollapsibleOpen,
      secondaryLeaderCollapsibleOpen,
    } = this.state;

    const {
      startDate: startDateValue,
      endDate: endDateValue,
    } = myTeamFilters;

    const {
      asMainAndSecondary,
      asMain,
      asSecondary,
    } = collaboratorsByLeader;

    const pieChartConfig = {
      size: 110,
      strokeSize: 16,
    };
    const collapsibleAsMainAndSecondaryLeader = newWordingForLeaders
      ? formatMessage(commonMessages.AsCareerAndExperienceLeader)
      : formatMessage(evaluationsMessages.EvaluationsMainAndSecondaryLeader);
    const collapsibleAsMainLeader = newWordingForLeaders
      ? formatMessage(evaluationsMessages.EvaluationsCareerLeader)
      : formatMessage(evaluationsMessages.EvaluationsMainLeader);
    const collapsibleAsSecondaryLeader = newWordingForLeaders
      ? formatMessage(evaluationsMessages.EvaluationsAccountLeader)
      : formatMessage(evaluationsMessages.EvaluationsSecondaryLeader);

    const getMessage = getMessageType(communityId);
    const evaluationNotStarted = getMessage('EvaluationsFilterByStatusNotStarted');

    const evaluationStatusMessages = {
      title: formatMessage(evaluationsMessages.EvaluationsCurrentStatus),
      description: formatMessage(evaluationsMessages.EvaluationsRightNow),
      buttonText: formatMessage(evaluationsMessages.EvaluationsViewAll),
      status: {
        submitted: formatMessage(evaluationsMessages.EvaluationsFilterByStatusSubmited),
        draft: formatMessage(evaluationsMessages.EvaluationsFilterByStatusDraft),
        foreignDraft: formatMessage(evaluationsMessages.EvaluationsFilterByStatusForeignDraft),
        notStarted: formatMessage(evaluationNotStarted),
      },
    };
    const allowsNewHireEvaluation = (allowsNewHireEvaluationCreation || allowsNewHireEvaluationDraft)

    return (
      <div className="team-dashboard">
        <Segment
          loadingType="betterme"
          className="team-dashboard__content"
        >
          <CardSimple
            className="team-dashboard__overall"
            gray
            title={formatMessage(messages.TeamDashboardOverallTitle)}
          >
            <div className="team-dashboard__card-content team-dashboard__card-content--team">
              <div className="team-dashboard__widget">
                <CompetencyBoxes
                  overallStrenghts={{
                    loading: loadingStrenghts,
                    ratings,
                    strengths,
                    trackEmptyLink: trackBoxStrengthsSendFeedbackEmpty,
                    rating: strengthsRating,
                  }}
                  overallOpportunities={{
                    loading: loadingOpportunities,
                    opportunities,
                    ratings,
                    trackEmptyLink: trackBoxOpportunitySendFeedbackEmpty,
                    trackLink: trackBoxOpportunitySendFeedbackLink,
                    rating: opportunitiesRating,
                  }}
                  intl={{ formatMessage }}
                />
              </div>
              <div className="team-dashboard__widget">
                <TeamFeedbackReceived
                  collaboratorsList={feedbacksSummaryTeam}
                  loading={loadingSummaryTeam}
                  link={{
                    onClick: trackFeedbacksReceivedViewMore,
                    to: getMyTeamFeedbacksLocation(),
                  }}
                />
              </div>
              {allowsGoals && (
                <div className="team-dashboard__widget">
                  <GoalsStatusCounter
                    inline={!allowsTeamEvaluationsWidget}
                    link={{
                      onClick: trackOverallPieChartGoalsViewMore,
                      to: getMyTeamGoalsLocation(),
                    }}
                    loading={loadingGoalsStatusCounter}
                    pieChartConfig={allowsTeamEvaluationsWidget ? pieChartConfig : undefined}
                    summaryGoals={summaryGoals.team}
                    title={formatMessage(goalsMessages.GoalsGoals)}
                  />
                </div>
              )}
              {allowsTeamEvaluationsWidget && (
                <div className="team-dashboard__widget">
                  <TeamEvaluationsStatus
                    evaluationsCount={evaluationsCount}
                    messages={evaluationStatusMessages}
                    link={{
                      to: getTeamEvaluationsLocation(),
                    }}
                    loading={loadingTeamEvaluations}
                  />
                </div>
              )}
            </div>
          </CardSimple>
          <div className="team-dashboard__team-members-heading">
            <Title
              className="team-dashboard__team-members-heading-title"
              gray
              size={15}
            >
              {formatMessage(messages.TeamDashboardTeamMembersTitle)}
            </Title>
            <Button
              className="team-dashboard__team-members-heading-button"
              onClick={this.handleLeadershipRequestButton}
              size="medium"
              variant="outline"
            >
              {formatMessage(messages.TeamDashboardTeamMembersLeadershipRequest)}
            </Button>
            {allowsAddToMyTeam && (
                <Button
                  className="team-dashboard__team-members-heading-button"
                  onClick={this.handleLeadershipAdminButton}
                  size="medium"
                  variant="outline"
                >
                  {formatMessage(messages.TeamDashboardTeamMembersAddMember)}
                </Button>
            )}
          </div>
          <div className="team-dashboard__team-members">
            <div className="team-dashboard__team-members-wrapper">
              {asMainAndSecondary?.length > 0 && (
                <Collapsible
                  baseHeight={10000}
                  expanded={mainAndSecondaryLeaderCollapsibleOpen}
                  onClick={this.handleCollapsibleOpenClose('mainAndSecondaryLeaderCollapsibleOpen')}
                  title={collapsibleAsMainAndSecondaryLeader}
                  variant="table"
                  withIcon
                >
                  {asMainAndSecondary.map((collaborator, index) => {
                    const memberData = teamMembers.find(member => member.id === collaborator.id) || {};
                    const engagementStatus = get(collaborator, 'engagement.status', null);
                    return (
                      <TeamMembers
                        {...memberData}
                        allowsContinuousEvaluation={allowsContinuousEvaluation}
                        allowsEngagementIndicator={allowsEngagementIndicator}
                        allowsEvaluationDrafts={allowsEvaluationDrafts}
                        allowsEvaluations={allowsEvaluations}
                        allowsEvaluationsCreation={allowsEvaluationsCreation}
                        allowsFeedbackRequest={allowsFeedbackRequest}
                        allowsGoals={allowsGoals}
                        allowsNewHireEvaluation={allowsNewHireEvaluation}
                        allowsPeriodEvaluation={allowsPeriodEvaluation}
                        allowsPromotions={allowsPromotions}
                        allowsRequestedOnBehalf={allowsRequestedOnBehalf}
                        allowsSmuDna={allowsSmuDna}
                        allowsGlbFeatures={allowsGlbFeatures}
                        allowsPerformanceRetrospectiveCreation={allowsPerformanceRetrospectiveCreation}
                        allowsPerformanceRetrospectiveDraft={allowsPerformanceRetrospectiveDraft}
                        allowsPeriodEvaluationCreation={allowsPeriodEvaluationCreation}
                        allowsPeriodEvaluationDraft={allowsPeriodEvaluationDraft}
                        canCreateBehaviourRetrospective={canCreateBehaviourRetrospective}
                        communityId={communityId}
                        defaultOpen={index === 0}
                        endDate={endDateValue}
                        enabledLeader
                        engagement={allowsEngagementIndicator && this.getEngagementInfo(engagementStatus)}
                        intl={{ formatMessage }}
                        key={collaborator.id}
                        leadershipLevels={leadershipLevels}
                        loadingValueKudos={loadingValueKudos}
                        selfUserId={userId}
                        sessionUser={user}
                        startDate={startDateValue}
                        user={collaborator}
                        valueKudos={valueKudos}
                      />
                    )
                  })}
                </Collapsible>
              )}
            </div>
          </div>
          <div className="team-dashboard__team-members">
            <div className="team-dashboard__team-members-wrapper">
              {asMain?.length > 0 && (
                <Collapsible
                  baseHeight={10000}
                  expanded={mainLeaderCollapsibleOpen}
                  onClick={this.handleCollapsibleOpenClose('mainLeaderCollapsibleOpen')}
                  title={collapsibleAsMainLeader}
                  variant="table"
                  withIcon
                >
                  {asMain.map((collaborator, index) => {
                    const memberData = teamMembers.find(member => member.id === collaborator.id) || {};
                    const engagementStatus = get(collaborator, 'engagement.status', null);
                    return (
                      <TeamMembers
                        {...memberData}
                        allowsContinuousEvaluation={allowsContinuousEvaluation}
                        allowsEngagementIndicator={allowsEngagementIndicator}
                        allowsEvaluationDrafts={allowsEvaluationDrafts}
                        allowsEvaluations={allowsEvaluations}
                        allowsEvaluationsCreation={allowsEvaluationsCreation}
                        allowsFeedbackRequest={allowsFeedbackRequest}
                        allowsGoals={allowsGoals}
                        allowsNewHireEvaluation={allowsNewHireEvaluation}
                        allowsPeriodEvaluation={allowsPeriodEvaluation}
                        allowsPromotions={allowsPromotions}
                        allowsRequestedOnBehalf={allowsRequestedOnBehalf}
                        allowsSmuDna={allowsSmuDna}
                        allowsGlbFeatures={allowsGlbFeatures}
                        allowsPerformanceRetrospectiveCreation={allowsPerformanceRetrospectiveCreation}
                        allowsPerformanceRetrospectiveDraft={allowsPerformanceRetrospectiveDraft}
                        allowsPeriodEvaluationCreation={allowsPeriodEvaluationCreation}
                        allowsPeriodEvaluationDraft={allowsPeriodEvaluationDraft}
                        canCreateBehaviourRetrospective={canCreateBehaviourRetrospective}
                        communityId={communityId}
                        defaultOpen={index === 0}
                        enabledLeader={false}
                        endDate={endDateValue}
                        engagement={allowsEngagementIndicator && this.getEngagementInfo(engagementStatus)}
                        intl={{ formatMessage }}
                        key={collaborator.id}
                        leadershipLevels={leadershipLevels}
                        loadingValueKudos={loadingValueKudos}
                        selfUserId={userId}
                        sessionUser={user}
                        startDate={startDateValue}
                        user={collaborator}
                        valueKudos={valueKudos}
                      />
                    )
                  })}
                </Collapsible>
              )}
            </div>
          </div>
          <div className="team-dashboard__team-members">
            <div className="team-dashboard__team-members-wrapper">
              {asSecondary?.length > 0 && (
                <Collapsible
                  baseHeight={10000}
                  expanded={secondaryLeaderCollapsibleOpen}
                  onClick={this.handleCollapsibleOpenClose('secondaryLeaderCollapsibleOpen')}
                  title={collapsibleAsSecondaryLeader}
                  variant="table"
                  withIcon
                >
                  {asSecondary.map((collaborator, index) => {
                    const memberData = teamMembers.find(member => member.id === collaborator.id) || {};
                    const engagementStatus = get(collaborator, 'engagement.status', null);
                    return (
                      <TeamMembers
                        {...memberData}
                        allowsContinuousEvaluation={allowsContinuousEvaluation}
                        allowsEngagementIndicator={allowsEngagementIndicator}
                        allowsEvaluationDrafts={allowsEvaluationDrafts}
                        allowsEvaluations={allowsEvaluations}
                        allowsEvaluationsCreation={allowsEvaluationsCreation}
                        allowsFeedbackRequest={allowsFeedbackRequest}
                        allowsGoals={allowsGoals}
                        allowsNewHireEvaluation={allowsNewHireEvaluation}
                        allowsPeriodEvaluation={allowsPeriodEvaluation}
                        allowsPromotions={allowsPromotions}
                        allowsRequestedOnBehalf={allowsRequestedOnBehalf}
                        allowsSmuDna={allowsSmuDna}
                        allowsGlbFeatures={allowsGlbFeatures}
                        allowsPerformanceRetrospectiveCreation={allowsPerformanceRetrospectiveCreation}
                        allowsPerformanceRetrospectiveDraft={allowsPerformanceRetrospectiveDraft}
                        allowsPeriodEvaluationCreation={allowsPeriodEvaluationCreation}
                        allowsPeriodEvaluationDraft={allowsPeriodEvaluationDraft}
                        canCreateBehaviourRetrospective={canCreateBehaviourRetrospective}
                        communityId={communityId}
                        defaultOpen={index === 0}
                        enabledLeader
                        endDate={endDateValue}
                        engagement={allowsEngagementIndicator && this.getEngagementInfo(engagementStatus)}
                        intl={{ formatMessage }}
                        key={collaborator.id}
                        leadershipLevels={leadershipLevels}
                        loadingValueKudos={loadingValueKudos}
                        selfUserId={userId}
                        sessionUser={user}
                        startDate={startDateValue}
                        user={collaborator}
                        valueKudos={valueKudos}
                      />
                    )
                  })}
                </Collapsible>
              )}
            </div>
          </div>
        </Segment>
      </div>
    );
  }
};

TeamDashboardTab.propTypes = {
  allowsAddToMyTeam: PropTypes.bool,
  allowsContinuousEvaluation: PropTypes.bool,
  allowsEngagementIndicator: PropTypes.bool,
  allowsGoals: PropTypes.bool,
  allowsNewHireEvaluation: PropTypes.bool,
  allowsPeriodicEvaluation: PropTypes.bool,
  allowsPromotions: PropTypes.bool,
  allowsRequestedOnBehalf: PropTypes.bool,
  communityId: PropTypes.number.isRequired,
  defaultPeriod: PropTypes.object,
  evaluationsCount: PropTypes.array,
  evaluationsScores: PropTypes.array,
  evaluationsTeam: PropTypes.object,
  feedbacksSummaryTeam: PropTypes.array.isRequired,
  getFeedbacksSummaryTeam: PropTypes.func.isRequired,
  getSummaryGoals: PropTypes.func.isRequired,
  getTeamEvaluations: PropTypes.func.isRequired,
  getTeamOpportunities: PropTypes.func.isRequired,
  getTeamStrengths: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  loadingGoalsStatusCounter: PropTypes.bool,
  loadingOpportunities: PropTypes.bool,
  loadingStrenghts: PropTypes.bool,
  loadingSummaryTeam: PropTypes.bool,
  loadingTeamEvaluations: PropTypes.bool,
  myTeamFilters: PropTypes.object,
  onHistoryTeamClick: PropTypes.func,
  opportunities: PropTypes.array.isRequired,
  opportunitiesRating: PropTypes.object,
  ratings: PropTypes.array.isRequired,
  ratingsEngagementColors: PropTypes.object,
  resetSummaryGoals: PropTypes.func.isRequired,
  resetTeamDashboardState: PropTypes.func.isRequired,
  strengths: PropTypes.array.isRequired,
  strengthsRating: PropTypes.object,
  summaryGoals: PropTypes.shape({
    assignedTo: PropTypes.object,
    team: PropTypes.object,
  }).isRequired,
  teamMembers: PropTypes.array.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  allowsAddToMyTeam: selectConfigurations('allowsAddToMyTeam')(state),
  allowsGlbFeatures: selectConfigurations('allowsGlbFeatures')(state),
  allowsContinuousEvaluation: selectConfigurations('allowsContinuousEvaluation')(state),
  allowsEngagementIndicator: selectConfigurations('allowsEngagementIndicator')(state),
  allowsEvaluationDrafts: selectConfigurations('allowsEvaluationDrafts')(state),
  allowsEvaluations: selectConfigurations('allowsEvaluations')(state),
  allowsEvaluationsCreation: selectConfigurations('allowsEvaluationsCreation')(state),
  allowsFeedbackRequest: selectConfigurations('allowsFeedbackRequest')(state),
  allowsGoals: selectConfigurations('allowsGoals')(state),
  allowsNewHireEvaluationCreation: selectConfigurations('allowsNewHireEvaluationCreation')(state),
  allowsNewHireEvaluationDraft: selectConfigurations('allowsNewHireEvaluationDraft')(state),
  allowsPeriodEvaluation: selectConfigurations('allowsPeriodEvaluation')(state),
  allowsPromotions: selectConfigurations('allowsPromotions')(state),
  allowsRequestedOnBehalf: selectConfigurations('allowsRequestedOnBehalf')(state),
  allowsSmuDna: selectConfigurations('allowsSmuDna')(state),
  allowsTeamEvaluationsWidget: selectConfigurations('allowsTeamEvaluationsLeaderDashboardWidget')(state),
  allowsPerformanceRetrospectiveCreation: selectConfigurations('allowsPerformanceRetrospectiveCreation')(state),
  allowsPerformanceRetrospectiveDraft: selectConfigurations('allowsPerformanceRetrospectiveDraft')(state),
  allowsPeriodEvaluationCreation: selectConfigurations('allowsPeriodEvaluationCreation')(state),
  allowsPeriodEvaluationDraft: selectConfigurations('allowsPeriodEvaluationDraft')(state),
  collaboratorsByLeader: selectCollaboratorsByLeader(state),
  communityId: selectCommunityId(state),
  defaultPeriod: selectEvaluationPeriod(state),
  evaluationsCount: selectEvaluationsCount(state),
  evaluationsScores: selectEvaluationsScores(state),
  evaluationsTeam: selectEvaluationsTeam(state),
  feedbacksSummaryTeam: selectFeedbacksSummaryTeam(state),
  leadershipLevels: selectCommunityLeadershipLevels(state),
  loadingGoalsStatusCounter: selectLoadingSummaryGoals(state),
  loadingOpportunities: selectLoadingOpportunities(state),
  loadingStrenghts: selectLoadingStrenghts(state),
  loadingSummaryTeam: selectLoadingSummaryTeam(state),
  loadingTeamEvaluations: selectLoadingTeamEvaluations(state),
  loadingValueKudos: selectLoadingValueKudos(state),
  opportunities: selectOpportunities(state),
  opportunitiesRating: selectOpportunitiesRating(state),
  ratings: selectRatings(state),
  ratingsEngagementColors: selectRatingsEngagementColors(state),
  strengths: selectStrengths(state),
  strengthsRating: selectStrengthsRating(state),
  summaryGoals: selectSummaryGoals(state),
  teamMembers: selectTeamMembers(state),
  valueKudos: selectValueKudos(state),
});

const mapDispatchToProps = {
  ...actions,
  canCreateBehaviourRetrospective,
  getSummaryGoals,
  resetSummaryGoals,
  goToLeadershipRequest: () => push(getLeadershipRequestLocation(RECEIVED)),
  goToLeadershipAdmin: () => push(getAddTeamMemberLocation()),
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TeamDashboardTab));
