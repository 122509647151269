// @packages
import React from 'react';
import { Link as LinkRouter } from 'react-router';

// @app
import { getLocalPathname } from 'smu-utils/url';

const Link = ({ href, children, ...rest }) => {
  const to = getLocalPathname(href);

  if (to) {
    return (
      <LinkRouter to={to} {...rest}>
        {children}
      </LinkRouter>
    );
  }

  return (
    <a href={href} {...rest}>
      {children}
    </a>
  );
};

export default Link;
