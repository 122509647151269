// @packages
import React from 'react';
import Container from 'smu-layout-components/Container';

// @own
import './styles.scss';

function MyTeamLayout({ children }) {
  return (
    <div className="my-team-layout flex-1 flex flex-col">
      <Container className="flex-1 flex flex-col" centered>
        {children}
      </Container>
    </div>
  );
}

export default MyTeamLayout;
