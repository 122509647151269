// @own
import {
  GET_SURVEY_QUESTIONS,
  GET_SURVEY_QUESTIONS_SUCCESS,
  GET_SURVEY_QUESTIONS_FAIL,
  GET_SURVEY_RATES,
  GET_SURVEY_RATES_SUCCESS,
  GET_SURVEY_RATES_FAIL,
  RESET_DRAFT_BEHAVIORS,
  RESET_SURVEY_QUESTIONS,
  RESET_SURVEY_RATES,
  SET_DRAFT_BEHAVIORS,
} from './actionTypes';

export function getSurveyQuestions(payload) {
  return {
    type: GET_SURVEY_QUESTIONS,
    payload,
  };
}

export function getSurveyQuestionsSuccess(response) {
  return {
    type: GET_SURVEY_QUESTIONS_SUCCESS,
    payload: response,
  };
}

export function getSurveyQuestionsFail(error) {
  return {
    type: GET_SURVEY_QUESTIONS_FAIL,
    payload: error,
  };
}

export function resetSurveyQuestions() {
  return {
    type: RESET_SURVEY_QUESTIONS,
  };
}

export function getSurveyRates(payload) {
  return {
    type: GET_SURVEY_RATES,
    payload,
  };
}

export function getSurveyRatesSuccess(response) {
  return {
    type: GET_SURVEY_RATES_SUCCESS,
    payload: response,
  };
}

export function getSurveyRatesFail(error) {
  return {
    type: GET_SURVEY_RATES_FAIL,
    payload: error,
  };
}

export function resetSurveyRates() {
  return {
    type: RESET_SURVEY_RATES,
  };
}

export function setDraftBehaviors(payload) {
  return {
    type: SET_DRAFT_BEHAVIORS,
    payload,
  };
}

export function resetDraftBehaviors() {
  return {
    type: RESET_DRAFT_BEHAVIORS,
  };
}
