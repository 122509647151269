// @packages
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

// @app
import DataList from 'common/DataList';
import { formatDate } from 'utils/formatDate';
import { formatPeriodDates } from 'utils/formatPeriodDates';

// @own
import './styles.scss';
import DueDateStatus from './DueDateStatus';
import OrgObjectivesList from './OrgObjectivesList';

const formatPeriod = (period) =>
  formatPeriodDates(period.startTime, period.endTime);

const TitleNWeight = ({
  assignment,
  attachments,
  className,
  dueDate,
  messages,
  orgObjectives,
  period,
  showExpired,
  startDate,
  status,
  title,
  weighting,
}) => {
  const dueDateText = dueDate && formatDate(dueDate);
  const startDateText = startDate && formatDate(startDate);
  const progressText = assignment === 'SHARED' ? messages.shared : messages.individual;
  const DueDateElement = (
    <DueDateStatus
      dueDateText={dueDateText}
      expiredText={messages.expired}
      messages={{
        dueDate: messages.dueDate,
        startDate: messages.startDate,
      }}
      showExpired={showExpired}
      startDateText={startDateText}
    />
  );

  const statusType = {
    'OPENED': messages.opened,
    'CLOSED': messages.closed,
    'PENDING_APPROVAL': messages.pending,
  };
  const statusClass = cn('title-n-width__status font-rubik font-bold text-xs', {
    'title-n-width__status--active': status === 'OPENED',
    'title-n-width__status--pending': status === 'PENDING_APPROVAL',
  });

  const dataItems = [
    { label: messages.weighting, value: weighting, shouldRender: weighting },
    { label: messages.period, value: formatPeriod(period), shouldRender: period },
    { label: '', value: DueDateElement, shouldRender: dueDate || startDate },
    { label: messages.progress, value: progressText, shouldRender: assignment },
    { label: messages.attachedFiles, value: attachments?.length, shouldRender: attachments?.length },
    {
      label: messages.orgObjectives,
      value: <OrgObjectivesList orgObjectives={orgObjectives} />,
      shouldRender: get(orgObjectives, 'length'),
    },
  ];

  return (
    <div className={`title-n-width ${className || ''}`}>
      <div className="title-n-width__header">
        <h4 className="title-n-width__title font-rubik text-base text-shark w-4/5">{title}</h4>
        <div className={statusClass}>{statusType[status]}</div>
      </div>
      <DataList
        className="title-n-width__data"
        items={dataItems.filter((item) => item.shouldRender)}
      />
    </div>
  );
};

TitleNWeight.propTypes = {
  assignment: PropTypes.string,
  className: PropTypes.string,
  dueDate: PropTypes.number,
  showExpired: PropTypes.bool,
  messages: PropTypes.object,
  orgObjectives: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })),
  status: PropTypes.string,
  title: PropTypes.string,
  weighting: PropTypes.number,
};

export default TitleNWeight;
