// @packages
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import { createSelector } from 'reselect';
import { getUserLanguage } from 'smu-utils/language';

export const selectUserPreferences = ({ cookiesBanner }) =>
  get(cookiesBanner, 'userPreferences');

export const selectCommunityConfigurations = ({ session }) =>
  get(session, 'communityConfigurations');

export const selectCommunityId = ({ session }) =>
  get(session, 'communityConfigurations.id');

export const selectCommunityName = ({ session }) =>
  get(session, 'communityConfigurations.name');

export const selectPromotionConfig = ({ session }) =>
  get(session, 'communityConfigurations.promotions') || {};

export const selectAllowsGoals = ({ session }) =>
  get(session, 'communityConfigurations.configurations.allowsGoals', true);

export const selectAllowsEvaluations = ({ session }) =>
  get(session, 'communityConfigurations.configurations.allowsEvaluations', true);

export const selectAllowsSkills = ({ session }) =>
  get(session, 'communityConfigurations.configurations.allowsSkills', false);

export const selectConfigurations = (field) => ({ session }) => {
  return field ? get(session, `communityConfigurations.configurations.${field}`) :
    get(session, 'communityConfigurations.configurations');
};

export const selectAllowsFeedbackRequestsDismiss = ({ session }) =>
  get(session, 'communityConfigurations.configurations.allowsFeedbackRequestsDismiss');

export const selectShowMyEvaluationsGoalFinalScore =
  selectConfigurations('allowsMyEvaluationsGoalScore');

export const selectShowTeamEvaluationsGoalFinalScore =
  selectConfigurations('allowsTeamEvaluationsGoalScore');

export const selectCustomScoreDefinition = ({ session }) =>
  get(session, 'communityConfigurations.customScoreDefinition');

export const selectScores = ({ session }) =>
  get(session, 'communityConfigurations.scores', []);

export const selectTopics = ({ session }) =>
  get(session, 'communityConfigurations.topics', []);

export const selectRatings = ({ session }) =>
  get(session, 'communityConfigurations.ratings', []);

export const selectEvaluationPeriod = ({ session }) =>
  get(session, 'communityConfigurations.evaluationPeriod', {});

export const selectDefaultPeriod = ({ session }) =>
  get(session, 'communityConfigurations.defaultPeriod');

export const selectUser = ({ session }) =>
  get(session, 'user');

export const selectSessionUserId = ({ session }) => session?.user?.id;

export const selectLeaderInfo = ({ session }) =>
  get(session, 'user.leader') || {};

export const selectEvaluatorsInfo = ({ session }) =>
  get(session, 'user.evaluators') || [];

export const selectCollaboratorsByLeader = ({ session }) =>
  get(session, 'user.collaborators') || [];

export const selectHasCollaborators = ({ session }) =>
  get(session, 'user.hasCollaborators');

export const selectLanguageCode = ({ session }) =>
  session?.user?.profile?.locale?.language;

export const selectUserRoles = ({ session }) => session?.user?.roles;

export const selectUserDraftEvaluation = ({ session }) =>
  get(session, 'userDraftEvaluation');

export const selectUserDraftEvaluationLoading = ({ session }) =>
  get(session, 'userDraftEvaluationLoading');

export const selectSessionLoading = ({ session }) =>
  get(session, 'loading');

export const selectSessionLoadingProfile = ({ session }) =>
  get(session, 'loadingProfile');

export const selectSessionLoadingRefreshUserInfo = ({ session }) =>
  get(session, 'loadingRefreshUserInfo');

export const selectCommunityLeadershipLevels = ({ session }) =>
  get(session, 'communityConfigurations.leadershipLevels', {});

export const selectFeedbackForLeadersRequestUrl = ({ session }) =>
  get(session, 'communityConfigurations.feedbackForLeaders.requestUrl');

export const selectFeedbackForLeadersRatings = ({ session }) =>
  get(session, 'feedbackForLeadersRatings', []);

export const selectUserInfo = createSelector(
  selectUser,
  (user) => {
    if (user) {
      const { leader, evaluators } = user;
      const leaders = [];
      const mainAndSecondayLeader = evaluators?.find(({ id }) => id === leader?.id);
      const secondaryLeaders = mainAndSecondayLeader
        ? evaluators.filter(({ id }) => id !== leader.id)
        : evaluators;

      if (leader) {
        leaders.push(leader);
      }

      if (secondaryLeaders) {
        leaders.push(...secondaryLeaders);
      }

      return {
        ...user,
        evaluators: secondaryLeaders,
        leaders: leaders.length ? leaders : null,
        mainAndSecondayLeader,
      };
    }

    return ({});
  });

export const selectRatingsEngagementColors = createSelector(
  selectRatings,
  (ratings) => {
    const orderedRatings = orderBy(ratings, ['value'], ['desc']);
    const ratingsLength = orderedRatings.length;
    const defaultColor = '#C2C7CC';
    const firstRating = ratingsLength
      ? orderedRatings[0].color
      : defaultColor;
    const lastRatingDefault = (ratingsLength && ratingsLength < 3)
      ? orderedRatings[ratingsLength - 1]
      : defaultColor;
    const lastRating = (ratingsLength && ratingsLength >= 3)
      ? orderedRatings[2].color
      : lastRatingDefault;

    return ({
      engaged: firstRating,
      noEngaged: lastRating,
    });
  });

export const selectScoresDescription = createSelector(
  selectScores,
  (scores) => {
    const selectedLocale = getUserLanguage();
    const scoresFiltered = scores.map(({ id, localizations }) => ({
      id,
      localizations,
    })).map(({ id, localizations }) => {
      const localizationFiltered = localizations.filter(localization =>
        localization.locale.language === selectedLocale,
      );

      return {
        id,
        ...localizationFiltered[0],
      };
    }).map(score => score.locale ? score : { ...score, description: null });

    return scoresFiltered;
  }
);

export const selectFeedbackForLeadersAnswersAllowed = ({ session }) =>
  get(session, 'communityConfigurations.feedbackForLeaders.answersAllowed');

export const selectAllCollaborators = createSelector(
  selectCollaboratorsByLeader,
  (collaborators) => {
    const allCollaborators = [
      ...(collaborators?.asMain || []),
      ...(collaborators?.asSecondary || []),
      ...(collaborators?.asMainAndSecondary || []),
    ];
    return allCollaborators;
  }
);

export const selectCollaboratorsAsMain = createSelector(
  selectCollaboratorsByLeader,
  (collaborators) => {
    const allCollaborators = [
      ...(collaborators?.asMain || []),
      ...(collaborators?.asMainAndSecondary || []),
    ];
    return allCollaborators;
  }
);

export const selectCollaboratorsAsSecondary = createSelector(
  selectCollaboratorsByLeader,
  (collaborators) => {
    const allCollaborators = [
      ...(collaborators?.asSecondary || []),
    ];
    return allCollaborators;
  }
);

export const selectCollaboratorsAsMainAndSecondary = createSelector(
  selectCollaboratorsByLeader,
  (collaborators) => {
    const allCollaborators = [
      ...(collaborators?.asMainAndSecondary || []),
    ];
    return allCollaborators;
  }
);
