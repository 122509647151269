import { defineMessages } from 'react-intl';

export default defineMessages({
  SuggestActionsBreadcrumbTitle: {
    id: 'Betterme.SuggestActions.BreadcrumbTitle',
    defaultMessage: 'Suggest actions'
  },
  SuggestActionsFormTitle: {
    id: 'Betterme.SuggestActions.FormTitle',
    defaultMessage: 'Suggestions for the future'
  },
  SuggestActionsFormSubtitle: {
    id: 'Betterme.SuggestActions.FormSubtitle',
    defaultMessage: '{username} won\'t see these suggestions'
  },
  SuggestActionsFormComment: {
    id: 'Betterme.SuggestActions.FormComment',
    defaultMessage: 'Leave a comment about the suggested actions'
  },
  SuggestActionsFormSuccess: {
    id: 'Betterme.SuggestActions.FormSuccess',
    defaultMessage: 'Your suggestions were sent successfully!'
  },
});
