import { defineMessages } from 'react-intl';

export default defineMessages({
  CarouselEvaluated: {
    id: 'Betterme.Carousel.Evaluated',
    defaultMessage: 'Evaluated',
  },
  CarouselInProgress: {
    id: 'Betterme.Carousel.InProgress',
    defaultMessage: 'In progress',
  },
  CarouselNotAvaible: {
    id: 'Betterme.Carousel.NotAvaible',
    defaultMessage: 'Not eligible yet',
  },
  CarouselNotAvaibleDescription: {
    id: 'Betterme.Carousel.NotAvaibleDescription',
    defaultMessage: 'This member joined the company less than <b>{period} months ago</b>.',
  },
  CarouselNotAvaibleDescriptionSingular: {
    id: 'Betterme.Carousel.NotAvaibleDescriptionSingular',
    defaultMessage: 'This member joined the company less than <b>{period} month ago</b>.',
  },
});
