// @packages
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

// @App
import CardSimple from 'common/CardSimple';

// @own
import './styles.scss';
import UserIcon from 'betterme-components/assets/icon_feedback_for_leaders.svg';
import messages from '../messages';
import {
  selectAwatingResultCounter,
  selectLoadingAwatingResultCounter,
} from './selectors';
import * as actions from './actions';

const FeedbackForLeadersReport = ({
  awatingResultCounter,
  getAwatingResultCounter,
  hasCollaborators,
  intl: { formatMessage },
  loadingAwatingResultCounter,
  user,
}) => {

  useEffect(() => {
    if (user) {
      getAwatingResultCounter({
        userId: user.id,
      });
    }
  }, []);

  if (!hasCollaborators && awatingResultCounter === 0) {
    return null;
  }

  return (
    <CardSimple
      title="feedback for leaders"
      subtitle="summary report"
      loading={loadingAwatingResultCounter}
    >
      <div className="feedback-for-leaders-report">
        <div className="feedback-for-leaders-report__content">
          <div className="feedback-for-leaders-report__evaluations">
            <h3 className="feedback-for-leaders-report__quantity">
              {awatingResultCounter}
            </h3>
            <img
              className="feedback-for-leaders-report__icon"
              src={UserIcon}
              alt="user icon"
            />
            <div className="feedback-for-leaders-report__container">
              <h3 className="feedback-for-leaders-report__title">
                 {formatMessage(messages.FeedbackForLeadersReportQuantityTitle, {
                  counter: awatingResultCounter,
                })}
              </h3>
              <p className="feedback-for-leaders-report__text">
                {formatMessage(messages.FeedbackForLeadersReportDescription)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </CardSimple>
  );
};

FeedbackForLeadersReport.propTypes = {
  awatingResultCounter: PropTypes.number.isRequired,
  hasCollaborators: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  loadingAwatingResultCounter: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  awatingResultCounter: selectAwatingResultCounter(state),
  loadingAwatingResultCounter: selectLoadingAwatingResultCounter(state),
});

const mapDispatchToProps = {
  ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(FeedbackForLeadersReport));
