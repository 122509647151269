import {
  CLEAN_SKILL_LIST,
  CLEAN_SKILL_STATE,
  DELETE_SKILL,
  GET_SKILLS_FAIL,
  GET_SKILLS_SUCCESS,
  INIT_SELECTED_SKILLS,
  SELECT_SKILL,
} from './actionTypes';

const initialState = {
  selectedSkills: [],
  skillsList: [],
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case CLEAN_SKILL_LIST:
      return { ...state, selectedSkills: [] };
    case CLEAN_SKILL_STATE:
      return initialState;
    case DELETE_SKILL:
      return {
        ...state,
        selectedSkills: state.selectedSkills.filter((skill) => skill.id !== payload.skillId),
      };
    case GET_SKILLS_FAIL:
      return { ...state, skillsList: [] };
    case GET_SKILLS_SUCCESS:
      return { ...state,  skillsList: payload.response };
    case INIT_SELECTED_SKILLS:
      return { ...state, selectedSkills: payload.skills };
    case SELECT_SKILL:
      return {
        ...state,
        skillsList: [],
        selectedSkills: [...state.selectedSkills, payload.skill],
      };
    default:
      return state;
  }
}
