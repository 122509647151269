// @packages
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// @app
import { selectUser } from '../Authorization/selectors';
import Feed from '../Feed';

// @own
import * as actions from './actions';
import { selectTeamFeedbacks } from './selectors';

class TeamFeedbacks extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      assignee,
      from,
      getTeamFeedbacks,
      getTeamFeedbacksComments,
      newStyle,
      ordering,
      postTeamFeedbacksComments,
      teamFeedbacks,
      to,
      user,
      userSession,
      view,
    } = this.props;

    return (
      <div className="my-feedbaks">
        <Feed
          assignee={assignee}
          feed={teamFeedbacks}
          from={from}
          getComments={getTeamFeedbacksComments}
          getFeed={getTeamFeedbacks}
          isTeamDashboard
          newStyle={newStyle}
          ordering={ordering}
          sendComment={postTeamFeedbacksComments}
          to={to}
          user={user || userSession}
          view={view}
        />
      </div>
    );
  }
}

TeamFeedbacks.defaultProps = {
  newStyle: false,
}

TeamFeedbacks.propTypes = {
  assignee: PropTypes.object,
  from: PropTypes.object,
  getTeamFeedbacksComments: PropTypes.func.isRequired,
  getTeamFeedbacks: PropTypes.func.isRequired,
  teamFeedbacks: PropTypes.shape({
    feedbacks: PropTypes.array,
    fetching: PropTypes.bool,
  }),
  myTeamFilters: PropTypes.object,
  newStyle: PropTypes.bool,
  ordering: PropTypes.string,
  postTeamFeedbacksComments: PropTypes.func.isRequired,
  to: PropTypes.object,
  user: PropTypes.object,
  userSession: PropTypes.object.isRequired,
  view: PropTypes.oneOf(['team', 'own', 'profile']),
};

const mapStateToProps = state => ({
  teamFeedbacks: selectTeamFeedbacks(state),
  userSession: selectUser(state),
});

export default connect(mapStateToProps, actions)(TeamFeedbacks);
