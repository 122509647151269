// @packages
import React, { useContext, createContext, useReducer } from 'react';

// @own
import {
  TYPE_FORM_CHANGE,
  TYPE_FORM_RESET,
  TYPE_FORM_UPDATE,
} from './constants';

const formContext = createContext('form');
const initialState = {};

const formReducer = (state, action) => {
  switch (action.type) {
    case TYPE_FORM_CHANGE:
      return {
        ...state,
        [action?.payload?.id]: {
          questionId: action?.payload?.questionId,
          text: action?.payload?.text,
          value: action?.payload?.value,
        },
      };
    case TYPE_FORM_RESET:
      return {
        ...initialState,
        ...action?.payload,
      };
    case TYPE_FORM_UPDATE:
      return {
        ...state,
        ...action?.payload,
      };
    default:
      throw new Error();
  }
};

function useProviderForm() {
  const [state, dispatch] = useReducer(formReducer, initialState);
  function handleChange(id, value, questionId, text) {
    dispatch({ type: TYPE_FORM_CHANGE, payload: { id, value, questionId, text } });
  }

  function reset(payload) {
    dispatch({ type: TYPE_FORM_RESET, payload });
  }

  function update(payload) {
    dispatch({ type: TYPE_FORM_UPDATE, payload });
  }

  return {
    change: handleChange,
    reset,
    update,
    values: state,
  };
}

export function ProvideForm({ children }) {
  const value = useProviderForm();
  return <formContext.Provider value={value}>{children}</formContext.Provider>;
}

export default function useForm() {
  return useContext(formContext);
}
