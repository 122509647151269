import { defineMessages } from 'react-intl';

export default defineMessages({
  SendRequestFeedbackFeedbackConfirmationModalTitle: {
    id: 'Betterme.SendRequestFeedback.FeedbackConfirmationModal.Title',
    defaultMessage: 'Thanks!',
  },
  SendRequestFeedbackFeedbackConfirmationModalFooterMessage: {
    id: 'Betterme.SendRequestFeedback.FeedbackConfirmationModal.FooterMessage',
    defaultMessage: 'Keep sending Feedback to help your colleagues grow',
  },
  AskRequestFeedbackFeedbackConfirmationModalFooterMessage: {
    id: 'Betterme.AskRequestFeedback.FeedbackConfirmationModal.FooterMessage',
    defaultMessage: 'Keep asking for Feedback and improve even faster',
  },
  SendRequestFeedbackFeedbackConfirmationModalSuccessfullySentFeedbackMessage: {
    id: 'Betterme.SendRequestFeedback.FeedbackConfirmationModal.SuccessfullySentFeedbackMessage',
    defaultMessage: 'was sent successfully',
  },
  SendRequestFeedbackFeedbackConfirmationModalSuccessfullySentFeedbackRequestMessage: {
    id: 'Betterme.SendRequestFeedback.FeedbackConfirmationModal.SuccessfullySentFeedbackRequestMessage',
    defaultMessage: 'was sent successfully!',
  },
  SendRequestFeedbackFeedbackConfirmationModalSendMessage: {
    id: 'Betterme.SendRequestFeedback.FeedbackConfirmationModal.SendMessage',
    defaultMessage: 'Your Feedback to',
  },
  SendRequestFeedbackFeedbackConfirmationModalRequestMessage: {
    id: 'Betterme.SendRequestFeedback.FeedbackConfirmationModal.RequestMessage',
    defaultMessage: 'Your Feedback Request to',
  },
  SendRequestFeedbackFeedbackConfirmationModalOnBehalfMessage: {
    id: 'Betterme.SendRequestFeedback.FeedbackConfirmationModal.OnBehalfMessage',
    defaultMessage: 'Your Feedback Request about',
  },
  SendRequestFeedbackFeedbackConfirmationModalReplyMessage: {
    id: 'Betterme.SendRequestFeedback.FeedbackConfirmationModal.ReplyMessage',
    defaultMessage: 'Your Feedback to',
  },
  SendRequestFeedbackFeedbackConfirmationModalMultipleRequestMessage: {
    id: 'Betterme.SendRequestFeedback.FeedbackConfirmationModal.MultipleRequestMessage',
    defaultMessage: 'Your Feedback requests were sent successfully!',
  },
  SendRequestFeedbackFeedbackConfirmationModalMultipleOnBehalfMessage: {
    id: 'Betterme.SendRequestFeedback.FeedbackConfirmationModal.MultipleOnBehalfMessage',
    defaultMessage: 'Your feedback requests about your collaborators are on their way!',
  },
  SendRequestFeedbackFeedbackConfirmationModalMultipleSendMessage: {
    id: 'Betterme.SendRequestFeedback.FeedbackConfirmationModal.MultipleSendMessage',
    defaultMessage: 'Your Feedback was sent successfully!',
  },
  SendRequestFeedbackFeedbackConfirmationModalMultipleRequestTitle: {
    id: 'Betterme.SendRequestFeedback.FeedbackConfirmationModal.MultipleRequestTitle',
    defaultMessage: 'Good job',
  },
  SendRequestFeedbackActionsRequestButton: {
    id: 'Betterme.SendRequestFeedback.Action.Request',
    defaultMessage: 'Request',
  },
  SendRequestFeedbackSubmitError: {
    id: 'Betterme.SubmitError',
    defaultMessage: 'Please complete all fields',
  },
  SendRequestFeedbackRateSelectorTitle: {
    id: 'Betterme.SendRequestFeedback.RateSelector.Title',
    defaultMessage: 'Give Feedback',
  },
  SendRequestFeedbackRateSelectorSubtitle: {
    id: 'Betterme.SendRequestFeedback.RateSelector.Subtitle',
    defaultMessage: 'Drag and drop competencies to rate them',
  },
  SendRequestFeedbackCompetenciesSelectorSubtitle: {
    id: 'Betterme.SendRequestFeedback.CompetencySelector.Subtitle',
    defaultMessage: 'Choose the competencies you want to get Feedback on',
  },
  SendRequestFeedbackCommentPlaceholderSend: {
    id: 'Betterme.SendRequestFeedback.CommentPlaceholderSend',
    defaultMessage: 'Add a comment related to the feedback.',
  },
  SendRequestFeedbackCommentPlaceholderRequest: {
    id: 'Betterme.SendRequestFeedback.CommentPlaceholderRequest',
    defaultMessage: 'Add a comment related to the feedback request.',
  },
  SendRequestFeedbackRequestCommentName: {
    id: 'Betterme.SendRequestFeedback.RequestCommentName',
    defaultMessage: '{name}\'s comment',
  },
  SendRequestFeedbackRequestDisableError: {
    id: 'Betterme.SendRequestFeedback.RequestDisableError',
    defaultMessage: 'This community does not allow Feedback requests',
  },
  SendRequestFeedbackRequestUserSelectorRequestTitle: {
    id: 'Betterme.SendRequestFeedback.UserSelectorRequestTitle',
    defaultMessage: 'Select one or more colleagues',
  },
  SendRequestFeedbackRequestUserSelectorSendTitle: {
    id: 'Betterme.SendRequestFeedback.UserSelectorSendTitle',
    defaultMessage: 'Select a colleague',
  },
  SendRequestFeedbackRequestUserSelectorCollaboratorTitle: {
    id: 'Betterme.SendRequestFeedback.UserSelectorCollaboratorTitle',
    defaultMessage: 'Requesting feedback about...',
  },
  SendRequestFeedbackRequestRequestEmptyStateCancellationTitle: {
    id: 'Betterme.SendRequestFeedback.RequestEmptyStateCancellationTitle',
    defaultMessage: 'This Feedback request has been cancelled by {username}',
  },
  SendRequestFeedbackRequestRequestEmptyStateFeedbackTitle: {
    id: 'Betterme.SendRequestFeedback.RequestEmptyStateFeedbackTitle',
    defaultMessage: 'You already answered this Feedback request from {username}',
  },
  SendRequestFeedbackRequestRequestEmptyStateDismissalTitle: {
    id: 'Betterme.SendRequestFeedback.RequestEmptyStateDismissalTitle',
    defaultMessage: 'You already dismissed this Feedback request from {username}',
  },
  SendRequestFeedbackRequestEmptyStateFeedbackNotFoundTitle: {
    id: 'Betterme.SendRequestFeedback.RequestEmptyStateFeedbackNotFoundTitle',
    defaultMessage: 'This feedback was not found',
  },
   SendRequestFeedbackRequestEmptyStateFeedbackNotFoundDescription: {
    id: 'Betterme.SendRequestFeedback.RequestEmptyStateFeedbackNotFoundDescription',
    defaultMessage: 'Go to <a href={xxx1}>my profile</a>',
  },
  SendRequestFeedbackRequestRequestEmptyStateLinkText: {
    id: 'Betterme.SendRequestFeedback.RequestEmptyStateLinkText',
    defaultMessage: 'Take a look',
  },
  SendRequestFeedbackLowestRatingDescriptionFeedback: {
    id: 'Betterme.LowestRating.DescriptionFeedback',
    defaultMessage: 'Identifying opportunities for improvement is really important for your colleague\'s career.',
  },
  SendRequestFeedbackBackToFeedbacks: {
    id: 'Betterme.SendRequestFeedback.BackToFeedbacks',
    defaultMessage: 'Back to feedbacks',
  },
  SendRequestFeedbackSendSelectColleaguesTitle: {
    id: 'Betterme.SendRequestFeedback.SelectColleagues.SendTitle',
    defaultMessage: 'Who do you want to send feedback to?',
  },
  SendRequestFeedbackRequestSelectColleaguesTitle: {
    id: 'Betterme.SendRequestFeedback.SelectColleagues.RequestTitle',
    defaultMessage: 'Who do you want to send you feedback?',
  },
  SendRequestFeedbackRequestBehalfSelectColleaguesTitle: {
    id: 'Betterme.SendRequestFeedback.SelectColleagues.OnBehalfTitle',
    defaultMessage: 'Who do you want to send feedback to your collaborators?',
  },
  SendRequestFeedbackRequestBehalfSelectColaboratorsTitle: {
    id: 'Betterme.SendRequestFeedback.SelectColaborators.OnBehalfColaboratorsTitle',
    defaultMessage: 'Which of your collaborators do you want to receive feedback?',
  },
  SendRequestFeedbackRequestBehalfSelectColaboratorsLimitMessage: {
    id: 'Betterme.SendRequestFeedback.SelectColaborators.OnBehalfColaboratorsLimitMessage',
    defaultMessage: 'The limit of colleagues to receive feedback is 10.',
  },
});
