// @packages
import cn from 'classnames';
import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

// @own
import './styles.scss';
import messages from '../../messages';

const GoalProgressItem = ({
  achieved,
  className,
  intl: { formatMessage },
  progress,
}) => {
  return (
    <div className={cn('goal-progress-item', className, {
      'goal-progress-item--achieved': achieved,
    })}>
      <div className="goal-progress-item__progress">
        {`${progress}%`}
      </div>
      <div className="goal-progress-item__label">
        {achieved
          ? formatMessage(messages.FeedbackDashboardGoalsChartAchieved)
          : formatMessage(messages.FeedbackDashboardGoalsChartRemaining)
        }
      </div>
    </div>
  );
};

GoalProgressItem.propTypes = {
  achieved: PropTypes.bool,
  className: PropTypes.string,
  intl: PropTypes.object.isRequired,
  progress: PropTypes.number.isRequired,
};

export default injectIntl(GoalProgressItem);
