// @packages
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import { createSelector } from 'reselect';

const reducerDomain = 'evaluationDetail';

export const selectIsFetching = (state) =>
  get(state, [reducerDomain, 'isFetching']);

export const selectEvaluationDetail = (state) =>
  get(state, [reducerDomain, 'evaluationDetail']);

export const selectDataCreateAnswerEvaluation = (state) =>
  get(state, [reducerDomain, 'dataCreateAnswerEvaluation']);

export const selectCoCreatedTopics = createSelector(
  selectEvaluationDetail,
  (evaluationDetail) => {
    const ratedTopics = get(evaluationDetail, ['ratedTopics']);
    const coCreatedRatedTopics = get(evaluationDetail, ['coCreated', 'ratedTopics']);
    const ratedTopicsOrdered = orderBy(ratedTopics, ['ordinality']);
    const coCreatedRatedTopicsOrdered = orderBy(coCreatedRatedTopics, ['ordinality']);
    const coCreatedRatedSkills = coCreatedRatedTopicsOrdered.map(ratedTopic => {
      const skills = ratedTopic?.topics?.reduce((accumulator, currentValue) => {
        if (currentValue.skill) {
          currentValue.ignoredRate = true;
          accumulator.push(currentValue);
        }
        return accumulator;
      }, []);
      return { ...ratedTopic, topics: skills };
    });

    const ratedTopicsAll = ratedTopicsOrdered.concat(coCreatedRatedSkills);
    const coCreatedTopics = ratedTopicsAll.reduce((accumulator, currentValue) => {
      const findElementIndex = accumulator.findIndex((element => element.id === currentValue.id));
      if (findElementIndex === -1) {
        accumulator.push({ ...currentValue });
      } else {
        accumulator[findElementIndex].topics = [
          ...accumulator[findElementIndex].topics,
          ...currentValue.topics,
        ];
      }
      return accumulator;
    }, []);
    return coCreatedTopics;
  },
);
