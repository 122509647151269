// @packages
import React from 'react';
import cn from 'classnames';

// @app
import Title from 'components/atoms/Title';

// @own
import './styles.scss';

const RequestInfo = ({
  className,
  noMargin,
  text,
  title,
}) => (
  <div className={cn(className, 'request-info', {
    'request-info--no-margin': noMargin,
  })}>
    <Title
      className="request-info__title"
      black
      level={2}
      size={16}
    >
      {title}
    </Title>
    <div className="request-info__content">
      {text}
    </div>
  </div>
);

export default RequestInfo;
