// @packages
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Popup from 'smu-ui-components/Popup';

// @own
import './styles.scss';

class DescriptionTooltip extends Component {
  constructor() {
    super();
    this.state = {
      open: false,
    };

    this.handleMouseOut = this.handleMouseOut.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { open: wasOpen } = prevState;
    const { open: isOpen } = this.state;
    const { onOpen } = this.props;

    if (!wasOpen && isOpen) {
      onOpen();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.showDescription);
  }

  getTrigger() {
    const { children } = this.props;
    return (
      <span onMouseOut={this.handleMouseOut} onMouseOver={this.handleMouseOver} onMouseDown={this.handleMouseOut}>
        {children}
      </span>
    );
  }

  handleMouseOut() {
    const { open } = this.state;
    if (open) {
      this.setState({ open: false });
    } else {
      clearTimeout(this.showDescription);
    }
  }

  handleMouseOver() {
    this.showDescription = setTimeout(() => this.setState({ open: true }), 500);
  }

  render() {
    const { description, name } = this.props;
    return (
      <Popup
        className="description-tooltip"
        content={description}
        flowing
        header={name && name.toUpperCase()}
        open={this.state.open}
        position="bottom left"
        trigger={this.getTrigger()}
      />
    );
  }
}

DescriptionTooltip.defaultProps = {
  onOpen: () => undefined,
};

DescriptionTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  description: PropTypes.string,
  name: PropTypes.string.isRequired,
  onOpen: PropTypes.func,
};

export default DescriptionTooltip;
