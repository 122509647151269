// @packages
import cn from 'classnames';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import AvatarMedia from 'smu-ui-components/AvatarMedia';

// @app
import ActionButtons from 'components/molecules/ActionButtons';
import TagItem from 'common/TagItem';
import TextParserContainer from 'betterme-components/TextParser';
import { trackFeedbackCompetenciesHover } from '../../Feed/analytics';
import { trackModalUserNameClick, trackModalDeclineButton } from '../analytics';

// @own
import './styles.scss';

const FeedbackRequestCard = ({
  activeTab,
  attachments,
  className,
  comment,
  creationTime,
  disabled,
  messages,
  onAnswer,
  onCancel,
  onDismiss,
  requestedAbout,
  requestedBy,
  topics,
  user,
}) => {
  const typeOf = activeTab === 'RECEIVED'
    ? 'awaiting'
    : 'requested';

  return (
    <div className={cn('feedback-request-card', className, {
      'feedback-request-card--disabled': disabled,
    })}>
      <div className="feedback-request-card__content">
        <div className="feedback-request-card__header">
          <AvatarMedia
            className="feedback-request-card__user"
            onClick={() => trackModalUserNameClick('requested_by')}
            {...user}
          />
          <div className="feedback-request-card__creation-time">
            {moment(creationTime).format('MMM D')}
          </div>
        </div>
        {requestedAbout && (
          <div className="feedback-request-card__requested-about">
            <h3 className="feedback-request-card__requested-about-title">
              {messages.requestedAboutTitle}
            </h3>
            <AvatarMedia
              className="feedback-request-card__requested-about-user"
              {...requestedAbout}
            />
          </div>
        )}
        <div className="feedback-request-card__comment">
          <TextParserContainer>{comment}</TextParserContainer>
        </div>
        {requestedBy && (
          <div className="feedback-request-card__requested-by">
            <p className="feedback-request-card__requested-by-user">
              {messages.requestedByTitle} <strong>{requestedBy.firstName} {requestedBy.lastName}</strong>.
            </p>
          </div>
        )}
        {topics.length > 0 && (
          <div className="feedback-request-card__topics">
            <div className="feedback-request-card__topics-title">
              {messages.topicTitle}
            </div>
            <ul className="feedback-request-card__topics-list">
              {topics.map(topic => (
                <TagItem
                  className={cn('feedback-request-card__topics-list-item', {
                    'feedback-request-card__topics-list-item-skill': topic.skill
                  })}
                  onDescriptionOpen={() => trackFeedbackCompetenciesHover(typeOf, topic)}
                  description={topic.description}
                  inverted
                  key={topic.id}
                  name={topic.name}
                />
              ))}
            </ul>
          </div>
        )}
      </div>
      {(onDismiss || onAnswer || onCancel) && (
        <div className="feedback-request-card__actions">
          <ActionButtons
            buttons={[
              {
                hide: !onDismiss,
                label: messages.decline,
                onClick: () => {
                  onDismiss();
                  trackModalDeclineButton();
                },
                variant: 'outline',
              },
              {
                label: onAnswer
                  ? messages.answer
                  : messages.cancel,
                onClick: onAnswer
                  ? onAnswer
                  : onCancel,
              },
            ]}
          />

        </div>
      )}
    </div>
  );
};

FeedbackRequestCard.propTypes = {
  comment: PropTypes.string,
  creationTime: PropTypes.number,
  topics: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  })),
  requestedAbout: PropTypes.object,
  requestedBy: PropTypes.object,
  user: PropTypes.object.isRequired,
};

export default FeedbackRequestCard;
