// @packages
import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import NumberSelector from 'smu-ui-components/NumberSelector';

// @own
import './styles.scss';

function handleNumberSelectorChange(value, onScoreChange, range) {
  if (value === '' || isContinousScoreValid(value, range)) {
    onScoreChange(value);
  }
}

function isContinousScoreValid(value, range) {
  const validateNumericRegex = /^(\d+)?([.]?\d{0,2})?$/g;
  if (value === undefined || value === null) {
    return false;
  }

  if (value > range.max || value < range.min) {
    return false;
  }
  return validateNumericRegex.test(value);
}

const NumericScoreSelector = ({
  className,
  disabled,
  onChange,
  range,
  value,
}) => {
  return (
    <NumberSelector
      className={cn('numeric-score-selector', className)}
      disabled={disabled}
      max={range.max}
      min={range.min}
      onChange={(event) =>
        handleNumberSelectorChange(event.target.value, onChange, range)
      }
      placeholder={`${range.min} - ${range.max}`}
      value={value}
      step="0.01"
    />
  );
};

NumericScoreSelector.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  range: PropTypes.shape({
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
  }).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
};

export default NumericScoreSelector;
