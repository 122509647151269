// @packages
import { takeLatest, call, put } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import { base64Decode } from 'smu-utils/base64';

// @app
import { getUser } from 'betterme-components/api';

// @own
import {
  getLeadershipAdminUserProfileFail,
  getLeadershipAdminUserProfileSuccess,
} from './actions';
import { GET_LEADERSHIP_ADMIN_USER_PROFILE } from './actionTypes';

function* leadershipAdminWorker({ payload: { identification } }) {
  try {
    const response = yield call(getUser, base64Decode(identification));
    yield put(getLeadershipAdminUserProfileSuccess(response));
  } catch (error) {
    yield put(getLeadershipAdminUserProfileFail(error));
    yield put(addToastMessage({ message: error.message, timeout: 3000 }));
  }
}

export default function* leadershipAdminWatcher() {
  yield takeLatest(GET_LEADERSHIP_ADMIN_USER_PROFILE, leadershipAdminWorker);
}
