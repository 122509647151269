import { defineMessages } from 'react-intl';

export default defineMessages({
  PeriodEvaluationScorePlaceholder: {
    id: 'Betterme.PeriodEvaluation.ScorePlaceholder',
    defaultMessage: 'Choose an overall score for the Evaluation (Required)',
  },
  PeriodEvaluationCommentPlaceholder: {
    id: 'Betterme.PeriodEvaluation.ScoreCommentPlaceholder',
    defaultMessage: 'Leave a message about their performance for this period (Required)',
  },
  PeriodEvaluationLowesRating: {
    id: 'Betterme.PeriodEvaluation.LowestRating',
    defaultMessage: 'Identifying opportunities for improvement is really important for your collaborator\'s career.',
  },
  PeriodEvaluationSuccessMessage: {
    id: 'Betterme.Evaluations.EvaluationSuccessMessage',
    defaultMessage: 'Well done!',
  },
  PeriodEvaluationSuccessFooterMessageTeam: {
    id: 'Betterme.Evaluations.SuccessFooterMessageTeam',
    defaultMessage: 'Your Evaluation to **{username}** was created successfully!',
  },
  PeriodEvaluationOverallScoreFiles: {
    id: 'Betterme.Evaluations.OverallScoreFiles',
    defaultMessage: 'Referring to the overall score',
  },
  PeriodEvaluationDraftSaved: {
    id: 'Betterme.Evaluations.DraftSaved',
    defaultMessage: 'The draft was saved successfully',
  },
  PeriodEvaluationCoEvaluatorDraftSaved: {
    id: 'Betterme.Evaluations.CoEvaluatorDraftSaved',
    defaultMessage: 'Draft was successfully saved! Notification for Co-Creator was sent',
  },
  PeriodEvaluationDraftDeleted: {
    id: 'Betterme.Evaluations.DraftDeleted',
    defaultMessage: 'The draft was deleted successfully.',
  },
  PeriodEvaluationNineBox: {
    id: 'Betterme.Evaluations.NineBox',
    defaultMessage: 'If this is your first time evaluating, disregard the following message. If you are looking for the 9Box, please review the following message.',
  },
  PeriodEvaluationNineBoxDisclaimer: {
    id: 'Betterme.Evaluations.NineBoxDisclaimer',
    defaultMessage: 'From now on **Potential Evaluation will be done once a year**. We are redesigning our talent strategy so the 9Box used until now in this section will be replaced with another tool in another instance.',
  },
  PeriodEvaluationCoCreatorTitle: {
    id: 'Betterme.Evaluations.CoCreatorTitle',
    defaultMessage: 'Technical Co-Evaluator',
  },
  PeriodEvaluationCoCreatorSearchBar: {
    id: 'Betterme.Evaluations.CoCreatorSearchBar',
    defaultMessage: 'Search Co-evaluator',
  },
  PeriodEvaluationCoCreatorNew: {
    id: 'Betterme.Evaluations.CoCreatorNew',
    defaultMessage: 'New',
  },
  PeriodEvaluationCoCreatorDraft: {
    id: 'Betterme.Evaluations.CoCreatorDraft',
    defaultMessage: 'The evaluation must be saved as draft to be able to add a Co-Evaluator',
  },
  PeriodEvaluationCoCreatorEvaluator: {
    id: 'Betterme.Evaluations.CoCreatorEvaluator',
    defaultMessage: 'In order to have a complementary assessment of competencies, skills and score suggested, add another person (e.g Technical Leader or other Leader) as partner in the evaluation',
  },
  PeriodEvaluationCoCreatorSlideOneTitle: {
    id: 'Betterme.Evaluations.CoCreatorSlideOneTitle',
    defaultMessage: 'Let’s work together',
  },
  PeriodEvaluationCoCreatorSlideOneText: {
    id: 'Betterme.Evaluations.CoCreatorSlideOneText',
    defaultMessage: 'You can add only one co-evaluator who will be receiving a notification to provide input about competencies, tech skills and suggested Performance score.',
  },
  PeriodEvaluationCoCreatorSlideTwoTitle: {
    id: 'Betterme.Evaluations.CoCreatorSlideTwoTitle',
    defaultMessage: 'Visibility',
  },
  PeriodEvaluationCoCreatorSlideTwoText: {
    id: 'Betterme.Evaluations.CoCreatorSlideTwoText',
    defaultMessage: 'Your evaluation will be visible only to your team member and current BetterMe leaders. You will be able to see the Technical Leader’s input and decide what to display in the evaluation before submitting it and sending it to the glober.',
  },
  PeriodEvaluationCoCreatorReason: {
    id: 'Betterme.Evaluations.CoCreatorReason',
    defaultMessage: 'If you haven’t added a Co-Evaluator, let us know why <a href={href} target={target}>here</a>.',
  },
  PeriodEvaluationTechnicalSkills: {
    id: 'Betterme.Evaluations.TechnicalSkills',
    defaultMessage: 'Technical Skills',
  },
  PeriodEvaluationTechnicalSuggestion: {
    id: 'Betterme.Evaluations.TechnicalSuggestion',
    defaultMessage: 'Technical Co-Evaluator Evaluation',
  },
  PeriodEvaluationTechnicalSuggestionMessage: {
    id: 'Betterme.Evaluations.TechnicalSuggestionMessage',
    defaultMessage: 'This is only visible to you.',
  },
  PeriodEvaluationTechnicalSuggestionSubtitle: {
    id: 'Betterme.Evaluations.TechnicalSuggestionSubtitle',
    defaultMessage: 'You can either show or hide this',
  },
  PeriodEvaluationYourResponse: {
    id: 'Betterme.Evaluations.YourResponse',
    defaultMessage: 'Your Response',
  },
  PeriodEvaluationSwitch: {
    id: 'Betterme.Evaluations.Switch',
    defaultMessage: 'Include in evaluation',
  },
  PeriodEvaluationCompetenciesEmpty: {
    id: 'Betterme.Evaluations.ComptenciesEmpty',
    defaultMessage: 'The rated competencies will be shown here once available',
  },
  PeriodEvaluationOverallScoreEmpty: {
    id: 'Betterme.Evaluations.OverallScoreEmpty',
    defaultMessage: 'Comment will be shown here once available',
  },
  PeriodEvaluationDeleteCoevaluator: {
    id: 'Betterme.Evaluations.DeleteCoevaluator',
    defaultMessage: 'Create a new draft in order to change/remove the Co-evaluator',
  },
  PeriodEvaluationSelectedCoEvaluator: {
    id: 'Betterme.Evaluations.SelectedCoEvaluator',
    defaultMessage: 'An extention of this form was sent to the following Co-evaluator:',
  },
  PeriodEvaluationCoEvaluatorScore: {
    id: 'Betterme.Evaluations.CoEvaluatorScore',
    defaultMessage: 'Suggested by {username}',
  },
  PeriodEvaluationCoEvaluatorScoreMessage: {
    id: 'Betterme.Evaluations.CoEvaluatorScoreMessage',
    defaultMessage: 'Feeling like having a <b>conversation with the Technical Co-evaluator</b>? An overall score mismatch was detected.',
  },
});