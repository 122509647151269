// @packages
import messages from 'smu-app-components/UserProfile/messages';
import { getWebUrl } from 'smu-utils/globalRequestHelper';
import { hasStarsToSend } from 'smu-app-components/UserProfile/helpers';

// @app
import Link from 'betterme-components/Link';

const starmeupUrl = getWebUrl();

export const helperActionsProps = ({
  actionsList,
  enabledBethere,
  enabledBetterme,
  enabledOneOnOne,
  enabledOneOnOneRequest,
  enabledStarmeup,
  formatMessage,
  member,
  owner,
  remainingStars,
}) => {
  const userUID = member.selectPropertyUID();
  const userIsLeader = member.selectConfigIsLeader();
  const userIsCollaborator = member.selectConfigIsCollaborator();
  const userIsMyLeader = member.selectConfigIsMyLeader();
  const userIsMyCollaborator = member.selectConfigIsMyCollaborator();

  let actions = [];

  if (enabledBetterme) {
    actions = actionsList
      ?.filter((action) => action?.show)
      .map((action) => ({
        children: action?.label,
        disabled: action?.disabled,
        href: action?.to?.pathname,
        onClick: action?.onClick,
        popupDescription: action?.popupDescription,
        showPopup: action?.showPopup,
        state: action?.state,
      }));
  }

  if (enabledStarmeup && hasStarsToSend(remainingStars)) {
    actions.push({
      children: formatMessage(messages.userProfileSendStar),
      href: owner
        ? `${starmeupUrl}/me/sendStar`
        : `${starmeupUrl}/profile/${userUID}/sendStar`,
    });
  }

  if (enabledStarmeup && enabledBethere) {
    actions.push({
      children: formatMessage(messages.userProfileUploadPost),
      href: `${starmeupUrl}/home?modal=upload-post`,
    });
  }

  if (enabledOneOnOne) {
    const getCreateOneOnOneAction = uid => ({
      children: formatMessage(messages.userProfileCreateOneOnOne),
      href: `${window.location.pathname}?modal=create-1on1${owner ? '' : `&uid=${uid}`}`,
    });

    const getRequestOneOnOneAction = uid => ({
      children: formatMessage(messages.userProfileRequestOneOnOne),
      href: `${window.location.pathname}?modal=request-1on1${owner ? '' : `&uid=${uid}`}`,
    });

    if (owner) {
      if (userIsLeader) {
        actions.push(getCreateOneOnOneAction());
      }

      if (enabledOneOnOneRequest && userIsCollaborator) {
        actions.push(getRequestOneOnOneAction());
      }
    } else {
      if (userIsMyCollaborator) {
        actions.push(getCreateOneOnOneAction(userUID));
      }

      if (enabledOneOnOneRequest && userIsMyLeader) {
        actions.push(getRequestOneOnOneAction(userUID));
      }
    }
  }

  return {
    actions: actions,
    components: {
      link: Link,
    },
    moreMessage: formatMessage(messages.userProfileMore),
  };
};

export function getContainerProps({ showPanel }) {
  const props = {};

  if (!showPanel) {
    props.shadow = true;
  }

  return props;
}
