import { takeLatest, call, put, select, all } from 'redux-saga/effects';
import { selectUser } from '../Authorization/selectors';
import { selectLastRequest } from './selectors';
import {
  REQUEST_FEED,
  REQUEST_FEED_SUCCESS,
  REQUEST_FEED_FAIL,
  REQUEST_COMMENTS,
  REQUEST_COMMENTS_SUCCESS,
  REQUEST_COMMENTS_FAIL,
  SEND_COMMENT,
  SEND_COMMENT_SUCCESS,
  SEND_COMMENT_FAIL
} from './actionTypes';

import { getFeedbacks, requestComments, sendComment } from '../api';

const sortFeed = (a, b) => {
  if (a.creationTime > b.creationTime)
    return -1;
  if (a.creationTime < b.creationTime)
    return 1;
  return 0;
};

function* requestFeedWorker({ payload }) {
  try {
    if (!payload) {
      payload = yield select(selectLastRequest);
    }
    const currentUser = yield select(selectUser);
    const user = (payload && payload.userId) ? { id: payload.userId } : currentUser;
    let params = {
      creationTimeFrom: payload.creationTimeFrom,
      creationTimeTo: payload.creationTimeTo,
      ordering: payload.ordering,
      pageNumber: payload.pageNumber,
      pageSize: 20,
    };
    let paramsQuery2 = {};
    let result;
    if (payload && payload.action === 'ALL') {
      params.recipientId = user.id;
      paramsQuery2.senderId = user.id;
      const [received, sent] = yield all([
        call(getFeedbacks, params),
        call(getFeedbacks, paramsQuery2)
      ]);
      result = received.concat(sent);
      result.sort(sortFeed);
    } else {
      if (!payload || payload.action === 'RECEIVED') {
        params.recipientId = user.id;
      } else {
        params.senderId = user.id;
      }
      result = yield call(getFeedbacks, params);
    }
    yield put({ type: REQUEST_FEED_SUCCESS, payload: { ...result, pageNumber: payload.pageNumber } });
  } catch (e) {
    yield put({ type: REQUEST_FEED_FAIL, payload: 'Failed to load your feedbacks' });
  }
}

function* requestCommentsWorker({ payload }) {
  const { feedbackId } = payload;
  try {
    const messages = yield call(requestComments, feedbackId);
    yield put({ type: REQUEST_COMMENTS_SUCCESS, payload: { messages, feedbackId: feedbackId } });
  } catch (e) {
    yield put({ type: REQUEST_COMMENTS_FAIL, payload: { e, feedbackId: feedbackId } });
  }
}

function* sendCommentWorker({ payload }) {
  try {
    const { feedbackId, comment } = payload;
    const data = { message: comment };
    yield call(sendComment, feedbackId, data);
    yield put({ type: SEND_COMMENT_SUCCESS, payload: { feedbackId } });
  } catch (e) {
    yield put({ type: SEND_COMMENT_FAIL, payload: { e } });
  }
}

export default function* requestFeedWatcher() {
  yield takeLatest(REQUEST_FEED, requestFeedWorker);
  yield takeLatest(REQUEST_COMMENTS, requestCommentsWorker);
  yield takeLatest(SEND_COMMENT_SUCCESS, requestCommentsWorker);
  yield takeLatest(SEND_COMMENT, sendCommentWorker);
}
