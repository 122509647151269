import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'smu-ui-components/Checkbox';
import InputArea from 'smu-ui-components/InputArea';
import InputRadio from 'smu-ui-components/InputRadio';
import InputText from 'smu-ui-components/InputText';
import cn from 'classnames';
import { injectIntl } from 'react-intl';

// @app
import messages from 'betterme-components/Profile/EngagementIndicatorForm/Form/messages';
import useForm from 'betterme-components/hooks/form';

// @own
import './styles.scss';
import {
  TYPE_CHECKBOX,
  TYPE_RADIO,
  TYPE_TEXT_AREA,
  TYPE_TEXT,
} from '../constants';

import Question from '../Question';

function Options({
  intl: { formatMessage },
  layout,
  options,
  questionId,
}) {
  const { change, update, values } = useForm();

  function handleChangeRadio(id, text) {
    const ids = options.reduce((acc, cur) => {
      if (cur.type === TYPE_RADIO) {
        acc[cur.id] = undefined;
      }

      if (cur.id === id) {
        acc[cur.id] = {
          questionId,
          value: 'true',
          text,
        }
      }

      return acc;
    }, {});

    update(ids);
  }

  function handleChange(id, value, text) {
    change(id, value, questionId, text)
  }

  const buildOption = (option) => {
    const {
      childQuestions,
      id,
      maximumCharacters,
      hint,
      title,
      type,
    } = option;

    const getOptionByType = {
      [TYPE_CHECKBOX]: (
        <label className="engagement-indicator-options__checkbox-wrapper">
          <Checkbox
            className="engagement-indicator-options__checkbox"
            onChange={() => handleChange(id, !values[id]?.value, messages[title]?.defaultMessage)}
            checked={!!values[id]?.value}
          />
          <span className="engagement-indicator-options__checkbox-span">{messages[title] && formatMessage(messages[title])}</span>
          {values[id] &&
            childQuestions?.map((q) =>
              <Question
                className="engagement-indicator-question--inline"
                isChild={true}
                key={q.id}
                question={{...q, title:"", description:""}}
              />
            )
          }
        </label>
      ),
      [TYPE_RADIO]: (
        <InputRadio
          checked={!!values[id]?.value}
          formFieldClassName="engagement-indicator-options__radio"
          key={id}
          label={messages[title] && formatMessage(messages[title])}
          name={id}
          onChange={() => handleChangeRadio(id, messages[title]?.defaultMessage)}
          value={values[id]?.value}
        />
      ),
      [TYPE_TEXT_AREA]: (
        <InputArea
          value={values[id]?.value}
          onChange={(e) => handleChange(id, e.target.value, e.target.value)}
          maxLength={maximumCharacters}
          placeholder={messages[hint] && formatMessage(messages[hint])}
        />
      ),
      [TYPE_TEXT]: (
        <InputText
          idName={id}
          value={values[id]?.value}
          placeholder={messages[hint] && formatMessage(messages[hint])}
          onChange={(e) => handleChange(id, e.target.value, e.target.value)}
        />
      ),
    };
    return getOptionByType[type];
  };

  useEffect(
    () => () => {
      const ids = options.reduce((acc, cur) => {
        acc[cur?.id] = undefined;

        return acc;
      }, {});

      update(ids);
    },
    []
  );

  return (
    <div
      className={cn('engagement-indicator-options', {
        [`engagement-indicator-options--${layout?.toLowerCase()}`]: layout,
      })}
    >
      {options?.map(buildOption)}
    </div>
  );
}

Options.propTypes = {
  intl: PropTypes.object,
  layout: PropTypes.string,
  options: PropTypes.array,
};

export default injectIntl(Options);
