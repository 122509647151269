// @packages
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import GenericEmptyState from 'smu-ui-components/GenericEmptyState';

// @app
import { feedbackMessages } from 'common/messages';
import getMessageType from 'utils/getMessageType';
import { selectCommunityId } from '../../Authorization/selectors';


const EvaluationListEmptyState = ({
  communityId,
  intl: { formatMessage },
  own,
  userName,
}) => {
  const getMessage = getMessageType(communityId);
  const emptyStateOwnReceivedTitle = getMessage('EvaluationsEmptyStateOwnReceivedTitle');
  const emptyStateOtherReceivedTitle = getMessage('EvaluationsEmptyStateOtherReceivedTitle');
  const texts = {
    own: {
      RECEIVED: {
        title: emptyStateOwnReceivedTitle,
        text: feedbackMessages.FeedbackAskColleagues,
      }
    },
    other: {
      RECEIVED: {
        title: emptyStateOtherReceivedTitle,
        text: feedbackMessages.FeedbackGiveColleagues,
      }
    }
  };
  const view = own ? 'own' : 'other';
  const name = own ? '' : `${userName} `;
  const emptyStateTitle = `${name}${formatMessage(texts[view].RECEIVED.title)}`;
  const emptyStateText = formatMessage(texts[view].RECEIVED.text);

  return (
    <GenericEmptyState
      className="px-5 py-20 bg-white"
      description={emptyStateText}
      image="list"
      limitTextWidth={false}
      size="large"
      title={emptyStateTitle}
    />
  );
};

EvaluationListEmptyState.propTypes = {
  communityId: PropTypes.number.isRequired,
  own: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    communityId: selectCommunityId(state),
  };
};

export default connect(mapStateToProps, null)(injectIntl(EvaluationListEmptyState));
