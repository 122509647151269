// @packages
import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import RootModal from 'smu-app-components/RootModal';

// @app
import GenericModal from 'components/templates/GenericModalTemplate';
import InfoCard from 'components/molecules/InfoCard';
import { SIZE_FIFTY, SIZE_THIRTY_SIX } from 'components/common/constants';
import { actionsMessages, commonMessages } from 'common/messages';
import { EVALUATION_DRAFT_DELETE_MODAL } from '../../constants/modalTypes';

// @own
import messages from '../messages';

const EvaluationDraftAlertDelete = ({
  draftId,
  intl: { formatMessage },
  loadingDeleteDraft,
  onAccept,
  closeModal,
}) => {

  const deleteButtonHandler = () => {
    onAccept(draftId, toastMessage);
    closeModal();
  }
  const toastMessage = {
    success: formatMessage(messages.FeedbackEvaluationsEvaluationCreateDraftDeleted)
  };

  const actionButton = [
    {
      label: formatMessage(actionsMessages.ActionsCancel),
      onClick: closeModal,
      variant: 'outline'
    },
    {
      label: formatMessage(actionsMessages.ActionsDelete),
      onClick: deleteButtonHandler,
    },
  ];

  return (
    <GenericModal
      actionButtons={actionButton}
      header={
        <InfoCard
          icon="info"
          iconSize={SIZE_FIFTY}
          message={formatMessage(messages.FeedbackEvaluationsEvaluationCreateAlertDeleteDraft)}
          title={formatMessage(commonMessages.KeepInMind)}
          titleSize={SIZE_THIRTY_SIX}
        />
      }
      loading={loadingDeleteDraft}
      success
      onClose={() => closeModal()}
    />
  );
};

EvaluationDraftAlertDelete.propTypes = {
  close: PropTypes.func.isRequired,
  draftId: PropTypes.number.isRequired,
  intl: PropTypes.object.isRequired,
  loadingDeleteDraft: PropTypes.bool,
};

const modalStyles = { maxWidth: '600px' };
const withRootModalComponent = RootModal(
  injectIntl(EvaluationDraftAlertDelete),
  EVALUATION_DRAFT_DELETE_MODAL,
  modalStyles,
  'modal__wrapper',
);

export default withRootModalComponent;
