import { defineMessages } from 'react-intl';

export default defineMessages({
  EngagementIndicatorFormBack: {
    defaultMessage: 'User Profile',
    id: 'BetterMe.EngagementIndicatorForm.Back',
  },
  EngagementIndicatorFormTitle: {
    defaultMessage: 'LEADER INTERVENTION',
    id: 'EngagementIndicatorForm.title',
  },
});
