// @packages
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Proptypes from 'prop-types';
import Select from 'smu-ui-components/SelectV2';

// @app
import Title from 'components/atoms/Title';
import { setDataCreateEvaluation } from 'betterme-components/Evaluations/actions';
import { setPerformanceRetrospective } from 'betterme-components/services/PerformanceRetrospective/actions';
import {
  selectDataCreateEvaluation,
  selectCanCreateBehaviourRetrospective,
} from 'betterme-components/Evaluations/selectors';
import { selectConfigurations } from 'betterme-components/Authorization/selectors';
import { selectEvaluationDetail } from 'betterme-components/EvaluationDetail/selectors';
import { evaluationsMessages } from 'common/messages';
import {
  EVALUATION,
  PERFORMANCE_RETROSPECTIVE,
  PERIOD_EVALUATION,
} from 'betterme-components/Evaluations/constants';

const EvaluationCreationSelectType = ({
  allowsEvaluationsCreation,
  allowsGlbFeatures,
  allowsPerformanceRetrospectiveCreation,
  allowsPerformanceRetrospectiveDraft,
  allowsPeriodEvaluation,
  allowsPeriodEvaluationCreation,
  allowsPeriodEvaluationDraft,
  canCreateBehaviourRetrospective,
  dataCreateEvaluation,
  evaluationDetail,
  intl: { formatMessage },
  setDataCreateEvaluation,
  setPerformanceRetrospective,
}) => {
  function handleChangeSelect(name, value) {
    setDataCreateEvaluation({
      [name]: value,
    });
    setPerformanceRetrospective({
      [name]: value,
    });
  }

  const evaluationTypesOptions = [
    {
      isActive: (allowsEvaluationsCreation && allowsPeriodEvaluation),
      label: formatMessage(evaluationsMessages.EvaluationsEvaluation),
      value: EVALUATION,
    },
    {
      isActive: (allowsGlbFeatures && (allowsPeriodEvaluationCreation || allowsPeriodEvaluationDraft)),
      label: formatMessage(evaluationsMessages.EvaluationsEvaluation),
      value: PERIOD_EVALUATION,
    },
    {
      isActive: (allowsPerformanceRetrospectiveCreation || allowsPerformanceRetrospectiveDraft) && canCreateBehaviourRetrospective,
      label: formatMessage(evaluationsMessages.PerformanceRetrospective),
      value: PERFORMANCE_RETROSPECTIVE,
    },
  ];
  const evaluationDraftType = evaluationDetail?.type;
  const evaluationTypesOptionsActive = evaluationTypesOptions?.filter(option => option?.isActive);
  const draftId = dataCreateEvaluation?.draftId;
  const evaluationTypePlaceholder = draftId
    ? dataCreateEvaluation?.type
    : formatMessage(evaluationsMessages.EvaluationsTypeEvaluationPlaceholder);
  const evaluationType = draftId
    ? evaluationDraftType
    : dataCreateEvaluation?.type;

  return (
    <>
      {evaluationTypesOptionsActive?.length > 1 && (
        <div className="mb-6">
          <Title className="text-black" size={16}>
            {formatMessage(evaluationsMessages.EvaluationsTypeEvaluation)}
          </Title>
          <Select
            disabled={draftId}
            isClearable={false}
            onChange={(value) => handleChangeSelect('type', value)}
            options={evaluationTypesOptionsActive}
            placeholder={evaluationTypePlaceholder}
            value={evaluationType}
          />
        </div>
      )}
    </>
  );
};

EvaluationCreationSelectType.propTypes = {
  allowsEvaluationsCreation: Proptypes.bool.isRequired,
  allowsGlbFeatures: Proptypes.bool.isRequired,
  allowsPerformanceRetrospectiveCreation: Proptypes.bool.isRequired,
  allowsPeriodEvaluation: Proptypes.bool.isRequired,
  allowsPeriodEvaluationCreation: Proptypes.bool.isRequired,
  canCreateBehaviourRetrospective: Proptypes.bool,
  dataCreateEvaluation: Proptypes.object.isRequired,
  evaluationDetail: Proptypes.object.isRequired,
  intl: Proptypes.object.isRequired,
  setDataCreateEvaluation: Proptypes.bool.isRequired,
  setPerformanceRetrospective: Proptypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  allowsEvaluationsCreation: selectConfigurations('allowsEvaluationsCreation')(state),
  allowsGlbFeatures: selectConfigurations('allowsGlbFeatures')(state),
  allowsPerformanceRetrospectiveCreation: selectConfigurations('allowsPerformanceRetrospectiveCreation')(state),
  allowsPeriodEvaluation: selectConfigurations('allowsPeriodEvaluation')(state),
  allowsPeriodEvaluationCreation: selectConfigurations('allowsPeriodEvaluationCreation')(state),
  allowsPerformanceRetrospectiveDraft: selectConfigurations('allowsPerformanceRetrospectiveDraft')(state),
  allowsPeriodEvaluationDraft: selectConfigurations('allowsPeriodEvaluationDraft')(state),
  canCreateBehaviourRetrospective: selectCanCreateBehaviourRetrospective(state),
  dataCreateEvaluation: selectDataCreateEvaluation(state),
  evaluationDetail: selectEvaluationDetail(state),
});

const mapDispatchToProps = {
  setDataCreateEvaluation,
  setPerformanceRetrospective,
};

export default connect(mapStateToProps, mapDispatchToProps)
  (injectIntl(EvaluationCreationSelectType));
