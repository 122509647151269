/* eslint-disable react-hooks/exhaustive-deps */
// @packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import orderBy from 'lodash/orderBy';
import { base64Decode } from 'smu-utils/base64';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import { openModal, closeModal } from 'smu-app-components/RootModal/actions';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import MessageBox from 'smu-ui-components/MessageBox';

// @app
import BehaviourSection from 'components/containers/BehaviourSection';
import CoCreatorSection from 'components/templates/CoCreatorSection';
import Evaluation from 'components/templates/EvaluationsTemplate';
import EvaluationDraftAlertDelete from 'betterme-components/Evaluations/EvaluationDraftAlertDelete';
import EvaluationDraftAlertSubmit from 'components/containers/EvaluationDraftAlertSubmit';
import EvaluationWidgets from 'components/containers/EvaluationWidgets';
import EvaluationsButtons from 'components/organisms/EvaluationButtons';
import ScoreSelector from 'components/templates/ScoreSelector';
import SendEvaluationConfirmationModal from 'components/containers/SendEvaluationConfirmationModal';
import SkillsComponent from 'components/containers/SkillsComponent';
import Title from 'components/atoms/Title';
import { MAX_FILES, MAX_FILE_SIZE, SUPPORTED_FORMATS } from 'betterme-components/constants/fileValidations';
import {
  resetPerformanceRetrospectiveBehaviours,
  resetPerformanceRetrospective,
  setPerformanceRetrospective,
} from 'betterme-components/services/PerformanceRetrospective/actions';
import { evaluationsMessages, promotionsMessages } from 'common/messages';
import { resetEvaluationDetail, getEvaluationDetail } from 'betterme-components/EvaluationDetail/actions';
import { resetSelectLeader } from 'betterme-components/services/ChangeLeaderRequest/actions';
import { getProfile, resetProfile } from 'betterme-components/Profile/actions';
import { getTeamEvaluationsLocation } from 'betterme-components/routes/urls';
import { handleAttachFile, handleDeleteFile } from 'utils/attachDeleteEvaluationsFilesHelper';
import { handleCommentChange, handleScoreChange } from 'utils/handleScoreCommentHelper';
import { selectEvaluationDetail, selectIsFetching } from 'betterme-components/EvaluationDetail/selectors';
import { selectEvaluationPeriod, selectConfigurations } from 'betterme-components/Authorization/selectors';
import { selectPerformanceRetrospective } from 'betterme-components/services/PerformanceRetrospective/selectors';
import { selectSurveyQuestions } from 'betterme-components/services/GetSurveyAndRates/selectors';
import { selectProfile, selectLoadingGetProfile } from 'betterme-components/Profile/selectors';
import { selectRatings } from 'betterme-components/services/Layout/selectors';
import { selectSelectedLeader } from 'betterme-components/services/ChangeLeaderRequest/selectors';
import { selectPerformanceRetrospectiveBehaviours } from 'betterme-components/services/PerformanceRetrospective/selectors';
import { createSaveEvaluation } from 'betterme-components/services/EvaluationsCreateSave/actions';
import { selectCreateSaveEvaluationLoading } from 'betterme-components/services/EvaluationsCreateSave/selectors';
import { selectUpdateSubmitEvaluationLoading } from 'betterme-components/services/EvaluationsUpdateSubmit/selectors';
import { updateSubmitEvaluation } from 'betterme-components/services/EvaluationsUpdateSubmit/actions';
import { selectTierType } from 'betterme-components/services/GetTierType/selectors';
import {
  draftFiles,
  deleteFiles,
  insertFiles,
  resetFiles,
  uploadFiles,
} from 'betterme-components/services/UploadFiles/actions';
import { selectFiles, selectFilesLoading } from 'betterme-components/services/UploadFiles/selectors';
import { getLocalStoredItem, removeLocalStoredItem } from 'utils/localStorage';
import { getTierType } from 'betterme-components/services/GetTierType/actions';
import { draftData } from 'utils/draftData';
import { formatPeriodDates } from 'utils/formatPeriodDates';
import {
  deleteDraftEvaluation,
  evaluationAddSkill,
  evaluationCleanSkills,
  resetDataCreateEvaluation,
} from 'betterme-components/Evaluations/actions';
import {
  selectLoadingDeleteDraft,
  selectOptionsScores,
} from 'betterme-components/Evaluations/selectors';
import {
  EVALUATION_DRAFT_DELETE_MODAL,
  EVALUATION_DRAFT_SUBMIT_MODAL,
  SEND_EVALUATION_CONFIRMATION_MODAL,
} from 'betterme-components/constants/modalTypes';
import { resetAssignedGoal } from 'betterme-components/Goals/actions';

// @own
import './styles.scss';
import {
  COCREATED_LINK,
  CREATE_EVALUATION,
  PERFORMANCE_RETROSPECTIVE,
  SUBMIT_DRAFT,
} from './constants';
import messages from './messages';

const PerformanceRetrospective = ({
  addToastMessage,
  allowsPerformanceRetrospectiveCreation,
  allowsPerformanceRetrospectiveDraft,
  behavioursData,
  blockScoreEdition,
  blockDraftDeletion,
  closeModal,
  createSaveEvaluation,
  createSaveEvaluationLoading,
  creationData,
  deleteFiles,
  deleteDraftEvaluation,
  evaluationCleanSkills,
  evaluationAddSkill,
  draftInfo,
  draftFiles,
  evaluationPeriod,
  files,
  getEvaluationDetail,
  getProfile,
  getTierType,
  goToEvaluationsTeam,
  insertFiles,
  intl: { formatMessage },
  draftInfoLoading,
  loadingFiles,
  loadingDeleteDraft,
  loadingProfile,
  openModal,
  optionsScores,
  location: {
    query,
  },
  params,
  profile: evalueeProfile,
  ratings,
  resetAssignedGoal,
  resetDataCreateEvaluation,
  resetPerformanceRetrospectiveBehaviours,
  resetPerformanceRetrospective,
  resetProfile,
  resetEvaluationDetail,
  resetFiles,
  resetSelectLeader,
  selectedLeader,
  setPerformanceRetrospective,
  session,
  surveyQuestions,
  tierType,
  uploadFiles,
  updateSubmitEvaluation,
  updateSubmitEvaluationLoading,
}) => {
  const coCreatedData = creationData?.coCreated;
  const [comment, setComment] = useState('');
  const [score, setScore] = useState('');
  const [showCoevaluatorScore, setShowCoevaluatorScore] = useState(null);
  const [showCoevaluatorSkills, setShowCoevaluatorSkills] = useState(null);
  const [showScoreMessagge, setShowScoreMessagge] = useState(false);
  const [dontGetDetail, setDontGetDetail] = useState(false);

  const endTime = evaluationPeriod?.endTime;
  const evaluationId = params?.evaluationId;
  const hasDraft = !!evaluationId;
  const mainActionText = hasDraft ? SUBMIT_DRAFT : CREATE_EVALUATION;
  const startTime = evaluationPeriod?.startTime;
  const userIdentification = base64Decode(query?.collaboratorIdentification);
  const hasCoEvaluator = coCreatedData?.evaluator?.id;
  const localData = getLocalStoredItem('DATA');
  const hasTitle = !!creationData?.title;
  const infoText = (<span className="text-sm">
    <p className="uppercase">{formatMessage(messages.PerformanceRetrospectiveCoCreatorSlideOneTitle)}</p>
    <p>{formatMessage(messages.PerformanceRetrospectiveCoCreatorSlideOneText)}</p>
    <p className="uppercase">{formatMessage(messages.PerformanceRetrospectiveCoCreatorSlideTwoTitle)}</p>
    <p>{formatMessage(messages.PerformanceRetrospectiveCoCreatorSlideTwoText)}</p>
  </span>);
  const coEvaluatorName = `${coCreatedData?.evaluator?.firstName} ${coCreatedData?.evaluator?.lastName}`;
  const suggestedBy = formatMessage(messages.PerformanceRetrospectiveCoEvaluatorScore,
    { username: coEvaluatorName });
  const coCreatedScoreOptions = optionsScores?.map(score =>
    score?.value === coCreatedData?.score
      ? { ...score, additionalText: `| ${suggestedBy}` }
      : score
  );
  const coEvaluatorRatedSkills = coCreatedData?.ratedTopics?.map(
    topic => {
      const filteredTopics = topic?.topics?.filter((t) => t?.skill)
      return { ...topic, topics: filteredTopics };
    }
  );
  const toastMessageSuccess = formatMessage(messages.PeriodEvaluationDraftSaved);
  const toastMessageCoEvaluator = formatMessage(messages.PeriodEvaluationCoEvaluatorDraftSaved);
  const evaluationDateFrom = (creationData?.dateTimeFrom);
  const evaluationDateTo = (creationData?.dateTimeTo);
  const setEvaluationDateFrom = (query?.collaboratorIdentification && !hasTitle)
    ? draftInfo?.dateTimeFrom
    : creationData?.dateTimeFrom || draftInfo?.dateTimeFrom;
  const setEevaluationDateTo = (query?.collaboratorIdentification && !hasTitle)
    ? draftInfo?.dateTimeTo
    : creationData?.dateTimeTo || draftInfo?.dateTimeTo;
  const setEvaluationTitle = creationData?.title || draftInfo?.title;
  const hasTechnicalInput = coCreatedData?.status === 'SUBMITTED';
  const scoreMessage = (
    <FormattedHTMLMessage
      defaultMessage={messages.PerformanceRetrospectiveCoEvaluatorScoreMessage.defaultMessage}
      id={messages.PerformanceRetrospectiveCoEvaluatorScoreMessage.id}
    />
  );
  const headerPeriod = formatPeriodDates(
    (creationData?.dateTimeFrom || draftInfo?.dateTimeFrom),
    (creationData?.dateTimeTo || draftInfo?.dateTimeTo),
  );

  function handleDeleteDraftModal() {
    openModal({
      modalType: EVALUATION_DRAFT_DELETE_MODAL,
      modalProps: {
        isPerformanceRetrospective: true,
        loadingDeleteDraft: false,
        onAccept: handleDeleteDraft,
      }
    });
  };

  function handleEvaluationDraftSubmitModal() {
    if (!isEmpty(selectedLeader)) {
      openModal({
        modalType: SEND_EVALUATION_CONFIRMATION_MODAL,
        modalProps: {
          isPerformanceRetrospective: true,
          isTechnicalEvaluator: !isEmpty(selectedLeader),
          loading: updateSubmitEvaluationLoading,
          sendEvaluation: handleUpdateSubmitDraft,
        },
      });
    } else {
      openModal({
        modalType: EVALUATION_DRAFT_SUBMIT_MODAL,
        modalProps: {
          showCocreatedMessage: coCreatedData?.evaluator && !hasTechnicalInput,
          isPerformanceRetrospective: true,
          loading: updateSubmitEvaluationLoading,
          onAccept: handleUpdateSubmitDraft,
          onCancel: () => { },
        }
      });
    }
  };

  function handleConfirmationModal() {
    const isTechnicalEvaluator = !isEmpty(selectedLeader);
    openModal({
      modalType: SEND_EVALUATION_CONFIRMATION_MODAL,
      modalProps: {
        isPerformanceRetrospective: true,
        isTechnicalEvaluator,
        sendEvaluation: handleCreateOrSave,
      },
    });
  };

  function createOrSubmitEvaluation() {
    if (hasDraft) {
      handleEvaluationDraftSubmitModal();
    } else {
      handleConfirmationModal();
    }
  };

  function handleBackTo() {
    evaluationCleanSkills();
    goToEvaluationsTeam();
    resetAssignedGoal();
    resetDataCreateEvaluation();
    resetEvaluationDetail();
    resetFiles();
    resetPerformanceRetrospectiveBehaviours();
    resetPerformanceRetrospective();
    resetSelectLeader();
    resetProfile();
    removeLocalStoredItem('DATA');
  };

  function handleCreateOrSave(evaluationStatus) {
    const { coCreated, evaluee, ...dataWithoutCoCreated } = creationData;
    const data = {
      ...dataWithoutCoCreated,
      behaviors: behavioursData,
      coEvaluatorId: selectedLeader?.id || coCreatedData?.evaluator?.id || null,
      comment: comment || '',
      evalueeId: evalueeProfile?.id,
      files: files?.map(file => file?.id) || [],
      flagComment: showCoevaluatorScore || null,
      flagSkills: showCoevaluatorSkills || null,
      score: creationData?.score || 0,
      surveyType: tierType?.type || localData?.type,
      status: evaluationStatus,
      title: setEvaluationTitle,
    };
    const message = evaluationStatus === 'SUBMITTED'
      ? formatMessage(messages.PerformanceRetrospectiveSuccessFooterMessageTeam)
      : !isEmpty(selectedLeader)
        ? toastMessageCoEvaluator
        : toastMessageSuccess;
    createSaveEvaluation({
      data,
      dataEvaluee: {
        identification: evalueeProfile?.identification,
      },
      dataEvaluator: {
        id: session?.user?.id,
        identification: session?.user?.identification,
      },
      evaluationType: PERFORMANCE_RETROSPECTIVE,
      message,
      evaluationPeriod: {
        startTime: evaluationDateFrom,
        endTime: evaluationDateTo,
      },
    });
    evaluationCleanSkills();
    resetSelectLeader();
  };

  function handleUpdateSubmitDraft(evaluationStatus) {
    const { coCreated, evaluee, ...dataWithoutCoCreated } = creationData;
    updateSubmitEvaluation({
      data: {
        ...dataWithoutCoCreated,
        behaviors: behavioursData,
        coEvaluatorId: selectedLeader?.id || coCreatedData?.evaluator?.id || null,
        evalueeId: evalueeProfile?.id,
        files: files?.map(file => file?.id) || [],
        surveyType: tierType?.type || localData?.type,
        status: evaluationStatus,
        title: setEvaluationTitle,
      },
      dataEvaluee: {
        identification: evalueeProfile?.identification,
      },
      dataEvaluator: {
        id: session?.user?.id,
        identification: session?.user?.identification,
      },
      evaluationId: evaluationId,
      evaluationType: PERFORMANCE_RETROSPECTIVE,
      message: evaluationStatus === 'SUBMITTED'
        ? formatMessage(messages.PerformanceRetrospectiveSuccessFooterMessageTeam,
          { username: evalueeProfile.firstName })
        : !isEmpty(selectedLeader)
          ? toastMessageCoEvaluator
          : toastMessageSuccess,
      evaluationPeriod: {
        startTime: evaluationDateFrom,
        endTime: evaluationDateTo,
      },
    });
    resetSelectLeader();
  };

  function handleDeleteDraft() {
    deleteDraftEvaluation({
      id: evaluationId,
      messages: { success: formatMessage(messages.PeriodEvaluationDraftDeleted) },
      typeEvaluation: PERFORMANCE_RETROSPECTIVE,
    });
    setDontGetDetail(!dontGetDetail);
    closeModal();
  };

  function saveOrUpdateDraft() {
    if (hasDraft) {
      handleUpdateSubmitDraft('DRAFT');
    } else {
      handleCreateOrSave('DRAFT');
    }
  };

  function isValidate() {
    const isValidate = behavioursData?.length === surveyQuestions?.length
      && (!isEmpty(comment) || !isEmpty(creationData?.comment))
      && (creationData?.score)
      && (!isEmpty(setEvaluationTitle) || !isEmpty(creationData?.title));
    return isValidate;
  };

  function isValidDraft() {
    if (hasCoEvaluator || !isEmpty(selectedLeader)) {
      return false;
    } else {
      return !isValidate();
    }
  };

  function handleCoEvaluatorScore() {
    setShowCoevaluatorScore(!showCoevaluatorScore);
    setPerformanceRetrospective({
      ...creationData,
      flagComment: !showCoevaluatorScore,
    });
  }

  function handleCoEvaluatorSkills() {
    setShowCoevaluatorSkills(!showCoevaluatorSkills);
    setPerformanceRetrospective({
      ...creationData,
      flagSkills: !showCoevaluatorSkills,
    });
  }

  function handleDraftFiles(id) {
    if (hasDraft) {
      handleDeleteFile(id, draftFiles, closeModal);
    } else {
      handleDeleteFile(id, deleteFiles, closeModal);
    }
  }

  useEffect(() => {
    if (!hasDraft && !hasTitle) {
      goToEvaluationsTeam();
    }
  }, []);

  useEffect(() => {
    getProfile(userIdentification);
  }, [getProfile, userIdentification]);

  useEffect(() => {
    let newDataEvaluation = {
      ...creationData,
      coEvaluatorId: selectedLeader?.id,
    };
    setPerformanceRetrospective({ ...newDataEvaluation });
  }, [selectedLeader]);

  useEffect(() => {
    if (isEmpty(draftInfo) && !isNil(evaluationId) && !dontGetDetail) {
      getEvaluationDetail(evaluationId);
    }
  }, [evaluationId, draftInfo]);

  useEffect(() => {
    const draftInfoData = draftData(draftInfo);
    if (!isEmpty(draftInfo)) {
      setPerformanceRetrospective({
        ...creationData,
        ...draftInfoData,
        dateTimeFrom: setEvaluationDateFrom,
        dateTimeTo: setEevaluationDateTo,
        title: setEvaluationTitle,
      });
      insertFiles(draftInfo?.attachments);
      setShowCoevaluatorScore(draftInfoData?.coCreated?.flagComment);
      setShowCoevaluatorSkills(draftInfoData?.coCreated?.flagSkills);
    } else {
      let newDataEvaluation = {
        ...creationData,
        dateTimeFrom: evaluationDateFrom || startTime,
        dateTimeTo: evaluationDateTo || endTime,
        evalueeId: evalueeProfile?.id,
        promotion: null,
        title: setEvaluationTitle,
        type: PERFORMANCE_RETROSPECTIVE,
      };
      setPerformanceRetrospective({ ...newDataEvaluation });
    }
  }, [draftInfo]);

  useEffect(() => {
    if (creationData?.draftId) {
      const draftSkills = creationData?.ratedTopics
        .filter(topic => topic.competency.skill)
        .map(topic => topic.competency);
      evaluationAddSkill(draftSkills);
    }
    return () => {
      evaluationCleanSkills();
    };
  }, [creationData?.draftId]);

  useEffect(() => {
    if (!tierType && evalueeProfile?.id) {
      getTierType({
        userId: evalueeProfile?.id,
        evaluationType: PERFORMANCE_RETROSPECTIVE,
      });
    }
  }, [evalueeProfile, tierType]);

  useEffect(() => {
    return () => {
      evaluationCleanSkills();
      goToEvaluationsTeam();
      resetAssignedGoal();
      resetDataCreateEvaluation();
      resetEvaluationDetail();
      resetFiles();
      resetPerformanceRetrospectiveBehaviours();
      resetPerformanceRetrospective();
      resetSelectLeader();
      resetProfile();
      removeLocalStoredItem('DATA');
    }
  }, []);

  return (
    <Evaluation
      className="performance-retrospective"
      date={headerPeriod}
      headerValues={[
        formatMessage(evaluationsMessages.EvaluationsEvaluations),
        setEvaluationTitle,
      ]}
      mainContentLoading={draftInfoLoading
        || updateSubmitEvaluationLoading
        || createSaveEvaluationLoading
        || loadingDeleteDraft
        || loadingProfile}
      navigateBack={handleBackTo}
      sideContent={
        <EvaluationWidgets
          dateTimeFrom={startTime}
          dateTimeTo={endTime}
          evaluee={evalueeProfile}
          loading={loadingProfile}
        />
      }
    >
      {blockScoreEdition && (
        <MessageBox
          className="score-selector-template__message-box"
          type="warning"
        >
          {(
            <FormattedHTMLMessage
              defaultMessage={messages.PerformanceRetrospectiveScoreWarning.defaultMessage}
              id={messages.PerformanceRetrospectiveScoreWarning.id}
            />
          )}
        </MessageBox>
      )}

      <Title
        className="mb-6"
        label={1}
        size={22}
      >
        <span className="text-black">
          {evaluationId
            ? formatMessage(messages.PerformanceRetrospectiveEditTitle)
            : formatMessage(messages.PerformanceRetrospectiveTitle)
          }
        </span>
      </Title>

      <CoCreatorSection
        className="mb-6"
        hasCoEvaluator={coCreatedData?.evaluator}
        infoText={infoText}
        placeholder={formatMessage(messages.PerformanceRetrospectiveCoEvaluatorSearch)}
        searchSectionDisclaimer={evaluationId
          ? formatMessage(messages.PerformanceRetrospectiveCoEvaluatorSelected)
          : formatMessage(messages.PerformanceRetrospectiveCoEvaluatorNotSelected)}
        searchSectionReason={
          <FormattedHTMLMessage
            defaultMessage={messages.PerformanceRetrospectiveCoEvaluatorHelp.defaultMessage}
            id={messages.PerformanceRetrospectiveCoEvaluatorHelp.id}
            values={{
              href: COCREATED_LINK,
              target: '_blank',
            }}
          />
        }
        searchSectionText={formatMessage(messages.PerformanceRetrospectiveCoEvaluatorDescription)}
        showClarification={isEmpty(selectedLeader)}
        title={formatMessage(messages.PerformanceRetrospectiveCoEvaluator)}
        user={coCreatedData?.evaluator || selectedLeader}
      />

      <BehaviourSection className="mb-6" />

      <SkillsComponent
        className="mb-6"
        data={creationData}
        hasCoEvaluator={coCreatedData?.evaluator}
        inputSkills={orderBy(coEvaluatorRatedSkills, 'ordinality')}
        onSwitchChange={handleCoEvaluatorSkills}
        showCoevaluatorSkills={showCoevaluatorSkills}
        setData={setPerformanceRetrospective}
        user={evalueeProfile}
      />

      <ScoreSelector
        attachFiles={{
          customText: formatMessage(messages.PerformanceRetrospectiveAttachFile),
          files,
          filesLoading: loadingFiles,
          filesMaxLenght: MAX_FILES,
          onChange: (event) => handleAttachFile({
            addToastMessage,
            evaluationType: creationData?.type,
            event,
            promotionsMessages: {
              promotionsErrorExceededSize: formatMessage(promotionsMessages.PromotionsErrorExceededSize, {
                size: MAX_FILE_SIZE,
              }),
              promotionsErrorNoSize: formatMessage(promotionsMessages.PromotionsErrorNoSize),
              promotionsErrorInvalidFormat: formatMessage(promotionsMessages.PromotionsErrorInvalidFormat, {
                formats: SUPPORTED_FORMATS,
              }),
            },
            uploadEvaluationFile: uploadFiles,
          }),
          onDeleteAccept: (id) => handleDraftFiles(id),
          supportedFormats: SUPPORTED_FORMATS,
        }}
        coEvaluator={{
          coCreatedData: coCreatedData,
          emptyState: formatMessage(messages.PerformanceRetrospectiveCoEvaluatorScoreEmpty),
          onSwitchChange: handleCoEvaluatorScore,
          scoreMessage,
          showCoevaluatorScore: showCoevaluatorScore,
          showScoreMessage: showScoreMessagge,
          switchEnabled: showCoevaluatorScore,
          switchSubtitle: formatMessage(messages.PeriodEvaluationTechnicalSuggestionSubtitle),
          switchText: formatMessage(messages.PeriodEvaluationSwitch),
          switchTitle: formatMessage(messages.PeriodEvaluationTechnicalSuggestion),
        }}
        hasCoevaluator={!isEmpty(coCreatedData)}
        hasTooltip
        scoreSelector={{
          disabled: blockScoreEdition,
          onScoreChange: (value) => handleScoreChange({
            value,
            dataEvaluation: creationData,
            setCoEvaluatorScoreMessagge: setShowScoreMessagge,
            setDataCreateEvaluation: setPerformanceRetrospective,
            setScore,
            coCreatedScore: coCreatedData?.score,
            hasTechnicalInput,
          }),
          scoreOptions: coCreatedScoreOptions,
          scorePlaceholder: formatMessage(messages.PerformanceRetrospectiveSelectRate),
          scoreValue: creationData?.score || score,
        }}
        textArea={{
          comment: creationData?.comment || comment,
          placeholder: formatMessage(messages.PerformanceRetrospectivePlaceholder),
          onTextChange: (event) =>
            handleCommentChange(event, creationData, setPerformanceRetrospective, setComment),
        }}
        title={formatMessage(messages.PerformanceRetrospectiveRate)}
      />

      {!(creationData?.status === 'SUBMITTED') && (
        <EvaluationsButtons
          deleteDraft={{
            deleteDraft: handleDeleteDraftModal,
            isDeleteDraftVisible: !blockDraftDeletion && hasDraft,
          }}
          saveDraft={{
            isDraftDisabled: isValidDraft(),
            isDraftVisible: allowsPerformanceRetrospectiveDraft,
            saveDraft: saveOrUpdateDraft,
          }}
          sendEvaluation={{
            isCreationVisible: allowsPerformanceRetrospectiveCreation,
            isSendEvaluationDisabled: !isValidate(),
            sendEvaluation: createOrSubmitEvaluation,
          }}
          type={mainActionText}
        />
      )}
      <EvaluationDraftAlertDelete />
      <EvaluationDraftAlertSubmit />
      <SendEvaluationConfirmationModal />
    </Evaluation>
  );
}

PerformanceRetrospective.propTypes = {
  creationData: PropTypes.object.isRequired,
  evaluationPeriod: PropTypes.object.isRequired,
  getProfile: PropTypes.func.isRequired,
  goToEvaluationsTeam: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  ratings: PropTypes.object.isRequired,
  selectedLeader: PropTypes.object.isRequired,
  setDataCreateEvaluation: PropTypes.func.isRequired,
};

const mapSateToProps = (state) => ({
  allowsPerformanceRetrospectiveCreation: selectConfigurations('allowsPerformanceRetrospectiveCreation')(state),
  allowsPerformanceRetrospectiveDraft: selectConfigurations('allowsPerformanceRetrospectiveDraft')(state),
  blockDraftDeletion: selectConfigurations('blockDraftDeletion')(state),
  behavioursData: selectPerformanceRetrospectiveBehaviours(state),
  blockScoreEdition: selectConfigurations('blockScoreEdition')(state),
  createSaveEvaluationLoading: selectCreateSaveEvaluationLoading(state),
  draftInfo: selectEvaluationDetail(state),
  evaluationPeriod: selectEvaluationPeriod(state),
  files: selectFiles(state),
  loadingFiles: selectFilesLoading(state),
  draftInfoLoading: selectIsFetching(state),
  loadingDeleteDraft: selectLoadingDeleteDraft(state),
  optionsScores: selectOptionsScores(state),
  creationData: selectPerformanceRetrospective(state),
  profile: selectProfile(state),
  loadingProfile: selectLoadingGetProfile(state),
  ratings: selectRatings(state),
  tierType: selectTierType(state),
  selectedLeader: selectSelectedLeader(state),
  surveyQuestions: selectSurveyQuestions(state),
  updateSubmitEvaluationLoading: selectUpdateSubmitEvaluationLoading(state),
});

const mapDispatchToProps = {
  addToastMessage,
  getProfile,
  getEvaluationDetail,
  setPerformanceRetrospective,
  insertFiles,
  closeModal,
  draftFiles,
  deleteFiles,
  getTierType,
  goToEvaluationsTeam: () => push(getTeamEvaluationsLocation()),
  openModal,
  uploadFiles,
  evaluationAddSkill,
  evaluationCleanSkills,
  createSaveEvaluation,
  updateSubmitEvaluation,
  deleteDraftEvaluation,
  resetAssignedGoal,
  resetEvaluationDetail,
  resetDataCreateEvaluation,
  resetPerformanceRetrospectiveBehaviours,
  resetPerformanceRetrospective,
  resetProfile,
  resetSelectLeader,
  resetFiles,
};

export default connect(
  mapSateToProps,
  mapDispatchToProps
)(injectIntl(PerformanceRetrospective));
