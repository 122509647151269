// @packages
import get from 'lodash/get';

export const selectPeriodByType = ({ periodByType }) => {
  return get(periodByType, 'periodByType');
}

export const selectPeriodByTypeLoading = ({ periodByType }) => {
  return get(periodByType, 'periodByTypeLoading');
}
