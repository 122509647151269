// @packages
import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

// @app
import formatScore from '../../utils/formatScore';

// @own
import './styles.scss';

const GoalScoreSummary = ({ className, comment, name, score, title }) => {
  const summary = {
    score,
    message: name,
  };
  return (
    <div className={cn('goal-score-summary', className)}>
      <div className="goal-score-summary__title">{title}</div>
      {score && <div className="goal-score-summary__name">{formatScore(summary)}</div>}
      <div className="goal-score-summary__comment">{comment}</div>
    </div>
  );
};

GoalScoreSummary.propTypes = {
  className: PropTypes.string,
  comment: PropTypes.string,
  name: PropTypes.string,
  score: PropTypes.number,
  showScore: PropTypes.bool,
};

export default GoalScoreSummary;
