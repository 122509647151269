// @own
import {
  SET_SELF_RETROSPECTIVE,
  SET_SELF_RETROSPECTIVE_FAIL,
  SET_SELF_RETROSPECTIVE_SUCCESS,
  RESET_SELF_RETROSPECTIVE,
  RESET_SELF_RETROSPECTIVE_BEHAVIOURS,
  SET_SELF_RETROSPECTIVE_BEHAVIOURS,
} from './actionTypes';

const initialState = {
  selfRetrospectiveData: {},
  selfRetrospectiveDataLoading: false,
  selfRetrospectiveDataError: null,
  selfRetrospectiveBehaviours: [],
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {

    case SET_SELF_RETROSPECTIVE:
      return {
        ...state,
        selfRetrospectiveData: { ...state.selfRetrospectiveData, ...payload },
        selfRetrospectiveDataLoading: true,
      };

    case SET_SELF_RETROSPECTIVE_FAIL:
      return {
        ...state,
        selfRetrospectiveDataError: payload,
        selfRetrospectiveDataLoading: false,
      };

    case SET_SELF_RETROSPECTIVE_SUCCESS:
      return {
        ...state,
        selfRetrospectiveData: payload,
        selfRetrospectiveDataLoading: false,
      };

    case RESET_SELF_RETROSPECTIVE:
      return {
        ...state,
        selfRetrospectiveData: {},
      };

    case SET_SELF_RETROSPECTIVE_BEHAVIOURS:
      return {
        ...state,
        selfRetrospectiveBehaviours: payload,
      };

    case RESET_SELF_RETROSPECTIVE_BEHAVIOURS:
      return {
        ...state,
        selfRetrospectiveBehaviours: [],
      };

    default:
      return state;
  }
}
