// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import cn from 'classnames';
import Divider from 'smu-ui-components/Divider';
import InputArea from 'smu-ui-components/InputArea';

// @own
import './styles.scss';
import messages from 'betterme-components/SendFeedbackForLeaders/messages';
import { MIN_LENGTH, MAX_LENGTH } from 'betterme-components/SendFeedbackForLeaders/constants';

const InsightsQuestion = ({
  answer,
  className,
  description,
  intl: { formatMessage },
  onChange,
  questionNumber,
  required,
  totalQuestions,
}) => {
  function handleTextChange(event) {
    const { target: { value } } = event;
    onChange(value);
  }

  const placeholderInputArea = required
    ? formatMessage(messages.SendFeedbackForLeadersPlaceholderOpen)
    : formatMessage(messages.SendFeedbackForLeadersPlaceholderOpenOptional);

  return (
    <div className={cn('insights-question', { [className]: className })}>
      <div className="insights-question__header">
        <div className="insights-question__counter">
          {`${questionNumber}
          ${formatMessage(messages.SendFeedbackForLeadersOf)}
          ${totalQuestions}
          ${formatMessage(messages.SendFeedbackForLeadersQuestion)}`}
        </div>
        <div className="insights-question__text">
          {description}
        </div>
      </div>
      <Divider nomargin />
      <div className="insights-question__content">
        <InputArea
          maxLength={MAX_LENGTH}
          onChange={handleTextChange}
          placeholder={placeholderInputArea}
          value={answer?.value || ''}
        />
      </div>
    </div>
  );
};

InsightsQuestion.propTypes = {
  answer: PropTypes.object,
  className: PropTypes.string,
  description: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  questionNumber: PropTypes.number.isRequired,
  required: PropTypes.bool.isRequired,
  totalQuestions: PropTypes.number.isRequired,
}

export default injectIntl(InsightsQuestion);
