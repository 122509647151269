// @packages
import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

// @own
import './styles.scss';

const CardContent = ({
  children,
  className,
  fullPadding,
}) => {
  return (
    <div className={cn('card-content', className, {
      'card-content--full-padding': fullPadding,
    })}>
      {children}
    </div>
  );
};

CardContent.defaultProps = {
  className: '',
  fullPadding: false,
};

CardContent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  fullPadding: PropTypes.bool,
};

export default CardContent;
