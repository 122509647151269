import { defineMessages } from 'react-intl';

export default defineMessages({
    emptyTitle: {
        id: 'Betterme.TechnicalRetrospectivesPage.AccessEmptyTitle',
        defaultMessage: 'It doesn’t seem you have access to this page.',
    },
    emptyDescription: {
        id: 'Betterme.TechnicalRetrospectivesPage.AccessEmptyDescription',
        defaultMessage: 'If you believe you should have permission, please contact your Administrator.',
    },
});
