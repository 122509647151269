// @app
import { handleServerError } from 'betterme-components/api';
import { apiInstance } from 'betterme-components/utils/requestHelper';

export function getChangeLeaderDismissReasons(reasonType) {
  return apiInstance.get(`/api/reasons?type=${reasonType}`)
    .then(({ data }) => data.result)
    .catch(handleServerError);
};

export function putChangeLeader(requestId, data) {
  return apiInstance.put(`api/users/leaders/request/${requestId}`, data)
    .then(({ data }) => data.result)
    .catch(handleServerError);
};
