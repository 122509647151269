// @packages
import { useEffect } from 'react';
import PropTypes from 'prop-types';

const ExternalLinkLayout = ({
  location: {
    query: { link },
  },
}) => {
  useEffect(() => {
    if (link) {
      window.location.assign(link);
    }
  }, [link]);

  return null;
};

ExternalLinkLayout.propTypes = {
  location: PropTypes.shape({
    query: PropTypes.shape({
      link: PropTypes.string,
    }),
  }).isRequired,
};

export default ExternalLinkLayout;
