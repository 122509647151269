// @packages
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getWebUrl } from 'smu-utils/globalRequestHelper';
import { useEffect } from 'react';

// @app
import { ENABLED_STARMEUP } from 'betterme-components/services/CommunityConfigs/constants';
import { getCommunityConfigValueBool } from 'betterme-components/services/CommunityConfigs/selectors';

const Me = ({ enabledStarMeUp, router }) => {
  useEffect(() => {
    const query = window.location.href.split('?')?.[1];

    if (enabledStarMeUp) {
      window.location.href = `${getWebUrl()}/me${query ? `?${query}` : ''}`;
    } else {
      router.push(`/myprofile${query ? `?${query}` : ''}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabledStarMeUp]);

  return null;
};

Me.propTypes = {
  enabledStarMeUp: PropTypes.bool.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  enabledStarMeUp: getCommunityConfigValueBool(state, ENABLED_STARMEUP),
});

export default connect(mapStateToProps)(Me);
