import { defineMessages } from 'react-intl';

export default defineMessages({
  AssignedGoalsDetailAssesments: {
    id: 'Betterme.AssignedGoals.Detail.Assesments',
    defaultMessage: 'Assessments',
  },
  AssignedGoalsDetailScales: {
    id: 'Betterme.AssignedGoals.Detail.Scales',
    defaultMessage: 'Scales',
  },
});
