// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @own
import './styles.scss';

const QuestionText = ({ className, text, type }) => (
  <div
    className={cn(
      'engagement-form-question',
      {
        [`engagement-form-question--${type}`]: type,
      },
      className
    )}
  >
    {text}
  </div>
);

QuestionText.defaultProps = {
  type: 'text',
};

QuestionText.propTypes = {
  className: PropTypes.string,
  text: PropTypes.oneOf(['text', 'title']),
  type: PropTypes.string,
};

export default QuestionText;
