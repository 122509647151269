// @packages
import React, { useState } from 'react';
import Collapsible from 'smu-ui-components/Collapsible';
import InputArea from 'smu-ui-components/InputArea';

// @app
import ScoreSelection from 'components/organisms/ScoreSelection';
import SuggestedAction from 'components/atoms/SuggestedAction';
import Title from 'components/atoms/Title';

// @own
import './styles.scss';

const SuggestedActions = ({
  collapsibleTitle,
  promotionMessage,
  scoreSelection: {
    description,
    disabled,
    disclaimerIcon,
    hasNineBox,
    nineBoxTitle,
    onChange,
    options,
    placeHolder,
    value,
  },
  suggestedActions: {
    disclaimer,
    disclaimerNineBox,
    emptySuggestionsText,
    isEmptySuggestions,
    onSelectedAction,
    suggeestedActionsIds,
    suggestedActions,
    suggestedActionsComment,
    suggestedActionsInputDisabled,
    suggestedActionsPlaceholder,
    suggestedActionsTextChange,
    suggestedActionstitle,
  },
}) => {
  const [open, setOpen] = useState(true);

  return (
    <Collapsible
      baseHeight={850}
      className="suggested-actions-template"
      expanded={open}
      onClick={() => setOpen(!open)}
      title={collapsibleTitle}
      variant="box"
      withIcon
    >
      {open && (
        <div className="suggested-actions-template__content">
          {hasNineBox && (
            <ScoreSelection
              className="suggested-actions-template__score-selection"
              description={description}
              disabled={disabled}
              media={disclaimerIcon}
              onChange={onChange}
              options={options}
              placeholder={placeHolder}
              title={nineBoxTitle}
              value={value}
            />
          )}
          <div className="suggested-actions-template__suggested-actions">
            {suggestedActionstitle && (
              <Title
                black
                className="suggested-actions-template__suggested-actions-title"
                level={3}
                size={16}
              >
                {suggestedActionstitle}
              </Title>
            )}
            <p className="suggested-actions-template__suggested-actions-text">
              <span className="suggested-actions-template__suggested-actions-icon">
                {disclaimerIcon}
              </span>
              {disclaimer}
            </p>
            <div className="mt-3 font-bold font-roboto">{promotionMessage}</div>
          </div>
          {isEmptySuggestions ? (
            <p className="suggested-actions-template__suggested-actions-empty-state">
              {emptySuggestionsText}
            </p>
          ) : (
            <ul className="suggested-actions-template__suggested-actions-list">
              {suggestedActions?.map(suggestedAction => (
                <li
                  className="suggested-actions-template__suggested-actions-list-item"
                  key={suggestedAction?.id}
                >
                  <SuggestedAction
                    {...suggestedAction}
                    selected={suggeestedActionsIds?.includes(suggestedAction?.id)}
                    onChange={() => onSelectedAction(suggestedAction)}
                  />
                </li>
              ))}
            </ul>
          )}
          {
            <InputArea
              disabled={suggestedActionsInputDisabled}
              maxLength={1200}
              onChange={suggestedActionsTextChange}
              placeholder={suggestedActionsPlaceholder}
              value={suggestedActionsComment}
            />
          }
        </div>
      )}
    </Collapsible>
  )
};

export default SuggestedActions;
