// @packages
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { push } from 'react-router-redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Segment from 'smu-ui-components/Segment';
import Tabs from 'smu-ui-components/Tabs';
import withRootModal from 'smu-app-components/RootModal/withRootModal';

// @app
import BreadcrumbHeader from 'components/organisms/BreadcrumbHeader';
import InfiniteScrollLoad from 'common/InfiniteScrollLoad';
import { selectConfigurations } from '../../Authorization/selectors';
import { actionsMessages, feedbackMessages } from 'common/messages';
import { FEEDBACK_REQUEST_DISMISS } from '../../constants/modalTypes';
import { getFeedbackFeedLocation } from '../../routes/urls';

// @own
import FeedbackRequestCard from '../FeedbackRequestCard';
import FeedbackRequestDismissModal from '../FeedbackRequestDismissModal';
import FeedbackRequestsInboxEmptyState from './FeedbackRequestsInboxEmptyState';
import * as actions from '../actions';
import {
  trackTabAwaitingMyFeedback,
  trackTabRequestedByMe,
  trackFeedbackRequestInboxAnswer,
  trackFeedbackRequestInboxDismiss,
  trackFeedbackRequestInboxCancel,
} from '../analytics';
import {
  AWAITING,
  RECEIVED,
  REQUESTED,
  SENT,
} from '../constants';
import messages from '../messages';
import {
  selectActiveTab,
  selectFeedbackRequestPage,
  selectFeedbackRequestReasons,
  selectFeedbacksRequestReceived,
  selectFeedbacksRequestSent,
  selectLoadingCancelId,
  selectLoadingFeedbacksRequests,
  selectLoadingReasons,
  selectSelectedReason,
} from '../selectors';
import './styles.scss';

class FeedbackRequestsInbox extends Component {
  constructor(props) {
    super(props);

    this.handleGetMoreFeedbackRequests = this.handleGetMoreFeedbackRequests.bind(this);
  }

  componentDidMount() {
    const {
      getFeedbackRequestReceived,
      getFeedbackRequestSent,
      location,
      session: { user },
      setActiveTab,
    } = this.props;
    const tab = location?.query?.tab
    const activeTab = (tab === AWAITING || tab === REQUESTED)
      ? tab
      : AWAITING;
    const tabs = { AWAITING: RECEIVED, REQUESTED: SENT };

    if(activeTab === REQUESTED) {
      getFeedbackRequestSent(user.id);
    } else {
      getFeedbackRequestReceived(user.id);
    }

    setActiveTab(tabs[activeTab]);
  }

  componentDidUpdate(prevProps) {
    const { activeTab: prevActiveTab } = prevProps;
    const { activeTab: currentActiveTab } = this.props;

    if (prevActiveTab !== currentActiveTab) {
      this.fetchFeedbackRequests(currentActiveTab);
    }
  }

  componentWillUnmount() {
    const { resetFeedbackRequestState } = this.props;
    resetFeedbackRequestState();
  }

  getActiveTabIndex() {
    const { activeTab } = this.props;
    const tabs = [RECEIVED, SENT];

    return tabs.indexOf(activeTab);
  }

  getFeedbackRequestList() {
    const {
      activeTab,
      allowsRequestedOnBehalfVisibility,
      feedbacksRequestReceived,
      feedbacksRequestSent,
    } = this.props;
    const isReceived = activeTab === RECEIVED;
    const isSent = activeTab === SENT;
    const toCard = (request) => ({
      comment: request.comment,
      creationTime: request.creationTime,
      id: request.requestId,
      topics: request.topics,
      user: isReceived ? request.sender : request.recipient,
      requestedAbout: (isSent && !!request.requestedBy)
        ? request.sender
        : undefined,
      requestedBy: (allowsRequestedOnBehalfVisibility && isReceived)
        ? request.requestedBy
        : undefined,
    });

    const feedbackRequests = isReceived
      ? feedbacksRequestReceived
      : feedbacksRequestSent;

    return feedbackRequests.map(toCard);
  }

  getFeedbackRequestCardMsg() {
    const { intl: { formatMessage } } = this.props;
    return {
      answer: formatMessage(messages.FeedbackRequestInboxTabsAnswer),
      cancel: formatMessage(actionsMessages.ActionsCancel),
      decline: formatMessage(messages.FeedbackRequestInboxTabsDecline),
      dismiss: formatMessage(messages.FeedbackRequestInboxTabsDismiss),
      requestedAboutTitle: formatMessage(feedbackMessages.FeedbackFeedbackRequestedOnBehalf),
      requestedByTitle: formatMessage(feedbackMessages.FeedbackFeedbackRequestedBy),
      topicTitle: formatMessage(messages.FeedbackRequestInboxTabsTopicsTitle),
    };
  }

  getDismissModalProps() {
    const {
      createFeedbackRequestDismiss,
      dismissReasons,
      getRequestDismissReasons,
      intl: { formatMessage },
      loadingReasons,
      selectReason,
      selectedReason,
    } = this.props;

    const texts = {
      accept: formatMessage(messages.FeedbackRequestDismissModalAccept),
      cancel: formatMessage(actionsMessages.ActionsCancel),
      title: formatMessage(messages.FeedbackRequestDismissModalTitle),
      toast: {
        success: formatMessage(messages.FeedbackRequestDismissModalSuccess),
        fail: formatMessage(messages.FeedbackRequestDismissModalFail),
      }
    };

    return {
      createFeedbackRequestDismiss,
      getRequestDismissReasons,
      loadingReasons,
      reasons: dismissReasons,
      selectReason,
      selectedReason,
      texts,
    };
  }

  getTabs() {
    const {
      intl: { formatMessage },
      setActiveTab,
    } = this.props;

    return [
      {
        name: formatMessage(messages.FeedbackRequestInboxTabsReceived),
        onClick: () => { setActiveTab(RECEIVED); trackTabAwaitingMyFeedback(); },
      },
      {
        name: formatMessage(messages.FeedbackRequestInboxTabsSent),
        onClick: () => { setActiveTab(SENT); trackTabRequestedByMe(); },
      },
    ];
  }

  getValues() {
    const {
      intl: { formatMessage },
    } = this.props;

    return [
      formatMessage(feedbackMessages.FeedbackFeedback),
      formatMessage(feedbackMessages.FeedbackRequests),
    ];
  }

  handleAnswer(request) {
    const { goToSendRequestFeedback } = this.props;
    const {
      comment,
      id,
      requestedBy,
      topics,
      user,
    } = request;

    trackFeedbackRequestInboxAnswer();
    goToSendRequestFeedback(user, id, requestedBy, topics, comment);
  }

  handleCancel(request) {
    const {
      createFeedbackRequestCancel,
      intl: { formatMessage },
      session: { user },
    } = this.props;

    const userId = user.id;
    const toastMsg = {
      success: formatMessage(messages.FeedbackRequestInboxTabsCancelSuccess),
    };

    trackFeedbackRequestInboxCancel();
    createFeedbackRequestCancel(request.id, userId, toastMsg);
  }

  handleDismiss(request) {
    const {
      openModal,
      session: { user },
    } = this.props;
    const userId = user.id;

    trackFeedbackRequestInboxDismiss();
    openModal({
      modalType: FEEDBACK_REQUEST_DISMISS,
      modalProps: { requestId: request.id, userId },
    });
  }

  handleGetMoreFeedbackRequests() {
    const {
      activeTab,
      feedbackRequestPage: { number },
      session: { user },
      getFeedbackRequestSent,
      getFeedbackRequestReceived,
    } = this.props;

    if (activeTab === RECEIVED) {
      getFeedbackRequestReceived(user.id, { pageNumber: number + 1 });
    } else if (activeTab === SENT) {
      getFeedbackRequestSent(user.id, { pageNumber: number + 1 });
    }
  }

  fetchFeedbackRequests(activeTab) {
    const {
      getFeedbackRequestSent,
      getFeedbackRequestReceived,
      session: { user },
    } = this.props;
    const userId = user.id;

    if (activeTab === RECEIVED) {
      getFeedbackRequestReceived(userId);
    } else if (activeTab === SENT) {
      getFeedbackRequestSent(userId);
    }
  }

  render() {
    const {
      activeTab,
      allowsFeedbackRequestsDismiss,
      exitFeedbackRequestsInbox,
      loading,
      loadingCancelId,
      feedbackRequestPage: { number, totalPages },
    } = this.props;
    const feedbackRequestsList = this.getFeedbackRequestList();
    const hasFeedbackRequests = feedbackRequestsList.length > 0;
    const hasMoreFeedbackRequests = (number + 1) < totalPages;

    return (
      <div className="feedback-requests-inbox">
        <BreadcrumbHeader
          breadCrumbsProps={{
            active: 1,
            onClick: exitFeedbackRequestsInbox,
            values: this.getValues(),
          }}
          className="evaluations-fullscreen-header"
        />
        <div className="feedback-requests-inbox__feed">
          <Tabs
            active={this.getActiveTabIndex()}
            className="feedback-requests-inbox__tabs"
            tabs={this.getTabs()}
          />
          <Segment
            loading={loading}
            loadingType="betterme"
            withChildren={hasFeedbackRequests}
          >
            {hasFeedbackRequests ? (
              <ol className="feedback-requests-inbox__list">
                <InfiniteScrollLoad
                  dataLength={feedbackRequestsList.length}
                  hasMore={hasMoreFeedbackRequests}
                  loadingType="betterme"
                  next={this.handleGetMoreFeedbackRequests}
                >
                  {feedbackRequestsList.map((request) =>
                    <li className="feedback-requests-inbox__list-item" key={request.id}>
                      <FeedbackRequestCard
                        {...request}
                        activeTab={activeTab}
                        disabled={request.id === loadingCancelId}
                        messages={this.getFeedbackRequestCardMsg()}
                        onAnswer={activeTab === RECEIVED
                          ? () => this.handleAnswer(request)
                          : undefined
                        }
                        onCancel={activeTab === SENT
                          ? () => this.handleCancel(request)
                          : undefined
                        }
                        onDismiss={activeTab === RECEIVED && allowsFeedbackRequestsDismiss
                          ? () => this.handleDismiss(request)
                          : undefined
                        }
                      />
                    </li>
                  )}
                </InfiniteScrollLoad>
              </ol>
            ) : (
              <FeedbackRequestsInboxEmptyState
                type={activeTab}
              />
            )}
          </Segment>
        </div>
        <FeedbackRequestDismissModal
          modalProps={this.getDismissModalProps()}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activeTab: selectActiveTab(state),
  allowsFeedbackRequestsDismiss: selectConfigurations('allowsFeedbackRequestsDismiss')(state),
  allowsRequestedOnBehalfVisibility: selectConfigurations('allowsRequestedOnBehalfVisibility')(state),
  dismissReasons: selectFeedbackRequestReasons(state),
  feedbackRequestPage: selectFeedbackRequestPage(state),
  feedbacksRequestReceived: selectFeedbacksRequestReceived(state),
  feedbacksRequestSent: selectFeedbacksRequestSent(state),
  loading: selectLoadingFeedbacksRequests(state),
  loadingCancelId: selectLoadingCancelId(state),
  loadingReasons: selectLoadingReasons(state),
  selectedReason: selectSelectedReason(state),
});

function goToSendRequestFeedback(user, requestId, requestedBy, initCompetency, comment) {
  const pathname = `/replyfeedback/${requestId}`;
  const locationDescriptor = {
    pathname,
    state: { user, requestId, requestedBy, initCompetency, comment },
  };

  return push(locationDescriptor);
}

const mapDispatchToProps = {
  exitFeedbackRequestsInbox: () => push(getFeedbackFeedLocation()),
  goToSendRequestFeedback,
  ...actions,
};

FeedbackRequestsInbox.propTypes = {
  activeTab: PropTypes.oneOf([RECEIVED, SENT]),
  createFeedbackRequestCancel: PropTypes.func.isRequired,
  createFeedbackRequestDismiss: PropTypes.func.isRequired,
  dismissReasons: PropTypes.array.isRequired,
  exitFeedbackRequestsInbox: PropTypes.func.isRequired,
  feedbackRequestPage: PropTypes.object,
  feedbacksRequestReceived: PropTypes.array.isRequired,
  feedbacksRequestSent: PropTypes.array.isRequired,
  getFeedbackRequestReceived: PropTypes.func.isRequired,
  getFeedbackRequestSent: PropTypes.func.isRequired,
  getRequestDismissReasons: PropTypes.func.isRequired,
  goToSendRequestFeedback: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  loadingCancelId: PropTypes.number,
  loadingReasons: PropTypes.bool,
  openModal: PropTypes.func.isRequired,
  resetFeedbackRequestState: PropTypes.func.isRequired,
  selectReason: PropTypes.func.isRequired,
  selectedReason: PropTypes.number,
  session: PropTypes.shape({
    user: PropTypes.object.isRequired,
  }),
  setActiveTab: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withRootModal(FeedbackRequestsInbox)));
