import { defineMessages } from 'react-intl';

export default defineMessages({
  ChangeLeadersRequestDescription: {
    id: 'Betterme.Messages.ChangeLeadersRequest.Description',
    defaultMessage: '{leader} is requesting you to be the new leader of {collaborator}',
  },
  ChangeLeadersRequestTitle: {
    id: 'Betterme.Messages.ChangeLeadersRequest.Title',
    defaultMessage: 'Leadership Request',
  },
  ChangeLeadersRequestFooterMessage: {
    id: 'Betterme.Messages.ChangeLeadersRequest.FooterMessage',
    defaultMessage: '{collaborator} is your new collaborator',
  },
});
