export const ALL = 'ALL';
export const EVALUATIONS_FILE = 'EVALUATIONS_FILE';
export const LEADERS = 'LEADERS';
export const MAIN = 'MAIN';
export const MAIN_AND_SECONDARY = 'MAIN_AND_SECONDARY';
export const MAX_LENGTH_OPEN_LIST = 5;
export const PEERS = 'PEERS';
export const PROMOTIONS_FILE = 'PROMOTIONS_FILE';
export const REMOVE_LEADER_SEND_EVALUATION = 'REMOVE_LEADER_SEND_EVALUATION';
export const SECONDARY = 'SECONDARY';

// @Evaluation Types
export const CONTINUOUS_EVALUATION = 'CONTINUOUS-EVALUATION';
export const EVALUATION = 'EVALUATION';
export const NH_EVALUATION = 'NH-EVALUATION';
export const NH_SELF_EVALUATION = 'NH-SELF-EVALUATION';
export const PERFORMANCE_RETROSPECTIVE = 'PERFORMANCE_RETROSPECTIVE';
export const PERIOD_EVALUATION = 'PERIOD-EVALUATION';
export const SELF_EVALUATION = 'SELF-EVALUATION';
export const SELF_RETROSPECTIVE = 'SELF_RETROSPECTIVE';
export const TECHNICAL_ASSESSMENT = 'TECHNICAL_ASSESSMENT';

// @Evaluation Status
export const DRAFT = 'DRAFT';
export const FOREIGN_DRAFT = 'FOREIGN_DRAFT';
export const SUBMITTED = 'SUBMITTED';
export const SUBMITTED_BY_ME = 'SUBMITTED_BY_ME';
export const SUBMITTED_BY_OTHER = 'SUBMITTED_BY_OTHER';
