import { takeLatest, call, put } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';

import {
  CREATE_ANSWER_EVALUATION,
  GET_EVALUATION_DETAIL,
} from './actionTypes';
import {
  createAnswerEvaluationSuccess,
  createAnswerEvaluationFail,
  getEvaluationDetail as getEvaluationDetailFailAction,
  getEvaluationDetailSuccess,
  getEvaluationDetailFail,
} from './actions';
import {
  createAnswerEvaluation,
  getEvaluationDetail,
} from '../api';

function* getEvaluationDetailWorker({ payload }) {
  const { evaluationId } = payload;
  try {
    const response = yield call(getEvaluationDetail, evaluationId);
    yield put(getEvaluationDetailSuccess(response));
  } catch (error) {
    yield put(getEvaluationDetailFail(error.message));
    yield put(addToastMessage({ message: error.message, timeout: 3000 }));
  }
}

function* createAnswerEvaluationWorker({ payload }) {
  const { evaluationId, data } = payload;
  try {
    const response = yield call(createAnswerEvaluation, evaluationId, data);
    yield put(createAnswerEvaluationSuccess(response));
    yield put(getEvaluationDetailFailAction(evaluationId));
  } catch (error) {
    yield put(createAnswerEvaluationFail(error.message));
    yield put(addToastMessage({ message: error.message, timeout: 3000 }));
  }
}

export default function* evaluationsWatcher() {
  yield takeLatest(GET_EVALUATION_DETAIL, getEvaluationDetailWorker);
  yield takeLatest(CREATE_ANSWER_EVALUATION, createAnswerEvaluationWorker);
}
