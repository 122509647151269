// @packages
import React from 'react';
import PropTypes from 'prop-types';
import Link from 'smu-ui-components/Link';

// @app
import EmptyStateView from 'common/EmptyStateView';

// @own
import request from './send.svg';
import './styles.scss';

const RequestFeedbackEmptyState = ({
  reasonText,
  title,
  link,
}) => {
  return (
    <EmptyStateView
      className="request-feedback-empty-state"
      image={request}
      text={reasonText}
      title={title}
    >
      {link && (
        <Link
          className="request-feedback-empty-state__link !underline"
          to={link.to}
        >
          {link.text}
        </Link>
      )}
    </EmptyStateView>
  );
};

RequestFeedbackEmptyState.propTypes = {
  reasonText: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.object,
};

export default RequestFeedbackEmptyState;
