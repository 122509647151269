// @packages
import React, { Component } from 'react';
import { connect } from 'react-redux';

// @app
import MyFeedbacks from '../../MyFeedbacks';

// @own
import AccessDeniedEmptyState from '../AccessDeniedEmptyState';

class ProfileFeedback extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { profile, params: { userid } } = this.props;
    const { access: { feedback } } = profile;

    return (
      (profile && feedback) ? (
        <div className="profile-feed">
          <MyFeedbacks user={profile} view="profile" />
        </div>
      ) : (
        <AccessDeniedEmptyState
          user={profile}
          userId={userid}
        />
      )
    );
  }
}

const mapStateToProps = ({ profile }) => ({ profile });

export default connect(mapStateToProps, null)(ProfileFeedback);
