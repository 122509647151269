// @packages
import cn from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Icon from 'smu-ui-components/Icon';
import { base64Encode } from 'smu-utils/base64';
import withFullScreenModal from 'smu-app-components/FullScreenModal/withFullScreenModal';

// @app
import {
  commonMessages,
  feedbackMessages,
  goalsMessages,
  placeholdersMessages,
  suggestedActionsMessages,
} from 'common/messages';
import CardSimple from 'common/CardSimple';
import FeedbackReceivedPie from 'common/FeedbackReceivedPie';
import getMessageType from 'utils/getMessageType';
import DashboardFeedbackSummary from '../../Dashboard/DashboardFeedbackSummary';
import {
  getMyTeamGoalsLocation,
  getNewFeedbackLocation,
  getNewFeedbackRequestOnBehalfLocation,
  getMyTeamPromotionsLocation,
  getTeamEvaluationsLocation,
} from '../../routes/urls';
import { GOAL_DETAIL_VIEW_MODAL } from 'betterme-components/constants/modalTypes'
import OptionsMenu from 'common/OptionsMenuV2';

// @own
import './styles.scss';
import {
  trackFeedbackRequestOnBehalf,
  trackMemberFeedbackReceivedSendFeedbackEmpty,
} from '../analytics';
import messages from '../messages';
import GoalsStatusCounter from '../GoalsStatusCounter';
import StarmeUpValues from '../StarmeUpValues';
import TeamCompetenciesHighlight from '../TeamCompetenciesHighlight';
import TeamMemberAccessDenied from '../TeamMemberAccessDenied';
import TeamUserInfo from '../TeamUserInfo';
import * as actions from '../actions';

class TeamMembers extends Component {
  constructor(props) {
    super();

    this.state = {
      open: props.defaultOpen,
      fetchOnOpen: !props.defaultOpen,
    };
  }

  componentDidMount() {
    const { open } = this.state;

    if (open) {
      this.getTeamMemberStarmeupSummary();
      this.getTeamMemberFeedbackReceived();
      this.getTeamMemberFeedbacksSummary();
      this.getTeamMemberOpportunities();
      this.getTeamMemberStrenghts();
      this.getTeamMemberSummaryGoals();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { endDate: prevEndDate, startDate: prevStartDate } = prevProps;
    const { defaultOpen, endDate: currentEndDate, startDate: currentStartDate } = this.props;
    const { open: wasOpen } = prevState;
    const { open: isOpen, fetchOnOpen } = this.state;
    const hasDateChanged = (prevEndDate !== currentEndDate) || (prevStartDate !== currentStartDate);

    if (hasDateChanged) {
      let newState = {};
      if (defaultOpen) {
        this.getTeamMemberStarmeupSummary();
        this.getTeamMemberFeedbackReceived();
        this.getTeamMemberFeedbacksSummary();
        this.getTeamMemberOpportunities();
        this.getTeamMemberStrenghts();
        this.getTeamMemberSummaryGoals();

        newState = { open: true };
      } else if (isOpen) {
        newState = { open: false, fetchOnOpen: true };
      } else {
        newState = { fetchOnOpen: true };
      }

      this.setState(newState);
    }

    if (!wasOpen && isOpen && fetchOnOpen) {
      this.getTeamMemberStarmeupSummary();
      this.getTeamMemberFeedbackReceived();
      this.getTeamMemberFeedbacksSummary();
      this.getTeamMemberOpportunities();
      this.getTeamMemberStrenghts();
      this.getTeamMemberSummaryGoals();

      this.setState({ fetchOnOpen: false });
    }
  }

  getTeamMemberStarmeupSummary() {
    const {
      endDate,
      getTeamMemberStarmeupSummary,
      startDate,
      user,
    } = this.props;
    const from = startDate ? startDate.valueOf() : undefined;
    const to = endDate ? endDate.valueOf() : undefined;

    getTeamMemberStarmeupSummary(user.id, from, to);
  }

  getTeamMemberFeedbackReceived() {
    const {
      endDate,
      getTeamMemberFeedbackReceived,
      startDate,
      user,
    } = this.props;
    const from = startDate ? startDate.valueOf() : undefined;
    const to = endDate ? endDate.valueOf() : undefined;

    if (this.showFeedbacksWidgets()) {
      getTeamMemberFeedbackReceived(user.id, from, to);
    }
  }

  getTeamMemberOpportunities() {
    const {
      endDate,
      getTeamMemberOpportunities,
      startDate,
      user,
    } = this.props;
    const from = startDate ? startDate.valueOf() : undefined;
    const to = endDate ? endDate.valueOf() : undefined;

    if (this.showFeedbacksWidgets()) {
      getTeamMemberOpportunities(user.id, from, to);
    }
  }

  getTeamMemberStrenghts() {
    const {
      endDate,
      getTeamMemberStrenghts,
      startDate,
      user,
    } = this.props;
    const from = startDate ? startDate.valueOf() : undefined;
    const to = endDate ? endDate.valueOf() : undefined;

    if (this.showFeedbacksWidgets()) {
      getTeamMemberStrenghts(user.id, from, to);
    }
  }

  getTeamMemberSummaryGoals() {
    const {
      endDate,
      getTeamMemberSummaryGoals,
      startDate,
      user,
    } = this.props;
    const from = startDate ? startDate.valueOf() : undefined;
    const to = endDate ? endDate.valueOf() : undefined;

    if (this.showFeedbacksWidgets()) {
      getTeamMemberSummaryGoals(user.id, from, to);
    }
  }

  getTeamMemberFeedbacksSummary() {
    const {
      endDate,
      getTeamMemberFeedbacksSummary,
      startDate,
      user,
    } = this.props;
    const from = startDate ? startDate.valueOf() : undefined;
    const to = endDate ? endDate.valueOf() : undefined;

    if (this.showFeedbacksWidgets()) {
      getTeamMemberFeedbacksSummary(user.id, from, to);
    }
  }

  showFeedbacksWidgets() {
    const { leadershipLevels } = this.props;

    return leadershipLevels.feedback > 0;
  }

  render() {
    const {
      allowsEvaluationDrafts,
      allowsEvaluations,
      allowsEvaluationsCreation,
      allowsFeedbackRequest,
      allowsGoals,
      allowsNewHireEvaluation,
      allowsPeriodEvaluation,
      allowsPeriodEvaluationCreation,
      allowsPeriodEvaluationDraft,
      allowsPromotions,
      allowsRequestedOnBehalf,
      allowsSmuDna,
      allowsGlbFeatures,
      allowsPerformanceRetrospectiveCreation,
      allowsPerformanceRetrospectiveDraft,
      canCreateBehaviourRetrospective,
      communityId,
      enabledLeader,
      engagement,
      feedbacksSummary,
      intl: { formatMessage },
      kudoStats,
      loadingFeedbacksSummary,
      loadingOpportunities,
      loadingStarmeUpValues,
      loadingStrenghts,
      loadingSummaryGoals,
      loadingValueKudos,
      openFullscreenModal,
      opportunities,
      opportunitiesRating,
      ratings,
      sessionUser,
      strengths,
      strengthsRating,
      summaryGoals,
      summaryStarmeUpValues,
      totalStars,
      user,
      valueKudos,
    } = this.props;
    const pieChartConfig = {
      size: 110,
      strokeSize: 16,
    };
    const { open } = this.state;
    const getMessage = getMessageType(communityId);
    const menuCreateEvaluation = getMessage('EvaluationsCreate');
    const sendFeedbackLocation = {
      pathname: `newfeedback/${base64Encode(user.identification)}`,
      state: { user }
    };
    const showFeedbacksWidgets = this.showFeedbacksWidgets();
    const isNewHire = allowsNewHireEvaluation && user.newHireEvalAvailable;
    const { newHireAvailable } = user;
    const evaluationAllowed = user?.evaluation?.evaluationAllowed;
    const allowsClientsEvaluationCreation = allowsPeriodEvaluation;
    const allowsGlbEvaluationCreation = allowsGlbFeatures && (allowsPeriodEvaluationCreation || allowsPeriodEvaluationDraft);
    const allowsPerformanceRetrospective = allowsPerformanceRetrospectiveCreation || allowsPerformanceRetrospectiveDraft;
    const canCreatePerformanceRetrospective = allowsPerformanceRetrospective && enabledLeader;
    const isEvaluationAvailable = (allowsClientsEvaluationCreation || allowsGlbEvaluationCreation) || canCreatePerformanceRetrospective;
    const handleCreateGoalsClick = () => {
      openFullscreenModal({
        modalType: GOAL_DETAIL_VIEW_MODAL,
        modalProps: {
          initialUsers: [user],
          user: sessionUser,
          type: 'CREATE',
        }
      });
    }
    const optionsMenu = [
      {
        label: formatMessage(feedbackMessages.FeedbackSendFeedback),
        show: true,
        to: getNewFeedbackLocation(user, false),
      },
      {
        label: formatMessage(feedbackMessages.FeedbackRequestFeedback),
        show: allowsFeedbackRequest,
        to: getNewFeedbackLocation(user, true),
      },
      {
        label: formatMessage(feedbackMessages.FeedbackRequestFeedbackOnBehalf),
        onClick: trackFeedbackRequestOnBehalf,
        show: allowsRequestedOnBehalf,
        to: getNewFeedbackRequestOnBehalfLocation(user),
      },
      {
        label: formatMessage(goalsMessages.GoalsCreate),
        onClick: handleCreateGoalsClick,
        show: allowsGoals,
        to: getMyTeamGoalsLocation(),
      },
      {
        disabled: !isEvaluationAvailable
          && (allowsEvaluationsCreation || allowsEvaluationDrafts)
          && (allowsPeriodEvaluation || allowsNewHireEvaluation),
        label: formatMessage(menuCreateEvaluation),
        onClick: () => canCreateBehaviourRetrospective(enabledLeader),
        show: allowsEvaluations && (
          (allowsEvaluationsCreation
            || allowsEvaluationDrafts
            || allowsPeriodEvaluation
            || allowsNewHireEvaluation)
          || (allowsPeriodEvaluationCreation || allowsPeriodEvaluationDraft)
          || canCreatePerformanceRetrospective),
        popupDescription: formatMessage(commonMessages.EvaluationsDisabled),
        showPopup: !isEvaluationAvailable,
        to: getTeamEvaluationsLocation({
          openCreateEvaluation: user.id,
          isNewHire,
          openedByUrl: true,
          performanceRetrospective: enabledLeader,
        }).pathname,
      },
      {
        label: formatMessage(suggestedActionsMessages.SuggestActionsPromotionButton),
        show: allowsPromotions,
       to: getMyTeamPromotionsLocation(),
      },
    ];

    const optionsMenuFiltered = optionsMenu.filter(action => action.show);

    return (
      <div className={cn('team-members', {
        'team-members--open': open,
      })}>
        <div className={cn('team-members__info', {
          'team-members__info--new-hire': newHireAvailable,
        })}>
          <TeamUserInfo
            engagement={engagement}
            onClick={() => this.setState({ open: !open })}
            showTooltip={!!engagement?.tooltip}
            user={user}
          />
          <Icon
            className="team-members__info-icon"
            icon={open ? 'angle-up' : 'angle-down'}
            onClick={() => this.setState({ open: !open })}
            size="medium"
          />
          <div className="team-members__options-menu">
            <OptionsMenu
              actions={optionsMenuFiltered}
            />
          </div>
        </div>
        {open && (
          <div className="team-members__content">
            {showFeedbacksWidgets && (
              <div className="team-members__content-summary">
                <DashboardFeedbackSummary
                  feedbackSummary={feedbacksSummary}
                  loading={loadingFeedbacksSummary}
                />
              </div>
            )}
            <div className="team-members__content-widgets">
              <div className="team-members__widget">
                {showFeedbacksWidgets ? (
                  <CardSimple
                    className="team-members__feedback-received"
                    noMargin
                    noPaddingTop
                    title={formatMessage(feedbackMessages.FeedbackFeedbackReceived)}
                    withoutCard
                  >
                    <div className="team-members__feedback-received-pie-wrapper">
                      <FeedbackReceivedPie
                        className="team-members__feedback-received-pie"
                        emptyLinkLabel={formatMessage(feedbackMessages.FeedbackSendFeedback)}
                        emptyMessage={formatMessage(messages.TeamDashboardFeedbackReceivedPieEmptyText)}
                        linkTo={sendFeedbackLocation}
                        onLinkClick={trackMemberFeedbackReceivedSendFeedbackEmpty}
                        pieLabel={formatMessage(feedbackMessages.FeedbackRatings)}
                        pieSize={pieChartConfig.size}
                        pieStrokeSize={pieChartConfig.strokeSize}
                        ratings={ratings}
                        small
                      />
                    </div>
                  </CardSimple>
                ) : (
                  <TeamMemberAccessDenied
                    text={formatMessage(commonMessages.ErrorStateMessage)}
                  />
                )}
              </div>
              <div className="team-members__widget">
                {showFeedbacksWidgets ? (
                  <TeamCompetenciesHighlight
                    loadingOpportunities={loadingOpportunities}
                    loadingStrenghts={loadingStrenghts}
                    opportunities={opportunities}
                    opportunitiesRating={opportunitiesRating}
                    sendFeedbackLocation={sendFeedbackLocation}
                    strengths={strengths}
                    strengthsRating={strengthsRating}
                  />
                ) : (
                  <TeamMemberAccessDenied
                    text={formatMessage(commonMessages.ErrorStateMessage)}
                  />
                )}
              </div>
              {allowsGoals && (
                <div className="team-members__widget">
                  <GoalsStatusCounter
                    loading={loadingSummaryGoals}
                    pieChartConfig={pieChartConfig}
                    summaryGoals={summaryGoals}
                    title={formatMessage(goalsMessages.GoalsSummary)}
                    withoutCard
                  />
                </div>
              )}
              {allowsSmuDna && (
                <div className="team-members__widget team-members__starmeup-values">
                  <StarmeUpValues
                    emptyMesageNoStars={formatMessage(messages.TeamDashboardStarmeUpValuesEmptyMessageNoStars)}
                    emptyMessagePeriod={formatMessage(messages.TeamDashboardStarmeUpValuesEmptyMessagePeriod)}
                    fromText={formatMessage(placeholdersMessages.PlaceholdersFrom)}
                    kudoStats={kudoStats}
                    loadingStarmeUpValues={loadingStarmeUpValues}
                    loadingValueKudos={loadingValueKudos}
                    starsText={formatMessage(messages.TeamDashboardStarmeUpValuesTitleStars)}
                    summaryStarmeUpValues={summaryStarmeUpValues}
                    title={formatMessage(commonMessages.Values)}
                    totalStars={totalStars}
                    valueKudos={valueKudos}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

TeamMembers.defaultProps = {
  ratings: [],
};

TeamMembers.propTypes = {
  allowsContinuousEvaluation: PropTypes.bool,
  allowsEngagementIndicator: PropTypes.bool.isRequired,
  allowsEvaluationDrafts: PropTypes.bool.isRequired,
  allowsEvaluations: PropTypes.bool.isRequired,
  allowsEvaluationsCreation: PropTypes.bool.isRequired,
  allowsFeedbackRequest: PropTypes.bool.isRequired,
  allowsGoals: PropTypes.bool.isRequired,
  allowsNewHireEvaluation: PropTypes.bool.isRequired,
  allowsPeriodEvaluation: PropTypes.bool.isRequired,
  allowsPromotions: PropTypes.bool.isRequired,
  allowsRequestedOnBehalf: PropTypes.bool.isRequired,
  allowsSmuDna: PropTypes.bool.isRequired,
  communityId: PropTypes.number.isRequired,
  endDate: PropTypes.object,
  engagement: PropTypes.shape({
    color: PropTypes.string.isRequired,
    icon: PropTypes.oneOf([
      'engaged',
      'no-data',
      'no-engaged',
      'no-service',
    ]).isRequired,
  }),
  feedbacksSummary: PropTypes.object,
  getTeamMemberFeedbackReceived: PropTypes.func.isRequired,
  getTeamMemberOpportunities: PropTypes.func.isRequired,
  getTeamMemberStrenghts: PropTypes.func.isRequired,
  getTeamMemberSummaryGoals: PropTypes.func.isRequired,
  loadingFeedbacksSummary: PropTypes.bool,
  loadingOpportunities: PropTypes.bool,
  loadingStrenghts: PropTypes.bool,
  loadingSummaryGoals: PropTypes.bool,
  opportunities: PropTypes.array,
  opportunitiesRating: PropTypes.object,
  ratings: PropTypes.array,
  selfUserId: PropTypes.number,
  startDate: PropTypes.object,
  strengths: PropTypes.array,
  strengthsRating: PropTypes.object,
  summaryGoals: PropTypes.object,
  user: PropTypes.object.isRequired,
};

export default withFullScreenModal(connect(null, actions)(TeamMembers));
