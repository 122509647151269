// @packages
import React from 'react';
import PropTypes from 'prop-types';

// @own
import StackedList from '../StackedList';

const CompetencyInfoBoxDescription = ({
  competencies,
  description,
  emptyDescription,
  showEmptyState,
  stacked,
}) => {
  if (showEmptyState) {
    return emptyDescription;
  }

  if (stacked) {
    return <StackedList competencies={competencies} />;
  }

  return description;
};

CompetencyInfoBoxDescription.defaultProps = {
  description: null,
  emptyDescription: null,
};

CompetencyInfoBoxDescription.propTypes = {
  competencies: PropTypes.array.isRequired,
  description: PropTypes.node,
  emptyDescription: PropTypes.node,
  showEmptyState: PropTypes.bool.isRequired,
  stacked: PropTypes.bool,
};

export default CompetencyInfoBoxDescription;
