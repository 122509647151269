// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @own
import EmptyStateCardDescription from './EmptyStateCardDescription';
import EmptyStateCardTitle from './EmptyStateCardTitle';
import './styles.scss';

const EmptyStateCard = ({
  children,
  className,
}) => {
  return (
    <div className={cn('empty-state-card', className)}>
      <div className="empty-state-card__content">
        {children}
      </div>
    </div>
  );
};

EmptyStateCard.propTypes = {
  children: PropTypes.node.isRequired,
};

EmptyStateCard.Description = EmptyStateCardDescription;
EmptyStateCard.Title = EmptyStateCardTitle;

export default EmptyStateCard;
