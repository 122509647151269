// @package
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @app
import StatusItem from 'components/atoms/StatusItem';

// @own
import './styles.scss';

const StatusList = ({
  className,
  onStatusClick,
  list,
}) => (
  <div className={cn('status-list', className)}>
    <ul className="status-list__list">
      {list.map((item, index) =>
        <li className="status-list__list-wrapper" key={index}>
          <StatusItem
            {...item}
            className="status-list__list-actions"
            onStatusClick={onStatusClick}
          />
        </li>
      )}
    </ul>
  </div>
);

StatusList.propTypes = {
  className: PropTypes.string,
  onStatusClick: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired,
};

export default StatusList;
