import { defineMessages } from 'react-intl';

export default defineMessages({
  FeedbackFeedback: {
    id: 'Betterme.Messages.Feedback.Feedback',
    defaultMessage: 'Feedback',
  },
  FeedbackFeedbackPlural: {
    id: 'Betterme.Messages.Feedback.FeedbackPlural',
    defaultMessage: 'Feedback',
  },
  FeedbackRequests: {
    id: 'Betterme.Messages.Feedback.Requests',
    defaultMessage: 'Feedback requests',
  },
  FeedbackSummary: {
    id: 'Betterme.Messages.Feedback.Summary',
    defaultMessage: 'Feedback summary',
  },
  FeedbackRated: {
    id: 'Betterme.Messages.Feedback.Rated',
    defaultMessage: 'Rated',
  },
  FeedbackRatings: {
    id: 'Betterme.Messages.Feedback.Ratings',
    defaultMessage: 'Ratings',
  },
  FeedbackCompetencies: {
    id: 'Betterme.Messages.Feedback.Competencies',
    defaultMessage: 'Competencies',
  },
  FeedbackCompetency: {
    id: 'Betterme.Messages.Feedback.Competency',
    defaultMessage: 'Competency',
  },
  FeedbackRequestFeedback: {
    id: 'Betterme.Messages.Feedback.RequestFeedback',
    defaultMessage: 'Request feedback',
  },
  FeedbackOverview: {
    id: 'Betterme.Messages.Feedback.Overview',
    defaultMessage: 'Feedback Overview',
  },
  FeedbackRequestFeedbackOnBehalf: {
    id: 'Betterme.Messages.Feedback.RequestFeedbackOnBehalf',
    defaultMessage: 'Request Feedback about...',
  },
  FeedbackFeedbackRequestedOnBehalf: {
    id: 'Betterme.Messages.Feedback.FeedbackRequestedOnBehalf',
    defaultMessage: 'Feedback requested about...',
  },
  FeedbackFeedbackRequestedBy: {
    id: 'Betterme.Messages.Feedback.FeedbackRequestedBy',
    defaultMessage: 'Feedback requested by',
  },
  FeedbackSendFeedback: {
    id: 'Betterme.Messages.Feedback.SendFeedback',
    defaultMessage: 'Send feedback',
  },
  FeedbackFeedbackReceived: {
    id: 'Betterme.Messages.Feedback.FeedbackReceived',
    defaultMessage: 'Feedback received',
  },
  FeedbackRequested: {
    id: 'Betterme.Messages.Feedback.Requested',
    defaultMessage: 'Requested',
  },
  FeedbackSent: {
    id: 'Betterme.Messages.Feedback.Sent',
    defaultMessage: 'Sent',
  },
  FeedbackReceived: {
    id: 'Betterme.Messages.Feedback.Received',
    defaultMessage: 'Received',
  },
  FeedbackPending: {
    id: 'Betterme.Messages.Feedback.Pending',
    defaultMessage: 'Pending',
  },
  FeedbackViewDetail: {
    id: 'Betterme.Messages.Feedback.ViewDetail',
    defaultMessage: 'View Detail',
  },
  FeedbackAskColleagues: {
    id: 'Betterme.Messages.Feedback.AskColleagues',
    defaultMessage: 'Ask colleagues for quick input on your performance to help your career grow',
  },
  FeedbackGiveColleagues: {
    id: 'Betterme.Messages.Feedback.GiveColleagues',
    defaultMessage: 'Give colleagues input on their performance to help their careers grow',
  },
  FeedbackNoFeedbacks: {
    id: 'Betterme.Messages.Feedback.NoFeedbacks',
    defaultMessage: 'You haven\'t received any feedback for this period yet',
  },
  FeedbackSentSuccess: {
    id: 'Betterme.Messages.Feedback.SentSuccess',
    defaultMessage: 'Your feedback to {username} was sent successfully',
  },
  FeedbackComparison: {
    id: 'Betterme.Messages.Feedback.Comparison',
    defaultMessage: 'Comparison',
  },
  FeedbackTopStrengths: {
    id: 'Betterme.Messages.Feedback.TopStrengths',
    defaultMessage: 'Top strengths',
  },
  FeedbackOpportunity: {
    id: 'Betterme.Messages.Feedback.Opportunity',
    defaultMessage: 'Opportunity',
  },
  FeedbackOpportunityDescription: {
    id: 'Betterme.Messages.Feedback.OpportunityDescription',
    defaultMessage: '{percentage}% of the ratings received were {rating}',
  },
  FeedbackFeed: {
    id: 'Betterme.Messages.Feedback.Feed',
    defaultMessage: 'Feed',
  },
  FeedbackCompetenciesComparisonTab: {
    id: 'Betterme.Messages.Feedback.CompetenciesComparison',
    defaultMessage: 'Competencies comparison',
  },
  FeedbackSkillsComparisonTab: {
    id: 'Betterme.Messages.Feedback.SkillsComparison',
    defaultMessage: 'Skills comparison',
  },
  FeedbackSkillsTitle: {
    id: 'Betterme.Messages.Feedback.SkillsTitlePieChart',
    defaultMessage: 'Skills',
  },
  FeedbackViewAllFeedbacks: {
    id: 'Betterme.Messages.Feedback.FeedbackViewAllFeedbacks',
    defaultMessage: 'View all feedback',
  },
  FeedbackReceivedDetailButton: {
    id: 'Betterme.Messages.Feedback.FeedbackReceivedDetailButton',
    defaultMessage: 'Detail',
  },
  FeedbackReceivedSkillSearch: {
    id: 'Betterme.Messages.Feedback.FeedbackReceivedSkillSearch',
    defaultMessage: 'Find by name',
  },
  FeedbackForLeadersAnnualAverage: {
    id: 'Betterme.Messages.Feedback.FeedbackForLeadersAnnualAverage',
    defaultMessage: '{year} Annual average',
  },
  FeedbackForLeadersPeriodFirstHalf: {
    id: 'Betterme.Messages.Feedback.FeedbackForLeadersPeriodFirstHalf',
    defaultMessage: '1ST Half',
  },
  FeedbackForLeadersPeriodSecondHalf: {
    id: 'Betterme.Messages.Feedback.FeedbackForLeadersPeriodSecondHalf',
    defaultMessage: '2ND Half',
  },
  FeedbackForLeadersPeriodSurveyed: {
    id: 'Betterme.Messages.Feedback.FeedbackForLeadersPeriodSurveyed',
    defaultMessage: 'Collaborators Surveyed',
  },
  FeedbackForLeadersPeriodAverage: {
    id: 'Betterme.Messages.Feedback.FeedbackForLeadersPeriodAverage',
    defaultMessage: 'Average result',
  },
  FeedbackForLeadersPeriodEmptyState: {
    id: 'Betterme.Messages.Feedback.FeedbackForLeadersPeriodEmptyState',
    defaultMessage: 'Without results',
  },
  FeedbackForLeadersViewDetails: {
    id: 'Betterme.Messages.Feedback.FeedbackForLeadersViewDetails',
    defaultMessage: 'View details',
  },
  FeedbackForLeadersDetails: {
    id: 'Betterme.Messages.Feedback.FeedbackForLeadersDetails',
    defaultMessage: 'Feedback for leaders details',
  },
  FeedbackForLeadersPeriod: {
    id: 'Betterme.Messages.Feedback.FeedbackForLeadersPeriod',
    defaultMessage: 'Period {year}',
  },
  FeedbackForLeadersAverageResults: {
    id: 'Betterme.Messages.Feedback.FeedbackForLeadersAverageResults',
    defaultMessage: 'Average Results',
  },
  FeedbackForLeadersAdditionalComments: {
    id: 'Betterme.Messages.Feedback.FeedbackForLeadersAdditionalComments',
    defaultMessage: 'Additional Comments',
  },
});
