// @packages
import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import Icon from 'smu-ui-components/Icon';

// @own
import './styles.scss';

const Breadcrumbs = ({
  active,
  className,
  date,
  onClick,
  size,
  values,
}) => {
  const itemList = values.map((value, index) => (
      <li
        onClick={index < active ? onClick : undefined}
        className={cn('breadcrumbs__item', {
          'breadcrumbs__item--active': active === index
        })}
        key={index}
      >
        {value}
      </li>
    )
  );

  return (
    <>
      <div className={cn('breadcrumbs', className, {
        'breadcrumbs--big': size === 'big',
      })}>
          <Icon
            className="breadcrumbs__icon-left"
            icon="angle-left"
            onClick={onClick}
            size={26}
          />
          <ul className="breadcrumbs__list">
            {itemList}
          </ul>
      </div>
      {date && (
        <div className="breadcrumbs__period-wrapper">
          <Icon icon="calendar" color="grey" size="medium" />
          <div className="breadcrumbs__period">
            {date}
          </div>
        </div>
      )}
    </>
  );
};

Breadcrumbs.defaultProps = {
  size: 'medium',
};

Breadcrumbs.propTypes = {
  active: PropTypes.number,
  className: PropTypes.string,
  date: PropTypes.object,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['medium', 'big']),
  values: PropTypes.array.isRequired,
};

export default Breadcrumbs;
