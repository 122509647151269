// @packages
import React from 'react';
import PropTypes from 'prop-types';
import RootModal from 'smu-app-components/RootModal';
import { injectIntl } from 'react-intl';

// @app
import GenericModal from 'components/templates/GenericModalTemplate';
import InfoCard from 'components/molecules/InfoCard';
import { GOAL_DETAIL_SUCCESS_CONFIRM_CREATE_MODAL } from 'betterme-components/constants/modalTypes';
import {
  actionsMessages,
  commonMessages,
} from 'common/messages';

// @own
import './styles.scss';
import messages from '../messages';

const SuccessfullGoalsConfirmCreateModal = ({
  close,
  intl: { formatMessage },
  isMyGoals,
}) => {
  const actionButton = [
    {
      label: formatMessage(actionsMessages.ActionsContinue),
      onClick: close,
    },
  ];

  const successMessage = isMyGoals
    ? messages.FeedbackGoalsDetailSuccessModalMyGoalSendValidateMessage
    : messages.FeedbackGoalsDetailSuccessModalSendValidateMessage

  const message = (
    <span className="successful-goal-confirm-create-modal__message">
      {formatMessage(successMessage)}
      <span className="successful-goal-confirm-create-modal__message-disclaimer">
        {formatMessage(messages.FeedbackGoalsDetailSuccessModalCloseFooterMessage)}
      </span>
    </span>
  );
  return (
    <GenericModal
      actionButtons={actionButton}
      className="successful-goal-confirm-create-modal"
      header={
        <InfoCard
          className="successful-goal-confirm-create-modal__content"
          icon="confirm_icon"
          message={message}
          title={formatMessage(commonMessages.WellDone)}
        />
      }
      success
      onClose={close}
    />
  );
}

SuccessfullGoalsConfirmCreateModal.propTypes = {
  close: PropTypes.func,
};

const modalStyles = { maxWidth: '600px' };
const withRootModalComponent = RootModal(
  injectIntl(SuccessfullGoalsConfirmCreateModal),
  GOAL_DETAIL_SUCCESS_CONFIRM_CREATE_MODAL,
  modalStyles,
  'modal__wrapper',
);

export default withRootModalComponent;
