// @packages
import React from 'react';
import PropTypes from 'prop-types';

// @own
import './styles.scss';

const OrgObjectivesList = ({
  orgObjectives,
}) => {
  return (
    <ul className="org-objetives-list">
      {orgObjectives.map(objective =>
        <li
          className="org-objetives-list__name"
          key={objective.id}
        >
          {`- ${objective.name}`}
        </li>
      )}
    </ul>
  );
};

OrgObjectivesList.defaultProps = {
  orgObjectives: [],
};

OrgObjectivesList.propTypes = {
  orgObjectives: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })),
};

export default OrgObjectivesList;
