// @packages
import React from 'react';
import PropTypes from 'prop-types';

// @own
import SelfEvaluationItem from 'components/molecules/SelfEvaluationItem';

const SelfEvaluationList = ({ itemMessages, items }) => {
  return (
    <>
      {items && (
        <>
          {items?.map(({ submissionTime, id, score, title, type }) => (
            <SelfEvaluationItem
              creationTime={submissionTime}
              id={id}
              itemMessages={itemMessages}
              key={id}
              score={score}
              title={title}
              type={type}
            />
          ))}
        </>
      )}
    </>
  );
};

SelfEvaluationList.propTypes = {
  detailFormatMessage: PropTypes.string.isRequired,
  itemMessages: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
};

export default SelfEvaluationList;
