import {
  SET_APP_ERROR
} from './actionTypes';


export function setAppError(message, status) {
  return {
    type: SET_APP_ERROR,
    payload: { message, status },
  };
}
