import {
  GET_GOALS_ASSIGNED,
  GET_GOALS_ASSIGNED_SUCCESS,
  GET_GOALS_ASSIGNED_FAIL,
  GET_GOALS_ASSIGNED_CLOSED,
  GET_GOALS_ASSIGNED_CLOSED_FAIL,
  GET_GOALS_ASSIGNED_CLOSED_SUCCESS,
  GET_GOALS_ASSIGNED_OPENED,
  GET_GOALS_ASSIGNED_OPENED_FAIL,
  GET_GOALS_ASSIGNED_OPENED_SUCCESS,
  RESET_GOALS_ASSIGNED,
} from './actionTypes';

const initialState = {
  goalsAssigned: [],
  goalsAssignedClosed: [],
  goalsAssignedOpened: [],
  loading: false,
  loadingClosed: false,
  loadingOpened: false,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_GOALS_ASSIGNED:
      return { ...state, loading: true, error: false };

    case GET_GOALS_ASSIGNED_SUCCESS:
      return { ...state, loading: false, goalsAssigned: payload.response };

    case GET_GOALS_ASSIGNED_FAIL:
      return { ...state, loading: false, goalsAssigned: [], error: payload.error };

    case GET_GOALS_ASSIGNED_CLOSED:
      return { ...state, loadingClosed: true, error: false };

    case GET_GOALS_ASSIGNED_CLOSED_SUCCESS:
      return { ...state, loadingClosed: false, goalsAssignedClosed: payload.response };

    case GET_GOALS_ASSIGNED_CLOSED_FAIL:
      return { ...state, loadingClosed: false, goalsAssignedClosed: [], error: payload.error };

    case GET_GOALS_ASSIGNED_OPENED:
      return { ...state, loadingOpened: true, error: false };

    case GET_GOALS_ASSIGNED_OPENED_SUCCESS:
      return { ...state, loadingOpened: false, goalsAssignedOpened: payload.response };

    case GET_GOALS_ASSIGNED_OPENED_FAIL:
      return { ...state, loadingOpened: false, goalsAssignedOpened: [], error: payload.error };

    case RESET_GOALS_ASSIGNED:
      return { ...state, goalsAssigned: [], goalsAssignedClosed: [], goalsAssignedOpened: [] };

    default:
      return state;
  }
}
