import React, { useEffect, useRef, useState } from 'react';
import Button from 'smu-ui-components/ButtonV2';
import Icon from 'smu-ui-components/IconV2';
import PropTypes from 'prop-types';
import Segment from 'smu-ui-components/Segment';
import cn from 'classnames';
import { connect } from 'react-redux';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import CarouselCard from 'components/molecules/CarouselCard';
import { ellipsis } from 'utils/ellipsis';
import { setUserCarousel } from 'betterme-components/services/Carousel/actions.js';
import { selectUserCarousel } from 'betterme-components/services/Carousel/selectors.js';
import { selectCommunityConfigurations } from 'betterme-components/Authorization/selectors';
import { trackSelectUser } from 'components/pages/AssessmentHomePage/analytics';
import './styles.scss';
import messages from './messages';

const Carousel = ({
  communityConfig,
  intl: { formatMessage },
  items,
  itemsMargin,
  itemsSpacing,
  loading,
  setUserCarousel,
  targetId,
  setIsUserSelected,
}) => {
  const [actualSection, setActualSection] = useState(1);
  const [disableLeftButton, setDisableLeftButton] = useState(true);
  const [disableRightButton, setDisableRightButton] = useState(false);
  const [itemsPerSection, setItemsPerSection] = useState(0);
  const [scrollAvailable, setScrollAvailable] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [sections, setSections] = useState(1);
  const [selectedCard, setSelectedCard] = useState(targetId);
  const contentRef = useRef();
  const minPeriod = communityConfig?.potentialAssessment?.potentialAssessmentMinMonths;
  const scrollerRef = useRef();
  const singleUser = items?.length === 1;

  const notAvaibleDescription = (
    <FormattedHTMLMessage
      id={messages.CarouselNotAvaibleDescription.id}
      defaultMessage={messages.CarouselNotAvaibleDescription.defaultMessage}
      values={{ period: minPeriod }}
    />
  );

  const notAvaibleDescriptionSingular = (
    <FormattedHTMLMessage
      id={messages.CarouselNotAvaibleDescriptionSingular.id}
      defaultMessage={messages.CarouselNotAvaibleDescriptionSingular.defaultMessage}
      values={{ period: minPeriod }}
    />
  );

  const notAvaibleText = (minPeriod !== 1 ? notAvaibleDescription : notAvaibleDescriptionSingular);

  function toScroll(newScroll, newSection) {
    if (scrollerRef.current) {
      scrollerRef.current.scroll({
        behavior: 'smooth',
        left: newScroll,
      });
    }

    setScrollPosition(newScroll);
    setActualSection(newSection);
    setDisableLeftButton(newScroll === 0);
    setDisableRightButton(newScroll + scrollerRef.current.clientWidth >= contentRef.current.scrollWidth);
  }

  function disableScroll() {
    setScrollAvailable(false);
    const timer = setTimeout(() => setScrollAvailable(true), 500);
    return () => clearTimeout(timer);
  }

  function scrollRight() {
    if (disableRightButton) return;

    const widthChild = contentRef?.current?.firstChild?.offsetWidth;
    const scrollWidth = (widthChild * itemsPerSection) + (itemsSpacing * itemsPerSection);
    const newScroll = actualSection < sections ? (
      actualSection === sections - 1 ? (
        contentRef?.current?.scrollWidth - scrollWidth
      ) : (
        scrollPosition +
        (widthChild * itemsPerSection) +
        (itemsSpacing * itemsPerSection)
      )
    ) : scrollPosition;
    const newSection = actualSection < sections
      ? actualSection + 1
      : actualSection;

    disableScroll();
    toScroll(newScroll, newSection);
  }

  function scrollLeft() {
    if (disableLeftButton) return;

    const widthChild = contentRef?.current?.firstChild?.offsetWidth;
    const newScroll = actualSection !== 2 ? (
      scrollPosition -
      ((widthChild * itemsPerSection) +
        (itemsSpacing * itemsPerSection))
    ) : 0;

    const newSection = actualSection !== 1 ? (
      actualSection - 1
    ) : actualSection;

    disableScroll();
    toScroll(newScroll, newSection);
  }

  useEffect(() => {
    if (!loading) {
      if (scrollerRef.current && contentRef.current) {
        const totalSections = Math.ceil(
          contentRef?.current?.clientWidth / scrollerRef?.current?.clientWidth
        );
        const widthChild = contentRef?.current?.firstChild?.offsetWidth + itemsSpacing;
        const perSection = Math.floor(scrollerRef?.current?.offsetWidth / widthChild);

        setItemsPerSection(perSection);
        setSections(totalSections);
        setDisableRightButton(perSection >= items.length);
      }
    }
  }, [scrollerRef, contentRef, loading, itemsSpacing, items.length]);

  useEffect(() => {
    if (singleUser) {
      setUserCarousel(items[0]);
    } else if (selectedCard) {
      setUserCarousel(items.find(item => item.id === selectedCard));
    }
  }, [items, selectedCard, setUserCarousel, singleUser]);

  useEffect(() => {
    if (targetId && items.length > 0) {
      const targetIndex = items.findIndex(item => item.id === targetId);
      if (targetIndex >= 0) {
        const widthChild = contentRef?.current?.firstChild?.offsetWidth + itemsSpacing;
        const perSection = Math.floor(scrollerRef?.current?.offsetWidth / widthChild);
        const targetSection = Math.ceil((targetIndex + 1) / perSection);
        const newScroll = targetIndex * widthChild - (scrollerRef.current.clientWidth / 2) + (widthChild / 2);

        toScroll(newScroll, targetSection);
      }
    }
  }, [targetId, items, itemsPerSection, itemsSpacing]);

  function handleCardClick(identification, status) {
    const avaibles = status === 'ENABLED_FOR_EVALUATE' || 'IN_PROGRESS';
    const unSelectedUser = selectedCard === identification ? false : true;
    setSelectedCard(
      selectedCard === identification
        ? null
        : identification
    );
    trackSelectUser(unSelectedUser);
    if (identification === targetId) {
      setIsUserSelected(avaibles);
    }
    if (!unSelectedUser) {
      setIsUserSelected(false)
    } else {
      setIsUserSelected(avaibles)
    }
  }

  function getLeadersCards(item, index, highlightFirst) {
    const {
      firstName,
      id,
      job,
      lastName,
      profileImageCode,
      status,
    } = item;
    const evaluated = status === 'EVALUATED';
    const notAvaible = status === 'NOT_AVAILABLE_YET';
    const inProgress = status === 'IN_PROGRESS';
    const maxLength = 20 - firstName.length;
    const isHighlighted = id === selectedCard && !notAvaible && !evaluated;
    const isHighlightedAvatar = !notAvaible && !evaluated && highlightFirst && index === 0;

    return (
      <CarouselCard
        evaluated={evaluated}
        evaluatedLabel={formatMessage(messages.CarouselEvaluated)}
        firstName={firstName}
        highlighted={isHighlighted}
        highlightedAvatar={isHighlightedAvatar}
        inProgress={inProgress}
        inProgressLabel={formatMessage(messages.CarouselInProgress)}
        job={ellipsis(job, 20)}
        key={id}
        lastName={ellipsis(lastName, maxLength)}
        notAvaible={notAvaible}
        notAvaibleDescription={notAvaibleText}
        notAvaibleLabel={formatMessage(messages.CarouselNotAvaible)}
        onClick={() => handleCardClick(id, status)}
        profileImageCode={profileImageCode}
      />
    );
  }

  return (
    <div className="carousel__container">
      <Button
        className={cn('carousel__side-button', 'carousel__side-button--left')}
        size="small"
        variant="outline"
        onClick={scrollAvailable && !disableLeftButton ? scrollLeft : () => { }}
        disabled={disableLeftButton}
      >
        <Icon
          className="carousel__chevron-icon"
          color="gray-dark"
          icon="chevron-left"
          size="small"
        />
      </Button>
      <Segment className="carousel" loading={loading} loadingType="betterme">
        <div className="carousel__scroller" ref={scrollerRef}>
          <div className="carousel__items" ref={contentRef}>
            {items?.map((item, index) => (
              <div
                className={cn('carousel__item')}
                key={item?.id}
                style={{
                  marginRight: itemsMargin,
                  marginLeft: itemsMargin,
                }}
              >
                {getLeadersCards(item, index)}
              </div>
            ))}
          </div>
        </div>
      </Segment>
      <Button
        className={cn('carousel__side-button', 'carousel__side-button--right')}
        size="small"
        variant="outline"
        onClick={scrollAvailable && !disableRightButton ? scrollRight : () => { }}
        disabled={disableRightButton}
      >
        <Icon
          className="carousel__chevron-icon"
          color="gray-dark"
          icon="chevron-right"
          size="small"
        />
      </Button>
    </div>
  );
};

Carousel.defaultProps = {
  itemsMargin: 15,
  itemsSpacing: 30,
  loading: false,
};

Carousel.propTypes = {
  items: PropTypes.array.isRequired,
  itemsMargin: PropTypes.number,
  itemsSpacing: PropTypes.number,
  loading: PropTypes.bool,
  targetId: PropTypes.number,
  setIsUserSelected: PropTypes.func
};

const mapStateToProps = (state) => ({
  userCarousel: selectUserCarousel(state),
  communityConfig: selectCommunityConfigurations(state),
});

const mapDispatchToProps = {
  setUserCarousel,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Carousel));
