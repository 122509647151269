// @own
import {
  GET_SURVEY_QUESTIONS,
  GET_SURVEY_QUESTIONS_SUCCESS,
  GET_SURVEY_QUESTIONS_FAIL,
  GET_SURVEY_RATES,
  GET_SURVEY_RATES_SUCCESS,
  GET_SURVEY_RATES_FAIL,
  RESET_SURVEY_QUESTIONS,
  RESET_SURVEY_RATES,
  RESET_DRAFT_BEHAVIORS,
  SET_DRAFT_BEHAVIORS,
} from './actionTypes';

const initialState = {
  surveyQuestions: [],
  surveyQuestionsError: false,
  surveyQuestionsLoading: false,
  surveyRates: [],
  surveyRatesError: false,
  surveyRatesLoading: false,
  ratedQuestions: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case RESET_SURVEY_QUESTIONS:
      return {
        ...state,
        surveyQuestions: [],
        surveyQuestionsError: false,
      };

    case GET_SURVEY_QUESTIONS:
      return {
        ...state,
        surveyQuestionsLoading: true,
      };

    case GET_SURVEY_QUESTIONS_FAIL:
      return {
        ...state,
        surveyQuestions: payload.error,
        surveyQuestionsError: true,
        surveyQuestionsLoading: false,
      };

    case GET_SURVEY_QUESTIONS_SUCCESS:
      return {
        ...state,
        surveyQuestions: payload,
        surveyQuestionsLoading: false,
      };

    case RESET_SURVEY_RATES:
      return {
        ...state,
        surveyRates: [],
        surveyRatesError: false,
      };

    case GET_SURVEY_RATES:
      return {
        ...state,
        surveyRatesLoading: true,
      };

    case GET_SURVEY_RATES_FAIL:
      return {
        ...state,
        surveyRates: payload.error,
        surveyRatesError: true,
        surveyRatesLoading: false,
      };

    case GET_SURVEY_RATES_SUCCESS:
      return {
        ...state,
        surveyRates: payload,
        surveyRatesLoading: false,
      };

    case SET_DRAFT_BEHAVIORS:
      return {
        ...state,
        ratedQuestions: payload,
      };

    case RESET_DRAFT_BEHAVIORS:
      return {
        ...state,
        ratedQuestions: [],
      };

    default:
      return state;
  }
};
