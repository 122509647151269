// Error types
export const EXCEEDED_SIZE = 'EXCEEDED_SIZE';
export const NO_SIZE = 'NO_SIZE';
export const WRONG_FORMAT = 'WRONG_FORMAT';

// Buisness rules
export const DETAILS_MAX_LENGTH = 280;
export const MAX_FILE_SIZE = 10;
export const MAX_FILES = 10;
export const MAX_FILES_GOAL_UPDATE_PROGRESS = 5;
export const SUPPORTED_FORMATS = [
  '.doc',
  '.docx',
  '.jpeg',
  '.jpg',
  '.pdf',
  '.png',
  '.xls',
  '.xlsx',
];
