// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @app
import ActionButtons from 'components/molecules/ActionButtons';
import HeaderCard from 'components/molecules/HeaderCard';
import Title from 'components/atoms/Title';
import { SIZE_THIRTEEN } from 'components/common/constants';

// @own
import './styles.scss';

const ReplaceLeaderReceived = ({
  actionButtons,
  className,
  sentDate,
  message,
  request: {
    requester,
  },
  title,
}) => (
  <div className={cn('replace-leader-received', className)}>
    <HeaderCard
      className="replace-leader-received__header"
      userAvatarProps={requester}
      date={sentDate}
    />
    {message && (
      <>
        <Title
          className="replace-leader-received__title"
          size={SIZE_THIRTEEN}
        >
          {title}
        </Title>
        <div className="replace-leader-received__message">
          {message}
        </div>
      </>
    )}
    <ActionButtons
      className="replace-leader-received__buttons"
      buttons={actionButtons}
    />
  </div>
);

ReplaceLeaderReceived.defaultProps = {
  actionButtons: [],
};

ReplaceLeaderReceived.propTypes = {
  actionButtons: PropTypes.array.isRequired,
  className: PropTypes.string,
  collaboratorAvatar: PropTypes.object.isRequired,
  comment: PropTypes.string,
  date: PropTypes.string,
  title: PropTypes.string,
};

export default ReplaceLeaderReceived;
