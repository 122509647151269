import {
  UPDATE_SUBMIT_EVALUATION,
  UPDATE_SUBMIT_EVALUATION_FAIL,
  UPDATE_SUBMIT_EVALUATION_SUCCESS,
} from './actionTypes';

export function updateSubmitEvaluation(payload) {
  return {
    type: UPDATE_SUBMIT_EVALUATION,
    payload,
  }
};

export function updateSubmitEvaluationFail(error) {
  return {
    type: UPDATE_SUBMIT_EVALUATION_FAIL,
    payload: { error },
  };
};

export function updateSubmitEvaluationSuccess(response) {
  return {
    type: UPDATE_SUBMIT_EVALUATION_SUCCESS,
    payload: { response },
  };
};
