// @packages
import FileSave from 'file-saver';
import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';

// @app
import {
  getFeedbacksReport,
} from '../api';
import {
  selectCommunityId
} from '../Authorization/selectors';
import getMimeType from 'utils/getMimeType';

// @own
import {
  getFeedbacksReportFail,
  getFeedbacksReportSuccess,
} from './actions';
import {
  GET_FEEDBACKS_REPORT,
} from './actionTypes';

function* getFeedbacksRequestWorker({ payload }) {
  const { fileType } = payload;
  const data = {
    communityId: yield select(selectCommunityId),
    ...payload,
  };
  const mimeType = getMimeType(fileType);

  try {
    const response = yield call(getFeedbacksReport, data);
    const blob = new Blob([response], { 'type': mimeType });
    FileSave.saveAs(blob, `feedbacks_report.${fileType}`);
    yield put(getFeedbacksReportSuccess(URL.createObjectURL(blob)));
  } catch (error) {
    yield put(getFeedbacksReportFail(error.message));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

export default function* feedbackReceivedWatcher() {
  yield takeLatest(GET_FEEDBACKS_REPORT, getFeedbacksRequestWorker);
}
