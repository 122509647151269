// @packages
import { takeLatest, call, put } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import { closeModal, openModal } from 'smu-app-components/RootModal/actions';

// @app
import { removeLeader } from 'betterme-components/services/RemoveLeader/actions';
import {
  FEEDBACK_REQUIRED_MODAL,
  SEND_EVALUATION_REQUIRED_MODAL,
  SUGGEST_LEADER_REQUIRED_MODAL,
} from 'betterme-components/constants/modalTypes';
import { setLocalStoredItem } from 'utils/localStorage';
import { ACTION_TYPE_REQUIRED } from 'components/common/constants';

// @own
import { actionRequired } from './api';
import { ACTION_REQUIRED } from './actionTypes';
import {
  actionRequiredFail,
  actionRequiredSuccess,
} from './actions';
import {
  REMOVE_LEADER_SEND_EVALUATION,
  REMOVE_LEADER_SEND_FEEDBACK,
  REMOVE_LEADER_SUGGEST_LEADER,
} from './constants';

function* actionRequiredWorker({ payload }) {
  const {
    collaborator,
    leaderType,
    reasonId,
    type,
    successMessage,
  } = payload;
  const collaboratorId = collaborator?.id;
  const params = {
    reasonId,
    collaboratorId,
  };

  try {
    const response = yield call(actionRequired, params);
    yield put(actionRequiredSuccess(response));
    const actionTypeRequired = {
      actionType: response?.actionType,
      leaderType: type,
      reasonId: response?.reasonId,
      reasonType: response?.reasonType,
      collaboratorId,
    };
    setLocalStoredItem(ACTION_TYPE_REQUIRED, actionTypeRequired);
    yield put(closeModal());
    switch (response?.actionType) {
      case null:
        yield put(removeLeader({
          collaborator,
          leaderType,
          type,
          reasonId,
          successMessage,
        }));
        break;

      case REMOVE_LEADER_SEND_FEEDBACK:
        yield put(openModal({
          modalType: FEEDBACK_REQUIRED_MODAL,
          modalProps: { user: collaborator },
        }));
        break;
      
      case REMOVE_LEADER_SUGGEST_LEADER:
        yield put(openModal({
          modalType: SUGGEST_LEADER_REQUIRED_MODAL,
          modalProps: { 
            user: collaborator,
            leaderType: type,
          },
        }));
      break;

      case REMOVE_LEADER_SEND_EVALUATION:
        yield put(openModal({
          modalType: SEND_EVALUATION_REQUIRED_MODAL,
          modalProps: {
            user: collaborator,
          },
        }));
        break;

      default:
        return ({});
    }
  } catch (error) {
    yield put(actionRequiredFail(error.message));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
};

export default function* actionRequiredWatcher() {
  yield takeLatest(ACTION_REQUIRED, actionRequiredWorker);
};
