// @packages
import React from 'react';
import PropTypes from 'prop-types';
import Input from 'smu-ui-components/Input';
import Icon from 'smu-ui-components/IconV2';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import Accordion from 'smu-ui-components/Accordion';
import Divider from 'smu-ui-components/Divider';

// @app
import { selectUser } from '../../Authorization/selectors';
import { placeholdersMessages } from 'common/messages';

// @own
import './styles.scss';
import { trackFeedbackSendCommentClick } from '../analytics';
import Item from './Item';

class Comments extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      inputValue: ''
    };
  }


  updateInputValue(ev) {
    this.setState({
      inputValue: ev.target.value
    });
  }

  send() {
    const { sendComment, feedbackId } = this.props;
    const { inputValue } = this.state;
    if (inputValue && inputValue.length) {
      sendComment(feedbackId, inputValue);
      this.setState({
        inputValue: ''
      });
    }
    // TODO: handle errors
  }

  handleClick() {
    const { typeOf } = this.props;
    this.send();
    trackFeedbackSendCommentClick(typeOf);
  }

  handleKeyPress(ev) {
    if (ev.key == 'Enter') {
      this.send();
    }
  }

  render() {
    const {
      comments,
      userSession: { userSessionid },
      loading,
      expanded,
    } = this.props;
    const { inputValue } = this.state;

    return (
      <div className="feedback-comments">
        <Accordion isExpanded={comments && expanded} canBeExpanded={false} extraClass="feedback-comments__accordion">
          <Divider />
          <div className="feedback-comments__accordion-header">
            <div className="feedback-comments__input-wrapper">
              <Input
                className="feedback-comments__input"
                fluid
                inverted
                value={inputValue}
                onChange={this.updateInputValue.bind(this)}
                onKeyPress={this.handleKeyPress.bind(this)}
                placeholder={this.props.intl.formatMessage(placeholdersMessages.PlaceholdersLeaveComment)}
                loading={loading}
                disabled={loading}
                maxLength="1200"
              />
            </div>
            <Icon
              className="feedback-comments__input-icon"
              color="grey"
              icon="send-message"
              onClick={this.handleClick.bind(this)}
              size={16}
            />
          </div>
          <div className="feedback-comments__comments">
            {comments &&
              comments.map(comment => {
                const position = comment.author.id === userSessionid ? 'right' : 'left';
                //TODO: implement smu-ui-component once deployed the new component
                return <Item key={comment.id} position={position} comment={comment} />;
              })
            }
          </div>
        </Accordion>
      </div>
    );
  }
}

Comments.propTypes = {
  expanded: PropTypes.bool,
};

Comments.defaultProps = {
  expanded: false,
  sendComment: PropTypes.func.isRequired,
  userSession: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  userSession: selectUser(state),
});

export default connect(mapStateToProps, null)(injectIntl(Comments));
