export const CLOSED = 'CLOSED';
export const CREATE = 'CREATE';
export const DUPLICATE = 'DUPLICATE';
export const EDIT = 'EDIT';
export const INDIVIDUAL = 'INDIVIDUAL';
export const MAIN_LEADER = 'MAIN_LEADER';
export const OPENED = 'OPENED';
export const PENDING_APPROVAL = 'PENDING_APPROVAL';
export const SECONDARY_LEADER = 'SECONDARY_LEADER';
export const SHARED = 'SHARED';
export const TEAM = 'TEAM';
