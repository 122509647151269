import {
  GET_LEADERSHIP_REQUESTS_RECEIVED,
  GET_LEADERSHIP_REQUESTS_RECEIVED_FAIL,
  GET_LEADERSHIP_REQUESTS_RECEIVED_SUCCESS,
} from './actionTypes';

const initialState = {
  leadershipRequestsPage: {},
  leadershipRequestsReceived: [],
  leadershipRequestsReceivedError: '',
  leadershipRequestsReceivedLoading: false,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_LEADERSHIP_REQUESTS_RECEIVED:
      return {
        ...state,
        leadershipRequestsReceivedLoading: true,
      };

    case GET_LEADERSHIP_REQUESTS_RECEIVED_FAIL:
      return {
        ...state,
        leadershipRequestsReceivedError: payload.error,
        leadershipRequestsReceivedLoading: false,
      };

    case GET_LEADERSHIP_REQUESTS_RECEIVED_SUCCESS:
      const {
        response: { result, page },
      } = payload;
      return {
        ...state,
        leadershipRequestsPage: page,
        leadershipRequestsReceived: page.number
          ? [...state.leadershipRequestsReceived, ...result]
          : result,
        leadershipRequestsReceivedLoading: false,
      }

    default:
      return state;
  }
};
