// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import AvatarMedia from 'smu-ui-components/AvatarMedia';

// @own
import './styles.scss';

const LeadersCard = ({
  className,
  highlightedLeader,
  leadersTitle,
  useLink,
  userAvatarProps,
}) => (
  <div className={cn('leaders-card', className)}>
    <h4 className="leaders-card__title">{leadersTitle}</h4>
    <div className={cn('leaders-card__leader', {
      'leaders-card__leader--highlighted': highlightedLeader,
    })}>
      <AvatarMedia
        className="leaders-card__leader-avatar"
        useLink={useLink}
        {...userAvatarProps}
      />
    </div>
  </div>
);

LeadersCard.defaultProps = {
  useLink: true,
};

LeadersCard.propTypes = {
  className: PropTypes.string,
  highlightedLeader: PropTypes.bool,
  leadersTitle: PropTypes.string.isRequired,
  useLink: PropTypes.bool,
  userAvatarProps: PropTypes.object.isRequired,
};

export default LeadersCard;
