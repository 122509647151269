// @packages
import React from 'react';
import { injectIntl } from 'react-intl';

// @app
import FeatureDiscovery from 'common/FeatureDiscovery';
import { SEND_REQUEST_FEEDBACK } from 'common/FeatureDiscovery/constGroups';
import { COMPETENCY_DESCRIPTION } from 'common/FeatureDiscovery/constTypes';

// @own
import messages from '../messages';

const CompetencyDescriptionFeatureDiscovery = ({ children, intl: { formatMessage } }) => {
  return (
    <FeatureDiscovery
      group={SEND_REQUEST_FEEDBACK}
      position="left"
      text={formatMessage(messages.CompetencyItemFeatureDiscoveryDescription)}
      type={COMPETENCY_DESCRIPTION}
    >
      {children}
    </FeatureDiscovery>
  );
};

export default injectIntl(CompetencyDescriptionFeatureDiscovery);
