import { useEffect } from 'react';
import { useMember } from 'smu-services/os-member/api/v1/community/member';

const CulturalLevelsContainer = () => {
  const { selectPropertyCulturalLevelNumber } = useMember({
    culturalLevel: true,
  });
  const levelNumber = selectPropertyCulturalLevelNumber();

  useEffect(() => {
    if (levelNumber) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        level: levelNumber,
      });
    }
  }, [levelNumber]);

  return null;
};

export default CulturalLevelsContainer;
