// @packages
import { createSelector } from 'reselect';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';

// @own
import { ellipsis } from 'utils/ellipsis';

const reducerDomain = 'feedbackForLeadersReceived';

export const selectFeedbackForLeadersReceived = (state) =>
  get(state, [reducerDomain, 'feedbackForLeadersReceived'], {});

export const selectLoadingFeedbackForLeadersReceived = (state) =>
  get(state, [reducerDomain, 'loadingFeedbackForLeadersReceived']);

export const selectFeedbackForLeadersReceivedCompetencies = (state) =>
  get(state, [reducerDomain, 'feedbackForLeadersReceived', 'competencies'], []);

export const selectFeedbackForLeadersReceivedCompetenciesRates = createSelector(
  selectFeedbackForLeadersReceivedCompetencies,
  (competencies) => (
    competencies?.map(({ ratings }) => {
      const orderedRatings = orderBy(ratings, ['rating.value'], ['asc']);
      const orderedRatingsFilter = orderedRatings?.filter(rate => rate?.rating?.value !== 0);
      return (
        orderedRatingsFilter?.map(rating => rating.count)
      );
    }
    ))
);

export const selectFeedbackForLeadersReceivedCompetenciesList = createSelector(
  selectFeedbackForLeadersReceivedCompetencies,
  (competencies) => (
    competencies.map(({ competency }) => ({
      description: competency?.description,
      displayValue: competency?.name,
      value: competency?.id,
    }))
  ),
);

export const selectFeedbackForLeadersReceivedChartCompetencies = createSelector(
  selectFeedbackForLeadersReceivedCompetencies,
  (competencies) => (
    competencies.map(competency =>
      `${competency.competency.name} [${competency.totalRate}]`
    )
  ),
);

export const selectFeedbackForLeadersReceivedInsights = (state) =>
  get(state, [reducerDomain, 'feedbackForLeadersReceived', 'insights'], []);

export const selectFeedbackForLeadersReceivedInsightsList = createSelector(
  selectFeedbackForLeadersReceivedInsights,
  (insights) => (
    insights
      .filter(insight => insight.answers.length > 0)
      .map(insight => ({
        description: insight.question?.name,
        displayValue: ellipsis(insight.question?.name, 45),
        value: insight.question?.id,
      }))
  )
);
