// @packages
import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import AvatarMedia from 'smu-ui-components/AvatarMedia';
import Popup from 'smu-ui-components/Popup';
import Label from 'smu-ui-components/Label';

// @app
import { commonMessages } from 'common/messages';
import { formatBoldText } from 'utils/formatBoldText';

// @own
import './styles.scss';
import messages from '../messages';
import { trackTeamMemberProfileClick } from '../analytics';

const TeamUserInfo = ({
  engagement,
  intl: { formatMessage },
  onClick,
  showTooltip,
  user: {
    account,
    area,
    firstName,
    identification,
    job,
    lastName,
    leftDaysNewHire,
    newHireAvailable,
    office,
    profileImageCode,
    project,
  },
}) => {
  const newHireDaysLeft = (leftDaysNewHire === 1)
    ? formatBoldText(formatMessage(commonMessages.NewHireDayLeft,
      {count: leftDaysNewHire},
    ))
    : formatBoldText(formatMessage(commonMessages.NewHireDaysLeft,
      {count: leftDaysNewHire},
    ));
  const nhLabelNode = (
    <span className="team-user-info__avatar--content-label">
      <Label
        color="tag-user-nh"
        size="small"
        text={formatMessage(commonMessages.NewHire)}
      />
      <p className="team-user-info__avatar--content-label-text">
        {newHireDaysLeft}
      </p>
    </span>
  );
  const getAvatarMedia = () => (
    <span className={cn('team-user-info__avatar--content', {
      'team-user-info__avatar--new-hire': newHireAvailable,
    })}>
      <AvatarMedia
        className="team-user-info__avatar--content-user"
        engagement={engagement}
        firstName={firstName}
        identification={identification}
        lastName={lastName}
        onClick={trackTeamMemberProfileClick}
        profileImageCode={profileImageCode}
        type="medium"
      />
      {newHireAvailable && nhLabelNode}
    </span>
  );

  return (
    <div className="team-user-info">
      <div className="team-user-info__content">
        <ul className="team-user-info__list">
          <li className="team-user-info__avatar">
            {showTooltip ? (
              <Popup
                className="engagement-indicator__avatar--popup"
                content={engagement?.tooltip}
                inverted
                hoverEnabled
                position="top center"
                style={{ maxWidth: '300px' }}
                trigger={getAvatarMedia()}
              />
            ) : (
              getAvatarMedia()
            )}
          </li>
          <li className="team-user-info__list-item" onClick={onClick}>
            <div className="team-user-info__list-item-content">
              <span className="team-user-info__list-item-name">
                {formatMessage(messages.TeamDashboardTeamMembersArea)}
              </span>
              <span className="team-user-info__list-item-value">
                {area || '-'}
              </span>
            </div>
          </li>
          <li className="team-user-info__list-item" onClick={onClick}>
            <div className="team-user-info__list-item-content">
              <span className="team-user-info__list-item-name">
                {formatMessage(messages.TeamDashboardTeamMembersJobPosition)}
              </span>
              <span className="team-user-info__list-item-value">
                {job || '-'}
              </span>
            </div>
          </li>
          <li className="team-user-info__list-item" onClick={onClick}>
            <div className="team-user-info__list-item-content">
              <span className="team-user-info__list-item-name">
                {formatMessage(messages.TeamDashboardTeamMembersAccount)}
              </span>
              <span className="team-user-info__list-item-value">
                {account || '-'}
              </span>
            </div>
          </li>
          <li className="team-user-info__list-item" onClick={onClick}>
            <div className="team-user-info__list-item-content">
              <span className="team-user-info__list-item-name">
                {formatMessage(messages.TeamDashboardTeamMembersProject)}
              </span>
              <span className="team-user-info__list-item-value">
                {project || '-'}
              </span>
            </div>
          </li>
          <li className="team-user-info__list-item" onClick={onClick}>
            <div className="team-user-info__list-item-content">
              <span className="team-user-info__list-item-name">
                {formatMessage(messages.TeamDashboardTeamMembersOffice)}
              </span>
              <span className="team-user-info__list-item-value">
                {office || '-'}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

TeamUserInfo.propTypes = {
  engagement: PropTypes.shape({
    color: PropTypes.string.isRequired,
    icon: PropTypes.oneOf([
      'engaged',
      'no-data',
      'no-engaged',
      'no-service',
    ]).isRequired,
  }),
  user: PropTypes.object.isRequired,
};

export default injectIntl(TeamUserInfo);
