// @packages
import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import TextWrapper from 'smu-ui-components/TextWrapper';

// @app
import Title from 'components/atoms/Title';
import Description from 'components/molecules/Description';

// @own
import './styles.scss';

const PersonalDataItem = ({
  className,
  description,
  title,
  variant,
  ...props
}) => (
  <div className={cn(
      className,
      'personal-data-item',
      `personal-data-item--${variant}`,
    )}
    {...props}
  >
    <Title
      blue
      className="personal-data-item__title"
      nomargin
      size={10}
    >
      {title}
      </Title>
      <Description className={cn(
          'personal-data-item__description',
          `personal-data-item__description--${variant}`,
        )}>
        <TextWrapper
          as="div"
          className="personal-data-item__text-wrapper"
          ellipsis
        >
          {description}
        </TextWrapper>
      </Description>
  </div>
);

PersonalDataItem.defaultProps = {
  variant: 'betterme',
};

PersonalDataItem.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
  variant: PropTypes.oneOfType(['betterme', 'gray']),
};

export default PersonalDataItem;
