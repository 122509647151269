import { base64Decode } from 'smu-utils/base64';

export const getEvaluationsQueryParams = (query) => {
  const encodeUserId = query?.openCreateEvaluation;
  const isNewHire = query?.isNewHire === 'true';
  const decodeUserId = encodeUserId && base64Decode(encodeUserId);
  const openCreateEvaluation = decodeUserId ? Number(decodeUserId) : '';
  return {
    isNewHire,
    openCreateEvaluation,
  };
};
