// @packages
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

// @app
import ContentCardTemplate from 'components/templates/ContentCardTemplate';
import RatingDetails from 'components/organisms/RatingDetails';
import CompetencyDetails from 'components/organisms/CompetencyDetails';
import {
  selectCompetenciesOrder,
  selectCompetenciesRatingList,
  selectFilterDropdownConfig,
  selectRatingFeedbacks,
  selectRatingFilter,
  selectRatingsFilterConfig,
  selectSelectedCompetencyDetails,
} from 'betterme-components/services/WidgetFeedbackOverview/selectors';
import { requestRatedTopics as requestRatedTopicsAction } from 'betterme-components/Dashboard/actions';
import * as actions from 'betterme-components/services/WidgetFeedbackOverview/actions';
import { feedbackMessages } from 'common/messages';

// @own
import messages from './messages';

const FeedbackOverviewWidget = ({
  addRatingFilter,
  changeCompetenciesOrder,
  competenciesOrder,
  competenciesRatingList,
  filterDropdownConfig,
  intl: { formatMessage },
  onFilterChange,
  onOrderChange,
  onToggleClick,
  onUserAvatarsClick,
  open,
  ratedTopics,
  ratingFeedbacks,
  ratingFilter,
  ratingsFilterConfig,
  removeRatingFilter,
  selectCompetencyDetails,
  selectedCompetencyDetails,
  title,
}) => {

  useEffect(() => {
    const { availableFilters } = filterDropdownConfig;
    const defaultFilter = availableFilters[availableFilters.length - 1];
    addRatingFilter(defaultFilter);
  }, [])

  useEffect(() => {
    const { availableFilters } = filterDropdownConfig;
    addRatingFilter(availableFilters[0]);
  }, [filterDropdownConfig])

  const handleFilterChange = (ratingId, isMultiple) => {
    addRatingFilter(ratingId, isMultiple);
    onFilterChange(ratingId, isMultiple);
  }

  const handleOrderChange = () => {
    changeCompetenciesOrder();
    onOrderChange(competenciesOrder);
  }

  const handleUserAvatarsClick = (competencyId) => {
    selectCompetencyDetails(competencyId);
    onUserAvatarsClick(competencyId);
  }

  const competencyDetailsMessages = {
    showing: formatMessage(messages.FeedbackOverviewCompetencyDetailsFilter),
  }

  const ratingDetailsMessages = {
    competencies: formatMessage(feedbackMessages.FeedbackCompetencies),
  }

  const competencyDetails = {
    ...ratingFeedbacks,
    addRatingFilter: handleFilterChange,
    onBackClick: () => selectCompetencyDetails(),
    ratingFilter,
    ratingsFilterConfig,
    removeRatingFilter,
    messages: competencyDetailsMessages,
  };

  const ratingsDetails = {
    competenciesRatingList,
    filterValue: ratingFilter,
    listOrder: competenciesOrder,
    onFilterChange: handleFilterChange,
    onOrderChange: handleOrderChange,
    onUserAvatarsClick: handleUserAvatarsClick,
    filterDropdownConfig,
    messages: ratingDetailsMessages,
  };

  return (
    <ContentCardTemplate
      title={title}
      open={open}
      onToggleClick={() => onToggleClick('feedbackOpen')}
    >
      <ContentCardTemplate.Body>
        {selectedCompetencyDetails ? (
            <CompetencyDetails
              {...competencyDetails}
            />
          ) : (
            <RatingDetails
              {...ratingsDetails}
            />
          )}
      </ContentCardTemplate.Body>
    </ContentCardTemplate>
  );
};

FeedbackOverviewWidget.defaultProps = {
  onFilterChange: () => undefined,
  onOrderChange: () => undefined,
  onUserAvatarsClick: () => undefined,
};

FeedbackOverviewWidget.propTypes = {
  feedbackForLeadersSummaryResult: PropTypes.array,
  goToProfileDetails: PropTypes.func,
  onToggleClick: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
  return {
    competenciesOrder: selectCompetenciesOrder(state),
    competenciesRatingList: selectCompetenciesRatingList(state, ownProps),
    filterDropdownConfig: selectFilterDropdownConfig(state, ownProps),
    ratingFeedbacks: selectRatingFeedbacks(state, ownProps),
    ratingFilter: selectRatingFilter(state),
    ratingsFilterConfig: selectRatingsFilterConfig(state, ownProps),
    selectedCompetencyDetails: selectSelectedCompetencyDetails(state),
  };
};

const mapDispatchToProps = {
  ...actions,
  requestRatedTopics: requestRatedTopicsAction,
};

export default connect(mapStateToProps, mapDispatchToProps) (injectIntl(FeedbackOverviewWidget));
