import { defineMessages } from 'react-intl';

export default defineMessages({
  LeaderSubstitutionBreadcrumbTitle: {
    id: 'Betterme.LeaderSubstitution.BreadcrumbTitle',
    defaultMessage: 'Leader Substitution',
  },
  LeaderSubstitutionFocalBreadcrumb: {
    id: 'Betterme.LeaderSubstitution.FocalBreadcrumb',
    defaultMessage: 'Manage Leaders',
  },
  LeaderSubstitutionSuggestedCareer: {
    id: 'Betterme.LeaderSubstitution.SuggestedCareer',
    defaultMessage: 'Suggested Career Mentors',
  },
  LeaderSubstitutionSuggestedAccount: {
    id: 'Betterme.LeaderSubstitution.SuggestedAccount',
    defaultMessage: 'Suggested Experience Leaders',
  },
  LeaderSubstitutionSuggestedMain: {
    id: 'Betterme.LeaderSubstitution.SuggestedMain',
    defaultMessage: 'Suggested Main Leaders',
  },
  LeaderSubstitutionSuggestedSecondary: {
    id: 'Betterme.LeaderSubstitution.SuggestedSecondary',
    defaultMessage: 'Suggested Secondary Leaders',
  },
  LeaderSubstitutionSearchingTitle: {
    id: 'Betterme.LeaderSubstitution.SearchingTitle',
    defaultMessage: 'Look for someone else',
  },
  LeaderSubstitutionSearchingPlaceholder: {
    id: 'Betterme.LeaderSubstitution.SearchingPlaceholder',
    defaultMessage: 'Search for participants by name or email',
  },
  LeaderSubstitutionCollaboratorsLeaderHas: {
    id: 'Betterme.LeaderSubstitution.CollaboratorsLeaderHas',
    defaultMessage: 'already has',
  },
  LeaderSubstitutionCollaboratorsAsMain: {
    id: 'Betterme.LeaderSubstitution.CollaboratorsAsMain2',
    defaultMessage: 'Teammates \nas Career Mentor',
  },
  LeaderSubstitutionCollaboratorsAsSecondary: {
    id: 'Betterme.LeaderSubstitution.CollaboratorsAsSecondary2',
    defaultMessage: 'Teammates \nas Experience Leader',
  },
  LeaderSubstitutionEmptyStateTitle: {
    id: 'Betterme.LeaderSubstitution.EmptyState.Title',
    defaultMessage: "Looks like it's your turn to search for the perfect match!"
  },
  LeaderSubstitutionEmptyStateDescription: {
    id: 'Betterme.LeaderSubstitution.EmptyState.Description',
    defaultMessage: "Our ever-evolving intelligence couldn't find a suggestion for this collaborator. Would you mind trying it yourself?",
  },
  LeaderSubstitutionAccountEmptyStateTitle: {
    id: 'Betterme.LeaderSubstitution.Account.EmptyState.Title',
    defaultMessage: 'There are no suggestions at this moment.',
  },
  LeaderSubstitutionAccountEmptyStateDescriptionText: {
    id: 'Betterme.LeaderSubstitution.Account.EmptyState.DescriptionText',
    defaultMessage: "You can still {link}",
  },
  LeaderSubstitutionAccountEmptyStateDescriptionLink: {
    id: 'Betterme.LeaderSubstitution.Account.EmptyState.DescriptionLink',
    defaultMessage: "go back to the talent's profile",
  },
  FocalsAdminAddLeaderToastSuccessMessage: {
    id: 'Betterme.FocalsAdmin.AddLeaderToastSuccess',
    defaultMessage: 'The leader was successfully assigned.'
  },
  FocalsAdminReplaceLeaderToastSuccessMessage: {
    id: 'Betterme.FocalsAdmin.ReplaceLeaderToastSuccess',
    defaultMessage: 'The leader was successfully replaced.'
  },
  FocalsAdminAccountEmptyStateDescriptionText: {
    id: 'Betterme.FocalsAdmin.Account.EmptyState.DescriptionText',
    defaultMessage: "You can still {link}",
  },
  FocalsAdminAccountEmptyStateDescriptionLink: {
    id: 'Betterme.FocalsAdmin.Account.EmptyState.DescriptionLink',
    defaultMessage: "go back to the talent's profile",
  },
  LeaderSubstitutionAccountAssignButton: {
    id: 'Betterme.LeaderSubstitution.Account.AssignButton',
    defaultMessage: "Assign",
  },
});
