// @packages
import { takeLatest, call, put } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import { openModal } from 'smu-app-components/RootModal/actions';

// @app
import { getLeadershipRequestsReceived } from 'betterme-components/services/LeadershipRequestReceived/actions';
import { APPROVED, RECEIVED } from 'components/common/constants';
import { ADD_LEADER_REQUEST_MODAL } from 'betterme-components/constants/modalTypes';

// @own
import {
  addLeaderRequestFail,
  addLeaderRequestSuccess,
} from './actions';
import { ADD_LEADER_REQUEST } from './actionTypes';
import { addLeaderRequest } from './api';

function* addLeaderRequestWorker({ payload: { requester, requestId, status, newWordingForLeaders, isMainRequest} }) {
  const leaderName = `${requester?.firstName} ${requester?.lastName}`;
  const leaderId = requester?.id;
  const data = { status };
  try {
    const response = yield call(addLeaderRequest, leaderId, requestId, data);
    if (status === APPROVED) {
      yield put(addLeaderRequestSuccess(response));
      yield put(
        openModal({
          modalType: ADD_LEADER_REQUEST_MODAL,
          modalProps: {
            isMainRequest,
            leader: leaderName,
            newWordingForLeaders,
          },
        })
      );
      yield put(getLeadershipRequestsReceived(RECEIVED));
    } else {
      yield put(addLeaderRequestSuccess(response));
      yield put(getLeadershipRequestsReceived(RECEIVED));
    }
  } catch (error) {
    yield put(addLeaderRequestFail(error.message));
    yield put(
      addToastMessage({ type: "error", message: error.message, timeout: 3000 })
    );
  }
};

export default function* leaderWatcher() {
  yield takeLatest(ADD_LEADER_REQUEST, addLeaderRequestWorker);
};
