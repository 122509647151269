// @packages
import get from 'lodash/get';

export const selectSelfRetrospective = ({ selfRetrospective }) =>
  get(selfRetrospective, 'selfRetrospectiveData');

export const selectSelfRetrospectiveLoading = ({ selfRetrospective }) =>
  get(selfRetrospective, 'selfRetrospectiveDataLoading');

export const selectSelfRetrospectiveBehaviours = ({ selfRetrospective }) =>
  get(selfRetrospective, 'selfRetrospectiveBehaviours');
