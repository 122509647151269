// @packages
import React from 'react';
import ContentCardTemplate from 'components/templates/ContentCardTemplate';
import Icon from 'smu-ui-components/IconV2';
import Link from 'smu-ui-components/Link';
import PropTypes from 'prop-types';
import cn from 'classnames'

// @own
import './styles.scss';
import DiscreteProgressBar from 'smu-ui-components/DiscreteProgressBar';

const PotentialAssessmentResultsGeneral = ({
  className,
  generalData: {
    description,
    moreInfoText,
    onMoreInfoClick,
    score,
    scores,
    title,
  },
}) => {

  return (
    <ContentCardTemplate
      className={cn('potential-assessment-results-general', className)}
      title={title}
    >
      <ContentCardTemplate.Body>
        <div className="potential-assessment-results-general__content">
          <div className="potential-assessment-results-general__content-text">
            {description}
          </div>
          <DiscreteProgressBar
            backgroundColor="#FFFFFF"
            emptyColor="#F0F2F5"
            progress={score}
            scores={scores}
          />
          <Link
            className="potential-assessment-results-general__footer hover:text-gray"
            onClick={onMoreInfoClick}
          >
            <Icon
              className="potential-assessment-results-general__footer-icon"
              icon="info"
              size={20}
            />
            <span className="potential-assessment-results-general__footer-text">
              {moreInfoText}
            </span>
          </Link>
        </div>
      </ContentCardTemplate.Body>
    </ContentCardTemplate>
  );
};

PotentialAssessmentResultsGeneral.propTypes = {
  className: PropTypes.string,
  generalData: PropTypes.objectOf(PropTypes.shape({
    description: PropTypes.string,
    moreInfoText: PropTypes.string,
    onMoreInfoClick: PropTypes.func,
    score: PropTypes.number,
    scores: PropTypes.array,
    title: PropTypes.string,
  })),
};

export default PotentialAssessmentResultsGeneral;
