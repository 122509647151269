import { defineMessages } from 'react-intl';

export default defineMessages({
  PotentialAssessmentTitle: {
    id: 'Betterme.PotentialAssessment.Title',
    defaultMessage: 'Potential Assessment',
  },
  PotentialAssessmentButton: {
    id: 'Betterme.PotentialAssessment.Button',
    defaultMessage: `Let's get started!`,
  },
  PotentialAssessmentYouGuessedIt: {
    id: 'Betterme.PotentialAssessment.YouGuessedIt',
    defaultMessage: 'Welcome to our new potential assessment.',
  },
  PotentialAssessmentRateBehaviours: {
    id: 'Betterme.PotentialAssessment.RateBehaviours',
    defaultMessage: `Now you can revise observable behaviors of the Globers in your team to identify their potential level. Before you start, remember that it is very useful to collect as much feedback as possible to understand the impact that each Glober generates in the community.`,
  },
  PotentialAssessmentLink: {
    id: 'Betterme.PotentialAssessment.Link',
    defaultMessage: 'Learn more about Potential',
  },
  PotentialAssementChooseMember: {
    id: 'Betterme.PotentialAssessment.ChooseMember',
    defaultMessage: "Now, let's get started! Choose a member of your team from the list. If you don't see it on the screen,",
  },
  PotentialAssementLinkText: {
    id: 'Betterme.PotentialAssessment.LinkText',
    defaultMessage: "<u>click here</u> to fill out this form and we'll take care of it.",
  },
  PotentialAssessmentEmptyStateTitle: {
    id: 'Betterme.FocalsAdmin.EmptyStateTitle',
    defaultMessage: 'It doesn’t seem you have <br /> access to this page.'
  },
  PotentialAssessmentEmptyStateDescription: {
    id: 'Betterme.FocalsAdmin.EmptyStateDescription',
    defaultMessage: 'If you believe you should have permission, <br /> please contact your Administrator.',
  },
});
