import {
  GET_CHANGE_LEADER_DISMISS_REASONS,
  GET_CHANGE_LEADER_DISMISS_REASONS_FAIL,
  GET_CHANGE_LEADER_DISMISS_REASONS_SUCCESS,
  PUT_CHANGE_LEADER,
  PUT_CHANGE_LEADER_FAIL,
  PUT_CHANGE_LEADER_SUCCESS,
  SELECTED_DISMISS_REASON,
  SELECTED_DISMISS_REASON_RESET,
} from './actionTypes';

export function getChangeLeaderDismissReason(reasonType) {
  return {
    type: GET_CHANGE_LEADER_DISMISS_REASONS,
    payload: { reasonType },
  }
};

export function getChangeLeaderDismissReasonFail(error) {
  return {
    type: GET_CHANGE_LEADER_DISMISS_REASONS_FAIL,
    payload: { error },
  };
};

export function getChangeLeaderDismissReasonSuccess(response) {
  return {
    type: GET_CHANGE_LEADER_DISMISS_REASONS_SUCCESS,
    payload: { response },
  };
};

//TODO: this action must receive an object
export function putChangeLeader(requestId, status, reasonId, comment, identification, message, modalType, modalProps) {
  return {
    type: PUT_CHANGE_LEADER,
    payload: {
      comment,
      identification,
      message,
      modalProps,
      modalType,
      reasonId,
      requestId,
      status,
    },
  };
};

export function putChangeLeaderFail(error) {
  return {
    type: PUT_CHANGE_LEADER_FAIL,
    payload: { error },
  };
};

export function putChangeLeaderSuccess(response) {
  return {
    type: PUT_CHANGE_LEADER_SUCCESS,
    payload: { response },
  };
};

export function selectedDismissReason(id) {
  return {
    type: SELECTED_DISMISS_REASON,
    payload: { id },
  };
};

export function resetDismissReason(id) {
  return {
    type: SELECTED_DISMISS_REASON_RESET,
    payload: { id },
  };
};
