import {
  GET_TEAM_FEEDBACKS,
  GET_TEAM_FEEDBACKS_FAIL,
  GET_TEAM_FEEDBACKS_SUCCESS,
  GET_TEAM_FEEDBACKS_COMMENTS,
  GET_TEAM_FEEDBACKS_COMMENTS_FAIL,
  GET_TEAM_FEEDBACKS_COMMENTS_SUCCESS,
  POST_TEAM_FEEDBACKS_COMMENTS,
  POST_TEAM_FEEDBACKS_COMMENTS_FAIL,
  POST_TEAM_FEEDBACKS_COMMENTS_SUCCESS,
} from './actionTypes';

export function getTeamFeedbacks(params) {
  return {
    type: GET_TEAM_FEEDBACKS,
    payload: params
  };
}

export function getTeamFeedbacksFail(error) {
  return {
    type: GET_TEAM_FEEDBACKS_FAIL,
    payload: { error },
  };
}

export function getTeamFeedbacksSuccess(response) {
  return {
    type: GET_TEAM_FEEDBACKS_SUCCESS,
    payload: { response },
  };
}

export function getTeamFeedbacksComments(feedbackId) {
  return {
    type: GET_TEAM_FEEDBACKS_COMMENTS,
    payload: { feedbackId },
  };
}

export function getTeamFeedbacksCommentsFail(error, feedbackId) {
  return {
    type: GET_TEAM_FEEDBACKS_COMMENTS_FAIL,
    payload: { error, feedbackId },
  };
}

export function getTeamFeedbacksCommentsSuccess(response, feedbackId) {
  return {
    type: GET_TEAM_FEEDBACKS_COMMENTS_SUCCESS,
    payload: { response, feedbackId },
  };
}

export function postTeamFeedbacksComments(feedbackId, comment) {
  return {
    type: POST_TEAM_FEEDBACKS_COMMENTS,
    payload: { feedbackId, comment },
  };
}

export function postTeamFeedbacksCommentsFail(error) {
  return {
    type: POST_TEAM_FEEDBACKS_COMMENTS_FAIL,
    payload: { error },
  };
}

export function postTeamFeedbacksCommentsSuccess(response, feedbackId) {
  return {
    type: POST_TEAM_FEEDBACKS_COMMENTS_SUCCESS,
    payload: { response, feedbackId },
  };
}
