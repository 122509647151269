import { trackEvent } from 'smu-utils/gtm';
const EVALUATIONS = 'Evaluations';

export const trackMyEvaluationDetailsClick = (evaluationType) => {
  trackEvent({
    category: EVALUATIONS,
    action: 'My_Row_Click',
    type: evaluationType,
  });
};

export const trackTeamViewHistoryClick = () => {
  trackEvent({
    category: EVALUATIONS,
    action: 'Team_View_History_Click',
  });
};

export const trackTeamCreateClick = () => {
  trackEvent({
    category: EVALUATIONS,
    action: 'Team_Create_Click'
  });
};

export const trackTeamEditClick = () => {
  trackEvent({
    category: EVALUATIONS,
    action: 'Team_Edit_Click'
  });
};

export const trackExpandFeedbackClick = () => {
  trackEvent({
    category: EVALUATIONS,
    action: 'Form_Expand_Feedback_Click',
  });
};

export const trackExpandGoalsClick = () => {
  trackEvent({
    category: EVALUATIONS,
    action: 'Form_Expand_Goals_Click',
  });
};

export const trackEvaluationsCreationClose = () => {
  trackEvent({
    category: EVALUATIONS,
    action: 'Exit_X_Click',
  });
};

export const trackEvaluationsCreationCancel = () => {
  trackEvent({
    category: EVALUATIONS,
    action: 'Exit_Cancel_Click',
  });
};

export const trackEvaluationsCreationSend = () => {
  trackEvent({
    category: EVALUATIONS,
    action: 'Send_Click',
  });
};

export const trackEvaluationsFeedbackOverviewDataToggle = (value, type) => {
  trackEvent({
    category: EVALUATIONS,
    action: 'Form_Feedback_Toggle',
    label: value ? 'Enable' : 'Disable',
    type,
  });
};

export const trackEvaluationsCompetenciesFilter = (query, type) => {
  trackEvent({
    category: EVALUATIONS,
    action: 'Form_Competencies_Filter',
    label: query,
    type,
  });
};

export const trackEvaluationsEvaluateCompetencies = (skill, rate) => {
  trackEvent({
    category: EVALUATIONS,
    action: 'Form_Evaluate_Competencies',
    label: `${skill} - ${rate}`,
  });
};

export const trackEvaluationsAddSkill = (skill) => {
  trackEvent({
    category: EVALUATIONS,
    action: 'Form_Add_Skill',
    label: skill,
  });
};

export const trackEvaluationsCreateSuccess = (title, range, evaluationType) => {
  trackEvent({
    category: EVALUATIONS,
    action: 'Team_Create_Success',
    label: `${title} - ${range}`,
    type: evaluationType,
  });
};

export const trackEvaluationsEditSuccess = (title, range, evaluationType) => {
  trackEvent({
    category: EVALUATIONS,
    action: 'Team_Edit_Success',
    label: `${title} - ${range}`,
    type: evaluationType,
  });
};

export const trackEvaluationsCreateCancel = () => {
  trackEvent({
    category: EVALUATIONS,
    action: 'Team_Create_Cancel',
  });
};

export const trackEvaluationsEditCancel = () => {
  trackEvent({
    category: EVALUATIONS,
    action: 'Team_Edit_Cancel',
  });
};

export const trackEvaluationsSelectedScore = (value, type, isTechnicalSuggestion) => {
  trackEvent({
    category: EVALUATIONS,
    action: 'Form_Overall_Score',
    label: value,
    type,
    int_type: isTechnicalSuggestion
      ? 'Suggested_By_TechEvaluator'
      : 'Not_Suggested_By_TechEvaluator',
  });
};

export const trackEvaluationsSelectedTalent = (value) => {
  trackEvent({
    category: EVALUATIONS,
    action: 'Form_Potencial_Talent',
    label: value,
  });
};

export const trackEvaluationsActionsSuggested = (value, type) => {
  trackEvent({
    category: EVALUATIONS,
    action: 'Form_Suggested_Actions',
    label: value,
    type,
  });
};

export const trackEvaluationsSelectPromoteTo = (value) => {
  trackEvent({
    category: EVALUATIONS,
    action: 'Form_Suggested_Promotion',
    label: value,
  });
};

export const trackEvaluationsAttachFile = (value, type) => {
  trackEvent({
    category: EVALUATIONS,
    action: 'Form_Attach_File',
    label: value,
    type,
  });
};

export const trackEvaluationsDeleteDraft = (type) => {
  trackEvent({
    category: EVALUATIONS,
    action: 'Form_Delete_Draft',
    type,
  });
};

export const trackEvaluationsDeleteDraftCancel = (type) => {
  trackEvent({
    category: EVALUATIONS,
    action: 'Form_Delete_Cancel',
    type,
  });
};

export const trackEvaluationsDeleteDraftSuccess = (type) => {
  trackEvent({
    category: EVALUATIONS,
    action: 'Form_Delete_Success',
    type,
  });
};

export const trackEvaluationsSaveAsDraft = (type) => {
  trackEvent({
    category: EVALUATIONS,
    action: 'Form_Save_Draft',
    type,
  });
};

export const trackEvaluationsSubmitWithoutImprovements = () => {
  trackEvent({
    category: EVALUATIONS,
    action: 'Form_Submit',
  });
};

export const trackEvaluationsSubmitWithImprovements = () => {
  trackEvent({
    category: EVALUATIONS,
    action: 'Form_Submit_Success',
  });
};

export const trackEvaluationsSendAnyway = () => {
  trackEvent({
    category: EVALUATIONS,
    action: 'Form_Improvement_Modal',
    label: 'Send_Anyway',
  });
};

export const trackEvaluationsAddImprovements = () => {
  trackEvent({
    category: EVALUATIONS,
    action: 'Form_Improvement_Modal',
    label: 'Add_Improvements',
  });
};

export const trackEvaluationsFormKeepInMindModal = () => {
  trackEvent({
    category: EVALUATIONS,
    action: 'Form_KeepInMind_Modal',
    label: 'Cancel',
  });
};

export const trackEvaluationsFormViewMore = (type) => {
  trackEvent({
    category: EVALUATIONS,
    action: 'Form_View_More',
    type,
  });
};

export const trackEvaluationsCollapsibleEvaluationsList = (leaderType, isOpen) => {
  trackEvent({
    category: EVALUATIONS,
    action: leaderType === 'MAIN' 
      ? 'Carrer_Leader'
      : leaderType === 'SECONDARY'
        ? 'Account_Leader' 
        : 'Career_Leader, Account_Leader',
    label: isOpen ? 'Expand' : 'Collapse',
  });
};

export const trackEvaluationsViewAllFeedbacks = (type) => {
  trackEvent({
    category: EVALUATIONS,
    action: 'Form_View_All_Feedbacks',
    type,
  });
};

export const trackPopUpAddSkillClick = (type) => {
  trackEvent({
    category: EVALUATIONS,
    action: 'PopUp_Add_Skill_Click',
    type,
  });
};
