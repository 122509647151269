import {
  ADD_LEADER_ACTION,
  ADD_LEADER_ACTION_FAIL,
  ADD_LEADER_ACTION_SUCCESS,
  REQUEST_LEADER,
  REQUEST_LEADER_FAIL,
  REQUEST_LEADER_SUCCESS,
} from "./actionsTypes";

export function addLeader(collaborator) {
  return {
    type: ADD_LEADER_ACTION,
    collaborator,
  };
}

export function addLeaderFail(error) {
  return {
    type: ADD_LEADER_ACTION_FAIL,
    payload: { error }
  };
}

export function addLeaderSuccess(response) {
  return {
    type: ADD_LEADER_ACTION_SUCCESS,
    payload: { response }
  };
}

export function requestLeader(leader, requestId, accept, message) {
  return {
    type: REQUEST_LEADER,
    payload: {
      accept,
      leader,
      message,
      requestId,
    },
  };
}

export function requestLeaderFail(error) {
  return {
    type: REQUEST_LEADER_FAIL,
    payload: { error }
  };
}

export function requestLeaderSuccess(response) {
  return {
    type: REQUEST_LEADER_SUCCESS,
    payload: { response }
  };
}
