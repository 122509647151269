// @packages
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

// @app
import ContentCardTemplate from 'components/templates/ContentCardTemplate';
import FeedbackForLeaders from 'components/organisms/FeedbackForLeaders';
import { feedbackMessages } from 'betterme-components/common/messages';
import {
  getFeedbackForLeaders,
  resetFeedbackForLeaders,
} from 'betterme-components/services/FeedbackForLeaders/actions';
import { getProfileFeedbackForLEadersReceivedLocation } from 'betterme-components/routes/urls';
import { selectFeedbackForLeadersSummaryResult } from 'betterme-components/services/FeedbackForLeaders/selectors';
import messages from 'betterme-components/Evaluations/messages';

const FeedbackOverviewWidget = ({
  feedbackForLeadersSummaryResult,
  getFeedbackForLeaders,
  identification,
  intl: { formatMessage },
  onToggleClick,
  open,
  resetFeedbackForLeaders,
  user,
}) => {

  useEffect(() => {
    getFeedbackForLeaders(user?.id);
    return () => {
      resetFeedbackForLeaders();
    };
  }, []);

  const goToProfileDetails = (half) =>
    getProfileFeedbackForLEadersReceivedLocation(identification, half);

  const feedbackForLeadersEvaluations =
    feedbackForLeadersSummaryResult?.evaluations;
  const title = formatMessage(
    messages.FeedbackEvaluationsEvaluationFeedbackForLeaders
  );
  const feedbackForLeadersPeriodSurveyed = formatMessage(
    feedbackMessages.FeedbackForLeadersPeriodSurveyed
  );
  const feedbackForLeadersPeriodAverage = formatMessage(
    feedbackMessages.FeedbackForLeadersPeriodAverage
  );
  const feedbackForLeadersViewDetails = formatMessage(
    feedbackMessages.FeedbackForLeadersViewDetails
  );
  const feedbackForLeadersPeriodEmptyState = formatMessage(
    feedbackMessages.FeedbackForLeadersPeriodEmptyState
  );

  return (
    <ContentCardTemplate
      title={title}
      open={open}
      onToggleClick={onToggleClick}
    >
      <ContentCardTemplate.Body>
        <FeedbackForLeaders
          feedbackForLeadersEvaluations={feedbackForLeadersEvaluations}
          feedbackForLeadersPeriodAverage={feedbackForLeadersPeriodAverage}
          feedbackForLeadersPeriodEmptyState={
            feedbackForLeadersPeriodEmptyState
          }
          feedbackForLeadersPeriodSurveyed={feedbackForLeadersPeriodSurveyed}
          feedbackForLeadersSummaryResult={feedbackForLeadersSummaryResult}
          feedbackForLeadersViewDetails={feedbackForLeadersViewDetails}
          goToProfileDetails={goToProfileDetails}
        />
      </ContentCardTemplate.Body>
    </ContentCardTemplate>
  );
};

FeedbackOverviewWidget.propTypes = {
  feedbackForLeadersSummaryResult: PropTypes.array.isRequired,
  getFeedbackForLeaders: PropTypes.func,
  identification: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  onToggleClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  resetFeedbackForLeaders: PropTypes.func,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  feedbackForLeadersSummaryResult: selectFeedbackForLeadersSummaryResult(state),
});

const mapDispatchToProps = {
  getFeedbackForLeaders,
  resetFeedbackForLeaders,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(FeedbackOverviewWidget));
