// @packages
import React from 'react';
import Button from 'smu-ui-components/ButtonV2';
import Paper from 'smu-ui-components/Paper';
import Typography from 'smu-ui-components/Typography';
import withRootModal from 'smu-app-components/FullScreenModal/withFullScreenModal';
import { injectIntl } from 'react-intl';

// @app
import messages from '../messages';
import { GOAL_DETAIL_VIEW_MODAL } from '../../constants/modalTypes';
import { trackMyGoalsCreateClick, trackMyTeamGoalsCreateClick } from '../../Goals/analytics';

const GoalsCreate = ({
  openFullscreenModal,
  user,
  intl,
  isMyGoals
}) => {
  const onGoalFormClick = (type, dataGoal) => {
    openFullscreenModal({
      modalType: GOAL_DETAIL_VIEW_MODAL,
      modalProps: { user, type, dataGoal, isMyGoals }
    });
  };

  const onCreateClick = () => {
    const trackCreateClick = isMyGoals
      ? trackMyGoalsCreateClick
      : trackMyTeamGoalsCreateClick;

    trackCreateClick();
    onGoalFormClick('CREATE');
  };
  const message = isMyGoals ? messages.FeedbackGoalsCreateDescriptionMyGoals : messages.FeedbackGoalsCreateDescription;
  return (
    <Paper className="py-5 px-5" rounded>
      <Typography component='p' className="text-center w-full mb-2" variant="body1">{intl.formatMessage(message)}</Typography>
      <Button color="unifyBlue" enabledUnification onClick={onCreateClick} fluid noMargin>
        {intl.formatMessage(messages.FeedbackGoalsCreateBtnNew)}
      </Button>
    </Paper>
  );
};

GoalsCreate.defaultProps = {
  isMyGoals: false,
};

export default withRootModal(injectIntl(GoalsCreate));
