// @packages
import React from 'react';

// @app
import MyTeamApp from 'betterme-components/MyTeam';
import MyTeamMenu from 'betterme-components/MyTeamMenu';
import TeamPromotionsTab from 'betterme-components/Team/TeamPromotionsTab';

// @own
import MyTeamLayout from '../';

function MyTeamPromotionsLayout(props) {
  return (
    <MyTeamLayout>
      <MyTeamMenu className="py-8 flex-1" path="/myteam/promotions">
        <MyTeamApp {...props}>
          <TeamPromotionsTab />
        </MyTeamApp>
      </MyTeamMenu>
    </MyTeamLayout>
  );
}

export default MyTeamPromotionsLayout;
