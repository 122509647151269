// @packages
import filter from 'lodash/filter';
import some from 'lodash/some';

export function handleRemoveCompetency(competency, dataEvaluation, setDataCreateEvaluation) {
  if (some(dataEvaluation?.ratedTopics, ({ competency: ratedTopic }) => (ratedTopic.id === competency.id))) {
    const newDataEvaluation = {
      ...dataEvaluation,
      ratedTopics: filter(dataEvaluation?.ratedTopics, ({ competency: { id } }) => id !== competency.id),
    }
    return setDataCreateEvaluation({ ...newDataEvaluation });
  }
};

export function handleCompetencySelect(competency, rate, dataEvaluation, setDataCreateEvaluation) {
  let newDataEvaluation = {
    ...dataEvaluation,
    ratedTopics: [
      ...filter(dataEvaluation?.ratedTopics,
        ({ competency: { id } }) => id !== competency.id),
      { competency, rateId: rate.id },
    ]
  }
  return setDataCreateEvaluation({ ...newDataEvaluation });
};
