// @packages
import { call, put, takeLatest } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';

// @app
import { getPotentialAssessmentResults, getPotentialType } from './api';

// @own
import {
  GET_POTENTIAL_ASSESSMENT_RESULTS,
  GET_POTENTIAL_TYPE,
} from './actionTypes';
import {
  getPotentialAssessmentResultsFail,
  getPotentialAssessmentResultsSuccess,
  getPotentialTypeFail,
  getPotentialTypeSuccess,
} from './actions';

function* getPotentialTypeWorker({ payload }) {
  try {
    const response = yield call(getPotentialType, payload);
    yield put(getPotentialTypeSuccess(response));
  } catch (error) {
    yield put(getPotentialTypeFail(error));
    yield put(addToastMessage({ type: 'error', error, timeout: 3000 }));
  }
}

function* getPotentialAssessmentResultsWorker({ payload }) {
  try {
    const response = yield call(getPotentialAssessmentResults, payload);
    yield put(getPotentialAssessmentResultsSuccess(response));
  } catch (error) {
    yield put(getPotentialAssessmentResultsFail(error));
    yield put(addToastMessage({ type: 'error', error, timeout: 3000 }));
  }
}

export default function* potentialAssessmentResultsWatcher() {
  yield takeLatest(GET_POTENTIAL_ASSESSMENT_RESULTS, getPotentialAssessmentResultsWorker);
  yield takeLatest(GET_POTENTIAL_TYPE, getPotentialTypeWorker);
}
