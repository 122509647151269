// @packages
import cn from 'classnames';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InputRadio from 'smu-ui-components/InputRadio';
import Segment from 'smu-ui-components/Segment';
import ActionButtons from 'components/molecules/ActionButtons';
import { connect } from 'react-redux';
import { getUserLanguage } from 'smu-utils/language';
import { injectIntl } from 'react-intl';

// @app
import { actionsMessages } from 'common/messages';

// @own
import {
  selectFeedbackRequestReasons,
  selectLoadingReasons,
  selectSelectedReason,
} from '../selectors';
import messages from '../messages';
import './styles.scss';
import {
  createFeedbackRequestDismiss,
  getRequestDismissReasons,
  selectReason,
} from '../actions';

class FeedbackRequestDismiss extends Component {
  constructor() {
    super();

    this.handleAccept = this.handleAccept.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
  }

  componentDidMount() {
    const { getRequestDismissReasons } = this.props;
    getRequestDismissReasons();
  }

  componentWillUnmount() {
    const { selectReason } = this.props;
    selectReason();
  }

  handleAccept() {
    const {
      createFeedbackRequestDismiss,
      intl: { formatMessage },
      onAccept,
      refreshParams,
      requestId,
      selectedReason,
      userId,
    } = this.props;
    const toast = {
      fail: formatMessage(messages.FeedbackRequestDismissModalFail),
      success: formatMessage(messages.FeedbackRequestDismissModalSuccess),
    };

    if (selectedReason) {
      createFeedbackRequestDismiss(requestId, selectedReason, userId, toast, refreshParams);
    }
    onAccept();
  }

  handleRadioChange(event) {
    const { value } = event.target;
    const { selectReason } = this.props;

    selectReason(value);
  }

  render() {
    const {
      className,
      intl: { formatMessage },
      loadingReasons,
      onCancel,
      radioClassName,
      reasons,
      selectedReason,
    } = this.props;
    const locale = getUserLanguage();

    return (
      <div
        className={cn('feedback-request-dissmiss', className)}
      >
        <Segment
          className={cn('feedback-request-dissmiss__radios', radioClassName)}
          loading={loadingReasons}
          loadingType="betterme"
        >
          {reasons.map(reason =>
            <InputRadio
              checked={selectedReason === reason.id}
              formFieldClassName="[&>label]:!whitespace-normal"
              idName={reason.id}
              key={reason.id}
              label={reason.localizations?.find(lang => lang.locale.language === locale)?.name
                ?? reason.name}
              onChange={this.handleRadioChange}
              value={reason.id}
            />
          )}
        </Segment>
        <ActionButtons
          buttons={[
            {
              label: formatMessage(actionsMessages.ActionsCancel),
              onClick: () => onCancel(),
              variant: 'outline',
            },
            {
              disabled: !selectedReason,
              label: formatMessage(actionsMessages.ActionsAccept),
              onClick: () => this.handleAccept(),
            },
          ]}
        />
      </div>
    );
  }
}

FeedbackRequestDismiss.propTypes = {
  createFeedbackRequestDismiss: PropTypes.func.isRequired,
  getRequestDismissReasons: PropTypes.func.isRequired,
  loadingReasons: PropTypes.bool.isRequired,
  reasons: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  requestId: PropTypes.number.isRequired,
  selectReason: PropTypes.func.isRequired,
  selectedReason: PropTypes.number,
};

const mapStateToProps = (state) => ({
  dismissReasons: selectFeedbackRequestReasons(state),
  loadingReasons: selectLoadingReasons(state),
  reasons: selectFeedbackRequestReasons(state),
  selectedReason: selectSelectedReason(state),
});

const mapDispatchToProps = {
  createFeedbackRequestDismiss,
  getRequestDismissReasons,
  selectReason,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(FeedbackRequestDismiss));
