// @packages
import React, { useState, useRef, useEffect } from 'react';
import ImageGallery from 'react-image-gallery';
import PropTypes from 'prop-types';
import includes from 'lodash/includes';
import uniq from 'lodash/uniq';
import { injectIntl } from 'react-intl';

// @app
import ActionButtons from 'components/molecules/ActionButtons';
import Discovery from 'smu-app-components/FeatureDiscoveryV2/discovery';
import Title from 'components/atoms/Title';

const LeadersListDiscovery = ({
  actionButtonTexts: {
    slideOneText,
    slideOneTextAlternative,
    slideTwoText,
    slideTwoTextAlternative,
  },
  children,
  galleryItems,
  handleOpen,
  open,
  title,
  type,
}) => {
  const [toggleDiscoverySlides, setToggleDiscoverySlides] = useState(true);
  const discoveryRef = useRef(null);

  const handleDiscoveryClick = (event) => {
    event.stopPropagation();
    const oldDiscoveries = JSON.parse(localStorage.getItem('discoveries')) || [];
    const newDiscoveries = JSON.stringify(uniq([...oldDiscoveries, type]));
    handleOpen(false);
    localStorage.setItem('discoveries', newDiscoveries);
  };

  const handleClickOutside = (event) => {
    const isClickedOutside =
      discoveryRef.current && !discoveryRef.current.contains(event.target);

    if (isClickedOutside) {
      handleDiscoveryClick(event);
      setToggleDiscoverySlides(true);
    }
  };

  const discoveryActionButtons = (onClick) => {
    const actionButtons = [
      {
        className: 'categorized-leaders-list__discovery-link',
        label: toggleDiscoverySlides
          ? slideOneText
          : slideOneTextAlternative,
        onClick: (event) => {
          handleDiscoveryClick(event);
          setToggleDiscoverySlides(true);
        },
        size: 'small',
        variant: 'link',
      },
      {
        className: 'categorized-leaders-list__discovery-button',
        label: toggleDiscoverySlides
          ? slideTwoText
          : slideTwoTextAlternative,
        onClick: (args) => {
          onClick(args);
          setToggleDiscoverySlides(!toggleDiscoverySlides);
        },
        size: 'small',
      },
    ];

    if (!toggleDiscoverySlides) {
      actionButtons.reverse();
    }
    return actionButtons;
  };

  const setInitialOpenState = () => {
    const discoveryList = JSON.parse(localStorage.getItem('discoveries'));
    const wasOpen = includes(discoveryList, type);
    handleOpen(!wasOpen);
  };

  useEffect(() => {
    setInitialOpenState();
    document.addEventListener('click', handleClickOutside, true);
    return document.removeEventListener('click', handleClickOutside);
  }, []);

  return (
    <Discovery
      color="btm"
      open={open}
      popupClassName="categorized-leaders-list__discovery"
      target={children}
      text={
        <div ref={discoveryRef}>
          {title && (
            <Title
              className="categorized-leaders-list__discovery-title"
              size={16}
              white
            >
              {title}
            </Title>
          )}
          <ImageGallery
            additionalClass="categorized-leaders-list__discovery-carousel"
            infinite={false}
            items={galleryItems}
            renderLeftNav={(onClick) =>
              !toggleDiscoverySlides && (
                <div className="categorized-leaders-list__custom-nav">
                  <ActionButtons
                    buttons={discoveryActionButtons(onClick)}
                  />
                </div>
              )
            }
            renderRightNav={(onClick) =>
              toggleDiscoverySlides && (
                <div className="categorized-leaders-list__custom-nav">
                  <ActionButtons
                    buttons={discoveryActionButtons(onClick)}
                  />
                </div>
              )
            }
            renderItem={(item) => (
              <>
                {item?.title && (
                  <div className="categorized-leaders-list__discovery-slide-title">
                    <p>{item?.title}</p>
                  </div>
                )}
                <div className="categorized-leaders-list__discovery-slide-text">
                  <p>{item?.text}</p>
                  <div className="categorized-leaders-list__discovery-slide-buttons"></div>
                </div>
              </>
            )}
            showFullscreenButton={false}
            showPlayButton={false}
            showThumbnails={false}
          />
        </div>
      }
    />
  );
};

LeadersListDiscovery.propTypes = {
  children: PropTypes.node,
  handleOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
}

export default injectIntl(LeadersListDiscovery);
