// @packages
import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import Step from "smu-ui-components/Step";

// @own
import "./styles.scss";

/**
 * Display progress through a sequence of logical and numbered steps
 */
const Stepper = ({
  children,
  className,
  direction,
  ordered,
  steps,
  vertical,
}) => {
  function getStepProps(step, key, last) {
    const props = {
      direction,
      key: `step-key-${key}`,
      vertical,
      ...step
    };

    if (ordered) {
      props.order = key + 1;
    }

    if (key === 0) {
      props.isFirst = true;
    }

    if (key === last) {
      props.isLast = true;
    }

    return props;
  }

  return (
    <div
      className={cn('bme-stepper suite-stepper', className, {
        'suite-stepper--vertical': vertical
      })}
    >
      {steps.length
        ? steps.map((step, key) => (
          <Step {...getStepProps(step, key, steps.length - 1)} />
        ))
        : React.Children.map(children || null, (child, i) => {
          return (
            <child.type
              {...getStepProps(
                child.props,
                i,
                React.Children.count(children) - 1
              )}
            />
          );
        })}
    </div>
  );
};

Stepper.propTypes = {
  /**
   * Primary content.
   */
  children: PropTypes.node,
  /**
   * Additional classes.
   */
  className: PropTypes.string,
  /**
   * A step can be displayed column or row.
   */
  direction: PropTypes.oneOf(['column', 'row']),
  /**
   * Additional classes.
   */
  ordered: PropTypes.bool,
  /**
   * Shows an ordered number in each step
   */
  steps: PropTypes.array,
  /**
   * A step can be displayed stacked vertically.
   */
  vertical: PropTypes.bool,
};

Stepper.defaultProps = {
  className: "",
  ordered: true,
  steps: [],
  vertical: false,
  direction: 'row',
};

export default Stepper;
