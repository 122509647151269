// @packages
import { get } from 'lodash';

const reducerDomain = 'myTeam';

export const selectMyTeamFilters = (state) =>
  get(state, [reducerDomain, 'myTeamFilters']);

export const selectMyTeamLoaded = (state) =>
  get(state, [reducerDomain, 'loaded']);
