// @packages
import React, { useEffect, useState } from 'react';
import Icon from 'smu-ui-components/IconV2';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { base64Decode } from 'smu-utils/base64';
import { connect } from 'react-redux';
import { getUserLanguage } from 'smu-utils/language';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import { push } from 'react-router-redux';

// @app
import FullScreenSection from 'components/templates/FullScreenSection';
import General from 'components/organisms/PotentialAssessmentResultsGeneral';
import Header from 'components/organisms/PotentialAssessmentResultsHeader';
import Insight from 'components/organisms/PotentialAssessmentResultsInsight';
import Comments from 'components/organisms/PotentialAssessmentResultsComments';
import Dimensions from 'components/organisms/PotentialAssessmentResultsDimensions';
import { selectUser } from 'betterme-components/Authorization/selectors';
import { getProfileLocation } from 'betterme-components/routes/urls';
import { getUserInfo, resetUserInfo } from 'betterme-components/services/UserInformation/actions';
import {
  commonMessages,
  potentialAssessmentMessages,
  userMessages,
} from 'common/messages';
import {
  selectuserInfo,
  selectuserInfoLoading,
} from 'betterme-components/services/UserInformation/selectors';
import {
  getPotentialAssessmentResults,
  getPotentialType,
  resetPotentialAssessmentResults,
} from 'betterme-components/services/PotentialAssessmentResults/actions';
import {
  selectPotentialAssessmentResults,
  selectPotentialType,
  selectPotentialAssessmentResultsLoading,
  selectPotentialTypeLoading,
} from 'betterme-components/services/PotentialAssessmentResults/selectors';

// @own
import './styles.scss';
import {
  MORE_INFO_LINK,
  SCORE_PROPS,
  SEGMENTS,
} from './constants';
import {
  trackLearnMore,
  trackSeeDescription,
} from './analytics';

const PotentialAssessmentResults = ({
  getPotentialAssessmentResults,
  getPotentialType,
  getUserInfo,
  goToProfile,
  intl: { formatMessage },
  loadingPotentialAssessmentResults,
  params,
  potentialAssessmentResults,
  potentialType,
  potentialTypeLoading,
  resetPotentialAssessmentResults,
  resetUserInfo,
  user: { id: selfUserId },
  userInfo,
  userInfoLoading,
}) => {
  const [showDescriptions, setShowDescriptions] = useState(false);
  const [comments, setComments] = useState(false);
  const [obtainedScore, setObtainedScore] = useState({ value: 0, description: '', icon: null });
  const [obtainedDimensions, setObtainedDimensions] = useState([]);
  const userFirstName = userInfo?.firstName;
  const locale = getUserLanguage();
  const profileSection = locale === 'es' || locale === 'pt'
    ? `${formatMessage(userMessages.UserProfile)} de ${userFirstName}`
    : `${userFirstName}’s ${formatMessage(userMessages.UserProfile)}`;
  const onMoreInfoClickHandler = () => {
    trackLearnMore(potentialType);
    window.open(MORE_INFO_LINK, '_blank');
  };
  const onShowDescriptionsClickHandler = () => {
    trackSeeDescription(potentialType);
    setShowDescriptions(!showDescriptions);
  };
  const companyName = formatMessage(potentialAssessmentMessages.PotentialAssessmentGlobant);
  const talentName = formatMessage(potentialAssessmentMessages.PotentialAssessmentGlober);
  const disclaimer = formatMessage(potentialAssessmentMessages.PotentialAssessmentDisclaimer);
  const evaluators = potentialAssessmentResults?.evaluatorUsers;
  const evaluator = evaluators && evaluators[0];
  const evaluatedByMe = evaluator?.userId === selfUserId;
  const evaluatorFirstName = evaluatedByMe ? formatMessage(commonMessages.Me) : evaluator?.firstName;
  const evaluatorLastName = !evaluatedByMe && evaluator?.lastName;
  const evaluatorProfileImageCode = evaluator?.profileImageCode;
  const headerData = {
    title: formatMessage(potentialAssessmentMessages.PotentialAssessmentTitle),
    date: potentialAssessmentResults?.period?.year,
    evaluators: {
      firstName: evaluatorFirstName,
      lastName: evaluatorLastName,
      list: potentialAssessmentResults?.evaluatorUsers,
      profileImageCode: evaluatorProfileImageCode,
    },
    createdByText: `${formatMessage(potentialAssessmentMessages.PotentialAssessmentCapturedBy)}:`,
  };
  const generalData = {
    description: <FormattedHTMLMessage
      defaultMessage={potentialAssessmentMessages.PotentialAssessmentGeneralResultBody.defaultMessage}
      id={potentialAssessmentMessages.PotentialAssessmentGeneralResultBody.id}
      values={{ company: companyName, Talent: talentName }}
    />,
    moreInfoText: <FormattedHTMLMessage
      defaultMessage={potentialAssessmentMessages.PotentialAssessmentGeneralResultLink.defaultMessage}
      id={potentialAssessmentMessages.PotentialAssessmentGeneralResultLink.id}
    />,
    onMoreInfoClick: onMoreInfoClickHandler,
    title: formatMessage(potentialAssessmentMessages.PotentialAssessmentGeneralResult),
    score: obtainedScore.value,
    scores: potentialAssessmentResults.selectedScoreRanges,
  };
  const insightData = {
    description: obtainedScore.description,
    icon: obtainedScore.icon,
    title: formatMessage(potentialAssessmentMessages.PotentialAssessmentInsight),
  };
  const dimensionsData = {
    title: formatMessage(potentialAssessmentMessages.PotentialAssessmentResultByDimensions),
    description: formatMessage(potentialAssessmentMessages.PotentialAssessmentResultByDimensionsBody),
    onToggleClick: onShowDescriptionsClickHandler,
    dimensionsLabel: formatMessage(potentialAssessmentMessages.PotentialAssessmentDimensions),
    showDescriptions: showDescriptions,
    barColor: obtainedScore.color,
    scores: [
      {
        id: 1,
        max: 1,
        min: 1,
        name: formatMessage(potentialAssessmentMessages.PotentialAssessmentDimensionScore1),
      },
      {
        id: 2,
        max: 2,
        min: 2,
        name: formatMessage(potentialAssessmentMessages.PotentialAssessmentDimensionScore2),
      },
      {
        id: 3,
        max: 3,
        min: 3,
        name: formatMessage(potentialAssessmentMessages.PotentialAssessmentDimensionScore3),
      }
    ],
    dimensions: obtainedDimensions,
  };

  const commentsData = {
    color: obtainedScore.color,
    data: comments,
    title: formatMessage(potentialAssessmentMessages.PotentialAssessmentComments),
  };

  useEffect(() => {
    if (!isEmpty(potentialAssessmentResults) && potentialType) {
      const segmentProps = SEGMENTS.find(element => element.name === potentialType);
      const finalScoreResultNumber = Number(potentialAssessmentResults?.selectedRatingKey?.replace('fs.result', ''));
      const scoreDescriptionId = `PotentialAssessment${segmentProps.segment}FinalScore${finalScoreResultNumber}`;
      const scoreDescription = formatMessage(potentialAssessmentMessages[scoreDescriptionId]);
      const scoreProps = SCORE_PROPS.find(score => score.scoreValue === finalScoreResultNumber);
      const scoreColor = scoreProps.scoreColor;
      const scoreImage = scoreProps.scoreImage;
      setComments(potentialAssessmentResults?.comments)

      setObtainedScore({
        color: scoreColor,
        description: scoreDescription,
        icon: scoreImage,
        value: potentialAssessmentResults?.selectedScore,
      });

      const dimensions = potentialAssessmentResults?.competencies.map(
        dimension => {
          const dimensionId = dimension.competency.name
            .replace(/\s+/g, '')
            .replace(/&/g, '')
            .replace(/-/g, '')
            .toLowerCase();
          const dimensionNameId = `PotentialAssessment${segmentProps.segment}${dimensionId}`;
          const dimensionName = potentialAssessmentMessages?.[dimensionNameId]?.id
            ? formatMessage(potentialAssessmentMessages[dimensionNameId])
            : '';
          const dimensionScore = Number(dimension.totalRateKey?.replace('ds.result', ''));;
          const dimensionDescriptionId = `PotentialAssessment${segmentProps.segment}${dimensionId}Result${dimensionScore}`;
          const dimensionDescription = potentialAssessmentMessages?.[dimensionDescriptionId]?.id
            ? formatMessage(potentialAssessmentMessages[dimensionDescriptionId])
            : '';

          return {
            name: dimensionName,
            description: dimensionDescription,
            score: dimensionScore,
          }
        }
      );

      setObtainedDimensions(dimensions)
    }
  }, [potentialAssessmentResults, potentialType]);

  useEffect(() => {
    if (isEmpty(userInfo)) {
      getUserInfo(base64Decode(params.userid));
    };
  }, []);

  useEffect(() => {
    return () => {
      resetPotentialAssessmentResults();
      resetUserInfo();
    }
  }, []);

  const isLoading = (userInfoLoading || potentialTypeLoading || loadingPotentialAssessmentResults);

  if (!isLoading && isEmpty(potentialAssessmentResults)) {
    if (!isEmpty(userInfo) && isEmpty(potentialType)) {
      getPotentialType(userInfo.id);
    };

    if (!isEmpty(userInfo) && !isEmpty(potentialType) && isEmpty(potentialAssessmentResults)) {
      getPotentialAssessmentResults({
        type: potentialType,
        userId: userInfo.id,
      });
    };
  }

  return (
    <FullScreenSection
      headerValues={[
        profileSection,
        formatMessage(potentialAssessmentMessages.PotentialAssessmentTitle),
      ]}
      loading={potentialTypeLoading || loadingPotentialAssessmentResults}
      navigateBack={() => goToProfile(userInfo.identification)}
    >
      {!isEmpty(potentialAssessmentResults) && (
        <div className="potential-assessment-results-page__content">
          <Header headerData={headerData} />
          <div className="potential-assessment-results-page__content-row">
            <General generalData={generalData} />
            <Insight insightData={insightData} />
            <Comments commentsData={commentsData} />
          </div>
          <div className="potential-assessment-results-page__content-row">
            <Dimensions dimensionsData={dimensionsData} />
            <div className="potential-assessment-results-page__content-disclaimer">
              <Icon
                className="potential-assessment-results-page__content-disclaimer-icon"
                icon="warning"
                size={20}
              />
              <p className="potential-assessment-results-page__content-disclaimer-text">
                {disclaimer}
              </p>
            </div>
          </div>
        </div>
      )}
    </FullScreenSection >
  );
};

PotentialAssessmentResults.propTypes = {
  getUserInfo: PropTypes.func,
  goToProfile: PropTypes.func,
  intl: PropTypes.object,
  params: PropTypes.object,
  resetUserInfo: PropTypes.func,
  startDate: PropTypes.object,
  userInfo: PropTypes.object,
};

const mapStateToProps = (state) => ({
  userInfo: selectuserInfo(state),
  potentialAssessmentResults: selectPotentialAssessmentResults(state),
  potentialType: selectPotentialType(state),
  loadingPotentialAssessmentResults: selectPotentialAssessmentResultsLoading(state),
  potentialTypeLoading: selectPotentialTypeLoading(state),
  user: selectUser(state),
  userInfoLoading: selectuserInfoLoading(state),
});

const mapDispatchToProps = {
  getUserInfo,
  getPotentialType,
  getPotentialAssessmentResults,
  resetPotentialAssessmentResults,
  resetUserInfo,
  goToProfile: (identification) => push(getProfileLocation(identification)),
};


export default connect(mapStateToProps, mapDispatchToProps)
  (injectIntl(PotentialAssessmentResults));
