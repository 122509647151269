// @packages
import moment from 'moment';

export function getDropdownRangeList(startTime, endTime, endMonth, limitPeriod) {
  const startTimePeriod = startTime.clone();
  const endTimePeriod = endTime.clone();
  let timeValues = [];

  while (endTimePeriod > startTimePeriod || startTimePeriod.format('M') === endTimePeriod.format('M')) {
    const item = {
      value: endMonth
        ? startTimePeriod.endOf('month').millisecond(0).valueOf()
        : startTimePeriod.startOf('month').valueOf(),
      label: startTimePeriod.format('MMMM YYYY'),
      disabled: endMonth
        ? startTimePeriod < limitPeriod
        : startTimePeriod > limitPeriod,
    };
    timeValues.push(item);
    startTimePeriod.add(1,'month');
  }

  return timeValues;
}
