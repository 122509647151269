// Feedbacks summary
export const INITIAL_FEEDBACKS_SUMMARY = {
  pendingSentFeedbackRequests: {},
  receivedFeedback: {},
  sentFeedback: {},
};

// Goals summary
export const INITIAL_SUMMARY_GOALS = {
  opened: 0,
  closed: 0,
  pending: 0,
  total: 0,
};

//Team dashboard
export const RECEIVED = 'RECEIVED';
