import {
  CREATE_PROMOTION_REQUEST,
  CREATE_PROMOTION_REQUEST_FAIL,
  CREATE_PROMOTION_REQUEST_SUCCESS,
  DELETE_PROMOTIONS_FILE,
  DELETE_PROMOTIONS_FILE_FAIL,
  DELETE_PROMOTIONS_FILE_SUCCESS,
  GET_POSITIONS_HISTORY,
  GET_POSITIONS_HISTORY_FAIL,
  GET_POSITIONS_HISTORY_SUCCESS,
  GET_PROMOTIONS_LEVEL,
  GET_PROMOTIONS_LEVEL_FAIL,
  GET_PROMOTIONS_LEVEL_SUCCESS,
  GET_PROMOTION_COLLABORATOR,
  GET_PROMOTION_COLLABORATOR_FAIL,
  GET_PROMOTION_COLLABORATOR_SUCCESS,
  GET_USER_PROMOTION_REQUESTS,
  GET_USER_PROMOTION_REQUESTS_FAIL,
  GET_USER_PROMOTION_REQUESTS_SUCCESS,
  GET_PROMOTION_SUGGESTED_VALIDATORS,
  GET_PROMOTION_SUGGESTED_VALIDATORS_FAIL,
  GET_PROMOTION_SUGGESTED_VALIDATORS_SUCCESS,
  RESET_PROMOTIONS_FILES,
  RESET_SUGGEST_PROMOTION,
  SET_PROMOTION_FILES,
  UPLOAD_PROMOTIONS_FILE,
  UPLOAD_PROMOTIONS_FILE_FAIL,
  UPLOAD_PROMOTIONS_FILE_SUCCESS,
} from './actionTypes';

export function getPromotionsLevel( userId, message ) {
  return {
    type: GET_PROMOTIONS_LEVEL,
    payload: { userId, message }
  };
}

export function getPromotionsLevelFail(error) {
  return {
    type: GET_PROMOTIONS_LEVEL_FAIL,
    payload: { error },
  };
}

export function getPromotionsLevelSuccess(response) {
  return {
    type: GET_PROMOTIONS_LEVEL_SUCCESS,
    payload: { response },
  };
}

export function getUserPromotionRequests(data) {
  return {
    type: GET_USER_PROMOTION_REQUESTS,
    payload: { data },
  };
}

export function getUserPromotionRequestsFail(error) {
  return {
    type: GET_USER_PROMOTION_REQUESTS_FAIL,
    payload: { error },
  };
}

export function getUserPromotionRequestsSuccess(response) {
  return {
    type: GET_USER_PROMOTION_REQUESTS_SUCCESS,
    payload: { response },
  };
}

export function getPromotionSuggestedValidators(data) {
  return {
    type: GET_PROMOTION_SUGGESTED_VALIDATORS,
    payload: { data },
  };
}

export function getPromotionSuggestedValidatorsFail(error) {
  return {
    type: GET_PROMOTION_SUGGESTED_VALIDATORS_FAIL,
    payload: { error },
  };
}

export function getPromotionSuggestedValidatorsSuccess(response) {
  return {
    type: GET_PROMOTION_SUGGESTED_VALIDATORS_SUCCESS,
    payload: { response },
  };
}

export function getPositionsHistory(userId) {
  return {
    type: GET_POSITIONS_HISTORY,
    payload: { userId },
  };
}

export function getPositionsHistoryFail(error) {
  return {
    type: GET_POSITIONS_HISTORY_FAIL,
    payload: { error },
  };
}

export function getPositionsHistorySuccess(response) {
  return {
    type: GET_POSITIONS_HISTORY_SUCCESS,
    payload: { response },
  };
}

export function uploadPromotionsFile(file) {
  return {
    type: UPLOAD_PROMOTIONS_FILE,
    payload: { file },
  }
}

export function uploadPromotionsFileFail(error) {
  return {
    type: UPLOAD_PROMOTIONS_FILE_FAIL,
    payload: { error },
  }
}

export function uploadPromotionsFileSuccess(response) {
  return {
    type: UPLOAD_PROMOTIONS_FILE_SUCCESS,
    payload: { response },
  }
}

export function deleteFile(id) {
  return {
    type: DELETE_PROMOTIONS_FILE,
    payload: { id },
  }
}

export function deleteFileFail(error) {
  return {
    type: DELETE_PROMOTIONS_FILE_FAIL,
    payload: { error },
  }
}

export function deleteFileSuccess(id) {
  return {
    type: DELETE_PROMOTIONS_FILE_SUCCESS,
    payload: { id },
  }
}

export function createPromotionRequest(payload) {
  return {
    type: CREATE_PROMOTION_REQUEST,
    payload,
  }
}

export function createPromotionRequestFail(error) {
  return {
    type: CREATE_PROMOTION_REQUEST_FAIL,
    payload: { error },
  }
}

export function createPromotionRequestSuccess() {
  return {
    type: CREATE_PROMOTION_REQUEST_SUCCESS,
  }
}

export function getPromotionCollaborator({ identification, messages }) {
  return {
    type: GET_PROMOTION_COLLABORATOR,
    payload: { identification, messages },
  }
}

export function getPromotionCollaboratorFail(error) {
  return {
    type: GET_PROMOTION_COLLABORATOR_FAIL,
    payload: { error },
  }
}

export function getPromotionCollaboratorSuccess(response) {
  return {
    type: GET_PROMOTION_COLLABORATOR_SUCCESS,
    payload: { response },
  }
}

export function setPromotionFiles(files) {
  return {
    type: SET_PROMOTION_FILES,
    payload: { files },
  }
}

export function resetSuggestPromotion() {
  return {
    type: RESET_SUGGEST_PROMOTION,
  }
}

export function resetPromotionsFiles() {
  return {
    type: RESET_PROMOTIONS_FILES,
  }
}
