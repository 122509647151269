// @packages
import React from 'react';
import AvatarMedia from 'smu-ui-components/AvatarMedia';
import Button from 'smu-ui-components/ButtonV2';
import Label from 'smu-ui-components/Label';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @own
import './styles.scss';

const UserAffinity = ({
  affinity: {
    affinityType: {
      high,
      low,
      medium,
      minimum,
    },
    buttonText,
    color,
    handleSendRequest,
    labelText,
  },
  className,
  user,
}) => (
  <div className={cn('user-affinity', className)}>
    <span className="user-affinity__user">
      <AvatarMedia
        {...user}
        useLink={false}
        firstName={user?.firstname}
        lastName={user?.lastname}
        className={cn('user-affinity__user-avatar',
          {
            'user-affinity__user-avatar--high': high,
            'user-affinity__user-avatar--medium': medium,
            'user-affinity__user-avatar--low': low,
            'user-affinity__user-avatar--minimum': minimum,
          })}
        noJob
      />
      <Label
        className="user-affinity__user-label"
        color={color}
        size="small"
        text={labelText}
      />
    </span>
    <Button
      className="user-affinity__user-button"
      onClick={(event) => handleSendRequest(event)}
      size="medium"
      variant="outline"
    >
      {buttonText}
    </Button>
  </div>
);

UserAffinity.propTypes = {
  affinity: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
};

export default UserAffinity;
