// @packages
import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Link from 'smu-ui-components/Link';
import { injectIntl } from 'react-intl';
import withFullScreenModal from 'smu-app-components/FullScreenModal/withFullScreenModal';
import LottieSpinner from 'smu-ui-components/LottieSpinner';
import TextWrapper from 'smu-ui-components/TextWrapper';

// @app
import Card from 'common/Card';
import EmptyStateCard from 'common/EmptyStateCard';
import InfoCountItem from 'common/InfoCountItem';
import { actionsMessages } from 'common/messages';
import { SUGGEST_ACTIONS_LIST_MODAL } from '../../constants/modalTypes';
import { trackSuggestedActionsViewMore } from '../../Profile/analytics';

// @own
import './styles.scss';
import messages from '../messages';

class DashboardSuggestions extends Component {
  constructor() {
    super();

    this.handleViewMore = this.handleViewMore.bind(this);
  }

  handleViewMore() {
    const {
      endDate,
      openFullscreenModal,
      startDate,
      user,
    } = this.props;
    trackSuggestedActionsViewMore();

    openFullscreenModal({
      modalType: SUGGEST_ACTIONS_LIST_MODAL,
      modalProps: {
        from: startDate ? startDate.valueOf() : undefined,
        to: endDate ? endDate.valueOf() : undefined,
        user,
      }
    });
  }

  render() {
    const {
      intl: { formatMessage },
      loadingSuggestion,
      suggestedActions,
      user,
    } = this.props;

    return (
      <Card className={cn('dashboard-suggestions', {
        'dashboard-suggestions--loading': loadingSuggestion,
      })}>
        <Card.Header>
          <Card.Title>
            {formatMessage(messages.DashboardSuggestionsTitle)}
          </Card.Title>
          <Card.Meta strong uppercase>
            {formatMessage(messages.DashboardSuggestionsSubtitle, { username: user.firstName })}
          </Card.Meta>
        </Card.Header>
        {loadingSuggestion ? (
          <LottieSpinner
            className="dashboard-suggestions__spinner"
            loadingType="betterme"
          />
        ) : (
            <React.Fragment>
              {suggestedActions.length > 0 ? (
                <React.Fragment>
                  <Card.Content fullPadding>
                    <ul className="dashboard-suggestions__list">
                      {suggestedActions.map(action =>
                        <InfoCountItem
                          key={action.id}
                          count={action.amount}
                          blue
                        >
                          <TextWrapper ellipsis>
                            {action.name}
                          </TextWrapper>
                        </InfoCountItem>
                      )}
                    </ul>
                  </Card.Content>
                  <Card.Actions>
                    <Link
                      color="black"
                      onClick={this.handleViewMore}
                      size="small"
                      type="button"
                    >
                      {formatMessage(actionsMessages.ActionsViewMore)}
                    </Link>
                  </Card.Actions>
                </React.Fragment>
              ) : (
                  <EmptyStateCard className="dashboard-suggestions__empty-state">
                    <EmptyStateCard.Title>
                      {formatMessage(messages.DashboardSuggestionsEmptyMessage, { username: user.firstName })}
                    </EmptyStateCard.Title>
                  </EmptyStateCard>
                )}
            </React.Fragment>
          )}
      </Card>
    );
  }
}

DashboardSuggestions.propTypes = {
  endDate: PropTypes.object,
  loadingSuggestion: PropTypes.bool,
  startDate: PropTypes.object,
  suggestedActions: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
};

export default withFullScreenModal(injectIntl(DashboardSuggestions));
