// @packages
import React from 'react';
import cn from 'classnames';
import InputArea from 'smu-ui-components/InputArea';
import MessageBox from 'smu-ui-components/MessageBox';

// @app
import CoEvaluationSwitch from 'components/molecules/CoEvaluationSwitch';
import CommentBox from 'components/molecules/CommentBox';
import EmptyStateBox from 'components/atoms/EmptyStateBox';
import ScoreSelection from 'components/organisms/ScoreSelection';
import UploadFile from 'betterme-components/components/containers/UploadFile';
import DescriptionTooltip from 'common/DescriptionTooltip';

// @own
import './styles.scss';

const ScoreSelector = ({
  attachFiles,
  className,
  coEvaluator,
  hasCoevaluator,
  scoreSelector: {
    disabled,
    onScoreChange,
    scoreOptions,
    scorePlaceholder,
    scoreValue,
  },
  textArea: {
    comment,
    placeholder,
    onTextChange,
  },
  title,
  hasTooltip
}) => {
  const coCreatedScore = coEvaluator?.coCreatedData?.score;
  const isCoCreatedSubmitted = coEvaluator?.coCreatedData?.status === 'SUBMITTED';

  const options = hasTooltip
    ? scoreOptions?.map((item) => ({
      ...item,
      label: (
        <DescriptionTooltip
          description={!!item?.description ? item?.description : item?.label}
        >
          {item?.label}
        </DescriptionTooltip>
      ),
    }))
    : scoreOptions;

  return (
    <CommentBox
      className={cn('score-selector-template', className)}
      title={title}
    >
      <div className="p-6">
        {
          hasCoevaluator
          && isCoCreatedSubmitted
          && (coEvaluator?.showScoreMessage || (scoreValue && (coCreatedScore !== scoreValue)))
          && (
            <MessageBox
              className="score-selector-template__message-box"
              type="warning"
            >
              {coEvaluator?.scoreMessage}
            </MessageBox>
          )
        }
        <ScoreSelection
          className="score-selector-template__score-selection"
          disabled={disabled}
          onChange={onScoreChange}
          options={options}
          placeholder={scorePlaceholder}
          value={scoreValue}
        />
        <InputArea
          className="score-selector-template__input-area"
          maxLength={3000}
          onChange={onTextChange}
          placeholder={placeholder}
          rows={12}
          value={comment}
        />
        {attachFiles && (
          <UploadFile
            className="score-selector-template__upload-file"
            customText={attachFiles?.customText}
            disabled={attachFiles?.files?.length >= attachFiles?.filesMaxLenght}
            files={attachFiles?.files}
            loading={attachFiles?.filesLoading}
            onChange={attachFiles?.onChange}
            onDeleteAccept={attachFiles?.onDeleteAccept}
            supportedFormats={attachFiles?.supportedFormats}
          />
        )}
        {hasCoevaluator && (
          <div className="score-selector-template__co-evaluator">
            <CoEvaluationSwitch
              className="score-selector-template__co-evaluator-switch"
              onSwitchChange={coEvaluator?.onSwitchChange}
              switchDisabled={false}
              switchEnabled={coEvaluator?.switchEnabled}
              switchId={'SCORE-SELECTOR_SWITCH'}
              subtitle={coEvaluator?.switchSubtitle}
              switchText={coEvaluator?.switchText}
              title={coEvaluator?.switchTitle}
            />
            {coEvaluator?.coCreatedData?.comment ? (
              <InputArea
                className={cn("score-selector-template__co-evaluator-comment", {
                  'score-selector-template__co-evaluator-comment--disabled': !(coEvaluator?.showCoevaluatorScore),
                })}
                rows={6}
                value={coEvaluator?.coCreatedData?.comment}
              />
            ) : (
              <EmptyStateBox
                className="score-selector-template__co-evaluator-empty"
                text={coEvaluator?.emptyState}
              />
            )
            }
          </div>
        )}
      </div>
    </CommentBox>
  );
}

export default ScoreSelector;
