// @packages
import React, { useEffect, useMemo } from 'react';
import Card from 'smu-ui-components/Card';
import Panel from 'smu-ui-components/Panel';
import PropTypes from 'prop-types';
import UserProfileOwner from 'smu-app-components/UserProfile/Owner';
import cn from 'classnames';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { openModal } from 'smu-app-components/RootModal/actions';
import { useMember } from 'smu-services/os-member/api/v1/community/member';
import { useRemainingStars } from 'smu-services/starmeup-api/v2/stellar/user/remaining/stars';

// @app
import { CROP_IMAGE } from 'betterme-components/constants/modalTypes';
import {
  ENABLED_BETHERE_FEED,
  ENABLED_BETTERME,
  ENABLED_BIRTHDAYS,
  ENABLED_ONE_ON_ONE,
  ENABLED_ONE_ON_ONE_REQUEST,
  ENABLED_SHARE_DNA,
  ENABLED_STARMEUP,
  ENABLED_SURVEYS_ONLY,
} from 'betterme-components/services/CommunityConfigs/constants';
import { actionsMessages } from 'common/messages';
import { getCommunityConfigValueBool } from 'betterme-components/services/CommunityConfigs/selectors';
import { selectUser } from 'betterme-components/Authorization/selectors';
import { updateProfileImage } from 'betterme-components/Authorization/actions';

// @own
import { getContainerProps, helperActionsProps } from './helpers';

const UserProfileOwnerContainer = ({
  actionsList,
  className,
  enableChangeProfileImage,
  enabledBethere,
  enabledBetterme,
  enabledOneOnOne,
  enabledOneOnOneRequest,
  enabledStarmeup,
  enabledSurveysOnly,
  intl: { formatMessage },
  openModal,
  showStars,
  updateProfileImage,
  user,
  showPanel,
  ...rest
}) => {
  const Container = showPanel ? Panel : Card;
  const containerProps = useMemo(
    () => getContainerProps({ showPanel }),
    [showPanel]
  );
  const userImageCode = user?.profileImageCode;
  const member = useMember();
  const memberImageCode = member.selectImageCode();
  const remainingStars = useRemainingStars({ shouldFetch: enabledStarmeup });
  const actionsProps = helperActionsProps({
    actionsList,
    enabledBethere,
    enabledBetterme,
    enabledOneOnOne,
    enabledOneOnOneRequest,
    enabledStarmeup,
    formatMessage,
    member,
    owner: true,
    remainingStars,
  });

  const handleUploadImage = (e) => {
    const file = e?.target?.files?.[0];

    openModal({
      modalType: CROP_IMAGE,
      modalProps: {
        closeOnDimmerClick: true,
        colorSaveButton: 'indigo',
        file,
        onCropImage: updateProfileImage,
        textCancelButton: formatMessage(actionsMessages.ActionsCancel),
        textSaveButton: formatMessage(actionsMessages.ActionsCropAndSave),
      },
    });
  };

  useEffect(() => {
    if (userImageCode && memberImageCode && userImageCode !== memberImageCode) {
      member.mutate();
    }
  }, [userImageCode, memberImageCode, member]);

  return (
    <Container
      className={cn(
        'bme-user-profile',
        {
          'px-3 py-4': !showPanel,
        },
        className
      )}
      {...containerProps}
    >
      <UserProfileOwner
        actionsProps={actionsProps}
        bettemeUrl=""
        enabledBethere={enabledBethere}
        enabledBetterme
        enabledOneOnOne={enabledOneOnOne}
        enabledOneOnOneRequest={enabledOneOnOneRequest}
        enabledStarmeup={enabledStarmeup}
        enabledSurveysOnly={enabledSurveysOnly}
        enabledUploadPhoto={enableChangeProfileImage}
        onChangePhoto={handleUploadImage}
        showCulturalLevels={enabledStarmeup}
        showFields={true}
        showQualifiers={false}
        showStars={false}
        {...rest}
      />
    </Container>
  );
};

UserProfileOwnerContainer.defaultProps = {
  actionsList: [],
  enableChangeProfileImage: true,
  enabledAnniversaries: false,
  enabledBethere: false,
  enabledBetterme: false,
  enabledBirthdays: false,
  enabledOneOnOne: false,
  enabledOneOnOneRequest: false,
  enabledShareDNA: false,
  enabledStarmeup: false,
  enabledSurveysOnly: false,
  showPanel: true,
};

UserProfileOwnerContainer.propTypes = {
  actionsList: PropTypes.array,
  enabledAnniversaries: PropTypes.bool,
  enabledBethere: PropTypes.bool,
  enabledBetterme: PropTypes.bool,
  enabledBirthdays: PropTypes.bool,
  enabledOneOnOne: PropTypes.bool,
  enabledOneOnOneRequest: PropTypes.bool,
  enabledShareDNA: PropTypes.bool,
  enabledStarmeup: PropTypes.bool,
  enabledSurveysOnly: PropTypes.bool,
  showPanel: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  enabledAnniversaries: getCommunityConfigValueBool(state, ENABLED_BIRTHDAYS),
  enabledBethere: getCommunityConfigValueBool(state, ENABLED_BETHERE_FEED),
  enabledBetterme: getCommunityConfigValueBool(state, ENABLED_BETTERME),
  enabledBirthdays: getCommunityConfigValueBool(state, ENABLED_BIRTHDAYS),
  enabledOneOnOne: getCommunityConfigValueBool(state, ENABLED_ONE_ON_ONE),
  enabledOneOnOneRequest: getCommunityConfigValueBool(
    state,
    ENABLED_ONE_ON_ONE_REQUEST
  ),
  enabledShareDNA: getCommunityConfigValueBool(state, ENABLED_SHARE_DNA),
  enabledStarmeup: getCommunityConfigValueBool(state, ENABLED_STARMEUP),
  enabledSurveysOnly: getCommunityConfigValueBool(state, ENABLED_SURVEYS_ONLY),
  user: selectUser(state),
});

export default connect(mapStateToProps, { openModal, updateProfileImage })(
  injectIntl(UserProfileOwnerContainer)
);
