import { push } from 'react-router-redux';
import { reduce, merge, replace } from 'lodash';

export const urls = {
  activity: '/me/activity/:activityId',
  activityComments: '/me/comments/:starId',
  advancedSearch: '/advancedSearch',
  communications: '/communications',
  congratulation: '/congratulation/:id',
  congratulationFeed: '/congratulation/feed/:userId',
  congratulations: '/congratulations',
  congratulationUser: '/congratulation/user/:userId',
  cookiesPolicy: '/cookies',
  discover: '/discover',
  giveStar: '/givestar',
  hashtags: '/hashtags/:hashtag',
  hashtagsBethere: '/hashtags/bethere/:hashtag',
  home: '/home(/questionnaire)(/:questionnaireId)',
  initiatives: '/initiatives(/:campaingnName)',
  insights: '/insights',
  leaderboard: '/leaderboard',
  myProfile: '/me(/sendStar)(/about-me)(/metrics)(/social-activity)(/communications)(/teams)(/sendStar)(/:sendStarProfile)',
  networkError: '/error503',
  newFeatures: '/updates',
  notificationsHistory: '/notifications',
  onboarding: '/onboarding',
  // it's commented because we are working with a different version in react-redux
  // osFeed: '/osfeed',
  profile: '/profile/:profileId(/sendStar)(/about-me)(/metrics)(/social-activity)(/communications)(/teams)(/sendStar)(/:sendStarProfile)',
  settings: '/settings/:tab',
  timeline: '/timeline/:profileId/:direction',
  unsubscribe: '/unsubscribe',
  widgetDNA: '/widgets/dna',
  widgetFeed: '/widgets/feed',
  widgetLeaderboard: '/widgets/leaderboard',
  widgetTopStars: '/widgets/top-stars',
  widgetTrends: '/widgets/trends',
  yir: 'yearInReview',
  bethere: '/posts/:cardId',
  questionnaireResults: '/questionnaire/:questionnaireId/results',
  insightDetail: '/insights/:id',
  share: '/share/:type',
  component: '/component/:name',
  external: '/externalLink',
  whatsnew: '/whats-new',
  notFound: '*',
};

const toPath = (url, params) => {
  const parsedUrl = url.replace(/ *\([^)]*\) */g, '');
  return reduce(params, (parsedUrl, v, param) => replace(parsedUrl, `:${param}`, String(v)), parsedUrl);
};

export const paths = reduce(
  urls,
  (memo, url, k) => {
    const f = params => toPath(url, params || {});
    return merge(memo, { [k]: f });
  },
  {},
);

export const actions = reduce(
  urls,
  (memo, url, k) => {
    const f = (params = {}, state) => push({
      pathname: toPath(url, params),
      state,
    });
    return merge(memo, { [k]: f });
  },
  {},
);
