// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import UserAvatar from 'smu-ui-components/UserAvatar';

// @own
import './styles.scss';

const UserAvatarList = ({
  bordered,
  className,
  maxDisplay,
  showPopup,
  size,
  popupContent,
  users,
}) => {
  const slicedList = users?.slice(0, maxDisplay);
  const hiddenUsersAmount = users?.length - slicedList?.length;
  const superpositionPx = `-${(size / 5)}px`;
  const usersHiddenStyle = {
    height: `${(size)}px`,
    marginLeft: superpositionPx,
    width: `${(size)}px`,
  };
  return (
    <ul className={cn('user-avatar-list', className)}>
      {slicedList?.map((user, index) => {
        return (
          <li
            className="user-avatar-list__item"
            key={user?.id}
            style={index > 0 ? { marginLeft: superpositionPx } : undefined}
          >
              <UserAvatar
                bordered={bordered}
                height={size}
                popupContent={popupContent && popupContent(user)}
                profileImageCode={user?.profileImageCode}
                showPopup={showPopup}
                user={user}
                width={size}
              />
          </li>
        )
      }
      )}
      {hiddenUsersAmount > 0 && (
        <li
          className="user-avatar-list__users-hidden"
          style={usersHiddenStyle}
        >
          <div className="user-avatar-list__users-hidden-ammount">
            {`+${hiddenUsersAmount}`}
          </div>
        </li>
      )}
    </ul>
  );
};

UserAvatarList.propTypes = {
  className: PropTypes.string,
  maxDisplay: PropTypes.number.isRequired,
  popupContent: PropTypes.func,
  size: PropTypes.number.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  ),
};

export default UserAvatarList;
