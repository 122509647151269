// @packages
import React, { useEffect } from 'react';
import I18nProvider from 'smu-i18n/Provider';
import PropTypes from 'prop-types';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { IntlProvider, addLocaleData } from 'react-intl';
import { connect } from 'react-redux';
import { getItem } from 'smu-utils/storage';
import { getUserLanguage, getQueryLanguage, normaliceLanguage } from 'smu-utils/language';

// ========================================================
// Internationalization Intl
// ========================================================
import de from 'react-intl/locale-data/de';
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import fr from 'react-intl/locale-data/fr';
import he from 'react-intl/locale-data/he';
import hi from 'react-intl/locale-data/hi';
import it from 'react-intl/locale-data/it';
import pl from 'react-intl/locale-data/pl';
import pt from 'react-intl/locale-data/pt';

// ========================================================
// Internationalization MomentJS
// ========================================================
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/he';
import 'moment/locale/hi';
import 'moment/locale/it';
import 'moment/locale/pl';
import 'moment/locale/pt';

// @app
import { logout } from 'betterme-components/Authorization/actions';
import { selectUser } from 'betterme-components/Authorization/selectors';

// @own
import { requestLocale } from './actions';
import { selectI18nLocale, selectI18nMessages } from './selectors';

addLocaleData([...de, ...en, ...es, ...fr, ...he, ...hi, ...it, ...pl, ...pt]);

const ConnectedIntlProvider = ({
  children,
  locale,
  logout,
  messages,
  requestLocale,
  user,
}) => {
  useEffect(() => {
    if (user) {
      const baseUrl = getItem('baseUrl');

      const userLanguageCode = normaliceLanguage(getQueryLanguage())
        || user?.profile?.locale?.language
        || getUserLanguage();

      if (baseUrl) {
        requestLocale(userLanguageCode);
        moment.locale(userLanguageCode);
        momentTz.defineLocale(locale, moment.localeData()._config);
        momentTz.locale(locale);
      } else {
        logout();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <IntlProvider locale={locale} messages={messages}>
      <I18nProvider>
        {children}
      </I18nProvider>
    </IntlProvider>
  );
}

ConnectedIntlProvider.propTypes = {
  applicationId: PropTypes.number,
  children: PropTypes.element,
  locale: PropTypes.string,
  logout: PropTypes.func,
  messages: PropTypes.object,
  requestLocale: PropTypes.func,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  locale: selectI18nLocale(state),
  messages: selectI18nMessages(state),
  user: selectUser(state),
});

const mapDispatchToProps = {
  logout,
  requestLocale,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedIntlProvider);
