import {
  ADD_COMPETENCE,
  ADD_DELETED_SKILL,
  ADD_FEEDBACK_COLLABORATOR,
  ADD_SKILLS,
  CHANGE_FEEDBACK_SELECTED_COLLABORATORS,
  CHANGE_FEEDBACK_TYPE,
  CLEAN_DELETED_SKILLS,
  CLEAN_RATED_COMPETENCES_STATE,
  CLEAN_SEND_FEEDBACK_REQUEST_STATE,
  CLEAN_SKILLS,
  CLEAN_USER_LIST,
  CREATE_REQUEST_FEEDBACK_ON_BEHALF,
  CREATE_REQUEST_FEEDBACK_ON_BEHALF_FAIL,
  CREATE_REQUEST_FEEDBACK_ON_BEHALF_SUCCESS,
  FEEDBACK_ERROR,
  FEEDBACK_SENT,
  GET_REQUEST_FEEDBACK_INFO,
  GET_REQUEST_FEEDBACK_INFO_ERROR,
  GET_REQUEST_FEEDBACK_INFO_SUCCESS,
  GET_USERS_SUCCESS,
  INIT_COMPETENCES,
  INIT_MANDATORY_COMPETENCES,
  INIT_REPLY_REQUEST_FEEDBACK_DATA,
  INIT_USER,
  REMOVE_COMPETENCE,
  REMOVE_FEEDBACK_ALL_COLLABORATORS,
  REMOVE_FEEDBACK_COLLABORATOR,
  REPLY_REQUEST_FEEDBACK,
  REQUEST_FEEDBACK,
  SELECT_COMPETENCE,
  SELECT_FEEDBACK_USER,
  SEND_FEEDBACK,
  UNSELECT_COMPETENCE,
  UNSELECT_FEEDBACK_USER,
  UPDATE_COMPETENCES,
} from './actionTypes';

const initialState = {
  competencies: [],
  confirmationType: 'SEND',
  deletedSkills: [],
  error: '',
  mandatoryCompetencies: [],
  ratedCompetencies: [],
  replyRequestComment: undefined,
  requestFeedbackInfo: {},
  requestId: undefined,
  requestedBy: undefined,
  selectedCollaborators: [],
  selectedCompetencies: [],
  selectedUsers: [],
  sending: false,
  skills: {
    SEND: [],
    REQUEST: [],
  },
  type: 'SEND',
  userList: [],
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case ADD_SKILLS:
      return {
        ...state, 
        skills: { 
          ...state.skills,
          [payload.type]: payload.skills 
        }
      };
    case ADD_DELETED_SKILL:
      return {
        ...state,
        deletedSkills: [
          ...state.deletedSkills, 
          payload.skill
        ]
      };
    case CLEAN_DELETED_SKILLS:
      return {
        ...state,
        deletedSkills: []
      };
    case CLEAN_SKILLS:
      return {
        ...state,
        skills: []
      };
    case CHANGE_FEEDBACK_TYPE:
      return {
        ...initialState,
        competencies: state.competencies,
        selectedUsers: state.selectedUsers,
        ratedCompetencies: [],
        skills: [],
        type: state.type === 'SEND' ? 'REQUEST' : 'SEND',
        user: state.user,
        userList: state.userList,
      };
    case CLEAN_SEND_FEEDBACK_REQUEST_STATE:
      return { ...initialState };
    case CLEAN_RATED_COMPETENCES_STATE:
      return { ...state, ratedCompetencies: [] };
    case CLEAN_USER_LIST:
      return { ...state, userList: [] };
    case REPLY_REQUEST_FEEDBACK:
      return { ...state, sending: true, error: '' };
    case REQUEST_FEEDBACK:
    case CREATE_REQUEST_FEEDBACK_ON_BEHALF:
      return { ...state, sending: true, error: '' };
    case SEND_FEEDBACK:
      return { ...state, sending: true, error: '' };
    case FEEDBACK_SENT:
    case CREATE_REQUEST_FEEDBACK_ON_BEHALF_SUCCESS:
      return { ...state, sending: false, error: '', confirmationType: payload.type };
    case FEEDBACK_ERROR:
    case CREATE_REQUEST_FEEDBACK_ON_BEHALF_FAIL:
      return { ...state, sending: false, error: payload.error };
    case ADD_COMPETENCE: {
      const ratedCompetency = { competency: payload.competency, rateId: payload.rateId };
      const ratedCompetencies = [...state.ratedCompetencies, ratedCompetency];
      return { ...state, ratedCompetencies };
    }
    case REMOVE_COMPETENCE: {
      const { competency } = payload;
      const ratedCompetencies = state.ratedCompetencies.filter((ratedCompetency) => ratedCompetency.competency.id !== competency.id);
      return { ...state, ratedCompetencies };
    }
    case SELECT_COMPETENCE: {
      const { competency } = payload;
      const selectedCompetencies = [...state.selectedCompetencies, competency];
      return { ...state, selectedCompetencies };
    }
    case UNSELECT_COMPETENCE: {
      const { competency } = payload;
      const selectedCompetencies = state.selectedCompetencies.filter((selectedCompetency) => selectedCompetency.id !== competency.id);
      return { ...state, selectedCompetencies };
    }
    case INIT_COMPETENCES: {
      const { competencies } = payload;
      return { ...state, competencies };
    }
    case UPDATE_COMPETENCES: {
      const { competencies: ratedCompetencies } = payload;
      return { ...state, ratedCompetencies };
    }
    case INIT_REPLY_REQUEST_FEEDBACK_DATA: {
      const {
        comment,
        requestId,
        requestedBy,
      } = payload;
      return {
        ...state,
        replyRequestComment: comment,
        requestId: parseInt(requestId, 10),
        requestedBy,
      };
    }
    case INIT_USER: {
      const { user } = payload;
      return { ...state, user };
    }
    case INIT_MANDATORY_COMPETENCES: {
      const { competencies } = payload;
      const mandatoryCompetencies = competencies.filter(
        (competency) => competency.active
      );
      const skills = mandatoryCompetencies.filter(
        (competency) => competency.skill
      );
      const ratedCompetencies = skills.map((skill) => ({
        competency: skill,
        rateId: undefined,
      }));
      return {
        ...state,
        mandatoryCompetencies,
        ratedCompetencies,
      };
    }
    case GET_REQUEST_FEEDBACK_INFO:
      return { ...state, loadingReply: true };
    case GET_REQUEST_FEEDBACK_INFO_ERROR:
      return { ...state, loadingReply: false };
    case GET_REQUEST_FEEDBACK_INFO_SUCCESS:
      return { ...state, requestFeedbackInfo: payload.response, loadingReply: false };
    case GET_USERS_SUCCESS:
      return { ...state, userList: payload.response };
    case SELECT_FEEDBACK_USER: {
      const { user, isMultiple } = payload;
      const selectedUsers = isMultiple ? [...state.selectedUsers, user] : [user];
      return { ...state, selectedUsers };
    }
    case ADD_FEEDBACK_COLLABORATOR: {
      const { collaborator } = payload;
      const selectedCollaborators = [...state.selectedCollaborators, collaborator];
      return { ...state, selectedCollaborators };
    }
    case REMOVE_FEEDBACK_ALL_COLLABORATORS:
      return { ...state, selectedCollaborators: [] };
    case REMOVE_FEEDBACK_COLLABORATOR: {
      const { collaborator } = payload;
      const selectedCollaborators = state.selectedCollaborators.filter(({ id }) => id !== collaborator.id);
      return { ...state, selectedCollaborators };
    }
    case UNSELECT_FEEDBACK_USER:
      return { ...state, selectedUsers: state.selectedUsers.filter(user => user.id !== payload.userId) };
    case CHANGE_FEEDBACK_SELECTED_COLLABORATORS:
      return { ...state, selectedCollaborators: payload.selectedCollaborators };
    default:
      return state;
  }
}
