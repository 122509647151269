// @packages
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';
import Segment from 'smu-ui-components/Segment';

// @app
import TeamEvaluationsStatus from 'components/organisms/TeamEvaluationsStatus';
import { updateFilterConfig } from 'betterme-components/services/MyTeamEvaluations/actions';
import CardSimple from 'common/CardSimple';

const TeamEvaluationsStatusContainer = ({
  evaluationsCount,
  link,
  loading,
  messages,
  navigateTo,
  updateFilterConfig,
}) => {
  const handleOnClick = (status) => {
    const filterConfig = status ? { [status]: true } : {};
    updateFilterConfig(filterConfig);
    navigateTo(link.to);
  };

  return (
    <Segment loading={loading} loadingType="betterme">
      <CardSimple>
        <TeamEvaluationsStatus
          evaluationsCount={evaluationsCount}
          messages={messages}
          onSeeMoreClick={handleOnClick}
          onStatusClick={handleOnClick}
        />
      </CardSimple>
    </Segment>
  );
};

const mapDispatchToProps = {
  updateFilterConfig,
  navigateTo: (route) => push({ pathname: route.pathname }),
};

TeamEvaluationsStatusContainer.propTypes = {
  evaluationsCount: PropTypes.array,
  link: PropTypes.object,
  messages: PropTypes.object,
  navigateTo: PropTypes.func,
  updateFilterConfig: PropTypes.func,
};

export default connect(
  null,
  mapDispatchToProps
)(TeamEvaluationsStatusContainer);
