import {
  GOT_IT,
  GOT_IT_FAIL,
  GOT_IT_SUCCESS,
} from './actionTypes';

const initialState = {
  gotIt: {},
  gotItError: '',
  gotItLoading: false,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GOT_IT:
      return {
        ...state,
        gotItLoading: true,
      };

    case GOT_IT_FAIL:
      return {
        ...state,
        gotItError: payload.error,
        gotItLoading: false,
      };

    case GOT_IT_SUCCESS:
      return {
        ...state,
        gotIt: payload.response,
        gotItLoading: false,
      }

    default:
      return state;
  }
};