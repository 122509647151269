// @packages
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

// @app
import getMessageType from 'utils/getMessageType';
import ContentCardTemplate from 'components/templates/ContentCardTemplate';
import { selectSelfEvaluationList } from 'betterme-components/services/SelfEvaluationWidget/selectors';
import { getSelfEvaluationsList } from 'betterme-components/services/SelfEvaluationWidget/actions';
import {
  commonMessages,
  evaluationsMessages,
  sectionsMessages,
  feedbackMessages,
} from 'common/messages';

// @own
import SelfEvaluationList from 'components/organisms/SelfEvaluationList';

const SelfEvaluationListWidget = ({
  communityId,
  getSelfEvaluationsList,
  intl: { formatMessage },
  items,
  onToggleClick,
  open,
  user,
}) => {
  useEffect(() => {
    if (user?.id !== undefined) getSelfEvaluationsList({ evalueeId: user?.id });
  }, [user?.id]);

  const getMessage = getMessageType(communityId);
  const titleEvaluationsSelfEvaluations = getMessage('EvaluationsSelfEvaluations');
  const title = formatMessage(titleEvaluationsSelfEvaluations);
  const itemMessages = {
    itemTitle: formatMessage(sectionsMessages.SectionsTitle),
    itemSubmittedDate: formatMessage(evaluationsMessages.EvaluationsSubmittedDate),
    itemScore: formatMessage(commonMessages.Score),
    itemViewDetail: formatMessage(feedbackMessages.FeedbackViewDetail),
  };

  return (
    items?.length > 0 && (
      <ContentCardTemplate
        onToggleClick={onToggleClick}
        open={open}
        title={title}
      >
        <ContentCardTemplate.Body>
          <SelfEvaluationList itemMessages={itemMessages} items={items} />
        </ContentCardTemplate.Body>
      </ContentCardTemplate>
    )
  );
};

SelfEvaluationListWidget.propTypes = {
  communityId: PropTypes.number,
  dateTimeFrom: PropTypes.object,
  dateTimeTo: PropTypes.object,
  getLastSelfEvaluation: PropTypes.func,
  intl: PropTypes.object,
  items: PropTypes.array,
  onToggleClick: PropTypes.func,
  open: PropTypes.bool,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  items: selectSelfEvaluationList(state),
});

const mapDispatchToProps = {
  getSelfEvaluationsList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(SelfEvaluationListWidget));
