export const CLEAN_CHANGE_LEADER_USER_LIST = 'CLEAN_CHANGE_LEADER_USER_LIST';
export const GET_LEADERS = 'GET_LEADERS';
export const GET_LEADERS_FAIL = 'GET_LEADERS_FAIL';
export const GET_LEADERS_SUCCESS = 'GET_LEADERS_SUCCESS';
export const RESET_CHANGE_LEADER_REQUEST_STATE = 'RESET_CHANGE_LEADER_REQUEST_STATE';
export const SELECT_LEADER = 'SELECT_LEADER';
export const SET_CHANGE_LEADER_COMMENT = 'SET_CHANGE_LEADER_COMMENT';
export const UPDATE_USER_LEADER = 'UPDATE_USER_LEADER';
export const UPDATE_USER_LEADER_FAIL = 'UPDATE_USER_LEADER_FAIL';
export const UPDATE_USER_LEADER_SUCCESS = 'UPDATE_USER_LEADER_SUCCESS';
export const CLOSE_USER_CARD = 'CLOSE_USER_CARD';
export const RESET_SELECT_LEADER = 'RESET_SELECT_LEADER';
