// @packages
import React, { useEffect } from 'react';
import Button from 'smu-ui-components/ButtonV2';
import Icon from 'smu-ui-components/IconV2';
import LottieSpinner from 'smu-ui-components/LottieSpinner';
import PropTypes from 'prop-types';
import Segment from 'smu-ui-components/Segment';
import cn from 'classnames';
import { actionRequestInit, actionRequestDestroy } from 'smu-utils/reduxRequests/actions';
import { connect } from 'react-redux';
import { getUserLanguage } from 'smu-utils/language';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import { openModal } from 'smu-app-components/RootModal/actions';
import { push } from 'react-router-redux';
import { selectResult, selectRequesting } from 'smu-utils/reduxRequests/selectors';

// @app
import AddLeaderBox from 'components/atoms/AddLeaderBox';
import ContentSection from 'components/templates/ContentSection';
import EmptyState from 'components/molecules/EmptyState';
import FocalRemoveLeaderModal from 'components/containers/FocalRemoveLeaderModal';
import FullScreenHeader from 'common/FullscreenHeader';
import LeadershipAdminUserInfo from 'components/organisms/LeadershipAdminUserInfo';
import Title from 'components/atoms/Title';
import UserInfoCard from 'components/organisms/UserInfoCard';
import { FOCAL_REMOVE_LEADER_MODAL } from 'betterme-components/constants/modalTypes';
import { MAIN_LEADER, SECONDARY_LEADER } from 'components/common/constants';
import {
  getManageLeadersLocation,
  getManageLeadersSubstitutionLocation,
} from 'betterme-components/routes/urls';
import { selectCommunityId } from 'betterme-components/Authorization/selectors';
import { commonMessages, userMessages } from 'common/messages';
import { selectCommunityConfigurations } from 'betterme-components/Authorization/selectors';

// @own
import './styles.scss';
import leaderSuggestEmptyState from 'betterme-components/assets/empty-states/feedback-empty-state.svg';
import messages from './messages';
import {
  REQUEST_ID_GET_LEADERSHIP_ADMIN_USER,
  REQUEST_ID_GET_USER_ROLES_AND_PERMISSIONS,
} from 'components/pages/ManageLeaders/constants';
import {
  apiGetLeadershipAdminUser,
  apiGetUserRolesAndPermissions,
} from 'betterme-components/services/ManageLeaders/api';
import {
  ADD_TO,
  FOCALS,
  MAIN,
  SECONDARY,
} from 'components/pages/ManageLeadersSubstitution/constants';

function ManageLeadersAdmin({
  actionRequestDestroy,
  actionRequestInit,
  collaborator,
  communityConfig,
  communityId,
  goToManageLeaders,
  goToManageLeadersSubstitution,
  intl: { formatMessage },
  openModal,
  params: { userId },
  requesting,
  requestingRolesAndPermissions,
  rolesAndPermissions,
}) {
  const {
    account,
    area,
    identification,
    leaders,
    office,
    project,
  } = collaborator;
  const locale = getUserLanguage();
  const breadcrumbMessage = locale === 'es'
    ? `${formatMessage(userMessages.UserProfile)} de ${collaborator?.firstName}`
    : `${collaborator?.firstName}'s ${formatMessage(userMessages.UserProfile)}`;
  const careerLeader = leaders?.filter(leader => leader?.type === MAIN)?.[0];
  const isAccountFocal = rolesAndPermissions?.permissions?.includes('OS:SECONDARYLEADERS:ALL');
  const isCareerFocal = rolesAndPermissions?.permissions?.includes('OS:MAINLEADERS:ALL');
  const hasAccess = isCareerFocal || isAccountFocal;
  const otherLeaders = leaders?.filter(leader => leader?.type === SECONDARY);
  const prevSection = FOCALS;
  const emptyStateTitle = (
    <FormattedHTMLMessage
      id={messages.FocalsAdminEmptyStateTitle.id}
      defaultMessage={messages.FocalsAdminEmptyStateTitle.defaultMessage}
    />
  );
  const emptyStateDescription = (
    <FormattedHTMLMessage
      id={messages.FocalsAdminEmptyStateDescription.id}
      defaultMessage={messages.FocalsAdminEmptyStateDescription.defaultMessage}
    />
  );

  const communityCustomField = communityConfig?.customFields?.studioReference;
  const customField = collaborator?.customFields;
  const studioField = customField?.[communityCustomField];

  const items = [
    {
      description: project,
      title: formatMessage(userMessages.UserProject),
    },
    {
      description: account,
      title: formatMessage(userMessages.UserAccount),
    },
    {
      description: area,
      title: formatMessage(userMessages.UserArea),
    },
    {
      description: office?.name,
      title: formatMessage(userMessages.UserOffice),
    },
    {
      description: studioField,
      title: formatMessage(userMessages.UserStudio),
    }
  ];

  const userInfoCardMessages = {
    account: formatMessage(userMessages.UserAccount),
    area: formatMessage(userMessages.UserArea),
    office: formatMessage(userMessages.UserOffice),
    project: formatMessage(userMessages.UserProject),
    remove: formatMessage(messages.FocalsAdminRemove),
    replace: formatMessage(messages.FocalsAdminReplace),
  };

  function getUserRolesAndPermissions() {
    actionRequestInit({
      id: REQUEST_ID_GET_USER_ROLES_AND_PERMISSIONS,
      api: apiGetUserRolesAndPermissions,
      cache: true,
    });
  }

  function getLeadershipAdminUser() {
    actionRequestInit({
      id: REQUEST_ID_GET_LEADERSHIP_ADMIN_USER,
      api: apiGetLeadershipAdminUser,
      params: {
        id: userId,
      },
    });
  };

  function handleRemove(leaderType, removeThisUser) {
    openModal({
      modalType: FOCAL_REMOVE_LEADER_MODAL,
      modalProps: {
        communityId,
        goBack: goToManageLeaders,
        leaderType,
        onSuccessMessage: formatMessage(messages.FocalsAdminRemoveLeaderToastSuccessMessage),
        removeThisUser,
        user: collaborator,
      }
    });
  };

  function normalizeUser(user) {
    return {
      ...user,
      account: user?.properties?.account,
      area: user?.properties?.area,
      job: user?.properties?.job,
      office: user?.properties?.officeName,
      profileImageCode: user?.image,
      project: user?.properties?.project,
      seniority: user?.properties?.seniority,
    }
  };

  useEffect(() => {
    getUserRolesAndPermissions();
    getLeadershipAdminUser(collaborator);

    return () => {
      actionRequestDestroy(REQUEST_ID_GET_LEADERSHIP_ADMIN_USER);
    }
  }, []);

  if (requestingRolesAndPermissions) {
    return (
      <div className="manage-leaders-admin__loading">
        <LottieSpinner loadingType="betterme" />
      </div>
    );
  }

  if (!hasAccess) {
    return (
      <div className="manage-leaders-admin__empty-state">
        <EmptyState
          image={leaderSuggestEmptyState}
          title={emptyStateTitle}
          description={emptyStateDescription}
          direction="column"
        />
      </div>
    );
  };

  return (
    <div className="manage-leaders-admin">
      <Segment loading={requesting} loadingType="betterme">
        <FullScreenHeader
          breadCrumbsProps={{
            active: 1,
            onClick: goToManageLeaders,
            size: "big",
            values: [
              formatMessage(messages.FocalsAdminBreadcrumb),
              breadcrumbMessage,
            ],
          }}
          className="manage-leaders-admin__breadcrumb"
        />
        <LeadershipAdminUserInfo items={items} user={collaborator} />
        <ContentSection>
          <section className="manage-leaders-admin__main">
            <Title
              className={cn('manage-leaders-admin__main-heading--title', '!text-black')}
              size={14}
            >
              {formatMessage(commonMessages.CareerLeader)}
            </Title>
            <div className="manage-leaders-admin__main-add-leadrs">
              {careerLeader ? (
                <UserInfoCard
                  className="manage-leaders-admin__main-user-info-card"
                  messages={userInfoCardMessages}
                  onRemoveClick={() => handleRemove('main', careerLeader?.id)}
                  onReplaceClick={() =>
                    goToManageLeadersSubstitution(
                      collaborator?.identification,
                      MAIN_LEADER,
                      prevSection,
                      careerLeader?.id
                    )
                  }
                  showActionButtons={isCareerFocal}
                  user={normalizeUser(careerLeader)}
                />
              ) : (
                <AddLeaderBox
                  className="manage-leaders-admin__main-add-leaders--empty-state"
                  disabled={!isCareerFocal}
                  onClick={() => {
                    isCareerFocal &&
                      goToManageLeadersSubstitution(identification, MAIN_LEADER, ADD_TO);
                  }}
                  popupText={formatMessage(
                    messages.FocalsAdminSecondaryLeaderTooltip
                  )}
                  text={formatMessage(messages.FocalsAdminAddMainLeader)}
                  withPopup={!isCareerFocal}
                />
              )}
            </div>
          </section>
          <section className="manage-leaders-admin__secondary">
            <div className="manage-leaders-admin__secondary-heading">
              <Title
                className={cn('manage-leaders-admin__main-heading--title', '!text-black')}
                size={14}
              >
                {formatMessage(commonMessages.ExperienceLeaders)}
              </Title>
              {(isAccountFocal && otherLeaders?.length > 0) && (
                <Button
                  className="manage-leaders-admin__secondary-heading--button"
                  endIcon={
                    <Icon
                      className="manage-leaders-admin__secondary-heading--button-icon"
                      icon="add-circular"
                    />
                  }
                  variant="link"
                  onClick={() =>
                    goToManageLeadersSubstitution(
                      identification,
                      SECONDARY_LEADER,
                      ADD_TO,
                    )
                  }
                >
                  {formatMessage(messages.FocalsAdminAddSecondaryLeaders)}
                </Button>
              )}
            </div>
            {otherLeaders?.length > 0 ? (
              otherLeaders?.map((evaluator) => (
                <UserInfoCard
                  className="manage-leaders-admin__secondary-user-info-card"
                  messages={userInfoCardMessages}
                  onRemoveClick={() => {
                    handleRemove('secondary', evaluator?.id)
                  }}
                  onReplaceClick={() =>
                    goToManageLeadersSubstitution(
                      collaborator?.identification,
                      SECONDARY_LEADER,
                      prevSection,
                      evaluator?.id
                    )
                  }
                  showActionButtons={isAccountFocal}
                  user={normalizeUser(evaluator)}
                />
              ))
            ) : (
              <AddLeaderBox
                className="manage-leaders-admin__secondary-add-leaders--empty-state"
                disabled={!isAccountFocal}
                onClick={() => {
                  isAccountFocal &&
                    goToManageLeadersSubstitution(
                      identification,
                      SECONDARY_LEADER,
                      ADD_TO,
                    );
                }}
                popupText={formatMessage(messages.FocalsAdminMainLeaderTooltip)}
                text={formatMessage(messages.FocalsAdminAddSecondaryLeaders)}
                withPopup={!isAccountFocal}
              />
            )}
          </section>
        </ContentSection>
        <FocalRemoveLeaderModal />
      </Segment>
    </div>
  );
};

const goToManageLeaders = () => push(getManageLeadersLocation());
const goToManageLeadersSubstitution = (identification, leaderType, prevSection, oldLeader) =>
  push(getManageLeadersSubstitutionLocation(identification, leaderType, prevSection, oldLeader));

const mapStateToProps = state => ({
  collaborator: selectResult(state, REQUEST_ID_GET_LEADERSHIP_ADMIN_USER),
  communityConfig: selectCommunityConfigurations(state),
  communityId: selectCommunityId(state),
  requesting: selectRequesting(state, REQUEST_ID_GET_LEADERSHIP_ADMIN_USER),
  requestingRolesAndPermissions: selectRequesting(state, REQUEST_ID_GET_USER_ROLES_AND_PERMISSIONS),
  rolesAndPermissions: selectResult(state, REQUEST_ID_GET_USER_ROLES_AND_PERMISSIONS),
});

const mapDispatchToProps = {
  actionRequestDestroy,
  actionRequestInit,
  goToManageLeaders,
  goToManageLeadersSubstitution,
  openModal,
};

ManageLeadersAdmin.propTypes = {
  actionRequestDestroy: PropTypes.func.isRequired,
  collaborator: PropTypes.object,
  goToManageLeaders: PropTypes.func.isRequired,
  intl: PropTypes.object,
  openModal: PropTypes.func,
  params: PropTypes.object,
};

ManageLeadersAdmin.defaultProps = {
  collaborator: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ManageLeadersAdmin));
