// @packages
import { get } from 'lodash';

const reducerDomain = 'getChangeLeaderDismissReason';
  
export const selectGetChangeLeaderDismissReason = (state) =>
  get(state, [reducerDomain, 'getChangeLeaderDismissReason']);

export const selectLoadingGetChangeLeaderDismissReason = (state) =>
  get(state, [reducerDomain, 'loadingChangeLeaderDismissReason']);
  
export const selectPutChangeLeader = (state) =>
  get(state, [reducerDomain, 'putChangeLeader']);

export const selectLoadingPutChangeLeader = (state) =>
  get(state, [reducerDomain, 'loadingChangeLeader']);

export const selectSelectedDismissReason = (state) =>
  get(state, [reducerDomain, 'selectedDismissReason']);
