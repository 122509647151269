// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Link from 'smu-ui-components/Link';

// @app
import CardSimple from 'common/CardSimple';
import { actionsMessages, feedbackMessages } from 'common/messages';

// @own
import './styles.scss';
import TeamFeedbackReceivedList from './TeamFeedbackReceivedList';
import messages from '../messages';

const TeamFeedbackReceived = ({
  collaboratorsList,
  intl: { formatMessage },
  link,
  loading,
  newStyle,
}) => (
  <CardSimple
    className="team-feedback-received"
    loading={loading}
    lowercaseSubtitle
    newStyle={newStyle}
    noMargin
    subtitle={formatMessage(messages.TeamDashboardTeamFeedbackReceivedSubtitle)}
    title={formatMessage(feedbackMessages.FeedbackFeedbackReceived)}
  >
    <div className="team-feedback-received__content">
      {collaboratorsList.length > 0 && (
        <TeamFeedbackReceivedList
          collaboratorsList={collaboratorsList}
          newStyle={newStyle}
        />
      )}
    </div>
    {link && (
      <div className="team-feedback-received__actions">
        <Link
          color="black"
          onClick={link.onClick}
          size="small"
          to={link.to}
          type="button"
          legacy={newStyle}
        >
          {formatMessage(actionsMessages.ActionsViewMore)}
        </Link>
      </div>
    )}
  </CardSimple>
);

TeamFeedbackReceived.propTypes = {
  collaboratorsList: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
  link: PropTypes.shape({
    to: PropTypes.object,
    onClick: PropTypes.func,
  }),
  loading: PropTypes.bool,
  newStyle: PropTypes.bool,
};

export default injectIntl(TeamFeedbackReceived);
