// @packages
import { takeLatest, call, put } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';

// @own
import { GET_EVALUATIONS_TEAM } from './actionTypes';
import { evaluationsTeam } from './api';
import { evaluationsTeamFail, evaluationsTeamSuccess } from './actions';

function* getEvaluationsTeamWorker({ payload: { identification } }) {
  try {
    const response = yield call(evaluationsTeam, identification);
    yield put(evaluationsTeamSuccess(response));
  } catch (error) {
    yield put(evaluationsTeamFail(error));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }))
  }
};

export default function* getEvaluationsTeamWatcher() {
  yield takeLatest(GET_EVALUATIONS_TEAM, getEvaluationsTeamWorker);
};