// @packages
import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

// @own
import './styles.scss';

const RatingCountItem = ({
  className,
  color,
  count,
  name,
}) => (
  <div className={cn('rating-count-item', className)}>
    <div
      className="rating-count-item__color"
      style={{ backgroundColor: color }}
    />
    <div className="rating-count-item__count">
      {count}
    </div>
    <div className="rating-count-item__name">{name}</div>
  </div>
);

RatingCountItem.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

export default RatingCountItem;
