// @packages
import React, { useRef, useEffect } from 'react';
import ImageGallery from 'react-image-gallery';
import PropTypes from 'prop-types';
import includes from 'lodash/includes';
import uniq from 'lodash/uniq';
import { injectIntl } from 'react-intl';

// @app
import ActionButtons from 'components/molecules/ActionButtons';
import Discovery from 'smu-app-components/FeatureDiscoveryV2/discovery';
import Title from 'components/atoms/Title';

const SuggestionDiscovery = ({
  actionButtonTexts: {
    slideOneText,
  },
  children,
  galleryItems,
  handleOpen,
  open,
  title,
  type,
}) => {
  const discoveryRef = useRef(null);

  const handleDiscoveryClick = (event) => {
    event.stopPropagation();
    const oldDiscoveries = JSON.parse(localStorage.getItem('discoveries')) || [];
    const newDiscoveries = JSON.stringify(uniq([...oldDiscoveries, type]));
    handleOpen(false);
    localStorage.setItem('discoveries', newDiscoveries);
  };

  const handleClickOutside = (event) => {
    const isClickedOutside =
      discoveryRef.current && !discoveryRef.current.contains(event.target);

    if (isClickedOutside) {
      handleDiscoveryClick(event);
    }
  };

  const discoveryActionButtons = () => {
    const actionButtons = [
      {
        className: 'discovery-btn',
        label: slideOneText,
        onClick: (event) => {
          handleDiscoveryClick(event);
        },
        size: 'small',
      },
    ];
    return actionButtons;
  };

  const setInitialOpenState = () => {
    const discoveryList = JSON.parse(localStorage.getItem('discoveries'));
    const wasOpen = includes(discoveryList, type);
    handleOpen(!wasOpen);
  };

  useEffect(() => {
    setInitialOpenState();
    document.addEventListener('click', handleClickOutside, true);
    return document.removeEventListener('click', handleClickOutside);
  }, []);

  return (
    <Discovery
      color="btm"
      open={open}
      popupClassName="categorized-leaders-list__discovery"
      target={children}
      text={
        <div ref={discoveryRef}>
          {title && (
            <Title
              className="categorized-leaders-list__discovery-title"
              white
            >
              {title}
            </Title>
          )}
          <ImageGallery
            additionalClass="categorized-leaders-list__discovery-carousel"
            infinite={false}
            items={galleryItems}
            renderItem={(item) => (
              <>
                {item?.title && (
                  <div className="categorized-leaders-list__discovery-slide-title">
                    <p>{item?.title}</p>
                  </div>
                )}
                <div className="suggestion-discovery-text">
                  <p>{item?.text}</p>
                  <div className="suggestion-discovery-buttons">
                    <ActionButtons
                      buttons={discoveryActionButtons()}
                    />
                  </div>
                </div>
              </>
            )}
            showFullscreenButton={false}
            showPlayButton={false}
            showThumbnails={false}
          />
        </div>
      }
    />
  );
};

SuggestionDiscovery.propTypes = {
  children: PropTypes.node,
  handleOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
}

export default injectIntl(SuggestionDiscovery);
