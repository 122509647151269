// @app
import messages from 'betterme-components/SendRequestFeedback/messages';
import {
  REQUEST_ON_BEHALF,
  SEND,
  REQUEST,
} from 'betterme-components/SendRequestFeedback/constants';

export const TYPE_MESSAGES = {
  [SEND]: {
    colleaguesTitle: messages.SendRequestFeedbackSendSelectColleaguesTitle,
  },
  [REQUEST]: {
    colleaguesTitle: messages.SendRequestFeedbackRequestSelectColleaguesTitle,
  },
  [REQUEST_ON_BEHALF]: {
    colleaguesTitle:
      messages.SendRequestFeedbackRequestBehalfSelectColleaguesTitle,
    collaboratorsTitle:
      messages.SendRequestFeedbackRequestBehalfSelectColaboratorsTitle,
  },
};
