export const ADD_TO_MY_TEAM_REQUEST_AS_MAIN = 'ADD_TO_MY_TEAM_REQUEST_AS_MAIN';
export const ADD_TO_MY_TEAM_REQUEST_AS_SECONDARY = 'ADD_TO_MY_TEAM_REQUEST_AS_SECONDARY';
export const APPROVED = 'APPROVED';
export const CANCELED = 'CANCELED';
export const CHANGE_LEADER_REQUEST_AS_MAIN = 'CHANGE_LEADER_REQUEST_AS_MAIN';
export const CHANGE_LEADER_REQUEST_AS_SECONDARY = 'CHANGE_LEADER_REQUEST_AS_SECONDARY';
export const EMPTY_STATE_LEADERS_IMAGE = 'ekuipdnpfmuxuecuweoy_png';
export const LEADER_SUBSTITUTION = 'Leader_Substitution';
export const NEW_LEADER = 'New_Leader';
export const ONE = 1;
export const RECEIVED = 'RECEIVED';
export const REJECTED = 'REJECTED';
export const SENT = 'SENT';
export const ZERO = 0;
