// @packages
import React from 'react';
import Onboarding from 'smu-app-components/Onboarding';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// @app
import {
  ENABLED_BETHERE_FEED,
  ENABLED_COMMUNICATIONS,
  ENABLED_DISCOVERY_PEOPLE,
} from 'betterme-components/services/CommunityConfigs/constants';
import { getCommunityConfigValueBool } from 'betterme-components/services/CommunityConfigs/selectors';
import {
  selectAllowsEvaluations,
  selectAllowsGoals,
  selectCommunityName,
} from 'betterme-components/Authorization/selectors';

function OnboardingContainer({
  bethereEnabled,
  communityName,
  discoveryPeopleEnabled,
  enabledEvaluations,
  enabledGoals,
  showOnlyPerformance,
  ...rest
}) {
  return (
    <Onboarding
      bethereEnabled={bethereEnabled}
      communityName={communityName}
      discoveryPeopleEnabled={discoveryPeopleEnabled}
      enabledEvaluations={enabledEvaluations}
      enabledGoals={enabledGoals}
      hasBetterme
      showOnlyPerformance={showOnlyPerformance}
      {...rest}
    />
  );
}

OnboardingContainer.propTypes = {
  bethereEnabled: PropTypes.bool,
  communityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  communityName: PropTypes.string,
  discoveryPeopleEnabled: PropTypes.bool,
  enabledEvaluations: PropTypes.bool,
  enabledGoals: PropTypes.bool,
  showOnlyPerformance: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const bethereEnabled = getCommunityConfigValueBool(
    state,
    ENABLED_BETHERE_FEED
  );
  const discoveryPeopleEnabled = getCommunityConfigValueBool(
    state,
    ENABLED_DISCOVERY_PEOPLE
  );
  const communicationsEnabled = getCommunityConfigValueBool(
    state,
    ENABLED_COMMUNICATIONS
  );

  return {
    bethereEnabled,
    communicationsEnabled,
    communityName: selectCommunityName(state),
    discoveryPeopleEnabled,
    enabledEvaluations: selectAllowsEvaluations(state),
    enabledGoals: selectAllowsGoals(state),
  };
};

export default connect(mapStateToProps)(OnboardingContainer);
