// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Dropdown from 'smu-ui-components/Dropdown';

// @app
import Description from 'components/molecules/Description';
import Title from 'components/atoms/Title';

// @own
import './styles.scss';

const ScoreSelection = ({
  className,
  description,
  disabled,
  media,
  onChange,
  options,
  placeholder,
  title,
  value,
}) => (
  <div className={cn('score-selection', className)}>
    <Title
      black
      className="score-selection__title"
      level={3}
      size={16}
    >
      {title}
    </Title>
    <Description className="score-selection__description" media={media}>
      {description}
    </Description>
    <Dropdown
      className="score-selection__dropdown"
      disabled={disabled}
      onChange={onChange}
      options={options}
      padding
      placeholder={placeholder}
      value={value}
    />
  </div>
);

ScoreSelection.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  media: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.string,
}

ScoreSelection.defaultProps = {
  options: [],
};

export default ScoreSelection;
