// @packages
import { get } from 'lodash';
import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

// @app
import { feedbackMessages } from 'common/messages';
import CardSimple from 'common/CardSimple';
import CompetencyInfoBox from 'common/CompetencyInfoBox';

// @own
import './styles.scss';
import messages from '../messages';
import {
  trackMemberBoxOpportunitySendFeedbackEmpty,
  trackMemberBoxStrengthsSendFeedbackEmpty,
} from '../analytics';

const TeamCompetenciesHighlight = ({
  intl: { formatMessage },
  loadingOpportunities,
  loadingStrenghts,
  opportunities,
  opportunitiesRating,
  sendFeedbackLocation,
  strengths,
  strengthsRating,
}) => {
  const emptyDescription = formatMessage(messages.TeamDashboardBoxEmptyDescription);
  const emptyInfo = formatMessage(messages.TeamDashboardBoxEmptyInfo);
  const emptyLink = (boxType) => ({
    label: formatMessage(feedbackMessages.FeedbackSendFeedback),
    to: sendFeedbackLocation,
    onClick: boxType === 'strengths'
      ? trackMemberBoxStrengthsSendFeedbackEmpty
      : trackMemberBoxOpportunitySendFeedbackEmpty,
  });

  const { percentage } = get(opportunities, '0', {});
  const worstRating = opportunitiesRating.name;
  const opportunitiesDescription = formatMessage(
    feedbackMessages.FeedbackOpportunityDescription,
    { percentage, rating: worstRating },
  );

  return (
    <CardSimple
      noMargin
      noPaddingTop
      title={formatMessage(messages.TeamDashboardCompetenciesHighlightTitle)}
      withoutCard
    >
      <div className="team-competencies-highlight">
        <CompetencyInfoBox
          className="team-competencies-highlight__strengths"
          color={strengthsRating.color}
          competencies={strengths}
          empty={{
            description: emptyDescription,
            info: emptyInfo,
            link: emptyLink('strengths'),
          }}
          inverted
          loading={loadingStrenghts}
          title={formatMessage(feedbackMessages.FeedbackTopStrengths)}
        />
        <CompetencyInfoBox
          className="team-competencies-highlight__opportunities"
          color={opportunitiesRating.color}
          competencies={opportunities}
          description={opportunitiesDescription}
          empty={{
            description: emptyDescription,
            info: emptyInfo,
            link: emptyLink('opportunities'),
          }}
          inverted
          loading={loadingOpportunities}
          title={formatMessage(feedbackMessages.FeedbackOpportunity)}
        />
      </div>
    </CardSimple>
  );
};

TeamCompetenciesHighlight.defaultProps = {
  opportunities: [],
  opportunitiesRating: {},
  strengths: [],
  strengthsRating: {},
};

const ratingPropType = PropTypes.shape({
  color: PropTypes.string,
  name: PropTypes.string,
});

TeamCompetenciesHighlight.propTypes = {
  intl: PropTypes.object.isRequired,
  loadingOpportunities: PropTypes.bool,
  loadingStrenghts: PropTypes.bool,
  opportunities: PropTypes.array,
  opportunitiesRating: ratingPropType,
  sendFeedbackLocation: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    state: PropTypes.shape({
      user: PropTypes.object.isRequired,
    }).isRequired,
  }).isRequired,
  strengths: PropTypes.array,
  strengthsRating: ratingPropType,
};

export default injectIntl(TeamCompetenciesHighlight);
