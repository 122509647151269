import { get, orderBy } from 'lodash';

export const selectFeedbackForLeadersSummaryResult = ({ feedbackForLeaders }) =>
  get(feedbackForLeaders, 'summary', {});

export const selectFeedbackForLeadersEvaluations = ({ feedbackForLeaders }) => {
  const evaluations = get(feedbackForLeaders, 'summary.evaluations', null);
  const evaluationsOrdered = orderBy(evaluations, ['order'], ['asc']);
  return evaluationsOrdered.length ? evaluationsOrdered : null;
}
