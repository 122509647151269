// @own
import {
  GET_POTENTIAL_ASSESSMENT_RESULTS,
  GET_POTENTIAL_ASSESSMENT_RESULTS_FAIL,
  GET_POTENTIAL_ASSESSMENT_RESULTS_SUCCESS,
  GET_POTENTIAL_TYPE,
  GET_POTENTIAL_TYPE_FAIL,
  GET_POTENTIAL_TYPE_SUCCESS,
  RESET_POTENTIAL_ASSESSMENT_RESULTS,
} from './actionTypes';

const initialState = {
  potentialAssessmentResults: {},
  potentialAssessmentResultsError: '',
  potentialAssessmentResultsLoading: false,
  potentialType: {},
  potentialTypeError: '',
  potentialTypeLoading: false,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {

    case GET_POTENTIAL_TYPE:
      return {
        ...state,
        potentialTypeLoading: true,
      };

    case GET_POTENTIAL_TYPE_SUCCESS:
      return {
        ...state,
        potentialType: payload.type,
        potentialTypeLoading: false,
      };

    case GET_POTENTIAL_TYPE_FAIL:
      return {
        ...state,
        potentialTypeError: payload.error,
        potentialTypeLoading: false,
      };

    case GET_POTENTIAL_ASSESSMENT_RESULTS:
      return {
        ...state,
        potentialAssessmentResultsLoading: true,
      };

    case GET_POTENTIAL_ASSESSMENT_RESULTS_FAIL:
      return {
        ...state,
        potentialAssessmentResultsError: payload.error,
        potentialAssessmentResultsLoading: false,
      }

    case GET_POTENTIAL_ASSESSMENT_RESULTS_SUCCESS:
      return {
        ...state,
        potentialAssessmentResultsLoading: false,
        potentialAssessmentResults: payload,
      };

    case RESET_POTENTIAL_ASSESSMENT_RESULTS:
      return initialState;

    default:
      return state;
  }
}
