// @packages
import { get, groupBy, transform, orderBy } from 'lodash';
import { createSelector } from 'reselect';

const reducerDomain = 'sendfeedbackForLeaders';

export const selectLoadingQuestionsFeedbackForLeaders = (state) =>
  get(state, [reducerDomain, 'loadingQuestionsFeedbackForLeaders']);

export const selectLoadingCreateFeedbackForLeaders = (state) =>
  get(state, [reducerDomain, 'loadingCreateFeedbackForLeaders']);

export const selectLoadingRatingsFeedbackForLeaders = (state) =>
  get(state, [reducerDomain, 'loadingRatingsFeedbackForLeaders']);

export const selectRatingsFeedbackForLeaders = (state) =>
  get(state, [reducerDomain, 'ratingsFeedbackForLeaders'], []);

export const selectQuestionsFeedbackForLeaders = (state) =>
  get(state, [reducerDomain, 'questionsFeedbackForLeaders'], []);

export const selectAnswersCompetencies = (state) =>
  get(state, [reducerDomain, 'answersCompetencies'], {});

export const selectAnswersInsights = (state) =>
  get(state, [reducerDomain, 'answersInsights'], {});

export const selectRatingsValuesFeedbackForLeaders = createSelector(
  selectRatingsFeedbackForLeaders,
  (ratings) => (
    orderBy(ratings.filter(rating => rating.value !== 0))
  )
);

export const selectRatingsNotApplyFeedbackForLeaders = createSelector(
  selectRatingsFeedbackForLeaders,
  (ratings) => (
    orderBy(ratings.filter(rating => rating.value === 0))
  )
);

export const selectCompetenciesQuestions = createSelector(
  selectQuestionsFeedbackForLeaders,
  (questions) => {
    const questionsFiltered = questions
      .filter(rateQuestion => rateQuestion.category === 'Competence')
      .map((rateQuestion, index) => ({
        ...rateQuestion,
        number: index + 1,
      }));
    const totalQuestions = questionsFiltered.length;
    const competencies = transform(
      groupBy(questionsFiltered, 'topic.name'),
      (acc, value, key) => {
        (acc).push({
          name: key,
          questions: value,
        });
      }, []);

    return ({
      competencies,
      totalQuestions,
    });
  },
);

export const selectInsightQuestions = createSelector(
  selectQuestionsFeedbackForLeaders,
  (questions) => {
    const questionsFiltered = questions
      .filter(rateQuestion => rateQuestion.category === 'Insight');
    const questionsOrdered = orderBy(questionsFiltered, 'questionType');

    return questionsOrdered;
  },
);
