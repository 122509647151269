import {
  AUTHENTICATE_USER_FAIL,
  AUTHENTICATE_USER_SUCCESS,
  AUTHENTICATE_USER,
  GET_DRAFT_SELF_EVALUATION_FAIL,
  GET_DRAFT_SELF_EVALUATION_SUCCESS,
  GET_DRAFT_SELF_EVALUATION,
  GET_FEEDBACK_FOR_LEADERS_RATINGS_FAIL,
  GET_FEEDBACK_FOR_LEADERS_RATINGS_SUCCESS,
  GET_FEEDBACK_FOR_LEADERS_RATINGS,
  GET_USER_INFO_FAIL,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO,
  GET_USER_TEAM_INFO_FAIL,
  GET_USER_TEAM_INFO_SUCCESS,
  GET_USER_TEAM_INFO,
  LOGOUT,
  REFRESH_USER_INFO_SUCCESS,
  REFRESH_USER_INFO,
  RESET_DRAFT_SELF_EVALUATION,
  SET_COMMUNITY_INFO_FAIL,
  SET_COMMUNITY_INFO,
  UPDATE_PROFILE_IMAGE_FAIL,
  UPDATE_PROFILE_IMAGE_SUCCESS,
  UPDATE_PROFILE_IMAGE,
  UPDATE_USER_INFO_FAIL,
  UPDATE_USER_INFO_SUCCESS,
  UPDATE_USER_INFO,
} from './actionTypes';


export function authenticateUser(justLoggedIn, resumeNavigation) {
  return {
    type: AUTHENTICATE_USER,
    meta: { resumeNavigation },
    payload: { justLoggedIn },
  };
}

export function authenticateUserFail(error) {
  return {
    type: AUTHENTICATE_USER_FAIL,
    payload: { error },
  };
}

export function authenticateUserSuccess() {
  return {
    type: AUTHENTICATE_USER_SUCCESS,
  };
}

export function getDraftSelfEvaluation(payload) {
  return {
    type: GET_DRAFT_SELF_EVALUATION,
    payload,
  };
}

export function getDraftSelfEvaluationSuccess(response) {
  return {
    type: GET_DRAFT_SELF_EVALUATION_SUCCESS,
    payload: { response }
  };
}

export function getDraftSelfEvaluationFail(error) {
  return {
    type: GET_DRAFT_SELF_EVALUATION_FAIL,
    payload: { error },
  };
}

export function resetDraftSelfEvaluation(payload) {
  return {
    type: RESET_DRAFT_SELF_EVALUATION,
    payload,
  };
}

export function getUserInfo(identification) {
  return {
    type: GET_USER_INFO,
    payload: { identification },
  };
}

export function getUserInfoFail(error) {
  return {
    type: GET_USER_INFO_FAIL,
    payload: { error },
  };
}

export function getUserInfoSuccess(user, communityConfigurations, userDraftEvaluation) {
  return {
    type: GET_USER_INFO_SUCCESS,
    payload: {
      communityConfigurations,
      user,
      userDraftEvaluation,
    },
  };
}

export function getUserTeamInfo(identification) {
  return {
    type: GET_USER_TEAM_INFO,
    payload: { identification },
  };
}

export function getUserTeamInfoFail(error) {
  return {
    type: GET_USER_TEAM_INFO_FAIL,
    payload: { error },
  };
}

export function getUserTeamInfoSuccess(response) {
  return {
    type: GET_USER_TEAM_INFO_SUCCESS,
    payload: { response },
  };
}

export function logout(message = '', error = false) {
  return {
    type: LOGOUT,
    payload: { error, message },
  };
}

export function refreshUserInfo(identification, action) {
  return {
    action,
    payload: { identification },
    type: REFRESH_USER_INFO,
  };
}

export function refreshUserInfoSuccess() {
  return {
    type: REFRESH_USER_INFO_SUCCESS,
  };
}

export function setCommunityInfo(scores, ratings, topics) {
  return {
    type: SET_COMMUNITY_INFO,
    payload: {
      scores,
      ratings,
      topics,
    },
  };
}

export function setCommunityInfoFail(error) {
  return {
    type: SET_COMMUNITY_INFO_FAIL,
    payload: { error },
  };
}

export function updateProfileImage(image) {
  return {
    type: UPDATE_PROFILE_IMAGE,
    payload: { image },
  };
}

export function updateProfileImageSuccess() {
  return {
    type: UPDATE_PROFILE_IMAGE_SUCCESS,
  };
}

export function updateProfileImageFail(message) {
  return {
    type: UPDATE_PROFILE_IMAGE_FAIL,
    payload: { message },
  };
}

export function updateUserInfo(userId, imageId, profileImageCode) {
  return {
    type: UPDATE_USER_INFO,
    payload: { userId, imageId, profileImageCode },
  };
}

export function updateUserInfoSuccess(response) {
  return {
    type: UPDATE_USER_INFO_SUCCESS,
    payload: { response }
  };
}

export function updateUserInfoFail(message) {
  return {
    type: UPDATE_USER_INFO_FAIL,
    payload: { message },
  };
}

export function getFeedbackForLeadersRaitings() {
  return {
    type: GET_FEEDBACK_FOR_LEADERS_RATINGS,
  };
}

export function getFeedbackForLeadersRaitingsFail(error) {
  return {
    type: GET_FEEDBACK_FOR_LEADERS_RATINGS_FAIL,
    payload: { error },
  };
}

export function getFeedbackForLeadersRaitingsSuccess(response) {
  return {
    type: GET_FEEDBACK_FOR_LEADERS_RATINGS_SUCCESS,
    payload: { response },
  };
}
