// @packages
import React from 'react';
import PropTypes from 'prop-types';
import RootModal from 'smu-app-components/RootModal';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { push } from 'react-router-redux';

// @app
import GenericModal from 'components/templates/GenericModalTemplate';
import InfoCard from 'components/molecules/InfoCard';
import {
  ACTION_TYPE_REQUIRED,
  SIZE_FIFTY,
  SIZE_THIRTY_SIX,
} from 'components/common/constants';
import { SEND_EVALUATION_REQUIRED_MODAL } from 'betterme-components/constants/modalTypes';
import { actionsMessages } from 'common/messages';
import { centerModalClass } from 'betterme-components/ConfirmationModal';
import { getTeamEvaluationsLocation } from 'betterme-components/routes/urls';
import { selectActionRequiredLoading } from 'betterme-components/services/ReasonsActions/selectors';
import { selectConfigurations } from 'betterme-components/Authorization/selectors';
import { removeLocalStoredItem } from 'utils/localStorage';

// @own
import messages from './messages';

const EvaluationRequiredModal = ({
  actionsLoading,
  closeModal,
  goToEvaluation,
  intl: { formatMessage },
  isContinuousEvaluation,
  user,
}) => {

  function handleCancel() {
    closeModal();
    removeLocalStoredItem(ACTION_TYPE_REQUIRED);
  };

  function handleAccept() {
    closeModal();
    goToEvaluation(user);
  };

  const collaborator = `${user?.firstName} ${user?.lastName}`;

  const actionButtons = [
    {
      label: formatMessage(actionsMessages.ActionsCancel),
      onClick: handleCancel,
      variant: 'outline',
    },
    {
      label: formatMessage(messages.CreateEvaluation),
      onClick: handleAccept,
    },
  ];
  
  const sendEvaluation = isContinuousEvaluation
    ? formatMessage(messages.SendContinuousEvaluation, { collaborator })
    : formatMessage(messages.SendEvaluation, { collaborator });

  return (
    <GenericModal
      actionButtons={actionButtons}
      header={
        <InfoCard
          icon="info"
          iconSize={SIZE_FIFTY}
          message={sendEvaluation}
          title={formatMessage(messages.EvaluationRequired)}
          titleSize={SIZE_THIRTY_SIX}
        />
      }
      loading={actionsLoading}
      onClose={handleCancel}
      success
    />
  );
};

EvaluationRequiredModal.propTypes = {
  actionsLoading: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  goToEvaluation: PropTypes.func.isRequired,
  intl: PropTypes.func.isRequired,
  isContinuousEvaluation: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
};

const modalStyles = {
  width: '610px',
};

const mapStateToProps = (state) => ({
  actionsLoading: selectActionRequiredLoading(state),
  isContinuousEvaluation: selectConfigurations('allowsContinuousEvaluation')(state),
});

const mapDispatchToProps = {
  goToEvaluation: (user) => push(getTeamEvaluationsLocation({
    openCreateEvaluation: user?.id,
    openedByUrl: true,
  }).pathname),
};

export default RootModal(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(EvaluationRequiredModal)),
  SEND_EVALUATION_REQUIRED_MODAL,
  modalStyles,
  centerModalClass,
);
