import {
  APPROVAL_GOAL,
  APPROVAL_GOAL_FAIL,
  APPROVAL_GOAL_SUCCESS,
  CLOSE_GOAL,
  CLOSE_GOAL_FAIL,
  CLOSE_GOAL_SUCCESS,
  CREATE_GOAL,
  CREATE_GOAL_ASSESSMENT,
  CREATE_GOAL_ASSESSMENT_FAILED,
  CREATE_GOAL_ASSESSMENT_SUCCESS,
  CREATE_GOAL_FAILED,
  CREATE_GOAL_SUCCESS,
  DELETE_GOAL,
  DELETE_GOAL_FAIL,
  DELETE_GOAL_SUCCESS,
  DISAPPROVAL_GOAL,
  DISAPPROVAL_GOAL_FAIL,
  DISAPPROVAL_GOAL_SUCCESS,
  GET_ASSIGNED_GOALS,
  GET_ASSIGNED_GOALS_FAILED,
  GET_ASSIGNED_GOALS_SUCCESS,
  GET_ASSIGNED_GOAL_DETAIL,
  GET_ASSIGNED_GOAL_DETAIL_FAILED,
  GET_ASSIGNED_GOAL_DETAIL_SUCCESS,
  GET_ORGANIZATIONAL_OBJECTIVES,
  GET_ORGANIZATIONAL_OBJECTIVES_FAIL,
  GET_ORGANIZATIONAL_OBJECTIVES_SUCCESS,
  GET_PERIODS,
  GET_PERIODS_FAILED,
  GET_PERIODS_SUCCESS,
  GET_SUMMARY_GOALS,
  GET_SUMMARY_GOALS_FAILED,
  GET_SUMMARY_GOALS_SUCCESS,
  GET_TEAM_GOALS,
  GET_TEAM_GOALS_FAILED,
  GET_TEAM_GOALS_SUCCESS,
  GET_TEAM_GOAL_DETAIL,
  GET_TEAM_GOAL_DETAIL_FAILED,
  GET_TEAM_GOAL_DETAIL_SUCCESS,
  GET_USER_TOTAL_WEIGHT,
  GET_USER_TOTAL_WEIGHT_FAIL,
  GET_USER_TOTAL_WEIGHT_SUCCESS,
  GOAL_WEIGHT_VALIDATION_CONTINUE,
  GOAL_WEIGHT_VALIDATION_ERROR,
  REMOVE_USER_TOTAL_WEIGHT,
  RESET_ASSIGNED_GOAL,
  RESET_ASSIGNED_GOAL_DETAIL,
  RESET_SUMMARY_GOALS,
  RESET_TEAM_GOAL,
  RESET_TEAM_GOAL_DETAIL,
  SET_MY_GOAL_FILTERS,
  SET_TEAM_GOAL_FILTERS,
  TOGGLE_EDIT_PROGRESS,
  UPDATE_APPROVAL_GOAL,
  UPDATE_APPROVAL_GOAL_FAIL,
  UPDATE_APPROVAL_GOAL_SUCCESS,
  UPDATE_GOAL,
  UPDATE_GOAL_FAILED,
  UPDATE_GOAL_SUCCESS,
} from './actionTypes';

//TODO order action creators

export function approvalGoal(id, data, messages = {}) {
  return {
    type: APPROVAL_GOAL,
    payload: { id, data, messages },
  };
}

export function approvalGoalSuccess(response) {
  return {
    type: APPROVAL_GOAL_SUCCESS,
    payload: { response },
  };
}

export function approvalGoalFailed(error) {
  return {
    type: APPROVAL_GOAL_FAIL,
    payload: { error },
  };
}

export function updateApprovalGoal(id, fromUserId, messages = {}) {
  return {
    type: UPDATE_APPROVAL_GOAL,
    payload: { id, messages },
    fromUserId,
  };
}

export function updateApprovalGoalFail(error) {
  return {
    type: UPDATE_APPROVAL_GOAL_FAIL,
    payload: { error }
  };
}

export function updateApprovalGoalSuccess(response) {
  return {
    type: UPDATE_APPROVAL_GOAL_SUCCESS,
    payload: { response },
  };
}

export function closeGoal(id, score, comment, isListGoals) {
  return {
    type: CLOSE_GOAL,
    payload: { id, score, comment },
    isListGoals,
  };
}

export function closeGoalFail(error) {
  return {
    type: CLOSE_GOAL_FAIL,
    payload: { error },
  };
}

export function closeGoalSuccess(response) {
  return {
    type: CLOSE_GOAL_SUCCESS,
    payload: { response }
  };
}

export function deleteGoal(id, messages = {}, isListGoals) {
  return {
    type: DELETE_GOAL,
    payload: { id, messages },
    isListGoals,
  };
}

export function deleteGoalFail(error) {
  return {
    type: DELETE_GOAL_FAIL,
    payload: { error }
  };
}

export function deleteGoalSuccess(response) {
  return {
    type: DELETE_GOAL_SUCCESS,
    payload: { response },
  };
}

export function disapprovalGoal(id, messages = {}, isListGoals) {
  return {
    type: DISAPPROVAL_GOAL,
    payload: { id, messages },
    isListGoals,
  };
}

export function disapprovalGoalFail(error) {
  return {
    type: DISAPPROVAL_GOAL_FAIL,
    payload: { error }
  };
}

export function disapprovalGoalSuccess(response) {
  return {
    type: DISAPPROVAL_GOAL_SUCCESS,
    payload: { response },
  };
}

export function getSummaryGoals(userId, from, to) {
  return {
    type: GET_SUMMARY_GOALS,
    payload: { userId, dateFrom: from, dateTo: to },
  };
}

export function getSummaryGoalsSuccess(response) {
  return {
    type: GET_SUMMARY_GOALS_SUCCESS,
    payload: { response },
  };
}

export function getSummaryGoalsFailed(error) {
  return {
    type: GET_SUMMARY_GOALS_FAILED,
    payload: { error },
  };
}

export function getAssignedGoals(userId, filters) {
  return {
    type: GET_ASSIGNED_GOALS,
    payload: { userId, filters },
  };
}

export function getAssignedGoalsSuccess(response) {
  return {
    type: GET_ASSIGNED_GOALS_SUCCESS,
    payload: { response },
  };
}

export function getAssignedGoalsFailed(error) {
  return {
    type: GET_ASSIGNED_GOALS_FAILED,
    payload: { error },
  };
}

export function getAssignedGoalDetail(id, userId) {
  return {
    type: GET_ASSIGNED_GOAL_DETAIL,
    payload: { id, userId },
  };
}

export function getAssignedGoalDetailSuccess(response) {
  return {
    type: GET_ASSIGNED_GOAL_DETAIL_SUCCESS,
    payload: { response },
  };
}

export function getAssignedGoalDetailFailed(error) {
  return {
    type: GET_ASSIGNED_GOAL_DETAIL_FAILED,
    payload: { error },
  };
}

export function getOrganizationalObjectives() {
  return {
    type: GET_ORGANIZATIONAL_OBJECTIVES,
  };
}

export function getOrganizationalObjectivesFail(error) {
  return {
    type: GET_ORGANIZATIONAL_OBJECTIVES_FAIL,
    payload: { error },
  };
}

export function getOrganizationalObjectivesSuccess(response) {
  return {
    type: GET_ORGANIZATIONAL_OBJECTIVES_SUCCESS,
    payload: { response },
  };
}

export function getTeamGoals(leaderId, filters) {
  return {
    type: GET_TEAM_GOALS,
    payload: { leaderId, filters },
  };
}

export function getTeamGoalsSuccess(response) {
  return {
    type: GET_TEAM_GOALS_SUCCESS,
    payload: { response },
  };
}

export function getTeamGoalsFailed(error) {
  return {
    type: GET_TEAM_GOALS_FAILED,
    payload: { error },
  };
}

export function getTeamGoalDetail(id, userId) {
  return {
    type: GET_TEAM_GOAL_DETAIL,
    payload: { id, userId },
  };
}

export function getTeamGoalDetailSuccess(response) {
  return {
    type: GET_TEAM_GOAL_DETAIL_SUCCESS,
    payload: { response },
  };
}

export function getUserTotalWeight(user, periodId, messages) {
  return {
    type: GET_USER_TOTAL_WEIGHT,
    payload: { user, periodId, messages },
  };
}

export function getUserTotalWeightFail(error) {
  return {
    type: GET_USER_TOTAL_WEIGHT_FAIL,
    payload: { error },
  };
}

export function getUserTotalWeightSuccess(user, totalWeight) {
  return {
    type: GET_USER_TOTAL_WEIGHT_SUCCESS,
    payload: { user, totalWeight },
  };
}

export function goalWeightValidationContinue() {
  return {
    type: GOAL_WEIGHT_VALIDATION_CONTINUE,
  };
}

export function goalWeightValidationError(error) {
  return {
    type: GOAL_WEIGHT_VALIDATION_ERROR,
    payload: { error },
  };
}

export function removeUserTotalWeight(userId) {
  return {
    type: REMOVE_USER_TOTAL_WEIGHT,
    payload: { userId },
  };
}

export function getTeamGoalDetailFailed(error) {
  return {
    type: GET_TEAM_GOAL_DETAIL_FAILED,
    payload: { error },
  };
}

export function createGoalAssessment(id, data, fromUserId, isMyGoals) {
  return {
    type: CREATE_GOAL_ASSESSMENT,
    payload: { id, data, isMyGoals },
    fromUserId,
  };
}

export function createGoalAssessmentSuccess(response) {
  return {
    type: CREATE_GOAL_ASSESSMENT_SUCCESS,
    payload: { response },
  };
}

export function createGoalAssessmentFailed(error) {
  return {
    type: CREATE_GOAL_ASSESSMENT_FAILED,
    payload: { error },
  };
}

export function getPeriods(available) {
  return {
    type: GET_PERIODS,
    payload: { available },
  };
}


export function getPeriodsSuccess(response) {
  return {
    type: GET_PERIODS_SUCCESS,
    payload: { response },
  };
}

export function getPeriodsFailed(error) {
  return {
    type: GET_PERIODS_FAILED,
    payload: { error },
  };
}

export function createGoal(data, goalActionsInfo) {
  return {
    type: CREATE_GOAL,
    payload: { data },
    goalActionsInfo,
  };
}

export function createGoalSuccess(response) {
  return {
    type: CREATE_GOAL_SUCCESS,
    payload: { response },
  };
}

export function createGoalFailed(error) {
  return {
    type: CREATE_GOAL_FAILED,
    payload: { error },
  };
}

export function updateGoal(id, data, fromUserId, status, isMyGoal) {
  return {
    type: UPDATE_GOAL,
    payload: { id, data },
    fromUserId,
    status,
    isMyGoal,
  };
}

export function updateGoalSuccess(response) {
  return {
    type: UPDATE_GOAL_SUCCESS,
    payload: { response },
  };
}

export function updateGoalFailed(error) {
  return {
    type: UPDATE_GOAL_FAILED,
    payload: { error },
  };
}

export function resetSummaryGoals() {
  return {
    type: RESET_SUMMARY_GOALS,
  };
}

export function resetAssignedGoal() {
  return {
    type: RESET_ASSIGNED_GOAL,
  };
}

export function resetTeamGoal() {
  return {
    type: RESET_TEAM_GOAL,
  };
}

export function resetAssignedGoalDetail() {
  return {
    type: RESET_ASSIGNED_GOAL_DETAIL,
  };
}

export function resetTeamGoalDetail() {
  return {
    type: RESET_TEAM_GOAL_DETAIL,
  };
}

export function setMyGoalFilters(filters) {
  return {
    type: SET_MY_GOAL_FILTERS,
    payload: { filters },
  };
}

export function setTeamGoalFilters(filters) {
  return {
    type: SET_TEAM_GOAL_FILTERS,
    payload: { filters },
  };
}

export function toggleEditProgress(forcedState) {
  return {
    type: TOGGLE_EDIT_PROGRESS,
    payload: { forcedState },
  };
}
