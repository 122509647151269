// @packages
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { push } from 'react-router-redux';
import { useConfigurations } from 'smu-services/os-member/api/v1/community/preferences/configurations';

// @app
import Assessment360WidgetManager from 'betterme-components/Assessment360/WidgetManager';
import Dashboard from 'betterme-components/Dashboard';
import FeedbackForLeadersResults from 'betterme-components/Dashboard/FeedbackForLeadersResults';
import getMessageType from 'utils/getMessageType';
import { feedbackMessages, goalsMessages } from 'common/messages';
import {
  getFeedbackForLeadersReceivedLocation,
  getNewFeedbackRequestLocation,
} from 'betterme-components/routes/urls';
import {
  selectCommunityId,
  selectConfigurations,
  selectHasCollaborators,
} from 'betterme-components/Authorization/selectors';
import {
  selectFeedbackForLeaders,
  selectLoadingFeedbackForLeaders,
} from 'betterme-components/Dashboard/selectors';

// @own
import FeedbackForLeadersReport from './FeedbackForLeadersReport';
import messages from './messages';
import {
  trackBoxOpportunityRequestFeedback,
  trackBoxOpportunityRequestFeedbackEmpty,
  trackBoxStrengthsRequestFeedbackEmpty,
} from './analytics';

const MyDashboard = ({
  allowsFeedbackForLeaders,
  allowsFeedbackForLeadersResult,
  allowsFeedbackRequest,
  communityId,
  feedbackForLeaders,
  goToRequestFeedback,
  hasCollaborators,
  intl: { formatMessage },
  loadingFeebackForLeaders,
  location,
  session: { user },
}) => {
  const useConfigurationsHook = useConfigurations();
  const assessment360IsEnabled = useConfigurationsHook.selectEnabledAssessment360();
  const oldFFLIsEnabled = useConfigurationsHook.selectEnabledOldFfl();
  const getMessage = getMessageType(communityId);
  const emptyStateOwnReceivedTitle = getMessage('EvaluationsEmptyStateOwnReceivedTitle');
  const { pathname } = location;

  const detailsPath = (state) => ({
    evaluationDetail: '/evaluations',
    feedbackLink: '/newfeedbackrequest',
    feedbackReceived: { pathname: `${pathname}/feedbackreceived`, state },
    goals: { pathname: `${pathname}/assignedgoals`, state },
  });

  const messagesEmptyStates = {
    feedbackReceived: {
      main: formatMessage(feedbackMessages.FeedbackNoFeedbacks),
      suggestion: formatMessage(feedbackMessages.FeedbackRequestFeedback),
    },
    goalsAssigned: {
      main: formatMessage(goalsMessages.GoalsNoAssigned),
    },
    lastEvaluation: {
      main: formatMessage(emptyStateOwnReceivedTitle),
    },
  };

  const baseLinkConfig = allowsFeedbackRequest ? ({
    label: formatMessage(feedbackMessages.FeedbackRequestFeedback),
    to: '/newfeedbackrequest',
  }) : {};

  const strengthBoxEmpty = {
    description: formatMessage(messages.MyDashboardBoxEmptyDescription),
    info: formatMessage(messages.MyDashboardBoxEmptyInfo),
    link: {
      ...baseLinkConfig,
      onClick: trackBoxStrengthsRequestFeedbackEmpty,
    },
  };

  const opportunitiesBoxEmpty = {
    description: formatMessage(messages.MyDashboardBoxEmptyDescription),
    info: formatMessage(messages.MyDashboardBoxEmptyInfo),
    link: {
      ...baseLinkConfig,
      onClick: trackBoxOpportunityRequestFeedbackEmpty,
    },
  };

  const opportunitiesLink = {
    ...baseLinkConfig,
    onClick: trackBoxOpportunityRequestFeedback,
  };

  function getFeedbackForLeadersComponent() {
    let component = '';

    if (allowsFeedbackForLeadersResult && (
      hasCollaborators || !isEmpty(feedbackForLeaders)
    )) {
      component = (
        <FeedbackForLeadersResults
          {...feedbackForLeaders}
          goToFeedbackReceived={getFeedbackForLeadersReceivedLocation}
          loading={loadingFeebackForLeaders}
        />
      );
    } else if (allowsFeedbackForLeaders) {
      component = (
        <FeedbackForLeadersReport
          hasCollaborators={hasCollaborators}
          user={user}
        />
      );
    }

    return component;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {oldFFLIsEnabled && getFeedbackForLeadersComponent()}
      {assessment360IsEnabled && (
        <Assessment360WidgetManager className="mb-4" isOwner userId={user.id} />
      )}
      <Dashboard
        detailsPath={detailsPath}
        feedbackReceivedLink="/newfeedbackrequest"
        goToFeedback={goToRequestFeedback}
        goToMyFeedbackReceived={getFeedbackForLeadersReceivedLocation}
        location={location}
        messagesEmptyStates={messagesEmptyStates}
        opportunitiesBoxEmpty={opportunitiesBoxEmpty}
        opportunitiesLink={opportunitiesLink}
        strengthBoxEmpty={strengthBoxEmpty}
        user={user}
      />
    </>
  );
};

MyDashboard.propTypes = {
  allowsFeedbackForLeaders: PropTypes.bool,
  allowsFeedbackForLeadersResult: PropTypes.bool,
  allowsFeedbackRequest: PropTypes.bool,
  communityId: PropTypes.number,
  feedbackForLeaders: PropTypes.object,
  goToRequestFeedback: PropTypes.func,
  hasCollaborators: PropTypes.bool,
  intl: PropTypes.object,
  loadingFeebackForLeaders: PropTypes.bool,
  location: PropTypes.object,
  session: PropTypes.object,
};

const mapStateToProps = (state) => ({
  allowsFeedbackForLeaders: selectConfigurations('allowsFeedbackForLeaders')(state),
  allowsFeedbackForLeadersResult: selectConfigurations('allowsFeedbackForLeadersResult')(state),
  allowsFeedbackRequest: selectConfigurations('allowsFeedbackRequest')(state),
  communityId: selectCommunityId(state),
  feedbackForLeaders: selectFeedbackForLeaders(state),
  hasCollaborators: selectHasCollaborators(state),
  loadingFeebackForLeaders: selectLoadingFeedbackForLeaders(state),
});

const goToRequestFeedback = () => push(getNewFeedbackRequestLocation());

const mapDispatchToProps = {
  goToRequestFeedback,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MyDashboard));
