// @packages
import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

// @own
import './styles.scss';
import view from './view.png';

const FeedbackRequestAction = ({
  actionText,
  className,
  onClick,
}) => {
  return (
    <div
      className={cn('feedback-request-action', className)}
      onClick={onClick}
    >
      <img
        className="feedback-request-action__img"
        src={view}
      />
      <div className="feedback-request-action__label">
        {actionText}
      </div>
    </div>
  );
};

FeedbackRequestAction.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default FeedbackRequestAction;
