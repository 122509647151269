// @app
import {
  COMMENT,
  IMAGE,
  LIFT,
  LIKE,
  STAR,
} from './constants';
import { isOtherNotification } from 'utils/checkNotificationsType';
import infoIcon from 'betterme-components/assets/img/icon-notification-smu.svg';

export const getIconProps = (n) => {
  let iconProps = {};
  switch (n.type) {
    case LIFT:
    case STAR:
      iconProps = {
        color: 'violet',
        icon: 'star',
      };
      break;
    case COMMENT:
    case IMAGE:
      iconProps = {
        color: 'light-blue',
        icon: 'comment-full',
      };
      break;
    case LIKE:
      iconProps = {
        color: 'red',
        icon: 'like-full',
      };
      break;
    default:
      iconProps = {
        color: '',
        icon: '',
      };
      break;
  }

  if (n?.payload?.badgeIconColor) {
    iconProps.backgroundColor =
      n?.payload?.badgeIconColor === '#00000000'
        ? '#ffffff'
        : n?.payload?.badgeIconColor;
  }

  if (n?.payload?.badgeIconUrl) {
    iconProps.icon = n?.payload?.badgeIconUrl;
  }

  if (n?.payload?.bigImageUrl) {
    iconProps.image = n?.payload?.bigImageUrl;
  }

  if (isOtherNotification(n?.type) && !iconProps?.image) {
    iconProps.image = n?.fromUser?.profileImageCode;
    if (!iconProps.icon) {
      iconProps.icon = infoIcon;
    }
  }

  return iconProps;
};



