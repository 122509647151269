// @packages
import React from 'react';
import Container from 'smu-layout-components/Container';
import PropTypes from 'prop-types';
import Results from 'smu-app-components/Assessment360/pages/Results';
import { TYPE_BY_ALIAS } from 'smu-app-components/Assessment360/constants';
import { connect } from 'react-redux';

// @app
import { selectCommunityId, selectSessionUserId } from 'betterme-components/Authorization/selectors';

function LayoutAs360Results({ communityId, params, sessionUserId }) {
  const evaluationType = TYPE_BY_ALIAS[params.type];
  const userId = Number(params.userId);
  const isOwner = sessionUserId === userId;

  return (
    <Container centered>
      <Results
        className="py-8"
        communityId={communityId}
        evaluationType={evaluationType}
        isOwner={isOwner}
        userId={userId}
      />
    </Container>
  );
}

LayoutAs360Results.propTypes = {
  /**
   * Community ID
   */
  communityId: PropTypes.number.isRequired,
  /**
   * Route params
   */
  params: PropTypes.shape({
    type: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
  }),
  /**
   * Session user ID
   */
  sessionUserId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  communityId: selectCommunityId(state),
  sessionUserId: selectSessionUserId(state),
});

export default connect(mapStateToProps)(LayoutAs360Results);
