import { defineMessages } from 'react-intl';

export default defineMessages({
  FeedbackDashboardLastEvaluationMain: {
    id: 'Betterme.Dashboard.LastEvaluationMain',
    defaultMessage: 'You haven\'t received any Evaluations for this period yet',
  },
  MyDashboardBoxEmptyInfo: {
    id: 'Betterme.MyDashboard.BoxEmptyInfo',
    defaultMessage: 'Get more ratings',
  },
  MyDashboardBoxEmptyDescription: {
    id: 'Betterme.MyDashboard.BoxEmptyDescription',
    defaultMessage: 'Why don\'t you ask your colleagues about your competencies?',
  },
  FeedbackForLeadersReportQuantityTitle: {
    id: 'Betterme.MyDashboard.QuantityTitle',
    defaultMessage: 'So far, you have {counter} faeedback',
  },
  FeedbackForLeadersReportDescription: {
    id: 'Betterme.MyDashboard.Description',
    defaultMessage: 'As soon as the feedback period is over, you will have access to your results. Remember, only you can see the results when at least three Feedback have been responded to.',
  },
});
