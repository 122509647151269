// @packages
import Icon from 'smu-ui-components/Icon';
import PropTypes from 'prop-types';
import React from 'react';
import UserAvatar from 'smu-ui-components/UserAvatar';
import cn from 'classnames';
import { FormattedRelative } from 'react-intl';
import { connect } from 'react-redux';
import { setReadNotification } from 'smu-app-components/NotificationsV2/actions';
import { trackEvent } from 'smu-utils/gtm';
import { withRouter } from 'react-router';

// @app
import infoIcon from 'betterme-components/assets/img/icon-notification-smu.svg';
import parseDate from 'utils/parseDate';
import { actions as routeActions } from 'utils/routes';
import { getIconProps } from 'betterme-components/NotificationsV2/helpers';
import { isOtherNotification } from 'utils/checkNotificationsType';
import { externalLinkRedirect } from 'utils/external';

// @own
import { setReadNotificationHistory } from './actions';
import './styles.scss';

const NotificationHistoryItem = ({
  setReadNotification,
  setReadNotificationHistory,
  notification,
  router,
  showActivity,
  showCongratulation,
  showHome,
}) => {
  const descriptionParsed = notification?.notificationText
    ? notification?.notificationText
      .replace(notification?.fromUser.firstName, '|')
      .replace(notification?.fromUser.middleName, '')
      .replace(notification?.fromUser.lastName, '')
      .trim()
    : '';
  const descriptionSplitted = descriptionParsed.split('|');

  const { fromUser } = notification;

  const iconProps = getIconProps(notification);

  const onClickHandler = (n) => {
    setReadNotificationHistory(n.id);
    setReadNotification(n.id);
    if (
      n.target === 'INTERACTION_ANNIVERSARY' ||
      n.target === 'INTERACTION_BIRTHDAY'
    ) {
      showCongratulation(n.starId);
    } else if (
      n.type === 'CUSTOM_GLOBAL_NOTIFICATION' ||
      n.type === 'YEARINREVIEW' ||
      n.type === 'DEEP_LINK'
    ) {
      externalLinkRedirect(n.target, router);
    } else if ((n.type === 'MENTION' || !isOtherNotification(n.type))) {
      showActivity(n.starId);
    } else {
      showHome();
    }
    trackEvent({
      action: 'Detail_All',
      category: 'Notifications',
      description: n?.description,
      label: n?.type,
      title: n?.notification,
      type: n?.isRead ? 'read' : 'unread',
    });
  };

  const getAvatarProps = () => {
    if (iconProps?.image) {
      return {
        imageUrl: iconProps?.image,
      };
    }

    return {
      profileImageCode: fromUser.profileImageCode,
    };
  };

  return (
    <div
      className={cn(
        'notifications-history__item',
        {
          'notifications-history__item--active': !notification.isRead,
        },
      )}
      onClick={() => onClickHandler(notification)}
    >
      <div className="notifications-history__item-user">
        <UserAvatar
          className="notifications-history__item--avatar"
          height={40}
          width={40}
          {...getAvatarProps()}
        />
        {isOtherNotification(notification.type) || notification?.payload?.badgeIconUrl ? (
          <div
            className="notifications-history__item--icon"
            style={{ background: iconProps?.backgroundColor }}
          >
            <img
              alt="Info"
              className="notifications-history__item--image"
              src={iconProps?.icon ? iconProps?.icon : infoIcon}
            />
          </div>
        ) : (
          <Icon
            className="notifications-history__item--icon"
            size="extra-small"
            {...getIconProps(notification)}
          />
        )}
      </div>
      <div className="notifications-history__item--text">
        <span className="notifications-history__item--notification">{descriptionSplitted[0]} </span>
        {!isOtherNotification(notification.type) &&
          <span className="notifications-history__item--name">
            {`${fromUser.firstName ? fromUser.firstName : ''}
            ${fromUser.middleName ? fromUser.middleName : ''}
            ${fromUser.lastName ? fromUser.lastName : ''} `}
          </span>
        }
        <span className="notifications-history__item--notification">{descriptionSplitted[1]}</span>
        {notification?.description && <div className="notifications-history__item--description">{notification?.description}</div>}
      </div>
      <div className="notifications-history__item--date">
        <span className="notifications-history__item--date-text">
          <FormattedRelative value={parseDate(notification.dateNotification).toDate()} />
        </span>
      </div>
    </div>
  );
};

NotificationHistoryItem.propTypes = {
  notification: PropTypes.object,
  showActivity: PropTypes.func.isRequired,
  showCongratulation: PropTypes.func.isRequired,
  showHome: PropTypes.func.isRequired,
  showQuestionnaire: PropTypes.func.isRequired,
};

const actions = {
  showActivity: activityId => routeActions.activity({ activityId }),
  showCongratulation: id => routeActions.congratulation({ id }),
  showHome: () => routeActions.home(),
  setReadNotificationHistory,
  setReadNotification,
};

export default connect(null, actions)(withRouter(NotificationHistoryItem));
