// @packages
import { get, orderBy } from 'lodash';
import { createSelector } from 'reselect';

const reducerDomain = 'performanceRetrospectiveDetail';

export const selectIsFetching = (state) =>
  get(state, [reducerDomain, 'isFetching']);

export const selectPerformanceRetrospectiveDetail = (state) =>
  get(state, [reducerDomain, 'performanceRetrospectiveDetail']);

export const selectBehavioralAssesmentDetailByRatings = createSelector(
  selectPerformanceRetrospectiveDetail,
  (performanceRetrospectiveDetail) => {
    const behaviors = get(performanceRetrospectiveDetail, ['behaviors'], []);
    const ratings = behaviors?.reduce((accumulator, current) => {
      const ratingHasNotAdded = accumulator.every((rating) => rating.name !== current.rating.name);
      const rating = current.rating;
      rating.questions = [];
      if (ratingHasNotAdded) {
        accumulator.push(rating)
      };
      return accumulator;
    }, []);
    behaviors.forEach(element => {
      const questionToAdd = element.question;
      const targetObject = ratings.find((obj) => obj.name === element.rating.name);
      targetObject.questions.push(questionToAdd);
    });
    const ratingsOrdered = orderBy(ratings, ['ordinality'], ['desc']);

    return ratingsOrdered;
  }
);

export const selectCoCreatedTopics = createSelector(
  selectPerformanceRetrospectiveDetail,
  (performanceRetrospectiveDetail) => {
    const ratedTopics = get(performanceRetrospectiveDetail, ['ratedTopics']);
    const coCreatedRatedTopics = get(performanceRetrospectiveDetail, ['coCreated', 'ratedTopics']);
    const ratedTopicsOrdered = orderBy(ratedTopics, ['ordinality']);
    const coCreatedRatedTopicsOrdered = orderBy(coCreatedRatedTopics, ['ordinality']);
    const coCreatedRatedSkills = coCreatedRatedTopicsOrdered.map(ratedTopic => {
      const skills = ratedTopic?.topics?.reduce((accumulator, currentValue) => {
        if (currentValue.skill) {
          currentValue.ignoredRate = true;
          accumulator.push(currentValue);
        }
        return accumulator;
      }, []);
      return { ...ratedTopic, topics: skills };
    });

    const ratedTopicsAll = ratedTopicsOrdered.concat(coCreatedRatedSkills);
    const coCreatedTopics = ratedTopicsAll.reduce((accumulator, currentValue) => {
      const findElementIndex = accumulator.findIndex((element => element.id === currentValue.id));
      if (findElementIndex === -1) {
        accumulator.push({ ...currentValue });
      } else {
        accumulator[findElementIndex].topics = [
          ...accumulator[findElementIndex].topics,
          ...currentValue.topics,
        ];
      }
      return accumulator;
    }, []);
    const coCreatedTopicsOrdered = orderBy(coCreatedTopics, ['ordinality']);
    return coCreatedTopicsOrdered;
  },
);

export const selectDataCreateAnswerPerformanceRetrospective = (state) =>
  get(state, [reducerDomain, 'dataCreateAnswerPerformanceRetrospective']);
