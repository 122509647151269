// @packages
import React from 'react';
import ModalOneOnOneForm from 'smu-app-components/OneOnOne/Form/Modal';
import PropTypes from 'prop-types';
import UtilsProvider from 'smu-app-components/UtilsProvider';
import { connect } from 'react-redux';

// @app
import { CALENDAR_PROVIDER, ENABLED_ONE_ON_ONE } from 'betterme-components/services/CommunityConfigs/constants';
import {
  getCommunityConfigValue,
  getCommunityConfigValueBool,
} from 'betterme-components/services/CommunityConfigs/selectors';

const ModalOneOnOneFormContainer = ({ calendarProvider, enabledOneOnOne, ...props}) => {
  const utils = {
    calendarProvider,
    enableSyncWithCalendar: true,
    mainFeatureIsEnabled: enabledOneOnOne,
  };

  return (
    <UtilsProvider utils={utils}>
      <ModalOneOnOneForm {...props} />
    </UtilsProvider>
  );
};

ModalOneOnOneFormContainer.propTypes = {
  calendarProvider: PropTypes.string,
  enabledOneOnOne: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  calendarProvider: getCommunityConfigValue(state, CALENDAR_PROVIDER),
  enabledOneOnOne: getCommunityConfigValueBool(state, ENABLED_ONE_ON_ONE),
});

export default connect(mapStateToProps)(ModalOneOnOneFormContainer);
