// @packages
import React, { useEffect } from 'react';
import AppQuickAccess from 'smu-app-components/AppQuickAccess';
import Header from 'smu-app-components/MainHeader';
import MainUserMenu from 'smu-app-components/MainUserMenu';
import PropTypes from 'prop-types';
import withRootModal from 'smu-app-components/RootModal/withRootModal';
import { actionRequestInit } from 'smu-utils/reduxRequests/actions';
import { buildImageUrl } from 'smu-utils/image';
import { connect } from 'react-redux';
import { getUserLanguage } from 'smu-utils/language';
import { getWebUrl } from 'smu-utils/globalRequestHelper';
import { push } from 'react-router-redux';
import { selectResult } from 'smu-utils/reduxRequests/selectors';
import { withRouter } from 'react-router';

// @app
import Link from 'betterme-components/Link';
import MainSearch from 'betterme-components/MainSearch';
import NotificationsV2 from 'betterme-components/NotificationsV2';
import { getLivingValuesTexts as apiGetLivingValuesTexts } from 'betterme-components/api';
import { handleServerError } from 'betterme-components/api';
import { logout } from 'betterme-components/Authorization/actions';
import { TELL_US_ABOUT_MODAL } from 'betterme-components/constants/modalTypes';
import {
  isEnabledEngagement,
  isEnabledIntercom,
  isEnabledOnboarding,
  isEnabledPerformance,
  isEnabledQuickAccess,
  isEnabledSurveysOnly,
} from 'betterme-components/services/CommunityConfigs/selectors';
import {
  selectCommunityConfigurations,
  selectCommunityId,
  selectUserInfo,
} from 'betterme-components/Authorization/selectors';

// @own
import { GET_LIVING_VALUES_TEXTS } from './constants';
import { getMyProfileLocation } from 'betterme-components/routes/urls';
import { getHeaderLink, getUserMenuLinks } from './helpers';

function MainHeader({
  actionRequestInit,
  communityId,
  communityImageCode,
  enabledPerformance,
  enabledQuickAccess,
  enabledStarMeUp,
  enabledSurveysOnly,
  intercomEnabled,
  livingValuesTexts,
  location,
  logout,
  onboardingEnabled,
  openModal,
  user,
}) {
  const communityLogo = communityImageCode
    ? buildImageUrl({
        code: communityImageCode,
        height: 40,
        width: 40,
      })
    : '';
  
  const headerLink = getHeaderLink({
    enabledPerformance,
    enabledStarMeUp,
    enabledSurveysOnly,
  });

  const goToProfile = () => {
    if (enabledStarMeUp) {
      location.href = `${getWebUrl()}/me`;
    } else {
      push(getMyProfileLocation());
    }
  };

  const openModalSuggestions = () => {
    openModal({ modalType: TELL_US_ABOUT_MODAL, modalProps: { user } });
  };

  const getLivingValuesTexts = () => {
    actionRequestInit({
      api: apiGetLivingValuesTexts,
      id: GET_LIVING_VALUES_TEXTS,
      params: {
        languageCode: getUserLanguage(),
        name: 'living_values',
      },
    });
  };

  const handleOrganizationChanged = () => {
    location.reload();
  };

  const linksConfig = getUserMenuLinks({
    enabledStarMeUp,
    intercomEnabled,
    livingValuesTexts,
    logout,
    onboardingEnabled,
    openModalSuggestions,
  });

  const HeaderLogo = (props) => (
    <Link href={headerLink} {...props} />
  );

  useEffect(() => {
    if (enabledStarMeUp) {
      getLivingValuesTexts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabledStarMeUp]);

  return (
    <Header
      components={{
        appQuickAccess: enabledQuickAccess ? <AppQuickAccess /> : null,
        logo: HeaderLogo,
        notifications: <NotificationsV2 unifiedExperience />,
        search: <MainSearch />,
        userMenu: (
          <MainUserMenu
            communityLogo={communityLogo}
            linksConfig={linksConfig}
            language={getUserLanguage()}
            onClickMyProfile={goToProfile}
            onError={handleServerError}
            onOrganizationChanged={handleOrganizationChanged}
            organizationId={communityId}
            origin="SMU_FEEDBACK_WEB"
            user={user}
            version={3}
          />
        ),
      }}
    />
  );
}

MainHeader.propTypes = {
  actionRequestInit: PropTypes.func,
  communityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  communityImageCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  enabledPerformance: PropTypes.bool,
  enabledQuickAccess: PropTypes.bool,
  enabledStarMeUp: PropTypes.bool,
  enabledSurveysOnly: PropTypes.bool,
  livingValuesTexts: PropTypes.object,
  location: PropTypes.object,
  logout: PropTypes.func,
  onboardingEnabled: PropTypes.bool,
  openModal: PropTypes.func,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  communityId: selectCommunityId(state),
  communityImageCode: selectCommunityConfigurations(state)?.imageCode,
  enabledPerformance: isEnabledPerformance(state),
  enabledQuickAccess: isEnabledQuickAccess(state),
  enabledStarMeUp: isEnabledEngagement(state),
  enabledSurveysOnly: isEnabledSurveysOnly(state),
  intercomEnabled: isEnabledIntercom(state),
  livingValuesTexts: selectResult(state, GET_LIVING_VALUES_TEXTS),
  onboardingEnabled: isEnabledOnboarding(state),
  user: selectUserInfo(state),
});

const mapDispatchToProps = { actionRequestInit, logout };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withRootModal(MainHeader)));
