import { defineMessages } from 'react-intl';

export default defineMessages({
  FeedbackForLeadersTitle: {
    id: 'Betterme.FeedbackForLeaders.Title',
    defaultMessage: 'Feedback for Leaders',
  },
  FeedbackForLeadersSubTitle: {
    id: 'Betterme.FeedbackForLeaders.SubTitle',
    defaultMessage: 'It’s your opportunity to contribute with your leaders’ career development.',
  },
  FeedbackForLeadersRecommendation1: {
    id: 'Betterme.FeedbackForLeaders.Recommendation1',
    defaultMessage: 'Make sure to provide ',
  },
  FeedbackForLeadersRecommendation2: {
    id: 'Betterme.FeedbackForLeaders.Recommendation2',
    defaultMessage: 'accurate and constructive feedback',
  },
  FeedbackForLeadersRecommendation3: {
    id: 'Betterme.FeedbackForLeaders.Recommendation3',
    defaultMessage: 'Assess your leader according to ',
  },
  FeedbackForLeadersRecommendation4: {
    id: 'Betterme.FeedbackForLeaders.Recommendation4',
    defaultMessage: 'your organization’s defined skills / competencies',
  },
  FeedbackForLeadersRecommendation5: {
    id: 'Betterme.FeedbackForLeaders.Recommendation5',
    defaultMessage: 'When possible, write your comments in English.',
  },
  FeedbackForLeadersRecommendation6: {
    id: 'Betterme.FeedbackForLeaders.Recommendation6',
    defaultMessage: 'To ensure anonymity and confidentiality, your leader will only be able to see the results when at least (3) three feedback have been responded to.',
  },
});
