import { defineMessages } from 'react-intl';

export default defineMessages({
  LeadershipAdminLeaderSubstitution: {
    id: 'Betterme.LeadershipAdmin.LeaderSubstitution',
    defaultMessage: 'Leader Substitution',
  },
  LeadershipAdminAccountLeaderModalMessage: {
    id: 'Betterme.LeadershipAdmin.AccountLeadersModalMessage2',
    defaultMessage: '{leader} will be the new Experience Leader of {collaborator}. This will be made once the candidate accepts your request.',
  },
  LeadershipAdminOtherLeaderModalMessage: {
    id: 'Betterme.LeadershipAdmin.OtherLeadersModalMessage',
    defaultMessage: '{leader} will be the new Leader of {collaborator}. This will be made once the candidate accepts your request.',
  },
  LeadershipAdminCareerLeaderModalMessage: {
    id: 'Betterme.LeadershipAdmin.CareerLeaderModalMessage2',
    defaultMessage: '{leader} will be the new Career Mentor of {collaborator}. This will be made once the candidate accepts your request.',
  },
  LeadershipAdminMainLeaderModalMessage: {
    id: 'Betterme.LeadershipAdmin.MainLeaderModalMessage',
    defaultMessage: '{leader} will be the new Main Leader of {collaborator}. This will be made once the candidate accepts your request.',
  },
  LeadershipAdminSecondaryLeaderModalMessage: {
    id: 'Betterme.LeadershipAdmin.SecondaryLeaderModalMessage',
    defaultMessage: '{leader} will be the new Secondary Leader of {collaborator}. This will be made once the candidate accepts your request.',
  },
  LeadershipAdminSendItModalMessage: {
    id: 'Betterme.LeadershipAdmin.SendIt',
    defaultMessage: 'Yes, assign',
  },
  LeadershipAdminSubstitutionTitleModalMessage: {
    id: 'Betterme.LeadershipAdmin.SubstitutioTitlenModalMessage',
    defaultMessage: 'You are about to assign a new leader',
  },
  LeadershipAdminSubstitutionModalMessage: {
    id: 'Betterme.LeadershipAdmin.SubstitutionModalMessage',
    defaultMessage: 'A notification will be sent to the Leader and the Glober in order to inform them about this change. Do you want to proceed?',
  },
  LeadershipAdminSubstitutionModalReplaceDescription: {
    id: 'Betterme.LeadershipAdmin.SubstitutionModalReplaceDescription',
    defaultMessage: 'A notification will be sent to the Current and New Leader as well as the Glober in order to inform them about this change. Do you want to proceed?',
  },
});
