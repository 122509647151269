// @packages
import { defineMessages } from 'react-intl';

export default defineMessages({
  breadcrumbHeader: {
    crumbs: {
      first: {
        id: 'oneOnOneDetail.breadcrumbHeader.crumbs.first',
        defaultMessage: '1:1 dashboard',
      },
      second: {
        id: 'oneOnOneDetail.breadcrumbHeader.crumbs.second',
        defaultMessage: 'My 1:1 with',
      },
    },
  },
});
