import {
  CREATE_INPUT,
  CREATE_INPUT_FAIL,
  CREATE_INPUT_SUCCESS,
  SET_INPUT,
  RESET_INPUT,
} from './actionTypes';

const initialState = {
  inputData: {},
  inputDataError: '',
  inputDataLoading: false,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case CREATE_INPUT:
      return {
        ...state,
        inputDataLoading: true,
      };

    case CREATE_INPUT_FAIL:
      return {
        ...state,
        inputDataLoading: false,
      };

    case CREATE_INPUT_SUCCESS:
      return {
        ...state,
        inputDataLoading: false,
      };

    case SET_INPUT:
      return {
        ...state,
        inputData: payload,
      };

    case RESET_INPUT:
      return {
        ...state,
        inputData: {},
      };
    default:
      return state;
  }
};
