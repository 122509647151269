// @packages
import { get } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import InputArea from 'smu-ui-components/InputArea';
import Input from 'smu-ui-components/Input';
import PropTypes from 'prop-types';
import RootModal from 'smu-app-components/RootModal';
import { getUserLanguage } from 'smu-utils/language';
import { injectIntl } from 'react-intl';

// @app
import { validEmail } from 'utils/validEmail';
import { TELL_US_ABOUT_MODAL } from '../constants/modalTypes';
import ConfirmationModal, { centerModalClass }  from '../ConfirmationModal';
import { actionsMessages, commonMessages } from 'common/messages';

// @own
import * as actions from './actions';
import {
  trackHelpClose,
  trackHelpSend,
} from './analytics';
import './styles.scss';
import messages from './messages';
import { selectReportBugLoading } from './selectors';

class TellUsAboutModal extends React.Component {
  constructor(props) {
    super(props);
    const {
      user: {
        email,
        firstName,
        lastName,
      },
    } = this.props;

    this.state = {
      comment: '',
      email: email || '',
      name: `${firstName} ${lastName || ''}`,
    };

    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleSendClick = this.handleSendClick.bind(this);
  }

  handleCancelClick() {
    const { close } = this.props;

    trackHelpClose();
    close();
  }

  handleSendClick() {
    const { createReportBug, user } = this.props;
    const {
      comment,
      email,
      name,
    } = this.state;

    trackHelpSend();
    createReportBug({
      app: "BetterMe",
      appLanguage: getUserLanguage(),
      user: name,
      id: user.id,
      community: user.organizationName,
      message: comment,
      browserInfo: navigator.userAgent,
      osInfo: navigator.platform,
      email,
    });
  }

  render() {
    const { intl: { formatMessage }, loading } = this.props;
    const {
      comment,
      email,
      name,
    } = this.state;
    const isCommentValid = get(comment.trim(), 'length') > 0;
    const isEmailValid = validEmail(email);
    const isNameValid = get(name.trim(), 'length') > 0;
    const activateSend = isCommentValid
      && isEmailValid
      && isNameValid
      && !loading;

    return (
      <ConfirmationModal
        acceptMessage={formatMessage(actionsMessages.ActionsSend)}
        cancelMessage={formatMessage(actionsMessages.ActionsCancel)}
        className="tell-us-about-modal"
        disableAccept={!activateSend}
        onAccept={this.handleSendClick}
        onCancel={this.handleCancelClick}
        title={formatMessage(commonMessages.TellUsAbout)}
      >
        <div className="tell-us-about-modal__content">
          <Input
            className="tell-us-about-modal__input"
            placeholder="Email"
            onChange={(e) => {
              this.setState({ email: e.target.value });
            }}
            value={email}
          />
          <Input
            className="tell-us-about-modal__input"
            placeholder={formatMessage(messages.TellUsAboutModalNamePlaceholder)}
            onChange={(e) => {
              this.setState({ name: e.target.value });
            }}
            value={name}
          />
          <InputArea
            className="tell-us-about-modal__textarea"
            maxLength={5000}
            onChange={(e) => {
              this.setState({ comment: e.target.value });
            }}
            placeholder={formatMessage(messages.TellUsAboutModalCommentPlaceholder)}
            rows={7}
            value={comment}
          />
        </div>
      </ConfirmationModal>
    );
  }
}

TellUsAboutModal.propTypes = {
  close: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  user: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    loading: selectReportBugLoading(state),
  };
}

const connectedComponent = connect(mapStateToProps, actions)(injectIntl(TellUsAboutModal));

export default RootModal(connectedComponent, TELL_US_ABOUT_MODAL, undefined, centerModalClass);
