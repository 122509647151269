// @packages
import { get } from 'lodash';

const reducerDomain = 'user';

export const selectUser = (state) =>
  get(state, [reducerDomain, 'user']);

export const selectLoadingUser = (state) =>
  get(state, [reducerDomain, 'loadingUser']);
