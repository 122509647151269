import { defineMessages } from 'react-intl';

export default defineMessages({
  FeedbackReceivedEmptyMessage: {
    id: 'Betterme.FeedbackReceived.EmptyMessage',
    defaultMessage: 'You haven\'t received any "{ratingName}" Feedback',
  },
  FeedbackReceivedEmptyStateTitle: {
    id: 'Betterme.FeedbackReceived.EmptyState.Title',
    defaultMessage: 'No results for your search',
  },
  FeedbackReceivedEmptyStateMessage: {
    id: 'Betterme.FeedbackReceived.EmptyState.Message',
    defaultMessage: 'Please try another filter',
  },
  FeedbackReceivedCommentLabel: {
    id: 'Betterme.FeedbackReceived.Comment.Label',
    defaultMessage: 'comments',
  },
});
