// @packages
import { get } from 'lodash';
import { createSelector } from 'reselect';

// @own
import { GOALS_ACHIEVED, GOALS_REMAINING } from './constants';

const selectGoalsProgress = ({ dashboard }) => get(dashboard, 'goalsProgress');
export const selectCommunityId = ({ session }) => get(session, 'communityConfigurations.id', 0);
export const selectCompetenciesOrder = ({ dashboard }) => get(dashboard, 'competenciesOrder');
export const selectDashboardFeedback = ({ dashboard }) => get(dashboard, 'dashboardFeedback', {});
export const selectDashboardFeedbackSummary = ({ dashboard }) => get(dashboard, 'dashboardFeedbackSummary', {});
export const selectDashboardPerformanceChart = ({ dashboard }) => get(dashboard, 'dashboardPerformanceChart', []);
export const selectFeedbackForLeaders = ({ dashboard }) => get(dashboard, 'feedbackForLeaders');
export const selectFilteredUsers = ({ dashboard }) => get(dashboard, 'filteredUsers', {});
export const selectLoading = ({ dashboard }) => get(dashboard, 'loading');
export const selectLoadingDashboardGoals = ({ dashboard }) => get(dashboard, 'loadingGoals', []);
export const selectLoadingDashboardPerformanceChart = ({ dashboard }) => get(dashboard, 'loadingDashboardPerformanceChart');
export const selectLoadingFeedbackForLeaders = ({ dashboard }) => get(dashboard, 'loadingFeedbackForLeaders');
export const selectLoadingFeedbackSummary = ({ dashboard }) => get(dashboard, 'loadingFeedbackSummary');
export const selectLoadingOpportunities = ({ dashboard }) => get(dashboard, 'loadingOpportunities');
export const selectLoadingStrengths = ({ dashboard }) => get(dashboard, 'loadingStrengths');
export const selectOpportunities = ({ dashboard }) => get(dashboard, 'opportunities', []);
export const selectOpportunitiesRating = ({ dashboard }) => get(dashboard, 'opportunitiesRating', {});
export const selectRatedTopics = ({ dashboard }) => get(dashboard, 'ratedTopics', []);
export const selectRatingFilter = ({ dashboard }) => get(dashboard, 'ratingFilter');
export const selectSelectedCompetencies = ({ dashboard }) => get(dashboard, 'selectedCompetencies', {});
export const selectSelectedCompetencyDetails = ({ dashboard }) => get(dashboard, 'selectedCompetencyDetails');
export const selectSelectedUser = ({ dashboard }) => get(dashboard, 'selectedUser', {});
export const selectStrengths = ({ dashboard }) => get(dashboard, 'strengths', []);
export const selectStrengthsRating = ({ dashboard }) => get(dashboard, 'strengthsRating', {});

export const selectGoalsProgressData = createSelector(
  selectGoalsProgress,
  (goalsProgress) => {
    if (!goalsProgress) {
      return undefined;
    }

    const {
      closed: { count: countClosed },
      opened: { count: countOpen },
    } = goalsProgress;

    return { total: countOpen + countClosed, closed: countClosed };
  }
);

export const selectGoalsChartData = createSelector(
  selectGoalsProgress,
  (goalsProgress) => {
    if (!goalsProgress) {
      return [];
    }
    const {
      closed: { weightingSum: weightingClosed },
      opened: { weightingSum: weightingOpen },
    } = goalsProgress;

    return [
      { type: GOALS_ACHIEVED, count: weightingClosed },
      { type: GOALS_REMAINING, count: weightingOpen },
    ];
  }
);
