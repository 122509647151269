// @packages
import React from 'react';
import PropTypes from 'prop-types';
import RootModal from 'smu-app-components/RootModal';
import Segment from 'smu-ui-components/Segment';
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

// @app
import ConfirmationModal, {
  centerModalClass,
  modalStyles
} from 'betterme-components/ConfirmationModal';
import { CHANGE_LEADER_REQUEST_MODAL, REASONS_DIMISS_MODAL } from 'betterme-components/constants/modalTypes';
import { actionsMessages, commonMessages } from 'common/messages';
import Title from 'components/atoms/Title';

// @own
import { APPROVED, TITLE } from './constants';
import messages from './messages';
import * as actions from './actions';
import {
  selectChangeLeaderRequest,
  selectLoadingChangeLeaderRequest,
} from './selectors';
import './styles.scss';

const ChangeLeaderRequestModal = ({
  close,
  collaborator,
  comment,
  intl: { formatMessage },
  leader,
  leadershipRequest,
  loadingChangeLeaderRequest,
  openModal,
  requestId,
  user,
}) => {
  const collaboratorFullName = `${collaborator.firstName} ${collaborator.lastName}`
  const leaderFullName = `${leader.firstName} ${leader.lastName}`;
  const userFullName = `${user.firstName} ${user.lastName}`;
  const date = moment()
    .format('MMM DD')
    .replace('.', '');

  function handleAccept() {
    const collaborator = collaboratorFullName;
    const status = APPROVED;
    leadershipRequest(collaborator, requestId, status);
  }

  return (
    <Segment
      loading={loadingChangeLeaderRequest}
      loadingType="betterme"
    >
      <ConfirmationModal
        acceptMessage={formatMessage(actionsMessages.ActionsAccept)}
        cancelMessage={formatMessage(actionsMessages.ActionsDecline)}
        className="change-leader-request-modal"
        closeModal={close}
        onAccept={handleAccept}
        onCancel={() => openModal({ 
          modalType: REASONS_DIMISS_MODAL,
          modalProps: {
            collaborator: collaboratorFullName,
            requestId: requestId,
          }})}
        title={formatMessage(messages.ChangeLeadersRequestTitle)}
      >
        <div className="change-leader-request-modal__content">
          <div className="change-leader-request-modal__header">
            <div className="change-leader-request-modal__user">
              <div className="change-leader-request-modal__user-details">
                <span className="change-leader-request-modal__user-details-fullname">
                  {userFullName}
                </span>
                <span className="change-leader-request-modal__user-details-job">
                  {user.job}
                </span>
              </div>
            </div>
            <span className="change-leader-request-modal__date">{date}</span>
          </div>
          <div className="change-leader-request-modal__message">
            {formatMessage(messages.ChangeLeadersRequestDescription, {
              leader: leaderFullName,
              collaborator: collaboratorFullName,
            })}
          </div>
          <Title
            className="change-leader-request-modal__comment-title"
            size={TITLE}
            black
          >
            {formatMessage(commonMessages.Comment)}
          </Title>
          <div className="change-leader-request-modal__comment">
            {comment}
          </div>
        </div>
      </ConfirmationModal>
    </Segment>
  );
};

ChangeLeaderRequestModal.propTypes = {
  close: PropTypes.func.isRequired,
  collaborator: PropTypes.object.isRequired,
  comment: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  leader: PropTypes.object.isRequired,
  requestId: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
  changeLeaderRequest: selectChangeLeaderRequest(state),
  loadingChangeLeaderRequest: selectLoadingChangeLeaderRequest(state),
});
const mapDispatchToProps = { ...actions };
const ChangeLeaderRequestModalActions = connect(mapStateToProps, mapDispatchToProps)(ChangeLeaderRequestModal)

export default RootModal(
  injectIntl(ChangeLeaderRequestModalActions),
  CHANGE_LEADER_REQUEST_MODAL,
  modalStyles,
  centerModalClass
);
