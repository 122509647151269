import { defineMessages } from 'react-intl';

export default defineMessages({
  LeadershipRequestComponentsActionTitle: {
    id: 'Betterme.LeadershipRequestComponents.Action.Title',
    defaultMessage: 'Leadership request',
  },
  LeadershipRequestComponentsActionText: {
    id: 'Betterme.LeadershipRequestComponents.Action.Text',
    defaultMessage: 'Are you sure you want to add {fullName} as your collaborator?',
  },
  LeadershipRequestComponentsSuccessTitle: {
    id: 'Betterme.LeadershipRequestComponents.Success.Title',
    defaultMessage: 'Well done!',
  },
  LeadershipRequestComponentsSuccessText: {
    id: 'Betterme.LeadershipRequestComponents.Success.Text',
    defaultMessage: 'Your request to {fullName} has been sent successfully!',
  },
  LeadershipRequestComponentsRequestText: {
    id: 'Betterme.LeadershipRequestComponents.Request.Text',
    defaultMessage: '{fullName} requested to be your Leader',
  },
  LeadershipRequestComponentsRequestSuccessText: {
    id: 'Betterme.LeadershipRequestComponents.RequestSuccess.Text',
    defaultMessage: '{fullName} is your leader',
  },
  LeadershipRequestComponentsRequestDeclineSuccessText: {
    id: 'Betterme.LeadershipRequestComponents.RequestDeclineSuccess.Text',
    defaultMessage: 'Your message was sent successfully!',
  },
  LeadershipRequestComponentsReachedLimitOfCollaboratorsMessage: {
    id: 'Betterme.LeadershipRequestComponents.ReachedLimitOfCollaborators.Message',
    defaultMessage: 'You reached the limit of 150 collaborators. Please, contact the Administrator.'
  },
});
