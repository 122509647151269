import {
  GET_TEAM_INFORMATION_WA,
  GET_TEAM_INFORMATION_WA_FAIL,
  GET_TEAM_INFORMATION_WA_SUCCESS,
} from './actionTypes';

export function getTeamInformationWA(identification) {
  return {
    type: GET_TEAM_INFORMATION_WA,
    payload: { identification },
  };
}

export function getTeamInformationFailWA(error) {
  return {
    type: GET_TEAM_INFORMATION_WA_FAIL,
    payload: { error },
  };
}

export function getTeamInformationSuccessWA(response) {
  return {
    type: GET_TEAM_INFORMATION_WA_SUCCESS,
    payload: { response },
  };
}
