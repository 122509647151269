//TODO migrate this logic to router

import { LOCATION_CHANGE, replace } from 'react-router-redux';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { getItem } from 'smu-utils/storage';

import {
  selectAllowsEvaluations,
  selectAllowsGoals,
} from './Authorization/selectors';


const selectLocationBeforeTransitions =
  ({ routing: { locationBeforeTransitions } }) => locationBeforeTransitions;

export function* locationChangeWorker({ payload }) {
  const { pathname } = payload;
  const token = yield call(getItem, 'token');
  const allowsGoals = yield select(selectAllowsGoals);
  const allowsEvaluations = yield select(selectAllowsEvaluations);

  if (pathname === '/login' && token) {
    yield put(replace('/myprofile'));
  }

  if (
    (pathname.includes('goals') && !allowsGoals)
    ||(pathname.includes('evaluations') && !allowsEvaluations)
  ) {
    yield put(replace('/myprofile'));
  }

}

export function* setUserInfoWorker() {
  const allowsGoals = yield select(selectAllowsGoals);
  const allowsEvaluations = yield select(selectAllowsEvaluations);
  const { pathname } = yield select(selectLocationBeforeTransitions);

  if (
    (pathname.includes('goals') && !allowsGoals)
    || (pathname.includes('evaluations') && !allowsEvaluations)
  ) {
    yield put(replace('/myprofile'));
  }
}

export default function* loginSuccesWatcher() {
  yield takeLatest(LOCATION_CHANGE, locationChangeWorker);
}
