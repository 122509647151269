// @packages
import { takeLatest, call, put } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import { logout } from 'betterme-components/Authorization/actions';

// @own
import { GET_PASSWORD_PROPERTIES, POST_CHANGE_PASSWORD } from './actionTypes';
import {
  getPasswordPropertiesFail,
  getPasswordPropertiesSuccess,
  postChangePasswordFail,
  postChangePasswordSuccess,
} from './actions';
import {
  getPasswordPropeties,
  postChangePassword,
} from './api';

function* PasswordPropetiesWorker() {
  try {
    const response = yield call(getPasswordPropeties);
    yield put(getPasswordPropertiesSuccess(response));
  } catch (error) {
    yield put(getPasswordPropertiesFail(error.message));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
};

function* ChangePasswordWorker({ payload }) {
  try {
    const response = yield call(postChangePassword, payload);
    yield put(postChangePasswordSuccess(response));
    yield put(logout());
  } catch (error) {
    yield put(postChangePasswordFail(error.message));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
};

export default function* PasswordPropetiesWatcher() {
  yield takeLatest(GET_PASSWORD_PROPERTIES, PasswordPropetiesWorker);
  yield takeLatest(POST_CHANGE_PASSWORD, ChangePasswordWorker);
};
