// @packages
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { injectIntl } from 'react-intl';
import { get, isEqual, filter } from 'lodash';
import * as actionsRootModal from 'smu-app-components/RootModal/actions';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import Tabs from 'smu-ui-components/Tabs';
import Segment from 'smu-ui-components/Segment';

// @app
import { getDropdownPeriodList } from 'utils/getDropdownPeriodList';
import {
  GOAL_DETAIL_ACTION_CLOSE_MODAL,
  GOAL_DETAIL_ACTION_DELETE_MODAL,
} from '../../constants/modalTypes';
import {
  actionsMessages,
  commonMessages,
  goalsMessages,
  sectionsMessages,
} from 'common/messages';
import { selectConfigurations } from '../../Authorization/selectors';
import { selectScores } from 'betterme-components/services/Layout/selectors';
import GoalFeedItem from '../GoalFeedItem';
import GoalsEmptyState from '../GoalsEmptyState';
import messages from '../messages';

// @own
import {
  MY_TEAM_GOALS,
  PENDING_APPROVAL,
} from '../constants';
import {
  selectLoadingApprovalId,
  selectLoadingDisapprovalId,
  selectTeamFiltersApplied,
  selectTeamGoalsFilters,
} from '../selectors';
import './styles.scss';
import * as actions from '../actions';

class MyTeamGoals extends Component {
  constructor(props) {
    super(props);

    const {
      intl: { formatMessage },
      myTeamFilters,
    } = this.props;
    this.state = {
      activeTab: 0,
      filters: myTeamFilters,
    };
    this.handleApprovalAction = this.handleApprovalAction.bind(this);
    this.handleCloseAction = this.handleCloseAction.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteAction = this.handleDeleteAction.bind(this);
    this.handleDisapproval = this.handleDisapproval.bind(this);
    this.handleDisapprovalAction = this.handleDisapprovalAction.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);
    this.handlerChangeTab = this.handlerChangeTab.bind(this);
    this.messagesMyTeamGoals = {
      approve: formatMessage(messages.FeedbackGoalsDetailModalDetailBtnApprove),
      attachedFiles: formatMessage(commonMessages.AttachedFiles),
      close: formatMessage(messages.FeedbackGoalsDetailActionClose),
      closed: formatMessage(goalsMessages.GoalsClosed),
      delete: formatMessage(actionsMessages.ActionsDelete),
      dueDate: formatMessage(sectionsMessages.SectionsDueDate),
      duplicate: formatMessage(messages.FeedbackGoalsDuplicate),
      edit: formatMessage(actionsMessages.ActionsEdit),
      emptyStateEvaluator: formatMessage(messages.GoalsEmptyStateEvaluator),
      evaluatedBy: formatMessage(messages.FeedbackGoalsEvaluatedBy),
      expired: formatMessage(messages.FeedbackGoalsExpired),
      individual: formatMessage(messages.FeedbackGoalsIndividual),
      individualProgress: formatMessage(messages.FeedbackGoalsIndividualProgress),
      opened: formatMessage(goalsMessages.GoalsOpened),
      pending: formatMessage(goalsMessages.GoalsPending),
      period: formatMessage(commonMessages.Period),
      progress: formatMessage(goalsMessages.GoalsProgress),
      shared: formatMessage(messages.FeedbackGoalsShared),
      sharedProgress: formatMessage(messages.FeedbackGoalsSharedProgress),
      startDate: formatMessage(sectionsMessages.SectionsStartDate),
      weighting: formatMessage(goalsMessages.GoalsWeight),
    };
  }

  componentDidMount() {
    const {
      getPeriods,
      getTeamGoals,
      myTeamFilters,
      myTeamFilters: {
        startDate,
        endDate,
      },
      user,
    } = this.props;
    const filters = this.getApiFilters(myTeamFilters);

    if (user.id && (startDate !== null || endDate !== null)) {
      getTeamGoals(user.id, filters);
    }
    getPeriods();
  }

  componentDidUpdate(prevProps) {
    const { myTeamFilters: prevMyTeamFilters } = prevProps;
    const {
      getTeamGoals,
      user,
      myTeamFilters: currentMyTeamFilters,
    } = this.props;

    if (!isEqual(prevMyTeamFilters, currentMyTeamFilters)) {
      getTeamGoals(user.id, this.getApiFilters(currentMyTeamFilters));
    }
  }

  getApiFilters(filters) {
    const {
      assignee,
      endDate,
      startDate,
      status,
      title,
    } = filters;

    const from = startDate ? startDate.valueOf() : undefined;
    const goalAssignee = get(assignee, 'identification', null);
    const goalStatus = status !== 'ALL' ? status : null;
    const goalTitle = title !== '' ? title : null;
    const to = endDate ? endDate.valueOf() : undefined;

    return {
      assignee: goalAssignee,
      dateFrom: from,
      dateTo: to,
      status: goalStatus,
      title: goalTitle,
    };
  }

  getPeriodList() {
    const { goals: { period } } = this.props;
    return getDropdownPeriodList(period, true);
  }

  handlerChangeTab(tab) {
    this.setState({ activeTab: tab });
  }

  handleDisapproval(goalId) {
    const {
      disapprovalGoal,
      intl: { formatMessage },
    } = this.props;
    const isListGoals = true;
    const confirmationMessages = {
      success: formatMessage(messages.FeedbackGoalsSuccessDisapprovalMessage),
    };

    disapprovalGoal(goalId, confirmationMessages, isListGoals);
  }

  handleDisapprovalAction(goalId) {
    const {
      intl: { formatMessage },
      openModal,
    } = this.props;

    openModal({
      modalType: GOAL_DETAIL_ACTION_DELETE_MODAL,
      modalProps: {
        acceptMessage: formatMessage(actionsMessages.ActionsContinue),
        cancelMessage: formatMessage(actionsMessages.ActionsCancel),
        goalId,
        onAccept: this.handleDisapproval,
        title: formatMessage(messages.FeedbackGoalsDetailModalDeleteTitle),
      }
    });
  }

  handleApprovalAction(goalId, assigneesList, period, weighting) {
    const {
      approvalGoal,
      getTeamGoalDetail,
      intl: { formatMessage },
      user,
    } = this.props;
    const data = {
      assignees: assigneesList.map(assignee => assignee.id),
      periodId: period.id,
      weighting,
    };
    const confirmationMessages = {
      success: formatMessage(messages.FeedbackGoalsSuccessApprovalMessage),
    };
    getTeamGoalDetail(goalId, user.id);
    approvalGoal(goalId, data, confirmationMessages);
  }

  handleCloseAction(progress, goalId) {
    const {
      closeGoal,
      intl: { formatMessage },
      isGoalScoreContinous,
      isPercentage,
      openModal,
      scores,
    } = this.props;

    const isListGoals = true;
    const options = isGoalScoreContinous ? undefined : scores.map(({ max, name }) =>
      ({ value: max, label: name }));
    const range = isGoalScoreContinous ? { min: scores[0].min, max: scores[scores.length - 1].max } : undefined;
    const defaultValue = progress && !isPercentage ? progress : '';

    openModal({
      modalType: GOAL_DETAIL_ACTION_CLOSE_MODAL,
      modalProps: {
        acceptMessage: formatMessage(messages.FeedbackGoalsDetailModalCloseAccept),
        cancelMessage: formatMessage(actionsMessages.ActionsCancel),
        defaultValue,
        goalId,
        isListGoals,
        isNumeric: isGoalScoreContinous,
        onAccept: closeGoal,
        messages: {
          checkbox: formatMessage(messages.FeedbackGoalsDetailModalCloseCheckbox),
          comment: formatMessage(messages.FeedbackGoalsDetailModalCloseComment),
          dropdown: formatMessage(messages.FeedbackGoalsDetailModalCloseDropdown),
          lastAssessmentWarning: formatMessage(messages.FeedbackGoalsDetailModalCloseWarning),
          numberSelector: formatMessage(messages.FeedbackGoalsDetailModalCloseNumberSelector),
        },
        options,
        range,
        title: formatMessage(messages.FeedbackGoalsDetailModalCloseTitle),
      }
    });
  }

  handleDelete(goalId) {
    const {
      deleteGoal,
      intl: { formatMessage },
    } = this.props;
    const isListGoals = true;
    const confirmationMessages = {
      success: formatMessage(messages.FeedbackGoalsSuccessDisapprovalMessage),
    };

    deleteGoal(goalId, confirmationMessages, isListGoals);
  }

  handleDeleteAction(goalId) {
    const {
      intl: { formatMessage },
      openModal,
    } = this.props;

    openModal({
      modalType: GOAL_DETAIL_ACTION_DELETE_MODAL,
      modalProps: {
        acceptMessage: formatMessage(actionsMessages.ActionsContinue),
        cancelMessage: formatMessage(actionsMessages.ActionsCancel),
        goalId,
        onAccept: this.handleDelete,
        title: formatMessage(messages.FeedbackGoalsDetailModalDeleteTitle),
      }
    });
  }

  handleOnClick(goal, typeAction) {
    const {
      addToastMessage,
      intl: { formatMessage },
      user,
    } = this.props;
    const isUserEvaluator = goal?.evaluator?.id === user?.id;
    const isPendingGoal = goal?.status === PENDING_APPROVAL;
    const showToastMessage = isPendingGoal && !isUserEvaluator;

    if (showToastMessage) {
      addToastMessage({
        message: formatMessage(messages.GoalsEmptyStateToastMessageIsNotEvaluator),
        timeout: 3000
      });
    } else {
      const {
        goToPath,
        params,
        user,
      } = this.props;
      const pathMyTeamGoals = params?.userid ? `/profile/${params?.userid}/goals/myteamgoals/` : '/myteam/goals/';

      goToPath({
        pathname: `${pathMyTeamGoals}${goal.id}`,
        state: { user, params, typeAction },
      });
    }
  }

  renderGoalsTeamList() {
    const {
      goals: { teamGoals },
      loadingApprovalId,
      loadingDisapprovalId,
      newStyle,
      user,
    } = this.props;
    const { activeTab } = this.state;
    let teamGoalsList;

    switch (activeTab) {
      case 0:
        teamGoalsList = filter(teamGoals, goal => goal.assignment === 'INDIVIDUAL');
        break;

      case 1:
        teamGoalsList = filter(teamGoals, goal => goal.assignment === 'SHARED');
        break;

      default:
        break;
    }

    if (teamGoalsList.length !== 0) {
      return teamGoalsList.map((goal) => {
        const isLoading = loadingApprovalId === goal.id || loadingDisapprovalId === goal.id;
        const allowedEdition = user.id === goal.evaluator?.id;

        return (
          <GoalFeedItem
            allowedEdition={allowedEdition}
            key={goal.id}
            loading={isLoading}
            messages={this.messagesMyTeamGoals}
            newStyle={newStyle}
            onClick={(typeAction) => this.handleOnClick(goal, typeAction)}
            onClickApproval={this.handleApprovalAction}
            onClickClose={this.handleCloseAction}
            onClickDelete={this.handleDeleteAction}
            onClickDisapproval={this.handleDisapprovalAction}
            typeGoals={MY_TEAM_GOALS}
            user={user}
            {...goal}
          />
        );
      });
    } else {
      return (
        <GoalsEmptyState type="TEAM" own />
      );
    }
  }

  render() {
    const { goals: { fetching } } = this.props;
    const { activeTab } = this.state;
    const goalsAssignedList = this.renderGoalsTeamList();
    const tabs = [
      {
        name: this.messagesMyTeamGoals.individual,
        enabled: true,
        onClick: () => this.handlerChangeTab(0),
      },
      {
        name: this.messagesMyTeamGoals.shared,
        enabled: true,
        onClick: () => this.handlerChangeTab(1)
      }
    ];

    return (
      <div className="my-team-goals">
        <div className="my-team-goals__fixed-menu">
          <Tabs tabs={tabs} active={activeTab} />
        </div>
        <div className="my-team-goals__list">
          <Segment
            loading={fetching}
            loadingType="betterme"
          >
            {goalsAssignedList}
          </Segment>
        </div>
      </div>
    );
  }
}

MyTeamGoals.defaultProps = {
  newStyle: false,
}

MyTeamGoals.propTypes = {
  approvalGoal: PropTypes.func.isRequired,
  disapprovalGoal: PropTypes.func.isRequired,
  filtersApplied: PropTypes.bool,
  getPeriods: PropTypes.func.isRequired,
  getTeamGoals: PropTypes.func.isRequired,
  goToPath: PropTypes.func.isRequired,
  goals: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  loadingDisapproval: PropTypes.bool,
  newStyle: PropTypes.bool,
  params: PropTypes.object,
  setTeamGoalFilters: PropTypes.func.isRequired,
  teamGoalsFilters: PropTypes.shape({
    filterPeriodId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    filterStatus: PropTypes.string,
    filterText: PropTypes.string,
    filterUser: PropTypes.string,
  }).isRequired,
  user: PropTypes.object,
};

const goToPath = (path) => push(path);
const mapStateToProps = (state) => {
  const { goals } = state;

  return {
    filtersApplied: selectTeamFiltersApplied(state),
    goals,
    isGoalScoreContinous: selectConfigurations('continuousGoalScores')(state),
    isPercentage: selectConfigurations('allowsPercentageProgress')(state),
    loadingApprovalId: selectLoadingApprovalId(state),
    loadingDisapprovalId: selectLoadingDisapprovalId(state),
    scores: selectScores(state),
    teamGoalsFilters: selectTeamGoalsFilters(state),
  };
};
const mapDispatchToProps = {
  ...actions,
  ...actionsRootModal,
  addToastMessage,
  goToPath,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MyTeamGoals));
