// @packages
import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

// @own
import './styles.scss';

const ProgressSliderButton = ({
  className,
  editing,
  innerRef,
  onMouseDown,
  popupContent,
  showPopup,
}) => {

  return (
    <div
      className={cn('progress-slider-button', className, {
        'progress-slider-button--editing': editing,
      })}
    >
      {showPopup && (
        <div className="progress-slider-button__tooltip">
          {popupContent}
        </div>
      )}
      <div className="progress-slider-button__button"
        onMouseDown={onMouseDown}
        ref={innerRef}
        tabIndex="0"
      />
    </div>
  );
};

ProgressSliderButton.propTypes = {
  className: PropTypes.string,
  editing: PropTypes.bool,
  innerRef: PropTypes.object.isRequired,
  onMouseDown: PropTypes.func.isRequired,
  popupContent: PropTypes.string,
  showPopup: PropTypes.bool.isRequired,
};

export default ProgressSliderButton;
