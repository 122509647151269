// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

// @app
import FeedbackReceivedComments from '../FeedbackReceivedComments';
import FeedbackReceivedSummary from '../FeedbackReceivedSummary';
import FeedbackReceivedTabs from '../FeedbackReceivedTabs';
import messages from '../messages';

const FeedbackReceivedDetails = ({
  getTabs,
  intl: { formatMessage },
  isSkillDetail,
  itemCommunityRatings,
  itemSummary,
  ratedItemsDetails,
  ratingName,
  selectRating,
  selectedItemName,
  selectedRating,
}) => (
  <div className="feedback-received__details">
    <FeedbackReceivedSummary
      className="feedback-received__summary"
      isSkillDetail={isSkillDetail}
      name={selectedItemName}
      ratings={itemSummary}
    />
    <div className="feedback-received__comments-wrapper">
      <FeedbackReceivedTabs
        active={selectedRating}
        onTabClick={selectRating}
        tabs={getTabs(itemCommunityRatings)}
      />
      <ul className="feedback-received__user-feedbacks">
        {ratedItemsDetails && ratedItemsDetails.length > 0 ? (
          ratedItemsDetails.map((detail) => (
            <li key={detail.user.senderId}>
              <FeedbackReceivedComments
                className="feedback-received__comments"
                commentsLabel={formatMessage(
                  messages.FeedbackReceivedCommentLabel
                )}
                feedbackList={detail.feedbackList}
                user={detail.user}
              />
            </li>
          ))
        ) : (
          <p className="feedback-received__user-feedbacks-empty-state">
            {formatMessage(messages.FeedbackReceivedEmptyMessage, {
              ratingName,
            })}
          </p>
        )}
      </ul>
    </div>
  </div>
);

FeedbackReceivedDetails.propTypes = {
  getTabs: PropTypes.func,
  intl: PropTypes.object.isRequired,
  itemSummary: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
      imageCode: PropTypes.string,
      name: PropTypes.string.isRequired,
    })
  ),
  itemCommunityRatings: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      imageCode: PropTypes.string,
      name: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ),
  ratedItemsDetails: PropTypes.arrayOf(
    PropTypes.shape({
      feedbackList: PropTypes.array,
      user: PropTypes.object,
    })
  ),
  selectedRating: PropTypes.number,
};

export default injectIntl(FeedbackReceivedDetails);
