// @packages
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { push } from 'react-router-redux';
import Icon from 'smu-ui-components/IconV2';

// @app
import SuggestedActionsTemplate from 'components/templates/SuggestedActions'; 
import { getTeamEvaluationsLocation } from 'betterme-components/routes/urls';
import {
  selectOptionsScores,
  selectSuggestedActions,
} from 'betterme-components/Evaluations/selectors';
import { getSuggestedActions } from 'betterme-components/Evaluations/actions';
import { selectuserInfo } from 'betterme-components/services/UserInformation/selectors';
import { evaluationsMessages } from 'common/messages';

// @own
import messages from './messages';

const SuggestedActions = ({
  getSuggestedActions,
  intl: { formatMessage },
  optionsScores,
  suggestedActions,
  isNineBoxDisabled,
  hasNineBox,
  promotionMessage,
  suggestedAction: {
    disclaimerNineBox,
    onSuggestedActionsChange,
    onSuggestedActionsCommentChange,
    suggeestedActionsIds,
    suggestedActionsComment,
  },
  user,
}) => {
  const [nineBoxScore, setNineBoxScore] = useState('');
  const userFullName = `${user?.firstName} ${user?.lastName}`;
  const disclaimerIcon = (
    <Icon
      className="period-evaluation__suggested-actions--icon"
      color="orange"
      icon="expired-icon"
      size={14}
    />
  );

  function handleNineBoxChange(value) {
    setNineBoxScore(value);
  };

  useEffect(() => { getSuggestedActions(false); }, []);

  return (
    <SuggestedActionsTemplate
      className="period-evaluation__suggested-actions"
      collapsibleTitle={formatMessage(evaluationsMessages.EvaluationsSuggestedActions)}
      promotionMessage={promotionMessage}
      scoreSelection={{
        description: formatMessage(messages.SuggestedActionsDisclaimer, { username: userFullName }),
        disabled: isNineBoxDisabled,
        disclaimerIcon,
        hasNineBox,
        nineBoxTitle: formatMessage(evaluationsMessages.EvaluationsNineBoxScore),
        onChange: handleNineBoxChange,
        options: optionsScores,
        placeHolder: formatMessage(evaluationsMessages.EvaluationsNineBoxPlaceholder),
        value: nineBoxScore,
      }}
      suggestedActions={{
        disclaimer: formatMessage(messages.SuggestedActionsDisclaimer, { username: userFullName }),
        disclaimerNineBox: disclaimerNineBox,
        emptySuggestionsText: formatMessage(messages.SuggestedActionsEmptyState),
        onSelectedAction: onSuggestedActionsChange,
        suggeestedActionsIds: suggeestedActionsIds,
        suggestedActions,
        suggestedActionsComment: suggestedActionsComment,
        suggestedActionsPlaceholder: formatMessage(messages.SuggestedActionsPlaceHolder),
        suggestedActionsTextChange: (event) => onSuggestedActionsCommentChange(event.target.value),
      }}
    />
  )
};

SuggestedActions.defaultProps = {
  suggestedActions: [],
  files: [],
};

SuggestedActions.propTypes = {
  backToEvaluationsTeam: PropTypes.func.isRequired,
  params: PropTypes.string,
};

const backToEvaluationsTeam = () => push(getTeamEvaluationsLocation(null));

const mapDispatchToProps = {
  backToEvaluationsTeam,
  getSuggestedActions,
};

const mapSateToProps = (state) => ({
  userInfo: selectuserInfo(state),
  optionsScores: selectOptionsScores(state),
  suggestedActions: selectSuggestedActions(state),
});

export default connect(
  mapSateToProps,
  mapDispatchToProps,
)(injectIntl(SuggestedActions));
