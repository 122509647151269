// @packages
import { takeLatest, call, put } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import { push } from 'react-router-redux';

// @app
import { getNotificationHistoryLocation } from 'betterme-components/routes/urls';

// @own
import { CREARE_TECHNICAL_ASSESSMENT } from './actionTypes';
import { createTechnicalAssessment } from './api';
import {
  setCreateTechnicalAssessmentFail,
  setCreateTechnicalAssessmentSuccess,
} from './actions';

function* createTechnicalAssessmentWorker({ payload }) {
  const {
    evaluationId,
    successMessage,
    technicalAsessmentData,
  } = payload;
  const goToNotifications = () => push(getNotificationHistoryLocation());

  try {
    yield call(createTechnicalAssessment, evaluationId, technicalAsessmentData);
    yield put(setCreateTechnicalAssessmentSuccess());
    yield put(goToNotifications());
    yield put(addToastMessage({ message: successMessage, timeout: 3000 }));
  } catch (error) {
    yield put(setCreateTechnicalAssessmentFail(error));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
};

export default function* technicalAssessmenWatcher() {
  yield takeLatest(CREARE_TECHNICAL_ASSESSMENT, createTechnicalAssessmentWorker);
};
