import {
  GET_PROMOTIONS_REPORT,
  GET_PROMOTIONS_REPORT_FAIL,
  GET_PROMOTIONS_REPORT_SUCCESS,
  RESET_MY_TEAM_FILTERS,
  SET_MY_TEAM_FILTERS,
} from './actionTypes';

export function getPromotionsReport(fileType, from, to) {
  return {
    type: GET_PROMOTIONS_REPORT,
    payload: {
      creationTimeFrom: from,
      creationTimeTo: to,
      fileType,
    },
  };
}

export function getPromotionsReportFail(error) {
  return {
    type: GET_PROMOTIONS_REPORT_FAIL,
    payload: { error },
  };
}

export function getPromotionsReportSuccess(response) {
  return {
    type: GET_PROMOTIONS_REPORT_SUCCESS,
    payload: { response },
  };
}

export function resetMyTeamFilters() {
  return {
    type: RESET_MY_TEAM_FILTERS,
  };
}

export function setMyTeamFilters(filters) {
  return {
    type: SET_MY_TEAM_FILTERS,
    payload: filters
  };
}
