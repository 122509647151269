// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Popup from 'smu-ui-components/Popup';

// @own
import './styles.scss';

const Description = ({
  children,
  className,
  media,
  popup,
  right,
  vertical,
}) => {
  const descriptionClassName = cn('description', className, {
    'description--right': right,
    'description--vertical': vertical,
  });
  const mediaNode = media && (
    <span className="description__media">
      {media}
    </span>
  );

  return (
    <div className={descriptionClassName}>
      {popup ?
        <Popup
          className="description__popup"
          content={popup}
          inverted
          on="hover"
          position="bottom center"
          trigger={mediaNode}
        />
        : mediaNode
      }
      <div className="description__content">
        {children}
      </div>
    </div>
  )
};

Description.defaultProps = {
  right: false,
  vertical: false,
};

Description.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
  media: PropTypes.node,
  popup: PropTypes.bool,
  right: PropTypes.bool,
  vertical: PropTypes.bool,
};

export default Description;
