import { takeLatest, call, put } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import { 
  GET_TEAM_PROMOTIONS_REQUESTS,
} from './actionTypes';
import { 
  getTeamPromotionsRequestsFail, 
  getTeamPromotionsRequestsSuccess, 
} from './actions';
import { getTeamPromotionsRequests } from '../../api';

function* getTeamPromotionsRequestsWorker({ payload }) {
  try {
    const response = yield call(getTeamPromotionsRequests, payload );
    yield put(getTeamPromotionsRequestsSuccess(response.result, response.page));
  } catch(error) {
    yield put(getTeamPromotionsRequestsFail(error));
    yield put(addToastMessage({ message: error.message, timeout: 3000 }));
  }
}

export default function* teamPromotionsTabWatcher() {
  yield takeLatest(GET_TEAM_PROMOTIONS_REQUESTS, getTeamPromotionsRequestsWorker);
}


