// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

// @app
import EmptyStateView from 'common/EmptyStateView';

// @own
import messages from '../messages';
import searchEmptyState from './search_empty_state.svg';

const FeedbackReceivedEmptyState = ({ loading, intl: { formatMessage } }) => {
  return !loading && (
    <EmptyStateView
      image={searchEmptyState}
      text={formatMessage(messages.FeedbackReceivedEmptyStateMessage)}
      title={formatMessage(messages.FeedbackReceivedEmptyStateTitle)}
    />
  );
};

FeedbackReceivedEmptyState.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(FeedbackReceivedEmptyState);
