import {
  UPDATE_SUBMIT_EVALUATION,
  UPDATE_SUBMIT_EVALUATION_FAIL,
  UPDATE_SUBMIT_EVALUATION_SUCCESS,
} from './actionTypes';

const initialState = {
  updateSubmitEvaluation: {},
  updateSubmitEvaluationError: '',
  updateSubmitEvaluationLoading: false,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case UPDATE_SUBMIT_EVALUATION:
      return {
        ...state,
        updateSubmitEvaluationLoading: true,
      };

    case UPDATE_SUBMIT_EVALUATION_FAIL:
      return {
        ...state,
        updateSubmitEvaluationError: payload.error,
        updateSubmitEvaluationLoading: false,
      };

    case UPDATE_SUBMIT_EVALUATION_SUCCESS:
      return {
        ...state,
        updateSubmitEvaluation: payload.response,
        updateSubmitEvaluationLoading: false,
      }

    default:
      return state;
  }
};
