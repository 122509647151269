// @packages
import React, { useEffect, useState } from 'react';
import AvatarMedia from 'smu-ui-components/AvatarMedia';
import Button from 'smu-ui-components/ButtonV2';
import DataTable from 'smu-ui-components/DataTable';
import KebabMenu from 'smu-ui-components/KebabMenu';
import Label from 'smu-ui-components/Label';
import Popup from 'smu-ui-components/Popup';
import PropTypes from 'prop-types';
import Icon from 'smu-ui-components/IconV2';
import Segment from 'smu-ui-components/Segment';
import Link from 'smu-ui-components/Link';
import GenericEmptyState from 'smu-ui-components/GenericEmptyState';
import UserAvatarList from 'smu-ui-components/UserAvatarList';
import cn from 'classnames'
import moment from 'moment';
import { buildImageUrl } from 'smu-utils/image';
import { closeModal, openModal } from 'smu-app-components/RootModal/actions';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { push } from 'react-router-redux';

// @app
import {
  selectCollaboratorsAsMain,
  selectCollaboratorsAsMainAndSecondary,
  selectCollaboratorsAsSecondary,
  selectConfigurations,
} from 'betterme-components/Authorization/selectors';
import { getNewPromotionLocation } from 'betterme-components/routes/urls';
import PositionsHistoryModal from 'betterme-components/PositionsHistoryModal';
import PromotionsHistoryModal from 'betterme-components/PromotionsHistoryModal';
import {
  PROMOTION_REQUEST_PROMOTIONS_HISTORY_MODAL,
  CREATE_PROMOTION_REQUEST_MODAL,
} from 'betterme-components/constants/modalTypes';
import { getProfileLocation } from 'betterme-components/routes/urls';
import {
  APPLIED,
  ASSIGN_MANAGER,
  DECLINED_BY_LEADER,
  DECLINED_BY_MANAGER,
  DRAFT,
  LEADER_REVIEW,
  MAIN_LEADER,
  MANAGER_REVIEW,
  NOT_READY,
  NOT_READY_FOR_PROMO,
  NOT_REQUESTED,
  PAGE_SIZE,
  PENDING,
  POTENTIALLY_READY,
  READINESS_INFO_LINK,
  READY,
  REQUESTED_SECONDARY_PROMOTION,
  SECONDARY_LEADER,
  TALENT_REVIEW,
  PARTIALLY_READY,
  WITHOUT_NEXT_SENIORITY,
} from 'betterme-components/constants/promotions';
import { commonMessages, promotionsMessages } from 'common/messages';
import { getTeamPromotionsRequests } from '../actions';
import {
  selectLoadingPromotionsRequests,
  selectPromotionsRequests,
  selectPagination,
} from '../selectors';
import PromotionsAvatar from 'components/molecules/PromotionsAvatar';

// @own
import './styles.scss';
import RequestPromotionModal from 'betterme-components/components/containers/RequestPromotionModal';
import messages from '../../messages';

const PromotionsTable = ({
  allowCallIntegrationTrafficLightUgpService,
  collaboratorsAsMain,
  collaboratorsAsMainAndSecondary,
  collaboratorsAsSecondary,
  getTeamPromotionsRequests,
  intl: { formatMessage },
  loadingPromotionsRequests,
  myTeamFilters,
  newStyle,
  openModal,
  page,
  promotionsRequests,
  push,
  user
}) => {

  const [fields, setFields] = useState([]);

  useEffect(() => {
    handlerGetTeamPromotionsRequests(page.number);
  }, [myTeamFilters]);

  const statusLabels = {
    [APPLIED]: {
      label: promotionsMessages.PromotionsStatusApplied,
      color: "tag-success",
    },
    [ASSIGN_MANAGER]: {
      label: promotionsMessages.PromotionsAssignValidator,
      color: "tag-status-promotion",
    },
    [DECLINED_BY_LEADER]: {
      label: promotionsMessages.PromotionsStatusDeclinedByLeader,
      color: "tag-status-promotion",
    },
    [DECLINED_BY_MANAGER]: {
      label: promotionsMessages.PromotionsStatusDeclinedByValidator,
      color: "tag-status-promotion",
    },
    [DRAFT]: {
      label: promotionsMessages.PromotionsStatusDraft,
      color: "tag-status-promotion",
    },
    [LEADER_REVIEW]: {
      label: promotionsMessages.PromotionsStatusLeaderReview,
      color: "tag-status-promotion",
    },
    [MANAGER_REVIEW]: {
      label: promotionsMessages.PromotionsStatusValidatorReview,
      color: "tag-status-promotion",
    },
    [NOT_REQUESTED]: {
      label: promotionsMessages.PromotionsNotRequested,
      color: "light-grey",
    },
    [PENDING]: {
      label: promotionsMessages.PromotionsStatusPending,
      color: "tag-status-promotion",
    },
    [TALENT_REVIEW]: {
      label: promotionsMessages.PromotionsStatusTalentReview,
      color: "tag-status-promotion",
    },
  };

  const nonBlockerStatus = [
    DECLINED_BY_LEADER,
    DECLINED_BY_MANAGER,
    NOT_REQUESTED,
  ];

  const inProgressStatus = [
    ASSIGN_MANAGER,
    LEADER_REVIEW,
    MANAGER_REVIEW,
    TALENT_REVIEW,
  ];

  const disableRequestButtonMessage = {
    [WITHOUT_NEXT_SENIORITY]: formatMessage(promotionsMessages.PromotionsNoMoreSeniority),
    [REQUESTED_SECONDARY_PROMOTION]: formatMessage(promotionsMessages.PromotionsRequestedSecondaryPromotion),
    [NOT_READY_FOR_PROMO]: formatMessage(promotionsMessages.PromotionsNotReady),
  };

  function handlePageChange(pageNumber) {
    handlerGetTeamPromotionsRequests(pageNumber);
  };

  function openHistoryModal(collaborator, modalType) {
    openModal({
      modalType,
      modalProps: { collaborator },
    });
  };

  function handlerGetTeamPromotionsRequests(page) {
    const { requestStatus, actions } = myTeamFilters;
    getTeamPromotionsRequests(page, requestStatus, actions);
  };

  function handleSuggestPromotion(identification, hasOneLeader) {
    if (hasOneLeader) {
      openModal({
        modalType: CREATE_PROMOTION_REQUEST_MODAL,
        modalProps: {
          onAccept: () => push(getNewPromotionLocation(identification))
        }
      })
    } else {
      push(getNewPromotionLocation(identification));
    }
  };

  function getButtonNode({ disable, onClick, label }) {
    return (
      <span>
        <Button
          color="unifyBlue"
          className="!h-auto !py-0 min-h-[22px]"
          disabled={disable}
          enabledUnification
          onClick={onClick}
        >
          {label}
        </Button>
      </span>
    )
  };

  function isCollaboratorAsMain(collaboratorId) {
    return collaboratorsAsMain.some((collaborator) =>
      collaborator.id === collaboratorId
    )
  };

  function isCollaboratorAsSecondary(collaboratorId) {
    return collaboratorsAsSecondary.some((collaborator) =>
      collaborator.id === collaboratorId
    )
  };

  function isCollaboratorAsMainAndSecondary(collaboratorId) {
    return collaboratorsAsMainAndSecondary.some((collaborator) =>
      collaborator.id === collaboratorId
    )
  };

  function requestersList(requesters) {
    const requesterFirstName = user?.id === requesters[0]?.id ? formatMessage(commonMessages.Me) : requesters[0]?.firstName;
    const requesterLastName = user?.id !== requesters[0]?.id ? requesters[0]?.lastName : null;

    return ((requesters.length > 1)
      ? <UserAvatarList
        className="promotions-table__requesters"
        maxDisplay={4}
        size={40}
        users={requesters}
        enablePopup
      />
      : <AvatarMedia
        className="promotions-table__requester"
        {...requesters[0]}
        useLink={false}
        noJob={true}
        showPopup
        user={requesters[0]}
        firstName={requesterFirstName}
        lastName={requesterLastName}
      />
    )
  };

  const Component = ({ item }) => (
    <span
      className="text-black hover:text-black"
    >
      {item.content}
    </span>
  );

  const avatarImage = (user) => buildImageUrl({
    code: user?.profileImageCode,
    height: 40,
    width: 40,
  });

  const avatarInfo = (user) => [
    {
      className: 'shark truncate ... w-200 text-base',
      label: `${user?.firstName} ${user?.lastName}`,
      variant: 'title2',
      linkTo: () => getProfileLocation(user.identification),
    },
    {
      className: 'font-roboto text-sm',
      label: user?.seniority?.name,
      variant: 'body2',
    },
    {
      className: 'font-roboto text-sm',
      label: user?.seniority?.startDate
        && moment(user?.seniority?.startDate).toNow(true),
      variant: 'body2',
    },
  ];

  const activeFields = [
    {
      customView: ({ promotionFor }) => (
        <PromotionsAvatar
          avatarInfo={avatarInfo(promotionFor)}
          className="promotions-table__promotion-for"
          src={avatarImage(promotionFor)}
        />
      ),
      fixedWidth: '25%',
      headerClassName: 'promotions-table__header',
      name: 'promotionFor',
      viewName: formatMessage(promotionsMessages.PromotionsGlober),
    },
    {
      customView: ({ promotionStatus, promotion }) => {
        const textStatusLabel = formatMessage(statusLabels[promotionStatus].label);
        const textStatusColor = statusLabels[promotionStatus].color;
        const textStatusDate = promotionStatus === 'APPLIED' ?
          <span className="block font-roboto text-slateGray text-xs italic my-1">
            {formatMessage(promotionsMessages.PromotionsAppliedSeniorityDate,
              { count: moment().diff(promotion?.appliedSeniorityDate, 'days') })}
          </span>
          : undefined;

        return (
          <div>
            <Label
              className={cn({ "promotions-table__label": newStyle })}
              color={textStatusColor}
              size="small"
              text={textStatusLabel}
            />
            {textStatusDate}
          </div>
        );
      },
      fixedWidth: '15%',
      headerClassName: 'promotions-table__header',
      name: 'promotionStatus',
      viewName: formatMessage(promotionsMessages.PromotionsRequestStatus),
    },
    {
      customView: ({ promotion }) => {
        const requesters = promotion && requestersList(promotion.requesters);
        return (requesters);
      },
      fixedWidth: '20%',
      headerClassName: 'promotions-table__header',
      name: 'requestedBy',
      viewName: formatMessage(promotionsMessages.PromotionsRequestedBy),
    },
    {
      customView: ({
        allowsRequestPromotion,
        deniedRequestPromotionReason,
        managerOrSecondaryLeaderLeft,
        promotion,
        promotionFor,
        promotionStatus,
        requestPromotionOwner,
        trafficLight,
        requireLeadersReviewByTalent,
      }) => {
        const userFullName = promotionFor ? `${promotionFor?.firstName} ${promotionFor?.lastName}` : '';
        const requesterType = promotion?.requesterType;
        const collaboratorId = promotionFor.id;
        const isValidatorReviewStatus = promotionStatus === MANAGER_REVIEW;
        const isAssignValidatorStatus = promotionStatus === ASSIGN_MANAGER;
        const isLeaderReviewStatus = promotionStatus === LEADER_REVIEW;
        const isWithoutNextSeniority = deniedRequestPromotionReason === WITHOUT_NEXT_SENIORITY;
        const isRequesterTypeMainLeader = requesterType === MAIN_LEADER;
        const isRequesterTypeSecondaryLeader = requesterType === SECONDARY_LEADER;
        const isInProgressStatus = inProgressStatus.some((status) => status === promotionStatus);
        const isNonBlockerStatus = nonBlockerStatus.some((status) => status === promotionStatus);
        const isNotReady = allowCallIntegrationTrafficLightUgpService
          && (trafficLight.readiness === NOT_READY || trafficLight.readiness === null);
        const hasOneLeader = requireLeadersReviewByTalent && allowsRequestPromotion;

        // Show Request Button
        const showRequestPromotionSecondaryPromotion = !requestPromotionOwner
          && isRequesterTypeSecondaryLeader
          && (isInProgressStatus && !isAssignValidatorStatus)
          && (isCollaboratorAsSecondary(collaboratorId) || isCollaboratorAsMainAndSecondary(collaboratorId))
          && !managerOrSecondaryLeaderLeft;
        const showRequestPromotion = (allowsRequestPromotion && isNonBlockerStatus)
          || (isNotReady && isNonBlockerStatus)
          || isWithoutNextSeniority
          || showRequestPromotionSecondaryPromotion;

        // Show Review Button
        const needApprovalBySecondaryLeader = isRequesterTypeMainLeader;
        const needApprovalByMainLeader = isRequesterTypeSecondaryLeader
          && isCollaboratorAsMain(collaboratorId);
        const showReviewPromotionInLeaderReview = isLeaderReviewStatus
          && (!requestPromotionOwner || allowsRequestPromotion)
          && (needApprovalBySecondaryLeader || needApprovalByMainLeader);
        const showReviewPromotionInValidatorReview = isValidatorReviewStatus
          && !requestPromotionOwner
          && needApprovalBySecondaryLeader;
        const showReviewPromotion = showReviewPromotionInLeaderReview;

        // Show Assign Validator
        const showAssignValidatorPromotion = isAssignValidatorStatus
          && (managerOrSecondaryLeaderLeft || allowsRequestPromotion)
          && (isCollaboratorAsSecondary(collaboratorId) || isCollaboratorAsMainAndSecondary(collaboratorId));

        // Disable Request Button
        const disableRequestButton = (!allowsRequestPromotion
          || showRequestPromotionSecondaryPromotion
          || isNotReady)
          && !managerOrSecondaryLeaderLeft;
        let disableRequestButtonText;
        if (disableRequestButton) {
          if (isWithoutNextSeniority) {
            disableRequestButtonText = disableRequestButtonMessage[WITHOUT_NEXT_SENIORITY];
          } else if (showRequestPromotionSecondaryPromotion) {
            disableRequestButtonText = disableRequestButtonMessage[REQUESTED_SECONDARY_PROMOTION];
          } else if (isNotReady) {
            disableRequestButtonText = disableRequestButtonMessage[NOT_READY_FOR_PROMO];
          }
        }
        // Buttons Config
        const configRequestPromotion = {
          disable: disableRequestButton,
          label: formatMessage(promotionsMessages.PromotionsRequest),
          onClick: () => handleSuggestPromotion(promotionFor.identification, hasOneLeader),
        };
        const configAssignValidatorPromotion = {
          disable: !isAssignValidatorStatus,
          label: formatMessage(promotionsMessages.PromotionsAssignValidator),
          onClick: () => handleSuggestPromotion(promotionFor.identification),
        };
        const configReviewPromotion = {
          disable: !isLeaderReviewStatus,
          label: formatMessage(promotionsMessages.PromotionsReview),
          onClick: () => handleSuggestPromotion(promotionFor.identification),
        };

        const requestPromotion = getButtonNode(configRequestPromotion);
        const assignValidatorPromotion = getButtonNode(configAssignValidatorPromotion);
        const reviewPromotion = getButtonNode(configReviewPromotion);

        return (
          <div className="promotions-table__actions">
            <div className={cn('promotions-table__buttons', {})}>
              {showRequestPromotion && (
                disableRequestButton ? (
                  <Popup
                    className="promotions-table__tooltip"
                    eventsEnabled
                    position="center bottom"
                    size="small"
                    suiteInverted
                    trigger={requestPromotion}
                  >
                    {disableRequestButtonText}
                  </Popup>
                ) : (
                  requestPromotion
                )
              )}
              {showReviewPromotion && (
                reviewPromotion
              )}
              {showAssignValidatorPromotion && (
                <div className="flex items-center">
                  <div className="flex items-center">
                    {managerOrSecondaryLeaderLeft &&
                      <Popup
                        hideOnScroll
                        inverted
                        position="bottom center"
                        trigger={
                          <span>
                            <Icon
                              className="mx-2 mb-1 text-orange-300"
                              icon="alert-fill"
                              size="small"
                            />
                          </span>
                        }
                      >
                        <span className="font-roboto text-xs">
                          {formatMessage(promotionsMessages.PromotionsWarningLeaderLeft)}
                        </span>
                      </Popup>
                    }
                  </div>
                  {assignValidatorPromotion}
                </div>
              )}
            </div>
            <div className="promotions-table__actions-dots-menu">
              <KebabMenu
                className="ml-2 mr-2"
                component={Component}
                items={[
                  {
                    content: formatMessage(commonMessages.Historic),
                    onClick: () => openHistoryModal(promotionFor, PROMOTION_REQUEST_PROMOTIONS_HISTORY_MODAL),
                  },
                ]}
                placement="left-start"
              />
            </div>
            <RequestPromotionModal collaboratorFullName={userFullName} />
          </div>
        );
      },
      headerClassName: 'promotions-table__header',
      name: 'actions',
      viewName: formatMessage(commonMessages.Actions),
    }
  ];


  useEffect(() => {
    if (allowCallIntegrationTrafficLightUgpService) {
      const infoIcon =
        (
          <Icon
            className="text-indigo self-center ml-[5px] cursor-pointer"
            icon="info"
            size={14}
          />
        );
      const readinessColumn = {
        customView: (promotion) => {
          const textStatusLabel = promotion.trafficLight.readiness;
          let textStatusColor;
          switch (promotion.trafficLight.readiness) {
            case PARTIALLY_READY:
              textStatusColor = "readiness-partially"
              break;
            case POTENTIALLY_READY:
              textStatusColor = "readiness-potentially"
              break;
            case READY:
              textStatusColor = "readiness-ready"
              break;
            default:
              break;
          }
          return (
            <div>
              {textStatusLabel ?
                <Label
                  className="promotions-table__label"
                  color={textStatusColor}
                  size="small"
                  text={textStatusLabel}
                />
                : null}
            </div>
          );
        },
        fixedWidth: '15%',
        headerClassName: 'promotions-table__header',
        name: 'promotionsReadiness',
        viewName: <div className="flex">
          {formatMessage(promotionsMessages.PromotionsReadiness)}
          <Popup
            eventsEnabled
            position="top right"
            size="small"
            suiteInverted
            trigger={infoIcon}
          >
            <span className="font-bold">
              {formatMessage(promotionsMessages.PromotionsReadyToPromoteTitle)}
            </span>
            <br />
            {formatMessage(promotionsMessages.PromotionsReadyToPromote)}
            <Link className="underline" target="_blank" href={READINESS_INFO_LINK}>
              <br />
              <span>
                {formatMessage(commonMessages.MoreInfoHere)}
              </span>
            </Link>
          </Popup>
        </div>,
      };
      activeFields.splice(1, 0, readinessColumn);
    };
    setFields(activeFields);
  }, []);

  return (
    <Segment loading={loadingPromotionsRequests} loadingType="betterme">
      {
        promotionsRequests.length ? (
          <DataTable
            className="promotions-table"
            data={promotionsRequests}
            datacellClassName="promotions-table__cell"
            datahighlightedClassname="promotions-table__row--active"
            datarowClassname="promotions-table__row"
            enableCheckbox={false}
            fields={fields}
            newStyle
            onPageChange={handlePageChange}
            pageNumber={page.number + 1}
            pageSize={PAGE_SIZE}
            showFirstLast
            totalItems={page.totalElements}
          />
        ) :
          (
            <GenericEmptyState
              className="px-5 py-20  bg-white h-[calc(100vh-220px)]"
              description={formatMessage(messages.TeamDashboardTableEmptyStateDescription)}
              image="listCheck"
              limitTextWidth={false}
              size="large"
              title={formatMessage(messages.TeamDashboardTableEmptyStateTitle)}
            />
          )
      }
      <PositionsHistoryModal />
      <PromotionsHistoryModal
        closeModal={closeModal}
        push={push}
        statusLabels={statusLabels}
      />
    </Segment>
  );
};

PromotionsTable.propTypes = {
  allowCallIntegrationTrafficLightUgpService: PropTypes.bool,
  collaboratorsAsMain: PropTypes.array,
  collaboratorsAsSecondary: PropTypes.array,
  loadingPromotionsRequests: PropTypes.bool.isRequired,
  myTeamFilters: PropTypes.object.isRequired,
  newStyle: PropTypes.bool,
  page: PropTypes.object,
  promotionsRequests: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  allowCallIntegrationTrafficLightUgpService: selectConfigurations('allowCallIntegrationTrafficLightUgpService')(state),
  collaboratorsAsMain: selectCollaboratorsAsMain(state),
  collaboratorsAsMainAndSecondary: selectCollaboratorsAsMainAndSecondary(state),
  collaboratorsAsSecondary: selectCollaboratorsAsSecondary(state),
  loadingPromotionsRequests: selectLoadingPromotionsRequests(state),
  page: selectPagination(state),
  promotionsRequests: selectPromotionsRequests(state),
});

export default connect(mapStateToProps, {
  getTeamPromotionsRequests,
  openModal,
  push,
})(injectIntl(PromotionsTable));
