// @packages
import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import { orderBy } from 'lodash';
import Icon from 'smu-ui-components/Icon';

// @own
import './styles.scss';
import RatingFeedbacksList from 'components/molecules/RatingFeedbacksList';
import RatingsFilter from 'components/molecules/RatingsFilter';

const handleFilterChange = (add, remove, id, selected) => {
  if (!selected.includes(id)) {
    add(id, true);
  } else if (selected.length > 1) {
    remove(id, true);
  }
};

const CompetencyDetails = ({
  addRatingFilter,
  className,
  messages: { showing },
  name,
  onBackClick,
  ratingFilter,
  ratings,
  ratingsFilterConfig,
  removeRatingFilter,
  total,
}) => {

  return (
    <div className={cn('competency-details', className)}>
      <div className="competency-details__header">
        <div className="competency-details__name-wrapper">
          <Icon
            className="competency-details__back-icon"
            icon="angle-left"
            size="medium"
            onClick={onBackClick}
          />
          <div className="competency-details__name">{name}</div>
        </div>
        <div className="competency-details__filter-wrapper">
          <div className="competency-details__filter-text">
            {showing}
          </div>
            <RatingsFilter
              className="competency-details__ratings-filter"
              config={ratingsFilterConfig}
              onChange={(id) =>
                handleFilterChange(addRatingFilter, removeRatingFilter, id, ratingFilter)
              }
              values={ratingFilter}
            />
        </div>
      </div>
      {orderBy(ratings, 'ordinality', 'desc').map(ratingWithFeedback => {
        const { color, feedback: feedbacks, id, name: ratingName, count } = ratingWithFeedback;

        return (<RatingFeedbacksList
          className="competency-details__list"
          color={color}
          count={count}
          feedbacks={feedbacks}
          key={id}
          name={ratingName}
          total={total}
        />);
      })}
    </div>
  );
};

CompetencyDetails.defaultProps = {
  ratings: [],
  ratingsFilters: [],
};

CompetencyDetails.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  ratings: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.string,
    count: PropTypes.number,
    feedbacks: PropTypes.array,
    name: PropTypes.string,
  })),
  ratingsFilters: PropTypes.array,
  total: PropTypes.number.isRequired,
};

export default CompetencyDetails;
