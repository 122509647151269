import { takeLatest, call, put } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';

import {
  CREATE_ANSWER_PERFORMANCE_RETROSPECTIVE,
  GET_PERFORMANCE_RETROSPECTIVE_DETAIL,
} from './actionTypes';
import {
  createAnswerPerformanceRetrospectiveFail,
  createAnswerPerformanceRetrospectiveSuccess,
  getPerformanceRetrospectiveDetail as getPerformanceRetrospectiveDetailAction,
  getPerformanceRetrospectiveDetailFail,
  getPerformanceRetrospectiveDetailSuccess,
} from './actions';
import {
  createAnswerPerformanceRetrospective,
  getPerformanceRetrospectiveDetail,
} from './api';

function* getPerformanceRetrospectiveDetailWorker({ payload }) {
  const { evaluationId } = payload;
  try {
    const response = yield call(getPerformanceRetrospectiveDetail, evaluationId);
    yield put(getPerformanceRetrospectiveDetailSuccess(response));
  } catch (error) {
    yield put(getPerformanceRetrospectiveDetailFail(error.message));
    yield put(addToastMessage({ message: error.message, timeout: 3000 }));
  };
};

function* createAnswerPerformanceRetrospectiveWorker({ payload }) {
  const { evaluationId, data } = payload;
  try {
    const response = yield call(createAnswerPerformanceRetrospective, evaluationId, data);
    yield put(createAnswerPerformanceRetrospectiveSuccess(response));
    yield put(getPerformanceRetrospectiveDetailAction(evaluationId));
  } catch (error) {
    yield put(createAnswerPerformanceRetrospectiveFail(error.message));
    yield put(addToastMessage({ message: error.message, timeout: 3000 }));
  }
}


export default function* performanceRetrospectiveDetailWatcher() {
  yield takeLatest(CREATE_ANSWER_PERFORMANCE_RETROSPECTIVE, createAnswerPerformanceRetrospectiveWorker);
  yield takeLatest(GET_PERFORMANCE_RETROSPECTIVE_DETAIL, getPerformanceRetrospectiveDetailWorker);
};
