import {
  RESET_SEND_FEEDBACK
} from './actionTypes';

const initialState = {
  requestFeedback: {},
  fetching: false,
  isFetched: false,
  error: null
};

export default function reducer(state = initialState, { type }) {
  switch (type) {
    case RESET_SEND_FEEDBACK:
      return {
        ...state,
        isFetched: false
      };
    default:
      return state;
  }
}
