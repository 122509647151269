import { takeLatest, call, put } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import {
  GET_SUGGESTED_ACTIONS_LIST,
} from './actionTypes';
import {
  getSuggestedActionsList,
} from '../api';
import {
  getSuggestedActionsListFail,
  getSuggestedActionsListSuccess,
} from './actions';

function* getSuggestedActionsListWorker({ payload }) {
  try {
    const response = yield call(getSuggestedActionsList, payload);
    yield put(getSuggestedActionsListSuccess(response));
  } catch (error) {
    yield put(getSuggestedActionsListFail(error));
    yield put(addToastMessage({ message: error.message, timeout: 3000 }));
  }
}

export default function* suggestActionsWatcher() {
  yield takeLatest(GET_SUGGESTED_ACTIONS_LIST, getSuggestedActionsListWorker);
}
