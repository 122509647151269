// @packages
import React from 'react';
import PropTypes from 'prop-types';
import Segment from 'smu-ui-components/Segment';
import cn from 'classnames';

// @own
import './styles.scss';

const ContentSection = ({
  children,
  className,
  loading,
}) => (
  <Segment
    className={cn('content-section', className)}
    loading={loading}
    loadingType="betterme"
  >
    <div className="content-section__content">
      {children}
    </div>
  </Segment>
);

ContentSection.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool,
};

export default ContentSection;
