// @packages
import Box from 'smu-app-components/TalentMatrix/Calibration/Box';
import Button from 'smu-ui-components/ButtonV2';
import Calibrate from 'smu-app-components/TalentMatrix/Calibration/Calibrate';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import UserInMatrixModal from 'smu-app-components/TalentMatrix/UserInMatrix/Modal';
import useModal from 'smu-custom-hooks/useModal';
import { injectIntl } from 'react-intl';

// @app
import CommentBox from 'components/molecules/CommentBox';

// @own
import messages from './messages';

function TalentMatrix({
  className,
  evaluee,
  intl: { formatMessage },
  onChange,
  readOnly,
  value,
}) {
  const modal = useModal();

  const handleOpenModal = useCallback(
    (currentBox) => {
      modal.open({
        selectedBox: {
          box: currentBox?.box,
          description: currentBox?.description,
          id: currentBox?.id,
          user: {
            fullName: `${evaluee?.firstName} ${evaluee?.lastName || ''}`.trim(),
            id: evaluee?.id,
            job: evaluee?.job,
            profileImageCode: evaluee?.profileImageCode,
          },
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [evaluee]
  );

  const renderAction = useCallback(
    (currentBox) => {
      return (
        <Button
          className="!text-blue"
          enabledUnification
          onClick={() => handleOpenModal(currentBox)}
          variant="link"
        >
          {formatMessage(messages.viewMore)}
        </Button>
      );
    },
    [handleOpenModal, formatMessage]
  );

  return (
    <CommentBox
      className={className}
      contentPage
      title={formatMessage(messages.title)}
    >
      <div className="p-6 space-y-4">
        <Calibrate readOnly={readOnly} onChange={onChange} value={value} />
        <Box
          horizontal={false}
          messageProps={{
            type: 'info',
          }}
          renderAction={renderAction}
          value={value}
        />
      </div>
      <UserInMatrixModal
        onClose={modal.close}
        open={modal.isOpen}
        {...modal.data}
      />
    </CommentBox>
  );
}

TalentMatrix.propTypes = {
  className: PropTypes.string,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  value: PropTypes.array,
  evaluee: PropTypes.shape({
    firstName: PropTypes.string,
    id: PropTypes.string,
    job: PropTypes.string,
    lastName: PropTypes.string,
    profileImageCode: PropTypes.string,
  }),
};

TalentMatrix.defaultProps = {
  className: '',
  readOnly: false,
  value: [],
};

export default injectIntl(TalentMatrix);
