// @packages
import cn from 'classnames';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import RootModal from 'smu-app-components/RootModal';

// @app
import ConfirmationModal, {
  centerModalClass,
  modalStyles,
} from 'betterme-components/ConfirmationModal';
import { SKILL_SELECTOR_MODAL } from 'betterme-components/constants/modalTypes';
import { actionsMessages } from 'common/messages';

// @own
import './styles.scss';
import * as actions from './actions';
import messages from './messages';
import SkillSelector from './SkillSelector';
import { selectSelectedSkills, selectSkills } from './selectors';
import {
  trackPopUpAdd,
  trackPopUpCancel,
} from './analytics';

class SkillSelectorModal extends Component {
  constructor() {
    super();

    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleInputChange = debounce(this.handleInputChange.bind(this), 300);
    this.handleSkillSelect = this.handleSkillSelect.bind(this);
    this.handleSkillClick = this.handleSkillClick.bind(this);
  }

  componentDidMount() {
    const { initSelectedSkills, initialSkills } = this.props;
    initSelectedSkills(initialSkills);
  }

  componentWillUnmount() {
    const { cleanSkillState, onModalClose } = this.props;

    cleanSkillState();
    onModalClose();
  }

  handleAddClick() {
    const {
      close,
      onAdd,
      selectedSkills,
    } = this.props;
    const skillsId = selectedSkills?.map(skill => skill?.id).toString();

    onAdd(selectedSkills);
    trackPopUpAdd(skillsId);
    close();
  }

  handleClose() {
    const {
      close,
      onCancel,
    } = this.props;

    onCancel();
    trackPopUpCancel();
    close();
  }

  handleInputChange(value) {
    const { getSkills, selectedSkills } = this.props;
    const trimmedValue = value.trim();
    if (trimmedValue) {
      getSkills(value, selectedSkills);
    }
  }

  handleSkillSelect(skillId) {
    const { skills, selectSkill } = this.props;
    const selectedSkill = skills.find(skill => skill.id === skillId);

    if (selectedSkill) {
      selectSkill(selectedSkill);
    }
  }

  handleSkillClick(skillId) {
    const { deleteSkill, onSkillClick } = this.props;

    deleteSkill(skillId);
    onSkillClick({ id: skillId });
  }

  render() {
    const {
      initialSkills,
      intl: { formatMessage },
      selectedSkills,
      isEvaluation,
      skills,
    } = this.props;
    const disableAdd = (!initialSkills.length && !selectedSkills.length);

    return (
      <ConfirmationModal
        acceptMessage={formatMessage(messages.SkillSelectorModalAdd)}
        cancelMessage={formatMessage(actionsMessages.ActionsCancel)}
        disableAccept={disableAdd}
        onAccept={this.handleAddClick}
        onCancel={this.handleClose}
        title={formatMessage(messages.SkillSelectorAdd)}
      >
        <SkillSelector
          className={cn('skill-selector-modal__selector', {
            'skill-selector-modal__selector--empty': (selectedSkills.length === 0)
          })}
          onInputChange={this.handleInputChange}
          onSelect={this.handleSkillSelect}
          onSkillClick={this.handleSkillClick}
          selectedSkills={selectedSkills}
          isEvaluation={isEvaluation}
          skills={skills}
        />
      </ConfirmationModal>
    );
  }
}

SkillSelectorModal.defaultProps = {
  initialSkills: [],
  onAdd: () => undefined,
  onCancel: () => undefined,
  onModalClose: () => undefined,
  onSkillClick: () => undefined,
};

SkillSelectorModal.propTypes = {
  cleanSkillList: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  deleteSkill: PropTypes.func.isRequired,
  getSkills: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  onAdd: PropTypes.func,
  onCancel: PropTypes.func,
  selectSkill: PropTypes.func.isRequired,
  selectedSkills: PropTypes.array,
  isEvaluation: PropTypes.bool,
  skills: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    selectedSkills: selectSelectedSkills(state),
    skills: selectSkills(state),
  };
};

export default RootModal(
  connect(mapStateToProps, actions)(injectIntl(SkillSelectorModal)),
  SKILL_SELECTOR_MODAL,
  modalStyles,
  centerModalClass,
);
