// @packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from 'smu-ui-components/Tabs';
import { base64Encode } from 'smu-utils/base64';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import { push } from 'react-router-redux';
import { useConfigurations } from 'smu-services/os-member/api/v1/community/preferences/configurations';

// @app
import Assessment360WidgetManager from 'betterme-components/Assessment360/WidgetManager';
import Dashboard from 'betterme-components/Dashboard';
import FeedbackForLeadersReport from 'betterme-components/MyDashboard/FeedbackForLeadersReport';
import FeedbackForLeadersResults from 'betterme-components/Dashboard/FeedbackForLeadersResults';
import getMessageType from 'utils/getMessageType';
import { feedbackMessages } from 'common/messages';
import { getProfileFeedbackForLEadersReceivedLocation } from 'betterme-components/routes/urls';
import { selectAllCollaborators } from 'betterme-components/Authorization/selectors';
import { selectConfigurations, selectCommunityId } from 'betterme-components/Authorization/selectors';
import {
  selectFeedbackForLeaders,
  selectLoadingFeedbackForLeaders,
} from 'betterme-components/Dashboard/selectors';

// @own
import AccessDeniedEmptyState from '../AccessDeniedEmptyState';
import messages from '../messages';
import {
  trackBoxOpportunitySendFeedback,
  trackBoxOpportunitySendFeedbackEmpty,
  trackBoxStrengthsSendFeedbackEmpty,
} from '../analytics';

const ProfileDashboard = ({
  allowsFeedbackForLeaders,
  allowsFeedbackForLeadersResult,
  allowsPerformanceDashboardSecondTab,
  allowsPotentialAssessmentResult,
  allowsSuggestions,
  collaborators,
  communityId,
  feedbackForLeaders,
  goToSendFeedback,
  intl: { formatMessage },
  loadingFeebackForLeaders,
  location,
  params: { userid },
  user,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const useConfigurationsHook = useConfigurations();
  const assessment360IsEnabled = useConfigurationsHook.selectEnabledAssessment360();
  const oldFFLIsEnabled = useConfigurationsHook.selectEnabledOldFfl();
  const {
    access: {
      evaluations,
      feedback,
      feedbackForLeadersReceived,
      goals,
      potentialAssessmentResult,
      suggestions,
    },
    firstName: userFirstName,
    hasCollaborators: userHasCollaborator,
    identification: userIdentification,
  } = user;
  const getMessage = getMessageType(communityId);
  const emptyStateUserReceivedTitle = getMessage('EvaluationsEmptyStateUserReceivedTitle');
  const hasAccess = feedback || goals || evaluations || suggestions || feedbackForLeadersReceived;
  const messagesParams = { username: userFirstName };
  const isMyCollaborator = collaborators.some((collaborator) => collaborator.identification === user.identification);
  const showPotentialAssessmentResults = allowsPotentialAssessmentResult && potentialAssessmentResult;
  const activeTabsOnDashboard = allowsPerformanceDashboardSecondTab && isMyCollaborator;
  const tabs = [
    {
      name: "Talent Summary",
      onClick: () => setActiveTab(0),
    },
    {
      name: "Detail",
      onClick: () => setActiveTab(1),
    }
  ];

  const linkDescriptor = {
    pathname: `/newfeedback/${base64Encode(userIdentification)}`,
    state: { defaultRequest: false, user },
  };
  const baseLinkConfig = {
    label: formatMessage(feedbackMessages.FeedbackSendFeedback),
    to: linkDescriptor,
  };
  const strengthBoxEmpty = {
    info: formatMessage(messages.FeedbackProfileFeedbackReceivedMain, messagesParams),
    link: {
      ...baseLinkConfig,
      onClick: trackBoxStrengthsSendFeedbackEmpty,
    },
  };
  const opportunitiesBoxEmpty = {
    info: formatMessage(messages.FeedbackProfileFeedbackReceivedMain, messagesParams),
    link: {
      ...baseLinkConfig,
      onClick: trackBoxOpportunitySendFeedbackEmpty,
    },
  };
  const opportunitiesLink = {
    ...baseLinkConfig,
    onClick: trackBoxOpportunitySendFeedback,
  };


  // TODO: Add URLs in /routes/urls
  const detailsPath = (state) => ({
    evaluationDetail: `/profile/${userid}/evaluations`,
    feedbackReceived: { pathname: `${location.pathname}/feedbackreceived`, state },
    goals: { pathname: `${location.pathname}/assignedgoals`, state },
    potentialAssessmentResults: { pathname: `${location.pathname}/potential-assessment-results`, state },
  });

  const feedbackForLeadersReceivedUrl = (half) => (
    getProfileFeedbackForLEadersReceivedLocation(userIdentification, half)
  );

  const messagesEmptyStates = {
    feedbackReceived: {
      main: formatMessage(messages.FeedbackProfileFeedbackReceivedMain, messagesParams),
      suggestion: formatMessage(feedbackMessages.FeedbackSendFeedback),
    },
    goalsAssigned: {
      main: formatMessage(messages.FeedbackProfileGoalsAssignedMain, messagesParams),
    },
    lastEvaluation: {
      main: formatMessage(emptyStateUserReceivedTitle, messagesParams),
    },
  };

  function getFeedbackForLeadersComponent() {
    let component = '';

    if (feedbackForLeadersReceived) {
      if (allowsFeedbackForLeadersResult && (
        userHasCollaborator || !isEmpty(feedbackForLeaders)
      )) {
        component = (
          <FeedbackForLeadersResults
            {...feedbackForLeaders}
            goToFeedbackReceived={feedbackForLeadersReceivedUrl}
            loading={loadingFeebackForLeaders}
            user={user}
          />
        );
      } else if (allowsFeedbackForLeaders) {
        component = (
          <FeedbackForLeadersReport
            hasCollaborators={userHasCollaborator}
            user={user}
          />
        );
      }
    }
    return component;
  };

  useEffect(() => {
    setActiveTab(0)
  }, [user]);


  return hasAccess ? (
    <>
      {allowsPerformanceDashboardSecondTab &&
        <Tabs
          active={activeTab}
          className="mb-3"
          tabs={tabs}
        />
      }
      {activeTabsOnDashboard
        ? activeTab === 1
          ? (
            <>
              {oldFFLIsEnabled && getFeedbackForLeadersComponent()}
              {assessment360IsEnabled && (
                <Assessment360WidgetManager className="mb-4" userId={user.id} />
              )}
            </>
          ) : null
        : (
          <>
            {oldFFLIsEnabled && getFeedbackForLeadersComponent()}
            {assessment360IsEnabled && (
              <Assessment360WidgetManager className="mb-4" userId={user.id} />
            )}
          </>
        )
      }
      <Dashboard
        activeTab={activeTab}
        activeTabsOnDashboard={activeTabsOnDashboard}
        detailsPath={detailsPath}
        feedbackReceivedLink={linkDescriptor}
        goToFeedback={() => goToSendFeedback(user)}
        goToMyFeedbackReceived={feedbackForLeadersReceivedUrl}
        isMyCollaborator={isMyCollaborator}
        location={location}
        messagesEmptyStates={messagesEmptyStates}
        noRequestFeedback
        opportunitiesBoxEmpty={opportunitiesBoxEmpty}
        opportunitiesLink={opportunitiesLink}
        showPotentialAssessmentResults={showPotentialAssessmentResults}
        showSuggestions={allowsSuggestions && suggestions}
        strengthBoxEmpty={strengthBoxEmpty}
        user={user}
      />
    </>
  ) : (
    <AccessDeniedEmptyState user={user} userId={userid} />
  );
};

ProfileDashboard.propTypes = {
  allowsFeedbackForLeaders: PropTypes.bool,
  allowsFeedbackForLeadersResult: PropTypes.bool,
  allowsPerformanceDashboardSecondTab: PropTypes.bool,
  allowsPotentialAssessmentResult: PropTypes.bool,
  allowsSuggestions: PropTypes.bool,
  collaborators: PropTypes.array,
  communityId: PropTypes.number,
  feedbackForLeaders: PropTypes.object,
  goToSendFeedback: PropTypes.func,
  intl: PropTypes.object,
  loadingFeebackForLeaders: PropTypes.bool,
  location: PropTypes.object,
  params: PropTypes.object,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  allowsFeedbackForLeaders: selectConfigurations('allowsFeedbackForLeaders')(state),
  allowsFeedbackForLeadersResult: selectConfigurations('allowsFeedbackForLeadersResult')(state),
  allowsPerformanceDashboardSecondTab: selectConfigurations('allowsPerformanceDashboardSecondTab')(state),
  allowsPotentialAssessmentResult: selectConfigurations('allowsPotentialAssessmentResult')(state),
  allowsSuggestions: selectConfigurations('allowsSuggestions')(state),
  collaborators: selectAllCollaborators(state),
  communityId: selectCommunityId(state),
  feedbackForLeaders: selectFeedbackForLeaders(state),
  loadingFeebackForLeaders: selectLoadingFeedbackForLeaders(state),
});

const goToSendFeedback = (user) => {
  const urlId = base64Encode(user);

  return push({
    pathname: `/newfeedback/${urlId}`,
    state: { user },
  });
};

const mapDispatchToProps = {
  goToSendFeedback,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ProfileDashboard));
