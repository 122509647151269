// @packages
import React, { Fragment } from 'react';
import Button from 'smu-ui-components/ButtonV2';
import Panel from 'smu-ui-components/Panel';
import PropTypes from 'prop-types';

// @app
import FeatureDiscovery from 'common/FeatureDiscovery';
import { CHANGE_LEADER_BUTTON } from 'common/FeatureDiscovery/constTypes';
import { PROFILE } from 'common/FeatureDiscovery/constGroups';
import { getLeadershipRequestLocation } from 'betterme-components/routes/urls';

// @own
import './styles.scss';
import CollaboratorsList from '../CollaboratorsList';
import LeadersList from '../LeadersList';
import { SENT } from '../constants';

const TeamInformationUI = ({
  allowsGlbFeatures,
  collaboratorsAsMainAndSecondaryLeader,
  collaboratorsAsMainLeader,
  collaboratorsAsSecondaryLeaders,
  collaboratorsByLeader,
  goToLeadershipAdmin,
  hasCollaborators,
  mainAndSecondayLeader,
  messages,
  newWordingForLeaders,
  showChangeLeader,
  showLeadersPanel,
  showLeadershipButton,
  showOtherLeaders,
  user: {
    evaluators,
    identification,
    leader,
  },
}) => {
  const hasLeaders = mainAndSecondayLeader?.length > 0
    || (evaluators?.length > 0)
    || leader;

  return (
    <Fragment>
      {(allowsGlbFeatures || hasLeaders) && showLeadersPanel && (
        <Panel className="team-information">
          <LeadersList
            allowsGlbFeatures={allowsGlbFeatures}
            evaluators={evaluators}
            mainAndSecondayLeader={mainAndSecondayLeader}
            mainLeader={leader}
            newWordingForLeaders={newWordingForLeaders}
            showOtherLeaders={showOtherLeaders}
          />
          {showChangeLeader && (
            <FeatureDiscovery
              group={PROFILE}
              position="right"
              text={messages.changeLeaderFeatureDiscovery}
              type={CHANGE_LEADER_BUTTON}
            >
              <Button
                className="team-information__button"
                onClick={() => goToLeadershipAdmin(identification)}
                size="medium"
                variant="outline"
              >
                {messages.changeLeaderAdminLeadership}
              </Button>
            </FeatureDiscovery>
          )}
        </Panel>
      )}
      <Panel className="team-information">
        <CollaboratorsList
          collaboratorsAsMainAndSecondaryLeader={collaboratorsAsMainAndSecondaryLeader}
          collaboratorsAsMainLeader={collaboratorsAsMainLeader}
          collaboratorsAsSecondaryLeaders={collaboratorsAsSecondaryLeaders}
          collaboratorsByLeader={collaboratorsByLeader}
          hasCollaborators={hasCollaborators}
          linkTo={getLeadershipRequestLocation(SENT)}
          messages={messages}
          showLeadershipButton={showLeadershipButton}
        />
      </Panel>
    </Fragment>
  )
}

TeamInformationUI.propTypes = {
  collaboratorsByLeader: PropTypes.object.isRequired,
  goToLeadershipAdmin: PropTypes.func,
  hasCollaborators: PropTypes.bool.isRequired,
  messages: PropTypes.object,
  showChangeLeader: PropTypes.bool,
  showLeadersPanel: PropTypes.bool,
  showLeadershipButton: PropTypes.bool.isRequired,
  showOtherLeaders: PropTypes.bool,
  user: PropTypes.shape({
    evaluators: PropTypes.array,
    identification: PropTypes.string,
    leader: PropTypes.object,
    leaders: PropTypes.array,
  }).isRequired,
};

export default TeamInformationUI;
