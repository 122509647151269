import { trackEvent } from 'smu-utils/gtm';
const LEADER_DASHBOARD = 'Leader_Dashboard';

export const trackDateFilterDropdown = (option) => {
  trackEvent({
    category: LEADER_DASHBOARD,
    action: 'TimeFrameFilter_SelectPeriod',
    label: option,
  });
};

export const trackDateFilterCalendar = (date) => {
  trackEvent({
    category: LEADER_DASHBOARD,
    action: 'TimeFrameFilter_SelectDateRange',
    label: date,
  });
};

export const trackBoxStrengthsSendFeedbackEmpty = () => {
  trackEvent({
    category: LEADER_DASHBOARD,
    action: 'BoxStrengths_SendFeedback_EmptyState',
  });
};

export const trackBoxOpportunitySendFeedbackEmpty = () => {
  trackEvent({
    category: LEADER_DASHBOARD,
    action: 'BoxOpportunity_SendFeedback_EmptyState',
  });
};

export const trackBoxOpportunitySendFeedbackLink = () => {
  trackEvent({
    category: LEADER_DASHBOARD,
    action: 'BoxOpportunity_SendFeedback_Default',
  });
};

export const trackMemberBoxStrengthsSendFeedbackEmpty = () => {
  trackEvent({
    category: LEADER_DASHBOARD,
    action: 'BoxStrengths_FeedbackReceived_EmptyState',
  });
};

export const trackMemberBoxOpportunitySendFeedbackEmpty = () => {
  trackEvent({
    category: LEADER_DASHBOARD,
    action: 'BoxOpportunity_FeedbackReceived_EmptyState',
  });
};

export const trackMemberFeedbackReceivedSendFeedbackEmpty = () => {
  trackEvent({
    category: LEADER_DASHBOARD,
    action: 'PieChart_FeedbackRatings_EmptyState',
  });
};

export const trackOverallPieChartGoalsViewMore = () => {
  trackEvent({
    category: LEADER_DASHBOARD,
    action: 'PieChart_Goals_ViewMore',
  });
};

export const trackFeedbacksReceivedViewMore = () => {
  trackEvent({
    category: LEADER_DASHBOARD,
    action: 'BoxFeedback_ViewMore',
  });
};

export const trackFeedbackRequestOnBehalf = () => {
  trackEvent({
    category: LEADER_DASHBOARD,
    action: 'RequestOnBehalf_Button',
  });
};

export const trackTeamMemberProfileClick = () => {
  trackEvent({
    action: 'click',
    category: 'Leader_Dashboard',
    label: 'view_profile',
  });
}

export const trackLeadershipRequestsProfileClick = () => {
  trackEvent({
    action: 'click',
    category: 'Leader_Dashboard',
    label: 'Leadership_Requests',
  });
}
