// @packages
import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import Icon from "smu-ui-components/Icon";

// @own
import "./styles.scss";

/**
 * A step shows the completion status of an activity in a series of activities.
 */
const Step = ({
  active,
  className,
  completed,
  description,
  direction,
  disabled,
  finished,
  icon,
  isFirst,
  isLast,
  onClick,
  order,
  title,
  vertical,
}) => {
  return (
    <div
      className={cn('bme-stepper-step suite-stepper-step', className, {
        'suite-stepper-step--active': active,
        'suite-stepper-step--completed': completed,
        'suite-stepper-step--direction-column': direction === 'column',
        'suite-stepper-step--disabled': disabled,
        'suite-stepper-step--finished': finished,
        'suite-stepper-step--first': isFirst,
        'suite-stepper-step--last': isLast,
        'suite-stepper-step--vertical': vertical,
      })}
      onClick={!disabled ? onClick : undefined}
    >
      <div
        className={cn('suite-stepper-step__order', {
          'suite-stepper-step__order--icon': icon || completed
        })}
      >
        {icon || completed ? (
          <Icon icon={completed ? 'check_icon' : icon} />
        ) : (
          order || ""
        )}
      </div>
      <div className="suite-stepper-step__text">
        <label className="suite-stepper-step__title">{title}</label>
        <label className="suite-stepper-step__description">{description}</label>
      </div>
    </div>
  );
};

Step.propTypes = {
  /**
   * A step can be highlighted as active.
   */
  active: PropTypes.bool,
  /**
   * Additional classes.
   */
  className: PropTypes.string,
  /**
   * A step can show that a user has completed it.
   */
  completed: PropTypes.bool,
  /**
   * A step can be displayed column or row.
   */
  direction: PropTypes.oneOf(['column', 'row']),
  /**
   * Disable interaction with the step
   */
  disabled: PropTypes.bool,
  /**
   * Indicates that it is the first step
   */
  isFirst: PropTypes.bool,
  /**
   * Indicates that it is the last step
   */
  isLast: PropTypes.bool,
  /**
   * Called on click.
   */
  onClick: PropTypes.func,
  /**
   * Step icon
   */
  icon: PropTypes.string,
  /**
   * Step order
   */
  order: PropTypes.number,
  /**
   * Step title
   */
  title: PropTypes.string,
  /**
   * A step can be displayed stacked vertically.
   */
  vertical: PropTypes.bool
};

Step.defaultProps = {
  active: false,
  className: "",
  completed: false,
  disabled: false,
  isFirst: false,
  isLast: false,
  onClick: () => { },
  order: 0,
  title: ""
};

export default Step;
