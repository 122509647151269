// @package
import React from 'react';
import MainSearchApi from 'smu-app-components/MainSearchApi';
import PropTypes from 'prop-types';
import trim from 'lodash/trim';
import usePrevious from 'smu-custom-hooks/usePrevious';
import { connect } from 'react-redux';
import { getWebUrl } from 'smu-utils/globalRequestHelper';
import { injectIntl } from 'react-intl';
import { push } from 'react-router-redux';
import { selectRequesting, selectResult } from 'smu-utils/reduxRequests/selectors';
import { trackEvent } from 'smu-utils/gtm';

// @app
import { ENABLED_STARMEUP } from 'betterme-components/services/CommunityConfigs/constants';
import { getCommunityConfigValueBool } from 'betterme-components/services/CommunityConfigs/selectors';
import { getProfileLocation } from 'betterme-components/routes/urls';
import { mainSearchUsers, smuMainSearchUsers } from 'betterme-components/api';

// @own
import messages from './messages';
import {
  BM_CODE,
  CATEGORY_SEARCH,
  MAIN_SEARCH_ID,
  MAIN_SEARCH_REQUEST_ID,
  SMU_CODE,
} from './constants';

function MainSearch({
  enabledStarMeUp,
  goToProfile,
  intl: { formatMessage },
  requesting,
  result,
  searchValue,
}) {
  const wasRequesting = usePrevious(requesting);
  const hasResults = !!result.length;
  const noItemsFound = wasRequesting && !requesting && !hasResults;
  const emptyStateTitle = enabledStarMeUp
    ? formatMessage(messages.MainSearchEmptyStateTitle)
    : noItemsFound ? formatMessage(messages.MainSearchNoResultsEmptyStateTitle) : '';
  const emptyStateSubTitle = enabledStarMeUp
    ? formatMessage(messages.MainSearchEmptyStateSubtitle)
    : '';
  const type = enabledStarMeUp ? SMU_CODE : BM_CODE;

  const handleAdvanceSearchclick = () => {
    trackEvent({
      action: 'advanced',
      category: CATEGORY_SEARCH,
      label: 'click',
    });

    let query = '';
    if (searchValue && trim(searchValue)) {
      query = `?fullName=${trim(searchValue)}`;
    }
    window.location = `${getWebUrl()}/advancedSearch${query}`;
  };

  const handleOnItemClick = (item) => {
    let analytics = {
      action: 'click_colleague',
    }
    if(enabledStarMeUp) {
      if (item?.hashtag) {
        analytics.action = 'click_hashtag';
        window.location = `${getWebUrl()}/hashtags/${item?.hashtag}`;
      } else {
        window.location = `${getWebUrl()}/profile/${item?.uid}`;
      }
    } else {
      goToProfile(item?.identification);
    }

    trackEvent({
      category: CATEGORY_SEARCH,
      ...analytics,
    });
  };

  const getFilters = (defaultFilters) => {
    const filtersByType = {
      [BM_CODE]: {
        isWhitelisted: true,
        pageNumber: defaultFilters?.page,
        query: defaultFilters?.searchValue,
        pageSize: defaultFilters?.limit,
      },
      [SMU_CODE]: {
        filterType: 'ALL',
        page: defaultFilters?.page,
        searchString: defaultFilters?.searchValue,
        size: defaultFilters?.limit,
      },
    }
    return filtersByType[type];
  }

  const getActions = () => {
    const actions = [{
      onClick: handleAdvanceSearchclick,
      size: 'medium',
      text: formatMessage(messages.MainSearchActionAdvanced),
    }];

    return actions;
  };

  const texts = {
    colleagues: formatMessage(messages.MainSearchColleagues),
    emptySubtitle: emptyStateSubTitle,
    emptyTitle: emptyStateTitle,
    hashtags: formatMessage(messages.MainSearchHashtags),
    placeholder: formatMessage(messages.MainSearchPlaceholder),
  };

  return (
    <MainSearchApi
      actions={getActions()}
      api={enabledStarMeUp ? smuMainSearchUsers : mainSearchUsers}
      cacheOptions
      getFilters={getFilters}
      id={MAIN_SEARCH_ID}
      onHashtagClick={handleOnItemClick}
      onUserClick={handleOnItemClick}
      texts={texts}
      type={type}
    />
  );
}

MainSearch.propTypes = {
  goToProfile: PropTypes.func.isRequired,
  goToSendRequestFeedback: PropTypes.func.isRequired,
  intl: PropTypes.object,
  requesting: PropTypes.bool,
  result: PropTypes.array,
  searchValue: PropTypes.string,
};

const mapStateToProps = (state) => {
  const { mainSearch } = state;
  return ({
    enabledStarMeUp:getCommunityConfigValueBool(state, ENABLED_STARMEUP),
    requesting: selectRequesting(state, MAIN_SEARCH_REQUEST_ID),
    result: selectResult(state, MAIN_SEARCH_REQUEST_ID, []),
    searchValue: mainSearch?.searchValue,
  });
};

const mapDispatchToProps = (dispatch) => {
  return {
    goToProfile: (id) => dispatch(push(getProfileLocation(id))),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MainSearch));
