/* eslint-disable import/default  */
// @packages
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import Root from './Root';
import configureStore from './betterme-components/store/configureStore';
import packagejson from '../package.json';

// import assets
import './betterme-components/favicon.ico';
import './betterme-components/assets/icons/fonts/ui-components-icon.css';
import './index.scss';

// eslint-disable-next-line no-console
console.log(`VERSION: ${packagejson.version}`);

const { store, history, persistor } = configureStore();
export { store };

render(
  <AppContainer>
    <Root store={store} history={history} persistor={persistor} />
  </AppContainer>,
  document.getElementById('app')
);

if (module.hot) {
  module.hot.accept('./Root', () => {
    const NewRoot = require('./Root').default;
    render(
      <AppContainer>
        <NewRoot store={store} history={history} />
      </AppContainer>,
      document.getElementById('app')
    );
  });
}
