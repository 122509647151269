// @packages
import React from 'react';
import { injectIntl } from 'react-intl';
import cn from 'classnames';

// @app
import AttachedFilesDetails from 'common/AttachedFilesDetails';
import TextParserContainer from 'betterme-components/TextParser';
import Title from 'components/atoms/Title';
import { formatDateV2 as formatDate } from 'utils/formatDateV2';
import { promotionsMessages } from 'common/messages';

// @own
import RequestInfo from './RequestInfo';
import RequestedBy from './RequestedBy';
import './styles.scss';

const PreviousRequest = ({
  assignerRequester,
  attachments,
  cardTitle,
  comment,
  creationDate,
  highlightRequester,
  intl: { formatMessage },
  level: { name },
  requester: {
    firstName,
    job,
    lastName,
    profileImageCode,
  },
}) => (
  <div className="previous-request">
    <div className="previous-request__info">
      <div className="previous-request__info-left">
        <RequestedBy
          cardTitle={cardTitle}
          firstName={firstName}
          highlight={highlightRequester}
          job={job}
          lastName={lastName}
          profileImageCode={profileImageCode}
        />
      </div>
      <div className="previous-request__info-right">
        <RequestInfo
          className="previous-request__requested-seniority"
          text={name}
          title={formatMessage(promotionsMessages.PromotionsRequestedSeniority)}
        />
        <RequestInfo
          className="previous-request__date"
          noMargin
          text={formatDate(creationDate, 'monthYearFormat')}
          title={formatMessage(promotionsMessages.PromotionsDate)}
        />
      </div>
    </div>
    <div className="previous-request__justification">
      <Title
        className={cn('previous-request__justification-title, text-black')}
        level={2}
        size={14}
      >
        {formatMessage(promotionsMessages.PromotionsWhyPromote)}
      </Title>
      <div className="previous-request__justification-text">
        <TextParserContainer>
          {comment?.length
            ? comment
            : formatMessage(
              promotionsMessages.PromotionsJustificationEmptyState
            )}
        </TextParserContainer>
      </div>
    </div>
    <AttachedFilesDetails
      attachments={attachments}
      className="previous-request__attached-files"
    />
    {assignerRequester &&
      <div className="previous-request__info-left mt-[22px]">
        <RequestedBy
          cardTitle={formatMessage(promotionsMessages.PromotionsAssignerTitle)}
          firstName={assignerRequester.firstName}
          job={assignerRequester.job}
          lastName={assignerRequester.lastName}
          profileImageCode={assignerRequester.profileImageCode}
          imageSize={40}
        />
      </div>
    }
  </div>
);

export default injectIntl(PreviousRequest);
