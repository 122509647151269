// @package
import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

// @app
import Title from 'components/atoms/Title';
import { SIZE_FOURTEEN } from 'components/common/constants';

// @own
import './styles.scss';

const CommentBox = ({
  children,
  className,
  title,
}) => (
  <div className={cn('comment-box', className)}>
    <div className="comment-box__title">
      <Title className="text-shark" level={3} size={SIZE_FOURTEEN}>
        {title}
      </Title>
    </div>
    <div className="comment-box__content">
      {children}
    </div>
  </div>
);

CommentBox.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  text: PropTypes.string,
}

export default CommentBox;
