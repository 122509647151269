export const GET_DASHBOARD_FEEDBACK = 'GET_DASHBOARD_FEEDBACK';
export const GET_DASHBOARD_FEEDBACK_FAIL = 'GET_DASHBOARD_FEEDBACK_FAIL';
export const GET_DASHBOARD_FEEDBACK_FOR_LEADERS_RESULTS = 'GET_DASHBOARD_FEEDBACK_FOR_LEADERS_RESULTS';
export const GET_DASHBOARD_FEEDBACK_FOR_LEADERS_RESULTS_FAIL = 'GET_DASHBOARD_FEEDBACK_FOR_LEADERS_RESULTS_FAIL';
export const GET_DASHBOARD_FEEDBACK_FOR_LEADERS_RESULTS_SUCCESS = 'GET_DASHBOARD_FEEDBACK_FOR_LEADERS_RESULTS_SUCCESS';
export const GET_DASHBOARD_FEEDBACK_SUCCESS = 'GET_DASHBOARD_FEEDBACK_SUCCESS';
export const GET_DASHBOARD_FEEDBACK_SUMMARY = 'GET_DASHBOARD_FEEDBACK_SUMMARY';
export const GET_DASHBOARD_FEEDBACK_SUMMARY_FAIL = 'GET_DASHBOARD_FEEDBACK_SUMMARY_FAIL';
export const GET_DASHBOARD_FEEDBACK_SUMMARY_SUCCESS = 'GET_DASHBOARD_FEEDBACK_SUMMARY_SUCCESS';
export const GET_DASHBOARD_GOALS_INFO = 'GET_DASHBOARD_GOALS_INFO';
export const GET_DASHBOARD_GOALS_INFO_FAIL = 'GET_DASHBOARD_GOALS_INFO_FAIL';
export const GET_DASHBOARD_GOALS_INFO_SUCCESS = 'GET_DASHBOARD_GOALS_INFO_SUCCESS';
export const GET_DASHBOARD_PERFORMANCE_CHART = 'GET_DASHBOARD_PERFORMANCE_CHART';
export const GET_DASHBOARD_PERFORMANCE_CHART_FAIL = 'GET_DASHBOARD_PERFORMANCE_CHART_FAIL';
export const GET_DASHBOARD_PERFORMANCE_CHART_SUCCESS = 'GET_DASHBOARD_PERFORMANCE_CHART_SUCCESS';
export const GET_DASHBOARD_TOPICS_OPPORTUNITIES = 'GET_DASHBOARD_TOPICS_OPPORTUNITIES';
export const GET_DASHBOARD_TOPICS_OPPORTUNITIES_FAIL = 'GET_DASHBOARD_TOPICS_OPPORTUNITIES_FAIL';
export const GET_DASHBOARD_TOPICS_OPPORTUNITIES_SUCCESS = 'GET_DASHBOARD_TOPICS_OPPORTUNITIES_SUCCESS';
export const GET_DASHBOARD_TOPICS_STRENGTHS = 'GET_DASHBOARD_TOPICS_STRENGTHS';
export const GET_DASHBOARD_TOPICS_STRENGTHS_FAIL = 'GET_DASHBOARD_TOPICS_STRENGTHS_FAIL';
export const GET_DASHBOARD_TOPICS_STRENGTHS_SUCCESS = 'GET_DASHBOARD_TOPICS_STRENGTHS_SUCCESS';
export const REQUEST_FEEDBACK_SELECT_COMPETENCY = 'REQUEST_FEEDBACK_SELECT_COMPETENCY';
export const REQUEST_FEEDBACK_SELECT_USER = 'REQUEST_FEEDBACK_SELECT_USER';
export const REQUEST_FEEDBACK_USERS = 'REQUEST_FEEDBACK_USERS';
export const REQUEST_FEEDBACK_USERS_FAIL = 'REQUEST_FEEDBACK_USERS_FAIL';
export const REQUEST_FEEDBACK_USERS_SUCCESS = 'REQUEST_FEEDBACK_USERS_SUCCESS';
export const REQUEST_RATED_TOPICS = 'REQUEST_RATED_TOPICS';
export const REQUEST_RATED_TOPICS_FAIL = 'REQUEST_RATED_TOPICS_FAIL';
export const REQUEST_RATED_TOPICS_SUCCESS = 'REQUEST_RATED_TOPICS_SUCCESS';
export const RESET_DASHBOARD_DATA = 'RESET_DASHBOARD_DATA';
export const RESET_GET_DASHBOARD_FEEDBACK = 'RESET_GET_DASHBOARD_FEEDBACK';
export const RESET_RATED_TOPICS = 'RESET_RATED_TOPICS';
export const RESET_REQUEST_FEEDBACK_DATA = 'RESET_REQUEST_FEEDBACK_DATA';
export const RESET_REQUEST_FEEDBACK_USERS = 'RESET_REQUEST_FEEDBACK_USERS';
export const SELECT_COMPETENCY_DETAILS = 'SELECT_COMPETENCY_DETAILS';
