// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { injectIntl } from 'react-intl';

// @app
import messages from 'betterme-components/Profile/EngagementIndicatorForm/Form/messages';

// @own
import './styles.scss';
import QuestionText from '../QuestionText';
import Options from '../Options';

const Question = ({
  className,
  isChild,
  key,
  question: {
    description,
    id,
    layout,
    options,
    title,
  },
  intl: { formatMessage },
}) => {
  return (
    <div
      className={cn(
        "engagement-indicator-question",
        className,
        { 'engagement-indicator-question__child': isChild },
      )}
      key={`${id}-${key}`}
    >
      {title && <QuestionText text={messages[title] && formatMessage(messages[title])} type="title" />}
      {description && <QuestionText text={description} type="text" />}
      <Options questionId={id} options={options} layout={layout} />
    </div>
  );
};

Question.propTypes = {
  className: PropTypes.string,
  intl: PropTypes.object,
  key: PropTypes.string.isRequired,
  question: PropTypes.object,
};

export default injectIntl(Question);
