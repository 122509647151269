// @packages
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import { push } from 'react-router-redux';

// @app
import {
  GET_ASSESSMENT_COLLABORATORS,
  GET_ASSESSMENT_QUESTIONS,
  GET_ASSESSMENT_RATINGS,
  GET_ASSESSMENT_SCORE,
  GET_ASSESSMENT_TIER,
  SEND_ASSESSMENT_RESULTS,
  SEND_ASSESSMENT_SUMMARY,
} from './actionTypes';
import { resetUserInfo } from 'betterme-components/services/UserInformation/actions';
import { assessmentHomePageLocation } from 'betterme-components/routes/urls';

// @own
import {
  getAssessmentCollaborators,
  getAssessmentQuestions,
  getAssessmentRatings,
  getAssessmentScore,
  getAssessmentTier,
  sendAssessmentResults,
  sendAssessmentSummary,
} from './api';
import {
  resetAssessment,
  getAssessmentCollaborators as getCollaboratorsAction,
  getAssessmentCollaboratorsFail,
  getAssessmentCollaboratorsSuccess,
  getAssessmentQuestionsFail,
  getAssessmentQuestionsSuccess,
  getAssessmentRatingsFail,
  getAssessmentRatingsSuccess,
  getAssessmentScoreFail,
  getAssessmentScoreSuccess,
  getAssessmentTierFail,
  getAssessmentTierSuccess,
  sendAssessmentResultsFail,
  sendAssessmentResultsSuccess,
  sendAssessmentSummaryFail,
  sendAssessmentSummarySuccess,
} from './actions';

function* getAssessmentRatingsWorker({ payload }) {
  const assessmentRatings = yield call(getAssessmentRatings, payload);
  try {
    yield put(getAssessmentRatingsSuccess(assessmentRatings));
  } catch (error) {
    yield put(getAssessmentRatingsFail(error?.message));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

function* getAssessmentQuestionsWorker({ payload }) {
  const assessmentQuestions = yield call(getAssessmentQuestions, payload);
  try {
    yield put(getAssessmentQuestionsSuccess(assessmentQuestions));
  } catch (error) {
    yield put(getAssessmentQuestionsFail(error?.message));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}


function* getAssessmentCollaboratorsWorker() {
  try {
    const response = yield call(getAssessmentCollaborators);
    yield put(getAssessmentCollaboratorsSuccess(response?.collaborators));
  } catch (error) {
    yield put(getAssessmentCollaboratorsFail(error.message));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

function* getAssessmentTierWorker({ payload: userId }) {
  const data = userId;
  try {
    const response = yield call(getAssessmentTier, data);
    yield put(getAssessmentTierSuccess(response));
  } catch (error) {
    yield put(getAssessmentTierFail(error.message));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

function* sendAssessmentSummaryWorker({ payload }) {
  const { answers, collaborator } = payload;
  const data = {
    answers,
    recipientId: collaborator?.id,
  };

  try {
    const response = yield call(sendAssessmentSummary, data);
    yield put(sendAssessmentSummarySuccess(response));
    yield put(getCollaboratorsAction());
  } catch (error) {
    yield put(sendAssessmentSummaryFail(error.message));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

function* sendAssessmentResultsWorker({ payload: { data, id, successMessage } }) {
  const goToAssessmentHomePage = () => push(assessmentHomePageLocation());

  try {
    const response = yield call(sendAssessmentResults, data, id);
    yield put(sendAssessmentResultsSuccess(response));
    yield put(resetUserInfo());
    yield put(resetAssessment());
    yield put(getCollaboratorsAction());
    yield put(goToAssessmentHomePage());
    yield put(addToastMessage({ message: successMessage, timeout: 5000 }));
  } catch (error) {
    yield put(sendAssessmentResultsFail(error.message));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

function* getAssessmentScoreWorker({ payload }) {
  const assessmentScore = yield call(getAssessmentScore, payload);
  try {
    yield put(getAssessmentScoreSuccess(assessmentScore));
  } catch (error) {
    yield put(getAssessmentScoreFail(error?.message));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

export default function* assessmentWatcher() {
  yield takeLatest(GET_ASSESSMENT_COLLABORATORS, getAssessmentCollaboratorsWorker);
  yield takeLatest(GET_ASSESSMENT_QUESTIONS, getAssessmentQuestionsWorker);
  yield takeLatest(GET_ASSESSMENT_RATINGS, getAssessmentRatingsWorker);
  yield takeLatest(GET_ASSESSMENT_SCORE, getAssessmentScoreWorker);
  yield takeLatest(GET_ASSESSMENT_TIER, getAssessmentTierWorker);
  yield takeLatest(SEND_ASSESSMENT_RESULTS, sendAssessmentResultsWorker);
  yield takeLatest(SEND_ASSESSMENT_SUMMARY, sendAssessmentSummaryWorker);
}
