// @packages
import React, { useCallback } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Dropdown from 'smu-ui-components/Dropdown';
import InputArea from 'smu-ui-components/InputArea';

// @app
import NumericScoreSelector from 'common/NumericScoreSelector';
import UploadFile from 'betterme-components/components/containers/UploadFile';

// @own
import './styles.scss';

const EvaluationScoreSelector = ({
  attachFileValues: {
    customText: attachFileCustomText,
    files: attachFileFiles,
    loading: attachFileLoading,
    maxFiles: attachFileMaxFiles,
    onChange: attachFileOnChange,
    onDeleteAccept: attachFileOnDeleteAccept,
    supportedFormats: attachFileSupportedFormats,
  },
  className,
  comment,
  disabled,
  dropdownPlaceholder,
  isContinuousEvaluation,
  isNewHire,
  isSelfNewHire,
  max,
  min,
  onScoreChange,
  onTextChange,
  optionsScores,
  score,
  textAreaPlaceholder,
  withAttachFiles,
}) => {
  const AttachFileComponent = useCallback(() => (
    <UploadFile
      customText={attachFileCustomText}
      disabled={attachFileFiles?.length >= attachFileMaxFiles}
      files={attachFileFiles}
      loading={attachFileLoading}
      onChange={attachFileOnChange}
      onDeleteAccept={attachFileOnDeleteAccept}
      supportedFormats={attachFileSupportedFormats}
    />
  ), [
    attachFileFiles,
    attachFileLoading,
    attachFileOnChange,
  ]);

  return (
    <div className={cn('evaluations-score-selector', className)}>
      {(!(isSelfNewHire || isNewHire) && !isContinuousEvaluation) && (
        optionsScores ? (
          <Dropdown
            className="evaluations-score-selector__dropdown"
            disabled={disabled}
            onChange={onScoreChange}
            options={optionsScores}
            padding
            placeholder={score ? undefined : dropdownPlaceholder}
            value={score}
          />
        ) : (
          <NumericScoreSelector
            className="evaluations-score-selector__number-selector"
            disabled={disabled}
            onChange={onScoreChange}
            range={{ min, max }}
            value={score}
          />
        ))}
      {(comment !== undefined) && (
        <InputArea
          className="evaluations-score-selector__input-area"
          maxLength={3000}
          onChange={onTextChange}
          placeholder={textAreaPlaceholder}
          rows={12}
          value={comment}
        />
      )}
      {withAttachFiles && <AttachFileComponent />}
    </div>
  );
};

EvaluationScoreSelector.defaultProps = {
  attachFileValues: {},
};

EvaluationScoreSelector.propTypes = {
  attachFileValues: PropTypes.shape({
    files: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    maxFiles: PropTypes.number,
    onChange: PropTypes.func.isRequired,
  }),
  className: PropTypes.string,
  comment: PropTypes.string,
  disabled: PropTypes.bool,
  dropdownPlaceholder: PropTypes.string,
  isNewHire: PropTypes.bool,
  max: PropTypes.number,
  min: PropTypes.number,
  onScoreChange: PropTypes.func,
  onTextChange: PropTypes.func,
  optionsScores: PropTypes.array,
  score: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  textAreaPlaceholder: PropTypes.string,
};

export default EvaluationScoreSelector;
