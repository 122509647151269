// @package
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Label from 'smu-ui-components/Label';
import UserAvatar from 'smu-ui-components/UserAvatar';

// @own
import './styles.scss';

const CarouselCard = ({
  className,
  evaluated,
  evaluatedLabel,
  firstName,
  highlighted,
  highlightedAvatar,
  inProgress,
  inProgressLabel,
  job,
  lastName,
  notAvaible,
  notAvaibleDescription,
  notAvaibleLabel,
  onClick,
  profileImageCode,
}) => {
  const labelColor = evaluated || inProgress ? 'assessment-evaluated-tag' : 'assessment-notavaible-tag';
  const labelText = evaluated ? evaluatedLabel : (inProgress ? inProgressLabel : notAvaibleLabel);

  return (
    <div
      className={cn('carousel-card', className, {
        'carousel-card--clickable': onClick && (!evaluated || !notAvaible || inProgress),
        'carousel-card--evaluated': evaluated,
        'carousel-card--in-progress': inProgress,
        'carousel-card--not-avaible': notAvaible,
        'carousel-card--highlighted-avatar': highlightedAvatar,
        'carousel-card--highlighted': highlighted,
      })}
      onClick={!evaluated && !notAvaible && onClick}
    >
      <div className="carousel-card__wraper">
        <UserAvatar
          className="carousel-card__avatar"
          profileImageCode={profileImageCode}
          height={70}
          width={70}
        />
        <span className="carousel-card__name">
          {`${firstName} ${lastName}`}
        </span>
      </div>
      {(evaluated || notAvaible || inProgress) ? (
        <div className="carousel-card__label">
          <Label
            className="carousel-card__label-tag"
            color={labelColor}
            size="small"
            text={labelText}
          />
          <p className="carousel-card__label-description">
            {notAvaibleDescription}
          </p>
        </div>
      ) : (
        <div className="carousel-card__job">
          {job}
        </div>
      )}
    </div>
  );
}

CarouselCard.propTypes = {
  className: PropTypes.string,
  evaluated: PropTypes.bool,
  evaluatedLabel: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  highlighted: PropTypes.bool,
  highlightedAvatar: PropTypes.bool,
  inProgress: PropTypes.bool,
  inProgressLabel: PropTypes.string,
  job: PropTypes.string,
  lastName: PropTypes.string.isRequired,
  notAvaible: PropTypes.bool,
  notAvaibleDescription: PropTypes.string,
  notAvaibleLabel: PropTypes.string,
  onClick: PropTypes.func,
  profileImageCode: PropTypes.string.isRequired,
};

export default CarouselCard;