// @packages
import React, { useState } from 'react';
import Label from 'smu-ui-components/Label';
import PropTypes from 'prop-types';

// @app
import Discovery from 'betterme-components/TeamInformation/LeadersList/Discovery';

function DiscoveryLabel({
  actionButtonTexts,
  className,
  discoveryType,
  galleryItems,
  labelText,
  showFeatureDiscovery,
}) {
  const [show, setShow] = useState(true);

  function onLabelClick(event) {
    event.stopPropagation();
    setShow(true);
  };

  const labelNode = (
    <Label
      className="ml-2"
      color="btm-blue-button"
      size="small"
      text={labelText}
    />
  );

  return (
    <div className={className}>
      {showFeatureDiscovery ? (
        <Discovery
          actionButtonTexts={actionButtonTexts}
          galleryItems={galleryItems}
          handleOpen={setShow}
          open={show}
          type={discoveryType}
        >
          <div
            className="cursor-pointer mt-[-4px] mr-0 mb-0 ml-[10px]"
            onClick={(event) => onLabelClick(event)}
          >
            {labelNode}
          </div>
        </Discovery>
      ) : (
        <>{labelNode}</>
      )}
    </div>
  )
};

DiscoveryLabel.propTypes = {
  actionButtonTexts: PropTypes.object,
  discoveryType: PropTypes.string,
  galleryItems: PropTypes.array,
  labelText: PropTypes.array,
  showFeatureDiscovery: PropTypes.bool,
};

export default DiscoveryLabel;