// @own
import { USER_CAROUSEL, RESET_USER_CAROUSEL } from './actionTypes';

export function setUserCarousel(payload) {
  return {
    type: USER_CAROUSEL,
    payload,
  };
}

export function resetUserCarousel(payload) {
  return {
    type: RESET_USER_CAROUSEL,
    payload,
  };
}
