// @packages
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// @app
import { selectUser } from '../Authorization/selectors';
import Feed from '../Feed';

// @own
import * as actions from './actions';
import { selectMyFeedbacks } from './selectors';

class MyFeedbacks extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      activeTab,
      from,
      getComments,
      getFeed,
      location,
      myFeedbacks,
      ordering,
      sendComment,
      to,
      user,
      userSession,
      view,
    } = this.props;

    return (
      <div className="my-feedbaks">
        <Feed
          activeTab={activeTab || location?.query?.tab}
          feed={myFeedbacks}
          from={from}
          getComments={getComments}
          getFeed={getFeed}
          ordering={ordering}
          sendComment={sendComment}
          to={to}
          user={user || userSession}
          view={view}
        />
      </div>
    );
  }
}

MyFeedbacks.propTypes = {
  activeTab: PropTypes.string,
  from: PropTypes.object,
  getComments: PropTypes.func.isRequired,
  getFeed: PropTypes.func.isRequired,
  myFeedbacks: PropTypes.shape({
    feedbacks: PropTypes.array,
    fetching: PropTypes.bool,
  }),
  myTeamFilters: PropTypes.object,
  ordering: PropTypes.string,
  sendComment: PropTypes.func.isRequired,
  to: PropTypes.object,
  user: PropTypes.object,
  userSession: PropTypes.object.isRequired,
  view: PropTypes.oneOf(['team', 'own', 'profile']),
};

const mapStateToProps = state => ({
  myFeedbacks: selectMyFeedbacks(state),
  userSession: selectUser(state),
});

export default connect(mapStateToProps, actions)(MyFeedbacks);
