import {
  GET_PROFILE,
  GET_PROFILE_FAIL,
  GET_PROFILE_SUCCESS,
  RESET_PROFILE,
} from './actionTypes';

const initialState = {
  loadingGetProfile: false,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_PROFILE:
      return { ...state, loadingGetProfile: true, };

    case GET_PROFILE_FAIL:
      return { ...state, loadingGetProfile: false, error: payload.error };

    case GET_PROFILE_SUCCESS:
      return { ...state, loadingGetProfile: false, ...payload.response };

    case RESET_PROFILE:
      return initialState;

    default:
      return state;
  }
}
