// @package
import React, { useState, useEffect } from 'react';
import Button from 'smu-ui-components/ButtonV2';
import Divider from 'smu-ui-components/Divider';
import Link from 'smu-ui-components/Link';
import PropTypes from 'prop-types';
import RootModal from 'smu-app-components/RootModal';
import cn from 'classnames';
import { closeModal } from 'smu-app-components/RootModal/actions';
import { connect } from 'react-redux';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import { push } from 'react-router-redux';
import { trackEvent } from 'smu-utils/gtm';
import GenericEmptyState from 'smu-ui-components/GenericEmptyState';

// @app
import Incognito from 'betterme-components/assets/incognito.png';
import Title from 'components/atoms/Title';
import { actionsMessages } from 'common/messages';
import { centerModalClass } from 'betterme-components/ConfirmationModal';
import { FEEDBACK_FOR_LEADERS_SELECTION_MODAL } from 'betterme-components/constants/modalTypes';
import { getNewFeedbackForLeadersLocation } from 'betterme-components/routes/urls';
import { selectConfigurations } from 'betterme-components/Authorization/selectors';
import { selectFeedbackForLeadersRequestUrl } from 'betterme-components/Authorization/selectors';

// @own
import './styles.scss';
import LeaderCard from './LeaderCard';
import CardsCarrousel from './CardsCarrousel';
import messages from './messages';
import {
  selectLeadersByPeriod,
  selectLoadingLeadersByPeriod,
} from './selectors';
import { getLeadersByPeriod } from './actions';

const FeedbackForLeadersSelectionModal = ({
  allowsGlbFeatures,
  closeModal,
  feedbackForLeadersRequestUrl,
  fetching,
  getLeadersByPeriod,
  intl: { formatMessage },
  leadersByPeriod,
  push,
}) => {
  const [selectedLeader, setSelectedLeader] = useState(null);
  const canContinue = selectedLeader !== null;
  const hasLeaders = !fetching && (
    leadersByPeriod?.currentLeaders?.length
    || leadersByPeriod?.previousLeaders?.length
  );
  const hasCurrentLeaders = hasLeaders && leadersByPeriod?.currentLeaders?.length;
  const hasPreviousLeaders = hasLeaders && leadersByPeriod?.previousLeaders?.length;

  function handleCardClick(identification) {
    setSelectedLeader(
      selectedLeader === identification
        ? null
        : identification
    );
  }

  function getLeadersCards(leaders, highlightFirst) {
    return leaders ? leaders.map(({
      feedbackForLeader,
      firstName,
      identification,
      job,
      lastName,
      profileImageCode,
    },
      index,
    ) => (
      <LeaderCard
        evaluated={!feedbackForLeader}
        firstName={firstName}
        highlighted={selectedLeader === identification}
        highlightedAvatar={highlightFirst && index === 0}
        job={job}
        key={index}
        lastName={lastName}
        onClick={() => handleCardClick(identification)}
        profileImageCode={profileImageCode}
      />
    )) : [];
  }

  function handleContinue() {
    closeModal();
    push(getNewFeedbackForLeadersLocation(selectedLeader).pathname);
    trackEvent({
      action: 'Modal',
      category: 'Feedback for Leaders',
      label: 'Leaders_Continue'
    });
  };

  function handleCloseModal() {
    closeModal();
    trackEvent({
      action: 'Modal',
      category: 'Feedback for Leaders',
      label: 'Leaders_Cancel'
    });
  }

  function trackGoToForm() {
    trackEvent({
      action: 'Modal',
      category: 'Feedback for Leaders',
      label: 'Leaders_GoToForm'
    });
  }

  useEffect(() => {
    getLeadersByPeriod();
  }, []);

  const descriptionMessage = <FormattedHTMLMessage
    defaultMessage={messages.FeedbackForLeadersSelectionModalDontHaveLeader.defaultMessage}
    id={messages.FeedbackForLeadersSelectionModalDontHaveLeader.id}
    values={{
      goTo: feedbackForLeadersRequestUrl,
    }}
  />

  return (
    <div className="feedback-for-leaders-selection-modal">
      <div className="feedback-for-leaders-selection-modal__header">
        <Title
          className="feedback-for-leaders-selection-modal__title"
          gray
          level={1}
          size={16}
        >
          {formatMessage(messages.FeedbackForLeadersSelectionModalChooseLeader)}
        </Title>
      </div>
      <Divider nomargin />
      <div className="feedback-for-leaders-selection-modal__content">
        <div className="feedback-for-leaders-selection-modal__incognito">
          <img
            alt={formatMessage(messages.FeedbackForLeadersSelectionModalAnonymous)}
            className="feedback-for-leaders-selection-modal__incognito-image"
            src={Incognito}
          />
          <div className="feedback-for-leaders-selection-modal__incognito-text">
            {formatMessage(messages.FeedbackForLeadersSelectionModalAnonymous)}
          </div>
        </div>
        {(!hasLeaders && !fetching) ? (
          <GenericEmptyState
            className="px-5 py-20 bg-white [&_a]:!underline [&_a]:!text-black"
            description={!allowsGlbFeatures && descriptionMessage}
            image="listCheck"
            limitTextWidth={false}
            size="large"
            title={formatMessage(messages.FeedbackForLeadersSelectionModalEmptyState)}
          />
        ) : (
          <>
            {(hasCurrentLeaders || fetching) && (
              <div className={cn('feedback-for-leaders-selection-modal__current', {
                'feedback-for-leaders-selection-modal__current--no-padding': !hasPreviousLeaders,
              })}>
                <Title
                  className="feedback-for-leaders-selection-modal__title"
                  level={2}
                  size={16}
                >
                  {formatMessage(messages.FeedbackForLeadersSelectionModalCurrentLeaders)}
                </Title>
                <CardsCarrousel
                  items={leadersByPeriod ? getLeadersCards(leadersByPeriod?.currentLeaders, true) : []}
                  itemsMargin={15}
                  loading={fetching}
                />
              </div>
            )}
            {(hasPreviousLeaders || fetching) && (
              <>
                <Divider nomargin />
                <div className="feedback-for-leaders-selection-modal__previous">
                  <Title
                    className="feedback-for-leaders-selection-modal__title"
                    level={2}
                    size={16}
                  >
                    {formatMessage(messages.FeedbackForLeadersSelectionModalPreviousLeaders)}
                  </Title>
                  <CardsCarrousel
                    items={leadersByPeriod ? getLeadersCards(leadersByPeriod?.previousLeaders) : []}
                    itemsMargin={15}
                    loading={fetching}
                  />
                </div>
              </>
            )}
            {!allowsGlbFeatures && (
              <div className="feedback-for-leaders-selection-modal__add-leaders">
                <span>{formatMessage(messages.FeedbackForLeadersSelectionModalCantFindLeader)}
                  <Link
                    className="feedback-for-leaders-selection-modal__form-link"
                    href={feedbackForLeadersRequestUrl}
                    target="_blank"
                    onClick={trackGoToForm}
                  >
                    {formatMessage(messages.FeedbackForLeadersSelectionModalClickHere)}
                  </Link>
                  {formatMessage(messages.FeedbackForLeadersSelectionModalCompleteThisForm)}
                </span>
              </div>
            )}
          </>
        )}
      </div>
      <Divider nomargin />
      <div className="feedback-for-leaders-selection-modal__footer">
        <div className="feedback-for-leaders-selection-modal__actions">
          <Button
            className="feedback-for-leaders-selection-modal__action-cancel"
            variant="outline"
            onClick={handleCloseModal}
          >
            {formatMessage(actionsMessages.ActionsCancel)}
          </Button>
          <Button
            className="feedback-for-leaders-selection-modal__action-continue"
            disabled={!canContinue}
            onClick={handleContinue}
          >
            {formatMessage(actionsMessages.ActionsContinue)}
          </Button>
        </div>
      </div>
    </div>
  );
};

FeedbackForLeadersSelectionModal.propTypes = {
  fetching: PropTypes.bool,
  getLeadersByPeriod: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  leadersByPeriod: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  allowsGlbFeatures: selectConfigurations('allowsGlbFeatures')(state),
  feedbackForLeadersRequestUrl: selectFeedbackForLeadersRequestUrl(state),
  fetching: selectLoadingLeadersByPeriod(state),
  leadersByPeriod: selectLeadersByPeriod(state),
});

const mapDispatchToProps = {
  closeModal,
  getLeadersByPeriod,
  push,
};

const withRootModalComponent = RootModal(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(FeedbackForLeadersSelectionModal)),
  FEEDBACK_FOR_LEADERS_SELECTION_MODAL,
  null,
  centerModalClass,
);

export default withRootModalComponent;
