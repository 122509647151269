// @packages
import React from 'react';
import cn from 'classnames';
import Proptypes from 'prop-types';
import Link from 'smu-ui-components/Link';
import Icon from 'smu-ui-components/IconV2';

// @app
import Carousel from 'components/containers/Carousel';

// @own
import './styles.scss';

const PotentialAssessment = ({
  analytics: {
    trackGoToForm,
    trackLearnMore,
  },
  className,
  collaborators,
  description,
  externalURL,
  externalURLText,
  googleFormLink,
  title,
  userId,
  setIsUserSelected
}) => {
  const {
    carouselDescription,
    linkText,
    rateBehaviours,
    youGuessedIt,
  } = description;

  return (
    <div className={cn('potential-assessment', className)}>
      <header className="potential-assessment__header">
        <div className="potential-assessment__header-title">
          <h1 className="potential-assessment__header-title-text">
            {title}
          </h1>
        </div>
      </header>
      <section className="potential-assessment__content">
        <div className="potential-assessment__content-container">
          <div className="potential-assessment__content-description">
            <p className="potential-assessment__content-description-text">
              {youGuessedIt}
            </p>
            <p className="potential-assessment__content-description-considerations">
              {rateBehaviours}
            </p>
            <Icon
              className="potential-assessment__content-description-icon"
              color="dark-indigo"
              icon="info"
              size="small"
            />
            <Link
              className="potential-assessment__content-description-link"
              href={externalURL}
              onClick={trackLearnMore}
              target="_blank"
            >
              {externalURLText}
            </Link>
          </div>
          <div className="potential-assessment__content-carousel">
            <p className="potential-assessment__content-carousel-description">
              {carouselDescription}
              <Link
                className="potential-assessment__content-carousel-description-link"
                href={googleFormLink}
                onClick={trackGoToForm}
              >
                {linkText}
              </Link>
            </p>
            <div className="potential-assessment__content-carousel-content">
              <Carousel
                items={collaborators}
                targetId={userId}
                setIsUserSelected={setIsUserSelected}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

PotentialAssessment.propTypes = {
  carouselDescription: Proptypes.string,
  className: Proptypes.string,
  collaborators: Proptypes.array,
  description: Proptypes.string,
  externalURLText: Proptypes.string,
  title: Proptypes.string,
  userId: Proptypes.number,
  setIsUserSelected: Proptypes.func
}

export default PotentialAssessment;
