import { trackEvent } from 'smu-utils/gtm';
const GOALS = 'Goals';

export const trackMyGoalsClick = () => {
  trackEvent({
    category: GOALS,
    action: 'My_Goals_Click',
  });
};

export const trackMyGoalsCreateClick = () => {
  trackEvent({
    category: GOALS,
    action: 'Create_Goal_On_Behalf_Click',
  });
};

export const trackGoalsSendCreate = () => {
  trackEvent({
    category: GOALS,
    action: 'Send_Goal_Click',
  });
};

export const trackGoalsCloseXCreate = () => {
  trackEvent({
    category: GOALS,
    action: 'Create_X_Goal_Click',
  });
};

export const trackGoalsCancelCreate = () => {
  trackEvent({
    category: GOALS,
    action: 'Create_Cancel_Goal_Click',
  });
};

export const trackMyTeamGoalsClick = () => {
  trackEvent({
    category: GOALS,
    action: 'My_Team_Goals_Click',
  });
};

export const trackMyTeamGoalsCreateClick = () => {
  trackEvent({
    category: GOALS,
    action: 'Create_Goal_Click',
  });
};

export const trackGoalsDuplicateClick = () => {
  trackEvent({
    category: GOALS,
    action: 'Duplicate_Goal_Click',
  });
};

export const trackGoalsEditClick = () => {
  trackEvent({
    category: GOALS,
    action: 'Edit_Goal_Click',
  });
};

export const trackGoalsDeleteClick = () => {
  trackEvent({
    category: GOALS,
    action: 'Delete_Goal_Click',
  });
};

export const trackGoalsCloseClick = () => {
  trackEvent({
    category: GOALS,
    action: 'Close_Goal_Click',
  });
};

export const trackGoalCloseCancel = () => {
  trackEvent({
    category: GOALS,
    action: 'Close_Goal_Cancel_Click'
  });
};

export const trackGoalDeleteCancel = () => {
  trackEvent({
    category: GOALS,
    action: 'Delete_Goal_Cancel_Click',
  });
};
