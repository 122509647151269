import { trackEvent } from 'smu-utils/gtm';

export const trackChipsHover = (name) => {
  trackEvent({
    category: 'Feedback',
    action: 'Chips_Hover',
    label: name,
    type: 'fdbk_awaiting',
  });
};
