// @packages
import React from 'react';
import cn from 'classnames';
import AvatarMedia from 'smu-ui-components/AvatarMedia';
import PropTypes from 'prop-types';

// @app
import PersonalDataItemsList from 'components/organisms/PersonalDataItemsList';

// @own
import './styles.scss';

const LeadershipAdminUserInfo = ({
  className,
  items,
  noJob,
  user: {
    firstName,
    identification,
    job,
    lastName,
    profileImageCode,
    seniority,
  },
}) => {
  return (
    <div className={cn('leadership-admin-user-info', className)}>
      <div className="leadership-admin-user-info__content">
        <AvatarMedia
          className="leadership-admin-user-info__avatar"
          firstName={firstName}
          identification={identification}
          job={job}
          lastName={lastName}
          profileImageCode={profileImageCode}
          seniority={seniority}
          showSeniority
          noJob={noJob}
        />
        <PersonalDataItemsList items={items} />
      </div>
    </div>
  );
};

LeadershipAdminUserInfo.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array,
  noJob: PropTypes.bool,
  user: PropTypes.object,
};

export default LeadershipAdminUserInfo;
