// @packages
import React, { useRef } from 'react';
import ButtonV2 from 'smu-ui-components/ButtonV2';
import IconV2 from 'smu-ui-components/IconV2';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { injectIntl } from 'react-intl';

// @app
import { promotionsMessages } from 'common/messages';

// @own
import './styles.scss';

const UploadFileButton = ({
  className,
  disabled,
  intl: { formatMessage },
  onChange,
  supportedFormats,
}) => {
  const attachRef = useRef();

  function handleClick() {
    attachRef.current.click();
  }

  return (
    <div className="upload-file-button">
      <ButtonV2
        className={cn(
          'upload-file-button__button !px-4 !py-2',
          { className: className }
        )}
        color="blue"
        disabled={disabled}
        enabledUnification
        onClick={handleClick}
        size="medium"
        variant="outline"
      >
        <IconV2 className="upload-file-button__icon" icon="clip" size={12} />
        {formatMessage(promotionsMessages.PromotionsAttachFile)}
      </ButtonV2>
      <input
        accept={supportedFormats}
        className="upload-file-button__attach"
        onChange={onChange}
        ref={attachRef}
        type="file"
      />
    </div>
  );
};

UploadFileButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  intl: PropTypes.object,
  onChange: PropTypes.func,
  supportedFormats: PropTypes.array,
};

export default injectIntl(UploadFileButton);
