// @packages
import FullScreenModal from 'smu-app-components/FullScreenModal';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Divider from 'smu-ui-components/Divider';

// @app
import Title from 'components/atoms/Title';
import Userbox from 'common/Userbox';
import BreadcrumbHeader from 'components/organisms/BreadcrumbHeader';
import { userMessages } from 'common/messages';
import { SUGGEST_ACTIONS_MODAL } from '../constants/modalTypes';

// @own
import SuggestActionsForm from './SuggestActionsForm';
import messages from './messages';
import * as actions from './actions';
import {
  selectSuggestedActions,
  selectSuggestedActionsLoadingCreation,
} from './selectors';
import './styles.scss';

class SuggestActions extends Component {
  constructor() {
    super();

    this.state = {
      comment: '',
      selectedSuggestions: [],
    };

    this.handleActionChange = this.handleActionChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.handleSend = this.handleSend.bind(this);
  }

  componentDidMount() {
    const { getSuggestedActions } = this.props;

    getSuggestedActions();
  }

  handleCancel() {
    const { close } = this.props;
    close();
  }

  handleActionChange(checked, actionId) {
    const { selectedSuggestions } = this.state;
    let newSelectedSuggestions;

    if (checked) {
      newSelectedSuggestions = [...selectedSuggestions, actionId];
    } else {
      newSelectedSuggestions = selectedSuggestions
        .filter(selectedActionId => selectedActionId !== actionId);
    }

    this.setState({ selectedSuggestions: newSelectedSuggestions });
  }

  handleCommentChange(event) {
    const { target: { value } } = event;
    this.setState({ comment: value });
  }

  handleSend() {
    const {
      createSuggestedActions,
      intl: { formatMessage },
      user: { id },
    } = this.props;
    const { comment, selectedSuggestions } = this.state;

    if (this.isFormValid()) {
      createSuggestedActions({
        comment,
        recipientId: id,
        suggestedActionsIds: selectedSuggestions,
        messages: {
          success: formatMessage(messages.SuggestActionsFormSuccess),
        }
      });
    }
  }

  isFormValid() {
    const { comment, selectedSuggestions } = this.state;

    return comment.trim() && selectedSuggestions.length > 0;
  }

  render() {
    const {
      intl: { formatMessage },
      loadingCreation,
      suggestedActions,
      user,
    } = this.props;
    const {
      comment,
      selectedSuggestions
    } = this.state;

    return (
      <div className="suggest-actions">
        <BreadcrumbHeader
          breadCrumbsProps={{
            active: 1,
            onClick: this.handleCancel,
            size: 'big',
            values: [
              `${user.firstName} ${user.lastName}`,
              formatMessage(messages.SuggestActionsBreadcrumbTitle),
            ],
          }}
          fixed
        />
        <div className="suggest-actions__suggest-actions-content">
          <Title className="text-black">
            {formatMessage(userMessages.UserAssignedTo)}
          </Title>
          <Userbox className="suggest-actions__user-box" {...user} />
          <Divider className="suggest-actions__first-divider" />
          <SuggestActionsForm
            comment={comment}
            onActionChange={this.handleActionChange}
            onCancel={this.handleCancel}
            onCommentChange={this.handleCommentChange}
            onSend={this.handleSend}
            username={user.firstName}
            selectedSuggestions={selectedSuggestions}
            suggestions={suggestedActions}
            valid={this.isFormValid() && !loadingCreation}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    suggestedActions: selectSuggestedActions(state),
    loadingCreation: selectSuggestedActionsLoadingCreation(state),
  };
}

SuggestActions.propTypes = {
  close: PropTypes.func.isRequired,
  createSuggestedActions: PropTypes.func.isRequired,
  getSuggestedActions: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  loadingCreation: PropTypes.bool,
  suggestedActions: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
};

export default FullScreenModal(
  connect(mapStateToProps, actions)(injectIntl(SuggestActions)),
  SUGGEST_ACTIONS_MODAL,
);
