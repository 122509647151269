// @packages
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { injectIntl } from 'react-intl';
import scrollToComponent from 'react-scroll-to-component';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import UserAvatar from 'smu-ui-components/UserAvatar';

// @app
import formatScore from 'utils/formatScore';
import ContentCard from 'common/ContentCard';
import CardFooter from 'common/CardFooter';
import RatedTopics from 'components/organisms/RatedTopics';
import BreadcrumbHeader from 'components/organisms/BreadcrumbHeader';
import AttachedFilesDetails from 'common/AttachedFilesDetails';
import Title from 'components/atoms/Title';
import { getEvaluationsLocation, getTeamEvaluationsLocation } from 'betterme-components/routes/urls'
import {
  commonMessages,
} from 'common/messages';
import {
  selectConfigurations,
  selectUserInfo,
} from 'betterme-components/Authorization/selectors';
import { selectScores } from 'betterme-components/services/Layout/selectors';
import EvaluationDetailScore from 'components/organisms/EvaluationDetailScore';
import {
  CONTINUOUS_EVALUATION,
  NH_SELF_EVALUATION,
  SELF_EVALUATION,
  SELF_RETROSPECTIVE,
} from 'betterme-components/Evaluations/constants';

// @own
import './styles.scss';
import * as actions from './actions';
import {
  selectEvaluationDetail,
} from './selectors';
import messages from './messages';
import {
  AVATAR_SIZE,
  OFFSET_NAV,
} from './constants';
import BehavioralAssessmentDetail from 'components/organisms/BehavioralAssessmentDetail';

class SefRetrospectiveDetail extends Component {
  componentDidMount() {
    const { params: { evaluationid }, getEvaluationDetail } = this.props;
    if (evaluationid) {
      getEvaluationDetail(evaluationid);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { params: { evaluationid }, getEvaluationDetail } = this.props;
    const { params: { evaluationid: nextEvaluationid } } = nextProps;

    if (evaluationid !== nextEvaluationid) {
      getEvaluationDetail(nextEvaluationid);
    }
  }

  componentDidUpdate() {
    const { location: { query } } = this.props;

    if (query.showAnswer && this.answer) {
      scrollToComponent(this.answer, {
        offset: OFFSET_NAV,
        align: 'top',
      });
    }
  }

  componentWillUnmount() {
    const { resetEvaluationDetail } = this.props;
    resetEvaluationDetail();
  }

  render() {
    const {
      coCreatedTopics,
      evaluationDetail: {
        attachments,
        behaviors,
        comment,
        evaluator,
        evaluee,
        promotion,
        ratedTopics,
        score,
        scoreId,
        title,
        type,
      },
      evaluationDetail,
      intl: { formatMessage },
      userInfo,
      goToPath,
      scores,
    } = this.props;

    if (isEmpty(evaluationDetail)) {
      return null;
    }

    const findScore = scores?.find(score => score?.id === scoreId);
    const scoreDescription = findScore?.description;
    const evalueeFullName = `${evaluee?.firstName} ${evaluee?.lastName}`;
    const isNHSelfEvaluation = type === NH_SELF_EVALUATION;
    const isSelfEvaluation = type === SELF_EVALUATION || isNHSelfEvaluation;
    const coCreatedData = evaluationDetail?.coCreated;
    const showCoCreatedComment = coCreatedData?.flagComment;
    const hasTechnicalInput = coCreatedData?.status === 'SUBMITTED';
    const isMyEvaluation = userInfo?.id === evaluee?.id;
    const isContinuousEvaluation = type === CONTINUOUS_EVALUATION;
    const evalueeSectionMessage = (isSelfEvaluation || isNHSelfEvaluation)
      ? formatMessage(messages.SelfEvaluationDetailEvalueeSelfEvaluationMainText)
      : formatMessage(messages.SelfEvaluationDetailEvalueeEvaluationMainText);
    const ratedTopicsOrdered = orderBy(ratedTopics, ['ordinality']);
    const hasCoCreated = !isEmpty(coCreatedData);
    const showCoCreatedSkills = coCreatedData?.flagSkills;
    const promotionsAttachments = promotion?.attachments;
    const showPromotionsAttachments = promotionsAttachments ? attachments.concat(promotionsAttachments) : attachments;
    const evaluationsAttachments = isMyEvaluation ? attachments : showPromotionsAttachments
    const ratings = behaviors?.reduce((accumulator, current) => {
      const ratingHasNotAdded = accumulator?.every((rating) => rating?.name !== current?.rating?.name);
      const rating = current?.rating;
      rating.questions = [];
      if (ratingHasNotAdded) {
        accumulator.push(rating)
      };
      return accumulator;
    }, []);
    const customNameEvaluator = isMyEvaluation
      ? formatMessage(commonMessages.Me)
      : null;
    const backTitle = isMyEvaluation
      ? formatMessage(messages.SelfEvaluationDetailEvaluations)
      : formatMessage(messages.SelfEvaluationDetailMyTeamEvaluations);
    behaviors.forEach(element => {
      const questionToAdd = element.question;
      const targetObject = ratings.find((obj) => obj.name === element.rating.name);
      targetObject.questions.push(questionToAdd);
    });
    const ratingsOrdered = orderBy(ratings, ['ordinality'], ['desc']);

    function handleBreadcrumbsClick() {
      isMyEvaluation
        ? goToPath(getEvaluationsLocation())
        : goToPath(getTeamEvaluationsLocation());
    };

    return (
      <div className="evaluation-detail">
        <BreadcrumbHeader
          breadCrumbsProps={{
            active: 1,
            onClick: handleBreadcrumbsClick,
            values: [backTitle, title],
          }}
        />
        <ContentCard
          className="evaluation-detail__content"
          title={SELF_RETROSPECTIVE}
          contentPage
        >
          <ContentCard.Body>
            <div className="evaluation-detail__info">
              <div className="evaluation-detail__section">
                <div className="evaluation-detail__evaluee-name-section">
                  <UserAvatar
                    height={AVATAR_SIZE}
                    profileImageCode={evaluee?.profileImageCode || evaluee?.imageCode}
                    width={AVATAR_SIZE}
                  />
                  <div className="evaluation-detail__main-text">
                    {evalueeSectionMessage}
                    <span className="evaluation-detail__evaluee-name">
                      {evalueeFullName}
                    </span>
                  </div>
                </div>
              </div>
              <EvaluationDetailScore
                actionMessage={formatMessage(messages.SelfEvaluationDetailWrittenBy)}
                className="evaluation-detail__section"
                coCreated={{
                  coCreatedData,
                  hasTechnicalInput,
                  showCoCreatedComment,
                }}
                comment={comment.trimStart()}
                evaluator={evaluator}
                isNHSelfEvaluation={isNHSelfEvaluation}
                isMyEvaluation={isMyEvaluation}
                score={!isContinuousEvaluation && formatScore(score)}
                scoreDescription={scoreDescription}
                showtooltip
                title={formatMessage(messages.SelfEvaluationDetailPerformanceScore)}
              />
              <BehavioralAssessmentDetail
                behaviors={ratingsOrdered}
                title={formatMessage(messages.SelfEvaluationDetailBehavioralAssessment)}
              />
              <div className="evaluation-detail__section">
                {(ratedTopicsOrdered.length > 0) &&
                  <div className="evaluation-detail__info">
                    <Title className="text-black" nomargin size={16}>
                      {formatMessage(messages.SelfEvaluationDetailSkills)}
                    </Title>
                    <RatedTopics ratedTopicsList={
                      (hasCoCreated && hasTechnicalInput && !showCoCreatedSkills) && !isMyEvaluation
                        ? coCreatedTopics
                        : ratedTopicsOrdered
                    }
                    />
                  </div>
                }
                <AttachedFilesDetails
                  attachments={evaluationsAttachments}
                  className="p-0 relative [&>h3]:text-base"
                />
              </div>
            </div>
          </ContentCard.Body>
          <CardFooter
            actionMessage={formatMessage(messages.SelfEvaluationDetailSendBy)}
            className="evaluation-detail__footer"
            user={evaluator}
            customName={customNameEvaluator}
          />
        </ContentCard>
      </div>
    );
  }
}

SefRetrospectiveDetail.propTypes = {
  coCreatedTopics: PropTypes.array,
  evaluationDetail: PropTypes.object,
  getEvaluationDetail: PropTypes.shape({
    attachments: PropTypes.array,
    behaviors: PropTypes.array,
    comment: PropTypes.string,
    evaluator: PropTypes.object,
    evaluee: PropTypes.object,
    promotion: PropTypes.object,
    ratedTopics: PropTypes.array,
    score: PropTypes.number,
    type: PropTypes.string,
  }),
  goToPath: PropTypes.func,
  scoresDescription: PropTypes.array,
  userInfo: PropTypes.object,
  intl: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  evaluationDetail: selectEvaluationDetail(state),
  scores: selectScores(state),
  userInfo: selectUserInfo(state),
});

const goToPath = (path) => push(path);

export default connect(mapStateToProps, { ...actions, goToPath })(
  injectIntl(SefRetrospectiveDetail)
);
