import {
  UPDATE_MY_TEAM_EVALUATIONS_FILTER_CONFIG,
} from './actionTypes';

export function updateFilterConfig(filterConfig) {
  return {
    type: UPDATE_MY_TEAM_EVALUATIONS_FILTER_CONFIG,
    payload: { filterConfig },
  };
}
