import { defineMessages } from 'react-intl';

export default defineMessages({
  MyTeamDashboardTitle: {
    id: 'Betterme.MyTeam.DashboardTitle',
    defaultMessage: 'My team\'s dashboard',
  },
  MyTeamFeedbacksTitle: {
    id: 'Betterme.MyTeam.FeedbacksTitle',
    defaultMessage: 'My team\'s feedback',
  },
  MyTeamGoalsTitle: {
    id: 'Betterme.MyTeam.GoalsTitle',
    defaultMessage: 'My team\'s goals',
  },
  MyTeamEvaluationsTitle: {
    id: 'Betterme.MyTeam.EvaluationsTitle',
    defaultMessage: 'My team\'s evaluations',
  },
  MyTeamEvaluationsListTitle: {
    id: 'Betterme.MyTeam.EvaluationsListTitle',
    defaultMessage: '{scoreName} Evaluations',
  },
  MyTeamPromotionsTitle: {
    id: 'Betterme.MyTeam.PromotionsTitle',
    defaultMessage: 'My team\'s promotions',
  },
  MyTeamNoPermissions: {
    id: 'Betterme.MyTeam.NoPermissions',
    defaultMessage: 'It seems you don\'t have permission to this section.',
  },
  MyTeamNoPermissionsSub: {
    id: 'Betterme.MyTeam.NoPermissionsSub',
    defaultMessage: 'But you can continue learning about your colleagues by visiting other sections.',
  },
});
