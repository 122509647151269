// @packages
import { omit } from 'lodash';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';

// @own
import DateFilter from './DateFilter';
import {
  PERIOD_ALL,
  PERIOD_CURRENT,
  PERIOD_SIX,
  PERIOD_THREE,
} from './constants';

function handleCalendarChange({ endDate, startDate }, trackDateFilterCalendar) {
  const endDateStr = endDate ? endDate.format('DD-MM-YYYY') : 'unselected';
  const startDateStr = startDate ? startDate.format('DD-MM-YYYY') : 'unselected';
  const label = `${startDateStr} - ${endDateStr}`;

  trackDateFilterCalendar(label);
}

function handleDropdownChange({ endDate, startDate }, periods, value, trackDateFilterDropdown) {
  const { trackLabel } = periods[value];

  trackDateFilterDropdown(trackLabel);
}

function handleDateChange(
  { endDate, startDate, dropdownValue, dropdownLabel },
  periods,
  trackDateFilterCalendar,
  trackDateFilterDropdown,
  onDateChange,
) {
  if (dropdownValue !== undefined) {
    handleDropdownChange({ endDate, startDate}, periods, dropdownValue, trackDateFilterDropdown);
  } else {
    handleCalendarChange({ endDate, startDate }, trackDateFilterCalendar);
  }

  onDateChange({
    dropdownLabel,
    endDate: endDate && endDate.endOf('day'),
    startDate: startDate && startDate.startOf('day'),
  });
}

function parseEvalPeriod(evaluationPeriod, label) {
  const { startTime, endTime } = evaluationPeriod;

  return {
    endDate: moment(endTime).utc(),
    label,
    startDate: moment(startTime).utc(),
    trackLabel: PERIOD_CURRENT,
  };
}

const PeriodDateFilter = ({
  className,
  datePickerTexts,
  defaultPeriodDateFilter,
  dropdownPlaceholder,
  endDate,
  evaluationPeriod,
  noStyles,
  onDateChange,
  periodLabels,
  startDate,
  title,
  trackDateFilterCalendar,
  trackDateFilterDropdown,
}) => {
  const defaultPeriods = [
    {
      endDate: moment(),
      label: periodLabels.six,
      startDate: moment().subtract(6, 'months'),
      trackLabel: PERIOD_SIX,
    },
    {
      endDate: moment(),
      label: periodLabels.three,
      startDate: moment().subtract(3, 'months'),
      trackLabel: PERIOD_THREE,
    },
    {
      endDate: null,
      label: periodLabels.all,
      startDate: null,
      trackLabel: PERIOD_ALL,
    },
  ];
  const evalPeriodLabel = periodLabels.current;
  const periods = evaluationPeriod
    ? [parseEvalPeriod(evaluationPeriod, evalPeriodLabel), ...defaultPeriods]
    : defaultPeriods;

  return (
    <DateFilter
      className={className}
      dateOptions={periods.map(period => omit(period, ['trackLabel']))}
      defaultPeriodDateFilter={defaultPeriodDateFilter}
      endDate={endDate}
      datePickerTexts={datePickerTexts}
      dropdownPlaceholder={dropdownPlaceholder}
      noStyles={noStyles}
      onDateChange={(dateValues) =>
        handleDateChange(
          dateValues,
          periods,
          trackDateFilterCalendar,
          trackDateFilterDropdown,
          onDateChange
        )
      }
      startDate={startDate}
      title={title}
    />
  );
};

PeriodDateFilter.defaultProps = {
  periodLabels: {
    all: 'All',
    current: 'Current period',
    six: 'Last 6 months',
    three: 'Last 3 months',
  },
  datePickerTexts: {
    endDatePlaceholder: 'dd/mm/yyyy',
    startDatePlaceholder: 'dd/mm/yyyy',
  },
  trackDateFilterCalendar: () => undefined,
  trackDateFilterDropdown: () => undefined,
};

PeriodDateFilter.propTypes = {
  className: PropTypes.string,
  datePickerTexts: PropTypes.shape({
    endDatePlaceholder: PropTypes.string,
    startDatePlaceholder: PropTypes.string,
  }).isRequired,
  defaultPeriodDateFilter: PropTypes.number,
  dropdownPlaceholder: PropTypes.string,
  endDate: PropTypes.object,
  evaluationPeriod: PropTypes.object,
  onDateChange: PropTypes.func.isRequired,
  noStyles: PropTypes.bool,
  periodLabels: PropTypes.shape({
    all: PropTypes.string,
    current: PropTypes.string,
    six: PropTypes.string,
    three: PropTypes.string,
  }).isRequired,
  startDate: PropTypes.object,
  title: PropTypes.string,
  trackDateFilterCalendar: PropTypes.func,
  trackDateFilterDropdown: PropTypes.func,
};

export default PeriodDateFilter;
