// @package
import React from 'react';
import PropTypes from 'prop-types';
import Image from 'smu-ui-components/Image';
import Button from 'smu-ui-components/ButtonV2';
import Segment from 'smu-ui-components/Segment';

// @app
import Title from 'components/atoms/Title';
import BeKind from 'betterme-components/assets/bekind.svg';

// @own
import './styles.scss';

const BekindMessage = ({
  imageCode,
  loading,
  onClick,
  messages: {
    button,
    text,
    title,
  }
}) => (
  <Segment
    loading={loading}
    loadingType="betterme"
  >
    <div className="be-kind">
      <div className="be-kind__left">
        {/* <Image
          height={80}
          profileImageCode={imageCode}
          scale={1}
          width={75}
        /> */}
        <img src={BeKind} />
      </div>
      <div className="be-kind__content">
        <div className="be-kind__text">
          <Title
            black
            className="be-kind__title"
            nomargin
            size={22}
          >
            {title}
          </Title>
         <div className="be-kind__message">
           {text}
          </div>
        </div>
        <div className="be-kind__right">
          <Button
            className="be-kind__button"
            color="tag-competencies"
            onClick={onClick}
          >
            {button}
          </Button>
        </div>
      </div>
    </div>
  </Segment>
);

BekindMessage.propTypes = {
  imageCode: PropTypes.string,
  loading: PropTypes.bool,
  messages: PropTypes.object,
  onClick: PropTypes.func,
};

export default BekindMessage;
