// @packages
import { EVENT } from 'smu-app-components/OneOnOne/analytics';
import { trackEvent } from 'smu-utils/gtm';

export const trackEventBackClick = () => {
  trackEvent({
    action: EVENT.ACTION.CLICK,
    category: EVENT.CATEGORY.MEETING_DETAIL,
    label: 'go_back',
  });
};
