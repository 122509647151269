// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Divider from 'smu-ui-components/Divider';

// @own
import './styles.scss';
import messages from '../messages';
import CompetencyDetail from './CompetencyDetail';

const CompetenciesDetails = ({
  competency,
  intl: { formatMessage },
}) => {
  const {
    competency: competencyDetail,
    questions: competencyQuestions,
  } = competency;

  return (
    <div className="competencies-details">
      <div className='competencies-details__header'>
        <h1 className='competencies-details__header-title'>
          {competencyDetail.name}
        </h1>
        <div className='competencies-details__header-total'>
          <span className='competencies-details__header-total-responses'>
            {formatMessage(messages.FeedbackForLeadersReceivedTotalResponses)}:
            <strong className='competencies-details__header-black'>
              {competency.count}
            </strong>
          </span>
          <span className='competencies-details__total-rates'>
            {formatMessage(messages.FeedbackForLeadersReceivedTotalRate)}:
            <strong className='competencies-details__header-black'>
              {competency.totalRate}
            </strong>
          </span>
        </div>
      </div>
      <Divider className='competencies-details__divider-top' />

      {competencyQuestions.map((question, index) => (
        <CompetencyDetail
          key={index}
          question={question}
          labelRate={formatMessage(messages.FeedbackForLeadersReceivedRates)}
        />
      ))}
    </div>
  );
};

CompetenciesDetails.propTypes = {
  competency: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
}

export default injectIntl(CompetenciesDetails);
