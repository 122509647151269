// @packages
import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'smu-ui-components/Checkbox';
import TextWrapper from 'smu-ui-components/TextWrapper';

// @app
import DescriptionTooltip from 'common/DescriptionTooltip';

// @own
import './styles.scss';

const SuggestedActionItem = ({
  className,
  description,
  name,
  onChange,
  selected,
}) => {
  return (
    <label className={cn('suggested-action-item', className)}>
      <Checkbox
        checked={selected}
        className="suggested-action-item__checkbox"
        onChange={onChange}
      />
      <DescriptionTooltip
        description={description || name}
      >
        <TextWrapper className="suggested-action-item__name">
          {name}
        </TextWrapper>
      </DescriptionTooltip>
    </label>
  );
};

SuggestedActionItem.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  selected: PropTypes.bool,
};

export default SuggestedActionItem;
