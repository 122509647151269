import { takeLatest, call, put } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import {
  getDashboardSuggestions,
} from '../../api';
import {
  GET_DASHBOARD_SUGGESTIONS,
} from './actionTypes';
import {
  getDashboardSuggestionsFail,
  getDashboardSuggestionsSuccess,
} from './actions';

function* getDashboardSuggestionsWorker({ payload }) {
  try {
    const response = yield call(getDashboardSuggestions, payload);
    yield put(getDashboardSuggestionsSuccess(response));
  } catch (error) {
    yield put(getDashboardSuggestionsFail(error));
    yield put(addToastMessage({ message: error.message, timeout: 3000 }));
  }
}

export default function* dashboardWatcher() {
  yield takeLatest(GET_DASHBOARD_SUGGESTIONS, getDashboardSuggestionsWorker);
}
