export const GET_SURVEY_QUESTIONS = 'GET_SURVEY_QUESTIONS';
export const GET_SURVEY_QUESTIONS_SUCCESS = 'GET_SURVEY_QUESTIONS_SUCCESS';
export const GET_SURVEY_QUESTIONS_FAIL = 'GET_SURVEY_QUESTIONS_FAIL';
export const GET_SURVEY_RATES = 'GET_SURVEY_RATES';
export const GET_SURVEY_RATES_SUCCESS = 'GET_SURVEY_RATES_SUCCESS';
export const GET_SURVEY_RATES_FAIL = 'GET_SURVEY_RATES_FAIL';
export const RESET_SURVEY_QUESTIONS = 'RESET_SURVEY_QUESTIONS';
export const RESET_SURVEY_RATES = 'RESET_SURVEY_RATES';
export const SET_DRAFT_BEHAVIORS = 'SET_DRAFT_BEHAVIORS';
export const RESET_DRAFT_BEHAVIORS = 'RESET_DRAFT_BEHAVIORS';
