import { defineMessages } from 'react-intl';

export default defineMessages({
  FocalsAdminMainLeader: {
    id: 'Betterme.FocalsAdmin.MainLeader2',
    defaultMessage: 'Career Mentor',
  },
  FocalsAdminSecondaryLeaders: {
    id: 'Betterme.FocalsAdmin.AccountLeaders2',
    defaultMessage: 'Experience Leaders',
  },
  FocalsAdminOtherLeaders: {
    id: 'Betterme.FocalsAdmin.OtherLeaders2',
    defaultMessage: 'Experience Leaders',
  },
  FocalsAdminLeadershipAdmin: {
    id: 'Betterme.FocalsAdmin.LeadershipAdmin',
    defaultMessage: 'Leadership Admin',
  },
  FocalsAdminBreadcrumb: {
    id: 'Betterme.FocalsAdmin.Breadcrumb',
    defaultMessage: 'Manage Leaders',
  },
  FocalsAdminAddMainLeader: {
    id: 'Betterme.FocalsAdmin.AddMainLeader2',
    defaultMessage: 'Add Career Mentor',
  },
  FocalsAdminAddSecondaryLeaders: {
    id: 'Betterme.FocalsAdmin.AddAccountLeaders2',
    defaultMessage: 'Add Experience Leaders',
  },
  FocalsAdminAddOtherLeaders: {
    id: 'Betterme.FocalsAdmin.AddOtherLeaders2',
    defaultMessage: 'Add Experience Leaders',
  },
  FocalsAdminRemove: {
    id: 'Betterme.FocalsAdmin.Remove',
    defaultMessage: 'Remove',
  },
  FocalsAdminReplace: {
    id: 'Betterme.FocalsAdmin.Replace',
    defaultMessage: 'Replace',
  },
  FocalsAdminSecondaryLeaderTooltip: {
    id: 'Betterme.FocalsAdmin.MainLeaderTooltip2',
    defaultMessage: 'You only have permissions to manage Experience Leaders',
  },
  FocalsAdminMainLeaderTooltip: {
    id: 'Betterme.FocalsAdmin.SecondaryLeaderTooltip2',
    defaultMessage: 'You only have permissions to manage Career Mentors',
  },
  FocalsAdminEmptyStateTitle: {
    id: 'Betterme.FocalsAdmin.EmptyStateTitle',
    defaultMessage: 'It doesn’t seem you have <br /> access to this page.'
  },
  FocalsAdminEmptyStateDescription: {
    id: 'Betterme.FocalsAdmin.EmptyStateDescription',
    defaultMessage: 'If you believe you should have permission, <br /> please contact your Administrator.',
  },
  FocalsAdminRemoveLeaderToastSuccessMessage: {
    id: 'Betterme.FocalsAdmin.RemoveLeaderToastSuccess',
    defaultMessage: 'The leader was succesfully removed.'
  },
});
