
export function handleSuggestedActionSelect(
  suggestedAction,
  dataEvaluation,
  setDataCreateEvaluation,
  trackEvaluationsActionsSuggested,
) {
  let newSuggestedActionsIds;

  trackEvaluationsActionsSuggested(suggestedAction?.name, dataEvaluation?.type);

  if (dataEvaluation?.suggestedActionsIds?.includes(suggestedAction?.id)) {
    newSuggestedActionsIds = removeSuggestedActionSelect(suggestedAction?.id, dataEvaluation);
  } else {
    newSuggestedActionsIds = addSuggestedActionSelect(suggestedAction?.id, dataEvaluation);
  }

  const newDataEvaluation = {
    ...dataEvaluation,
    suggestedActionsIds: newSuggestedActionsIds,
  }

  return setDataCreateEvaluation({ ...newDataEvaluation });
};

function addSuggestedActionSelect(suggestedActionId, dataEvaluation) {
  return ([...dataEvaluation?.suggestedActionsIds, suggestedActionId]);
};

function removeSuggestedActionSelect(suggestedActionId, dataEvaluation) {
  return (dataEvaluation?.suggestedActionsIds.filter(
    suggestedActionsIds => suggestedActionsIds !== suggestedActionId
  ));
};