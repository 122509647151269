// @packages
import React from 'react';
import { injectIntl } from 'react-intl';

// @app
import AssignedGoals from '../AssignedGoals';
import { goalsMessages, userMessages } from 'common/messages';

// @own
import messages from './messages';

const MyAssignedGoals = ({
  intl: { formatMessage },
  location,
  session: { user },
}) => {
  const messagesEmptyStates = {
    openGoals: {
      main: formatMessage(goalsMessages.GoalsNoInProgress),
      suggestion: formatMessage(goalsMessages.GoalsAskLeader),
    },
    closedGoals: {
      main: formatMessage(goalsMessages.GoalsNoClosed),
      suggestion: formatMessage(messages.FeedbackMyAssignedGoalsClosedSuggestion),
    },
  };

  return (
    <AssignedGoals
      location={location}
      messagesEmptyStates={messagesEmptyStates}
      navigateBackLabel={formatMessage(userMessages.UserMyProfile)}
      navigateBackTo="/myprofile"
      user={user}
    />
  );
};

export default injectIntl(MyAssignedGoals);
