// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

// @app
import DownloadFiles from 'betterme-components/components/organisms/DownloadFile';
import {
  selectFile,
  selectFileId,
  selectFileLoading,
} from 'betterme-components/services/DownloadFile/selectors';
import { commonMessages, promotionsMessages } from 'common/messages';
import { fileDownload, fileDownloadReset } from 'betterme-components/services/DownloadFile/actions';

const DonwloadFilesContainer = ({
  attachments,
  className,
  file,
  fileDownload,
  fileDownloadReset,
  fileId,
  fileLoading,
  intl: { formatMessage },
  ...rest
}) => {

  const messages = {
    title: formatMessage(commonMessages.AttachedFiles),
    noFilesAttached: formatMessage(promotionsMessages.PromotionsNoFilesAttached),
  };

  return (
    <DownloadFiles
      attachments={attachments}
      className={className}
      file={file}
      fileDownload={fileDownload}
      fileDownloadReset={fileDownloadReset}
      fileId={fileId}
      fileLoading={fileLoading}
      messages={messages}
      {...rest}
    />
  );
};

DonwloadFilesContainer.propTypes = {
  attachments: PropTypes.array.isRequired,
  className: PropTypes.string,
  file: PropTypes.object.isRequired,
  fileDownload: PropTypes.func.isRequired,
  fileDownloadReset: PropTypes.func.isRequired,
  fileId: PropTypes.number,
  fileLoading: PropTypes.bool,
  intl: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  file: selectFile(state),
  fileId: selectFileId(state),
  fileLoading: selectFileLoading(state),
});

const mapDispatchToProps = {
  fileDownload,
  fileDownloadReset,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DonwloadFilesContainer));
