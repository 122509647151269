// @packages
import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Image from 'smu-ui-components/Image';

// @own
import './styles.scss';

const EmptyStateView = ({
  big,
  children,
  className,
  image,
  newStyle,
  text,
  title,
}) => {
  return (
    <div className={cn('empty-state-view', className, {
      'empty-state-view--big': big,
      'empty-state-view--new-style': newStyle,
    })}>
      <div className="empty-state-view__image-wrapper">
        <Image
          className="empty-state-view__image"
          imageUrl={image}
        />
      </div>
      <div className="empty-state-view__description">
        <div className="empty-state-view__description-title">
          {title}
        </div>
        <div className="empty-state-view__description-text">
          {text}
        </div>
        {children && (
          <div className="empty-state-view__description-content">
            {children}
          </div>
        )}
      </div>
    </div>
  );
};

EmptyStateView.defaultProps = {
  newStyle: false,
}

EmptyStateView.propTypes = {
  children: PropTypes.node,
  image: PropTypes.string.isRequired,
  newStyle: PropTypes.bool,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default EmptyStateView;
