// @packages
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Button from 'smu-ui-components/ButtonV2';
import Collapsible from 'smu-ui-components/Collapsible';

// @own
import './styles.scss';

const ActionButtonsWithOptions = ({ buttons, className }) => {
  const getInitialMessage = () => {
    const buttonWithOptions = buttons.find((button) => button?.options);
    return buttonWithOptions?.options[0]?.message;
  };

  const [buttonGroupText, setButtonGroupText] = useState(getInitialMessage());

  useEffect(() => {
    const buttonWithOptions = buttons.find((button) => (button?.options));
    const getOptionFromButton = buttonWithOptions.getOption;
    if (getOptionFromButton) {
      getOptionFromButton(buttonGroupText);
    }
  }, [buttonGroupText]);

  const [expanded, setExpanded] = useState(false);
  const renderButton = (button, index) => {
    if (button?.options) {
      const onOptionClick = (option) => {
        setExpanded(!expanded);
        return setButtonGroupText(option.message);
      };

      return (
        <div className={cn('action-buttons-whit-options', className)}>
          <Collapsible
            className="action-buttons-whit-options__collapsible"
            colorButtonGroup="black"
            expanded={expanded}
            title={
              <Button
                {...button}
                className="action-buttons-whit-options__button"
                key={index}
                size="large"
              >
                {buttonGroupText}
              </Button>
            }
            onClick={() => setExpanded(!expanded)}
            primary
            withIcon
          >
            {button?.options
              .filter((option) => option.message !== buttonGroupText)
              .map((option, index) => (
                <p
                  key={index}
                  className={cn(
                    'action-buttons-whit-options__option',
                    className,
                    { 'action-buttons-whit-options__option--visible': expanded },
                  )}
                  onClick={() => onOptionClick(option)}
                >
                  {option.message}
                </p>
              ))}
          </Collapsible>
        </div>
      );
    } else {
      return (
        <Button
          {...button}
          className="action-buttons-whit-options__button"
          key={index}
          size="large"
        >
          {button.label}
        </Button>
      );
    }
  };
  return (
    <div className={cn('action-buttons-whit-options', className)}>
      {buttons.map((button, index) => renderButton(button, index))}
    </div>
  );
};

ActionButtonsWithOptions.propTypes = {
  buttons: PropTypes.array.isRequired,
  className: PropTypes.string,
};

export default ActionButtonsWithOptions;
