// @packages
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @app
import AssessmentCards from 'components/organisms/AssessmentCards';
import { handleSliceQuestions } from 'utils/assessmentHelper';

// @own
import messages from './messages';

const AssessmentQuestionsCards = ({
  assessmentQuestions,
  cardClassName,
  className,
  currentTab,
  handleQuestions,
  intl: { formatMessage },
  options,
  questionsSlicedSize,
}) => {
  const [assessmentSlicedQuestions, setSlicedQuestions] = useState
    (handleSliceQuestions(assessmentQuestions, questionsSlicedSize));

  const handleOptionChange = (newResponse) => {
    const { answer, cardId } = newResponse;
    const currentQuesitons = assessmentSlicedQuestions[currentTab];
    const newResponseArray = currentQuesitons.map((value) =>
      (value.id === cardId)
        ? { ...value, answer }
        : value
    );
    const newSlicedQuestions = assessmentSlicedQuestions.map((value, index) =>
      (index === currentTab)
        ? newResponseArray
        : value
    );

    setSlicedQuestions(newSlicedQuestions);
    handleQuestions({ answer, id: cardId });
  }

  return (
    <div className={cn('assessment-questions-cards', className)} >
      {assessmentSlicedQuestions[currentTab].map(
        ({ name, id, answer }, index) => (
          <AssessmentCards
            cardId={id}
            cardLabel={name}
            cardOptions={options}
            className={cardClassName}
            counter={formatMessage(messages.Counter,
              {
                index: (currentTab * questionsSlicedSize + index + 1),
                length: assessmentQuestions.length,
              },
            )}
            key={id}
            onOptionChange={handleOptionChange}
            selectedOption={answer}
          />
        )
      )}
    </div>
  );
}

AssessmentQuestionsCards.propTypes = {
  assessmentQuestions: PropTypes.arrayOf
    (PropTypes.object).isRequired,
  currentTab: PropTypes.number.isRequired,
  handleQuestions: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
};

export default injectIntl(AssessmentQuestionsCards);
