import {
  REQUEST_COMMUNITY_CONFIGS,
  REQUEST_COMMUNITY_CONFIGS_SUCCESS,
  REQUEST_COMMUNITY_CONFIGS_FAIL,
} from './actionTypes';

export function requestCommunityConfigs(payload) {
  return {
    type: REQUEST_COMMUNITY_CONFIGS,
    payload,
  };
}

export function requestCommunityConfigsSuccess(payload) {
  return {
    type: REQUEST_COMMUNITY_CONFIGS_SUCCESS,
    payload,
  };
}

export function requestCommunityConfigsFail(payload) {
  return {
    type: REQUEST_COMMUNITY_CONFIGS_FAIL,
    payload,
  };
}
