import { defineMessages } from 'react-intl';

export default defineMessages({
  LeadershipAdminLeadershipAdmin: {
    id: 'Betterme.LeadershipAdmin.LeadershipAdmin',
    defaultMessage: 'Leadership Admin',
  },
  LeadershipAdminAddMeAs: {
    id: 'Betterme.LeadershipAdmin.AddMeAs',
    defaultMessage: 'Add me as',
  },
  LeadershipAdminMainLeader: {
    id: 'Betterme.LeadershipAdmin.MainLeader',
    defaultMessage: 'Main Leader',
  },
  LeadershipAdminSecondaryLeaders: {
    id: 'Betterme.LeadershipAdmin.SecondaryLeaders',
    defaultMessage: 'Secondary Leaders',
  },
  LeadershipAdminSecondaryLeader: {
    id: 'Betterme.LeadershipAdmin.SecondaryLeader',
    defaultMessage: 'Secondary Leader',
  },
  LeadershipAdminCareerLeader: {
    id: 'Betterme.LeadershipAdmin.CareerLeader2',
    defaultMessage: 'Career Mentor',
  },
  LeadershipAdminAccountLeaders: {
    id: 'Betterme.LeadershipAdmin.AccountLeaders2',
    defaultMessage: 'Experience Leaders',
  },
  LeadershipAdminOtherLeaders: {
    id: 'Betterme.LeadershipAdmin.OtherLeaders2',
    defaultMessage: 'Experience Leaders',
  },
  LeadershipAdminAccountLeader: {
    id: 'Betterme.LeadershipAdmin.AccountLeader2',
    defaultMessage: 'Experience Leader',
  },
  LeadershipAdminChangeLeaderToastSuccess: {
    id: 'Betterme.LeadershipAdmin.ToastSuccess',
    defaultMessage: 'Your leadership request was sent successfully',
  },
  LeadershipAdminChangeLeaderToastFail: {
    id: 'Betterme.LeadershipAdmin.ToastFail',
    defaultMessage: 'We couldn’t change the leader',
  },
  LeadershipAdminReplaceText: {
    id: 'Betterme.LeadershipAdmin.ReplaceText',
    defaultMessage: 'Replace',
  },
  LeadershipAdminRemoveText: {
    id: 'Betterme.LeadershipAdmin.RemoveText',
    defaultMessage: 'Remove',
  },
  LeadershipAdminAccountLeaderBoxTitle: {
    id: 'Betterme.LeadershipAdmin.AccountLeaderBoxTitle',
    defaultMessage: 'No body here yet.',
  },
  LeadershipAdminAccountLeaderBoxDescription: {
    id: 'Betterme.LeadershipAdmin.AccountLeaderBoxDescription',
    defaultMessage: 'It’s fine! A leader should soon show up in this area.',
  },
  LeadershipAdminAddLeaderPopup: {
    id: 'Betterme.LeadershipAdmin.AddLeaderPopup',
    defaultMessage: 'You are not suggested as an Experience Leader for this user.',
  }
});
