// @packages
import { takeLatest, call, put } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import { openModal, closeModal } from 'smu-app-components/RootModal/actions';

// @app
import { CHANGE_LEADER_SUCCESS_MODAL } from '../constants/modalTypes';
import { leadershipRequest } from '../api';

// @own
import { LEADERSHIP_REQUEST } from './actionTypes';
import {
  leadershipRequestFail,
  leadershipRequestSuccess
} from './actions';
import { APPROVED } from './constants';

function* leadershipRequestWorker({ payload: { collaborator, requestId, status } }) {
  try {
    const response = yield call(leadershipRequest, requestId, status);
    yield put(leadershipRequestSuccess(response));
    if (status === APPROVED) { 
      yield put(
        openModal({
          modalType: CHANGE_LEADER_SUCCESS_MODAL,
          modalProps: { user: collaborator }
        })
      );
    } else {
      yield put(closeModal());
    }
  } catch (error) {
    yield put(leadershipRequestFail(error.message));
    yield put(closeModal());
    yield put(
      addToastMessage({ type: 'error', message: error.message, timeout: 3000 })
    );
  }
}

export default function* leadershipChangeWatcher() {
  yield takeLatest(LEADERSHIP_REQUEST, leadershipRequestWorker);
}
