// @app
import { castStringToBoolean } from 'utils/fotmatString';
import { ENABLED_BETTERME } from 'betterme-components/services/CommunityConfigs/constants';

export const isEnabledPerformance = ({ result }) =>
  castStringToBoolean(result?.[ENABLED_BETTERME]);

export const convertUserSMUToBME = (data) => {
  return {
    access: {},
    account: data.result.account,
    birthDate: data.result.birthDateMilisec,
    communityId: data.additionalInfo?.organization?.id,
    email: data.result.email,
    enabled: data.result.enabled,
    firstName: data.result.firstName,
    id: data.result.id,
    identification: data.result.identification,
    job: data.result.job,
    lastName: data.result.lastName,
    office: data.result.office.name,
    orgEntryDate: data.result.orgEntryDateMilisec,
    profileImageCode: data.result.profileImageCode,
    profile: {
      locale: {
        id: data.result.language?.id,
        language: data.result.language?.code,
      },
    },
    project: data.result.project,
    roles: data.result.roles,
    uid: data.result.uid,
  };
};

export const getRedirectUrl = ({
  href = window.location.href,
  isEnabledPerformance,
  isEnabledSurveysOnly,
}) => {
  const url = new URL(href);

  if (
    !isEnabledPerformance &&
    isEnabledSurveysOnly &&
    (url.pathname === '/' || url.pathname === '/myprofile')
  ) {
    url.pathname = '/questionnaires';
    return url.href;
  }

  if (url.pathname === '/') {
    url.pathname = '/myprofile';
    return url.href;
  }

  return null;
};
