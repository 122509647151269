// @packages
import React from 'react';

// @app

// @own
import Evaluations from '../Evaluations';

const MyEvaluations = ({
  router: { location },
  session: { user },
}) => {

  return (
    <Evaluations
      location={location}
      own
      user={user}
    />
  );
};

export default MyEvaluations;
