import { defineMessages } from 'react-intl';

export default defineMessages({
  FeedbackForLeadersPrimaryText: {
    id: 'Betterme.CallToActions.FeedbackForLeaders.PrimaryText',
    defaultMessage: 'You have until',
  },
  FeedbackForLeadersActionText: {
    id: 'Betterme.CallToActions.FeedbackForLeaders.ActionText',
    defaultMessage: 'Generate Feedback for Leaders',
  },
  PotentialAssessmentPrimaryText: {
    id: 'Betterme.CallToActions.PotentialAssessment.PrimaryText',
    defaultMessage: 'You have until',
  },
  PotentialAssessmentActionText: {
    id: 'Betterme.CallToActions.PotentialAssessment.ActionText',
    defaultMessage: 'Generate Potential Assessment',
  },
});
