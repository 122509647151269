export const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
export const AUTHENTICATE_USER_FAIL = 'AUTHENTICATE_USER_FAIL';
export const AUTHENTICATE_USER_SUCCESS = 'AUTHENTICATE_USER_SUCCESS';
export const GET_DRAFT_SELF_EVALUATION = 'GET_DRAFT_SELF_EVALUATION';
export const GET_DRAFT_SELF_EVALUATION_FAIL = 'GET_DRAFT_SELF_EVALUATION_FAIL';
export const GET_DRAFT_SELF_EVALUATION_SUCCESS = 'GET_DRAFT_SELF_EVALUATION_SUCCESS';
export const GET_FEEDBACK_FOR_LEADERS_RATINGS = 'GET_FEEDBACK_FOR_LEADERS_RATINGS';
export const GET_FEEDBACK_FOR_LEADERS_RATINGS_FAIL = 'GET_FEEDBACK_FOR_LEADERS_RATINGS_FAIL';
export const GET_FEEDBACK_FOR_LEADERS_RATINGS_SUCCESS = 'GET_FEEDBACK_FOR_LEADERS_RATINGS_SUCCESS';
export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_USER_INFO_FAIL = 'GET_USER_INFO_FAIL';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const GET_USER_TEAM_INFO = 'GET_USER_TEAM_INFO';
export const GET_USER_TEAM_INFO_FAIL = 'GET_USER_TEAM_INFO_FAIL';
export const GET_USER_TEAM_INFO_SUCCESS = 'GET_USER_TEAM_INFO_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const REFRESH_USER_INFO = 'REFRESH_USER_INFO';
export const REFRESH_USER_INFO_SUCCESS = 'REFRESH_USER_INFO_SUCCESS';
export const RESET_DRAFT_SELF_EVALUATION = 'RESET_DRAFT_SELF_EVALUATION';
export const SET_COMMUNITY_INFO = 'SET_COMMUNITY_INFO';
export const SET_COMMUNITY_INFO_FAIL = 'SET_COMMUNITY_INFO_FAIL';
export const UPDATE_PROFILE_IMAGE = 'UPDATE_PROFILE_IMAGE';
export const UPDATE_PROFILE_IMAGE_FAIL = 'UPDATE_PROFILE_IMAGE_FAIL';
export const UPDATE_PROFILE_IMAGE_SUCCESS = 'UPDATE_PROFILE_IMAGE_SUCCESS';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const UPDATE_USER_INFO_FAIL = 'UPDATE_USER_INFO_FAIL';
export const UPDATE_USER_INFO_SUCCESS = 'UPDATE_USER_INFO_SUCCESS';
