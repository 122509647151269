// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

// @app
import Stepper from "common/Stepper";
import Step from "common/Step";

// @own
import './styles.scss';
import messages from '../messages';

const FormStepper = ({
  isFinishedCompetencies,
  isFinishedInsights,
  intl: { formatMessage },
  onChangeSection,
}) => {

  return (
    <div className="form-stepper">
      <Stepper
        className="form-stepper__list"
        vertical
        direction="column"
      >
        <Step
          active={!isFinishedCompetencies}
          className="form-stepper__item"
          finished={isFinishedCompetencies}
          onClick={() => { onChangeSection(1) }}
          title={formatMessage(messages.SendFeedbackForLeadersCompetencies)}
        />
        <Step
          active={isFinishedCompetencies && !isFinishedInsights}
          className="form-stepper__item"
          disabled={!isFinishedCompetencies}
          finished={isFinishedInsights}
          onClick={() => { onChangeSection(2) }}
          title={formatMessage(messages.SendFeedbackForLeadersInsights)}
        />
        <Step
          active={isFinishedInsights}
          className="form-stepper__item"
          disabled={!isFinishedInsights}
          icon="send-promotion"
          title={formatMessage(messages.SendFeedbackForLeadersSendFeedback)}
        />
      </Stepper>
    </div>
  );

};

FormStepper.propTypes = {
  isFinishedCompetencies: PropTypes.bool.isRequired,
  isFinishedInsights: PropTypes.bool.isRequired,
  onChangeSection: PropTypes.func.isRequired,
}

export default injectIntl(FormStepper);
