// @packages
import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

// @own
import './styles.scss';

const StackedList = ({ className, competencies }) => {
  return (
    <ul className={cn('stacked-list', className)}>
      {competencies.map(competency =>
        <li
          className="stacked-list__item"
          key={competency.id}
        >
          {competency.name}
        </li>
      )}
    </ul>
  );
};

StackedList.propTypes = {
  className: PropTypes.string,
  competencies: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
};

export default StackedList;
