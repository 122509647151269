import {
  GET_LEADERSHIP_ADMIN_USER_PROFILE_FAIL,
  GET_LEADERSHIP_ADMIN_USER_PROFILE_SUCCESS,
  GET_LEADERSHIP_ADMIN_USER_PROFILE,
} from './actionTypes';

export function getLeadershipAdminUserProfile(identification) {
  return {
    type: GET_LEADERSHIP_ADMIN_USER_PROFILE,
    payload: { identification },
  };
}

export function getLeadershipAdminUserProfileFail(error) {
  return {
    type: GET_LEADERSHIP_ADMIN_USER_PROFILE_FAIL,
    payload: { error },
  };
}

export function getLeadershipAdminUserProfileSuccess(response) {
  return {
    type: GET_LEADERSHIP_ADMIN_USER_PROFILE_SUCCESS,
    payload: { response },
  };
}
