// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @own
import './styles.scss';
import StackedRatesBar from './StackedRatesBar';

const StackedChartsSummary = ({
  className,
  rates,
  title,
}) => (
  <div className={cn('stacked-charts-summary', className)}>
    <div className="stacked-charts-summary__title">
      {title}
    </div>
    <div className="stacked-charts-summary__charts">
      {rates && rates.map((rate, key) => (
        <StackedRatesBar {...rate} key={key} />
      ))}
    </div>
  </div>
);

StackedChartsSummary.propTypes = {
  className: PropTypes.string,
};

export default StackedChartsSummary;
