import {
  GET_FEEDBACKS_REPORT,
  GET_FEEDBACKS_REPORT_FAIL,
  GET_FEEDBACKS_REPORT_SUCCESS,
  SELECT_ITEM_SECTION_LIST,
} from './actionTypes';

export function getFeedbacksReport(userId, fileType, from, to) {
  return {
    type: GET_FEEDBACKS_REPORT,
    payload: {
      creationTimeFrom: from,
      creationTimeTo: to,
      userId,
      fileType,
    },
  };
}

export function getFeedbacksReportFail(error) {
  return {
    type: GET_FEEDBACKS_REPORT_FAIL,
    payload: { error },
  };
}

export function getFeedbacksReportSuccess(response) {
  return {
    type: GET_FEEDBACKS_REPORT_SUCCESS,
    payload: { response },
  };
}
