// @packages
import React, { useEffect } from 'react';
import Modal from 'smu-app-components/WhatsNew/components/Modal';
import PropTypes from 'prop-types';
import RootModal from 'smu-app-components/RootModal';
import { SMU_APP_MODAL_WHATS_NEW } from 'smu-app-components/WhatsNew/components/Modal/constants';
import { connect } from 'react-redux';
import { closeModal, openModal } from 'smu-app-components/RootModal/actions';
import { withRouter } from 'react-router';

// @app
import { STAR_SELECTOR_VERSION } from 'betterme-components/services/CommunityConfigs/constants';
import {
  getCommunityConfigValueBool,
  getCommunityConfigValue,
} from 'betterme-components/services/CommunityConfigs/selectors';
import { selectCommunityId } from 'betterme-components/Authorization/selectors';

// @own
import { MODAL_QUERY_WHATS_NEW } from './constants';

const WhatsNewModal = RootModal(Modal, SMU_APP_MODAL_WHATS_NEW, {}, '');

function WhatsNewModalContainer({
  closeModal,
  communityId,
  enabledStarMeUp,
  location,
  openModal,
  router,
  starSelectorVersion,
}) {
  const modal = location?.query?.modal;
  const isWhatsNew = modal === MODAL_QUERY_WHATS_NEW;
  const isGBL = communityId === 2;
  const showModal = enabledStarMeUp && isGBL && starSelectorVersion === 'V2';

  const handleCloseModal = () => {
    router.push(location.pathname);
    closeModal();
  };

  const handleOpenModal = () =>
    openModal({
      modalType: SMU_APP_MODAL_WHATS_NEW,
      modalProps: {
        onClose: handleCloseModal,
        version: '6',
      },
    });

  useEffect(() => {
    if (isWhatsNew && showModal) {
      handleOpenModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWhatsNew, showModal]);

  return <WhatsNewModal />;
}

WhatsNewModalContainer.propTypes = {
  closeModal: PropTypes.func.isRequired,
  communityId: PropTypes.number,
  enabledStarMeUp: PropTypes.bool,
  location: PropTypes.shape({
    query: PropTypes.shape({
      modal: PropTypes.string,
    }),
  }),
  openModal: PropTypes.func.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
  starSelectorVersion: PropTypes.string,
};

const mapStateToProps = (state) => ({
  communityId: selectCommunityId(state),
  enabledStarMeUp: getCommunityConfigValueBool(state, STAR_SELECTOR_VERSION),
  starSelectorVersion: getCommunityConfigValue(
    state,
    STAR_SELECTOR_VERSION
  ),
});

export default connect(mapStateToProps, { closeModal, openModal })(
  withRouter(WhatsNewModalContainer)
);
