// @packages
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import scrollToComponent from 'react-scroll-to-component';
import { isEmpty } from 'lodash';
import orderBy from 'lodash/orderBy';
import InputArea from 'smu-ui-components/InputArea';
import InputRadio from 'smu-ui-components/InputRadio';
import Collapsible from 'smu-ui-components/Collapsible';
import UserAvatar from 'smu-ui-components/UserAvatar';

// @app
import AttachedFilesDetails from 'common/AttachedFilesDetails';
import BreadcrumbHeader from 'components/organisms/BreadcrumbHeader';
import CardFooter from 'common/CardFooter';
import ContentCard from 'common/ContentCard';
import RatedTopics from 'components/organisms/RatedTopics';
import UserAvatarList from 'common/UserAvatarList';
import formatScore from 'utils/formatScore';
import { isEnabledTalentMatrix } from 'betterme-components/TalentMatrix/selectors';
import { formatPeriodDates } from 'utils/formatPeriodDates';
import { getTeamEvaluationsLocation, getEvaluationsLocation } from 'betterme-components/routes/urls'
import {
  evaluationsMessages,
  feedbackMessages,
  commonMessages,
  promotionsMessages,
} from 'common/messages';
import {
  selectConfigurations,
  selectCustomScoreDefinition,
  selectUserInfo,
} from 'betterme-components/Authorization/selectors';
import ActionButtons from 'components/molecules/ActionButtons';
import {
  NH_EVALUATION,
  NH_SELF_EVALUATION,
  SELF_EVALUATION,
} from 'betterme-components/Evaluations/constants';
import TalentMatrix from 'betterme-components/TalentMatrix';
import { getTalentMatrixDimensions } from 'betterme-components/TalentMatrix/helpers';
import { selectScores } from 'betterme-components/services/Layout/selectors';

// @own
import './styles.scss';
import * as actions from './actions';
import {
  selectCoCreatedTopics,
  selectDataCreateAnswerEvaluation,
  selectEvaluationDetail,
  selectIsFetching,
} from './selectors';
import EvaluationDetailScore from 'components/organisms/EvaluationDetailScore';
import SuggestedActions from './SuggestedActions';
import SuggestedPromotion from './SuggestedPromotion';
import messages from './messages';
import { AVATAR_SIZE, OFFSET_NAV } from './constants';

class EvaluationDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      potentialBoxOpen: true,
    };

    this.handleChangeRadio = this.handleChangeRadio.bind(this);
    this.handleChangeText = this.handleChangeText.bind(this);
    this.handleClearForm = this.handleClearForm.bind(this);
    this.handleCreateAnswerEvaluation = this.handleCreateAnswerEvaluation.bind(this);
    this.handleToggleCard = this.handleToggleCard.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
  }

  componentDidMount() {
    const { params: { evaluationid }, getEvaluationDetail } = this.props;
    if (evaluationid) {
      getEvaluationDetail(evaluationid);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { params: { evaluationid }, getEvaluationDetail } = this.props;
    const { params: { evaluationid: nextEvaluationid } } = nextProps;

    if (evaluationid !== nextEvaluationid) {
      getEvaluationDetail(nextEvaluationid);
    }
  }

  componentDidUpdate() {
    const { location: { query } } = this.props;

    if (query.showAnswer && this.answer) {
      scrollToComponent(this.answer, {
        offset: OFFSET_NAV,
        align: 'top',
      });
    }
  }

  componentWillUnmount() {
    const { resetEvaluationDetail, resetDataCreateAnswerEvaluation } = this.props;
    resetEvaluationDetail();
    resetDataCreateAnswerEvaluation();
  }

  handleChangeText(name, event) {
    const { value } = event.target;
    const cleanValue = value.trimStart();
    this.props.setDataCreateAnswerEvaluation({ [name]: cleanValue });
  }

  handleChangeRadio(event) {
    const { value } = event.target;
    this.props.setDataCreateAnswerEvaluation({ approved: value === 'true' });
  }

  handleOptionChange(event) {
    const { value } = event.target;
    this.props.setDataCreateAnswerEvaluation({ meaningfulConversation: value });
  }

  handleClearForm() {
    this.props.resetDataCreateAnswerEvaluation();
  }

  handleCreateAnswerEvaluation() {
    const {
      params: { evaluationid },
      dataCreateAnswerEvaluation,
      createAnswerEvaluation,
    } = this.props;
    createAnswerEvaluation(evaluationid, dataCreateAnswerEvaluation);
  }

  handleToggleCard(cardState) {
    return () => this.setState(
      (prevState) => ({ [cardState]: !prevState[cardState] })
    );
  }

  render() {
    const {
      allowsEvaluationsNineBox,
      allowsEvaluationsSuggestedActions,
      allowsGlbFeatures,
      coCreatedTopics,
      customScoreDefinition,
      dataCreateAnswerEvaluation: {
        comment: answerComment,
        approved: answerApproved,
        meaningfulConversation: answerMeaningfulConversation,
      },
      enableTalentMatrix,
      evaluationDetail: {
        attachments,
        answer,
        comment,
        customScore,
        dateTimeFrom,
        dateTimeTo,
        evaluator,
        evaluee,
        promotion,
        ratedTopics,
        score,
        scoreId,
        selectedNineBox,
        selectedSuggestedActions,
        title,
        type,
      },
      evaluationDetail,
      intl: { formatMessage },
      isFetching,
      userInfo,
      scores,
    } = this.props;
    const { potentialBoxOpen } = this.state;
    const { goToPath } = this.props;

    if (isEmpty(evaluationDetail)) {
      return null;
    }
    const coCreatedData = evaluationDetail?.coCreated;
    const showCoCreatedComment = coCreatedData?.flagComment;
    const showCoCreatedSkills = coCreatedData?.flagSkills;
    const ratedTopicsOrdered = orderBy(ratedTopics, ['ordinality']);
    const hasCoCreated = !isEmpty(coCreatedData);
    const hasTechnicalInput = coCreatedData?.status === 'SUBMITTED';
    const evaluators = [
      evaluator,
      coCreatedData?.evaluator,
    ];
    const evalueeFullName = `${evaluee.firstName} ${evaluee.lastName}`;
    const isNHEvaluation = type === NH_EVALUATION;
    const isNHSelfEvaluation = type === NH_SELF_EVALUATION;
    const isSelfEvaluation = type === SELF_EVALUATION || type === NH_SELF_EVALUATION;
    const isMyEvaluation = userInfo.id === evaluee.id;
    const isMySelfEvaluation = isSelfEvaluation && isMyEvaluation;
    const typeEvaluationtitle = isSelfEvaluation
      ? formatMessage(evaluationsMessages.EvaluationsSelfEvaluation)
      : formatMessage(evaluationsMessages.EvaluationsEvaluation);
    const customNameEvaluator = isMySelfEvaluation
      ? formatMessage(commonMessages.Me)
      : null;
    const isValid = answerComment.length
      && (answerApproved !== null)
      && (answerMeaningfulConversation !== null);
    const isAnswer = !!answer;
    const currentAnswerComment = isAnswer ? answer.comment : answerComment;
    const currentAnswerApproved = isAnswer ? answer.approved : answerApproved;
    const isVisible = isMyEvaluation ? true : isAnswer;
    const shouldRenderAnswer = !isSelfEvaluation && isVisible;
    const shouldRenderNineBox = selectedNineBox
      && allowsEvaluationsNineBox
      && !isMyEvaluation
      && !isNHEvaluation
      && !isSelfEvaluation;
    const shouldRenderSuggestedActions = selectedSuggestedActions
      && allowsEvaluationsSuggestedActions
      && !isMyEvaluation
      && !isSelfEvaluation;
    const shouldRenderCustomScore = customScore
      && customScoreDefinition
      && customScoreDefinition.isEnabled
      && !isNHEvaluation
      && !isSelfEvaluation;
    const shouldRenderDetailScore = !isNHEvaluation;
    const titleEvaluationBox = allowsGlbFeatures
      ? formatMessage(evaluationsMessages.EvaluationsPotentialBox)
      : formatMessage(evaluationsMessages.EvaluationsTalentReview);
    const titleGeneralRemarks = isNHEvaluation
      ? formatMessage(evaluationsMessages.EvaluationsGeneralRemarks)
      : titleEvaluationBox;
    const detailScoreTitle = formatMessage(evaluationsMessages.EvaluationsOverallScore);
    const shouldRenderPotentialBox = shouldRenderNineBox || shouldRenderSuggestedActions;
    const showEvaluationPeriod = !isNHEvaluation && !isNHSelfEvaluation;
    const headerPeriod = showEvaluationPeriod && formatPeriodDates(dateTimeFrom, dateTimeTo);
    const evalueeSectionMessage = (isSelfEvaluation || isNHSelfEvaluation)
      ? formatMessage(messages.FeedbackEvaluationDetailEvalueeSelfEvaluationMainText)
      : formatMessage(messages.FeedbackEvaluationDetailEvalueeEvaluationMainText);
    const promotionTitles = {
      currentSeniority: formatMessage(messages.FeedbackEvaluationDetailCurrentSeniority),
      justification: formatMessage(promotionsMessages.PromotionsWhyPromote),
      promoteTo: formatMessage(messages.FeedbackEvaluationDetailPromoteTo),
      suggestedPromotion: formatMessage(messages.FeedbackEvaluationDetailSuggestPromotion),
    };
    const promotionsAttachments = promotion?.attachments;
    const showPromotionsAttachments = promotionsAttachments ? attachments.concat(promotionsAttachments) : attachments;
    const evaluationsAttachments = isMyEvaluation ? attachments : showPromotionsAttachments;
    const backTitle = isMyEvaluation
      ? formatMessage(messages.FeedbackEvaluationDetailEvaluations)
      : formatMessage(messages.FeedbackEvaluationDetailMyTeamEvaluations);
    const suggestedActionsTitle = isNHEvaluation
      ? titleGeneralRemarks
      : formatMessage(evaluationsMessages.EvaluationsSuggestedActions);
    const talentMatrixValue = getTalentMatrixDimensions(
      evaluationDetail?.talentMatrix?.dimensions
    );
    const findScore = scores?.find(score => score?.id === scoreId);
    const scoreDescription = findScore?.description;

    function handleBreadcrumbsClick() {
      isMyEvaluation
        ? goToPath(getEvaluationsLocation())
        : goToPath(getTeamEvaluationsLocation());
    };

    return (
      <div className="evaluation-detail">
        <BreadcrumbHeader
          breadCrumbsProps={{
            active: 1,
            onClick: handleBreadcrumbsClick,
            values: [backTitle, title],
          }}
          date={headerPeriod}
        />
        <ContentCard
          className="evaluation-detail__content"
          title={typeEvaluationtitle}
          contentPage
        >
          <ContentCard.Body>
            <div className="evaluation-detail__info">
              <div className="evaluation-detail__section">
                <div className="evaluation-detail__evaluee-name-section">
                  <UserAvatar
                    height={AVATAR_SIZE}
                    profileImageCode={evaluee.profileImageCode || evaluee.imageCode}
                    width={AVATAR_SIZE}
                  />
                  <div className="evaluation-detail__main-text">
                    {evalueeSectionMessage}
                    <span className="evaluation-detail__evaluee-name">
                      {evalueeFullName}
                    </span>
                  </div>
                </div>
              </div>
              {(ratedTopicsOrdered.length > 0) &&
                <div className="evaluation-detail__section">
                  <h3 className="evaluation-detail__section-title">
                    {formatMessage(feedbackMessages.FeedbackCompetencies)}
                  </h3>
                  <RatedTopics ratedTopicsList={
                    (hasCoCreated && hasTechnicalInput && !showCoCreatedSkills) && !isMyEvaluation
                      ? coCreatedTopics
                      : ratedTopicsOrdered
                  }
                  />
                </div>
              }
              {shouldRenderDetailScore && (
                <EvaluationDetailScore
                  actionMessage={formatMessage(messages.FeedbackEvaluationDetailWrittenBy)}
                  className="evaluation-detail__section"
                  coCreated={{
                    coCreatedData,
                    hasTechnicalInput,
                    showCoCreatedComment,
                  }}
                  comment={comment.trimStart()}
                  evaluator={evaluator}
                  isNHSelfEvaluation={isNHSelfEvaluation}
                  isMyEvaluation={isMyEvaluation}
                  score={formatScore(score)}
                  scoreDescription={scoreDescription}
                  showtooltip
                  title={isNHSelfEvaluation
                    ? formatMessage(evaluationsMessages.EvaluationsGeneralRemarks)
                    : detailScoreTitle
                  }
                />
              )}
              {!isMyEvaluation && promotion && (
                <SuggestedPromotion
                  className="evaluation-detail__promotions"
                  promotion={promotion}
                  titles={promotionTitles}
                />
              )}
              {!isNHEvaluation && (
                <AttachedFilesDetails
                  attachments={evaluationsAttachments}
                  className="evaluation-detail__section"
                />
              )}
              {shouldRenderCustomScore && (
                <EvaluationDetailScore
                  className="evaluation-detail__section"
                  score={customScore.message}
                  title={customScore.name}
                />
              )}
              {enableTalentMatrix && talentMatrixValue?.length > 0 && (
                <div className="evaluation-detail__section">
                  <TalentMatrix
                    evaluee={evaluee}
                    readOnly
                    value={talentMatrixValue}
                  />
                </div>
              )}
              {shouldRenderPotentialBox && (
                <div className="evaluation-detail__potential-box">
                  <Collapsible
                    expanded={potentialBoxOpen}
                    onClick={this.handleToggleCard('potentialBoxOpen')}
                    title={suggestedActionsTitle}
                    variant="box"
                    withIcon
                  >
                    {shouldRenderNineBox && (
                      <EvaluationDetailScore
                        className="evaluation-detail__section"
                        comment={selectedNineBox.comment}
                        score={selectedNineBox.nineBoxScore.name}
                        scoreDescription={selectedNineBox.nineBoxScore.description}
                        showtooltip
                        title={formatMessage(evaluationsMessages.EvaluationsNineBoxScore)}
                      />
                    )}
                    {shouldRenderSuggestedActions && (
                      <SuggestedActions
                        {...selectedSuggestedActions}
                        title={formatMessage(evaluationsMessages.EvaluationsSuggestedActions)}
                        noTitle
                      />
                    )}
                  </Collapsible>
                </div>
              )}
              {hasCoCreated ? (
                <div className="evaluation-detail__co-evaluation-footer">
                  <UserAvatarList
                    className="evaluation-detail__co-evaluation-footer-avatar"
                    maxDisplay={4}
                    showPopup
                    size={32}
                    users={evaluators}
                  />
                  <p className="evaluation-detail__co-evaluation-footer-text">
                    <FormattedHTMLMessage
                      defaultMessage={messages.EvaluationsDetailEvaluationSentBy.defaultMessage}
                      id={messages.EvaluationsDetailEvaluationSentBy.id}
                      values={{
                        evaluator: `${evaluator?.firstName} ${evaluator?.lastName}`,
                        coEvaluator: `${coCreatedData?.evaluator?.firstName} ${coCreatedData?.evaluator?.lastName}`,
                      }}
                    />
                  </p>
                </div>
              ) : (
                <CardFooter
                  actionMessage={formatMessage(messages.FeedbackEvaluationDetailSendBy)}
                  className="evaluation-detail__footer"
                  user={evaluator}
                  customName={customNameEvaluator}
                />
              )}
            </div>
          </ContentCard.Body>
        </ContentCard>
        {shouldRenderAnswer && (
          <ContentCard
            contentPage
            className="evaluation-detail__content"
            loading={isFetching}
            ref={(answer) => (this.answer = answer)}
            title={formatMessage(messages.FeedbackEvaluationDetailReplyTitle)}
          >
            <ContentCard.Body>
              <div className="evaluation-detail__info">
                <div className="evaluation-detail__section">
                  {(!isAnswer ||
                    (isAnswer && currentAnswerApproved)) && (
                      <InputRadio
                        checked={currentAnswerApproved === true}
                        disabled={isAnswer}
                        label={formatMessage(
                          messages.FeedbackEvaluationDetailLabelAgree
                        )}
                        name="evaluation"
                        onChange={(event) => this.handleChangeRadio(event)}
                        value={true}
                      />
                    )}
                  {(!isAnswer || (isAnswer && !currentAnswerApproved)) && (
                    <InputRadio
                      checked={currentAnswerApproved === false}
                      disabled={isAnswer}
                      label={formatMessage(
                        messages.FeedbackEvaluationDetailLabelDisagree
                      )}
                      name="evaluation"
                      onChange={(event) => this.handleChangeRadio(event)}
                      value={false}
                    />
                  )}
                  <InputArea
                    disabled={isAnswer}
                    maxLength={!isAnswer ? 1200 : null}
                    onChange={(event) =>
                      this.handleChangeText("comment", event)
                    }
                    placeholder={formatMessage(
                      messages.FeedbackEvaluationDetailPlaceholderComment
                    )}
                    rows={7}
                    value={currentAnswerComment}
                  />
                </div>
                {!isAnswer && (
                  <div className="evaluation-detail__actions">
                    <ActionButtons
                      buttons={[
                        {
                          label: formatMessage(messages.FeedbackEvaluationDetailClear),
                          onClick: this.handleClearForm,
                          variant: 'outline',
                        },
                        {
                          disabled: !isValid,
                          label: formatMessage(messages.FeedbackEvaluationDetailAnswer),
                          onClick: this.handleCreateAnswerEvaluation,
                        },
                      ]}
                    />
                  </div>
                )}
              </div>
            </ContentCard.Body>
          </ContentCard>
        )}
      </div>
    );
  }
}

EvaluationDetail.propTypes = {
  coCreatedTopics: PropTypes.array,
  enableTalentMatrix: PropTypes.bool,
  evaluationDetail: PropTypes.object,
  getEvaluationDetail: PropTypes.func,
  goToPath: PropTypes.func,
  scoresDescription: PropTypes.array,
  talentMatrixEnabled: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const evaluationDetail = selectEvaluationDetail(state);

  return {
    allowsContinuousEvaluation: selectConfigurations('allowsContinuousEvaluation')(state),
    allowsEvaluationsNineBox: selectConfigurations('allowsEvaluationsNineBox')(state),
    allowsEvaluationsSuggestedActions: selectConfigurations('allowsEvaluationsSuggestedActions')(state),
    allowsGlbFeatures: selectConfigurations('allowsGlbFeatures')(state),
    allowsNewHireEvaluationCreation: selectConfigurations('allowsNewHireEvaluationCreation')(state),
    allowsNewHireEvaluationDraft: selectConfigurations('allowsNewHireEvaluationDraft')(state),
    allowsNewHireSelfEvaluationCreation: selectConfigurations('allowsNewHireSelfEvaluationCreation')(state),
    allowsNewHireSelfEvaluationDraft: selectConfigurations('allowsNewHireSelfEvaluationDraft')(state),
    coCreatedTopics: selectCoCreatedTopics(state),
    customScoreDefinition: selectCustomScoreDefinition(state),
    dataCreateAnswerEvaluation: selectDataCreateAnswerEvaluation(state),
    enableTalentMatrix: isEnabledTalentMatrix(state),
    evaluationDetail,
    isFetching: selectIsFetching(state),
    isGoalScoreContinous: selectConfigurations('continuousGoalScores')(state),
    scores: selectScores(state),
    userInfo: selectUserInfo(state),
  }
};

const goToPath = (path) => push(path);

export default connect(mapStateToProps, { ...actions, goToPath })(
  injectIntl(EvaluationDetail)
);
