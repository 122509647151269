// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { base64Encode } from 'smu-utils/base64';
import Suggestions from 'smu-app-components/Suggestions';
import Panel from 'smu-ui-components/Panel';

// @app

// @own
import isCollaborator from '../../utils/isCollaborator';
import messages from '../messages';
import {
  trackSuggestedUserProfileClick,
  trackSuggestedUserFeedbackClick,
} from '../analytics';

// @ api
import { getSuggestions } from '../../api';

const handleSuggestionClick = (user, position, navigate) => {
  trackSuggestedUserFeedbackClick(position);
  navigate(user);
};

const FeedbackSuggestions = ({ goToSendRequestFeedback, hasAccessProfiles, intl }) => {
  const messagesFeedbackSuggestions = {
    suggestionsTitle: intl.formatMessage(messages.FeedbackRightColumnSuggestions),
  };

  return (
    <Panel>
      <span className="text-base font-bold uppercase font-montserrat text-loblolly block mb-[5px]">
        {messagesFeedbackSuggestions.suggestionsTitle}
      </span>
      <Suggestions
        api={getSuggestions}
        onAvatarMediaClick={trackSuggestedUserProfileClick}
        onClick={(user, position) => handleSuggestionClick(user, position, goToSendRequestFeedback)}
        hasAccessProfiles={hasAccessProfiles}
        checkAccessProfile={isCollaborator}
      />
    </Panel>
  );
};

FeedbackSuggestions.propTypes = {
  goToSendRequestFeedback: PropTypes.func.isRequired,
  hasAccessProfiles: PropTypes.bool
};

const mapDispatchToProps = (dispatch) => {
  return {
    goToSendRequestFeedback: (user) => {
      const urlId = base64Encode(user.identification);
      const pathname = `/newfeedback/${urlId}`;
      const locationDescriptor = {
        pathname,
        state: { user },
      };
      dispatch(push(locationDescriptor));
    },
  };
};

export default connect(null, mapDispatchToProps)(injectIntl(FeedbackSuggestions));
