// @packages
import jwtDecode from 'jwt-decode';
import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import { getItem } from 'smu-utils/storage';
import { navigateToLoginV2 } from 'smu-utils/globalRequestHelper';
import { apiGetUserWithToken } from 'smu-services/starmeup-api/v2/sec/userwithtoken/api';

// @app
import { getAssessmentCollaborators } from 'betterme-components/services/Assessment/actions';
import { getCommunityPreferences } from 'betterme-components/services/CommunityConfigs/api';
import { getPasswordProperties } from 'betterme-components/services/AdminPassword/actions';
import { requestCommunityConfigs } from 'betterme-components/services/CommunityConfigs/actions';

// @own
import {
  selectConfigurations,
  selectUserInfo,
} from './selectors';
import {
  AUTHENTICATE_USER,
  GET_DRAFT_SELF_EVALUATION,
  GET_FEEDBACK_FOR_LEADERS_RATINGS,
  GET_USER_TEAM_INFO,
  LOGOUT,
  REFRESH_USER_INFO,
  UPDATE_PROFILE_IMAGE,
  UPDATE_USER_INFO,
} from './actionTypes';
import {
  authenticateUserFail,
  authenticateUserSuccess,
  getDraftSelfEvaluationFail,
  getDraftSelfEvaluationSuccess,
  getFeedbackForLeadersRaitings as getFeedbackForLeadersRaitingsAction,
  getFeedbackForLeadersRaitingsFail,
  getFeedbackForLeadersRaitingsSuccess,
  getUserInfo,
  getUserInfoSuccess,
  getUserTeamInfoFail,
  getUserTeamInfoSuccess,
  logout,
  refreshUserInfoSuccess,
  updateProfileImageFail,
  updateProfileImageSuccess,
  updateUserInfo as updateUserInfoAction,
  updateUserInfoFail,
  updateUserInfoSuccess,
} from './actions';
import {
  getCommunityConfigurations,
  getCommunityRatings,
  getComunityEvaluations,
  getProfile,
  putImageToSMU,
  updateUserInfo,
} from '../api';
import {
  FEEDBACK_FOR_LEADERS,
  INVALID_TOKEN,
  INVALID_USER,
  SELF_EVALUATION,
  SELF_RETROSPECTIVE,
  TOKEN_NOT_FOUND,
} from './constants';
import { convertUserSMUToBME, isEnabledPerformance } from './helpers';

function* authenticateUserWorker({ meta, payload }) {
  const { resumeNavigation } = meta;
  try {
    yield call(getCookieAuthTokenWorker);
    const { identification } = yield call(decodeToken);
    const preferencesData = yield call(getCommunityPreferences);
    yield put(requestCommunityConfigs({ data: preferencesData }));
    resumeNavigation();
    const enabledPerformance = isEnabledPerformance(preferencesData);

    if (enabledPerformance) {
      yield call(getUserInfoWorker, getUserInfo(identification));
    } else {
      yield call(getUserInfoWorker, {
        payload: { identification, enabledPerformance },
      });
    }

    yield put(authenticateUserSuccess());
  } catch (error) {
    yield put(authenticateUserFail(error.message));
  }
}

function* getCookieAuthTokenWorker() {
  try {
    const token = yield call(getItem, 'token');
    const baseUrl = yield call(getItem, 'baseUrl');

    if (!baseUrl || !token || token === 'undefined') {
      yield put(logout());
      throw new Error(TOKEN_NOT_FOUND);
    }
  } catch (error) {
    throw new Error(TOKEN_NOT_FOUND);
  }
}

function* decodeToken() {
  try {
    const token = yield call(getItem, 'token');
    const result = yield call(jwtDecode, token);
    return result;
  } catch (error) {
    throw new Error(INVALID_TOKEN);
  }
}

export function* getUserInfoWorker({ payload }, action) {
  const { enabledPerformance = true, identification } = payload;
  
  try {
    let user;
    if (enabledPerformance) {
      user = yield call(getProfile, identification, true);
    } else {
      const smuUSER = yield call(apiGetUserWithToken, {
        origin: 'SMU_WEB_v3',
        version: 3,
      });
      user = convertUserSMUToBME(smuUSER);
    }
    const communityConfigurations = yield call(
      getCommunityConfigurations,
      user.communityId
    );
    const {
      allowsFeedbackForLeaders,
      allowsFeedbackForLeadersResult,
      allowsPotentialAssessment,
    } = communityConfigurations.configurations;

    if (allowsFeedbackForLeaders || allowsFeedbackForLeadersResult) {
      yield put(getFeedbackForLeadersRaitingsAction());
    }

    yield put(getPasswordProperties());
    yield put(getUserInfoSuccess(user, communityConfigurations));
    yield put(refreshUserInfoSuccess());

    if (allowsPotentialAssessment) {
      yield put(getAssessmentCollaborators());
    }

    if (action) {
      action();
    }
  } catch (error) {
    yield put(addToastMessage({ type: 'error', message: error.message }));
    if (error.status >= 500) {
      throw error;
    }
    throw new Error(INVALID_USER);
  }
}

export function* logoutWorker() {
  yield call(navigateToLoginV2, 'SMU_FEEDBACK_WEB');
}

export function* refreshUserInfoWorker({ payload, action }) {
  const { identification } = payload;
  yield call(getUserInfoWorker, getUserInfo(identification), action);
}

export function* updateProfileImageWorker({ payload: { image } }) {
  const { id: userId } = yield select(selectUserInfo);

  try {
    const response = yield call(putImageToSMU, image, image.name);
    yield put(updateProfileImageSuccess(response));
    yield put(updateUserInfoAction(userId, response.id, response.code));
  } catch (error) {
    yield put(updateProfileImageFail(error));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

export function* updateUserInfoWorker({ payload }) {
  try {
    const response = yield call(updateUserInfo, [payload]);
    yield put(updateUserInfoSuccess(response[0]));
  } catch (error) {
    yield put(updateUserInfoFail(error));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

export function* getDraftEvaluationWorker({ payload }) {
  const allowsEvaluations = yield select(selectConfigurations('allowsEvaluations'));
  const allowsSelfRetrospectiveDraft = yield select(selectConfigurations('allowsSelfRetrospectiveDraft'));
  const allowsSelfRetrospectiveCreation = yield select(selectConfigurations('allowsSelfRetrospectiveCreation'));
  const allowsNewHireSelfEvaluationDraft = yield select(selectConfigurations('allowsNewHireSelfEvaluationDraft'));
  const allowsNewHireSelfEvaluationCreation = yield select(selectConfigurations('allowsNewHireSelfEvaluationCreation'));
  const allowsNewHireEvaluationDraft = yield select(selectConfigurations('allowsNewHireEvaluationDraft'));
  const allowsNewHireEvaluationCreation = yield select(selectConfigurations('allowsNewHireEvaluationCreation'));
  const allowsPerformanceRetrospectiveDraft = yield select(selectConfigurations('allowsPerformanceRetrospectiveDraft'));
  const allowsPerformanceRetrospectiveCreation = yield select(selectConfigurations('allowsPerformanceRetrospectiveCreation'));
  const { evaluator, evaluee, isCollaborator, isNewHire } = payload;
  const selfEvaluationType = (isNewHire && (allowsNewHireSelfEvaluationDraft || allowsNewHireSelfEvaluationCreation))
    ? 'NH_SELF_EVALUATION'
    : allowsEvaluations && (
      (allowsSelfRetrospectiveDraft || allowsSelfRetrospectiveCreation) 
        ? SELF_RETROSPECTIVE
        : SELF_EVALUATION);
  const evaluationType = ((allowsNewHireEvaluationDraft || allowsNewHireEvaluationCreation) && isNewHire)
    ? 'NH_EVALUATION'
    : allowsEvaluations && (
      (allowsPerformanceRetrospectiveDraft || allowsPerformanceRetrospectiveCreation) 
        ? 'PERFORMANCE_RETROSPECTIVE'
        : 'EVALUATION');
  const type = isCollaborator ? evaluationType : selfEvaluationType;
  const params = {
    currentPeriod: true,
    evaluator,
    evaluee,
    pageSize: 1,
    status: 'DRAFT',
    types: type,
  };
  const isDraftActive = (allowsEvaluations
    || allowsSelfRetrospectiveDraft
    || allowsNewHireSelfEvaluationDraft
    || allowsNewHireEvaluationDraft
    || allowsPerformanceRetrospectiveDraft);

  try {
    const response = isDraftActive ? yield call(getComunityEvaluations, params) : [];
    yield put(getDraftSelfEvaluationSuccess(response));
  } catch (error) {
    yield put(getDraftSelfEvaluationFail(error));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

export function* getUserTeamInfoWorker({ payload }) {
  const { identification } = payload;

  try {
    const response = yield call(getProfile, identification, true);
    yield put(getUserTeamInfoSuccess(response));
  } catch (error) {
    yield put(getUserTeamInfoFail(error));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

export function* getFeedbackForLeadersRaitingsWorker() {
  const params = { type: FEEDBACK_FOR_LEADERS };

  try {
    const response = yield call(getCommunityRatings, params);
    yield put(getFeedbackForLeadersRaitingsSuccess(response));
  } catch (error) {
    yield put(getFeedbackForLeadersRaitingsFail(error));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

export default function* authorizeSagas() {
  yield takeLatest(AUTHENTICATE_USER, authenticateUserWorker);
  yield takeLatest(LOGOUT, logoutWorker);
  yield takeLatest(REFRESH_USER_INFO, refreshUserInfoWorker);
  yield takeLatest(UPDATE_PROFILE_IMAGE, updateProfileImageWorker);
  yield takeLatest(UPDATE_USER_INFO, updateUserInfoWorker);
  yield takeLatest(GET_DRAFT_SELF_EVALUATION, getDraftEvaluationWorker);
  yield takeLatest(GET_USER_TEAM_INFO, getUserTeamInfoWorker);
  yield takeLatest(GET_FEEDBACK_FOR_LEADERS_RATINGS, getFeedbackForLeadersRaitingsWorker);
}
