import { GALICIA_ID } from '../Authorization/constants';
import { evaluationsMessages } from 'common/messages';

const getMessageType = (communityId) => (messageName) => {
  const messageType = communityId === GALICIA_ID
    ? evaluationsMessages[`${messageName}Glc`]
    : evaluationsMessages[messageName];

  return messageType;
};

export default getMessageType;
