// @packages
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// @app
import Main from '../../Main';
import { selectEvaluationPeriod } from '../../Authorization/selectors';

// @own
import {
  setMyGoalFilters,
  setTeamGoalFilters,
} from '../actions';
import { INITIAL_FILTERS } from '../constants';

class GoalsRouteWrapper extends Component {
  componentDidMount() {
    const {
      defaultPeriod,
      setMyGoalFilters,
      setTeamGoalFilters,
    } = this.props;

    if (defaultPeriod) {
      const { id: filterPeriodId } = defaultPeriod;

      setMyGoalFilters({ filterPeriodId });
      setTeamGoalFilters({ filterPeriodId });
    }
  }

  componentWillUnmount() {
    const { setMyGoalFilters, setTeamGoalFilters } = this.props;
    setMyGoalFilters(INITIAL_FILTERS);
    setTeamGoalFilters(INITIAL_FILTERS);
  }

  getMainProps() {
    const {
      RightColumn,
      Sidenav,
      defaultPeriod,
      mainContent,
      session,
    } = this.props;
    const goalsCurrentPeriod = defaultPeriod || {};

    return {
      Main: mainContent,
      mainProps: { goalsCurrentPeriod },
      RightColumn,
      Sidenav,
      session,
    };
  }

  render() {
    const { children, defaultPeriod, ...props } = this.props;
    const goalsCurrentPeriod = defaultPeriod || {};

    if (!children) {
      return <Main {...this.getMainProps()} />;
    }

    return React.cloneElement(children, { ...props, goalsCurrentPeriod });
  }
}

const PeriodPropType = PropTypes.shape({
  endTime: PropTypes.number,
  startTime: PropTypes.number,
  id: PropTypes.number,
}).isRequired;

GoalsRouteWrapper.propTypes = {
  children: PropTypes.number,
  defaultPeriod: PeriodPropType,
  session: PropTypes.object.isRequired,
  setMyGoalFilters: PropTypes.func.isRequired,
  setTeamGoalFilters: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  defaultPeriod: selectEvaluationPeriod(state),
});

const mapDispatchToProps = {
  setMyGoalFilters,
  setTeamGoalFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(GoalsRouteWrapper);
