// @packages
import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import LottieSpinner from 'smu-ui-components/LottieSpinner';

// @app
import InfiniteScroll from 'react-infinite-scroll-component';

const InfiniteScrollLoad = ({
  children,
  className,
  loadingType,
  ...props
}) => {
  const SPINNER_SIZE = 40;
  const loader = (
    <LottieSpinner
      className="infinite-scroll-load__loader"
      loadingType={loadingType}
      height={SPINNER_SIZE}
      width={SPINNER_SIZE}
    />
  );

  return (
    <InfiniteScroll
      {...props}
      className={cn('infinite-scroll-load', className)}
      loader={loader}
      scrollThreshold={0.9}
    >
      {children}
    </InfiniteScroll>
  );
};

InfiniteScrollLoad.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  loadingType: PropTypes.string.isRequired,
};

export default InfiniteScrollLoad;
