export const ADD_COMPETENCY_CREATE_EVALUATION = 'ADD_COMPETENCY_CREATE_EVALUATION';
export const CAN_CREATE_BEHAVIOUR_RETROSPECTIVE = 'CAN_CREATE_BEHAVIOUR_RETROSPECTIVE';
export const CLEAN_TEAM_EVALUATION_STATE = 'CLEAN_TEAM_EVALUATION_STATE';
export const CREATE_DRAFT_EVALUATION = 'CREATE_DRAFT_EVALUATION';
export const CREATE_DRAFT_EVALUATION_FAIL = 'CREATE_DRAFT_EVALUATION_FAIL';
export const CREATE_DRAFT_EVALUATION_SUCCESS = 'CREATE_DRAFT_EVALUATION_SUCCESS';
export const CREATE_EVALUATION = 'CREATE_EVALUATION';
export const CREATE_EVALUATION_FAIL = 'CREATE_EVALUATION_FAIL';
export const CREATE_EVALUATION_SUCCESS = 'CREATE_EVALUATION_SUCCESS';
export const DELETE_DRAFT_EVALUATION = 'DELETE_DRAFT_EVALUATION';
export const DELETE_DRAFT_EVALUATION_FAIL = 'DELETE_DRAFT_EVALUATION_FAIL';
export const DELETE_DRAFT_EVALUATION_SUCCESS = 'DELETE_DRAFT_EVALUATION_SUCCESS';
export const DELETE_EVALUATION_FILE = 'DELETE_EVALUATION_FILE';
export const DELETE_EVALUATION_FILE_FAIL = 'DELETE_EVALUATION_FILE_FAIL';
export const DELETE_EVALUATION_FILE_SUCCESS = 'DELETE_EVALUATION_FILE_SUCCESS';
export const EVALUATION_ADD_DELETED_SKILL = 'EVALUATION_ADD_DELETED_SKILL';
export const EVALUATION_ADD_SKILL = 'EVALUATION_ADD_SKILL';
export const EVALUATION_ADD_SKILLS = 'EVALUATION_ADD_SKILLS';
export const EVALUATION_CHANGE_SKILL = 'EVALUATION_CHANGE_SKILL';
export const EVALUATION_CLEAN_DELETED_SKILLS = 'EVALUATION_CLEAN_DELETED_SKILLS';
export const EVALUATION_CLEAN_SKILLS = 'EVALUATION_CLEAN_SKILLS';
export const EVALUATION_DELETED_SKILL = 'EVALUATION_DELETED_SKILL';
export const GET_CLOSED_ASSIGNED_GOALS = 'GET_CLOSED_ASSIGNED_GOALS';
export const GET_CLOSED_ASSIGNED_GOALS_FAIL = 'GET_CLOSED_ASSIGNED_GOALS_FAIL';
export const GET_CLOSED_ASSIGNED_GOALS_SUCCESS = 'GET_CLOSED_ASSIGNED_GOALS_SUCCESS';
export const GET_CUSTOM_SCORES = 'GET_CUSTOM_SCORES';
export const GET_CUSTOM_SCORES_FAIL = 'GET_CUSTOM_SCORES_FAIL';
export const GET_CUSTOM_SCORES_SUCCESS = 'GET_CUSTOM_SCORES_SUCCESS';
export const GET_GOALS_FINAL_SCORE = 'GET_GOALS_FINAL_SCORE';
export const GET_GOALS_FINAL_SCORE_FAIL = 'GET_GOALS_FINAL_SCORE_FAIL';
export const GET_GOALS_FINAL_SCORE_SUCCESS = 'GET_GOALS_FINAL_SCORE_SUCCESS';
export const GET_LAST_EVALUATION = 'GET_LAST_EVALUATION';
export const GET_LAST_EVALUATION_FAIL = 'GET_LAST_EVALUATION_FAIL';
export const GET_LAST_EVALUATION_SUCCESS = 'GET_LAST_EVALUATION_SUCCESS';
export const GET_LAST_SELF_EVALUATION = 'GET_LAST_SELF_EVALUATION';
export const GET_LAST_SELF_EVALUATION_FAIL = 'GET_LAST_SELF_EVALUATION_FAIL';
export const GET_LAST_SELF_EVALUATION_SUCCESS = 'GET_LAST_SELF_EVALUATION_SUCCESS';
export const GET_SUGGESTED_ACTIONS = 'GET_EVALUATION_SUGGESTED_ACTIONS';
export const GET_SUGGESTED_ACTIONS_FAIL = 'GET_EVALUATION_SUGGESTED_ACTIONS_FAIL';
export const GET_SUGGESTED_ACTIONS_SUCCESS = 'GET_EVALUATION_SUGGESTED_ACTIONS_SUCCESS';
export const GET_TEAM_ALL_EVALUATIONS = 'GET_TEAM_ALL_EVALUATIONS';
export const GET_TEAM_ALL_EVALUATIONS_FAIL = 'GET_TEAM_ALL_EVALUATIONS_FAIL';
export const GET_TEAM_ALL_EVALUATIONS_SUCCESS = 'GET_TEAM_ALL_EVALUATIONS_SUCCESS';
export const REMOVE_COMPETENCY_CREATE_EVALUATION = 'REMOVE_COMPETENCY_CREATE_EVALUATION';
export const REQUEST_EVALUATIONS = 'REQUEST_EVALUATIONS';
export const REQUEST_EVALUATIONS_FAIL = 'REQUEST_EVALUATIONS_FAIL';
export const REQUEST_EVALUATIONS_SUCCESS = 'REQUEST_EVALUATIONS_SUCCESS';
export const REQUEST_TEAM_EVALUATIONS = 'REQUEST_TEAM_EVALUATIONS';
export const REQUEST_TEAM_EVALUATIONS_FAIL = 'REQUEST_TEAM_EVALUATIONS_FAIL';
export const REQUEST_TEAM_EVALUATIONS_SUCCESS = 'REQUEST_TEAM_EVALUATIONS_SUCCESS';
export const RESET_DATA_CREATE_EVALUATION = 'RESET_DATA_CREATE_EVALUATION';
export const RESET_EVALUATION_RECENTLY_CREATED = 'RESET_EVALUATION_RECENTLY_CREATED';
export const RESET_LAST_EVALUATION = 'RESET_LAST_EVALUATION';
export const RESET_LAST_SELF_EVALUATION = 'RESET_LAST_SELF_EVALUATION';
export const SET_DATA_CREATE_EVALUATION = 'SET_DATA_CREATE_EVALUATION';
export const SET_EVALUATION_RECENTLY_CREATED = 'SET_EVALUATION_RECENTLY_CREATED';
export const SUBMIT_DRAFT_EVALUATION = 'SUBMIT_DRAFT_EVALUATION';
export const SUBMIT_DRAFT_EVALUATION_FAIL = 'SUBMIT_DRAFT_EVALUATION_FAIL';
export const SUBMIT_DRAFT_EVALUATION_SUCCESS = 'SUBMIT_DRAFT_EVALUATION_SUCCESS';
export const UPDATE_DRAFT_EVALUATION = 'UPDATE_DRAFT_EVALUATION';
export const UPDATE_DRAFT_EVALUATION_FAIL = 'UPDATE_DRAFT_EVALUATION_FAIL';
export const UPDATE_DRAFT_EVALUATION_SUCCESS = 'UPDATE_DRAFT_EVALUATION_SUCCESS';
export const UPLOAD_EVALUATION_FILE = 'UPLOAD_EVALUATION_FILE';
export const UPLOAD_EVALUATION_FILE_FAIL = 'UPLOAD_EVALUATION_FILE_FAIL';
export const UPLOAD_EVALUATION_FILE_SUCCESS = 'UPLOAD_EVALUATION_FILE_SUCCESS';
