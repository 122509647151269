// @app
import { handleServerError } from 'betterme-components/api';
import { dataServiceApiInstance } from 'betterme-components/utils/requestHelper';

export function getFeedbackForLeaders(params) {
  return dataServiceApiInstance
    .get('v1/bme/api/feedbacks/feedbackForLeaders/summary', { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
};
