// @packages
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { actionRequestInit, actionRequestDestroy } from 'smu-utils/reduxRequests/actions';
import { add as showToast } from 'smu-app-components/ToastNotifications/actions';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { openModal } from 'smu-app-components/RootModal/actions';
import { push } from 'react-router-redux';
import { selectResult } from 'smu-utils/reduxRequests/selectors';

// @app
import AddMeHereModal from 'components/containers/AddMeHereModal';
import AddMemberCard from 'components/organisms/AddMemberCard';
import ContentSection from 'components/templates/ContentSection';
import EmptyStateView from 'common/EmptyStateView';
import HeadingSection from 'components/templates/HeadingSection';
import LeadershipAdminMessages from 'components/pages/LeadershipAdmin/messages';
import SearchBar from 'components/containers/SearchBar';
import Title from 'components/atoms/Title';
import { ADD_ME_HERE_MODAL } from 'betterme-components/constants/modalTypes';
import { addToMyTeam } from 'betterme-components/services/LeadershipRequest/actions';
import { apiGetSuggestedLeaders } from 'betterme-components/services/LeadershipSubstitution/api';
import { getMyTeamDashboardLocation } from 'betterme-components/routes/urls';
import { resetChangeLeaderRequestState, updateUserLeader } from 'betterme-components/services/ChangeLeaderRequest/actions';
import { selectConfigurations } from 'betterme-components/Authorization/selectors';
import { selectSelectedUser } from 'betterme-components/services/SearchBar/selectors';
import { userMessages } from 'common/messages';
import UserInfoCard from 'components/organisms/UserInfoCard';
import AddLeaderBox from 'components/atoms/AddLeaderBox';

// @own
import {
  ADD_TO_MY_TEAM_REQUEST_AS_MAIN,
  ADD_TO_MY_TEAM_REQUEST_AS_SECONDARY,
  REQUEST_ID_GET_SUGGESTED_LEADERS,
  SECONDARY,
} from './constants';
import sendImage from './send.svg';
import messages from './messages';
import './styles.scss';

const AddMember = ({
  actionRequestDestroy,
  actionRequestInit,
  addToMyTeam,
  allowsAddAsAccountLeader,
  allowsGlbFeatures,
  allowsSuggestAccountLeaders,
  closeModal,
  intl: { formatMessage },
  navigateBack,
  openModal,
  resetChangeLeaderRequestState,
  selectedUser,
  session: { user },
  showToast,
  suggestions,
}) => {
  const isSelectedLeader = !(Object.keys(selectedUser).length === 0);
  const { access, hasCollaborators } = user;
  const { newWordingForLeaders } = access;
  const mainLeaderWording = newWordingForLeaders
    ? formatMessage(messages.AddMemberCareerLeader)
    : formatMessage(messages.AddMemberMainLeader);
  const secondaryLeaderWording = newWordingForLeaders
    ? formatMessage(messages.AddMemberAccountLeaders)
    : formatMessage(messages.AddMemberSecondaryLeaders);
  const itemsPerRow = allowsGlbFeatures ? 3 : 2;
  const isSuggestedLeader = suggestions?.leaderSuggestions?.some(
    ({ leader }) => leader?.id === user?.id,
  );
  const allowsAddSecondaryLeader =
    (allowsSuggestAccountLeaders && allowsAddAsAccountLeader)
      ? isSuggestedLeader
      : allowsAddAsAccountLeader;
  const isNotCurrentSecondaryLeader = (
    selectedUser[0]?.evaluators
    && !selectedUser[0]?.evaluators?.some(evaluator => evaluator?.id === user?.id)
  );
  const accountAddLeaderBoxMessage = allowsAddAsAccountLeader
    ? `${formatMessage(messages.AddMemberAddMeAs)} ${secondaryLeaderWording}`
    : (
      <div className="add-member__account-leader-box">
        <p className="add-member__account-leader-box-title">
          {formatMessage(messages.AddMemberAccountLeaderBoxTitle)}
        </p>
        <span className="add-member__account-leader-box-description">
          {formatMessage(messages.AddMemberAccountLeaderBoxDescription)}
        </span>
      </div>
    );

  const handleAddMeHere = (type) => {
    openModal({
      modalType: ADD_ME_HERE_MODAL,
      modalProps: {
        addToMyTeam,
        closeModal,
        collaborator: selectedUser[0],
        leaderId: user.id,
        leaderType: null,
        messages: {
          success: formatMessage(LeadershipAdminMessages.LeadershipAdminChangeLeaderToastSuccess),
          fail: formatMessage(LeadershipAdminMessages.LeadershipAdminChangeLeaderToastFail),
        },
        type,
        suggestionId: suggestions?.suggestionId,
      }
    })
  }

  function getSuggestedLeaders() {
    actionRequestInit({
      api: apiGetSuggestedLeaders,
      onError: (error) => showToast({
        message: error.message,
        timeout: 5000,
      }),
      id: REQUEST_ID_GET_SUGGESTED_LEADERS,
      params: {
        leaderType: SECONDARY,
        memberId: selectedUser?.id,
      },
    })
  };

  useEffect(() => {
    if (!isEmpty(selectedUser) && allowsSuggestAccountLeaders) {
      getSuggestedLeaders();
      return () => actionRequestDestroy(REQUEST_ID_GET_SUGGESTED_LEADERS);
    }
  }, [selectedUser?.id]);

  useEffect(() => {
    return () => resetChangeLeaderRequestState();
  }, []);

  return (
    hasCollaborators ? (
      <>
        <HeadingSection
          className="add-member"
          headerValues={[
            formatMessage(messages.AddMemberDashboard),
            formatMessage(messages.AddMemberTitle),
          ]}
          navigateBack={navigateBack}
          noMargin
        />
        <ContentSection className="add-member__section">
          <div className="add-member__searching">
            <Title
              className="text-black"
              size={18}
            >
              {formatMessage(messages.AddMemberSearchingTitle)}
            </Title>
            <SearchBar
              optionalCardTitle={formatMessage(messages.AddMemberYourSelection)}
              placeholder={formatMessage(messages.AddMemberSearchingPlaceholder)} />
            {isSelectedLeader && (
              <>
                <Title
                  className="add-member__sub-section text-black pt-12 border-t-2 mt-5"
                  size={18}
                >
                  {formatMessage(messages.AddMemberSendLeadershipRequest)}
                </Title>
                <>
                  <Title
                    className="add-member__main-leader text-black"
                    level={5}
                    size={14}
                  >
                    {mainLeaderWording}
                  </Title>
                  {selectedUser[0]?.leader ? (
                    <UserInfoCard
                      highlighted={selectedUser?.leader?.id === user?.id}
                      user={selectedUser[0]?.leader}
                      messages={{
                        account: formatMessage(userMessages.UserAccount),
                        area: formatMessage(userMessages.UserArea),
                        office: formatMessage(userMessages.UserOffice),
                        project: formatMessage(userMessages.UserProject),
                      }}
                    />
                  ) : (
                    <AddLeaderBox
                      text={`${formatMessage(messages.AddMemberAddMeAs)} ${mainLeaderWording}`}
                      onClick={() => handleAddMeHere(ADD_TO_MY_TEAM_REQUEST_AS_MAIN)}
                    />
                  )}
                  <Title
                    className="add-member__secondary-leaders text-black"
                    level={5}
                    size={14}
                  >
                    {secondaryLeaderWording}
                  </Title>
                  {selectedUser[0]?.evaluators && (
                    selectedUser[0]?.evaluators?.map((evaluator) => (
                      <UserInfoCard
                        className="add-member__user-info-card"
                        highlighted={evaluator?.id === user?.id}
                        user={evaluator}
                        messages={{
                          account: formatMessage(userMessages.UserAccount),
                          area: formatMessage(userMessages.UserArea),
                          office: formatMessage(userMessages.UserOffice),
                          project: formatMessage(userMessages.UserProject),
                        }}
                      />
                    ))
                  )}
                  {isNotCurrentSecondaryLeader && (
                    <AddLeaderBox
                      className="add-member__add-leader-box"
                      disabled={!allowsAddSecondaryLeader}
                      onClick={() => {
                        allowsAddSecondaryLeader && (
                          handleAddMeHere(
                            ADD_TO_MY_TEAM_REQUEST_AS_SECONDARY
                          )
                        )
                      }}
                      popupText={formatMessage(
                        messages.AddMemberOtherLeaderPopup
                      )}
                      text={accountAddLeaderBoxMessage}
                      withPopup={!allowsAddSecondaryLeader}
                    />
                  )}
                </>
              </>
            )}
          </div>
        </ContentSection>
        <AddMeHereModal />
      </>
    ) : (
      <ContentSection>
        <div className="add-member__no-permissions">
          <EmptyStateView
            image={sendImage}
            text={formatMessage(messages.AddMemberEmptyStateText)}
            title={formatMessage(messages.AddMemberEmptyStateTitle)}
          />
        </div>
      </ContentSection>
    )
  );
};

AddMember.propTypes = {
  actionRequestDestroy: PropTypes.func.isRequired,
  actionRequestInit: PropTypes.func.isRequired,
  addToMyTeam: PropTypes.func,
  allowsGlbFeatures: PropTypes.bool,
  closeModal: PropTypes.func,
  intl: PropTypes.object,
  navigateBack: PropTypes.func,
  openModal: PropTypes.func,
  resetChangeLeaderRequestState: PropTypes.func,
  selectedUser: PropTypes.object,
  session: PropTypes.object,
};

AddMember.defaultProps = {
  suggestions: [],
};

const navigateBack = () => push(getMyTeamDashboardLocation());

const mapDispatchToProps = {
  actionRequestDestroy,
  actionRequestInit,
  addToMyTeam,
  navigateBack,
  openModal,
  resetChangeLeaderRequestState,
  showToast,
  updateUserLeader,
};

const mapSateToProps = (state) => ({
  allowsAddAsAccountLeader: selectConfigurations('allowsAddAsAccountLeader')(state),
  allowsGlbFeatures: selectConfigurations('allowsGlbFeatures')(state),
  allowsSuggestAccountLeaders: selectConfigurations('allowsSuggestAccountLeaders')(state),
  selectedUser: selectSelectedUser(state),
  suggestions: selectResult(state, REQUEST_ID_GET_SUGGESTED_LEADERS),
});

export default connect(
  mapSateToProps,
  mapDispatchToProps
)(injectIntl(AddMember));
