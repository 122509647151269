// @packages
import React, { Component, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { injectIntl } from 'react-intl';
import FullScreenModal from 'smu-app-components/FullScreenModal';
import { closeFullscreenModal } from 'smu-app-components/FullScreenModal/actions';
import { filter } from 'lodash';
import moment from 'moment';
import Dropdown from 'smu-ui-components/Dropdown';
import Scales from 'smu-ui-components/Scales';
import Segment from 'smu-ui-components/Segment';
import InputText from 'smu-ui-components/InputText';
import InputArea from 'smu-ui-components/InputArea';
import UserCombobox from 'smu-ui-components/UserCombobox';
import Switch from 'smu-ui-components/Switch';
import Datepicker from 'smu-ui-components/Datepicker';
import UserCarousel from 'smu-ui-components/UserCarousel';
import { closeModal, openModal } from 'smu-app-components/RootModal/actions';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';

// @app
import ActionButtons from 'components/molecules/ActionButtons';
import BreadcrumbHeader from 'components/organisms/BreadcrumbHeader';
import Reasons from 'components/molecules/Reasons';
import UploadFile from 'betterme-components/components/containers/UploadFile';
import Title from 'components/atoms/Title';
import InfoIcon from 'common/InfoIcon';
import { formatBoldText } from 'utils/formatBoldText';
import { getDropdownPeriodList } from 'utils/getDropdownPeriodList';
import {
  selectConfigurations,
  selectDefaultPeriod,
  selectLeaderInfo,
} from 'betterme-components/Authorization/selectors';
import { GOAL_DETAIL_VIEW_MODAL, CREATE_GOAL_VALIDATION } from 'betterme-components/constants/modalTypes';
import { getMyTeamGoalsLocation, getMyGoalsLocation } from 'betterme-components/routes/urls';
import CreateGoalValidationModal from 'components/containers/CreateGoalValidationModal';
import {
  actionsMessages,
  goalsMessages,
  placeholdersMessages,
  promotionsMessages,
  sectionsMessages,
} from 'common/messages';
import {
  DETAILS_MAX_LENGTH,
  EXCEEDED_SIZE,
  MAX_FILES,
  MAX_FILE_SIZE,
  NO_SIZE,
  SUPPORTED_FORMATS,
  WRONG_FORMAT,
} from 'betterme-components/constants/fileValidations';
import { runValidations } from 'utils/filesAttach';
import {
  fileDelete,
  fileUpload,
  fileClear,
} from 'betterme-components/services/FileGoals/actions';
import {
  selectFiles,
  selectFilesLoading,
} from 'betterme-components/services/FileGoals/selectors';
import { selectScores } from 'betterme-components/services/Layout/selectors';

// @ownselectScores
import './styles.scss';
import OrgObjectivesSelector from '../OrgObjectivesSelector';
import {
  trackGoalsCancelCreate,
  trackGoalsCloseXCreate,
  trackGoalsDeleteClick,
} from '../analytics';
import * as actions from '../actions';
import messages from '../messages';
import {
  CREATE,
  DUPLICATE,
  EDIT,
  INDIVIDUAL,
  MAIN_LEADER,
  PENDING_APPROVAL,
  SECONDARY_LEADER,
  SHARED,
  TEAM,
} from '../../constants/goal';
import { selectAvailableWeight, selectOrgObjectives } from '../selectors';

const UploadFileComponent = ({
  className,
  disabled,
  files,
  loading,
  onChange,
  onDeleteAccept,
  supportedFormats,
}) => {
  const Component = useCallback(
    () => (
      <UploadFile
        className={className}
        disabled={disabled}
        files={files}
        loading={loading}
        onChange={onChange}
        onDeleteAccept={onDeleteAccept}
        supportedFormats={supportedFormats}
      />
    ),
    [files, loading, onChange, supportedFormats]
  );

  return <Component />;
};

class GoalsActionsDetailModal extends Component {
  constructor(props) {
    super(props);

    const {
      intl: { formatMessage },
      user,
      dataGoal,
      isMyGoals,
      leader,
      scores,
      type,
    } = this.props;
    const {
      collaborators: {
        asMain,
        asMainAndSecondary,
        asSecondary,
      },
      leaders
    } = user;
    const initScales = (dataGoal && type === EDIT)
      ? (dataGoal.scales?.map((scale) => ({
        scaleId: scale.id || 0,
        description: scale.name,
        value:
          scale.min === scale.max
            ? scale.min
            : `${scale.min} - ${scale.max}`,
        details: scale.details,
      }))) : (
        scores.map((score, index) => ({
          scaleId: score.id,
          description: score.name,
          value:
            score.min === score.max
              ? score.min
              : `${score.min} - ${score.max}`,
          details: type === DUPLICATE
            ? dataGoal?.scales[index]?.details
            : '',
        })));

    const initialDueDate = (dataGoal && dataGoal?.dueDate)
      ? moment(dataGoal?.dueDate).utc()
      : null;
    const initialStartDate = (dataGoal && dataGoal?.startDate)
      ? moment(dataGoal?.startDate).utc()
      : null;
    const selectedOrgObjectives = (dataGoal && dataGoal?.objectives)
      ? dataGoal?.objectives?.map(({ id }) => id)
      : [];
    const attachments = type !== DUPLICATE ? dataGoal?.attachments : [];
    const isOneEvaluator = leaders?.length === 1;
    const isMyGoalInitialEvaluator = isOneEvaluator
      ? leaders[0]?.id
      : '';
    const createGoalInitialEvaluator = isMyGoals ? isMyGoalInitialEvaluator : user?.id;
    const initialLeaderTypeOneEvaluator = (leaders && (leaders[0]?.id === leader?.id))
      ? MAIN_LEADER
      : SECONDARY_LEADER;
    const isMyGoalsInitialSelectedRelation = isOneEvaluator
      ? initialLeaderTypeOneEvaluator
      : '';
    const isNotMyGoalrelationType = asMain?.length ? MAIN_LEADER : SECONDARY_LEADER;
    const isMainAndSecondaryLeader = asMainAndSecondary?.length ? '' : true;
    const isMainOrSecondary = !(asMain?.length && asSecondary?.length)
      ? isNotMyGoalrelationType
      : '';
    const initialColaboratorLeaderType = isMainAndSecondaryLeader && isMainOrSecondary;
    const isMoreThanOneUser = this.getInitialUsersSelected()?.length > 1;
    const isMainLeader = this.getInitialUsersSelected()[0]?.mainLeaderId === createGoalInitialEvaluator;
    const initialCollaboratorSelectedLeaderType =
      ((this.getInitialUsersSelected()[0]?.leader?.id === user?.id)
      || (!isMoreThanOneUser && isMainLeader))
        ? MAIN_LEADER
        : SECONDARY_LEADER;
    const isNotMyGoalInitialSelectedRelation = this.getInitialUsersSelected().length
      ? initialCollaboratorSelectedLeaderType
      : initialColaboratorLeaderType;
    const initialIsOneEvaluatorSelected = (isOneEvaluator && isMyGoals) ? [leaders[0]] : '';
    const createInitialSelectedRelation = isMyGoals
      ? isMyGoalsInitialSelectedRelation
      : isNotMyGoalInitialSelectedRelation;

    this.state = {
      attachments: attachments,
      description: dataGoal?.description || '',
      dueDate: initialDueDate,
      dueDateFocused: false,
      enableSharedObjectives: false,
      evaluatorSelectedId: dataGoal?.evaluator?.id || createGoalInitialEvaluator,
      goalId: dataGoal?.id || 0,
      initialDueDate,
      isOneEvaluatorSelected: dataGoal?.evaluator
        ? [dataGoal?.evaluator]
        : initialIsOneEvaluatorSelected,
      isSharedObjetives: dataGoal?.assignment === SHARED || false,
      periodId: dataGoal?.period?.id || '',
      scales: initScales,
      selectedOrgObjectives: selectedOrgObjectives,
      selectedRelation: dataGoal?.relation || createInitialSelectedRelation,
      startDate: initialStartDate,
      startDateFocused: false,
      status: dataGoal?.status || '',
      title: dataGoal?.title || '',
      usersSelected: this.getInitialUsersSelected(),
      weight: dataGoal?.weighting || '',
    };

    this.messagesScales = {
      labels: {
        addScaleButtonLabel: formatMessage(
          messages.FeedbackGoalsScalesAddScaleButtonLabel
        ),
        valueLabel: formatMessage(messages.FeedbackGoalsScalesValueLabel),
        descriptionLabel: formatMessage(
          messages.FeedbackGoalsScalesDescriptionLabel
        ),
        detailsLabel: formatMessage(messages.FeedbackGoalsScalesDetailsLabel),
      },
      validate: {
        validateRepeat: formatMessage(
          messages.FeedbackGoalsScalesValidateRepeat
        ),
        validateEmpty: formatMessage(messages.FeedbackGoalsScalesValidateEmpty),
        validateIncorrectValue: formatMessage(
          messages.FeedbackGoalsScalesValidateIncorrectValue
        ),
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeText = this.handleChangeText.bind(this);
    this.handleChangeAddCollaborator =
      this.handleChangeAddCollaborator.bind(this);
    this.handleChangeRemoveCollaborator =
      this.handleChangeRemoveCollaborator.bind(this);
    this.handleChangeSharedObjetives =
      this.handleChangeSharedObjetives.bind(this);
    this.setRelation = this.setRelation.bind(this);
    this.setEvaluator = this.setEvaluator.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleFileAttach = this.handleFileAttach.bind(this);
    this.handleFileDelete = this.handleFileDelete.bind(this);
    this.handleSend = this.handleSend.bind(this);
    this.handleOrgObjectivesSelect = this.handleOrgObjectivesSelect.bind(this);
    this.getOutsideDateRangeDueDate =
      this.getOutsideDateRangeDueDate.bind(this);
    this.getInitialVisibleMonth = this.getInitialVisibleMonth.bind(this);
    this.getMergedListOfFiles = this.getMergedListOfFiles.bind(this);
  }

  componentDidMount() {
    const {
      allowsGoalsCreationInThePast,
      allowsGoalCreationWithSumValidation,
      intl: { formatMessage },
      getOrganizationalObjectives,
      getPeriods,
      getUserTotalWeight,
    } = this.props;
    const { periodId, usersSelected } = this.state;
    const availablePeriods = !allowsGoalsCreationInThePast || null;

    getPeriods(availablePeriods);
    getOrganizationalObjectives();

    if (allowsGoalCreationWithSumValidation) {
      usersSelected.forEach((user) =>
        getUserTotalWeight(user, periodId, {
          fail: formatMessage(messages.GoalsCreateToastError),
        })
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      goals: {
        fetchingPeriod: wasFetchingPeriod,
      },
    } = prevProps;
    const {
      allowsGoalCreationWithSumValidation,
      defaulPeriod,
      intl: { formatMessage },
      getUserTotalWeight,
      goals: {
        fetchingPeriod: isFetchingPeriod,
      },
      type,
    } = this.props;
    const { periodId: prevPeriodId } = prevState;
    const { periodId: currentPeriodId, usersSelected } = this.state;
    const shouldSetPeriodId = (type === CREATE)
      && wasFetchingPeriod
      && !isFetchingPeriod
      && defaulPeriod.id
      && !currentPeriodId;

    if (shouldSetPeriodId) {
      this.setState({ periodId: defaulPeriod.id });
    }

    if (prevPeriodId !== currentPeriodId) {
      if (allowsGoalCreationWithSumValidation) {
        usersSelected.forEach((user) =>
          getUserTotalWeight(user, currentPeriodId, {
            fail: formatMessage(messages.GoalsCreateToastError),
          })
        );
      }

      this.setTime(currentPeriodId);
    }
  }

  componentWillUnmount() {
    const {
      allowsGoalCreationWithSumValidation,
      fileClear,
      removeUserTotalWeight,
    } = this.props;

    fileClear();
    if (allowsGoalCreationWithSumValidation) {
      removeUserTotalWeight();
    }
  }

  handleChange(name, value) {
    this.setState({ [name]: value });
  }

  handleChangeText(name, event) {
    let value = event.target.value;
    if (name === 'weight') {
      value = value >= 0 && value <= 100 ? value : this.state[name];
    }
    this.setState({ [name]: value });
  }

  handleChangeAddCollaborator(id, dataUser) {
    const {
      allowsGoalCreationWithSumValidation,
      intl: { formatMessage },
      getUserTotalWeight,
    } = this.props;
    const { usersSelected, periodId } = this.state;

    this.setState({ usersSelected: [...usersSelected, dataUser] });

    if (allowsGoalCreationWithSumValidation) {
      getUserTotalWeight(dataUser, periodId, {
        fail: formatMessage(messages.GoalsCreateToastError),
      });
    }
  }

  handleChangeRemoveCollaborator(dataUser) {
    const { usersSelected } = this.state;
    const { allowsGoalCreationWithSumValidation, removeUserTotalWeight } =
      this.props;

    this.setState(
      { usersSelected: filter(usersSelected, ({ id }) => id !== dataUser.id) },
      () => {
        if (this.state.usersSelected.length < 2) {
          this.setState({ isSharedObjetives: false });
        }

        if (allowsGoalCreationWithSumValidation) {
          removeUserTotalWeight(dataUser.id);
        }
      }
    );
  }

  handleChangeSharedObjetives() {
    this.setState({ isSharedObjetives: !this.state.isSharedObjetives });
  }

  handleDelete(goalId) {
    trackGoalsDeleteClick();
    this.props.disapprovalGoal(goalId);
  }

  handleClose(event) {
    const {
      close,
      goToMyGoals,
      goToMyTeamGoals,
      isMyGoals,
      type,
    } = this.props;
    const trackGoalsClose = (event === 'cancel')
      ? trackGoalsCancelCreate
      : trackGoalsCloseXCreate;

    if (type === CREATE) {
      trackGoalsClose();
    }

    if (isMyGoals) goToMyGoals();
    else goToMyTeamGoals();

    close();
  }

  handleFileAttach(event) {
    const { target } = event;
    const { files: listFiles } = target;
    const {
      addToastMessage,
      intl: { formatMessage },
      fileUpload,
    } = this.props;
    const errorMessages = {
      [EXCEEDED_SIZE]: formatMessage(
        promotionsMessages.PromotionsErrorExceededSize,
        {
          size: MAX_FILE_SIZE,
        }
      ),
      [NO_SIZE]: formatMessage(promotionsMessages.PromotionsErrorNoSize),
      [WRONG_FORMAT]: formatMessage(
        promotionsMessages.PromotionsErrorInvalidFormat,
        {
          formats: SUPPORTED_FORMATS,
        }
      ),
    };

    if (listFiles.length) {
      const file = listFiles[0];
      const error = runValidations(file, MAX_FILE_SIZE, SUPPORTED_FORMATS);

      if (!error) {
        fileUpload(file);
        target.value = null;
      } else {
        addToastMessage({
          message: errorMessages[error],
          timeout: 3000,
        });
        target.value = null;
      }
    }
  }

  handleFileDelete(fileId) {
    const { closeModal, fileDelete } = this.props;
    const { attachments } = this.state;

    this.setState({
      ...this.state,
      attachments: attachments?.filter((item) => item.id !== fileId),
    });

    fileDelete(fileId);
    closeModal();
  }

  handleSend() {
    const {
      attachments,
      description,
      dueDate,
      evaluatorSelectedId,
      goalId,
      isOneEvaluatorSelected,
      isSharedObjetives,
      periodId,
      selectedOrgObjectives,
      selectedRelation,
      startDate,
      status,
      title,
      usersSelected,
      weight,
    } = this.state;
    const {
      createGoal,
      files,
      intl: { formatMessage },
      isMyGoals,
      openModal,
      scales,
      type,
      updateGoal,
      user,
    } = this.props;
    const sharedObjetives = isSharedObjetives ? SHARED : INDIVIDUAL;
    const typeScalesParam = type === EDIT ? 'scaleId' : 'scoreId';
    const mergedListOfFiles = this.getMergedListOfFiles(files, attachments);
    const scalesList = (scales && scales?.values)
      ? scales.values.scales.map(({ scaleId, details }) => ({
        [typeScalesParam]: scaleId,
        details,
      }))
      : null;

    if (this.isFormValid()) {
      const data = {
        assignees: usersSelected.map((user) => user.id),
        assignment: usersSelected?.length > 1 ? sharedObjetives : INDIVIDUAL,
        description,
        dueDate: dueDate.valueOf(),
        evaluatorId: evaluatorSelectedId,
        files: mergedListOfFiles.map((file) => file?.id),
        objectiveIds: selectedOrgObjectives,
        periodId,
        relation: selectedRelation,
        scales: scalesList,
        startDate: startDate.valueOf(),
        title,
        type: TEAM,
        weighting: weight,
      };

      if (isMyGoals && !isOneEvaluatorSelected) {
        openModal({
          modalType: CREATE_GOAL_VALIDATION,
          modalProps: {
            onAccept: () => createGoal(data, {
              goalCreateSuccessToastMessage:
                formatMessage(messages.GoalsCreateToastMessage),
              isMyGoals,
            }),
          }
        });
        return;
      }

      if (type === EDIT)
        updateGoal(goalId, data, user.id, status, isMyGoals);
      else
        createGoal(data, { actionGoalType: type, isMyGoals });
    }
  }

  handleOrgObjectivesSelect(id) {
    const { selectedOrgObjectives } = this.state;
    let nextSelectedOrgObjectives;

    if (selectedOrgObjectives.includes(id)) {
      nextSelectedOrgObjectives = selectedOrgObjectives?.filter(
        (objectiveId) => objectiveId !== id
      );
    } else {
      nextSelectedOrgObjectives = [...selectedOrgObjectives, id];
    }

    this.setState({ selectedOrgObjectives: nextSelectedOrgObjectives });
  }

  getInitialUsersSelected() {
    const {
      dataGoal,
      initialUsers,
      isMyGoals,
      type,
      user,
    } = this.props;
    let initialUsersSelected = [];

    if (isMyGoals) {
      initialUsersSelected = [user];
    } else if (dataGoal && type === EDIT) {
      initialUsersSelected = dataGoal.assignees;
    }
    return [...initialUsersSelected, ...initialUsers];
  }

  getPeriodsList() {
    const { goals: { period }, } = this.props;
    return getDropdownPeriodList(period);
  }

  getOutsideDateRangeStartDueDate(today, startTime) {
    const { type } = this.props;
    const { initialDueDate } = this.state;

    return type === EDIT && initialDueDate
      ? moment.max(today, startTime, initialDueDate)
      : moment.max(today, startTime);
  }

  getOutsideDateRangeDueDate(day) {
    const { startDate, periodId } = this.state;
    const selectedPeriod = this.getSelectedPeriod(periodId) || {};
    const utcDay = day.utc();

    if (!selectedPeriod) {
      return true;
    }

    const { endTime, startTime } = selectedPeriod;
    const momentStartTime = moment.utc(startTime);
    const momentEndTime = moment.utc(endTime);
    const rangeStart = momentStartTime;
    const rangeEnd = momentEndTime;
    const outsideDateRangeDueDate = utcDay.isBefore(rangeStart, 'day')
      || utcDay.isAfter(rangeEnd, 'day')
      || utcDay.isBefore(startDate);

    return outsideDateRangeDueDate;
  }

  getOutsideDateRangeStartDate(day) {
    const { periodId } = this.state;
    const selectedPeriod = this.getSelectedPeriod(periodId) || {};
    const { dueDate } = this.state;
    const utcDay = day.utc();

    if (!selectedPeriod) {
      return true;
    }

    const { endTime, startTime } = selectedPeriod;
    const momentStartTime = moment.utc(startTime);
    const momentEndTime = moment.utc(endTime);
    const rangeStart = momentStartTime;
    const rangeEnd = momentEndTime;
    const outsideDateRangeStartDate = utcDay.isBefore(rangeStart, 'day')
      || utcDay.isAfter(rangeEnd, 'day')
      || utcDay.isAfter(dueDate, 'day');

    return outsideDateRangeStartDate;
  }

  getInitialVisibleMonth(today, selectedPeriod) {
    const { dueDate } = this.state;
    let initialMonth = dueDate || today;

    if (!dueDate && selectedPeriod) {
      const { endTime, startTime } = selectedPeriod;
      const momentStartTime = moment.utc(startTime);
      const momentEndTime = moment.utc(endTime);

      if (!today.isBetween(momentStartTime, momentEndTime)) {
        initialMonth = momentStartTime;
      }
    }

    return initialMonth;
  }

  getComboboxUserList() {
    const {
      user: {
        collaborators: {
          asMain,
          asSecondary,
          asMainAndSecondary,
        }
      },
    } = this.props;
    const { selectedRelation, usersSelected } = this.state;
    const collaborators = (selectedRelation === MAIN_LEADER)
      ? [...asMain, ...asMainAndSecondary]
      : [...asSecondary, ...asMainAndSecondary]

    const isNotUserSelected = (user) =>
      !usersSelected.some(selecteduser =>
        user?.id === selecteduser?.id);

    return filter(collaborators, isNotUserSelected);
  }

  getSelectedPeriod(periodId) {
    const { goals: { period: periodList }, } = this.props;
    const { periodId: statePeriodId } = this.state;
    const currentPeriodId = periodId || statePeriodId;

    return periodList.find((period) => period?.id === currentPeriodId);
  }

  setRelation(event) {
    const { value } = event.target;
    this.setState({ selectedRelation: value, usersSelected: [] })
  }

  setEvaluator(id) {
    const { leader } = this.props;
    const selectedRelation = leader?.id === id
      ? MAIN_LEADER
      : SECONDARY_LEADER

    this.setState({ evaluatorSelectedId: id, selectedRelation })
  }

  setTime(defaultPeriodId) {
    const selectedPeriod = this.getSelectedPeriod(defaultPeriodId) || {};
    const { endTime, startTime } = selectedPeriod;
    const momentStartTime = moment.utc(startTime);
    const momentEndTime = moment.utc(endTime);
    const today = moment.utc();

    const isSelectedPeriodCurrentPeriod = today.isBetween(
      momentStartTime,
      momentEndTime,
    );
    let newTime;

    if (isSelectedPeriodCurrentPeriod) {
      newTime = {
        dueDate: momentEndTime,
        startDate: today,
      };
    } else {
      newTime = {
        dueDate: momentEndTime,
        startDate: momentStartTime,
      };
    }

    this.setState(newTime);
  }

  getMergedListOfFiles(files, attachments) {
    const parsedAttachments = attachments?.length > 0
      ? attachments?.map((item) => ({
        creationDate: moment.now(),
        fileName: item?.name,
        id: item?.id,
        urlFile: item?.url,
      }))
      : [];

    return [...files, ...parsedAttachments]
  };

  isFormValid() {
    const {
      description,
      dueDate,
      evaluatorSelectedId,
      selectedRelation,
      startDate,
      title,
      usersSelected,
      weight,
    } = this.state;

    const isFormValid = title
      && description
      && weight
      && dueDate
      && startDate
      && selectedRelation
      && (usersSelected.length > 0);

    return this.props.isMyGoals
      ? (isFormValid && evaluatorSelectedId)
      : isFormValid;
  }

  render() {
    const {
      allowsGoalCreationWithSumValidation,
      availableWeight,
      files,
      filesLoading,
      intl: { formatMessage },
      type,
      isMyGoals,
      goals: {
        fetchingPeriod,
        fetchingSent,
        loadingApproval,
        period: periodList,
      },
      organizationalObjectives,
      user: {
        access: { newWordingForLeaders },
        collaborators: {
          asMain,
          asMainAndSecondary,
          asSecondary,
        },
        leaders,
      },
    } = this.props;
    const {
      attachments,
      description,
      dueDate,
      dueDateFocused,
      enableSharedObjectives,
      evaluatorSelectedId,
      goalId,
      isOneEvaluatorSelected,
      isSharedObjetives,
      periodId,
      scales,
      selectedOrgObjectives,
      selectedRelation,
      startDate,
      startDateFocused,
      status,
      title,
      usersSelected,
      weight,
    } = this.state;
    const isGoalEdit = type === EDIT;
    const isGoalPendingApproval = status === PENDING_APPROVAL;
    const isGoalApproval = isGoalEdit && isGoalPendingApproval;
    const isMainAndSecondaryLeader = !!(asMain?.length && asSecondary?.length)
    const showRelationTypeOptions =
      (asMainAndSecondary?.length || isMainAndSecondaryLeader)
      && !isMyGoals;
    const isDisabledRelationTypeGoalOptions = (isGoalEdit || isGoalPendingApproval);
    const asMainLeaderText = newWordingForLeaders
      ? formatMessage(messages.GoalModalDetailAsCareerMentor)
      : formatMessage(messages.GoalModalDetailAsMainLeader);
    const asSecondaryLeaderText = newWordingForLeaders
      ? formatMessage(messages.GoalModalDetailAsExperienceLeader)
      : formatMessage(messages.GoalModalDetailAsSecondaryLeader);

    const relationTypeGoalOptions = [
      {
        disabled: isDisabledRelationTypeGoalOptions,
        id: MAIN_LEADER,
        name: asMainLeaderText,
      },
      {
        disabled: isDisabledRelationTypeGoalOptions,
        id: SECONDARY_LEADER,
        name: asSecondaryLeaderText,
      },
    ]

    const labelCancel = isGoalApproval
      ? formatMessage(actionsMessages.ActionsDelete)
      : formatMessage(actionsMessages.ActionsCancel);

    const labelEdit = isGoalPendingApproval
      ? formatMessage(messages.FeedbackGoalsDetailModalDetailBtnApprove)
      : formatMessage(actionsMessages.ActionsSave);

    const labelCreate = isGoalEdit
      ? labelEdit
      : formatMessage(actionsMessages.ActionsCreate);

    const labelSend = isMyGoals
      ? formatMessage(messages.FeedbackGoalsDetailModalDetailBtnValidate)
      : labelCreate;
    const placeholderDescription = isMyGoals
      ? formatMessage(
        messages.FeedbackGoalsDetailModalDetailDescriptionSelfPlaceholder
      )
      : formatMessage(
        messages.FeedbackGoalsDetailModalDetailDescriptionPlaceholder
      );

    const onClickCancel = isGoalApproval
      ? () => this.handleDelete(goalId)
      : () => this.handleClose('cancel');
    const selectedPeriod = periodList.find((period) => period?.id === periodId);
    const today = moment.utc();

    const goalsSection = isMyGoals
      ? formatMessage(messages.FeedbackGoalsMyGoalsTitle)
      : formatMessage(messages.FeedbackGoalsTeamGoalsTitle);
    const breadCrumbsValues = [
      goalsSection,
      formatMessage(goalsMessages.GoalsCreate),
    ];

    return (
      <Segment
        loading={fetchingPeriod || fetchingSent || loadingApproval}
        className="goals-actions-detail-modal"
        loadingType="betterme"
      >
        <BreadcrumbHeader
          breadCrumbsProps={{
            active: 1,
            onClick: () => this.handleClose('exit'),
            values: breadCrumbsValues,
          }}
          fixed
        />
        <div className="goals-actions-detail-modal__body">
          <div className="goals-actions-detail-modal__body-content">
            {showRelationTypeOptions &&
              (<div className="goals-actions-detail-modal__section">
                <Title className="text-black" level={4}>
                  {formatMessage(messages.GoalModalDetailWillCreateThisGoal)}
                </Title>
                <Reasons
                  onChange={this.setRelation}
                  selectedReason={selectedRelation}
                  reasons={relationTypeGoalOptions}
                />
              </div>)
            }
            <div className="goals-actions-detail-modal__section">
              <Title className="text-black" level={4}>
                {isMyGoals ? (
                  formatMessage(messages.FeedbackGoalsDetailModalDetailAssigneesSelfTitle)
                ) : (
                  formatMessage(messages.FeedbackGoalsDetailModalDetailAssigneesTitle)
                )}
              </Title>
              {!isMyGoals && (
                <UserCombobox
                  disabled={((type === EDIT) && !isSharedObjetives) || !selectedRelation}
                  placeholder={formatMessage(messages.FeedbackGoalsDetailModalDetailAssigneesSearch)}
                  userList={this.getComboboxUserList()}
                  onChange={this.handleChangeAddCollaborator}
                  value=""
                />
              )}
              <UserCarousel
                userList={usersSelected}
                onRemoveUser={this.handleChangeRemoveCollaborator}
                disableRemove={((isGoalEdit || isGoalPendingApproval) && usersSelected.length <= 2) || isMyGoals}
              />
              {!isMyGoals && (
                <Switch
                  idName="SharedObjetives"
                  message={formatMessage(
                    messages.FeedbackGoalsDetailModalDetailSharedToggle
                  )}
                  checked={isSharedObjetives}
                  disabled={enableSharedObjectives || type === EDIT || usersSelected.length < 2}
                  onChange={this.handleChangeSharedObjetives}
                  type="betterme"
                />
              )}
            </div>
            <div className="goals-actions-detail-modal__section">
              <Title className="text-black" level={4}>
                {formatMessage(sectionsMessages.SectionsTitle)}
              </Title>
              <InputText
                idName="goalTitle"
                value={title}
                onChange={(event) => this.handleChangeText('title', event)}
                maxLength={90}
              />
              {organizationalObjectives.length > 0 && (
                <div className="goals-actions-detail-modal__objectives">
                  <p className="goals-actions-detail-modal__objectives-label">
                    {formatMessage(
                      messages.FeedbackGoalsDetailModalDetailGoalOrgObjectives
                    )}
                  </p>
                  <OrgObjectivesSelector
                    className="goals-actions-detail-modal__tag-selector"
                    objectives={organizationalObjectives}
                    values={selectedOrgObjectives}
                    onSelect={this.handleOrgObjectivesSelect}
                  />
                </div>
              )}
            </div>
            <div className="goals-actions-detail-modal__section">
              <Title className="text-black" level={4}>
                {formatMessage(sectionsMessages.SectionsDescription)}
              </Title>
              <InputArea
                value={description}
                onChange={(event) =>
                  this.handleChangeText('description', event)
                }
                maxLength={1200}
                placeholder={placeholderDescription}
              />
              <UploadFileComponent
                className="goal-add-assessments__upload-files"
                disabled={this.getMergedListOfFiles(files, attachments)?.length >= MAX_FILES}
                files={this.getMergedListOfFiles(files, attachments)}
                loading={filesLoading}
                onChange={(event) => this.handleFileAttach(event)}
                onDeleteAccept={this.handleFileDelete}
                supportedFormats={SUPPORTED_FORMATS}
              />
            </div>
            <div className="goals-actions-detail-modal__section">
              <div className="goals-actions-detail-modal__weight-header">
                <Title className="text-black" level={4} nomargin>
                  {formatMessage(messages.FeedbackGoalWeightWarningModalTitle)}
                </Title>
                <InfoIcon padding position="left center">
                  <Title
                    className="goals-actions-detail-modal__weight-tooltip-title"
                    level={5}
                    nomargin
                    small
                  >
                    {formatMessage(goalsMessages.GoalsWeight)}
                  </Title>
                  <p className="goals-actions-detail-modal__weight-tooltip-content">
                    {formatMessage(messages.GoalsCreateWeightTooltipContent)}
                  </p>
                </InfoIcon>
              </div>
              {allowsGoalCreationWithSumValidation && (
                <h5 className="goals-actions-detail-modal__weight-subtitle">
                  {formatBoldText(
                    formatMessage(messages.GoalsCreateWeightSubtitle, {
                      value: availableWeight,
                    })
                  )}
                </h5>
              )}
              <InputText
                idName="goalWeight"
                className="goals-actions-detail-modal__weight-input"
                value={weight}
                onChange={(event) => this.handleChangeText('weight', event)}
                placeholder={formatMessage(
                  messages.FeedbackGoalsDetailModalDetailWeightPlaceholder
                )}
              />
            </div>
            <div className="goals-actions-detail-modal__time">
              <Title className="text-black" level={4}>
                {formatMessage(sectionsMessages.SectionsScope)}
              </Title>
              <div className="goals-actions-detail-modal__time-content">
                <div className="goals-actions-detail-modal__time-period">
                  <h5 className="goals-actions-detail-modal__time-label">
                    {formatMessage(
                      messages.FeedbackGoalsDetailModalDetailEvaluationPeriodTitle
                    )}
                  </h5>
                  <Dropdown
                    className="goals-actions-detail-modal__periods"
                    onChange={(value) => this.handleChange('periodId', value)}
                    options={this.getPeriodsList()}
                    padding
                    placeholder={
                      periodId
                        ? undefined
                        : formatMessage(
                          placeholdersMessages.PlaceholdersSelectPeriod
                        )
                    }
                    value={periodId}
                  />
                </div>
                <div className="goals-actions-detail-modal__time-dates">
                  <div className="goals-actions-detail-modal__dates">
                    <h5 className="goals-actions-detail-modal__time-label">
                      {formatMessage(sectionsMessages.SectionsStartDate)}
                    </h5>
                    <Datepicker
                      align="right"
                      className="goals-actions-detail-modal__start-date"
                      isOutsideRange={(day) =>
                        this.getOutsideDateRangeStartDate(
                          day,
                          today,
                        )
                      }
                      onChange={(value) =>
                        this.handleChange('startDate', value)
                      }
                      onFocus={({ focused }) =>
                        this.handleChange('startDateFocused', focused)
                      }
                      placeholder={formatMessage(
                        placeholdersMessages.PlaceholdersDate
                      )}
                      readOnly
                      styleInput
                      type="betterme"
                      value={startDate}
                      valueFocused={startDateFocused}
                    />
                  </div>
                  <div className="goals-actions-detail-modal__dates">
                    <h5 className="goals-actions-detail-modal__time-label">
                      {formatMessage(sectionsMessages.SectionsDueDate)}
                    </h5>
                    <Datepicker
                      align="right"
                      className="goals-actions-detail-modal__due-date"
                      initialVisibleMonth={() =>
                        this.getInitialVisibleMonth(today, selectedPeriod)
                      }
                      isOutsideRange={(day) =>
                        this.getOutsideDateRangeDueDate(
                          day,
                          today,
                        )
                      }
                      onChange={(value) => this.handleChange('dueDate', value)}
                      onFocus={({ focused }) =>
                        this.handleChange('dueDateFocused', focused)
                      }
                      placeholder={formatMessage(
                        placeholdersMessages.PlaceholdersDate
                      )}
                      readOnly
                      styleInput
                      type="betterme"
                      value={dueDate}
                      valueFocused={dueDateFocused}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="goals-actions-detail-modal__section">
              <Title className="text-black" level={4}>
                {formatMessage(
                  messages.FeedbackGoalsDetailModalDetailScaleTitle
                )}
              </Title>
              {scales.length > 0 && (
                <Scales
                  detailsMaxLength={DETAILS_MAX_LENGTH}
                  editScales={false}
                  initialValues={{ scales }}
                  messages={this.messagesScales}
                />
              )}
            </div>
            {(isMyGoals && leaders) && (
              <div className="goals-actions-detail-modal__section-evaluators">
                <Title className="text-black" level={4}>
                  {formatMessage(isOneEvaluatorSelected
                    ? messages.FeedbackGoalsDetailModalDetailOneEvaluatorTitle
                    : messages.FeedbackGoalsDetailModalDetailEvaluatorTitle
                  )}
                </Title>
                {isOneEvaluatorSelected ? (
                  <UserCarousel
                    userList={isOneEvaluatorSelected}
                    disableRemove={true}
                  />
                ) : (
                  <UserCombobox
                    className="goals-actions-detail-modal__evaluator"
                    placeholder={formatMessage(
                      messages.FeedbackGoalsDetailModalDetailEvaluatorPlaceholder
                    )}
                    userList={leaders}
                    onChange={this.setEvaluator}
                    value={evaluatorSelectedId}
                  />
                )}
              </div>
            )}
            <div className="goals-actions-detail-modal__actions">
              <ActionButtons
                buttons={[
                  {
                    label: labelCancel,
                    onClick: onClickCancel,
                    variant: 'outline',
                  },
                  {
                    disabled: !this.isFormValid(),
                    label: labelSend,
                    onClick: this.handleSend,
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <CreateGoalValidationModal />
      </Segment>
    );
  }
}

GoalsActionsDetailModal.defaultProps = {
  initialUsers: [],
};

GoalsActionsDetailModal.propTypes = {
  add: PropTypes.func,
  close: PropTypes.func,
  getPeriods: PropTypes.func,
  goToMyGoals: PropTypes.func,
  goToMyTeamGoals: PropTypes.func,
  createGoal: PropTypes.func,
  updateGoal: PropTypes.func,
  user: PropTypes.object,
  goals: PropTypes.object,
  intl: PropTypes.object,
  dataGoal: PropTypes.object,
};

const mapStateToProps = (state) => {
  const {
    goals,
    form: { scales },
  } = state;

  return {
    allowsGoalCreationWithSumValidation: selectConfigurations(
      'allowsGoalCreationWithSumValidation'
    )(state),
    allowsGoalsCreationInThePast: selectConfigurations(
      'allowsGoalsCreationInThePast'
    )(state),
    availableWeight: selectAvailableWeight(state),
    defaulPeriod: selectDefaultPeriod(state),
    files: selectFiles(state),
    filesLoading: selectFilesLoading(state),
    goals,
    leader: selectLeaderInfo(state),
    organizationalObjectives: selectOrgObjectives(state),
    scales,
    scores: selectScores(state),
  };
};

const goToMyGoals = () => push(getMyGoalsLocation());
const goToMyTeamGoals = () => push(getMyTeamGoalsLocation());

const connectedGoalsActionsDetailModal = connect(mapStateToProps, {
  ...actions,
  addToastMessage,
  closeFullscreenModal,
  closeModal,
  fileClear,
  fileDelete,
  fileUpload,
  goToMyGoals,
  goToMyTeamGoals,
  openModal,
})(injectIntl(GoalsActionsDetailModal));

export default FullScreenModal(
  connectedGoalsActionsDetailModal,
  GOAL_DETAIL_VIEW_MODAL
);
