import {
  EXCEEDED_SIZE,
  NO_SIZE,
  WRONG_FORMAT,
} from 'betterme-components/constants/fileValidations';

export function isValidFormat(file, supportedFormats) {
  const nameSplitted = file.name.split('.');
  const fileFormat = `.${nameSplitted[nameSplitted.length - 1].toLocaleLowerCase()}`;

  return supportedFormats.includes(fileFormat);
}

export function getFileSizeInMB(file) {
  return (file.size / (1024 * 1024)).toFixed(2);
}

export const runValidations = (
  file,
  maxSize,
  supportedFormats,
) => {
  const fileSize = Number(getFileSizeInMB(file));
  const hasExceededSize = fileSize > maxSize
    ? EXCEEDED_SIZE
    : null;
  const validateSize = fileSize === 0
    ? NO_SIZE
    : hasExceededSize;
  const error = !isValidFormat(file, supportedFormats)
    ? WRONG_FORMAT
    : validateSize;

  return error;
}
