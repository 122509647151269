// @packages
import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import Button from 'smu-ui-components/Button';
import Icon from 'smu-ui-components/Icon';
import RangeDatePicker from 'smu-ui-components/RangeDatePicker';
import Segment from 'smu-ui-components/Segment';
import SearchBox from 'smu-ui-components/SearchBox';
import { isEmpty } from 'lodash';
import { injectIntl } from 'react-intl';

// @app
import InfiniteScrollLoad from 'common/InfiniteScrollLoad';
import { feedbackMessages } from 'common/messages';
import { getCalendarYears } from 'utils/getCalendarYears';
// @own
import './styles.scss';
import SectionListItem from './SectionListItem';

const SectionList = ({
  active,
  className,
  dataLength,
  datePicker,
  hasMore,
  intl: { formatMessage },
  isSkill,
  listOrder,
  loading,
  messagesEmptyStates,
  next,
  onClickSelectionList,
  onOrderClick,
  onSearch,
  onSelect,
  scrollableTarget,
  sections,
  serchBox,
  title,
  value,
}) => {
  const icon = {
    asc: 'sort-asc',
    desc: 'sort-desc',
  };

  return (
    <Segment
      className={cn('section-list', className)}
      loading={loading}
      loadingType="betterme"
    >
      <div className="section-list__header">
        <h4 className="section-list__title">{title}</h4>
        {(onOrderClick || !isEmpty(datePicker)) && (
          <div className="section-list__buttons">
            {onOrderClick && (
              <Button
                className="section-list__button"
                onClick={() => onOrderClick(listOrder)}
              >
                <Icon
                  icon={icon[listOrder]}
                  className="rating-details__order-icon"
                />
              </Button>
            )}
            {datePicker && (
              <RangeDatePicker
                className="section-list__date-picker"
                isOutsideRange={() => false}
                type="betterme"
                yearsList={getCalendarYears()}
                {...datePicker}
              />
            )}
          </div>
        )}
      </div>
      {serchBox && (
        <div className="section-list__searchbox">
          <SearchBox
            flavor="modal"
            onChange={(query) => onSearch(query)}
            placeholder={formatMessage(
              feedbackMessages.FeedbackReceivedSkillSearch
            )}
          />
        </div>
      )}
      {!sections.length && messagesEmptyStates ? (
        <div className="section-list__empty">
          <strong className="section-list__empty-message">
            {messagesEmptyStates.main}
          </strong>
          <span className="section-list__empty-suggestion">
            {messagesEmptyStates.suggestion}
          </span>
        </div>
      ) : (
        <ul id={scrollableTarget} className="section-list__list">
          <InfiniteScrollLoad
            dataLength={dataLength}
            hasMore={hasMore}
            loadingType="betterme"
            next={() => next()}
            scrollableTarget={scrollableTarget}
            useWindow={false}
          >
            {sections.map((section) => (
              <SectionListItem
                {...section}
                active={active}
                isSkill={isSkill}
                key={section.value}
                onClick={(event) => {
                  onSelect(section.value, event);
                }}
                selected={value === section.value}
              />
            ))}
          </InfiniteScrollLoad>
        </ul>
      )}
    </Segment>
  );
};

const sectionListValueProptype = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]);

SectionList.propTypes = {
  datePicker: PropTypes.shape({
    endDateId: PropTypes.string.isRequired,
    endDateValue: PropTypes.object,
    focusedInput: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    startDateId: PropTypes.string.isRequired,
    startDateValue: PropTypes.object,
  }),
  intl: PropTypes.object.isRequired,
  listOrder: PropTypes.string,
  maxItems: PropTypes.number,
  messagesEmptyStates: PropTypes.object,
  onOrderClick: PropTypes.func,
  onSelect: PropTypes.func,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      displayValue: PropTypes.string.isRequired,
      value: sectionListValueProptype,
    })
  ).isRequired,
  serchBox: PropTypes.bool,
  title: PropTypes.string.isRequired,
  value: sectionListValueProptype,
};

export default injectIntl(SectionList);
