// @packages
import { get } from 'lodash';
import { createSelector } from 'reselect';

const reducerDomain = 'suggestPromotion';

export const selectLoadingPromotionsFiles = (state) =>
  get(state, [reducerDomain, 'loadingFiles']);

export const selectLoadingPositionsHistory = (state) =>
  get(state, [reducerDomain, 'loadingPositionsHistory']);

export const selectLoadingPromotionsLevel = (state) =>
  get(state, [reducerDomain, 'loadingPromotionsLevel']);

export const selectLoadingSubmit = (state) =>
  get(state, [reducerDomain, 'loadingSubmit']);

export const selectLoadingUser = (state) =>
  get(state, [reducerDomain, 'loadingUser']);

export const selectLoadingUserPreviousRequests = (state) =>
  get(state, [reducerDomain, 'loadingPreviousRequests']);

export const selectPromotionCollaborator = (state) =>
  get(state, [reducerDomain, 'collaborator']);

export const selectPromotionsFiles = (state) =>
  get(state, [reducerDomain, 'files']);

export const selectPositionsHistory = (state) =>
  get(state, [reducerDomain, 'positionsHistory']);

export const selectPromotionsLevel = (state) =>
  get(state, [reducerDomain, 'promotionsLevel']);

export const selectUserPreviousRequests = (state) =>
  get(state, [reducerDomain, 'previousRequests']);

export const selectUserPreviousRequestsFiltered = createSelector(
  selectUserPreviousRequests,
  userPreviousRequests => (
    userPreviousRequests.reduce((accumulator, currentValue) => {
      const findElementIndex = accumulator.findIndex(({ requester }) =>
        requester?.id === currentValue?.requester?.id);
      const isValidatorReview = (currentValue?.requesterType === 'MANAGER'
        && currentValue?.status === 'MANAGER_REVIEW');

      if (findElementIndex === -1 && !isValidatorReview) {
        accumulator.push(currentValue);
      }
      return accumulator;
    }, [])
  )
);

export const selectPromotionProcessAllowed = (state) =>
  get(state, [reducerDomain, 'promotionProcessAllowed']);

export const selectPromotionSuggestedValidators = (state) =>
  get(state, [reducerDomain, 'suggestedValidators']);

