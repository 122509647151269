// @packages
import moment from 'moment';

export function setRangeDate (evaluationPeriod, onChange, evaluationValues) {
  const {
    startTime,
    endTime,
  } = evaluationPeriod;
  const {
    dateTimeFrom,
    dateTimeTo,
  } = evaluationValues;
  const todayUTC = moment.utc();
  const initialStartDate = dateTimeFrom
    ? moment.utc(dateTimeFrom)
    : moment.utc(startTime);
  const validateEndPeriod = todayUTC.isBefore(endTime, 'month')
    ? todayUTC
    : moment.utc(endTime);
  const endPeriod = todayUTC.isBetween(startTime, endTime, 'month', '[]')
    ? validateEndPeriod
    : moment.utc(endTime);
  const initialStartPeriod = initialStartDate.startOf('month');
  const initialEndPeriod = (dateTimeTo && endPeriod.isAfter(dateTimeTo, 'month'))
    ? moment.utc(dateTimeTo).endOf('month').millisecond(0)
    : endPeriod.endOf('month').millisecond(0);
  onChange({
    startDate: initialStartPeriod,
    endDate: initialEndPeriod,
  });
}
