// @packages
import { takeLatest, call, put } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';

// @app
import { getLeadershipRequestsReceived } from 'betterme-components/services/LeadershipRequestReceived/actions';

// @own
import { GOT_IT } from './actionTypes';
import {
  gotItFail,
  gotItSuccess,
} from './actions';
import { gotIt } from './api';

function* gotItWorker({ payload: { requestId } }) {
  try {
    const response = yield call(gotIt, requestId);
    yield put(gotItSuccess(response));
    yield put(getLeadershipRequestsReceived('RECEIVED'));
  } catch(error) {
    yield put(gotItFail(error.message));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
};

export default function* changeLeaderRequestWorker() {
  yield takeLatest(GOT_IT, gotItWorker);
};