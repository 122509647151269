export const COMMENT_QUESTION_MAX_LENGTH = 2000;
export const NO_TIER = 'NO_TIER';
export const QUESTION_SLICED_SIZE = 2;
export const RATE = 'RATE';
export const OPEN = 'OPEN';
export const TABLE_HEADER_COL_SPAN = 3;

export const SEGMENTS = [
    {
        segment: 'Segment1',
        name: 'RISING_STAR',
    },
    {
        segment: 'Segment2',
        name: 'RISING_LEADERSHIP',
    },
    {
        segment: 'Segment3',
        name: 'SUPERNOVA_LEADERSHIP',
    },
    {
        segment: 'Segment4',
        name: 'EXECUTIVE_LEADERSHIP',
    },
];