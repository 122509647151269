// @packages
import React from 'react';
import Button from 'smu-ui-components/ButtonV2';
import IconButton from 'smu-ui-components/IconButton';
import PropTypes from 'prop-types';
import Tooltip from 'smu-labs/Tooltip';
import cn from 'classnames';

// @own
import './styles.scss';

const File = ({
  actionIcon,
  className,
  fileDownload,
  fileName,
  id,
  isDownloadFiles,
  noPadding,
  noPaddingHorizontal,
  noPaddingVertical,
  onActionClick,
  textRemoveFile,
}) => (
  <div
    className={cn(className, 'file', {
      'file--no-padding': noPadding,
      'file--no-padding-horizontal': noPaddingHorizontal,
      'file--no-padding-vertical': noPaddingVertical,
    })}
  >
    {isDownloadFiles ? (
      <div className="file__name">{fileName}</div>
    ) : (
      <Button
        className="file__name--button"
        onClick={() => fileDownload(id)}
        variant="link"
      >
        {fileName}
      </Button>
    )}
    <div className="file__icon">
    <Tooltip content={textRemoveFile} placement="top">
      <IconButton
        className="p-2 text-gray-scorpion"
        icon={actionIcon}
        onClick={() => onActionClick && onActionClick(id)}
        size="medium"
      />
    </Tooltip>
    </div>
  </div>
);

File.propTypes = {
  actionIcon: PropTypes.string.isRequired,
  className: PropTypes.string,
  fileDownload: PropTypes.func,
  fileName: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  isDownloadFiles: PropTypes.bool,
  noPadding: PropTypes.bool,
  noPaddingHorizontal: PropTypes.bool,
  noPaddingVertical: PropTypes.bool,
  onActionClick: PropTypes.func,
  textRemoveFile: PropTypes.string,
};

export default File;
