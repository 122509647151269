import {
  CREATE_ANSWER_EVALUATION,
  CREATE_ANSWER_EVALUATION_FAIL,
  CREATE_ANSWER_EVALUATION_SUCCESS,
  GET_EVALUATION_DETAIL,
  GET_EVALUATION_DETAIL_FAIL,
  GET_EVALUATION_DETAIL_SUCCESS,
  RESET_EVALUATION_DETAIL,
  RESET_DATA_CREATE_ANSWER_EVALUATION,
  SET_DATA_CREATE_ANSWER_EVALUATION,
} from './actionTypes';

export function createAnswerEvaluation(evaluationId, data) {
  return {
    type: CREATE_ANSWER_EVALUATION,
    payload: { evaluationId, data },
  };
}

export function createAnswerEvaluationFail(error) {
  return {
    type: CREATE_ANSWER_EVALUATION_FAIL,
    payload: { error },
  };
}

export function createAnswerEvaluationSuccess(response) {
  return {
    type: CREATE_ANSWER_EVALUATION_SUCCESS,
    payload: { response },
  };
}

export function getEvaluationDetail(evaluationId) {
  return {
    type: GET_EVALUATION_DETAIL,
    payload: { evaluationId },
  };
}

export function getEvaluationDetailFail(error) {
  return {
    type: GET_EVALUATION_DETAIL_FAIL,
    payload: { error },
  };
}

export function getEvaluationDetailSuccess(response) {
  return {
    type: GET_EVALUATION_DETAIL_SUCCESS,
    payload: { response },
  };
}

export function resetEvaluationDetail() {
  return {
    type: RESET_EVALUATION_DETAIL,
  };
}

export function resetDataCreateAnswerEvaluation() {
  return {
    type: RESET_DATA_CREATE_ANSWER_EVALUATION,
  };
}

export function setDataCreateAnswerEvaluation(props) {
  return {
    type: SET_DATA_CREATE_ANSWER_EVALUATION,
    payload: props,
  };
}
