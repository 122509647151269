// @packages
import React, { Component } from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';

// @app
import EvaluationCreateSelf from '../Evaluations/EvaluationCreateSelf';
import FeedbackRequest from '../FeedbackRequest';
import { selectConfigurations, selectUserInfo } from '../Authorization/selectors';

// @own
import './styles.scss';
import FeedbackSuggestions from './FeedbackSuggestions';
import GoalsCreate from './GoalsCreate';

class RightColumn extends Component {

  render() {
    const {
      allowsEvaluationDrafts,
      allowsEvaluations,
      allowsFeedbackRequest,
      allowsSelfEvaluations,
      allowsSelfRetrospectiveCreation,
      allowsSelfRetrospectiveDraft,
      location,
      session,
    } = this.props;
    const isActiveSelfEvaluation = allowsEvaluations && (allowsSelfEvaluations || allowsEvaluationDrafts);
    const isActiveSelfRetrospective = allowsEvaluations && (allowsSelfRetrospectiveCreation || allowsSelfRetrospectiveDraft);
    const allowsGoalCreation = get(session, 'communityConfigurations.configurations.allowsGoalsCreationOnBehalfOfLeader', true);
    const showMyTeamGoalsCreate = location.pathname === '/goals/myteamgoals';
    const showMyGoalsCreate = location.pathname === '/goals/mygoals'
      && session.user.leaders
      && allowsGoalCreation;
    const showCreateSelfEvaluation = location.pathname === '/evaluations'
      && (isActiveSelfEvaluation || isActiveSelfRetrospective);
    const showFeedbackRequest = location?.pathname === '/feedback' && allowsFeedbackRequest;
    const showFeedbackSuggestions = location?.pathname === '/feedback';

    return (
      <div className="right-column">
        <div className="right-column__content">
          {(showMyTeamGoalsCreate || showMyGoalsCreate) &&
            <div className="right-column__create-goal">
              <GoalsCreate user={session.user} isMyGoals={showMyGoalsCreate} />
            </div>
          }

          {showCreateSelfEvaluation && (
            <EvaluationCreateSelf />
          )}

          {showFeedbackRequest && (
            <div className="right-column__feedback-request">
              <FeedbackRequest
                user={session.user}
              />
            </div>
          )}

          {showFeedbackSuggestions && (
            <div className="right-column__feedback-suggestions">
              <FeedbackSuggestions hasAccessProfiles />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allowsEvaluationDrafts: selectConfigurations('allowsEvaluationDrafts')(state),
    allowsEvaluations: selectConfigurations('allowsEvaluations')(state),
    allowsFeedbackRequest: selectConfigurations('allowsFeedbackRequest')(state),
    allowsSelfEvaluations: selectConfigurations('allowsSelfEvaluations')(state),
    allowsSelfRetrospectiveCreation: selectConfigurations('allowsSelfRetrospectiveCreation')(state),
    allowsSelfRetrospectiveDraft: selectConfigurations('allowsSelfRetrospectiveDraft')(state),
    userInfo: selectUserInfo(state),
  };
};

export default connect(mapStateToProps, null)(RightColumn);