// @packages
import moment from 'moment';

export function formatDateV2(date, type) {
  const currentDate = date || moment();
  let format;

  switch (type) {
    case 'smallFormat':
      format = 'MM/YYYY';
      break;

    case 'monthYearFormat':
      format = 'MMM YYYY';
      break;

    case 'monthDay':
      format = 'MMM DD';
      break;

    case 'largeMonthDayFormat':
      format = 'MMMM, DD';
      break;

    default:
      format = 'L';
      break;
  }

  return moment.utc(currentDate).format(format).replace('.', '');
}
