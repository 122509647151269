import {
  GET_SKILLS_RATED,
  GET_SKILLS_RATED_FAIL,
  GET_SKILLS_RATED_SUCCESS,
  GET_SEARCH_SKILLS_RATED,
  GET_SEARCH_SKILLS_RATED_FAIL,
  GET_SEARCH_SKILLS_RATED_SUCCESS,
  SELECT_SKILL,
  SELECT_RATING_SKILL,
  SORT_SKILLS_RATED,
} from './actionTypes';

const initialState = {
  error: '',
  loading: false,
  order: 'desc',
  ratedSkills: [],
  selectedSkillId: undefined,
  selectedRatingSkill: undefined,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_SKILLS_RATED:
      return { ...state, loading: true };
    case GET_SKILLS_RATED_FAIL:
      return { ...state, error: payload.error, loading: false };
    case GET_SKILLS_RATED_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
        number: payload.response.page.number,
        numberOfElements: payload.response.page.numberOfElements,
        ratedSkills:
        payload.response.page.number > 0 
            ? [...state.ratedSkills, ...payload.response.result]
            : payload.response.result,
        totalPages: payload.response.page.totalPages,
      };
    case GET_SEARCH_SKILLS_RATED:
      return { ...state, loading: true };
    case GET_SEARCH_SKILLS_RATED_FAIL:
      return { ...state, error: payload.error, loading: false };
    case GET_SEARCH_SKILLS_RATED_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
        number: 0,
        numberOfElements: payload.response.page.numberOfElements,
        ratedSkills: payload.response.result,
        selectedSkillId: payload.response.result[0].id,
        totalPages: payload.response.page.totalPages,
      };
    case SELECT_SKILL:
      return { ...state, selectedSkillId: payload.skillId };
    case SELECT_RATING_SKILL:
      return {
        ...state,
        selectedRatingSkill: payload.ratingId,
      };
    case SORT_SKILLS_RATED: {
      const newOrder = state.order === 'asc' ? 'desc' : 'asc';
      return { ...state, order: payload.order || newOrder };
    }
    default:
      return state;
  }
}
