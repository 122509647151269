import {
  GET_COMMUNITY_RATINGS,
  GET_COMMUNITY_RATINGS_FAIL,
  GET_COMMUNITY_RATINGS_SUCCESS,
  GET_COMMUNITY_SCORES,
  GET_COMMUNITY_SCORES_FAIL,
  GET_COMMUNITY_SCORES_SUCCESS,
  GET_COMMUNITY_TOPICS,
  GET_COMMUNITY_TOPICS_FAIL,
  GET_COMMUNITY_TOPICS_SUCCESS,
} from './actionTypes';

const initialState = {
  ratings: null,
  scores: null,
  topics: null,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_COMMUNITY_RATINGS:
      return {
        ...state,
      };
    case GET_COMMUNITY_RATINGS_FAIL: 
      return {
        ...state,
      };
    case GET_COMMUNITY_RATINGS_SUCCESS:
      const { ratings } = payload;
      return {
        ...state, 
        ratings,
      };
      case GET_COMMUNITY_SCORES:
        return {
          ...state,
        };
      case GET_COMMUNITY_SCORES_FAIL: 
        return {
          ...state,
        };
      case GET_COMMUNITY_SCORES_SUCCESS:
        const { scores } = payload;
        return {
          ...state, 
          scores,
        };
        case GET_COMMUNITY_TOPICS:
          return {
            ...state,
          };
        case GET_COMMUNITY_TOPICS_FAIL: 
          return {
            ...state,
          };
        case GET_COMMUNITY_TOPICS_SUCCESS:
          const { topics } = payload;
          return {
            ...state, 
            topics,
          };
    default:
      return state;
  };
};
