// @packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Link from 'smu-ui-components/Link';

// @app
import SectionTitle from 'components/atoms/SectionTitle';
import StatusList from 'components/molecules/StatusList';

// @own
import './styles.scss';

const TeamEvaluationsStatus = ({
  className,
  evaluationsCount,
  messages,
  onSeeMoreClick,
  onStatusClick,
}) => {
  const { buttonText, description, title } = messages;
  const list = [
    {
      statusName: messages.status.submitted,
      count: evaluationsCount[0].count,
    },
    {
      statusName: messages.status.draft,
      count: evaluationsCount[1].count,
    },
    {
      statusName: messages.status.foreignDraft,
      count: evaluationsCount[2].count,
    },
    {
      statusName: messages.status.notStarted,
      count: evaluationsCount[3].count,
    },
  ];

  return (
    <>
      <div className={cn('evaluations-status', className)}>
        <SectionTitle
          className="evaluations-status__heading"
          description={description}
          noPaddingTop
          noborder
          title={title}
        >
          <div className="evaluations-status__wrapper">
            <StatusList list={list} onStatusClick={onStatusClick} />
          </div>
          <div className="evaluations-status__button-wrapper">
            <Link
              color="black"
              onClick={() => onSeeMoreClick()}
              size="small"
              type="button"
            >
              {buttonText}
            </Link>
          </div>
        </SectionTitle>
      </div>
    </>
  );
};

TeamEvaluationsStatus.propTypes = {
  className: PropTypes.string,
  messages: PropTypes.array,
  onSeeMoreClick: PropTypes.func,
  onStatusClick: PropTypes.func,
};

export default TeamEvaluationsStatus;
