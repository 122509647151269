import {
  GET_EVALUATIONS_TEAM,
  GET_EVALUATIONS_TEAM_FAIL,
  GET_EVALUATIONS_TEAM_SUCCESS,
} from './actionTypes';

export function evaluationsTeam(identification) {
  return {
    type: GET_EVALUATIONS_TEAM,
    payload: { identification },
  };
}

export function evaluationsTeamFail(error) {
  return {
    type: GET_EVALUATIONS_TEAM_FAIL,
    payload: { error },
  };
}

export function evaluationsTeamSuccess(response) {
  return {
    type: GET_EVALUATIONS_TEAM_SUCCESS,
    payload: { response },
  };
}
