// @packages
import React, { Component } from 'react';
import Icon from 'smu-ui-components/Icon';
import Link from 'smu-ui-components/Link';
import Popup from 'smu-ui-components/Popup';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @own
import './styles.scss';

class OptionsMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      colorIcon: 'gray-dark',
      isOpen: false,
    };

    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleOnclick = this.handleOnclick.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleMouseEnter() {
    this.setState({ colorIcon: 'black' });
  }

  handleMouseLeave() {
    this.setState({ colorIcon: 'gray-dark' });
  }

  handleToggle(event) {
    event.stopPropagation();
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  handleOnclick(event, onClick) {
    this.handleToggle(event);
    onClick(event);
  }

  render() {
    const {
      className,
      options,
      position,
    } = this.props;

    const {
      colorIcon,
      isOpen,
    } = this.state;

    const iconNode = (
      <span
        className="options-menu__icon"
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <Icon
          color={colorIcon}
          icon="kebob-menu"
          size="semi-medium"
        />
      </span>
    );

    const menuStyle = {
      border: 'none',
    };

    return (
      <Popup
        basic
        className={cn('options-menu', className)}
        hideOnScroll
        on="click"
        onClose={this.handleToggle}
        onOpen={this.handleToggle}
        open={isOpen}
        position={position}
        style={menuStyle}
        trigger={iconNode}
      >
        {options.map((option, index) => (
          <div
            className={cn('options-menu__item', {
              'options-menu__item--disabled': option?.disabled,
            })}
            key={index}
          >
            <Link
              className="options-menu__link"
              onClick={!(option?.disabled) ? (event) => this.handleOnclick(event, option.onClick) : null}
              to={!(option?.disabled) ? option.to : null}
            >
              {option.label}
            </Link>
          </div>
        ))}
      </Popup>
    );
  }
}

OptionsMenu.defaultProps = {
  position: 'bottom left',
};

OptionsMenu.propTypes = {
  className: PropTypes.string,
  options: PropTypes.PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    to: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]),
  })).isRequired,
  position: PropTypes.string,
};

export default OptionsMenu;
