// @packages
import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

// @own
import './styles.scss';

const EvaluationsHeader = ({
  className,
  headerItems,
}) => (
  <li className={cn(className, 'evaluations-header')}>
    {headerItems.map(item =>
      <ul
        className={cn(item.className, 'evaluations-header__item')}
        key={item.index}
      >
        {item.name}
      </ul>
    )}
  </li>
);

EvaluationsHeader.defaultProps = {
  headerItems: [],
};

EvaluationsHeader.propTypes = {
  className: PropTypes.string,
  headerItems: PropTypes.object.isRequired,
};

export default EvaluationsHeader;
