export const ADD_COMPETENCE = 'ADD_COMPETENCE';
export const ADD_DELETED_SKILL = 'ADD_DELETED_SKILL';
export const ADD_FEEDBACK_COLLABORATOR = 'ADD_FEEDBACK_COLLABORATOR';
export const ADD_SKILLS = 'ADD_SKILLS';
export const CHANGE_FEEDBACK_SELECTED_COLLABORATORS = 'CHANGE_FEEDBACK_SELECTED_COLLABORATORS';
export const CHANGE_FEEDBACK_TYPE = 'CHANGE_FEEDBACK_TYPE';
export const CLEAN_DELETED_SKILLS = 'CLEAN_DELETED_SKILLS';
export const CLEAN_RATED_COMPETENCES_STATE = 'CLEAN_RATED_COMPETENCES_STATE';
export const CLEAN_SEND_FEEDBACK_REQUEST_STATE = 'CLEAN_SEND_FEEDBACK_REQUEST_STATE';
export const CLEAN_SKILLS = 'CLEAN_SKILLS';
export const CLEAN_USER_LIST = 'CLEAN_USER_LIST';
export const CREATE_REQUEST_FEEDBACK_ON_BEHALF = 'CREATE_REQUEST_FEEDBACK_ON_BEHALF';
export const CREATE_REQUEST_FEEDBACK_ON_BEHALF_FAIL = 'CREATE_REQUEST_FEEDBACK_ON_BEHALF_FAIL';
export const CREATE_REQUEST_FEEDBACK_ON_BEHALF_SUCCESS = 'CREATE_REQUEST_FEEDBACK_ON_BEHALF_SUCCESS';
export const FEEDBACK_ERROR = 'FEEDBACK_ERROR';
export const FEEDBACK_SENT = 'FEEDBACK_SENT';
export const GET_REQUEST_FEEDBACK_INFO = 'GET_REQUEST_FEEDBACK_INFO';
export const GET_REQUEST_FEEDBACK_INFO_ERROR = 'GET_REQUEST_FEEDBACK_INFO_ERROR';
export const GET_REQUEST_FEEDBACK_INFO_SUCCESS = 'GET_REQUEST_FEEDBACK_INFO_SUCCESS';
export const GET_USERS = 'GET_USERS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const INIT_COMPETENCES = 'INIT_COMPETENCES';
export const INIT_MANDATORY_COMPETENCES = 'INIT_MANDATORY_COMPETENCES';
export const INIT_REPLY_REQUEST_FEEDBACK_DATA = 'INIT_REPLY_REQUEST_FEEDBACK_DATA';
export const INIT_USER = 'INIT_USER';
export const REMOVE_COMPETENCE = 'REMOVE_COMPETENCE';
export const REMOVE_FEEDBACK_ALL_COLLABORATORS = 'REMOVE_FEEDBACK_ALL_COLLABORATORS';
export const REMOVE_FEEDBACK_COLLABORATOR = 'REMOVE_FEEDBACK_COLLABORATOR';
export const REPLY_REQUEST_FEEDBACK = 'REPLY_REQUEST_FEEDBACK';
export const REQUEST_FEEDBACK = 'REQUEST_FEEDBACK';
export const RESET_MODAL = 'RESET_MODAL';
export const SELECT_COMPETENCE = 'SELECT_COMPETENCE';
export const SELECT_FEEDBACK_USER = 'SELECT_FEEDBACK_USER';
export const SEND_FEEDBACK = 'SEND_FEEDBACK';
export const UNSELECT_COMPETENCE = 'UNSELECT_COMPETENCE';
export const UNSELECT_FEEDBACK_USER = 'UNSELECT_FEEDBACK_USER';
export const UPDATE_COMPETENCES = 'UPDATE_COMPETENCES';
