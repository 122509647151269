// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

// @app
import CardSimple from 'common/CardSimple';
import FeedbackReceivedPie from 'common/FeedbackReceivedPie';
import { feedbackMessages } from 'common/messages';

// @own
import './styles.scss';

const DashboardFeedbackReceived = ({
  intl: { formatMessage },
  dashboardFeedback: {
    ratings,
  },
  feedbackReceivedLink,
  loading,
  messagesEmptyStates,
  showRequestFeedback,
  trackEventEmptyState,
}) => {

  return (
    <div className="dashboard-feedback-received">
      <CardSimple
        className="dashboard-feedback-received__card"
        loading={loading}
        subtitle={formatMessage(feedbackMessages.FeedbackRatings)}
        title={formatMessage(feedbackMessages.FeedbackFeedback)}
      >
        <div className="dashboard-feedback-received__content">
          <div className="dashboard-feedback-received__stats">
            <FeedbackReceivedPie
              emptyLinkLabel={messagesEmptyStates.suggestion}
              emptyMessage={messagesEmptyStates.main}
              linkTo={feedbackReceivedLink}
              onLinkClick={trackEventEmptyState}
              pieLabel={formatMessage(feedbackMessages.FeedbackRatings)}
              ratings={ratings}
              showLink={showRequestFeedback}
            />
          </div>
        </div>
      </CardSimple>
    </div>
  );
};

DashboardFeedbackReceived.propTypes = {
  dashboardFeedback: PropTypes.shape({
    ratings: PropTypes.array.isRequired,
  }).isRequired,
  intl: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  messagesEmptyStates: PropTypes.object.isRequired,
  showRequestFeedback: PropTypes.bool,
  trackEventEmptyState: PropTypes.func.isRequired,
};

export default injectIntl(DashboardFeedbackReceived);
