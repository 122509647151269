import {
  GET_LEADERS_BY_PERIOD,
  GET_LEADERS_BY_PERIOD_FAIL,
  GET_LEADERS_BY_PERIOD_SUCCESS,
} from './actionTypes';

const initialState = {
  errorLeadersByPeriod: '',
  leadersByPeriod: {},
  loadingLeadersByPeriod: false,
}

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_LEADERS_BY_PERIOD:
      return { ...state, loadingLeadersByPeriod: true };
    case GET_LEADERS_BY_PERIOD_FAIL:
      return {
        ...state,
        errorLeadersByPeriod: payload.error,
        loadingLeadersByPeriod: false,
      };
    case GET_LEADERS_BY_PERIOD_SUCCESS:
      return {
        ...state,
        leadersByPeriod: { ...payload.response },
        loadingLeadersByPeriod: false,
      };
    default:
      return state;
  }
}
