export const CREATE_FEEDBACKS_FOR_LEADERS = 'CREATE_FEEDBACKS_FOR_LEADERS';
export const CREATE_FEEDBACKS_FOR_LEADERS_FAIL = 'CREATE_FEEDBACKS_FOR_LEADERS_FAIL';
export const CREATE_FEEDBACKS_FOR_LEADERS_SUCCESS = 'CREATE_FEEDBACKS_FOR_LEADERS_SUCCESS';
export const GET_QUESTIONS_FEEDBACKS_FOR_LEADERS = 'GET_QUESTIONS_FEEDBACKS_FOR_LEADERS';
export const GET_QUESTIONS_FEEDBACKS_FOR_LEADERS_FAIL = 'GET_QUESTIONS_FEEDBACKS_FOR_LEADERS_FAIL';
export const GET_QUESTIONS_FEEDBACKS_FOR_LEADERS_SUCCESS = 'GET_QUESTIONS_FEEDBACKS_FOR_LEADERS_SUCCESS';
export const GET_RATINGS_FEEDBACKS_FOR_LEADERS = 'GET_RATINGS_FEEDBACKS_FOR_LEADERS';
export const GET_RATINGS_FEEDBACKS_FOR_LEADERS_FAIL = 'GET_RATINGS_FEEDBACKS_FOR_LEADERS_FAIL';
export const GET_RATINGS_FEEDBACKS_FOR_LEADERS_SUCCESS = 'GET_RATINGS_FEEDBACKS_FOR_LEADERS_SUCCESS';
export const RESET_FEEDBACKS_FOR_LEADERS = 'RESET_FEEDBACKS_FOR_LEADERS';
export const SET_ANSWER_COMPETENCY = 'SET_ANSWER_COMPETENCY';
export const SET_ANSWER_INSIGHT = 'SET_ANSWER_INSIGHT';
