// @packages
import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @app
import ContentCardTemplate from 'components/templates/ContentCardTemplate';
import StarmeUpDNAWidget from 'betterme-components/components/containers/StarmeUpDNAWidget';

// @own
import './styles.scss';

const DNAWidget = ({
  className,
  dateTimeFrom,
  dateTimeTo,
  onToggleClick,
  open,
  title,
  user,
}) => (
  <ContentCardTemplate
    className={cn('widget-dna', className)}
    title={title}
    open={open}
    onToggleClick={() => onToggleClick('valuesOpen')}
  >
    <ContentCardTemplate.Body>
      <StarmeUpDNAWidget
        black
        className={className}
        compact
        endDate={dateTimeTo}
        startDate={dateTimeFrom}
        user={user}
      />
    </ContentCardTemplate.Body>
  </ContentCardTemplate>
);

DNAWidget.propTypes = {
  className: PropTypes.string,
  dateTimeFrom: PropTypes.string,
  dateTimeTo: PropTypes.string,
  onToggleClick: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  user: PropTypes.object,
};

export default injectIntl(DNAWidget);
