// @packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Chip from 'smu-ui-components/Chip';

// @app
import MessageBox from 'components/organisms/MessageBox';

// @own
import './styles.scss';
import FormCompetenciesItem from './FormCompetenciesItem';
import AnonymousEvaluation from '../AnonymousEvaluation';

const FormCompetencies = ({
  alertMessage,
  answersCompetencies,
  competencies,
  currentTab,
  infoMessage,
  isDisabled,
  lastSelectedTab,
  onChange,
  onChangeTab,
  ratings,
  totalQuestions,
}) => {
  const [infoOpen, setInfoOpen] = useState(true);
  return (
    <div className="form-competencies">
      <div className="form-competencies__tabs">
        {competencies.map((competency, index) => {
          const isFirstTab = index === 0;
          const isPreviousTab = (index + 1) < currentTab;
          const isNextTab = (index + 1) > currentTab;
          const isActiveTab = currentTab === index + 1;
          const isCompletedTab = (index + 1) <= lastSelectedTab;
          const isFirstOrPrevious = isFirstTab || isPreviousTab;
          const enabledBehaviour = (!isFirstOrPrevious || !isCompletedTab) && ((isActiveTab && !isFirstTab) && isDisabled)
          return (
            <Chip
              className="form-competencies__tabs-item"
              color="tag-competencies"
              disabled={(isNextTab && !isCompletedTab) || enabledBehaviour}
              key={index}
              onClick={() => {
                onChangeTab(index + 1);
              }}
              size="medium"
              variant={!isActiveTab && 'outline'}
            >
              {competency.name}
            </Chip>
          )
        })}
      </div>
      <div className="form-competencies__contents">
        {infoOpen && (
          <MessageBox
            className="form-competencies__message-box"
            canBeClosed
            closeIconSize={16}
            icon="bubble"
            iconColor="message-box-informative-icon"
            iconSize={20}
            message={infoMessage}
            onClose={() => setInfoOpen(!infoOpen)}
          />
        )}
        {alertMessage && (
          <MessageBox
            className="form-competencies__message-box"
            message={alertMessage}
            variant="alert"
          />
        )}
        <AnonymousEvaluation />
        {competencies.map((competency, index) => (
          (currentTab === (index + 1)) && (
            <FormCompetenciesItem
              answersCompetencies={answersCompetencies}
              key={index}
              onChange={onChange}
              questions={competency.questions}
              ratings={ratings}
              totalQuestions={totalQuestions}
            />
          )
        ))}
      </div>
    </div>
  )
};

FormCompetencies.defaultProps = {
  competencies: [],
}

FormCompetencies.propTypes = {
  alertMessage: PropTypes.string,
  answersCompetencies: PropTypes.object.isRequired,
  competencies: PropTypes.array,
  infoMessage: PropTypes.string,
  currentTab: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeTab: PropTypes.func.isRequired,
  ratings: PropTypes.shape({
    values: PropTypes.array.isRequired,
    notApply: PropTypes.array.isRequired,
  }).isRequired,
}

export default FormCompetencies;
