// @packages
import React from 'react';
import PropTypes from 'prop-types';
import Button from 'smu-ui-components/ButtonV2';
import Link from 'smu-ui-components/Link';

// @own
import './styles.scss';

const FeedbackForLeaders = ({
  feedbackForLeadersEvaluations,
  feedbackForLeadersPeriodAverage,
  feedbackForLeadersPeriodEmptyState,
  feedbackForLeadersPeriodSurveyed,
  feedbackForLeadersSummaryResult,
  feedbackForLeadersViewDetails,
  goToProfileDetails,
}) =>
  !!feedbackForLeadersEvaluations ? (
    <div className="feedback-for-leaders">
      <div className="feedback-for-leaders__title">
        <h3 className="feedback-for-leaders__title-annual">
          {feedbackForLeadersSummaryResult?.title}
        </h3>
        <span className="feedback-for-leaders__title-rate">
          {feedbackForLeadersSummaryResult?.annualAverage}
        </span>
      </div>
      {feedbackForLeadersEvaluations.map((evaluation) => (
        <div className="feedback-for-leaders__half">
          <h4 className="feedback-for-leaders__half-title">
            {evaluation.title}
          </h4>
          <div className="feedback-for-leaders__half-content">
            <p className="feedback-for-leaders__half-content-surveyed">
              {feedbackForLeadersPeriodSurveyed}
            </p>
            <span className="feedback-for-leaders__half-content-total">
              {evaluation.summary?.surveyed}
            </span>
          </div>
          <div className="feedback-for-leaders__half-content">
            <p className="feedback-for-leaders__half-content-surveyed">
              {feedbackForLeadersPeriodAverage}
            </p>
            <span className="feedback-for-leaders__half-content-total">
              {evaluation.summary?.average}
            </span>
          </div>
          <Button
            className="feedback-for-leaders__half-button"
            size="small"
            variant="link"
          >
            <Link
              className="feedback-for-leaders__half-link"
              href={goToProfileDetails(evaluation.half)}
              target="_blank"
            >
              {feedbackForLeadersViewDetails}
            </Link>
          </Button>
        </div>
      ))}
    </div>
  ) : (
    <div className="feedback-for-leaders__empty-state">
      {feedbackForLeadersPeriodEmptyState}
    </div>
  );

FeedbackForLeaders.propTypes = {
  feedbackForLeadersSummaryResult: PropTypes.array.isRequired,
  goToProfileDetails: PropTypes.string.isRequired,
};

export default FeedbackForLeaders;
