import {
  SET_APP_ERROR,
} from './actionTypes';

const initialState = {
  error: false,
  message: '',
  status: undefined,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_APP_ERROR:
      return {
        ...state,
        error: true,
        message: payload.message,
        status: payload.status,
      };

    default:
      return state;
  }
}
