// @packages
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import withFullScreenModal from 'smu-app-components/FullScreenModal/withFullScreenModal';
import withRootModal from 'smu-app-components/RootModal/withRootModal';
import { add as showToast } from 'smu-app-components/ToastNotifications/actions';
import { connect } from 'react-redux';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import { push } from 'react-router-redux';
import isEmpty from 'lodash/isEmpty';

// @app
import Disconnecting from 'betterme-components/Profile/EngagementIndicatorModal/Disconnecting';
import FFLActive from 'betterme-components/assets/icons/ffl-active.svg';
import FFLDisabled from 'betterme-components/assets/icons/ffl-disabled.svg';
import FFLHover from 'betterme-components/assets/icons/ffl-hover.svg';
import SPActive from 'betterme-components/assets/icons/sp-active.svg';
import SPDisabled from 'betterme-components/assets/icons/sp-disabled.svg'
import SPHover from 'betterme-components/assets/icons/sp-hover.svg'
import SPMediumActive from 'betterme-components/assets/icons/sp-medium-active.svg';
import SPMediumDisabled from 'betterme-components/assets/icons/sp-medium-disabled.svg';
import SPMediumHover from 'betterme-components/assets/icons/sp-medium-hover.svg';
import SPTooltip from 'betterme-components/assets/icons/sp-tooltip.svg';
import Sidenav from 'betterme-components/Sidenav';
import getMessageType from 'utils/getMessageType';
import { formatDate } from 'utils/formatDate';
import {
  feedbackForLeadersMessages,
  feedbackMessages,
  goalsMessages,
  sectionsMessages,
  suggestedActionsMessages,
  userMessages,
} from 'common/messages';
import {
  ENGAGEMENT_INDICATOR_MODAL,
  FEEDBACK_FOR_LEADERS_INFO_MODAL,
  GOAL_DETAIL_VIEW_MODAL,
  PROFILE_MORE_INFO,
  SUGGEST_ACTIONS_MODAL,
} from 'betterme-components/constants/modalTypes';
import {
  assessmentHomePageLocation,
  getEngagementFormLocation,
  getLeadershipAdminLocation,
  getMyTeamGoalsLocation,
  getNewFeedbackForLeadersLocation,
  getNewFeedbackLocation,
  getNewFeedbackRequestLocation,
  getNewFeedbackRequestOnBehalfLocation,
  getMyTeamPromotionsLocation,
  getTeamEvaluationsLocation,
} from 'betterme-components/routes/urls';
import { trackAdminLeadershipClick } from 'betterme-components/services/LeadershipAdmin/analytics';
import {
  selectConfigurations,
  selectRatingsEngagementColors,
  selectUserDraftEvaluation,
  selectUserDraftEvaluationLoading,
} from 'betterme-components/Authorization/selectors';
import { getDraftSelfEvaluation } from 'betterme-components/Authorization/actions';
import {
  selectAssessmentCollaborators,
  selectAssessmentCollaboratorsLoading,
} from 'betterme-components/services/Assessment/selectors';
import { canCreateBehaviourRetrospective } from 'betterme-components/Evaluations/actions';

// @own
import {
  trackCreateEvaluation,
  trackCreateGoal,
  trackCreateSuggestionClick,
  trackEngagementFormOpen,
  trackEngagementFormPageView,
  trackEngagementIndicatorClick,
  trackEngagementIndicatorHover,
  trackEngagementIndicatorModal,
  trackRequestFeedback,
  trackRequestFeedbackOnBehalf,
  trackSendFeedback,
} from '../analytics';
import messages from '../messages';

const ProfileSidenav = ({
  allowsAddToMyTeam,
  allowsDisconnectingForm,
  allowsEngagementIndicator,
  allowsEvaluationDrafts,
  allowsEvaluations,
  allowsEvaluationsCreation,
  allowsPeriodEvaluation,
  allowsFeedbackForLeaders,
  allowsFeedbackRequest,
  allowsGlbFeatures,
  allowsGoals,
  allowsLeaderChangeCollaboratorAssignation,
  allowsLeaderWidgetInColleague,
  allowsPerformanceRetrospectiveCreation,
  allowsPerformanceRetrospectiveDraft,
  allowsPeriodEvaluationCreation,
  allowsPeriodEvaluationDraft,
  allowsPromotions,
  allowsRequestedOnBehalf,
  allowsSuggestions,
  assessmentCollaboratorsLoading,
  canCreateBehaviourRetrospective,
  collaboratorsByLeader,
  communityId,
  getDraftSelfEvaluation,
  goToAssessmentHomePage,
  goToEngagementForm,
  goToLeadershipAdmin,
  goToNewFeedbackForLeaders,
  intl: { formatMessage },
  isCollaborator,
  isMyLeader,
  loading,
  onUserAvatarClick,
  openFullscreenModal,
  openModal,
  sessionUser,
  user,
  userDraftEvaluation,
}) => {
  const {
    access: {
      engagementIndicators,
      evaluationAvailable,
      evaluations,
      feedback,
      feedbackForLeader,
      feedbackForLeadersReceived,
      feedbackOnBehalf,
      goals,
      newWordingForLeaders,
      otherLeaders,
      performanceRetrospective,
      suggestPromotion,
      suggestions,
    },
    account,
    area,
    email,
    endAssignment,
    engagement,
    firstName,
    hasCollaborators,
    job,
    lastName,
    newHireEvalAvailable,
    office,
    profileImageCode,
    project,
    seniority,
    trafficLightStatus,
  } = user;

  function showEngagementIndicatorForm() {
    const action = engagement?.status === 'not-engaged' ? 'form_open' : 'new_form_open';
    const page = engagement?.status === 'not-engaged' ? '/myteam/intervention-form' : '/myteam/intervention-form/new';
    trackEngagementFormOpen(action, engagement?.status);
    trackEngagementFormPageView(page);
    goToEngagementForm(user);
  };

  function getEngagementWithFormModalContent() {
    return (
      <Disconnecting
        messages={{
          followQuestion: formatMessage(
            messages.FeedbackProfileEngagementFollowQuestion
          ),
          followQuestionLink: formatMessage(
            messages.FeedbackProfileEngagementFollowQuestionLink
          ),
          noEngagedTitle: formatMessage(
            messages.FeedbackProfileEngagementNoEngagedTitle
          ),
          title: formatMessage(
            messages.FeedbackProfileEngagementFollowUpSuccessTitle
          ),
        }}
        onClickLink={() => {
          trackEngagementIndicatorModal('complete_modal_new', 'not-engaged-with-form');
          showEngagementIndicatorForm();
        }}
      />
    );
  };

  function getEngagementInfo(type) {
    switch (type) {
      case 'not-engaged':
        return ({
          color: '#fd317f',
          description: formatMessage(messages.FeedbackProfileEngagementNoEngaged),
          icon: 'no-engaged',
          onClick: () => {
            if (allowsDisconnectingForm) {
              showEngagementIndicatorForm();
            } else {
              handleEngagementIndicator(
                formatMessage(messages.FeedbackProfileEngagementNoEngagedTitle),
                formatMessage(messages.FeedbackProfileEngagementNoEngagedDescription),
                {
                  acceptAction: 'indicator_modal_click',
                  openModalAction: 'indicator_modal_open',
                  type: 'not-engaged',
                },
              );
            }
            trackEngagementIndicatorClick('not-engaged');
          },
          onMouseEnter: () => {
            trackEngagementIndicatorHover('not-engaged');
          },
        });

      case 'not-engaged-with-form':
        return ({
          color: '#01bc56',
          description: formatMessage(messages.FeedbackProfileEngagementNoEngaged),
          icon: 'no-engaged',
          onClick: () => {
            if (allowsDisconnectingForm) {
              handleEngagementIndicator(
                formatMessage(messages.FeedbackProfileEngagementFollowUpTitle),
                getEngagementWithFormModalContent(),
                {
                  acceptAction: 'complete_modal_click',
                  noPadding: true,
                  openModalAction: 'complete_modal_open',
                  type: 'not-engaged-with-form',
                }
              );
            } else {
              handleEngagementIndicator(
                formatMessage(messages.FeedbackProfileEngagementNoEngagedTitle),
                formatMessage(messages.FeedbackProfileEngagementNoEngagedDescription),
                {
                  acceptAction: 'indicator_modal_click',
                  openModalAction: 'indicator_modal_open',
                  type: 'not-engaged-with-form',
                },
              );
            }
            trackEngagementIndicatorClick('not-engaged-with-form');
          },
          onMouseEnter: () => {
            trackEngagementIndicatorHover('not-engaged-with-form');
          },
        });

      default:
        return undefined;
    };
  };

  function handleCreateSuggestion() {
    trackCreateSuggestionClick();
    openFullscreenModal({
      modalType: SUGGEST_ACTIONS_MODAL,
      modalProps: { user },
    });
  };

  function handleViewPersonalInformation() {
    const hasAccess = feedback || goals || evaluations || suggestions || feedbackForLeadersReceived;
    const userProps = [
      {
        label: formatMessage(userMessages.UserEmail),
        value: !hasAccess && email,
      },
      {
        label: formatMessage(userMessages.UserProject),
        value: project,
      },
      {
        label: formatMessage(userMessages.UserEndAssignment),
        value: hasAccess && formatDate(endAssignment),
      },
      {
        label: formatMessage(userMessages.UserOffice),
        value: office,
      },
      {
        label: formatMessage(userMessages.UserArea),
        value: area,
      },
      {
        label: formatMessage(userMessages.UserAccount),
        value: account,
      },
    ];
    openModal({
      modalType: PROFILE_MORE_INFO,
      modalProps: {
        firstName,
        job,
        lastName,
        profileImageCode,
        title: formatMessage(userMessages.UserPersonalInformation),
        userProps: userProps?.filter(item => !!item?.value),
        seniority: hasAccess && seniority,
      },
    });
  };

  function handleCreateGoalsClick() {
    trackCreateGoal();
    openFullscreenModal({
      modalType: GOAL_DETAIL_VIEW_MODAL,
      modalProps: { initialUsers: [user], user: sessionUser, type: 'CREATE' },
    });
  };

  function handleEngagementIndicator(title, description, otherProps) {
    openModal({
      modalType: ENGAGEMENT_INDICATOR_MODAL,
      modalProps: { title, description, ...otherProps },
    });
  };

  function handleFeedbackForLeadersSelectionModal() {
    goToNewFeedbackForLeaders(user?.identification);
  };

  function handleFeedbackForLeadersInfoModal() {
    openModal({
      modalType: FEEDBACK_FOR_LEADERS_INFO_MODAL,
      modalProps: {
        onAccept: handleFeedbackForLeadersSelectionModal,
        allowsGlbFeatures,
      },
    });
  };

  function handleGoToLeadershipAdmin(identification) {
    trackAdminLeadershipClick();
    goToLeadershipAdmin(identification);
  };

  const disableSuggestPromotionButton =
    !suggestPromotion ||
    trafficLightStatus === 'LOW' ||
    trafficLightStatus === null;
  const hasAccess = feedback || goals || evaluations || suggestions || feedbackForLeadersReceived;
  const showOtherLeaders = otherLeaders;
  const showEngagementIndicator = allowsEngagementIndicator && engagementIndicators;
  const showLeaderWidgetInColleague = hasAccess || allowsLeaderWidgetInColleague;
  const getMessage = getMessageType(communityId);
  const menuCreateEvaluation = !isEmpty(userDraftEvaluation)
    ? getMessage('EvaluationsEdit')
    : getMessage('EvaluationsCreate');
  const allowsClientsEvaluationCreation = allowsPeriodEvaluation;
  const allowsGlbEvaluationCreation = allowsGlbFeatures && (allowsPeriodEvaluationCreation || allowsPeriodEvaluationDraft);
  const allowsPerformanceRetrospective = allowsPerformanceRetrospectiveCreation || allowsPerformanceRetrospectiveDraft;
  const canCreatePerformanceRetrospective = allowsPerformanceRetrospective && performanceRetrospective;
  const isEvaluationAvailable = newHireEvalAvailable
    || ((allowsClientsEvaluationCreation || allowsGlbEvaluationCreation) && evaluationAvailable)
    || canCreatePerformanceRetrospective;
  const showChangeLeader = isCollaborator && allowsLeaderChangeCollaboratorAssignation;
  const suggestPromotionsResources = {
    HIGH: {
      imageResource: {
        active: SPActive,
        disabled: SPDisabled,
        hover: SPHover,
      },
      tooltip: {
        description: (
          <FormattedHTMLMessage
            defaultMessage={suggestedActionsMessages.SuggestActionsPromotionHighTooltipDescription.defaultMessage}
            id={suggestedActionsMessages.SuggestActionsPromotionHighTooltipDescription.id}
          />
        ),
        icon: SPTooltip,
        title: formatMessage(suggestedActionsMessages.SuggestActionsPromotionHighTooltipTitle),
      },
    },
    MEDIUM: {
      imageResource: {
        active: SPMediumActive,
        disabled: SPMediumDisabled,
        hover: SPTooltip,
      },
      tooltip: {
        description: (
          <FormattedHTMLMessage
            defaultMessage={suggestedActionsMessages.SuggestActionsPromotionMediumTooltipDescription.defaultMessage}
            id={suggestedActionsMessages.SuggestActionsPromotionMediumTooltipDescription.id}
          />
        ),
        icon: SPMediumHover,
        title: formatMessage(suggestedActionsMessages.SuggestActionsPromotionMediumTooltipTitle),
      },
    },
    LOW: {
      tooltip: {
        description: formatMessage(suggestedActionsMessages.SuggestActionsPromotionLowTooltipDescription),
        title: formatMessage(suggestedActionsMessages.SuggestActionsPromotionLowTooltipTitle),
      }
    }
  };
  const actionsList = [
    {
      disabled: !feedbackForLeader,
      label: formatMessage(feedbackForLeadersMessages.FeedbackforLeaders),
      onClick: handleFeedbackForLeadersInfoModal,
      resources: {
        imageResource: {
          active: FFLActive,
          disabled: FFLDisabled,
          hover: FFLHover,
        },
      },
      show: allowsFeedbackForLeaders && isMyLeader,
      type: 'bluebe',
    },
    {
      label: formatMessage(feedbackMessages.FeedbackSendFeedback),
      onClick: trackSendFeedback,
      show: true,
      to: getNewFeedbackLocation(user, false),
    },
    {
      disabled: !isEvaluationAvailable,
      label: formatMessage(menuCreateEvaluation),
      onClick: () => {
        trackCreateEvaluation();
        canCreateBehaviourRetrospective(performanceRetrospective);
      },
      show: newHireEvalAvailable || (allowsEvaluations
        && ((allowsEvaluationDrafts || allowsEvaluationsCreation)
          || allowsGlbEvaluationCreation || allowsPerformanceRetrospective)
        && (isCollaborator)),
      to: getTeamEvaluationsLocation({
        openCreateEvaluation: user?.id,
        isNewHire: newHireEvalAvailable,
        openedByUrl: true,
        performanceRetrospective,
      }),
    },
    {
      label: formatMessage(feedbackMessages.FeedbackRequestFeedback),
      onClick: trackRequestFeedback,
      show: allowsFeedbackRequest,
      to: getNewFeedbackRequestLocation(user, true),
    },
    {
      label: formatMessage(feedbackMessages.FeedbackRequestFeedbackOnBehalf),
      onClick: trackRequestFeedbackOnBehalf,
      show: allowsRequestedOnBehalf && feedbackOnBehalf,
      to: getNewFeedbackRequestOnBehalfLocation(user),
    },
    {
      label: formatMessage(sectionsMessages.SectionsOverview),
    },
    {
      label: formatMessage(goalsMessages.GoalsCreate),
      onClick: handleCreateGoalsClick,
      show: allowsGoals && isCollaborator,
      to: getMyTeamGoalsLocation(),
    },
    {
      disabled: disableSuggestPromotionButton,
      resources: suggestPromotion && suggestPromotionsResources[trafficLightStatus],
      label: formatMessage(suggestedActionsMessages.SuggestActionsPromotionButton),
      show: allowsPromotions && isCollaborator,
      to: getMyTeamPromotionsLocation(),
      type: 'bordered',
    },
    {
      label: formatMessage(userMessages.UserLeaderAddToMyTeam),
      to: getLeadershipAdminLocation(user?.identification),
      show: allowsAddToMyTeam && !isCollaborator && !isMyLeader,
    },
    {
      label: formatMessage(suggestedActionsMessages.SuggestedActionsSuggestActions),
      onClick: handleCreateSuggestion,
      show: allowsSuggestions && isCollaborator,
    },
  ];

  const optionsMenu = [
    {
      label: formatMessage(suggestedActionsMessages.SuggestedActionsSuggestActions),
      onClick: handleCreateSuggestion,
      show: allowsSuggestions && isCollaborator,
    },
    {
      label: formatMessage(userMessages.UserMoreInfo),
      onClick: handleViewPersonalInformation,
      show: true,
    },
  ];

  useEffect(() => {
    if (isCollaborator) {
      getDraftSelfEvaluation({
        evaluator: sessionUser?.identification,
        evaluee: user?.identification,
        isCollaborator: true,
        isNewHire: newHireEvalAvailable,
      });
    }
  }, []);

  return (
    <Sidenav
      actionsList={actionsList?.filter(action => action?.show)}
      allowsGlbFeatures={allowsGlbFeatures}
      onClickAction={{
        handleFeedbackForLeaders: handleFeedbackForLeadersInfoModal,
        handlePotentialAssessment: () => goToAssessmentHomePage(user),
      }}
      collaboratorsByLeader={collaboratorsByLeader}
      collaboratorsListTitle={formatMessage(userMessages.UserTeam)}
      engagement={showEngagementIndicator && getEngagementInfo(engagement && engagement?.status)}
      goToLeadershipAdmin={handleGoToLeadershipAdmin}
      hasCollaborators={hasCollaborators}
      isCollaborator={isCollaborator}
      loading={loading || assessmentCollaboratorsLoading}
      newHireEvalAvailable={newHireEvalAvailable}
      newWordingForLeaders={newWordingForLeaders}
      onUserAvatarClick={() => onUserAvatarClick(user?.identification)}
      optionsMenu={optionsMenu?.filter(action => action?.show)}
      showChangeLeader={showChangeLeader}
      showLeaderWidget={showLeaderWidgetInColleague}
      showOtherLeaders={showOtherLeaders}
      showSeniority={hasAccess}
      user={user}
    />
  )
}

ProfileSidenav.propTypes = {
  allowsAddToMyTeam: PropTypes.bool.isRequired,
  allowsDisconnectingForm: PropTypes.bool,
  allowsEngagementIndicator: PropTypes.bool.isRequired,
  allowsEvaluationDrafts: PropTypes.bool.isRequired,
  allowsEvaluations: PropTypes.bool.isRequired,
  allowsEvaluationsCreation: PropTypes.bool.isRequired,
  allowsFeedbackForLeaders: PropTypes.bool.isRequired,
  allowsFeedbackRequest: PropTypes.bool.isRequired,
  allowsGoals: PropTypes.bool.isRequired,
  allowsLeaderWidgetInColleague: PropTypes.bool.isRequired,
  allowsPromotions: PropTypes.bool.isRequired,
  allowsRequestedOnBehalf: PropTypes.bool.isRequired,
  allowsSuggestions: PropTypes.bool.isRequired,
  collaboratorsByLeader: PropTypes.object.isRequired,
  communityId: PropTypes.number.isRequired,
  goToEngagementForm: PropTypes.func,
  goToLeadershipAdmin: PropTypes.func,
  goToNewFeedbackForLeaders: PropTypes.func,
  intl: PropTypes.object.isRequired,
  isCollaborator: PropTypes.bool.isRequired,
  isMyLeader: PropTypes.bool,
  newHireEvalAvailable: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  ratingsEngagementColors: PropTypes.object,
  showToast: PropTypes.func,
  user: PropTypes.object.isRequired,
};

const goToEngagementForm = (user) => push(getEngagementFormLocation(user));
const goToNewFeedbackForLeaders = (identification) => push(getNewFeedbackForLeadersLocation(identification));
const goToLeadershipAdmin = (identification) => push(getLeadershipAdminLocation(identification));
const goToAssessmentHomePage = (user) => push(assessmentHomePageLocation(user));

const mapStateToProps = (state) => ({
  allowsDisconnectingForm: selectConfigurations('allowsDisconnectingForm')(state),
  allowsFeedbackForLeaders: selectConfigurations('allowsFeedbackForLeaders')(state),
  allowsGlbFeatures: selectConfigurations('allowsGlbFeatures')(state),
  allowsLeaderChangeCollaboratorAssignation: selectConfigurations('allowsLeaderChangeCollaboratorAssignation')(state),
  allowsPerformanceRetrospectiveCreation: selectConfigurations('allowsPerformanceRetrospectiveCreation')(state),
  allowsPerformanceRetrospectiveDraft: selectConfigurations('allowsPerformanceRetrospectiveDraft')(state),
  allowsEvaluationDrafts: selectConfigurations('allowsEvaluationDrafts')(state),
  allowsPeriodEvaluation: selectConfigurations('allowsPeriodEvaluation')(state),
  allowsPeriodEvaluationCreation: selectConfigurations('allowsPeriodEvaluationCreation')(state),
  allowsPeriodEvaluationDraft: selectConfigurations('allowsPeriodEvaluationDraft')(state),
  allowsPotentialAssessment: selectConfigurations('allowsPotentialAssessment')(state),
  assessmentCollaborators: selectAssessmentCollaborators(state),
  assessmentCollaboratorsLoading: selectAssessmentCollaboratorsLoading(state),
  ratingsEngagementColors: selectRatingsEngagementColors(state),
  userDraftEvaluation: selectUserDraftEvaluation(state),
  userDraftEvaluationLoading: selectUserDraftEvaluationLoading(state),
});

const mapDispatchToProps = {
  canCreateBehaviourRetrospective,
  getDraftSelfEvaluation,
  goToAssessmentHomePage,
  goToEngagementForm,
  goToLeadershipAdmin,
  goToNewFeedbackForLeaders,
  showToast,
};
export default connect(mapStateToProps, mapDispatchToProps)(
  withFullScreenModal(withRootModal(injectIntl(ProfileSidenav)))
);
