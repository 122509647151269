// @packages
import { takeLatest, call, put } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';

// @app
import { getFile } from '../../api';

// @own
import {
  fileDownloadFail,
  fileDownloadSuccess,
} from './actions';
import { FILE_DOWNLOAD } from './actionTypes';

function* downloadFileWorker({ payload: { id } }) {
  try {
    const response = yield call(getFile, id);
    yield put(fileDownloadSuccess(response.result));
  } catch(error) {
    yield put(fileDownloadFail(error.message));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

export default function* fileWatcher() {
  yield takeLatest(FILE_DOWNLOAD, downloadFileWorker);
}
