// @packages
import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import RootModal from 'smu-app-components/RootModal';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';

// @app
import GenericModal from 'components/templates/GenericModalTemplate';
import InfoCard from 'components/molecules/InfoCard';
import { centerModalClass } from 'betterme-components/ConfirmationModal';
import { REMOVE_LEADER_WELLDONE_MODAL } from 'betterme-components/constants/modalTypes';
import { SIZE_FORTY, SIZE_THIRTY_SIX } from 'components/common/constants';
import { selectLoading } from 'betterme-components/services/RemoveLeader/selectors';
import {
  trackAdminLeadershipRemoveClose,
  trackAdminLeadershipRemoveSuccess,
} from 'betterme-components/services/LeadershipAdmin/analytics';

// @own
import messages from './messages';

const RemoveLeaderWellDoneModal = ({
  closeModal,
  collaborator,
  goToMyProfile,
  intl: { formatMessage },
  leaderType,
  loading,
}) => {
  const username = `${collaborator?.firstName} ${collaborator?.lastName}`;

  function handleContinue() {
    closeModal();
    goToMyProfile();
    trackAdminLeadershipRemoveClose(leaderType);
  };

  const actionButton = [
    {
      label: formatMessage(messages.GoToMyProfile),
      onClick: handleContinue,
    },
  ];

  useEffect(() => {
    trackAdminLeadershipRemoveSuccess(leaderType);
  }, []);

  return (
    <GenericModal
      actionButtons={actionButton}
      header={
        <InfoCard
          icon="confirm_icon"
          iconSize={SIZE_FORTY}
          message={formatMessage(messages.WeNotifyThemToo, { username })}
          title={formatMessage(messages.YouveBeenRemoved)}
          titleSize={SIZE_THIRTY_SIX}
        />
      }
      loading={loading}
      onClose={() => handleContinue()}
      success
    />
  )
};

RemoveLeaderWellDoneModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  collaborator: PropTypes.string.isRequired,
  goToMyProfile: PropTypes.func,
  intl: PropTypes.object,
  leaderType: PropTypes.string,
  loading: PropTypes.bool,
};

const modalStyles = {
  width: '610px',
};

const mapDispatchToProps = {
  goToMyProfile: () => push('/myprofile'),
};

const mapStateToProps = (state) => ({
  loading: selectLoading(state),
});

export default RootModal(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(RemoveLeaderWellDoneModal)),
  REMOVE_LEADER_WELLDONE_MODAL,
  modalStyles,
  centerModalClass,
);
