// @packages
import { reverse } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Link from 'smu-ui-components/Link';

// @app
import CardSimple from 'common/CardSimple';
import PieChart from 'common/PieChart';
import { actionsMessages, goalsMessages } from 'common/messages';

// @own
import './styles.scss';
import GoalProgressList from '../GoalProgressList';
import messages from '../messages';
import { GOALS_ACHIEVED, GOALS_REMAINING } from '../constants';
import { getDashboardGoalsInfo } from '../actions';
import { trackDashGoalsViewMore } from '../analytics';
import {
  selectGoalsChartData,
  selectGoalsProgressData,
  selectLoadingDashboardGoals,
} from '../selectors';

class DashboardGoals extends Component {
  constructor(props) {
    super(props);

    const { intl: { formatMessage } } = props;

    this.goalsChartMessages = {
      [GOALS_ACHIEVED]: formatMessage(messages.FeedbackDashboardGoalsChartAchieved),
      [GOALS_REMAINING]: formatMessage(messages.FeedbackDashboardGoalsChartRemaining),
    };
    this.goalsChartColors = {
      [GOALS_ACHIEVED]: '#4B4EFF',
      [GOALS_REMAINING]: '#F0F2F5',
    };
  }

  componentDidMount() {
    this.getDashboardGoalsInfo();
  }

  componentDidUpdate(prevProps) {
    const {
      endDate: prevEndDate,
      startDate: prevStartDate,
      userId: prevUserId,
    } = prevProps;
    const {
      endDate: currentEndDate,
      startDate: currentStartDate,
      userId: currentUserId,
    } = this.props;

    const hasDateChanged = (prevEndDate !== currentEndDate) || (prevStartDate !== currentStartDate);

    if (hasDateChanged || (currentUserId && prevUserId !== currentUserId)) {
      this.getDashboardGoalsInfo();
    }
  }

  getDashboardGoalsInfo() {
    const {
      endDate,
      getDashboardGoalsInfo,
      startDate,
      userId,
    } = this.props;
    const creationTimeFrom = startDate ? startDate.valueOf() : undefined;
    const creationTimeTo = endDate ? endDate.valueOf() : undefined;

    getDashboardGoalsInfo(userId, creationTimeFrom, creationTimeTo);
  }

  render() {
    const {
      detailsPath,
      goalsChartData,
      goalsProgressData,
      intl: { formatMessage },
      loading,
      messagesEmptyStates,
    } = this.props;
    const goalsPieChartData = reverse(goalsChartData.map(({ count, type }) => ({
      color: this.goalsChartColors[type],
      count,
      name: this.goalsChartMessages[type],
    })));

    return (
      <CardSimple
        loading={loading}
        className="dashboard-goals"
        title={formatMessage(goalsMessages.GoalsGoals)}
        subtitle={formatMessage(messages.FeedbackDashboardGoalsSubtitle)}
      >
        {(goalsProgressData && goalsProgressData.total > 0)
          ? (
            <div className="dashboard-goals__content-chart">
              <div className="dashboard-goals__stats">
                <div className="dashboard-goals__stats-chart">
                  <PieChart
                    data={goalsPieChartData}
                    size={164}
                    strokeSize={22}
                  >
                    {goalsPieChartData[1] && (
                      <div className="dashboard-goals__stats-chart-label">
                        {`${goalsPieChartData[1].count}%`}
                      </div>
                    )}
                  </PieChart>
                </div>
                {goalsProgressData &&
                  <GoalProgressList
                    className="dashboard-goals__progress"
                    goalsData={goalsChartData.map(({ count }) => count)}
                    goalsProgressData={goalsProgressData}
                  />
                }
              </div>
              <div className="dashboard-goals__actions">
                <Link
                  className="dashboard-goals__actions-more"
                  color="black"
                  onClick={trackDashGoalsViewMore}
                  size="small"
                  to={detailsPath.goals}
                  type="button"
                >
                  {formatMessage(actionsMessages.ActionsViewMore)}
                </Link>
              </div>

            </div>
          )
          : (
            <div className="dashboard-goals__empty">
              <strong className="dashboard-goals__empty-message">
                {messagesEmptyStates.main}
              </strong>
            </div>
          )
        }
      </CardSimple>
    );
  }
}

DashboardGoals.propTypes = {
  getDashboardGoalsInfo: PropTypes.func.isRequired,
  goalsChartData: PropTypes.arrayOf(PropTypes.shape({
    count: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  })).isRequired,
  goalsProgressData: PropTypes.shape({
    closed: PropTypes.number,
    total: PropTypes.number,
  }),
  intl: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  messagesEmptyStates: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  return {
    goalsChartData: selectGoalsChartData(state),
    goalsProgressData: selectGoalsProgressData(state),
    loading: selectLoadingDashboardGoals(state),
  };
};

export default connect(mapStateToProps, { getDashboardGoalsInfo })(injectIntl(DashboardGoals));
