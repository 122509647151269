// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @app
import SkillSelectorTrigger from 'betterme-components/components/atoms/SkillSelectorTrigger';

// @own
import './styles.scss';
import CompetencyItem from '../CompetencyItem';

const findCompetency = (id) => (competency) => competency.id === id;

const CompetencySelector = ({
  addSkills,
  className,
  competencies,
  mandatoryCompetencies,
  onCompetencySelect,
  values,
}) => {
  const competencySelectorClass = cn('competency-selector', {
    [className]: !!className,
  });

  return (
    <div className={competencySelectorClass}>
      <div className="competency-selector__competencies-items">
        {competencies?.map((competency, index) => {
          const isMandatory = mandatoryCompetencies.some(findCompetency(competency.id));
          const competencyItemClass = cn('competency-selector__item', {
            'competency-selector__item--read-only': !onCompetencySelect || isMandatory,
          });
          const competencyItemProps = {
            className: competencyItemClass,
            competency,
            handleClick: onCompetencySelect && !isMandatory
              ? () => onCompetencySelect(competency)
              : undefined,
            selected: values.some(findCompetency(competency.id))
              || isMandatory,
          };

          return (
            <div className="competency-selector__item-wrapper" key={competency.id} >
              <CompetencyItem
                variant="filled"
                {...competencyItemProps}
                withFeatureDiscovery={index === 0}
              />
            </div>
          );
        })}
      </div>
      {addSkills && <SkillSelectorTrigger className="competency-selector__add-skill"/>}
    </div>
  );
};

CompetencySelector.defaultProps = {
  values: [],
  mandatoryCompetencies: [],
};

CompetencySelector.propTypes = {
  addSkills: PropTypes.bool,
  className: PropTypes.string,
  competencies: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })),
  mandatoryCompetencies: PropTypes.array,
  text: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
  }),
  onCompetencySelect: PropTypes.func,
  values: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
  })),
  readOnly: PropTypes.bool,
};

export default CompetencySelector;
