// @own
import {
  GET_SELF_EVALUATION_LIST,
  GET_SELF_EVALUATION_LIST_FAIL,
  GET_SELF_EVALUATION_LIST_SUCCESS,
  RESET_SELF_EVALUATION_LIST,
} from './actionTypes';

export function getSelfEvaluationsList(payload) {
  return {
    type: GET_SELF_EVALUATION_LIST,
    payload,
  }
}

export function getSelfEvaluationsListFail(payload) {
  return {
    type: GET_SELF_EVALUATION_LIST_FAIL,
    payload,
  }
}

export function getSelfEvaluationsListSuccess(payload) {
  return {
    type: GET_SELF_EVALUATION_LIST_SUCCESS,
    payload,
  }
}

export function resetSelfEvaluationsList(payload) {
  return {
    type: RESET_SELF_EVALUATION_LIST,
    payload,
  }
}
