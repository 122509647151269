import { useEffect } from 'react';

const Home = ({ router }) => {

  useEffect(() => {
    const query = window.location.href.split('?')?.[1];
    router.push(`/myprofile${query ? `?${query}` : ''}`);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default Home;
