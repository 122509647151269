import { defineMessages } from 'react-intl';

export default defineMessages({
  PromotionsPromotionFor: {
    id: 'Betterme.Messages.Promotions.PromotionFor',
    defaultMessage: 'Promotion for',
  },
  PromotionsGlober: {
    id: 'Betterme.Messages.Promotions.Glober',
    defaultMessage: 'Glober',
  },
  PromotionsCurrentSeniority: {
    id: 'Betterme.Messages.Promotions.CurrentSeniority',
    defaultMessage: 'Current seniority',
  },
  PromotionsPromotionStatus: {
    id: 'Betterme.Messages.Promotions.PromotionStatus',
    defaultMessage: 'Promotion status',
  },
  PromotionsParticipants: {
    id: 'Betterme.Messages.Promotions.Participants',
    defaultMessage: 'Participants',
  },
  PromotionsRequestPromotionTitle: {
    id: 'Betterme.Messages.Promotions.RequestPromotion',
    defaultMessage: 'Request Promotion',
  },
  PromotionsAcceptRequestTitle: {
    id: 'Betterme.Messages.Promotions.AcceptRequestTitle',
    defaultMessage: 'Review Promotion',
  },
  PromotionsAcceptRequestValidatorTitle: {
    id: 'Betterme.Messages.Promotions.AcceptRequestValidatorTitle',
    defaultMessage: 'Validate request',
  },
  PromotionsPromotionsHistory: {
    id: 'Betterme.Messages.Promotions.PromotionsHistory',
    defaultMessage: 'Promotions History',
  },
  PromotionsPositionsHistory: {
    id: 'Betterme.Messages.Promotions.PositionsHistory',
    defaultMessage: 'Positions History',
  },
  PromotionsStatusApplied: {
    id: 'Betterme.Messages.Promotions.Status.Applied',
    defaultMessage: 'Applied',
  },
  PromotionsMyTeamsPromotion: {
    id: 'Betterme.Messages.Promotions.MyTeamsPromotions',
    defaultMessage: 'My Team\'s Promotions',
  },
  PromotionsAttachedFiles: {
    id: 'Betterme.Messages.Promotions.AttachedFiles',
    defaultMessage: 'Attach files',
  },
  PromotionsAttachFile: {
    id: 'Betterme.Messages.Promotions.AttachFile',
    defaultMessage: 'Attach file',
  },
  PromotionsErrorNoSize: {
    id: 'Betterme.Messages.Promotions.Error.NoSize',
    defaultMessage: 'The file is empty. Try another file',
  },
  PromotionsErrorExceededSize: {
    id: 'Betterme.Messages.Promotions.Error.ExceededSize',
    defaultMessage: 'The file cannot exceed {size} MB',
  },
  PromotionsErrorInvalidFormat: {
    id: 'Betterme.Messages.Promotions.Error.InvalidFormat',
    defaultMessage: 'Invalid file type. The valid formats are: {formats}',
  },
  PromotionsDeleteModalMessage: {
    id: 'Betterme.Messages.Promotions.DeleteModal.Message',
    defaultMessage: 'File will be deleted. Are you sure?',
  },
  PromotionsPromoteTo: {
    id: 'Betterme.Messages.Promotions.PromoteTo',
    defaultMessage: 'Promote To',
  },
  PromotionsSupportedFormats: {
    id: 'Betterme.Messages.Promotions.SupportedFormats',
    defaultMessage: 'Attaching files is optional and they must have these formats:',
  },
  PromotionsWellDone: {
    id: 'Betterme.Messages.Promotions.WellDone',
    defaultMessage: 'Well done!',
  },
  PromotionsSuccessMessage: {
    id: 'Betterme.Messages.Promotions.SuccessMessage',
    defaultMessage: 'Your promotion suggestion for **{username}** has been created successfully! This suggestion will be considered for the next validation of the promotion process.',
  },
  PromotionsSuccessMessageRequest: {
    id: 'Betterme.Messages.Promotions.SuccessMessageJoinRequest',
    defaultMessage: 'Your promotion request for {username} has been created successfully!',
  },
  PromotionsPosition: {
    id: 'Betterme.Messages.Promotions.Position',
    defaultMessage: 'Position',
  },
  PromotionsPeriod: {
    id: 'Betterme.Messages.Promotions.Period',
    defaultMessage: 'Period',
  },
  PromotionsRequestedSeniority: {
    id: 'Betterme.Messages.Promotions.RequestedSeniority',
    defaultMessage: 'Requested seniority',
  },
  PromotionsDate: {
    id: 'Betterme.Messages.Promotions.Date',
    defaultMessage: 'Date',
  },
  PromotionsPreviousRequests: {
    id: 'Betterme.Messages.Promotions.PreviousRequests',
    defaultMessage: 'Previous Requests',
  },
  PromotionsNoFilesAttached: {
    id: 'Betterme.Messages.Promotions.NoFilesAttached',
    defaultMessage: 'No files attached',
  },
  PromotionsNoMoreSeniority: {
    id: 'Betterme.Messages.Promotions.NoMoreSeniority',
    defaultMessage: 'Contact Talent Team to review the next possible seniority',
  },
  PromotionsPresent: {
    id: 'Betterme.Messages.Promotions.Present',
    defaultMessage: 'Present',
  },
  PromotionsAll: {
    id: 'Betterme.Messages.Promotions.All',
    defaultMessage: 'All',
  },
  PromotionsNotRequested: {
    id: 'Betterme.Messages.Promotions.NotRequested',
    defaultMessage: 'Not Requested',
  },
  PromotionsPromotionRequest: {
    id: 'Betterme.Messages.Promotions.PromotionRequest',
    defaultMessage: 'Promotion Request',
  },
  PromotionsWhyPromote: {
    id: 'Betterme.Messages.Promotions.WhyPromote',
    defaultMessage: 'Promotion answer justification',
  },
  PromotionsAssignerTitle: {
    id: 'Betterme.Messages.Promotions.AssignerTitle',
    defaultMessage: 'Validator assigned by',
  },
  PromotionsJustificationEmptyState: {
    id: 'Betterme.Messages.Promotions.JustificationEmptyState',
    defaultMessage: "There's no comment",
  },
  PromotionsJustificationPlaceholder: {
    id: 'Betterme.Messages.Promotions.JustificationPlaceHolder',
    defaultMessage: 'Before sending the promotion suggestion please make sure you consider Feedback (from client, other leaders, 360º), Goals Achievement (Project/Account and individual), Performance and potential, Tech Skills related to the seniority (MyGrowth) and Soft Skills (Talent Manifesto and StarMeUp DNA)',
  },
  PromotionsCreatedBy: {
    id: 'Betterme.Messages.Promotions.CreatedBy',
    defaultMessage: 'Created by',
  },
  PromotionsNoEvaluations: {
    id: 'Betterme.Messages.Promotions.NoEvaluations',
    defaultMessage: "This collaborator hasn't received an evaluation during the last six (6) months, please evaluate them to suggest a promotion",
  },
  PromotionsRequest: {
    id: 'Betterme.Messages.Promotions.Request',
    defaultMessage: 'Request',
  },
  PromotionsReadiness: {
    id: 'Betterme.Messages.Promotions.Readiness',
    defaultMessage: 'Readiness',
  },
  PromotionsRequestStatus: {
    id: 'Betterme.Messages.Promotions.RequestStatus',
    defaultMessage: 'Request Status',
  },
  PromotionsStatusAssignValidator: {
    id: 'Betterme.Messages.Promotions.PromotionsAssignValidator',
    defaultMessage: 'Assign Validator',
  },
  PromotionsStatusDeclinedByLeader: {
    id: 'Betterme.Messages.Promotions.PromotionsStatusDeclinedByLeader',
    defaultMessage: 'Declined by Leader',
  },
  PromotionsStatusDeclinedByValidator: {
    id: 'Betterme.Messages.Promotions.PromotionsStatusDeclinedByValidator',
    defaultMessage: 'Declined by Validator',
  },
  PromotionsStatusDraft: {
    id: 'Betterme.Messages.Promotions.PromotionsStatusDraft',
    defaultMessage: 'Draft',
  },
  PromotionsStatusLeaderReview: {
    id: 'Betterme.Messages.Promotions.PromotionsStatusLeaderReview',
    defaultMessage: 'Waiting for Agreement',
  },
  PromotionsStatusValidatorReview: {
    id: 'Betterme.Messages.Promotions.PromotionsStatusValidatorReview',
    defaultMessage: 'Validator Review',
  },
  PromotionsStatusPending: {
    id: 'Betterme.Messages.Promotions.PromotionsStatusPending',
    defaultMessage: 'Pending',
  },
  PromotionsStatusTalentReview: {
    id: 'Betterme.Messages.Promotions.PromotionsStatusTalentReview',
    defaultMessage: 'Talent Review',
  },
  PromotionsAssignValidator: {
    id: 'Betterme.Messages.Promotions.PromotionsAssignValidator',
    defaultMessage: 'Assign Validator',
  },
  PromotionsReview: {
    id: 'Betterme.Messages.Promotions.PromotionsReview',
    defaultMessage: 'Review',
  },
  PromotionsRequestedBy: {
    id: 'Betterme.Messages.Promotions.PromotionsRequestedBy',
    defaultMessage: 'Requested By',
  },
  PromotionsDeclinedBy: {
    id: 'Betterme.Messages.Promotions.PromotionsDeclinedBy',
    defaultMessage: 'Declined By',
  },
  PromotionsValidatedBy: {
    id: 'Betterme.Messages.Promotions.PromotionsValidatedBy',
    defaultMessage: 'Validated By',
  },
  PromotionsRequestedByTable: {
    id: 'Betterme.Messages.Promotions.RequestedBy',
    defaultMessage: 'Requested By',
  },
  PromotionsRequestedSecondaryPromotion: {
    id: 'Betterme.Messages.Promotions.PromotionsRequestedSecondaryPromotion',
    defaultMessage: 'You cannot perform this action. Another Experience leader already created this request.',
  },
  PromotionsSuccessMessageToast: {
    id: 'Betterme.Messages.Promotions.PromotionsSuccessMessageToast',
    defaultMessage: 'The request has been accepted',
  },
  PromotionsAddValidatorDescription: {
    id: 'Betterme.Messages.Promotions.PromotionsAddValidatorDescription',
    defaultMessage: 'Look for a validator and send a request in order to proceed with the promotion process',
  },
  PromotionsAddValidatorPlaceholder: {
    id: 'Betterme.Messages.Promotions.PromotionsAddValidatorPlaceholder',
    defaultMessage: 'Search Validator',
  },
  PromotionsEmptyHistoryMessage: {
    id: 'Betterme.Messages.Promotions.PromotionsEmptyHistoryMessage',
    defaultMessage: 'This collaborator doesn’t have any promotion requests created.',
  },
  PromotionsAppliedSeniorityDate: {
    id: 'Betterme.Messages.Promotions.AppliedSeniorityDate',
    defaultMessage: '{count} days ago',
  },
  PromotionsReadyToPromoteTitle: {
    id: 'Betterme.Messages.Promotions.ReadyToPromoteTitle',
    defaultMessage: 'Ready to be promoted',
  },
  PromotionsReadyToPromote: {
    id: 'Betterme.Messages.Promotions.ReadyToPromote',
    defaultMessage: 'Promotion is recommended while all requirements are fulfilled.'
  },
  PromotionsNotReady: {
    id: 'Betterme.Messages.Promotions.NotReady',
    defaultMessage: 'You will be able to request the promotion when your collaborator is ready.'
  },
  PromotionsDeclineMessageToast: {
    id: 'Betterme.Messages.Promotions.PromotionsDeclineMessageToast',
    defaultMessage: 'You have declined the promotion request for {username}.',
  },
  PromotionsAcceptedByValidatorMessageToast: {
    id: 'Betterme.Messages.Promotions.PromotionsAcceptedByValidatorMessageToast',
    defaultMessage: 'You have accepted the promotion request for {username}.',
  },
  PromotionsAssignValidatorMessageToast: {
    id: 'Betterme.Messages.Promotions.PromotionsAssignValidatorMessageToast',
    defaultMessage: 'You have assigned {validatorName} as a Validator to validate the promotion request for {username}.',
  },
  PromotionsAddValidatorMessage: {
    id: 'Betterme.Messages.Promotions.AddValidatorMessage',
    defaultMessage: 'A Validator needs to be added to accept the promotion request.',
  },
  PromotionsSuggestedActionRedirect: {
    id: 'Betterme.Messages.Promotions.SuggestedActionRedirect',
    defaultMessage: 'If you want to request a promotion for your team, <a href={goTo} target="_blank">click here</a> and start the New Promotion Journey.',
  },
  PromotionsRequestModal: {
    id: 'Betterme.Messages.Promotions.PromotionsRequestModal',
    defaultMessage: 'A notification will be sent to Talent in order to review the Collaborator’s situation.',
  },
  PromotionsRequestModalTitle: {
    id: 'Betterme.Messages.Promotions.PromotionsRequestModalTitle',
    defaultMessage: 'You are about to create a request',
  },
  PromotionsNoValidatorSuggestions: {
    id: 'Betterme.Messages.Promotions.PromotionsNoValidatorSuggestions',
    defaultMessage: 'There are no suggestions available.',
  },
  PromotionsRequestEmptyTitle1: {
    id: 'Betterme.Messages.Promotions.RequestEmptyTitle1',
    defaultMessage: 'Looks like you’ve already responded this request.',
  },
  PromotionsRequestEmptyTitle2: {
    id: 'Betterme.Messages.Promotions.RequestEmptyTitle2',
    defaultMessage: 'It doesn’t seem you have access to this page.',
  },
  PromotionsRequestEmptyDescription2: {
    id: 'Betterme.Messages.Promotions.RequestEmptyDescription2',
    defaultMessage: 'If you believe you should have permission, please contact your Administrator.',
  },
  PromotionsStatusAll: {
    id: "Betterme.Messages.Promotions.all",
    defaultMessage: "All",
  },
  PromotionsRequestStatusLeaderReview: {
    id: "Betterme.Messages.Promotions.leaderReview",
    defaultMessage: "Waiting for agreement",
  },
  PromotionsRequestStatusAssignManager: {
    id: "Betterme.Messages.Promotions.assignManager",
    defaultMessage: "Assign Validator",
  },
  PromotionsRequestStatusManagerReview: {
    id: "Betterme.Messages.Promotions.managerReview",
    defaultMessage: "Validator review",
  },
  PromotionsRequestStatusTalentReview: {
    id: "Betterme.Messages.Promotions.talentReview",
    defaultMessage: "Talent review",
  },
  PromotionsRequestStatusApplied: {
    id: "Betterme.Messages.Promotions.applied",
    defaultMessage: "Applied",
  },
  PromotionsRequestStatusNotRequested: {
    id: "Betterme.Messages.Promotions.notRequested",
    defaultMessage: "Not requested",
  },
  PromotionsActionsRequest: {
    id: "Betterme.Messages.Promotions.requested",
    defaultMessage: "Request",
  },
  PromotionsActionsReview: {
    id: "Betterme.Messages.Promotions.review",
    defaultMessage: "Review",
  },
  PromotionsActionsAssignManager: {
    id: "Betterme.Messages.Promotions.assignManager",
    defaultMessage: "Assign Validator",
  },
  PromotionsWarningLeaderLeft: {
    id: 'Betterme.Messages.Promotions.WarningLeaderLeft',
    defaultMessage: 'A leader is no longer active. Please add a validator to continue with this promotion request.',
  },
  PromotionsActionsRemoveFile: {
    id: "Betterme.Messages.Promotions.removeFile",
    defaultMessage: "Remove file",
  },
});
