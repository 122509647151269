// @packages
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useModal from 'smu-custom-hooks/useModal';
import usePrevious from 'smu-custom-hooks/usePrevious';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

// @app
import { ENABLED_STARMEUP } from 'betterme-components/services/CommunityConfigs/constants';
import { getCommunityConfigValueBool } from 'betterme-components/services/CommunityConfigs/selectors';

// @own
import { MODAL_HANDLERS, MODAL_COMPONENTS } from './constants';

function QueryModals({
  enabledStarMeUp,
  router: {
    push,
    location: { query },
  },
}) {
  const modal = query?.modal ?? '';
  const prevModal = usePrevious(modal);
  const { close, isOpen, open, data } = useModal();
  const ModalComponent = MODAL_COMPONENTS[modal];
  const showModalComponent = isOpen && !!ModalComponent;

  useEffect(() => {
    if (isOpen && !showModalComponent) {
      close();
    }
  }, [isOpen, showModalComponent, close]);

  useEffect(() => {
    function handleClose(path = '/myprofile') {
      push(path);
    }

    if (
      prevModal !== modal &&
      modal &&
      typeof MODAL_HANDLERS[modal.toLowerCase()] === 'function'
    ) {
      MODAL_HANDLERS[modal]({
        enabledStarMeUp,
        onClose: handleClose,
        open,
        query,
      });
    }
  }, [query, modal, prevModal, push, open]);

  return showModalComponent ? (
    <ModalComponent onClose={close} open={isOpen} {...data} />
  ) : null;
}

QueryModals.propTypes = {
  enabledStarMeUp: PropTypes.bool,
  intl: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  enabledStarMeUp: getCommunityConfigValueBool(state, ENABLED_STARMEUP),
});

export default connect(mapStateToProps)(injectIntl(withRouter(QueryModals)));
