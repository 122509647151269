// @package
import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import cn from 'classnames';
import AvatarMedia from 'smu-ui-components/AvatarMedia';
import Label from 'smu-ui-components/Label';

// @own
import './styles.scss';
import messages from '../messages';

const LeaderCard = ({
  evaluated,
  firstName,
  highlightedAvatar,
  highlighted,
  intl: { formatMessage },
  job,
  lastName,
  onClick,
  profileImageCode,
}) => (
  <div
    className={cn('leader-card', {
    'leader-card--clickable': onClick && !evaluated,
    'leader-card--evaluated': evaluated,
    'leader-card--highlighted-avatar': highlightedAvatar,
    'leader-card--highlighted': highlighted,
    })}
    onClick={!evaluated && onClick}
  >
    <div className="leader-card__avatar">
      <AvatarMedia
        disabled={evaluated}
        firstName={firstName}
        job={job}
        lastName={lastName}
        profileImageCode={profileImageCode}
        toTop
        useLink={false}
      />
    </div>
    {evaluated && (
      <div className="leader-card__evaluated">
        <Label
          color="btm-blue"
          size="small"
          text={formatMessage(messages.FeedbackForLeadersSelectionModalEvaluated)}
        />
      </div>
    )}
  </div>
);

LeaderCard.propTypes = {
  evaluated: PropTypes.bool,
  firstName: PropTypes.string.isRequired,
  highlightedAvatar: PropTypes.bool,
  highlighted: PropTypes.bool,
  intl: PropTypes.object.isRequired,
  job: PropTypes.string,
  lastName: PropTypes.string.isRequired,
  profileImageCode: PropTypes.string.isRequired,
};

export default injectIntl(LeaderCard);
