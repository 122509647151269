import { defineMessages } from 'react-intl';

export default defineMessages({
  SuggestedActionsPlaceHolder: {
    id: 'Betterme.PotentialBox.SuggestedActionsPlaceHolder',
    defaultMessage: 'Leave a comment about the suggested actions',
  },
  SuggestedActionsEmptyState: {
    id: 'Betterme.PotentialBox.SuggestedActionsEmptyState',
    defaultMessage: 'There are no suggested actions available.',
  },
  SuggestedActionsDisclaimer: {
    id: 'Betterme.PotentialBox.SuggestedActionsDisclaimer',
    defaultMessage: '{username} won\'t see these suggestions',
  },
  SuggestPromotionCustomText: {
    id: 'Betterme.PotentialBox.SuggestPromotionCustomText',
    defaultMessage: 'Referring to the suggestion',
  },
});