// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import UserAvatar from 'smu-ui-components/UserAvatar';

// @own
import './styles.scss';

const FooterCard = ({
  className,
  sentBy,
  userAvatar,
  userName,
}) => {
  const userAvatarProps = {
    profileImageCode: userAvatar.profileImageCode,
    height: 32,
    width: 32,
  };

  return (
    <div className={cn('footer-card', className)}>
      <UserAvatar className="footer-card__avatar" {...userAvatarProps} />
      <span className="footer-card__sent-by">
        {sentBy}
      </span>
      <span className="footer-card__user-name">
        {userName}
      </span>
    </div>
  );
};

FooterCard.defaultProps = {
  userAvatar:{
    profileImageCode: 'ekuipdnpfmuxuecuweoy_png',
  }
};

FooterCard.propTypes = {
  className: PropTypes.string,
  sentBy: PropTypes.string,
  userAvatarProps: PropTypes.object,
  userName: PropTypes.string,
};

export default FooterCard;
