import { defineMessages } from 'react-intl';

export default defineMessages({
  SkillSelectorModalAdd: {
    id: 'Betterme.SkillSelectorModal.ModalAdd',
    defaultMessage: 'Add',
  },
  SkillSelectorAdd: {
    id: 'Betterme.SkillSelectorModal.Add',
    defaultMessage: 'Add skills',
  },
  SkillSelectorSearchPlaceholder: {
    id: 'Betterme.SkillSelector.SearchPlaceholder',
    defaultMessage: 'Search skills',
  },
  SkillSelectorSkillLimitText: {
    id: 'Betterme.SkillSelector.SkillLimitText',
    defaultMessage: 'Find and select up to ten new skills to be more accurate on your Feedback.',
  },
  SkillSelectorSkillLimitTextEvaluation: {
    id: 'Betterme.SkillSelector.SkillLimitTextEvaluation',
    defaultMessage: 'Find and select up to ten new skills to be more accurate on your Evaluation.',
  },
});
