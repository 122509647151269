import {
  ADD_TO_MY_TEAM,
  ADD_TO_MY_TEAM_FAIL,
  ADD_TO_MY_TEAM_SUCCESS,
} from './actionTypes';

const initialState = {
  error: null,
  loading: false,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case ADD_TO_MY_TEAM:
      return { ...state, loading: true };

    case ADD_TO_MY_TEAM_FAIL:
      return { ...state, error: payload.error, loading: false };

    case ADD_TO_MY_TEAM_SUCCESS:
      return { ...state, loading: false };

    default:
      return state;
  }
}
