// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'smu-ui-components/IconV2';
import Segment from 'smu-ui-components/Segment';

// @app
import ActionButtons from 'components/molecules/ActionButtons';
import ActionButtonsWithOptions from 'components/molecules/ActionButtonsWithOptions';

// @own
import './styles.scss';

const GenericModalTemplate = ({
  actionButtons,
  actionButtonsWithOptions,
  className,
  content,
  disabledClose,
  disclaimer,
  header,
  loading,
  onClose,
  success,
  warning,
}) => (
  <Segment
    className={cn('generic-modal-template', className)}
    loading={loading}
    loadingType="betterme"
  >
    <div className={cn('generic-modal-template__header', {
      'generic-modal-template__header--success': success,
      'generic-modal-template__header--warning': warning,
    })}>
      {!disabledClose && (
        <Icon
          className="generic-modal-template__header-close"
          icon="close"
          onClick={onClose}
          size="x-small"
        />
      )}
      {header}
    </div>
    {content && (
      <div className="generic-modal-template__content">
        {content}
        {disclaimer && (
          <div className="generic-modal-template__content--disclaimer">
            {disclaimer}
          </div>
        )}
      </div>
    )}
    {(actionButtons || actionButtonsWithOptions) && (
      <div className={cn('generic-modal-template__footer', {
        'generic-modal-template__footer--options': actionButtonsWithOptions,
      })}>
        {actionButtons && (
          <ActionButtons buttons={actionButtons} />
        )}
        {actionButtonsWithOptions && (
          <ActionButtonsWithOptions buttons={actionButtonsWithOptions} />
        )}
      </div>
    )}
  </Segment>
);

GenericModalTemplate.propTypes = {
  actionButtons: PropTypes.array,
  className: PropTypes.string,
  content: PropTypes.node,
  disabledClose: PropTypes.bool,
  header: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  success: PropTypes.bool,
  warning: PropTypes.bool,
};

export default GenericModalTemplate;
