// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Segment from 'smu-ui-components/Segment';

// @own
import './styles.scss';
import SectionListItem from './SectionListItem';

const SectionListV2 = ({
  className,
  fitContent,
  isSkill,
  listItems,
  loading,
  messagesEmptyStates,
  onClick,
  title,
  value,
}) => (
  <Segment
    className={cn('section-list-v2', className)}
    loading={loading}
    loadingType="betterme"
  >
    <div className="section-list-v2__header">
      <h4 className="section-list-v2__title">{title}</h4>
    </div>

    {!listItems.length && messagesEmptyStates ? (
      <div className="section-list-v2__empty">
        <strong className="section-list-v2__empty-message">
          {messagesEmptyStates.main}
        </strong>
        <span className="section-list-v2__empty-suggestion">
          {messagesEmptyStates.suggestion}
        </span>
      </div>
    ) : (
      <ul className={cn("section-list-v2__list", {"section-list-v2__list--fit-content": fitContent})}>
        {listItems.map((item) => (
          <SectionListItem
            {...item}
            active
            isSkill={isSkill}
            key={item.value}
            onClick={() => onClick(item.value)}
            selected={value === item.value}
          />
        ))}
      </ul>
    )}
  </Segment>
);

SectionListV2.defaultProps = {
  isSkill: false,
};

SectionListV2.propTypes = {
  maxItems: PropTypes.number,
  messagesEmptyStates: PropTypes.object,
  onClick: PropTypes.func,
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      displayValue: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

export default SectionListV2;
