import {
  GET_GOAL_DETAIL,
  GET_GOAL_DETAIL_FAIL,
  GET_GOAL_DETAIL_SUCCESS,
  GET_GOALS_PERIOD,
  GET_GOALS_PERIOD_FAIL,
  GET_GOALS_PERIOD_SUCCESS,
  SELECT_GOAL,
  RESET_GOAL_DETAIL,
} from './actionTypes';

export function getGoalsPeriod() {
  return {
    type: GET_GOALS_PERIOD,
  };
}

export function getGoalsPeriodSuccess(response) {
  return {
    type: GET_GOALS_PERIOD_SUCCESS,
    payload: { response },
  };
}

export function getGoalsPeriodFail(error) {
  return {
    type: GET_GOALS_PERIOD_FAIL,
    payload: { error },
  };
}

export function getGoalDetail(id, userId) {
  return {
    type: GET_GOAL_DETAIL,
    payload: { id, userId },
  };
}

export function getGoalDetailSuccess(response) {
  return {
    type: GET_GOAL_DETAIL_SUCCESS,
    payload: { response },
  };
}

export function getGoalDetailFail(error) {
  return {
    type: GET_GOAL_DETAIL_FAIL,
    payload: { error },
  };
}

export function selectGoal(goalId) {
  return {
    type: SELECT_GOAL,
    payload: { goalId }
  };
}

export function resetGoalDetail() {
  return {
    type: RESET_GOAL_DETAIL,
  };
}
