// @packages
import { orderBy } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

// @app
import SkillSelector from 'components/containers/SkillSelector';

// @own
import './styles.scss';
import DraggableCompetencyItem from '../DraggableCompetencyItem';
import RateBox from '../RateBox';
import withDragState from '../withDragState';
import CustomDragLayer from '../CustomDragLayer';

const CompetenciesRateSelector = ({
  className,
  competencies,
  isDragging,
  mandatoryCompetencies,
  onBeginDrag,
  onCompetencyDrop,
  onEndDrag,
  onRateBoxItemClick,
  ratedCompetencies,
  rates,
  showSkills,
  skillSelectorProps,
}) => {
  const bigHeader = rates.some((rate) => rate.name.length > 20);
  const draggingClass = isDragging ? 'competencies-rate-selector--dragging' : '';
  return (
    <div className={`competencies-rate-selector ${className || ''} ${draggingClass}`}>
      <CustomDragLayer />
      <div className="competencies-rate-selector__competencies-items">
        {competencies?.map((competency, index) => {
          const mandatory = mandatoryCompetencies.find((mandatoryCompetency) =>
            mandatoryCompetency.id === competency.id);
          return (
            <DraggableCompetencyItem
              className="competencies-rate-selector__competencies-item"
              competency={competency}
              key={competency.id}
              onCompetencyDrop={onCompetencyDrop.bind(this)}
              onBeginDrag={onBeginDrag}
              onEndDrag={onEndDrag}
              selected={!!mandatory}
              withFeatureDiscovery={(index === 0)}
            />
          );
        })}
      </div>
      {showSkills && (
        <SkillSelector
          initialSkills={skillSelectorProps.initialSkills}
          label={skillSelectorProps.label}
          onAdd={skillSelectorProps.onAdd}
          onModalClose={skillSelectorProps.onModalClose}
          onSkillClick={skillSelectorProps.onSkillClick}
        />
      )}
      <div className="competencies-rate-selector__boxs-wrapper">

        <div className="competencies-rate-selector__boxs">
          {orderBy(rates, ['ordinality']).map((rate) => {
            const elements = ratedCompetencies.filter((ratedCompetency) => ratedCompetency.rateId === rate.id)
              .map((ratedCompetency) => ratedCompetency.competency);

            return (
              <div className="competencies-rate-selector__box-wrapper" key={rate.id}>
                <RateBox
                  {...rate}
                  className="competencies-rate-selector__rate-box"
                  elements={elements}
                  onRateBoxItemClick={onRateBoxItemClick}
                  bigHeader={bigHeader}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default withDragState(CompetenciesRateSelector);

CompetenciesRateSelector.propTypes = {
  className: PropTypes.string,
  competencies: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  })),
  isDragging: PropTypes.bool,
  mandatoryCompetencies: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
  })),
  onBeginDrag: PropTypes.func.isRequired,
  onCompetencyDrop: PropTypes.func.isRequired,
  onEndDrag: PropTypes.func.isRequired,
  onRateBoxItemClick: PropTypes.func.isRequired,
  ratedCompetencies: PropTypes.arrayOf(PropTypes.shape({
    competency: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
    rateId: PropTypes.number,
  })),
  rates: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    color: PropTypes.string,
  })),
  showSkills: PropTypes.bool,
};
