// @packages
import React, { useState, useEffect } from 'react';
import TextWrapper from 'smu-ui-components/TextWrapper';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @app
import SelectionChip from 'betterme-components/components/molecules/SelectionChip';
import DescriptionTooltip from 'common/DescriptionTooltip';

// @own
import './styles.scss';

const CompetencyRow = ({
  className,
  description,
  id,
  name,
  onCompetencyAdd,
  onCompetencyRemove,
  rates,
  ratings,
  selectedRate,
  skill,
  showOverview,
  total: totalRates,
  useAsync,
}) => {
  const tooltipInfo = description?.length ? description : name;
  const [currentSelectedRate, setCurrentSelectedRate] = useState();
  async function handleSelect(rate) {
    await onCompetencyRemove({ id });

    if (rate.id !== selectedRate) {
      onCompetencyAdd({ id, name }, rate);
    }
  }

  function handleAdd(rate) {
    if (currentSelectedRate?.id) {
      if (currentSelectedRate.id === rate.id) {
        onCompetencyRemove({ id });
        setCurrentSelectedRate(null);
      } else {
        onCompetencyRemove({ id: currentSelectedRate.competencyId });
        setCurrentSelectedRate({...rate, competencyId: id})
        onCompetencyAdd({ id, name: currentSelectedRate.name }, rate);
      }
    } else {
      onCompetencyAdd({ id, name }, rate);
      setCurrentSelectedRate({...rate, competencyId: id});
    }
  }

  return (
    <div className="competency-row">
      <div className={cn('competency-row__name', {
        'competency-row__name--blue': skill,
      })}>
        <DescriptionTooltip description={tooltipInfo}>
          <TextWrapper>
            {name}
          </TextWrapper>
        </DescriptionTooltip>
      </div>
      <div className="competency-row__rates">
        {rates?.map(({
          color,
          id,
          imageCode,
          name: label,
          oppositeImageCode,
        },
          index,
        ) => {
          const rating = ratings?.find(rating => rating.id === id);

          return (
            <SelectionChip
              className="competency-row__selection-chip"
              active={id === selectedRate}
              key={index}
              onClick={useAsync ? () => handleSelect({ id, label }) : () => handleAdd({ id, label })}
              showOverview={showOverview}
              totalRates={totalRates}
              rates={rating?.count}
              item={{
                color,
                imageCode,
                oppositeImageCode,
                label,
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

CompetencyRow.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onCompetencyAdd: PropTypes.func.isRequired,
  onCompetencyRemove: PropTypes.func.isRequired,
  rates: PropTypes.array.isRequired,
  selectedRate: PropTypes.number,
  showOverview: PropTypes.bool,
  ratings: PropTypes.array,
  skill: PropTypes.bool,
  total: PropTypes.number,
  useAsync: PropTypes.bool,
};

export default CompetencyRow;
