import {
  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
  RESET_PROFILE,
} from './actionTypes';

export function getProfile(identification) {
  return {
    type: GET_PROFILE,
    payload: { identification },
  };
}

export function getProfileSuccess(response) {
  return {
    type: GET_PROFILE_SUCCESS,
    payload: { response },
  };
}

export function getProfileFail(error) {
  return {
    type: GET_PROFILE_FAIL,
    payload: { error }
  };
}

export function resetProfile() {
  return {
    type: RESET_PROFILE,
  };
}
