// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { get } from 'lodash';

// @app
import CardSimple from 'common/CardSimple';
import { feedbackMessages } from 'common/messages';

// @own
import './styles.scss';

const DashboardFeedbackSummary = ({
  feedbackSummary: {
    pendingReceivedFeedbackRequests,
    pendingSentFeedbackRequests,
    receivedFeedback,
    sentFeedback,
  },
  intl: { formatMessage },
  loading,
}) => {
  const pending = get(pendingReceivedFeedbackRequests, 'total', 0);
  const received = get(receivedFeedback, 'total', 0);
  const requested = get(pendingSentFeedbackRequests, 'total', 0);
  const sent = get(sentFeedback, 'total', 0);

  return (
    <CardSimple
      loading={loading}
      className="dashboard-feedback-summary"
      title={formatMessage(feedbackMessages.FeedbackSummary)}
    >
      <div className="dashboard-feedback-summary__content">
        <ul className="dashboard-feedback-summary__list">
          <li className="dashboard-feedback-summary__item">
            <span className="dashboard-feedback-summary__item-value">
              {received}
            </span>
            <span className="dashboard-feedback-summary__item-label">
              {formatMessage(feedbackMessages.FeedbackReceived)}
            </span>
          </li>
          <li className="dashboard-feedback-summary__item">
            <span className="dashboard-feedback-summary__item-value">
              {sent}
            </span>
            <span className="dashboard-feedback-summary__item-label">
              {formatMessage(feedbackMessages.FeedbackSent)}
            </span>
          </li>
          <li className="dashboard-feedback-summary__item">
            <span className="dashboard-feedback-summary__item-value">
              {requested}
            </span>
            <span className="dashboard-feedback-summary__item-label">
              {formatMessage(feedbackMessages.FeedbackRequested)}
            </span>
          </li>
          <li className="dashboard-feedback-summary__item">
            <span className="dashboard-feedback-summary__item-value">
              {pending}
            </span>
            <span className="dashboard-feedback-summary__item-label">
              {formatMessage(feedbackMessages.FeedbackPending)}
            </span>
          </li>
        </ul>
      </div>
    </CardSimple>
  );
};

DashboardFeedbackSummary.defaultProps = {
  feedbackSummary: {
    pendingReceivedFeedbackRequests: {},
    pendingSentFeedbackRequests: {},
    receivedFeedback: {},
    sentFeedback: {},
  },
};

DashboardFeedbackSummary.propTypes = {
  intl: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  feedbackSummary: PropTypes.shape({
    pendingReceivedFeedbackRequests: PropTypes.object,
    pendingSentFeedbackRequests: PropTypes.object,
    receivedFeedback: PropTypes.object,
    sentFeedback: PropTypes.object,
  }).isRequired,
};

export default injectIntl(DashboardFeedbackSummary);
