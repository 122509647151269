import {
  GET_LEADERS_BY_PERIOD,
  GET_LEADERS_BY_PERIOD_FAIL,
  GET_LEADERS_BY_PERIOD_SUCCESS,
} from './actionTypes';

export function getLeadersByPeriod() {
  return {
    type: GET_LEADERS_BY_PERIOD,
  };
}

export function getLeadersByPeriodFail(error) {
  return {
    type: GET_LEADERS_BY_PERIOD_FAIL,
    payload: { error },
  };
}

export function getLeadersByPeriodSuccess(response) {
  return {
    type: GET_LEADERS_BY_PERIOD_SUCCESS,
    payload: { response },
  };
}
