import {
  ADD_RATING_FILTER,
  CHANGE_COMPETENCIES_ORDER,
  REMOVE_RATING_FILTER,
  SELECT_COMPETENCY_DETAILS,
} from './actionTypes';

export function addRatingFilter(ratingId, isMultiple) {
  return {
    type: ADD_RATING_FILTER,
    payload: { ratingId, isMultiple },
  };
}
export function changeCompetenciesOrder() {
  return {
    type: CHANGE_COMPETENCIES_ORDER,
  };
}

export function removeRatingFilter(ratingId) {
  return {
    type: REMOVE_RATING_FILTER,
    payload: { ratingId },
  };
}

export function selectCompetencyDetails(competencyId) {
  return {
    type: SELECT_COMPETENCY_DETAILS,
    payload: { competencyId },
  };
}
