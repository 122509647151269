// @packages
import React from 'react';
import PropTypes from 'prop-types';

// @app
import MyTeamApp from 'betterme-components/MyTeam';
import MyTeamMenu from 'betterme-components/MyTeamMenu';
import TeamEvaluationsTab from 'betterme-components/Team/TeamEvaluationsTab';

// @own
import MyTeamLayout from '../';

function MyTeamEvaluationsLayout(props) {
  const { router } = props;
  return (
    <MyTeamLayout>
      <MyTeamMenu className="py-8 flex-1" path="/myteam/evaluations">
        <MyTeamApp {...props}>
          <TeamEvaluationsTab router={router} />
        </MyTeamApp>
      </MyTeamMenu>
    </MyTeamLayout>
  );
}

MyTeamEvaluationsLayout.propTypes = {
  router: PropTypes.object.isRequired,
};

export default MyTeamEvaluationsLayout;
