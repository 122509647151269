import {
  CLEAN_CHANGE_LEADER_USER_LIST,
  CLOSE_USER_CARD,
  GET_LEADERS,
  GET_LEADERS_FAIL,
  GET_LEADERS_SUCCESS,
  RESET_CHANGE_LEADER_REQUEST_STATE,
  RESET_SELECT_LEADER,
  SELECT_LEADER,
  SET_CHANGE_LEADER_COMMENT,
  UPDATE_USER_LEADER,
  UPDATE_USER_LEADER_FAIL,
  UPDATE_USER_LEADER_SUCCESS,
} from './actionTypes';

const initialState = {
  closeUserCard: false,
  comment: '',
  loadingUpdateUserLeader: false,
  selectedLeader: {},
  userList: [],
  userListLoading: false,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case CLEAN_CHANGE_LEADER_USER_LIST:
      return { ...state, userList: [] };

    case GET_LEADERS:
      return {
        ...state,
        userListLoading: true,
      };

    case GET_LEADERS_SUCCESS:
      return {
        ...state,
        userList: payload.response,
        userListLoading: false,
      };

    case GET_LEADERS_FAIL:
      return {
        ...state,
        userList: payload.error,
        userListLoading: false,
      };

    case RESET_CHANGE_LEADER_REQUEST_STATE:
      return initialState;

    case SELECT_LEADER:
      return { ...state, selectedLeader: payload.user };

    case SET_CHANGE_LEADER_COMMENT:
      return { ...state, comment: payload.value };

    case UPDATE_USER_LEADER:
      return { ...state, loadingUpdateUserLeader: true };

    case UPDATE_USER_LEADER_FAIL:
      return { ...state, loadingUpdateUserLeader: false, error: payload.error };

    case UPDATE_USER_LEADER_SUCCESS:
      return { ...state, loadingUpdateUserLeader: false };

    case CLOSE_USER_CARD:
      return { ...state, closeUserCard: payload };

    case RESET_SELECT_LEADER:
      return { ...state, selectedLeader: {} }

    default:
      return state;
  }
}
