// @packages
import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

// @own
import CompetencyRow from 'components/organisms/CompetencyRow';

const CompetenciesRateSelector = ({
  className,
  competencies,
  onCompetencyAdd,
  onCompetencyRemove,
  ratedTopics,
  rates,
  showOverview,
  useAsync,
}) => (
  <div className={cn('competencies-rate-selector', className)}>
    {competencies?.map((competency, index) => (
      <CompetencyRow
        {...competency}
        key={index}
        onCompetencyAdd={onCompetencyAdd}
        onCompetencyRemove={onCompetencyRemove}
        rates={rates}
        selectedRate={ratedTopics[competency?.id]}
        showOverview={showOverview}
        useAsync={useAsync}
      />
    ))}
  </div>
);

CompetenciesRateSelector.defaultProps = {
  retedTopics: [],
};

CompetenciesRateSelector.propTypes = {
  className: PropTypes.string,
  competencies: PropTypes.array.isRequired,
  onCompetencyAdd: PropTypes.func.isRequired,
  onCompetencyRemove: PropTypes.func.isRequired,
  rates: PropTypes.array.isRequired,
  showOverview: PropTypes.bool,
  useAsync: PropTypes.bool,
};

export default CompetenciesRateSelector;
