// @packages
import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

// @own
import './styles.scss';

const CardMeta = ({
  children,
  className,
  strong,
  uppercase,
}) => {
  return (
    <div className={cn('card-meta', className, {
      'card-meta--strong': strong,
      'card-meta--uppercase': uppercase,
    })}>
      {children}
    </div>
  );
};

CardMeta.defaultProps = {
  className: '',
  uppercase: false,
};

CardMeta.propTypes = {
  children: PropTypes.number.isRequired,
  className: PropTypes.string,
  uppercase: PropTypes.bool,
};

export default CardMeta;
