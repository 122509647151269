// @packages
import { get } from 'lodash';

// @own
import { authenticateUser } from './actions';

const authorizeRoutes = (store) => (nextState, replace, resumeNavigation) => {
  const justLoggedIn = get(nextState, 'location.query.logged', false);
  store.dispatch(authenticateUser(justLoggedIn, resumeNavigation));
};

export { authorizeRoutes };
