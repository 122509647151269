// @packages
import React from 'react';
import Container from 'smu-layout-components/Container';
import GenericErrorState from 'smu-app-components/GenericErrorState';
import PropTypes from 'prop-types';
import SurveysDetailsModal from 'smu-app-components/SurveysDetails/components/Modal';
import { buildImageUrl } from 'smu-utils/image';
import { connect } from 'react-redux';

// @app
import { ENABLED_TAKEPART_SURVEY } from 'betterme-components/services/CommunityConfigs/constants';
import { getCommunityConfigValueBool } from 'betterme-components/services/CommunityConfigs/selectors';
import { selectCommunityConfigurations, selectCommunityId, } from 'betterme-components/Authorization/selectors';

function QuestionnaireLayout({
  communityId,
  communityImageCode,
  enabledTakePartSurvey,
  location,
  params,
  router,
}) {
  const backPath = location.query?.backPath;
  const id = params?.questionnaireId;
  const surveyId = id ? Number(id) : undefined;
  const communityLogo =
    communityImageCode &&
    buildImageUrl({ code: communityImageCode, width: 40, height: 40 });

  const handleSeeResults = () => {
    router.push('/me/be-kind');
  };

  const handleGoToHome = () => {
    router.push(backPath || '/questionnaires');
  };

  if (!enabledTakePartSurvey) {
    return (
      <Container
        className="flex-1 flex flex-col items-center justify-center"
        centered
      >
        <GenericErrorState image="padlock" statusCode={403} />
      </Container>
    );
  }

  return (
    <SurveysDetailsModal
      open
      onClose={handleGoToHome}
      surveyProps={{
        communityLogo,
        onGoToHome: handleGoToHome,
        onSeeResults: handleSeeResults,
        orgId: communityId,
        surveyId,
        variant: 'container',
      }}
    />
  );
}

QuestionnaireLayout.propTypes = {
  communityId: PropTypes.number,
  communityImageCode: PropTypes.string,
  enabledTakePartSurvey: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  communityId: selectCommunityId(state),
  communityImageCode: selectCommunityConfigurations(state)?.imageCode,
  enabledTakePartSurvey: getCommunityConfigValueBool(
    state,
    ENABLED_TAKEPART_SURVEY
  ),
});

export default connect(mapStateToProps)(QuestionnaireLayout);
