// @packages
import React from 'react';
import ContentCardTemplate from 'components/templates/ContentCardTemplate';
import PropTypes from 'prop-types';
import cn from 'classnames'

// @own
import './styles.scss';

const PotentialAssessmentResultsComments = ({
  className,
  commentsData: {
    color,
    data,
    title
  },
}) =>
  <ContentCardTemplate
    className={cn('potential-assessment-results-comments', className)}
    title={title}
  >
    <ContentCardTemplate.Body>
      <div className="items-center pl-3 w-580">
        <div className="h-40 overflow-auto">
          <div className="flex flex-col mr-4 mb-4 mt-4">
            {data && Object?.keys(data)?.map((key) => (
              <div key={key} className="flex items-start mb-4">
                <div
                  className="min-w-[8px] w-[8px] h-[8px] rounded-full mr-2 inline-block mt-1.5"
                  style={{ backgroundColor: color }}
                />
                <div className="text-gray-500 font-text text-sm">
                  <span>{data[key]}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </ContentCardTemplate.Body>
  </ContentCardTemplate>

PotentialAssessmentResultsComments.propTypes = {
  className: PropTypes.string,
  commentsData: PropTypes.objectOf(PropTypes.shape({
    color: PropTypes.string,
    data: PropTypes.array,
    title: PropTypes.string,
  })),
};

PotentialAssessmentResultsComments.defaultProps = {
  commentsData: {
    data: {},
  },
};

export default PotentialAssessmentResultsComments;
