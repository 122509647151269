// @packages
import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RootModal from 'smu-app-components/RootModal';

// @app
import ChooseReason from 'components/organisms/ChooseReason';
import GenericModal from 'components/templates/GenericModalTemplate';
import Title from 'components/atoms/Title';
import { REMOVE_LEADER_MODAL } from 'betterme-components/constants/modalTypes';
import { SIZE_SIXTEEN } from 'components/common/constants';
import { actionsMessages } from 'common/messages';
import { centerModalClass } from 'betterme-components/ConfirmationModal';
import { actionRequired } from 'betterme-components/services/ReasonsActions/actions';
import * as actions from 'betterme-components/services/ChangeLeaderRequestAcceptDecline/actions';
import {
  selectGetChangeLeaderDismissReason,
  selectLoadingGetChangeLeaderDismissReason,
  selectSelectedDismissReason,
} from 'betterme-components/services/ChangeLeaderRequestAcceptDecline/selectors';
import { REMOVE_MYSELF_AS_MAIN_LEADER } from 'components/pages/LeadershipAdmin/constants';
import {
  trackAdminLeadershipRemoveCancel,
  trackAdminLeadershipRemoveClose,
  trackAdminLeadershipRemoveContinue,
} from 'betterme-components/services/LeadershipAdmin/analytics';

// @own
import messages from './messages';

const RemoveLeaderModal = ({
  actionRequired,
  closeModal,
  collaborator,
  getChangeLeaderDismissReason,
  intl: { formatMessage },
  leaderType,
  loadingReasons,
  newWordingForLeaders,
  reasons,
  reasonsType,
  resetDismissReason,
  selectedDismissReason,
  selectedReason,
  type,
}) => {
  useEffect(() => {
    getChangeLeaderDismissReason(reasonsType);
    return () => resetDismissReason();
  }, []);

  const collaboratorName = `${collaborator?.firstName} ${collaborator?.lastName}`;
  const hasSelectedReason = !!selectedReason;
  const mainLeaderTile = reasonsType === REMOVE_MYSELF_AS_MAIN_LEADER
    ? formatMessage(messages.RemoveLeaderModalMainTitle, { collaborator: collaboratorName })
    : formatMessage(messages.RemoveLeaderModalSecondaryTitle, { collaborator: collaboratorName });
  const careerLeaderTile = reasonsType === REMOVE_MYSELF_AS_MAIN_LEADER
    ? formatMessage(messages.RemoveLeaderModalCareerTitle, { collaborator: collaboratorName })
    : formatMessage(messages.RemoveLeaderModalAccountTitle, { collaborator: collaboratorName });
  const titleWording = newWordingForLeaders ? careerLeaderTile : mainLeaderTile;
  const disclaimer = `* ${formatMessage(messages.RemoveLeaderModalSubitle)}`;

  function handleReasonChange(event) {
    const { value } = event.target;
    selectedDismissReason(value);
  };

  function handleClose() {
    closeModal();
    resetDismissReason();
    trackAdminLeadershipRemoveClose(leaderType);
  };

  function handleCancel() {
    closeModal();
    resetDismissReason();
    trackAdminLeadershipRemoveCancel(leaderType);
  }

  function handleRemoveLeader() {
    const reasonId = selectedReason;
    const reason = reasons.filter(reason => reason.id !== reasonId)[0]?.name;
    actionRequired({
      collaborator,
      leaderType,
      type,
      reasonId,
      successMessage: formatMessage(messages.RemoveLeaderModalToastSuccess),
    });
    trackAdminLeadershipRemoveContinue({ type: leaderType, label: reason });
  }

  const actionButtons = [
    {
      label: formatMessage(actionsMessages.ActionsCancel),
      onClick: handleCancel,
      variant: 'outline',
    },
    {
      disabled: !hasSelectedReason,
      label: formatMessage(actionsMessages.ActionsContinue),
      onClick: handleRemoveLeader,
    },
  ];


  return (
    <GenericModal
      actionButtons={actionButtons}
      content={
        <ChooseReason
          onReasonChange={(event) => handleReasonChange(event)}
          reasons={reasons}
          selectedReason={selectedReason}
        />
      }
      disclaimer={disclaimer}
      header={
        <Title className="text-black" size={SIZE_SIXTEEN}>
          {titleWording}
        </Title>
      }
      loading={loadingReasons}
      onClose={handleClose}
    />
  );
};

RemoveLeaderModal.propTypes = {
  closeModal: PropTypes.func,
  collaborator: PropTypes.object,
  getChangeLeaderDismissReason: PropTypes.func,
  intl: PropTypes.object,
  leaderType: PropTypes.string,
  loadingReasons: PropTypes.bool,
  loadingRemove: PropTypes.bool,
  newWordingForLeaders: PropTypes.bool,
  reasons: PropTypes.array,
  removeLeader: PropTypes.func,
  resetDismissReason: PropTypes.func,
  selectedDismissReason: PropTypes.object,
  selectedReason: PropTypes.object,
  type: PropTypes.string.isRequired,
};

const modalStyles = {
  width: '610px',
};

const mapStateToProps = state => ({
  loadingReasons: selectLoadingGetChangeLeaderDismissReason(state),
  reasons: selectGetChangeLeaderDismissReason(state),
  selectedReason: selectSelectedDismissReason(state),
});

const mapDispatchToProps = {
  actionRequired,
  ...actions,
};

export default RootModal(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(RemoveLeaderModal)),
  REMOVE_LEADER_MODAL,
  modalStyles,
  centerModalClass,
);
