// @packages
import { call, put, takeLatest, } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';

// @own
import { getPeriodByType } from './api';
import { GET_PERIOD_BY_TYPE } from './actionTypes';
import { getPeriodByTypeFail, getPeriodByTypeSuccess } from './actions';

function* getPeriodByTypeWorker({ payload }) {
  try {
    const response = yield call(getPeriodByType, payload);
    yield put(getPeriodByTypeSuccess(response));
  } catch (error) {
    yield put(getPeriodByTypeFail(error.message));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

export default function* getPeriodByTypeWatcher() {
  yield takeLatest(GET_PERIOD_BY_TYPE, getPeriodByTypeWorker);
}
