// @packages
import React from 'react';
import PropTypes from 'prop-types';

// @own
import OverallOpportunities from '../OverallOpportunities';
import OverallStrenghts from '../OverallStrenghts';
import './styles.scss';

const CompetencyBoxes = ({
  intl,
  overallOpportunities,
  overallStrenghts,
}) => {
  return (
    <div className="competency-boxes">
      <OverallStrenghts
        className="competency-boxes__strenghts"
        {...overallStrenghts}
        intl={intl}
      />
      <OverallOpportunities
        className="competency-boxes__opportunities"
        {...overallOpportunities}
        intl={intl}
      />
    </div>
  );
};

CompetencyBoxes.propTypes = {
  intl: PropTypes.object.isRequired,
  overallOpportunities: PropTypes.object.isRequired,
  overallStrenghts: PropTypes.object.isRequired,
};

export default CompetencyBoxes;
