// @packages
import React from 'react';
import PropTypes from 'prop-types';
import RootModal from 'smu-app-components/RootModal';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { push } from 'react-router-redux';

// @app
import GenericModal from 'components/templates/GenericModalTemplate';
import InfoCard from 'components/molecules/InfoCard';
import { SUGGEST_LEADER_REQUIRED_MODAL } from 'betterme-components/constants/modalTypes';
import { actionsMessages } from 'common/messages';
import { centerModalClass } from 'betterme-components/ConfirmationModal';
import { getLeaderSubstitutionLocation } from 'betterme-components/routes/urls';
import { selectActionRequiredLoading } from 'betterme-components/services/ReasonsActions/selectors';
import { removeLocalStoredItem } from 'utils/localStorage';
import { ACTION_TYPE_REQUIRED } from 'components/common/constants';

// @own
import messages from './messages';

const LeaderSuggestionRequiredModal = ({
  actionsLoading,
  closeModal,
  goToLeaderSustitution,
  intl: { formatMessage },
  leaderType,
  user,
}) => {

  function handleCancel() {
    closeModal();
    removeLocalStoredItem(ACTION_TYPE_REQUIRED);
  };

  function handleAccept() {
    goToLeaderSustitution(user?.identification, leaderType);
    closeModal();
  };

  const collaborator = `${user?.firstName} ${user?.lastName}`;

  const actionButtons = [
    {
      label: formatMessage(actionsMessages.ActionsCancel),
      onClick: handleCancel,
      variant: 'outline',
    },
    {
      label: formatMessage(messages.SuggestLeader),
      onClick: handleAccept,
    },
  ];

  return (
    <GenericModal
      actionButtons={actionButtons}
      header={
        <InfoCard
          icon="info"
          title={formatMessage(messages.SuggestionRequired)}
          message={formatMessage(messages.SendSuggestion, { collaborator })}
        />
      }
      loading={actionsLoading}
      onClose={handleCancel}
      success
    />
  );
};

LeaderSuggestionRequiredModal.propTypes = {
  actionsLoading: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  goToLeaderSustitution: PropTypes.func.isRequired,
  intl: PropTypes.func.isRequired,
  leaderType: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
};

const modalStyles = {
  width: '610px',
};

const mapStateToProps = (state) => ({
  actionsLoading: selectActionRequiredLoading(state),
});

const mapDispatchToProps = {
  goToLeaderSustitution: (user, leaderType) => push(getLeaderSubstitutionLocation(user, leaderType)),
};

export default RootModal(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(LeaderSuggestionRequiredModal)),
  SUGGEST_LEADER_REQUIRED_MODAL,
  modalStyles,
  centerModalClass,
);
