// @packages
import React, { useState } from 'react';
import Collapsible from 'smu-ui-components/Collapsible';
import Icon from 'smu-ui-components/IconV2';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @app
import CollaboratorsCount from 'components/molecules/CollaboratorsCount';
import UserAffinity from 'components/molecules/UserAffinity';

// @own
import './styles.scss';

const SuggestedLeaderCard = ({
  affinityType: {
    high,
    low,
    medium,
    minimum,
  },
  className,
  fields,
  user,
  userAffinity,
  userCollaborators,
  userHasText,
}) => {
  const [expanded, setExpanded] = useState(false)

  function handleOpenCard() {
    setExpanded(!expanded);
  };

  return (
    <Collapsible
      baseHeight={850}
      className={cn('suggested-leader-card', className,
        {
          'suggested-leader-card--high': high,
          'suggested-leader-card--low': low,
          'suggested-leader-card--medium': medium,
          'suggested-leader-card--minimum': minimum,
        })}
      expanded={expanded}
      onClick={() => handleOpenCard()}
      title={
        <UserAffinity
          affinity={userAffinity}
          className="suggested-leader-card__avatar"
          user={user}
        />
      }
      variant="table"
      withIcon
    >
      <div className="suggested-leader-card__content--wrapper">
        {fields.map(field => {
          const { affinity, description, name } = field;
          return (
            <span className="suggested-leader-card__content">
              <Icon
                className={cn('suggested-leader-card__content--icon', {
                  'suggested-leader-card__content--icon-green': affinity,
                  'suggested-leader-card__content--icon-orange': !affinity,
                })}
                icon={affinity ? 'check-fill' : 'cancel-fill'}
                size="small"
              />
              <span className="suggested-leader-card__content--info">
                {name}
                <strong>{description || '-'}</strong>
              </span>
            </span>
          )
        })}
      </div>
      <div className="suggested-leader-card__collaborators">
        <span>{user.firstname} {userHasText}</span>
        <div className='suggested-leader-card__collaborators-count'>
          {userCollaborators.map(({ number, text }) => (
            <CollaboratorsCount number={number} text={text}/>
          ))}
        </div>
      </div>
    </Collapsible>
  );
}

SuggestedLeaderCard.defaultProps = {
  fields: [],
};

SuggestedLeaderCard.propTypes = {
  affinityType: PropTypes.arrayOf(PropTypes.shape({
    high: PropTypes.bool.isRequired,
    low: PropTypes.bool.isRequired,
    medium: PropTypes.bool.isRequired,
    minimum: PropTypes.bool.isRequired,
  })).isRequired,
  className: PropTypes.string.isRequired,
  fields: PropTypes.array.isRequired,
  user: PropTypes.array.isRequired,
  userAffinity: PropTypes.array.isRequired,
  userCollaborators: PropTypes.array.isRequired,
  userHasText: PropTypes.string.isRequired,
};

export default SuggestedLeaderCard;
