// @packages
import PropTypes from 'prop-types';
import React from 'react';
import UserAvatar from 'smu-ui-components/UserAvatar';

// @app

// @own
import './styles.scss';
import logo from '../../assets/hr_logo.png';

const AVATAR_SIZE = 32;

const GoalFooter = ({ actionMessage, className, user }) => {
  const profileImageCode = user.profileImageCode || user.imageCode;

  return (
    <div className={`goal-footer ${className || ''}`}>
      {profileImageCode ? (
        <div className="goal-footer__content-avatar">
          <UserAvatar
            className="goal-footer__avatar"
            profileImageCode={profileImageCode}
            height={AVATAR_SIZE}
            width={AVATAR_SIZE}
          />
        </div>
      ) : (
        <img className="goal-footer__hr-image" src={logo} />
        )}
      <div className="goal-footer__message">
        <span className="inline-block font-rubik text-blue text-xs pl-2">{`${actionMessage} `}</span>
        <span className="inline-block font-rubik text-blue text-xs font-bold pl-1">{user.name}</span>
      </div>
    </div>
  );
};

GoalFooter.propTypes = {
  actionMessage: PropTypes.string.isRequired,
  className: PropTypes.string,
  user: PropTypes.object.isRequired,
};

export default GoalFooter;
