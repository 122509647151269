import {
  GET_DASHBOARD_FEEDBACK_FAIL,
  GET_DASHBOARD_FEEDBACK_FOR_LEADERS_RESULTS_FAIL,
  GET_DASHBOARD_FEEDBACK_FOR_LEADERS_RESULTS_SUCCESS,
  GET_DASHBOARD_FEEDBACK_FOR_LEADERS_RESULTS,
  GET_DASHBOARD_FEEDBACK_SUCCESS,
  GET_DASHBOARD_FEEDBACK_SUMMARY_FAIL,
  GET_DASHBOARD_FEEDBACK_SUMMARY_SUCCESS,
  GET_DASHBOARD_FEEDBACK_SUMMARY,
  GET_DASHBOARD_FEEDBACK,
  GET_DASHBOARD_GOALS_INFO_FAIL,
  GET_DASHBOARD_GOALS_INFO_SUCCESS,
  GET_DASHBOARD_GOALS_INFO,
  GET_DASHBOARD_TOPICS_OPPORTUNITIES_FAIL,
  GET_DASHBOARD_TOPICS_OPPORTUNITIES_SUCCESS,
  GET_DASHBOARD_TOPICS_OPPORTUNITIES,
  GET_DASHBOARD_TOPICS_STRENGTHS_FAIL,
  GET_DASHBOARD_TOPICS_STRENGTHS_SUCCESS,
  GET_DASHBOARD_TOPICS_STRENGTHS,
  REQUEST_FEEDBACK_SELECT_COMPETENCY,
  REQUEST_FEEDBACK_SELECT_USER,
  REQUEST_FEEDBACK_USERS_FAIL,
  REQUEST_FEEDBACK_USERS_SUCCESS,
  REQUEST_FEEDBACK_USERS,
  REQUEST_RATED_TOPICS_FAIL,
  REQUEST_RATED_TOPICS_SUCCESS,
  REQUEST_RATED_TOPICS,
  RESET_DASHBOARD_DATA,
  RESET_GET_DASHBOARD_FEEDBACK,
  RESET_RATED_TOPICS,
  RESET_REQUEST_FEEDBACK_DATA,
  RESET_REQUEST_FEEDBACK_USERS,
  SELECT_COMPETENCY_DETAILS,
  GET_DASHBOARD_PERFORMANCE_CHART,
  GET_DASHBOARD_PERFORMANCE_CHART_FAIL,
  GET_DASHBOARD_PERFORMANCE_CHART_SUCCESS
} from './actionTypes';

const initialState = {
  competenciesOrder: 'asc',
  dashboardFeedback: {
    ratings: [],
  },
  dashboardFeedbackSummary: {},
  errorFeedbackForLeaders: null,
  feedbackForLeaders: {},
  fileUrl: null,
  filteredUsers: [],
  goalsProgress: null,
  loading: false,
  loadingFeedbackForLeaders: false,
  loadingFeedbackSummary: false,
  loadingGoals: false,
  loadingOpportunities: false,
  loadingStrengths: false,
  opportunities: [],
  opportunitiesRating: {},
  ratedTopics: [],
  ratingFilter: [],
  selectedCompetencies: [],
  selectedCompetencyDetails: undefined,
  selectedUser: {},
  strengths: [],
  strengthsRating: {},
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_DASHBOARD_GOALS_INFO:
      return { ...state, loadingGoals: true };

    case GET_DASHBOARD_GOALS_INFO_FAIL:
      return { ...state, loadingGoals: false, error: payload.error };

    case GET_DASHBOARD_GOALS_INFO_SUCCESS:
      return { ...state, loadingGoals: false, goalsProgress: payload.response };

    case GET_DASHBOARD_TOPICS_OPPORTUNITIES:
      return { ...state, loadingOpportunities: true };

    case GET_DASHBOARD_TOPICS_OPPORTUNITIES_FAIL:
      return { ...state, loadingOpportunities: false, opportunities: [] };

    case GET_DASHBOARD_TOPICS_OPPORTUNITIES_SUCCESS:
      return {
        ...state,
        loadingOpportunities: false,
        opportunities: payload.response.competencies,
        opportunitiesRating: payload.response.rating,
      };

    case GET_DASHBOARD_TOPICS_STRENGTHS:
      return { ...state, loadingStrengths: true };

    case GET_DASHBOARD_TOPICS_STRENGTHS_FAIL:
      return { ...state, loadingStrengths: false, strengths: [] };

    case GET_DASHBOARD_TOPICS_STRENGTHS_SUCCESS:
      return {
        ...state,
        loadingStrengths: false,
        strengths: payload.response.competencies,
        strengthsRating: payload.response.rating,
      };

    case GET_DASHBOARD_FEEDBACK:
      return { ...state, loading: true };

    case GET_DASHBOARD_FEEDBACK_FAIL:
      return { ...state, loading: false, error: payload.error };

    case GET_DASHBOARD_FEEDBACK_SUCCESS:
      return { ...state, loading: false, dashboardFeedback: payload.response };

    case GET_DASHBOARD_FEEDBACK_SUMMARY:
      return { ...state, loadingFeedbackSummary: true };

    case GET_DASHBOARD_FEEDBACK_SUMMARY_FAIL:
      return { ...state, loadingFeedbackSummary: false, error: payload.error };

    case GET_DASHBOARD_FEEDBACK_SUMMARY_SUCCESS:
      return { ...state, loadingFeedbackSummary: false, dashboardFeedbackSummary: payload.response };

    case REQUEST_FEEDBACK_USERS:
      return { ...state, loading: true };

    case REQUEST_FEEDBACK_USERS_FAIL:
      return { ...state, loading: false, filteredUsers: [] };

    case REQUEST_FEEDBACK_USERS_SUCCESS:
      return { ...state, loading: false, filteredUsers: payload.response };

    case REQUEST_FEEDBACK_SELECT_USER:
      return { ...state, selectedUser: payload.userData };

    case REQUEST_FEEDBACK_SELECT_COMPETENCY:
      return { ...state, selectedCompetencies: payload.competency };

    case RESET_REQUEST_FEEDBACK_USERS:
      return { ...state, filteredUsers: [] };

    case RESET_GET_DASHBOARD_FEEDBACK:
      return { ...state, dashboardFeedback: {} };

    case RESET_DASHBOARD_DATA:
      return { ...initialState };

    case SELECT_COMPETENCY_DETAILS:
      return { ...state, selectedCompetencyDetails: payload.competencyId };

    case REQUEST_RATED_TOPICS:
      return { ...state, loading: true };

    case REQUEST_RATED_TOPICS_FAIL:
      return { ...state, loading: false, ratedTopics: undefined };

    case REQUEST_RATED_TOPICS_SUCCESS:
      return { ...state, loading: false, ratedTopics: payload.response };

    case RESET_REQUEST_FEEDBACK_DATA:
      return { ...state, selectedUser: {}, selectedCompetencies: [], filteredUsers: [] };

    case GET_DASHBOARD_FEEDBACK_FOR_LEADERS_RESULTS:
      return {
        ...state,
        loadingFeedbackForLeaders: true,
      };

    case GET_DASHBOARD_FEEDBACK_FOR_LEADERS_RESULTS_FAIL:
      return {
        ...state,
        errorFeedbackForLeaders: payload.error,
        loadingFeedbackForLeaders: false,
      };

    case GET_DASHBOARD_FEEDBACK_FOR_LEADERS_RESULTS_SUCCESS:
      return {
        ...state,
        feedbackForLeaders: {...payload.response},
        loadingFeedbackForLeaders: false,
      };

    case GET_DASHBOARD_PERFORMANCE_CHART:
      return {
        ...state,
        loadingDashboardPerformanceChart: true,
      };
  
    case GET_DASHBOARD_PERFORMANCE_CHART_FAIL:
      return {
        ...state,
        errorDashboardPerformanceChart: payload.error,
        loadingDashboardPerformanceChart: false,
      };

    case GET_DASHBOARD_PERFORMANCE_CHART_SUCCESS:
      return {
        ...state,
        dashboardPerformanceChart: [...payload.response],
        loadingDashboardPerformanceChart: false,
      };

    case RESET_RATED_TOPICS:
      return {
        ...state,
        ratedTopics: [],
      };

    default:
      return state;
  }
}
