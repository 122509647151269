import {
  SET_PERFORMANCE_RETROSPECTIVE,
  SET_PERFORMANCE_RETROSPECTIVE_FAIL,
  SET_PERFORMANCE_RETROSPECTIVE_SUCCESS,
  SET_PERFORMANCE_RETROSPECTIVE_BEHAVIOURS,
  RESET_PERFORMANCE_RETROSPECTIVE,
  RESET_PERFORMANCE_RETROSPECTIVE_BEHAVIOURS,
} from './actionTypes';

export function setPerformanceRetrospective(payload) {
  return {
    type: SET_PERFORMANCE_RETROSPECTIVE,
    payload,
  };
}

export function setPerformanceRetrospectiveFail(error) {
  return {
    type: SET_PERFORMANCE_RETROSPECTIVE_FAIL,
    payload: error,
  };
}

export function setPerformanceRetrospectiveSuccess(response) {
  return {
    type: SET_PERFORMANCE_RETROSPECTIVE_SUCCESS,
    payload: response,
  };
}

export function resetPerformanceRetrospective() {
  return {
    type: RESET_PERFORMANCE_RETROSPECTIVE,
  };
}

export function setPerformanceRetrospectiveBehaviours(payload) {
  return {
    type: SET_PERFORMANCE_RETROSPECTIVE_BEHAVIOURS,
    payload,
  };
}

export function resetPerformanceRetrospectiveBehaviours() {
  return {
    type: RESET_PERFORMANCE_RETROSPECTIVE_BEHAVIOURS,
  };
}
