import {
  ASSESSMENT_EDIT_ANSWER,
  GET_ASSESSMENT_COLLABORATORS,
  GET_ASSESSMENT_COLLABORATORS_FAIL,
  GET_ASSESSMENT_COLLABORATORS_SUCCESS,
  GET_ASSESSMENT_QUESTIONS,
  GET_ASSESSMENT_QUESTIONS_FAIL,
  GET_ASSESSMENT_QUESTIONS_SUCCESS,
  GET_ASSESSMENT_RATINGS,
  GET_ASSESSMENT_RATINGS_FAIL,
  GET_ASSESSMENT_RATINGS_SUCCESS,
  GET_ASSESSMENT_SCORE,
  GET_ASSESSMENT_SCORE_FAIL,
  GET_ASSESSMENT_SCORE_SUCCESS,
  GET_ASSESSMENT_TIER,
  GET_ASSESSMENT_TIER_FAIL,
  GET_ASSESSMENT_TIER_SUCCESS,
  RESET_ASSESSMENT,
  RESET_TIER,
  SEND_ASSESSMENT_RESULTS,
  SEND_ASSESSMENT_RESULTS_FAIL,
  SEND_ASSESSMENT_RESULTS_SUCCESS,
  SEND_ASSESSMENT_SUMMARY,
  SEND_ASSESSMENT_SUMMARY_FAIL,
  SEND_ASSESSMENT_SUMMARY_SUCCESS,
} from './actionTypes';

const initialState = {
  assessmentCollaborators: [],
  assessmentCollaboratorsError: '',
  assessmentCollaboratorsLoading: false,
  assessmentQuestions: {},
  assessmentQuestionsError: '',
  assessmentQuestionsLoading: false,
  assessmentRatings: {},
  assessmentRatingsError: '',
  assessmentRatingsLoading: false,
  assessmentScore: {},
  assessmentScoreError: '',
  assessmentScoreLoading: false,
  assessmentTier: {},
  assessmentTierError: '',
  assessmentTierLoading: false,
  sendAssessmentError: '',
  sendAssessmentLoading: false,
  sendAssessmentResultsError: '',
  sendAssessmentResultsLoading: false,
  sendAssessmentSummaryError: '',
  sendAssessmentSummaryLoading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ASSESSMENT_COLLABORATORS:
      return {
        ...state,
        assessmentCollaboratorsLoading: true,
      }

    case GET_ASSESSMENT_COLLABORATORS_FAIL:
      return {
        ...state,
        assessmentCollaboratorsError: payload.error,
        assessmentCollaboratorsLoading: false,
      };

    case GET_ASSESSMENT_COLLABORATORS_SUCCESS:
      return {
        ...state,
        assessmentCollaboratorsLoading: false,
        assessmentCollaborators: payload
      }

    case GET_ASSESSMENT_RATINGS:
      return {
        ...state,
        assessmentRatingsLoading: true,
      };

    case GET_ASSESSMENT_RATINGS_FAIL:
      return {
        ...state,
        assessmentRatings: payload.error,
        assessmentRatingsLoading: false,
      };

    case GET_ASSESSMENT_RATINGS_SUCCESS:
      return {
        ...state,
        assessmentRatings: payload.response,
        assessmentRatingsLoading: false,
      };

    case GET_ASSESSMENT_QUESTIONS:
      return {
        ...state,
        assessmentQuestionsLoading: true,
      };

    case GET_ASSESSMENT_QUESTIONS_FAIL:
      return {
        ...state,
        assessmentQuestionsError: payload.error,
        assessmentQuestionsLoading: false,
      };

    case GET_ASSESSMENT_QUESTIONS_SUCCESS:
      return {
        ...state,
        assessmentQuestions: payload,
        assessmentQuestionsLoading: false,
      };

    case ASSESSMENT_EDIT_ANSWER:
      return {
        ...state,
        assessmentQuestions: payload,
      };

    case RESET_TIER:
      return {
        ...state,
        assessmentRatings: null,
        assessmentQuestions: null,
        assessmentTier: null,
      };

    case GET_ASSESSMENT_TIER:
      return {
        ...state,
        assessmentTierLoading: true,
      };

    case GET_ASSESSMENT_TIER_FAIL:
      return {
        ...state,
        assessmentTier: payload.error,
        assessmentTierLoading: false,
      };

    case GET_ASSESSMENT_TIER_SUCCESS:
      return {
        ...state,
        assessmentTier: payload.response,
        assessmentTierLoading: false,
      };

    case SEND_ASSESSMENT_SUMMARY:
      return {
        ...state,
        sendAssessmentSummaryLoading: true,
      };

    case SEND_ASSESSMENT_SUMMARY_FAIL:
      return {
        ...state,
        sendAssessmentSummaryError: payload.error,
        sendAssessmentSummaryLoading: false,
      };

    case SEND_ASSESSMENT_SUMMARY_SUCCESS:
      return {
        ...state,
        sendAssessmentSummaryLoading: false,
      };

    case SEND_ASSESSMENT_RESULTS:
      return {
        ...state,
        sendAssessmentResultsLoading: true,
      };

    case SEND_ASSESSMENT_RESULTS_FAIL:
      return {
        ...state,
        sendAssessmentResultsError: payload.error,
        sendAssessmentResultsLoading: false,
      };

    case SEND_ASSESSMENT_RESULTS_SUCCESS:
      return {
        ...state,
        sendAssessmentResultsLoading: false,
      };

    case RESET_ASSESSMENT:
      return {
        ...state,
        assessmentScore: {},
      };

    case GET_ASSESSMENT_SCORE:
      return {
        ...state,
        assessmentScoreLoading: true,
      };

    case GET_ASSESSMENT_SCORE_FAIL:
      return {
        ...state,
        assessmentScoreError: payload.error,
        assessmentScoreLoading: false,
      };

    case GET_ASSESSMENT_SCORE_SUCCESS:
      return {
        ...state,
        assessmentScore: payload.response,
        assessmentScoreLoading: false,
      };

    default:
      return state;
  }
};
