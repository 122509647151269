// @app
import { handleServerError } from 'betterme-components/api';
import { apiInstanceOsMember } from 'betterme-components/utils/requestHelper';

export function getPasswordPropeties() {
  return apiInstanceOsMember.get('api/v1/community/members/properties', { data: {} })
    .then(({ data }) => data.result)
    .catch(handleServerError);
};

export function postChangePassword(data) {
  return apiInstanceOsMember.post('api/v1/community/members/change-password', data)
    .then(({ data }) => data.result)
    .catch(handleServerError);
};
