import {
  GET_FEEDBACK,
  GET_FEEDBACK_FAIL,
  GET_FEEDBACK_SUCCESS,
  REQUEST_COMMENTS_RESET
} from './actionTypes';
import {
  REQUEST_COMMENTS_SUCCESS,
  SEND_COMMENT,
  SEND_COMMENT_SUCCESS,
  SEND_COMMENT_FAIL
} from '../MyFeedbacks/actionTypes';

const initialState = {
  errorStatus: 0,
  feedback: {},
  fetching: false,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_FEEDBACK:
      return { ...state, fetching: true };
    case GET_FEEDBACK_SUCCESS:
      return { feedback: payload, fetching: false };
    case GET_FEEDBACK_FAIL:
      return { ...state, fetching: false, errorStatus: payload };
    case REQUEST_COMMENTS_SUCCESS:
      if (state.feedback && payload.feedbackId === state.feedback.id) {
        return { ...state, feedback: { ...state.feedback, comments: payload.messages } };
      } else {
        return state;
      }
    case REQUEST_COMMENTS_RESET:
      return { ...state, feedback: {} };
    case SEND_COMMENT:
      return { ...state, feedback: { ...state.feedback, sendingMessage: true } };
    case SEND_COMMENT_SUCCESS:
    case SEND_COMMENT_FAIL:
      return { ...state, feedback: { ...state.feedback, sendingMessage: false } };
    default:
      return state;
  }
}
