// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import UserAvatar from 'smu-ui-components/UserAvatar';

// @own
import './styles.scss';

const LeadersCardDisabled = ({
  className,
  emptyStateImage,
  emptyStateText,
  emptyStateTitle,
  highlightedLeader,
}) => (
  <div className={cn('leaders-card-disabled', className)}>
    <h4 className="leaders-card-disabled__title">{emptyStateTitle}</h4>
    <div className={cn('leaders-card__leader', {
      'leaders-card__leader--highlighted': highlightedLeader,
    })} >
      <UserAvatar
        className="leaders-card-disabled__leader--avatar"
        height={40}
        profileImageCode={emptyStateImage}
        width={40}
      />
      <p className="leaders-card-disabled__leader--text">{emptyStateText}</p>
    </div>
  </div>
);

LeadersCardDisabled.propTypes = {
  className: PropTypes.string,
  emptyStateImage: PropTypes.string,
  emptyStateText: PropTypes.string,
  emptyStateTitle: PropTypes.string,
};

export default LeadersCardDisabled;
