// @packages
import React from 'react';

// @app
import useForm from 'betterme-components/hooks/form';

// @own
import Question from '../Question';
import './styles.scss';

function Questions({ questions }) {
  const { values } = useForm();
  const childrens = [];

  function buildQuestion(question, isChild) {
    childrens.push(<Question isChild={isChild} key={question?.id} question={question}/>);

    if (question?.layout === 'GRID' && question?.options?.length) {
      question?.options.forEach((option) => {
        if (values[option?.id] && option?.childQuestions?.length) {
          buildChildQuestions(option?.childQuestions, true);
        }
      });
    }
  }

  function buildChildQuestions(questions, isChild = false) {
    questions.forEach((question) => {
      buildQuestion(question, isChild);
    });
  }

  buildChildQuestions(questions);

  return <div className="engagement-indicator-questions">{childrens}</div>;
}

export default Questions;
