// @packages
import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import AvatarMedia from 'smu-ui-components/AvatarMedia';
import Label from 'smu-ui-components/Label';

// @own
import './styles.scss';

const EvaluationListRow = ({
  className,
  customScore,
  evaluator,
  evaluee,
  goalScore,
  isByScore,
  isHeading,
  onClick,
  score,
  submissionDate,
  title,
  type,
}) => (
  <li
    className={cn('evaluation-list-row', className, {
      'evaluation-list-row--big': goalScore !== undefined,
    })}
    onClick={onClick}
  >
    {isByScore && (
      <div className="evaluation-list-row__collaborator evaluation-list-row__field">
        <div className={isHeading ? 'evaluation-list-row__text-no-wrap--no-elipsis' : 'evaluation-list-row__text-no-wrap'}>
          {typeof evaluee === 'string' ? (
            evaluee
          ) : (
            <AvatarMedia {...evaluee} useLink={false} />
          )}
        </div>
      </div>
    )}
    <div className="evaluation-list-row__creation-date evaluation-list-row__field">
      <div className={isHeading ? 'evaluation-list-row__text-no-wrap--no-elipsis' : 'evaluation-list-row__text-no-wrap'}>
        {submissionDate}
      </div>
    </div>
    {!isByScore && (
      <div className="evaluation-list-row__type evaluation-list-row__field">
        <div className={isHeading ? 'evaluation-list-row__text-no-wrap--no-elipsis' : 'evaluation-list-row__text-no-wrap'}>
          {typeof type === 'string'
            ? type
            : (
              <Label
                color={type?.color}
                size="small"
                text={type?.text}
              />
            )
          }
        </div>
      </div>
    )}
    <div className="evaluation-list-row__title evaluation-list-row__field">
      <div className={isHeading ? 'evaluation-list-row__text-no-wrap--no-elipsis' : 'evaluation-list-row__text-no-wrap'}>
        {title}
      </div>
    </div>
    {!isByScore && (
      <div className="evaluation-list-row__score evaluation-list-row__field">
        <div className={isHeading ? 'evaluation-list-row__text-no-wrap--no-elipsis' : 'evaluation-list-row__text-no-wrap'}>
          {score}
        </div>
      </div>
    )}
    <div className="evaluation-list-row__evaluator evaluation-list-row__field">
      <div className={isHeading ? 'evaluation-list-row__text-no-wrap--no-elipsis' : 'evaluation-list-row__text-no-wrap'}>
        {evaluator}
      </div>
    </div>
    {(!isByScore && (goalScore !== undefined)) && (
      <div className="evaluation-list-row__goal-final-score evaluation-list-row__field">
        {goalScore}
      </div>
    )}
    {(!isByScore && (customScore !== undefined)) && (
      <div className="evaluation-list-row__custom-score evaluation-list-row__field">
        {customScore}
      </div>
    )}
  </li>
);

EvaluationListRow.propTypes = {
  evaluator: PropTypes.string.isRequired,
  evaluee: PropTypes.object,
  goalScore: PropTypes.string,
  isByScore: PropTypes.bool,
  isHeading: PropTypes.bool,
  score: PropTypes.string.isRequired,
  submissionDate: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default EvaluationListRow;
