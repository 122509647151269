// @ App
import addToastMessage from 'utils/defaultToastAdd';
import {
  DEFAULT_TOAST_TIMEOUT,
  UNAUTHORIZED_ERROR,
  UNAUTHORIZED_ERROR_MESSAGE,
} from 'utils/constants';
import { navigateToLoginV2 } from 'smu-utils/globalRequestHelper';
import storeRef from 'utils/store';

// @ own
import messages from '../api/messages';

const errorMessageHandler = (status = undefined, defaultMessage) => {
  const {
    i18n: { messages: storeMessages },
  } = storeRef().getState();

  if (status === UNAUTHORIZED_ERROR) {
    storeRef().dispatch(
      addToastMessage({
        message:
          defaultMessage ||
          storeMessages?.errorHandler?.unAuthorize ||
          messages?.errorHandlerUnAuthorize?.defaultMessage,
        timeout: DEFAULT_TOAST_TIMEOUT,
      }),
    );
  } else {
    storeRef().dispatch(
      addToastMessage({
        message:
          defaultMessage ||
          storeMessages?.errorHandler?.anyError ||
          messages?.errorHandlerAnyError?.defaultMessage,
        timeout: DEFAULT_TOAST_TIMEOUT,
      }),
    );
  }
};
export const errorHandler = (error = {}, defaultMessage, showMessage = true) => {
  const status = error?.response?.status || error?.request?.status;
  const unauthorized =
    status === UNAUTHORIZED_ERROR || error?.message === UNAUTHORIZED_ERROR_MESSAGE;

  if (showMessage) {
    if (error?.response) {
      errorMessageHandler(status, defaultMessage);
    } else if (unauthorized) {
      errorMessageHandler(UNAUTHORIZED_ERROR);
    } else {
      errorMessageHandler(undefined, defaultMessage);
    }
  }

  if (!error?.response && unauthorized) {
    if (showMessage) {
      setTimeout(() => {
        navigateToLoginV2('SMU_WEB_v3');
      }, DEFAULT_TOAST_TIMEOUT);
    } else {
      navigateToLoginV2('SMU_WEB_v3');
    }
  }

  throw error;
};
