import { defineMessages } from 'react-intl';

export default defineMessages({
  ActionsAccept: {
    id: 'Betterme.Messages.Actions.Accept',
    defaultMessage: 'Accept',
  },
  ActionsYesAccept: {
    id: 'Betterme.Messages.Actions.YesAccept',
    defaultMessage: 'Yes, Accept',
  },
  ActionsYesDecline: {
    id: 'Betterme.Messages.Actions.YesDecline',
    defaultMessage: 'Yes, Decline',
  },
  ActionsYesCreate: {
    id: 'Betterme.Messages.Actions.YesCreate',
    defaultMessage: 'Yes, request',
  },
  ActionsApply: {
    id: 'Betterme.Messages.Actions.Apply',
    defaultMessage: 'Apply',
  },
  ActionsSendInput: {
    id: 'Betterme.Messages.Actions.SendInput',
    defaultMessage: 'Send Input',
  },
  ActionsCancel: {
    id: 'Betterme.Messages.Actions.Cancel',
    defaultMessage: 'Cancel',
  },
  ActionsClear: {
    id: 'Betterme.Messages.Actions.Clear',
    defaultMessage: 'Clear',
  },
  ActionsCreate: {
    id: 'Betterme.Messages.Actions.Create',
    defaultMessage: 'Create',
  },
  ActionsDecline: {
    id: 'Betterme.Messages.Actions.Decline',
    defaultMessage: 'Decline',
  },
  ActionsDelete: {
    id: 'Betterme.Messages.Actions.Delete',
    defaultMessage: 'Delete',
  },
  ActionsReadLess: {
    id: 'Betterme.Messages.Actions.ReadLess',
    defaultMessage: 'Read Less',
  },
  ActionsReadMore: {
    id: 'Betterme.Messages.Actions.ReadMore',
    defaultMessage: 'Read More',
  },
  ActionsSend: {
    id: 'Betterme.Messages.Actions.Send',
    defaultMessage: 'Send',
  },
  ActionsViewLess: {
    id: 'Betterme.Messages.Actions.ViewLess',
    defaultMessage: 'View Less',
  },
  ActionsViewMore: {
    id: 'Betterme.Messages.Actions.ViewMore',
    defaultMessage: 'View More',
  },
  ActionsEdit: {
    id: 'Betterme.Messages.Actions.Edit',
    defaultMessage: 'Edit',
  },
  ActionsSave: {
    id: 'Betterme.Messages.Actions.Save',
    defaultMessage: 'Save',
  },
  ActionsSendAnyway: {
    id: 'Betterme.Messages.Actions.SendAnyway',
    defaultMessage: 'Send Anyway',
  },
  ActionsAddImprovements: {
    id: 'Betterme.Messages.Actions.AddImprovements',
    defaultMessage: 'Add Improvements',
  },
  ActionsRequest: {
    id: 'Betterme.Messages.Actions.Request',
    defaultMessage: 'Request',
  },
  ActionsSendRequest: {
    id: 'Betterme.Messages.Actions.SendRequest',
    defaultMessage: 'Send Request',
  },
  ActionsAssign: {
    id: 'Betterme.Messages.Actions.Assign',
    defaultMessage: 'Assign',
  },
  ActionsContinue: {
    id: 'Betterme.Messages.Actions.Continue',
    defaultMessage: 'Continue',
  },
  ActionsNext: {
    id: 'Betterme.Messages.Actions.Next',
    defaultMessage: 'Next',
  },
  ActionsBack: {
    id: 'Betterme.Messages.Actions.Back',
    defaultMessage: 'Back',
  },
  ActionsChange: {
    id: 'Betterme.Messages.Actions.Change',
    defaultMessage: 'Change',
  },
  ActionsYesAddMe: {
    id: 'Betterme.Messages.Actions.YesAddMe',
    defaultMessage: 'Yes, Add Me',
  },
  ActionsSubmit: {
    id: 'Betterme.Messages.Actions.Submit',
    defaultMessage: 'Submit',
  },
  ActionsSaveDraft: {
    id: 'Betterme.Messages.Actions.SaveDraft',
    defaultMessage: 'Save Draft',
  },
  ActionsDeleteDraft: {
    id: 'Betterme.Messages.Actions.DeleteDraft',
    defaultMessage: 'Delete Draft',
  },
  ActionsSkip: {
    id: 'Betterme.Messages.Actions.Skip',
    defaultMessage: 'Skip',
  },
  ActionsGotIt: {
    id: 'Betterme.Messages.Actions.GotIt',
    defaultMessage: 'Got It',
  },
  ActionsCropAndSave: {
    id: 'Betterme.Messages.Actions.CropAndSave',
    defaultMessage: 'Crop And Save',
  },
});
