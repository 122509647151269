import { defineMessages } from 'react-intl';

export default defineMessages({
  PotentialAssessmentEditAnswerTitle: {
    id: 'Betterme.PotentialAssessment.EditAnswerTitle',
    defaultMessage: 'Edit answer',
  },
  PotentialAssessmentSaveChangesButton: {
    id: 'Betterme.PotentialAssessment.SaveChangesButton',
    defaultMessage: 'Save Changes',
  },
  PotentialAssessmentDiscardButton: {
    id: 'Betterme.PotentialAssessment.DiscardButton',
    defaultMessage: 'Discard',
  },
});
