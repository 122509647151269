import {
  ADD_RATING_FILTER,
  CHANGE_COMPETENCIES_ORDER,
  REMOVE_RATING_FILTER,
  SELECT_COMPETENCY_DETAILS,
} from './actionTypes';

const initialState = {
  competenciesOrder: 'asc',
  ratingFilter: [],
  selectedCompetencyDetails: undefined,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case ADD_RATING_FILTER: {
      const { ratingId, isMultiple } = payload;
      const ratingFilter = isMultiple ? [ratingId, ...state.ratingFilter] : [ratingId];
      return { ...state, ratingFilter };
    }

    case CHANGE_COMPETENCIES_ORDER: {
      const competenciesOrder = state.competenciesOrder === 'desc' ? 'asc' : 'desc';
      return { ...state, competenciesOrder  };
    }

    case REMOVE_RATING_FILTER: {
      const { ratingId } = payload;
      const ratingFilter = state.ratingFilter.filter((id) => id !== ratingId);
      return { ...state, ratingFilter };
    }

    case SELECT_COMPETENCY_DETAILS:
      return { ...state, selectedCompetencyDetails: payload.competencyId };

    default:
      return state;
  }
}
