import {
  GET_PASSWORD_PROPERTIES,
  GET_PASSWORD_PROPERTIES_FAIL,
  GET_PASSWORD_PROPERTIES_SUCCESS,
  POST_CHANGE_PASSWORD,
  POST_CHANGE_PASSWORD_FAIL,
  POST_CHANGE_PASSWORD_SUCCESS,
} from './actionTypes';

const initialState = {
  changePassword: {},
  changePasswordError: '',
  changePasswordLoading: false,
  passwordProperties: {
    changePasswordMandatory: false,
    resetPasswordEnabled: false,
  },
  passwordPropertiesError: '',
  passwordPropertiesLoading: false,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_PASSWORD_PROPERTIES:
      return {
        ...state,
        passwordPropertiesLoading: true,
      };

    case GET_PASSWORD_PROPERTIES_FAIL:
      return {
        ...state,
        passwordPropertiesError: payload.error,
        passwordPropertiesLoading: false,
      };

    case GET_PASSWORD_PROPERTIES_SUCCESS:
      return {
        ...state,
        passwordPropertiesLoading: false,
        passwordProperties: payload.response,
      };

    case POST_CHANGE_PASSWORD:
      return {
        ...state,
        changePasswordLoading: true,
      };

    case POST_CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        changePasswordError: payload.error,
        changePasswordLoading: false,
      };

    case POST_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordLoading: false,
        changePassword: payload.response,
      };

    default:
      return state;
  }
};
