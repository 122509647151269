// @packages
import { get } from 'lodash';

const reducerDomain = 'updateSubmitEvaluation';

export const selectUpdateSubmitEvaluation = (state) =>
  get(state, [reducerDomain, 'updateSubmitEvaluation']);

export const selectUpdateSubmitEvaluationLoading = (state) =>
  get(state, [reducerDomain, 'updateSubmitEvaluationLoading']);
