// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Link from 'smu-ui-components/Link';
import GenericEmptyState from 'smu-ui-components/GenericEmptyState';

// @app
import { feedbackMessages } from 'common/messages';

// @own
import './styles.scss';
import messages from '../../messages';
import { RECEIVED, SENT } from '../../constants';

const texts = {
  [RECEIVED]: {
    title: messages.FeedbackRequestInboxEmptyStateReceivedTitle,
    text: messages.FeedbackRequestInboxEmptyStateReceivedText,
  },
  [SENT]: {
    title: messages.FeedbackRequestInboxEmptyStateSentTitle,
    text: messages.FeedbackRequestInboxEmptyStateSentText,
  },
};

const linkText = {
  [RECEIVED]: feedbackMessages.FeedbackSendFeedback,
  [SENT]: feedbackMessages.FeedbackRequestFeedback,
};

const linkTo = {
  [RECEIVED]: '/newfeedbacksend',
  [SENT]: '/newfeedbackrequest',
};

const FeedbackRequestsInboxEmptyState = ({
  intl: { formatMessage },
  type,
}) => {
  return (
    <div className="feedback-requests-inbox-empty-state">
      <GenericEmptyState
        className="px-5 pt-20 bg-white"
        description={formatMessage(texts[type].text)}
        image="listCheck"
        limitTextWidth={false}
        size="large"
        title={formatMessage(texts[type].title)}
      />
      <div className="feedback-requests-inbox-empty-state__link-wrapper">
        <Link
          className="!underline text-black"
          to={linkTo[type]}
        >
          {formatMessage(linkText[type])}
        </Link>
      </div>
    </div>
  );
};

FeedbackRequestsInboxEmptyState.propTypes = {
  intl: PropTypes.object.isRequired,
  type: PropTypes.oneOf([RECEIVED, SENT]).isRequired,
};

export default injectIntl(FeedbackRequestsInboxEmptyState);
