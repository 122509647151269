// @packages
import moment from 'moment';

export function formatDate(date, smallFormat) {
  if (!date) {
    return '';
  }
  const format = smallFormat ? 'MM/YYYY' : 'L';

  return moment.utc(date).format(format);
}
