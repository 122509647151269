// @packages
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TalentMatrixWidget from 'smu-app-components/TalentMatrix/Widget';
import usePrevious from 'smu-custom-hooks/usePrevious';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

// @app
import Evaluations from 'betterme-components/Evaluations';
import Main from 'betterme-components/Main';
import TeamEvaluationsFilterBy from 'betterme-components/components/containers/TeamEvaluationsFilterByContainer';
import getMessageType from 'utils/getMessageType';
import messages from 'common/messages/evaluations';
import { isEnabledTalentMatrix } from 'betterme-components/TalentMatrix/selectors';
import { selectCommunityId, selectEvaluationPeriod } from 'betterme-components/Authorization/selectors';
import { selectEvaluationsTeamLoading } from 'betterme-components/services/TeamEvaluations/selectors';
import { selectFilterConfig } from 'betterme-components/services/MyTeamEvaluations/selectors';

// @own
import { getTeamEvaluations, resetTeamDashboardState } from '../actions';
import { selectEvaluationsScores } from '../selectors';

const TeamEvaluationsTab = ({
  communityId,
  enableTalentMatrix,
  evaluationPeriod,
  evaluationsTeamLoading,
  filterConfig,
  getTeamEvaluations: getTeamEvaluationsAction,
  intl: { formatMessage },
  myTeamFilters,
  resetTeamDashboardState,
  router,
  user,
}) => {
  const getMessage = getMessageType(communityId);
  const evaluationStatus = getMessage('EvaluationsFilterBySubtitle');
  const evaluationNotStarted = getMessage('EvaluationsFilterByStatusNotStarted');
  const startDateFilter = myTeamFilters.startDate;
  const endDateFilter = myTeamFilters.endDate;
  const previousStartDateFilter = usePrevious(startDateFilter);
  const previousEndDateFilter = usePrevious(endDateFilter);
  const datesFiltersChanged = startDateFilter !== previousStartDateFilter
    || endDateFilter !== previousEndDateFilter;
  const previousEvaluationsTeamLoading = usePrevious(evaluationsTeamLoading);
  const filterMessages = {
    buttonText: formatMessage(messages.EvaluationsFilterByApply),
    subtitle: formatMessage(evaluationStatus),
    status: {
      submittedByOther: formatMessage(messages.EvaluationsFilterByStatusSubmitedByOther),
      submittedByMe: formatMessage(messages.EvaluationsFilterByStatusSubmitedByMe),
      draft: formatMessage(messages.EvaluationsFilterByStatusDraft),
      foreignDraft: formatMessage(messages.EvaluationsFilterByStatusForeignDraft),
      notStarted: formatMessage(evaluationNotStarted),
    },
    title: formatMessage(messages.EvaluationsFilterByTitle),
  };

  const getTeamEvaluations = () => {
    const from = myTeamFilters.startDate ? myTeamFilters.startDate.valueOf() : undefined;
    const to = myTeamFilters.endDate ? myTeamFilters.endDate.valueOf() : undefined;
    getTeamEvaluationsAction(user.id, from, to);
  }

  const handleTalentMatrixWidgetButtonClick = () => {
    router.push('/myteam/evaluations/talent-matrix');
  }

  useEffect(() => {
    if (datesFiltersChanged) {
      getTeamEvaluations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datesFiltersChanged]);

  useEffect(() => {
    getTeamEvaluations();

    return () => {
      resetTeamDashboardState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Main
        Sidenav={
          <div className="flex flex-col space-y-5">
            <TeamEvaluationsFilterBy
              filterConfig={filterConfig}
              messages={filterMessages}
              newStyle
            />
            {enableTalentMatrix && (
              <TalentMatrixWidget
                onButtonClick={handleTalentMatrixWidgetButtonClick}
                periodId={evaluationPeriod.id}
                refreshService={!evaluationsTeamLoading && previousEvaluationsTeamLoading}
              />
            )}
          </div>
        }
        Main={
          <Evaluations
            filterConfig={filterConfig}
            isMyTeam
            location={router.location}
            messages={filterMessages}
            myTeamFilters={myTeamFilters}
            newStyle
            user={user}
          />
        }
      />
    </>
  );
}

TeamEvaluationsTab.propTypes = {
  communityId: PropTypes.number,
  enableTalentMatrix: PropTypes.bool,
  evaluationPeriod: PropTypes.object,
  evaluationsScores: PropTypes.array,
  evaluationsTeamLoading: PropTypes.bool,
  filterConfig: PropTypes.object,
  getTeamEvaluations: PropTypes.func,
  intl: PropTypes.object,
  myTeamFilters: PropTypes.object,
  resetTeamDashboardState: PropTypes.func,
  router: PropTypes.object,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  communityId: selectCommunityId(state),
  enableTalentMatrix: isEnabledTalentMatrix(state),
  evaluationPeriod: selectEvaluationPeriod(state),
  evaluationsScores: selectEvaluationsScores(state),
  evaluationsTeamLoading: selectEvaluationsTeamLoading(state),
  filterConfig: selectFilterConfig(state),
});

const mapDispatchToProps = {
  getTeamEvaluations,
  resetTeamDashboardState,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(TeamEvaluationsTab));
