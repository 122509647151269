import {
  CREATE_FEEDBACK_REQUEST_CANCEL,
  CREATE_FEEDBACK_REQUEST_CANCEL_FAIL,
  CREATE_FEEDBACK_REQUEST_CANCEL_SUCCESS,
  GET_FEEDBACKS_REQUEST,
  GET_FEEDBACKS_REQUESTS_RECEIVED,
  GET_FEEDBACKS_REQUESTS_RECEIVED_FAIL,
  GET_FEEDBACKS_REQUESTS_RECEIVED_SUCCESS,
  GET_FEEDBACKS_REQUESTS_SENT,
  GET_FEEDBACKS_REQUESTS_SENT_FAIL,
  GET_FEEDBACKS_REQUESTS_SENT_SUCCESS,
  GET_FEEDBACKS_REQUEST_FAIL,
  GET_FEEDBACKS_REQUEST_SUCCESS,
  GET_REQUEST_DISMISS_REASONS,
  GET_REQUEST_DISMISS_REASONS_FAIL,
  GET_REQUEST_DISMISS_REASONS_SUCCESS,
  RESET_FEEDBACK_REQUEST_STATE,
  SELECT_REASON,
  SET_ACTIVE_TAB,
} from './actionTypes';
import {
  RECEIVED,
} from './constants';

const initialState = {
  activeTab: RECEIVED,
  error: null,
  feedbackRequestPage: {},
  feedbacksRequestReceived: [],
  feedbacksRequestSent: [],
  feedbacksRequests: [],
  loadingCancelId: 0,
  loadingFeedbackRequests: false,
  loadingReasons: false,
  reasons: [],
  selectedReason: undefined,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case CREATE_FEEDBACK_REQUEST_CANCEL:
      return { ...state, loadingCancelId: payload.requestId };
    case CREATE_FEEDBACK_REQUEST_CANCEL_FAIL:
      return { ...state, loadingCancelId: 0 };
    case CREATE_FEEDBACK_REQUEST_CANCEL_SUCCESS:
      return { ...state, loadingCancelId: 0 };
    case GET_REQUEST_DISMISS_REASONS:
      return { ...state, loadingReasons: true };
    case GET_REQUEST_DISMISS_REASONS_FAIL:
      return { ...state, loadingReasons: false, error: payload.error };
    case GET_REQUEST_DISMISS_REASONS_SUCCESS:
      return { ...state, loadingReasons: false, reasons: payload.response };
    case GET_FEEDBACKS_REQUEST:
      return { ...state, loadingFeedbackRequests: true, error: null };
    case GET_FEEDBACKS_REQUEST_FAIL:
      return { ...state, loadingFeedbackRequests: false, error: payload.error };
    case GET_FEEDBACKS_REQUEST_SUCCESS:
      return {
        ...state,
        loadingFeedbackRequests: false,
        feedbacksRequests: payload.response.result,
        feedbackRequestPage: payload.response.page,
      };
    case GET_FEEDBACKS_REQUESTS_RECEIVED:
      return { ...state, loadingFeedbackRequests: true };
    case GET_FEEDBACKS_REQUESTS_RECEIVED_FAIL:
      return { ...state, loadingFeedbackRequests: false };
    case GET_FEEDBACKS_REQUESTS_RECEIVED_SUCCESS: {
      const { response: { result, page } } = payload;
      return {
        ...state,
        loadingFeedbackRequests: false,
        feedbacksRequestReceived: page.number ? [...state.feedbacksRequestReceived, ...result] : result,
        feedbackRequestPage: page,
      };
    }
    case GET_FEEDBACKS_REQUESTS_SENT:
      return { ...state, loadingFeedbackRequests: true };
    case GET_FEEDBACKS_REQUESTS_SENT_FAIL:
      return { ...state, loadingFeedbackRequests: false, error: payload.error };
    case GET_FEEDBACKS_REQUESTS_SENT_SUCCESS: {
      const { response: {result, page} } = payload;
      return {
        ...state,
        loadingFeedbackRequests: false,
        feedbacksRequestSent: page.number ? [...state.feedbacksRequestSent, ...result] : result,
        feedbackRequestPage: page,
      };
    }
    case RESET_FEEDBACK_REQUEST_STATE:
      return initialState;
    case SET_ACTIVE_TAB:
      return { ...state, activeTab: payload.tab };
    case SELECT_REASON:
      return { ...state, selectedReason: parseInt(payload.id, 10) };
    default:
      return state;
  }
}
