// @packages
import { call, put, takeLatest } from 'redux-saga/effects';
import { closeModal } from 'smu-app-components/RootModal/actions';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';

// @app
import { reportBug } from '../api';

// @own
import {
  createReportBugFail,
  createReportBugSuccess,
} from './actions';
import {
  CREATE_REPORT_BUG,
} from './actionTypes';

function* createReportBugWorker({ payload }) {
  try {
    yield call(reportBug, payload);
    yield put(createReportBugSuccess());
    yield put(closeModal());
  } catch (e) {
    yield put(createReportBugFail());
    yield put(addToastMessage(e));
  }
}

export default function* sendExperienceWatcher() {
  yield takeLatest(CREATE_REPORT_BUG, createReportBugWorker);
}
