// @packages
import { takeLatest, call, put } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';

// @app
import { getUserPromotionRequestsByPromotionGroup } from 'betterme-components/api';

// @own
import {
  getUserPromotionRequestsGroupFail,
  getUserPromotionRequestsGroupSuccess,
} from './actions';
import { GET_USER_PROMOTION_REQUESTS_GROUP } from './actionTypes';

function* userPromotionRequestsGroupWorker({ payload: { promotionParentId, ordering } }) {
  try {
    const response = yield call(getUserPromotionRequestsByPromotionGroup, { promotionParentId, ordering });
    yield put(getUserPromotionRequestsGroupSuccess(response));
  } catch (error) {
    yield put(getUserPromotionRequestsGroupFail(error.message));
    yield put(addToastMessage({ message: error.message, timeout: 3000 }));
  }
}

export default function* userPromotionRequestsGroupWatcher() {
  yield takeLatest(GET_USER_PROMOTION_REQUESTS_GROUP, userPromotionRequestsGroupWorker);
}
