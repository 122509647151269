// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Typography from 'smu-ui-components/Typography';

// @app
import PieChart from 'common/PieChart';

// @own
import './styles.scss';

const DashboardPieChart = ({
  inline,
  pieChartConfig: {
    size,
    strokeSize,
  },
  pieChartData,
  pieChartCounter,
}) => {
  return (
    <div className={cn('dashboard-piechart__content', {
      'dashboard-piechart__content--inline': inline,
    })}>
      <div className="dashboard-piechart__content-chart">
        <PieChart
          data={pieChartData}
          size={size}
          strokeSize={strokeSize}
        >
          <Typography className="block font-bold text-lg text-shark" variant="subtitle2">
            {pieChartCounter.total}
          </Typography>
          <Typography className="block capitalize text-sm text-shark" variant="body1">
            {pieChartCounter.label}
          </Typography>
        </PieChart>
      </div>
      <div className="dashboard-piechart__content-data">
        <ul className="dashboard-piechart__list">
          {pieChartData.map((item, index) => (
            <li
              className="dashboard-piechart__item"
              key={index}
            >
              <span
                className="dashboard-piechart__color"
                style={{ backgroundColor: item.color }}
              />
              <Typography className="text-sm w-8 text-right inline-block text-shark" variant="title4">
                {item.count}
              </Typography>
              <Typography className="text-xs pl-2 text-raven capitalize" variant="body1">
                {item.name}
              </Typography>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

DashboardPieChart.defaultProps = {
  pieChartConfig: {
    size: 145,
    strokeSize: 20,
  },
};

DashboardPieChart.propTypes = {
  inline: PropTypes.bool,
  pieChartData: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      count: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
  pieChartConfig: PropTypes.shape({
    size: PropTypes.number,
    strokeSize: PropTypes.number,
  }),
  pieChartCounter: PropTypes.shape({
    label: PropTypes.string,
    total: PropTypes.number,
  }).isRequired,
};

export default DashboardPieChart;
