// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @app
import ActionButtons from 'components/molecules/ActionButtons';
import FooterCard from 'components/molecules/FooterCard';
import HeaderCard from 'components/molecules/HeaderCard';
import LeadersCard from 'components/molecules/LeadersCard';
import Title from 'components/atoms/Title';
import { SIZE_THIRTEEN } from 'components/common/constants';

// @own
import './styles.scss';

const ReplaceLeaderSent = ({
  actionButtons,
  className,
  footer: {
    sentBy,
    userName,
  },
  leaderTitle,
  request: {
    collaborator,
    requester,
  },
  sentDate,
  title,
}) => (
  <div className={cn('replace-leader-sent', className)}>
    <HeaderCard
      className="replace-leader-sent__header"
      userAvatarProps={collaborator}
      date={sentDate}
    />
    <Title
      className="replace-leader-sent__title"
      size={SIZE_THIRTEEN}
    >
      {title}
    </Title>
    <LeadersCard
      className="replace-leader-sent__leader"
      leadersTitle={leaderTitle}
      userAvatarProps={requester}
      highlightedLeader
    />
    <FooterCard
      className="replace-leader-sent__footer"
      sentBy={sentBy}
      userAvatar={requester}
      userName={userName}
    />
    <ActionButtons
      className="replace-leader-sent__buttons"
      buttons={actionButtons}
    />
  </div>
);

ReplaceLeaderSent.defaultProps = {
  actionButtons: [],
};

ReplaceLeaderSent.propTypes = {
  actionButtons: PropTypes.array.isRequired,
  className: PropTypes.string,
  footer: PropTypes.arrayOf(PropTypes.shape(
    {
      sentBy: PropTypes.string,
      userName: PropTypes.string,
    },
  )),
  leaderTitle: PropTypes.string,
  request: PropTypes.arrayOf(PropTypes.shape(
    {
      collaborator: PropTypes.object.isRequired,
      requester: PropTypes.object.isRequired,
    },
  )),
  sentDate: PropTypes.string,
  title: PropTypes.string,
};

export default ReplaceLeaderSent;
