// @packages
import React from 'react';
import RootModal from 'smu-app-components/RootModal';
import cn from 'classnames';
import { connect } from 'react-redux';

// @app
import ActionButtons from 'components/molecules/ActionButtons';

// @own
import './styles.scss';
import { GOAL_DETAIL_ACTION_DELETE_MODAL } from 'betterme-components/constants/modalTypes';
import { selectLoadingDelete } from 'betterme-components/Goals/selectors';
import { trackGoalDeleteCancel } from 'betterme-components/Goals/analytics';

//TODO implement <ConfirmationModal> here

const handleClose = (close) => {
  trackGoalDeleteCancel();
  close();
};

const GoalsActionsDeleteModal = ({
  acceptMessage,
  cancelMessage,
  close,
  goalId,
  loading,
  onAccept,
  title,
}) => (
  <div
    className={cn('goals-actions-delete-modal', {
      'goals-actions-delete-modal--loading': loading,
    })}
  >
    <div className="goals-actions-delete-modal__title">{title}</div>
    <div className="goals-actions-delete-modal__button-wrapper">
      <ActionButtons
        buttons={[
          {
            label: cancelMessage,
            onClick: () => handleClose(close),
            variant: 'outline',
          },
          {
            label: acceptMessage,
            onClick: () => onAccept(goalId),
          },
        ]}
      />
    </div>
  </div>
);

const mapStateToProps = (state) => ({
  modalProps: {
    loading: selectLoadingDelete(state),
  },
});

const modalStyles = { maxWidth: '600px' };
const withRootModalComponent = RootModal(
  GoalsActionsDeleteModal,
  GOAL_DETAIL_ACTION_DELETE_MODAL,
  modalStyles,
  'goals-actions-delete-modal__wrapper'
);

export default connect(mapStateToProps, null)(withRootModalComponent);
