import { put, call, takeEvery } from 'redux-saga/effects';
import { REQUEST_NOTIFICATIONS } from 'smu-app-components/NotificationsV2/actionTypes';

import NotificationsAPI from 'betterme-components/NotificationsV2/api';

import {
  MARK_ALL_NOTIFICATION_HISTORY_AS_READ,
  MARK_ALL_NOTIFICATION_HISTORY_AS_READ_FAIL,
  MARK_ALL_NOTIFICATION_HISTORY_AS_READ_SUCCESS,
  REQUEST_NOTIFICATIONS_HIS,
  REQUEST_NOTIFICATIONS_HIS_SUCCESS,
  REQUEST_NOTIFICATIONS_HIS_FAILED,
  SET_READ_NOTIFICATION_HISTORY,
  SET_READ_NOTIFICATION_HISTORY_SUCCESS,
  SET_READ_NOTIFICATION_HISTORY_FAIL,
} from './actionTypes';

import {
  getNotifications as getNotificationsAPI,
  setReadNotificationHistoryAPI,
  markAllNotificationsAsReadAPI,
} from './api';


export function* getNotificationHistorysWorker({ payload }) {
  try {
    const results = yield call(getNotificationsAPI, payload);
    yield put({ type: REQUEST_NOTIFICATIONS_HIS_SUCCESS, payload: results });
  } catch (error) {
    yield put({ type: REQUEST_NOTIFICATIONS_HIS_FAILED, payload: error });
  }
}

export function* markAllNotificationsHistoryAsReadWorker() {
  try {
    yield call(markAllNotificationsAsReadAPI);
    yield put({ type: MARK_ALL_NOTIFICATION_HISTORY_AS_READ_SUCCESS });
    yield put({
      type: REQUEST_NOTIFICATIONS,
      payload: { api: NotificationsAPI.getNotifications, pageNumber: 0 },
    });
  } catch (error) {
    yield put({ type: MARK_ALL_NOTIFICATION_HISTORY_AS_READ_FAIL, payload: error });
  }
}

export function* setNotificationAsReadWorker({ payload }) {
  try {
    yield call(setReadNotificationHistoryAPI, payload);
    yield put({ type: SET_READ_NOTIFICATION_HISTORY_SUCCESS });
    yield put({
      type: REQUEST_NOTIFICATIONS,
      payload: { api: NotificationsAPI.getNotifications, pageNumber: 0 },
    });
  } catch (error) {
    yield put({ type: SET_READ_NOTIFICATION_HISTORY_FAIL, payload: error });
  }
}

export function* getNotificationsHistoryWatcher() {
  yield takeEvery(REQUEST_NOTIFICATIONS_HIS, getNotificationHistorysWorker);
}
export function* markAllNotificationsHistoryAsReadWatcher() {
  yield takeEvery(MARK_ALL_NOTIFICATION_HISTORY_AS_READ, markAllNotificationsHistoryAsReadWorker);
}

export function* setNotificationAsReadWatcher() {
  yield takeEvery(SET_READ_NOTIFICATION_HISTORY, setNotificationAsReadWorker);
}

export default {
  getNotificationsHistoryWatcher,
  markAllNotificationsHistoryAsReadWatcher,
  setNotificationAsReadWatcher,
};
