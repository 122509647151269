// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import InputArea from 'smu-ui-components/InputArea';
import Divider from 'smu-ui-components/Divider';

// @app
import ActionButtons from 'components/molecules/ActionButtons';
import Card from 'common/Card';
import {
  actionsMessages,
  suggestedActionsMessages,
} from 'common/messages';

// @own
import SuggestedActionItem from '../SuggestedActionItem';
import smallAlert from './small_alert.svg';
import messages from '../messages';
import './styles.scss';

const SuggestActionsForm = ({
  comment,
  intl: { formatMessage },
  onActionChange,
  onCancel,
  onCommentChange,
  onSend,
  username,
  selectedSuggestions,
  suggestions,
  valid,
}) => {
  const isEmptySuggestions = suggestions.length === 0;

  return (
    <div className="suggest-actions-form">
      <Card lightGray className="suggest-actions-form__card">
        <Card.Header>
          <Card.Title>
            {formatMessage(messages.SuggestActionsFormTitle)}
          </Card.Title>
          <Card.Meta className="suggest-actions-form__card-subtitle">
            <img
              alt='icon-alert'
              className="suggest-actions-form__icon-alert"
              src={smallAlert}
            />
            {formatMessage(messages.SuggestActionsFormSubtitle, { username })}
          </Card.Meta>
        </Card.Header>
        <Divider />
        <Card.Content className="suggest-actions-form__card-form">
          {isEmptySuggestions ? (
            <p className="suggest-actions-form__empty-suggestions-label">
              {formatMessage(suggestedActionsMessages.SuggestActionsEmptySuggestions)}
            </p>
          ) : (
            <ul className="suggest-actions-form__list">
              {suggestions.map(suggestion =>
                <li className="suggest-actions-form__list-item" key={suggestion.id}>
                  <SuggestedActionItem
                    description={suggestion.description}
                    name={suggestion.name}
                    onChange={(event) => onActionChange(event.target.checked, suggestion.id)}
                    selected={selectedSuggestions.includes(suggestion.id)}
                  />
                </li>
              )}
            </ul>
          )}
          <InputArea
            disabled={isEmptySuggestions}
            maxLength={1200}
            onChange={onCommentChange}
            placeholder={formatMessage(messages.SuggestActionsFormComment)}
            rows={5}
            value={comment}
          />
        </Card.Content>
      </Card>
      <div className="suggest-actions-form__actions">
        <ActionButtons
          buttons={[
            {
              label: formatMessage(actionsMessages.ActionsCancel),
              onClick: onCancel,
              variant: 'outline',
            },
            {
              disabled: !valid,
              label: formatMessage(actionsMessages.ActionsSend),
              onClick: onSend,
            },
          ]}
        />
      </div>
    </div>
  );
};

SuggestActionsForm.defaultProps = {
  onActionChange: () => undefined,
  selectedSuggestions: [],
  suggestions: [],
};

SuggestActionsForm.propTypes = {
  comment: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  onActionChange: PropTypes.func,
  onCancel: PropTypes.func,
  onCommentChange: PropTypes.func,
  onSend: PropTypes.func,
  username: PropTypes.string.isRequired,
  selectedSuggestions: PropTypes.array,
  suggestions: PropTypes.array,
  valid: PropTypes.bool,
};

export default injectIntl(SuggestActionsForm);
