import { defineMessages } from 'react-intl';

export default defineMessages({
  AddSkillsTitle: {
    id: 'Betterme.AddSkillsSection.SkillsTitle',
    defaultMessage: 'Technical Skills'
  },
  AddSkillsSectionHardSkills: {
    id: 'Betterme.AddSkillsSection.HardSkills',
    defaultMessage: 'Here you can evaluate other technical/specialty skills if you believe you have enough information to do so.'
  },
  AddSkillsSectionTechnicalSkills: {
    id: 'Betterme.AddSkillsSection.PerformanceRetrospectiveInput.HardSkills',
    defaultMessage: 'Here you can evaluate other technical/specialty skills. Please add at least one in order to submit your input.'
  },
  AddSkillsSectionSelfSkills: {
    id: 'Betterme.AddSkillsSection.SelfRetrospective.HardSkills',
    defaultMessage: 'Here you can assess other technical/specialty skills if you consider it appropriate.'
  },
  AddSkillsSectionEmptySkills: {
    id: 'Betterme.AddSkillsSection.EmptySkills',
    defaultMessage: 'No skills have been added yet'
  },
  AddSkillsSectionTechnicalSuggestionSubtitle: {
    id: 'Betterme.Evaluations.TechnicalSuggestionSubtitle',
    defaultMessage: 'You can either show or hide this',
  },
  AddSkillsSectionYourResponse: {
    id: 'Betterme.Evaluations.YourResponse',
    defaultMessage: 'Your Response',
  },
  AddSkillsSectionSwitch: {
    id: 'Betterme.Assessment.Switch',
    defaultMessage: 'Include in assessment',
  },
  AddSkillsSectionTechnicalSuggestion: {
    id: 'Betterme.Evaluations.CoCreatorSuggestion',
    defaultMessage: 'Technical Co-Creator Suggestion',
  },
});
