// @packages
import { buildUrlProps, isStarMeUpDomain } from 'smu-utils/url';

function decodeContinueUrl(url) {
  try {
    return atob(url);
  } catch {
    return null;
  }
}

export function externalLinkRedirect(url, router, newTab) {
  if (!url) {
    return;
  }

  let customUrl = url;
  const objUrl = new URL(url);
  const queryContinue = objUrl.searchParams.get('continue');
  const queryLink = objUrl.searchParams.get('link');
  const continueUrl = queryContinue ? decodeContinueUrl(queryContinue) : null;
  const isStarMeUpDomainUrl = isStarMeUpDomain(url);

  if (continueUrl && isStarMeUpDomainUrl) {
    customUrl = continueUrl;
  } else if (queryLink && isStarMeUpDomainUrl) {
    customUrl = queryLink;
  }

  const urlProps = buildUrlProps(customUrl);

  if (urlProps.target === '_blank' || newTab) {
    window.open(urlProps.href, '_blank');
  } else if (urlProps.isLocal) {
    router.push(urlProps.href);
  } else {
    window.location.href = urlProps.href;
  }
}
