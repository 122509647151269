import {
  INSERT_FILES,
  RESET_FILES,
  DELETE_FILES,
  DELETE_FILES_FAIL,
  DELETE_FILES_SUCCESS,
  DRAFT_FILES,
  UPLOAD_FILES,
  UPLOAD_FILES_FAIL,
  UPLOAD_FILES_SUCCESS,
} from './actionTypes';

export function deleteFiles(id) {
  return {
    type: DELETE_FILES,
    payload: { id },
  };
}

export function deleteFilesFail(error) {
  return {
    type: DELETE_FILES_FAIL,
    payload: { error },
  };
}

export function deleteFilesSuccess(id) {
  return {
    type: DELETE_FILES_SUCCESS,
    payload: { id },
  };
}

export function uploadFiles(file) {
  return {
    type: UPLOAD_FILES,
    payload: { file },
  };
}

export function uploadFilesFail(error) {
  return {
    type: UPLOAD_FILES_FAIL,
    payload: { error },
  };
}

export function uploadFilesSuccess(file) {
  return {
    type: UPLOAD_FILES_SUCCESS,
    payload: { file },
  };
}

export function resetFiles() {
  return {
    type: RESET_FILES,
  };
}

export function insertFiles(payload) {
  return {
    type: INSERT_FILES,
    payload,
  };
}

export function draftFiles(payload) {
  return {
    type: DRAFT_FILES,
    payload,
  };
}
