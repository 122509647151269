import {
  GET_USER_INFORMATION,
  GET_USER_INFORMATION_FAIL,
  GET_USER_INFORMATION_SUCCESS,
  RESET_USER_INFORMATION,
} from './actionTypes';

const initialState = {
  userInfo: {},
  userInfoError: '',
  userInfoLoading: false,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_USER_INFORMATION:
      return {
        ...state,
        userInfoLoading: true,
      };

    case GET_USER_INFORMATION_FAIL:
      return {
        ...state,
        userInfoError: payload.error,
        userInfoLoading: false,
      };

    case GET_USER_INFORMATION_SUCCESS:
      return {
        ...state,
        userInfo: payload.response,
        userInfoLoading: false,
      };

    case RESET_USER_INFORMATION:
      return {
        ...state,
        userInfo: {},
      };

    default:
      return state;
  }
};
