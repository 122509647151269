// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @own
import './styles.scss';

const EmptyStateCardTitle = ({
  children,
  className,
}) => {
  return (
    <div className={cn('empty-state-card-title', className)}>
      <div className="empty-state-card-title__content">
        {children}
      </div>
    </div>
  );
};

EmptyStateCardTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

export default EmptyStateCardTitle;
