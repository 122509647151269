// @packages
import get from 'lodash/get';

export const selectTierType = ({ tierType }) => {
  return get(tierType, 'tierType');
}

export const selectTierTypeLoading = ({ tierType }) => {
  return get(tierType, 'tierTypeLoading');
}

export const selectTierTypeErrorMessage = ({ tierType }) => {
  return get(tierType, 'tierTypeErrorMessage');
}
