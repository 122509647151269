// @packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Image from 'smu-ui-components/Image';

//@own
import './styles.scss';

const CompetenciesCardItemValue = ({
  description,
  imageCode,
  imageCodeHover,
  isChecked,
  onChange,
  radioId,
  value,
}) => {
  const [isHover, setHover] = useState(false);
  const sizeImage = 80;
  const radioImage = (isHover || isChecked) ? imageCodeHover : imageCode;

  return (
    <>
      <input
        checked={isChecked}
        className="competencies-card-item-value__input competencies-card-item-value__emojis-input"
        id={radioId}
        onChange={onChange}
        type="radio"
        value={value}
      />
      <label
        className="competencies-card-item-value__emojis-card"
        for={radioId}
        onMouseEnter={() => { setHover(true) }}
        onMouseLeave={() => { setHover(false) }}
      >
        <Image
          alt={description}
          className="competencies-card-item-value__emojis-image"
          code={radioImage}
          height={sizeImage}
          scale={1}
          width={sizeImage}
        />
        <p className="competencies-card-item-value__emojis-text">
          {description}
        </p>
      </label>
    </>
  );
};

CompetenciesCardItemValue.defaultProps = {
  imageCode: 1,
  imageCodeHover: 1,
  onChange: () => { },
};

CompetenciesCardItemValue.propTypes = {
  description: PropTypes.string.isRequired,
  imageCode: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  imageCodeHover: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  radioId: PropTypes.string.isRequired,
  value: PropTypes.number,
}

export default CompetenciesCardItemValue;
