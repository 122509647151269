// @packages
import { takeLatest, call, put } from 'redux-saga/effects';

// @own
import {
  getCommunityRatingsFail,
  getCommunityRatingsSuccess,
  getCommunityScoresFail,
  getCommunityScoresSuccess,
  getCommunityTopicsFail,
  getCommunityTopicsSuccess,
} from './actions';
import { GET_COMMUNITY_RATINGS, GET_COMMUNITY_SCORES, GET_COMMUNITY_TOPICS } from './actionTypes';
import {
  getCommunityRatings,
  getCommunityScores,
  getCommunityTopics,
} from './api';

function* getCommunityRatingsWorker() {
  try {
    const communityRatings = yield call(getCommunityRatings);
    yield put(getCommunityRatingsSuccess(communityRatings));
  } catch (error) {
    yield put(getCommunityRatingsFail(error.message));
  }
};

function* getCommunityScoresWorker() {
  try {
    const communityScores = yield call(getCommunityScores);
    yield put(getCommunityScoresSuccess(communityScores));
  } catch (error) {
    yield put(getCommunityScoresFail(error.message));
  }
};

function* getCommunityTopicsWorker() {
  const topicsParams = {
    active: true,
    pageSize: 100,
  };
  try {
    const communityTopics = yield call(getCommunityTopics, topicsParams);
    yield put(getCommunityTopicsSuccess(communityTopics));
  } catch (error) {
    yield put(getCommunityTopicsFail(error.message));
  }
};

export default function* communityInfoWatcher() {
  yield takeLatest(GET_COMMUNITY_RATINGS, getCommunityRatingsWorker);
  yield takeLatest(GET_COMMUNITY_SCORES, getCommunityScoresWorker);
  yield takeLatest(GET_COMMUNITY_TOPICS, getCommunityTopicsWorker);
};
