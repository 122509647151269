import { defineMessages } from 'react-intl';

export default defineMessages({
  PerformanceRetrospectiveTitle: {
    id: 'Betterme.PerformanceRetrospective.Title',
    defaultMessage: 'Create Performance Retrospective',
  },
  PerformanceRetrospectiveEditTitle: {
    id: 'Betterme.PerformanceRetrospective.EditTitle',
    defaultMessage: 'Edit Performance Retrospective',
  },
  PerformanceRetrospectiveCoEvaluator: {
    id: 'Betterme.PerformanceRetrospective.CoEvaluator',
    defaultMessage: 'Assessment Co-Creator',
  },
  PerformanceRetrospectiveCoEvaluatorSearch: {
    id: 'Betterme.PerformanceRetrospective.CoCreatorSearch',
    defaultMessage: 'Search Co-Creator',
  },
  PerformanceRetrospectiveCoEvaluatorDescription: {
    id: 'Betterme.PerformanceRetrospective.CoEvaluatorDescription',
    defaultMessage: 'In order to complement the Performance Retrospective, add another person (e.g. Technical Leader or other Leader) as a partner in the assessment.',
  },
  PerformanceRetrospectiveCoEvaluatorHelp: {
    id: 'Betterme.PerformanceRetrospective.CoEvaluatorHelp',
    defaultMessage: 'If you haven’t added a Co-Creator, let us know why <a target={target} href={href}>here</a>.',
  },
  PerformanceRetrospectiveCoEvaluatorNotSelected: {
    id: 'Betterme.PerformanceRetrospective.CoEvaluatorNotSelected',
    defaultMessage: 'The Performance Retrospective must be saved as draft to be able to add a Co-Creator.',
  },
  PerformanceRetrospectiveCoEvaluatorSelected: {
    id: 'Betterme.PerformanceRetrospective.CoEvaluatorSelected',
    defaultMessage: 'Create a new draft to change or remove the Co-Creator.',
  },
  PerformanceRetrospectiveAttachFile: {
    id: 'Betterme.PerformanceRetrospective.AttachFile',
    defaultMessage: '(Referring to the performance score)',
  },
  PerformanceRetrospectiveRate: {
    id: 'Betterme.PerformanceRetrospective.Score',
    defaultMessage: 'Performance Score',
  },
  PerformanceRetrospectiveSelectRate: {
    id: 'Betterme.PerformanceRetrospective.SelectRate',
    defaultMessage: 'Choose a performance score for the assessment (Required)',
  },
  PerformanceRetrospectivePlaceholder: {
    id: 'Betterme.PerformanceRetrospective.Placeholder',
    defaultMessage: 'Provide feedback to the Glober on their strengths and areas for improvement, contributing to team goals and ongoing talent development. (Required)',
  },
  PerformanceRetrospectiveCoCreatorSlideOneTitle: {
    id: 'Betterme.Evaluations.CoCreatorSlideOneTitle',
    defaultMessage: 'Let’s work together',
  },
  PerformanceRetrospectiveCoCreatorSlideTwoTitle: {
    id: 'Betterme.Evaluations.CoCreatorSlideTwoTitle',
    defaultMessage: 'Visibility',
  },
  PerformanceRetrospectiveCoCreatorSlideOneText: {
    id: 'Betterme.PerformanceRetrospective.CoCreatorSlideOneText',
    defaultMessage: 'Choose a partner to review the Glober’s performance together. You will need someone who can assess their technical or specialty skills. Save this assessment as draft to activate a notification for this co-creator.',
  },
  PerformanceRetrospectiveCoCreatorSlideTwoText: {
    id: 'Betterme.Evaluations.CoCreatorSlideTwoText',
    defaultMessage: 'Once the partner completes their assessment, you will be able to see their input in each section. The "Technichal skills" and comment box for co-creator will be visible to the Glober unless you have disabled those sections of the assessment. We recommend conducting a final review with the partner before submitting the assessment. Once submitted, the Glober will receive a notification and will be able to access the Performance Retrospective.',
  },
  PerformanceRetrospectiveCoEvaluatorScore: {
    id: 'Betterme.Evaluations.CoEvaluatorScore',
    defaultMessage: 'Suggested by {username}',
  },
  PeriodEvaluationSuccessMessage: {
    id: 'Betterme.Evaluations.EvaluationSuccessMessage',
    defaultMessage: 'Well done!',
  },
  PeriodEvaluationOverallScoreFiles: {
    id: 'Betterme.Evaluations.OverallScoreFiles',
    defaultMessage: 'Referring to the overall score',
  },
  PeriodEvaluationDraftSaved: {
    id: 'Betterme.Evaluations.DraftSaved',
    defaultMessage: 'The draft was saved successfully',
  },
  PeriodEvaluationCoEvaluatorDraftSaved: {
    id: 'Betterme.Evaluations.CoEvaluatorDraftSaved',
    defaultMessage: 'Draft was successfully saved! Notification for Co-Creator was sent',
  },
  PeriodEvaluationDraftDeleted: {
    id: 'Betterme.Evaluations.DraftDeleted',
    defaultMessage: 'The draft was deleted successfully.',
  },
  PerformanceRetrospectiveCoEvaluatorScoreEmpty: {
    id: 'Betterme.Evaluations.OverallScoreEmpty',
    defaultMessage: 'Comment will be shown here once available',
  },
  PeriodEvaluationTechnicalSuggestionSubtitle: {
    id: 'Betterme.Evaluations.TechnicalSuggestionSubtitle',
    defaultMessage: 'You can either show or hide this',
  },
  PeriodEvaluationYourResponse: {
    id: 'Betterme.Evaluations.YourResponse',
    defaultMessage: 'Your Response',
  },
  PeriodEvaluationSwitch: {
    id: 'Betterme.Assessment.Switch',
    defaultMessage: 'Include in assessment',
  },
  PeriodEvaluationTechnicalSuggestion: {
    id: 'Betterme.Evaluations.CoCreatorSuggestion',
    defaultMessage: 'Technical Co-Creator Suggestion',
  },
  PerformanceRetrospectiveSuccessFooterMessageTeam: {
    id: 'Betterme.PerformanceRetrospective.SuccessFooterMessageTeam',
    defaultMessage: 'Your Performance Retrospective was created successfully',
  },
  PerformanceRetrospectiveScoreWarning: {
    id: 'Betterme.PerformanceRetrospective.ScoreWarning',
    defaultMessage: '<b>Keep in mind</b>: You cannot change or add a Performance Score.',
  },
  PerformanceRetrospectiveCoEvaluatorScoreMessage: {
    id: 'Betterme.PerformanceRetrospective.CoEvaluatorScoreMessage',
    defaultMessage: 'Feeling like having a <b>conversation with the Technical Co-Creator</b>? A performance score mismatch was detected.',
  },
});
