// @own
import {
  GET_TIER_TYPE,
  GET_TIER_TYPE_SUCCESS,
  GET_TIER_TYPE_FAIL,
  RESET_TIER_TYPE,
  TIER_TYPE_ERROR_MESSAGE,
} from './actionTypes';

export function getTierType(payload) {
  return {
    type: GET_TIER_TYPE,
    payload,
  };
}

export function getTierTypeSuccess(response) {
  return {
    type: GET_TIER_TYPE_SUCCESS,
    payload: response,
  };
}

export function getTierTypeFail(error) {
  return {
    type: GET_TIER_TYPE_FAIL,
    payload: error,
  };
}

export function resetTierType() {
  return {
    type: RESET_TIER_TYPE,
  };
}

export function tierErrorMessage(payload) {
  return {
    type: TIER_TYPE_ERROR_MESSAGE,
    payload,
  };
}
