import { defineMessages } from 'react-intl';

export default defineMessages({
  PlaceholdersDownloadReport: {
    id: 'Betterme.Messages.Placeholders.DownloadReport',
    defaultMessage: 'Download Report',
  },
  PlaceholdersFrom: {
    id: 'Betterme.Messages.Placeholders.From',
    defaultMessage: 'From',
  },
  PlaceholdersTo: {
    id: 'Betterme.Messages.Placeholders.To',
    defaultMessage: 'To',
  },
  PlaceholdersLeaveComment: {
    id: 'Betterme.Messages.Placeholders.LeaveComment',
    defaultMessage: 'Leave a comment',
  },
  PlaceholdersDate: {
    id: 'Betterme.Messages.Placeholders.Date',
    defaultMessage: 'MM/DD/YYYY',
  },
  PlaceholdersNoResults: {
    id: 'Betterme.Messages.Placeholders.NoResults',
    defaultMessage: 'There are no results to show',
  },
  PlaceholdersCsvDoc: {
    id: 'Betterme.Messages.Placeholders.CsvDoc',
    defaultMessage: 'CSV Document (.csv)',
  },
  PlaceholdersXlsDoc: {
    id: 'Betterme.Messages.Placeholders.XlsDoc',
    defaultMessage: 'XLS Document (.xls)',
  },
  PlaceholdersXlsxDoc: {
    id: 'Betterme.Messages.Placeholders.XlsxDoc',
    defaultMessage: 'XLSX Document (.xlsx)',
  },
  PlaceholdersSelectPeriod: {
    id: 'Betterme.Messages.Placeholders.SelectPeriod',
    defaultMessage: 'Select a period',
  },
  PlaceholdersCurrentPeriod: {
    id: 'Betterme.Messages.Placeholders.CurrentPeriod',
    defaultMessage: 'Current period',
  },
  PlaceholdersSixMonths: {
    id: 'Betterme.Messages.Placeholders.SixMonths',
    defaultMessage: 'Last 6 months',
  },
  PlaceholdersThreeMonths: {
    id: 'Betterme.Messages.Placeholders.ThreeMonths',
    defaultMessage: 'Last 3 months',
  },
  PlaceholdersAll: {
    id: 'Betterme.Messages.Placeholders.All',
    defaultMessage: 'All',
  },
  PlaceholdersSelectStatus: {
    id: 'Betterme.Messages.Placeholders.SelectStatus',
    defaultMessage: 'Select status',
  },
  PlaceholdersActions: {
    id: 'Betterme.Messages.Placeholders.Actions',
    defaultMessage: 'Actions',
  },
  PlaceholdersSelectAssigned: {
    id: 'Betterme.Messages.Placeholders.SelectAssigned',
    defaultMessage: 'Select assigned',
  },
  PlaceholdersSearchByGoalTitle: {
    id: 'Betterme.Messages.Placeholders.SearchByGoalTitle',
    defaultMessage: 'Search by Goal title',
  },
  PlaceholdersOtherReason: {
    id: 'Betterme.Messages.Placeholders.OtherReason',
    defaultMessage: 'Leave a comment in case you think it is neccesary',
  },
  PlaceholderPromotionsRequestStatus: {
    id: "Betterme.Messages.Placeholders.requestStatusFilter",
    defaultMessage: "Request Status",
  },
  PlaceholdersMandatory: {
    id: "Betterme.Messages.Placeholders.mandatory",
    defaultMessage: "Mandatory",
  },
});
