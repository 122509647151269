import { defineMessages } from 'react-intl';

export default defineMessages({
  PotentialAssessmentDashboardTitle: {
    id: 'Betterme.PotentialAssessment.DashboardTitle',
    defaultMessage: 'Last Potential Assessment',
  },
  PotentialAssessmentTitle: {
    id: 'Betterme.PotentialAssessment.Title',
    defaultMessage: 'Potential Assessment',
  },
  PotentialAssessmentDashboardWarning: {
    id: 'Betterme.PotentialAssessment.DashboardWarning',
    defaultMessage: 'Result is only visible to leaders.',
  },
  PotentialAssessmentDashboardWarningPilot: {
    id: 'Betterme.PotentialAssessment.DashboardWarningPilot',
    defaultMessage: 'This Glober was part of a pilot for a new process. Check on "View more" for details.',
  },
  PotentialAssessmentDashboardEmptyStatePendingEvalNotStarted: {
    id: 'Betterme.PotentialAssessment.DashboardEmptyStateEvalNotStarted',
    defaultMessage: 'Talent was not assessed during this period.',
  },
  PotentialAssessmentGlober: {
    id: 'Betterme.PotentialAssessment.Glober',
    defaultMessage: 'Glober',
  },
  PotentialAssessmentGlobant: {
    id: 'Betterme.PotentialAssessment.Globant',
    defaultMessage: 'Globant',
  },
  PotentialAssessmentDimensions: {
    id: 'Betterme.PotentialAssessment.Dimensions',
    defaultMessage: 'Dimensions',
  },
  PotentialAssessmentDashboardEmptyStateEvalOngoing: {
    id: 'Betterme.PotentialAssessment.DashboardEmptyStateEvalOngoing',
    defaultMessage: 'Results will show up here once available.',
  },
  PotentialAssessmentCapturedBy: {
    id: 'Betterme.PotentialAssessment.CapturedBy',
    defaultMessage: 'Captured By:',
  },
  PotentialAssessmentGeneralResult: {
    id: 'Betterme.PotentialAssessment.GeneralResult',
    defaultMessage: 'General Result',
  },
  PotentialAssessmentGeneralResultBody: {
    id: 'Betterme.PotentialAssessment.GeneralResultBody',
    defaultMessage: 'In {company}, we know that every member in the organization has Potential to grow, however <b>each {Talent} manifests it at a different pace</b>, down below you will see how fast your team member will be able to adapt to a new position within the company.',
  },
  PotentialAssessmentGeneralResultLink: {
    id: 'Betterme.PotentialAssessment.GeneralResultLink',
    defaultMessage: 'Click <u>here</u> to find out how to take action on this result.',
  },
  PotentialAssessmentResultByDimensions: {
    id: 'Betterme.PotentialAssessment.ResultByDimensions',
    defaultMessage: 'Result by dimensions',
  },
  PotentialAssessmentResultByDimensionsToggle: {
    id: 'Betterme.PotentialAssessment.ResultByDimensionsToggle',
    defaultMessage: 'See results descriptions',
  },
  PotentialAssessmentResultByDimensionsBody: {
    id: 'Betterme.PotentialAssessment.ResultByDimensionsBody',
    defaultMessage: 'Dimensions help leaders understand what their team members’ weaknesses and strengths could be when facing new challenges in their careers.',
  },
  PotentialAssessmentDisclaimer: {
    id: 'Betterme.PotentialAssessment.Disclaimer',
    defaultMessage: 'This page is only visible for leaders.',
  },
  PotentialAssessmentDisclaimerPilot: {
    id: 'Betterme.PotentialAssessment.DisclaimerPilot',
    defaultMessage: 'You are seeing this information because this Glober was part of a pilot to test Globant’s new potential model. This data is confidential, not visible to the Glober and has no impact on any company process. Any doubt contact talentdevelopment@globant.com',
  },
  PotentialAssessmentInsight: {
    id: 'Betterme.PotentialAssessment.Insight',
    defaultMessage: 'Insight',
  },
  PotentialAssessmentDashboardScore1: {
    id: 'Betterme.PotentialAssessment.3498.FinalScore1',
    defaultMessage: 'Foundational',
  },
  PotentialAssessmentDashboardScore2: {
    id: 'Betterme.PotentialAssessment.3498.FinalScore2',
    defaultMessage: 'Intermediate',
  },
  PotentialAssessmentDashboardScore3: {
    id: 'Betterme.PotentialAssessment.3498.FinalScore3',
    defaultMessage: 'Advanced',
  },
  PotentialAssessmentDashboardScore4: {
    id: 'Betterme.PotentialAssessment.3498.FinalScore4',
    defaultMessage: 'High',
  },
  PotentialAssessmentDimensionScore1: {
    id: 'Betterme.PotentialAssessment.3498.DimensionScore1',
    defaultMessage: 'Let’s work on this',
  },
  PotentialAssessmentDimensionScore2: {
    id: 'Betterme.PotentialAssessment.3498.DimensionScore2',
    defaultMessage: 'Cool',
  },
  PotentialAssessmentDimensionScore3: {
    id: 'Betterme.PotentialAssessment.3498.DimensionScore3',
    defaultMessage: 'Rocks it!',
  },
  PotentialAssessmentSegment1FinalScore1: {
    id: 'Betterme.PotentialAssessment.3498.Segment1FinalScore1',
    defaultMessage: 'Needs time-intensive development to manage higher complexity.',
  },
  PotentialAssessmentSegment1FinalScore2: {
    id: 'Betterme.PotentialAssessment.3498.Segment1FinalScore2',
    defaultMessage: 'Developing key skills for adapting to future complexity growth.',
  },
  PotentialAssessmentSegment1FinalScore3: {
    id: 'Betterme.PotentialAssessment.3498.Segment1FinalScore3',
    defaultMessage: 'Mastering future complexity, growing skills to excel.',
  },
  PotentialAssessmentSegment1FinalScore4: {
    id: 'Betterme.PotentialAssessment.3498.Segment1FinalScore4',
    defaultMessage: 'Excelling in key skills to handle future complexity challenges.',
  },
  PotentialAssessmentSegment2FinalScore1: {
    id: 'Betterme.PotentialAssessment.3498.Segment2FinalScore1',
    defaultMessage: 'Needs support in developing skills for leadership complexity.',
  },
  PotentialAssessmentSegment2FinalScore2: {
    id: 'Betterme.PotentialAssessment.3498.Segment2FinalScore2',
    defaultMessage: 'Improving leadership skills for future complexity growth.',
  },
  PotentialAssessmentSegment2FinalScore3: {
    id: 'Betterme.PotentialAssessment.3498.Segment2FinalScore3',
    defaultMessage: 'Prepared to master skills for future leadership complexities.',
  },
  PotentialAssessmentSegment2FinalScore4: {
    id: 'Betterme.PotentialAssessment.3498.Segment2FinalScore4',
    defaultMessage: 'Excelling in core leadership skills to handle future complex roles.',
  },
  PotentialAssessmentSegment3FinalScore1: {
    id: 'Betterme.PotentialAssessment.3498.Segment3FinalScore1',
    defaultMessage: "Needs support to lead large units to Globant's future.",
  },
  PotentialAssessmentSegment3FinalScore2: {
    id: 'Betterme.PotentialAssessment.3498.Segment3FinalScore2',
    defaultMessage: "Improving leadership skills to lead large units to Globant's future.",
  },
  PotentialAssessmentSegment3FinalScore3: {
    id: 'Betterme.PotentialAssessment.3498.Segment3FinalScore3',
    defaultMessage: "Prepared to master leadership skills to lead large units to Globant's future",
  },
  PotentialAssessmentSegment3FinalScore4: {
    id: 'Betterme.PotentialAssessment.3498.Segment3FinalScore4',
    defaultMessage: "Excelling in core leadership skills to lead large units to Globant's future.",
  },
  PotentialAssessmentSegment4FinalScore1: {
    id: 'Betterme.PotentialAssessment.3498.Segment4FinalScore1',
    defaultMessage: 'Needs support to master skills for the highest roles complexities.',
  },
  PotentialAssessmentSegment4FinalScore2: {
    id: 'Betterme.PotentialAssessment.3498.Segment4FinalScore2',
    defaultMessage: 'Improving to master skills for the highest roles complexities.',
  },
  PotentialAssessmentSegment4FinalScore3: {
    id: 'Betterme.PotentialAssessment.3498.Segment4FinalScore3',
    defaultMessage: 'Prepared to master skills for the highest roles complexities.',
  },
  PotentialAssessmentSegment4FinalScore4: {
    id: 'Betterme.PotentialAssessment.3498.Segment4FinalScore4',
    defaultMessage: 'Excelling in core skills to handle the highest level of complexity.',
  },
  PotentialAssessmentSegment1: {
    id: 'Betterme.PotentialAssessment.3498.Segment1',
    defaultMessage: 'Initial Growth Potential',
  },
  PotentialAssessmentSegment1learnability: {
    id: 'Betterme.PotentialAssessment.3498.Segment1learnability',
    defaultMessage: 'Learnability',
  },
  PotentialAssessmentSegment1learnabilityResult1: {
    id: 'Betterme.PotentialAssessment.3498.Segment1learnabilityResult1',
    defaultMessage: 'Basic learning capacity. Requires tailored interventions to adapt to evolving demands.',
  },
  PotentialAssessmentSegment1learnabilityResult2: {
    id: 'Betterme.PotentialAssessment.3498.Segment1learnabilityResult2',
    defaultMessage: 'Consider opportunities to enhance learning capacity to effectively and consistently meet evolving demands.',
  },
  PotentialAssessmentSegment1learnabilityResult3: {
    id: 'Betterme.PotentialAssessment.3498.Segment1learnabilityResult3',
    defaultMessage: 'Exceptional learning capacity for mastering future skills and adapting to evolving demands.',
  },
  PotentialAssessmentSegment1networking: {
    id: 'Betterme.PotentialAssessment.3498.Segment1networking',
    defaultMessage: 'Networking',
  },
  PotentialAssessmentSegment1networkingResult1: {
    id: 'Betterme.PotentialAssessment.3498.Segment1networkingResult1',
    defaultMessage: 'It is suggested to monitor the progress of social skills to obtain current differential solutions.',
  },
  PotentialAssessmentSegment1networkingResult2: {
    id: 'Betterme.PotentialAssessment.3498.Segment1networkingResult2',
    defaultMessage: 'It is suggested to continue monitoring the progress of social skills for current and future differential solutions.',
  },
  PotentialAssessmentSegment1networkingResult3: {
    id: 'Betterme.PotentialAssessment.3498.Segment1networkingResult3',
    defaultMessage: 'Demonstrates the ability to have valuable relationships based on empathy. It is suggested to promote the generation of spaces to generate awareness with their peers.',
  },
  PotentialAssessmentSegment1motivationforresults: {
    id: 'Betterme.PotentialAssessment.3498.Segment1motivationforresults',
    defaultMessage: 'Motivation for Results',
  },
  PotentialAssessmentSegment1motivationforresultsResult1: {
    id: 'Betterme.PotentialAssessment.3498.Segment1motivationforresultsResult1',
    defaultMessage: 'Little determination is inferred in the search for results on a constant basis. It is recommended to review motivators!.',
  },
  PotentialAssessmentSegment1motivationforresultsResult2: {
    id: 'Betterme.PotentialAssessment.3498.Segment1motivationforresultsResult2',
    defaultMessage: 'Shows interest in the search for his current role, it is recommended to verify his motivators to continue working on them.',
  },
  PotentialAssessmentSegment1motivationforresultsResult3: {
    id: 'Betterme.PotentialAssessment.3498.Segment1motivationforresultsResult3',
    defaultMessage: 'Demonstrates a continuous search for results in current role that can be beneficial to achieve objectives of greater complexity.',
  },
  PotentialAssessmentSegment1problemsolving: {
    id: 'Betterme.PotentialAssessment.3498.Segment1problemsolving',
    defaultMessage: 'Problem Solving',
  },
  PotentialAssessmentSegment1problemsolvingResult1: {
    id: 'Betterme.PotentialAssessment.3498.Segment1problemsolvingResult1',
    defaultMessage: 'Follow-up and mentoring is recommended to improve the quality of solutions provided.',
  },
  PotentialAssessmentSegment1problemsolvingResult2: {
    id: 'Betterme.PotentialAssessment.3498.Segment1problemsolvingResult2',
    defaultMessage: 'Demonstrates ability to provide accurate solutions. Follow-up is necessary to strengthen these skills and provide value-added solutions.',
  },
  PotentialAssessmentSegment1problemsolvingResult3: {
    id: 'Betterme.PotentialAssessment.3498.Segment1problemsolvingResult3',
    defaultMessage: 'Demonstrates the ability to provide value-added solutions. It is suggested to encourage the complexity of the assigned challenges.',
  },
  PotentialAssessmentSegment1emotionalintelligence: {
    id: 'Betterme.PotentialAssessment.3498.Segment1emotionalintelligence',
    defaultMessage: 'Emotional Intelligence',
  },
  PotentialAssessmentSegment1emotionalintelligenceResult1: {
    id: 'Betterme.PotentialAssessment.3498.Segment1emotionalintelligenceResult1',
    defaultMessage: 'It is recommended to monitor and give more tools to improve your management of emotions and that of others when faced with ambiguity.',
  },
  PotentialAssessmentSegment1emotionalintelligenceResult2: {
    id: 'Betterme.PotentialAssessment.3498.Segment1emotionalintelligenceResult2',
    defaultMessage: 'Demonstrates some ability to manage his own feelings and those of others when faced with ambiguity. Follow-up is suggested to provide more tools.',
  },
  PotentialAssessmentSegment1emotionalintelligenceResult3: {
    id: 'Betterme.PotentialAssessment.3498.Segment1emotionalintelligenceResult3',
    defaultMessage: 'Demonstrates great ability to manage their own emotions and those of others in the face of ambiguity. It is suggested to promote the generation of spaces to generate awareness with their peers.',
  },
  PotentialAssessmentSegment1relationaleffectiveness: {
    id: 'Betterme.PotentialAssessment.3498.Segment1relationaleffectiveness',
    defaultMessage: 'Relational Effectiveness',
  },
  PotentialAssessmentSegment1relationaleffectivenessResult1: {
    id: 'Betterme.PotentialAssessment.3498.Segment1relationaleffectivenessResult1',
    defaultMessage: 'Basic networking skills. Requires tailored interventions to improve meaningful working relations.',
  },
  PotentialAssessmentSegment1relationaleffectivenessResult2: {
    id: 'Betterme.PotentialAssessment.3498.Segment1relationaleffectivenessResult2',
    defaultMessage: 'Consider opportunities to strengthen networking to obtain differential solutions, it is important to continue monitoring progress.',
  },
  PotentialAssessmentSegment1relationaleffectivenessResult3: {
    id: 'Betterme.PotentialAssessment.3498.Segment1relationaleffectivenessResult3',
    defaultMessage: 'Highly effective networking abilities. Capable of creating opportunities to manage more complex challenges.',
  },
  PotentialAssessmentSegment1resultsdrivenmotivation: {
    id: 'Betterme.PotentialAssessment.3498.Segment1resultsdrivenmotivation',
    defaultMessage: 'Results-Driven Motivation',
  },
  PotentialAssessmentSegment1resultsdrivenmotivationResult1: {
    id: 'Betterme.PotentialAssessment.3498.Segment1resultsdrivenmotivationResult1',
    defaultMessage: 'Basic determination to overcome obstacles. Requires motivation enhancement for achieving results. Identify personal motivators.',
  },
  PotentialAssessmentSegment1resultsdrivenmotivationResult2: {
    id: 'Betterme.PotentialAssessment.3498.Segment1resultsdrivenmotivationResult2',
    defaultMessage: 'Shows determination to overcome obstacles of different complexity but has the ability to give more, revise their personal motivators.',
  },
  PotentialAssessmentSegment1resultsdrivenmotivationResult3: {
    id: 'Betterme.PotentialAssessment.3498.Segment1resultsdrivenmotivationResult3',
    defaultMessage: 'Autonomously overcoming obstacles of varying complexity. Boost their personal motivators!',
  },
  PotentialAssessmentSegment1analyticalthinkingandcommunication: {
    id: 'Betterme.PotentialAssessment.3498.Segment1analyticalthinkingandcommunication',
    defaultMessage: 'Analytical Thinking and Communication',
  },
  PotentialAssessmentSegment1analyticalthinkingandcommunicationResult1: {
    id: 'Betterme.PotentialAssessment.3498.Segment1analyticalthinkingandcommunicationResult1',
    defaultMessage: 'Basic problem-solving skills present; requires tailored interventions to consider multiple variables for effective solutions.',
  },
  PotentialAssessmentSegment1analyticalthinkingandcommunicationResult2: {
    id: 'Betterme.PotentialAssessment.3498.Segment1analyticalthinkingandcommunicationResult2',
    defaultMessage: 'Possesses analytical problem-solving skills; however, further development is needed to consider additional variables.',
  },
  PotentialAssessmentSegment1analyticalthinkingandcommunicationResult3: {
    id: 'Betterme.PotentialAssessment.3498.Segment1analyticalthinkingandcommunicationResult3',
    defaultMessage: 'Exceptional problem-solving skills, creating plentiful opportunities to leverage and benefit from this well-developed proficiency.',
  },
  PotentialAssessmentSegment1emotionalagility: {
    id: 'Betterme.PotentialAssessment.3498.Segment1emotionalagility',
    defaultMessage: 'Emotional Agility',
  },
  PotentialAssessmentSegment1emotionalagilityResult1: {
    id: 'Betterme.PotentialAssessment.3498.Segment1emotionalagilityResult1',
    defaultMessage: 'Basic adaptability with straightforward solutions. Requires improvement in handling ambiguity and complexities.',
  },
  PotentialAssessmentSegment1emotionalagilityResult2: {
    id: 'Betterme.PotentialAssessment.3498.Segment1emotionalagilityResult2',
    defaultMessage: 'Moderate adaptability, exploring simple solutions. Needs improvement in handling ambiguity and complexities.',
  },
  PotentialAssessmentSegment1emotionalagilityResult3: {
    id: 'Betterme.PotentialAssessment.3498.Segment1emotionalagilityResult3',
    defaultMessage: 'Exceptional capacity to respond to changes, finding simple solutions when faced with ambiguity. Leveraging this ability in complex situations.',
  },
  PotentialAssessmentSegment2: {
    id: 'Betterme.PotentialAssessment.3498.Segment2',
    defaultMessage: 'Mid Leadership Potential',
  },
  PotentialAssessmentSegment2passionresults: {
    id: 'Betterme.PotentialAssessment.3498.Segment2passionresults',
    defaultMessage: 'Passion & Results',
  },
  PotentialAssessmentSegment2passionresultsResult1: {
    id: 'Betterme.PotentialAssessment.3498.Segment2passionresultsResult1',
    defaultMessage: 'It is recommended to give more tools and 1:1 mentoring to improve leadership skills therefore help boost the team’s achievements in outstanding results, passion and continuous learning.',
  },
  PotentialAssessmentSegment2passionresultsResult2: {
    id: 'Betterme.PotentialAssessment.3498.Segment2passionresultsResult2',
    defaultMessage: 'It is recommended to give more tools to improve leadership skills and in turn help to enhance the team’s achievements in outstanding results, passion and continuous learning.',
  },
  PotentialAssessmentSegment2passionresultsResult3: {
    id: 'Betterme.PotentialAssessment.3498.Segment2passionresultsResult3',
    defaultMessage: 'Shows great leadership skills that are reflected in team achievements for outstanding results, passion, and continuous learning. It is suggested sharing good practices with other team members to encourage their development.',
  },
  PotentialAssessmentSegment2socialintelligence: {
    id: 'Betterme.PotentialAssessment.3498.Segment2socialintelligence',
    defaultMessage: 'Social Intelligence',
  },
  PotentialAssessmentSegment2socialintelligenceResult1: {
    id: 'Betterme.PotentialAssessment.3498.Segment2socialintelligenceResult1',
    defaultMessage: 'It is recommended to implement a learning path where they can get practical tools to improve the social skills.',
  },
  PotentialAssessmentSegment2socialintelligenceResult2: {
    id: 'Betterme.PotentialAssessment.3498.Segment2socialintelligenceResult2',
    defaultMessage: 'Can adapt to some levels of conversation with other interlocutors. With more tools, he could further develop this skill.',
  },
  PotentialAssessmentSegment2socialintelligenceResult3: {
    id: 'Betterme.PotentialAssessment.3498.Segment2socialintelligenceResult3',
    defaultMessage: 'Can easily create meaningful relationships by adapting to different levels of conversations with different people. It is suggested to encourage the generation of spaces between different people to take advantage of this ability.',
  },
  PotentialAssessmentSegment2complexitymanagement: {
    id: 'Betterme.PotentialAssessment.3498.Segment2complexitymanagement',
    defaultMessage: 'Complexity Management',
  },
  PotentialAssessmentSegment2complexitymanagementResult1: {
    id: 'Betterme.PotentialAssessment.3498.Segment2complexitymanagementResult1',
    defaultMessage: 'It is recommended to provide tools that facilitate comfort to get out of your comfort zone and manage a diversity of projects.',
  },
  PotentialAssessmentSegment2complexitymanagementResult2: {
    id: 'Betterme.PotentialAssessment.3498.Segment2complexitymanagementResult2',
    defaultMessage: 'Has the ability to manage complex projects by finding simple solutions. Providing more support and tools in the process will help find solutions outside of the status quo.',
  },
  PotentialAssessmentSegment2complexitymanagementResult3: {
    id: 'Betterme.PotentialAssessment.3498.Segment2complexitymanagementResult3',
    defaultMessage: 'Is comfortable stepping out of the comfort zone and has the skills needed to manage complex projects. It is suggested to encourage the complexity of the assigned challenges.',
  },
  PotentialAssessmentSegment2businessvision: {
    id: 'Betterme.PotentialAssessment.3498.Segment2businessvision',
    defaultMessage: 'Business Vision',
  },
  PotentialAssessmentSegment2businessvisionResult1: {
    id: 'Betterme.PotentialAssessment.3498.Segment2businessvisionResult1',
    defaultMessage: 'It is recommended to provide 1:1 spaces to provide more information about the account and the company in order to offer solutions before the problem arises.',
  },
  PotentialAssessmentSegment2businessvisionResult2: {
    id: 'Betterme.PotentialAssessment.3498.Segment2businessvisionResult2',
    defaultMessage: 'The integration of more information is recommended to provide solutions before the problem arises. More tools are needed to improve this capability.',
  },
  PotentialAssessmentSegment2businessvisionResult3: {
    id: 'Betterme.PotentialAssessment.3498.Segment2businessvisionResult3',
    defaultMessage: 'Shows a great understanding of the business and the ability to provide solutions before the problem arises. It is suggested to promote the generation of spaces to generate awareness with their peers.',
  },
  PotentialAssessmentSegment2leadershipaccountability: {
    id: 'Betterme.PotentialAssessment.3498.Segment2leadershipaccountability',
    defaultMessage: 'Leadership Accountability',
  },
  PotentialAssessmentSegment2leadershipaccountabilityResult1: {
    id: 'Betterme.PotentialAssessment.3498.Segment2leadershipaccountabilityResult1',
    defaultMessage: 'Basic leadership and management skills with some impact. Requires improvement to inspire the team effectively and explore wider opportunities.',
  },
  PotentialAssessmentSegment2leadershipaccountabilityResult2: {
    id: 'Betterme.PotentialAssessment.3498.Segment2leadershipaccountabilityResult2',
    defaultMessage: 'Competent leadership and management skills contributing to results, moderately inspiring the team. Opportunities for broader impact can be explored.',
  },
  PotentialAssessmentSegment2leadershipaccountabilityResult3: {
    id: 'Betterme.PotentialAssessment.3498.Segment2leadershipaccountabilityResult3',
    defaultMessage: 'Exceptional leadership and management skills that inspire the whole team. Explore ways to extend this impact to a wider audience.',
  },
  PotentialAssessmentSegment2interpersonalfluency: {
    id: 'Betterme.PotentialAssessment.3498.Segment2interpersonalfluency',
    defaultMessage: 'Interpersonal Fluency',
  },
  PotentialAssessmentSegment2interpersonalfluencyResult1: {
    id: 'Betterme.PotentialAssessment.3498.Segment2interpersonalfluencyResult1',
    defaultMessage: 'Basic proficiency in relationship cultivation, with room for improvement in prioritizing trust and collaboration for more impactful connections.',
  },
  PotentialAssessmentSegment2interpersonalfluencyResult2: {
    id: 'Betterme.PotentialAssessment.3498.Segment2interpersonalfluencyResult2',
    defaultMessage: 'Ability to foster meaningful relationships. Provide opportunities for enhacement.',
  },
  PotentialAssessmentSegment2interpersonalfluencyResult3: {
    id: 'Betterme.PotentialAssessment.3498.Segment2interpersonalfluencyResult3',
    defaultMessage: 'Excels in fostering meaningful relationships based on trust and collaboration. Facilitate spaces with other teams throughtout Globant!',
  },
  PotentialAssessmentSegment2changeandriskmanagement: {
    id: 'Betterme.PotentialAssessment.3498.Segment2changeandriskmanagement',
    defaultMessage: 'Change and Risk Management',
  },
  PotentialAssessmentSegment2changeandriskmanagementResult1: {
    id: 'Betterme.PotentialAssessment.3498.Segment2changeandriskmanagementResult1',
    defaultMessage: 'Basic adaptability to unexpected changes. Requires tailored interventions to feel secure to find simple solutions.',
  },
  PotentialAssessmentSegment2changeandriskmanagementResult2: {
    id: 'Betterme.PotentialAssessment.3498.Segment2changeandriskmanagementResult2',
    defaultMessage: 'Competent to manage somewhat complex projects. Provide tools to discover solutions beyond the status quo!',
  },
  PotentialAssessmentSegment2changeandriskmanagementResult3: {
    id: 'Betterme.PotentialAssessment.3498.Segment2changeandriskmanagementResult3',
    defaultMessage: 'Thrives in leaving the comfort zone and has the necessary tools to manage complex projects.',
  },
  PotentialAssessmentSegment2strategicthinking: {
    id: 'Betterme.PotentialAssessment.3498.Segment2strategicthinking',
    defaultMessage: 'Strategic Thinking',
  },
  PotentialAssessmentSegment2strategicthinkingResult1: {
    id: 'Betterme.PotentialAssessment.3498.Segment2strategicthinkingResult1',
    defaultMessage: 'Basic ability to identify business opportunities. Requires significant improvement in proactively integrating diverse information.'
  },
  PotentialAssessmentSegment2strategicthinkingResult2: {
    id: 'Betterme.PotentialAssessment.3498.Segment2strategicthinkingResult2',
    defaultMessage: 'Capable of identifying opportunities to impact business with solutions. Improvements are needed in proactively integrating diverse information and sharing their vision.',
  },
  PotentialAssessmentSegment2strategicthinkingResult3: {
    id: 'Betterme.PotentialAssessment.3498.Segment2strategicthinkingResult3',
    defaultMessage: 'Exceptional at identifying business opportunities and implementing proactive solutions by integrating diverse information. Encourage open spaces for diverse conversations to impact Globant positively!',
  },
  PotentialAssessmentSegment3: {
    id: 'Betterme.PotentialAssessment.3498.Segment3',
    defaultMessage: 'Top Leadership Potential',
  },
  PotentialAssessmentSegment3personalreinvention: {
    id: 'Betterme.PotentialAssessment.3498.Segment3personalreinvention',
    defaultMessage: 'Personal Reinvention',
  },
  PotentialAssessmentSegment3personalreinventionResult1: {
    id: 'Betterme.PotentialAssessment.3498.Segment3personalreinventionResult1',
    defaultMessage: 'It is recommended to give more accompaniment and tools to achieve the development of skills that promote ideas that are at the forefront of technology and then make them a reality.',
  },
  PotentialAssessmentSegment3personalreinventionResult2: {
    id: 'Betterme.PotentialAssessment.3498.Segment3personalreinventionResult2',
    defaultMessage: 'Has the skills to generate new ideas at the forefront of the world of technology. A greater understanding of the business and abandoning the status quo is recommended to bring these ideas to life.',
  },
  PotentialAssessmentSegment3personalreinventionResult3: {
    id: 'Betterme.PotentialAssessment.3498.Segment3personalreinventionResult3',
    defaultMessage: 'Demonstrates a great capacity for learning to develop new ideas at the forefront of technology that can expand our business. It is suggested to promote spaces where they can share innovative ideas and put them into practice with other teams.',
  },
  PotentialAssessmentSegment3businessreinvention: {
    id: 'Betterme.PotentialAssessment.3498.Segment3businessreinvention',
    defaultMessage: 'Business Reinvention',
  },
  PotentialAssessmentSegment3businessreinventionResult1: {
    id: 'Betterme.PotentialAssessment.3498.Segment3businessreinventionResult1',
    defaultMessage: 'A greater set of tools and support is recommended to develop decision-making within the framework of the execution of strategic changes.',
  },
  PotentialAssessmentSegment3businessreinventionResult2: {
    id: 'Betterme.PotentialAssessment.3498.Segment3businessreinventionResult2',
    defaultMessage: 'Demonstrate decision-making capacity, it is suggested to broaden their global vision of the business to generate strategic changes with a greater impact on the company.',
  },
  PotentialAssessmentSegment3businessreinventionResult3: {
    id: 'Betterme.PotentialAssessment.3498.Segment3businessreinventionResult3',
    defaultMessage: 'Demonstrates the ability to generate added value to the vision of the business. It could generate a great differential by contributing to other work groups with greater impact.',
  },
  PotentialAssessmentSegment3teamreinvention: {
    id: 'Betterme.PotentialAssessment.3498.Segment3teamreinvention',
    defaultMessage: 'Team Reinvention',
  },
  PotentialAssessmentSegment3teamreinventionResult1: {
    id: 'Betterme.PotentialAssessment.3498.Segment3teamreinventionResult1',
    defaultMessage: 'Providing more support and tools is recommended to improve the construction of significant relationships and guide the team in their complex situations.',
  },
  PotentialAssessmentSegment3teamreinventionResult2: {
    id: 'Betterme.PotentialAssessment.3498.Segment3teamreinventionResult2',
    defaultMessage: 'Demonstrates ability to lead teams and create meaningful relationships. It is recommended to give tools to improve the ability to give key messages according to the types of motivators within your team.',
  },
  PotentialAssessmentSegment3teamreinventionResult3: {
    id: 'Betterme.PotentialAssessment.3498.Segment3teamreinventionResult3',
    defaultMessage: 'Demonstrates great ability to guide teams through key messages and create meaningful relationships based on empathy and kindness. They could do some great work inspiring more people through meaningful conversations!.',
  },
  PotentialAssessmentSegment3culturalreinvention: {
    id: 'Betterme.PotentialAssessment.3498.Segment3culturalreinvention',
    defaultMessage: 'Cultural Reinvention',
  },
  PotentialAssessmentSegment3culturalreinventionResult1: {
    id: 'Betterme.PotentialAssessment.3498.Segment3culturalreinventionResult1',
    defaultMessage: 'Promoting exchange with other people is recommended in order to understand the cultural diversity that exists between the different colleagues and thus generate different visions, teams and ideas.',
  },
  PotentialAssessmentSegment3culturalreinventionResult2: {
    id: 'Betterme.PotentialAssessment.3498.Segment3culturalreinventionResult2',
    defaultMessage: 'Manages to create a cultural awareness that allows for a variety of people, visions and ideas within his teams. It is recommended to give more support in extending the ability to the entire company.',
  },
  PotentialAssessmentSegment3culturalreinventionResult3: {
    id: 'Betterme.PotentialAssessment.3498.Segment3culturalreinventionResult3',
    defaultMessage: 'Demonstrates ability to generate cultural awareness on a large scale that enables different visions, teams, and ideas across the company. It is suggested to share their skills with other colleagues to continue creating cultural awareness.',
  },
  PotentialAssessmentSegment3continuousevolution: {
    id: 'Betterme.PotentialAssessment.3498.Segment3continuousevolution',
    defaultMessage: 'Continuous Evolution',
  },
  PotentialAssessmentSegment3continuousevolutionResult1: {
    id: 'Betterme.PotentialAssessment.3498.Segment3continuousevolutionResult1',
    defaultMessage: 'Basic proficiency in adapting skills. Requires significant development to actively engage in innovative initiatives and contribute to a dynamic business environment.',
  },
  PotentialAssessmentSegment3continuousevolutionResult2: {
    id: 'Betterme.PotentialAssessment.3498.Segment3continuousevolutionResult2',
    defaultMessage: 'Competent in shaping a dynamic business environment through skill acquisition. Seek opportunities for involvement in innovative initiatives.',
  },
  PotentialAssessmentSegment3continuousevolutionResult3: {
    id: 'Betterme.PotentialAssessment.3498.Segment3continuousevolutionResult3',
    defaultMessage: 'Exceptional ability to shape a dynamic business environment through continuous skills acquisition. Foster collaborations with key stakeholders to deepen innovative and strategic understanding.',
  },
  PotentialAssessmentSegment3culturestrategyhero: {
    id: 'Betterme.PotentialAssessment.3498.Segment3culturestrategyhero',
    defaultMessage: 'Culture & Strategy Hero',
  },
  PotentialAssessmentSegment3culturestrategyheroResult1: {
    id: 'Betterme.PotentialAssessment.3498.Segment3culturestrategyheroResult1',
    defaultMessage: 'Basic ability to make impactful decisions for long-term success and competitive advantage. Mentor on analyzing multifaceted variables to attain strategic business vision',
  },
  PotentialAssessmentSegment3culturestrategyheroResult2: {
    id: 'Betterme.PotentialAssessment.3498.Segment3culturestrategyheroResult2',
    defaultMessage: 'Proficient skills in strategy and culture management. Seek learnings and exposure to expand the strategic vision.',
  },
  PotentialAssessmentSegment3culturestrategyheroResult3: {
    id: 'Betterme.PotentialAssessment.3498.Segment3culturestrategyheroResult3',
    defaultMessage: "Excels in expanding cultural evolution by making high-impact decisions through analyzing multifaceted variables. Encourage to seek partnerships with external entities to boost the company's strategic positioning and growth.",
  },
  PotentialAssessmentSegment3leadershipempowerment: {
    id: 'Betterme.PotentialAssessment.3498.Segment3leadershipempowerment',
    defaultMessage: 'Leadership Empowerment',
  },
  PotentialAssessmentSegment3leadershipempowermentResult1: {
    id: 'Betterme.PotentialAssessment.3498.Segment3leadershipempowermentResult1',
    defaultMessage: 'Basic ability to guide teams through complex situations and cultivate meaningful relationships. Requires additional support and tools for further development.',
  },
  PotentialAssessmentSegment3leadershipempowermentResult2: {
    id: 'Betterme.PotentialAssessment.3498.Segment3leadershipempowermentResult2',
    defaultMessage: 'Has the ability to guide teams and cultivate meaningful relationships but has room for further development. Recommend to seek feedback from leaders to create agile teams!',
  },
  PotentialAssessmentSegment3leadershipempowermentResult3: {
    id: 'Betterme.PotentialAssessment.3498.Segment3leadershipempowermentResult3',
    defaultMessage: 'Exceptional ability to guide through key messages and cultivate meaningful relationships based on empathy and kindness.',
  },
  PotentialAssessmentSegment3futureforwardvision: {
    id: 'Betterme.PotentialAssessment.3498.Segment3futureforwardvision',
    defaultMessage: 'Future-Forward Vision',
  },
  PotentialAssessmentSegment3futureforwardvisionResult1: {
    id: 'Betterme.PotentialAssessment.3498.Segment3futureforwardvisionResult1',
    defaultMessage: 'Basic ability to anticipate industry trends, and customer and sustainability. Requires resources for developing skills in future-oriented thinking.',
  },
  PotentialAssessmentSegment3futureforwardvisionResult2: {
    id: 'Betterme.PotentialAssessment.3498.Segment3futureforwardvisionResult2',
    defaultMessage: 'Capable of having the forward-thinking perspective to anticipate industry trends, customer and sustainability needs but has room for further development. Encourage them to explore new strategies for integrating future perspectives.',
  },
  PotentialAssessmentSegment3futureforwardvisionResult3: {
    id: 'Betterme.PotentialAssessment.3498.Segment3futureforwardvisionResult3',
    defaultMessage: 'Excels in having a forward-thinking perspective to anticipate industry trends, customer and sustainability needs, and technological advancements. Support their initiatives for integrating future perspectives.',
  },
  PotentialAssessmentSegment4: {
    id: 'Betterme.PotentialAssessment.3498.Segment4',
    defaultMessage: 'Executive Leadership Potential',
  },
  PotentialAssessmentSegment4continuousevolution: {
    id: 'Betterme.PotentialAssessment.3498.Segment4continuousevolution',
    defaultMessage: 'Continuous Evolution',
  },
  PotentialAssessmentSegment4continuousevolutionResult1: {
    id: 'Betterme.PotentialAssessment.3498.Segment4continuousevolutionResult1',
    defaultMessage: 'Basic proficiency; needs improvement in fostering learning and guiding teams through transformations. Offer mentorship for further development.',
  },
  PotentialAssessmentSegment4continuousevolutionResult2: {
    id: 'Betterme.PotentialAssessment.3498.Segment4continuousevolutionResult2',
    defaultMessage: 'Proficient in fostering learning and resilience; can enhance agility during transformations. Encourage them to participate in cross-functional initiatives that require adaptability and agility.',
  },
  PotentialAssessmentSegment4continuousevolutionResult3: {
    id: 'Betterme.PotentialAssessment.3498.Segment4continuousevolutionResult3',
    defaultMessage: 'Excels in fostering continuous learning and resilience, guiding teams through transformational moments. Encourage them to share their successful strategies and best practices for navigating transformational moments.',
  },
  PotentialAssessmentSegment4futureforwardvision: {
    id: 'Betterme.PotentialAssessment.3498.Segment4futureforwardvision',
    defaultMessage: 'Future-Forward Vision',
  },
  PotentialAssessmentSegment4futureforwardvisionResult1: {
    id: 'Betterme.PotentialAssessment.3498.Segment4futureforwardvisionResult1',
    defaultMessage: 'Basic ability in envisioning possibilities, leading strategic planning, and proposing disruptive initiatives. Requires significant improvement, offer mentorship on strategic visioning.',
  },
  PotentialAssessmentSegment4futureforwardvisionResult2: {
    id: 'Betterme.PotentialAssessment.3498.Segment4futureforwardvisionResult2',
    defaultMessage: 'Proficient in envisioning possibilities, leading strategic planning, and proposing disruptive initiatives. Provide training on strategic foresight.',
  },
  PotentialAssessmentSegment4futureforwardvisionResult3: {
    id: 'Betterme.PotentialAssessment.3498.Segment4futureforwardvisionResult3',
    defaultMessage: 'Excels in envisioning extraordinary possibilities, leading strategic planning, and proposing disruptive initiatives. Support their vision and encourage innovative strategies.',
  },
  PotentialAssessmentSegment4leadershipempowerment: {
    id: 'Betterme.PotentialAssessment.3498.Segment4leadershipempowerment',
    defaultMessage: 'Leadership Empowerment',
  },
  PotentialAssessmentSegment4leadershipempowermentResult1: {
    id: 'Betterme.PotentialAssessment.3498.Segment4leadershipempowermentResult1',
    defaultMessage: 'Basic ability to guide teams through complex situations and cultivate meaningful relationships. Requires additional support and tools for further development.',
  },
  PotentialAssessmentSegment4leadershipempowermentResult2: {
    id: 'Betterme.PotentialAssessment.3498.Segment4leadershipempowermentResult2',
    defaultMessage: 'Has the ability to guide teams and cultivate meaningful relationships. Recommend to seek feedback from leaders to create agile teams!.',
  },
  PotentialAssessmentSegment4leadershipempowermentResult3: {
    id: 'Betterme.PotentialAssessment.3498.Segment4leadershipempowermentResult3',
    defaultMessage: 'Exceptional ability to guide through key messages and cultivate meaningful relationships based on empathy and kindness.',
  },
  PotentialAssessmentSegment4culturestrategyhero: {
    id: 'Betterme.PotentialAssessment.3498.Segment4culturestrategyhero',
    defaultMessage: 'Culture & Strategy Hero',
  },
  PotentialAssessmentSegment4culturestrategyheroResult1: {
    id: 'Betterme.PotentialAssessment.3498.Segment4culturestrategyheroResult1',
    defaultMessage: 'Basic ability of articulating diverse strategies to drive substantial changes and expand cultural evolution. Requires significant development to expand this ability throughout the company.',
  },
  PotentialAssessmentSegment4culturestrategyheroResult2: {
    id: 'Betterme.PotentialAssessment.3498.Segment4culturestrategyheroResult2',
    defaultMessage: 'Capable of articulating diverse strategies to drive substantial changes and expand cultural evolution. More support is needed to expand this ability throughout the company, with a gradual introduction to external contexts.',
  },
  PotentialAssessmentSegment4culturestrategyheroResult3: {
    id: 'Betterme.PotentialAssessment.3498.Segment4culturestrategyheroResult3',
    defaultMessage: 'Exceptional skills in articulating diverse strategies to drive substantial changes and expand cultural evolution. Enhance opportunities to showcase this capability in external contexts.',
  },
  PotentialAssessmentComments: {
    id: 'Betterme.PotentialAssessment.Comments',
    defaultMessage: `Comments about Glober’s Potential`,
  },
});

