// @packages
import React from 'react';
import Link from 'smu-ui-components/Link';
import PropTypes from 'prop-types';
import { formatDate } from 'utils/formatDate';

// @own
import './styles.scss';

const SelfEvaluationItem = ({
  creationTime,
  id,
  score,
  title,
  type,
  itemMessages: {
    itemScore,
    itemSubmittedDate,
    itemTitle,
    itemViewDetail,
  },
}) => (
  <div className="self-evaluation-item">
    <div className="self-evaluation-item__prop">
      <strong>{itemTitle}:</strong>
      <span>{title}</span>
    </div>
    <div className="self-evaluation-item__prop">
      <strong>{itemSubmittedDate}:</strong>
      <span>{formatDate(creationTime)}</span>
    </div>
    <div className="self-evaluation-item__prop">
      <strong>{itemScore}:</strong>
      <span>{score.value} - {score.message}</span>
    </div>
    <div className="self-evaluation-item__actions">
      <Link
        className="self-evaluation-item__link"
        to={
          type === 'SELF_RETROSPECTIVE'
            ? `/evaluations/self-retrospective/${id}`
            : `/evaluations/${id}`
        }
        target="_blank"
      >
        {itemViewDetail}
      </Link>
    </div>
  </div>
);

SelfEvaluationItem.propTypes = {
  creationTime: PropTypes.object,
  id: PropTypes.number,
  score: PropTypes.number,
  itemMessages: PropTypes.array,
  title: PropTypes.string.isRequired,
};

export default SelfEvaluationItem;
