import { defineMessages } from 'react-intl';

export default defineMessages({
  AddMemberTitleHeading: {
    id: 'Betterme.AddMember.ModalTitle',
    defaultMessage: 'Leadership request',
  },
  AddMemberMessage: {
    id: 'Betterme.AddMember.Message',
    defaultMessage: 'Are you sure you want to add {fullName} as your team member?',
  },
});
