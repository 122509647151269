// @packages
import { get } from 'lodash';

const reducerDomain = 'leadershipRequestsSent';

export const selectGetLeadershipRequestsPage = (state) =>
  get(state, [reducerDomain, 'leadershipRequestsPage']);

export const selectGetLeadershipRequestsSent = (state) =>
  get(state, [reducerDomain, 'leadershipRequestsSent']);

export const selectLoadingLeadershipRequestsSent = (state) =>
  get(state, [reducerDomain, 'leadershipRequestsSentLoading']);
