// @own
import { buildClosePath } from '../helpers';

const oneOnOneCreate = ({ onClose, open, query }) => {
  open({
    defaultValues: { toUserUid: query?.uid },
    onClose: () => onClose(buildClosePath(['uid'])),
    onSubmitSuccess: response => onClose(`/1on1/detail/${response?.meetings?.[0]?.id}`),
  });
};

export default oneOnOneCreate;
