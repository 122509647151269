import {
  GET_EVALUATIONS_TEAM,
  GET_EVALUATIONS_TEAM_FAIL,
  GET_EVALUATIONS_TEAM_SUCCESS,
} from './actionTypes';

const initialState = {
  evaluationsTeam: {},
  evaluationsTeamLoading: false,
  evaluationsTeamError: '',
};

export default function reducer(
  state = initialState,
  {
    type,
    payload,
  },
) {
  switch (type) {
    case GET_EVALUATIONS_TEAM:
      return {
        ...state,
        evaluationsTeamLoading: true,
      };

    case GET_EVALUATIONS_TEAM_FAIL:
      return {
        ...state,
        evaluationsTeamLoading: false,
        error: payload.error,
      };

    case GET_EVALUATIONS_TEAM_SUCCESS:
      return {
        ...state,
        evaluationsTeamLoading: false,
        evaluationsTeam: payload.response,
      };

    default: 
      return state;
  }
};
