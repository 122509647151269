import React from 'react';
import PropTypes from 'prop-types';
import Segment from 'smu-ui-components/Segment';
import cn from 'classnames';

// @app
import BreadcrumbHeader from 'components/organisms/BreadcrumbHeader';
import ActionButtons from 'components/molecules/ActionButtons';

// @own
import './styles.scss';

const FullScreenSection = ({
  actionButtons,
  children,
  className,
  headerValues,
  loading,
  navigateBack,
  showButtons,
}) => (
  <div className={cn('full-screen-section', className)}>
    <BreadcrumbHeader
      breadCrumbsProps={{
        active: 1,
        onClick: navigateBack,
        size: 'big',
        values: headerValues,
      }}
      className="full-screen-section__breadcrumb"
    />
    <Segment
      className='full-screen-section__content'
      loading={loading}
      loadingType="betterme"
    >
      {children}
    </Segment>
    {showButtons && (
      <footer className="full-screen-section__footer">
        <div className="full-screen-section__footer-container">
          <ActionButtons
            className="full-screen-section__footer-buttons"
            buttons={actionButtons}
          />
        </div>
      </footer>
    )}
  </div>
);

FullScreenSection.defaultProps = {
  actionButtons: [],
  headerValues: [],
  loading: false,
};

FullScreenSection.propTypes = {
  actionButtons: PropTypes.array,
  children: PropTypes.node,
  className: PropTypes.string,
  headerValues: PropTypes.object,
  loading: PropTypes.bool,
  navigateBack: PropTypes.func,
  withButtons: PropTypes.bool,
};

export default FullScreenSection;
