export const GET_GOALS_ASSIGNED = 'GET_GOALS_ASSIGNED';
export const GET_GOALS_ASSIGNED_FAIL = 'GET_GOALS_ASSIGNED_FAIL';
export const GET_GOALS_ASSIGNED_SUCCESS = 'GET_GOALS_ASSIGNED_SUCCESS';
export const GET_GOALS_ASSIGNED_CLOSED = 'GET_GOALS_ASSIGNED_CLOSED';
export const GET_GOALS_ASSIGNED_CLOSED_FAIL = 'GET_GOALS_ASSIGNED_CLOSED_FAIL';
export const GET_GOALS_ASSIGNED_CLOSED_SUCCESS = 'GET_GOALS_ASSIGNED_CLOSED_SUCCESS';
export const GET_GOALS_ASSIGNED_OPENED = 'GET_GOALS_ASSIGNED_OPENED';
export const GET_GOALS_ASSIGNED_OPENED_FAIL = 'GET_GOALS_ASSIGNED_OPENED_FAIL';
export const GET_GOALS_ASSIGNED_OPENED_SUCCESS = 'GET_GOALS_ASSIGNED_OPENED_SUCCESS';
export const RESET_GOALS_ASSIGNED = 'RESET_GOALS_ASSIGNED';
