// @packages
import PropTypes from 'prop-types';
import React from 'react';
import RootModal from 'smu-app-components/RootModal';
import { injectIntl } from 'react-intl';
import UserAvatar from 'smu-ui-components/UserAvatar';

// @app
import { PROFILE_MORE_INFO } from '../constants/modalTypes';
import SimpleModal from 'common/SimpleModal';

// @own
import './styles.scss';

const ProfileMoreInfoModal = ({
  close,
  firstName,
  job,
  lastName,
  profileImageCode,
  seniority,
  title,
  userProps,
}) => (
  <SimpleModal
    className="profile-more-info-modal"
    closeModal={close}
  >
    <div className="profile-more-info-modal__main-details">
      <UserAvatar
        bordered
        height={70}
        profileImageCode={profileImageCode}
        width={70}
      />
      <div className="profile-more-info-modal__user-info">
        <h3 className="profile-more-info-modal__user-info-name">
          {`${firstName} ${lastName}`}
        </h3>
        {job && (
          <span className="profile-more-info-modal__user-info-job">
            {job}
          </span>
        )}
        {seniority && (
          <span className="profile-more-info-modal__user-info-seniority">
            {seniority}
          </span>
        )}
      </div>
    </div>
    <div className="profile-more-info-modal__info">
      {
        title && (
          <h3 className="profile-more-info-modal__title">
            {title}
          </h3>
        )
      }

      {(userProps.length > 0) && (
        <ul className="profile-more-info-modal__list">
          {
            userProps.map(prop => (
              <div className="profile-more-info-modal__item">
                <div className="profile-more-info-modal__item-title">
                  {prop.label}
                </div>
                <div className="profile-more-info-modal__item-value">
                  {prop.value}
                </div>
              </div>
            ))
          }
        </ul>
      )}
    </div>
  </SimpleModal>
);

ProfileMoreInfoModal.propTypes = {
  close: PropTypes.func.isRequired,
};

const modalStyles = { maxWidth: '600px', top: '25%', left: 0, right: 0, margin: '0 auto' };

export default RootModal(
  injectIntl(ProfileMoreInfoModal),
  PROFILE_MORE_INFO,
  modalStyles,
);
