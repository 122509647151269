import {
  CREATE_FEEDBACK_REQUEST_CANCEL,
  CREATE_FEEDBACK_REQUEST_CANCEL_FAIL,
  CREATE_FEEDBACK_REQUEST_CANCEL_SUCCESS,
  CREATE_FEEDBACK_REQUEST_DISMISS,
  CREATE_FEEDBACK_REQUEST_DISMISS_FAIL,
  CREATE_FEEDBACK_REQUEST_DISMISS_SUCCESS,
  GET_FEEDBACKS_REQUEST,
  GET_FEEDBACKS_REQUESTS_RECEIVED,
  GET_FEEDBACKS_REQUESTS_RECEIVED_FAIL,
  GET_FEEDBACKS_REQUESTS_RECEIVED_SUCCESS,
  GET_FEEDBACKS_REQUESTS_SENT,
  GET_FEEDBACKS_REQUESTS_SENT_FAIL,
  GET_FEEDBACKS_REQUESTS_SENT_SUCCESS,
  GET_FEEDBACKS_REQUEST_FAIL,
  GET_FEEDBACKS_REQUEST_SUCCESS,
  GET_REQUEST_DISMISS_REASONS,
  GET_REQUEST_DISMISS_REASONS_FAIL,
  GET_REQUEST_DISMISS_REASONS_SUCCESS,
  RESET_FEEDBACK_REQUEST_STATE,
  SELECT_REASON,
  SET_ACTIVE_TAB,
} from './actionTypes';

export function createFeedbackRequestCancel(requestId, userId, messages = {}) {
  return {
    type: CREATE_FEEDBACK_REQUEST_CANCEL,
    payload: { requestId, userId, messages },
  };
}

export function createFeedbackRequestCancelFail(error) {
  return {
    type: CREATE_FEEDBACK_REQUEST_CANCEL_FAIL,
    payload: { error },
  };
}

export function createFeedbackRequestCancelSuccess(response) {
  return {
    type: CREATE_FEEDBACK_REQUEST_CANCEL_SUCCESS,
    payload: { response },
  };
}

export function createFeedbackRequestDismiss(
  requestId,
  reasonId,
  userId,
  messages = {},
  refreshParams,
) {
  return {
    type: CREATE_FEEDBACK_REQUEST_DISMISS,
    payload: { requestId, reasonId, userId, messages, refreshParams },
  };
}

export function createFeedbackRequestDismissFail(error) {
  return {
    type: CREATE_FEEDBACK_REQUEST_DISMISS_FAIL,
    payload: { error },
  };
}

export function createFeedbackRequestDismissSuccess(response) {
  return {
    type: CREATE_FEEDBACK_REQUEST_DISMISS_SUCCESS,
    payload: { response },
  };
}

export function getFeedbacksRequest(params) {
  return {
    type: GET_FEEDBACKS_REQUEST,
    payload: { params },
  };
}

export function getFeedbacksRequestFail(error) {
  return {
    type: GET_FEEDBACKS_REQUEST_FAIL,
    payload: { error },
  };
}

export function getFeedbacksRequestSuccess(response) {
  return {
    type: GET_FEEDBACKS_REQUEST_SUCCESS,
    payload: { response },
  };
}

export function getFeedbackRequestReceived(userId, params) {
  return {
    type: GET_FEEDBACKS_REQUESTS_RECEIVED,
    payload: { userId, ...params },
  };
}

export function getFeedbackRequestReceivedFail(error) {
  return {
    type: GET_FEEDBACKS_REQUESTS_RECEIVED_FAIL,
    payload: { error },
  };
}

export function getFeedbackRequestReceivedSuccess(response) {
  return {
    type: GET_FEEDBACKS_REQUESTS_RECEIVED_SUCCESS,
    payload: { response },
  };
}

export function getFeedbackRequestSent(userId, params) {
  return {
    type: GET_FEEDBACKS_REQUESTS_SENT,
    payload: { userId, ...params },
  };
}

export function getFeedbackRequestSentFail(error) {
  return {
    type: GET_FEEDBACKS_REQUESTS_SENT_FAIL,
    payload: { error },
  };
}

export function getFeedbackRequestSentSuccess(response) {
  return {
    type: GET_FEEDBACKS_REQUESTS_SENT_SUCCESS,
    payload: { response },
  };
}

export function getRequestDismissReasons() {
  return {
    type: GET_REQUEST_DISMISS_REASONS,
  };
}

export function getRequestDismissReasonsFail(error) {
  return {
    type: GET_REQUEST_DISMISS_REASONS_FAIL,
    payload: { error },
  };
}

export function getRequestDismissReasonsSuccess(response) {
  return {
    type: GET_REQUEST_DISMISS_REASONS_SUCCESS,
    payload: { response },
  };
}

export function resetFeedbackRequestState() {
  return {
    type: RESET_FEEDBACK_REQUEST_STATE,
  };
}

export function selectReason(id) {
  return {
    type: SELECT_REASON,
    payload: { id }
  };
}

export function setActiveTab(tab) {
  return {
    type: SET_ACTIVE_TAB,
    payload: { tab },
  };
}
