// @packages
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { DragSource } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import cn from 'classnames';

// @own
import CompetencyItem from '../../CompetencyItem';
import { COMPETENCE } from '../constants';
import './styles.scss';

const competencySource = {
  beginDrag(props) {
    const { competency, onBeginDrag } = props;
    if (onBeginDrag) onBeginDrag(props);
    return { competency };
  },
  endDrag(props, monitor) {
    const { onEndDrag } = props;
    if (onEndDrag) onEndDrag(props);
    if (monitor.didDrop()) {
      const { competency, onCompetencyDrop } = props;
      if (onCompetencyDrop) {
        onCompetencyDrop(competency, monitor.getDropResult().rateId);
      }
    }
  },
};

function collect(connect, monitor) {
  return {
    connectDragPreview: connect.dragPreview(),
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
    canDrag: monitor.canDrag()
  };
}

class DraggableCompetencyItem extends Component {
  componentDidMount() {
    const { connectDragPreview } = this.props;
    connectDragPreview(getEmptyImage(), { captureDraggingState: true });
  }

  render() {
    const {
      canDrag,
      className,
      competency,
      connectDragSource,
      handleClick,
      isDragging,
      itemClassName,
      nomarginbottom,
      selected,
      withFeatureDiscovery,
    } = this.props;

    const props = {
      canDrag,
      className: itemClassName,
      competency,
      handleClick,
      isDragging,
      nomarginbottom,
      selected,
      variant: 'filled',
      withFeatureDiscovery
    };

    const classNames = cn(className, {
      'competency-item-draggable': true,
      'competency-item-draggable--dragging': isDragging,
    });

    return connectDragSource(
      <div className={classNames}>
        <CompetencyItem {...props} />
      </div>
    );
  }

}

DraggableCompetencyItem.propTypes = {
  canDrag: PropTypes.bool,
  className: PropTypes.string,
  competency: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
  }),
  connectDragPreview: PropTypes.func.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  handleClick: PropTypes.func,
  isDragging: PropTypes.bool,
  itemClassName: PropTypes.string,
  nomarginbottom: PropTypes.bool,
  withFeatureDiscovery: PropTypes.bool,
};

export default DragSource(COMPETENCE, competencySource, collect)(DraggableCompetencyItem);
