// @packages
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { useConfigurations } from 'smu-services/os-member/api/v1/community/preferences/configurations';

// @app
import WidgetCallToAction from 'components/organisms/WidgetCallToAction';
import { formatCTADate } from 'utils/formatCTADate';
import { getPeriodByType } from 'betterme-components/services/GetPeriodByType/actions';
import { selectConfigurations } from 'betterme-components/Authorization/selectors';
import { selectPeriodByType } from 'betterme-components/services/GetPeriodByType/selectors';
import {
  selectAssessmentCollaborators,
  selectAssessmentCollaboratorsLoading,
} from 'betterme-components/services/Assessment/selectors';

// @own
import messages from './messages';

const CallToActionsContainer = ({
  allowsFeedbackForLeaders,
  allowsPotentialAssessment,
  assessmentCollaborators,
  getPeriodByType,
  intl: { formatMessage },
  onClickAction: action,
  periodByType,
  ...rest
}) => {
  const useConfigurationsHook = useConfigurations();
  const oldFFLIsEnabled = useConfigurationsHook.selectEnabledOldFfl();
  const hasAssessmentCollaborators = assessmentCollaborators.length > 0;

  const callToActionsList = periodByType?.map(period => {
    const periodType = (period?.type === 'FEEDBACK_FOR_LEADERS')
      ? period?.type
      : 'POTENTIAL_ASSESSMENT';

    const permissionsByType = {
      FEEDBACK_FOR_LEADERS: allowsFeedbackForLeaders,
      POTENTIAL_ASSESSMENT: allowsPotentialAssessment && hasAssessmentCollaborators,
    };

    const primaryTextByType = {
      FEEDBACK_FOR_LEADERS: formatMessage(messages.FeedbackForLeadersPrimaryText),
      POTENTIAL_ASSESSMENT: formatMessage(messages.PotentialAssessmentPrimaryText),
    };

    const onClickAction = {
      FEEDBACK_FOR_LEADERS: action?.handleFeedbackForLeaders,
      POTENTIAL_ASSESSMENT: action?.handlePotentialAssessment,
    };

    const nameByType = {
      FEEDBACK_FOR_LEADERS: formatMessage(messages.FeedbackForLeadersActionText),
      POTENTIAL_ASSESSMENT: formatMessage(messages.PotentialAssessmentActionText),
    };

    const endDate = formatCTADate(period?.endTime);

    return ({
      date: endDate,
      name: nameByType[periodType],
      onClickAction: onClickAction[periodType],
      primaryText: primaryTextByType[periodType],
      show: oldFFLIsEnabled && permissionsByType[periodType],
      type: period?.type,
    });
  });

  useEffect(() => {
    getPeriodByType({ types: 'FEEDBACK_FOR_LEADERS,RISING_STAR' });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    callToActionsList?.map(period => (
      period?.show && (
      <WidgetCallToAction
        actionText={period?.name}
        key={period?.id}
        onClickAction={period?.onClickAction}
        primaryText={period?.primaryText}
        secondaryText={period?.date}
        type={period?.type}
        {...rest}
      />
    )))
  );
};

CallToActionsContainer.propTypes = {
  allowsFeedbackForLeaders: PropTypes.bool,
  allowsPotentialAssessment: PropTypes.bool,
  analyticsEvent: PropTypes.object,
  assessmentCollaborators: PropTypes.array,
  getPeriodByType: PropTypes.func,
  intl: PropTypes.object,
  onClickAction: PropTypes.object,
  periodByType: PropTypes.array,
};

const mapDispatchToProps = {
  getPeriodByType,
};

const mapStateToProps = (state) => {
  return {
    allowsFeedbackForLeaders: selectConfigurations('allowsFeedbackForLeaders')(state),
    allowsPotentialAssessment: selectConfigurations('allowsPotentialAssessment')(state),
    assessmentCollaborators: selectAssessmentCollaborators(state),
    assessmentCollaboratorsLoading: selectAssessmentCollaboratorsLoading(state),
    periodByType: selectPeriodByType(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(
  injectIntl(CallToActionsContainer),
);
