// @packages
import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

// @own
import './styles.scss';

const GradientLine = ({ reverse }) => {
  return (
    <div className={cn('gradient-line', {
      'gradient-line--reverse': reverse,
    })} />
  );
};

GradientLine.defaultProps = {
  reverse: false,
};

GradientLine.propTypes = {
  reverse: PropTypes.bool,
};

export default GradientLine;
