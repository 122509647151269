// @packages
import get from 'lodash/get';
import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';

export const selectSurveyQuestions = ({ surveyQuestionsAndRates }) => {
  return get(surveyQuestionsAndRates, 'surveyQuestions');
}

export const selectSurveyQuestionsLoading = ({ surveyQuestionsAndRates }) => {
  return get(surveyQuestionsAndRates, 'surveyQuestionsLoading');
}

export const selectSurveyQuestionsError = ({ surveyQuestionsAndRates }) => {
  return get(surveyQuestionsAndRates, 'surveyQuestionsError');
}

export const selectSurveyRates = ({ surveyQuestionsAndRates }) => {
  return get(surveyQuestionsAndRates, 'surveyRates');
}

export const selectSurveyRatesLoading = ({ surveyQuestionsAndRates }) => {
  return get(surveyQuestionsAndRates, 'surveyRatesLoading');
}

export const selectSurveyRatesError = ({ surveyQuestionsAndRates }) => {
  return get(surveyQuestionsAndRates, 'surveyRatesError');
}


export const selectDraftQuestions = ({ surveyQuestionsAndRates }) => {
  return get(surveyQuestionsAndRates, 'ratedQuestions');
}

export const selectSurveyModifiedRates = createSelector(
  selectSurveyRates,
  (surveyRates) => {
    if (!isEmpty(surveyRates)) {
      const modifiedRates = surveyRates?.map(({ name, id }) => {
        return { label: name, value: id };
      });
      return modifiedRates;
    }
  }
);

export const selectRatedQuestions = createSelector(
  selectDraftQuestions,
  (ratedQuestions) => {
    const {
      draftData,
      surveyQuestions,
    } = ratedQuestions;

    if (!isEmpty(draftData)) {
      function createIndexObject(arr) {
        return arr?.reduce((obj, element) => {
          obj[element?.question?.id] = element?.rating?.id;
          return obj;
        }, {});
      }
      const behaviors = draftData?.behaviors ? createIndexObject(draftData?.behaviors) : [];
      const coEvaluatorBehaviors = draftData?.coCreated ? createIndexObject(draftData?.coCreated?.behaviors) : [];
      const questionsWithPreviousRate = surveyQuestions?.map((element) => ({
        ...element,
        previousRate: behaviors[element?.id] || null,
      }));
      const ratedQuestions = questionsWithPreviousRate?.map((element) => ({
        ...element,
        ratedByCoEvaluator: coEvaluatorBehaviors[element?.id] || null,
      }));
      return ratedQuestions;
    } else {
      return surveyQuestions;
    }
  }
);
