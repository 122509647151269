import { defineMessages } from 'react-intl';

export default defineMessages({
  SendFeedbackForLeadersOf: {
    id: 'Betterme.SendFeedbackForLeaders.Of',
    defaultMessage: 'Of',
  },
  SendFeedbackForLeadersQuestion: {
    id: 'Betterme.SendFeedbackForLeaders.Question',
    defaultMessage: 'Questions',
  },
  SendFeedbackForLeadersPlaceholderOpen: {
    id: 'Betterme.SendFeedbackForLeaders.PlaceholderOpen',
    defaultMessage: 'Mandatory',
  },
  SendFeedbackForLeadersPlaceholderOpenOptional: {
    id: 'Betterme.SendFeedbackForLeaders.PlaceholderOpenOptional',
    defaultMessage: 'Optional',
  },
  SendFeedbackForLeadersCompetencies: {
    id: 'Betterme.SendFeedbackForLeaders.Competencies',
    defaultMessage: 'Competencies',
  },
  SendFeedbackForLeadersInsights: {
    id: 'Betterme.SendFeedbackForLeaders.Insights',
    defaultMessage: 'Insights',
  },
  SendFeedbackForLeadersSendFeedback: {
    id: 'Betterme.SendFeedbackForLeaders.SendFeedback',
    defaultMessage: 'Send Feedback',
  },
  SendFeedbackForLeadersSendFeedbackSuccessTitle: {
    id: 'Betterme.SendFeedbackForLeaders.SendFeedbackSuccessTitle',
    defaultMessage: 'Well done!',
  },
  SendFeedbackForLeadersSendFeedbackSuccessDescription: {
    id: 'Betterme.SendFeedbackForLeaders.SendFeedbackSuccessDescription',
    defaultMessage: 'Your leader feedback for **{leaderName}** has been created successfully!',
  },
  SendFeedbackForLeadersBeKindTitle: {
    id: 'Betterme.SendFeedbackForLeaders.BeKind.Title',
    defaultMessage: 'BE KIND',
  },
  SendFeedbackForLeadersBeKindText: {
    id: 'Betterme.SendFeedbackForLeaders.BeKind.Description',
    defaultMessage: 'It’s important to provide constructive feedback based on shared experiences to bring together different points of view. Remember always BeKind.',
  },
  SendFeedbackForLeadersBeKindButton: {
    id: 'Betterme.SendFeedbackForLeaders.BeKind.Button',
    defaultMessage: 'GOT IT',
  },
  SendFeedbackForLeadersInfoMessage: {
    id: 'Betterme.SendFeedbackForLeaders.InfoMessage',
    defaultMessage: 'Support leadership development by providing honest and constructive feedback. Consider exploring other ratings besides “Strongly Disagree/Totally Agree”.',
  },
  SendFeedbackForLeadersAlertMessage: {
    id: 'Betterme.SendFeedbackForLeaders.AlertMessage',
    defaultMessage: 'Help your leader find areas where they can grow and develop. Review at least {number} statements where you consecutively responded {valueName} to provide a more balanced feedback. ',
  },
});
