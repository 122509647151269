import get from 'lodash/get';

const reducerDomain = 'potentialAssessmentResults';

export const selectPotentialType = state =>
  get(state, [reducerDomain, 'potentialType']);

export const selectPotentialTypeLoading = state =>
  get(state, [reducerDomain, 'potentialTypeLoading']);

export const selectPotentialAssessmentResults = state => {
  const potentialAssessmentResults = { ...get(state, [reducerDomain, 'potentialAssessmentResults']) };
  if (potentialAssessmentResults.selectedScore) {
    const interactionKeyToName = {
      'fs.result1': 'Foundational',
      'fs.result2': 'Intermediate',
      'fs.result3': 'Advanced',
      'fs.result4': 'High',
    };
    potentialAssessmentResults.selectedScoreRanges = potentialAssessmentResults.scoreRanges
      ?.filter(range => range.interactionKey?.startsWith("fs.result"))
      ?.map(range => {
        let name = interactionKeyToName[range.interactionKey] || '';
        let id = range.interactionKey?.replace(/\D/g, "");
        return {
          min: range.min,
          max: range.max,
          id: id,
          name: name
        };
      })
      ?.sort((a, b) => a.min - b.min);
  }

  return potentialAssessmentResults;
}

export const selectPotentialAssessmentResultsLoading = state =>
  get(state, [reducerDomain, 'potentialAssessmentResultsLoading']);
