import { defineMessages } from 'react-intl';

export default defineMessages({
  UserAccount: {
    id: 'Betterme.Messages.User.Account',
    defaultMessage: 'Account',
  },
  UserArea: {
    id: 'Betterme.Messages.User.Area',
    defaultMessage: 'Area',
  },
  UserEndAssignment: {
    id: 'Betterme.Messages.User.EndAssignment',
    defaultMessage: 'End Assignment',
  },
  UserAssignedTo: {
    id: 'Betterme.Messages.User.AssignedTo',
    defaultMessage: 'Assigned to',
  },
  UserLeader: {
    id: 'Betterme.Messages.User.Leader',
    defaultMessage: 'Leader',
  },
  UserLeaders: {
    id: 'Betterme.Messages.User.Leaders',
    defaultMessage: 'Leaders',
  },
  UserMyProfile: {
    id: 'Betterme.Messages.User.MyProfile',
    defaultMessage: 'My Profile',
  },
  UserLeaderMyDashboard: {
    id: 'Betterme.Messages.User.LeaderMyDashboard',
    defaultMessage: 'Personal Dashboard',
  },
  UserLeaderMyFeedback: {
    id: 'Betterme.Messages.User.LeaderMyFeedback',
    defaultMessage: 'My Feedback',
  },
  UserLeaderMyGoals: {
    id: 'Betterme.Messages.User.LeaderMyGoals',
    defaultMessage: 'My Goals',
  },
  UserLeaderMyEvaluations: {
    id: 'Betterme.Messages.User.LeaderMyEvaluations',
    defaultMessage: 'My Evaluations',
  },
  UserOffice: {
    id: 'Betterme.Messages.User.Office',
    defaultMessage: 'Office',
  },
  UserProject: {
    id: 'Betterme.Messages.User.Project',
    defaultMessage: 'Project',
  },
  UserTeam: {
    id: 'Betterme.Messages.User.Team',
    defaultMessage: 'Team',
  },
  UserMyTeam: {
    id: 'Betterme.Messages.User.MyTeam',
    defaultMessage: 'My Team',
  },
  UserEmail: {
    id: 'Betterme.Messages.User.Email',
    defaultMessage: 'Email',
  },
  UserLeaderAddToMyTeam: {
    id: 'Betterme.Messages.User.LeaderAddToMyTeam',
    defaultMessage: 'Add to my team',
  },
  UserPersonalInformation: {
    id: 'Betterme.Messages.User.PersonalInformation',
    defaultMessage: 'Personal information',
  },
  UserMoreInfo: {
    id: 'Betterme.Messages.User.MoreInfo',
    defaultMessage: 'More Info',
  },
  UserAging: {
    id: 'Betterme.Messages.User.Aging',
    defaultMessage: 'Since',
  },
  UserPosition: {
    id: 'Betterme.Messages.User.Position',
    defaultMessage: 'Position',
  },
  UserSeniority: {
    id: 'Betterme.Messages.User.Seniority',
    defaultMessage: 'Seniority',
  },
  UserProfile: {
    id: 'Betterme.Messages.User.Profile',
    defaultMessage: 'Profile',
  },
  CantBeLeader: {
    id: 'Betterme.Messages.User.CantBeLeader',
    defaultMessage: 'I can\'t be the leader of {username} because',
  },
  UserTDC: {
    id: 'Betterme.Messages.User.TDC',
    defaultMessage: 'TDC',
  },
  UserStudio: {
    id: 'Betterme.Messages.User.Studio',
    defaultMessage: 'Studio',
  },
  UserJob: {
    id: 'Betterme.Messages.User.Job',
    defaultMessage: 'Job',
  },
  UserCustomfield1: {
    id: 'Betterme.Messages.User.CustomField1',
    defaultMessage: 'Studio',
  },
  UserCustomfield4: {
    id: 'Betterme.Messages.User.CustomField4',
    defaultMessage: 'Gender',
  },
  UserCustomfield6: {
    id: 'Betterme.Messages.User.CustomField6',
    defaultMessage: 'TDC',
  },
  UserCustomfield9: {
    id: 'Betterme.Messages.User.CustomField9',
    defaultMessage: 'Pod',
  },
  UserCustomfield11: {
    id: 'Betterme.Messages.User.CustomField11',
    defaultMessage: 'Region',
  }
});
