// @app
import { handleServerError } from 'betterme-components/api';
import { dataServiceApiInstance, apiInstance } from 'betterme-components/utils/requestHelper';

export function getPotentialType(userId) {
    return apiInstance
        .get(`api/survey/type/${userId}/tier`)
        .then(({ data }) => data.result)
        .catch(handleServerError);
}

export function getPotentialAssessmentResults(params) {
    return dataServiceApiInstance
        .get('v1/bme/api/feedbacks/feedbackForLeaders/results?', { params })
        .then(({ data }) => data.result)
        .catch(handleServerError);
}
