import { get } from 'lodash';
import { createSelector } from 'reselect';

const reducerDomain = 'teamPromotions';

const selectPromotionRequestsResult = state =>
  get(state, [reducerDomain, 'promotionsRequests']);

export const selectPromotionsRequests = createSelector(
  selectPromotionRequestsResult,
  promotionsRequests => (
    promotionsRequests.map(({
      promotion,
      user,
      ...rest
    }) => ({
        promotionFor: user,
        promotion,
        promotionStatus: promotion ? promotion.status : 'NOT_REQUESTED',
        ...rest,
      })
    )
  )
);

export const selectLoadingPromotionsRequests = state =>
  get(state, [reducerDomain, 'loadingPromotionsRequests']);

export const selectPagination = state =>
  get(state, [reducerDomain, 'page']);
