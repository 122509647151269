// @packages
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { get, trimStart } from 'lodash';
import moment from 'moment';
import ButtonMenu from 'smu-ui-components/ButtonMenu';
import Dropdown from 'smu-ui-components/Dropdown';
import Icon from 'smu-ui-components/Icon';
import Input from 'smu-ui-components/Input';
import Paper from 'smu-ui-components/Paper';
import Segment from 'smu-ui-components/Segment';
import UserCombobox from 'smu-ui-components/UserCombobox';
import LottieSpinner from 'smu-ui-components/LottieSpinner';
import * as actionsRootModal from 'smu-app-components/RootModal/actions';
import GenericEmptyState from 'smu-ui-components/GenericEmptyState';

// @app
import {
  actionsMessages,
  goalsMessages,
  placeholdersMessages,
  promotionsMessages,
} from 'common/messages';
import PeriodDateFilter from 'common/PeriodDateFilter';
import {
  selectAllCollaborators,
  selectCommunityId,
  selectConfigurations,
  selectCustomScoreDefinition,
  selectEvaluationPeriod,
  selectUser,
} from '../Authorization/selectors';
import {
  selectLoadingTeamEvaluations,
  selectTeamEvaluations,
} from '../Evaluations/selectors';
import { getTeamEvaluationDetailsLocation } from '../routes/urls';
import TeamEvaluationsHistory from '../Evaluations/TeamEvaluationsHistory';
import { setTeamGoalFilters } from '../Goals/actions';
import {
  cleanTeamEvaluationState,
  getTeamAllEvaluations,
} from '../Evaluations/actions';
import { TEAM_EVALUATIONS_HISTORY } from '../constants/modalTypes';
import { INITIAL_FILTERS } from '../Goals/constants';
import Main from '../Main';

// @own
import * as actions from './actions';
import { selectMyTeamFilters, selectMyTeamLoaded } from './selectors';
import { trackDateFilterCalendar, trackDateFilterDropdown } from '../Team/analytics';
import messages from './messages';
import {
  ALL,
  CURRENT_PERIOD,
} from './constants';
import './styles.scss';
import {
  APPLIED,
  ASSIGN_MANAGER,
  LEADER_REVIEW,
  MANAGER_REVIEW,
  NOT_REQUESTED,
  REQUEST,
  REVIEW,
  TALENT_REVIEW
} from '../constants/promotions';

class MyTeam extends Component {
  constructor(props) {
    super(props);

    this.handleButtonMenuClick = this.handleButtonMenuClick.bind(this);
    this.handlerChangeFilterText = this.handlerChangeFilterText.bind(this);
    this.handleHistoryTeamClick = this.handleHistoryTeamClick.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
  }

  componentDidMount() {
    const {
      defaultPeriod,
      setTeamGoalFilters,
    } = this.props;

    this.getInitialFilterDate();

    if (defaultPeriod) {
      const { id: filterPeriodId } = defaultPeriod;

      setTeamGoalFilters({ filterPeriodId });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.getInitialFilterDate();
    };
  }

  componentWillUnmount() {
    const { setTeamGoalFilters, resetMyTeamFilters } = this.props;
    setTeamGoalFilters(INITIAL_FILTERS);
    resetMyTeamFilters();
  }

  getButtonMenuOptions() {
    const { intl: { formatMessage } } = this.props;
    return [
      {
        text: formatMessage(placeholdersMessages.PlaceholdersCsvDoc),
        value: 'csv',
      },
      {
        text: formatMessage(placeholdersMessages.PlaceholdersXlsDoc),
        value: 'xls',
      },
      {
        text: formatMessage(placeholdersMessages.PlaceholdersXlsxDoc),
        value: 'xlsx',
      },
    ];
  }

  getMainProps() {
    const {
      RightColumn,
      Sidenav,
      defaultPeriod,
      mainContent,
      session,
    } = this.props;
    const goalsCurrentPeriod = defaultPeriod || {};

    return {
      Main: mainContent,
      mainProps: { goalsCurrentPeriod },
      RightColumn,
      Sidenav,
      session,
    };
  }

  getInitialFilterDate() {
    const { defaultPeriod, location: { pathname }, setMyTeamFilters } = this.props;
    let initialFilters;

    if (defaultPeriod) {
      const showPromotionsFilters = pathname === '/myteam/promotions';
      const showEvaluationsFilters = pathname === '/myteam/evaluations';
      const { endTime, startTime } = defaultPeriod;
      if (showPromotionsFilters || showEvaluationsFilters) {
        initialFilters = {
          endDate: null,
          startDate: null,
        };
      } else {
        initialFilters = {
          endDate: moment.utc(endTime).endOf('day'),
          startDate: moment.utc(startTime).startOf('day'),
        };
      }
    } else {
      initialFilters = {
        endDate: moment(),
        startDate: moment().subtract(6, 'months'),
      };
    }
    setMyTeamFilters(initialFilters);
  }

  handleButtonMenuClick(value) {
    const {
      getPromotionsReport,
      myTeamFilters: { endDate, startDate },
    } = this.props;
    const creationTimeFrom = startDate?.valueOf();
    const creationTimeTo = endDate?.valueOf();

    getPromotionsReport(
      value,
      creationTimeFrom,
      creationTimeTo
    );
  }

  handlerChangeFilterText(event) {
    const { setMyTeamFilters } = this.props;
    const { value } = event.target;
    const cleanValue = trimStart(value);
    if (cleanValue.length === 0 || cleanValue.length > 2) {
      setMyTeamFilters({ title: cleanValue });
    }
  }

  handleItemClick(evaluation) {
    const { gotoTeamEvaluation } = this.props;
    gotoTeamEvaluation(evaluation.id);
  }

  handleHistoryTeamClick(score) {
    const {
      cleanTeamEvaluationState,
      getTeamAllEvaluations,
      intl: { formatMessage },
      myTeamFilters: { endDate, startDate },
      openModal,
      user,
    } = this.props;

    const {
      id: scoreId,
      name: scoreName,
    } = score;

    const from = startDate ? startDate.valueOf() : undefined;
    const to = endDate ? endDate.valueOf() : undefined;

    getTeamAllEvaluations({
      creationTimeFrom: from,
      creationTimeTo: to,
      leaderId: user.id,
      scoreId,
    });

    openModal({
      modalType: TEAM_EVALUATIONS_HISTORY,
      modalProps: {
        onClose: cleanTeamEvaluationState,
        onItemClick: this.handleItemClick,
        title: formatMessage(messages.MyTeamEvaluationsListTitle, { scoreName }),
        isByScore: true,
      }
    });
  }

  render() {
    const {
      allCollaborators,
      allowsTeamEvaluationsGoalScore,
      children,
      collaboratorsIds,
      customScoreDefinition,
      defaultPeriod,
      intl: { formatMessage },
      isGoalScoreContinous,
      loaded,
      loadingTeamEvaluations,
      location: { pathname },
      myTeamFilters,
      session: { user },
      setMyTeamFilters,
      teamEvaluations,
    } = this.props;

    const allowsCustomScoreDefinition = customScoreDefinition && customScoreDefinition.isEnabled;
    const showGoalsFilters = pathname === '/myteam/goals';
    const showFeedbackFilters = pathname === '/myteam/feedbacks';
    const showPromotionsFilters = pathname === '/myteam/promotions';
    const showDashboardFilters = !(showGoalsFilters || showFeedbackFilters || showPromotionsFilters);
    const defaultPeriodDateFilter = showPromotionsFilters ? ALL : CURRENT_PERIOD;

    const {
      assignee: assigneeValue,
      endDate: endDateValue,
      startDate: startDateValue,
      status: statusValue,
      title: statusTitle,
      requestStatus: requestStatusValue,
      actions: actionsValue,
    } = myTeamFilters;

    const assigneeId = get(assigneeValue, 'id', 0);

    const optionsStatus = [
      {
        value: 'ALL',
        label: formatMessage(goalsMessages.GoalsAll),
      },
      {
        value: 'CLOSED',
        label: formatMessage(goalsMessages.GoalsClosed),
      },
      {
        value: 'OPENED',
        label: formatMessage(goalsMessages.GoalsOpened),
      },
      {
        value: 'PENDING_APPROVAL',
        label: formatMessage(goalsMessages.GoalsPending),
      },
    ];

    const nodeDateFilters = (
      <PeriodDateFilter
        className="my-team__filters-group my-team__filters-date my-team__filters-section-item"
        datePickerTexts={{
          endDatePlaceholder: formatMessage(placeholdersMessages.PlaceholdersDate),
          startDatePlaceholder: formatMessage(placeholdersMessages.PlaceholdersDate),
        }}
        defaultPeriodDateFilter={defaultPeriodDateFilter}
        dropdownPlaceholder={formatMessage(placeholdersMessages.PlaceholdersSelectPeriod)}
        endDate={endDateValue}
        evaluationPeriod={defaultPeriod}
        onDateChange={({ startDate, endDate }) =>
          setMyTeamFilters({ startDate, endDate })
        }
        periodLabels={{
          all: formatMessage(placeholdersMessages.PlaceholdersAll),
          current: formatMessage(placeholdersMessages.PlaceholdersCurrentPeriod),
          six: formatMessage(placeholdersMessages.PlaceholdersSixMonths),
          three: formatMessage(placeholdersMessages.PlaceholdersThreeMonths),
        }}
        startDate={startDateValue}
        trackDateFilterCalendar={trackDateFilterCalendar}
        trackDateFilterDropdown={trackDateFilterDropdown}
        noStyles
      />
    );

    const promotionsRequestStatus = [
      {
        value: " ",
        label: formatMessage(promotionsMessages.PromotionsStatusAll),
      },
      {
        value: LEADER_REVIEW,
        label: formatMessage(promotionsMessages.PromotionsRequestStatusLeaderReview)
      },
      {
        value: ASSIGN_MANAGER,
        label: formatMessage(promotionsMessages.PromotionsRequestStatusAssignManager)
      },
      {
        value: MANAGER_REVIEW,
        label: formatMessage(promotionsMessages.PromotionsRequestStatusManagerReview)
      },
      {
        value: TALENT_REVIEW,
        label: formatMessage(promotionsMessages.PromotionsRequestStatusTalentReview)
      },
      {
        value: APPLIED,
        label: formatMessage(promotionsMessages.PromotionsRequestStatusApplied)
      },
      {
        value: NOT_REQUESTED,
        label: formatMessage(promotionsMessages.PromotionsRequestStatusNotRequested)
      }
    ];

    const promotionsActions = [
      {
        value: " ",
        label: formatMessage(promotionsMessages.PromotionsStatusAll),
      },
      {
        value: REQUEST,
        label: formatMessage(promotionsMessages.PromotionsActionsRequest)
      },
      {
        value: REVIEW,
        label: formatMessage(promotionsMessages.PromotionsActionsReview)
      },
      {
        value: ASSIGN_MANAGER,
        label: formatMessage(promotionsMessages.PromotionsActionsAssignManager)
      }
    ];

    const nodePromotionsFilters = (
      <>
        <Dropdown
          className="my-team__filters-section-item my-team__filters-section-status text-black	"
          onChange={(value) => setMyTeamFilters({ requestStatus: value })}
          options={promotionsRequestStatus}
          padding
          placeholder={formatMessage(placeholdersMessages.PlaceholderPromotionsRequestStatus)}
          value={requestStatusValue}
        />
        <Dropdown
          className="my-team__filters-section-item my-team__filters-section-status"
          onChange={(value) => setMyTeamFilters({ actions: value })}
          options={promotionsActions}
          padding
          placeholder={formatMessage(placeholdersMessages.PlaceholdersActions)}
          value={actionsValue}
        />
      </>
    );

    if (!loaded) {
      return (
        <LottieSpinner
          loadingType="betterme"
          width={60}
          height={60}
        />
      );
    }
    const { hasCollaborators } = user;

    return (
      hasCollaborators ? (
        <div className="my-team">
          {!children ? (
            <Main {...this.getMainProps()} />
          ) : (
            <Segment
              className="my-team__content"
              loadingType="betterme"
            >
              {
                <Paper className="items-center flex justify-end mb-2 py-2 px-3">
                  {showDashboardFilters && (
                    <div className="my-team__filters-group my-team__filters-section">
                      {nodeDateFilters}
                    </div>
                  )}
                  {showGoalsFilters && (
                    <div className="my-team__filters-group my-team__filters-section">
                      <div className="my-team__filters-section-item my-team__filters-section-text">
                        <Icon icon="search" size="small" />
                        <Input
                          className="suite-filters__text-input"
                          onChange={this.handlerChangeFilterText}
                          placeholder={formatMessage(placeholdersMessages.PlaceholdersSearchByGoalTitle)}
                          defaultValue={statusTitle}
                        />
                      </div>
                      <Dropdown
                        className="my-team__filters-section-item my-team__filters-section-status"
                        onChange={(value) => setMyTeamFilters({ status: value })}
                        options={optionsStatus}
                        padding
                        placeholder={formatMessage(placeholdersMessages.PlaceholdersSelectStatus)}
                        value={statusValue}
                      />
                      <UserCombobox
                        className="my-team__filters-section-item my-team__filters-section-user"
                        onChange={(id, dataUser) => setMyTeamFilters({ assignee: dataUser })}
                        placeholder={formatMessage(placeholdersMessages.PlaceholdersSelectAssigned)}
                        type="small"
                        userList={allCollaborators}
                        value={assigneeId}
                      />
                      {nodeDateFilters}
                    </div>
                  )}
                  {showFeedbackFilters && (
                    <div className="my-team__filters-group my-team__filters-section">
                      <UserCombobox
                        className="my-team__filters-section-item my-team__filters-section-user"
                        onChange={(id, dataUser) => setMyTeamFilters({ assignee: dataUser })}
                        placeholder={formatMessage(placeholdersMessages.PlaceholdersSelectAssigned)}
                        type="small"
                        userList={allCollaborators}
                        value={assigneeId}
                      />
                      {nodeDateFilters}
                    </div>
                  )}
                  {showPromotionsFilters && (
                    <div className="my-team__filters-section">
                      {nodePromotionsFilters}
                      <ButtonMenu
                        buttonIcon="download"
                        buttonText={formatMessage(placeholdersMessages.PlaceholdersDownloadReport)}
                        className="my-team__filters-section-report"
                        onClickItem={this.handleButtonMenuClick}
                        options={this.getButtonMenuOptions()}
                      />
                    </div>
                  )}
                </Paper>
              }
              {React.cloneElement(children, {
                collaboratorsIds,
                myTeamFilters,
                onHistoryTeamClick: this.handleHistoryTeamClick,
                user,
              })}
            </Segment>
          )}
          <TeamEvaluationsHistory
            modalProps={{
              isGoalScoreContinous,
              showGlobalScore: allowsCustomScoreDefinition,
              showGoalsFinalScore: allowsTeamEvaluationsGoalScore,
              loading: loadingTeamEvaluations,
              teamEvaluations,
            }}
          />
        </div>
      ) : (
        <GenericEmptyState
          className="px-5 py-20  bg-white h-[calc(100vh-220px)]"
          description={formatMessage(messages.MyTeamNoPermissionsSub)}
          image="padlock"
          limitTextWidth={false}
          size="large"
          title={formatMessage(messages.MyTeamNoPermissions)}
        />
      )
    );
  }
}

const PeriodPropType = PropTypes.shape({
  endTime: PropTypes.number,
  startTime: PropTypes.number,
  id: PropTypes.number,
}).isRequired;

MyTeam.propTypes = {
  allCollaborators: PropTypes.array,
  allowsEvaluations: PropTypes.bool,
  allowsGoals: PropTypes.bool,
  allowsTeamEvaluationsGoalScore: PropTypes.bool,
  children: PropTypes.node,
  communityId: PropTypes.number.isRequired,
  customScoreDefinition: PropTypes.object,
  defaultPeriod: PeriodPropType,
  getPromotionsReport: PropTypes.func.isRequired,
  isGoalScoreContinous: PropTypes.bool,
  loaded: PropTypes.bool,
  loadingTeamEvaluations: PropTypes.bool,
  myTeamFilters: PropTypes.object,
  resetMyTeamFilters: PropTypes.func.isRequired,
  session: PropTypes.object.isRequired,
  setMyTeamFilters: PropTypes.func.isRequired,
  setTeamGoalFilters: PropTypes.func.isRequired,
  teamEvaluations: PropTypes.array,
};

const mapStateToProps = (state) => ({
  allCollaborators: selectAllCollaborators(state),
  allowsEvaluations: selectConfigurations('allowsEvaluations')(state),
  allowsGoals: selectConfigurations('allowsGoals')(state),
  allowsTeamEvaluationsGoalScore: selectConfigurations('allowsTeamEvaluationsGoalScore')(state),
  communityId: selectCommunityId(state),
  customScoreDefinition: selectCustomScoreDefinition(state),
  defaultPeriod: selectEvaluationPeriod(state),
  isGoalScoreContinous: selectConfigurations('continuousGoalScores')(state),
  loaded: selectMyTeamLoaded(state),
  loadingTeamEvaluations: selectLoadingTeamEvaluations(state),
  myTeamFilters: selectMyTeamFilters(state),
  teamEvaluations: selectTeamEvaluations(state),
  user: selectUser(state),
});

const gotoTeamEvaluation = (evaluationId) => push(getTeamEvaluationDetailsLocation(evaluationId));
const mapDispatchToProps = {
  ...actions,
  ...actionsRootModal,
  cleanTeamEvaluationState,
  getTeamAllEvaluations,
  gotoTeamEvaluation,
  setTeamGoalFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MyTeam));