// @own
import { buildClosePath } from '../helpers';

const oneOnOneRequest = ({ onClose, open, query }) => {
  open({
    defaultValues: { toUserUid: query?.uid },
    onClose: () => onClose(buildClosePath(['uid'])),
  });
};

export default oneOnOneRequest;
