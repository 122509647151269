// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const ContentCardBody = ({ children, className }) => {

  return (
    <div className={cn('content-card__body', className)}>
      {children}
    </div>
  );
};

ContentCardBody.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default ContentCardBody;
