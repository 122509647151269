// @packages
import React from 'react';
import Button from 'smu-ui-components/ButtonV2';
import Icon from 'smu-ui-components/IconV2';
import Popup from 'smu-ui-components/Popup';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @own
import './styles.scss';

const AddLeaderBox = ({
  className,
  disabled,
  onClick,
  popupText,
  showIcon,
  text,
  withPopup,
}) => {
  const addLeaderNode = (
    <Button
      className="add-leader-box__button"
      endIcon={
        (showIcon ?? true) && (
          <Icon
            className="add-leader-box__button--icon"
            icon="add-circular"
          />
        )
      }
      onClick={onClick}
      variant="link"
    >
      {text}
    </Button>
  );

  return (
    <div className={cn('add-leader-box', {
      'add-leader-box--disabled': disabled,
    },
      className,
    )}>
      {withPopup ? (
        <Popup
          className="promotions-table__tooltip"
          eventsEnabled
          position="center bottom"
          size="small"
          suiteInverted
          trigger={addLeaderNode}
        >
          {popupText}
        </Popup>
      ) : (
        addLeaderNode
      )}
    </div>
  )
};

AddLeaderBox.defaultProps = {
  onClick: () => {},
};

AddLeaderBox.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  popupText: PropTypes.string,
  text: PropTypes.string.isRequired,
  withPopup: PropTypes.bool,
};

export default AddLeaderBox;
