// @packages
import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import UserAvatarList from 'smu-ui-components/UserAvatarList';

// @own
import './styles.scss';

const CompetencyRatingListItem = ({
  className,
  count,
  name,
  onUserAvatarsClick,
  total,
  users,
}) => {
  const percentage = Math.round(count * 100 / total);
  return (
    <li className={cn('competency-rating-list-item', className)}>
      <div className="competency-rating-list-item__name">{name}</div>
      <div className="competency-rating-list-item__stats">
        <div className="competency-rating-list-item__percentage">{`${percentage}%`}</div>
        <div className="competency-rating-list-item__count">{`( ${count}/${total} )`}</div>
      </div>
      <div className="competency-rating-list-item__user-avatar-list-wrapper">
        <UserAvatarList
          className="competency-rating-list-item__user-avatar-list"
          onClick={onUserAvatarsClick}
          users={users}
          maxDisplay={4}
          size={36}
        />
      </div>
    </li>
  );
};

CompetencyRatingListItem.propTypes = {
  count: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  users: PropTypes.array.isRequired,
};

export default CompetencyRatingListItem;
