// @packages
import flatten from 'flat';

// @own
import {
  CHANGE_LOCALE,
  REQUEST_LOCALE,
  REQUEST_LOCALE_FAILED,
  REQUEST_LOCALE_SUCCESS,
} from './actionTypes';

const initialState = {
  locale: '',
  messages: {},
  requesting: true,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case CHANGE_LOCALE:
      return {
        ...state,
        locale: payload.locale,
      };
    case REQUEST_LOCALE:
      return {
        requesting: true,
      };
    case REQUEST_LOCALE_SUCCESS:
      return {
        ...state,
        locale: payload.locale,
        messages: flatten(payload.messages),
        requesting: false,
      };
    case REQUEST_LOCALE_FAILED:
      return {
        requesting: false,
      };
    default:
      return state;
  }
}
