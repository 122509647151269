// @packages
import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

// @own
import './styles.scss';

const FeedbackReceivedTabs = ({
  active,
  className,
  onTabClick,
  tabs,
}) => {
  return (
    <ul className={cn('feedback-received-tabs', className)}>
      {tabs.map(tab => {
        const activeTab = (active === tab.value);
        const selectedStyles = {
          'borderBottom': activeTab ? `4px solid ${tab.color}` : undefined,
          'color': activeTab ? tab.color : undefined,
        };
        return (
          <li
            className={cn('feedback-received-tabs__tab', {
              'feedback-received-tab--selected': (active === tab.value)
            })}
            key={tab.value}
            onClick={(event) => onTabClick(tab.value, event)}
            style={selectedStyles}
          >
            {tab.displayValue}
          </li>
        );
      })}
    </ul>
  );
};

FeedbackReceivedTabs.propTypes = {
  active: PropTypes.number,
  className: PropTypes.string,
  onTabClick: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.string.isRequired,
    displayValue: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  })).isRequired,
};

export default FeedbackReceivedTabs;
