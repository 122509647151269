// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { openModal } from 'smu-app-components/RootModal/actions';

// @app
import SkillSelectorModal from 'betterme-components/SkillSelectorModal';
import SkillSelectorTrigger from 'components/atoms/SkillSelectorTrigger';
import { SKILL_SELECTOR_MODAL } from 'betterme-components/constants/modalTypes';

const SkillSelector = ({
  className,
  initialSkills,
  label,
  onAdd,
  onModalClose,
  onSkillClick,
  openModal,
  isEvaluation,
}) => {
  const openSkillModal = () => openModal({ modalType: SKILL_SELECTOR_MODAL });

  return (
    <>
      <SkillSelectorTrigger
        className={className}
        label={label}
        onClick={openSkillModal}
      />
      <SkillSelectorModal
        modalProps={{
          initialSkills,
          onAdd,
          onModalClose,
          onSkillClick,
          isEvaluation,
        }}
      />
    </>
  );
};

const mapDispatchToProps = { openModal };

SkillSelector.propTypes = {
  className: PropTypes.string,
  initialSkills: PropTypes.array,
  label: PropTypes.string,
  onAdd: PropTypes.func,
  onModalClose: PropTypes.func,
  onSkillClick: PropTypes.func,
  openModal: PropTypes.func,
  isEvaluation: PropTypes.bool,
};

export default connect(null, mapDispatchToProps)(SkillSelector);
