// @packages
import React from 'react';
import AvatarMedia from 'smu-ui-components/AvatarMedia';
import Button from 'smu-ui-components/ButtonV2';
import Link from 'smu-ui-components/Link';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

// @app
import Title from 'components/atoms/Title';
import { trackLeadershipRequestsClick } from 'betterme-components/services/LeadershipRequest/analytics';

// @own
import './styles.scss';
import UsersListInfo from '../UsersListInfo';
import { MIN_LENGTH, SIZE, SUBSIZE, USER_LENGTH } from '../constants';

const CollaboratorsList = ({
  collaboratorsAsMainAndSecondaryLeader,
  collaboratorsAsMainLeader,
  collaboratorsAsSecondaryLeaders,
  hasCollaborators,
  linkTo,
  messages,
  showLeadershipButton,
}) => (
  <div className="team-collaborators-list__collaborators">
    <Title size={SIZE}>
      {messages.collaboratorsListTitle}
    </Title>
    {hasCollaborators ? (
      <span className="team-collaborators-list__container">
        {collaboratorsAsMainAndSecondaryLeader.length > MIN_LENGTH && (
          <div className="team-collaborators-list__main-leader">
            <Title className="text-raven" size={SUBSIZE}>
              {messages.asMainAndSecondaryLeaderTitle}
            </Title>
            <ul className="team-collaborators-list__main-leader-list">
              {(collaboratorsAsMainAndSecondaryLeader?.length > USER_LENGTH) ? (
                collaboratorsAsMainAndSecondaryLeader.map((collaborator, index) => (
                  <UsersListInfo
                    key={collaborator.id}
                    position={collaboratorsAsMainAndSecondaryLeader.length - index}
                    {...collaborator}
                  />
                ))
              ) : (
                <AvatarMedia
                  className="categorized-leaders-list__leader-avatar"
                  {...collaboratorsAsMainAndSecondaryLeader[0]}
                />
              )}
            </ul>
          </div>
        )}
        {(collaboratorsAsMainLeader.length > MIN_LENGTH) && (
          <div className="team-collaborators-list__main-leader">
            <Title className="text-raven" size={SUBSIZE}>
              {messages.asMainLeaderTitle}
            </Title>
            <ul className="team-collaborators-list__main-leader-list">
              {(collaboratorsAsMainLeader?.length > USER_LENGTH) ? (
                collaboratorsAsMainLeader.map((collaborator, index) => (
                  <UsersListInfo
                    key={collaborator.id}
                    position={collaboratorsAsMainLeader.length - index}
                    {...collaborator}
                  />
                ))
              ) : (
                <AvatarMedia
                  className="categorized-leaders-list__leader-avatar"
                  {...collaboratorsAsMainLeader[0]}
                />
              )}
            </ul>
          </div>
        )}
        {(collaboratorsAsSecondaryLeaders.length > MIN_LENGTH) && (
          <div className="team-collaborators-list__secondary-leader">
            <Title className="text-raven" size={SUBSIZE}>
              {messages.asSecondaryLeaderTitle}
            </Title>
            <ul className="team-collaborators-list__secondary-leader-list">
              {(collaboratorsAsSecondaryLeaders?.length > USER_LENGTH) ? (
                collaboratorsAsSecondaryLeaders.map((collaborator, index) => (
                  <UsersListInfo
                    key={collaborator.id}
                    position={collaboratorsAsSecondaryLeaders.length - index}
                    {...collaborator}
                  />
                ))
              ) : (
                <AvatarMedia
                  className="categorized-leaders-list__leader-avatar"
                  {...collaboratorsAsSecondaryLeaders[0]}
                />
              )}
            </ul>
          </div>
        )}
      </span>
    ) : (
      <div className="team-collaborators-list__empty-state">
        {showLeadershipButton
          ? messages.emptyStateText
          : messages.emptyStateLeadersView
        }
      </div>
    )}
    {showLeadershipButton && (
      <Link to={linkTo}>
        <Button
          className="team-collaborators-list__button"
          onClick={trackLeadershipRequestsClick}
          size="medium"
          variant="outline"
        >
          {messages.leadersListLeadershipRequests}
        </Button>
      </Link>
    )}
  </div>
);

CollaboratorsList.defaultProps = {
  collaboratorsAsMainLeader: [],
  collaboratorsAsSecondaryLeaders: [],
};

CollaboratorsList.propTypes = {
  asMainLeaderTitle: PropTypes.string,
  asSecondaryLeaderTitle: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  collaboratorsAsMainLeader: PropTypes.array.isRequired,
  collaboratorsAsSecondaryLeaders: PropTypes.array.isRequired,
  collaboratorsListTitle: PropTypes.string,
  emptyStateLeadersView: PropTypes.string.isRequired,
  emptyStateText: PropTypes.string.isRequired,
  hasCollaborators: PropTypes.bool.isRequired,
  linkTo: PropTypes.func.isRequired,
  showLeadershipButton: PropTypes.bool.isRequired,
};

export default injectIntl(CollaboratorsList);
