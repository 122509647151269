// @packages
import { takeLatest, call, put } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';

// @own
import { GET_TEAM_INFORMATION_WA } from './actionTypes';
import { getTeamInformationWA } from './api';
import { getTeamInformationFailWA, getTeamInformationSuccessWA } from './actions';

function* getTeamInfoWAWorker({ payload: { identification } }) {
  try {
    const response = yield call(getTeamInformationWA, identification);
    yield put(getTeamInformationSuccessWA(response));
  } catch (error) {
    yield put(getTeamInformationFailWA(error));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }))
  }
};

export default function* getUserInfoWatcher() {
  yield takeLatest(GET_TEAM_INFORMATION_WA, getTeamInfoWAWorker);
};
