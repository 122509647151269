const isCollaborator = (id, user) => {
  const { collaborators } = user;
  if (collaborators) {
    for (let collaborator of collaborators) {
      if (collaborator.id === id)
        return true;
    }
  }
  return false;
};

export default isCollaborator;
