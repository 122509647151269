import { takeEvery, call, put, select } from 'redux-saga/effects';
import { getAssignedGoalDetail } from '../api';
import { selectCommunityConfigurations } from './selectors';
import {
  REQUEST_GOAL
} from './actionTypes';

import { GOAL_DETAIL } from '../constants/modalTypes';
import { openModal } from 'smu-app-components/RootModal/actions';

import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';

export function* fetchGoalWorker({ payload: {goalId} }) {
  try {
    const communityConfigurations = yield select(selectCommunityConfigurations);
    const goal = yield call(getAssignedGoalDetail, goalId, communityConfigurations.id);

    const modalProps = { goal, community: communityConfigurations };

    yield put(openModal({ modalType: GOAL_DETAIL, modalProps }));
  } catch ({ message }) {
    yield put(addToastMessage({ type: 'error', message }));
  }
}

export function* fetchGoalWatcher() {
  yield takeEvery(REQUEST_GOAL, fetchGoalWorker);
}

export default {
  fetchGoalWatcher,
};
