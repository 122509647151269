import {
  ADD_LEADER_REQUEST,
  ADD_LEADER_REQUEST_FAIL,
  ADD_LEADER_REQUEST_SUCCESS,
} from './actionTypes';

export function addLeaderRequest(requester, requestId, status, newWordingForLeaders, isMainRequest) {
  return {
    type: ADD_LEADER_REQUEST,
    payload: {
      isMainRequest,
      newWordingForLeaders,
      requestId,
      requester,
      status,
    },
  };
}

export function addLeaderRequestFail(error) {
  return {
    type: ADD_LEADER_REQUEST_FAIL,
    payload: { error }
  };
}

export function addLeaderRequestSuccess(response) {
  return {
    type: ADD_LEADER_REQUEST_SUCCESS,
    payload: { response }
  };
}
