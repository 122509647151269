import { defineMessages } from 'react-intl';

export default defineMessages({
  Evaluator: {
    id: 'Betterme.Messages.Common.Evaluator',
    defaultMessage: 'Evaluator',
  },
  Collaborator: {
    id: 'Betterme.Messages.Common.Collaborator',
    defaultMessage: 'Collaborator',
  },
  Period: {
    id: 'Betterme.Messages.Common.Period',
    defaultMessage: 'Period',
  },
  Score: {
    id: 'Betterme.Messages.Common.Score',
    defaultMessage: 'Score',
  },
  Me: {
    id: 'Betterme.Messages.Common.Me',
    defaultMessage: 'Me',
  },
  KeepInMind: {
    id: 'Betterme.Messages.Common.KeepInMind',
    defaultMessage: 'Keep in mind',
  },
  TellUsAbout: {
    id: 'Betterme.Messages.Common.TellUsAbout',
    defaultMessage: 'Tell us about your experience',
  },
  SendBy: {
    id: 'Betterme.Messages.Common.SendBy',
    defaultMessage: 'Sent by',
  },
  Suggested: {
    id: 'Betterme.Messages.Common.Suggested',
    defaultMessage: 'Suggested',
  },
  Values: {
    id: 'Betterme.Messages.Common.Values',
    defaultMessage: 'Values',
  },
  Goals: {
    id: 'Betterme.Messages.Common.Goals',
    defaultMessage: 'Goals',
  },
  MyGrowth: {
    id: 'Betterme.Messages.Common.MyGrowth',
    defaultMessage: 'My Growth',
  },
  FeedbackForLeaders: {
    id: 'Betterme.Messages.Common.FeedbackForLeaders',
    defaultMessage: 'Feedback For Leaders',
  },
  AttachedFiles: {
    id: 'Betterme.Messages.Common.AttachedFiles',
    defaultMessage: 'Attached files',
  },
  WellDone: {
    id: 'Betterme.Messages.Common.WellDone',
    defaultMessage: 'Well done!',
  },
  Comment: {
    id: 'Betterme.Messages.Common.Comment',
    defaultMessage: 'Comment',
  },
  NewHire: {
    id: 'Betterme.Messages.Common.NewHire',
    defaultMessage: 'New Hire',
  },
  NewHireDaysLeft: {
    id: 'Betterme.Messages.Common.NewHireDaysLeft',
    defaultMessage: '**{count}** days left',
  },
  NewHireDayLeft: {
    id: 'Betterme.Messages.Common.NewHireDayLeft',
    defaultMessage: '**{count}** day left',
  },
  NewHirePeriod: {
    id: 'Betterme.Messages.Common.NewHirePeriod',
    defaultMessage: 'until the New Hire period ends',
  },
  Actions: {
    id: 'Betterme.Messages.Common.Actions',
    defaultMessage: 'Actions',
  },
  MoreInfoHere: {
    id: 'Betterme.Messages.Common.MoreInfoHere',
    defaultMessage: 'More info here',
  },
  Day: {
    id: 'Betterme.Messages.Common.Day',
    defaultMessage: 'day',
  },
  Days: {
    id: 'Betterme.Messages.Common.Days',
    defaultMessage: 'days',
  },
  Month: {
    id: 'Betterme.Messages.Common.Month',
    defaultMessage: 'month',
  },
  Months: {
    id: 'Betterme.Messages.Common.Months',
    defaultMessage: 'months',
  },
  Year: {
    id: 'Betterme.Messages.Common.Year',
    defaultMessage: 'year',
  },
  Years: {
    id: 'Betterme.Messages.Common.Years',
    defaultMessage: 'years',
  },
  CareerLeader: {
    id: 'Betterme.Messages.Common.CareerLeader2',
    defaultMessage: 'Career Mentor',
  },
  CareerAndExperienceLeader: {
    id: 'Betterme.Messages.Common.CareerAndExperienceLeader2',
    defaultMessage: 'Career Mentor & Experience Leader',
  },
  CareerAndExperienceLeaders: {
    id: 'Betterme.Messages.Common.CareerAndExperienceLeaders2',
    defaultMessage: 'Career Mentor & Experience Leaders',
  },
  ExperienceLeader: {
    id: 'Betterme.Messages.Common.ExperienceLeader2',
    defaultMessage: 'Experience Leader',
  },
  ExperienceLeaders: {
    id: 'Betterme.Messages.Common.ExperienceLeaders2',
    defaultMessage: 'Experience Leaders',
  },
  OtherExperienceLeaders: {
    id: 'Betterme.Messages.Common.OtherExperienceLeaders2',
    defaultMessage: 'Experience Leaders',
  },
  AsCareerLeader: {
    id: 'Betterme.Messages.Common.AsCareerLeader2',
    defaultMessage: 'As Career Mentor',
  },
  AsExperienceLeader: {
    id: 'Betterme.Messages.Common.AsExperienceLeader2',
    defaultMessage: 'As Experience Leader',
  },
  AsExperienceLeaders: {
    id: 'Betterme.Messages.Common.AsExperienceLeaders2',
    defaultMessage: 'As Experience Leaders',
  },
  AsCareerAndExperienceLeader: {
    id: 'Betterme.Messages.Common.AsCareerAndExperienceLeader2',
    defaultMessage: 'As Career Mentor & Experience Leader',
  },
  AsCareerAndExperienceLeaders: {
    id: 'Betterme.Messages.Common.AsCareerAndExperienceLeaders2',
    defaultMessage: 'As Career Mentor & Experience Leaders',
  },
  ErrorStateMessage: {
    id: 'Betterme.FeedbackView.ErrorState.Message',
    defaultMessage: 'You can\'t access this content at the moment',
  },
  EvaluationsDisabled: {
    id: 'Betterme.Messages.Common.EvaluationsDisabled',
    defaultMessage: 'An evaluation was recently created',
  },
  EvaluationsNotifications: {
    id: 'Betterme.Messages.Common.EvaluationsNotifications',
    defaultMessage: 'Notifications',
  },
  Historic: {
    id: 'Betterme.Messages.Common.Historic',
    defaultMessage: 'Historic',
  },
});

