// @packages
import React, { useState, useEffect } from 'react';
import Datepicker from 'smu-ui-components/Datepicker';
import Dropdown from 'smu-ui-components/Dropdown';
import PropTypes from 'prop-types';
import cn from 'classnames';
import moment from 'moment';
import { findIndex } from 'lodash';

// @app
import { ALL } from 'betterme-components/MyTeam/constants';

// @own
import './styles.scss';

const DateFilter = ({
  className,
  dateOptions,
  datePickerTexts: {
    endDatePlaceholder,
    startDatePlaceholder,
  },
  defaultPeriodDateFilter,
  dropdownPlaceholder,
  endDate,
  noStyles,
  onDateChange,
  startDate,
  title,
}) => {
  const initialDropdownValue = defaultPeriodDateFilter === ALL
    ? getDropdownValue(null, null, dateOptions)
    : getDropdownValue(startDate, endDate, dateOptions);
  const [dropdownValue, setDropdownValue] = useState(initialDropdownValue);
  const todayUTC = moment.utc();
  const DateFilterClassName = cn('date-filter', className, {
    'date-filter--container': !noStyles,
  });


  function handleCalendarChange({ endDate, startDate }, onDateChange) {
    onDateChange({
      endDate: endDate && endDate.clone().startOf('day'),
      startDate: startDate && startDate.clone().startOf('day'),
    });
  };

  function handleDropdownChange(value, dateOptions, onDateChange) {
    if (value) {
      const { startDate, endDate, label } = dateOptions[value - 1];
      onDateChange({ startDate, endDate, dropdownValue: value - 1, dropdownLabel: label });
      setDropdownValue(value);
    }
  };

  function getDropdownValue(startDate, endDate, dateOptions) {
    if (startDate === null && endDate === null) {
      return findIndex(dateOptions, option =>
        option.startDate === null
        && option.endDate === null
      ) + 1;
    }
    const optionIndex = findIndex(dateOptions, option =>
      option.startDate
      && option.endDate
      && option.startDate.isSame(startDate, 'day')
      && option.endDate.isSame(endDate, 'day')
    );
    return optionIndex === -1
      ? ''
      : optionIndex + 1;
  };

  function getOutsideDateRangeStartDate(day) {
    const utcDay = day.utc();
    const outsideDateRangeStartDate = utcDay.isSameOrAfter(endDate, 'day');
    return outsideDateRangeStartDate;
  };

  function getOutsideDateRangeDueDate(day) {
    const utcDay = day.utc();
    const outsideDateRangeDueDate = utcDay.isSameOrBefore(startDate, 'day');
    return outsideDateRangeDueDate;
  };

  useEffect(() => {
    setDropdownValue(initialDropdownValue);
  }, [initialDropdownValue]);

  return (
    <div className={DateFilterClassName}>
      <div className="date-filter__title">
        {title}
      </div>
      <div className="date-filter__inputs">
        <Dropdown
          className="date-filter__dropdown"
          onChange={value => handleDropdownChange(value, dateOptions, onDateChange)}
          options={dateOptions.map(({ label }, index) => ({ label, value: index + 1 }))}
          padding
          placeholder={!dropdownValue
            ? dropdownPlaceholder
            : undefined
          }
          value={dropdownValue}
        />
        <Datepicker
          key="from"
          className="max-w-44 ml-2"
          initialVisibleMonth={() => startDate || endDate || todayUTC}
          isOutsideRange={getOutsideDateRangeStartDate}
          onChange={(value) => handleCalendarChange({
            startDate: value,
            endDate: endDate,
          }, onDateChange)}
          placeholder={startDatePlaceholder}
          styleInput
          value={startDate}
        />
        <Datepicker
          key="to"
          className="max-w-44 ml-2"
          initialVisibleMonth={() => endDate || startDate || todayUTC}
          isOutsideRange={getOutsideDateRangeDueDate}
          onChange={(value) => handleCalendarChange({
            startDate: startDate,
            endDate: value,
          }, onDateChange)}
          placeholder={endDatePlaceholder}
          styleInput
          value={endDate}
        />
      </div>
    </div>
  );
};

DateFilter.propTypes = {
  className: PropTypes.string,
  dateOptions: PropTypes.array,
  datePickerTexts: PropTypes.object,
  defaultPeriodDateFilter: PropTypes.number,
  dropdownPlaceholder: PropTypes.string,
  endDate: PropTypes.object,
  evaluationoption: PropTypes.object,
  noStyles: PropTypes.bool,
  onDateChange: PropTypes.func.isRequired,
  startDate: PropTypes.object,
  title: PropTypes.string,
};

export default DateFilter;
