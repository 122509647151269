//TODO activate linter when the backend integration is done
// @packages
import { takeLatest, call, put } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import { openModal } from 'smu-app-components/RootModal/actions';

// @app
import {
  createRequestFeedbackOnBehalf,
  getRequestFeedback,
  requestFeedback,
  searchUsers,
  sendFeedback,
  sendMultipleFeedback,
  sendRequestFeedback,
} from 'betterme-components/api';
import { removeLocalStoredItem } from 'utils/localStorage';
import {
  FEEDBACK_CONFIRMATION,
  LEADERSHIP_REMOVAL_MODAL,
} from 'betterme-components/constants/modalTypes';
import { ACTION_TYPE_REQUIRED } from 'components/common/constants';

// @own
import {
  feedbackError,
  feedbackSent,
  createRequestFeedbackOnBehalfFail,
  createRequestFeedbackOnBehalfSuccess,
  getRequestFeedbackInfoError,
  getRequestFeedbackInfoSuccess,
  getUsersFail,
  getUsersSuccess,
  initMandatoryCompetencies,
  initReplyRequestFeedbackData,
  initUser,
} from './actions';
import {
  CREATE_REQUEST_FEEDBACK_ON_BEHALF,
  GET_REQUEST_FEEDBACK_INFO,
  GET_USERS,
  REPLY_REQUEST_FEEDBACK,
  REQUEST_FEEDBACK,
  SEND_FEEDBACK,
} from './actionTypes';
import {
  REPLY,
  REQUEST,
  REQUEST_ON_BEHALF,
  SEND,
} from './constants';

const parseRatedCompetencies = (competenciesByRate, ratedCompetency, _index, ratedCompetencies) => {
  const rateId = ratedCompetency.rateId;
  const rateIdExist = competenciesByRate.find((rateInfo) => rateInfo.ratingId === rateId);
  if (!rateIdExist) {
    const filteredCompetencies = ratedCompetencies.filter((competencyInfo) => competencyInfo.rateId === rateId);
    const topicIds = filteredCompetencies.map((competencyInfo) => competencyInfo.competency.id);
    const competencyByRate = { ratingId: rateId, topicIds };
    competenciesByRate = [...competenciesByRate, competencyByRate];
  }

  const ratedTopics = competenciesByRate?.filter(r => r.ratingId);

  return ratedTopics;
};

function* submitSendFeedbackWorker({ payload: { recipientIds, ratedCompetencies, comment, files, actionRequired, user, selfUser, suggestPromotion } }) {
  try {
    const ratedTopics = ratedCompetencies.reduce(parseRatedCompetencies, []);
    if (recipientIds.length === 1) {
      const data = {
        comment,
        files,
        recipientId: recipientIds[0],
        ratedTopics,
        suggestPromotion,
      };
      yield call(sendFeedback, data);
    } else {
      const data = {
        comment,
        files,
        recipientIds,
        ratedTopics,
      };
      yield call(sendMultipleFeedback, data);
    }
    yield put(feedbackSent(SEND));
    if (user?.id === actionRequired?.collaboratorId &&
      actionRequired?.actionType === 'REMOVE_LEADER_SEND_FEEDBACK') {
      yield put(openModal({
        modalType: LEADERSHIP_REMOVAL_MODAL,
        modalProps: {
          actionRequired,
          collaborator: user,
          user: selfUser,
        },
      }));
      removeLocalStoredItem(ACTION_TYPE_REQUIRED);
    } else {
      yield put(openModal({ modalType: FEEDBACK_CONFIRMATION }));
    };
  } catch (error) {
    yield put(feedbackError(error.message));
    yield put(addToastMessage({ message: error.message, timeout: 3000 }));
  }
}

function* submitReplyRequestFeedbackWorker({ payload: { requestId, ratedCompetencies, comment, files, suggestPromotion } }) {
  try {
    const ratedTopics = ratedCompetencies.reduce(parseRatedCompetencies, []);
    const data = {
      comment: comment,
      files,
      ratedTopics,
      suggestPromotion,
    };
    yield call(sendRequestFeedback, requestId, data);
    yield put(feedbackSent(REPLY));
    yield put(openModal({ modalType: FEEDBACK_CONFIRMATION }));
  } catch (error) {
    yield put(feedbackError(error.message));
    yield put(addToastMessage({ message: error.message, timeout: 3000 }));
  }
}

function* submitRequestFeedbackWorker({ payload: { comment, selectedCompetencies, recipientIds } }) {
  try {
    const data = recipientIds.map(recipientId => ({
      comment,
      topicIds: selectedCompetencies.map((selectedCompetency) => selectedCompetency.id),
      recipientId,
    }));

    yield call(requestFeedback, data);
    yield put(feedbackSent(REQUEST));
    yield put(openModal({ modalType: FEEDBACK_CONFIRMATION }));
  } catch (error) {
    yield put(feedbackError(error.message));
    yield put(addToastMessage({ message: error.message, timeout: 3000 }));
  }
}

function* submitRequestFeedbackOnBehalfWorker({
  payload: {
    comment,
    evalueeIds,
    recipientIds,
    selectedCompetencies,
  } }) {
  try {
    const data = {
      comment,
      evalueeIds,
      recipientIds,
      topicIds: selectedCompetencies.map((selectedCompetency) => selectedCompetency.id),
    };

    const response = yield call(createRequestFeedbackOnBehalf, data);
    yield put(createRequestFeedbackOnBehalfSuccess(response));
    yield put(feedbackSent(REQUEST_ON_BEHALF));
    yield put(openModal({ modalType: FEEDBACK_CONFIRMATION }));
  } catch (error) {
    yield put(createRequestFeedbackOnBehalfFail(error));
    yield put(feedbackError(error.message));
    yield put(addToastMessage({ message: error.message, timeout: 3000 }));
  }
}

export function* getRequestFeedbackInfoWorker({ payload: { requestId } }) {
  try {
    const response = yield call(getRequestFeedback, requestId);
    const {
      comment,
      requestedBy,
      sender,
      topics,
    } = response;
    yield put(getRequestFeedbackInfoSuccess(response));
    yield put(initReplyRequestFeedbackData(requestId, requestedBy, comment));
    if (topics) {
      yield put(initMandatoryCompetencies(topics));
    }
    yield put(initUser(sender));
  } catch (error) {
    yield put(getRequestFeedbackInfoError(error));
    if (error.status !== 404) {
      yield put(addToastMessage({ message: error.message, timeout: 3000 }));
    }
  }
}

export function* getUsersWorker({ payload }) {
  const { query, selectedUsers, selectedCollaborators } = payload;
  const pageSize = selectedUsers.length + 5;
  const selectedUsersId = selectedUsers.map(user => user.id);
  const selectedCollaboratorsId = selectedCollaborators.map(collaborator => collaborator.id);

  try {
    const response = yield call(searchUsers, query, pageSize);
    const users = response
      .filter(user => !(selectedUsersId.includes(user.id) || selectedCollaboratorsId.includes(user.id)))
      .slice(0, 5);
    yield put(getUsersSuccess(users));
  } catch (error) {
    yield put(getUsersFail(error));
    yield put(addToastMessage({ message: error.message, timeout: 3000 }));
  }
}

export default function* sendFeedbackWatcher() {
  yield takeLatest(CREATE_REQUEST_FEEDBACK_ON_BEHALF, submitRequestFeedbackOnBehalfWorker);
  yield takeLatest(GET_REQUEST_FEEDBACK_INFO, getRequestFeedbackInfoWorker);
  yield takeLatest(GET_USERS, getUsersWorker);
  yield takeLatest(REPLY_REQUEST_FEEDBACK, submitReplyRequestFeedbackWorker);
  yield takeLatest(REQUEST_FEEDBACK, submitRequestFeedbackWorker);
  yield takeLatest(SEND_FEEDBACK, submitSendFeedbackWorker);
}
