// @packages
import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Segment from 'smu-ui-components/Segment';

// @app
import GoalOverviewItem from 'components/molecules/GoalOverviewItem';
import GoalOverviewFilters from 'components/molecules/GoalOverviewFilters';

// @own
import './styles.scss';

function GoalOverview({
  className,
  dropdownOptions,
  goals,
  initialDropdownValue,
  isGoalsContinous,
  loading,
  messages: {
    dueDate,
    placeholderNoResults,
    score,
    viewMore,
    weight,
  },
  onFilterChange,
}) {
  const [viewingMore, setViewingMore] = useState(false);
  const [dropdownValue, setDropdownValue] = useState(initialDropdownValue);
  const displayedGoals = viewingMore ? goals : goals.slice(0, 3);
  const dropdownOnChange = value => setDropdownValue(value);

  useEffect(() => onFilterChange(dropdownValue), [dropdownValue]);

  return (
    <Segment loading={loading} className={cn('goal-overview', className)} loadingType="betterme">
      <GoalOverviewFilters
        dropdownOptions={dropdownOptions}
        dropdownValue={dropdownValue}
        dueDate={dueDate}
        onChange={dropdownOnChange}
        score={score}
        weight={weight}
      />
      {goals.length > 0 ? (
        <ul className="goal-overview__goals">
          {displayedGoals.map(goal =>
            <GoalOverviewItem
              {...goal}
              className="goal-overview__item"
              isGoalsContinous={isGoalsContinous}
              key={goal.id}
            />)
          }
        </ul>
      ) : (
        <div className="goal-overview__no-results">
          {placeholderNoResults}
        </div>
      )}
      {goals.length > 3 && !viewingMore && (
        <div
          className="goal-overview__view-more"
          onClick={() => setViewingMore(true)}
        >
          {viewMore}
        </div>
      )}
    </Segment>
  );
};

GoalOverview.defaultProps = {
  onFilterChange: () => undefined,
  defaultFilterOpen: true,
};

GoalOverview.propTypes = {
  className: PropTypes.string,
  dropdownOptions: PropTypes.array,
  goals: PropTypes.array.isRequired,
  initialDropdownValue: PropTypes.string,
  intl: PropTypes.object.isRequired,
  isGoalsContinous: PropTypes.bool,
  loading: PropTypes.bool,
  messages: PropTypes.object,
  onFilterChange: PropTypes.func,
};

export default GoalOverview;
