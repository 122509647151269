// @packages
import React, { useState } from 'react';
import Checkbox from 'smu-ui-components/Checkbox';
import Divider from 'smu-ui-components/Divider';
import Icon from 'smu-ui-components/IconV2';
import Label from 'smu-ui-components/Label';
import Paper from 'smu-ui-components/Paper';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import { useQuestions } from 'smu-services/betterme/api/questions/get';
import InputArea from 'smu-ui-components/InputArea';

//@app
import messages from './messages';
import {
  actionsMessages,
  placeholdersMessages
} from 'common/messages';
// @own
import './styles.scss';
import SuggestionDiscovery from './SuggestionDiscovery';
import { ellipsis } from 'utils/ellipsis';
import { FEEDBACK_SUGGESTED_PROMOTIONS, SUGGESTED_PROMOTION_DISCOVERY } from './constants';

const SuggestedPromoQuestion = ({
  question,
  onChange,
  value,
  placeholder,
  name,
}) => {
  return (
    <section className="suggested-promoquestion__card">
      <label for="first_name"
        class="
        block
        mb-2
        text-sm
        text-gray-900
        text-bold
        font-semibold
        mt-5">
        {question}
      </label>
      <InputArea
        className="block 
        resize-none	
        p-2.5 
        w-full 
        text-sm
        text-gray-900
        focus:outline-none"
        maxLength={2000}
        onChange={onChange}
        placeholder={placeholder}
        rows={7}
        value={value[name]}
        name={name}
      />
      <Divider noMarginBottom />
    </section>
  )
}

const SuggestedPromotions = ({
  user,
  showPromotion,
  handleShowPromotion,
  getPromotionAnswers,
  intl: { formatMessage },
  currentSeniority = "",
}) => {
  const name = ellipsis(`${user.firstName} ${user.lastName}`, 28);
  const [openDiscovery, setOpenDiscovery] = useState(true);
  const [promotionAnswersLocal, setPromotionAnswerLocal] = useState({});
  const {
    data: dataQuestions,
  } = useQuestions({ type: FEEDBACK_SUGGESTED_PROMOTIONS });

  const actionButtonTexts = {
    slideOneText: formatMessage(actionsMessages.ActionsGotIt),
  };

  const galleryItems = [
    {
      text: (
        <FormattedHTMLMessage
          defaultMessage={messages.discoveryText.defaultMessage}
          id={messages.discoveryText.id}
        />
      ),
    },
  ];

  const handleUpdateQuestion = (e) => {
    const { target } = e;
    const newAnswers = {
      ...promotionAnswersLocal,
      [target.name]: target.value,
    };
    const quantityQuestions = dataQuestions?.result?.length;
    const quantityAnsw = Object.values(newAnswers).length;
    const hasEmptyString = Object.values(newAnswers).some(value => typeof value === 'string' && value.trim() === '');

    setPromotionAnswerLocal(newAnswers);
    getPromotionAnswers({
      data: newAnswers,
      isSomeEmpty: quantityQuestions !== quantityAnsw || hasEmptyString,
    })
  }

  return (
    <section className="suggested-promotions space-y-4">
      <Paper className="p-6" shadow>
        <div className="suggested-promotions__seniority-title">
          <p className='pr-1 mb-4'> {formatMessage(messages.seniorityLevel, { currentSeniority, name })}</p>
          <SuggestionDiscovery
            handleOpen={setOpenDiscovery}
            open={openDiscovery}
            type={SUGGESTED_PROMOTION_DISCOVERY}
            title={formatMessage(messages.discoveryTitle)}
            galleryItems={galleryItems}
            actionButtonTexts={actionButtonTexts}
          >
            <div onClick={() => setOpenDiscovery(true)}>
              <Label
                color="btm-blue-button"
                size="small"
                text={formatMessage(messages.discoveryTrigger)}
              />
            </div>
          </SuggestionDiscovery>
        </div>
        <p className='mb-4 suggested-promotions__checkSection'>
          <span>{formatMessage(messages.checkBoxSuggested)}</span>
          <Checkbox
            checked={showPromotion}
            className="suggested-action__checkbox"
            disabled={false}
            name={"check"}
            onChange={handleShowPromotion}
          />
        </p>
        <p className="mb-4 show-promotion-title">
          <Icon className="mr-2" icon="alert-fill" />
          {formatMessage(messages.warningMessage)}
        </p>
      </Paper>
      {
        showPromotion && dataQuestions && dataQuestions.result.length > 0 && (
          <Paper className="p-6" shadow>
            <section className="suggested-promotions__alert">
              <p> {formatMessage(messages.promotionMessage)}</p>
            </section>
            {
              dataQuestions.result.map(question =>
                <SuggestedPromoQuestion
                  key={question.id}
                  question={question.name}
                  name={question.id}
                  value={promotionAnswersLocal}
                  onChange={handleUpdateQuestion}
                  placeholder={formatMessage(placeholdersMessages.PlaceholdersMandatory)}
                />
              )
            }
          </Paper>
        )
      }

    </section>
  );
}

SuggestedPromotions.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    seniority: PropTypes.string,
    currentSeniority: PropTypes.string,
  }).isRequired,
  showPromotion: PropTypes.bool.isRequired,
  handleShowPromotion: PropTypes.func.isRequired,
  getPromotionAnswers: PropTypes.func.isRequired,
}
export default injectIntl(SuggestedPromotions);