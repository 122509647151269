import {
  CLEAN_CHANGE_LEADER_USER_LIST,
  CLOSE_USER_CARD,
  GET_LEADERS,
  GET_LEADERS_FAIL,
  GET_LEADERS_SUCCESS,
  RESET_CHANGE_LEADER_REQUEST_STATE,
  RESET_SELECT_LEADER,
  SELECT_LEADER,
  SET_CHANGE_LEADER_COMMENT,
  UPDATE_USER_LEADER,
  UPDATE_USER_LEADER_FAIL,
  UPDATE_USER_LEADER_SUCCESS,
} from './actionTypes';

export function cleanChangeLeaderUserList() {
  return {
    type: CLEAN_CHANGE_LEADER_USER_LIST,
  };
}

export function getLeaders(query) {
  return {
    type: GET_LEADERS,
    payload: { query },
  };
}

export function getLeadersFail(error) {
  return {
    type: GET_LEADERS_FAIL,
    payload: { error },
  };
}

export function getLeadersSuccess(response) {
  return {
    type: GET_LEADERS_SUCCESS,
    payload: { response },
  };
}

export function resetChangeLeaderRequestState() {
  return {
    type: RESET_CHANGE_LEADER_REQUEST_STATE,
  };
}

export function selectLeader(user) {
  return {
    type: SELECT_LEADER,
    payload: { user },
  };
}

export function setChangeLeaderComment(value) {
  return {
    type: SET_CHANGE_LEADER_COMMENT,
    payload: { value },
  };
}

export function updateUserLeader({ leader, collaborator, comment, to, type, toastMessages, actionTypeRequeried, successMessage, suggestionId }) {
  return {
    type: UPDATE_USER_LEADER,
    payload: {
      leader,
      collaborator,
      comment,
      to,
      type,
      toastMessages,
      actionTypeRequeried,
      successMessage,
      suggestionId,
    },
  };
}

export function updateUserLeaderFail(error) {
  return {
    type: UPDATE_USER_LEADER_FAIL,
    payload: { error },
  };
}

export function updateUserLeaderSuccess(response) {
  return {
    type: UPDATE_USER_LEADER_SUCCESS,
    payload: { response },
  };
}

export function closeUserCard(payload) {
  return {
    type: CLOSE_USER_CARD,
    payload,
  }
}

export function resetSelectLeader(user) {
  return {
    type: RESET_SELECT_LEADER,
    payload: { user },
  };
}
