// @packages
import { takeLatest, call, put } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';

// @own
import { GET_LEADERSHIP_REQUESTS_SENT } from './actionTypes';
import {
  getLeadershipRequestsSentFail,
  getLeadershipRequestsSentSuccess,
} from './actions';
import { getLeadershipRequestsSent } from './api';

function* getLeadershipRequestsSentWorker({ payload: { type, params } }) {
  try {
    const currentParams = {
      pageSize: 100,
      relation: type,
      status: 'CREATED',
      ...params,
    };
    const response = yield call(getLeadershipRequestsSent, currentParams);
    yield put(getLeadershipRequestsSentSuccess(response));
  } catch (error) {
    yield put(getLeadershipRequestsSentFail(error.message));
    yield put(
      addToastMessage({ type: 'error', message: error.message, timeout: 3000 })
    );
  }
}

export default function* changeLeaderRequestWorker() {
  yield takeLatest(
    GET_LEADERSHIP_REQUESTS_SENT,
    getLeadershipRequestsSentWorker
  );
}
