// @packages
import ActionButtons from 'components/molecules/ActionButtons';
import Checkbox from 'smu-ui-components/Checkbox';
import cn from 'classnames';
import Dropdown from 'smu-ui-components/Dropdown';
import PropTypes from 'prop-types';
import React from 'react';
import InputArea from 'smu-ui-components/InputArea';

// @app
import NumericScoreSelector from 'common/NumericScoreSelector';

// @own
import './styles.scss';

const GoalsActionsCloseModalUI = ({
  acceptMessage,
  cancelMessage,
  close,
  disableAccept,
  disableCalification,
  messages: {
    checkbox: checkboxMessage,
    comment: commentMessage,
    dropdown: dropdownMessage,
    numberSelector: numberSelectorMessage,
  },
  loading,
  value,
  options,
  onAccept,
  onCalificationChange,
  onCheckboxChange,
  onCommentChange,
  range,
  title,
}) => (
  <div className={cn('goals-actions-close-modal', {
    'goals-actions-close-modal--loading': loading,
  })}>
    <div className="goals-actions-close-modal__title">{title}</div>
    <div className="goals-actions-close-modal__calification-wrapper">
      {options ?
        <Dropdown
          className="goals-actions-close-modal__dropdown"
          disabled={disableCalification}
          onChange={onCalificationChange}
          options={options}
          padding
          placeholder={value ? '' : dropdownMessage}
          value={value}
        /> :
        <div className="goals-actions-close-modal__number-selector-wrapper">
          <NumericScoreSelector
            className="goals-actions-close-modal__number-selector"
            disabled={disableCalification}
            onChange={onCalificationChange}
            range={range}
            value={value}
          />
          <div className="goals-actions-close-modal__calification-text">
            {numberSelectorMessage}
          </div>
        </div>
      }
    </div>
    <div className="goals-actions-close-modal__comment-wrapper">
      <InputArea
        className="goals-actions-close-modal__comment"
        maxLength={255}
        onChange={onCommentChange}
        placeholder={commentMessage}
      />
    </div>
    <div className="goals-actions-close-modal__actions-wrapper">
      <div className="goals-actions-close-modal__checkbox-wrapper">
        <Checkbox
          checked={disableCalification}
          className="goals-actions-close-modal__checkbox"
          onChange={onCheckboxChange}
        />
        <div className="goals-actions-close-modal__checkbox-label">{checkboxMessage}</div>
      </div>
      <div className="goals-actions-close-modal__button-wrapper">
        <ActionButtons
          buttons={[
            {
              label: cancelMessage,
              onClick: close,
              variant: "outline",
            },
            {
              disabled: disableAccept,
              label: acceptMessage,
              onClick: onAccept,
            },
          ]}
        />
      </div>
    </div>
  </div>
);

GoalsActionsCloseModalUI.propTypes = {
  acceptMessage: PropTypes.string,
  cancelMessage: PropTypes.string,
  close: PropTypes.func.isRequired,
  disableAccept: PropTypes.bool.isRequired,
  disableCalification: PropTypes.bool.isRequired,
  messages: PropTypes.shape({
    checkbox: PropTypes.string,
    comment: PropTypes.string,
    dropdown: PropTypes.string,
    numberSelector: PropTypes.string,
  }),
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  onAccept: PropTypes.func.isRequired,
  onCalificationChange: PropTypes.func.isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
  onCommentChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  range: PropTypes.shape({
    max: PropTypes.number,
    min: PropTypes.number,
  }),
  title: PropTypes.string,
};

export default GoalsActionsCloseModalUI;
