/**
 * Is valid talent matrix dimensions
 * @param {array} dimensions
 * @param {boolean} isEnabled
 * @returns {boolean}
 */
export function isValidTalentMatrixDimensions(
  dimensions = [],
  isEnabled = false
) {
  return (
    !isEnabled ||
    (dimensions?.length > 0 && dimensions?.every((d) => d?.ratingId))
  );
}

/**
 * Get talent matrix dimensions from api response
 * @param {array} dimensions
 */
export function getTalentMatrixDimensions(dimensions = []) {
  return dimensions?.map((d) => ({
    ratingId: d?.answers?.[0]?.id,
    questionId: d?.id,
  }));
}

/**
 * Get talent matrix body to send to api
 * @param {array} dimensions
 */
export function getTalentMatrixBody(dimensions) {
  const isValid = isValidTalentMatrixDimensions(dimensions);
  return isValid
    ? {
        dimensions,
        type: 'TALENT_MATRIX_V1',
      }
    : undefined;
}
