// @packages
import { trackEvent } from 'smu-utils/gtm';

// @own
import messages from './messages';

/**
 * Returns a description message based on user's competencies and username.
 * @param {Function} formatMessage - A function that formats and returns a localized message.
 * @param {Object} competencies - An object containing the user's competencies.
 * @param {string} username - The username of the user.
 * @returns {string} - A formatted message based on user's competencies and username.
 */

export const getDescription = (formatMessage, competencies, username) => {
  const data = competencies
    ? Object.keys(competencies)
        .reduce((acc, cur) => {
          let c = competencies[cur] ? competencies[cur].split(',') : [];
          acc.push({
            id: Number(cur),
            count: c.length,
            competencies: c,
          });
          return acc;
        }, [])
        .sort((a, b) => a.id - b.id)
    : [];

  let id = [];
  let plural = data[0]?.count > 1 || data[1]?.count > 1 || data[2]?.count > 1;

  if (data[0]?.count && data[2]?.count) {
    id = [2, 0];
  } else if (data[2]?.count) {
    id = [2];
  } else if (data[0]?.count && data[1]?.count) {
    id = [1, 0];
  } else if (data[1]?.count) {
    id = [1];
  } else if (data[0]?.count) {
    id = [0];
  }

  let xxxx = data?.[id[0]]?.competencies?.[0];
  let xxxxx = data?.[id[1]]?.competencies?.[0];
  let numbers = id
    .sort()
    .map((i) => i + 1)
    .join('');

  return formatMessage(
    messages[`firstStepDescription${numbers}${plural ? 'P' : ''}`],
    {
      xxx: username,
      xxxx,
      xxxxx,
    }
  );
};

const sendEvent = (data) =>
  trackEvent({
    category: 'feedback',
    action: 'click',
    type: 'send_fdbk',
    ...data,
  });

export const onClickConfirmationActionNo = () =>
  sendEvent({
    label: 'GPT_regenerate_modal',
    int_type: 'no',
  });

export const onClickConfirmationActionYes = () =>
  sendEvent({
    label: 'GPT_regenerate_modal',
    int_type: 'yes',
  });

export const onClickFirstStepAction = () =>
  sendEvent({
    label: 'GPT_generate',
  });

export const onClickFirstStepText = () =>
  sendEvent({
    label: 'GPT_text_box',
  });

export const onClickMainButton = () =>
  sendEvent({
    label: 'GPT_icon',
  });

export const onClickSecondStepActionDiscard = () =>
  sendEvent({
    label: 'GPT_discard',
  });

export const onClickSecondStepActionSetting = (s, o) =>
  sendEvent({
    label: 'GPT_filters',
    filter: `${s?.value} - ${o?.selected}`,
  });

export const onClickSecondStepActionSettings = () =>
  sendEvent({
    label: 'GPT_adjust',
  });

export const onClickSecondStepActionInsert = () =>
  sendEvent({
    label: 'GPT_insert',
  });

export const onClickSecondStepActionSave = () =>
  sendEvent({
    label: 'GPT_filters_update',
  });

export const onClickSecondStepActionUpdate = () =>
  sendEvent({
    label: 'GPT_regenerate',
  });
