// @packages
import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { PieChart as PieChartUI, Pie, Cell, Tooltip } from 'recharts';

// @own
import ChartTooltip from './ChartTooltip';
import './styles.scss';

const EMPTY_CHART_COLOR = '#F0F2F5';

const PieChart = ({
  children,
  className,
  data,
  size,
  strokeSize,
  tooltip,
}) => {
  const total = data.reduce((acc, { count }) => acc + count, 0);
  const emptyData = [{ name: 'empty', count: 100, color: EMPTY_CHART_COLOR }];
  const pieData = total > 0 ? data.slice(0).reverse() : emptyData;
  const radius = (size / 2) - 5;

  return (
    <div className={cn('pie-chart', className)}>
      <PieChartUI width={size} height={size}>
        <circle cx={size / 2} cy={size / 2} stroke="white" r={radius} strokeWidth={5} fill="white"/>
        <Pie
          cx={radius}
          cy={radius}
          data={pieData}
          dataKey="count"
          endAngle={450}
          innerRadius={radius - strokeSize}
          isAnimationActive={false}
          outerRadius={radius}
          paddingAngle={0}
          startAngle={90}
        >
          {pieData
            .map(({ color }) => <Cell key={color} fill={color} />)
          }
        </Pie>
        {tooltip && <Tooltip
          content={<ChartTooltip total={total} />}
          isAnimationActive={false}
          offset={0}
        />}
      </PieChartUI>
      {children && <div className="pie-chart__label">
        {children}
      </div>}
    </div>
  );
};

PieChart.defaultProps = {
  size: 220,
  strokeSize: 31,
};

PieChart.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.string,
    count: PropTypes.number,
  })),
  tooltip: PropTypes.bool,
};

export default PieChart;
