/* eslint-disable react-hooks/exhaustive-deps */
// @packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { base64Decode } from 'smu-utils/base64';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { injectIntl } from 'react-intl';
import { closeModal } from 'smu-app-components/RootModal/actions';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';

// @app
import ActionButtons from 'components/molecules/ActionButtons';
import SkillsComponent from 'components/containers/SkillsComponent';
import BehaviourSection from 'components/containers/BehaviourSection';
import CoCreatorSection from 'components/templates/CoCreatorSection';
import Evaluation from 'components/templates/EvaluationsTemplate';
import EvaluationWidgets from 'components/containers/EvaluationWidgets';
import ScoreSelector from 'components/templates/ScoreSelector';
import Title from 'components/atoms/Title';
import { actionsMessages } from 'common/messages';
import { getProfile } from 'betterme-components/Profile/actions';
import { getNotificationHistoryLocation } from 'betterme-components/routes/urls';
import { handleCommentChange, handleScoreChange } from 'utils/handleScoreCommentHelper';
import { selectEvaluationPeriod } from 'betterme-components/Authorization/selectors';
import { selectProfile } from 'betterme-components/Profile/selectors';
import { selectRatings } from 'betterme-components/services/Layout/selectors';
import { evaluationCleanSkills } from 'betterme-components/Evaluations/actions';
import { selectOptionsScores } from 'betterme-components/Evaluations/selectors';
import { createInput, setInput, resetInput } from 'betterme-components/services/PerformanceRetrospectiveInput/actions';
import { selectInputData, selectInputLoading } from 'betterme-components/services/PerformanceRetrospectiveInput/selectors';
import { getTierType } from 'betterme-components/services/GetTierType/actions';
import { selectTierType, selectTierTypeLoading } from 'betterme-components/services/GetTierType/selectors';
import { selectPerformanceRetrospectiveBehaviours } from 'betterme-components/services/PerformanceRetrospective/selectors';
import { getUserInfo } from 'betterme-components/services/UserInformation/actions';
import { selectuserInfo, selectuserInfoLoading } from 'betterme-components/services/UserInformation/selectors';
import { selectSurveyQuestions } from 'betterme-components/services/GetSurveyAndRates/selectors';

// @own
import './styles.scss';
import messages from './messages';

const PerformanceRetrospective = ({
  behavioursData,
  createInput,
  evaluationCleanSkills,
  evaluationPeriod,
  getProfile,
  getTierType,
  getUserInfo,
  goToNotifications,
  intl: { formatMessage },
  inputData,
  inputDataLoading,
  location: {
    query: {
      evaluatorIdentification,
      collaboratorIdentification,
    },
  },
  optionsScores,
  params,
  profile: evalueeProfile,
  resetInput,
  setInput,
  surveyQuestions,
  tierTypeLoading,
  tierType,
  userInfoLoading,
  userInfo,
}) => {
  const [comment, setComment] = useState('');
  const [score, setScore] = useState('');
  const endTime = evaluationPeriod?.endTime;
  const evaluationId = params?.evaluationId;
  const evaluatorId = base64Decode(evaluatorIdentification);
  const startTime = evaluationPeriod?.startTime;
  const userIdentification = base64Decode(collaboratorIdentification);
  const infoText = (<span className="text-sm">
    <p className="uppercase">{formatMessage(messages.PerformanceRetrospectiveCoCreatorSlideOneTitle)}</p>
    <p>{formatMessage(messages.PerformanceRetrospectiveInputCoCreatorSlideOneText)}</p>
    <p className="uppercase">{formatMessage(messages.PerformanceRetrospectiveCoCreatorSlideTwoTitle)}</p>
    <p>{formatMessage(messages.PerformanceRetrospectiveInputCoCreatorSlideTwoText)}</p>
  </span>);

  function handleBackTo() {
    goToNotifications();
    evaluationCleanSkills();
    resetInput();
  };

  function isValidate() {
    const isValidate = behavioursData?.length === surveyQuestions?.length
      && inputData?.ratedTopics?.length > 0
      && !isEmpty(comment)
      && !isNil(score)
    return isValidate;
  };

  function handleCreateInput() {
    const collaboratorName = `${evalueeProfile.firstName} ${evalueeProfile.lastName}`;
    const successMessage = formatMessage(messages.PerformanceRetrospectiveSuccessMessage, {
      collaboratorName,
    })
    createInput({
      evaluationId,
      successMessage,
      data: inputData,
    });
    evaluationCleanSkills();
  };

  useEffect(() => {
    getProfile(userIdentification);
  }, [getProfile, userIdentification]);

  useEffect(() => {
    getUserInfo(evaluatorId);
  }, [getProfile, evaluatorId]);

  useEffect(() => {
    if (evalueeProfile?.id) {
      getTierType({
        userId: evalueeProfile?.id,
        evaluationType: 'PERFORMANCE_RETROSPECTIVE',
      });
    }
  }, [evalueeProfile]);

  useEffect(() => {
    let newDataEvaluation = {
      ...inputData,
      behaviors: behavioursData || [],
      ratedTopics: inputData?.ratedTopics || [],
      comment: comment,
      score: score,
      surveyType: tierType?.type,
    };
    setInput({ ...newDataEvaluation });
  }, [evalueeProfile, comment, score, behavioursData]);

  useEffect(() => {
    return () => {
      evaluationCleanSkills();
      resetInput();
    }
  }, []);

  return (
    <Evaluation
      className="performance-retrospective"
      headerValues={[
        formatMessage(messages.NotificationsTitle),
        formatMessage(messages.PerformanceRetrospectiveTitle),
      ]}
      mainContentLoading={tierTypeLoading || userInfoLoading || inputDataLoading}
      navigateBack={handleBackTo}
      sideContent={
        <EvaluationWidgets
          dateTimeFrom={startTime}
          dateTimeTo={endTime}
          dontShowWidgets
          evaluee={evalueeProfile}
        />
      }
    >

      <Title
        className="mb-6"
        label={1}
        size={22}
      >
        <span className="text-black">
          {formatMessage(messages.PerformanceRetrospectiveTitle)}
        </span>
      </Title>

      <CoCreatorSection
        className="mb-6"
        hasCoEvaluator
        infoText={infoText}
        searchSectionDisclaimer={formatMessage(messages.PerformanceRetrospectiveCoEvaluatorKeepInMind)}
        searchSectionText={formatMessage(messages.PerformanceRetrospectiveCoEvaluatorSubtitle)}
        title={formatMessage(messages.PerformanceRetrospectiveSecondaryTitle)}
        user={userInfo}
      />

      <BehaviourSection className="mb-6" />

      <SkillsComponent
        data={inputData}
        setData={setInput}
        technicalInput
        user={evalueeProfile}
      />

      <ScoreSelector
        className="period-evaluation__score"
        hasCoevaluator={false}
        hasTooltip
        scoreSelector={{
          disabled: false,
          onScoreChange: (value) => handleScoreChange({
            value,
            dataEvaluation: inputData,
            setDataCreateEvaluation: setInput,
            setScore,
          }),
          scoreOptions: optionsScores,
          scorePlaceholder: formatMessage(messages.PerformanceRetrospectiveSelectRate),
          scoreValue: inputData?.score || score,
        }}
        textArea={{
          comment: inputData?.comment || comment,
          placeholder: formatMessage(messages.PerformanceRetrospectivePlaceholder),
          onTextChange: (event) =>
            handleCommentChange(event, inputData, setInput, setComment),
        }}
        title={formatMessage(messages.PerformanceRetrospectiveRate)}
      />

      <ActionButtons
        buttons={[
          {
            disabled: !isValidate(),
            label: formatMessage(actionsMessages.ActionsSubmit),
            onClick: handleCreateInput,
          }
        ]}
      />
    </Evaluation>
  );
}

PerformanceRetrospective.propTypes = {
  evaluationPeriod: PropTypes.object.isRequired,
  getProfile: PropTypes.func.isRequired,
  goToNotifications: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  ratings: PropTypes.object.isRequired,
};

const mapSateToProps = (state) => ({
  behavioursData: selectPerformanceRetrospectiveBehaviours(state),
  evaluationPeriod: selectEvaluationPeriod(state),
  inputData: selectInputData(state),
  inputDataLoading: selectInputLoading(state),
  optionsScores: selectOptionsScores(state),
  profile: selectProfile(state),
  ratings: selectRatings(state),
  surveyQuestions: selectSurveyQuestions(state),
  tierType: selectTierType(state),
  tierTypeLoading: selectTierTypeLoading(state),
  userInfo: selectuserInfo(state),
  userInfoLoading: selectuserInfoLoading(state),
});

const mapDispatchToProps = {
  getProfile,
  getUserInfo,
  getTierType,
  setInput,
  closeModal,
  createInput,
  addToastMessage,
  goToNotifications: () => push(getNotificationHistoryLocation()),
  evaluationCleanSkills,
  resetInput,
};

export default connect(
  mapSateToProps,
  mapDispatchToProps
)(injectIntl(PerformanceRetrospective));
