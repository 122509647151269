import {
  CREATE_ANSWER_EVALUATION,
  CREATE_ANSWER_EVALUATION_FAIL,
  CREATE_ANSWER_EVALUATION_SUCCESS,
  GET_EVALUATION_DETAIL,
  GET_EVALUATION_DETAIL_FAIL,
  GET_EVALUATION_DETAIL_SUCCESS,
  RESET_EVALUATION_DETAIL,
  RESET_DATA_CREATE_ANSWER_EVALUATION,
  SET_DATA_CREATE_ANSWER_EVALUATION,
} from './actionTypes';

const initialState = {
  dataCreateAnswerEvaluation: {
    approved: null,
    comment: '',
  },
  evaluationDetail: {},
  responseAnswerEvaluation: {},
  isFetching: false,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case CREATE_ANSWER_EVALUATION:
    case GET_EVALUATION_DETAIL:
      return { ...state, isFetching: true };
    case CREATE_ANSWER_EVALUATION_FAIL:
    case GET_EVALUATION_DETAIL_FAIL:
      return { ...state, isFetching: false };
    case GET_EVALUATION_DETAIL_SUCCESS:
      return { ...state, isFetching: false, evaluationDetail: payload.response };
    case CREATE_ANSWER_EVALUATION_SUCCESS:
      return { ...state, isFetching: false, responseAnswerEvaluation: payload.response };
    case RESET_EVALUATION_DETAIL:
      return { ...state, isFetching: false, evaluationDetail: {} };
    case SET_DATA_CREATE_ANSWER_EVALUATION:
      return { ...state, dataCreateAnswerEvaluation: { ...state.dataCreateAnswerEvaluation, ...payload } };
    case RESET_DATA_CREATE_ANSWER_EVALUATION:
      return {
        ...state,
        dataCreateAnswerEvaluation: {
          approved: null,
          comment: '',
        }
      };
    default:
      return state;
  }
}
