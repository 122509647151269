// @packages
import React, { useEffect, useState } from 'react';
import Button from 'smu-ui-components/ButtonV2';
import Grid from 'smu-layout-components/Grid';
import PropTypes from 'prop-types';
import Segment from 'smu-ui-components/Segment';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { push } from 'react-router-redux';
import { countBy, size, every, transform } from 'lodash';
import { trackEvent } from 'smu-utils/gtm';

// @app
import { selectFeedbackForLeadersAnswersAllowed } from 'betterme-components/Authorization/selectors';
import { getMyProfileLocation } from 'betterme-components/routes/urls';
import { getUser } from 'betterme-components/common/User/actions';
import { selectLoadingUser, selectUser } from 'betterme-components/common/User/selectors';
import BreadcrumbHeader from 'components/organisms/BreadcrumbHeader';
import UserDetailsInfo from 'components/molecules/UserDetailsInfo';
import ConfirmationCreateSuccessModal from 'common/ConfirmationCreateSuccessModal';
import { formatBoldText } from 'utils/formatBoldText';
import { formatDateV2 } from 'utils/formatDateV2';
import {
  actionsMessages,
  feedbackForLeadersMessages,
  feedbackMessages,
  promotionsMessages,
  userMessages,
} from 'common/messages';
import { selectConfigurations } from 'betterme-components/Authorization/selectors';

//@own
import './styles.scss';
import * as actions from './actions';
import {
  selectAnswersCompetencies,
  selectAnswersInsights,
  selectCompetenciesQuestions,
  selectInsightQuestions,
  selectLoadingCreateFeedbackForLeaders,
  selectLoadingQuestionsFeedbackForLeaders,
  selectLoadingRatingsFeedbackForLeaders,
  selectRatingsNotApplyFeedbackForLeaders,
  selectRatingsValuesFeedbackForLeaders,
} from './selectors';
import messages from './messages';
import FormCompetencies from './FormCompetencies';
import FormInsights from './FormInsights';
import FormStepper from './FormStepper';
import { MIN_LENGTH } from './constants';
import { isEnabledContinue } from './helpers';

const SendFeedbackForLeaders = ({
  allowsGlbFeatures,
  answersAllowed,
  answersCompetencies,
  answersInsights,
  competenciesQuestions: {
    competencies,
    totalQuestions,
  },
  createFeedbackForLeaders,
  getQuestionsFeedbackForLeaders,
  getRatingsFeedbackForLeaders,
  getUser,
  goToMyProfile,
  insightQuestions,
  intl: { formatMessage },
  loadingCreateFeedbackForLeaders,
  loadingQuestionsFeedbackForLeaders,
  loadingRatingsFeedbackForLeaders,
  loadingUser,
  params: { userId },
  ratingsNotApplyFeedbackForLeaders,
  ratingsValuesFeedbackForLeaders,
  resetFeedbackForLeaders,
  setAnswerCompetency,
  setAnswerInsight,
  user,
}) => {
  const {
    firstName: userFirstName,
    job: userJob,
    lastName: userLastName,
    profileImageCode: userProfileImageCode,
  } = user;
  const [formSection, setFormSection] = useState(1);
  const [formTab, setFormTab] = useState(1);
  const [beKindVisible, setBeKindVisible] = useState(true);
  const [lastSelectedTab, setLastSelectedTab] = useState(1);

  useEffect(() => {
    if (userId) {
      getUser({ identification: userId });
      getQuestionsFeedbackForLeaders();
      getRatingsFeedbackForLeaders();
    }

    return () => {
      resetFeedbackForLeaders();
    }
  }, [getQuestionsFeedbackForLeaders, getRatingsFeedbackForLeaders, getUser, resetFeedbackForLeaders, userId]);

  const userFullName = `${userFirstName} ${userLastName}`;
  const ratings = {
    values: ratingsValuesFeedbackForLeaders,
    notApply: ratingsNotApplyFeedbackForLeaders,
  };
  const isLastSection = (formSection === 2);
  const isLastTab = (formTab === (competencies.length));
  const indexTab = formTab - 1;
  const buttonAcceptLabel = (formSection === 1)
    ? formatMessage(actionsMessages.ActionsNext)
    : formatMessage(feedbackMessages.FeedbackSendFeedback);

  const isQuestionnaireValid = () => {
    return countBy(answersCompetencies, (object) => {
      if (object.value === 5) {
        return 'Totally Agree';
      } else if (object.value === 4) {
        return 'Agree'
      } else if (object.value === 3) {
        return 'Neither Agree or Disagree'
      } else if (object.value === 2) {
        return 'Disagree'
      } else if (object.value === 1) {
        return 'Strongly Disagree'
      }
    })
  }

  const infoMessage = formatMessage(messages.SendFeedbackForLeadersInfoMessage)
  const alertMessage = (isQuestionnaireValid()['Totally Agree'] > answersAllowed
    || isQuestionnaireValid()['Strongly Disagree'] > answersAllowed)
    ? formatMessage(messages.SendFeedbackForLeadersAlertMessage, {
      number: (isQuestionnaireValid()['Totally Agree'] > answersAllowed) ? isQuestionnaireValid()['Totally Agree'] - answersAllowed : isQuestionnaireValid()['Strongly Disagree'] - answersAllowed,
      valueName: (isQuestionnaireValid()['Totally Agree'] > answersAllowed) ? 'Totally Agree' : 'Strongly Disagree',
    })
    : null;

  function getIsFinishedCompetencies() {
    return (totalQuestions === size(answersCompetencies)) && (!((isQuestionnaireValid()['Totally Agree'] > answersAllowed)) &&
      !((isQuestionnaireValid()['Strongly Disagree'] > answersAllowed)))
  }

  function getIsFinishedInsights() {
    return every(answersInsights, (answer) => (
      answer.required ? answer.validated : !answer.required
    ));
  }

  function isEnabledNext() {
    return isEnabledContinue(indexTab, answersCompetencies, competencies);
  };

  function isDisabledNext() {
    if (!(lastSelectedTab > formTab)) {
    return isLastSection
      ? (!getIsFinishedCompetencies() || !getIsFinishedInsights())
      : (!isEnabledNext()
        || (((isQuestionnaireValid()['Totally Agree'] > answersAllowed) && isLastTab)
          || (isQuestionnaireValid()['Strongly Disagree'] > answersAllowed && isLastTab))
      );
    }
  };

  function handleChangeCompetency(questionData) {
    setAnswerCompetency(questionData);
  }

  function getAnswersList(answers) {
    return transform(answers, (acc, answer) => {
      const valueType = (answer.questionType === 'OPEN')
        ? 'answer'
        : 'ratingId';

      return ((answer.valueId || answer.value)
        ? acc.push({
          questionId: answer.id,
          [valueType]: answer.valueId || answer.value,
        })
        : acc
      );
    }, []);
  }

  function handleChangeInsight(questionData) {
    const {
      questionType,
      required,
      value,
    } = questionData;
    const validateType = (questionType === 'OPEN')
      ? value?.length >= MIN_LENGTH
      : value !== undefined;
    const validated = required ? validateType : !required;
    setAnswerInsight({ ...questionData, validated });
  };

  function handleChangeBack() {
    if (isLastSection) {
      setFormSection(1);
      trackEvent({
        action: 'Insights',
        category: 'Feedback for Leaders',
        label: 'Back'
      });
    } else {
      setFormTab(formTab - 1);
      trackEvent({
        action: 'Competencies',
        category: 'Feedback for Leaders',
        label: `Back_${competencies[formTab - 2]?.name}`
      });
    };
  };

  function breadcrumbClick() {
    if (formSection === 1) {
      trackEvent({
        action: 'Competencies',
        category: 'Feedback for Leaders',
        label: 'Leave'
      });
    } else {
      trackEvent({
        action: 'Insights',
        category: 'Feedback for Leaders',
        label: 'Leave'
      });
    }
  }

  function handleCreateFeedbackForLeaders() {
    const answersCompetenciesList = getAnswersList(answersCompetencies);
    const answersInsightsList = getAnswersList(answersInsights);
    const createFeedbackMessages = {
      successFooter: formatBoldText(formatMessage(messages.SendFeedbackForLeadersSendFeedbackSuccessDescription, {
        leaderName: userFullName,
      })),
      successContent: formatMessage(promotionsMessages.PromotionsWellDone),
    };

    createFeedbackForLeaders([
      ...answersCompetenciesList,
      ...answersInsightsList,
    ],
      createFeedbackMessages,
      user,
    );
  }

  function handleChangeAccept() {
    if (isLastSection) {
      handleCreateFeedbackForLeaders();
      let insightsAnswers = Object.values(answersInsights);
      let insightsQA = insightsAnswers?.map(({ id, value }) => `Question: ${id} - Answer: ${value} `).toString();

      trackEvent({
        action: 'Send Feedback',
        category: 'Feedback for Leaders',
        q_a: insightsQA,
        type: 'with_comment',
      });
    } else if (isLastTab && getIsFinishedCompetencies()) {
      setFormSection(2);
    } else if (!isLastTab) {
      if (isEnabledNext()) {
        setFormTab(formTab + 1);
        setLastSelectedTab(formTab + 1);
      }
    };

    let firstQA = Object.values(answersCompetencies)[(2 * formTab) - 2];
    let secondQA = Object.values(answersCompetencies)[(2 * formTab) - 1];
    trackEvent({
      action: 'Competencies',
      category: 'Feedback for Leaders',
      label: `Next_${competencies[formTab - 1]?.name}`,
      q_a: `Question: ${firstQA?.id} - Answer: ${firstQA?.value} , Question: ${secondQA?.id} - Answer: ${secondQA?.value}`
    });
  }

  function hideBeKindMessage() {
    setBeKindVisible(false);
  }

  return (
    <Segment
      className="send-feedback-for-leaders"
      loading={
        loadingUser
        || loadingCreateFeedbackForLeaders
        || loadingQuestionsFeedbackForLeaders
        || loadingRatingsFeedbackForLeaders
      }
      loadingType="betterme"
      withChildren={loadingCreateFeedbackForLeaders}
      noFixed={true}
    >
      <BreadcrumbHeader
        breadCrumbsProps={{
          active: 1,
          onClick: goToMyProfile,
          size: 'big',
          values: [
            formatMessage(userMessages.UserMyProfile),
            formatMessage(feedbackForLeadersMessages.FeedbackforLeaders),
          ]
        }}
        date={formatDateV2(null, 'monthYearFormat')}
        onBreadcrumbsClick={() => {
          breadcrumbClick();
          goToMyProfile();
        }}
        sectionBreadcrumb={formatMessage(userMessages.UserMyProfile)}
        title={formatMessage(feedbackForLeadersMessages.FeedbackforLeaders)}
      />
      <div className="send-feedback-for-leaders__content">
        <Grid>
          <Grid.Col tablet={3}>
            <UserDetailsInfo
              className="send-feedback-for-leaders__user-info"
              fullName={userFullName}
              job={userJob}
              profileImageCode={userProfileImageCode}
            />
            <FormStepper
              isFinishedCompetencies={getIsFinishedCompetencies()}
              isFinishedInsights={getIsFinishedInsights()}
              onChangeSection={setFormSection}
            />
          </Grid.Col>
          <Grid.Col
            className="send-feedback-for-leaders__fluid"
            tablet={9}
          >
            {(formSection === 1) && (
              <FormCompetencies
                alertMessage={alertMessage}
                answersCompetencies={answersCompetencies}
                competencies={competencies}
                currentTab={formTab}
                infoMessage={infoMessage}
                isDisabled={isDisabledNext()}
                isEnabled={isEnabledNext()}
                onChange={handleChangeCompetency}
                onChangeTab={setFormTab}
                ratings={ratings}
                lastSelectedTab={lastSelectedTab}
                totalQuestions={totalQuestions}
              />
            )}

            {(formSection === 2) && (
              <FormInsights
                answersInsights={answersInsights}
                bekindMessages={{
                  button: formatMessage(messages.SendFeedbackForLeadersBeKindButton),
                  text: formatMessage(messages.SendFeedbackForLeadersBeKindText),
                  title: formatMessage(messages.SendFeedbackForLeadersBeKindTitle),
                }}
                beKindVisible={beKindVisible && allowsGlbFeatures}
                onBeKindClick={hideBeKindMessage}
                onChange={handleChangeInsight}
                questions={insightQuestions}
                ratings={ratings}
              />
            )}
          </Grid.Col>
        </Grid>
      </div>
      <div className="send-feedback-for-leaders__actions">
        <div className="send-feedback-for-leaders__actions--content">
          <Button
            className="send-feedback-for-leaders__actions--content-cancel"
            disabled={formSection === 1 && formTab === 1}
            onClick={handleChangeBack}
            variant="outline"
          >
            {formatMessage(actionsMessages.ActionsBack)}
          </Button>
          <Button
            className="send-feedback-for-leaders__actions--content-send"
            onClick={handleChangeAccept}
            disabled={isDisabledNext()}
          >
            {buttonAcceptLabel}
          </Button>
        </div>
      </div>

      <ConfirmationCreateSuccessModal
        modalProps={{
          onClose: goToMyProfile,
        }}
      />
    </Segment>
  );

};

SendFeedbackForLeaders.propTypes = {
  answersAllowed: PropTypes.number,
  answersCompetencies: PropTypes.object.isRequired,
  answersInsights: PropTypes.object.isRequired,
  competenciesQuestions: PropTypes.array.isRequired,
  getQuestionsFeedbackForLeaders: PropTypes.func.isRequired,
  getRatingsFeedbackForLeaders: PropTypes.func.isRequired,
  getUser: PropTypes.object.isRequired,
  insightQuestions: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
  loadingCreateFeedbackForLeaders: PropTypes.bool,
  loadingQuestionsFeedbackForLeaders: PropTypes.bool,
  loadingRatingsFeedbackForLeaders: PropTypes.bool,
  loadingUser: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  ratingsNotApplyFeedbackForLeaders: PropTypes.object.isRequired,
  ratingsValuesFeedbackForLeaders: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
}

const goToMyProfile = () => push(getMyProfileLocation());

const mapStateToProps = state => ({
  allowsGlbFeatures: selectConfigurations('allowsGlbFeatures')(state),
  answersAllowed: selectFeedbackForLeadersAnswersAllowed(state),
  answersCompetencies: selectAnswersCompetencies(state),
  answersInsights: selectAnswersInsights(state),
  competenciesQuestions: selectCompetenciesQuestions(state),
  insightQuestions: selectInsightQuestions(state),
  loadingCreateFeedbackForLeaders: selectLoadingCreateFeedbackForLeaders(state),
  loadingQuestionsFeedbackForLeaders: selectLoadingQuestionsFeedbackForLeaders(state),
  loadingRatingsFeedbackForLeaders: selectLoadingRatingsFeedbackForLeaders(state),
  loadingUser: selectLoadingUser(state),
  ratingsNotApplyFeedbackForLeaders: selectRatingsNotApplyFeedbackForLeaders(state),
  ratingsValuesFeedbackForLeaders: selectRatingsValuesFeedbackForLeaders(state),
  user: selectUser(state),
});

const mapDispatchToProps = {
  ...actions,
  getUser,
  goToMyProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SendFeedbackForLeaders));
