import {
  GET_LEADERSHIP_REQUESTS_SENT,
  GET_LEADERSHIP_REQUESTS_SENT_FAIL,
  GET_LEADERSHIP_REQUESTS_SENT_SUCCESS,
} from './actionTypes';

const initialState = {
  leadershipRequestsPage: {},
  leadershipRequestsSent: [],
  leadershipRequestsSentError: '',
  leadershipRequestsSentLoading: false,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_LEADERSHIP_REQUESTS_SENT:
      return {
        ...state,
        leadershipRequestsSentLoading: true,
      };

    case GET_LEADERSHIP_REQUESTS_SENT_FAIL:
      return {
        ...state,
        leadershipRequestsSentError: payload.error,
        leadershipRequestsSentLoading: false,
      };

    case GET_LEADERSHIP_REQUESTS_SENT_SUCCESS:
      const {
        response: { result, page },
      } = payload;
      return {
        ...state,
        leadershipRequestsPage: page,
        leadershipRequestsSent: page.number
          ? [...state.leadershipRequestsSent, ...result]
          : result,
          leadershipRequestsSentLoading: false,
      };

    default:
      return state;
  }
}
