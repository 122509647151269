import {
  CREATE_ANSWER_PERFORMANCE_RETROSPECTIVE,
  CREATE_ANSWER_PERFORMANCE_RETROSPECTIVE_FAIL,
  CREATE_ANSWER_PERFORMANCE_RETROSPECTIVE_SUCCESS,
  GET_PERFORMANCE_RETROSPECTIVE_DETAIL,
  GET_PERFORMANCE_RETROSPECTIVE_DETAIL_FAIL,
  GET_PERFORMANCE_RETROSPECTIVE_DETAIL_SUCCESS,
  RESET_DATA_CREATE_ANSWER_PERFORMANCE_RETROSPECTIVE,
  RESET_PERFORMANCE_RETROSPECTIVE_DETAIL,
  SET_DATA_CREATE_ANSWER_PERFORMANCE_RETROSPECTIVE,
} from './actionTypes';


export function getPerformanceRetrospectiveDetail(evaluationId) {
  return {
    type: GET_PERFORMANCE_RETROSPECTIVE_DETAIL,
    payload: { evaluationId },
  };
};

export function getPerformanceRetrospectiveDetailFail(error) {
  return {
    type: GET_PERFORMANCE_RETROSPECTIVE_DETAIL_FAIL,
    payload: { error },
  };
};

export function getPerformanceRetrospectiveDetailSuccess(response) {
  return {
    type: GET_PERFORMANCE_RETROSPECTIVE_DETAIL_SUCCESS,
    payload: { response },
  };
};

export function resetPerformanceRetrospectiveDetail() {
  return {
    type: RESET_PERFORMANCE_RETROSPECTIVE_DETAIL,
  };
};

export function createAnswerPerformanceRetrospective(evaluationId, data) {
  return {
    type: CREATE_ANSWER_PERFORMANCE_RETROSPECTIVE,
    payload: { evaluationId, data },
  };
};

export function createAnswerPerformanceRetrospectiveFail(error) {
  return {
    type: CREATE_ANSWER_PERFORMANCE_RETROSPECTIVE_FAIL,
    payload: { error },
  };
};

export function createAnswerPerformanceRetrospectiveSuccess(response) {
  return {
    type: CREATE_ANSWER_PERFORMANCE_RETROSPECTIVE_SUCCESS,
    payload: { response },
  };
};

export function resetDataCreateAnswerPerformanceRetrospective() {
  return {
    type: RESET_DATA_CREATE_ANSWER_PERFORMANCE_RETROSPECTIVE,
  };
};

export function setDataCreateAnswerPerformanceRetrospective(props) {
  return {
    type: SET_DATA_CREATE_ANSWER_PERFORMANCE_RETROSPECTIVE,
    payload: props,
  };
};
