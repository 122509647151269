import {
  GET_FEEDBACK,
  REQUEST_COMMENTS_RESET
} from './actionTypes';

export function getFeedback(id) {
  return {
    type: GET_FEEDBACK,
    payload: id
  };
}

export function requestCommentsReset() {
  return {
    type: REQUEST_COMMENTS_RESET
  };
}
