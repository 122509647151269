// @packages
import PropTypes from 'prop-types';
import React from 'react';
import RoundedTag from 'smu-ui-components/RoundedTag';
import Tooltip from 'smu-labs/Tooltip';
import cn from 'classnames';

// @own
import './styles.scss';

const TagItem = ({
  className,
  description,
  fluid,
  inverted,
  isDragging,
  name,
  onClick,
  onDescriptionOpen,
  type,
  variant,
}) => {
  const classNames = cn('tag-item', className, {
    'tag-item--actionable': onClick,
    'tag-item--fluid': fluid,
    [`tag-item--${type}`]: type,
    [`tag-item--variant-${variant}`]: variant,
  });
  const content = description || name;

  return (
    <Tooltip
      content={isDragging ? '' : content}
      inverted={inverted}
      onOpen={onDescriptionOpen}
    >
      <span>
        <RoundedTag
          className={classNames}
          onClick={onClick}
        >
          {name}
        </RoundedTag>
      </span>
    </Tooltip>
  );
};

TagItem.defaultProps = {
  variant: 'outline',
}

TagItem.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  inverted: PropTypes.bool,
  isDragging: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onDescriptionOpen: PropTypes.func,
  type: PropTypes.oneOf(['primary', 'secondary']),
  variant: PropTypes.oneOf(['outline', 'filled']),
};

export default TagItem;
