export const CLOSED_STATUS = 'CLOSED';
export const MY_GOALS = 'MY_GOALS';
export const MY_TEAM_GOALS = 'MY_TEAM_GOALS';
export const OPENED_STATUS = 'OPENED';
export const PENDING_APPROVAL = 'PENDING_APPROVAL';
export const SHARED = 'SHARED';

// Goals FeatureDiscovery types
export const BAR_CONTINOUS = 'BAR_CONTINOUS';
export const BAR_DISCRETE = 'BAR_DISCRETE';
export const BAR_PERCENTAGE = 'BAR_PERCENTAGE';
export const UPDATE_BUTTON = 'UPDATE_BUTTON';

// Goals feed filters
export const INITIAL_FILTERS = {
  filterPeriodId: '',
  filterStatus: '',
  filterText: '',
  filterUser: '',
};

// Goals summary

export const INITIAL_SUMMARY = {
  opened: 0,
  closed: 0,
  pending: 0,
  total: 0,
};
