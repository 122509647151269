// @packages
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import cn from 'classnames';
import Segment from 'smu-ui-components/Segment';

// @app
import Title from 'components/atoms/Title';
import File from 'common/File';
import { fileDownload, fileDownloadReset } from 'common/File/actions';
import {
  selectFile,
  selectFileId,
  selectFileLoading,
} from 'common/File/selectors';
import { commonMessages, promotionsMessages } from 'common/messages';

// @own
import './styles.scss';

const AttachedFilesDetails = ({
  attachments,
  className,
  file,
  fileDownload,
  fileDownloadReset,
  fileId,
  fileLoading,
  intl: { formatMessage },
}) => {
  const isFileEmpty = Object.keys(file).length === 0;
  const hasAttachedFiles = attachments.length > 0;

  useEffect(() => {
    if (!isFileEmpty) {
      let downloadFileAnchor = document.createElement('a');
      downloadFileAnchor.setAttribute('download', file.name);
      downloadFileAnchor.setAttribute('href', `data:;base64,${file.byteArrayResource}`);
      downloadFileAnchor.style.display = 'none';
      document.body.appendChild(downloadFileAnchor);
      downloadFileAnchor.click();
      document.body.removeChild(downloadFileAnchor);
      fileDownloadReset();
    }
  }, [file]);

  return (
    <div className={cn('attached-files', className)}>
      <Title
        className={cn('attached-files__title', 'text-black', {
          'attached-files__title--bordered': hasAttachedFiles,
        })}
        size={14}
      >
        {formatMessage(commonMessages.AttachedFiles)}
      </Title>
      {hasAttachedFiles ? attachments.map((file, key) => (
        <Segment loading={fileLoading && fileId === file.id} loadingType="betterme">
          <File
            actionIcon="download"
            fileName={file.name}
            fileUrl={file.url}
            id={file.id}
            key={key}
            noPaddingHorizontal
            onActionClick={fileDownload}
          />
        </Segment>
      )) : (
        <>
          <span className="attached-files__empty">
            {formatMessage(promotionsMessages.PromotionsNoFilesAttached)}
          </span>
        </>
      )}
    </div>
  );
};

AttachedFilesDetails.propTypes = {
  attachments: PropTypes.array.isRequired,
  className: PropTypes.string,
  file: PropTypes.object.isRequired,
  fileDownload: PropTypes.func.isRequired,
  fileDownloadReset: PropTypes.func.isRequired,
  fileId: PropTypes.number,
  fileLoading: PropTypes.bool,
  intl: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  file: selectFile(state),
  fileId: selectFileId(state),
  fileLoading: selectFileLoading(state),
});

const mapDispatchToProps = {
  fileDownload,
  fileDownloadReset,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AttachedFilesDetails));
