// @packages
import { orderBy } from 'lodash';

const getGoalProgress = (progress, scores) => {
  if (!progress || !scores) {
    return 0;
  }

  const orderedscores = orderBy(scores, 'min');
  const maxScaleValue = orderedscores[orderedscores.length - 1].max;
  const progressPertentage = progress < maxScaleValue ? (progress * 100) / maxScaleValue : 100;

  return progressPertentage;
};

export default getGoalProgress;
