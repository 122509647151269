// @packages
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Image from 'smu-ui-components/Image';
import TextWrapper from 'smu-ui-components/TextWrapper';
import Popup from 'smu-ui-components/Popup';

// @own
import './styles.scss';

const SelectionChip = ({
  active,
  className,
  onClick,
  rates,
  showOverview,
  totalRates,
  item: {
    color,
    imageCode,
    label,
  },
}) => {
  const [hovered, setHovered] = useState(false);
  const [backgroundWidth, setBackgroundWidth] = useState(0);
  const coloredBackground = hovered || active || showOverview;
  const hideLabel = showOverview && !hovered && !active;
  const hideCounter = !showOverview || hovered || active;
  const chipStyles = {
    borderColor: color,
    color: active ? '#FFFFFF' : showOverview
      ? '#7C8089'  : color,
    fontWeight: active ? 'bold' : '400',
  };
  const backgroundStyles = {
    backgroundColor: coloredBackground ? color : '',
    opacity: active ? 1 : .2,
    width: `${backgroundWidth}%`
  };
  const imageStyles = {
    backgroundColor: color,
  };

  useEffect(() => {
    const width = hovered || active ? 100 : rates * 100 / totalRates;
    setBackgroundWidth(width || 0);
  }, [hovered, active, rates, totalRates]);

  function handleHover() {
    setHovered(!hovered);
  }

  return (
    <Popup
      content={label}
      inverted
      on="hover"
      position="bottom center"
      trigger={
        <div
          className={cn('selection-chip', className)}
          style={chipStyles}
        >
          <div
            className={cn('selection-chip__background', {
              'selection-chip__background--hovered': hovered,
            })}
            style={backgroundStyles}
          />
          <div
            className="selection-chip__overlay"
            onClick={onClick}
            onMouseOver={handleHover}
            onMouseLeave={handleHover}
          />
          <div
            className="selection-chip__image-wrapper"
            style={imageStyles}
          >
            <Image
              className="selection-chip__image"
              code={imageCode}
              height={17}
              scale={2}
              width={21}
            />
          </div>
          <div className={cn('selection-chip__content')}>
              <div className={cn('selection-chip__counter', {
                'selection-chip__counter--hidden': hideCounter,
              })}>
                {totalRates > 0 ? `${rates}/${totalRates}` : 0}
              </div>

            <TextWrapper ellipsis>
              <div className={cn('selection-chip__label', {
                'selection-chip__label--hidden': hideLabel,
                'selection-chip__label--bold': showOverview,
              })}>
                {label}
              </div>
            </TextWrapper>
          </div>
        </div>
      }
    />
  );
};

SelectionChip.defaultProps = {
  onClick: () => {},
  rates: 0,
  totalRates: 0,
};

SelectionChip.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  rates: PropTypes.number,
  showOverview: PropTypes.bool,
  totalRates: PropTypes.number,
  item: PropTypes.shape({
    color: PropTypes.string.isRequired,
    imageCode: PropTypes.string,
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default SelectionChip;
