// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'smu-ui-components/IconV2';
import Segment from 'smu-ui-components/Segment';

// @app
import ActionButtons from 'components/molecules/ActionButtons';
import ActionButtonsWithOptions from 'components/molecules/ActionButtonsWithOptions';

// @own
import './styles.scss';

const GenericModalAssessment = ({
  actionButtons,
  actionButtonsWithOptions,
  className,
  content,
  disabledClose,
  disclaimer,
  header,
  loading,
  onClose,
}) => (
  <Segment
    className={cn('generic-modal-assessment', className)}
    loading={loading}
    loadingType="betterme"
  >
    <div className={cn('generic-modal-assessment__header', {
      'generic-modal-assessment__header--secondary' : header,
    })}>
      {!disabledClose && (
        <Icon
          className="generic-modal-assessment__header-close"
          icon="close"
          size="small"
          onClick={onClose}
        />
      )}
      {header && (
        <div className='generic-modal-assessment__header-title' >
          {header}
        </div>
      )}
    </div>
    {content && (
      <div className="generic-modal-assessment__content">
        {content}
        {disclaimer && (
          <div className="generic-modal-assessment__content--disclaimer">
            {disclaimer}
          </div>
        )}
      </div>
    )}
    {(actionButtons || actionButtonsWithOptions) && (
      <div className={cn('generic-modal-assessment__footer', {
        'generic-modal-assessment__footer--options': actionButtonsWithOptions,
      })}>
        {actionButtons && (
          <ActionButtons 
          className="generic-modal-assessment__footer-buttons"
          buttons={actionButtons} 
          />
        )}
        {actionButtonsWithOptions && (
          <ActionButtonsWithOptions buttons={actionButtonsWithOptions} />
        )}
      </div>
    )}
  </Segment>
);

GenericModalAssessment.propTypes = {
  actionButtons: PropTypes.array,
  className: PropTypes.string,
  content: PropTypes.node,
  disabledClose: PropTypes.bool,
  header: PropTypes.node,
  loading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  success: PropTypes.bool,
  warning: PropTypes.bool,
};

export default GenericModalAssessment;
