import { defineMessages } from 'react-intl';

export default defineMessages({
  FeedbackforLeaders: {
    id: 'Betterme.Messages.feedbackForLeaders.FeedbackforLeaders',
    defaultMessage: 'Feedback for Leaders',
  },
  FeedbackForLeadersAnonymousMessage: {
    id: 'Betterme.Messages.feedbackForLeaders.Anonymous',
    defaultMessage: 'Remember that this feedback will be completely anonymous',
  },
  FeedbackForLeadersAll: {
    id: 'Betterme.Messages.feedbackForLeaders.All',
    defaultMessage: 'All',
  },
});
