// @packages
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RootModal from 'smu-app-components/RootModal';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';

// @app
import { GOAL_DETAIL_ACTION_CLOSE_MODAL } from '../../constants/modalTypes';

// @own
import GoalsActionsCloseModalUI from './GoalsActionsCloseModalUI';
import { trackGoalCloseCancel } from '../analytics';
import { selectLoadingClose } from '../selectors';

class GoalsActionsCloseModal extends Component {
  constructor(props) {
    super(props);
    const { defaultValue } = props;

    this.state = {
      selectedValue: defaultValue,
      comment: '',
      notApply: false,
    };

    this.handleAccept = this.handleAccept.bind(this);
    this.handleCalificationChange = this.handleCalificationChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedValue: prevSelectedValue } = prevState;
    const { selectedValue: currentselectedValue } = this.state;
    const {
      addToastMessage,
      defaultValue,
      messages: { lastAssessmentWarning },
    } = this.props;
    if (
      defaultValue
      && (Number(prevSelectedValue) !== Number(currentselectedValue))
      && (Number(currentselectedValue) !== defaultValue)
    ) {
      addToastMessage({
        message: lastAssessmentWarning,
        timeout: 3000,
      });
    }
  }

  isValid() {
    const { comment, notApply, selectedValue } = this.state;
    return Boolean(comment && (notApply || selectedValue));
  }

  handleAccept() {
    const { selectedValue, comment, notApply } = this.state;
    const { onAccept, goalId, isListGoals } = this.props;
    const score = notApply ? null : selectedValue;
    if (onAccept) {
      onAccept(goalId, score, comment, isListGoals);
    }
  }

  handleCalificationChange(selectedValue) {
    this.setState({ selectedValue });
  }

  handleCheckboxChange(event) {
    this.setState({ notApply: event.target.checked });
  }

  handleClose() {
    const { close } = this.props;
    trackGoalCloseCancel();
    close();
  }

  handleCommentChange(event) {
    this.setState({ comment: event.target.value });
  }

  getOptions() {
    const { options, isNumeric } = this.props;

    return !isNumeric ? options : undefined;
  }

  render() {
    const {
      acceptMessage,
      cancelMessage,
      loading,
      messages,
      options,
      range,
      title,
    } = this.props;
    const { notApply, selectedValue } = this.state;

    return (
      <GoalsActionsCloseModalUI
        acceptMessage={acceptMessage}
        cancelMessage={cancelMessage}
        close={this.handleClose}
        disableCalification={notApply}
        disableAccept={!this.isValid()}
        loading={loading}
        messages={messages}
        value={selectedValue}
        onAccept={this.handleAccept}
        onCalificationChange={this.handleCalificationChange}
        onCheckboxChange={this.handleCheckboxChange}
        onCommentChange={this.handleCommentChange}
        options={options}
        range={range}
        title={title}
      />
    );
  }
}

GoalsActionsCloseModal.propTypes = {
  acceptMessage: PropTypes.string,
  addToastMessage: PropTypes.func.isRequired,
  cancelMessage: PropTypes.string,
  close: PropTypes.func.isRequired,
  defaultValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  goalId: PropTypes.number.isRequired,
  isNumeric: PropTypes.bool,
  loading: PropTypes.bool,
  messages: PropTypes.shape({
    checkbox: PropTypes.string,
    comment: PropTypes.string,
    dropdown: PropTypes.string,
    lastAssessmentWarning: PropTypes.string,
    numberSelector: PropTypes.string,
  }),
  onAccept: PropTypes.func.isRequired,
  options: PropTypes.array,
  range: PropTypes.shape({
    max: PropTypes.number,
    min: PropTypes.number,
  }),
};

const mapStateToProps = (state) => ({
  loading: selectLoadingClose(state),
});

const modalStyles = { maxWidth: '600px' };
const withRootModalComponent = RootModal(
  connect(mapStateToProps, { addToastMessage })(GoalsActionsCloseModal),
  GOAL_DETAIL_ACTION_CLOSE_MODAL,
  modalStyles, 'goals-actions-close-modal__wrapper'
);

export default withRootModalComponent;
