/* eslint-disable no-restricted-globals */
// @packages
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import Icon from 'smu-ui-components/Icon';
import Link from 'smu-ui-components/Link';
import Popup from 'smu-ui-components/Popup';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @own
import './styles.scss';

const OptionsMenuV2 = ({
  actions,
  className,
  onClickMore,
}) => {
  const listRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = status => {
    setIsOpen(status);
  };

  const handleClickMore = () => {
    if (onClickMore) onClickMore();
    handleOpen(true);
  };

  useEffect(() => {
    const handleClick = () => {
      setIsOpen(false);
    };

    if (isOpen) {
      document.addEventListener('click', handleClick, false);
    }

    return () => open && document.removeEventListener('click', handleClick, false);
  }, [isOpen, setIsOpen]);

  return (
    <div className={cn('option-menu', className)}>
      <div className="option-menu__wrapper">
        <Icon
          icon="kebob-menu"
          size="semi-medium"
          onClick={handleClickMore}
        />
        <div
          className={cn('option-menu__list', {
            'option-menu__list--open': isOpen,
          })}
          ref={listRef}
        >
          {actions?.map((action, index) => {
            const {
              disabled,
              label,
              onClick,
              popupDescription,
              showPopup,
              to,
            } = action;
            const trigger = (
              <span
                key={index}
              >
                <Link
                  className={cn('option-menu__list-item', {
                    'option-menu__list-item--disabled': disabled,
                  })}
                  onClick={onClick}
                  to={to}
                >
                  {label}
                </Link>
              </span>
            );

            return (
              showPopup && popupDescription ? (
                <Popup
                  inverted
                  key={index}
                  position="left center"
                  trigger={trigger}
                >
                  {popupDescription}
                </Popup>
              ) : (
                trigger
              ));
          })}
        </div>
      </div>
    </div>
  );
};

OptionsMenuV2.propTypes = {
  actions: PropTypes.array,
  className: PropTypes.string,
  onClickMore: PropTypes.func,
};

OptionsMenuV2.defaultProps = {
  actions: [],
};

export default OptionsMenuV2;
