import {
  GET_USER,
  GET_USER_FAIL,
  GET_USER_SUCCESS,
  RESET_USER,
} from './actionTypes';

const initialState = {
  error: '',
  loadingUser: false,
  user: {},
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_USER:
      return { ...state, loadingUser: true };

    case GET_USER_FAIL:
      return {
        ...state,
        error: payload.error,
        loadingUser: false,
      };

    case GET_USER_SUCCESS:
      return {
        ...state,
        error: '',
        loadingUser: false,
        user: payload.response,
      };

    case RESET_USER:
      return {
        ...state,
        error: '',
        loadingUser: false,
        user: {},
      };

    default:
      return state;
  }
}
