import { buildClosePath } from '../helpers';

const culturalLevels = ({ open, onClose }) => {
  const closePath = buildClosePath();
  open({
    onClose: () => onClose(closePath),
  });
};

export default culturalLevels;
