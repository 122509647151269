import {
  GET_FEEDBACKS_SUMMARY_TEAM_FAIL,
  GET_FEEDBACKS_SUMMARY_TEAM_SUCCESS,
  GET_FEEDBACKS_SUMMARY_TEAM,
  GET_STARMEUP_KUDOS_INFO_FAIL,
  GET_STARMEUP_KUDOS_INFO_SUCCESS,
  GET_STARMEUP_KUDOS_INFO,
  GET_TEAM_EVALUATIONS_FAIL,
  GET_TEAM_EVALUATIONS_SUCCESS,
  GET_TEAM_EVALUATIONS,
  GET_TEAM_MEMBER_FEEDBACK_RECEIVED_FAIL,
  GET_TEAM_MEMBER_FEEDBACK_RECEIVED_SUCCESS,
  GET_TEAM_MEMBER_FEEDBACK_RECEIVED,
  GET_TEAM_MEMBER_FEEDBACKS_SUMMARY_FAIL,
  GET_TEAM_MEMBER_FEEDBACKS_SUMMARY_SUCCESS,
  GET_TEAM_MEMBER_FEEDBACKS_SUMMARY,
  GET_TEAM_MEMBER_OPPORTUNITIES_FAIL,
  GET_TEAM_MEMBER_OPPORTUNITIES_SUCCESS,
  GET_TEAM_MEMBER_OPPORTUNITIES,
  GET_TEAM_MEMBER_STARMEUP_STARS_SUMMARY_FAIL,
  GET_TEAM_MEMBER_STARMEUP_STARS_SUMMARY_SUCCESS,
  GET_TEAM_MEMBER_STARMEUP_STARS_SUMMARY,
  GET_TEAM_MEMBER_STRENGHTS_FAIL,
  GET_TEAM_MEMBER_STRENGHTS_SUCCESS,
  GET_TEAM_MEMBER_STRENGHTS,
  GET_TEAM_MEMBER_SUMMARY_GOALS_FAIL,
  GET_TEAM_MEMBER_SUMMARY_GOALS_SUCCESS,
  GET_TEAM_MEMBER_SUMMARY_GOALS,
  GET_TEAM_OPPORTUNITIES_FAIL,
  GET_TEAM_OPPORTUNITIES_SUCCESS,
  GET_TEAM_OPPORTUNITIES,
  GET_TEAM_STRENGHTS_FAIL,
  GET_TEAM_STRENGHTS_SUCCESS,
  GET_TEAM_STRENGHTS,
  RESET_TEAM_DASHBOARD_STATE,
} from './actionTypes';

import {
  INITIAL_SUMMARY_GOALS,
  INITIAL_FEEDBACKS_SUMMARY,
} from './constants';

const initialState = {
  evaluationsCount: [
    {
      statusName: 'submitted',
      count: 0,
    },
    {
      statusName: 'draft',
      count: 0,
    },
    {
      statusName: 'foreignDraft',
      count: 0,
    },
    {
      statusName: 'notStarted',
      count: 0,
    },
  ],
  feedbacksSummaryTeam: [],
  loadingOpportunities: false,
  loadingPromotionsRequests: false,
  loadingStrenghts: false,
  loadingSummaryTeam: false,
  loadingTeamEvaluations: false,
  loadingValueKudos: false,
  opportunities: [],
  opportunitiesRating: {},
  strengths: [],
  strengthsRating: {},
  teamMembers: [],
  valueKudos: undefined,
};

const teamMembersInitialState = {
  evaluationsScores: [],
  evaluationsTeam: {},
  feedbacksSummary: INITIAL_FEEDBACKS_SUMMARY,
  kudoStats: undefined,
  loadingFeedbacksSummary: false,
  loadingOpportunities: false,
  loadingStarmeUpValues: false,
  loadingStrenghts: false,
  loadingSummaryGoals: false,
  opportunities: [],
  opportunitiesRating: {},
  ratings: [],
  strengths: [],
  strengthsRating: {},
  summaryGoals: INITIAL_SUMMARY_GOALS,
  summaryStarmeUpValues: [],
  totalStars: {
    current: 0,
    historic: 0,
  },
};

function teamMembersReducer(state = teamMembersInitialState, { type, payload }) {
  switch (type) {
    case GET_TEAM_MEMBER_STARMEUP_STARS_SUMMARY:
      return { ...state, loadingStarmeUpValues: true };

    case GET_TEAM_MEMBER_STARMEUP_STARS_SUMMARY_FAIL:
      return { ...state, loadingStarmeUpValues: false };

    case GET_TEAM_MEMBER_STARMEUP_STARS_SUMMARY_SUCCESS:
      return {
        ...state,
        kudoStats: payload.kudoStats,
        loadingStarmeUpValues: false,
        summaryStarmeUpValues: payload.summary,
        totalStars: payload.totalStars,
      };

    case GET_TEAM_MEMBER_FEEDBACK_RECEIVED:
      return { ...state, loadingFeedbacks: true };

    case GET_TEAM_MEMBER_FEEDBACK_RECEIVED_FAIL:
      return { ...state, loadingFeedbacks: false };

    case GET_TEAM_MEMBER_FEEDBACK_RECEIVED_SUCCESS:
      return { ...state, loadingFeedbacks: false, ratings: payload.response.ratings };

    case GET_TEAM_MEMBER_OPPORTUNITIES:
      return { ...state, loadingOpportunities: true };

    case GET_TEAM_MEMBER_OPPORTUNITIES_FAIL:
      return { ...state, loadingOpportunities: false };

    case GET_TEAM_MEMBER_OPPORTUNITIES_SUCCESS:
      return {
        ...state,
        loadingOpportunities: false,
        opportunities: payload.response.competencies,
        opportunitiesRating: payload.response.rating,
      };

    case GET_TEAM_MEMBER_STRENGHTS:
      return { ...state, loadingStrenghts: true };

    case GET_TEAM_MEMBER_STRENGHTS_FAIL:
      return { ...state, loadingStrenghts: false };

    case GET_TEAM_MEMBER_STRENGHTS_SUCCESS:
      return {
        ...state,
        loadingStrenghts: false,
        strengths: payload.response.competencies,
        strengthsRating: payload.response.rating,
      };

    case GET_TEAM_MEMBER_SUMMARY_GOALS:
      return { ...state, loadingSummaryGoals: true };

    case GET_TEAM_MEMBER_SUMMARY_GOALS_FAIL:
      return { ...state, loadingSummaryGoals: false };

    case GET_TEAM_MEMBER_SUMMARY_GOALS_SUCCESS:
      return {
        ...state,
        loadingSummaryGoals: false,
        summaryGoals: payload.response.assignedTo,
      };

    case GET_TEAM_MEMBER_FEEDBACKS_SUMMARY:
      return { ...state, loadingFeedbacksSummary: true };

    case GET_TEAM_MEMBER_FEEDBACKS_SUMMARY_FAIL:
      return { ...state, loadingFeedbacksSummary: false };

    case GET_TEAM_MEMBER_FEEDBACKS_SUMMARY_SUCCESS:
      return {
        ...state,
        loadingFeedbacksSummary: false,
        feedbacksSummary: payload.response,
      };

    default:
      return state;
  }
}

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_FEEDBACKS_SUMMARY_TEAM:
      return { ...state, loadingSummaryTeam: true };

    case GET_FEEDBACKS_SUMMARY_TEAM_FAIL:
      return { ...state, loadingSummaryTeam: false, feedbacksSummaryTeam: [] };

    case GET_FEEDBACKS_SUMMARY_TEAM_SUCCESS:
      return { ...state, loadingSummaryTeam: false, feedbacksSummaryTeam: payload.response };

    case GET_STARMEUP_KUDOS_INFO:
      return { ...state, loadingValueKudos: true };

    case GET_STARMEUP_KUDOS_INFO_FAIL:
      return { ...state, loadingValueKudos: false };

    case GET_STARMEUP_KUDOS_INFO_SUCCESS:
      return { ...state, loadingValueKudos: false, valueKudos: payload.response };

    case GET_TEAM_MEMBER_STARMEUP_STARS_SUMMARY:
    case GET_TEAM_MEMBER_STARMEUP_STARS_SUMMARY_FAIL:
    case GET_TEAM_MEMBER_STARMEUP_STARS_SUMMARY_SUCCESS:
    case GET_TEAM_MEMBER_FEEDBACKS_SUMMARY:
    case GET_TEAM_MEMBER_FEEDBACKS_SUMMARY_FAIL:
    case GET_TEAM_MEMBER_FEEDBACKS_SUMMARY_SUCCESS:
    case GET_TEAM_MEMBER_FEEDBACK_RECEIVED:
    case GET_TEAM_MEMBER_FEEDBACK_RECEIVED_FAIL:
    case GET_TEAM_MEMBER_FEEDBACK_RECEIVED_SUCCESS:
    case GET_TEAM_MEMBER_OPPORTUNITIES:
    case GET_TEAM_MEMBER_OPPORTUNITIES_FAIL:
    case GET_TEAM_MEMBER_OPPORTUNITIES_SUCCESS:
    case GET_TEAM_MEMBER_STRENGHTS:
    case GET_TEAM_MEMBER_STRENGHTS_FAIL:
    case GET_TEAM_MEMBER_STRENGHTS_SUCCESS:
    case GET_TEAM_MEMBER_SUMMARY_GOALS:
    case GET_TEAM_MEMBER_SUMMARY_GOALS_FAIL:
    case GET_TEAM_MEMBER_SUMMARY_GOALS_SUCCESS:
      return {
        ...state,
        teamMembers: [
          ...state.teamMembers.filter(member => member.id !== payload.userId),
          {
            id: payload.userId,
            ...teamMembersReducer(
              state.teamMembers.find(member => member.id === payload.userId),
              { type, payload }
            ),
          }
        ]
      };

    case GET_TEAM_EVALUATIONS:
      return { ...state, loadingTeamEvaluations: true };

    case GET_TEAM_EVALUATIONS_FAIL:
      return { ...state, loadingTeamEvaluations: false };

    case GET_TEAM_EVALUATIONS_SUCCESS:
      return {
        ...state,
        loadingTeamEvaluations: false,
        evaluationsCount: payload.response,
      };
    case GET_TEAM_OPPORTUNITIES:
      return { ...state, loadingOpportunities: true };

    case GET_TEAM_OPPORTUNITIES_FAIL:
      return { ...state, loadingOpportunities: false };

    case GET_TEAM_OPPORTUNITIES_SUCCESS:
      return {
        ...state,
        loadingOpportunities: false,
        opportunities: payload.response.competencies,
        opportunitiesRating: payload.response.rating
      };

    case GET_TEAM_STRENGHTS:
      return { ...state, loadingStrenghts: true };

    case GET_TEAM_STRENGHTS_FAIL:
      return { ...state, loadingStrenghts: false };

    case GET_TEAM_STRENGHTS_SUCCESS:
      return {
        ...state,
        loadingStrenghts: false,
        strengths: payload.response.competencies,
        strengthsRating: payload.response.rating
      };

    case RESET_TEAM_DASHBOARD_STATE:
      return initialState;

    default:
      return state;
  }
}
