// @own
import {
  GET_PERIOD_BY_TYPE,
  GET_PERIOD_BY_TYPE_FAIL,
  GET_PERIOD_BY_TYPE_SUCCESS,
} from './actionTypes';

const initialState = {
  periodByType: [],
  periodByTypeLoading: false,
  periodByTypeError: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_PERIOD_BY_TYPE:
      return {
        ...state,
        periodByTypeLoading: true,
      };

    case GET_PERIOD_BY_TYPE_FAIL:
      return {
        ...state,
        periodByTypeLoading: false,
        periodByTypeError: payload,
      };

    case GET_PERIOD_BY_TYPE_SUCCESS:
      return {
        ...state,
        periodByType: payload,
        periodByTypeLoading: false,
      };

    default:
      return state;
  }
};
