// @packages
import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

// @own
import './styles.scss';

const InfoCountItem = ({
  as,
  blue,
  children,
  count,
  className,
}) => {
  const Tag = as;
  return (
    <Tag className={cn('info-count-item', className)}>
      <div className="info-count-item__children">
        {children}
      </div>
      <div className={cn('info-count-item__count', {
        'info-count-item__count--blue': blue,
      })}>
        {count}
      </div>
    </Tag>
  );
};

InfoCountItem.defaultProps = {
  as: 'li'
};

InfoCountItem.propTypes = {
  children: PropTypes.node.isRequired,
};

export default InfoCountItem;
