// @packages
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from 'smu-layout-components/Grid';
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import { push } from 'react-router-redux';

// @app
import * as goalDetailActions from 'common/GoalDetail/actions';
import * as goalsAssignedActions from 'common/GoalsAssigned/actions';
import {
  selectGoalsAssignedClosed,
  selectGoalsAssignedClosedLoading,
  selectGoalsAssignedOpened,
  selectGoalsAssignedOpenedLoading,
} from 'common/GoalsAssigned/selectors';
import {
  selectGoalDetail,
  selectGoalDetailLoading,
  selectGoalsPeriod,
  selectSelectedGoalId,
} from 'common/GoalDetail/selectors';
import BreadcrumbHeader from 'components/organisms/BreadcrumbHeader';
import SectionList from 'common/SectionList';
import { actionsMessages, goalsMessages, placeholdersMessages } from 'common/messages';
import { selectConfigurations } from '../Authorization/selectors';

// @own
import './styles.scss';
import AssignedGoalsDetail from './AssignedGoalsDetail';

class AssignedGoals extends Component {
  constructor() {
    super();

    this.getOutsideDateRange = this.getOutsideDateRange.bind(this);
    this.handleClosedDatePickerChange = this.handleClosedDatePickerChange.bind(this);
    this.handleOpenedDatePickerChange = this.handleOpenedDatePickerChange.bind(this);
    this.handleSelectGoal = this.handleSelectGoal.bind(this);
  }

  componentDidMount() {
    const {
      getGoalsAssignedClosed,
      getGoalsAssignedOpened,
      getGoalsPeriod,
      user,
    } = this.props;
    const { startDate, endDate } = this.getInitialDates();
    const startDateUnix = startDate ? startDate.valueOf() : undefined;
    const endDateUnix = endDate ? endDate.valueOf() : undefined;

    getGoalsPeriod();
    getGoalsAssignedClosed(user.id, { dueDateFrom: startDateUnix, dueDateTo: endDateUnix });
    getGoalsAssignedOpened(user.id, { dueDateFrom: startDateUnix, dueDateTo: endDateUnix });
  }

  componentDidUpdate(prevProps) {
    const {
      loadingClosed: wasLoadingClosed,
      loadingOpened: wasLoadingOpened,
    } = prevProps;
    const {
      goalsAssignedClosed,
      goalsAssignedOpened,
      loadingClosed: isLoadingClosed,
      loadingOpened: isLoadingOpened,
    } = this.props;

    if (
      wasLoadingOpened
      && !isLoadingOpened
      && goalsAssignedOpened.length > 0
    ) {
      this.handleSelectGoal(goalsAssignedOpened[0].id);
    }

    if (
      wasLoadingClosed
      && !isLoadingClosed
      && goalsAssignedClosed.length > 0
      && goalsAssignedOpened.length === 0
    ) {
      this.handleSelectGoal(goalsAssignedClosed[0].id);
    }
  }

  componentWillUnmount() {
    const { resetGoalsAssigned, resetGoalDetail } = this.props;
    resetGoalsAssigned();
    resetGoalDetail();
  }

  getInitialDates() {
    const { location: { state } } = this.props;
    let initialDates = {};

    if (state) {
      const { endDate, startDate } = state;
      initialDates.endDate = endDate ? moment(endDate) : undefined;
      initialDates.startDate = startDate ? moment(startDate) : undefined;
    }

    return initialDates;
  }

  getOutsideDateRange(day, goalsPeriodEndTime) {
    const rangeEnd = moment(goalsPeriodEndTime);
    return day.isAfter(rangeEnd, 'day');
  }

  getSectionListSections(goalsAssigned) {
    return goalsAssigned.map(goalAssigned => ({
      displayValue: goalAssigned.title, value: goalAssigned.id
    }));
  }

  handleClosedDatePickerChange({ startDate, endDate }) {
    const { getGoalsAssignedClosed, user } = this.props;
    const startDateUnix = startDate ? startDate.valueOf() : undefined;
    const endDateUnix = endDate ? endDate.valueOf() : undefined;

    getGoalsAssignedClosed(user.id, { dueDateFrom: startDateUnix, dueDateTo: endDateUnix });
  }

  handleOpenedDatePickerChange({ startDate, endDate }) {
    const { getGoalsAssignedOpened, user } = this.props;
    const startDateUnix = startDate ? startDate.valueOf() : undefined;
    const endDateUnix = endDate ? endDate.valueOf() : undefined;

    getGoalsAssignedOpened(user.id, { dueDateFrom: startDateUnix, dueDateTo: endDateUnix });
  }

  handleSelectGoal(goalId) {
    const { getGoalDetail, selectGoal, user } = this.props;
    selectGoal(goalId);
    getGoalDetail(goalId, user.id);
  }

  render() {
    const {
      goalDetail,
      goalsAssignedClosed,
      goalsAssignedOpened,
      goalsPeriod,
      intl: { formatMessage },
      isPercentage,
      loadingClosed,
      loadingDetail,
      loadingOpened,
      messagesEmptyStates,
      navigateBack,
      navigateBackLabel,
      navigateBackTo,
      selectedGoalId,
    } = this.props;

    const {
      endDate: initialEndDate,
      startDate: initialStartDate,
    }  = this.getInitialDates();

    return (
      <div className="assigned-goals">
        <BreadcrumbHeader
          breadCrumbsProps={{
            active: 1,
            onClick: () => navigateBack(navigateBackTo, { initialEndDate, initialStartDate }),
            size: 'big',
            values: [
              navigateBackLabel,
              formatMessage(goalsMessages.GoalsGoals),
            ]
          }}
        />
        <div className="assigned-goals__content">
          <Grid>
            <Grid.Col desktop={4}>
              <SectionList
                className="assigned-goals__section-list"
                datePicker={{
                  actionsLabel: {
                    apply: formatMessage(actionsMessages.ActionsApply),
                    clear: formatMessage(actionsMessages.ActionsClear),
                  },
                  align: 'right',
                  endDatePlaceholder: formatMessage(placeholdersMessages.PlaceholdersTo),
                  initialEndDate,
                  initialStartDate,
                  onChange: this.handleOpenedDatePickerChange,
                  startDatePlaceholder: formatMessage(placeholdersMessages.PlaceholdersFrom),
                  styleInput: 'short',
                  type: 'betterme',
                }}
                loading={loadingOpened}
                maxItems={4}
                onSelect={this.handleSelectGoal}
                messagesEmptyStates={messagesEmptyStates.openGoals}
                sections={this.getSectionListSections(goalsAssignedOpened)}
                title={formatMessage(goalsMessages.GoalsInProgress)}
                toggleLabel={{
                  less: formatMessage(actionsMessages.ActionsViewLess),
                  more: formatMessage(actionsMessages.ActionsViewMore),
                }}
                value={selectedGoalId}
              />

              <SectionList
                className="assigned-goals__section-list"
                datePicker={{
                  actionsLabel: {
                    apply: formatMessage(actionsMessages.ActionsApply),
                    clear: formatMessage(actionsMessages.ActionsClear),
                  },
                  align: 'right',
                  endDatePlaceholder: formatMessage(placeholdersMessages.PlaceholdersTo),
                  initialEndDate,
                  initialStartDate,
                  isOutsideRange: day => this.getOutsideDateRange(day, goalsPeriod.endTime),
                  onChange: this.handleClosedDatePickerChange,
                  startDatePlaceholder: formatMessage(placeholdersMessages.PlaceholdersFrom),
                  styleInput: 'short',
                  type: 'betterme',
                }}
                loading={loadingClosed}
                maxItems={4}
                onSelect={this.handleSelectGoal}
                messagesEmptyStates={messagesEmptyStates.closedGoals}
                sections={this.getSectionListSections(goalsAssignedClosed)}
                title={formatMessage(goalsMessages.GoalsClosedGoals)}
                toggleLabel={{
                  less: formatMessage(actionsMessages.ActionsViewLess),
                  more: formatMessage(actionsMessages.ActionsViewMore),
                }}
                value={selectedGoalId}
              />
            </Grid.Col>
            <Grid.Col desktop={8}>
              {!isEmpty(goalDetail) && (
                <AssignedGoalsDetail
                  goalAssignedDetail={goalDetail}
                  isPercentage={isPercentage}
                  loading={loadingDetail}
                />
              )}
            </Grid.Col>
          </Grid>
        </div>
      </div>
    );
  }
}

AssignedGoals.propTypes = {
  goalDetail: PropTypes.object,
  goalsAssignedClosed: PropTypes.array.isRequired,
  goalsAssignedOpened: PropTypes.array.isRequired,
  goalsPeriod: PropTypes.object.isRequired,
  isPercentage: PropTypes.bool.isRequired,
  loadingClosed: PropTypes.bool,
  loadingDetail: PropTypes.bool,
  loadingOpened: PropTypes.bool,
  messagesEmptyStates: PropTypes.shape({
    main: PropTypes.string,
    suggestion: PropTypes.string,
  }),
  selectedGoalId: PropTypes.number,
};

const mapStateToProps = (state) => ({
  goalDetail: selectGoalDetail(state),
  goalsAssignedClosed: selectGoalsAssignedClosed(state),
  goalsAssignedOpened: selectGoalsAssignedOpened(state),
  goalsPeriod: selectGoalsPeriod(state),
  isPercentage: selectConfigurations('allowsPercentageProgress')(state),
  loadingClosed: selectGoalsAssignedClosedLoading(state),
  loadingDetail: selectGoalDetailLoading(state),
  loadingOpened: selectGoalsAssignedOpenedLoading(state),
  selectedGoalId: selectSelectedGoalId(state),
});

const mapDispatchToProps = {
  ...goalDetailActions,
  ...goalsAssignedActions,
  navigateBack: (to, state) => push({ pathname: to, state }),
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AssignedGoals));
