// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import cn from 'classnames';
import Segment from 'smu-ui-components/Segment';
import Divider from 'smu-ui-components/Divider';

// @own
import './styles.scss';
import messages from '../messages';
import CompetenciesCardItemValue from './CompetenciesCardItemValue';
import CompetenciesCardItemNotApply from './CompetenciesCardItemNotApply';

const CompetenciesCard = ({
  competenciesDescription,
  intl: { formatMessage },
  onChange,
  questionNumber,
  ratings: {
    values: ratingsValues,
    notApply: ratingsNotApply,
  },
  totalQuestions,
  answer,
}) => {

  function handleCheckboxChange(valueId, event) {
    const { target: { value } } = event;
    const parseValue = parseInt(value, 10);

    onChange(parseValue, valueId);
  }

  return (
    <Segment className="competencies-card">
      <div className="competencies-card__header">
        <span className="competencies-card__header-counter">
          {`${questionNumber}
            ${formatMessage(messages.SendFeedbackForLeadersOf)}
            ${totalQuestions}
            ${formatMessage(messages.SendFeedbackForLeadersQuestion)}`}
        </span>
        <p className={cn('competencies-card__header-text', {
          'competencies-card__disabled': answer?.value === 0,
        })}>
          {competenciesDescription}
        </p>
      </div>
      <div className="competencies-card__content">
        <div className="competencies-card__inputs-container">
          <div className={cn('competencies-card__ratings', {
            'competencies-card__disabled': answer?.value === 0,
          })}>
            {ratingsValues.map(rating => (
              <CompetenciesCardItemValue
                description={rating.name}
                imageCode={rating.imageCode}
                imageCodeHover={rating.oppositeImageCode}
                isChecked={answer?.value === rating.value}
                onChange={(event) => handleCheckboxChange(rating.id, event)}
                radioId={`rating-${questionNumber}-${rating.id}`}
                value={rating.value}
              />
            ))}
          </div>
          <div className="competencies-card__checkbox">
            {ratingsNotApply.map(rating => (
              <CompetenciesCardItemNotApply
                description={rating.name}
                isChecked={answer?.value === rating.value}
                onChange={(event) => handleCheckboxChange(rating.id, event)}
                radioId={`rating-${questionNumber}-${rating.id}`}
                value={rating.value}
              />
            ))}
          </div>
        </div>
      </div>
    </Segment>
  );
};

CompetenciesCard.propTypes = {
  answer: PropTypes.object,
  competenciesDescription: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  questionNumber: PropTypes.number.isRequired,
  ratings: PropTypes.shape({
    values: PropTypes.array.isRequired,
    notApply: PropTypes.array.isRequired,
  }).isRequired,
  totalQuestions: PropTypes.number.isRequired,
}

export default injectIntl(CompetenciesCard);
