import {
  REQUEST_FEED,
  REQUEST_COMMENTS,
  SEND_COMMENT
} from './actionTypes';

export function getFeed(params) {
  return {
    type: REQUEST_FEED,
    payload: params
  };
}

export function getComments(feedbackId) {
  return {
    type: REQUEST_COMMENTS,
    payload: { feedbackId }
  };
}

export function sendComment(feedbackId, comment) {
  return {
    type: SEND_COMMENT,
    payload: { feedbackId, comment }
  };
}
