// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import Segment from 'smu-ui-components/Segment';

// @app
import {
  commonMessages,
  goalsMessages,
  sectionsMessages,
} from 'common/messages';
import { formatPeriodDates } from 'utils/formatPeriodDates';
import DownloadFile from 'betterme-components/components/containers/DownloadFile';
import ContinuousProgressBar from 'common/ContinuousProgressBar';
import DataList from 'common/DataList';
import DiscreteProgressBar from 'smu-ui-components/DiscreteProgressBar';
import GoalAssessmentList from 'common/GoalAssessmentList';
import GoalScoreSummary from 'common/GoalScoreSummary';
import isScaleDiscrete from 'utils/isScaleDiscrete';
import OrgObjectivesList from '../../Goals/TitleNWeight/OrgObjectivesList';

// @own
import './styles.scss';
import messages from '../messages';

const AssignedGoalsDetail = ({
  goalAssignedDetail: {
    assignment,
    attachments,
    description,
    dueDate,
    evaluator,
    period,
    scales,
    title,
    weighting,
    progress,
    summary,
    assessments,
    objectives,
  },
  intl: { formatMessage },
  isPercentage,
  loading,
}) => {
  const dataListItems = [
    {
      label: formatMessage(commonMessages.Period),
      value: formatPeriodDates(period.startTime, period.endTime),
      shouldRender: period,
    },
    {
      label: formatMessage(sectionsMessages.SectionsDueDate),
      value: moment(dueDate).utc().format('L'),
      shouldRender: dueDate,
    },
    {
      label: formatMessage(goalsMessages.GoalsWeight),
      value: weighting,
      shouldRender: weighting,
    },
    {
      label: formatMessage(goalsMessages.GoalsProgress),
      value: assignment === 'SHARED' ? 'Shared' : 'Individual',
      shouldRender: assignment,
    },
    {
      label: formatMessage(commonMessages.Evaluator),
      value: evaluator?.name,
      shouldRender: evaluator,
    },
    {
      label: formatMessage(goalsMessages.GoalsOrgObjectives),
      value: <OrgObjectivesList orgObjectives={objectives} />,
      shouldRender: objectives.length > 0,
    },
    {
      label: formatMessage(commonMessages.AttachedFiles),
      value: attachments?.length,
      shouldRender: attachments?.length,
    },
  ];
  const isGoalScoreContinous = !isScaleDiscrete(scales);

  return (
    <Segment
      className="assigned-goals-detail"
      loading={loading}
      loadingType="betterme"
    >
      <div className="assigned-goals-detail__section">
        <h3 className="assigned-goals-detail__title">{title}</h3>
        <DataList
          className="assigned-goals-detail__general-data"
          items={dataListItems.filter((item) => item.shouldRender)}
        />
        <div className="assigned-goals-detail__description">
          <h2 className="assigned-goals-detail__section-title">
            {formatMessage(sectionsMessages.SectionsDescription)}:
          </h2>
          <pre className="assigned-goals-detail__description-text">
            {description}
          </pre>
        </div>
        <div className="assigned-goals-detail__download-file-wrapper">
          <DownloadFile
            attachments={attachments}
            className="assigned-goals-detail__download-file"
          />
        </div>
      </div>

      <div className="assigned-goals-detail__section">
        <div className="assigned-goals-detail__progress">
          <h2 className="assigned-goals-detail__section-title">
            {formatMessage(goalsMessages.GoalsProgress)}
          </h2>

          {(isGoalScoreContinous || isPercentage) ? (
            <ContinuousProgressBar
              className="assigned-goals-detail__progress-bar"
              isPercentage={isPercentage}
              progress={progress || 0}
              scores={scales}
            />
          ) : (
            <DiscreteProgressBar
              backgroundColor="#FFFFFF"
              className="assigned-goals-detail__progress-bar"
              emptyColor="#F0F2F5"
              progress={progress}
              scores={scales}
              width={720}
            />
          )}

          {summary && (
            <div className="assigned-goals-detail__progress-summary">
              <GoalScoreSummary
                comment={summary.comment}
                name={summary.message}
                score={summary.score}
              />
            </div>
          )}
        </div>

        {(assessments.length > 0) && (
          <div className="assigned-goals-detail__assesments">
            <h2 className="assigned-goals-detail__section-title">
              {formatMessage(messages.AssignedGoalsDetailAssesments)}
            </h2>
            <GoalAssessmentList
              assessments={assessments}
              isPercentage={isPercentage}
              scales={scales}
            />
          </div>
        )}
      </div>
    </Segment>
  );
};

AssignedGoalsDetail.defaultProps = {
  goalAssignedDetail: {
    assessments: [],
    summary: {},
    progress: 0,
  },
};

AssignedGoalsDetail.propTypes = {
  goalAssignedDetail: PropTypes.shape({
    assessments: PropTypes.array.isRequired,
    assignment: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    dueDate: PropTypes.number.isRequired,
    evaluator: PropTypes.object.isRequired,
    period: PropTypes.object.isRequired,
    progress: PropTypes.number,
    scales: PropTypes.array.isRequired,
    summary: PropTypes.object,
    title: PropTypes.string.isRequired,
    weighting: PropTypes.number.isRequired,
  }).isRequired,
  intl: PropTypes.object.isRequired,
  isPercentage: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
};

export default injectIntl(AssignedGoalsDetail);
