// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

// @app
import messages from 'betterme-components/Dashboard/messages';
import EmptyStateImg from 'betterme-components/assets/FeedbackForLeadersResultsEmpty.svg';

// @own
import './styles.scss';

const EmptyState = ({
  intl: { formatMessage },
  user,
}) => (
  <div className="empty-state">
    <img
      alt="Empty State"
      className="empty-state__image"
      src={EmptyStateImg}
    />
    <div className="empty-state__text">
      {formatMessage(messages.DashboardFeedbackForLeadersEmpty,
        {username: `${user.firstName} ${user.lastName}`})}
    </div>
  </div>
);

EmptyState.propTypes = {
  intl: PropTypes.object.isRequired,
}

export default injectIntl(EmptyState);
