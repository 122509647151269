// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @app
import Reasons from 'components/molecules/Reasons';

// @own
import './styles.scss';

const AssessmentCard = ({
  cardId,
  cardLabel,
  cardOptions,
  className,
  counter,
  onOptionChange,
  selectedOption,
}) => {

  const handleOption = (event) => {
    onOptionChange({
      answer: +event.target.value,
      cardId,
    }, event);
  };

  return (
    <div className={cn('assessment-card', className)}>
      <span className="assessment-card__counter">
        {counter}
      </span>
      <div className="assessment-card__question">
        <div className="assessment-card__question-description">
          {cardLabel}
        </div>
        <div className="assessment-card__question-options">
          <Reasons
            className="assessment-card__question-options-items"
            onChange={handleOption}
            reasons={cardOptions}
            optionsId={cardId}
            selectedReason={selectedOption}
          />
        </div>
      </div>
    </div>
  )
}

AssessmentCard.defaultProps = {
  onOptionChange: () => { },
  selectedOption: null,
};

AssessmentCard.propTypes = {
  cardId: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  cardLabel: PropTypes.string,
  cardOptions: PropTypes.array,
  className: PropTypes.string,
  counter: PropTypes.string,
  onOptionChange: PropTypes.func,
  question: PropTypes.string,
  selectedOption: PropTypes.any,
};

export default AssessmentCard;
