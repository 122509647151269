// @packages
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Segment from 'smu-ui-components/Segment';

// @app
import Title from 'components/atoms/Title';
import File from 'betterme-components/components/molecules/File';

// @own
import './styles.scss';

const DownloadFile = ({
  attachments,
  className,
  file,
  fileDownload,
  fileDownloadReset,
  fileId,
  fileLoading,
  messages,
  newStyle,
}) => {
  const isFileEmpty = Object.keys(file)?.length === 0;
  const hasAttachedFiles = attachments?.length > 0;

  useEffect(() => {
    if (!isFileEmpty) {
      let downloadFileAnchor = document.createElement('a');
      downloadFileAnchor.setAttribute('download', file.name);
      downloadFileAnchor.setAttribute('href', `data:;base64,${file.byteArrayResource}`);
      downloadFileAnchor.style.display = 'none';
      document.body.appendChild(downloadFileAnchor);
      downloadFileAnchor.click();
      document.body.removeChild(downloadFileAnchor);
      fileDownloadReset();
    }
  }, [file]);

  return (
    <div className={cn('download-file', className)}>
      <Title size={18}>
        {messages.title}
      </Title>
      {hasAttachedFiles ? attachments.map((file, key) => (
        <Segment loading={fileLoading && fileId === file.id} loadingType="betterme">
            <File
              actionIcon="download"
              fileName={file.name}
              fileUrl={file.url}
              id={file.id}
              isDownloadFiles
              key={key}
              noPaddingHorizontal
              onActionClick={fileDownload}
            />
          </Segment>
        )) : (
        <>
          <span className="download-file__empty">
            {messages.noFilesAttached}
          </span>
        </>
      )}
    </div>
  );
};

DownloadFile.propTypes = {
  attachments: PropTypes.array.isRequired,
  className: PropTypes.string,
  file: PropTypes.object.isRequired,
  fileDownload: PropTypes.func.isRequired,
  fileDownloadReset: PropTypes.func.isRequired,
  fileId: PropTypes.number,
  fileLoading: PropTypes.bool,
}

export default DownloadFile
