import {
  CREATE_ANSWER_PERFORMANCE_RETROSPECTIVE,
  CREATE_ANSWER_PERFORMANCE_RETROSPECTIVE_SUCCESS,
  GET_PERFORMANCE_RETROSPECTIVE_DETAIL,
  GET_PERFORMANCE_RETROSPECTIVE_DETAIL_FAIL,
  GET_PERFORMANCE_RETROSPECTIVE_DETAIL_SUCCESS,
  RESET_DATA_CREATE_ANSWER_PERFORMANCE_RETROSPECTIVE,
  RESET_PERFORMANCE_RETROSPECTIVE_DETAIL,
  SET_DATA_CREATE_ANSWER_PERFORMANCE_RETROSPECTIVE,
} from './actionTypes';

const initialState = {
  dataCreateAnswerPerformanceRetrospective: {
    approved: null,
    comment: '',
  },
  performanceRetrospectiveDetail: {},
  isFetching: false,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_PERFORMANCE_RETROSPECTIVE_DETAIL:
      return { ...state, isFetching: true };
    case GET_PERFORMANCE_RETROSPECTIVE_DETAIL_FAIL:
      return { ...state, isFetching: false };
    case GET_PERFORMANCE_RETROSPECTIVE_DETAIL_SUCCESS:
      return { ...state, isFetching: false, performanceRetrospectiveDetail: payload.response };
    case RESET_PERFORMANCE_RETROSPECTIVE_DETAIL:
      return { ...state, isFetching: false, performanceRetrospectiveDetail: {} };
    case CREATE_ANSWER_PERFORMANCE_RETROSPECTIVE:
    case CREATE_ANSWER_PERFORMANCE_RETROSPECTIVE_SUCCESS:
        return { ...state, isFetching: false, responseAnswerEvaluation: payload.response };
    case SET_DATA_CREATE_ANSWER_PERFORMANCE_RETROSPECTIVE:
      return { ...state, dataCreateAnswerPerformanceRetrospective: { ...state.dataCreateAnswerPerformanceRetrospective, ...payload } };
    case RESET_DATA_CREATE_ANSWER_PERFORMANCE_RETROSPECTIVE:
      return {
        ...state,
        dataCreateAnswerPerformanceRetrospective: {
          approved: null,
          comment: '',
        }
      };
    default:
      return state;
  };
};
