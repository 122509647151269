// @packages
import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

// @app
import AvatarMedia from 'smu-ui-components/AvatarMedia';

// @own
import './styles.scss';

const AvatarMediaList = ({ className, type, users }) => {
  return (
    <ul className={`avatar-media-list ${className || ''}`}>
      {users.map((user, index) => {
        const avatarMediaListClass = cn('avatar-media-list__item', {
          'avatar-media-list__item--self': !index
        });

        return (
          <li className={avatarMediaListClass} key={user.id}>
            <AvatarMedia {...{ ...user, type }} />
          </li>
        );
      })}
    </ul>
  );
};

AvatarMediaList.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  users: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  ),
};

export default AvatarMediaList;
