import { takeLatest, call, put } from 'redux-saga/effects';
import { openModal } from 'smu-app-components/RootModal/actions';
import { LEADER_CONFIRMATION_MODAL } from '../../constants/modalTypes';
import { LEADER_ACTION_REQUEST } from '../../constants/leader';
import {
  getLeaderRequestModalInfo as getLeaderRequestModalInfoApi
} from '../../api';
import { GET_LEADER_MODAL_INFO } from './actionTypes';
import {
  getLeaderRequestModalInfoFail,
  getLeaderRequestModalInfoSuccess,
} from './actions';

export function* getLeaderRequestModalInfoWorker({ payload: { requestId }}) {
  try {
    const response = yield call(getLeaderRequestModalInfoApi, requestId);
    yield put(getLeaderRequestModalInfoSuccess(response));
    yield put(openModal({
      modalType: LEADER_CONFIRMATION_MODAL,
      modalProps: {
        action: LEADER_ACTION_REQUEST,
        user: response.result.leader,
        requestId: response.result.id,
      },
    }));
    return { response };
  } catch (error) {
    yield put(getLeaderRequestModalInfoFail(error.message));
    return { error };
  }
}

export default function* getLeaderRequestModalInfoWatcher() {
  yield takeLatest(GET_LEADER_MODAL_INFO, getLeaderRequestModalInfoWorker);
}
