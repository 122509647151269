import { defineMessages } from 'react-intl';
export default defineMessages({
  SelfEvaluationDetailTitle: {
    id: 'Betterme.EvaluationDetail.Title',
    defaultMessage: 'Evaluation',
  },
  SelfEvaluationDetailSendBy: {
    id: 'Betterme.EvaluationDetail.SendBy',
    defaultMessage: 'Sent by',
  },
  SelfEvaluationDetailWrittenBy: {
    id: 'Betterme.EvaluationDetail.WrittenBy',
    defaultMessage: 'Written by',
  },
  SelfEvaluationDetailEvalueeEvaluationMainText: {
    id: 'Betterme.SelfEvaluation.EvalueeMainText',
    defaultMessage: 'Self-Retrospective of',
  },
  SelfEvaluationDetailSuggestPromotion: {
    id: 'Betterme.EvaluationDetail.SuggestPromotion',
    defaultMessage: 'Suggested Promotion',
  },
  SelfEvaluationDetail: {
    id: 'Betterme.EvaluationDetail.Evaluations',
    defaultMessage: 'My Evaluations',
  },
  SelfEvaluationDetailPerformanceScore: {
    id: 'Betterme.SelfEvaluation.PerformanceScore',
    defaultMessage: 'PERFORMANCE SCORE',
  },
  SelfEvaluationDetailBehavioralAssessment: {
    id: 'Betterme.SelfEvaluation.BehavioralAssessment',
    defaultMessage: 'Behavioral Assessment',
  },
  SelfEvaluationDetailSkills: {
    id: 'Betterme.SelfEvaluation.Skills',
    defaultMessage: 'Skills',
  },
  SelfEvaluationDetailEvaluations: {
    id: 'Betterme.PerformanceRetrospectiveDetail.MyEvaluations',
    defaultMessage: 'My Evaluations',
  },
  SelfEvaluationDetailMyTeamEvaluations: {
    id: 'Betterme.PerformanceRetrospectiveDetail.MyTeamEvaluations',
    defaultMessage: 'My Team\'s Evaluations',
  },
});
