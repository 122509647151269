// @packages
import React from 'react';
import Button from 'smu-ui-components/ButtonV2';
import Icon from 'smu-ui-components/Icon';
import PropTypes from 'prop-types';
import RootModal from 'smu-app-components/RootModal';
import { injectIntl } from 'react-intl';
import { trackEvent } from 'smu-utils/gtm';

// @app
import { FEEDBACK_FOR_LEADERS_INFO_MODAL } from 'betterme-components/constants/modalTypes';
import ModalIlustration from 'betterme-components/assets/ilustracion_modal.svg';
import { actionsMessages } from 'common/messages';

// @own
import messages from './messages';
import './styles.scss';

const FeedbackForLeadersInfoModal = ({
  close,
  intl: { formatMessage },
  onAccept,
  allowsGlbFeatures,
}) => {
  const handleCloseModal = () => {
    close();
    trackEvent({
      action: 'Modal',
      category: 'Feedback for Leaders',
      label: 'Main_Cancel'
    });
  };

  const handleContinue = () => {
    close();
    onAccept();
    trackEvent({
      action: 'Modal',
      category: 'Feedback for Leaders',
      label: 'Main_Continue'
    });
  };

  return (
    <div className="feedback-for-leaders-info-modal">
      <header className="feedback-for-leaders-info-modal__header">
        <div className="feedback-for-leaders-info-modal__header-icon">
          <Icon
            color="white"
            icon="info"
            size={60}
          />
        </div>
        <div>
          <h3 className="feedback-for-leaders-info-modal__header-title">
            {formatMessage(messages.FeedbackForLeadersTitle)}
          </h3>
          <p className="feedback-for-leaders-info-modal__header-text">
            {formatMessage(messages.FeedbackForLeadersSubTitle)}
          </p>
        </div>
        <div className="feedback-for-leaders-info-modal__header-close-icon">
          <Icon
            color="white"
            icon="close"
            onClick={handleCloseModal}
            size={15}
          />
        </div>
      </header>
      <div className="feedback-for-leaders-info-modal__content">
        <div className="feedback-for-leaders-info-modal__content-col">
          <img
            alt="ilustration"
            className="feedback-for-leaders-info-modal__content-ilustration"
            src={ModalIlustration}
          />
        </div>
        <div className="feedback-for-leaders-info-modal__content-col">
          <ul className="feedback-for-leaders-info-modal__content-list">
            <li className="feedback-for-leaders-info-modal__content-item">
              - {formatMessage(messages.FeedbackForLeadersRecommendation1)}
              <strong>{formatMessage(messages.FeedbackForLeadersRecommendation2)}</strong>.
            </li>
            <li className="feedback-for-leaders-info-modal__content-item">
              - {formatMessage(messages.FeedbackForLeadersRecommendation3)}
              <strong>{formatMessage(messages.FeedbackForLeadersRecommendation4)}</strong>.
            </li>
            {allowsGlbFeatures && (
              <li className="feedback-for-leaders-info-modal__content-item">
                - {formatMessage(messages.FeedbackForLeadersRecommendation5)}
              </li>
            )}
            <li className="feedback-for-leaders-info-modal__content-item">
              {formatMessage(messages.FeedbackForLeadersRecommendation6)}
            </li>
          </ul>
        </div>
      </div>
      <hr className="feedback-for-leaders-info-modal__hr" />
      <footer className="feedback-for-leaders-info-modal__footer">
        <Button
          className="feedback-for-leaders-info-modal__btn-cancel"
          onClick={handleCloseModal}
          variant="outline"
        >
          {formatMessage(actionsMessages.ActionsCancel)}
        </Button>
        <Button onClick={handleContinue}>
          {formatMessage(actionsMessages.ActionsContinue)}
        </Button>
      </footer>
    </div>
  );
};

FeedbackForLeadersInfoModal.propTypes = {
  close: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

const modalStyles = { top: '50%' };

export default RootModal(
  injectIntl(FeedbackForLeadersInfoModal),
  FEEDBACK_FOR_LEADERS_INFO_MODAL,
  modalStyles,
  'simple-modal__wrapper',
);
