// @packages
import React from 'react';
import { injectIntl } from 'react-intl';

// @app
import EmptyStateView  from 'common/EmptyStateView';

// @own
import './styles.scss';
import clock_baloon from './clock_baloon.svg';
import messages from '../messages';

const AppError = ({ intl: { formatMessage } }) => {
    return (
      <div className="app-error">
        <EmptyStateView
          image={clock_baloon}
          className="app-error__empty-state-view"
          text={formatMessage(messages.AppErrorDescription)}
          title={formatMessage(messages.AppErrorTitle)}
        />
      </div>
    );
};

export default injectIntl(AppError);
