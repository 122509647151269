// @packages
import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

// @app
import Title from 'components/atoms/Title';

// @own
import './styles.scss';

const CardTitle = ({
  children,
  className,
  ...props
}) => (
  <Title
    {...props}
    black
    className={cn('card-title', className)}
    nomargin
  >
    {children}
  </Title>
);

CardTitle.defaultProps = {
  className: '',
  padding: true,
  paddingSecondary: false,
};

CardTitle.propTypes = {
  children: PropTypes.number.isRequired,
  className: PropTypes.string,
  padding: PropTypes.bool,
  paddingSecondary: PropTypes.bool,
};

export default CardTitle;
