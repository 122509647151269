// @packages
import get from 'lodash/get';
import last from 'lodash/last';
import { createInstance } from 'smu-utils/globalRequestHelper';
import { getUserLanguage } from 'smu-utils/language';

// @app
import { errorSanitizer } from 'utils/errorSanitizer';
import { FAIL } from 'utils/constants';

// Get the current environment checking the url prefix with format product-env.
const devEnvs = ['dev', 'qa', 'uat'];
const prefix = last(window.location.host.split('.')[0].split('-'));
const HOST = devEnvs.indexOf(prefix) !== -1 ? prefix : undefined;

// Validations to run the app in localhost against UAT environment
const ENV = process.env.REACT_APP_STAGE || HOST === 'dev'
  ? 'uat'
  : HOST;

const ADMIN_URL = {
  qa: 'https://os-admin-qa.starmeup.com/accounts/dashboard',
  uat: 'https://os-admin-uat.starmeup.com/accounts/dashboard',
  prod: 'https://os-admin.starmeup.com/accounts/dashboard',
};

const navigateToAdmin = () => {
  let adminUrl = ADMIN_URL.prod;
  if (ENV === 'qa' || ENV === 'uat') {
    adminUrl = ADMIN_URL[ENV];
  }

  window.open(adminUrl);
};

// wrapper api instance
const instanceWrapper = instance => (...rest) =>
  new Promise((resolve, reject) => {
    instance(...rest)
      .then((result) => {
        const data = get(result, 'data');
        resolve(result);
        if (data.status === FAIL) {
          const errorInfo = errorSanitizer(data);
          if (errorInfo.status) {
            reject(errorInfo);
          }
        }
      })
      .catch(err => reject(err));
  });

const adminApiInstance = createInstance('OS', 'SMU_FEEDBACK_WEB', 2, 2);
const apiInstance = createInstance('BME', 'SMU_FEEDBACK_WEB', 2, 2);
const apiInstanceOsMember = createInstance('OSMEMBER', 'SMU_FEEDBACK_WEB', 2, 2);
const apiInstanceSMU = createInstance('SMU', 'SMU_FEEDBACK_WEB', 2, 2);
const apiInstanceTP = createInstance('TP','SMU_FEEDBACK_WEB', 3, 2);
const authInstance = createInstance('AUTH', 'SMU_FEEDBACK_WEB', 2, 2);
const notificationsApiInstance = instanceWrapper(createInstance('NOTIFICATIONS', 'SMU_FEEDBACK_WEB', 2));
const dataServiceApiInstance = createInstance('DATA_SERVICE', 'SMU_FEEDBACK_WEB', 2, 2);
const defaultApi = createInstance('', 'SMU_FEEDBACK_WEB', 2, 2);
const language = getUserLanguage();
apiInstanceSMU.defaults.headers.common.language = language;
apiInstance.defaults.timeout = 120000;

export {
  adminApiInstance,
  apiInstance,
  apiInstanceOsMember,
  apiInstanceSMU,
  apiInstanceTP,
  authInstance,
  dataServiceApiInstance,
  defaultApi,
  navigateToAdmin,
  notificationsApiInstance,
};
