import moment from 'moment';

export const interval = (values, rate) => (values.selectedDate === 'monthData' ?
  moment(values.plotDataValue, 'YYYY-MM').add(rate, 'months').valueOf() :
  values.selectedDate === 'dayData' ? moment(values.plotDataValue, 'YYYY/MM/DD').add(rate, 'days').valueOf() :
    moment(values.plotDataValue, 'YYYY-MM-DD').add(rate, 'weeks').valueOf());

export const colors = {
  colors: ['#007AD1', '#00D0F5', '#C1D82F', '#FFAF22', '#FA0C93', '#df3a94', '#ffaf22', '#61cae7', '#FFAF22', '#4E5B65', '#995AE6', '#04D9CE', '#04d9ce', '#007ad1', '#9266d7', '#7779FB', '#75E16E', '#CA3CBB', '#ECC254'],
};

export const formatNumber = value => (value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : value);
