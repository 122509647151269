import {
  FILE_GOAL_CLEAR,
  FILE_GOAL_DELETE,
  FILE_GOAL_DELETE_FAIL,
  FILE_GOAL_DELETE_SUCCESS,
  FILE_GOAL_UPLOAD,
  FILE_GOAL_UPLOAD_FAIL,
  FILE_GOAL_UPLOAD_SUCCESS,
} from './actionTypes';

export function fileDelete(id) {
  return {
    type: FILE_GOAL_DELETE,
    payload: { id },
  };
}

export function fileDeleteFail(error) {
  return {
    type: FILE_GOAL_DELETE_FAIL,
    payload: { error },
  };
}

export function fileDeleteSuccess(id) {
  return {
    type: FILE_GOAL_DELETE_SUCCESS,
    payload: { id },
  };
}

export function fileUpload(file) {
  return {
    type: FILE_GOAL_UPLOAD,
    payload: { file },
  };
}

export function fileUploadFail(error) {
  return {
    type: FILE_GOAL_UPLOAD_FAIL,
    payload: { error },
  };
}

export function fileUploadSuccess(file) {
  return {
    type: FILE_GOAL_UPLOAD_SUCCESS,
    payload: { file },
  };
}

export function fileClear() {
  return {
    type: FILE_GOAL_CLEAR,
  };
}
