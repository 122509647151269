// @packages
import { takeLatest, call, put } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';

// @own
import { GET_USERS_LIST } from './actionTypes';
import { getUsersList } from './api';
import {
  getUsersListFail,
  getUsersListSuccess,
} from './actions';

function* getUsersListWorker({ payload }) {
  const { query, selectedUsers } = payload;
  const selectedUsersId = selectedUsers?.map(user => user?.id);

  try {
    const response = yield call(getUsersList, query);
    const users = response
      ?.filter(user => !(selectedUsersId?.includes(user?.id)))
      ?.slice(0, 5);
    yield put(getUsersListSuccess(users));
  } catch (error) {
    yield put(getUsersListFail(error.message));
    yield put(addToastMessage({
      message: error.message,
      timeout: 3000,
    }));
  }
}

export default function* searchBarWatcher() {
  yield takeLatest(GET_USERS_LIST, getUsersListWorker);
}
