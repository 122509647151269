// @packages
import { takeLatest, put, call } from 'redux-saga/effects';
import { setCookieLanguage } from 'smu-utils/language';

// @own
import { getLocales } from '../api';
import { requestLocaleSuccess, requestLocaleFailed } from './actions';
import { REQUEST_LOCALE } from './actionTypes';

function* localeWorker({ payload }) {
  const { locale } = payload;

  const params = {
    o: 'BM_WEB',
    languageCode: locale,
  };

  try {
    const data = yield call(getLocales, params);
    yield call(setCookieLanguage, locale);
    yield put(requestLocaleSuccess(locale, data));
  } catch (error) {
    yield put(requestLocaleFailed(error));
  }
}

export function* localeWatcher() {
  yield takeLatest(REQUEST_LOCALE, localeWorker);
}
