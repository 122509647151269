// @packages
import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'smu-ui-components/Icon';
import { Popup } from 'semantic-ui-react';

// @app

// @own
import './styles.scss';

const GoalActions = ({ actionList }) => {
  const renderActionList = actionList.map((action, index) => {
    const elementTrigger = (
      <li
        className={cn('goal-actions__item', { 'goal-actions__item--disabled': action.disabled })}
        onClick={!action.disabled ? action.onClick : undefined}
      >
        <Icon icon={action.icon} />
      </li>
    );
    return (
      <Popup
        key={index}
        inverted
        trigger={elementTrigger}
        position="left center"
        content={action.title}
        hideOnScroll
      />
    );
  });

  return (
    <div className="goal-actions">
      <ul className="goal-actions__list">
        {renderActionList}
      </ul>
    </div>
  );
};

GoalActions.propTypes = {
  actionList: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      onClick: PropTypes.func,
    })
  ).isRequired
};

export default GoalActions;
