// @packages
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import AvatarMedia from 'smu-ui-components/AvatarMedia';
import RootModal from 'smu-app-components/RootModal';
import Icon from 'smu-ui-components/IconV2';
import Segment from 'smu-ui-components/Segment';

// @app
import { PROMOTION_REQUEST_PROMOTIONS_HISTORY_MODAL } from 'betterme-components/constants/modalTypes';
import AgingDate from 'common/AgingDate';
import { modalStyles, centerModalClass } from 'betterme-components/ConfirmationModal';
import PromotionsHistoryTable from 'betterme-components/PromotionsHistoryTable';
import {
  getPromotionRequestsHistory,
  resetPromotionRequestsHistory,
} from 'betterme-components/PromotionsHistoryTable/actions';
import Title from 'components/atoms/Title';
import { promotionsMessages } from 'common/messages';
import {
  selectLoadingPromotionRequestsHistory,
  selectPagination,
  selectPromotionRequestsHistoryResult,
} from '../PromotionsHistoryTable/selectors';

// @own
import './styles.scss';

const PromotionsHistoryModal = ({
  closeModal,
  getPromotionRequestsHistory,
  intl: { formatMessage },
  loading,
  page,
  collaborator,
  promotionRequestsHistory,
  resetPromotionRequestsHistory,
  push,
  statusLabels
}) => {
  const { seniority: { name, startDate } } = collaborator;
  useEffect(() => {
    getPromotionRequestsHistory(collaborator?.id, page.number);
  }, []);

  function handlePageChange(event) {
    getPromotionRequestsHistory(collaborator?.id, event);
  }

  function handleClose() {
    resetPromotionRequestsHistory();
    closeModal();
  }

  return (
    <div className="promotions-history-modal">
      <div className="promotions-history-modal__header">
        <div className="promotions-history-modal__heading">
          <Title className="promotions-history-modal__title" size={16}>
            {formatMessage(promotionsMessages.PromotionsPromotionsHistory)}
          </Title>
          <div className="promotions-history-modal__subtitle">
            {formatMessage(promotionsMessages.PromotionsAll)}
          </div>
        </div>
        <Icon
          className="promotions-history-modal__close-icon"
          color="iconPrimary"
          icon="close"
          size="small"
          onClick={handleClose}
        />
      </div>
      <div className="promotions-history-modal__promotion-user">
        <AvatarMedia
          className="promotions-history-modal__promotion-user--avatar"
          noJob={true}
          useLink={false}
          {...collaborator}
        />
        <div className="promotions-history-modal__promotion-user--info">
          <span className="promotions-history-modal__promotion-user--info-name">{name}</span>
          <AgingDate date={startDate} />
        </div>
      </div>
      <Segment loadingType="betterme">
        <PromotionsHistoryTable
          collaboratorId={collaborator?.id}
          loading={loading}
          onPageChange={handlePageChange}
          page={page}
          promotionsHistory={promotionRequestsHistory}
          push={push}
          statusLabels={statusLabels}
        />
      </Segment>
    </div>
  );
};

PromotionsHistoryModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  collaboratorId: PropTypes.number.isRequired,
  getPromotionRequestsHistory: PropTypes.func.isRequired,
  formatMessage: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  page: PropTypes.shape({
    number: PropTypes.number.isRequired,
    numberOfElements: PropTypes.number.isRequired,
    size: PropTypes.number.isRequired,
    totalElements: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
  }),
  promotionRequestsHistory: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  loading: selectLoadingPromotionRequestsHistory(state),
  page: selectPagination(state),
  promotionRequestsHistory: selectPromotionRequestsHistoryResult(state),
});

const mapDispatchToProps = {
  getPromotionRequestsHistory,
  resetPromotionRequestsHistory,
};

const withRootModalComponent = RootModal(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(injectIntl(PromotionsHistoryModal)),
  PROMOTION_REQUEST_PROMOTIONS_HISTORY_MODAL,
  modalStyles,
  centerModalClass,
);


export default withRootModalComponent;
