// @packages
import { trackEvent } from 'smu-utils/gtm';

// @own
import { SKILLS } from '../SkillSelectorModal/analytics';

const AWAITING = 'fdbk_awaiting';
const FEEDBACK = 'Feedback';
const SEND_FEEDBACK = 'Send_Feedback';
export const REQUEST_FEEDBACK = 'Request_Feedback';

export const trackFeedbackClick = () => {
  trackEvent({
    category: SEND_FEEDBACK,
    action: 'Feedback_Click',
  });
};

export const trackSendFeedbackClick = (withGPT) => {
  trackEvent({
    category: 'feedback',
    action: 'success_send',
    type: 'send_fdbk',
    int_type: withGPT ? 'with_GPT' : 'with_comment',
  });
};

export const trackCancelFeedbackClick = (withGPT) => {
  trackEvent({
    category: 'feedback',
    action: 'cancel',
    type: 'send_fdbk',
    int_type: withGPT ? 'with_GPT' : 'with_comment',
  });
};

export const trackFeedbackSendAnyway = () => {
  trackEvent({
    category: FEEDBACK,
    action: 'Modal_Improvements',
    label: 'Send_Anyway',
    type: AWAITING,
  });
};

export const trackFeedbackAddImprovements = () => {
  trackEvent({
    category: FEEDBACK,
    action: 'Modal_Improvements',
    label: 'Add_Improvements',
    type: AWAITING,
  });
};

export const trackReplyFeedbackUserAvatarClick = (feedbackType) => {
  trackEvent({
    category: FEEDBACK,
    action: 'Profile_Icon',
    label: feedbackType,
    type: AWAITING,
  });
}

export const trackChipsFeedbackHover = () => {
  trackEvent({
    category: SEND_FEEDBACK,
    action: 'Chips_Hover',
  });
};

export const trackRequestFeedbackTabClick = () => {
  trackEvent({
    category: REQUEST_FEEDBACK,
    action: 'Request_Tab',
  });
};

export const trackRequestFeedbackClick = () => {
  trackEvent({
    category: 'feedback',
    action: 'success_request',
    type: 'request_fdbk',
  });
};

export const trackCancelRequestFeedbackClick = () => {
  trackEvent({
    category: 'feedback',
    action: 'cancel',
    type: 'request_fdbk',
  });
};

export const trackRequestFeedbackOnBehalfClick = () => {
  trackEvent({
    category: 'feedback',
    action: 'success_on_behalf',
    type: 'request_fdbk_on_behalf',
  });
};

export const trackCancelRequestFeedbackOnBehalfClick = () => {
  trackEvent({
    category: 'feedback',
    action: 'cancel',
    type: 'request_fdbk_on_behalf',
  });
};

export const trackChipsRequestFeedbackHover = () => {
  trackEvent({
    category: REQUEST_FEEDBACK,
    action: 'Chip_Hover',
  });
};

export const trackSendFeedbackAddSkill = () => {
  trackEvent({
    category: SKILLS,
    action: 'Send_Feedback_Click',
  });
};

export const trackRequestFeedbackAddSkill = () => {
  trackEvent({
    category: SKILLS,
    action: 'Request_Feedback_Click',
  });
};

export const trackReplyFeedbackAddFile = () => {
  trackEvent({
    category: FEEDBACK,
    action: 'File_Add',
    type: AWAITING,
  });
}

export const trackReplyFeedbackDeleteFile = () => {
  trackEvent({
    category: FEEDBACK,
    action: 'File_Delete',
    type: AWAITING,
  });
}
