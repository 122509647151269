export const CREATE_PROMOTION_REQUEST = 'CREATE_PROMOTION_REQUEST';
export const CREATE_PROMOTION_REQUEST_FAIL = 'CREATE_PROMOTION_REQUEST_FAIL';
export const CREATE_PROMOTION_REQUEST_SUCCESS = 'CREATE_PROMOTION_REQUEST_SUCCESS';
export const DELETE_PROMOTIONS_FILE = 'DELETE_PROMOTIONS_FILE';
export const DELETE_PROMOTIONS_FILE_FAIL = 'DELETE_PROMOTIONS_FILE_FAIL';
export const DELETE_PROMOTIONS_FILE_SUCCESS = 'DELETE_PROMOTIONS_FILE_SUCCESS';
export const GET_POSITIONS_HISTORY = 'GET_POSITIONS_HISTORY';
export const GET_POSITIONS_HISTORY_FAIL = 'GET_POSITIONS_HISTORY_FAIL';
export const GET_POSITIONS_HISTORY_SUCCESS = 'GET_POSITIONS_HISTORY_SUCCESS';
export const GET_PROMOTIONS_LEVEL = 'GET_PROMOTIONS_LEVEL';
export const GET_PROMOTIONS_LEVEL_FAIL = 'GET_PROMOTIONS_LEVEL_FAIL';
export const GET_PROMOTIONS_LEVEL_SUCCESS = 'GET_PROMOTIONS_LEVEL_SUCCESS';
export const GET_PROMOTION_COLLABORATOR = 'GET_PROMOTION_COLLABORATOR';
export const GET_PROMOTION_COLLABORATOR_FAIL = 'GET_PROMOTION_COLLABORATOR_FAIL';
export const GET_PROMOTION_COLLABORATOR_SUCCESS = 'GET_PROMOTION_COLLABORATOR_SUCCESS';
export const GET_PROMOTION_SUGGESTED_VALIDATORS = 'GET_PROMOTION_SUGGESTED_VALIDATORS';
export const GET_PROMOTION_SUGGESTED_VALIDATORS_FAIL = 'GET_PROMOTION_SUGGESTED_VALIDATORS_FAIL';
export const GET_PROMOTION_SUGGESTED_VALIDATORS_SUCCESS = 'GET_PROMOTION_SUGGESTED_VALIDATORS_SUCCESS';
export const GET_USER_PROMOTION_REQUESTS = 'GET_USER_PROMOTION_REQUESTS';
export const GET_USER_PROMOTION_REQUESTS_FAIL = 'GET_USER_PROMOTION_REQUESTS_FAIL';
export const GET_USER_PROMOTION_REQUESTS_SUCCESS = 'GET_USER_PROMOTION_REQUESTS_SUCCESS';
export const RESET_PROMOTIONS_FILES = 'RESET_PROMOTIONS_FILES';
export const RESET_SUGGEST_PROMOTION = 'RESET_SUGGEST_PROMOTION';
export const SET_PROMOTION_FILES = 'SET_PROMOTION_FILES';
export const UPLOAD_PROMOTIONS_FILE = 'UPLOAD_PROMOTIONS_FILE';
export const UPLOAD_PROMOTIONS_FILE_FAIL = 'UPLOAD_PROMOTIONS_FILE_FAIL';
export const UPLOAD_PROMOTIONS_FILE_SUCCESS = 'UPLOAD_PROMOTIONS_FILE_SUCCESS';
