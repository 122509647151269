// @packages
import moment from 'moment';

export function getDropdownPeriodList(periods, smallFormat) {
  const formatL = moment.localeData().longDateFormat('L');
  const formatYearlessL = formatL.replace(/YYYY/g,'YY');
  const format = smallFormat ? formatYearlessL : 'L';

  return periods.map(({ id, startTime, endTime }) => ({
    value: id,
    label: `${moment.utc(startTime).format(format)} - ${moment.utc(endTime).format(format)}`
  }));
}
