// @packages
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import TouchBackend from 'react-dnd-touch-backend';
import MultiBackend, { TouchTransition } from 'react-dnd-multi-backend';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

// @own
import AppError from './App/AppError';
import { selectError } from './App/selectors';
import * as actions from './App/actions';

class App extends Component {

  render() {
    const { children, error } = this.props;

    return !error ? children : <AppError />;
  }
}

App.propTypes = {
  router: PropTypes.object.isRequired,
  children: PropTypes.node,
  location: PropTypes.object.isRequired
};

const HTML5toTouch = {
  backends: [
    { backend: HTML5Backend },
    {
      backend: TouchBackend({ enableMouseEvents: true }),
      preview: true,
      transition: TouchTransition,
    }
  ]
};

function mapStateToProps(state) {
  return {
    error: selectError(state),
  };
}

const withDragDropContext = DragDropContext(MultiBackend(HTML5toTouch));
const withRedux = connect(mapStateToProps, actions);

export default withDragDropContext(withRedux(withRouter(App)));
