import {
  CREATE_SAVE_EVALUATION,
  CREATE_SAVE_EVALUATION_FAIL,
  CREATE_SAVE_EVALUATION_SUCCESS,
} from './actionTypes';

const initialState = {
  createSaveEvaluation: {},
  createSaveEvaluationError: '',
  createSaveEvaluationLoading: false,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case CREATE_SAVE_EVALUATION:
      return {
        ...state,
        createSaveEvaluationLoading: true,
      };

    case CREATE_SAVE_EVALUATION_FAIL:
      return {
        ...state,
        createSaveEvaluationError: payload.error,
        createSaveEvaluationLoading: false,
      };

    case CREATE_SAVE_EVALUATION_SUCCESS:
      return {
        ...state,
        createSaveEvaluation: payload.response,
        createSaveEvaluationLoading: false,
      }

    default:
      return state;
  }
};
