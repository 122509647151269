// @packages
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { Router } from 'react-router';

// @own
import IntlProvider from './betterme-components/IntlProvider';
import createRoutes from './betterme-components/routes';

export default class Root extends Component {
  render() {
    const { store, history, persistor } = this.props;

    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
        {/* applicationId for feedback is 2 */}
          <IntlProvider applicationId={2}>
            <Router history={history} routes={createRoutes(store)} />
          </IntlProvider>
        </PersistGate>
      </Provider>
    );
  }
}

Root.propTypes = {
  history: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
};
