// @own
import {
  FILE_DOWNLOAD,
  FILE_DOWNLOAD_FAIL,
  FILE_DOWNLOAD_RESET,
  FILE_DOWNLOAD_SUCCESS,
} from './actionTypes';

export function fileDownload(id) {
  return {
    type: FILE_DOWNLOAD,
    payload: { id },
  }
}

export function fileDownloadFail(error) {
  return {
    type: FILE_DOWNLOAD_FAIL,
    payload: { error },
  }
}

export function fileDownloadSuccess(response) {
  return {
    type: FILE_DOWNLOAD_SUCCESS,
    payload: { response },
  }
}

export function fileDownloadReset() {
  return {
    type: FILE_DOWNLOAD_RESET,
  }
}
