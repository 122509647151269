// @packages
import React from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import Icon from 'smu-ui-components/Icon';
import Segment from 'smu-ui-components/Segment';
import PropTypes from 'prop-types';

// @app
import CompetenciesSectionTemplate from 'components/templates/CompetenciesSectionTemplate';
import {
  getMyFeedbackReceivedLocation,
  getProfileFeedbackReceivedLocation,
} from 'betterme-components/routes/urls';
import {
  selectActiveCompetenciesWithRatesCount,
} from 'betterme-components/Evaluations/selectors';
import {
  addCompetency,
  removeCompetency,
  setDataCreateEvaluation,
} from 'betterme-components/Evaluations/actions';

// @own
import messages from './messages';
import { ALL, LEADERS } from './constants';
import {
  trackEvaluationsFeedbackOverviewDataToggle,
  trackEvaluationsViewAllFeedbacks,
} from './analytics';

const CompetenciesSection = ({
  activeCompetencies,
  className,
  coEvaluator: {
    hasCoEvaluator,
    emptyState,
    disclaimer,
    title,
  },
  coEvaluatorRatedTopics,
  disabledFeedbackFeatures,
  evaluationAddSkill,
  evaluationType,
  feedbackOverviewDropdownValue,
  feedbackOverviewEnabled,
  intl: { formatMessage },
  isSelfEvaluation,
  onCompetencyRemove,
  onCompetencySelect,
  onFeedbackOverviewDropdownChange,
  ratedTopics,
  rates,
  setFeedbackOverviewEnabled,
  user,
}) => {
  const feedbackOverviewOptions = [
    {
      value: ALL,
      label: formatMessage(messages.CompetenciesSectionAll),
    },
    {
      value: LEADERS,
      label: formatMessage(messages.CompetenciesSectionLeaders),
    },
  ];

  const goToFeedbackIcon = (
    <Icon
      color="black"
      icon="external-link"
      size="extra-small"
    />
  );

  const goToFeedbackLink = isSelfEvaluation
    ? getMyFeedbackReceivedLocation()
    : getProfileFeedbackReceivedLocation(user);

  const competenciesAdded = [...activeCompetencies];

  function handleFeedbackOverviewEnabled() {
    setFeedbackOverviewEnabled(!feedbackOverviewEnabled);
    trackEvaluationsFeedbackOverviewDataToggle(feedbackOverviewEnabled, evaluationType);
  };

  return (
    <Segment className={cn('competencies-section', className)}>
      <CompetenciesSectionTemplate
        comptenciesTitle={formatMessage(messages.CompetenciesSectionCompetencies)}
        competenciesRate={{
          addCompetency: evaluationAddSkill,
          onCompetencySelect: onCompetencySelect,
          competenciesAdded,
          ratedTopicsByCompetency: ratedTopics,
          ratings: rates,
          removeCompetency: onCompetencyRemove,
          coEvaluatorRatedTopics,
        }}
        disabledFeedbackFeatures={disabledFeedbackFeatures}
        feedbackOverview={{
          onFeedbackChange: onFeedbackOverviewDropdownChange,
          options: feedbackOverviewOptions,
          placeholder: formatMessage(messages.CompetenciesSectionAll),
          value: feedbackOverviewDropdownValue,
        }}
        feedbackOverviewText={formatMessage(messages.CompetenciesSectionFeedbackOverview)}
        goToFeedback={{
          feedbackClick: () => trackEvaluationsViewAllFeedbacks(evaluationType),
          goToFeedbackIcon,
          goToFeedbackLink,
          goToFeedbackText: formatMessage(messages.CompetenciesSectionViewFeedback),
        }}
        switch={{
          onSwitchChange: handleFeedbackOverviewEnabled,
          switchDisabled: false,
          switchEnabled: feedbackOverviewEnabled,
        }}
        coEvaluator={{
          hasCoEvaluator,
          emptyState,
          disclaimer,
          title,
        }}
      />
    </Segment>
  );
};

CompetenciesSection.propTypes = {
  activeCompetencies: PropTypes.object,
  className: PropTypes.string,
  feedbackOverviewDropdownValue: PropTypes.object,
  intl: PropTypes.func,
  isSelfEvaluation: PropTypes.bool,
  onCompetencyRemove: PropTypes.func,
  onCompetencySelect: PropTypes.func,
  onFeedbackOverviewDropdownChange: PropTypes.object,
  ratedTopics: PropTypes.array,
  rates: PropTypes.array,
  user: PropTypes.object,
};

const mapDispatchToProps = {
  addCompetency,
  removeCompetency,
  setDataCreateEvaluation,
};

const mapSateToProps = (state) => ({
  activeCompetencies: selectActiveCompetenciesWithRatesCount(state),
});

export default connect(
  mapSateToProps,
  mapDispatchToProps
)(injectIntl(CompetenciesSection));
