// @packages
import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

// @app
import Title from 'components/atoms/Title';

// @own
import './styles.scss';

const StatusItem = ({
  className,
  count,
  onStatusClick,
  statusName,
}) => {
  return (
    <div
      className={cn('status-item', className)}
      onClick={() => onStatusClick(statusName)}
    >
      <div className="status-item__container">
        <Title
          black
          className="status-item__name"
          level={3}
          nomargin
        >
          {statusName}
        </Title>
        <div className="status-item__circle">
          <div className="status-item__number">{count}</div>
        </div>
      </div>
    </div>
  );
};

StatusItem.propTypes = {
  className: PropTypes.string,
  count: PropTypes.number,
  onStatusClick: PropTypes.func,
  statusName: PropTypes.string,
};

export default StatusItem;
