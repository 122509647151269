// @packages
import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';


const BehavioralAssessmentDetail = ({
  behaviors,
  className,
  subtitle,
  title,
}) => (
  <div className={cn('p-5 relative', className)}>
    <h3 className="text-shark mb-[10px] uppercase text-base font-bold">
      {title}
    </h3>
    <p className="font-roboto text-sm">
      {subtitle}
    </p>
    {behaviors.map((rating) =>
      <div className="border-t-gray-gallery border-t mt-5">
        <h3 className="text-shark mb-[10px] uppercase text-sm !mt-[26px] font-bold">
          {rating?.name}
        </h3>
        {rating.questions.map((question) =>
          <p className="font-roboto text-sm mb-4">
            <b>{`${question?.topic?.name}: `}</b>{question?.name}
          </p>
        )}
      </div>
    )}
  </div>
);

BehavioralAssessmentDetail.propTypes = {
  behaviors: PropTypes.array,
  className: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

export default BehavioralAssessmentDetail;
