// @packages
import React from 'react';
import PropTypes from 'prop-types';
import TextParserContainer from 'betterme-components/TextParser';

// @own
import './styles.scss';

const RequestComment = ({ className, comment, name }) => (
  <div className={`request-comment ${className || ''}`}>
    <div className="request-comment__name">{name}</div>
    <div className="request-comment__comment">
      <TextParserContainer>{comment}</TextParserContainer>
    </div>
  </div>
);

RequestComment.propTypes = {
  className: PropTypes.string,
  comment: PropTypes.node.isRequired,
  name: PropTypes.node.isRequired,
};

export default RequestComment;
