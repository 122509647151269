// @packages
import React, { useMemo } from 'react';
import MessageBox from 'smu-ui-components/MessageBox';
import PropTypes from 'prop-types';
import Select from 'smu-app-components/Select';
import UserSelect from 'smu-ui-components/UserSelect';
import { buildImageUrl } from 'smu-utils/image';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

// @app
import messages from 'betterme-components/SendRequestFeedback/messages';
import { changeFeedbackSelectedCollaborators } from 'betterme-components/SendRequestFeedback/actions';
import { selectAllCollaborators } from 'betterme-components/Authorization/selectors';
import { selectSelectedCollaborators } from 'betterme-components/SendRequestFeedback/selectors';

function SelectCollaborators({
  allCollaborators,
  changeFeedbackSelectedCollaborators,
  className,
  intl: { formatMessage },
  maxCollaborators,
  selectedUsers,
}) {
  const handleChangeCollaborators = (value) => {
    if (value?.length > maxCollaborators) {
      return;
    }
    const collaborators = value?.map((id) => ({ id }));
    changeFeedbackSelectedCollaborators(collaborators);
  };

  const options = useMemo(
    () =>
      allCollaborators?.map((u) => ({
        label: `${u.firstName} ${u.lastName}`,
        value: u?.id,
        user: {
          id: u?.id,
          name: `${u.firstName} ${u.lastName}`,
          description: u?.job,
          avatarSrc: buildImageUrl({
            code: u?.profileImageCode,
            height: 40,
            width: 40,
          }),
        },
      })),
    [allCollaborators]
  );

  const value = useMemo(
    () => selectedUsers?.map((u) => u?.id),
    [selectedUsers]
  );

  return (
    <>
      <UserSelect
        className={className}
        component={Select}
        isMultiple
        isMultipleScrollEnabled
        onChange={handleChangeCollaborators}
        options={options}
        showArrow={false}
        showSearchIcon
        value={value}
      />
      {selectedUsers?.length >= maxCollaborators && (
        <MessageBox className="w-full mt-3" type="warning">
          {formatMessage(
            messages.SendRequestFeedbackRequestBehalfSelectColaboratorsLimitMessage
          )}
        </MessageBox>
      )}
    </>
  );
}

SelectCollaborators.defaultProps = {
  maxCollaborators: 10,
};

SelectCollaborators.propTypes = {
  allCollaborators: PropTypes.array,
  changeFeedbackSelectedCollaborators: PropTypes.func,
  intl: PropTypes.object,
  maxCollaborators: PropTypes.number,
  selectedUsers: PropTypes.array,
};

const mapStateToProps = (state) => ({
  allCollaborators: selectAllCollaborators(state),
  selectedUsers: selectSelectedCollaborators(state),
});

const mapDispatchToProps = {
  changeFeedbackSelectedCollaborators,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(SelectCollaborators));
