// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ConfirmationModal from 'smu-app-components/ConfirmationModal';

// @app

// @own
import messages from '../messages';

const FeedbackConfirmationModal = ({
  collaborators,
  confirmationType,
  onModalClose,
  users,
}) => {
  const sendJSX  = <FormattedMessage {...messages.SendRequestFeedbackFeedbackConfirmationModalFooterMessage} />;
  const requestJSX = <FormattedMessage {...messages.AskRequestFeedbackFeedbackConfirmationModalFooterMessage} />;
  const user = users[0] || {};
  const collaborator = collaborators[0] || {};
  const isRequestOnBehalf = confirmationType === 'REQUEST_ON_BEHALF';
  const recipients = isRequestOnBehalf ? collaborators : users;
  const recipientsLength = recipients.length > 1;

  const feedbackMessageConfig = {
    REPLY: (
      <span>
        <FormattedMessage {...messages.SendRequestFeedbackFeedbackConfirmationModalReplyMessage} />
        <b> {user.firstName} </b>
        <FormattedMessage {...messages.SendRequestFeedbackFeedbackConfirmationModalSuccessfullySentFeedbackMessage} />
      </span>
    ),
    REQUEST: (
      <span>
        <FormattedMessage {...messages.SendRequestFeedbackFeedbackConfirmationModalRequestMessage} />
        <b> {user.firstName} </b>
        <FormattedMessage {...messages.SendRequestFeedbackFeedbackConfirmationModalSuccessfullySentFeedbackRequestMessage} />
      </span>
    ),
    REQUEST_ON_BEHALF: (
      <span>
        <FormattedMessage {...messages.SendRequestFeedbackFeedbackConfirmationModalOnBehalfMessage} />
        <b> {collaborator.firstName} </b>
        <FormattedMessage {...messages.SendRequestFeedbackFeedbackConfirmationModalSuccessfullySentFeedbackRequestMessage} />
      </span>
    ),
    SEND: (
      <span>
        <FormattedMessage {...messages.SendRequestFeedbackFeedbackConfirmationModalSendMessage} />
        <b> {user.firstName} </b>
        <FormattedMessage {...messages.SendRequestFeedbackFeedbackConfirmationModalSuccessfullySentFeedbackMessage} />
      </span>
    ),
  };
  const multipleRequestFeedbackTexts = {
    message: {
      REQUEST: <FormattedMessage {...messages.SendRequestFeedbackFeedbackConfirmationModalMultipleRequestMessage} />,
      REQUEST_ON_BEHALF: <FormattedMessage {...messages.SendRequestFeedbackFeedbackConfirmationModalMultipleOnBehalfMessage} />,
      SEND: <FormattedMessage {...messages.SendRequestFeedbackFeedbackConfirmationModalMultipleSendMessage} />,
    },
    title: <FormattedMessage {...messages.SendRequestFeedbackFeedbackConfirmationModalMultipleRequestTitle} />,
  };

  const props = {
    modalProps: {
      colorTheme: 'blue',
      footerMessage: (confirmationType !== 'REQUEST' && !isRequestOnBehalf)
        ? sendJSX
        : requestJSX,
      horizontalRecipients: recipientsLength,
      message: recipientsLength
        ? multipleRequestFeedbackTexts.message[confirmationType]
        : feedbackMessageConfig[confirmationType],
      recipients,
      title: recipientsLength
        ? multipleRequestFeedbackTexts.title
        : <FormattedMessage {...messages.SendRequestFeedbackFeedbackConfirmationModalTitle} />,
    },
    onModalClose,
  };
  return <ConfirmationModal {...props} />;
};

FeedbackConfirmationModal.defaultProps = {
  users: [],
};

FeedbackConfirmationModal.propTypes = {
  confirmationType: PropTypes.oneOf([
    'REPLY',
    'REQUEST',
    'REQUEST_ON_BEHALF',
    'SEND',
  ]).isRequired,
  onModalClose: PropTypes.func,
  users: PropTypes.array.isRequired,
};

export default FeedbackConfirmationModal;
