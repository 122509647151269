// @packages
import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

// @own
import CompetencyRatingListItem from '../CompetencyRatingListItem';
import './styles.scss';

const CompetencyRatingList = ({ className, competencies, onUserAvatarsClick }) => {
  return (
    <ul className={cn('competency-rating-list', className)}>
      {competencies.map(competency =>
        <CompetencyRatingListItem
          className="competency-rating-list__item"
          key={competency.id}
          onUserAvatarsClick={() => onUserAvatarsClick(competency.id)}
          {...competency}
        />
      )}
    </ul>
  );
};

CompetencyRatingList.propTypes = {
  competencies: PropTypes.array.isRequired,
};

export default CompetencyRatingList;
