// @packages
import { takeLatest, call, put } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';

// @app
import { deleteFiles, uploadFiles } from './api';

// @own
import {
  deleteFilesFail,
  deleteFilesSuccess,
  uploadFilesFail,
  uploadFilesSuccess,
} from './actions';
import { DELETE_FILES, UPLOAD_FILES } from './actionTypes';

function* uploadFilesWorker({ payload: { file } }) {
  try {
    const response = yield call(uploadFiles, file);
    yield put(uploadFilesSuccess(response));
  } catch(error) {
    yield put(uploadFilesFail(error.message));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

function* deleteFilesWorker({ payload: { id } }) {
  try {
    yield call(deleteFiles, id);
    yield put(deleteFilesSuccess(id));
  } catch(error) {
    yield put(deleteFilesFail(error.message));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

export default function* filesGoalWatcher() {
  yield takeLatest(UPLOAD_FILES, uploadFilesWorker);
  yield takeLatest(DELETE_FILES, deleteFilesWorker);
}
