import { partial } from 'lodash';
import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as form } from 'redux-form';

// app reducers // TODO organize import
// import communityConfigurations from './communityConfigurationReducer';
// import feedbacks from './feedbacksReducer';
// import feedbacksFilter from './feedbacksFilterReducer';
// import suggestions from './suggestionsReducer';
// import topics from './topicsReducer';
import * as searchActions from 'smu-app-components/Search/actions';
import actionRequired from 'betterme-components/services/ReasonsActions/reducer'
import addLeaderRequest from 'betterme-components/services/AddToMyTeam/reducer'
import adminPassword from 'betterme-components/services/AdminPassword/reducer';
import app from 'betterme-components/App/reducer';
import applications from 'betterme-components/services/Applications/reducer';
import assessment from 'betterme-components/services/Assessment/reducer';
import authenticate from 'smu-app-components/Login/reducer';
import awatingResultCounter from 'betterme-components/MyDashboard/FeedbackForLeadersReport/reducer';
import changeLeaderRequest from 'betterme-components/services/ChangeLeaderRequest/reducer';
import changeLeaderRequestModal from 'betterme-components/ChangeLeaderRequestModal/reducer';
import communityConfigs from 'betterme-components/services/CommunityConfigs/reducer';
import communityInformation from 'betterme-components/services/Layout/reducer';
import dashboard from 'betterme-components/Dashboard/reducer';
import dashboardSuggestions from 'betterme-components/Dashboard/DashboardSuggestions/reducer';
import evaluationDetail from 'betterme-components/EvaluationDetail/reducer';
import evaluationsTeam from 'betterme-components/services/TeamEvaluations/reducer';
import evaluations from 'betterme-components/Evaluations/reducer';
import featureDiscovery from 'smu-app-components/FeatureDiscoveryV2/reducer';
import feedbackForLeaders from 'betterme-components/services/FeedbackForLeaders/reducer';
import feedbackForLeadersReceived from 'betterme-components/FeedbackForLeadersReceived/reducer';
import feedbackOverview from '../FeedbackOverview/reducer';
import feedbackView from 'betterme-components/FeedbackView/reducer';
import file from 'common/File/reducer';
import fileFeedback from 'betterme-components/services/FileFeedback/reducer';
import fileGoal from 'betterme-components/services/FileGoals/reducer';
import fullscreenModal from 'smu-app-components/FullScreenModal/reducer';
import getChangeLeaderDismissReason from 'betterme-components/services/ChangeLeaderRequestAcceptDecline/reducer';
import goalDetail from 'common/GoalDetail/reducer';
import goals from 'betterme-components/Goals/reducer';
import goalsAssigned from 'common/GoalsAssigned/reducer';
import gotIt from 'betterme-components/services/GotIt/reducer';
import i18n from 'betterme-components/IntlProvider/reducer';
import leaderActions from 'betterme-components/LeadershipRequest/reducer';
import leadersByPeriod from 'betterme-components/FeedbackForLeadersSelectionModal/reducer';
import leadershipAdmin from 'betterme-components/services/LeadershipAdmin/reducer';
import leadershipRequest from 'betterme-components/services/LeadershipRequest/reducer';
import leadershipRequestsReceived from 'betterme-components/services/LeadershipRequestReceived/reducer';
import leadershipRequestsSent from 'betterme-components/services/LeadershipRequestSent/reducer';
import mainHeader from 'smu-app-components/MainHeader/reducer';
import mainSearch from 'smu-app-components/MainSearchApi/reducer';
import modal from 'smu-app-components/RootModal/reducer';
import myFeedbacks from 'betterme-components/MyFeedbacks/reducer';
import myTeam from 'betterme-components/MyTeam/reducer';
import myTeamEvaluations from 'betterme-components/services/MyTeamEvaluations/reducer';
import notifications from 'smu-app-components/Notifications/reducer';
import notificationsV2 from 'smu-app-components/NotificationsV2/reducer';
import notificationsFeedback from 'betterme-components/Notifications/reducer';
import notificationsHistory from 'betterme-components/NotificationsHistory/reducer';
import performanceRetrospectiveDetail from 'betterme-components/services/PerformanceRetrospectiveDetail/reducer';
import profile from 'betterme-components/Profile/reducer';
import promotionRequestsHistory from 'betterme-components/PromotionsHistoryTable/reducer';
import potentialAssessmentResults from 'betterme-components/services/PotentialAssessmentResults/reducer'
import removeLeaderModal from 'betterme-components/services/RemoveLeader/reducer';
import requestFeedbackR from 'betterme-components/FeedbackRequest/reducer';
import requests from 'smu-utils/reduxRequests/reducer';
import search from 'smu-app-components/Search/reducer';
import sendRequestFeedback from 'betterme-components/SendRequestFeedback/reducer';
import sendfeedbackForLeaders from 'betterme-components/SendFeedbackForLeaders/reducer';
import session from 'betterme-components/Authorization/reducer';
import skills from 'betterme-components/SkillSelectorModal/reducer';
import skillsRated from 'common/SkillsRated/reducer';
import starmeUpDNAWidget from 'betterme-components/services/StarmeUpDNAWidget/reducer';
import stellar from 'smu-app-components/StellarWidget/reducer';
import suggestActions from 'betterme-components/SuggestActions/reducer';
import suggestPromotion from 'betterme-components/SendSuggestPromotion/reducer';
import suggestedActionsList from 'betterme-components/SuggestedActionsList/reducer';
import suggestions from 'smu-app-components/Suggestions/reducer';
import teamDashboard from 'betterme-components/Team/reducer';
import teamFeedbacks from 'betterme-components/TeamFeedbacks/reducer';
import teamPromotions from 'betterme-components/Team/TeamPromotionsTab/reducer';
import tellUsAbout from 'betterme-components/TellUsAboutModal/reducer';
import toastr from 'smu-app-components/ToastNotifications/reducer';
import topicsRated from 'common/TopicsRated/reducer';
import user from 'betterme-components/common/User/reducer';
import userCarousel from 'betterme-components/services/Carousel/reducer';
import userInfo from 'betterme-components/services/UserInformation/reducer';
import userPromotionRequestsGroup from 'betterme-components/PromotionsHistoryScreen/reducer';
import { i18n as smui18n } from 'smu-app-components/StarmeUpWidgetContainer/reducer';
import teamInformationWA from '../Team/Team-Service-Workaround/reducer'; // TO DO: REMOVE
import updateSubmitEvaluation from 'betterme-components/services/EvaluationsUpdateSubmit/reducer';
import createSaveEvaluation from 'betterme-components/services/EvaluationsCreateSave/reducer';
import performanceRetrospective from 'betterme-components/services/PerformanceRetrospective/reducer';
import selfRetrospective from 'betterme-components/services/SelfRetrospective/reducer';
import tierType from 'betterme-components/services/GetTierType/reducer';
import surveyQuestionsAndRates from 'betterme-components/services/GetSurveyAndRates/reducer';
import files from 'betterme-components/services/UploadFiles/reducer';
import inputData from 'betterme-components/services/PerformanceRetrospectiveInput/reducer';
import searchBar from 'betterme-components/services/SearchBar/reducer';
import selfEvaluationList from 'betterme-components/services/SelfEvaluationWidget/reducer';
import periodByType from 'betterme-components/services/GetPeriodByType/reducer';


const rootReducer = combineReducers({
  actionRequired,
  addLeaderRequest,
  adminPassword,
  app,
  applications,
  assessment,
  authenticate,
  awatingResultCounter,
  changeLeaderRequest,
  changeLeaderRequestModal,
  communityConfigs,
  communityInformation,
  createSaveEvaluation,
  dashboard,
  dashboardSuggestions,
  evaluationDetail,
  evaluations,
  evaluationsTeam,
  featureDiscovery,
  feedbackForLeaders,
  feedbackForLeadersReceived,
  feedbackOverview,
  feedbackView,
  file,
  fileFeedback,
  fileGoal,
  files,
  form,
  fullscreenModal,
  getChangeLeaderDismissReason,
  tierType,
  goalDetail,
  goals,
  goalsAssigned,
  gotIt,
  i18n,
  inputData,
  leaderActions,
  leadersByPeriod,
  leadershipAdmin,
  leadershipRequest,
  leadershipRequestsReceived,
  leadershipRequestsSent,
  mainHeader,
  mainSearch,
  modal,
  myFeedbacks,
  myTeam,
  myTeamEvaluations,
  notifications,
  notificationsV2,
  notificationsFeedback,
  notificationsHistory,
  performanceRetrospective,
  selfRetrospective,
  performanceRetrospectiveDetail,
  periodByType,
  profile,
  promotionRequestsHistory,
  potentialAssessmentResults,
  removeLeaderModal,
  requestFeedbackR,
  requests,
  routing,
  search: partial(search, searchActions),
  searchBar,
  selfEvaluationList,
  sendRequestFeedback,
  session,
  skills,
  skillsRated,
  stellar,
  SMUWidgetContainer: combineReducers({
    i18n: smui18n,
    starmeUpDNAWidget,
  }),
  surveyQuestionsAndRates,
  sendfeedbackForLeaders,
  suggestActions,
  suggestPromotion,
  suggestedActionsList,
  suggestions,
  teamDashboard,
  teamFeedbacks,
  teamPromotions,
  tellUsAbout,
  toastr,
  topicsRated,
  updateSubmitEvaluation,
  user,
  userCarousel,
  userInfo,
  userPromotionRequestsGroup,
  teamInformationWA, // TO DO: REMOVE
});

export default rootReducer;
