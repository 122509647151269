/* eslint-disable react-hooks/exhaustive-deps */
// @packages
import React, { useEffect, useMemo } from 'react';
import BreadcrumbHeader from 'smu-ui-components/BreadcrumbHeader';
import Container from 'smu-layout-components/Container';
import OneOnOneDetail from 'smu-app-components/OneOnOne/Detail';
import PropTypes from 'prop-types';
import UtilsProvider from 'smu-app-components/UtilsProvider';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { useOneOnOneDetail } from 'smu-services/briefme/api/v1/community/member/one-to-one/meets/[id]';

// @app
import {
  CALENDAR_PROVIDER,
  ENABLED_ONE_ON_ONE,
  ENABLED_ONE_ON_ONE_TP_AI_SUGGESTIONS,
} from 'betterme-components/services/CommunityConfigs/constants';
import {
  getCommunityConfigValue,
  getCommunityConfigValueBool,
} from 'betterme-components/services/CommunityConfigs/selectors';

// @own
import messages from './messages';
import { trackEventBackClick } from './analytics';

const OneOnOneDetailLayout = ({
  calendarProvider,
  enabledOneOnOne,
  enabledOneOnOneTPAISuggestions,
  intl: { formatMessage },
  params,
  router,
}) => {
  const id = params?.id;
  const { error, isLoading, isValidating, selectUser } = useOneOnOneDetail({
    id,
    shouldFetch: enabledOneOnOne,
  });
  const user = selectUser();

  const crumbs = useMemo(() => {
    const crumbs = [
      {
        onClick: trackEventBackClick,
        text: formatMessage(messages.breadcrumbHeader.crumbs.first),
        url: '/1on1',
      },
      {
        isLoading: isLoading || (isValidating && error),
        text: `${formatMessage(
          messages.breadcrumbHeader.crumbs.second,
        )} ${user?.firstName} ${user?.lastName}`,
      },
    ];

    if (error && !isValidating) {
      crumbs.pop();
    }

    return crumbs;
  }, [error, user]);

  const handleBreadcrumbHeaderArrowClick = () => {
    trackEventBackClick();
    router.goBack();
  };

  const buildUserProfileUrl = user => (
    `/profile/${window.btoa(user?.identification)}/dashboard`
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const utils = {
    buildUserProfileUrl,
    calendarProvider,
    enableAddToCalendar: true,
    enableMeetingRoom: true,
    enableSyncWithCalendar: true,
    enableTalkingPointsAISuggestions: enabledOneOnOneTPAISuggestions,
    enabledSideBarPendingActions: true,
    mainFeatureIsEnabled: enabledOneOnOne,
  };

  return (
    <div className="h-full">
      {enabledOneOnOne && (
        <div className="sticky top-[64px] w-full z-10">
          <BreadcrumbHeader
            breadcrumbsClassName="w-full max-w-[1260px] mx-auto"
            className="p-5"
            crumbs={crumbs}
            innerShadow
            legacy={false}
            onArrowClick={handleBreadcrumbHeaderArrowClick}
            rounded={false}
          />
        </div>
      )}
      <Container centered>
        <div className="py-8">
          <UtilsProvider utils={utils}>
            <OneOnOneDetail id={Number(id)} />
          </UtilsProvider>
        </div>
      </Container>
    </div>
  );
};

OneOnOneDetailLayout.propTypes = {
  calendarProvider: PropTypes.string,
  enabledOneOnOne: PropTypes.bool,
  enabledOneOnOneTPAISuggestions: PropTypes.bool,
  intl: PropTypes.object,
  params: PropTypes.object,
  router: PropTypes.object,
};

const mapStateToProps = (state) => ({
  calendarProvider: getCommunityConfigValue(state, CALENDAR_PROVIDER),
  enabledOneOnOne: getCommunityConfigValueBool(state, ENABLED_ONE_ON_ONE),
  enabledOneOnOneTPAISuggestions: getCommunityConfigValueBool(
    state,
    ENABLED_ONE_ON_ONE_TP_AI_SUGGESTIONS,
  ),
});

export default injectIntl(connect(mapStateToProps)(OneOnOneDetailLayout));
