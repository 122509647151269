import { defineMessages } from 'react-intl';

export default defineMessages({
  confirmationActionNo: {
    id: 'Betterme.WriteForMe.Feedback.ConfirmationActionNo',
    defaultMessage: 'No',
  },
  confirmationActionYes: {
    id: 'Betterme.WriteForMe.Feedback.ConfirmationActionYes',
    defaultMessage: 'Yes',
  },
  confirmationTitle: {
    id: 'Betterme.WriteForMe.Feedback.ConfirmationTitle',
    defaultMessage:
      'Regenerating your response will create a new text and overwrite the previous suggestion. Are you sure you want to continue?',
  },
  firstStepAction: {
    id: 'Betterme.WriteForMe.Feedback.FirstStepAction',
    defaultMessage: 'Generate',
  },
  firstStepActionLoading: {
    id: 'Betterme.WriteForMe.Feedback.FirstStepActionLoading',
    defaultMessage: 'Generating',
  },
  firstStepPlaceholder: {
    id: 'Betterme.WriteForMe.Feedback.FirstStepPlaceholder',
    defaultMessage: 'Write your feedback here.',
  },
  firstStepTitle: {
    id: 'Betterme.WriteForMe.Feedback.FirstStepTitle',
    defaultMessage: 'Let me help you write your feedback',
  },
  firstStepDescription: {
    id: 'Betterme.WriteForMe.Feedback.FirstStepDescription',
    defaultMessage:
      'Give an example of why {xxx} rocks at a competency or needs to work on it. With that, an example of feedback can be suggested for you to review.',
  },
  firstStepDescription13: {
    id: 'Betterme.WriteForMe.Feedback.FirstStepDescription13',
    defaultMessage:
      'Share why you think {xxx} excels at {xxxx}, and needs to work on {xxxxx}.',
  },
  firstStepDescription13P: {
    id: 'Betterme.WriteForMe.Feedback.FirstStepDescription13P',
    defaultMessage:
      'Share why you think {xxx} excels at {xxxx}, or any of the other competencies you selected, and needs to work on {xxxxx}.',
  },
  firstStepDescription3: {
    id: 'Betterme.WriteForMe.Feedback.FirstStepDescription3',
    defaultMessage: 'Share why you think {xxx} excels at {xxxx}.',
  },
  firstStepDescription3P: {
    id: 'Betterme.WriteForMe.Feedback.FirstStepDescription3P',
    defaultMessage:
      'Share why you think {xxx} excels at {xxxx}, or any of the other competencies you selected.',
  },
  firstStepDescription2: {
    id: 'Betterme.WriteForMe.Feedback.FirstStepDescription2',
    defaultMessage: 'Share why you think {xxx} does well at {xxxx}',
  },
  firstStepDescription2P: {
    id: 'Betterme.WriteForMe.Feedback.FirstStepDescription2P',
    defaultMessage:
      'Share why you think {xxx} does well at {xxxx}, or any of the other competencies you selected.',
  },
  firstStepDescription12: {
    id: 'Betterme.WriteForMe.Feedback.FirstStepDescription12',
    defaultMessage:
      'Share why you think {xxx} does well at {xxxx} and needs to work on {xxxxx}.',
  },
  firstStepDescription12P: {
    id: 'Betterme.WriteForMe.Feedback.FirstStepDescription12P',
    defaultMessage:
      'Share why you think {xxx} does well at {xxxx} and needs to work on {xxxxx}, or any of the other competencies you selected.',
  },
  firstStepDescription1: {
    id: 'Betterme.WriteForMe.Feedback.FirstStepDescription1',
    defaultMessage:
      'Share why you think {xxx} needs to work on {xxxx}. Can you also highlight something positive about them?',
  },
  firstStepDescription1P: {
    id: 'Betterme.WriteForMe.Feedback.FirstStepDescription1P',
    defaultMessage:
      'Share why you think {xxx} needs to work on {xxxx}, or any of the other competencies you selected. Can you also highlight something positive about them?',
  },
  mainButtonTooltip: {
    id: 'Betterme.WriteForMe.Feedback.MainButtonTooltip',
    defaultMessage:
      'This feature helps you generate well-cratfed and meaningful feedbacks. Enhance your writing and help your colleagues succeed! Give it a try.',
  },
  secondStepActionInsert: {
    id: 'Betterme.WriteForMe.Feedback.SecondStepActionInsert',
    defaultMessage: 'Insert',
  },
  secondStepActionSave: {
    id: 'Betterme.WriteForMe.Feedback.SecondStepActionSave',
    defaultMessage: 'Update',
  },
  secondStepActionSettings: {
    id: 'Betterme.WriteForMe.Feedback.SecondStepActionSettings',
    defaultMessage: 'Adjust',
  },
  secondStepActionUpdate: {
    id: 'Betterme.WriteForMe.Feedback.SecondStepActionUpdate',
    defaultMessage: 'Re-generate',
  },
  secondStepActionUpdateLoading: {
    id: 'Betterme.WriteForMe.Feedback.SecondStepActionUpdateLoading',
    defaultMessage: 'Re-generating',
  },
  secondStepDescription: {
    id: 'Betterme.WriteForMe.Feedback.SecondStepDescription',
    defaultMessage:
      'Here is the suggested text. You can continue personalizing your text by changing the language, length, and perfect tone, or just use this text as it is.',
  },
  secondStepTitle: {
    id: 'Betterme.WriteForMe.Feedback.SecondStepTitle',
    defaultMessage: 'Let me help you write your feedback',
  },
  secondStepActionDiscard: {
    id: 'Betterme.WriteForMe.Feedback.SecondStepActionDiscard',
    defaultMessage: 'Discard',
  },
  error: {
    id: 'Betterme.WriteForMe.Feedback.Error',
    defaultMessage: 'Something went wrong. Please try again later.',
  },
});
