// @packages
import FileSave from 'file-saver';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';

// @app
import {
  getPromotionsReport,
} from '../api';

// @own
import {
  getPromotionsReportFail,
  getPromotionsReportSuccess,
} from './actions';
import {
  GET_PROMOTIONS_REPORT,
} from './actionTypes';

const getMimeType = fileType => {
  switch (fileType) {
    case 'csv':
      return 'text/csv';
    case 'xls':
      return 'application/vnd.ms-excel';
    case 'xlsx':
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    default:
      return
  }
};

function* getPromotionsRequestWorker({ payload }) {
  const { fileType } = payload;
  const data = {
    ...payload,
  };
  const mimeType = getMimeType(fileType);

  try {
    const response = yield call(getPromotionsReport, data);
    const blob = new Blob([response], { 'type': mimeType });
    FileSave.saveAs(blob, `promotions_report.${fileType}`);
    yield put(getPromotionsReportSuccess(URL.createObjectURL(blob)));
  } catch (error) {
    yield put(getPromotionsReportFail(error.message));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

export default function* promotionsWatcher() {
  yield takeLatest(GET_PROMOTIONS_REPORT, getPromotionsRequestWorker);
}
