// @packages
import React from 'react';
import Typography from 'smu-ui-components/Typography';
import Link from 'smu-ui-components/Link';
import cn from 'classnames';

const TextParserUrlContainer = ({ children, className, ...rest }) => (
  <Typography
    className={cn('hover:text-violet font-bold break-all', className)}
    component={Link}
    href={children}
    legacy={false}
    underline="hover"
    {...rest}
  >
    {children}
  </Typography>
);

TextParserUrlContainer.defaultProps = {
  className: 'text-black underline',
};

export default TextParserUrlContainer;
