// @packages
import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import AvatarMedia from 'smu-ui-components/AvatarMedia';
import moment from 'moment';

// @own
import './styles.scss';

const RatingFeedbacksListItem = ({
  className,
  creationTime,
  job,
  profileImageCode,
  senderName,
}) => {
  return (
    <li className={cn('rating-feedback-list-item', className)}>
      <AvatarMedia
        className="rating-feedback-list-item__avatar"
        firstName={senderName}
        job={job}
        profileImageCode={profileImageCode}
        useLink={false}
      />
      <div className="rating-feedback-list-item__creation">
        {moment(creationTime).format('L')}
      </div>
    </li>
  );
};

RatingFeedbacksListItem.propTypes = {
  className: PropTypes.string,
  creationTime: PropTypes.number.isRequired,
  job: PropTypes.string.isRequired,
  profileImageCode: PropTypes.string.isRequired,
  senderName: PropTypes.string.isRequired,
};

export default RatingFeedbacksListItem;
