// @app
import { handleServerError } from 'betterme-components/api';
import { apiInstance } from 'betterme-components/utils/requestHelper';

export function getPerformanceRetrospectiveDetail(evaluationId) {
  return apiInstance.get(`api/evaluations/${evaluationId}`)
    .then(({ data }) => data.result)
    .catch(handleServerError);
};

export function createAnswerPerformanceRetrospective(evaluationId, data) {
  return apiInstance.post(`api/evaluations/${evaluationId}/answer`, data)
    .then(({ data }) => data.result)
    .catch(handleServerError);
};
