// @packages
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// @app
import MyTeamLayout from 'betterme-components/Layouts/MyTeam';
import MyTeamMenu from 'betterme-components/MyTeamMenu';
import TalentMatrixDetails from 'smu-app-components/TalentMatrix/Details';
import { isEnabledTalentMatrix } from 'betterme-components/TalentMatrix/selectors';
import { selectEvaluationPeriod } from 'betterme-components/Authorization/selectors';

function MyTeamEvaluationsTalentMatrixLayout({
  enableTalentMatrix,
  evaluationPeriod,
  router,
}) {
  useEffect(() => {
    window.scrollTo(0, 0);

    if (!enableTalentMatrix) {
      router.push('/myprofile');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableTalentMatrix])

  return (
    <MyTeamLayout>
      <MyTeamMenu className="py-8 flex-1" path="/myteam/evaluations">
        <TalentMatrixDetails className="mt-6" periodId={evaluationPeriod.id} />
      </MyTeamMenu>
    </MyTeamLayout>
  );
}

MyTeamEvaluationsTalentMatrixLayout.propTypes = {
  enableTalentMatrix: PropTypes.bool,
  evaluationPeriod: PropTypes.object,
  router: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  enableTalentMatrix: isEnabledTalentMatrix(state),
  evaluationPeriod: selectEvaluationPeriod(state),
});

export default connect(mapStateToProps)(MyTeamEvaluationsTalentMatrixLayout);
