// @packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Select from 'smu-ui-components/SelectV2';

// @own
import './styles.scss';

const BehaviourQuestion = ({
  behaviourOptions,
  coEvaluatorName,
  onChange,
  placeholder,
  question,
  suggestedByMessage,
}) => {
  const [behaviourValue, setBehaviourValue] = useState(question?.previousRate);
  const handleChange = (value) => {
    setBehaviourValue(value);
    onChange({ questionId: question?.id, ratingId: value });
  };

  const renderOptionLabel = (option) => {
    const isCoEvaluatorOption = option?.value === question?.ratedByCoEvaluator;

    return (
      <div className={cn('py-2.5 px-5', {
        'flex flex-col items-start content-start border-l-4 border-l-indigo': isCoEvaluatorOption,
      })}>
        {option?.label}
        {isCoEvaluatorOption && (
          <span className='text-xs'>
            {formatMessage(suggestedByMessage,
              { username: coEvaluatorName }
            )}
          </span>
        )}
      </div>
    );
  };

  return (
    <div className="border-t mt-6 p-6 flex items-center justify-between">
      <p className="m-0 w-96 font-roboto text-sm text-shark">
        <b>{`${question?.topic?.name}: `}</b>{question?.name}
      </p>
      <Select
        className="behaviour-questions__options w-72"
        isClearable={false}
        onChange={handleChange}
        options={behaviourOptions}
        placeholder={placeholder}
        renderOptionLabel={renderOptionLabel}
        value={behaviourValue}
      />
    </div>
  );
}

BehaviourQuestion.propTypes = {
  behaviourOptions: PropTypes.array.isRequired,
  coEvaluatorName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  question: PropTypes.object.isRequired,
  suggestedByMessage: PropTypes.object.isRequired,
};

export default BehaviourQuestion;
