// @packages
import { createSelector } from 'reselect';

export const selectGoalDetailDomain = (state) => state.goalDetail;

export const selectGoalsPeriod = createSelector(
  selectGoalDetailDomain,
  (state) => state.goalsPeriod,
);

export const selectGoalDetail = createSelector(
  selectGoalDetailDomain,
  (state) => state.goalDetail,
);

export const selectGoalDetailLoading = createSelector(
  selectGoalDetailDomain,
  (state) => state.loading,
);

export const selectSelectedGoalId = createSelector(
  selectGoalDetailDomain,
  (state) => state.selectedGoalId,
);
