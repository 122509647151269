export function draftData(data) {
  const ratedTopics = data?.ratedTopics?.reduce((acc, ratedTopic) => {
    const competenciesWithRate = ratedTopic?.topics?.map(competency =>
      ({ competency, rateId: ratedTopic?.id }));
    return [...acc, ...competenciesWithRate];
  }, []);

  return {
    coCreated: data?.coCreated || [],
    comment: data?.comment,
    draftId: data?.id,
    evalueeId: data?.evaluee?.id,
    flagComment: data?.coCreated?.flagComment || false,
    flagSkills: data?.coCreated?.flagSkills || false,
    promotion: null,
    ratedTopics,
    score: (data?.score && data?.score?.value),
    status: data?.status,
    title: data?.title,
    type: data?.type,
  };
}
