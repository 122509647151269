// @packages
import React from 'react';
import PropTypes from 'prop-types';

//@own
import './styles.scss';

const CompetenciesCardItemNotApply = ({
  description,
  isChecked,
  onChange,
  radioId,
  value,
}) => (
  <>
    <input
      className="competencies-card-item-not-apply__input competencies-card-item-not-apply__checkbox-input"
      id={radioId}
      checked={isChecked}
      onChange={onChange}
      type="radio"
      value={value}
    />
    <label className="competencies-card-item-not-apply__checkbox-label" for={radioId}>
      <span className="competencies-card-item-not-apply__checkbox-label-check">
        <svg className="competencies-card-item-not-apply__checkbox-label-svg" width="12px" height="9px" viewbox="0 0 12 9">
          <polyline points="1 5 4 8 11 1"></polyline>
        </svg>
      </span>
      <span>
        {description}
      </span>
    </label>
  </>
);

CompetenciesCardItemNotApply.defaultProps = {
  onChange: () => { },
};

CompetenciesCardItemNotApply.propTypes = {
  description: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  radioId: PropTypes.string.isRequired,
  value: PropTypes.number,
}

export default CompetenciesCardItemNotApply;
