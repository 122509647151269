import { handleServerError } from 'betterme-components/api';
import { apiInstance } from 'betterme-components/utils/requestHelper';

export function getAssessmentQuestions(params) {
  return apiInstance.get('api/questions', { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getAssessmentRatings(params) {
  return apiInstance.get('api/ratings', { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function sendAssessmentSummary(data) {
  return apiInstance.post('api/survey/answer', data)
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function sendAssessmentResults(data, id) {
  return apiInstance.patch(`api/survey/score/${id}`, data)
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getAssessmentCollaborators() {
  return apiInstance.get('api/potentialAssessment/collaborators')
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getAssessmentTier(userId) {
  return apiInstance.get(`/api/survey/type/${userId}/tier`)
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getAssessmentScore(params) {
  return apiInstance.get('api/survey/score', { params })
    .then(({ data }) => data)
    .catch(handleServerError);
}