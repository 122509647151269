// @packages
import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";
import RootModal from "smu-app-components/RootModal";
import UserAvatar from "smu-ui-components/UserAvatar";
import moment from "moment";

// @app
import ConfirmationModal, {
  centerModalClass,
  modalStyles
} from "../../ConfirmationModal";
import { LEADER_CONFIRMATION_MODAL } from "../../constants/modalTypes";
import {
  LEADER_ACTION_ADD,
  LEADER_ACTION_REQUEST
} from "../../constants/leader";
import actionsMessages from "../../common/messages/actions";

// @own
import messages from "../messages";
import * as actions from "../actions";
import "./styles.scss";

const AVATAR_SIZE = 32;

const LeadershipRequestActionModal = ({
  action,
  addLeader,
  close,
  intl: { formatMessage },
  leaderActions: { fetching },
  requestId,
  requestLeader,
  user,
}) => {
  const cnRoot = "leadership-request-action-modal";
  const profileImageCode = user.profileImageCode || user.imageCode;
  const fullName = `${user.firstName} ${user.lastName}`;
  const date = moment()
    .format("MMM DD")
    .replace(".", "");

  return (
    <ConfirmationModal
      acceptMessage={formatMessage(actionsMessages.ActionsAccept)}
      cancelMessage={formatMessage(
        actionsMessages[
          action === LEADER_ACTION_ADD ? "ActionsCancel" : "ActionsDecline"
        ]
      )}
      className={cnRoot}
      closeModal={() => close()}
      loading={fetching}
      onAccept={() => {
        if (action === LEADER_ACTION_ADD) {
          addLeader(user);
        } else {
          requestLeader(user, requestId, true);
        }
      }}
      onCancel={() => {
        if (action === LEADER_ACTION_ADD) {
          close();
        } else {
          requestLeader(
            user,
            requestId,
            false,
            formatMessage(messages.LeadershipRequestComponentsRequestDeclineSuccessText)
          );
        }
      }}
      title={formatMessage(messages.LeadershipRequestComponentsActionTitle)}
    >
      <div className={`${cnRoot}__content`}>
        <div className={`${cnRoot}__header`}>
          <div className={`${cnRoot}__user`}>
            <UserAvatar
              height={AVATAR_SIZE}
              profileImageCode={profileImageCode}
              width={AVATAR_SIZE}
            />
            <div className={`${cnRoot}__user-details`}>
              <span className={`${cnRoot}__user-fullname`}>{fullName}</span>
              <span className={`${cnRoot}__user-job`}>{user.job}</span>
            </div>
          </div>
          <span className={`${cnRoot}__date`}>{date}</span>
        </div>
        <div className={`${cnRoot}__message`}>
          {formatMessage(
            messages[
              action === LEADER_ACTION_ADD
                ? "LeadershipRequestComponentsActionText"
                : "LeadershipRequestComponentsRequestText"
            ],
            {
              fullName
            }
          )}
        </div>
      </div>
    </ConfirmationModal>
  );
};

LeadershipRequestActionModal.propTypes = {
  action: PropTypes.oneOf([LEADER_ACTION_ADD, LEADER_ACTION_REQUEST])
    .isRequired,
  addLeader: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  requestId: PropTypes.number,
  requestLeader: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = ({ leaderActions }) => ({
  leaderActions
});
const ConnenctedLeadershipRequestActionModal = connect(mapStateToProps, {
  ...actions
})(LeadershipRequestActionModal);

export default RootModal(
  injectIntl(ConnenctedLeadershipRequestActionModal),
  LEADER_CONFIRMATION_MODAL,
  modalStyles,
  centerModalClass
);
