import { handleServerError } from 'betterme-components/api';
import { apiInstance } from 'betterme-components/utils/requestHelper';

export function sendLeadershipRequest(leaderId, collaboratorId, data) {
  return apiInstance.post(
    `api/users/leaders/${leaderId}/collaborators/${collaboratorId}/leadershipRequest`, data
  )
    .then(({ data }) => data.result)
    .catch(handleServerError);
}
