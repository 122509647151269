import {
  GET_LEADERSHIP_ADMIN_USER_PROFILE_FAIL,
  GET_LEADERSHIP_ADMIN_USER_PROFILE_SUCCESS,
  GET_LEADERSHIP_ADMIN_USER_PROFILE,
} from './actionTypes';

const initialState = {
  errorUser: null,
  loadingUser: false,
  user: {},
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_LEADERSHIP_ADMIN_USER_PROFILE_FAIL:
      return {
        ...state,
        errorUser: payload.error,
        loadingUser: false,
      };

    case GET_LEADERSHIP_ADMIN_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loadingUser: false,
        user: payload.response,
      };

    case GET_LEADERSHIP_ADMIN_USER_PROFILE:
      return {
        ...state,
        loadingUser: true,
      };

    default:
      return state;
  }
}
