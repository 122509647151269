import {
  ACTION_REQUIRED,
  ACTION_REQUIRED_FAIL,
  ACTION_REQUIRED_SUCCESS,
} from './actionTypes';

const initialState = {
  actionRequired: {},
  actionRequiredError: null,
  actionRequiredLoading: false,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case ACTION_REQUIRED:
      return {
        ...state,
        actionRequiredLoading: true,
      };

    case ACTION_REQUIRED_FAIL:
      return {
        ...state,
        actionRequiredError: payload.error,
        actionRequiredLoading: false
      };

    case ACTION_REQUIRED_SUCCESS:
      return {
        ...state,
        actionRequired: payload.response,
        actionRequiredLoading: false,
      };

    default:
      return state;
  }
}
