// @packages
import React from 'react';
import PropTypes from 'prop-types';

// @app
import PieChart from 'common/PieChart';

// @own
import './styles.scss';

const CompetencyDetail = ({ labelRate, question, pieChartConfig }) => {
  const {
    question: questionDetail,
    ratings: ratingsList,
  } = question;

  const ratingsListPieChart = ratingsList.map(rating => ({
    color: rating.rating?.color,
    count: rating.count,
    name: rating.rating?.name,
  }));

  return (
    <div className='competency-detail__content'>
      <div className="competency-detail__content-description">
        {questionDetail.name}
      </div>
      <div className="competency-detail__content-piechart">
        <div className="competency-detail__content-piechart-pie">
          <PieChart
            data={ratingsListPieChart}
            size={pieChartConfig.size}
            strokeSize={pieChartConfig.strokeSize}
          >
            <strong className="competency-detail__content-piechart-total-count">
              {question.totalRate}
            </strong>
            <span className="competency-detail__content-piechart-total-name">
              {labelRate}
              </span>
          </PieChart>
        </div>
        <div className="competency-detail__content-piechart-data">
          <ul className="competency-detail__content-piechart-list">
            {ratingsList.map((rating, index) => (
              <li
                className="competency-detail__content-piechart-item"
                key={index}
              >
                <span
                  className="competency-detail__content-piechart-color"
                  style={{ backgroundColor: rating.rating?.color }}
                />
                <strong className="competency-detail__content-piechart-count">
                  {rating.count}
                </strong>
                <span className="competency-detail__content-piechart-name">
                  {rating.rating?.name}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

CompetencyDetail.defaultProps = {
  pieChartConfig: {
    size: 160,
    strokeSize: 25,
  },
};

CompetencyDetail.propTypes = {
  question: PropTypes.object.isRequired,
  labelRate: PropTypes.string.isRequired,
};

export default CompetencyDetail;