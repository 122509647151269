// @own
import {
  GET_FEEDBACK_FOR_LEADERS_SUMMARY,
  GET_FEEDBACK_FOR_LEADERS_SUMMARY_FAIL,
  GET_FEEDBACK_FOR_LEADERS_SUMMARY_SUCCESS,
  RESET_FEEDBACK_FOR_LEADERS,
} from './actionTypes';

export function getFeedbackForLeaders(userId) {
  return {
    type: GET_FEEDBACK_FOR_LEADERS_SUMMARY,
    payload: { userId }
  };
}

export function getFeedbackForLeadersFail(error) {
  return {
    type: GET_FEEDBACK_FOR_LEADERS_SUMMARY_FAIL,
    payload: { error }
  };
}

export function getFeedbackForLeadersSuccess(response) {
  return {
    type: GET_FEEDBACK_FOR_LEADERS_SUMMARY_SUCCESS,
    payload: { response },
  };
}

export function resetFeedbackForLeaders() {
  return {
    type: RESET_FEEDBACK_FOR_LEADERS,
  }
}
