// @app
import { castStringToBoolean } from 'utils/fotmatString';

// @own
import {
  ENABLED_BETTERME,
  ENABLED_INTERCOM,
  ENABLED_QUICK_ACCESS,
  ENABLED_STARMEUP,
  ENABLED_SURVEYS_ONLY,
  ONBOARDING_ENABLED,
} from './constants';

export const getCommunityConfigError = (state) =>
  state?.communityConfigs?.error;

export const getCommunityConfigFetching = (state) =>
  state?.communityConfigs?.fetching;

export const getCommunityConfigResult = (state) =>
  state?.communityConfigs?.result;

export const getCommunityConfigHasResult = (state) =>
  !!state?.communityConfigs?.result;

export const getCommunityConfigValue = (state, value) =>
  state?.communityConfigs?.result?.[value];

export const getCommunityConfigValueBool = (state, value) =>
  castStringToBoolean(getCommunityConfigValue(state, value));

export const isEnabledPerformance = (state) =>
  getCommunityConfigValueBool(state, ENABLED_BETTERME);

export const isEnabledIntercom = (state) =>
  getCommunityConfigValueBool(state, ENABLED_INTERCOM);

export const isEnabledQuickAccess = (state) =>
  getCommunityConfigValueBool(state, ENABLED_QUICK_ACCESS);

export const isEnabledEngagement = (state) =>
  getCommunityConfigValueBool(state, ENABLED_STARMEUP);

export const isEnabledSurveysOnly = (state) =>
  getCommunityConfigValueBool(state, ENABLED_SURVEYS_ONLY);

export const isEnabledOnboarding = (state) =>
  getCommunityConfigValueBool(state, ONBOARDING_ENABLED);
