// @packages
import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'smu-ui-components/Avatar';
import Typography from 'smu-ui-components/Typography';
import Link from 'smu-ui-components/Link';
import cn from 'classnames';

const PromotionsAvatar = ({
  avatarInfo,
  src,
}) => (
  <div className="flex flex-row">
    <Avatar
      className="my-auto"
      height={40}
      src={src}
      width={40}
    />
    <div className="flex flex-col ml-3 my-auto truncate">
      {avatarInfo?.map((info, index) => {
        return (
          <Typography
            className={info?.className}
            key={index}
            variant={info?.variant}
          >
            {
              info?.linkTo ?
                <Link
                  className={cn('text-black hover:text-black', info?.className)}
                  target="_blank"
                  to={info.linkTo}
                >
                  {info?.label}
                </Link>
                :
                info?.label
            }
          </Typography>
        );
      })}
    </div>
  </div>
);

PromotionsAvatar.propTypes = {
  avatarInfo: PropTypes.arrayOf(PropTypes.shape({
    className: PropTypes.string,
    label: PropTypes.string,
    variant: PropTypes.string,
  })),
  src: PropTypes.string,
};

export default PromotionsAvatar;
