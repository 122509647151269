// @packages
import PropTypes from 'prop-types';
import * as actionsFullScreenModal from 'smu-app-components/FullScreenModal/actions';
import * as actionsRootModal from 'smu-app-components/RootModal/actions';
import React, { Component } from 'react';
import Segment from 'smu-ui-components/Segment';
import filter from 'lodash/filter';
import find from 'lodash/find';
import omit from 'lodash/omit';
import some from 'lodash/some';
import uniqBy from 'lodash/uniqBy';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { push } from 'react-router-redux';

// @app
import ToastNotificationsAction from 'common/ToastNotificationAction';
import DeleteFileModal from 'common/DeleteFileModal';
import getMessageType from 'utils/getMessageType';
import { formatPeriodDates } from 'utils/formatPeriodDates';
import {
  getMyTeamGoalsLocation,
  getMyTeamPromotionsLocation,
} from 'betterme-components/routes/urls';
import { resetDashboardData } from 'betterme-components/Dashboard/actions';
import EvaluationList from 'betterme-components/EvaluationList';
import {
  EVALUATION_CREATE,
  EVALUATION_CREATE_FORM,
  EVALUATION_DRAFT_DELETE_MODAL,
  TEAM_EVALUATIONS_HISTORY,
} from 'betterme-components/constants/modalTypes';
import {
  selectAllCollaborators,
  selectCommunityId,
  selectConfigurations,
  selectEvaluationPeriod,
} from 'betterme-components/Authorization/selectors';
import { selectRatings, selectScores } from 'betterme-components/services/Layout/selectors';
import { deleteFile, setPromotionFiles } from 'betterme-components/SendSuggestPromotion/actions';
import { getEvaluationDetail, resetEvaluationDetail } from 'betterme-components/EvaluationDetail/actions';
import { selectIsFetching, selectEvaluationDetail } from 'betterme-components/EvaluationDetail/selectors';
import EvaluationDraftAlertSubmit from 'components/containers/EvaluationDraftAlertSubmit';
import { removeLocalStoredItem } from 'utils/localStorage';
import { ACTION_TYPE_REQUIRED } from 'components/common/constants';
import { evaluationsTeam } from 'betterme-components/services/TeamEvaluations/actions';
import {
  selectEvaluationsTeam,
  selectEvaluationsTeamLoading,
} from 'betterme-components/services/TeamEvaluations/selectors';
import { updateSubmitEvaluation } from 'betterme-components/services/EvaluationsUpdateSubmit/actions';
import { createSaveEvaluation } from 'betterme-components/services/EvaluationsCreateSave/actions';
import { setPerformanceRetrospective } from 'betterme-components/services/PerformanceRetrospective/actions';
import { selectPerformanceRetrospective } from 'betterme-components/services/PerformanceRetrospective/selectors';

// @own
import './styles.scss';
import * as actions from './actions';
import {
  trackEvaluationsCreateSuccess,
  trackEvaluationsDeleteDraft,
  trackEvaluationsDeleteDraftCancel,
  trackEvaluationsDeleteDraftSuccess,
  trackEvaluationsEditSuccess,
  trackEvaluationsEvaluateCompetencies,
  trackEvaluationsFormKeepInMindModal,
  trackEvaluationsSubmitWithImprovements,
  trackMyEvaluationDetailsClick,
  trackTeamViewHistoryClick,
} from './analytics';
import EvaluationCreate from './EvaluationCreate';
import EvaluationCreateForm from './EvaluationCreateForm';
import TeamEvaluations from './TeamEvaluations';
import EvaluationDraftAlertDelete from './EvaluationDraftAlertDelete';
import messages from './messages';
import {
  selectCanCreateBehaviourRetrospective,
  selectClosedGoals,
  selectDataCreateEvaluation,
  selectEvaluations,
  selectEvaluationRecentlyCreated,
  selectLoading,
  selectLoadingClosedGoals,
  selectLoadingDeleteDraft,
  selectLoadingEvaluationsDrafts,
  selectLoadingSubmitDraft,
  selectLoadingSuggestedActions,
  selectLoadingUpdateDraft,
  selectOptionsScores,
  selectRangeScores,
  selectSuggestedActions,
} from './selectors';
import { getEvaluationsQueryParams } from './helpers';
import {
  EVALUATION,
  NH_EVALUATION,
  NH_SELF_EVALUATION,
  PERFORMANCE_RETROSPECTIVE,
  SELF_EVALUATION,
  SELF_RETROSPECTIVE,
} from './constants';

class Evaluations extends Component {
  constructor() {
    super();

    this.state = {
      alreadyOpenCreateEvaluation: false,
    };

    this.handleAddCompetency = this.handleAddCompetency.bind(this);
    this.handleCalificationChange = this.handleCalificationChange.bind(this);
    this.handleChangePromotion = this.handleChangePromotion.bind(this);
    this.handleChangeRangeDatepicker = this.handleChangeRangeDatepicker.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.handleChangeText = this.handleChangeText.bind(this);
    this.handleCreateClick = this.handleCreateClick.bind(this);
    this.handleCreateDraft = this.handleCreateDraft.bind(this);
    this.handleCreateEvaluation = this.handleCreateEvaluation.bind(this);
    this.handleCreateFormClick = this.handleCreateFormClick.bind(this);
    this.handleDeleteDraft = this.handleDeleteDraft.bind(this);
    this.handleDeleteDraftCancel = this.handleDeleteDraftCancel.bind(this);
    this.handleDraftDelete = this.handleDraftDelete.bind(this);
    this.handleHistoryClick = this.handleHistoryClick.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.handleRemoveCompetency = this.handleRemoveCompetency.bind(this);
    this.handleResetDataCreateEvaluation = this.handleResetDataCreateEvaluation.bind(this);
    this.handleSubmitDraft = this.handleSubmitDraft.bind(this);
    this.handleCancelSubmitDraft = this.handleCancelSubmitDraft.bind(this);
    this.handleToastMessageClick = this.handleToastMessageClick.bind(this);
    this.handleUpdateDraft = this.handleUpdateDraft.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlerCloseConfirmationModal = this.handlerCloseConfirmationModal.bind(this);
    this.handleOpenEvaluation = this.handleOpenEvaluation.bind(this);
  }

  componentDidMount() {
    const {
      evaluationsTeam,
      isMyTeam,
      requestEvaluations,
      user: {
        id,
        identification,
      },
    } = this.props;

    if (!isMyTeam) {
      requestEvaluations(id);
    }

    evaluationsTeam(identification);

    this.handleOpenEvaluation();
  }

  componentDidUpdate(prevProps) {
    const {
      closedGoals: prevClosedGoals,
      dataCreateEvaluation: {
        dateTimeFrom: prevDateTimeFrom,
        dateTimeTo: prevDateTimeTo,
      },
      location: prevLocation,
    } = prevProps;
    const {
      addToastMessage,
      allowsGoals,
      allowsGoalsIncludedInEvaluation,
      closedGoals: currentClosedGoals,
      dataCreateEvaluation: {
        dateTimeFrom: currentDateTimeFrom,
        dateTimeTo: currentDateTimeTo,
        evaluee,
      },
      getClosedAssignedGoals,
      location,
      intl: { formatMessage },
    } = this.props;
    const { openCreateEvaluation } = getEvaluationsQueryParams(location?.query);
    const { openCreateEvaluation: prevOpenCreateEvaluation } = getEvaluationsQueryParams(prevLocation?.query);

    if (allowsGoals && !this.isSelfEvaluation() && allowsGoalsIncludedInEvaluation) {
      const dateTimeFromChanged = prevDateTimeFrom !== currentDateTimeFrom;
      const dateTimeToChanged = prevDateTimeTo !== currentDateTimeTo;
      const currentDateSelected = currentDateTimeFrom && currentDateTimeTo;

      if (dateTimeFromChanged || dateTimeToChanged) {
        if (currentDateSelected) {
          const goalsFilters = {
            dueDateFrom: currentDateTimeFrom?.valueOf(),
            dueDateTo: currentDateTimeTo?.valueOf(),
          };
          getClosedAssignedGoals(
            evaluee?.id,
            goalsFilters,
          );
        }
      }

      if (prevClosedGoals !== currentClosedGoals) {
        const closedGoalsWeightingSum = currentClosedGoals && currentClosedGoals.reduce((totalWeight, { weighting }) =>
          totalWeight + weighting, 0);
        const invalidGoalWeighting = closedGoalsWeightingSum !== 100;
        if (invalidGoalWeighting) {
          const actionText = formatMessage(
            messages.FeedbackEvaluationsEvaluationCreateWeightValidationAction
          );
          addToastMessage({
            fluid: true,
            message: formatMessage(
              messages.FeedbackEvaluationsEvaluationCreateWeightValidationMsg
            ),
            timeout: 5000,
            customClose: (props) => (<ToastNotificationsAction
              children={actionText}
              onClick={() => this.handleToastMessageClick(getMyTeamGoalsLocation())}
              {...props}
            />)
          });
        }
        this.setState({ invalidGoalWeighting });
      }
    }

    if (openCreateEvaluation !== prevOpenCreateEvaluation) {
      this.handleOpenEvaluation();
    }
  }

  componentWillUnmount() {
    const {
      dataCreateEvaluation: {
        type,
      },
      closeFullscreenModal,
      closeModal,
    } = this.props;


    if (type === EVALUATION
      || type === NH_EVALUATION
      || type === NH_SELF_EVALUATION
      || type === SELF_EVALUATION) {
      closeModal();
      closeFullscreenModal();
      this.handleResetDataCreateEvaluation();
    }
  }

  handleOpenEvaluation() {
    const { alreadyOpenCreateEvaluation } = this.state;
    const {
      location,
    } = this.props;
    const { openCreateEvaluation, isNewHire } = getEvaluationsQueryParams(location?.query);

    if (openCreateEvaluation && !alreadyOpenCreateEvaluation) {
      this.handleCreateClick({
        id: openCreateEvaluation,
        newHireEvalAvailable: isNewHire,
      }, true);
      this.setState({ alreadyOpenCreateEvaluation: true });
    }
  }

  getEvaluationCreateModalProps() {
    const {
      allowsEvaluationsSuggestedActions,
      allowsGlbFeatures,
      allowsGoalsWeightingSumValidation,
      allowsEvaluationsCreation,
      allowsEvaluationDrafts,
      leaderCanCreateBehaviourRetrospective,
      canCreateBehaviourRetrospective,
      communityId,
      dataCreateEvaluation,
      evaluationDetail,
      evaluationPeriod,
      getEvaluationDetail,
      getSuggestedActions,
      location,
      loadingClosedGoals,
      loadingEvalDetail,
      loadingSuggestedActions,
      resetDataCreateEvaluation,
      resetEvaluationDetail,
      setDataCreateEvaluation,
      setPromotionFiles,
      suggestedActions,
      user,
    } = this.props;
    const { invalidGoalWeighting } = this.state;
    const invalidGoalWeightingSum = !this.isSelfEvaluation()
      && allowsGoalsWeightingSumValidation
      && invalidGoalWeighting;

    return {
      allowsEvaluationsSuggestedActions,
      allowsGlbFeatures,
      allowsEvaluationsCreation,
      allowsEvaluationDrafts,
      canCreateBehaviourRetrospective: leaderCanCreateBehaviourRetrospective,
      canCreateBehaviourRetrospectiveAction: canCreateBehaviourRetrospective,
      communityId,
      evaluationDetail,
      evaluationPeriod,
      getEvaluationDetail,
      getSuggestedActions,
      invalidGoalWeightingSum,
      loadingClosedGoals,
      loadingEvalDetail,
      loadingSuggestedActions,
      onChangeRangeDatepicker: this.handleChangeRangeDatepicker,
      onChangeSelect: this.handleChangeSelect,
      onChangeText: this.handleChangeText,
      onClose: this.handleResetDataCreateEvaluation,
      onCreateForm: this.handleCreateFormClick,
      locationQuery: location?.query,
      resetDataCreateEvaluation,
      resetEvaluationDetail,
      setDataCreateEvaluation,
      setPromotionFiles,
      suggestedActions,
      values: dataCreateEvaluation,
      user,
    };
  }

  handleItemClick(evaluation) {
    const { params, goToPath, isMyTeam } = this.props;

    const userId = params?.userid;
    const evaluationType = evaluation?.type;
    const evaluationId = evaluation?.id;
    let pathEvaluationDetail;

    switch (evaluationType) {
      case PERFORMANCE_RETROSPECTIVE:
        pathEvaluationDetail = isMyTeam ?
          `/myteam/performance-retrospective-detail/` :
          `/performance-retrospective-detail/`;
        break;
      case SELF_RETROSPECTIVE:
        pathEvaluationDetail = `/evaluations/self-retrospective/`;
        break;
      default:
        pathEvaluationDetail = userId ? `/profile/${userId}/evaluations/` :
          (isMyTeam ? `/myteam/evaluations/` : `/evaluations/`);
        break;
    };
    trackMyEvaluationDetailsClick(evaluationType);
    goToPath(`${pathEvaluationDetail}${evaluationId}`);
  }

  handleHistoryClick(evalueeId, userName) {
    const {
      cleanTeamEvaluationState,
      communityId,
      intl: { formatMessage },
      myTeamFilters: { endDate, startDate },
      openModal,
      requestTeamEvaluations,
    } = this.props;

    const getMessage = getMessageType(communityId);
    const titleCompletedEvaluations = getMessage('EvaluationsCompletedEvaluations');
    const from = startDate ? startDate.valueOf() : undefined;
    const to = endDate ? endDate.valueOf() : undefined;

    trackTeamViewHistoryClick();
    requestTeamEvaluations({
      creationTimeFrom: from,
      creationTimeTo: to,
      evalueeId,
    });

    openModal({
      modalType: TEAM_EVALUATIONS_HISTORY,
      modalProps: {
        onClose: cleanTeamEvaluationState,
        onItemClick: this.handleItemClick,
        title: formatMessage(titleCompletedEvaluations),
        userName,
      }
    });
  }

  handleCreateClick(currentCollaborator, createFromOutside) {
    const {
      addToastMessage,
      allCollaborators,
      evaluationPeriod,
      intl: { formatMessage },
      openModal,
      setDataCreateEvaluation,
    } = this.props;
    const dataCreateEvaluation = {
      evaluee: find(allCollaborators, collaborator => collaborator.id === currentCollaborator.id),
    };
    const newHireAvailable = this.isSelfEvaluation()
      ? currentCollaborator?.newHireSelfEvalAvailable
      : currentCollaborator?.newHireEvalAvailable;

    setDataCreateEvaluation(dataCreateEvaluation);

    if (evaluationPeriod) {
      openModal({
        modalType: EVALUATION_CREATE,
        modalProps: {
          draftId: currentCollaborator.draftId,
          isNewHire: newHireAvailable,
          isSelfEvaluation: this.isSelfEvaluation(),
        },
      });
    } else {
      addToastMessage({
        fluid: true,
        message: formatMessage(
          messages.FeedbackEvaluationsEvaluationCreatePeriodValidationAction
        ),
        timeout: 5000,
      });
    }
  }

  handleCreateFormClick(isNewHire, evaluationTitle, draftId) {
    const {
      dataCreateEvaluation: {
        dateTimeFrom,
        dateTimeTo,
        title,
        type,
      },
      openFullscreenModal,
    } = this.props;
    const evaluationRange = formatPeriodDates(dateTimeFrom, dateTimeTo);
    if (!draftId) {
      trackEvaluationsCreateSuccess(title, evaluationRange, type);
    } else {
      trackEvaluationsEditSuccess(title, evaluationRange, type);
    }
    openFullscreenModal({
      modalType: EVALUATION_CREATE_FORM,
      modalProps: {
        evaluationTitle,
        isNewHire,
      },
    });
  }

  handleChange(name, value) {
    this.props.setDataCreateEvaluation({ [name]: value });
  }

  handleChangeText(name, event) {
    let value = event.target.value;
    this.props.setDataCreateEvaluation({ [name]: value });
  }

  handleChangeSelect(name, value) {
    this.props.setDataCreateEvaluation({ [name]: value });
  }

  handleChangePromotion(name, value) {
    const {
      dataCreateEvaluation: { promotion },
      setDataCreateEvaluation
    } = this.props;
    setDataCreateEvaluation({
      promotion: {
        ...promotion,
        [name]: value,
      }
    });
  }

  handleCalificationChange(name, value) {
    const { setDataCreateEvaluation } = this.props;
    setDataCreateEvaluation({ name, score: value });
  }

  handleChangeRangeDatepicker({ startDate, endDate }) {
    this.props.setDataCreateEvaluation({
      dateTimeFrom: startDate,
      dateTimeTo: endDate,
    });
  }

  handleAddCompetency(competency, rate) {
    const {
      dataCreateEvaluation: { ratedTopics },
      setDataCreateEvaluation,
    } = this.props;
    const { id: rateId, label: rateLabel } = rate;
    trackEvaluationsEvaluateCompetencies(competency.name, rateLabel);
    const newRatedTopics = uniqBy(
      [{ competency, rateId }, ...ratedTopics],
      (i) => i?.competency?.id
    );
    setDataCreateEvaluation({
      ratedTopics: newRatedTopics,
    });
  }

  handleRemoveCompetency(competency) {
    const { dataCreateEvaluation: { ratedTopics } } = this.props;
    if (some(ratedTopics, ({ competency: ratedTopic }) => ratedTopic.id === competency.id)) {
      this.props.setDataCreateEvaluation({
        ratedTopics: filter(ratedTopics, ({ competency: { id } }) => id !== competency.id)
      });
    }
  }

  handleCreateEvaluation(goalsFinalScore, isValidatePromotion) {
    const {
      createSaveEvaluation,
      dataCreateEvaluation,
      evaluationPeriod,
      intl: { formatMessage },
      user,
    } = this.props;
    const dataPromotions = isValidatePromotion ? dataCreateEvaluation?.promotion : null;
    const dataGoalsFinalScore = goalsFinalScore ? { score: goalsFinalScore } : {};
    const evaluationFilesID = dataCreateEvaluation.attachments.map(file => file.id);
    const dataCreateEvaluationFiltered = omit(dataCreateEvaluation, ['attachments', 'evaluee']);
    const ratedTopics = dataCreateEvaluation?.ratedTopics?.filter(t => t?.rateId);
    const typeEvaluation = this.getTypeEvaluation();
    const isSelfEvaluation = (typeEvaluation === 'SELF-EVALUATION'
      || typeEvaluation === 'NH-SELF-EVALUATION');
    const wellDoneMessage = isSelfEvaluation
      ? formatMessage(messages.FeedbackEvaluationsEvaluationSuccessFooterMessage)
      : formatMessage(
        messages.FeedbackEvaluationsEvaluationSuccessFooterMessageTeam,
        { username: dataCreateEvaluation?.evaluee?.firstName },
      );
    const data = {
      ...dataCreateEvaluationFiltered,
      ...dataGoalsFinalScore,
      evalueeId: dataCreateEvaluation?.evaluee?.id,
      files: evaluationFilesID,
      nineBoxComment: dataCreateEvaluation?.nineBoxComment || '',
      promotion: dataPromotions,
      ratedTopics,
      status: 'SUBMITTED',
      suggestedActionsComment: dataCreateEvaluation?.suggestedActionsComment || '',
    };

    createSaveEvaluation({
      data,
      dataEvaluee: {
        firstName: dataCreateEvaluation?.evaluee?.firstName,
        identification: dataCreateEvaluation?.evaluee?.identification,
      },
      dataEvaluator: {
        id: user?.id,
        identification: user?.identification,
      },
      evaluationId: dataCreateEvaluation?.draftId,
      message: wellDoneMessage,
      typeEvaluation: data?.type,
      evaluationPeriod,
    });
  }

  handleResetDataCreateEvaluation() {
    const { resetDashboardData, resetDataCreateEvaluation } = this.props;
    resetDataCreateEvaluation();
    resetDashboardData();
  }

  handleToastMessageClick(goTo) {
    const { goToPath } = this.props;
    goToPath(goTo);
  }

  handleUpdateDraft(goalsFinalScore, isValidatePromotion) {
    const {
      dataCreateEvaluation,
      intl: { formatMessage },
      updateSubmitEvaluation,
      user,
    } = this.props;
    const dataPromotions = isValidatePromotion ? dataCreateEvaluation?.promotion : null;
    const dataGoalsFinalScore = goalsFinalScore ? { score: goalsFinalScore } : {};
    const evaluationFilesID = dataCreateEvaluation.attachments.map(file => file.id);
    const dataCreateEvaluationFiltered = omit(dataCreateEvaluation, ['attachments', 'evaluee']);
    const ratedTopics = dataCreateEvaluation?.ratedTopics?.filter(t => t?.rateId);

    const data = {
      ...dataCreateEvaluationFiltered,
      ...dataGoalsFinalScore,
      evalueeId: dataCreateEvaluation?.evaluee?.id,
      files: evaluationFilesID,
      promotion: dataPromotions,
      ratedTopics,
      status: 'DRAFT',
    };
    const toastMessage = formatMessage(messages.FeedbackEvaluationsEvaluationCreateDraftSaved);

    updateSubmitEvaluation({
      data,
      dataEvaluee: {
        firstName: dataCreateEvaluation?.evaluee?.firstName,
        identification: dataCreateEvaluation?.evaluee?.identification,
      },
      dataEvaluator: {
        id: user?.id,
        identification: user?.identification,
      },
      evaluationId: dataCreateEvaluation?.draftId,
      message: toastMessage,
      typeEvaluation: data?.type,
    });
  }

  handleCreateDraft(goalsFinalScore, isValidatePromotion) {
    const {
      createSaveEvaluation,
      dataCreateEvaluation,
      evaluationPeriod,
      intl: { formatMessage },
      user,
    } = this.props;
    const dataGoalsFinalScore = goalsFinalScore ? { score: goalsFinalScore } : {};
    const dataPromotions = isValidatePromotion ? dataCreateEvaluation?.promotion : null;
    const evaluationFilesID = dataCreateEvaluation.attachments.map(file => file.id);
    const dataCreateEvaluationFiltered = omit(dataCreateEvaluation, ['attachments', 'evaluee']);
    const ratedTopics = dataCreateEvaluation?.ratedTopics?.filter(t => t?.rateId);

    const data = {
      ...dataCreateEvaluationFiltered,
      ...dataGoalsFinalScore,
      evalueeId: dataCreateEvaluation?.evaluee?.id,
      files: evaluationFilesID,
      promotion: dataPromotions,
      ratedTopics,
      status: 'DRAFT',
    };
    const toastMessage = formatMessage(messages.FeedbackEvaluationsEvaluationCreateDraftSaved);

    createSaveEvaluation({
      data,
      dataEvaluee: {
        firstName: dataCreateEvaluation?.evaluee?.firstName,
        identification: dataCreateEvaluation?.evaluee?.identification,
      },
      dataEvaluator: {
        id: user?.id,
        identification: user?.identification,
      },
      evaluationId: dataCreateEvaluation?.draftId,
      message: toastMessage,
      typeEvaluation: data?.type,
      evaluationPeriod,
    });
  }

  handleSubmitDraft(goalsFinalScore, isValidatePromotion) {
    const {
      dataCreateEvaluation,
      intl: { formatMessage },
      updateSubmitEvaluation,
      user,
    } = this.props;
    const dataGoalsFinalScore = goalsFinalScore ? { score: goalsFinalScore } : {};
    const dataPromotions = isValidatePromotion ? dataCreateEvaluation?.promotion : null;
    const evaluationFilesID = dataCreateEvaluation.attachments.map(file => file.id);
    const dataCreateEvaluationFiltered = omit(dataCreateEvaluation, ['attachments', 'evaluee']);
    const typeEvaluation = this.getTypeEvaluation();
    const isSelfEvaluation = (typeEvaluation === 'SELF-EVALUATION'
      || typeEvaluation === 'NH-SELF-EVALUATION');
    const wellDoneMessage = isSelfEvaluation
      ? formatMessage(messages.FeedbackEvaluationsEvaluationSuccessFooterMessage)
      : formatMessage(
        messages.FeedbackEvaluationsEvaluationSuccessFooterMessageTeam,
        { username: dataCreateEvaluation?.evaluee?.firstName },
      );
    const data = {
      ...dataCreateEvaluationFiltered,
      ...dataGoalsFinalScore,
      files: evaluationFilesID,
      promotion: dataPromotions,
      score: dataCreateEvaluation?.score,
      status: 'SUBMITTED',
      evalueeId: dataCreateEvaluation?.evaluee?.id,
    };
    trackEvaluationsSubmitWithImprovements();
    updateSubmitEvaluation({
      data,
      dataEvaluee: {
        firstName: dataCreateEvaluation?.evaluee?.firstName,
        identification: dataCreateEvaluation?.evaluee?.identification,
      },
      dataEvaluator: {
        id: user?.id,
        identification: user?.identification,
      },
      evaluationId: dataCreateEvaluation?.draftId,
      message: wellDoneMessage,
      typeEvaluation: data?.type,
    });
  }

  handleCancelSubmitDraft() {
    trackEvaluationsFormKeepInMindModal();
  }

  handleDeleteDraft(draftId, toastMessage) {
    const {
      deleteDraftEvaluation,
      values,
    } = this.props;
    deleteDraftEvaluation({
      id: draftId,
      messages: toastMessage,
      typeEvaluation: this.getTypeEvaluation(),
    });
    trackEvaluationsDeleteDraftSuccess(values?.type);
    removeLocalStoredItem(ACTION_TYPE_REQUIRED);
  }

  handleDraftDelete(draftId) {
    const {
      openModal,
      values,
    } = this.props;
    trackEvaluationsDeleteDraft(values?.type);
    openModal({
      modalType: EVALUATION_DRAFT_DELETE_MODAL,
      modalProps: { draftId }
    });
    removeLocalStoredItem(ACTION_TYPE_REQUIRED);
  }

  handleDeleteDraftCancel() {
    const { values } = this.props;
    trackEvaluationsDeleteDraftCancel(values?.type);
    removeLocalStoredItem(ACTION_TYPE_REQUIRED);
  }

  handlerCloseConfirmationModal(showConfirmationPromotion) {
    const {
      addToastMessage,
      closeFullscreenModal,
      intl: { formatMessage },
    } = this.props;
    closeFullscreenModal();

    if (showConfirmationPromotion) {
      const actionText = formatMessage(
        messages.FeedbackEvaluationsEvaluationPromotionCreationAction
      );
      addToastMessage({
        fluid: true,
        message: formatMessage(
          messages.FeedbackEvaluationsEvaluationPromotionCreation
        ),
        timeout: 5000,
        customClose: (props) => (
          <ToastNotificationsAction
            children={actionText}
            onClick={() => this.handleToastMessageClick(getMyTeamPromotionsLocation())}
            {...props}
          />
        ),
      });
    }
  }

  getActiveTab() {
    const { isMyTeam } = this.props;

    return isMyTeam ? 1 : 0;
  }

  isSelfEvaluation() {
    const { dataCreateEvaluation: { evaluee }, user } = this.props;
    return (evaluee?.id === user?.id);
  }

  getTypeEvaluation() {
    const { dataCreateEvaluation: { evaluee }, user } = this.props;
    return (evaluee?.id === user?.id
      ? 'SELF-EVALUATION'
      : 'EVALUATION'
    );
  }

  render() {
    const {
      allowsEvaluationDrafts,
      allowsEvaluationsCreation,
      allowsMyEvaluationsGoalScore,
      allowsNewHireEvaluationCreation,
      allowsNewHireEvaluationDraft,
      allowsPeriodEvaluation,
      allowsRatedTopicsEvaluationsCreation,
      allowsTeamEvaluationsGoalScore,
      allowsPerformanceRetrospectiveCreation,
      allowsPerformanceRetrospectiveDraft,
      canCreateBehaviourRetrospective,
      communityId,
      dataCreateEvaluation,
      evaluations,
      evaluationsTeamInfo,
      evaluationsTeamLoading,
      filterConfig,
      isGoalScoreContinous,
      loading,
      loadingDeleteDraft,
      loadingEvaluationsDrafts,
      loadingSubmitDraft,
      loadingUpdateDraft,
      messages,
      newStyle,
      optionsScores,
      own,
      rangeScores,
      ratings,
      resetEvaluationDetail,
      user: {
        access: { newWordingForLeaders },
        firstName,
        id: userId,
      },
      user,
    } = this.props;
    const activeTab = this.getActiveTab();
    const asMainLeader = evaluationsTeamInfo?.asMain || [];
    const asMainAndSecondaryLeader = evaluationsTeamInfo?.asMainAndSecondary || [];
    const asSecondaryLeader = evaluationsTeamInfo?.asSecondary || [];

    return (
      <div className="evaluations">
        {activeTab === 0 ? (
          <Segment
            className="evaluations__received"
            loading={loading}
            loadingType="betterme"
            withChildren={false}
          >
            <EvaluationList
              evaluations={evaluations}
              isGoalScoreContinous={isGoalScoreContinous}
              onItemClick={this.handleItemClick}
              userName={firstName}
              own={own}
              showGoalsFinalScore={own
                ? allowsMyEvaluationsGoalScore
                : allowsTeamEvaluationsGoalScore
              }
              showGlobalScore={false}
            />
          </Segment>
        ) : (
          <Segment
            loading={evaluationsTeamLoading}
            loadingType="betterme"
          >
            <TeamEvaluations
              allowsEvaluationDrafts={allowsEvaluationDrafts}
              allowsEvaluationsCreation={allowsEvaluationsCreation}
              allowsNewHireEvaluationCreation={allowsNewHireEvaluationCreation}
              allowsNewHireEvaluationDraft={allowsNewHireEvaluationDraft}
              allowsPeriodEvaluation={allowsPeriodEvaluation}
              allowsPerformanceRetrospectiveCreation={allowsPerformanceRetrospectiveCreation}
              allowsPerformanceRetrospectiveDraft={allowsPerformanceRetrospectiveDraft}
              asMainAndSecondaryLeader={asMainAndSecondaryLeader}
              asMainLeader={asMainLeader}
              asSecondaryLeader={asSecondaryLeader}
              canCreateBehaviourRetrospective={canCreateBehaviourRetrospective}
              filterConfig={filterConfig}
              loadingEvaluationsDrafts={loadingEvaluationsDrafts}
              statusMessages={messages}
              newStyle={newStyle}
              newWordingForLeaders={newWordingForLeaders}
              onCreateClick={this.handleCreateClick}
              onHistoryClick={this.handleHistoryClick}
              selfUserId={userId}
            />
          </Segment>
        )}
        <EvaluationCreate
          modalProps={this.getEvaluationCreateModalProps()}
        />
        <EvaluationCreateForm
          onModalClose={this.handleResetDataCreateEvaluation}
          modalProps={{
            allowsEvaluationsCreation,
            allowsRatedTopicsEvaluationsCreation,
            communityId,
            onAddCompetency: this.handleAddCompetency,
            onCalificationChange: this.handleCalificationChange,
            onChange: this.handleChange,
            onChangePromotion: this.handleChangePromotion,
            onChangeText: this.handleChangeText,
            onCreateDraft: this.handleCreateDraft,
            onCreateEvaluation: this.handleCreateEvaluation,
            onRemoveCompetency: this.handleRemoveCompetency,
            onUpdateDraft: this.handleUpdateDraft,
            optionsScores,
            rangeScores,
            ratings,
            resetEvaluationDetail,
            selfEvaluation: this.isSelfEvaluation(),
            values: dataCreateEvaluation,
            userId,
            user,
          }}
        />
        <EvaluationDraftAlertDelete
          modalProps={{
            loadingDeleteDraft,
            onAccept: this.handleDeleteDraft,
            onCancel: this.handleDeleteDraftCancel,
          }}
        />
        <EvaluationDraftAlertSubmit
          modalProps={{
            loading: loadingSubmitDraft || loadingUpdateDraft,
            onAccept: this.handleSubmitDraft,
            onCancel: this.handleCancelSubmitDraft,
          }}
        />
        <DeleteFileModal />
      </div>
    );
  }
}

Evaluations.propTypes = {
  allCollaborators: PropTypes.array,
  allowsEvaluationDrafts: PropTypes.bool,
  allowsEvaluationsCreation: PropTypes.bool,
  allowsGlbFeatures: PropTypes.bool.isRequired,
  allowsGoals: PropTypes.bool.isRequired,
  allowsPeriodEvaluation: PropTypes.bool.isRequired,
  allowsRatedTopicsEvaluationsCreation: PropTypes.bool,
  cleanTeamEvaluationState: PropTypes.func.isRequired,
  closedGoals: PropTypes.array,
  communityId: PropTypes.number.isRequired,
  deleteDraftEvaluation: PropTypes.func.isRequired,
  evaluationPeriod: PropTypes.object,
  evaluations: PropTypes.array,
  intl: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  loadingDeleteDraft: PropTypes.bool,
  loadingSubmitDraft: PropTypes.bool,
  loadingUpdateDraft: PropTypes.bool,
  newStyle: PropTypes.bool,
  params: PropTypes.object,
  requestEvaluations: PropTypes.func.isRequired,
  setPromotionFiles: PropTypes.func.isRequired,
  submitDraftEvaluation: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    collaborators: PropTypes.array.isRequired,
  }).isRequired,
};


const mapStateToProps = (state) => {
  return {
    allCollaborators: selectAllCollaborators(state),
    allowsContinuousEvaluation: selectConfigurations('allowsContinuousEvaluation')(state),
    allowsEvaluationDrafts: selectConfigurations('allowsEvaluationDrafts')(state),
    allowsEvaluationsCreation: selectConfigurations('allowsEvaluationsCreation')(state),
    allowsEvaluationsSuggestedActions: selectConfigurations('allowsEvaluationsSuggestedActions')(state),
    allowsGlbFeatures: selectConfigurations('allowsGlbFeatures')(state),
    allowsGoals: selectConfigurations('allowsGoals')(state),
    allowsGoalsWeightingSumValidation: selectConfigurations('allowsEvaluationClosedGoalsWeightingSumValidation')(state),
    allowsMyEvaluationsGoalScore: selectConfigurations('allowsMyEvaluationsGoalScore')(state),
    allowsNewHireEvaluationCreation: selectConfigurations('allowsNewHireEvaluationCreation')(state),
    allowsNewHireEvaluationDraft: selectConfigurations('allowsNewHireEvaluationDraft')(state),
    allowsPeriodEvaluation: selectConfigurations('allowsPeriodEvaluation')(state),
    allowsPeriodEvaluationCreation: selectConfigurations('allowsPeriodEvaluationCreation')(state),
    allowsPeriodEvaluationDraft: selectConfigurations('allowsPeriodEvaluationDraft')(state),
    allowsPerformanceRetrospectiveCreation: selectConfigurations('allowsPerformanceRetrospectiveCreation')(state),
    allowsPerformanceRetrospectiveDraft: selectConfigurations('allowsPerformanceRetrospectiveDraft')(state),
    allowsRatedTopicsEvaluationsCreation: selectConfigurations('allowsRatedTopicsEvaluationsCreation')(state),
    allowsTeamEvaluationsGoalScore: selectConfigurations('allowsTeamEvaluationsGoalScore')(state),
    allowsGoalsIncludedInEvaluation: selectConfigurations('allowsGoalsIncludedInEvaluation')(state),
    closedGoals: selectClosedGoals(state),
    communityId: selectCommunityId(state),
    dataCreateEvaluation: selectDataCreateEvaluation(state),
    evaluationDetail: selectEvaluationDetail(state),
    evaluationPeriod: selectEvaluationPeriod(state),
    evaluations: selectEvaluations(state),
    evaluationRecentlyCreated: selectEvaluationRecentlyCreated(state),
    evaluationsTeamInfo: selectEvaluationsTeam(state),
    evaluationsTeamLoading: selectEvaluationsTeamLoading(state),
    isGoalScoreContinous: selectConfigurations('continuousGoalScores')(state),
    loading: selectLoading(state),
    loadingClosedGoals: selectLoadingClosedGoals(state),
    loadingDeleteDraft: selectLoadingDeleteDraft(state),
    loadingEvalDetail: selectIsFetching(state),
    loadingEvaluationsDrafts: selectLoadingEvaluationsDrafts(state),
    loadingSubmitDraft: selectLoadingSubmitDraft(state),
    loadingSuggestedActions: selectLoadingSuggestedActions(state),
    loadingUpdateDraft: selectLoadingUpdateDraft(state),
    optionsScores: selectOptionsScores(state),
    rangeScores: selectRangeScores(state),
    ratings: selectRatings(state),
    scores: selectScores(state),
    suggestedActions: selectSuggestedActions(state),
    leaderCanCreateBehaviourRetrospective: selectCanCreateBehaviourRetrospective(state),
    performanceRetrospectiveData: selectPerformanceRetrospective(state),
  };
};

const goToPath = (path) => push(path);

export default connect(mapStateToProps, {
  evaluationsTeam,
  ...actions,
  ...actionsRootModal,
  ...actionsFullScreenModal,
  updateSubmitEvaluation,
  createSaveEvaluation,
  addToastMessage,
  deleteFile,
  getEvaluationDetail,
  goToPath,
  resetDashboardData,
  resetEvaluationDetail,
  setPromotionFiles,
  setPerformanceRetrospective,
})(injectIntl(Evaluations));
