import {
  CLEAN_SKILL_LIST,
  CLEAN_SKILL_STATE,
  DELETE_SKILL,
  GET_SKILLS,
  GET_SKILLS_FAIL,
  GET_SKILLS_SUCCESS,
  INIT_SELECTED_SKILLS,
  SELECT_SKILL,
} from './actionTypes';

export function cleanSkillList() {
  return {
    type: CLEAN_SKILL_LIST,
  };
}

export function cleanSkillState() {
  return {
    type: CLEAN_SKILL_STATE,
  };
}

export function deleteSkill(skillId) {
  return {
    type: DELETE_SKILL,
    payload: { skillId },
  };
}

export function getSkills(name, selectedSkills) {
  return {
    type: GET_SKILLS,
    payload: { name, selectedSkills },
  };
}

export function getSkillsFail(error) {
  return {
    type: GET_SKILLS_FAIL,
    payload: { error },
  };
}

export function getSkillsSuccess(response) {
  return {
    type: GET_SKILLS_SUCCESS,
    payload: { response },
  };
}

export function initSelectedSkills(skills) {
  return {
    type: INIT_SELECTED_SKILLS,
    payload: { skills },
  };
}

export function selectSkill(skill) {
  return {
    type: SELECT_SKILL,
    payload: { skill },
  };
}
