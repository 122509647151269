// @packages
import { takeLatest, call, put } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';

// @own
import { GET_USER_INFORMATION } from './actionTypes';
import { getUserInfo } from './api';
import { getUserInfoFail, getUserInfoSuccess } from './actions';

function* getUserInfoWorker({ payload: { identification } }) {
  try {
    const response = yield call(getUserInfo, identification);
    yield put(getUserInfoSuccess(response));
  } catch (error) {
    yield put(getUserInfoFail(error));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }))
  }
};

export default function* getUserInfoWatcher() {
  yield takeLatest(GET_USER_INFORMATION, getUserInfoWorker);
};
