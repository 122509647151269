// @packages
import React, { useEffect } from 'react';
import BannerCookiesConsent from 'smu-app-components/BannerCookiesConsent/New';
import ChangePasswordMandatory from 'smu-app-components/ChangePasswordMandatory';
import CropImages from 'smu-ui-components/CropImage';
import PropTypes from 'prop-types';
import ToastNotifications from 'smu-app-components/ToastNotifications';
import cn from 'classnames';
import withRootModal from 'smu-app-components/RootModal/withRootModal';
import { actionRequestInit } from 'smu-utils/reduxRequests/actions';
import { connect } from 'react-redux';
import { detectWebView } from 'smu-utils/device';
import { push } from 'react-router-redux';
import { useGetPreferences } from 'smu-services/os-member/api/v1/community/preferences/get';

// @app
import ChangeLeaderRequestModal from 'betterme-components/ChangeLeaderRequestModal';
import ChangeLeaderSuccessModal from 'betterme-components/ChangeLeaderRequestModal/ChangeLeaderSuccessModal';
import CulturalLevels from 'betterme-components/CulturalLevels';
import EngagementIndicatorModal from 'betterme-components/Profile/EngagementIndicatorModal';
import FeedbackForLeadersInfoModal from 'betterme-components/FeedbackForLeadersInfoModal';
import FeedbackForLeadersSelectionModal from 'betterme-components/FeedbackForLeadersSelectionModal';
import GoalWeightWarningModal from 'betterme-components/Goals/GoalWeightWarningModal';
import GoalsActionsCloseModal from 'betterme-components/Goals/GoalsActionsCloseModal';
import GoalsActionsDeleteModal from 'betterme-components/Goals/GoalsActionsDeleteModal';
import GoalsActionsDetailModal from 'betterme-components/Goals/GoalsActionsDetailModal';
import GoogleTagManager from 'betterme-components/GoogleTagManager';
import LeadershipRemovalModal from 'components/containers/LeadershipRemovalModal';
import LeadershipRequestActionModal from 'betterme-components/LeadershipRequest/LeadershipRequestActionModal';
import LeadershipRequestSuccessModal from 'betterme-components/LeadershipRequest/LeadershipRequestSuccessModal';
import Main from 'betterme-components/Main';
import MainHeader from 'betterme-components/MainHeader';
import NavMenu from 'betterme-components/NavMenu';
import OnboardingModal from 'betterme-components/Onboarding/OnboardingModal';
import ProfileMoreInfoModal from 'betterme-components/ProfileMoreInfoModal';
import QueryModals from 'betterme-components/QueryModals';
import RemoveLeaderWellDoneModal from 'components/containers/RemoveLeaderWellDoneModal';
import SuccessfulGoalsCloseModal from 'betterme-components/Goals/SuccessfulGoalsCloseModal';
import SuccessfullGoalsConfirmCreateModal from 'betterme-components/Goals/SuccessfullGoalsConfirmCreateModal';
import SuggestActions from 'betterme-components/SuggestActions';
import SuggestedActionsList from 'betterme-components/SuggestedActionsList';
import TellUsAboutModal from 'betterme-components/TellUsAboutModal';
import ValidationLowestRatingModal from 'betterme-components/ValidationLowestRatingModal';
import WhatsNewModal from 'betterme-components/WhatsNewModal';
import { ONBOARDING_ENABLED } from 'betterme-components/services/CommunityConfigs/constants';
import { getCommunityConfigValueBool } from 'betterme-components/services/CommunityConfigs/selectors';
import { getCommunityScores, getCommunityRatings, getCommunityTopics } from 'betterme-components/services/Layout/actions'
import { getLeaderRequestModalInfo } from 'common/Layout/actions';
import { getMyProfileLocation } from 'betterme-components/routes/urls';
import {
  selectConfigurations,
  selectUser,
} from 'betterme-components/Authorization/selectors';

// @own
import './styles.scss';

const Layout = ({
  RightColumn,
  Sidenav,
  children,
  getCommunityRatings,
  getCommunityScores,
  getCommunityTopics,
  getLeaderRequestModalInfo,
  location: { query },
  mainContent,
  openMenu,
  session,
}) => {
  const { selectCommunityAllowsCookies } = useGetPreferences({
    name: 'cookies',
  });
  const communityAllowsCookies = selectCommunityAllowsCookies();
  const userData = session ? session.user : {};
  const isWebView = detectWebView();


  const getMainProps = () => ({
    Main: mainContent,
    RightColumn,
    Sidenav,
    session,
  });

  useEffect(() => {
    if (query.leaderRequestId) {
      getLeaderRequestModalInfo(query.leaderRequestId);
    }

    getCommunityRatings();
    getCommunityScores();
    getCommunityTopics();
  }, []);

  return (
    <div className={cn('layout', {
      'layout--padding': !isWebView,
      'layout--open-menu': !isWebView && openMenu,
      'layout--webview': isWebView,
    })}>
      {!isWebView && (
        <>
          <MainHeader />
          <NavMenu />
        </>
      )}
      {!children ?
        <Main {...getMainProps()} /> :
        React.cloneElement(children, { session })
      }
      {communityAllowsCookies && (
        <>
          <BannerCookiesConsent />
          <GoogleTagManager />
        </>
      )}
      <ToastNotifications position="bottom-left" color="black" />
      <GoalsActionsDetailModal outsideClickIgnoreClass="modals" closeOnclickOutside />
      <GoalsActionsCloseModal />
      <GoalsActionsDeleteModal />
      <SuccessfulGoalsCloseModal />
      <SuccessfullGoalsConfirmCreateModal />
      <GoalWeightWarningModal />
      <SuggestActions closeOnclickOutside />
      <SuggestedActionsList closeOnclickOutside />
      <CropImages />
      <TellUsAboutModal
        modalProps={{ user: userData }}
      />
      <LeadershipRequestActionModal />
      <LeadershipRequestSuccessModal />
      <EngagementIndicatorModal />
      <ValidationLowestRatingModal />
      <ProfileMoreInfoModal />
      <FeedbackForLeadersInfoModal />
      <FeedbackForLeadersSelectionModal />
      <ChangeLeaderRequestModal />
      <ChangeLeaderSuccessModal />
      <WhatsNewModal />
      <OnboardingModal />
      <LeadershipRemovalModal />
      <RemoveLeaderWellDoneModal />
      <QueryModals />
      <CulturalLevels />
      <ChangePasswordMandatory />
    </div>
  );
}

Layout.propTypes = {
  RightColumn: PropTypes.func,
  Sidenav: PropTypes.func,
  allowsEvaluationDrafts: PropTypes.bool,
  allowsEvaluations: PropTypes.bool,
  allowsSelfRetrospectiveCreation: PropTypes.bool,
  allowsSelfRetrospectiveDraft: PropTypes.bool,
  children: PropTypes.node,
  community: PropTypes.object,
  getCommunityRatings: PropTypes.func,
  getCommunityScores: PropTypes.func,
  getCommunityTopics: PropTypes.func,
  getLeaderRequestModalInfo: PropTypes.func,
  goToMyProfile: PropTypes.func,
  location: PropTypes.object,
  mainContent: PropTypes.func,
  openMenu: PropTypes.bool,
  openModal: PropTypes.func,
  session: PropTypes.object.isRequired,
  user: PropTypes.object,
};

const goToMyProfile = () => push(getMyProfileLocation());

const mapStateToProps = (state) => ({
  allowsEvaluationDrafts: selectConfigurations('allowsEvaluationDrafts')(state),
  allowsEvaluations: selectConfigurations('allowsEvaluations')(state),
  allowsSelfRetrospectiveCreation: selectConfigurations('allowsSelfRetrospectiveCreation')(state),
  allowsSelfRetrospectiveDraft: selectConfigurations('allowsSelfRetrospectiveDraft')(state),
  onboardingEnabled: getCommunityConfigValueBool(state, ONBOARDING_ENABLED),
  openMenu: state?.mainHeader?.openMenu,
  user: selectUser(state),
});

const mapDispatchToProps = {
  actionRequestInit,
  getCommunityRatings,
  getCommunityScores,
  getCommunityTopics,
  getLeaderRequestModalInfo,
  goToMyProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRootModal(Layout));
