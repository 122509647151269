import { handleServerError } from 'betterme-components/api';
import { apiInstance } from 'betterme-components/utils/requestHelper';

export function getSurveyQuestions(params) {
  return apiInstance.get('api/questions', { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getSurveyRates(params) {
  return apiInstance.get('api/ratings', { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}
