// @packages
import { flatten, get, map, uniqBy, orderBy } from 'lodash';
import { createSelector } from 'reselect';

// @app
import { selectRatings } from 'betterme-components/services/Layout/selectors';

const selectRatedTopics = (state, ownProps) => ownProps.ratedTopics;
export const selectCompetenciesOrder = ({ feedbackOverview }) => get(feedbackOverview, 'competenciesOrder');
export const selectRatingFilter = ({ feedbackOverview }) => get(feedbackOverview, 'ratingFilter');
export const selectSelectedCompetencyDetails = ({ feedbackOverview }) => get(feedbackOverview, 'selectedCompetencyDetails');

export const selectCompetenciesRatingList = createSelector(
  selectRatedTopics,
  selectRatingFilter,
  selectCompetenciesOrder,
  (ratedTopics, ratingFilter, order) => {
    if (!ratingFilter.length) {
      return [];
    }

    const filteredRatedTopics = ratedTopics.filter((ratedTopic) => {
      const isRatedWithRatingFilter = ratedTopic.ratings.find((rating) =>
      rating.id === ratingFilter[0]);

      return isRatedWithRatingFilter;
    });

    const competenciesWithRatings = filteredRatedTopics.map((ratedTopic) => {
      const { id, name, ratings } = ratedTopic;
      const totalFeedbacks = ratings.reduce((acc, { count }) => acc + count, 0);
      const ratingInfo = ratings.find((rating) => rating.id === ratingFilter[0]);
      const { count, feedback } = ratingInfo;
      const users = uniqBy(
        feedback.map(({ senderName: name,  profileImageCode, senderId }) => ({ name, profileImageCode, id: senderId })),
        'id'
      );

      return { id, name, total: totalFeedbacks, count, users };
    });

    return orderBy(competenciesWithRatings, [({ count, total }) => count / total], [order]);
  }
);

export const selectRatingFeedbacks = createSelector(
  selectRatedTopics,
  selectRatingFilter,
  selectSelectedCompetencyDetails,
  selectRatings,
  (ratedTopics, ratingFilter, selectedCompetencyDetails, ratingsInfo) => {
    if (!(ratedTopics.length &&
        ratingFilter.length &&
        selectedCompetencyDetails &&
        ratingsInfo)) {
      return undefined;
    }
    const selectedCompetency = ratedTopics.find((ratedTopic) =>
      ratedTopic.id === selectedCompetencyDetails);
    const total = selectedCompetency.ratings.reduce((acc, { count }) => acc + count, 0);
    const ratings = selectedCompetency.ratings
      .filter((rating) => ratingFilter.includes(rating.id))
      .map((rating) => {
        const { color, ordinality } = ratingsInfo.find(ratingInfo => ratingInfo.id === rating.id);
        return { ...rating, color, ordinality };
      });

    return { ...selectedCompetency, total, ratings };
  }
);

export const selectRatingsFilterConfig = createSelector(
    selectRatings,
    selectRatedTopics,
    selectSelectedCompetencyDetails,
    (ratings, ratedTopics, selectedCompetencyDetails) => {
      const selectedCompetency = ratedTopics.find((ratedTopic) =>
        ratedTopic.id === selectedCompetencyDetails);
      const availableFilters = selectedCompetency ? selectedCompetency.ratings.map(({ id }) => id) : [];
      const sortedAvailableFilters = ratings.filter((rating) => availableFilters.includes(rating.id));

      return {
        availableFilters: map(sortedAvailableFilters, ({ id }) => id),
        ratings,
      };
    }
);

export const selectFilterDropdownConfig = createSelector(
    selectRatings,
    selectRatedTopics,
    (ratingsConfig, ratedTopics) => {
      const ratingsOrdered = orderBy(ratingsConfig, ['ordinality'], ['desc']);
      const availableFilters = map(uniqBy(flatten(map(ratedTopics, ({ ratings }) => ratings)), 'id'), ({ id }) => id);
      const sortedAvailableFilters = ratingsOrdered.filter((rating) => availableFilters.includes(rating.id));

      return {
        availableFilters: map(sortedAvailableFilters, ({ id }) => id),
        ratings: ratingsOrdered,
      };
    }
);
