// @packages
import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'smu-ui-components/PopupV2';
import Image from 'smu-ui-components/Image';

// @own
import './styles.scss';

const StackedRatesBar = ({
  competency: { name },
  ratings,
  totalRate,
}) => {
  const filteredRatings = ratings?.filter(rating => rating?.percentage 
    && (rating?.rating?.value !== 0));

  return (
    <div className="stacked-rates-bar">
      <div className="stacked-rates-bar__header">
        <div className="stacked-rates-bar__competence">
          {name}
        </div>
        <div className="stacked-rates-bar__rate">
          {totalRate}
        </div>
      </div>
      <div className="stacked-rates-bar__bar">
        {filteredRatings.map(({
          name,
          percentage,
          rating,
        }, key) => (
            <div
              className="stacked-rates-bar__rate"
              key={key}
              style={{
                backgroundColor: rating.color,
                width: `${percentage}%`,
              }}
            >
              <Popup
                color="black"
                hoverEnabled
                trigger={<div className="stacked-rates-bar__tooltip-overlay" />}
              >
                <div className="stacked-rates-bar__tooltip">
                  <Image
                    alt={name}
                    className="stacked-rates-bar__tooltip-image"
                    code={rating.oppositeImageCode}
                    height={21}
                    scale={2}
                    width={21}
                  />
                  <div className="stacked-rates-bar__tooltip-percentage">
                    {percentage}%
                  </div>
                </div>
              </Popup>
            </div>
        ))}
      </div>
    </div>
  );
};

StackedRatesBar.propTypes = {
  competence: PropTypes.object.isRequired,
  ratings: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalRate: PropTypes.number.isRequired,
};

export default StackedRatesBar;
