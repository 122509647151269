// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Popup } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import AvatarMedia from 'smu-ui-components/AvatarMedia';
import Icon from 'smu-ui-components/Icon';
import Label from 'smu-ui-components/Label';

// @app
import { actionsMessages, commonMessages } from 'common/messages';
import {
  DRAFT,
  FOREIGN_DRAFT,
  SUBMITTED,
  SUBMITTED_BY_ME,
  SUBMITTED_BY_OTHER
} from 'betterme-components/Evaluations/constants';
import { formatBoldText } from 'utils/formatBoldText';
import UserAvatarList from 'common/UserAvatarList';
import { formatDateV2 as formatDate } from 'utils/formatDateV2';

// @own
import './styles.scss';
import messages from '../messages';

const TeamEvaluationsItem = ({
  allowsEvaluationsCreation,
  allowsEvaluationDrafts,
  allowsNewHireEvaluationCreation,
  allowsNewHireEvaluationDraft,
  allowsPeriodEvaluation,
  allowsPerformanceRetrospectiveCreation,
  allowsPerformanceRetrospectiveDraft,
  canCreateBehaviourRetrospective,
  className,
  enabledLeader,
  evaluation,
  firstName,
  id,
  identification,
  intl: { formatMessage },
  job,
  lastName,
  leftDaysNewHire,
  newHireAvailable,
  newHireEvalAvailable,
  onCreateClick,
  onHistoryClick,
  profileImageCode,
  selfUserId,
}) => {
  const hasDraft = evaluation?.status === DRAFT;
  const isDraft = (evaluation?.status === DRAFT) || (evaluation?.status === FOREIGN_DRAFT);
  const evaluators = evaluation?.evaluators;
  const draftId = evaluation?.draftId;
  const evaluationStatus = (evaluation?.status === DRAFT) ? DRAFT : evaluation?.status;
  const isSubmitted = ((evaluationStatus === SUBMITTED) && !evaluation?.evaluationAllowed)
    ? formatMessage(messages.FeedbackEvaluationsEvaluationSubmitted)
    : formatMessage(actionsMessages.ActionsCreate);
  const canCreateNewHireEvaluation = (newHireEvalAvailable &&
    (allowsNewHireEvaluationCreation || allowsNewHireEvaluationDraft));
  const showCreateEvaluation = (allowsPeriodEvaluation && (allowsEvaluationsCreation || allowsEvaluationDrafts))
    || canCreateNewHireEvaluation
    || (allowsPerformanceRetrospectiveCreation || allowsPerformanceRetrospectiveDraft);
  const evaluationsCreateDescrption = hasDraft
    ? formatMessage(messages.FeedbackEvaluationsTeamEvaluationsItemEdit)
    : isSubmitted;
  const evaluationsCreateIcon = hasDraft
    ? 'action-edit'
    : 'evaluation';
  const canCreatePerformanceRetrospective = ((allowsPerformanceRetrospectiveCreation || allowsPerformanceRetrospectiveDraft) && enabledLeader);
  const canCreateEvaluation = (allowsPeriodEvaluation && (allowsEvaluationsCreation || allowsEvaluationDrafts));
  const isEvaluationEnabled = ((evaluation?.evaluationAllowed === undefined) || (evaluation?.evaluationAllowed === true));
  const evaluationAllowed = (canCreateEvaluation || canCreateNewHireEvaluation || canCreatePerformanceRetrospective) && isEvaluationEnabled;
  const handleCreateEvaluation = evaluationAllowed
    ? () => {
      onCreateClick({ id, newHireEvalAvailable, draftId });
      canCreateBehaviourRetrospective(enabledLeader)
    }
    : undefined;

  const disabledIcon = evaluationAllowed ? 'blue-be' : 'grey';
  const typeOriginConfigs = {
    [DRAFT]: {
      status: 'tag-warning',
      text: formatMessage(messages.FeedbackEvaluationsEvaluationDraft),
    },
    [SUBMITTED]: {
      status: 'tag-success',
      text: formatMessage(messages.FeedbackEvaluationsEvaluationSubmitted),
    },
    [SUBMITTED_BY_ME]: {
      status: 'tag-success',
      text: formatMessage(messages.FeedbackEvaluationsEvaluationSubmittedByMe),
    },
    [SUBMITTED_BY_OTHER]: {
      status: 'tag-success',
      text: formatMessage(messages.FeedbackEvaluationsEvaluationSubmittedByOther),
    },
    [FOREIGN_DRAFT]: {
      status: 'tag-warning',
      text: formatMessage(messages.FeedbackEvaluationsEvaluationForeignDraft),
    },
  };
  const newHireDaysLeft = (leftDaysNewHire === 1)
    ? formatBoldText(formatMessage(commonMessages.NewHireDayLeft,
      { count: leftDaysNewHire },
    ))
    : formatBoldText(formatMessage(commonMessages.NewHireDaysLeft,
      { count: leftDaysNewHire },
    ));
  const evaluationLabelNode = (
    <span className="team-evaluations-item__history-icon">
      {evaluationStatus !== undefined ? (
        <Label
          className="team-evaluations-item__label"
          color={typeOriginConfigs[evaluationStatus]?.status}
          size="small"
          text={typeOriginConfigs[evaluationStatus]?.text}
        />
      ) : null}
    </span>
  );
  const nhLabelNode = (
    <span className="team-evaluations-item__nh-label">
      <Label
        color="tag-user-nh"
        size="small"
        text={formatMessage(commonMessages.NewHire)}
      />
      <p className="team-evaluations-item__nh-text">
        {newHireDaysLeft}
      </p>
    </span>
  );
  const evaluationsHistoryNode = (
    <span className="team-evaluations-item__history-icon">
      <Icon
        className="team-evaluations-item__history-icon"
        icon="history"
        color="blue-be"
      />
    </span>
  );
  const evaluationsCreateNode = (
    <span className="team-evaluations-item__history-icon">
      <Icon
        className="team-evaluations-item__create-icon"
        color={disabledIcon}
        icon={evaluationsCreateIcon}
      />
    </span>
  );
  const evaluationsLabelTextNode = (
    <div className="team-evaluations-item__label-tooltip">
      <p className="team-evaluations-item__label-tooltip-title">
        {formatMessage(
          messages.FeedbackEvaluationsEvaluationEvaluationSubmitted
        )}
      </p>
      <p className="team-evaluations-item__label-tooltip-subtitle">
        {formatMessage(
          messages.FeedbackEvaluationsEvaluationEvaluationSubmittedSubtitle
        )}
      </p>
    </div>
  );
  const evaluationsHistoryTextNode = (
    <div className="team-evaluations-item__history-text">
      {formatMessage(messages.FeedbackEvaluationsTeamEvaluationsItemHistory)}
    </div>
  );
  const evaluationsCreateTextNode = (
    <div className="team-evaluations-item__create-text">
      {evaluationsCreateDescrption}
    </div>
  );

  const evaluator = evaluators && evaluators[0];
  const hasEvaluator = evaluation && (evaluator?.id === selfUserId);
  const evaluatorFirstName = hasEvaluator ? formatMessage(commonMessages.Me) : evaluator?.firstName;
  const evaluatorLastName = !hasEvaluator && evaluator?.lastName;
  const evaluatorIdentification = evaluator?.identification;
  const isEvaluator = evaluators?.find(evaluator => evaluator?.id === selfUserId);
  const getLabelNode = () => {
    const hasPopup = ((evaluationStatus === SUBMITTED) && (evaluation?.evaluationAllowed) && !isEvaluator);

    if (hasPopup) {
      return (
        <Popup
          className="team-evaluations-item__label-tooltip"
          content={evaluationsLabelTextNode}
          inverted
          on="hover"
          position="bottom center"
          trigger={evaluationLabelNode}
        />
      );
    } else {
      return evaluationLabelNode;
    }
  };

  const getPopupContentNode = (evaluator) => {
    const {
      creationTime,
      submissionTime,
    } = evaluator;

    const dateLabel = isDraft
      ? formatMessage(messages.EvaluationsHeaderCreationDate)
      : formatMessage(messages.EvaluationsHeaderSubmittedDate);
    const date = isDraft ? submissionTime : creationTime;

    return (`${dateLabel}: ${formatDate(date, 'L')}`);
  };

  return (
    <>
      <li className={cn('team-evaluations-item', className, {
        'team-evaluations-item__new-hire': newHireAvailable,
      })}>
        <span className="team-evaluations-item__user-info">
          <AvatarMedia
            className="team-evaluations-item__avatar-media"
            firstName={firstName}
            identification={identification}
            job={job}
            lastName={lastName}
            profileImageCode={profileImageCode}
          />
          {newHireAvailable && nhLabelNode}
        </span>
        <span className="team-evaluations-item__status">
          {getLabelNode()}
        </span>
        <span className="team-evaluations-item__sender">
          {evaluation &&
            (evaluators?.length > 1 ? (
              <UserAvatarList
                maxDisplay={4}
                popupContent={getPopupContentNode}
                showPopup
                size={40}
                users={evaluators}
              />
            ) : (
              <AvatarMedia
                className="team-evaluations-item__sender--avatar"
                firstName={evaluatorFirstName}
                identification={evaluatorIdentification}
                lastName={evaluatorLastName}
                popupContent={getPopupContentNode(evaluators[0])}
                profileImageCode={evaluator?.profileImageCode}
                showPopup
                user={evaluators[0]}
              />
            ))}
        </span>
        <div className="team-evaluations-item__actions">
          <div
            className="team-evaluations-item__actions--history"
            onClick={() => onHistoryClick(id, firstName)}
          >
            <Popup
              className="team-evaluations-item__icon"
              content={evaluationsHistoryTextNode}
              inverted
              on="hover"
              position="bottom center"
              trigger={evaluationsHistoryNode}
            />
          </div>
          {(showCreateEvaluation) && (
            <div
              className="team-evaluations-item__action"
              onClick={handleCreateEvaluation}
            >
              <Popup
                className="team-evaluations-item__icon"
                content={evaluationsCreateTextNode}
                inverted
                on="hover"
                position="bottom center"
                trigger={evaluationsCreateNode}
              />
            </div>
          )}
        </div>
      </li>
    </>
  );
};

TeamEvaluationsItem.propTypes = {
  allowsEvaluationDrafts: PropTypes.bool.isRequired,
  allowsEvaluationsCreation: PropTypes.bool.isRequired,
  className: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  intl: PropTypes.object.isRequired,
  job: PropTypes.string,
  lastName: PropTypes.string,
  onCreateClick: PropTypes.func.isRequired,
  onHistoryClick: PropTypes.func.isRequired,
  profileImageCode: PropTypes.string,
  selfUserId: PropTypes.number,
};

export default injectIntl(TeamEvaluationsItem);
