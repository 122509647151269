import { defineMessages } from 'react-intl';

export default defineMessages({
  RemoveLeaderModalAccountTitle: {
    id: 'Betterme.RemoveLeaderModal.AccountTitle2',
    defaultMessage: 'I remove myself from being an Experience Leader of {collaborator} because:',
  },
  RemoveLeaderModalExperienceTitle: {
    id: 'Betterme.RemoveLeaderModal.OtherLeaderTitle2',
    defaultMessage: 'I remove myself from being a Experience Leader of {collaborator} because:',
  },
  RemoveLeaderModalCareerTitle: {
    id: 'Betterme.RemoveLeaderModal.CareerTitle2',
    defaultMessage: 'I remove myself from being a Career Mentor of {collaborator} because:',
  },
  RemoveLeaderModalMainTitle: {
    id: 'Betterme.RemoveLeaderModal.MainTitle',
    defaultMessage: 'I remove myself from being a Main Leader of {collaborator} because:',
  },
  RemoveLeaderModalSecondaryTitle: {
    id: 'Betterme.RemoveLeaderModal.SecondaryTitle',
    defaultMessage: 'I remove myself from being a Secondary Leader of {collaborator} because:',
  },
  RemoveLeaderModalSubitle: {
    id: 'Betterme.RemoveLeaderModal.SubTitle',
    defaultMessage: 'Remember when you make this action, all pending requests will be cancelled.',
  },
  RemoveLeaderModalToastSuccess: {
    id: 'Betterme.RemoveLeaderModal.ToastSuccess',
    defaultMessage: 'The action was successfully done',
  },
  RemoveLeaderModalToastFail: {
    id: 'Betterme.RemoveLeaderModal.ToastFail',
    defaultMessage: 'The leader couldn\'t be removed',
  },
});
