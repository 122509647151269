import {
  REMOVE_LEADER,
  REMOVE_LEADER_FAIL,
  REMOVE_LEADER_SUCCESS,
} from './actionTypes';

const initialState = {
  error: null,
  loading: false,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case REMOVE_LEADER:
      return { ...state, loading: true };

    case REMOVE_LEADER_FAIL:
      return { ...state, error: payload.error, loading: false };

    case REMOVE_LEADER_SUCCESS:
      return { ...state, loading: false };

    default:
      return state;
  }
}
