import { get } from 'lodash';
import { createSelector } from 'reselect';

const reducerDomain = 'userPromotionRequestsGroup';

export const selectUserPromotionRequestsGroup = state =>
  get(state, [reducerDomain, 'promotionRequestsGroup']);

export const selectLoadingUserPromotionRequestsGroup = state =>
  get(state, [reducerDomain, 'loadingPromotionRequestsGroup']);

export const selectPromotionProcessAllowed = (state) =>
  get(state, [reducerDomain, 'promotionProcessAllowed']);

export const selectPromotionRequestHistorical = createSelector(
  selectUserPromotionRequestsGroup,
  (promotionRequestsGroup) => {
    let assignerObj;
    let managerObj;

    promotionRequestsGroup.forEach(item => {
      if (item.requesterType === 'ASSIGNER') {
        assignerObj = item;
      } else if (item.requesterType === 'MANAGER') {
        managerObj = item;
      }
    });

    if (assignerObj && managerObj) {
      managerObj.assignerRequester = assignerObj.requester;
      promotionRequestsGroup = promotionRequestsGroup.filter(item => item.requesterType !== 'ASSIGNER');
    }

    return promotionRequestsGroup;
  },
);
