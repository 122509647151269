import { takeEvery, call, put } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import {
  getProfile,
} from '../api';
import {
  GET_PROFILE,
} from './actionTypes';
import {
  getProfileSuccess,
  getProfileFail,
} from './actions';

function* fetchProfileWorker({ payload: { identification } }) {
  try {
    const response = yield call(getProfile, identification);
    response.user = response.sender;
    delete response.sender;
    yield put(getProfileSuccess(response));
  } catch (error) {
    yield put(getProfileFail(error));
    yield put(addToastMessage({ type: 'error', message: error.message}));
  }
}

export default function* fetchProfileWatcher() {
  yield takeEvery(GET_PROFILE, fetchProfileWorker);
}
