import { useState } from 'react';
import { getIsClickedCTA, updateIsClickedCTA } from './helpers';

const useCTA = (key) => {
  const [isClicked, setIsClicked] = useState(getIsClickedCTA(key));

  const onClick = () => {
    if (key && !isClicked) {
      const status = updateIsClickedCTA(key);
      setIsClicked(status);
    }
  };

  return {
    isClicked,
    onClick,
  };
};

export default useCTA;
