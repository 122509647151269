import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'Betterme.TalentMatrix.Evaluation.Title',
    defaultMessage: 'Talent Matrix',
  },
  viewMore: {
    id: 'Betterme.TalentMatrix.Evaluation.ViewMore',
    defaultMessage: 'View in Talent Matrix',
  },
});
