// @packages
import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Image from 'smu-ui-components/Image';

// @own
import './styles.scss';

const RatingsFilters = ({
  className,
  config: { ratings: filters, availableFilters },
  onChange,
  values,
}) => {
  return (
    <ul className={cn('rating-filters', className)}>
      {filters.map(filter =>
        <li
          className={cn('rating-filters__item', {
            'rating-filters__item--disabled': !availableFilters.includes(filter.id),
          })}
          key={filter.id}
          onClick={() => onChange(filter.id)}
          title={filter.name}
        >
          <Image
            className="rating-filters__item-image"
            width={32}
            height={32}
            code={filter.imageCode}
            style={{ backgroundColor: values.includes(filter.id) ? filter.color : undefined }}
          />
        </li>
      )}
    </ul>
  );
};

RatingsFilters.defaultProps = {
  onChange: () => undefined,
};

RatingsFilters.propTypes = {
  className: PropTypes.string,
  config: PropTypes.shape({
    availableFilters: PropTypes.array.isRequired,
    ratings: PropTypes.arrayOf(PropTypes.shape({
      color: PropTypes.string.isRequired,
      imageCode: PropTypes.string,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })).isRequired,
  }),
  onChange: PropTypes.func,
  values: PropTypes.array.isRequired,
};

export default RatingsFilters;
