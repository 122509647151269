// @packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import Tabs from 'smu-ui-components/Tabs';
import Segment from 'smu-ui-components/Segment';
import scrollToComponent from 'react-scroll-to-component';

// @app
import HeadingSection from 'components/templates/HeadingSection';
import LeadershipRequestReceived from 'components/containers/LeadershipRequestReceived';
import LeadershipRequestSent from 'components/containers/LeadershipRequestSent';
import ReasonsDismissModal from 'components/containers/ReasonsDismissModal';
import AcceptAddToMyTeamModal from 'components/containers/AcceptAddToMyTeamModal';
import LeadershipRequestAcceptModal from 'components/containers/LeadershipRequestAcceptModal';
import EmptyStateImage from 'betterme-components/assets/empty-states/balloon2-empty-states.svg';
import LeadershipRequestCancelModal from 'components/containers/LeadershipRequestCancelModal';
import {
  getLeadershipRequestLocation,
  getMyProfileLocation,
  getMyTeamDashboardLocation,
} from 'betterme-components/routes/urls';
import { selectUser, selectConfigurations } from 'betterme-components/Authorization/selectors';
import { selectLoadingPutChangeLeader } from 'betterme-components/services/ChangeLeaderRequestAcceptDecline/selectors';
import { actionsMessages, commonMessages } from 'common/messages';

// @own
import messages from './messages';
import {
  EMPTY_STATE_LEADERS_IMAGE,
  ONE,
  RECEIVED,
  SENT,
  ZERO,
} from './constants';

const LeadershipRequestsSection = ({
  changeTabReceived,
  changeTabSent,
  intl: { formatMessage },
  loadingChangeLeader,
  location: { query },
  navigateToMyProfile,
  navigateToTeamDashboard,
  user,
}) => {

  const [tabState, setTabState] = useState(ZERO);
  const { access: { newWordingForLeaders } } = user;

  useEffect(() => {
    scrollToComponent(document.body, 'top', 0);
  });

  useEffect(() => {
    if (query)
      setTabState(getRequestsType(query?.tab));
  }, [query]);

  function getRequestsType(tabState) {
    switch (tabState) {
      case RECEIVED:
        return ZERO;
      case SENT:
        return ONE;
    }
  };

  const tabs = [
    {
      name: formatMessage(messages.LeadershipRequestReceived),
      onClick: () => changeTabReceived(),
    },
    {
      name: formatMessage(messages.LeadershipRequestSent),
      onClick: () => changeTabSent(),
    }
  ];
  const { hasCollaborators } = user;
  const sentType = tabState === ONE;
  const dynamicLabel = hasCollaborators
    ? formatMessage(messages.LeadershipRequestMyTeamDashboard)
    : formatMessage(messages.LeadershipRequestMyProfile);
  const navigateBack = hasCollaborators
    ? navigateToTeamDashboard
    : navigateToMyProfile;
  const navigateTo = hasCollaborators
    ? getMyTeamDashboardLocation()
    : getMyProfileLocation();
  const navigateToText = hasCollaborators
    ? formatMessage(messages.LeadershipRequestGoToMyTeamDashboard)
    : formatMessage(messages.LeadershipRequestGoToMyProfile);

  const leadersTypeTitles = {
    currentLeader: formatMessage(messages.LeadershipRequestCurrentLeader),
    newAccountLeader: formatMessage(messages.LeadershipRequestNewAccount),
    newCareerLeader: formatMessage(messages.LeadershipRequestNewCareer),
    newMainLeader: formatMessage(messages.LeadershipRequestNewMain),
    newSecondaryLeader: formatMessage(messages.LeadershipRequestNewSecondary),
  };
  const emptyState = {
    emptyStateImage: EmptyStateImage,
    emptyStateText: tabState === ZERO
      ? formatMessage(messages.LeadershipRequestReceivedTabDescription)
      : formatMessage(messages.LeadershipRequestSentTabDescription),
    emptyStateTitle: tabState === ZERO
      ? formatMessage(messages.LeadershipRequestReceivedTabTitle)
      : formatMessage(messages.LeadershipRequestSentTabTitle),
    navigateTo: navigateTo,
    navigateToText: navigateToText,
  };
  const requestTypeTitlesSent = {
    leaderSubstitution: formatMessage(messages.LeadershipRequestLeaderSubstitution),
    newLeader: formatMessage(messages.LeadershipRequestNewLeader),
  };
  const requestTypeTitlesReceived = {
    leaderSubstitution: formatMessage(messages.LeadershipRequestLeaderSubstitution),
    accountLeader: formatMessage(messages.LeadershipRequestAccountRequestTitle),
    careerLeader: formatMessage(messages.LeadershipRequestCareerRequestTitle),
    mainLeader: formatMessage(messages.LeadershipRequestMainRequestTitle),
    secondaryLeader: formatMessage(messages.LeadershipRequestSecondaryRequestTitle),
  };
  const requestTypeMessages = {
    accountLeaderMsg: formatMessage(messages.LeadershipRequestOtherLeaderRequest),
    careerLeaderMsg: formatMessage(messages.LeadershipRequestCareerRequest),
    mainLeaderMsg: formatMessage(messages.LeadershipRequestMainRequest),
    secondaryLeaderMsg: formatMessage(messages.LeadershipRequestSecondaryRequest),
  };
  const leaderEmptyState = {
    leaderEmptyStateText: formatMessage(messages.LeadershipRequestEmptyState),
    leaderEmptyStateImage: EMPTY_STATE_LEADERS_IMAGE,
  };

  return (
    <Segment loading={loadingChangeLeader} loadingType="betterme">
      <HeadingSection
        headerValues={[
          dynamicLabel,
          formatMessage(messages.LeadershipRequestSectionTitle),
        ]}
        navigateBack={navigateBack}
      >
        <Tabs
          className="leadership-request-section__tabs"
          active={tabState}
          tabs={tabs}
        />
        {sentType ? (
          <LeadershipRequestSent
            buttonsText={{ cancelText: formatMessage(actionsMessages.ActionsCancel) }}
            commentTitle={formatMessage(commonMessages.Comment)}
            emptyState={emptyState}
            footer={{
              me: formatMessage(messages.LeadershipRequestMe),
              sentBy: formatMessage(commonMessages.SendBy),
            }}
            leaderEmptyState={leaderEmptyState}
            leadersTitle={leadersTypeTitles}
            newWordingForLeaders={newWordingForLeaders}
            requestTitle={requestTypeTitlesSent}
            user={user}
          />
        ) : (
          <LeadershipRequestReceived
            buttonsText={{
              acceptText: formatMessage(actionsMessages.ActionsAccept),
              cancelText: formatMessage(actionsMessages.ActionsDecline),
              gotItText: formatMessage(actionsMessages.ActionsGotIt),
            }}
            commentTitle={formatMessage(commonMessages.Comment)}
            disclaimer={formatMessage(messages.LeadershipRequestAutomaticallyAccepted)}
            emptyState={emptyState}
            footer={{
              sentBy: formatMessage(commonMessages.SendBy),
            }}
            leaderEmptyState={leaderEmptyState}
            leadersTitle={leadersTypeTitles}
            messages={requestTypeMessages}
            newWordingForLeaders={newWordingForLeaders}
            requestTitle={requestTypeTitlesReceived}
            user={user}
          />
        )}
        <AcceptAddToMyTeamModal />
        <LeadershipRequestAcceptModal />
        <LeadershipRequestCancelModal />
        <ReasonsDismissModal />
      </HeadingSection>
    </Segment>
  );
};

LeadershipRequestsSection.propTypes = {
  injectIntl: PropTypes.object.isRequired,
  loadingChangeLeader: PropTypes.func,
  navigateBackToMyProfile: PropTypes.func.isRequired,
  navigateBackToTeamDashboard: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const navigateToMyProfile = () => push(getMyProfileLocation());
const navigateToTeamDashboard = () => push(getMyTeamDashboardLocation());
const changeTabReceived = () => push(getLeadershipRequestLocation(RECEIVED));
const changeTabSent = () => push(getLeadershipRequestLocation(SENT));

const mapSateToProps = state => ({
  user: selectUser(state),
  loadingChangeLeader: selectLoadingPutChangeLeader(state),
});

const mapDispatchToProps = {
  changeTabReceived,
  changeTabSent,
  navigateToMyProfile,
  navigateToTeamDashboard,
};

export default connect(mapSateToProps, mapDispatchToProps)(injectIntl(LeadershipRequestsSection));
