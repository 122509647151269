// @packages
import React from 'react';
import PropTypes from 'prop-types';
import Evaluations from '../../Evaluations';

// @own
import AccessDeniedEmptyState from '../AccessDeniedEmptyState';

const ProfileEvaluations = ({ params, user }) => {
  const { access: { evaluations } } = user;
  const { userid } = params;

  return evaluations ? (
    <Evaluations
      params={params}
      user={user}
    />
  ) : (
    <AccessDeniedEmptyState
      user={user}
      userId={userid}
    />
  );
};

ProfileEvaluations.propTypes = {
  user: PropTypes.object,
};

export default ProfileEvaluations;
