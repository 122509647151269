// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

// @own
import './styles.scss';
import messages from '../messages';

const CompetenciesHeader = ({
  intl: { formatMessage },
  totalRate,
  responses,
  title,
}) => (
  <section className="competencies-header">
    <h1 className="competencies-header__title">
      {title}
    </h1>
    <div className="competencies-header__total">
      <span className="competencies-header__total-responses">
      {formatMessage(messages.FeedbackForLeadersReceivedTotalResponses)}:
        <strong className="competencies-header__total-black">
          {responses}
        </strong>
      </span>
      <span className="competencies-header__total-rates">
      {formatMessage(messages.FeedbackForLeadersReceivedTotalRate)}:
        <strong className="competencies-header__total-black">
          {totalRate}
        </strong>
      </span>
    </div>
  </section>
);

CompetenciesHeader.propTypes = {
  intl: PropTypes.object.isRequired,
  totalRate: PropTypes.string.isRequired,
  responses: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default injectIntl(CompetenciesHeader);
