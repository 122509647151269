// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import InputArea from 'smu-ui-components/InputArea';

// @app
import Reasons from 'components/molecules/Reasons';
import Title from 'components/atoms/Title';
import {
  DESCRIPTION_FIELD_MAX_LENGTH,
  DESCRIPTION_FIELD_ROWS,
  SIZE_TWELVE,
} from 'components/common/constants';

// @own
import './styles.scss';

const ChooseReason = ({
  className,
  commentPlaceholder,
  commentValue,
  disabledTextBox,
  onCommentChange,
  onReasonChange,
  reasons,
  selectedReason,
  title,
}) => (
  <div className={cn('choose-reason', className)}>
    <Reasons
      className="choose-reason__reasons"
      onChange={onReasonChange}
      reasons={reasons}
      selectedReason={selectedReason}
    />
    {disabledTextBox && (
      <>
        <Title
          black
          className="choose-reason__title"
          size={SIZE_TWELVE}
        >
          {title}
        </Title>
        <InputArea
          className="choose-reason__comment"
          maxLength={DESCRIPTION_FIELD_MAX_LENGTH}
          onChange={onCommentChange}
          placeholder={commentPlaceholder}
          rows={DESCRIPTION_FIELD_ROWS}
          value={commentValue}
        />
      </>
    )}
  </div>
);

ChooseReason.propTypes = {
  className: PropTypes.string,
  commentPlaceholder: PropTypes.string,
  commentValue: PropTypes.object,
  disabledTextBox: PropTypes.bool,
  onCommentChange: PropTypes.func.isRequired,
  onReasonChange: PropTypes.func.isRequired,
  reasons: PropTypes.array.isRequired,
  selectedReason: PropTypes.number,
  title: PropTypes.string,
};

ChooseReason.defaultProps = {
  disabledTextBox: false,
  reasons: [],
};

export default ChooseReason;
