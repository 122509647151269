// @packages
import React from 'react';
import PropTypes from 'prop-types';
import RootModal from 'smu-app-components/RootModal';
import { injectIntl } from 'react-intl';

// @app
import GenericModal from 'components/templates/GenericModalTemplate';
import InfoCard from 'components/molecules/InfoCard';
import { ACCEPT_DECLINE_PROMOTION_MODAL } from 'betterme-components/constants/modalTypes';
import {
  actionsMessages,
} from 'common/messages';

// @own
import messages from './messages';

const AcceptDeclinePromotion = ({
  acceptPromotion,
  actionType,
  close: closeModal,
  declinePromotion,
  intl: { formatMessage },
}) => {

  function handleDecline() {
    declinePromotion();
    closeModal();
  };

  function handleAccept() {
    acceptPromotion();
    closeModal();
  };

  const type = actionType?.toLowerCase();
  const actions = {
    accept: {
      actionYes: formatMessage(actionsMessages.ActionsYesAccept),
      actionTitle: formatMessage(messages.AcceptPromotionTitle),
      actionContent: formatMessage(messages.AcceptPromotionMessage),
      clickHandler: () => handleAccept(),
    },
    create: {
      actionYes: formatMessage(actionsMessages.ActionsYesCreate),
      actionTitle: formatMessage(messages.CreatePromotionTitle),
      actionContent: formatMessage(messages.CreatePromotionMessage),
      clickHandler: () => handleAccept(),
    },
    decline: {
      actionYes: formatMessage(actionsMessages.ActionsYesDecline),
      actionTitle: formatMessage(messages.DeclinePromotionTitle),
      actionContent: formatMessage(messages.DeclinePromotionMessage),
      clickHandler: () => handleDecline(),
    },
  };

  const actionButton = [
    {
      label: formatMessage(actionsMessages.ActionsCancel),
      onClick: closeModal,
      variant: 'outline',
    },
    {
      label: actions[type].actionYes,
      onClick: actions[type].clickHandler,
    },
  ];

  const titleMessage = actions[type].actionTitle;

  const contentMessage = actions[type].actionContent;

  return (
    <GenericModal
      actionButtons={actionButton}
      header={(
        <InfoCard
          icon="info"
          message={contentMessage}
          title={titleMessage}
        />
      )}
      onClose={closeModal}
      success
    />
  );
};

AcceptDeclinePromotion.propTypes = {
  close: PropTypes.func.isRequired,
  collaboratorName: PropTypes.string.isRequired,
  goToNotifications: PropTypes.func,
  intl: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

const modalStyles = { maxWidth: '600px' };

const withRootModalComponent = RootModal(
  injectIntl(AcceptDeclinePromotion),
  ACCEPT_DECLINE_PROMOTION_MODAL,
  modalStyles,
  'modal__wrapper',
);

export default withRootModalComponent;
