import { trackEvent } from 'smu-utils/gtm';


const eventCategory = 'Potential_Assessment';
const eventAction = 'Results';

export const trackLearnMore = (type) => {
  trackEvent({
    category: eventCategory,
    action: eventAction,
    label: 'Learn_More',
    type: type ? type.toLowerCase() : undefined,
  });
};

export const trackSeeDescription = (type) => {
  trackEvent({
    category: eventCategory,
    action: eventAction,
    label: 'See_Description',
    type: type ? type.toLowerCase() : undefined,
  });
};
