import { defineMessages } from 'react-intl';

export default defineMessages({
  BehaviourSectionTitle: {
    id: 'Betterme.BehaviourSection.Title',
    defaultMessage: 'Behavioral Assessment',
  },
  BehaviourSectionDescription: {
    id: 'Betterme.BehaviourSection.Description',
    defaultMessage: 'Analyze each behaviour in order to assess the performance of the team member.',
  },
  BehaviourSectionSelect: {
    id: 'Betterme.BehaviourSection.Select',
    defaultMessage: 'Select frequency',
  },
  BehaviourSectionBehaviour: {
    id: 'Betterme.BehaviourSection.Behaviour',
    defaultMessage: 'Behavior',
  },
  BehaviourSectionResponse: {
    id: 'Betterme.BehaviourSection.Response',
    defaultMessage: 'Response',
  },
  PerformanceRetrospectiveNew: {
    id: 'Betterme.Evaluations.CoCreatorNew',
    defaultMessage: 'New',
  },
  BehavioralAssessmentSlideOneTitle: {
    id: 'Betterme.PerformanceRetrospective.BehavioralAssessmentSlideOneTitle',
    defaultMessage: 'Check it out!',
  },
  BehavioralAssessmentSlideTwoTitle: {
    id: 'Betterme.PerformanceRetrospective.BehavioralAssessmentSlideTwoTitle',
    defaultMessage: 'Performance Behaviors',
  },
  BehavioralAssessmentSlideOneText: {
    id: 'Betterme.PerformanceRetrospective.BehavioralAssessmentSlideOneText',
    defaultMessage: 'These key behaviors, aligned with our Talent Manifesto competencies, reflect what is expected for achieving a high level of performance.',
  },
  BehavioralAssessmentSlideTwoText: {
    id: 'Betterme.PerformanceRetrospective.BehavioralAssessmentSlideTwoText',
    defaultMessage: 'The assessment will show you a set of behaviours depending on the Glober’s seniority. Enter <a style={style} href={link} target="_blank">here</a> for more details.',
  },
  BehavioralAssessmentSuggestedBy: {
    id: 'Betterme.Evaluations.CoEvaluatorScore',
    defaultMessage: 'Suggested by {username}',
  },
  BehaviourSectionSelfRetrospectiveDescription: {
    id: 'Betterme.BehaviourSection.SelfRetrospective.Description',
    defaultMessage: 'Please complete the frequency level you\'ve observed for the behaviors, taking into account consistency and development in each of them during the analyzed period.',
  },
  BehavioralAssessmentSelfRetrospectiveSlideOneText: {
    id: 'Betterme.BehaviourSection.SelfRetrospective.SlideOneText',
    defaultMessage: 'These behaviors, aligned with our Talent Manifesto competencies, establish general expectations for an effective performance at Globant.',
  },
  BehavioralAssessmentSelfRetrospectiveSlideTwoText: {
    id: 'Betterme.BehaviourSection.SelfRetrospective.SlideTwoText',
    defaultMessage: 'The behaviors are tailored to different tiers of seniority, taking into account the complexity that can be effectively managed within each tier. Enter <a style={style} href={link} target="_blank">here</a> for more details.',
  },
});
