import {
  FILE_GOAL_CLEAR,
  FILE_GOAL_DELETE_FAIL,
  FILE_GOAL_DELETE_SUCCESS,
  FILE_GOAL_DELETE,
  FILE_GOAL_UPLOAD_FAIL,
  FILE_GOAL_UPLOAD_SUCCESS,
  FILE_GOAL_UPLOAD,
} from './actionTypes';

const initialState = {
  error: undefined,
  files: [],
  loading: false,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case FILE_GOAL_DELETE:
    case FILE_GOAL_UPLOAD:
      return {
        ...state,
        loading: true,
      };

    case FILE_GOAL_DELETE_FAIL:
    case FILE_GOAL_UPLOAD_FAIL:
      return {
        ...state,
        error: payload.error,
        loading: false,
      };

    case FILE_GOAL_DELETE_SUCCESS:
      return {
      ...state,
      files: state.files.filter(f => f.id !== payload.id),
      loading: false,
    };

    case FILE_GOAL_UPLOAD_SUCCESS:
      return {
      ...state,
      files: [...state.files, payload.file],
      loading: false,
    };

    case FILE_GOAL_CLEAR:
      return initialState;

    default:
      return state;
  }
}
