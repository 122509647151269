// @packages
import React, { useEffect } from 'react';
import RootModal from 'smu-app-components/RootModal';
import { closeModal, openModal } from 'smu-app-components/RootModal/actions';
import { connect } from 'react-redux';
import { trackEvent } from 'smu-utils/gtm';
import { withRouter } from 'react-router';

// @app
import Onboarding from 'betterme-components/Onboarding';
import { ONBOARDING_ENABLED } from 'betterme-components/services/CommunityConfigs/constants';
import { centerModalClass } from 'betterme-components/ConfirmationModal';
import { getCommunityConfigValueBool } from 'betterme-components/services/CommunityConfigs/selectors';

// @own
import './styles.scss';

const OnboardingModal = RootModal(
  Onboarding,
  'ONBOARDING_MODAL',
  null,
  centerModalClass
);

const OnboardingModalContainer = ({
  closeModal,
  location,
  onboardingEnabled,
  openModal,
  router,
}) => {
  const isOnboardingModal = location?.query?.modal === 'onboarding';
  const showOnlyPerformance = location?.query?.performance === 'true';

  const handleOpenOnboarding = () =>
    openModal({ modalType: 'ONBOARDING_MODAL' });

  useEffect(() => {
    if (onboardingEnabled && isOnboardingModal) {
      handleOpenOnboarding();
    }
  }, [onboardingEnabled, isOnboardingModal]);

  return (
    <OnboardingModal
      onSendStar={() => {
        trackEvent({
          action: 'click',
          category: 'onboarding',
          label: 'start_explore',
        });
        router.push(location.pathname);
        closeModal();
      }}
      onCompleteProfile={() => {
        trackEvent({
          action: 'click',
          category: 'onboarding',
          label: 'complete_profile',
        });
        router.push('/myprofile');
        closeModal();
      }}
      onClose={(step) => {
        if (step) {
          trackEvent({
            action: 'close',
            category: 'onboarding',
            label: `${step}`,
          });
        }
        router.push(location.pathname);
        closeModal();
      }}
      showOnlyPerformance={showOnlyPerformance}
    />
  );
};
const mapDispatchToProps = {
  closeModal,
  openModal,
};

const mapStateToProps = (state) => ({
  onboardingEnabled: getCommunityConfigValueBool(state, ONBOARDING_ENABLED),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OnboardingModalContainer)
);
