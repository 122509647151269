// @packages
import React from 'react';
import PropTypes from 'prop-types';
import Grid from 'smu-layout-components/Grid';
import Container from 'smu-layout-components/Container';

// @own
import './styles.scss';

const Main = ({
  Main,
  mainProps,
  RightColumn,
  Sidenav,
  session,
  sidenavVisible,
  toggleSidenav,
}) => {
  const colSizeMain = !!Sidenav && !RightColumn ? 9 : 6;

  return (
    <main className="main">
      <Container centered>
        <Grid className="main__grid">
          <Grid.Col extraClass="main__grid-sidenav" desktop={3} tablet={4}>
            {Sidenav && React.cloneElement(Sidenav, {
              session,
              sidenavVisible,
              toggleSidenav,
            })}
          </Grid.Col>

          <Grid.Col extraClass="main__grid-main" desktop={colSizeMain} tablet={8} mobile={12}>
            {Main && React.cloneElement(Main, { session: session, ...mainProps })}
          </Grid.Col>

          <Grid.Col extraClass="main__grid-rightnav" desktop={3}>
            {RightColumn && React.cloneElement(RightColumn, { session: session })}
          </Grid.Col>
        </Grid>
      </Container>
    </main>
  );
};

Main.propTypes = {
  Main: PropTypes.object,
  MainAlt: PropTypes.object,
  RightColumn: PropTypes.object,
  Sidenav: PropTypes.object,
  session: PropTypes.object,
  sidenavVisible: PropTypes.bool,
};

export default Main;
