// @packages
import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'smu-ui-components/Icon';
import TextWrapper from 'smu-ui-components/TextWrapper';

// @app
import DescriptionTooltip from 'common/DescriptionTooltip';

// @own
import './styles.scss';

const SectionListItem = ({
  description,
  displayValue,
  isSkill,
  onClick,
  selected,
  active,
}) => {
  return (
    <li
      className={cn('section-list-item__item', {
        'section-list-item__item--selected': selected && active,
        'section-list-item__item--selected-skill': selected && active && isSkill,
      })}
      onClick={onClick}
    >
      <TextWrapper
        as="div"
        className={cn('section-list-item__item-text', {
          'section-list-item__item-text-skill': selected && active && isSkill,
        })}
      >
        <DescriptionTooltip
          description={description || displayValue}
        >
          {displayValue}
        </DescriptionTooltip>
      </TextWrapper>
      <Icon
        className={cn('section-list-item__item-icon', {
          'section-list-item__item-icon-skill': selected && active && isSkill,
        })}
        icon="angle-right"
        size="medium"
      />
    </li>
  );
};

SectionListItem.propTypes = {
  description: PropTypes.string,
  displayValue: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};

export default SectionListItem;
