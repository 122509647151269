// @packages
import parse from 'url-parse';

export function buildClosePath(removeQuery = []) {
  const parsed = parse(window.location.href, true);
  delete parsed.query.modal;

  removeQuery.forEach((element) => {
    delete parsed.query[element];
  });

  let str = parsed.toString();
  let path = str.replace(parsed.origin, '');

  return path;
}
