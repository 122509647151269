// @packages
import cn from 'classnames';
import { orderBy } from 'lodash';
import React from 'react';
import LottieSpinner from 'smu-ui-components/LottieSpinner';
import StarmeUpWidgetLogo from 'smu-app-components/StarmeUpWidgetLogo';

// @own
import StarmeUpValuesList from './StarmeUpValuesList';
import './styles.scss';

const StarmeUpValues = ({
  emptyMesageNoStars,
  emptyMessagePeriod,
  fromText,
  kudoStats,
  loadingStarmeUpValues,
  loadingValueKudos,
  starsText,
  summaryStarmeUpValues,
  title,
  totalStars,
  valueKudos,
}) => {
  const loading = loadingStarmeUpValues || loadingValueKudos;
  const summary = [...summaryStarmeUpValues];

  if (valueKudos && kudoStats) {
    const kudosSummary = {
      backgroundColor: valueKudos.backgroundColor,
      id: valueKudos.id,
      imageCode: valueKudos.imageCode,
      name: valueKudos.nameShowed,
      percentageStars: kudoStats.percentageKudos,
      sender: kudoStats.userName,
      totalStars: kudoStats.totalKudos,
    };

    summary.push(kudosSummary);
  }

  const orderedSummary = orderBy(
    summary.filter(value => value.totalStars > 0),
    'percentageStars',
    'desc'
  ).slice(0, 3);

  return (
    <div className="starmeup-values">
      <div className="starmeup-values__title-wrapper">
        <span className="starmeup-values__title">
          {`${title} |`}
        </span>
        <span className={cn('starmeup-values__stars-text', {
          'starmeup-values__stars-text--loading': loading,
        })}>
          {`${totalStars.current} ${starsText}`}
        </span>
      </div>
      {!loading ? (
        <StarmeUpValuesList
          emptyMessage={totalStars.historic > 0
            ? emptyMessagePeriod
            : emptyMesageNoStars
          }
          orderedSummary={orderedSummary}
          totalStars={totalStars.current}
        />
      ) : (
        <LottieSpinner
          className="starmeup-values__spinner"
          loadingType="betterme"
          width={60}
          height={60}
        />
      )}
      <StarmeUpWidgetLogo
        className="starmeup-values__smu-logo"
        fromText={fromText}
      />
    </div>
  );
};

StarmeUpValues.defaultProps = {
  title: 'DNA',
  starsText: 'stars',
  summaryStarmeUpValues: [],
  totalStars: 0,
};

export default StarmeUpValues;
