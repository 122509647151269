// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @app
import Title from 'components/atoms/Title';

// @own
import './styles.scss';

const EmptyStateBox = ({
  className,
  text,
}) => (
  <div className={cn('empty-state-box', className)}>
    <Title 
      className="empty-state-box__text"
      level={3}
      nomargin
      size={14}
    >
      {text}
    </Title>
  </div>
);

EmptyStateBox.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
};

export default EmptyStateBox;
