// @packages
import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'smu-ui-components/Checkbox';

// @app
import DescriptionTooltip from 'common/DescriptionTooltip';

// @own
import './styles.scss';

const SuggestedAction = ({
  className,
  description,
  disabled,
  name,
  onChange,
  selected,
}) => {
  return (
    <label className={cn('suggested-action', className)}>
      <Checkbox
        checked={selected}
        className="suggested-action__checkbox"
        disabled={disabled}
        name={name}
        onChange={onChange}
      />
      {description ? (
        <DescriptionTooltip description={description}>
          <span
            className={cn('suggested-action__name', {
              'suggested-action__name--disabled': disabled,
            })}
          >
            {name}
          </span>
        </DescriptionTooltip>
      ) : (
        <span
          className={cn('suggested-action__name', {
            'suggested-action__name--disabled': disabled,
          })}
        >
          {name}
        </span>
      )}
    </label>
  );
};

SuggestedAction.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  selected: PropTypes.bool,
};

export default SuggestedAction;
