import { defineMessages } from 'react-intl';

export default defineMessages({
  TeamDashboardPeriodTitle: {
    id: 'Betterme.TeamDashboard.PeriodTitle',
    defaultMessage: 'My team\'s dashboard',
  },
  TeamDashboardOverallTitle: {
    id: 'Betterme.TeamDashboard.OverallTitle',
    defaultMessage: 'Overall',
  },
  TeamDashboardTeamMembersTitle: {
    id: 'Betterme.TeamDashboard.TeamMembersTitle',
    defaultMessage: 'Team members',
  },
  TeamDashboardTeamMembersLeadershipRequest: {
    id: 'Betterme.TeamDashboard.TeamMembersLeadershipRequest',
    defaultMessage: 'Leadership Requests',
  },
  TeamDashboardTeamMembersAddMember: {
    id: 'Betterme.TeamDashboard.TeamMembersAddMember',
    defaultMessage: 'Add member',
  },
  TeamDashboardTeamFeedbackReceivedSubtitle: {
    id: 'Betterme.TeamDashboard.TeamFeedbackReceivedSubtitle',
    defaultMessage: 'The least amount of feedback received so far',
  },
  TeamDashboardBoxEmptyInfo: {
    id: 'Betterme.TeamDashboard.BoxEmptyInfo',
    defaultMessage: 'No ratings',
  },
  TeamDashboardBoxEmptyDescription: {
    id: 'Betterme.TeamDashboard.BoxEmptyDescription',
    defaultMessage: 'Take advantage of being here and rate your collaborator\'s competencies.',
  },
  TeamDashboardCompetenciesHighlightTitle: {
    id: 'Betterme.TeamDashboard.CompetenciesHighlightTitle',
    defaultMessage: 'Competencies highlights',
  },
  TeamDashboardFeedbackReceivedPieEmptyText: {
    id: 'Betterme.TeamDashboard.FeedbackReceivedPieEmptyText',
    defaultMessage: 'Your collaborator hasn\'t received any feedback for this period yet',
  },
  TeamDashboardTeamMembersArea: {
    id: 'Betterme.TeamDashboard.TeamMembersArea',
    defaultMessage: 'Area:',
  },
  TeamDashboardTeamMembersJobPosition: {
    id: 'Betterme.TeamDashboard.TeamMembersJobPosition',
    defaultMessage: 'Position:',
  },
  TeamDashboardTeamMembersAccount: {
    id: 'Betterme.TeamDashboard.TeamMembersAccount',
    defaultMessage: 'Account:',
  },
  TeamDashboardTeamMembersProject: {
    id: 'Betterme.TeamDashboard.TeamMembersProject',
    defaultMessage: 'Project:',
  },
  TeamDashboardTeamMembersOffice: {
    id: 'Betterme.TeamDashboard.TeamMembersOffice',
    defaultMessage: 'Office:',
  },
  TeamDashboardStarmeUpValuesTitleStars: {
    id: 'Betterme.TeamDashboard.StarmeUpValues.TitleStars',
    defaultMessage: 'Stars',
  },
  TeamDashboardStarmeUpValuesEmptyMessagePeriod: {
    id: 'Betterme.TeamDashboard.StarmeUpValues.EmptyMessagePeriod',
    defaultMessage: 'No stars have been received in this period.',
  },
  TeamDashboardStarmeUpValuesEmptyMessageNoStars: {
    id: 'Betterme.TeamDashboard.StarmeUpValues.EmptyMessageNoStars',
    defaultMessage: 'The first star is on its way, we can feel it!',
  },
  TeamDashboardEngagementFeatureDiscovery: {
    id: 'Betterme.TeamDashboard.Engagement.FeatureDiscovery',
    defaultMessage: 'Here you\'ll find the connectivity Indicator to know what actions are needed for your collaborator',
  },
  TeamDashboardTableEmptyStateTitle: {
    id: 'Betterme.TeamDashboard.Table.EmptyStateTitle',
    defaultMessage: 'Oops! It seems that your search did not return results',
  },
  TeamDashboardTableEmptyStateDescription: {
    id: 'Betterme.TeamDashboard.Table.EmptyStateDescription',
    defaultMessage: 'Please try using a different filter',
  },
});
