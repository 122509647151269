export const CREATE_FEEDBACK_REQUEST_CANCEL = 'CREATE_FEEDBACK_REQUEST_CANCEL';
export const CREATE_FEEDBACK_REQUEST_CANCEL_FAIL = 'CREATE_FEEDBACK_REQUEST_CANCEL_FAIL';
export const CREATE_FEEDBACK_REQUEST_CANCEL_SUCCESS = 'CREATE_FEEDBACK_REQUEST_CANCEL_SUCCESS';
export const CREATE_FEEDBACK_REQUEST_DISMISS = 'CREATE_FEEDBACK_REQUEST_DISMISS';
export const CREATE_FEEDBACK_REQUEST_DISMISS_FAIL = 'CREATE_FEEDBACK_REQUEST_DISMISS_FAIL';
export const CREATE_FEEDBACK_REQUEST_DISMISS_SUCCESS = 'CREATE_FEEDBACK_REQUEST_DISMISS_SUCCESS';
export const GET_FEEDBACKS_REQUEST = 'GET_FEEDBACKS_REQUEST';
export const GET_FEEDBACKS_REQUESTS_RECEIVED = 'GET_FEEDBACKS_REQUESTS_RECEIVED';
export const GET_FEEDBACKS_REQUESTS_RECEIVED_FAIL = 'GET_FEEDBACKS_REQUESTS_RECEIVED_FAIL';
export const GET_FEEDBACKS_REQUESTS_RECEIVED_SUCCESS = 'GET_FEEDBACKS_REQUESTS_RECEIVED_SUCCESS';
export const GET_FEEDBACKS_REQUESTS_SENT = 'GET_FEEDBACKS_REQUESTS_SENT';
export const GET_FEEDBACKS_REQUESTS_SENT_FAIL = 'GET_FEEDBACKS_REQUESTS_SENT_FAIL';
export const GET_FEEDBACKS_REQUESTS_SENT_SUCCESS = 'GET_FEEDBACKS_REQUESTS_SENT_SUCCESS';
export const GET_FEEDBACKS_REQUEST_FAIL = 'GET_FEEDBACKS_REQUEST_FAIL';
export const GET_FEEDBACKS_REQUEST_SUCCESS = 'GET_FEEDBACKS_REQUEST_SUCCESS';
export const GET_REQUEST_DISMISS_REASONS = 'GET_REQUEST_DISMISS_REASONS';
export const GET_REQUEST_DISMISS_REASONS_FAIL = 'GET_REQUEST_DISMISS_REASONS_FAIL';
export const GET_REQUEST_DISMISS_REASONS_SUCCESS = 'GET_REQUEST_DISMISS_REASONS_SUCCESS';
export const RESET_FEEDBACK_REQUEST_STATE = 'RESET_FEEDBACK_REQUEST_STATE';
export const SELECT_REASON = 'SELECT_REASON';
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
