import { defineMessages } from 'react-intl';

export default defineMessages({
  FeedbackRightColumnSuggestions: {
    id: 'Betterme.RightColumn.Suggestions',
    defaultMessage: 'Suggestions for you'
  },
  FeedbackGoalsCreateDescription: {
    id: "Betterme.GoalsCreate.Description",
    defaultMessage: "Create new Goals for your team"
  },
  FeedbackGoalsCreateBtnNew: {
    id: "Betterme.GoalsCreate.BtnNew",
    defaultMessage: "Create Goal"
  },
  FeedbackGoalsCreateDescriptionMyGoals: {
    id: "Betterme.GoalsCreate.DescriptionMyGoals",
    defaultMessage: "Create new Goals for yourself"
  },
});
