// @packages
import React from 'react';
import PropTypes from 'prop-types';

// @own
import './styles.scss';

const handleClick = (ev, close, onClick) => {
  close();
  onClick(ev);
};

const ToastNotificationsAction = ({ children, close, onClick }) => {
  return (
    <span
      className="toast-notifications-actions"
      onClick={(ev) => handleClick(ev, close,onClick)}
    >
      {children}
    </span>
  );
};

ToastNotificationsAction.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ToastNotificationsAction;
