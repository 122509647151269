// @own
import {
  GET_PERIOD_BY_TYPE,
  GET_PERIOD_BY_TYPE_FAIL,
  GET_PERIOD_BY_TYPE_SUCCESS,
} from './actionTypes';

export function getPeriodByType(payload) {
  return {
    type: GET_PERIOD_BY_TYPE,
    payload,
  };
}

export function getPeriodByTypeFail(response) {
  return {
    type: GET_PERIOD_BY_TYPE_FAIL,
    payload: response,
  };
}

export function getPeriodByTypeSuccess(error) {
  return {
    type: GET_PERIOD_BY_TYPE_SUCCESS,
    payload: error,
  };
}
