// @packages
import React, { useEffect } from 'react';
import Segment from 'smu-ui-components/Segment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { openModal } from 'smu-app-components/RootModal/actions';
import GenericEmptyState from 'smu-ui-components/GenericEmptyState';
import Link from 'smu-ui-components/Link';

// @app
import ChangeLeaderCard from 'components/organisms/ChangeLeaderCard';
import ReplaceLeaderSent from 'components/organisms/ReplaceLeaderSent';
import InfiniteScrollLoad from 'common/InfiniteScrollLoad';
import * as actions from 'betterme-components/services/LeadershipRequestSent/actions';
import {
  selectGetLeadershipRequestsPage,
  selectGetLeadershipRequestsSent,
  selectLoadingLeadershipRequestsSent,
} from 'betterme-components/services/LeadershipRequestSent/selectors';
import { formatDateV2 } from 'utils/formatDateV2';
import {
  ADD_TO_MY_TEAM_REQUEST_AS_MAIN,
  CHANGE_LEADER_REQUEST_AS_MAIN,
  CHANGE_LEADER_REQUEST_AS_SECONDARY,
  SENT,
} from 'components/pages/LeadershipRequestSection/constants';
import { LEADERSHIP_REQUEST_CANCEL_MODAL } from 'betterme-components/constants/modalTypes';

const LeadershipRequestSent = ({
  buttonsText: { cancelText },
  commentTitle,
  emptyState: {
    emptyStateText,
    emptyStateTitle,
    navigateTo,
    navigateToText,
  },
  footer: { me, sentBy },
  getLeadershipRequestsSent,
  leaderEmptyState: {
    leaderEmptyStateText,
    leaderEmptyStateImage,
  },
  leadersTitle: {
    currentLeader,
    newAccountLeader,
    newCareerLeader,
    newMainLeader,
    newSecondaryLeader,
  },
  loading,
  newWordingForLeaders,
  openModal,
  page: { number, totalPages },
  requests,
  requestTitle: {
    leaderSubstitution,
    newLeader,
  },
}) => {
  const hasLeadershipRequestSent = requests.length > 0;
  const hasMoreLeadershipRequestSent = number + 1 < totalPages;

  useEffect(() => {
    getLeadershipRequestsSent(SENT, { pageNumber: number });
  }, []);

  function handleCancel(requestId) {
    openModal({
      modalType: LEADERSHIP_REQUEST_CANCEL_MODAL,
      modalProps: {
        requestId,
      }
    })
  };

  function handleGetMoreLeadershipRequests() {
    getLeadershipRequestsSent(SENT, { pageNumber: number + 1 });
  }

  return (
    <Segment loading={loading} loadingType="betterme">
      {hasLeadershipRequestSent ? (
        <InfiniteScrollLoad
          dataLength={requests.length}
          hasMore={hasMoreLeadershipRequestSent}
          loadingType="betterme"
          next={handleGetMoreLeadershipRequests}
          scrollableTarget="root"
        >
          {requests.map((request, index) => {
            const {
              isRequesterCurrentLeader,
              requestId,
              type,
              sentDate,
            } = request;
            const isMainLeader = type === CHANGE_LEADER_REQUEST_AS_MAIN;
            const isMainRequest = type === ADD_TO_MY_TEAM_REQUEST_AS_MAIN;
            const isSecondaryLeader = type === CHANGE_LEADER_REQUEST_AS_SECONDARY;
            const isChangeLeaderRequest = isMainLeader || isSecondaryLeader;
            const mainLeaderText = newWordingForLeaders ? newCareerLeader : newMainLeader;
            const secondaryLeaderText = newWordingForLeaders ? newAccountLeader : newSecondaryLeader;
            const newLeaderTitle = (isMainLeader || isMainRequest) ? mainLeaderText : secondaryLeaderText;
            const date = formatDateV2(sentDate, 'monthDay');
            const actionButton = [
              {
                label: cancelText,
                onClick: () => handleCancel(requestId),
              },
            ];

            return (
              <>
                {isChangeLeaderRequest ? (
                  <ChangeLeaderCard
                    actionButtons={actionButton}
                    approverCard={{
                      approverTitle: newLeaderTitle,
                    }}
                    buttonsEnabled={isRequesterCurrentLeader}
                    commentTitle={commentTitle}
                    emptyState={{
                      emptyStateText: leaderEmptyStateText,
                      emptyStateTitle: currentLeader,
                      emptyStateImage: leaderEmptyStateImage,
                    }}
                    footer={{
                      sentBy,
                      sentByName: me,
                    }}
                    key={index}
                    leaderEnabled={isRequesterCurrentLeader}
                    request={request}
                    requesterCard={{
                      requesterHighlight: true,
                      requesterTitle: currentLeader,
                    }}
                    sentDate={date}
                    title={leaderSubstitution}
                  />
                ) : (
                  <ReplaceLeaderSent
                    actionButtons={actionButton}
                    buttonsEnabled={true}
                    footer={{
                      sentBy,
                      userName: me,
                    }}
                    leaderTitle={newLeaderTitle}
                    request={request}
                    sentDate={date}
                    title={newLeader}
                  />
                )}
              </>
            );
          })}
        </InfiniteScrollLoad>
      ) : (
        <div className="bg-white text-center px-5 py-20">
          <GenericEmptyState
            className=""
            description={emptyStateText}
            image="listCheck"
            limitTextWidth={false}
            size="large"
            title={emptyStateTitle}
          />

          <Link
            className="block mt-5 !underline"
            to={navigateTo}
          >
            {navigateToText}
          </Link>
        </div>
      )}
    </Segment>
  );
};

LeadershipRequestSent.defaultProps = {
  requests: [],
};

LeadershipRequestSent.propTypes = {
  buttonsText: PropTypes.arrayOf(PropTypes.shape({
    cancelText: PropTypes.string.isRequired,
  })),
  commentTitle: PropTypes.string.isRequired,
  emptyState: PropTypes.arrayOf(PropTypes.shape({
    emptyStateText: PropTypes.string.isRequired,
    emptyStateTitle: PropTypes.string.isRequired,
    navigateTo: PropTypes.func,
    navigateToText: PropTypes.string.isRequired,
  })),
  footer: PropTypes.arrayOf(PropTypes.shape({
    me: PropTypes.string.isRequired,
    sentBy: PropTypes.string.isRequired,
  })),
  getLeadershipRequestsSent: PropTypes.func,
  leadersTitle: PropTypes.arrayOf(PropTypes.shape({
    currentLeader: PropTypes.string.isRequired,
    newAccountLeader: PropTypes.string.isRequired,
    newCareerLeader: PropTypes.string.isRequired,
    newMainLeader: PropTypes.string.isRequired,
    newSecondaryLeader: PropTypes.string.isRequired,
  })),
  leaderEmptyState: PropTypes.arrayOf(PropTypes.shape({
    leaderEmptyStateText: PropTypes.string.isRequired,
    leaderEmptyStateImage: PropTypes.string.isRequired,
  })),
  loading: PropTypes.bool,
  openModal,
  page: PropTypes.arrayOf(PropTypes.shape({
    number: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
  })),
  requests: PropTypes.func.isRequired,
  requestTitle: PropTypes.arrayOf(PropTypes.shape({
    leaderSubstitution: PropTypes.string.isRequired,
    newLeader: PropTypes.string.isRequired,
  })),
};

const mapSateToProps = (state) => ({
  loading: selectLoadingLeadershipRequestsSent(state),
  page: selectGetLeadershipRequestsPage(state),
  requests: selectGetLeadershipRequestsSent(state),
});

const mapDispatchToProps = {
  ...actions,
  openModal,
};

export default connect(
  mapSateToProps,
  mapDispatchToProps
)(LeadershipRequestSent);
