import { takeLatest, call, put } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import { closeFullscreenModal } from 'smu-app-components/FullScreenModal/actions';
import {
  CREATE_SUGGESTED_ACTIONS,
  GET_SUGGESTED_ACTIONS,
} from './actionTypes';
import {
  createSuggestedActions,
  getSuggestedActions,
} from '../api';
import {
  createSuggestedActionsFail,
  createSuggestedActionsSuccess,
  getSuggestedActionsFail,
  getSuggestedActionsSuccess,
} from './actions';

function* createSuggestedActionsWorker({ payload }) {
  try {
    const { messages: { success }, ...data } = payload;
    const response = yield call(createSuggestedActions, data);
    yield put(createSuggestedActionsSuccess(response));
    yield put(closeFullscreenModal());
    yield put(addToastMessage({ message: success, timeout: 3000 }));
  } catch (error) {
    yield put(createSuggestedActionsFail(error));
    yield put(addToastMessage({ message: error.message, timeout: 3000 }));
  }
}

function* getSuggestedActionsWorker({ payload }) {
  try {
    const params = {
      active: true,
      pageSize: 20,
      ...payload
    };
    const response = yield call(getSuggestedActions, params);
    yield put(getSuggestedActionsSuccess(response));
  } catch (error) {
    yield put(getSuggestedActionsFail(error));
  }
}

export default function* suggestActionsWatcher() {
  yield takeLatest(CREATE_SUGGESTED_ACTIONS, createSuggestedActionsWorker);
  yield takeLatest(GET_SUGGESTED_ACTIONS, getSuggestedActionsWorker);
}
