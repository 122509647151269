// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'smu-ui-components/IconV2';

// @app
import ActionButtons from 'components/molecules/ActionButtons';
import FooterCard from 'components/molecules/FooterCard';
import HeaderCard from 'components/molecules/HeaderCard';
import LeadersCard from 'components/molecules/LeadersCard';
import LeadersCardDisabled from 'components/molecules/LeadersCardDisabled';
import Title from 'components/atoms/Title';
import { SIZE_FIFTEEN, SIZE_THIRTEEN } from 'components/common/constants';

// @own
import './styles.scss';

const ChangeLeaderCardDisabled = ({
  actionButtons,
  approverCard: { approverHighlight, approverTitle },
  className,
  commentTitle,
  disclaimer,
  emptyStateText,
  footer: { sentBy, sentByName },
  request: {
    approver,
    collaborator,
    comment,
    requester,
  },
  requesterCard: { requesterHighlight, requesterTitle },
  sentDate,
  title,
}) => {
  return (
    <div className={cn('change-leader-card-disabled', className)}>
      <span className="change-leader-card-disabled__disclaimer">
        <Icon
          className="change-leader-card-disabled__disclaimer--icon"
          color="orange"
          icon="expired-icon"
          size={14}
        />
        <p className="change-leader-card-disabled__disclaimer--text">{disclaimer}</p>
      </span>
      <div className="change-leader-card-disabled--opacity">
        <HeaderCard
          className="change-leader-card-disabled__header"
          date={sentDate}
          useLink={false}
          userAvatarProps={collaborator}
        />
        <Title
          className="change-leader-card-disabled__title"
          size={SIZE_FIFTEEN}
        >
          {title}
        </Title>
        <div className="change-leader-card-disabled__leaders">
          <LeadersCardDisabled
            className="change-leader-card-disabled__leaders-card"
            emptyStateText={emptyStateText}
            emptyStateTitle={requesterTitle}
            leadersTitle={requesterTitle}
            useLink={false}
            userAvatarProps={requester}
          />
          <Icon
            className="change-leader-card-disabled__leaders-icon"
            icon="substitution"
            size={20}
          />
          <LeadersCard
            className="change-leader-card-disabled__leaders-card"
            highlightedLeader={approverHighlight}
            leadersTitle={approverTitle}
            useLink={false}
            userAvatarProps={approver}
          />
        </div>
        {comment && (
          <>
            <Title
              black
              className="change-leader-card-disabled__comment-title"
              size={SIZE_THIRTEEN}
            >
              {commentTitle}
            </Title>
            <div className="change-leader-card-disabled__comment">
              {comment}
            </div>
          </>
        )}
        <FooterCard
          className="change-leader-card-disabled__footer"
          sentBy={sentBy}
          userAvatar={requester}
          userName={sentByName}
        />
      </div>
      <ActionButtons
        buttons={actionButtons}
        className="change-leader-card-disabled__buttons"
      />
    </div>
  )
};

ChangeLeaderCardDisabled.defaultProps = {
  actionButtons: [],
  approverHighlight: false,
  requesterHighlight: false,
};

ChangeLeaderCardDisabled.propTypes = {
  actionButtons: PropTypes.array.isRequired,
  approverCard: PropTypes.arrayOf(PropTypes.shape(
    {
      approverHighlight: PropTypes.bool,
      approverTitle: PropTypes.string,
    }
  )),
  className: PropTypes.string,
  commentTitle: PropTypes.string,
  footer: PropTypes.arrayOf(PropTypes.shape(
    {
      sentBy: PropTypes.string,
      sentByName: PropTypes.string,
    }
  )),
  requesterCard: PropTypes.arrayOf(PropTypes.shape(
    {
      requesterHighlight: PropTypes.bool,
      requesterTitle: PropTypes.string,
    }
  )),
  request: PropTypes.arrayOf(PropTypes.shape(
    {
      approver: PropTypes.object.isRequired,
      collaborator: PropTypes.object.isRequired,
      comment: PropTypes.string,
      requester: PropTypes.object.isRequired,
      sentDate: PropTypes.string,
    }
  )),
  title: PropTypes.string,
};

export default ChangeLeaderCardDisabled;
