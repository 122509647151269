// @packages
import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import RootModal from 'smu-app-components/RootModal';

// @app
import GenericModal from 'components/templates/GenericModalTemplate';
import InfoCard from 'components/molecules/InfoCard';
import messages from 'components/pages/LeadershipRequestSection/messages';
import { centerModalClass } from 'betterme-components/ConfirmationModal';
import { actionsMessages, commonMessages } from 'common/messages';
import { SIZE_FIFTY, SIZE_THIRTY_SIX} from 'components/common/constants';
import { ADD_LEADER_REQUEST_MODAL } from 'betterme-components/constants/modalTypes';

const LeadershipRequestAcceptModal = ({
  closeModal,
  intl: { formatMessage },
  isMainRequest,
  leader,
  newWordingForLeaders,
}) => {
  const actionButton = [
    {
      label: formatMessage(actionsMessages.ActionsContinue),
      onClick: closeModal,
    },
  ];
  const mainLeaderMessage = newWordingForLeaders
    ? formatMessage(messages.LeadershipRequestCareerRequestModal)
    : formatMessage(messages.LeadershipRequestMainRequestModal);
  const secondaryLeaderMessage = newWordingForLeaders
    ? formatMessage(messages.LeadershipRequestExperienceRequestModal)
    : formatMessage(messages.LeadershipRequestSecondaryRequestModal);
  const newLeaderMessage = isMainRequest ? mainLeaderMessage : secondaryLeaderMessage;

  return (
    <GenericModal
      actionButtons={actionButton}
      header={
        <InfoCard
          icon="confirm_icon"
          iconSize={SIZE_FIFTY}
          message={`${leader} ${newLeaderMessage}`}
          title={formatMessage(commonMessages.WellDone)}
          titleSize={SIZE_THIRTY_SIX}
        />
      }
      success
      onClose={closeModal}
    />
  )
};

LeadershipRequestAcceptModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  intl: PropTypes.object,
  isMainRequest: PropTypes.bool,
  leader: PropTypes.string,
  newWordingForLeaders: PropTypes.bool,
};

const modalStyles = {
  width: '610px',
};

export default RootModal(
  injectIntl(LeadershipRequestAcceptModal),
  ADD_LEADER_REQUEST_MODAL,
  modalStyles,
  centerModalClass,
);
