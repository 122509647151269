import { takeLatest, call, put } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import {
  getDashboardFeedback,
  getDashboardGoalsInfo,
  getDashboardPerformanceChart,
  getDashboardTopicsOpportunities,
  getDashboardTopicsStrengths,
  getFeedbackForLeadersReceived,
  getFeedbacksSummary,
  getRatedTopics,
  searchUsers,
} from '../api';
import {
  GET_DASHBOARD_FEEDBACK_FOR_LEADERS_RESULTS,
  GET_DASHBOARD_FEEDBACK_SUMMARY,
  GET_DASHBOARD_FEEDBACK,
  GET_DASHBOARD_GOALS_INFO,
  GET_DASHBOARD_PERFORMANCE_CHART,
  GET_DASHBOARD_TOPICS_OPPORTUNITIES,
  GET_DASHBOARD_TOPICS_STRENGTHS,
  REQUEST_FEEDBACK_USERS,
  REQUEST_RATED_TOPICS,
} from './actionTypes';
import {
  getDashboardFeedbackFail,
  getDashboardFeedbackForLeadersResultsFail,
  getDashboardFeedbackForLeadersResultsSuccess,
  getDashboardFeedbackSuccess,
  getDashboardFeedbackSummaryFail,
  getDashboardFeedbackSummarySuccess,
  getDashboardGoalsInfoFail,
  getDashboardGoalsInfoSuccess,
  getDashboardPerformanceChartFail,
  getDashboardPerformanceChartSuccess,
  getDashboardTopicsOpportunitiesFail,
  getDashboardTopicsOpportunitiesSuccess,
  getDashboardTopicsStrengthsFail,
  getDashboardTopicsStrengthsSuccess,
  requestFeedbackUsersFail,
  requestFeedbackUsersSuccess,
  requestRatedTopicsFail,
  requestRatedTopicsSuccess,
} from './actions';

function* getDashboardGoalsInfoWorker({ payload }) {
  const { userId, from: dateFrom, to: dateTo } = payload;
  const filters = { dateFrom, dateTo };
  try {
    const response = yield call(getDashboardGoalsInfo, userId, filters);
    yield put(getDashboardGoalsInfoSuccess(response));
  } catch (error) {
    yield put(getDashboardGoalsInfoFail(error));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

function* getDashboardFeedbackSummaryWorker({ payload }) {
  const { userId } = payload;

  try {
    const response = yield call(getFeedbacksSummary, payload);
    yield put(getDashboardFeedbackSummarySuccess(userId, response));
  } catch (error) {
    yield put(getDashboardFeedbackSummaryFail(error, userId));
    if (error.status && error.status !== 400) {
      yield put(addToastMessage({ message: error.message, timeout: 3000 }));
    }
  }
}

function* getDashboardTopicsOpportunitiesWorker({ payload }) {
  try {
    const response = yield call(getDashboardTopicsOpportunities, payload);
    yield put(getDashboardTopicsOpportunitiesSuccess(response));
  } catch (error) {
    yield put(getDashboardTopicsOpportunitiesFail(error));
    yield put(addToastMessage({ message: error.message, timeout: 3000 }));
  }
}

function* getDashboardTopicsStrengthsWorker({ payload }) {
  try {
    const response = yield call(getDashboardTopicsStrengths, payload);
    yield put(getDashboardTopicsStrengthsSuccess(response));
  } catch (error) {
    yield put(getDashboardTopicsStrengthsFail(error));
    yield put(addToastMessage({ message: error.message, timeout: 3000 }));
  }
}

function* getDashboardFeedbackWorker({ payload }) {
  try {
    const response = yield call(getDashboardFeedback, payload);
    yield put(getDashboardFeedbackSuccess(response));
  } catch (error) {
    yield put(getDashboardFeedbackFail(error));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

function* requestFeedbackUsersWorker({ payload }) {
  const { query } = payload;
  const pageSize = 20;
  try {
    const response = yield call(searchUsers, query, pageSize);
    yield put(requestFeedbackUsersSuccess(response));
  } catch (error) {
    yield put(requestFeedbackUsersFail(error.message));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

function* requestRatedTopicsWorker({ payload }) {
  try {
    const response = yield call(getRatedTopics, payload);
    yield put(requestRatedTopicsSuccess(response));
  } catch (error) {
    yield put(requestRatedTopicsFail(error.message));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

function* getDashboardFeedbackForLeadersResultsWorker({ payload: { params } }) {
  const defaultParams = {
    higherLowerSection: true,
    ...params,
  };

  try {
    const response = yield call(getFeedbackForLeadersReceived, defaultParams);
    yield put(getDashboardFeedbackForLeadersResultsSuccess(response));
  } catch(error) {
    yield put(getDashboardFeedbackForLeadersResultsFail(error.message));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

function* getDashboardPerformanceChartWorker({ payload }) {
  try {
    const response = yield call(getDashboardPerformanceChart, payload);
    yield put(getDashboardPerformanceChartSuccess(response));
  } catch (error) {
    yield put(getDashboardPerformanceChartFail(error));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

export default function* dashboardWatcher() {
  yield takeLatest(GET_DASHBOARD_FEEDBACK, getDashboardFeedbackWorker);
  yield takeLatest(GET_DASHBOARD_FEEDBACK_SUMMARY, getDashboardFeedbackSummaryWorker);
  yield takeLatest(GET_DASHBOARD_GOALS_INFO, getDashboardGoalsInfoWorker);
  yield takeLatest(GET_DASHBOARD_PERFORMANCE_CHART, getDashboardPerformanceChartWorker);
  yield takeLatest(GET_DASHBOARD_TOPICS_OPPORTUNITIES, getDashboardTopicsOpportunitiesWorker);
  yield takeLatest(GET_DASHBOARD_TOPICS_STRENGTHS, getDashboardTopicsStrengthsWorker);
  yield takeLatest(REQUEST_FEEDBACK_USERS, requestFeedbackUsersWorker);
  yield takeLatest(REQUEST_RATED_TOPICS, requestRatedTopicsWorker);
  yield takeLatest(GET_DASHBOARD_FEEDBACK_FOR_LEADERS_RESULTS, getDashboardFeedbackForLeadersResultsWorker);
}
