import {
  GET_LEADERSHIP_REQUESTS_SENT,
  GET_LEADERSHIP_REQUESTS_SENT_FAIL,
  GET_LEADERSHIP_REQUESTS_SENT_SUCCESS,
} from './actionTypes';

export function getLeadershipRequestsSent(type, params) {
  return {
    type: GET_LEADERSHIP_REQUESTS_SENT,
    payload: { type, params },
  }
};

export function getLeadershipRequestsSentFail(error) {
  return {
    type: GET_LEADERSHIP_REQUESTS_SENT_FAIL,
    payload: { error },
  };
};

export function getLeadershipRequestsSentSuccess(response) {
  return {
    type: GET_LEADERSHIP_REQUESTS_SENT_SUCCESS,
    payload: { response },
  };
};
