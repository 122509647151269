import {
  CREATE_SUGGESTED_ACTIONS,
  CREATE_SUGGESTED_ACTIONS_FAIL,
  CREATE_SUGGESTED_ACTIONS_SUCCESS,
  GET_SUGGESTED_ACTIONS,
  GET_SUGGESTED_ACTIONS_FAIL,
  GET_SUGGESTED_ACTIONS_SUCCESS,
} from './actionTypes';

export function createSuggestedActions({
  comment,
  recipientId,
  suggestedActionsIds,
  messages,
}) {
  return {
    type: CREATE_SUGGESTED_ACTIONS,
    payload: {
      comment,
      messages,
      recipientId,
      suggestedActionsIds,
    },
  };
}

export function createSuggestedActionsFail(error) {
  return {
    type: CREATE_SUGGESTED_ACTIONS_FAIL,
    payload: { error },
  };
}

export function createSuggestedActionsSuccess(response) {
  return {
    type: CREATE_SUGGESTED_ACTIONS_SUCCESS,
    payload: { response },
  };
}

export function getSuggestedActions() {
  return {
    type: GET_SUGGESTED_ACTIONS,
    payload: {  },
  };
}

export function getSuggestedActionsFail(error) {
  return {
    type: GET_SUGGESTED_ACTIONS_FAIL,
    payload: { error },
  };
}

export function getSuggestedActionsSuccess(response) {
  return {
    type: GET_SUGGESTED_ACTIONS_SUCCESS,
    payload: { response },
  };
}
