// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import RootModal from 'smu-app-components/RootModal';

// @app
import { GOAL_CREATE_WEIGHT_WARNING } from '../../constants/modalTypes';
import ConfirmationModal from '../../ConfirmationModal';
import { actionsMessages } from 'common/messages';

// @own
import { goalWeightValidationContinue } from '../actions';
import messages from '../messages';
import './styles.scss';

const GoalWeightWarningModal = ({
  assignees,
  close,
  goalWeightValidationContinue,
  intl: { formatMessage },
  allowsGoalCreationWithSumValidation,
}) => {
  const confirmationModalProps = allowsGoalCreationWithSumValidation ? ({
    acceptMessage: formatMessage(messages.FeedbackGoalWeightWarningModalRestrictiveAcceptButton),
    onAccept: close,
  }) : ({
    acceptMessage: formatMessage(actionsMessages.ActionsContinue),
    cancelMessage: formatMessage(actionsMessages.ActionsCancel),
    onAccept: goalWeightValidationContinue,
    onCancel: close,
  });

  return (
    <ConfirmationModal
      className="goal-weight-warning-modal"
      title={formatMessage(messages.FeedbackGoalWeightWarningModalTitle)}
      {...confirmationModalProps}
    >
      <div className="goal-weight-warning-modal__content">
        <div className="goal-weight-warning-modal__message">
          {allowsGoalCreationWithSumValidation ?
            formatMessage(messages.FeedbackGoalWeightWarningModalRestrictiveDescription) :
            formatMessage(messages.FeedbackGoalWeightWarningModalDescription)
          }
        </div>
        <ul className="goal-weight-warning-modal__list">
          {assignees.map(assignee =>
            <li
              className="goal-weight-warning-modal__user"
              key={assignee.id}
              >
                {`${assignee.firstName} ${assignee.lastName || ''}`}
            </li>
          )}
          </ul>
          {!allowsGoalCreationWithSumValidation && (
            <div className="goal-weight-warning-modal__message">
              {formatMessage(messages.FeedbackGoalWeightWarningModalConfirmation)}
            </div>
          )}
      </div>
    </ConfirmationModal>
  );
};

GoalWeightWarningModal.propTypes = {
  assignees: PropTypes.arrayOf(PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string,
  })),
  close: PropTypes.func.isRequired,
  goalWeightValidationContinue: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  allowsGoalCreationWithSumValidation: PropTypes.bool,
};

const modalStyles = { maxWidth: '600px' };
const withIntlComponent = injectIntl(GoalWeightWarningModal);
const withRootModalComponent = RootModal(
  withIntlComponent,
  GOAL_CREATE_WEIGHT_WARNING,
  modalStyles, 'confirmation-modal__wrapper'
);

const mapDispatchToProps = (dispatch) => {
  return {
    modalProps: {
      goalWeightValidationContinue: () => dispatch(goalWeightValidationContinue()),
    }
  };
};

export default connect(null, mapDispatchToProps)(withRootModalComponent);
