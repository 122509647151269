// @packages
import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import RootModal from 'smu-app-components/RootModal';

// @app
import GenericModal from 'components/templates/GenericModalTemplate';
import InfoCard from 'components/molecules/InfoCard';
import messages from 'components/pages/LeadershipRequestSection/messages';
import { centerModalClass } from 'betterme-components/ConfirmationModal';
import { actionsMessages, commonMessages } from 'common/messages';
import { SIZE_FIFTY, SIZE_THIRTY_SIX } from 'components/common/constants';
import { LEADERSHIP_REQUEST_ACCEPT_MODAL } from 'betterme-components/constants/modalTypes';

const LeadershipRequestAcceptModal = ({
  closeModal,
  collaborator,
  intl: { formatMessage },
}) => {
  const actionButton = [
    {
      label: formatMessage(actionsMessages.ActionsContinue),
      onClick: closeModal,
    },
  ];

  return (
    <GenericModal
      actionButtons={actionButton}
      header={
        <InfoCard
          icon="confirm_icon"
          iconSize={SIZE_FIFTY}
          message={formatMessage(messages.LeadershipRequestSuccessModal,
            { username: collaborator },
          )}
          title={formatMessage(commonMessages.WellDone)}
          titleSize={SIZE_THIRTY_SIX}
        />
      }
      success
      onClose={closeModal}
    />
  )
};

LeadershipRequestAcceptModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  collaborator: PropTypes.string.isRequired,
  intl: PropTypes.object,
};

const modalStyles = {
  width: '610px',
};

export default RootModal(
  injectIntl(LeadershipRequestAcceptModal),
  LEADERSHIP_REQUEST_ACCEPT_MODAL,
  modalStyles,
  centerModalClass,
);
