import {
  CHANGE_LEADER_BUTTON,
  COMPETENCY_DESCRIPTION,
  ENGAGEMENT_INDICATOR,
  FEEDBACK_REQUEST_INBOX_VIEW,
  PROGRESS_BAR,
  SELF_EVALUATION,
  SEND_SUGGESTIONS,
  UPDATE_PROGRESS,
} from './constTypes';

import {
  EVALUATIONS,
  FEEDBACK_FEED,
  GOALS_DETAILS,
  MY_TEAM,
  PROFILE,
  SEND_REQUEST_FEEDBACK,
} from './constGroups';

// Types of discoveries in the order that will appear by group
export default {
  [EVALUATIONS]: [SELF_EVALUATION],
  [FEEDBACK_FEED]: [FEEDBACK_REQUEST_INBOX_VIEW],
  [GOALS_DETAILS]: [UPDATE_PROGRESS, PROGRESS_BAR],
  [MY_TEAM]: [ENGAGEMENT_INDICATOR],
  [PROFILE]: [SEND_SUGGESTIONS, ENGAGEMENT_INDICATOR, CHANGE_LEADER_BUTTON],
  [SEND_REQUEST_FEEDBACK]: [COMPETENCY_DESCRIPTION],
};
