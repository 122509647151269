// @packages
import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

// @app
import PersonalDataItem from 'components/organisms/PersonalDataItem';

// @own
import './styles.scss';

const PersonalDataItemsList = ({
  className,
  items,
  itemsPerRow,
  variant,
}) => (
  <div className={cn(className, 'personal-data-items-list')}>
    {items.map(({ title, description }, index) =>
      <PersonalDataItem
        className="personal-data-items-list__item"
        description={description || '-'}
        key={index}
        style={itemsPerRow && { width: `${100 / itemsPerRow}%` }}
        title={title}
        variant={variant}
      />
    )}
  </div>
);

PersonalDataItemsList.defaultProps = {
  items: [],
  variant: 'betterme',
};

PersonalDataItemsList.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array,
  itemsPerRow: PropTypes.number,
  variant: PropTypes.oneOf(['betterme', 'gray']),
};

export default PersonalDataItemsList;
