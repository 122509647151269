export const ACCOUNT_LEADER_ANALYTICS = 'account_leader';
export const ACTION_TYPE_REQUIRED = 'actionTypeRequired';
export const APPROVED = 'APPROVED';
export const CANCELED = 'CANCELED';
export const CAREER_LEADER_ANALYTICS = 'career_leader';
export const CHANGE_LEADER_REQUEST_DECLINE = 'CHANGE_LEADER_REQUEST_DECLINE';
export const DATA = 'DATA';
export const DATE_PICKER_EN_PLACEHOLDER = 'mm/dd/yyyy';
export const DATE_PICKER_ES_PLACEHOLDER = 'dd/mm/aaaa';
export const DESCRIPTION_FIELD_MAX_LENGTH = 1200;
export const DESCRIPTION_FIELD_ROWS = 7;
export const LEADER_TYPE_MAIN = 'CHANGE_LEADER_REQUEST_MAIN';
export const LEADER_TYPE_SECONDARY = 'CHANGE_LEADER_REQUEST_SECONDARY';
export const MAIN_LEADER = 'MAIN_LEADER';
export const MILESTONE_MAX_LIMIT = 10;
export const OKR_FORM_NAME_FIELD_MAX_LENGTH = 60;
export const OKR_ORGANIZATIONAL_FORM_LEADER = 'LEADER';
export const RECEIVED = 'RECEIVED';
export const REJECTED = 'REJECTED';
export const REMOVE_LEADER_REQUEST_AS_MAIN = 'REMOVE_LEADER_REQUEST_AS_MAIN';
export const REMOVE_MYSELF_AS_LEADER = 'REMOVE_MYSELF_AS_LEADER';
export const SECONDARY_LEADER = 'SECONDARY_LEADER';
export const SENT = 'SENT';
export const SIZE_FIFTEEN = 15;
export const SIZE_FIFTY = 50;
export const SIZE_FIFTY_SIX = 56;
export const SIZE_FORTY = 40;
export const SIZE_FOURTEEN = 14;
export const SIZE_ONE_HUNDRED = 100;
export const SIZE_SEVENTEEN = 17;
export const SIZE_SIXTEEN = 16;
export const SIZE_THIRTEEN = 13;
export const SIZE_THIRTY_SIX = 36;
export const SIZE_TWELVE = 12;
