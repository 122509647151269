// @packages
import { getUserLanguage } from 'smu-utils/language';

export function formatCTADate(date) {
  const language = getUserLanguage();
  const newDate = new Date(date);
  const day = newDate.getDate();
  const month = newDate.toLocaleString(language, { month: 'long' });

  const formattedDate = language === 'en'
    ? `${month} ${day}`
    : `${day} de ${month.charAt(0).toUpperCase() + month.slice(1)}`;

  return formattedDate;
}
