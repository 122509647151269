export const ACCEPT_DECLINE_PROMOTION_MODAL = 'ACCEPT_DECLINE_PROMOTION_MODAL';
export const ADD_LEADER_REQUEST_MODAL = 'ADD_LEADER_REQUEST_MODAL';
export const ADD_ME_HERE_MODAL = 'ADD_ME_HERE_MODAL';
export const CHANGE_LEADER_ALERT_MODAL = 'CHANGE_LEADER_ALERT_MODAL';
export const CHANGE_LEADER_CONFIRMATION_MODAL = 'CHANGE_LEADER_CONFIRMATION_MODAL';
export const CHANGE_LEADER_MODAL = 'CHANGE_LEADER_MODAL';
export const CHANGE_LEADER_REQUEST_MODAL = 'CHANGE_LEADER_REQUEST_MODAL';
export const CHANGE_LEADER_SUCCESS_MODAL = 'CHANGE_LEADER_SUCCESS_MODAL';
export const CONFIRMATION_CREATE_SUCCESS_MODAL = 'CONFIRMATION_CREATE_SUCCESS_MODAL';
export const CREATE_GOAL_VALIDATION= "CREATE_GOAL_VALIDATION";
export const CROP_IMAGE = 'CROP_IMAGE';
export const DASHBOARD_REQUEST_FEEDBACK_MODAL = 'DASHBOARD_REQUEST_FEEDBACK_MODAL';
export const DELETE_FILE_MODAL = 'DELETE_FILE_MODAL';
export const ENGAGEMENT_FORM_SUCCESS_MODAL = 'ENGAGEMENT_FORM_SUCCESS_MODAL';
export const ENGAGEMENT_INDICATOR_MODAL = 'ENGAGEMENT_INDICATOR_MODAL';
export const EVALUATION_CREATE = 'EVALUATION_CREATE';
export const EVALUATION_CREATE_FORM = 'EVALUATION_CREATE_FORM';
export const EVALUATION_DRAFT_DELETE_MODAL = 'EVALUATION_DRAFT_DELETE_MODAL';
export const EVALUATION_DRAFT_SUBMIT_MODAL = 'EVALUATION_DRAFT_SUBMIT_MODAL';
export const FEEDBACK_CONFIRMATION = 'FEEDBACK_CONFIRMATION';
export const FEEDBACK_FOR_LEADERS_INFO_MODAL = 'FEEDBACK_FOR_LEADERS_INFO_MODAL';
export const FEEDBACK_FOR_LEADERS_SELECTION_MODAL = 'FEEDBACK_FOR_LEADERS_SELECTION_MODAL';
export const FEEDBACK_REQUEST_DISMISS = 'FEEDBACK_REQUEST_DISMISS';
export const FEEDBACK_REQUEST_QUICK_VIEW = 'FEEDBACK_REQUEST_QUICK_VIEW';
export const FEEDBACK_REQUIRED_MODAL = 'FEEDBACK_REQUIRED_MODAL';
export const FOCALS_LEADERSHIP_REQUEST_MODAL = 'FOCALS_LEADERSHIP_REQUEST_MODAL';
export const FOCAL_REMOVE_LEADER_MODAL = "FOCAL_REMOVE_LEADER_MODAL";
export const GENERIC_MODAL = 'GENERIC_MODAL';
export const GIVE_FEEDBACK = 'GIVE_FEEDBACK';
export const GOAL_CREATE = 'GOAL_CREATE';
export const GOAL_CREATED_DETAIL = 'GOAL_CREATED_DETAIL';
export const GOAL_CREATE_WEIGHT_WARNING = 'GOAL_CREATE_WEIGHT_WARNING';
export const GOAL_DETAIL = 'GOAL_DETAIL';
export const GOAL_DETAIL_ACTION_CLOSE_MODAL = 'GOAL_DETAIL_ACTION_CLOSE_MODAL';
export const GOAL_DETAIL_ACTION_DELETE_MODAL = 'GOAL_DETAIL_ACTION_DELETE_MODAL';
export const GOAL_DETAIL_SUCCESS_CLOSE_MODAL = 'GOAL_DETAIL_SUCCESS_CLOSE_MODAL';
export const GOAL_DETAIL_SUCCESS_CONFIRM_CREATE_MODAL = 'GOAL_DETAIL_SUCCESS_CONFIRM_CREATE_MODAL';
export const GOAL_DETAIL_VIEW_MODAL = 'GOAL_DETAIL_VIEW_MODAL';
export const LEADERSHIP_REMOVAL_MODAL = 'LEADERSHIP_REMOVAL_MODAL';
export const LEADERSHIP_REQUEST_ACCEPT_MODAL = 'LEADERSHIP_REQUEST_ACCEPT_MODAL';
export const LEADERSHIP_REQUEST_CANCEL_MODAL = 'LEADERSHIP_REQUEST_CANCEL_MODAL';
export const LEADERSHIP_REQUEST_MODAL = 'LEADERSHIP_REQUEST_MODAL';
export const LEADER_CONFIRMATION_MODAL = 'LEADER_CONFIRMATION_MODAL';
export const LEADER_CONFIRMATION_SUCCESS_MODAL = 'LEADER_CONFIRMATION_SUCCESS_MODAL';
export const NEW_HIRE_EXPIRED_SELF_VALIDATION = 'NEW_HIRE_EXPIRED_SELF_VALIDATION';
export const NEW_HIRE_EXPIRED_VALIDATION = 'NEW_HIRE_EXPIRED_VALIDATION';
export const PE_OFF_NEW_HIRE_EXPIRED = 'PE_OFF_NEW_HIRE_EXPIRED';
export const POTENTIAL_ASSESSMENT_EDIT_MODAL = 'POTENTIAL_ASSESSMENT_EDIT_MODAL';
export const PROFILE_MORE_INFO = 'PROFILE_MORE_INFO';
export const PROMOTION_REQUEST_POSITIONS_HISTORY_MODAL = 'PROMOTION_REQUEST_POSITIONS_HISTORY_MODAL';
export const PROMOTION_REQUEST_PROMOTIONS_HISTORY_MODAL = 'PROMOTION_REQUEST_PROMOTIONS_HISTORY_MODAL';
export const REASONS_DIMISS_MODAL = 'REASONS_DIMISS_MODAL';
export const REMOVE_LEADER_MODAL = 'REMOVE_LEADER_MODAL';
export const REMOVE_LEADER_WELLDONE_MODAL = 'REMOVE_LEADER_WELLDONE_MODAL';
export const SEND_EVALUATION_CONFIRMATION_MODAL = 'SEND_EVALUATION_CONFIRMATION_MODAL';
export const SEND_EVALUATION_REQUIRED_MODAL = 'SEND_EVALUATION_REQUIRED_MODAL';
export const SEND_REQUEST_FEEDBACK = 'SEND_REQUEST_FEEDBACK';
export const SKILL_SELECTOR_MODAL = 'SKILL_SELECTOR_MODAL';
export const SUGGEST_ACTIONS_LIST_MODAL = 'SUGGEST_ACTIONS_LIST_MODAL';
export const SUGGEST_ACTIONS_MODAL = 'SUGGEST_ACTIONS_MODAL';
export const SUGGEST_LEADER_REQUIRED_MODAL = 'SUGGEST_LEADER_REQUIRED_MODAL';
export const TEAM_EVALUATIONS_HISTORY = 'TEAM_EVALUATIONS_HISTORY';
export const TELL_US_ABOUT_MODAL = 'TELL_US_ABOUT_MODAL';
export const VALIDATION_LOWEST_RATING = 'VALIDATION_LOWEST_RATING';
export const VALIDATION_NEW_HIRE_EXPIRED = 'VALIDATION_NEW_HIRE_EXPIRED';
export const CREATE_PROMOTION_REQUEST_MODAL = 'CREATE_PROMOTION_REQUEST_MODAL';
export const SEND_ASSESSMENT_CONFIRMATION_MODAL= 'SEND_ASSESSMENT_CONFIRMATION_MODAL';
