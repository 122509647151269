// @packages
import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

// @own
import './styles.scss';
import CardActions from './CardActions';
import CardContent from './CardContent';
import CardHeader from './CardHeader';
import CardMeta from './CardMeta';
import CardTitle from './CardTitle';

const Card = ({
  children,
  className,
  lightGray,
  noBorder,
  transparent,
}) => {
  return (
    <div className={cn('card', className, {
      'card--light-gray': lightGray,
      'card--no-border': noBorder,
      'card--transparent': transparent,
    })}>
      {children}
    </div>
  );
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
};

Card.Actions = CardActions;
Card.Content = CardContent;
Card.Header = CardHeader;
Card.Meta = CardMeta;
Card.Title = CardTitle;

export default Card;
