// @packages
import cn from 'classnames';
import React from 'react';
import UserAvatar from 'smu-ui-components/UserAvatar';

// @own
import './styles.scss';

const Userbox = ({
  className,
  firstName,
  lastName,
  profileImageCode,
}) => {
  return (
    <div className={cn('user-box', className)}>
      <UserAvatar profileImageCode={profileImageCode} width={32} height={32} />
      <strong className="user-box__name">
        {`${firstName} ${lastName}`}
      </strong>
    </div>
  );
};

export default Userbox;