export const ASSESSMENT_EDIT_ANSWER = 'ASSESSMENT_EDIT_ANSWER';
export const ASSESSMENT_SEND_ANSWER = 'ASSESSMENT_SEND_ANSWER';
export const GET_ASSESSMENT_COLLABORATORS = 'GET_ASSESSMENT_COLLABORATORS';
export const GET_ASSESSMENT_COLLABORATORS_FAIL= 'GET_ASSESSMENT_COLLABORATORS_FAIL';
export const GET_ASSESSMENT_COLLABORATORS_SUCCESS = 'GET_ASSESSMENT_COLLABORATORS_SUCCESS';
export const GET_ASSESSMENT_QUESTIONS = 'GET_ASSESSMENT_QUESTIONS';
export const GET_ASSESSMENT_QUESTIONS_FAIL = 'GET_ASSESSMENT_QUESTIONS_FAIL';
export const GET_ASSESSMENT_QUESTIONS_SUCCESS = 'GET_ASSESSMENT_QUESTIONS_SUCCESS';
export const GET_ASSESSMENT_RATINGS = 'GET_ASSESSMENT_RATINGS';
export const GET_ASSESSMENT_RATINGS_FAIL = 'GET_ASSESSMENT_RATINGS_FAIL';
export const GET_ASSESSMENT_RATINGS_SUCCESS = 'GET_ASSESSMENT_RATINGS_SUCCESS';
export const GET_ASSESSMENT_SCORE = 'GET_ASSESSMENT_SCORE';
export const GET_ASSESSMENT_SCORE_FAIL = 'GET_ASSESSMENT_SCORE_FAIL';
export const GET_ASSESSMENT_SCORE_SUCCESS = 'GET_ASSESSMENT_SCORE_SUCCESS';
export const GET_ASSESSMENT_TIER = 'GET_ASSESSMENT_TIER';
export const GET_ASSESSMENT_TIER_FAIL = 'GET_ASSESSMENT_TIER_FAIL';
export const GET_ASSESSMENT_TIER_SUCCESS = 'GET_ASSESSMENT_TIER_SUCCESS';
export const RESET_ASSESSMENT = 'RESET_ASSESSMENT';
export const RESET_TIER = 'RESET_TIER';
export const SEND_ASSESSMENT_RESULTS = 'SEND_ASSESSMENT_RESULTS';
export const SEND_ASSESSMENT_RESULTS_FAIL = 'SEND_ASSESSMENT_RESULTS_FAIL';
export const SEND_ASSESSMENT_RESULTS_SUCCESS = 'SEND_ASSESSMENT_RESULTS_SUCCESS';
export const SEND_ASSESSMENT_SUMMARY = 'SEND_ASSESSMENT_SUMMARY';
export const SEND_ASSESSMENT_SUMMARY_FAIL = 'SEND_ASSESSMENT_SUMMARY_FAIL';
export const SEND_ASSESSMENT_SUMMARY_SUCCESS = 'SEND_ASSESSMENT_SUMMARY_SUCCESS';
