import { defineMessages } from 'react-intl';

export default defineMessages({
  SectionsDescription: {
    id: 'Betterme.Messages.Sections.Description',
    defaultMessage: 'Description',
  },
  SectionsTitle: {
    id: 'Betterme.Messages.Sections.Title',
    defaultMessage: 'Title',
  },
  SectionsOverview: {
    id: 'Betterme.Messages.Sections.Overview',
    defaultMessage: 'Overview',
  },
  SectionsScope: {
    id: 'Betterme.Messages.Sections.Scope',
    defaultMessage: 'Scope',
  },
  SectionsDueDate: {
    id: 'Betterme.Messages.Sections.DueDate',
    defaultMessage: 'Due Date',
  },
  SectionsStartDate: {
    id: 'Betterme.Messages.Sections.StartDate',
    defaultMessage: 'Start Date',
  },
  SectionsName: {
    id: 'Betterme.Messages.Sections.Name',
    defaultMessage: 'Name',
  },
});
