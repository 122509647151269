// @packages
import React, { Component } from 'react';
import RootModal from 'smu-app-components/RootModal';
import SimpleModal from 'common/SimpleModal';
import { CSSTransition } from 'react-transition-group';
import { injectIntl } from 'react-intl';

// @app
import { FEEDBACK_REQUEST_QUICK_VIEW } from '../../constants/modalTypes';
import { actionsMessages, feedbackMessages } from 'common/messages';
import { centerModalClass, modalStyles }  from '../../ConfirmationModal';
import { ellipsis } from 'utils/ellipsis';

// @own
import './styles.scss';
import FeedbackRequestCard from '../FeedbackRequestCard';
import FeedbackRequestDismiss from '../FeedbackRequestDismiss';
import messages from '../messages';
import {
  trackCloseModalClick,
  trackModalAnswerButton,
} from '../analytics';

class FeedbackRequestQuickView extends Component {
  constructor() {
    super();
    this.state = {
      showingDismiss: false,
      transitionIn: undefined,
    };
  }

  getFeedbackRequestCardMsg() {
    const { intl: { formatMessage } } = this.props;
    return {
      answer: formatMessage(messages.FeedbackRequestInboxTabsAnswer),
      cancel: formatMessage(actionsMessages.ActionsCancel),
      decline: formatMessage(messages.FeedbackRequestInboxTabsDecline),
      dismiss: formatMessage(messages.FeedbackRequestInboxTabsDismiss),
      requestedByTitle: formatMessage(feedbackMessages.FeedbackFeedbackRequestedBy),
      topicTitle: formatMessage(messages.FeedbackRequestInboxTabsTopicsTitle),
    };
  }

  getModalTitle() {
    const { intl: { formatMessage } } = this.props;
    const { showingDismiss } = this.state;

    return showingDismiss
      ? formatMessage(messages.FeedbackRequestDismissModalTitle)
      : formatMessage(feedbackMessages.FeedbackRequests);
  }

  getPrimaryButtonProps() {
    const { intl: { formatMessage } } = this.props;
    return {
      color: 'indigo',
      children: formatMessage(messages.FeedbackRequestInboxTabsAnswer),
    };
  }

  getSecondaryButtonProps() {
    const { intl: { formatMessage } } = this.props;
    return {
      children: formatMessage(messages.FeedbackRequestInboxTabsDismiss),
    };
  }

  handleAnswer() {
    const {
      close,
      feedbackRequest,
      goToSendRequestFeedback,
    } = this.props;
    const {
      comment,
      id,
      requestedBy,
      topics,
      user,
    } = feedbackRequest;
    close();
    goToSendRequestFeedback(user, id, requestedBy, topics, comment);
    trackModalAnswerButton();
  }

  handleDismiss() {
    this.setState({ showingDismiss: true });
  }

  handleTransitionEnd(done, node, ev) {
    if (ev.target === node) {
      done();
    }
  }

  render() {
    const {
      allowsFeedbackRequestsDismiss,
      close,
      feedbackRequest,
      userId,
    } = this.props;
    const { showingDismiss, transitionIn } = this.state;

    return (
      <SimpleModal
        className="feeback-request-quick-view"
        closeModal={() => {
          close();
          trackCloseModalClick();
        }}
        title={this.getModalTitle()}
      >
        <CSSTransition
          addEndListener={(node, done) => {
            node.addEventListener(
              'transitionend',
              (e) => this.handleTransitionEnd(done, node, e),
              false,
            );
          }}
          classNames="feeback-request-quick-view__content"
          in={transitionIn}
          onEntered={() => this.setState({ showingDismiss: true })}
          onExited={() => this.setState({ showingDismiss: false })}
        >
          {()  => (
            <div className="feeback-request-quick-view__content">
            {!showingDismiss ? (
              <FeedbackRequestCard
                {...feedbackRequest}
                comment={ellipsis(feedbackRequest.comment, 1200)}
                className="feeback-request-quick-view__request-card"
                messages={this.getFeedbackRequestCardMsg()}
                onAnswer={() => this.handleAnswer()}
                onDismiss={allowsFeedbackRequestsDismiss
                  ? () => this.setState({ transitionIn: true })
                  : undefined
                }
              />
            ) : (
              <FeedbackRequestDismiss
                className="feeback-request-quick-view__dismiss"
                onAccept={close}
                onCancel={() => this.setState({ transitionIn: false })}
                radioClassName="feeback-request-quick-view__radios-dismiss"
                refreshParams={{ pageSize: 3 }}
                requestId={feedbackRequest.id}
                userId={userId}
              />
            )}
          </div>
          )}
        </CSSTransition>
      </SimpleModal>
    );
  }

}

export default RootModal(
  injectIntl(FeedbackRequestQuickView),
  FEEDBACK_REQUEST_QUICK_VIEW,
  modalStyles,
  centerModalClass,
);
