// @packages
import PropTypes from 'prop-types';
import React from 'react';
import Icon from 'smu-ui-components/Icon';

// @own
import './styles.scss';

const DueDateStatus = ({
  dueDateText,
  expiredText,
  messages,
  showExpired,
  startDateText,
}) => {
  return (
    <span className="duedate-status">
      {startDateText && (
        <span className="duedate-status__start-date">
          <strong>{messages.startDate}: </strong>
          <span>{startDateText} - </span>
        </span>
      )}

      {dueDateText && (
        <span className="duedate-status__end-date">
          <strong>{messages.dueDate}: </strong>
          <span>{dueDateText}</span>
        </span>
      )}

      {showExpired && (
        <span className="duedate-status__expired">
          <Icon
            className="duedate-status__expired-icon"
            color="orange"
            icon="expired-icon"
            size={14}
          />
          <strong className="duedate-status__expired-text" >
            {expiredText}
          </strong>
        </span>
      )}
    </span>
  );
};

DueDateStatus.propTypes = {
  dueDateText: PropTypes.string.isRequired,
  startDateText: PropTypes.string,
  expiredText: PropTypes.string.isRequired,
  showExpired: PropTypes.bool.isRequired,
  messages: PropTypes.shape({
    dueDate: PropTypes.string,
    startDate: PropTypes.string,
  }),
};

export default DueDateStatus;
