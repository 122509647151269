/* eslint-disable react-hooks/exhaustive-deps */
// @packages
import React, { useEffect } from 'react';
import Container from 'smu-layout-components/Container';
import OneOnOneHome from 'smu-app-components/OneOnOne/Home';
import PropTypes from 'prop-types';
import UtilsProvider from 'smu-app-components/UtilsProvider';
import { connect } from 'react-redux';

// @app
import {
  CALENDAR_PROVIDER,
  ENABLED_ONE_ON_ONE,
  ENABLED_ONE_ON_ONE_REQUEST,
} from 'betterme-components/services/CommunityConfigs/constants';
import {
  getCommunityConfigValue,
  getCommunityConfigValueBool,
} from 'betterme-components/services/CommunityConfigs/selectors';

const OneOnOneHomeLayout = ({
  calendarProvider,
  enabledOneOnOne,
  enabledOneOnOneRequest,
}) => {
  const buildUserProfileUrl = user => (
    `/profile/${window.btoa(user?.identification)}/dashboard`
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const utils = {
    buildUserProfileUrl,
    calendarProvider,
    defaultExcludeFormer: true,
    enableRequestFlow: enabledOneOnOneRequest,
    enableSyncWithCalendar: true,
    excludeFormerMenu: true,
    mainFeatureIsEnabled: enabledOneOnOne,
  };

  return (
    <Container centered>
      <div className="py-8">
        <UtilsProvider utils={utils}>
          <OneOnOneHome />
        </UtilsProvider>
      </div>
    </Container>
  );
};

OneOnOneHomeLayout.propTypes = {
  calendarProvider: PropTypes.string,
  enabledOneOnOne: PropTypes.bool,
  enabledOneOnOneRequest: PropTypes.bool,
  router: PropTypes.object,
};

const mapStateToProps = (state) => ({
  calendarProvider: getCommunityConfigValue(state, CALENDAR_PROVIDER),
  enabledOneOnOne: getCommunityConfigValueBool(state, ENABLED_ONE_ON_ONE),
  enabledOneOnOneRequest: getCommunityConfigValueBool(state, ENABLED_ONE_ON_ONE_REQUEST),
});

export default connect(mapStateToProps)(OneOnOneHomeLayout);
