import { defineMessages } from 'react-intl';

export default defineMessages({
  AddMemberSearchingTitle: {
    id: 'Betterme.AddMember.SearchingTitle',
    defaultMessage: 'Look for someone',
  },
  AddMemberSearchingPlaceholder: {
    id: 'Betterme.LeaderSubstitution.SearchingPlaceholder',
    defaultMessage: 'Search for participants by name or email',
  },
  AddMemberDashboard: {
    id: 'Betterme.AddMember.Dashboard',
    defaultMessage: 'My Team\'s Dashboard',
  },
  AddMemberTitle: {
    id: 'Betterme.AddMember.Title',
    defaultMessage: 'Add Member',
  },
  AddMemberEmptyStateTitle: {
    id:'Betterme.AddMember.EmptyStateTitle',
    defaultMessage: 'It seems you don\'t have permission to this section.',
  },
  AddMemberEmptyStateText: {
    id: 'Betterme.AddMember.EmptyStateText',
    defaultMessage: 'But you can continue learning about your colleagues by visiting other sections.',
  },
  AddMemberYourSelection: {
    id: 'Betterme.AddMember.YourSelection',
    defaultMessage: 'Your selection',
  },
  AddMemberSendLeadershipRequest: {
    id: 'Betterme.AddMember.SendLeadershipRequest',
    defaultMessage: 'Send leadership request',
  },
  AddMemberCareerLeader: {
    id: 'Betterme.AddMember.CareerLeader2',
    defaultMessage: 'Career Mentor',
  },
  AddMemberAccountLeaders: {
    id: 'Betterme.AddMember.AccountLeaders2',
    defaultMessage: 'Experience Leaders',
  },
  AddMemberMainLeader: {
    id: 'Betterme.AddMember.MainLeader',
    defaultMessage: 'Main Leader',
  },
  AddMemberSecondaryLeaders: {
    id: 'Betterme.AddMember.SecondaryLeaders',
    defaultMessage: 'Secondary Leaders',
  },
  AddMemberOtherLeaders: {
    id: 'Betterme.AddMember.OtherLeaders',
    defaultMessage: 'Experience Leaders',
  },
  AddMemberOtherLeaderPopup: {
    id: 'Betterme.LeadershipAdmin.AddLeaderPopup',
    defaultMessage: 'You are not suggested as an Experience Leader for this user.',
  },
  AddMemberAddMeAs: {
    id: 'Betterme.AddMember.AddMeAs',
    defaultMessage: 'Add me as',
  },
  AddMemberAccountLeaderBoxTitle: {
    id: 'Betterme.LeadershipAdmin.AccountLeaderBoxTitle',
    defaultMessage: 'No body here yet.',
  },
  AddMemberAccountLeaderBoxDescription: {
    id: 'Betterme.LeadershipAdmin.AccountLeaderBoxDescription',
    defaultMessage: 'It’s fine! A leader should soon show up in this area.',
  },
});
