// @packages
import { get } from 'lodash';

const reducerDomain = 'teamFeedbacks';

export const selectTeamFeedbacks = (state) =>
  get(state, [reducerDomain]);

export const selectLastRequest = (state) =>
  get(state, [reducerDomain, 'request']);
