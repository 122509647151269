import {
  GET_TEAM_FEEDBACKS,
  GET_TEAM_FEEDBACKS_FAIL,
  GET_TEAM_FEEDBACKS_SUCCESS,
  GET_TEAM_FEEDBACKS_COMMENTS,
  GET_TEAM_FEEDBACKS_COMMENTS_FAIL,
  GET_TEAM_FEEDBACKS_COMMENTS_SUCCESS,
  POST_TEAM_FEEDBACKS_COMMENTS,
  POST_TEAM_FEEDBACKS_COMMENTS_FAIL,
  POST_TEAM_FEEDBACKS_COMMENTS_SUCCESS,
} from './actionTypes';

const initialState = {
  error: '',
  feedbacks: [],
  fetching: false,
  loadingComments: false,
  page: {},
  request: null,
  sendingMessage: false,
};

function teamFeedbackReducer(state, { type, payload }) {
  switch (type) {
    case GET_TEAM_FEEDBACKS_COMMENTS_SUCCESS:
      return { ...state, loadingComments: false, comments: payload };

    case POST_TEAM_FEEDBACKS_COMMENTS:
      return { ...state, sendingMessage: true };
    case POST_TEAM_FEEDBACKS_COMMENTS_SUCCESS:
    case POST_TEAM_FEEDBACKS_COMMENTS_FAIL:
      return { ...state, sendingMessage: false };

    default:
      return state;
  }
}

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_TEAM_FEEDBACKS:
      return { ...state, fetching: true, request: payload };

    case GET_TEAM_FEEDBACKS_FAIL:
      return { ...state, fetching: false, feedbacks: [] };

    case GET_TEAM_FEEDBACKS_SUCCESS: {
      const { result, page, pageNumber } = payload.response;
      return {
        ...state,
        fetching: false,
        feedbacks: pageNumber ? [...state.feedbacks, ...result] : result,
        page,
      };
    }

    case GET_TEAM_FEEDBACKS_COMMENTS:
      return { ...state, loadingComments: true };

    case GET_TEAM_FEEDBACKS_COMMENTS_FAIL:
      return { ...state, loadingComments: false };

    case GET_TEAM_FEEDBACKS_COMMENTS_SUCCESS:
    case POST_TEAM_FEEDBACKS_COMMENTS:
    case POST_TEAM_FEEDBACKS_COMMENTS_FAIL:
    case POST_TEAM_FEEDBACKS_COMMENTS_SUCCESS:
      return {
        ...state,
        feedbacks: state.feedbacks.map(feedback => (feedback.id === payload.feedbackId)
          ? teamFeedbackReducer(feedback, { type, payload: payload.response })
          : feedback
        )
      };

    default:
      return state;
  }
}
