// @packages
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from 'smu-layout-components/Grid';
import moment from 'moment';
import Button from 'smu-ui-components/Button';
import ButtonMenu from 'smu-ui-components/ButtonMenu';
import Icon from 'smu-ui-components/Icon';
import RangeDatePicker from 'smu-ui-components/RangeDatePicker';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { injectIntl } from 'react-intl';
import { push } from 'react-router-redux';

// @app
import { getCalendarYears } from 'utils/getCalendarYears';
import * as topicsRatedActions from 'common/TopicsRated/actions';
import * as skillsRatedActions from 'common/SkillsRated/actions';
import {
  selectCommunityRatings,
  selectCompetencySummary,
  selectRatedCompetencies,
  selectRatedCompetencyDetails,
  selectSelectedCompetencyId,
  selectSelectedRating,
  selectTopicsRatedLoading,
  selectTopicsRatedOrder,
} from 'common/TopicsRated/selectors';
import {
  selectCommunityRatingsSkills,
  selectPageSkills,
  selectRatedSearchSkills,
  selectRatedSkillDetails,
  selectRatedSkills,
  selectSelectedRatingSkills,
  selectSelectedSkillId,
  selectSkillSummary,
  selectTotalPageSkills,
} from 'common/SkillsRated/selectors';
import SectionList from 'common/SectionList';
import BreadcrumbHeader from 'components/organisms/BreadcrumbHeader';
import {
  actionsMessages,
  feedbackMessages,
  placeholdersMessages,
} from 'common/messages';
import MyFeedbacks from '../MyFeedbacks';

// @own
import './styles.scss';
import FeedbackReceivedDetails from './FeedbackReceivedDetails';
import FeedbackReceivedEmptyState from './FeedbackReceivedEmptyState';
import SKILLS_PAGE_SIZE from './constants';
import { getFeedbacksReport } from './actions';

class FeedbackReceived extends Component {
  constructor(props) {
    super(props);

    const {
      endDate: creationTimeTo,
      startDate: creationTimeFrom,
    } = this.getInitialDates();

    this.state = {
      activeSkillSection: false,
      creationTimeFrom,
      creationTimeTo,
      feedbackOptionFeed: true,
    };

    this.getOutsideDateRange = this.getOutsideDateRange.bind(this);
    this.handleButtonMenuClick = this.handleButtonMenuClick.bind(this);
    this.handleDatePickerChange = this.handleDatePickerChange.bind(this);
    this.handleFeedbackOptionFeed = this.handleFeedbackOptionFeed.bind(this);
    this.handleFeedbackOptionDetail = this.handleFeedbackOptionDetail.bind(
      this
    );
    this.validateActiveTabFromDashboard = this.validateActiveTabFromDashboard.bind(this);
  }

  componentDidMount() {
    this.getSkillsRated();
    this.getTopicsRated();
    this.validateActiveTabFromDashboard();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      communityRatings: prevCommunityRatings,
      communityRatingsSkills: prevCommunityRatingsSkills,
      listOrder: prevOrder,
      loading: wasLoading,
    } = prevProps;
    const {
      creationTimeFrom: prevCreationTimeFrom,
      creationTimeTo: prevCreationTimeTo,
    } = prevState;
    const {
      communityRatings,
      communityRatingsSkills,
      listOrder: currentOrder,
      loading: isLoading,
      ratedCompetencies,
      ratedSkills,
      selectCompetency,
      selectRating,
      selectRatingSkill,
      selectSkill,
    } = this.props;
    const {
      activeSkillSection,
      creationTimeFrom: currentCreationTimeFrom,
      creationTimeTo: currentCreationTimeTo,
    } = this.state;

    if (!prevCommunityRatings.length && communityRatings.length) {
      selectRating(communityRatings[0].id);
    }

    if (!prevCommunityRatingsSkills.length && communityRatingsSkills.length) {
      selectRatingSkill(communityRatingsSkills[0].id);
    }

    if (
      !activeSkillSection &&
      wasLoading &&
      !isLoading &&
      ratedCompetencies.length > 0
    ) {
      selectCompetency(ratedCompetencies[0].id);
    }

    if (
      activeSkillSection &&
      wasLoading &&
      !isLoading &&
      ratedSkills.length > 0
    ) {
      selectSkill(ratedSkills[0].id);
    }

    if (
      prevOrder !== currentOrder
      || prevCreationTimeFrom !== currentCreationTimeFrom
      || prevCreationTimeTo !== currentCreationTimeTo
    ) {
      this.getSkillsRated();
      this.getTopicsRated();
    }
  }

  getOutsideDateRange(day) {
    const rangeEnd = moment();
    return day.isAfter(rangeEnd, 'day');
  }

  getButtonMenuOptions() {
    const { intl: { formatMessage } } = this.props;

    return [
      {
        text: formatMessage(placeholdersMessages.PlaceholdersCsvDoc),
        value: 'csv',
      },
      {
        text: formatMessage(placeholdersMessages.PlaceholdersXlsDoc),
        value: 'xls',
      },
      {
        text: formatMessage(placeholdersMessages.PlaceholdersXlsxDoc),
        value: 'xlsx',
      },
    ];
  }

  getInitialDates() {
    const { location: { state } } = this.props;
    let initialDates = {};

    if (state) {
      const { endDate, startDate } = state;
      initialDates.endDate = endDate ? moment(endDate) : undefined;
      initialDates.startDate = startDate ? moment(startDate) : undefined;
    }

    return initialDates;
  }

  getSectionListSections(ratedItems) {
    return ratedItems.map((ratedItem) => ({
      description: ratedItem.description,
      displayValue: ratedItem.name,
      value: ratedItem.id,
    }));
  }

  getTabs(itemRatings) {
    return itemRatings.map((rating) => ({
      color: rating.color,
      displayValue: rating.name,
      value: rating.id,
    }));
  }

  getTopicsRated() {
    const { getTopicsRated, listOrder, user } = this.props;
    const { creationTimeFrom, creationTimeTo } = this.state;
    const creationTimeFromUnix = creationTimeFrom
      ? creationTimeFrom.valueOf()
      : undefined;
    const creationTimeToUnix = creationTimeTo
      ? creationTimeTo.valueOf()
      : undefined;

    getTopicsRated(user.id, {
      creationTimeFrom: creationTimeFromUnix,
      creationTimeTo: creationTimeToUnix,
      ordering: listOrder.toUpperCase(),
    });
  }

  getSkillsRated() {
    const { getSkillsRated, listOrder, user } = this.props;
    const { creationTimeFrom, creationTimeTo } = this.state;
    const creationTimeFromUnix = creationTimeFrom
      ? creationTimeFrom.valueOf()
      : undefined;
    const creationTimeToUnix = creationTimeTo
      ? creationTimeTo.valueOf()
      : undefined;

    getSkillsRated(user.id, {
      creationTimeFrom: creationTimeFromUnix,
      creationTimeTo: creationTimeToUnix,
      isSkill: true,
      ordering: listOrder.toUpperCase(),
      pageSize: SKILLS_PAGE_SIZE,
    });
  }

  handleButtonMenuClick(value) {
    const { getFeedbacksReport, user } = this.props;
    const { creationTimeFrom, creationTimeTo } = this.state;
    const creationTimeFromUnix = creationTimeFrom
      ? creationTimeFrom.valueOf()
      : undefined;
    const creationTimeToUnix = creationTimeTo
      ? creationTimeTo.valueOf()
      : undefined;

    getFeedbacksReport(
      user.id,
      value,
      creationTimeFromUnix,
      creationTimeToUnix
    );
  }

  handleDatePickerChange({ startDate, endDate }) {
    this.setState({
      creationTimeFrom: startDate && startDate.local().startOf("day"),
      creationTimeTo: endDate && endDate.local().endOf("day"),
    });
  }

  handleFeedbackOptionFeed() {
    this.setState({ feedbackOptionFeed: true });
  }

  handleFeedbackOptionDetail() {
    this.setState({ feedbackOptionFeed: false });
  }

  validateActiveTabFromDashboard() {
    const { location: { query } } = this.props;

    if (query.activeTab === '1') {
      this.setState({ activeSkillSection: true });
    }
  }

  handleGetMoreItems() {
    const { getSkillsRated, listOrder, numberSkills, user } = this.props;

    getSkillsRated(user.id, {
      ordering: listOrder.toUpperCase(),
      pageSize: SKILLS_PAGE_SIZE,
      pageNumber: numberSkills + 1,
    });
  }

  onSearchSkill(query) {
    const {
      getSearchSkillsRated,
      getSkillsRated,
      listOrder,
      numberSkills,
      user,
    } = this.props;

    if (query === '') {
      getSkillsRated(user.id, {
        ordering: listOrder.toUpperCase(),
        pageSize: SKILLS_PAGE_SIZE,
        pageNumber: numberSkills,
      });
      return;
    } else {
      getSearchSkillsRated(user.id, {
        ordering: listOrder.toUpperCase(),
        searchCriteria: query,
        pageSize: SKILLS_PAGE_SIZE,
      });
      this.setState({ activeSkillSection: true });
    }
  }

  selectedItemName(ratedItems, selectedItemId) {
    return get(
      ratedItems.find(({ id }) => id === selectedItemId),
      'name'
    );
  }

  ratingName(communityRatingsItem, selectedRatingItem) {
    return get(
      communityRatingsItem.find(({ id }) => id === selectedRatingItem),
      'name'
    );
  }

  renderFeedBackReceivedDetails(selectedItemId) {
    const {
      communityRatings,
      communityRatingsSkills,
      competencySummary,
      ratedCompetencies,
      ratedCompetencyDetails,
      ratedSkillDetails,
      ratedSkills,
      selectRating,
      selectRatingSkill,
      selectedCompetencyId,
      selectedRating,
      selectedRatingSkill,
      selectedSkillId,
      skillSummary,
    } = this.props;

    const renderCompetenciesDetails = selectedItemId === selectedCompetencyId;

    return (
      <FeedbackReceivedDetails
        getTabs={this.getTabs}
        itemCommunityRatings={
          renderCompetenciesDetails ? communityRatings : communityRatingsSkills
        }
        isSkillDetail={!renderCompetenciesDetails}
        itemSummary={
          renderCompetenciesDetails ? competencySummary : skillSummary
        }
        ratedItemsDetails={
          renderCompetenciesDetails ? ratedCompetencyDetails : ratedSkillDetails
        }
        ratingName={
          renderCompetenciesDetails
            ? this.ratingName(communityRatings, selectedRating)
            : this.ratingName(communityRatingsSkills, selectedRatingSkill)
        }
        selectRating={
          renderCompetenciesDetails ? selectRating : selectRatingSkill
        }
        selectedItemName={
          renderCompetenciesDetails
            ? this.selectedItemName(ratedCompetencies, selectedCompetencyId)
            : this.selectedItemName(ratedSkills, selectedSkillId)
        }
        selectedRating={
          renderCompetenciesDetails ? selectedRating : selectedRatingSkill
        }
      />
    );
  }


  render() {
    const {
      activeTab,
      intl: { formatMessage },
      listOrder,
      loading,
      navigateBack,
      navigateBackLabel,
      navigateBackTo,
      numberSkills,
      ratedCompetencies,
      ratedSkills,
      selectCompetency,
      selectSkill,
      selectedCompetencyId,
      selectedSkillId,
      showFeedbackOptions,
      sortTopicsRated,
      totalPagesSkills,
      user,
    } = this.props;

    const {
      creationTimeTo,
      creationTimeFrom,
      feedbackOptionFeed,
      selectedIdItem,
      activeSkillSection,
    } = this.state;
    const {
      endDate: initialEndDate,
      startDate: initialStartDate,
    } = this.getInitialDates();
    const icon = {
      asc: 'sort-asc',
      desc: 'sort-desc',
    };

    const hasMoreSkills = numberSkills + 1 < totalPagesSkills;

    const handleClickSectionListActive = (id, isSkill) => {
      if (isSkill) {
        selectSkill(id);
      } else {
        selectCompetency(id);
      };

      this.setState({
        selectedIdItem: id,
        activeSkillSection: isSkill,
      });
    };

    return (
      <div className="feedback-received">
        <BreadcrumbHeader
          breadCrumbsProps={{
            active: 1,
            onClick: () =>
              navigateBack(navigateBackTo, {
                initialEndDate,
                initialStartDate,
              }),
            size: 'big',
            values: [
              navigateBackLabel,
              formatMessage(feedbackMessages.FeedbackFeedbackReceived),
            ],
          }}
        >
          <div className="feedback-received__actions">
            {showFeedbackOptions && (
              <div className="feedback-received__feedback-options">
                <Button
                  className="feedback-received__button"
                  color={feedbackOptionFeed ? 'black' : undefined}
                  noMargin
                  onClick={this.handleFeedbackOptionFeed}
                >
                  {formatMessage(feedbackMessages.FeedbackFeed)}
                </Button>
                <Button
                  className="feedback-received__button"
                  color={!feedbackOptionFeed ? 'black' : undefined}
                  noMargin
                  onClick={this.handleFeedbackOptionDetail}
                >
                  {formatMessage(feedbackMessages.FeedbackReceivedDetailButton)}
                </Button>
              </div>
            )}
            <Button
              className="feedback-received__button"
              onClick={() => sortTopicsRated()}
            >
              <Icon
                icon={icon[listOrder]}
                className="rating-details__order-icon"
              />
            </Button>
            <RangeDatePicker
              className="feedback-received__date-picker"
              type="betterme"
              yearsList={getCalendarYears()}
              actionsLabel={{
                apply: formatMessage(actionsMessages.ActionsApply),
                clear: formatMessage(actionsMessages.ActionsClear),
              }}
              align="right"
              endDatePlaceholder={formatMessage(
                placeholdersMessages.PlaceholdersTo
              )}
              initialEndDate={initialEndDate}
              initialStartDate={initialStartDate}
              isOutsideRange={(day) => this.getOutsideDateRange(day)}
              onChange={this.handleDatePickerChange}
              startDatePlaceholder={formatMessage(
                placeholdersMessages.PlaceholdersFrom
              )}
              styleInput="short"
            />
            <ButtonMenu
              buttonIcon="download"
              buttonText={formatMessage(
                placeholdersMessages.PlaceholdersDownloadReport
              )}
              className="feedback-received__report-button"
              onClickItem={this.handleButtonMenuClick}
              options={this.getButtonMenuOptions()}
            />
          </div>
        </BreadcrumbHeader>
        {!showFeedbackOptions || !feedbackOptionFeed ? (
          <div className="feedback-received__content">
            <Grid>
              <Grid.Col
                desktop={4}
                extraClass="feedback-received__section-list-wrapper"
              >
                <SectionList
                  active={!activeSkillSection}
                  className="feedback-received__section-list-competencies"
                  isSkill={false}
                  loading={loading}
                  onSelect={(id) => { handleClickSectionListActive(id, false) }}
                  sections={this.getSectionListSections(ratedCompetencies)}
                  serchBox={false}
                  title={formatMessage(feedbackMessages.FeedbackCompetencies)}
                  value={selectedCompetencyId}
                />
                <SectionList
                  active={activeSkillSection}
                  className="feedback-received__section-list-skills"
                  dataLength={this.getSectionListSections(ratedSkills).length}
                  hasMore={hasMoreSkills}
                  isSkill={true}
                  loading={loading}
                  next={() => this.handleGetMoreItems()}
                  onSearch={(e) => this.onSearchSkill(e)}
                  onSelect={(id) => { handleClickSectionListActive(id, true) }}
                  page={numberSkills}
                  scrollableTarget="skillScroll"
                  sections={this.getSectionListSections(ratedSkills)}
                  serchBox={true}
                  title={formatMessage(feedbackMessages.FeedbackSkillsTitle)}
                  value={selectedSkillId}
                />
              </Grid.Col>
              <Grid.Col desktop={8}>
                {ratedCompetencies.length > 0 || ratedSkills.length > 0 ? (
                  selectedIdItem ? (
                    this.renderFeedBackReceivedDetails(selectedIdItem)
                  ) : (
                    activeSkillSection ?
                      this.renderFeedBackReceivedDetails(selectedSkillId) :
                      this.renderFeedBackReceivedDetails(selectedCompetencyId)
                  )
                ) : (
                  <FeedbackReceivedEmptyState loading={loading} />
                )}
              </Grid.Col>
            </Grid>
          </div>
        ) : (
          <div className="feedback-received__feeds">
            <MyFeedbacks
              activeTab={activeTab}
              from={creationTimeFrom}
              ordering={listOrder}
              to={creationTimeTo}
              user={user}
              view="profile"
            />
          </div>
        )}
      </div>
    );
  }
}

FeedbackReceived.defaultProps = {
  competencySummary: [],
  ratedCompetencyDetails: [],
  ratedSkillDetails: [],
  skillSummary: [],
};

FeedbackReceived.propTypes = {
  activeTab: PropTypes.string,
  communityRatings: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      imageCode: PropTypes.string,
      name: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ),
  competencySummary: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
      imageCode: PropTypes.string,
      name: PropTypes.string.isRequired,
    })
  ),
  communityRatingsSkills: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      imageCode: PropTypes.string,
      name: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ),
  getFeedbacksReport: PropTypes.func.isRequired,
  getTopicsRated: PropTypes.func.isRequired,
  getSkillsRated: PropTypes.func.isRequired,
  getSearchSkillsRated: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  listOrder: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  navigateBack: PropTypes.func.isRequired,
  navigateBackLabel: PropTypes.string.isRequired,
  navigateBackTo: PropTypes.string.isRequired,
  ratedCompetencies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  ratedCompetencyDetails: PropTypes.arrayOf(
    PropTypes.shape({
      feedbackList: PropTypes.array,
      user: PropTypes.object,
    })
  ),
  ratedSkills: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  ratedSkillDetails: PropTypes.arrayOf(
    PropTypes.shape({
      feedbackList: PropTypes.array,
      user: PropTypes.object,
    })
  ),
  selectCompetency: PropTypes.func.isRequired,
  selectSkill: PropTypes.func.isRequired,
  selectRating: PropTypes.func.isRequired,
  selectRatingSkill: PropTypes.func.isRequired,
  selectedCompetencyId: PropTypes.number,
  selectedSkillId: PropTypes.number,
  selectedRating: PropTypes.number,
  selectedRatingSkill: PropTypes.number,
  skillSummary: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
      imageCode: PropTypes.string,
      name: PropTypes.string.isRequired,
    })
  ),
  user: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    communityRatings: selectCommunityRatings(state),
    communityRatingsSkills: selectCommunityRatingsSkills(state),
    competencySummary: selectCompetencySummary(state),
    listOrder: selectTopicsRatedOrder(state),
    loading: selectTopicsRatedLoading(state),
    numberSkills: selectPageSkills(state),
    ratedCompetencies: selectRatedCompetencies(state),
    ratedCompetencyDetails: selectRatedCompetencyDetails(state),
    ratedSkillDetails: selectRatedSkillDetails(state),
    ratedSkills: selectRatedSkills(state),
    searchRatedSkills: selectRatedSearchSkills(state),
    selectedCompetencyId: selectSelectedCompetencyId(state),
    selectedRating: selectSelectedRating(state),
    selectedRatingSkill: selectSelectedRatingSkills(state),
    selectedSkillId: selectSelectedSkillId(state),
    skillSummary: selectSkillSummary(state),
    totalPagesSkills: selectTotalPageSkills(state),
  };
};

const mapDispatchToProps = {
  ...topicsRatedActions,
  ...skillsRatedActions,
  getFeedbacksReport,
  navigateBack: (to, state) => push({ pathname: to, state }),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(FeedbackReceived));
