// @packages
import { takeLatest, call, put } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';

// @own
import { GET_LEADERSHIP_REQUESTS_RECEIVED } from './actionTypes';
import {
  getLeadershipRequestsReceivedFail,
  getLeadershipRequestsReceivedSuccess,
} from './actions';
import { getLeadershipRequestsReceived } from './api';

function* getLeadershipRequestsReceivedWorker({ payload: { type, params } }) {
  try {
    const currentParams = {
      pageSize: 100,
      relation: type,
      status: 'CREATED,ASSIGNED_CREATED',
      ...params,
    };
    const response = yield call(getLeadershipRequestsReceived, currentParams);
    yield put(getLeadershipRequestsReceivedSuccess(response));
  } catch(error) {
    yield put(getLeadershipRequestsReceivedFail(error.message));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
};

export default function* changeLeaderRequestWorker() {
  yield takeLatest(GET_LEADERSHIP_REQUESTS_RECEIVED, getLeadershipRequestsReceivedWorker);
};
