// @packages
import moment from 'moment';

export function formatPeriodDates(from, to, smallFormat) {
  if (!from || !to) {
    return '';
  }
  const format = smallFormat ? 'MM/YYYY' : 'L';

  return `${moment.utc(from).format(format)} - ${moment.utc(to).format(format)}`;
}
