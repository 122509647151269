// @packages
import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

// @own
import RatingFeedbacksListItem from 'components/atoms/RatingFeedbacksListItem';
import './styles.scss';

const RatingFeedbacksList = ({
  className,
  color,
  count,
  feedbacks,
  name,
  total,
}) => {
  const percentage = Math.round(count * 100 / total);

  return (
    <div className={cn('rating-feedbacks-list', className)}>
      <div className="rating-feedbacks-list__title" style={{ color }}>
        <span className="rating-feedbacks-list__name">{name}</span>
        <span className="rating-feedbacks-list__stats">
          <span className="rating-feedbacks-list__stats-percentage">
            {`${percentage}%`}
          </span>
          <span className="rating-feedbacks-list__stats-proportion">
            {`${count}/${total}`}
          </span>
        </span>
      </div>
      <ul className="rating-feedbacks-list__list">
        {feedbacks.map(feedback =>
          <RatingFeedbacksListItem
            className="rating-feedbacks-list__item"
            key={feedback.id}
            {...feedback}
          />
        )}
      </ul>
    </div>
  );
};

RatingFeedbacksList.defaultProps = {
  feedbacks: [],
};

RatingFeedbacksList.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  count: PropTypes.number,
  feedbacks: PropTypes.array,
  name: PropTypes.string,
  total: PropTypes.number.isRequired,
};

export default RatingFeedbacksList;
