// @packages
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Container from 'smu-layout-components/Container';
import UserSettings from 'smu-app-components/UserSettings';
import { connect } from 'react-redux';

// @app
import { NEW_NOTIFICATION_SETTINGS } from 'betterme-components/services/CommunityConfigs/constants';
import { getCommunityConfigValueBool } from 'betterme-components/services/CommunityConfigs/selectors';

function UserSettingsPage({ newNotificationsSettings, location, ...rest }) {
  const group = location?.query?.group;

  const defaultExpandedId = useMemo(() => {
    return group ? Number(group) : undefined;
  }, [group]);

  const handleChangeLanguage = useCallback(() => {
    window.location.reload();
  }, []);

  const notificationProps = useMemo(() => {
    return {
      newNotificationsSettings,
      defaultExpandedId,
    };
  }, [newNotificationsSettings, defaultExpandedId]);

  const languagueProps = useMemo(() => {
    return {
      onChange: handleChangeLanguage,
    };
  }, [handleChangeLanguage]);

  return (
    <div className="flex flex-col min-h-screen">
      <Container centered compact>
        <div className="py-5">
          <UserSettings
            languagueProps={languagueProps}
            notificationProps={notificationProps}
          />
        </div>
      </Container>
    </div>
  );
}

UserSettingsPage.propTypes = {
  newNotificationsSettings: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  newNotificationsSettings: getCommunityConfigValueBool(
    state,
    NEW_NOTIFICATION_SETTINGS
  ),
});

export default connect(mapStateToProps)(UserSettingsPage);
