import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import { takeLatest, call, put } from 'redux-saga/effects';
import { base64Decode } from 'smu-utils/base64';

import { getUser } from 'betterme-components/api';
import { GET_USER } from './actionTypes';
import {
  getUserFail,
  getUserSuccess,
} from './actions';

function* getUserWorker({ payload: { identification } }) {
  try {
    const response = yield call(getUser, base64Decode(identification));
    yield put(getUserSuccess(response));
  } catch(error) {
    yield put(getUserFail(error.message));
    yield put(addToastMessage({ message: error.message, timeout: 3000 }));
  }
}

export default function* getUserWatcher() {
  yield takeLatest(GET_USER, getUserWorker);
}
