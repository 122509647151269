// @own
import {
  GET_FEEDBACK_FOR_LEADERS_SUMMARY,
  GET_FEEDBACK_FOR_LEADERS_SUMMARY_FAIL,
  GET_FEEDBACK_FOR_LEADERS_SUMMARY_SUCCESS,
  RESET_FEEDBACK_FOR_LEADERS,
} from './actionTypes';

const initialState = {
  errorSummary: '',
  loadingSummary: false,
  summary: {},
};

export default function reducer(state = initialState, { type, payload }) {
  switch(type) {
    case GET_FEEDBACK_FOR_LEADERS_SUMMARY:
      return {
        ...state,
        loadingSummary: true,
      };
    case GET_FEEDBACK_FOR_LEADERS_SUMMARY_FAIL:
      return {
        ...state,
        errorSummary: payload.error,
        loadingSummary: false,
      }
    case GET_FEEDBACK_FOR_LEADERS_SUMMARY_SUCCESS:
      return {
        ...state,
        errorSummary: '',
        loadingSummary: false,
        summary: payload.response,
      };
    case RESET_FEEDBACK_FOR_LEADERS:
      return initialState;
    
      default:
      return state;
  }
}
