// @packages
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { injectIntl } from 'react-intl';
import Segment from 'smu-ui-components/Segment';
import Tabs from 'smu-ui-components/Tabs';
import { base64Encode } from 'smu-utils/base64';

// @app
import InfiniteScrollLoad from 'common/InfiniteScrollLoad';
import { selectConfigurations, selectUser } from '../Authorization/selectors';

// @own
import './styles.scss';
import messages from './messages';
import Item from './Item';
import FeedEmptyState from './FeedEmptyState';
import { RECEIVED, SENT } from './constants';
import {
  trackFeedbackReceivedTabClick,
  trackFeedbackSendTabClick,
} from './analytics';

class Feed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      filter: RECEIVED,
      loadMoreFeedbacks: false,
    };

    this.handleGetMoreFeeds = this.handleGetMoreFeeds.bind(this);
    this.handleMessagesCounterClick = this.handleMessagesCounterClick.bind(this);
    this.handlerChangeTab = this.handlerChangeTab.bind(this);
  }

  componentDidMount() {
    const {
      activeTab,
      from,
      getFeed,
      ordering,
      to,
      user: { id },
    } = this.props;
    const creationTimeFrom = from ? from.valueOf() : undefined;
    const creationTimeTo = to ? to.valueOf() : undefined;
    const tabs = { RECEIVED: 0, SENT: 1 };

    getFeed({
      action: activeTab || RECEIVED,
      userId: id,
      creationTimeFrom,
      creationTimeTo,
      ordering: ordering ? ordering.toUpperCase() : undefined,
    });

    this.setState({ activeTab: tabs[activeTab] || 0 });
    trackFeedbackReceivedTabClick();
  }

  componentDidUpdate(prevProps) {
    const {
      assignee: prevAssignee,
      from: prevFrom,
      ordering: prevOrdering,
      to: PrevTo,
      user: { id: prevId },
    } = prevProps;
    const {
      assignee: currentAssignee,
      from: currentFrom,
      getFeed,
      ordering: currentOrdering,
      to: currentTo,
      user: { id: currentId },
    } = this.props;

    const { filter } = this.state;
    const prevAssigneeId = prevAssignee && prevAssignee.id;
    const currentAssigneeId = currentAssignee && currentAssignee.id;
    const hasDateChanged = (prevFrom !== currentFrom) || (PrevTo !== currentTo);
    const hasAssigneeChanged = prevAssigneeId !== currentAssigneeId;
    const hasOrderingChanged = prevOrdering !== currentOrdering;

    if ((prevId !== currentId) || hasDateChanged || hasAssigneeChanged || hasOrderingChanged) {
      const creationTimeFrom = currentFrom ? currentFrom.valueOf() : undefined;
      const creationTimeTo = currentTo ? currentTo.valueOf() : undefined;

      this.setState({ loadMoreFeedbacks: false }, () => {
        getFeed({
          action: filter,
          creationTimeFrom,
          creationTimeTo,
          ordering: currentOrdering ? currentOrdering.toUpperCase() : undefined,
          recipientId: currentAssigneeId,
          userId: currentId,
        });
      });
    }
  }

  handleGetMoreFeeds() {
    const {
      assignee: currentAssignee,
      feed: { page: { number } },
      from,
      getFeed,
      ordering,
      user: { id },
      to,
    } = this.props;
    const { filter } = this.state;
    const creationTimeFrom = from ? from.valueOf() : undefined;
    const creationTimeTo = to ? to.valueOf() : undefined;

    this.setState({
      loadMoreFeedbacks: true,
    }, () => {
      getFeed({
        action: filter,
        creationTimeFrom,
        creationTimeTo,
        pageNumber: number + 1,
        userId: id,
        ordering: ordering ? ordering.toUpperCase() : undefined,
        recipientId: currentAssignee?.id,
      });
    });
  }

  handleMessagesCounterClick(feedbackId) {
    const { getComments } = this.props;
    getComments(feedbackId);
  }

  handlerChangeTab(tab) {
    const {
      from,
      getFeed,
      ordering,
      user: { id },
      to,
    } = this.props;
    const feedType = this.getFeedType(tab);
    const trackTabChange = (feedType === RECEIVED)
      ? trackFeedbackReceivedTabClick
      : trackFeedbackSendTabClick;

    const creationTimeFrom = from ? from.valueOf() : undefined;
    const creationTimeTo = to ? to.valueOf() : undefined;

    this.setState({
      activeTab: tab,
      filter: feedType,
      loadMoreFeedbacks: false,
    }, () => {
      getFeed({
        action: feedType,
        creationTimeFrom,
        creationTimeTo,
        ordering: ordering ? ordering.toUpperCase() : undefined,
        userId: id,
      });
      trackTabChange();
    });
  }

  getFeedType(activeTab) {
    switch (activeTab) {
      case 0:
        return RECEIVED;
      case 1:
        return SENT;
      default:
        return '';
    }
  }

  getUserName(user) {
    const {
      intl: { formatMessage },
      selfUser,
    } = this.props;

    const userName = selfUser.id === user.id
      ? formatMessage(messages.FeedbackFeedMenuItemMe)
      : `${user.firstName} ${user.lastName}`;

    return userName;
  }

  renderItem(feedback, typeOf) {
    const {
      allowsRequestedOnBehalfVisibility,
      goToProfile,
      isTeamDashboard,
      newStyle,
      selfUser,
      sendComment,
    } = this.props;
    const requestedByUserName = (allowsRequestedOnBehalfVisibility && !!feedback?.requestedBy)
      ? this.getUserName(feedback?.requestedBy)
      : undefined;

    const itemProps = {
      ...feedback,
      isTeamDashboard,
      key: feedback.id,
      newStyle,
      onClickUserInfo: () => goToProfile(feedback?.sender?.identification),
      onMessagesCounterClick: this.handleMessagesCounterClick,
      requestedBy: requestedByUserName,
      selfUser,
      sendComment,
      typeOf,
    };

    return <Item {...itemProps} />;
  }

  render() {
    const {
      feed: {
        feedbacks,
        fetching,
        page: { number, totalPages },
      },
      intl: { formatMessage },
      isTeamDashboard,
      newStyle,
      user: { firstName },
      view,
    } = this.props;
    const {
      activeTab,
      filter,
      loadMoreFeedbacks,
    } = this.state;
    const hasFeedbacks = feedbacks.length > 0;
    const hasMoreFeedbacks = (number + 1) < totalPages;
    const loading = fetching && !loadMoreFeedbacks;
    const tabs = [
      {
        name: formatMessage(messages.FeedbackFeedMenuItemReceived),
        onClick: () => this.handlerChangeTab(0),
      },
      {
        name: formatMessage(messages.FeedbackFeedMenuItemSent),
        onClick: () => this.handlerChangeTab(1)
      }
    ];

    return (
      <div className="feed">
        {!isTeamDashboard && (
          <Tabs
            active={activeTab}
            className="feed__tabs"
            tabs={tabs}
          />
        )}
        <div className="feed__timeline">
          <Segment
            className={cn({ 'rounded-lg overflow-hidden': newStyle && !loading })}
            loading={loading}
            loadingType="betterme"
            withChildren={!loading}
          >
            {hasFeedbacks && !loading ? (
              <InfiniteScrollLoad
                dataLength={feedbacks.length}
                hasMore={hasMoreFeedbacks}
                loadingType="betterme"
                next={this.handleGetMoreFeeds}
                scrollableTarget="root"
              >
                {feedbacks.map(feedback => this.renderItem(feedback, filter))}
              </InfiniteScrollLoad>
            ) : (
              <FeedEmptyState
                type={filter}
                userName={firstName}
                view={view}
              />
            )}
          </Segment>
        </div>
      </div>
    );
  }
}

Feed.propTypes = {
  allowsRequestedOnBehalfVisibility: PropTypes.bool.isRequired,
  feed: PropTypes.shape({
    feedbacks: PropTypes.array,
    fetching: PropTypes.bool,
  }),
  from: PropTypes.object,
  getComments: PropTypes.func,
  getFeed: PropTypes.func.isRequired,
  isTeamDashboard: PropTypes.bool,
  newStyle: PropTypes.bool,
  sendComment: PropTypes.func,
  to: PropTypes.object,
  user: PropTypes.object.isRequired,
  view: PropTypes.oneOf(['team', 'own', 'profile']),
};

const mapStateToProps = (state) => ({
  allowsRequestedOnBehalfVisibility: selectConfigurations('allowsRequestedOnBehalfVisibility')(state),
  selfUser: selectUser(state),
});

const mapDispatchToProps = {
  goToSendFeedback: () => push('/newfeedbacksend'),
  goToRequestFeedback: () => push('/newfeedbackrequest'),
  goToProfile: identification => push(`/profile/${base64Encode(identification)}`),
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Feed));
