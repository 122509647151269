import {
  GET_AWAITING_RESULT_COUNTER,
  GET_AWAITING_RESULT_COUNTER_FAIL,
  GET_AWAITING_RESULT_COUNTER_SUCCESS,
} from './actionTypes';

export function getAwatingResultCounter(params) {
  return {
    type: GET_AWAITING_RESULT_COUNTER,
    payload: { params },
  };
}

export function getAwatingResultCounterFail(error) {
  return {
    type: GET_AWAITING_RESULT_COUNTER_FAIL,
    payload: { error },
  }
}

export function getAwatingResultCounterSuccess(response) {
  return {
    type: GET_AWAITING_RESULT_COUNTER_SUCCESS,
    payload: { response },
  }
}
