import { get } from 'lodash';
import { takeEvery, takeLatest, call, put } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import {
  GET_FEEDBACKS_SUMMARY_TEAM,
  GET_STARMEUP_KUDOS_INFO,
  GET_TEAM_EVALUATIONS,
  GET_TEAM_MEMBER_FEEDBACK_RECEIVED,
  GET_TEAM_MEMBER_FEEDBACKS_SUMMARY,
  GET_TEAM_MEMBER_OPPORTUNITIES,
  GET_TEAM_MEMBER_STARMEUP_STARS_SUMMARY,
  GET_TEAM_MEMBER_STRENGHTS,
  GET_TEAM_MEMBER_SUMMARY_GOALS,
  GET_TEAM_OPPORTUNITIES,
  GET_TEAM_STRENGHTS,
} from './actionTypes';
import {
  getDashboardFeedback,
  getDashboardTopicsOpportunities,
  getDashboardTopicsStrengths,
  getFeedbacksSummary,
  getFeedbacksSummaryTeam,
  getKudosInformation,
  getStarsSummary,
  getSummaryGoals,
  getTeamDashboardTopicsOpportunities,
  getTeamDashboardTopicsStrengths,
} from '../api';
import { getMyTeamEvaluationsCount } from 'betterme-components/services/MyTeamEvaluations/api'
import {
  getFeedbacksSummaryTeamFail,
  getFeedbacksSummaryTeamSuccess,
  getStarmeupKudosInfoFail,
  getStarmeupKudosInfoSuccess,
  getTeamEvaluationsFail,
  getTeamEvaluationsSuccess,
  getTeamMemberFeedbackReceivedFail,
  getTeamMemberFeedbackReceivedSuccess,
  getTeamMemberFeedbacksSummaryFail,
  getTeamMemberFeedbacksSummarySuccess,
  getTeamMemberOpportunitiesFail,
  getTeamMemberOpportunitiesSuccess,
  getTeamMemberStarmeupSummaryFail,
  getTeamMemberStarmeupSummarySuccess,
  getTeamMemberStrenghtsFail,
  getTeamMemberStrenghtsSuccess,
  getTeamMemberSummaryGoalsFail,
  getTeamMemberSummaryGoalsSuccess,
  getTeamOpportunitiesFail,
  getTeamOpportunitiesSuccess,
  getTeamStrengthsFail,
  getTeamStrengthsSuccess,
} from './actions';

function* getStarmeupStarsSummaryWorker({ payload }) {
  const { userId } = payload;
  try {
    const response = yield call(getStarsSummary, payload);
    const {
      coreValuesSummary,
      historicalReceived,
      percentageKudos,
      totalCoreValues,
      totalKudos,
      userName,
    } = response.result;

    const kudoStats = {
      percentageKudos,
      totalKudos,
      userName,
    };

    const totalStars = {
      current: totalKudos + totalCoreValues,
      historic: historicalReceived,
    };

    const partialSummary = coreValuesSummary.map(value => ({
      backgroundColor: value.star.backgroundColor,
      id: value.id,
      imageCode: value.imageCode,
      name: value.name,
      percentageStars: value.percentageStars,
      sender: value.userName,
      totalStars: value.totalStars,
    }));
    yield put(getTeamMemberStarmeupSummarySuccess(userId, kudoStats, partialSummary, totalStars));
  } catch (error) {
    const { userId } = payload;
    yield put(getTeamMemberStarmeupSummaryFail(error, userId));
  }
}

function* getFeedbacksSummaryTeamWorker({ payload }) {
  try {
    const response = yield call(getFeedbacksSummaryTeam, payload);
    yield put(getFeedbacksSummaryTeamSuccess(response));
  } catch (error) {
    yield put(getFeedbacksSummaryTeamFail(error));
    yield put(addToastMessage({ message: error.message, timeout: 3000 }));
  }
}

function* getStarmeupKudosInfoWorker({ payload }) {
  try {
    const response = yield call(getKudosInformation, payload);
    const valueKudos = get(response.result, '0', {});
    yield put(getStarmeupKudosInfoSuccess(valueKudos));
  } catch (error) {
    yield put(getStarmeupKudosInfoFail(error));
  }
}

function* getTeamMemberFeedbackReceivedWorker({ payload }) {
  const { userId } = payload;
  try {
    const response = yield call(getDashboardFeedback, payload);
    yield put(getTeamMemberFeedbackReceivedSuccess(userId, response));
  } catch (error) {
    const { userId } = payload;
    yield put(getTeamMemberFeedbackReceivedFail(error, userId));
    if (error.status && error.status !== 400) {
      yield put(addToastMessage({ message: error.message, timeout: 3000 }));
    }
  }
}

function* getTeamMemberOpportunitiesWorker({ payload }) {
  const { userId } = payload;
  try {
    const response = yield call(getDashboardTopicsOpportunities, payload);
    yield put(getTeamMemberOpportunitiesSuccess(userId, response));
  } catch (error) {
    const { userId } = payload;
    yield put(getTeamMemberOpportunitiesFail(error, userId));
    if (error.status && error.status !== 400) {
      yield put(addToastMessage({ message: error.message, timeout: 3000 }));
    }
  }
}

function* getTeamMemberStrenghtsWorker({ payload }) {
  const { userId } = payload;
  try {
    const response = yield call(getDashboardTopicsStrengths, payload);
    yield put(getTeamMemberStrenghtsSuccess(userId, response));
  } catch (error) {
    const { userId } = payload;
    yield put(getTeamMemberStrenghtsFail(error, userId));
    if (error.status && error.status !== 400) {
      yield put(addToastMessage({ message: error.message, timeout: 3000 }));
    }
  }
}

function* getTeamOpportunitiesWorker({ payload }) {
  try {
    const response = yield call(getTeamDashboardTopicsOpportunities, payload);
    yield put(getTeamOpportunitiesSuccess(response));
  } catch (error) {
    yield put(getTeamOpportunitiesFail(error));
  }
}

function* getTeamStrengthsWorker({ payload }) {
  try {
    const response = yield call(getTeamDashboardTopicsStrengths, payload);
    yield put(getTeamStrengthsSuccess(response));
  } catch (error) {
    yield put(getTeamStrengthsFail(error));
  }
}

function* getTeamEvaluationsWorker() {
  try {
    const response = yield call(getMyTeamEvaluationsCount);
    yield put(getTeamEvaluationsSuccess(response));
  } catch (error) {
    yield put(getTeamEvaluationsFail(error));
  }
}

function* getTeamMemberSummaryGoalsWorker({ payload }) {
  const { userId } = payload;

  try {
    const response = yield call(getSummaryGoals, payload);
    yield put(getTeamMemberSummaryGoalsSuccess(userId, response));
  } catch (error) {
    const { userId } = payload;
    yield put(getTeamMemberSummaryGoalsFail(error, userId));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

function* getTeamMemberFeedbacksSummaryWorker({ payload }) {
  const { userId } = payload;

  try {
    const response = yield call(getFeedbacksSummary, payload);
    yield put(getTeamMemberFeedbacksSummarySuccess(userId, response));
  } catch (error) {
    yield put(getTeamMemberFeedbacksSummaryFail(error, userId));
    if (error.status && error.status !== 400) {
      yield put(addToastMessage({ message: error.message, timeout: 3000 }));
    }
  }
}

export default function* teamDashboardWatcher() {
  yield takeEvery(GET_TEAM_MEMBER_STARMEUP_STARS_SUMMARY, getStarmeupStarsSummaryWorker);
  yield takeEvery(GET_TEAM_MEMBER_FEEDBACKS_SUMMARY, getTeamMemberFeedbacksSummaryWorker);
  yield takeEvery(GET_TEAM_MEMBER_FEEDBACK_RECEIVED, getTeamMemberFeedbackReceivedWorker);
  yield takeEvery(GET_TEAM_MEMBER_OPPORTUNITIES, getTeamMemberOpportunitiesWorker);
  yield takeEvery(GET_TEAM_MEMBER_STRENGHTS, getTeamMemberStrenghtsWorker);
  yield takeEvery(GET_TEAM_MEMBER_SUMMARY_GOALS, getTeamMemberSummaryGoalsWorker);
  yield takeLatest(GET_FEEDBACKS_SUMMARY_TEAM, getFeedbacksSummaryTeamWorker);
  yield takeLatest(GET_STARMEUP_KUDOS_INFO, getStarmeupKudosInfoWorker);
  yield takeLatest(GET_TEAM_OPPORTUNITIES, getTeamOpportunitiesWorker);
  yield takeLatest(GET_TEAM_STRENGHTS, getTeamStrengthsWorker);
  yield takeLatest(GET_TEAM_EVALUATIONS, getTeamEvaluationsWorker);
}
