import { notificationsApiInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';
import { isOtherNotification } from 'utils/checkNotificationsType';
import photoSmu from 'betterme-components/assets/img/photo-smu.png';
import { stringify } from 'querystring';
import { addParams, times, vo, formData } from '../NotificationsV2/api';

const transformFromUserToFrom = notifications =>
  notifications.map(item => ({
    ...item,
    fromUser: item?.from && !isOtherNotification(item?.type)
      ? item?.from
      : {
        profileImageCode: photoSmu,
        firstName: '',
        middleName: '',
        lastName: '',
      },
    notificationText: item?.notification || item?.title,
  }));

export function getNotifications({ page }) {
  const endpointUrl = '/api/v3/notifications';
  return notificationsApiInstance({
    url: addParams(endpointUrl, { page }),
    method: 'get',
  })
    .then(({ data }) => {
      const dataMapped = transformFromUserToFrom(data.result);
      return dataMapped;
    })
    .catch(errorHandler);
}

export function markAllNotificationsAsReadAPI() {
  return notificationsApiInstance({
    url: '/api/v3/notifications/reads',
    method: 'post',
  })
    .then(({ data }) => data)
    .catch(errorHandler);
}

export function setReadNotificationHistoryAPI(id) {
  const { timestamp } = times();
  notificationsApiInstance({
    url: addParams(`api/v3/notifications/reads/${id}`, { ...vo, ...times() }),
    ...formData,
    data: stringify({ timestamp, ...vo }),
  })
    .catch(errorHandler);
}
