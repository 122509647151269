// @app
import { apiInstance } from 'utils/requestHelper';
import { handleServerError } from 'betterme-components/api';

export function getUsersList(query, pageSize = 20) {
  const params = {
    isWhitelisted: true,
    pageNumber: 0,
    pageSize,
    query,
  };

  return apiInstance.get(`api/users`, { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}
