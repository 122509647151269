// @packages
import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

// @own
import './styles.scss';

const CardHeader = ({
  children,
  className,
}) => {
  return (
    <div className={cn('card-header', className)}>
      {children}
    </div>
  );
};

CardHeader.defaultProps = {
  className: '',
};

CardHeader.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default CardHeader;
