// @packages
import React from 'react';
import Image from 'smu-ui-components/Image';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @own
import './styles.scss';

function EmptyState({
  className,
  description,
  direction,
  image,
  title,
}) {
  return (
    <div className={cn("suite-empty-state", className, {
      [`suite-empty-state--${direction}`]: direction,
    })}>
      <div className="suite-empty-state__image-wrapper">
        <Image className="suite-empty-state__image" imageUrl={image} />
      </div>
      <div className="suite-empty-state__info">
        <div className="suite-empty-state__info-title">
          {title}
        </div>
        <div className="suite-empty-state__info-description">
          {description}
        </div>
      </div>
    </div>
  )
};

EmptyState.propTypes = {
  description: PropTypes.string,
  direction: PropTypes.oneOf(["row", "column"]),
  image: PropTypes.string,
  title: PropTypes.string,
};

export default EmptyState;