// @packages
import React, { useEffect, useState, useMemo } from 'react';
import Button from 'smu-ui-components/Button';
import DashboardDNA from 'smu-app-components/PerformanceDashboard/components/DnaWidget'
import DashboardFeedbackForLeaders from 'smu-app-components/PerformanceDashboard/components/FeedbackForLeadersWidget'
import DashboardGoalsV2 from 'smu-app-components/PerformanceDashboard/components/GoalsWidget'
import DashboardGrowthAndReadiness from 'smu-app-components/PerformanceDashboard/components/GrowthAndReadiness'
import DashboardItem from 'smu-layout-components/DashboardItem';
import DashboardLayout from 'smu-layout-components/Dashboard';
import DashboardPerformance from 'smu-app-components/PerformanceDashboard/components/PerformanceChartWidget'
import DashboardPotential from 'smu-app-components/PerformanceDashboard/components/PotentialAssessmentWidget'
import GenericEmptyState from 'smu-ui-components/GenericEmptyState';
import Icon from 'smu-ui-components/Icon';
import PropTypes from 'prop-types';
import StarmeUpWidgetLogo from 'smu-app-components/StarmeUpWidgetLogo';
import Tabs from 'smu-ui-components/Tabs';
import cn from 'classnames';
import moment from 'moment';
import { connect } from 'react-redux';
import { get, chunk, pick } from 'lodash';
import { injectIntl } from 'react-intl';
import { openModal } from 'smu-app-components/RootModal/actions';
import InsightWidget from 'smu-app-components/PerformanceDashboard/components/InsightWidget';
// @app
import CardSimple from 'common/CardSimple';
import PeriodDateFilter from 'common/PeriodDateFilter';
import StarmeUpDNAWidget from 'betterme-components/components/containers/StarmeUpDNAWidget';
import { getCommunityConfigValue } from 'betterme-components/services/CommunityConfigs/selectors';
import {
  BME_GLOBER_PROFILE_PERFORMANCE_TYPES
} from 'betterme-components/services/CommunityConfigs/constants';
import { formatPeriodDates } from 'utils/formatPeriodDates';
import { getLastEvaluation, resetLastEvaluation } from 'betterme-components/Evaluations/actions';
import { getSkillsRated, sortSkillsRated } from 'common/SkillsRated/actions';
import { getTopicsRated, sortTopicsRated } from 'common/TopicsRated/actions';
import { selectRatedSkills, selectSkillsRatedLoading } from 'common/SkillsRated/selectors';
import { selectRatings } from 'betterme-components/services/Layout/selectors';
import {
  selectLastEvaluationRounded,
  selectLoadingLastEvaluation,
} from 'betterme-components/Evaluations/selectors';
import {
  selectCommunityId,
  selectConfigurations,
  selectEvaluationPeriod,
} from 'betterme-components/Authorization/selectors';
import { getFeedbackForLeadersEvaluationDetailed } from 'betterme-components/routes/urls'
import {
  selectRatedTopics,
  selectTopicsRatedLoading,
  selectTopicsRatedOrder,
  selectCommunityRatings,
} from 'common/TopicsRated/selectors';
import {
  actionsMessages,
  commonMessages,
  feedbackMessages,
  placeholdersMessages,
} from 'common/messages';

// @own
import './styles.scss';
import * as actions from './actions';
import * as dashboardSuggestionsActions from './DashboardSuggestions/actions';
import DashboardFeedbackReceived from './DashboardFeedbackReceived';
import DashboardFeedbackSummary from './DashboardFeedbackSummary';
import DashboardGoals from './DashboardGoals';
import DashboardLastEvaluation from './DashboardLastEvaluation';
import DashboardOpportunity from './DashboardOpportunity';
import DashboardStrengths from './DashboardStrengths';
import DashboardSuggestions from './DashboardSuggestions';
import StackedBarChart from './StackedBarChart';
import messages from './messages';
import { COMPETENCIES_TAB, SKILLS_TAB, TABS_MAP } from './constants';
import {
  selectLoadingDashboardSuggestions,
  selectSuggestedActions,
} from './DashboardSuggestions/selectors';
import {
  trackBoxStackedBarRequestFeedbackEmpty,
  trackDateFilterCalendar,
  trackDateFilterDropdown,
  trackPieChartRequestFeedbackEmpty,
} from './analytics';
import {
  ASSESSMENT_360,
  COMPETENCIES_AND_SKILLS,
  DEFAULT_WIDGETS_ORDER,
  DNA,
  FEEDBACK,
  FEEDBACK_FOR_LEADERS,
  FEEDBACK_V2,
  GOALS,
  GOALS_V2,
  GROWTH_AND_READINESS,
  INSIGHTS,
  LAST_EVALUATION,
  PERFORMANCE,
  PERIOD_DATE_FILTER,
  POTENTIAL,
  STRENGTHS_AND_OPPORTUNITIES,
  SUGGESTIONS,
  VALUES,
} from './constants';
import {
  selectDashboardFeedback,
  selectDashboardFeedbackSummary,
  selectFeedbackForLeaders,
  selectLoading,
  selectLoadingDashboardPerformanceChart,
  selectLoadingFeedbackForLeaders,
  selectLoadingFeedbackSummary,
  selectLoadingOpportunities,
  selectLoadingStrengths,
  selectOpportunities,
  selectOpportunitiesRating,
  selectSelectedCompetencyDetails,
  selectSelectedUser,
  selectStrengths,
  selectStrengthsRating,
} from './selectors';


const Dashboard = ({
  activeTab,
  activeTabsOnDashboard,
  allowsDashboardCompetenciesAndSkills,
  allowsDashboardFeedbackForLeadersChart,
  allowsDashboardFeedbackSummary,
  allowsDashboardGoals,
  allowsDashboardGrowthAndReadinessChart,
  allowsDashboardPerformanceChart,
  allowsDashboardStrengthsAndOpportunities,
  allowsEvaluations,
  allowsGloberProfileInsightsVisibility,
  allowsFeedbackForLeadersResult,
  allowsFeedbackRequest,
  allowsGoals,
  allowsPotentialAssessment,
  allowsSmuDna,
  className,
  communityRatings,
  dashboardFeedback,
  dashboardFeedbackSummary,
  dashboardSuggestions,
  defaultPeriod,
  detailsPath: getDetailsPath,
  evaluationType,
  feedbackReceivedLink,
  getDashboardFeedback,
  getDashboardFeedbackForLeadersResults,
  getDashboardFeedbackSummary,
  getDashboardPerformanceChart,
  getDashboardSuggestions,
  getDashboardTopicsOpportunities,
  getDashboardTopicsStrengths,
  getLastEvaluation,
  getSkillsRated,
  getTopicsRated,
  goToFeedback,
  intl: { formatMessage },
  isMyCollaborator,
  lastEvaluation,
  listOrder,
  loading,
  loadingFeedbackSummary,
  loadingLastEvaluation,
  loadingOpportunities,
  loadingStrengths,
  location: { state },
  messagesEmptyStates,
  noRequestFeedback,
  opportunities,
  opportunitiesBoxEmpty,
  opportunitiesLink,
  opportunitiesRating,
  ratedSkills,
  ratedTopics,
  resetDashboardData,
  showPotentialAssessmentResults,
  showSuggestions,
  skillsRatedLoading,
  sortSkillsRated,
  sortTopicsRated,
  strengthBoxEmpty,
  strengths,
  strengthsRating,
  topicsRatedLoading,
  user,
  widgetsOrder,
}) => {
  const [activeItem, setActiveItem] = useState(TABS_MAP[COMPETENCIES_TAB]);
  const [dropdownLabel, setDropdownLabel] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [feedbackActiveTab, setFeedbackActiveTab] = useState(COMPETENCIES_TAB);
  const [initialFiltersAssigned, setInitialFiltersAssigned] = useState(false);
  const [ratedCompetenciesCurrentPage, setRatedCompetenciesCurrentPage] = useState(0);
  const [ratedSkillsCategoriesPages, setRatedSkillsCategoriesPages] = useState([]);
  const [ratedSkillsCurrentPage, setRatedSkillsCurrentPage] = useState(0);
  const [ratedSkillsPaginate, setRatedSkillsPaginate] = useState([]);
  const [ratedSkillsStackedPages, setRatedSkillsStackedPages] = useState([]);
  const [ratedTopicsCategoriesPages, setRatedTopicsCategoriesPages] = useState([]);
  const [ratedTopicsPaginate, setRatedTopicsPaginate] = useState([]);
  const [ratedTopicsStackedPages, setRatedTopicsStackedPages] = useState([]);
  const [startDate, setStartDate] = useState(null);

  const from = startDate ? startDate.valueOf() : undefined;
  const to = endDate ? endDate.valueOf() : undefined;
  const isSkill = feedbackActiveTab === SKILLS_TAB;
  const isSortAsc = listOrder === 'asc';
  const detailsPath = getDetailsPath({ endDate: endDate, startDate: startDate });
  const starmeUpDNASubtitle = dropdownLabel !== undefined
    ? dropdownLabel
    : formatPeriodDates(startDate, endDate);
  const {
    access: {
      evaluations: accessEvaluations,
      feedback: accessFeedback,
      feedbackForLeadersReceived: accessFeedbackForLeadersReceived,
      goals: accessGoals,
      potentialAssessmentResult: accessPotentialAssessmentResult,
    },
    hasCollaborators: userHasCollaborator,
    firstName,
    id: userId,
  } = user;
  const configCompetenciesStacked = {
    options: {
      chart: { type: 'column', height: 359 },
      title: { text: '' },
      xAxis: {
        categories: ratedTopicsCategoriesPages[ratedCompetenciesCurrentPage],
        labels: { autoRotation: false }
      },
      yAxis: {
        title: { text: '' },
        labels: { format: '{value} %' },
        tickInterval: 25,
      },
      tooltip: {
        backgroundColor: '#000',
        borderWidth: 0,
        shadow: false,
        style: { 'color': '#fff' },
        formatter: function () {
          return `<b>${this.y} / ${this.point.stackTotal}</b>`;
        },
        positioner: function (labelWidth, labelHeight, point) {
          return { x: point.plotX + 35, y: point.plotY + (point.h / 2) };
        },
        shape: 'rect',
      },
      plotOptions: {
        column: { stacking: 'percent', pointWidth: 18 }
      },
    },
  };
  const configSkillsStacked = {
    options: {
      chart: { type: 'column', height: 359 },
      title: { text: '' },
      xAxis: {
        categories: ratedSkillsCategoriesPages[ratedSkillsCurrentPage],
        labels: { autoRotation: false }
      },
      yAxis: {
        title: { text: '' },
        labels: { format: '{value} %' },
        tickInterval: 25,
      },
      tooltip: {
        backgroundColor: '#000',
        borderWidth: 0,
        shadow: false,
        style: { 'color': '#fff' },
        formatter: function () {
          return `<b>${this.y} / ${this.point.stackTotal}</b>`;
        },
        positioner: function (labelWidth, labelHeight, point) {
          return { x: point.plotX + 35, y: point.plotY + (point.h / 2) };
        },
        shape: 'rect',
      },
      plotOptions: {
        column: { stacking: 'percent', pointWidth: 18 }
      },
    },
  };
  const dataCompetenciesStacked = {
    series: ratedTopicsCategoriesPages.length > 0
      ? ratedTopicsStackedPages[ratedCompetenciesCurrentPage]
      : [],
  };
  const dataSkillsStacked = {
    series: ratedSkillsCategoriesPages.length > 0
      ? ratedSkillsStackedPages[ratedSkillsCurrentPage]
      : [],
  };
  const periodDateFilterTitlte = formatMessage(
    messages.FeedbackDashboardDateFilterTitle,
    { username: firstName },
  );
  const tabs = [
    {
      disabled: false,
      discovery: {
        noCurtain: true,
        open: false,
        position: 'bottom',
        text: formatMessage(feedbackMessages.FeedbackCompetenciesComparisonTab),
      },
      name: formatMessage(feedbackMessages.FeedbackCompetenciesComparisonTab),
      onClick: () => {
        setActiveItem(TABS_MAP[COMPETENCIES_TAB]);
        setFeedbackActiveTab(COMPETENCIES_TAB);
      },
    },
    {
      disabled: false,
      discovery: {
        noCurtain: true,
        open: false,
        position: 'bottom',
        text: formatMessage(feedbackMessages.FeedbackSkillsComparisonTab),
      },
      name: formatMessage(feedbackMessages.FeedbackSkillsComparisonTab),
      onClick: () => {
        setActiveItem(TABS_MAP[SKILLS_TAB]);
        setFeedbackActiveTab(SKILLS_TAB);
      },
    },
  ];

  const areWidgetsLoading = loading
    || loadingFeedbackSummary
    || loadingLastEvaluation
    || loadingOpportunities
    || loadingStrengths
    || skillsRatedLoading
    || topicsRatedLoading;

  const getInitialFilterDate = () => {
    let initialFilters;

    if (state && (state.initialEndDate || state.initialStartDate)) {
      const { initialEndDate, initialStartDate } = state;
      initialFilters = {
        endDate: initialEndDate ? moment(initialEndDate) : undefined,
        startDate: initialStartDate ? moment(initialStartDate) : undefined,
      };
    }
    else if (defaultPeriod) {
      const { endTime, startTime } = defaultPeriod;
      initialFilters = {
        endDate: moment.utc(endTime).endOf('day'),
        startDate: moment.utc(startTime).startOf('day'),
        dropdownLabel: formatMessage(placeholdersMessages.PlaceholdersCurrentPeriod),
      };
    } else {
      initialFilters = {
        endDate: moment(),
        startDate: moment().subtract(6, 'months'),
        dropdownLabel: formatMessage(placeholdersMessages.PlaceholdersSixMonths),
      };
    }
    setStartDate(initialFilters.startDate);
    setEndDate(initialFilters.endDate);
    setDropdownLabel(initialFilters.dropdownLabel);
    setInitialFiltersAssigned(true);

    return initialFilters;
  };

  const handleChangePage = (page, type) => {
    type === 'compentencies' && setRatedCompetenciesCurrentPage(page);
    type === 'skills' && setRatedSkillsCurrentPage(page);
  };

  const ratedCompetenciesHandler = () => {
    const ratedTopicsPages = chunk(ratedTopics, 9);
    const ratedTopicsCategoriesPages = ratedTopicsPages.map(ratedTopics =>
      ratedTopics.map(ratedTopic => ratedTopic.name)
    );
    const ratedTopicsStackedPages = ratedTopicsPages.map(ratedTopics => {
      return communityRatings.map(rating => {
        const ratingInfo = pick(rating, ['color', 'id', 'name']);
        const data = ratedTopics.map(ratedTopic => {
          const ratings = ratedTopic.ratings.find(({ id }) => id === rating.id);
          const count = get(ratings, 'count', 0);
          return count;
        });
        return { ...ratingInfo, data, showInLegend: false };
      });
    });
    const ratedTopicsPaginate = ratedTopicsPages.map((page, index) => ({
      number: index,
      totalElements: page.length,
    }));
    setRatedTopicsCategoriesPages(ratedTopicsCategoriesPages);
    setRatedTopicsPaginate(ratedTopicsPaginate);
    setRatedTopicsStackedPages(ratedTopicsStackedPages);
  };

  const ratedSkillsHandler = () => {
    const ratedSkillsPages = chunk(ratedSkills, 9);
    const ratedSkillsCategoriesPages = ratedSkillsPages.map(ratedSkills =>
      ratedSkills.map(ratedSkill => ratedSkill.name)
    );
    const ratedSkillsStackedPages = ratedSkillsPages.map(ratedSkills => {
      return communityRatings.map(rating => {
        const ratingInfo = pick(rating, ['color', 'id', 'name']);
        const data = ratedSkills.map(ratedSkill => {
          const ratings = ratedSkill.ratings.find(({ id }) => id === rating.id);
          const count = get(ratings, 'count', 0);
          return count;
        });
        return { ...ratingInfo, data, showInLegend: false };
      });
    });
    const ratedSkillsPaginate = ratedSkillsPages.map((page, index) => ({
      number: index,
      totalElements: page.length,
    }));

    setRatedSkillsCategoriesPages(ratedSkillsCategoriesPages);
    setRatedSkillsPaginate(ratedSkillsPaginate);
    setRatedSkillsStackedPages(ratedSkillsStackedPages);
  };

  const onOrderClick = () => {
    sortTopicsRated();
    sortSkillsRated();
  };

  const assignedTabForCollabProfile = (tab) => {
    return activeTabsOnDashboard ? tab === activeTab : true;
  };

  useEffect(() => {
    getInitialFilterDate();
  }, []);

  useEffect(() => {
    if (accessFeedback && initialFiltersAssigned) {
      allowsDashboardCompetenciesAndSkills && getDashboardFeedback(user.id, from, to, isSkill);
    }
  }, [userId, startDate, endDate, initialFiltersAssigned, feedbackActiveTab]);

  useEffect(() => {
    if (accessFeedback && initialFiltersAssigned) {
      if (allowsDashboardCompetenciesAndSkills) {
        setRatedSkillsCurrentPage(0);
        setRatedCompetenciesCurrentPage(0);

        const ratedParams = {
          creationTimeFrom: from,
          creationTimeTo: to,
          ordering: listOrder.toUpperCase(),
          showFeedback: false,
        };

        getSkillsRated(user.id, ratedParams);
        getTopicsRated(user.id, ratedParams);
      };
    };
  }, [userId, startDate, endDate, initialFiltersAssigned, listOrder]);

  useEffect(() => {
    !topicsRatedLoading && ratedCompetenciesHandler();
    !skillsRatedLoading && ratedSkillsHandler();
  }, [ratedSkills, ratedTopics, userId]);

  useEffect(() => {
    if (accessFeedback && initialFiltersAssigned) {
      allowsDashboardFeedbackSummary && getDashboardFeedbackSummary(user.id, from, to);

      if (allowsDashboardStrengthsAndOpportunities) {
        const creationTimeFrom = from;
        const creationTimeTo = to;
        getDashboardTopicsOpportunities(user.id, { creationTimeFrom, creationTimeTo });
        getDashboardTopicsStrengths(user.id, { creationTimeFrom, creationTimeTo });
      };
    };
  }, [userId, startDate, endDate, initialFiltersAssigned]);

  useEffect(() => {
    if (initialFiltersAssigned) {
      showSuggestions && getDashboardSuggestions(user.id, from, to);
    }
  }, [userId, startDate, endDate, initialFiltersAssigned]);

  useEffect(() => {
    if (allowsDashboardPerformanceChart && isMyCollaborator) {
      getDashboardPerformanceChart({ userId, evaluationType });
    }
  }, [userId]);

  useEffect(() => {
    resetDashboardData();
    resetLastEvaluation();

    if (userId) {
      allowsEvaluations && accessEvaluations && getLastEvaluation(userId);
      allowsFeedbackForLeadersResult
        && accessFeedbackForLeadersReceived
        && getDashboardFeedbackForLeadersResults({ userId });
    }
  }, [userId]);

  useEffect(() => {
    return () => {
      resetDashboardData();
      resetLastEvaluation();
    };
  }, []);


  const showWidget = {
    [DNA]:
      assignedTabForCollabProfile(0)
      && activeTabsOnDashboard
      && allowsSmuDna,
    [COMPETENCIES_AND_SKILLS]:
      assignedTabForCollabProfile(1)
      && allowsDashboardCompetenciesAndSkills,
    [FEEDBACK_FOR_LEADERS]:
      assignedTabForCollabProfile(0)
      && activeTabsOnDashboard
      && allowsDashboardFeedbackForLeadersChart
      && isMyCollaborator
      && userHasCollaborator,
    [FEEDBACK]:
      assignedTabForCollabProfile(1)
      && accessFeedback
      && allowsDashboardFeedbackSummary
      && initialFiltersAssigned,
    [GOALS]:
      assignedTabForCollabProfile(1)
      && accessGoals
      && allowsDashboardGoals
      && allowsGoals
      && initialFiltersAssigned,
    [GOALS_V2]:
      assignedTabForCollabProfile(0)
      && activeTabsOnDashboard
      && accessGoals
      && allowsDashboardGoals
      && allowsGoals
      && initialFiltersAssigned
      && isMyCollaborator,
    [GROWTH_AND_READINESS]:
      assignedTabForCollabProfile(0)
      && activeTabsOnDashboard
      && allowsDashboardGrowthAndReadinessChart
      && isMyCollaborator,
    [LAST_EVALUATION]:
      assignedTabForCollabProfile(1)
      && accessEvaluations
      && allowsEvaluations,
    [POTENTIAL]:
      assignedTabForCollabProfile(0)
      && accessPotentialAssessmentResult
      && isMyCollaborator
      && showPotentialAssessmentResults,
    [PERFORMANCE]:
      assignedTabForCollabProfile(0)
      && allowsDashboardPerformanceChart
      && isMyCollaborator
      && activeTabsOnDashboard,
    [PERIOD_DATE_FILTER]:
      assignedTabForCollabProfile(1),
    [VALUES]:
      assignedTabForCollabProfile(1)
      && allowsSmuDna,
    [STRENGTHS_AND_OPPORTUNITIES]:
      assignedTabForCollabProfile(1)
      && accessFeedback
      && allowsDashboardStrengthsAndOpportunities,
    [SUGGESTIONS]:
      assignedTabForCollabProfile(1)
      && showSuggestions,
    [INSIGHTS]:
      assignedTabForCollabProfile(0)
      && allowsGloberProfileInsightsVisibility
      && isMyCollaborator
      && activeTabsOnDashboard,
  };

  const areWidgetsAvailable = Object.values(showWidget)
    .some(widget => widget === true);

  const widgetComponents = {
    [FEEDBACK]: (
      <DashboardItem span={2}>
        <DashboardFeedbackSummary
          feedbackSummary={dashboardFeedbackSummary}
          loading={loadingFeedbackSummary}
        />
      </DashboardItem>
    ),
    [COMPETENCIES_AND_SKILLS]: (
      <DashboardItem span={2}>
        {allowsDashboardCompetenciesAndSkills && (
          <>
            {accessFeedback && (
              <div className="dashboard__tabs-header">
                <Tabs active={activeItem} tabs={tabs} />
                {onOrderClick && (
                  <div className="dashboard__tabs-buttons">
                    <Button
                      className={cn('dashboard__feedback-chart-button', {
                        'dashboard__feedback-chart-button--disabled': isSortAsc,
                      })}
                      color={isSortAsc ? "black" : null}
                      onClick={!isSortAsc ? () => onOrderClick(listOrder) : null}
                      size="narrow"
                    >
                      <Icon
                        className="rating-details__order-icon"
                        color={isSortAsc ? "white" : null}
                        icon="sort-asc"
                      />
                    </Button>
                    <Button
                      className={cn('dashboard__feedback-chart-button', {
                        'dashboard__feedback-chart-button--disabled': !isSortAsc,
                      })}
                      color={!isSortAsc ? 'black' : null}
                      onClick={isSortAsc ? () => onOrderClick(listOrder) : null}
                      size="narrow"
                    >
                      <Icon
                        className="rating-details__order-icon"
                        color={!isSortAsc ? 'white' : null}
                        icon="sort-desc"
                      />
                    </Button>
                  </div>
                )}
              </div>
            )}
            {accessFeedback && feedbackActiveTab === COMPETENCIES_TAB && (
              <div className="dashboard__feedbacks">
                <DashboardFeedbackReceived
                  dashboardFeedback={dashboardFeedback}
                  feedbackReceivedLink={feedbackReceivedLink}
                  goToFeedback={goToFeedback}
                  loading={loading}
                  messagesEmptyStates={messagesEmptyStates.feedbackReceived}
                  showRequestFeedback={allowsFeedbackRequest}
                  trackEventEmptyState={trackPieChartRequestFeedbackEmpty}
                />
                <StackedBarChart
                  activeTab={activeItem}
                  config={configCompetenciesStacked}
                  currentPage={ratedCompetenciesCurrentPage}
                  data={dataCompetenciesStacked}
                  detailsPath={detailsPath}
                  feedbackReceivedLink={feedbackReceivedLink}
                  loading={topicsRatedLoading}
                  messagesEmptyStates={messagesEmptyStates.feedbackReceived}
                  onChangePage={(page) => handleChangePage(page)}
                  ratings={dashboardFeedback.ratings}
                  showRequestFeedback={allowsFeedbackRequest}
                  totalPages={ratedTopicsPaginate}
                  trackEventEmptyState={trackBoxStackedBarRequestFeedbackEmpty}
                />
              </div>
            )}
            {accessFeedback && feedbackActiveTab === SKILLS_TAB && (
              <div className="dashboard__feedbacks">
                <DashboardFeedbackReceived
                  dashboardFeedback={dashboardFeedback}
                  feedbackReceivedLink={feedbackReceivedLink}
                  goToFeedback={goToFeedback}
                  loading={loading}
                  messagesEmptyStates={messagesEmptyStates.feedbackReceived}
                  showRequestFeedback={allowsFeedbackRequest}
                  trackEventEmptyState={trackPieChartRequestFeedbackEmpty}
                />
                <StackedBarChart
                  activeTab={activeItem}
                  config={configSkillsStacked}
                  currentPage={ratedSkillsCurrentPage}
                  data={dataSkillsStacked}
                  detailsPath={detailsPath}
                  feedbackReceivedLink={feedbackReceivedLink}
                  loading={skillsRatedLoading}
                  messagesEmptyStates={messagesEmptyStates.feedbackReceived}
                  onChangePage={(page) => handleChangePage(page)}
                  ratings={dashboardFeedback.ratings}
                  showRequestFeedback={allowsFeedbackRequest}
                  totalPages={ratedSkillsPaginate}
                  trackEventEmptyState={trackBoxStackedBarRequestFeedbackEmpty}
                />
              </div>
            )}
          </>
        )}
        {!allowsDashboardCompetenciesAndSkills && (
          <div className="bg-white h-96 mb-4">
            <GenericEmptyState
              className="my-auto h-full"
              description={formatMessage(messages.DashboardSkillsAndCompetenciesEmptyState)}
              image="clock"
              limitTextWidth={false}
              size="large"
              title={formatMessage(messages.DashboardSkillsAndCompetenciesEmptyStateTitle)}
            />
          </div>
        )}
      </DashboardItem>
    ),
    [STRENGTHS_AND_OPPORTUNITIES]: (
      <DashboardItem span={2}>
        <DashboardLayout columns={2} spacing={2}>
          <DashboardItem
            className="dashboard__competency-info-box-item"
            span={1}
          >
            <DashboardStrengths
              empty={strengthBoxEmpty}
              intl={{ formatMessage }}
              loading={loadingStrengths}
              noRequestFeedback={noRequestFeedback}
              rating={strengthsRating}
              strengths={strengths}
            />
          </DashboardItem>
          <DashboardItem
            className="dashboard__competency-info-box-item"
            span={1}
          >
            <DashboardOpportunity
              empty={opportunitiesBoxEmpty}
              intl={{ formatMessage }}
              link={opportunitiesLink}
              loading={loadingOpportunities}
              noRequestFeedback={noRequestFeedback}
              opportunities={opportunities}
              rating={opportunitiesRating}
            />
          </DashboardItem>
        </DashboardLayout>
      </DashboardItem>
    ),
    [GOALS]: (
      <DashboardItem span={1}>
        <DashboardGoals
          detailsPath={detailsPath}
          endDate={endDate}
          messagesEmptyStates={messagesEmptyStates.goalsAssigned}
          startDate={startDate}
          userId={user.id}
        />
      </DashboardItem>
    ),
    [SUGGESTIONS]: (
      <DashboardItem span={1}>
        <DashboardSuggestions
          {...dashboardSuggestions}
          endDate={endDate}
          startDate={startDate}
          user={user}
        />
      </DashboardItem>
    ),
    [LAST_EVALUATION]: (
      <DashboardItem span={1}>
        <DashboardLastEvaluation
          detailsPath={detailsPath}
          lastEvaluationDetail={lastEvaluation}
          loading={loadingLastEvaluation}
          messagesEmptyStates={messagesEmptyStates.lastEvaluation}
        />
      </DashboardItem>
    ),
    [VALUES]: (
      <DashboardItem span={1}>
        <div className="dashboard__smu-dna-widget-wrapper">
          <StarmeUpDNAWidget
            black
            className="dashboard__smu-dna-widget"
            compact
            endDate={endDate}
            startDate={startDate}
            subtitle={starmeUpDNASubtitle}
            title={formatMessage(commonMessages.Values)}
            user={user}
          />
          <StarmeUpWidgetLogo
            className="dashboard__smu-widget-logo"
            fromText={formatMessage(placeholdersMessages.PlaceholdersFrom)}
          />
        </div>
      </DashboardItem>
    ),
    [PERFORMANCE]: (
      <DashboardItem span={1}>
        <DashboardPerformance userId={userId} />
      </DashboardItem>
    ),
    [GROWTH_AND_READINESS]: (
      <DashboardItem span={1}>
        <DashboardGrowthAndReadiness userId={userId} />
      </DashboardItem>
    ),
    [POTENTIAL]: (
      <DashboardItem span={1}>
        <DashboardPotential
          detailsPath={detailsPath}
          isAssessmentActive={allowsPotentialAssessment}
          userId={userId}
        />
      </DashboardItem>
    ),
    [FEEDBACK_FOR_LEADERS]: (
      <DashboardItem span={1}>
        <DashboardFeedbackForLeaders userId={userId} goToFeedbackReceived={getFeedbackForLeadersEvaluationDetailed} />
      </DashboardItem>
    ),
    [GOALS_V2]: (
      <DashboardItem span={1}>
        <DashboardGoalsV2
          detailsPath={detailsPath}
          userId={userId}
          messagesEmptyStates={messagesEmptyStates.goalsAssigned}
        />
      </DashboardItem>
    ),
    [FEEDBACK_V2]: (
      <DashboardItem span={1}>
        Feedback
      </DashboardItem>
    ),
    [DNA]: (
      <DashboardItem span={1}>
        <DashboardDNA userId={userId} />
      </DashboardItem>
    ),
    [ASSESSMENT_360]: (
      <DashboardItem span={2}>
        Feedback 360
      </DashboardItem>
    ),
    [INSIGHTS]: (
      <DashboardItem span={2}>
        <InsightWidget userId={userId} />
      </DashboardItem>
    ),
  };

  const sortedWidgets = useMemo(() => {
    const parsedWidgetsOrder = widgetsOrder
      ? JSON?.parse(widgetsOrder)
      : DEFAULT_WIDGETS_ORDER;

    const widgets = parsedWidgetsOrder?.sort((a, b) => a.order - b.order)
      .map(config => showWidget[config.name] && widgetComponents[config.name]);

    return (
      <DashboardLayout columns={2} spacing={3}>
        {showWidget[PERIOD_DATE_FILTER] && (
          <DashboardItem span={2}>
            <PeriodDateFilter
              className="dashboard__filters"
              datePickerTexts={{
                endDatePlaceholder: formatMessage(placeholdersMessages.PlaceholdersDate),
                startDatePlaceholder: formatMessage(placeholdersMessages.PlaceholdersDate),
              }}
              dropdownPlaceholder={formatMessage(placeholdersMessages.PlaceholdersSelectPeriod)}
              endDate={endDate}
              evaluationPeriod={defaultPeriod}
              onDateChange={({ startDate, endDate, dropdownLabel }) => {
                setStartDate(startDate);
                setEndDate(endDate);
                setDropdownLabel(dropdownLabel);
              }}
              periodLabels={{
                all: formatMessage(placeholdersMessages.PlaceholdersAll),
                current: formatMessage(placeholdersMessages.PlaceholdersCurrentPeriod),
                six: formatMessage(placeholdersMessages.PlaceholdersSixMonths),
                three: formatMessage(placeholdersMessages.PlaceholdersThreeMonths),
              }}
              startDate={startDate}
              title={periodDateFilterTitlte}
              trackDateFilterCalendar={trackDateFilterCalendar}
              trackDateFilterDropdown={trackDateFilterDropdown}
            />
          </DashboardItem>
        )}
        {widgets}
      </DashboardLayout>
    );
  }, [widgetsOrder, showWidget, widgetComponents]);


  return (
    <div className={cn('dashboard', className)}>
      {sortedWidgets}
      {!areWidgetsAvailable && (
        <CardSimple loading={areWidgetsLoading}>
          <GenericEmptyState
            className="bg-white px-5 py-20 "
            image="list"
            limitTextWidth={false}
            size="large"
            title={formatMessage(messages.DashboardDetailEmptyStateTitle)}
          />
        </CardSimple>
      )}
    </div>
  );

};

Dashboard.defaultProps = {
  activeTab: 0,
};

Dashboard.propTypes = {
  activeTab: PropTypes.number,
  activeTabsOnDashboard: PropTypes.bool,
  allowsDashboardPerformanceChart: PropTypes.bool.isRequired,
  allowsEvaluations: PropTypes.bool.isRequired,
  allowsFeedbackRequest: PropTypes.bool.isRequired,
  allowsGoals: PropTypes.bool,
  allowsPotentialAssessment: PropTypes.bool,
  allowsSmuDna: PropTypes.bool.isRequired,
  communityRatings: PropTypes.array,
  dashboardFeedback: PropTypes.object,
  defaultPeriod: PropTypes.object,
  detailsPath: PropTypes.func.isRequired,
  evaluationType: PropTypes.array,
  getDashboardFeedback: PropTypes.func.isRequired,
  getDashboardTopicsOpportunities: PropTypes.func.isRequired,
  getDashboardTopicsStrengths: PropTypes.func.isRequired,
  getLastEvaluation: PropTypes.func.isRequired,
  getSkillsRated: PropTypes.func.isRequired,
  getTopicsRated: PropTypes.func.isRequired,
  goToFeedback: PropTypes.func.isRequired,
  goToMyFeedbackReceived: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  isMyCollaborator: PropTypes.bool,
  lastEvaluation: PropTypes.object,
  listOrder: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  loadingLastEvaluation: PropTypes.bool,
  loadingOpportunities: PropTypes.bool,
  loadingStrengths: PropTypes.bool,
  messagesEmptyStates: PropTypes.object.isRequired,
  noRequestFeedback: PropTypes.bool,
  opportunities: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string.isRequired, })),
  resetDashboardData: PropTypes.func.isRequired,
  resetLastEvaluation: PropTypes.func.isRequired,
  resetRequestFeedbackData: PropTypes.func.isRequired,
  selectedUser: PropTypes.object,
  skillsRatedLoading: PropTypes.bool,
  sortSkillsRated: PropTypes.func.isRequired,
  sortTopicsRated: PropTypes.func.isRequired,
  strengths: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string.isRequired, })),
  topicsRatedLoading: PropTypes.bool,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    access: PropTypes.shape({
      feedback: PropTypes.bool.isRequired,
      goals: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => {
  return {
    allowsDashboardCompetenciesAndSkills: selectConfigurations('allowsDashboardCompetenciesAndSkills')(state),
    allowsDashboardFeedbackForLeadersChart: selectConfigurations('globerProfileFflChartVisibility')(state),
    allowsDashboardFeedbackSummary: selectConfigurations('allowsDashboardFeedbackSummary')(state),
    allowsDashboardGoals: selectConfigurations('allowsDashboardGoals')(state),
    allowsDashboardGrowthAndReadinessChart: selectConfigurations('isGloberProfileReadinessChartVisibility')(state),
    allowsDashboardPerformanceChart: selectConfigurations('globerProfilePerformanceChartVisibility')(state),
    allowsDashboardStrengthsAndOpportunities: selectConfigurations('allowsDashboardStrengthsAndOpportunities')(state),
    allowsEvaluations: selectConfigurations('allowsEvaluations')(state),
    allowsFeedbackForLeadersResult: selectConfigurations('allowsFeedbackForLeadersResult')(state),
    allowsFeedbackRequest: selectConfigurations('allowsFeedbackRequest')(state),
    allowsGoals: selectConfigurations('allowsGoals')(state),
    allowsGloberProfileInsightsVisibility: selectConfigurations('allowsGloberProfileInsightsVisibility')(state),
    allowsPotentialAssessment: selectConfigurations('allowsPotentialAssessment')(state),
    allowsSelfRetrospectiveCreation: selectConfigurations('allowsSelfRetrospectiveCreation')(state),
    allowsSelfRetrospectiveDraft: selectConfigurations('allowsSelfRetrospectiveDraft')(state),
    allowsSmuDna: selectConfigurations('allowsSmuDna')(state),
    communityId: selectCommunityId(state),
    communityRatings: selectCommunityRatings(state),
    dashboardFeedback: selectDashboardFeedback(state),
    dashboardFeedbackSummary: selectDashboardFeedbackSummary(state),
    dashboardSuggestions: {
      loadingSuggestion: selectLoadingDashboardSuggestions(state),
      suggestedActions: selectSuggestedActions(state)
    },
    defaultPeriod: selectEvaluationPeriod(state),
    evaluationType: getCommunityConfigValue(state, BME_GLOBER_PROFILE_PERFORMANCE_TYPES),
    feedbackForLeaders: selectFeedbackForLeaders(state),
    lastEvaluation: selectLastEvaluationRounded(state),
    listOrder: selectTopicsRatedOrder(state),
    loading: selectLoading(state),
    loadingDashboardPerformanceChart: selectLoadingDashboardPerformanceChart(state),
    loadingFeebackForLeaders: selectLoadingFeedbackForLeaders(state),
    loadingFeedbackSummary: selectLoadingFeedbackSummary(state),
    loadingLastEvaluation: selectLoadingLastEvaluation(state),
    loadingOpportunities: selectLoadingOpportunities(state),
    loadingStrengths: selectLoadingStrengths(state),
    opportunities: selectOpportunities(state),
    opportunitiesRating: selectOpportunitiesRating(state),
    ratedSkills: selectRatedSkills(state),
    ratedTopics: selectRatedTopics(state),
    ratings: selectRatings(state),
    selectedCompetencyDetails: selectSelectedCompetencyDetails(state),
    selectedUser: selectSelectedUser(state),
    skillsRatedLoading: selectSkillsRatedLoading(state),
    strengths: selectStrengths(state),
    strengthsRating: selectStrengthsRating(state),
    topicsRatedLoading: selectTopicsRatedLoading(state),
    widgetsOrder: selectConfigurations('globerProfileWidgetOrder')(state),
  };
};

const mapDispatchToProps = {
  ...actions,
  ...dashboardSuggestionsActions,
  getLastEvaluation,
  getSkillsRated,
  getTopicsRated,
  openModal,
  resetLastEvaluation,
  sortSkillsRated,
  sortTopicsRated,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Dashboard));
