// @packages
import React from 'react';
import ContentCardTemplate from 'components/templates/ContentCardTemplate';
import TextWrapper from 'smu-ui-components/TextWrapper';
import PropTypes from 'prop-types';
import cn from 'classnames'

// @own
import './styles.scss';
import DiscreteProgressBar from 'smu-ui-components/DiscreteProgressBar';

const PotentialAssessmentResultsDimensions = ({
  className,
  dimensionsData: {
    barColor,
    description,
    dimensions,
    dimensionsLabel,
    scores,
    title,
  },
}) => {

  return (
    <ContentCardTemplate
      className={cn('potential-assessment-results-dimensions', className)}
      title={title}
    >
      <ContentCardTemplate.Body className="potential-assessment-results-dimensions body">

        <div className="potential-assessment-results-dimensions__text">
          {description}
        </div>

        <div className="potential-assessment-results-dimensions__table-container">
          <div className='potential-assessment-results-dimensions__dimensions-header'>
            <div className='potential-assessment-results-dimensions__dimensions-header--column-name'>
              <TextWrapper>
                <span>{dimensionsLabel}</span>
              </TextWrapper>
            </div>
            {scores.map(label =>
              <div
                className='potential-assessment-results-dimensions__dimensions-header--score-label'
                key={label.id}
                style={{ width: `${70 / scores.length}%` }}
              >
                <TextWrapper>
                  <span>{label.name}</span>
                </TextWrapper>
              </div>
            )}
          </div>
          {dimensions.map(dimension =>
            <div
              className='potential-assessment-results-dimensions__dimension'
              key={dimension.name}
            >
              <div className='potential-assessment-results-dimensions__dimension-name'>
                <span>{dimension.name}</span>
              </div>
              <div className='potential-assessment-results-dimensions__dimension-score'>
                <DiscreteProgressBar
                  backgroundColor="#FFFFFF"
                  barColor={barColor}
                  emptyColor="#F0F2F5"
                  progress={dimension.score}
                  scores={scores}
                  hideDescription={true}
                />
                <span className='potential-assessment-results-dimensions__dimension--score-text'>
                  {dimension.description}
                </span>
              </div>
            </div>
          )}
        </div>

      </ContentCardTemplate.Body>
    </ContentCardTemplate>
  );
};

PotentialAssessmentResultsDimensions.propTypes = {
  className: PropTypes.string,
  dimensionsData: PropTypes.objectOf(PropTypes.shape({
    barColor: PropTypes.string,
    description: PropTypes.string,
    dimensions: PropTypes.array.isRequired,
    dimensionsLabel: PropTypes.string,
    scores: PropTypes.array.isRequired,
    title: PropTypes.string,
  })),
};

export default PotentialAssessmentResultsDimensions;
