// @packages
import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

// @app
import Breadcrumbs from 'common/Breadcrumbs';
import GradientLine from 'common/GradientLine';

// @own
import './styles.scss';

const FullscreenHeader = ({
  breadCrumbsProps,
  children,
  className,
  noFixed
}) => {
  const breadProps = {
    size: 'big',
    ...breadCrumbsProps
  };

  return (
    <div className={cn('fullscreen-header', className, {
      'fullscreen-header--noFixed': noFixed,
    })}>
      <GradientLine />
      <div className="fullscreen-header__header-content">
        <Breadcrumbs
          {...breadProps}
        />
        {children}
      </div>
    </div>
  );
};

FullscreenHeader.defaultProps = {
  breadCrumbsProps: {},
  noFixed: false,
};

FullscreenHeader.propTypes = {
  children: PropTypes.node,
  breadCrumbsProps: PropTypes.shape({
  active: PropTypes.number,
  className: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['medium', 'big']),
  values: PropTypes.array.isRequired,
  }),
  className: PropTypes.string,
  noFixed: PropTypes.bool,
};

export default FullscreenHeader;
