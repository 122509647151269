import {
  REMOVE_LEADER,
  REMOVE_LEADER_FAIL,
  REMOVE_LEADER_SUCCESS,
} from './actionTypes';

export function removeLeader({ collaborator, type, reasonId, successMessage }) {
  return {
    type: REMOVE_LEADER,
    payload: {
      collaborator,
      reasonId,
      successMessage,
      type,
    },
  };
}

export function removeLeaderFail(error) {
  return {
    type: REMOVE_LEADER_FAIL,
    payload: { error },
  };
}

export function removeLeaderSuccess() {
  return {
    type: REMOVE_LEADER_SUCCESS,
  };
}
