import { defineMessages } from 'react-intl';

export default defineMessages({
  GoalsGoals: {
    id: 'Betterme.Messages.Goals.Goals',
    defaultMessage: 'Goals',
  },
  GoalsCreate: {
    id: 'Betterme.Messages.Goals.Create',
    defaultMessage: 'Create goal',
  },
  GoalsInProgress: {
    id: 'Betterme.Messages.Goals.InProgress',
    defaultMessage: 'Goals in progress',
  },
  GoalsWeight: {
    id: 'Betterme.Messages.Goals.Weight',
    defaultMessage: 'Weight',
  },
  GoalsAskLeader: {
    id: 'Betterme.Messages.Goals.AskLeader',
    defaultMessage: 'Ask your leader to add your Goals to BetterMe',
  },
  GoalsAssignHim: {
    id: 'Betterme.Messages.Goals.AssingHim',
    defaultMessage: 'Assign him Goals on BetterMe',
  },
  GoalsClosedGoals: {
    id: 'Betterme.Messages.Goals.ClosedGoals',
    defaultMessage: 'Closed goals',
  },
  GoalsProgress: {
    id: 'Betterme.Messages.Goals.Progress',
    defaultMessage: 'Progress',
  },
  GoalsScales: {
    id: 'Betterme.Messages.Goals.Scales',
    defaultMessage: 'Scales',
  },
  GoalsNoAssigned: {
    id: 'Betterme.Messages.Goals.NoAssigned',
    defaultMessage: 'You haven\'t been assigned goals for this period yet',
  },
  GoalsNoInProgress: {
    id: 'Betterme.Messages.Goals.NoInProgress',
    defaultMessage: 'There aren\'t goals in progress yet',
  },
  GoalsNoClosed: {
    id: 'Betterme.Messages.Goals.NoClosed',
    defaultMessage: 'You don\'t have closed goals in this timeframe',
  },
  GoalsOpened: {
    id: 'Betterme.Messages.Goals.Opened',
    defaultMessage: 'In progress',
  },
  GoalsClosed: {
    id: 'Betterme.Messages.Goals.Closed',
    defaultMessage: 'Closed',
  },
  GoalsClosedPlural: {
    id: 'Betterme.Messages.Goals.ClosedPlural',
    defaultMessage: 'Closed',
  },
  GoalsPending: {
    id: 'Betterme.Messages.Goals.Pending',
    defaultMessage: 'Pending',
  },
  GoalsPendingPlural: {
    id: 'Betterme.Messages.Goals.PendingPlural',
    defaultMessage: 'Pending',
  },
  GoalsSummary: {
    id: 'Betterme.Messages.Goals.Summary',
    defaultMessage: 'Goals summary',
  },
  GoalsOrgObjectives: {
    id: 'Betterme.Messages.Goals.OrgObjectives',
    defaultMessage: 'Organizational objectives',
  },
  GoalsAll: {
    id: 'Betterme.Messages.Goals.All',
    defaultMessage: 'All',
  },
  GoalsOverview: {
    id: 'Betterme.Messages.Goals.Overview',
    defaultMessage: 'Goals Overview',
  },
});
