// @packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import Segment from 'smu-ui-components/Segment';
import GenericErrorState from 'smu-app-components/GenericErrorState';

// @app
import BehaviourQuestion from 'components/molecules/BehaviourQuestion'
import CommentBox from 'components/molecules/CommentBox';
import { actionsMessages } from 'common/messages';
import DiscoveryLabel from 'components/molecules/DiscoveryLabel';
import {
  getSurveyQuestions,
  getSurveyRates,
  resetDraftBehaviors,
  resetSurveyQuestions,
  resetSurveyRates,
} from 'betterme-components/services/GetSurveyAndRates/actions';
import { selectPerformanceRetrospectiveBehaviours } from 'betterme-components/services/PerformanceRetrospective/selectors';
import { selectEvaluationDetail } from 'betterme-components/EvaluationDetail/selectors';
import {
  setPerformanceRetrospectiveBehaviours,
  resetPerformanceRetrospectiveBehaviours,
} from 'betterme-components/services/PerformanceRetrospective/actions';
import { selectIsFetching } from 'betterme-components/EvaluationDetail/selectors';
import {
  selectRatedQuestions,
  selectSurveyModifiedRates,
  selectSurveyQuestionsError,
  selectSurveyQuestionsLoading,
  selectSurveyRatesError,
  selectSurveyRatesLoading,
} from 'betterme-components/services/GetSurveyAndRates/selectors';
import { selectTierType, selectTierTypeLoading } from 'betterme-components/services/GetTierType/selectors';

// @own
import messages from './messages';
import {
  BEHAVIORAL_ASSESSMENT,
  BEHAVIOUR_PERFORMANCE_RETROSPECTIVE_LINK,
  BEHAVIOUR_SELF_RETROSPECTIVE_LINK,
} from './constants';

const BehaviourSection = ({
  behavioursData,
  className,
  draftData,
  draftLoading,
  getSurveyQuestions,
  getSurveyRates,
  intl: { formatMessage },
  isSelfRetrospective,
  ratedQuestions,
  resetDraftBehaviors,
  resetPerformanceRetrospectiveBehaviours,
  setPerformanceRetrospectiveBehaviours,
  surveyQuestionsError,
  surveyQuestionsLoading,
  surveyRates,
  surveyRatesError,
  surveyRatesLoading,
  tierType,
}) => {
  const [areRequestsDone, setAreRequestsDone] = useState(false);

  const galleryItems = [
    {
      title: formatMessage(messages.BehavioralAssessmentSlideOneTitle),
      text: formatMessage(messages.BehavioralAssessmentSlideOneText),
    },
    {
      title: formatMessage(messages.BehavioralAssessmentSlideTwoTitle),
      text: <FormattedHTMLMessage
        defaultMessage={messages.BehavioralAssessmentSlideTwoText.defaultMessage}
        id={messages.BehavioralAssessmentSlideTwoText.id}
        values={{
          link: BEHAVIOUR_PERFORMANCE_RETROSPECTIVE_LINK,
          style: "text-decoration:underline;color:white;",
        }}
      />
    },
  ];

  const galleryItemsSelfRetrospective = [
    {
      title: formatMessage(messages.BehavioralAssessmentSlideOneTitle),
      text: formatMessage(messages.BehavioralAssessmentSelfRetrospectiveSlideOneText),
    },
    {
      title: formatMessage(messages.BehavioralAssessmentSlideTwoTitle),
      text: <FormattedHTMLMessage
        defaultMessage={messages.BehavioralAssessmentSelfRetrospectiveSlideTwoText.defaultMessage}
        id={messages.BehavioralAssessmentSelfRetrospectiveSlideTwoText.id}
        values={{
          link: BEHAVIOUR_SELF_RETROSPECTIVE_LINK,
          style: "text-decoration:underline;color:white;",
        }}
      />
    },
  ];

  const descriptionText = isSelfRetrospective
    ? formatMessage(messages.BehaviourSectionSelfRetrospectiveDescription)
    : formatMessage(messages.BehaviourSectionDescription);

  const galleryItemsText = isSelfRetrospective
    ? galleryItemsSelfRetrospective
    : galleryItems;

  const actionButtonTexts = {
    slideOneText: formatMessage(actionsMessages.ActionsSkip),
    slideOneTextAlternative: formatMessage(actionsMessages.ActionsGotIt),
    slideTwoText: formatMessage(actionsMessages.ActionsContinue),
    slideTwoTextAlternative: formatMessage(actionsMessages.ActionsBack),
  };

  const behaviourTitle = (
    <span className="flex items-center">
      <p className="m-0 text-shark">
        {formatMessage(messages.BehaviourSectionTitle)}
      </p>
      <DiscoveryLabel
        actionButtonTexts={actionButtonTexts}
        discoveryType={BEHAVIORAL_ASSESSMENT}
        galleryItems={galleryItemsText}
        labelText={formatMessage(messages.PerformanceRetrospectiveNew)}
        showFeatureDiscovery
      />
    </span>
  );

  const isLoading = surveyQuestionsLoading || surveyRatesLoading || draftLoading;
  const doesExistsQuestionsAndRates = ratedQuestions?.length > 0 && !isEmpty(surveyRates);
  const errorOnQuestionsAndRates = surveyQuestionsError || surveyRatesError;

  const handleRateBehaviours = ({ questionId, ratingId }) => {
    let isUpdated = false;
    const updatedBehaviours = behavioursData?.map((behaviour) => {
      if (behaviour?.questionId === questionId) {
        isUpdated = true;
        return {
          ...behaviour,
          ratingId,
        };
      }
      return behaviour;
    });

    if (!isUpdated) {
      updatedBehaviours.push({ questionId, ratingId });
    }

    setPerformanceRetrospectiveBehaviours(updatedBehaviours);
  };

  const renderOptionLabel = (option, rateByCoEvaluator) => {
    const isCoEvaluatorOption = option?.value === rateByCoEvaluator;
    const coEvaluatorName = `${draftData?.coCreated?.evaluator?.firstName} ${draftData?.coCreated?.evaluator?.lastName}`;
    return (
      <div className={cn('py-2.5 px-5', {
        'flex flex-col items-start content-start border-l-4 border-l-indigo': isCoEvaluatorOption,
      })}>
        {option?.label}
        {isCoEvaluatorOption && (
          <span className='text-xs'>
            {formatMessage(messages.BehavioralAssessmentSuggestedBy,
              { username: coEvaluatorName }
            )}
          </span>
        )}
      </div>
    );
  };

  function getSurveyData() {
    getSurveyQuestions({ tierType, draftData });
    getSurveyRates(tierType);
  };

  useEffect(() => {
    if (!isEmpty(tierType)) {
      getSurveyData();
    };
  }, [tierType, draftData]);

  useEffect(() => {
    const behaviorsDraft = ratedQuestions?.reduce((accumulator, behaviour) => {
      if (behaviour?.previousRate) {
        accumulator.push({
          questionId: behaviour?.id,
          ratingId: behaviour?.previousRate,
        });
      }
      return accumulator;
    }, []);

    setPerformanceRetrospectiveBehaviours(behaviorsDraft);
  }, [ratedQuestions, draftData]);

  useEffect(() => {
    return () => {
      resetDraftBehaviors();
      resetPerformanceRetrospectiveBehaviours();
      resetSurveyQuestions();
      resetSurveyRates();
    };
  }, []);

  useEffect(() => {
    setAreRequestsDone(false)
    if (!isLoading) {
      if (doesExistsQuestionsAndRates) {
        setAreRequestsDone(true);
      }
      else if (errorOnQuestionsAndRates) {
        setAreRequestsDone(true);
      }
    }
  }, [isLoading, doesExistsQuestionsAndRates, errorOnQuestionsAndRates]);

  return (
    <Segment
      className="m-0"
      loading={!areRequestsDone}
      loadingType="betterme"
    >
      <CommentBox
        className={className}
        title={behaviourTitle}
      >
        {doesExistsQuestionsAndRates && (
          <>
            <p className="text-sm m-0 p-6 font-roboto text-shark">
              {descriptionText}
            </p>
            <div className="flex items-center justify-between mx-6 font-montserrat font-semibold text-blue">
              <h4 className="m-0 text-sm">
                {formatMessage(messages.BehaviourSectionBehaviour)}
              </h4>
              <h4 className="m-0 mr-36 text-sm">
                {formatMessage(messages.BehaviourSectionResponse)}
              </h4>
            </div>
            {ratedQuestions?.map((question) =>
              <BehaviourQuestion
                behaviourOptions={surveyRates}
                behaviourQuestion={question?.name}
                behaviourTopic={question?.topic?.name}
                coEvaluatorRate={question?.ratedByCoEvaluator}
                key={question?.id}
                mismatchMessage={formatMessage(messages.BehaviourSectionMismatch)}
                onChange={handleRateBehaviours}
                placeholder={formatMessage(messages.BehaviourSectionSelect)}
                previousRate={question?.previousRate}
                questionId={question?.id}
                renderOptionLabel={(option) => renderOptionLabel(option, question?.ratedByCoEvaluator)}
              />
            )}
          </>
        )}
        {(!doesExistsQuestionsAndRates && !areRequestsDone) &&
          <div
            className="h-64"
          />
        }
        {(!doesExistsQuestionsAndRates && areRequestsDone) &&
          <GenericErrorState
            action={{
              onClick: getSurveyData,
            }}
            className="py-10"
            image="clock"
            limitTextWidth={false}
            size="large"
          />
        }
      </CommentBox>
    </Segment>
  );
}

BehaviourSection.defaultProps = {
  behavioursData: [],
  draftData: [],
  ratedQuestions: [],
};

BehaviourSection.propTypes = {
  behavioursData: PropTypes.array.isRequired,
  className: PropTypes.string,
  getSurveyQuestions: PropTypes.func.isRequired,
  getSurveyRates: PropTypes.func.isRequired,
  getTierType: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  isSelfRetrospective: PropTypes.bool.isRequired,
  setPerformanceRetrospectiveBehaviours: PropTypes.func.isRequired,
  resetPerformanceRetrospectiveBehaviours: PropTypes.func.isRequired,
  surveyQuestions: PropTypes.object.isRequired,
  surveyQuestionsLoading: PropTypes.bool.isRequired,
  surveyRates: PropTypes.object.isRequired,
  surveyRatesLoading: PropTypes.bool.isRequired,
  tierType: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  behavioursData: selectPerformanceRetrospectiveBehaviours(state),
  draftData: selectEvaluationDetail(state),
  draftLoading: selectIsFetching(state),
  ratedQuestions: selectRatedQuestions(state),
  surveyQuestionsError: selectSurveyQuestionsError(state),
  surveyQuestionsLoading: selectSurveyQuestionsLoading(state),
  surveyRates: selectSurveyModifiedRates(state),
  surveyRatesError: selectSurveyRatesError(state),
  surveyRatesLoading: selectSurveyRatesLoading(state),
  tierType: selectTierType(state),
  tierTypeLoading: selectTierTypeLoading(state),
});

const mapDispatchToProps = {
  getSurveyQuestions,
  getSurveyRates,
  resetDraftBehaviors,
  resetPerformanceRetrospectiveBehaviours,
  resetSurveyQuestions,
  resetSurveyRates,
  setPerformanceRetrospectiveBehaviours,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(BehaviourSection));
