// @packages
import React from 'react';
import PropTypes from 'prop-types';

// @app
import BekindMessage from 'components/molecules/BekindMessage';

//@own
import './styles.scss';
import InsightsQuestion from './InsightsQuestion';
import CompetenciesCard from '../CompetenciesCard';
import AnonymousEvaluation from '../AnonymousEvaluation';

const FormInsights = ({
  answersInsights,
  bekindMessages: {
    button: bekindButton,
    text: bekindText,
    title: bekindTitle,
  },
  beKindVisible,
  onBeKindClick,
  onChange,
  questions,
  ratings,
}) => {
  const totalQuestion = questions.length;

  return (
    <div className="form-insights">
      <AnonymousEvaluation />
      {beKindVisible && (
        <BekindMessage
          messages={{
            button: bekindButton,
            text: bekindText,
            title: bekindTitle,
          }}
          onClick={onBeKindClick}
        />
      )}
      <div className="form-insights__cards">
        {questions.map((question, index) => (
          (question.questionType === 'OPEN') ? (
            <InsightsQuestion
              answer={answersInsights[question.id]}
              className="form-insights__card"
              description={question.name}
              key={index}
              onChange={(value) => onChange({
                id: question.id,
                questionType: question.questionType,
                required: question.required,
                value,
              })}
              questionNumber={index + 1}
              required={question.required}
              totalQuestions={totalQuestion}
            />
          ) : (
            <CompetenciesCard
              answer={answersInsights[question.id]}
              competenciesDescription={question.name}
              key={index}
              onChange={(value, valueId) => onChange({
                id: question.id,
                questionType: question.questionType,
                required: question.required,
                value,
                valueId,
              })}
              questionNumber={index + 1}
              ratings={ratings}
              totalQuestions={totalQuestion}
            />
          )
        ))}
      </div>
    </div>
  );
};

FormInsights.propTypes = {
  answersInsights: PropTypes.object.isRequired,
  bekindMessages: PropTypes.object.isRequired,
  beKindVisible: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  questions: PropTypes.array.isRequired,
  ratings: PropTypes.shape({
    values: PropTypes.array.isRequired,
    notApply: PropTypes.array.isRequired,
  }).isRequired,
}

export default FormInsights;
