import { trackEvent } from 'smu-utils/gtm';

const LEADERSHIP_REQUESTS = 'Leadeship_Requests';
const LEADER_DASHBOARD = 'Leader_Dashboard';

export const trackLeadershipRequestsClick = () => {
  trackEvent({
    category: LEADER_DASHBOARD,
    action: 'click',
    label: 'Leadership_Requests',
  });
};

export const trackLeadershipRequestDecline = ({ type, label }) => {
  trackEvent({
    action: 'Received_Declined',
    category: LEADERSHIP_REQUESTS,
    label,
    type,
  });
};

export const trackLeadershipRequestAccept = ({ type, label }) => {
  trackEvent({
    action: 'Received_Accept',
    category: LEADERSHIP_REQUESTS,
    label,
    type,
  });
};

export const trackLeadershipRequestDeclineReasonSend = ({ type, label }) => {
  trackEvent({
    action: 'Received_Declined_Send',
    category: LEADERSHIP_REQUESTS,
    label,
    type,
  });
};

export const trackLeadershipRequestSentCancel = ({ type, label }) => {
  trackEvent({
    action: 'Sent_Cancel',
    category: LEADERSHIP_REQUESTS,
    label,
    type,
  });
};
