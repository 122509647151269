import {
  GET_TEAM_PROMOTIONS_REQUESTS_FAIL,
  GET_TEAM_PROMOTIONS_REQUESTS_SUCCESS,
  GET_TEAM_PROMOTIONS_REQUESTS,
} from './actionTypes';

const initialState = {
  loadingPromotionsRequests: false,
  page: {
    number: 0,
    numberOfElements: 0,
    size: 0,
    totalElements: 0,
    totalPages: 0,
  },
  promotionsRequests: [],
}

export default function reducer(state = initialState, { type, payload }) {
  switch(type) {
    case GET_TEAM_PROMOTIONS_REQUESTS:
      return { ...state, loadingPromotionsRequests: true };

    case GET_TEAM_PROMOTIONS_REQUESTS_FAIL:
      return { ...state, loadingPromotionsRequests: false };

    case GET_TEAM_PROMOTIONS_REQUESTS_SUCCESS:
      return {
        ...state,
        loadingPromotionsRequests: false,
        promotionsRequests: [...payload.response],
        page: {...payload.page},
      };

    default:
      return state;
  }
}
