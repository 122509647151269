// @packages
import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Label from 'smu-ui-components/Label';
import AvatarMedia from 'smu-ui-components/AvatarMedia';
import DataTable from 'smu-ui-components/DataTable';
import Segment from 'smu-ui-components/Segment';

// @app
import {
  PAGE_SIZE,
  PENDING,
} from 'betterme-components/constants/promotions';
import { formatDateV2 } from 'betterme-components/utils/formatDateV2';
import { getMyTeamPromotionsDetailLocation } from 'betterme-components/routes/urls';
import UserAvatarList from 'common/UserAvatarList';
import { promotionsMessages } from 'common/messages';

// @own
import './styles.scss';

const PromotionsHistoryTable = ({
  intl: { formatMessage },
  loading,
  onPageChange,
  page,
  promotionsHistory,
  statusLabels,
  push,
}) => {
  const requestersList = requesters => (
    (requesters.length > 1) ? (
      <UserAvatarList
        className="promotions-history-table__requesters"
        maxDisplay={4}
        showPopup
        size={40}
        users={requesters}
      />
    ) : (
      <AvatarMedia
        className="promotions-history-table__requester"
        {...requesters[0]}
        useLink={false}
        noJob={true}
      />
    )
  );
  const fields = [
    {
      customView: promotion => (
        <div className="promotions-history-table__requested-seniority">
          <span className="promotions-history-table__requested-seniority--info">{promotion?.level?.seniority}</span>
          <span>{promotion?.level?.position}</span>
        </div>
      ),
      fixedWidth: '32%',
      headerClassName: 'promotions-history-table__header',
      name: 'requestedSeniority',
      viewName: formatMessage(promotionsMessages.PromotionsRequestedSeniority),
    },
    {
      customView: promotion => (
        <div className="promotions-history-table__requested-by">
          {requestersList(promotion?.requesters)}
        </div>
      ),
      fixedWidth: '30%',
      headerClassName: 'promotions-history-table__header',
      name: 'requestedBy',
      viewName: formatMessage(promotionsMessages.PromotionsRequestedBy),
    },
    {
      customView: ({ status }) => {
        const textStatusLabel = formatMessage(statusLabels[status].label);
        const textStatusColor = statusLabels[status].color;
        return (
          <Label
            color={textStatusColor}
            size="small"
            text={textStatusLabel}
          />
        );
      },
      fixedWidth: '22%',
      headerClassName: 'promotions-history-table__header',
      name: 'promotionStatus',
      viewName: formatMessage(promotionsMessages.PromotionsPromotionStatus),
    },
    {
      customView: ({ creationDate }) => (
        <div className="promotions-history-table__date">
          {formatDateV2(creationDate, 'monthYearFormat')}
        </div>
      ),
      headerClassName: 'promotions-history-table__header',
      name: 'date',
      viewName: formatMessage(promotionsMessages.PromotionsDate),
    },
  ];

  const handleRowClick = (e, row) =>
    push(getMyTeamPromotionsDetailLocation(row?.parentPromotionId));

  return (
    <Segment loading={loading} loadingType="betterme">
      <div className='relative'>
        <DataTable
          className="promotions-history-table"
          data={promotionsHistory}
          datacellClassName="promotions-history-table__cell"
          datahighlightedClassname="promotions-history-table__row--active"
          datarowClassname="promotions-history-table__row"
          enableCheckbox={false}
          fields={fields}
          onPageChange={onPageChange}
          onRowClick={handleRowClick}
          pageNumber={page.number + 1}
          pageSize={PAGE_SIZE}
          showFirstLast
          totalItems={page.totalElements}
        />
        {!promotionsHistory?.length && (
          <span className='promotions-history-table__empty-message'>
            {formatMessage(promotionsMessages?.PromotionsEmptyHistoryMessage)}
          </span>
        )}
      </div>
    </Segment>
  );
}

PromotionsHistoryTable.propTypes = {
  formatMessage: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.shape({
    number: PropTypes.number.isRequired,
    numberOfElements: PropTypes.number.isRequired,
    size: PropTypes.number.isRequired,
    totalElements: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
  }),
  promotionsHistory: PropTypes.array.isRequired,
};

export default injectIntl(PromotionsHistoryTable);
