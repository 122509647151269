// @packages
import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

// @app
import Breadcrumbs from 'components/molecules/Breadcrumbs';
import GradientLine from 'components/atoms/GradientLine';

// @own
import './styles.scss';

const BreadcrumbHeader = ({
  breadCrumbsProps,
  children,
  className,
  date,
  noFixed,
}) => {
  const breadProps = {
    ...breadCrumbsProps,
    date,
    size: 'big',
  };

  return (
    <div className={cn('breadcrumb-header', className, {
      'breadcrumb-header--noFixed': noFixed,
    })}>
      <GradientLine />
      <div className="breadcrumb-header__header-content">
        <Breadcrumbs
          {...breadProps}
        />
        {children}
      </div>
    </div>
  );
};

BreadcrumbHeader.defaultProps = {
  breadCrumbsProps: {},
  noFixed: false,
};

BreadcrumbHeader.propTypes = {
  breadCrumbsProps: PropTypes.object.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  date: PropTypes.object,
  noFixed: PropTypes.bool,
};

export default BreadcrumbHeader;
