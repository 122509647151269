import { get, isNil, some } from 'lodash';
import {
  UPDATE_APPROVAL_GOAL,
  UPDATE_APPROVAL_GOAL_FAIL,
  UPDATE_APPROVAL_GOAL_SUCCESS,
  CLOSE_GOAL,
  CLOSE_GOAL_FAIL,
  CLOSE_GOAL_SUCCESS,
  CLOSE_MODAL,
  DELETE_GOAL,
  DELETE_GOAL_FAIL,
  DELETE_GOAL_SUCCESS,
  DISAPPROVAL_GOAL,
  DISAPPROVAL_GOAL_FAIL,
  DISAPPROVAL_GOAL_SUCCESS,
  GET_SUMMARY_GOALS,
  GET_SUMMARY_GOALS_SUCCESS,
  GET_SUMMARY_GOALS_FAILED,
  GET_ASSIGNED_GOALS,
  GET_ASSIGNED_GOALS_SUCCESS,
  GET_ASSIGNED_GOALS_FAILED,
  GET_ASSIGNED_GOAL_DETAIL,
  GET_ASSIGNED_GOAL_DETAIL_SUCCESS,
  GET_ASSIGNED_GOAL_DETAIL_FAILED,
  GET_ORGANIZATIONAL_OBJECTIVES,
  GET_ORGANIZATIONAL_OBJECTIVES_FAIL,
  GET_ORGANIZATIONAL_OBJECTIVES_SUCCESS,
  GET_TEAM_GOALS,
  GET_TEAM_GOALS_SUCCESS,
  GET_TEAM_GOALS_FAILED,
  GET_TEAM_GOAL_DETAIL,
  GET_TEAM_GOAL_DETAIL_SUCCESS,
  GET_TEAM_GOAL_DETAIL_FAILED,
  GET_PERIODS,
  GET_PERIODS_SUCCESS,
  GET_PERIODS_FAILED,
  GET_USER_TOTAL_WEIGHT,
  GET_USER_TOTAL_WEIGHT_FAIL,
  GET_USER_TOTAL_WEIGHT_SUCCESS,
  GOAL_WEIGHT_VALIDATION_ERROR,
  REMOVE_USER_TOTAL_WEIGHT,
  CREATE_GOAL,
  CREATE_GOAL_SUCCESS,
  CREATE_GOAL_FAILED,
  UPDATE_GOAL,
  UPDATE_GOAL_SUCCESS,
  UPDATE_GOAL_FAILED,
  CREATE_GOAL_ASSESSMENT,
  CREATE_GOAL_ASSESSMENT_SUCCESS,
  CREATE_GOAL_ASSESSMENT_FAILED,
  RESET_SUMMARY_GOALS,
  RESET_ASSIGNED_GOAL,
  RESET_TEAM_GOAL,
  RESET_ASSIGNED_GOAL_DETAIL,
  RESET_TEAM_GOAL_DETAIL,
  SET_MY_GOAL_FILTERS,
  SET_TEAM_GOAL_FILTERS,
  TOGGLE_EDIT_PROGRESS,
} from './actionTypes';
import { INITIAL_SUMMARY } from './constants';


const initialGoalsFilters = {
  filterText: '',
  filterStatus: '',
  filterUser: '',
  filterPeriodId: '',
};

const initialState = {
  assignedGoalDetail: {},
  assignedGoals: [],
  editingProgress: false,
  error: '',
  fetching: false,
  fetchingPeriod: false,
  fetchingSent: false,
  filtersApplied: false,
  loadingApprovalId: 0,
  loadingClose: false,
  loadingDelete: false,
  loadingDisapprovalId: 0,
  loadingSummaryGoals: false,
  myGoalsFilters: initialGoalsFilters,
  organizationalObjectives: [],
  period: [],
  summaryGoals: {
    assignedTo: INITIAL_SUMMARY,
    team: INITIAL_SUMMARY,
  },
  teamGoalDetail: {},
  teamGoals: [],
  teamGoalsFilters: initialGoalsFilters,
  usersSelectedTotalWeight: [],
};



export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case UPDATE_APPROVAL_GOAL:
      return { ...state, loadingApprovalId: payload.id };
    case UPDATE_APPROVAL_GOAL_FAIL:
    case UPDATE_APPROVAL_GOAL_SUCCESS:
      return { ...state, loadingApprovalId: 0 };
    case CLOSE_GOAL:
      return { ...state, loadingClose: true };

    case CLOSE_GOAL_FAIL:
    case CLOSE_GOAL_SUCCESS:
      return { ...state, loadingClose: false };

    case CLOSE_MODAL:
      return { ...state, fetchingSent: false };

    case DELETE_GOAL:
      return { ...state, loadingDelete: true };

    case DELETE_GOAL_FAIL:
    case DELETE_GOAL_SUCCESS:
      return { ...state, loadingDelete: false };

    case DISAPPROVAL_GOAL:
      return { ...state, loadingDisapprovalId: payload.id };

    case DISAPPROVAL_GOAL_FAIL:
    case DISAPPROVAL_GOAL_SUCCESS:
      return { ...state, loadingDisapprovalId: 0 };

    case GET_ASSIGNED_GOALS:
    case GET_TEAM_GOALS:
      return {
        ...state,
        fetching: true,
        error: false,
        filtersApplied: some(payload.filters, (value) => !(isNil(value) && value !== ''))
      };

    case GET_ASSIGNED_GOAL_DETAIL:
    case GET_TEAM_GOAL_DETAIL:
      return { ...state, fetching: true, error: false };

    case GET_SUMMARY_GOALS:
      return { ...state, loadingSummaryGoals: true, error: false };

    case GET_SUMMARY_GOALS_SUCCESS:
      return { ...state, loadingSummaryGoals: false, summaryGoals: payload.response };

    case GET_SUMMARY_GOALS_FAILED:
      return { ...state, loadingSummaryGoals: false, summaryGoals: {}, error: payload.error };

    case GET_ASSIGNED_GOALS_SUCCESS:
      return { ...state, fetching: false, assignedGoals: payload.response };

    case GET_ASSIGNED_GOALS_FAILED:
      return { ...state, fetching: false, assignedGoals: [], error: payload.error };

    case GET_ASSIGNED_GOAL_DETAIL_SUCCESS:
      return { ...state, fetching: false, assignedGoalDetail: payload.response };

    case GET_ASSIGNED_GOAL_DETAIL_FAILED:
      return { ...state, fetching: false, assignedGoalDetail: {}, error: payload.error };

    case GET_ORGANIZATIONAL_OBJECTIVES_SUCCESS:
      return { ...state, fetchingSent: false, organizationalObjectives: payload.response };

    case GET_TEAM_GOALS_SUCCESS:
      return { ...state, fetching: false, teamGoals: payload.response };

    case GET_TEAM_GOALS_FAILED:
      return { ...state, fetching: false, teamGoals: [], error: payload.error };

    case GET_TEAM_GOAL_DETAIL_SUCCESS:
      return { ...state, fetching: false, teamGoalDetail: payload.response };

    case GET_TEAM_GOAL_DETAIL_FAILED:
      return { ...state, fetching: false, teamGoalDetail: {}, error: payload.error };

    case GET_PERIODS:
      return { ...state, fetchingPeriod: true, error: false };

    case GET_PERIODS_SUCCESS:
      return { ...state, fetchingPeriod: false, period: payload.response };

    case GET_PERIODS_FAILED:
      return { ...state, fetchingPeriod: false, period: {}, error: payload.error };

    case GET_USER_TOTAL_WEIGHT:
      return { ...state, loadingTotalWeight: true };

    case GET_USER_TOTAL_WEIGHT_FAIL:
      return {
        ...state,
        loadingTotalWeight: false,
      };

    case GET_USER_TOTAL_WEIGHT_SUCCESS:
      return {
        ...state,
        loadingTotalWeight: false,
        usersSelectedTotalWeight: [
          ...state.usersSelectedTotalWeight.filter(({ user }) => user.id !== payload.user.id),
          payload
        ],
      };

    case UPDATE_GOAL:
    case CREATE_GOAL:
    case CREATE_GOAL_ASSESSMENT:
    case GET_ORGANIZATIONAL_OBJECTIVES:
      return { ...state, fetchingSent: true, error: false };

    case UPDATE_GOAL_SUCCESS:
    case CREATE_GOAL_SUCCESS:
    case CREATE_GOAL_ASSESSMENT_SUCCESS:
      return { ...state, fetchingSent: false, error: false };

    case UPDATE_GOAL_FAILED:
    case CREATE_GOAL_FAILED:
    case CREATE_GOAL_ASSESSMENT_FAILED:
    case GET_ORGANIZATIONAL_OBJECTIVES_FAIL:
    case GOAL_WEIGHT_VALIDATION_ERROR:
      return { ...state, fetchingSent: false, error: payload.error };

    case REMOVE_USER_TOTAL_WEIGHT:
      return {
        ...state,
        usersSelectedTotalWeight: payload.userId
          ? state.usersSelectedTotalWeight.filter(({ user }) => user.id !== payload.userId)
          : [],
      };

    case RESET_SUMMARY_GOALS:
      return {
        ...state,
        fetching: false,
        summaryGoals: {
          assignedTo: INITIAL_SUMMARY,
          team: INITIAL_SUMMARY,
        },
        error: false,
      };

    case RESET_ASSIGNED_GOAL:
      return { ...state, fetching: false, assignedGoals: [], error: false };

    case RESET_TEAM_GOAL:
      return { ...state, fetching: false, teamGoals: [], error: false };

    case RESET_ASSIGNED_GOAL_DETAIL:
      return { ...state, fetching: false, assignedGoalDetail: {}, error: false };

    case RESET_TEAM_GOAL_DETAIL:
      return { ...state, fetching: false, teamGoalDetail: {}, error: false };

    case SET_MY_GOAL_FILTERS:
      return { ...state, myGoalsFilters: payload.filters };

    case SET_TEAM_GOAL_FILTERS:
      return { ...state, teamGoalsFilters: payload.filters };
    case TOGGLE_EDIT_PROGRESS:
      return { ...state, editingProgress: get(payload, 'forcedState', !state.editingProgress) };
    default:
      return state;
  }
}
