// @packages
import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import { isEmpty } from 'lodash';

// @app
import * as feedActions from 'betterme-components/MyFeedbacks/actions';
import EmptyState from 'components/molecules/EmptyState';
import Item from 'betterme-components/Feed/Item';
import Segment from 'smu-ui-components/Segment';
import { getNewFeedbackRequestLocation } from 'betterme-components/routes/urls';
import messages from 'betterme-components/Feed/messages';
import { selectConfigurations, selectUser } from 'betterme-components/Authorization/selectors';
import feedBackEmptyState from 'betterme-components/assets/empty-states/feedback-empty-state.svg';

// @own
import * as actions from './actions';
import './styles.scss';
import {
  selectFeedbackDetail,
  selectFeedbackErrorStatus,
  selectFeedbackFetching,
} from './selectors';

class FeedbackView extends React.Component {

  constructor(props) {
    super(props);
    this.onMessagesClick = this.onMessagesClick.bind(this);
  }

  componentDidMount() {
    const { params, getFeedback } = this.props;
    getFeedback(params.feedbackid);
  }

  componentWillReceiveProps(nextProps) {
    const { params: nextParams } = nextProps;
    const { params, getFeedback } = this.props;

    if (nextParams && nextParams.feedbackid !== params.feedbackid) {
      getFeedback(nextParams.feedbackid);
    }
  }

  componentWillUnmount() {
    this.props.requestCommentsReset();
  }

  getUserName(user) {
    const {
      intl: { formatMessage },
      selfUser,
    } = this.props;

    const userName = selfUser.id === user.id
      ? formatMessage(messages.FeedbackFeedMenuItemMe)
      : `${user.firstName} ${user.lastName}`;

    return userName;
  }

  onMessagesClick() {
    const { feedbackDetail, getComments } = this.props;
    getComments(feedbackDetail.id);
  }

  render() {
    const {
      allowsRequestedOnBehalfVisibility,
      feedbackDetail,
      feedbackErrorStatus,
      feedbackFetching,
      intl: { formatMessage },
      location: { state },
      sendComment,
    } = this.props;
    const { requestedBy } = feedbackDetail;
    const commentExpanded = state ? state.comment : false;
    const requestedByUserName = (allowsRequestedOnBehalfVisibility && !!requestedBy)
      ? this.getUserName(requestedBy)
      : undefined;

    return (
      <Segment
        className="feedback-view"
        loading={feedbackFetching}
        loadingType="betterme"
      >
        {(!isEmpty(feedbackDetail) && !feedbackErrorStatus) ? (
          <Item
            {...feedbackDetail}
            commentExpanded={commentExpanded}
            onMessagesCounterClick={this.onMessagesClick}
            requestedBy={requestedByUserName}
            sendComment={sendComment}
          />
        ) : (
          <EmptyState
            className="feedback-view__empty-state"
            description={
              <FormattedHTMLMessage
                values={{ link: getNewFeedbackRequestLocation().pathname }}
                defaultMessage={messages.FeedbackFeedEmptyStateDescription.defaultMessage}
                id={messages.FeedbackFeedEmptyStateDescription.id}
              />
            }
            direction="column"
            image={feedBackEmptyState}
            title={formatMessage(messages.FeedbackFeedEmptyStateTitle)}
          />
        )}
      </Segment>
    );
  }
}

const mapStateToProps = (state) => ({
  allowsRequestedOnBehalfVisibility: selectConfigurations('allowsRequestedOnBehalfVisibility')(state),
  feedbackDetail: selectFeedbackDetail(state),
  feedbackErrorStatus: selectFeedbackErrorStatus(state),
  feedbackFetching: selectFeedbackFetching(state),
  selfUser: selectUser(state),
});
const mapDispatchToProps = {
  ...actions,
  ...feedActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(FeedbackView));
