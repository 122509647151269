// @packages
import React from 'react';
import PropTypes from 'prop-types';
import Segment from 'smu-ui-components/Segment';
import cn from 'classnames';

// @app
import BreadcrumbHeader from 'components/organisms/BreadcrumbHeader';

// @own
import './styles.scss';

const Evaluation = ({
  children,
  className,
  date,
  headerValues,
  mainContentLoading,
  navigateBack,
  sideContent,
  sideContentLoading,
}) => (
  <div className={cn('evaluation', className)}>
    <BreadcrumbHeader
      breadCrumbsProps={{
        active: 1,
        onClick: navigateBack,
        values: headerValues,
      }}
      className="evaluation__header"
      date={date}
    />
    <div className="evaluation__content">
      <Segment
        className="evaluation__content--main"
        loading={mainContentLoading}
        loadingType="betterme"
      >
        {children}
      </Segment>
      <Segment
        className="evaluation__content--side"
        loading={sideContentLoading}
        loadingType="betterme"
      >
        {sideContent}
      </Segment>
    </div>
  </div>
);

Evaluation.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  mainContentLoading: PropTypes.bool.isRequired,
  sideContent: PropTypes.node.isRequired,
  sideContentLoading: PropTypes.bool.isRequired,
};

export default Evaluation;
