// @packages
import { takeLatest, call, put } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import { push } from 'react-router-redux';

// @app
import { getNotificationHistoryLocation } from 'betterme-components/routes/urls';
import { groupByRateId } from 'utils/evaluationHelper';

// @own
import { CREATE_INPUT } from './actionTypes';
import { createInput } from './api';
import {
  createInputFail,
  createInputSuccess,
} from './actions';

function* createInputWorker({ payload }) {
  const {
    evaluationId,
    successMessage,
    data,
  } = payload;
  const goToNotifications = () => push(getNotificationHistoryLocation());
  const ratedTopics = groupByRateId(data?.ratedTopics);
  const inputData = {
    ...data,
    ratedTopics,
  };

  try {
    const response = yield call(createInput, evaluationId, inputData);
    yield put(createInputSuccess(response));
    yield put(goToNotifications());
    yield put(addToastMessage({ message: successMessage, timeout: 9000 }));
  } catch (error) {
    yield put(createInputFail(error));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
};

export default function* createInputWatcher() {
  yield takeLatest(CREATE_INPUT, createInputWorker);
};
