// @packages
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';
import Divider from 'smu-ui-components/Divider';
import Segment from 'smu-ui-components/Segment';
import GenericEmptyState from 'smu-ui-components/GenericEmptyState';

// @app
import PreviousRequest from 'betterme-components/PreviousPromotionRequest';
import { getMyTeamPromotionsLocation } from 'betterme-components/routes/urls'
import { ORDERING_ASC } from 'betterme-components/constants/filterTypes';
import BreadcrumbHeader from 'components/organisms/BreadcrumbHeader';
import { promotionsMessages } from 'common/messages';
import Title from 'components/atoms/Title';
import { formatDateV2 } from 'utils/formatDateV2';

// @own
import {
  selectPromotionRequestHistorical,
  selectLoadingUserPromotionRequestsGroup,
  selectPromotionProcessAllowed,
} from './selectors';
import { getUserPromotionRequestsGroup } from './actions';
import './styles.scss';
import { DECLINED_BY_LEADER, DECLINED_BY_MANAGER } from '../constants/promotions';

const PromotionsHistoryScreen = ({
  getUserPromotionRequestsGroup,
  goToPath,
  intl: { formatMessage },
  loadingPromotionRequestsGroup,
  promotionProcessAllowed,
  promotionRequests,
}) => {
  const date = promotionRequests?.length ?
    formatDateV2(promotionRequests[0].creationDate, 'monthYearFormat') : '';
  const { firstName, lastName } = promotionRequests.length &&
    promotionRequests[0].promoted;
  const fullName = !loadingPromotionRequestsGroup && promotionRequests?.length ?
    `${firstName} ${lastName}` : '';
  const isFirstOne = key => key === 0;

  useEffect(() => {
    const promotionParentId = window.location.href.split('/').pop();
    getUserPromotionRequestsGroup(promotionParentId, ORDERING_ASC);
  }, []);

  function handleBreadcrumbsClick() {
    goToPath(getMyTeamPromotionsLocation());
  }

  function getCardTitle(key, status, length) {
    if (key === 0) {
      return formatMessage(promotionsMessages.PromotionsRequestedBy);
    }
    if (length === 2 && key === 1 && (status === DECLINED_BY_LEADER || status === DECLINED_BY_MANAGER)) {
      return formatMessage(promotionsMessages.PromotionsDeclinedBy);
    }
    if (length === 3 && key === 2 && status === DECLINED_BY_MANAGER) {
      return formatMessage(promotionsMessages.PromotionsDeclinedBy);
    }
    return formatMessage(promotionsMessages.PromotionsValidatedBy);
  }

  return (
    <Segment className="promotions-history" loadingType="betterme">
      <BreadcrumbHeader
        breadCrumbsProps={{
          active: 1,
          onClick: () => handleBreadcrumbsClick(),
          size: 'big',
          values: [
            formatMessage(promotionsMessages.PromotionsMyTeamsPromotion),
            fullName,
          ]
        }}
        date={date}
      />
      {promotionProcessAllowed && (
        <Segment
          className="promotions-history__content"
          loading={loadingPromotionRequestsGroup}
          loadingType="betterme"
        >
          <Title
            black
            className="promotions-history__title"
            size={20}
          >
            {formatMessage(promotionsMessages.PromotionsPromotionRequest)}
          </Title>
          <Divider className="promotions-history__divider" />
          {promotionRequests.map((request, key) => (
            <div className="promotions-history__request" key={request.id}>
              <PreviousRequest
                {...request}
                cardTitle={getCardTitle(key, request.status, promotionRequests.length)}
                highlightRequester={isFirstOne(key)}
              />
            </div>
          ))}
        </Segment>
      )}
      {(!promotionProcessAllowed && !loadingPromotionRequestsGroup) && (
        <GenericEmptyState
          className="mt-[70px] h-[calc(100vh-140px)]"
          image="dartboard"
          description={formatMessage(promotionsMessages.PromotionsRequestEmptyDescription2)}
          title={formatMessage(promotionsMessages.PromotionsRequestEmptyTitle2)}
        />
      )}
    </Segment>
  );
};

PromotionsHistoryScreen.propTypes = {
  formatMessage: PropTypes.func.isRequired,
  getUserPromotionRequestsGroup: PropTypes.func.isRequired,
  goToPath: PropTypes.func.isRequired,
  loadingPromotionRequestsGroup: PropTypes.bool,
  promotionRequests: PropTypes.array.isRequired,
}

const mapStateToProps = state => ({
  loadingPromotionRequestsGroup: selectLoadingUserPromotionRequestsGroup(state),
  promotionProcessAllowed: selectPromotionProcessAllowed(state),
  promotionRequests: selectPromotionRequestHistorical(state),
});

const goToPath = path => push(path);

export default connect(mapStateToProps, { getUserPromotionRequestsGroup, goToPath })
  (injectIntl(PromotionsHistoryScreen));
