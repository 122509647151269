// @packages
import { get } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import TextRevealer from 'smu-ui-components/TextRevealer';
import TextParserContainer from 'betterme-components/TextParser';

// @app
import DownloadFile from 'betterme-components/components/containers/DownloadFile';

// @own
import './styles.scss';

function getScaleValueName(value, scales) {
  const valueScale = scales.find(scale => scale.min <= value && value <= scale.max);

  return get(valueScale, 'name', '');
}

function getAssessments(assessments, scales) {
  return assessments.map(assessment => ({
    ...assessment,
    message: getScaleValueName(assessment.value, scales),
  }));
}

const GoalAssessment = ({
  assessments,
  isPercentage,
  messages,
  scales,
}) => {
  const assessmentsWithMessage = getAssessments(assessments, scales);

  return (
    <ul className="goal-assessments">
      {assessmentsWithMessage.map((assessment) => {
        const assessmentMessage = assessment.message ? ` - ${assessment.message}` : '';
        const progress = isPercentage
          ? `${assessment.value}%`
          : `${assessment.value}${assessmentMessage}`;

        return (
          <li className="goal-assessments__item" key={assessment.id}>
            <div className="goal-assessments__info">
              <span className="goal-assessments__info-progress">
                {progress}
              </span>
              <span className="goal-assessments__info-date">
                {moment.utc(assessment.creationTime).format('MMM D')}
              </span>
            </div>
            <div className="goal-assessments__comment">
              <TextRevealer
                moreButtonLabel={messages.btnReadMore}
                lessButtonLabel={messages.btnReadLess}
                maxHeight={60}
                inline
              >
                <pre className="goal-assessments__comment-text">
                  <TextParserContainer>
                    {assessment.comment}
                  </TextParserContainer>
                </pre>
                <div className="goal-assessments__comment-signature">
                  {messages.by} {assessment.evaluator.firstName} {assessment.evaluator.lastName}
                </div>
              </TextRevealer>
              <div className="goal-assessments__download-file-wrapper">
                <DownloadFile
                  attachments={assessment.attachments}
                  className="goal-assessments__download-file"
                />
              </div>
              </div>
            </li>
          );
        }
      )}
    </ul>
  );
};

GoalAssessment.propTypes = {
  assessments: PropTypes.array.isRequired,
  messages: PropTypes.object,
};

export default GoalAssessment;
