export const GOALS_ACHIEVED = 'GOALS_ACHIEVED';
export const GOALS_REMAINING = 'GOALS_REMAINING';

// DashboardDateFilter dropdown track labels
export const PERIOD_ALL = 'PERIOD_ALL';
export const PERIOD_CURRENT = 'PERIOD_CURRENT';
export const PERIOD_SIX = 'PERIOD_SIX';
export const PERIOD_THREE = 'PERIOD_THREE';

// Tabs
export const COMPETENCIES_TAB = 'COMPETENCIES_TAB';
export const SKILLS_TAB = 'SKILLS_TAB';

export const TABS_MAP = {
  COMPETENCIES_TAB: 0,
  SKILLS_TAB: 1,
};

// Widget names
export const ASSESSMENT_360 = 'ASSESSMENT_360';
export const COMPETENCIES_AND_SKILLS = 'COMPETENCIES_AND_SKILLS';
export const DNA = 'DNA';
export const FEEDBACK = 'FEEDBACK';
export const FEEDBACK_FOR_LEADERS = 'FEEDBACK_FOR_LEADERS';
export const FEEDBACK_V2 = 'FEEDBACK_V2';
export const GOALS = 'GOALS';
export const GOALS_V2 = 'GOALS_V2';
export const GROWTH_AND_READINESS = 'GROWTH_AND_READINESS';
export const INSIGHTS = 'INSIGHTS';
export const LAST_EVALUATION = 'LAST_EVALUATION';
export const PERFORMANCE = 'PERFORMANCE';
export const PERIOD_DATE_FILTER = 'PERIOD_DATE_FILTER';
export const POTENTIAL = 'POTENTIAL';
export const STRENGTHS_AND_OPPORTUNITIES = 'STRENGTHS_AND_OPPORTUNITIES';
export const SUGGESTIONS = 'SUGGESTIONS';
export const VALUES = 'VALUES';

export const DEFAULT_WIDGETS_ORDER = [
  { name: FEEDBACK, order: 1 },
  { name: COMPETENCIES_AND_SKILLS, order: 2 },
  { name: STRENGTHS_AND_OPPORTUNITIES, order: 3 },
  { name: GOALS, order: 4 },
  { name: SUGGESTIONS, order: 5 },
  { name: LAST_EVALUATION, order: 6 },
  { name: VALUES, order: 99 },
  { name: PERFORMANCE, order: 8 },
  { name: GROWTH_AND_READINESS, order: 9 },
  { name: POTENTIAL, order: 10 },
  { name: FEEDBACK_FOR_LEADERS, order: 11 },
  { name: GOALS_V2, order: 12 },
  { name: FEEDBACK_V2, order: 13 },
  { name: DNA, order: 14 },
  { name: ASSESSMENT_360, order: 15 },
  { name: INSIGHTS, order: 16 },
];
