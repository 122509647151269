// @packages
import PropTypes from 'prop-types';
import React from 'react';
import Button from 'smu-ui-components/Button';
import Icon from 'smu-ui-components/Icon';

// @app
import CompetencyRatingList from 'components/molecules/CompetencyRatingList';
import FilterDropdown from 'components/molecules/FilterDropdown';

// @own
import './styles.scss';

const RatingDetails = ({
  competenciesRatingList,
  filterDropdownConfig,
  filterValue,
  listOrder,
  messages: { competencies },
  onFilterChange,
  onOrderChange,
  onUserAvatarsClick,
}) => {
  const icon = {
    asc: 'sort-asc',
    desc: 'sort-desc',
  };

  return (
    <div className="rating-details">
      <div className="rating-details__header">
        <h3 className="rating-details__title">
          {competencies}
        </h3>
        <div className="rating-details__filter">
          <div className="rating-details__dropdown-wrapper">
            <FilterDropdown
              onChange={onFilterChange}
              padding
              config={filterDropdownConfig}
              value={filterValue[0] || ''}
            />
          </div>
          <Button className="rating-details__order-button" onClick={() => onOrderChange(listOrder)}>
            <Icon icon={icon[listOrder]} className="rating-details__order-icon" />
          </Button>
        </div>
      </div>
      <CompetencyRatingList
        className="rating-details__competency-rating-list"
        competencies={competenciesRatingList}
        onUserAvatarsClick={onUserAvatarsClick}
      />
    </div>
  );
};

RatingDetails.propTypes = {
  competenciesRatingList: PropTypes.array,
  filterDropdownConfig: PropTypes.object.isRequired,
  filterValue: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.string,
  ]).isRequired,
  intl: PropTypes.object.isRequired,
  listOrder: PropTypes.string.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onOrderChange: PropTypes.func.isRequired,
  onUserAvatarsClick: PropTypes.func.isRequired,
  ratingsFilters: PropTypes.array,
};

export default RatingDetails;
