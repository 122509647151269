// @packages
import { takeEvery, put, call } from 'redux-saga/effects';

// @own
import { getCommunityPreferences } from './api';
import { REQUEST_COMMUNITY_CONFIGS } from './actionTypes';
import {
  requestCommunityConfigsSuccess,
  requestCommunityConfigsFail,
} from './actions';

function* getCommunityConfigsWorker({ payload: { data, ...rest } }) {
  try {
    let result = data;
    if (!result) {
      result = yield call(getCommunityPreferences, { ...rest });
    }
    yield put(requestCommunityConfigsSuccess(result ?? {}));
  } catch (e) {
    yield put(requestCommunityConfigsFail(e));
  }
}

export default function* getCommunityConfigsWatcher() {
  yield takeEvery(REQUEST_COMMUNITY_CONFIGS, getCommunityConfigsWorker);
}
