// @packages
import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Link from 'smu-ui-components/Link';
import ButtonV2 from 'smu-ui-components/ButtonV2';

const ButtonLink = ({
  className,
  icon,
  label,
  onClick,
  target,
  to,
}) => (
  <Link
    className={cn('button-link', cn(className))}
    target={target}
    to={to}
  >
    <ButtonV2
      endIcon={icon}
      onClick={onClick}
      size="medium"
      variant="outline"
    >
      {label}
    </ButtonV2>
  </Link>
);

ButtonLink.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  target: PropTypes.string,
  to: PropTypes.string,
};

export default ButtonLink;
