// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'smu-ui-components/Icon';

// @app
import Title from 'components/atoms/Title';

// @own
import './styles.scss';

const SimpleModal = ({
  children,
  className,
  closeModal,
  title,
  subtitle,
}) => {
  return (
    <div className={cn('simple-modal', className)}>
      <Icon
        className="simple-modal__close-icon"
        icon="close"
        onClick={closeModal}
      />

      {(title || subtitle) && (
        <div className="simple-modal__header">
          <div className="simple-modal__header-title">
            <Title className="text-black" nomargin size={16}>{title}</Title>
            <h4 className="simple-modal__subtitle">{subtitle}</h4>
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

SimpleModal.defaultProps = {
  closeModal: () => undefined,
};

SimpleModal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  closeModal: PropTypes.func,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

export default SimpleModal;
