// @packages
import React, { Component } from 'react';
import SectionList from './SectionList';
import PropTypes from 'prop-types';

class SectionListComponent extends Component {
  constructor() {
    super();
    this.state = {
      expanded: false,
    };
  }

  render() {
    const {
      expanded,
    } = this.state;
    const {
      datePicker,
      ...props
    } = this.props;

    return (
      <SectionList
        expanded={expanded}
        onToggleClick={() => this.setState({ expanded: !expanded })}
        {...props}
        datePicker={datePicker}
      />
    );
  }
}

SectionListComponent.defaultProps = {
  maxItems: 5,
  toggleLabel: {
    less: 'View less',
    more: 'View more',
  },
};

const sectionListValueProptype = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]);

SectionListComponent.propTypes = {
  datePicker: PropTypes.shape({
    endDateId: PropTypes.string.isRequired,
    startDateId: PropTypes.string.isRequired,
  }),
  expanded: PropTypes.bool,
  listOrder: PropTypes.string,
  maxItems: PropTypes.number,
  messagesEmptyStates: PropTypes.shape({
    main: PropTypes.string,
    suggestion: PropTypes.string,
  }),
  onDatePickerChange: PropTypes.func,
  onOrderClick: PropTypes.func,
  onSelect: PropTypes.func,
  onToggleClick: PropTypes.func,
  sections: PropTypes.arrayOf(PropTypes.shape({
    value: sectionListValueProptype,
    displayValue: PropTypes.string.isRequired,
  })).isRequired,
  title: PropTypes.string.isRequired,
  value: sectionListValueProptype,
};

export default SectionListComponent;
