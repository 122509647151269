import {
  UPDATE_MY_TEAM_EVALUATIONS_FILTER_CONFIG,
} from './actionTypes';

const initialState = {
  filterConfig: {},
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case UPDATE_MY_TEAM_EVALUATIONS_FILTER_CONFIG:
      return { 
        ...state,
        filterConfig: payload.filterConfig,
      };

    default:
      return state;
  }
}
