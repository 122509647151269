// @packages
import { takeLatest, call, put } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import { openModal, closeModal } from 'smu-app-components/RootModal/actions';

// @app
import {
  APPROVED,
  CANCELED,
  RECEIVED,
  SENT,
} from 'components/common/constants';
import { refreshUserInfo } from 'betterme-components/Authorization/actions';

// @own
import {
  GET_CHANGE_LEADER_DISMISS_REASONS,
  PUT_CHANGE_LEADER,
} from './actionTypes';
import {
  getChangeLeaderDismissReasonFail,
  getChangeLeaderDismissReasonSuccess,
  putChangeLeaderFail,
  putChangeLeaderSuccess,
} from './actions';
import { getLeadershipRequestsReceived } from 'betterme-components/services/LeadershipRequestReceived/actions';
import { getLeadershipRequestsSent } from 'betterme-components/services/LeadershipRequestSent/actions';
import {
  getChangeLeaderDismissReasons,
  putChangeLeader,
} from './api';

function* getChangeLeaderDismissReasonsWorker({ payload: { reasonType } }) {
  try {
    const response = yield call(getChangeLeaderDismissReasons, reasonType);
    yield put(getChangeLeaderDismissReasonSuccess(response));
  } catch (error) {
    yield put(getChangeLeaderDismissReasonFail(error.message));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
};

//TODO: this action must receive an object
function* putChangeLeaderWorker({ payload: { requestId, status, reasonId, comment, identification, message, modalType, modalProps } }) {
  try {
    const data = {
      comment,
      reasonId,
      status,
    };
    const response = yield call(putChangeLeader, requestId, data);

    if (status === APPROVED) {
      yield put(putChangeLeaderSuccess(response));
      yield put(openModal({
        modalType,
        modalProps,
      }));
      yield put(getLeadershipRequestsReceived(RECEIVED));
    }
    else if (status === CANCELED) {
      yield put(putChangeLeaderSuccess(response));
      yield put(getLeadershipRequestsSent(SENT));
      yield put(getLeadershipRequestsReceived(RECEIVED));
    }
    else {
      yield put(putChangeLeaderSuccess(response));
      yield put(addToastMessage({ message, timeout: 3000 }));
      yield put(getLeadershipRequestsReceived(RECEIVED));
    }
  } catch (error) {
    yield put(putChangeLeaderFail(error.message));
    yield put(closeModal());
    yield put(
      addToastMessage({ type: 'error', message: error.message, timeout: 3000 })
    );
  }
};

export default function* changeLeaderRequestWorker() {
  yield takeLatest(GET_CHANGE_LEADER_DISMISS_REASONS, getChangeLeaderDismissReasonsWorker);
  yield takeLatest(PUT_CHANGE_LEADER, putChangeLeaderWorker);
};
