// @own
import { USER_CAROUSEL, RESET_USER_CAROUSEL } from './actionTypes';

const initialState = {
  userCarousel: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case USER_CAROUSEL:
      return {
        ...state,
        userCarousel: payload,
      };

      case RESET_USER_CAROUSEL:
        return {
          ...state,
          userCarousel: null,
        };
  
    default:
      return state;
  }
};
