// @packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Button from 'smu-ui-components/ButtonV2';

// @app
import SectionTitle from 'components/atoms/SectionTitle';
import FilterCheckboxes from 'components/molecules/SuggestedActionsList';

// @own
import './styles.scss';

const TeamEvaluationsFilterBy = ({
  className,
  filterConfig,
  messages: {
    buttonText,
    status: { draft, foreignDraft, notStarted, submitted, submittedByMe, submittedByOther },
    subtitle,
    title,
  },
  newStyle,
  onApply,
}) => {
  const [localFilterConfig, setFilterConfig] = useState(filterConfig);

  const handleChange = (element) => {
    const selector = element.name;
    const value = !element.value === localFilterConfig[selector];
    const newState = {
      [selector]: value,
    }
    setFilterConfig({
      ...localFilterConfig,
      ...newState,
    })
  };

  const handleOnApply = () => {
    onApply(localFilterConfig);
  };

  return (
    <>
      <div className={cn('evaluations-filter', className, {
        "evaluations-filter--new-style": newStyle
      })}>
        <SectionTitle
          className="evaluations-filter__heading"
          description={subtitle}
          noPaddingTop
          noborder
          nopadding
          title={title}
        >
          <div className="evaluations-filter__wrapper">
            <FilterCheckboxes
              className="evaluations-filter__checkboxes"
              onChange={(event) => handleChange(event.target)}
              suggestedActions={[
                {
                  className: 'evaluations-filter__option',
                  disabled: false,
                  name: submittedByMe,
                  selected: localFilterConfig[submittedByMe],
                },
                {
                  className: 'evaluations-filter__option',
                  disabled: false,
                  name: submittedByOther,
                  selected: localFilterConfig[submittedByOther],
                },
                {
                  className: 'evaluations-filter__option',
                  disabled: false,
                  name: draft,
                  selected: localFilterConfig[draft],
                },
                {
                  className: 'evaluations-filter__option',
                  disabled: false,
                  name: foreignDraft,
                  selected: localFilterConfig[foreignDraft],
                },
                {
                  className: 'evaluations-filter__option',
                  disabled: false,
                  name: notStarted,
                  selected: localFilterConfig[notStarted],
                },
              ]}
            />
          </div>
          <div className="evaluations-filter__button-wrapper">
            <Button
              className="evaluations-filter__button"
              color={newStyle ? "unifyBlue" : ""}
              enabledUnification={newStyle}
              onClick={() => handleOnApply()}
              variant={newStyle ? "" : "outline"}
            >
              {buttonText}
            </Button>
          </div>
        </SectionTitle>
      </div>
    </>
  );
};

TeamEvaluationsFilterBy.propTypes = {
  className: PropTypes.string,
  filterConfig: PropTypes.array,
  messages: PropTypes.array,
  newStyle: PropTypes.bool,
  onApply: PropTypes.func,
};

export default TeamEvaluationsFilterBy;
