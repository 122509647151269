// @packages
import React from 'react';
import ContentCardTemplate from 'components/templates/ContentCardTemplate';
import PropTypes from 'prop-types';
import cn from 'classnames'

// @own
import './styles.scss';

const PotentialAssessmentResultsInsight = ({
  className,
  insightData: {
    description,
    icon,
    title,
  },
}) => {

  return (
    <ContentCardTemplate
      className={cn('potential-assessment-results-insight', className)}
      title={title}
    >
      <ContentCardTemplate.Body>
        <div className="potential-assessment-results-insight__content">
          <img
            className="potential-assessment-results-insight__content-img"
            src={icon}
            alt="light-icon"
          />
          <div className="potential-assessment-results-insight__content-text">
            {description}
          </div>
        </div>
      </ContentCardTemplate.Body>
    </ContentCardTemplate>
  );
};

PotentialAssessmentResultsInsight.propTypes = {
  className: PropTypes.string,
  insightData: PropTypes.objectOf(PropTypes.shape({
    description: PropTypes.string,
    title: PropTypes.string,
  })),
};

export default PotentialAssessmentResultsInsight;
