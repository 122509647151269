// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

// @app
import ContentCardTemplate from 'components/templates/ContentCardTemplate';
import MyGrowth from 'components/molecules/MyGrowth';

// @own
import messages from './messages';

const MyGrowthWidget = ({
  intl: { formatMessage },
  onToggleClick,
  open,
}) => (
  <ContentCardTemplate
    onToggleClick={onToggleClick}
    open={open}
    title={formatMessage(messages.MyGrowthWidgetHeading)}
  >
    <ContentCardTemplate.Body>
      <MyGrowth
        buttonText={formatMessage(messages.MyGrowthWidgetButtonText)}
        title={formatMessage(messages.MyGrowthWidgetTitle)}
      />
    </ContentCardTemplate.Body>
  </ContentCardTemplate>
);

MyGrowthWidget.propTypes = {
  intl: PropTypes.object,
  onToggleClick: PropTypes.func,
  open: PropTypes.bool,
}

export default injectIntl(MyGrowthWidget);
