import { trackEvent } from 'smu-utils/gtm';

const OTHER_PROFILE = 'Other_Profile';
const CHANGE_LEADER = 'Change_Leader';

export const trackAdminLeadershipClick = () => {
  trackEvent({
    category: OTHER_PROFILE,
    action: 'Admin_Leadership',
  });
};

export const trackAdminLeadershipRemove = type => {
  trackEvent({
    category: OTHER_PROFILE,
    action: 'Remove',
    type,
  });
};

export const trackAdminLeadershipReplace = type => {
  trackEvent({
    category: OTHER_PROFILE,
    action: 'Replace',
    type,
  });
};

export const trackAdminLeadershipAddMeAs = type => {
  trackEvent({
    category: OTHER_PROFILE,
    action: 'Add_Me',
    type,
  });
};

export const trackAdminLeadershipAddMeSuccess = type => {
  trackEvent({
    category: OTHER_PROFILE,
    action: 'Add_Me_Success',
    type,
  });
};

export const trackAdminLeadershipAddMeCancel = type => {
  trackEvent({
    category: OTHER_PROFILE,
    action: 'Add_Me_Cancel',
    type,
  });
};

export const trackAdminLeadershipSearchUser = type => {
  trackEvent({
    category: CHANGE_LEADER,
    action: 'Search_Leader',
    type,
  });
};

export const trackAdminLeadershipSelectUser = type => {
  trackEvent({
    category: CHANGE_LEADER,
    action: 'Select_Leader',
    type,
  });
};

export const trackAdminLeadershipChange = type => {
  trackEvent({
    category: CHANGE_LEADER,
    action: 'Change_Leader',
    type,
  });
};

export const trackAdminLeadershipChangeCancel = type => {
  trackEvent({
    category: CHANGE_LEADER,
    action: 'Cancel',
    type,
  });
};

export const trackAdminLeadershipRemoveContinue = ({ type, label }) => {
  trackEvent({
    action: 'Remove_Continue',
    category: CHANGE_LEADER,
    label,
    type,
  });
};

export const trackAdminLeadershipRemoveCancel = type => {
  trackEvent({
    action: 'Remove_Cancel',
    category: CHANGE_LEADER,
    type,
  });
};

export const trackAdminLeadershipRemoveSuccess = type => {
  trackEvent({
    action: 'Remove_Success',
    category: CHANGE_LEADER,
    type,
  });
};

export const trackAdminLeadershipRemoveClose = type => {
  trackEvent({
    action: 'Remove_Close',
    category: CHANGE_LEADER,
    type,
  });
};
