// @own
import {
  REQUEST_LOCALE,
  CHANGE_LOCALE,
  REQUEST_LOCALE_SUCCESS,
  REQUEST_LOCALE_FAILED,
} from './actionTypes';

export function requestLocale(locale, applicationId) {
  return {
    type: REQUEST_LOCALE,
    payload: { locale, applicationId }
  };
}

export function requestLocaleSuccess(locale, messages) {
  return {
    type: REQUEST_LOCALE_SUCCESS,
    payload: { locale, messages },
  };
}

export function requestLocaleFailed(error) {
  return {
    type: REQUEST_LOCALE_FAILED,
    payload: { error },
  };
}

export function changeLocale(locale) {
  return {
    type: CHANGE_LOCALE,
    payload: { locale },
  };
}
