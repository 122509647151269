// @packages
import { defineMessages } from 'react-intl';

export default defineMessages({
  FocalRemoveLeaderModalTitle: {
    id: 'Betterme.FocalRemoveLeaderModal.Title',
    defaultMessage: 'You are about to remove a leader',
  },
  FocalRemoveLeaderModalDescription: {
    id: 'Betterme.FocalRemoveLeaderModal.Description',
    defaultMessage: 'A notification will be sent to the Leader and the Glober in order to inform them about this change. Do you want to proceed?',
  },
  FocalRemoveLeaderModalActionRemove: {
    id: 'Betterme.FocalRemoveLeaderModal.ActionRemove',
    defaultMessage: 'Yes, remove',
  },
});
