import { takeLatest, call, put } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import {
  getAssignedGoals,
} from '../../api';
import {
  GET_GOALS_ASSIGNED,
  GET_GOALS_ASSIGNED_CLOSED,
  GET_GOALS_ASSIGNED_OPENED,
} from './actionTypes';
import {
  getGoalsAssignedFail,
  getGoalsAssignedSuccess,
  getGoalsAssignedClosedFail,
  getGoalsAssignedClosedSuccess,
  getGoalsAssignedOpenedFail,
  getGoalsAssignedOpenedSuccess,
} from './actions';

function* getGoalsAssignedWorker({ payload: { userId, filters } }) {
  try {
    const data = {
      userId,
      ...filters,
    };
    const response = yield call(getAssignedGoals, data);
    yield put(getGoalsAssignedSuccess(response));
  } catch (error) {
    yield put(getGoalsAssignedFail(error));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

function* getGoalsAssignedClosedWorker({ payload: { userId, filters } }) {
  try {
    const data = {
      userId,
      status: 'CLOSED',
      ...filters,
    };
    const response = yield call(getAssignedGoals, data);
    yield put(getGoalsAssignedClosedSuccess(response));
  } catch (error) {
    yield put(getGoalsAssignedClosedFail(error));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

function* getGoalsAssignedOpenedWorker({ payload: { userId, filters } }) {
  try {
    const data = {
      userId,
      status: 'OPENED',
      ...filters,
    };
    const response = yield call(getAssignedGoals, data);
    yield put(getGoalsAssignedOpenedSuccess(response));
  } catch (error) {
    yield put(getGoalsAssignedOpenedFail(error));
    yield put(addToastMessage({ type: 'error', message: error.message, timeout: 3000 }));
  }
}

export default function* goalsWatcher() {
  yield takeLatest(GET_GOALS_ASSIGNED, getGoalsAssignedWorker);
  yield takeLatest(GET_GOALS_ASSIGNED_CLOSED, getGoalsAssignedClosedWorker);
  yield takeLatest(GET_GOALS_ASSIGNED_OPENED, getGoalsAssignedOpenedWorker);
}
