// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

//@own
import './styles.scss';

const SummaryTable = ({
  className,
  dataTable,
  description,
  fields,
  headerColSpan,
  title,
}) => (
  <div className={cn('summary-table', className)}>
    <table className="summary-table__table">
      <thead>
        {(title || description) && (
          <tr>
            <th colSpan={headerColSpan} className="summary-table__table-header">
              {title && (
                <div className="summary-table__table-header-title">
                  {title}
                </div>
              )}
              {description && (
                <p className="summary-table__table-header-description">
                  {description}
                </p>
              )}
            </th>
          </tr>
        )}
        <tr>
          {fields?.map(({ headerClassName, name, viewName }) => (
            <th key={name} className="summary-table__table-header-body">
              <div className={headerClassName} >
                {viewName}
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {dataTable?.map((row, index) => (
          <tr key={row?.id || index} >
            {fields?.map(({ customView, fixedWidth, name }) => (
              <td key={name} width={fixedWidth} className="summary-table__table-body">
                {customView ? (customView(row)) : (row[name])}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </div>);

SummaryTable.propTypes = {
  /**
   * Component className.
   */
  className: PropTypes.string,
  /**
   * Table data.
   */
  dataTable: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * Table description.
   */
  description: PropTypes.string,
  /**
   * Field name por each column.
   */
  fields: PropTypes.arrayOf(PropTypes.object),
  /**
   * Table headerColSpan.
   */
  headerColSpan: PropTypes.number.isRequired,
  /**
   * Table title.
   */
  title: PropTypes.string,
}

export default SummaryTable;
