// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Icon from 'smu-ui-components/Icon';

//@app
import AssessmentEditModal from 'components/containers/AssessmentEditModal';
import AssessmentQuestionsCards from 'components/containers/AssessmentQuestionsCards';
import AssessmentResultCard from 'components/organisms/AssesmentResultCards';
import LeftColumn from 'components/organisms/LeftColumn';
import MessageBox from 'components/organisms/MessageBox';
import SendAssessmentConfirmationModal from 'components/containers/SendAssessmentConfirmationModal';
import SummaryTable from 'components/molecules/SummaryTable';
import { POTENTIAL_ASSESSMENT_EDIT_MODAL } from 'betterme-components/constants/modalTypes';
import { handleSliceQuestions } from 'utils/assessmentHelper';
import { openModal } from 'smu-app-components/RootModal/actions';

// @own
import './styles.scss';

const AssessmentFormTemplate = ({
  assessmentBehaviourQuestions,
  currentTab,
  formatMessage,
  handleAssessmentQuestions,
  handleChangeRadio,
  handleResultAnswers,
  headerData,
  infoOpen,
  isSummaryAnswered,
  isSummarySended,
  leftColumnTexts,
  messages,
  modifyScore,
  openModal,
  options,
  questionsSlicedSize,
  resultAnswers,
  setInfoOpen,
  summaryTexts: {
    answer,
    behaviour,
    description,
    sectionTitle,
  },
  tableHeaderColSpan,
  tooltipText,
  userInfo,
}) => {
  const onClickSaveChanges = (newResponse) => {
    handleAssessmentQuestions(newResponse);
  };

  const onClickEdit = (question) => {
    openModal({
      modalType: POTENTIAL_ASSESSMENT_EDIT_MODAL,
      modalProps: {
        onClickSaveChanges,
        options,
        question,
      },
    });
  };

  const {
    behaviours,
    behavioursDescription,
    results,
    resultsDescription,
    summary,
    summaryDescription,
  } = leftColumnTexts;

  const STEPS = [
    {
      name: behaviours,
      description: behavioursDescription,
      finished: isSummaryAnswered,
      isActive: true,
      childSteps: handleSliceQuestions(assessmentBehaviourQuestions, questionsSlicedSize).length,
      currentStep: currentTab,
    },
    {
      name: summary,
      description: summaryDescription,
      finished: isSummarySended,
      isActive: isSummaryAnswered,
    },
    {
      name: results,
      description: resultsDescription,
      isActive: isSummarySended,
    },
  ];
  const fields = [
    {
      headerClassName: 'assessment-form__summary-table-behaviour-header',
      name: 'name',
      viewName: behaviour,
    },
    {
      customView: ({ answer }) => (
        <span className="assessment-form__summary-table-response">
          {options?.find(({ id }) => answer === id)?.name}
        </span>
      ),
      fixedWidth: '35%',
      headerClassName: 'assessment-form__summary-table-response-header',
      name: 'response',
      viewName: answer,
    },
    {
      customView: (question) => (
        <Icon
          className="assessment-form__summary-table-edit-button"
          icon='action-edit'
          onClick={() => onClickEdit(question)}
        />
      ),
      headerClassName: 'assessment-form__summary-table-edit-button-header',
      name: 'editButton',
      viewName: '',
    }
  ];
  const messageBoxDescription = isSummarySended ?
    messages?.PotentialAssessmentResultWarningDescription
    : messages?.PotentialAssessmentSummaryWarningDescription;



  return (
    <div className="assessment-form">
      <div className="assessment-form__container">
        {(infoOpen && isSummaryAnswered) && (
          <MessageBox
            className="mb-6"
            canBeClosed
            closeIconSize={16}
            iconColor="message-box-informative-icon"
            iconSize={20}
            message={formatMessage(messageBoxDescription)}
            onClose={() => setInfoOpen(!infoOpen)}
            variant="warning"
          />
        )}
        <div className="flex">
          <LeftColumn
            className="w-[320px] h-[655px]"
            currentTab={currentTab}
            steps={STEPS}
            userInfo={userInfo}
          />
          {(!isSummaryAnswered) &&
            <AssessmentQuestionsCards
              assessmentQuestions={assessmentBehaviourQuestions}
              cardClassName="assessment-form__questions-card"
              className="assessment-form__questions"
              currentTab={currentTab}
              handleQuestions={handleAssessmentQuestions}
              options={options}
              questionsSlicedSize={questionsSlicedSize}
            />
          }
          {(isSummaryAnswered && !isSummarySended) &&
            <div className="assessment-form__card">
              <SummaryTable
                className="assessment-form__summary-table"
                dataTable={assessmentBehaviourQuestions}
                description={description}
                fields={fields}
                headerColSpan={tableHeaderColSpan}
                title={sectionTitle}
              />
              <SendAssessmentConfirmationModal />
              <AssessmentEditModal />
            </div>
          }
          {(isSummarySended) &&
            <div className="assessment-form__card">
              <AssessmentResultCard
                formatMessage={formatMessage}
                handleChangeRadio={handleChangeRadio}
                handleResultAnswers={handleResultAnswers}
                headerData={headerData}
                modifyScore={modifyScore}
                resultAnswers={resultAnswers}
                tooltipText={tooltipText}
                userInfo={userInfo}
              />
            </div>
          }
        </div>
      </div>
    </div>
  );
};

AssessmentFormTemplate.propTypes = {
  assessmentBehaviourQuestions: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
  currentTab: PropTypes.number.isRequired,
  handleAssessmentQuestions: PropTypes.func.isRequired,
  handleChangeRadio: PropTypes.func,
  handleResultAnswers: PropTypes.func,
  headerData: PropTypes.object,
  isFormComplete: PropTypes.bool.isRequired,
  isSummaryAnswered: PropTypes.bool.isRequired,
  modifyScore: PropTypes.bool,
  openModal: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  questionsSlicedSize: PropTypes.number.isRequired,
  resultAnswers: PropTypes.object,
  summaryQuestion: PropTypes.object.isRequired,
  tableHeaderColSpan: PropTypes.number,
  userInfo: PropTypes.object,
};

const mapDispatchToProps = {
  openModal,
};

export default connect(null, mapDispatchToProps)
  (AssessmentFormTemplate);
