// @packages
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import NotificationsComponent from 'smu-app-components/NotificationsV2';
import usePrevious from 'smu-custom-hooks/usePrevious';
import { actions as routeActions } from 'utils/routes';
import { connect } from 'react-redux';
import { isOtherNotification } from 'utils/checkNotificationsType';
import { trackEvent } from 'smu-utils/gtm';
import { withRouter } from 'react-router';

// @app
import { externalLinkRedirect } from 'utils/external';

// @own
import { getIconProps } from './helpers';
import messages from './messages';
import NotificationsAPI from './api';

const { getNotificationsCount, getNotifications, changeNotificationsStatus } = NotificationsAPI;

const Notifications = ({
  all,
  fetching,
  notifications,
  opened,
  router,
  showActivity,
  showCongratulation,
  showHome,
  showNotificationsHistory,
  unifiedExperience,
}) => {
  const prevFetching = usePrevious(fetching);

  const onOpenNotifications = () => {
    trackEvent({
      action: 'Notifications',
      category: 'Menu',
      label: 'click',
    });
  };

  const handleClickItem = (selected) => {
    externalLinkRedirect(selected.target, router);

    trackEvent({
      action: 'Detail',
      category: 'Notifications',
      description: selected?.description,
      label: selected?.type,
      title: selected?.notification,
      type: selected?.isRead ? 'read' : 'unread',
    });
  };

  const viewAllHandler = () => {
    trackEvent({
      category: 'Notifications',
      action: 'View_All',
    });
    if (showNotificationsHistory) {
      showNotificationsHistory();
    }
  };

  useEffect(() => {
    const fetchingFinished = prevFetching && !fetching;
    if (fetchingFinished && opened) {
      if (notifications.length) {
        notifications.forEach(notification => {
          trackEvent({
            action: 'View',
            category: 'Notifications',
            description: notification?.description,
            label: notification?.type,
            title: notification?.notification,
            type: notification?.isRead ? 'read' : 'unread',
          });
        });
      } else {
        trackEvent({
          action: 'Empty_State',
          category: 'Notifications',
        });
      }
    }
  }, [prevFetching, fetching, opened]);

  return (
    <NotificationsComponent
      apiCount={getNotificationsCount}
      apiGet={getNotifications}
      apiPut={changeNotificationsStatus}
      cleanViewedNotifications={false}
      dimmed={opened}
      handleClickViewAllNotifications={notifications?.length ? viewAllHandler : undefined}
      iconColor={(n) => getIconProps(n)?.color}
      iconProps={getIconProps}
      iconType={(n) => getIconProps(n)?.icon}
      isLoading={false}
      // isSmuNotification
      messages={messages}
      onClickItem={(selected) => handleClickItem(selected)}
      onOpenNotifications={onOpenNotifications}
      unifiedExperience={unifiedExperience}
    />
  );
};

Notifications.propTypes = {
  opened: PropTypes.bool,
  showActivity: PropTypes.func.isRequired,
  showCongratulation: PropTypes.func.isRequired,
  showHome: PropTypes.func.isRequired,
  showNotificationsHistory: PropTypes.func.isRequired,
  unifiedExperience: PropTypes.bool,
};

const mapStateToProps = ({ notificationsV2 }) => ({
  fetching: notificationsV2?.fetching,
  notifications: notificationsV2?.list,
  opened: notificationsV2?.opened,
  all: notificationsV2,
});

const actions = {
  showActivity: activityId => routeActions.activity({ activityId }),
  showCongratulation: id => routeActions.congratulation({ id }),
  showHome: () => routeActions.home(),
  showNotificationsHistory: () => routeActions.notificationsHistory(),
};

export default connect(mapStateToProps, actions)(withRouter(Notifications));
