import { defineMessages } from 'react-intl';

export default defineMessages({
  FeedbackRequired: {
    id: 'Betterme.FeedbackRequiredModal.FeedbackRequired',
    defaultMessage: 'Feedback Required',
  },
  SendFeedback: {
    id: 'Betterme.FeedbackRequiredModal.SendFeedback',
    defaultMessage: 'In order to remove yourself from this leadership you must have to send a feedback for {collaborator}.',
  },
});
