export function getLocalStoredItem(item) {
  return JSON.parse(localStorage.getItem(item));
};

export function removeLocalStoredItem(item) {
  return localStorage.removeItem(item);
};

export function setLocalStoredItem(itemName, item) {
  return localStorage.setItem(itemName, JSON.stringify(item));
};
