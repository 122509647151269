// @packages
import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';

// @own
import './styles.scss';
import messages from '../messages';
import SkillItem from '../SkillItem';
import SkillSearchBox from '../SkillSearchBox';

const SkillSelector = ({
  className,
  intl: { formatMessage },
  isEvaluation,
  onInputChange,
  onSelect,
  onSkillClick,
  selectedSkills,
  skills,
}) => {
  const excededSkills = selectedSkills?.length >= 10;
  const skillsSelectorText = isEvaluation
    ? formatMessage(messages.SkillSelectorSkillLimitTextEvaluation)
    : formatMessage(messages.SkillSelectorSkillLimitText);
  return (
    <div className={cn('skill-selector', className)}>
      <p className="skill-selector__text">
        {skillsSelectorText}
      </p>
      <SkillSearchBox
        className="skill-selector__search-box"
        disabled={excededSkills}
        onChange={onSelect}
        onInputChange={onInputChange}
        skills={skills}
      />
      <ul className="skill-selector__list">
        {selectedSkills.map(skill =>
          <SkillItem
            {...skill}
            key={skill.id}
            onClick={onSkillClick}
          />
        )}
      </ul>
    </div>
  );
};

SkillSelector.propTypes = {
  className: PropTypes.string,
  intl: PropTypes.object,
  isEvaluation: PropTypes.bool,
  onInputChange: PropTypes.func,
  onSelect: PropTypes.func,
  onSkillClick: PropTypes.func,
  selectedSkills: PropTypes.array,
  skills: PropTypes.array,
};

export default injectIntl(SkillSelector);
