// @packages
import React from 'react';
import PropTypes from 'prop-types';
import RootModal from 'smu-app-components/RootModal';
import { actionRequestInit } from 'smu-utils/reduxRequests/actions';
import { add as showToast } from 'smu-app-components/ToastNotifications/actions';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

// @app
import GenericModal from 'components/templates/GenericModalTemplate';
import InfoCard from 'components/molecules/InfoCard';
import { FOCAL_REMOVE_LEADER_MODAL } from 'betterme-components/constants/modalTypes';
import { actionsMessages } from 'common/messages';
import { centerModalClass } from 'betterme-components/ConfirmationModal';

// @own
import messages from './messages';
import { REQUEST_ID_PATCH_FOCAL_LEADER } from './constants';
import { patchLeadershipAdminLeaders } from './api';

const FocalRemoveLeaderModal = ({
  actionRequestInit,
  closeModal,
  communityId,
  goBack,
  intl: { formatMessage },
  leaderType,
  onSuccessMessage,
  removeThisUser,
  showToast,
  user,
}) => {
  function handleClose() {
    closeModal();
  };

  function handleContinue() {
    const data = [{
      created: false,
      mainLeader: leaderType === 'main',
      memberId: removeThisUser,
    }];

    actionRequestInit({
      api: patchLeadershipAdminLeaders,
      id: REQUEST_ID_PATCH_FOCAL_LEADER,
      params: {
        communityId: communityId,
        memberId: user?.id,
        data,
      },
      onSuccess: () => showToast({
        message: onSuccessMessage,
        timeout: 5000,
      }),
      onError: (error) => showToast({
        message: error.message,
        timeout: 5000,
      })
    });
    closeModal();
    goBack();
  }

  const actionButtons = [
    {
      label: formatMessage(actionsMessages.ActionsCancel),
      onClick: handleClose,
      variant: 'outline',
    },
    {
      label: formatMessage(messages.FocalRemoveLeaderModalActionRemove),
      onClick: handleContinue,
    },
  ];

  return (
    <GenericModal
      actionButtons={actionButtons}
      header={
        <InfoCard
          icon="info"
          message={formatMessage(messages.FocalRemoveLeaderModalDescription)}
          title={formatMessage(messages.FocalRemoveLeaderModalTitle)}
        />
      }
      onClose={handleClose}
      success
    />
  );
};

FocalRemoveLeaderModal.propTypes = {
  closeModal: PropTypes.func,
  intl: PropTypes.object,
  showToast: PropTypes.func,
};

const modalStyles = {
  width: '610px',
};

const mapDispatchToProps = {
  actionRequestInit,
  showToast,
}

export default RootModal(
  injectIntl(connect(null, mapDispatchToProps)(FocalRemoveLeaderModal)),
  FOCAL_REMOVE_LEADER_MODAL,
  modalStyles,
  centerModalClass,
);
