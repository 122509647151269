// @packages
import React from 'react';
import PropTypes from 'prop-types';
import EvaluationDetail from '../../EvaluationDetail';

// @own
import AccessDeniedEmptyState from '../AccessDeniedEmptyState';

const ProfileEvaluationsDetail = (props) => {
  const { params, user } = props;
  const { access: { evaluations } } = user;
  const { userid } = params;

  return evaluations ? (
    <EvaluationDetail
      noFixed
      {...props}
    />
  ) : (
    <AccessDeniedEmptyState
      user={user}
      userId={userid}
    />
  );
};

ProfileEvaluationsDetail.propTypes = {
  user: PropTypes.object,
};

export default ProfileEvaluationsDetail;
