import { takeLatest, call, put, select } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import { openModal } from 'smu-app-components/RootModal/actions';
import { CONFIRMATION_CREATE_SUCCESS_MODAL } from 'betterme-components/constants/modalTypes';
import {
  createFeedbackForLeaders,
  getCommunityRatings,
  getQuestions,
} from 'betterme-components/api';
import {
  CREATE_FEEDBACKS_FOR_LEADERS,
  GET_QUESTIONS_FEEDBACKS_FOR_LEADERS,
  GET_RATINGS_FEEDBACKS_FOR_LEADERS,
} from './actionTypes';
import {
  createFeedbackForLeadersFail,
  createFeedbackForLeadersSuccess,
  getQuestionsFeedbackForLeadersFail,
  getQuestionsFeedbackForLeadersSuccess,
  getRatingsFeedbackForLeadersFail,
  getRatingsFeedbackForLeadersSuccess,
} from './actions';

const feedbackForLeadersParams = {
  type: 'FEEDBACK_FOR_LEADERS',
};

function* createFeedbackForLeadersWorker({ payload: { data }, messages, user }) {
  const dataValues = {
    answers: data,
    leaderId: user.id,
  };

  try {
    const response = yield call(createFeedbackForLeaders, dataValues);
    yield put(createFeedbackForLeadersSuccess(response));
    yield put(openModal({
      modalType: CONFIRMATION_CREATE_SUCCESS_MODAL,
      modalProps: {
        contentMessage: messages.successContent,
        footerMessage: messages.successFooter,
        user,
      },
    }));
  } catch (error) {
    yield put(createFeedbackForLeadersFail(error.message));
    yield put(addToastMessage({ message: error.message, timeout: 3000 }));
  }
}

function* getQuestionsFeedbackForLeadersWorker({ payload: { params } }) {
  const getParams = {
    ...params,
    ...feedbackForLeadersParams,
  };

  try {
    const response = yield call(getQuestions, getParams);
    yield put(getQuestionsFeedbackForLeadersSuccess(response));
  } catch (error) {
    yield put(getQuestionsFeedbackForLeadersFail(error.message));
    yield put(addToastMessage({ message: error.message, timeout: 3000 }));
  }
}

function* getRatingsFeedbackForLeadersWorker({ payload: { params } }) {
  const getParams = {
    ...params,
    ...feedbackForLeadersParams,
  };

  try {
    const response = yield call(getCommunityRatings, getParams);
    yield put(getRatingsFeedbackForLeadersSuccess(response));
  } catch (error) {
    yield put(getRatingsFeedbackForLeadersFail(error.message));
    yield put(addToastMessage({ message: error.message, timeout: 3000 }));
  }
}

export default function* getQuestionsFeedbackForLeadersWatcher() {
  yield takeLatest(CREATE_FEEDBACKS_FOR_LEADERS, createFeedbackForLeadersWorker);
  yield takeLatest(GET_QUESTIONS_FEEDBACKS_FOR_LEADERS, getQuestionsFeedbackForLeadersWorker);
  yield takeLatest(GET_RATINGS_FEEDBACKS_FOR_LEADERS, getRatingsFeedbackForLeadersWorker);
}
