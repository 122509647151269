import {
  SET_SELF_RETROSPECTIVE,
  SET_SELF_RETROSPECTIVE_FAIL,
  SET_SELF_RETROSPECTIVE_SUCCESS,
  SET_SELF_RETROSPECTIVE_BEHAVIOURS,
  RESET_SELF_RETROSPECTIVE,
  RESET_SELF_RETROSPECTIVE_BEHAVIOURS,
} from './actionTypes';

export function setSelfRetrospective(payload) {
  return {
    type: SET_SELF_RETROSPECTIVE,
    payload,
  };
}

export function setSelfRetrospectiveFail(error) {
  return {
    type: SET_SELF_RETROSPECTIVE_FAIL,
    payload: error,
  };
}

export function setSelfRetrospectiveSuccess(response) {
  return {
    type: SET_SELF_RETROSPECTIVE_SUCCESS,
    payload: response,
  };
}

export function resetSelfRetrospective() {
  return {
    type: RESET_SELF_RETROSPECTIVE,
  };
}

export function setSelfRetrospectiveBehaviours(payload) {
  return {
    type: SET_SELF_RETROSPECTIVE_BEHAVIOURS,
    payload,
  };
}

export function resetSelfRetrospectiveBehaviours() {
  return {
    type: RESET_SELF_RETROSPECTIVE_BEHAVIOURS,
  };
}
