// @packages
import React from 'react';

export default function (WrappedComponent) {
  class WithDragState extends React.Component {
    constructor() {
      super();
      this.state = {
        isDragging: false,
      };
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          onBeginDrag={() => this.setState({ isDragging: true })}
          onEndDrag={() => this.setState({ isDragging: false })}
          isDragging={this.state.isDragging}
        />
      );
    }
  }

  WithDragState.displayName = `WithDragState${getDisplayName(WrappedComponent)}`;
  return WithDragState;
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}
