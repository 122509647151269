import { handleServerError } from 'betterme-components/api';
import { apiInstance } from 'betterme-components/utils/requestHelper';

export function getCommunityScores() {
  return apiInstance.get(`api/scores`)
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getCommunityRatings(params) {
  return apiInstance.get('api/ratings', { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getCommunityTopics(params) {
  return apiInstance.get('api/topics', { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}
