// @packages
import Axios from 'axios';
import get from 'lodash/get';
import { apiGetCommunityById } from 'smu-services/betterme/api/communities/[id]/get/api';
import { getItem } from 'smu-utils/storage';
import { stringify } from 'querystring';

// @app
import escapeHtml from 'utils/escapeHtml';
import getDataFile from '../utils/getDataFile';
import {
  dataServiceApiInstance,
  apiInstance,
  apiInstanceOsMember,
  apiInstanceSMU,
} from '../utils/requestHelper';
import { store } from '../../index';
import { logout } from '../Authorization/actions';
import { setAppError } from '../App/actions';

function sanitizeApiError(error) {
  let errorInfo;

  if (error.response) {
    // The request was made and the server responded
    const status = get(error.response, 'status');

    errorInfo = {
      message: get(error.response, 'data.message', `Request failed with status code: ${status}`),
      status,
    };
  } else if (error.request) {
    // The request was made but no response was received

    errorInfo = {
      message: get(error.request, 'message', 'Request failed with no response'),
      status: get(error.request, 'status', 0),
    };
  } else {
    // Something happened in setting up the request that triggered an Error
    // Probably JS runtime error

    errorInfo = {
      message: 'Something unexpected happened',
      status: null,
    };
  }

  return errorInfo;
}

function catchHandler(error) {
  const errorInfo = sanitizeApiError(error);
  const status = get(errorInfo, 'status', 0);

  if (status === 401) {
    store.dispatch(logout(error.status));
  }

  return Promise.reject(errorInfo);
}

export function handleServerError(error, hasSetAppError) {
  const errorInfo = sanitizeApiError(error);
  const status = errorInfo?.status || 0;

  if (hasSetAppError && (status >= 500 || status === 0 || status === null)) {
    store.dispatch(setAppError(undefined, error.status));
  }

  if (status === 401 || error?.status === 401) {
    store.dispatch(logout(error.status));
  }

  return Promise.reject(errorInfo);
}

// BME API
export function getCommunityConfigurations(communityId) {
  return apiGetCommunityById({ id: communityId })
    .then((data) => data.result)
    .catch((error) => handleServerError(error, true));
}

export function getCommunitySkills(params) {
  return apiInstance.get('api/skills', { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getCommunityTopics(params) {
  return apiInstance.get('api/topics', { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getCommunityRatings(params) {
  return apiInstance.get('api/ratings', { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getCommunityScores() {
  return apiInstance.get(`api/scores`)
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getCustomScores() {
  return apiInstance.get('api/scores/custom')
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getSuggestedActions(params) {
  return apiInstance.get('api/suggested-actions', { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getSuggestedActionsList(params) {
  return apiInstance.get('api/suggestions', { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getSuggestions() {
  return apiInstance.get('api/users/suggestions')
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function createSuggestedActions({
  comment,
  recipientId,
  suggestedActionsIds,
}) {
  const data = {
    comment,
    recipientId,
    suggestedActionsIds,
  };

  return apiInstance.post('api/suggestions', data)
    .then(({ data }) => data)
    .catch(handleServerError);
}

export function getFeedbacks(params) {
  return apiInstance.get('api/feedbacks', { params })
    .then(({ data }) => data)
    .catch(handleServerError);
}

export function getTeamFeedbacks(params) {
  return apiInstance.get('api/feedbacks/team', { params })
    .then(({ data }) => data)
    .catch(handleServerError);
}

export function getNotificationsCount() {
  const paramsGet = {
    params: {
      statuses: 'CREATED,SENT'
    }
  };
  return apiInstance.get('/notifications/count', paramsGet)
    .then(({ data }) => data.data);
}

export function getNotifications(pageNumber) {
  const paramsGet = {
    params: {
      pageSize: 10,
      pageNumber
    }
  };
  return apiInstance.get('/notifications', paramsGet)
    .then(({ data }) => data);
}

export function changeNotificationsStatus(status, ids = []) {
  const paramsPut = {
    status,
    ids
  };
  return apiInstance.put('/notifications', paramsPut)
    .then(({ data }) => data);
}

export function searchUsers(query, pageSize = 10) {
  const params = {
    isWhitelisted: true,
    pageNumber: 0,
    pageSize,
    query,
  };

  return apiInstance.get(`api/users`, { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function mainSearchUsers(params) {
  return apiInstance.get(`api/users`, { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function smuMainSearchUsers(params) {
  const { searchString } = params;
  const escapedQuery = escapeHtml(searchString);
  const withHashtag = /\s*#(.*)/;
  const filterType = escapedQuery.match(withHashtag) ? 'HASHTAG' : params?.filterType;
  const stringToSearch = escapedQuery.match(withHashtag)
    ? escapedQuery.match(withHashtag)[1]
    : escapedQuery;

  const queryParams = {
    ...params,
    filterType,
    searchString: stringToSearch,
  }

  return apiInstanceSMU.get('/v2/stellar/locate', { params: queryParams })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}


export function sendFeedback(data) {
  const dataFile = getDataFile(data);
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return apiInstance.post(`api/feedbacks`, dataFile, config)
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function sendMultipleFeedback(data) {
  const dataFile = getDataFile(data);
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return apiInstance.post('api/feedbacks/multiple', dataFile, config)
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function sendRequestFeedback(requestId, data) {
  const dataFile = getDataFile(data);
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };

  return apiInstance.post(`api/feedbacks/requests/${requestId}/reply`, dataFile, config)
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getRequestFeedback(requestId) {
  return apiInstance.get(`api/feedbacks/requests/${requestId}`)
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getFeedbacksRequest(params) {
  return apiInstance.get('api/feedbacks/requests', { params })
    .then(({ data }) => data)
    .catch(handleServerError);
}

export function getFeedbacksRequestsReceived(params) {
  return apiInstance.get('api/feedbacks/requests/received', { params })
    .then(({ data }) => data)
    .catch(handleServerError);
}

export function getFeedbacksRequestsSent(params) {
  return apiInstance.get('api/feedbacks/requests/sent', { params })
    .then(({ data }) => data)
    .catch(handleServerError);
}

export function requestFeedback(data) {
  return apiInstance.post(`api/feedbacks/requests`, data)
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function createRequestFeedbackOnBehalf(data) {
  return apiInstance.post(`api/feedbacks/requests/onbehalf`, data)
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getFeedbackRequestDismissReasons(params) {
  return apiInstance.get('api/feedbacks/requests/dismiss/reasons', { params })
    .then(({ data }) => data)
    .catch(handleServerError);
}

export function createFeedbackRequestCancel(requestId) {
  return apiInstance.post(`api/feedbacks/requests/${requestId}/cancel`)
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function createFeedbackRequestDismiss(requestId, data) {
  return apiInstance.post(`api/feedbacks/requests/${requestId}/dismiss`, data)
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getFeedback(id) {
  return apiInstance.get(`api/feedbacks/${id}`)
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function requestComments(feedbackId) {
  return apiInstance.get(`api/feedbacks/${feedbackId}/messages`)
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function sendComment(feedbackId, data) {
  return apiInstance.post(`api/feedbacks/${feedbackId}/messages`, data)
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getFeedbacksSummaryTeam(params) {
  return apiInstance.get('api/feedbacks/summary/team', { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getFeedbacksSummary(params) {
  return apiInstance.get('api/feedbacks/summary', { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

//TODO separate team from profile info
export function getProfile(identification, hasSetAppError) {
  function getUserProfile() {
    return apiInstance.get(`api/users/${identification}`)
      .then(({ data }) => data.result);
  }

  function getUserProfileTeam() {
    return apiInstance.get(`api/v2/users/${identification}/team`)
      .then(({ data }) => data.result);
  }

  return Axios.all([getUserProfile(identification), getUserProfileTeam(identification)])
    .then(Axios.spread((user, { asMainAndSecondary, asMain, asSecondary }) => {
      return {
        ...user,
        collaborators: {
          asMainAndSecondary,
          asMain,
          asSecondary,
        },
      };
    }))
    .catch((error) => handleServerError(error, hasSetAppError));
}

export function getUser(identification) {
  return apiInstance.get(`api/users/${identification}`)
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getUserTeam(identification) {
  return apiInstance.get(`api/users/${identification}/team`)
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function leadershipRequest(requestId, status) {
  return apiInstance.put(`api/users/leaders/request/${requestId}`, { status })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getLeaderRequestModalInfo(requestId) {
  return apiInstance.get(`api/users/leaders/requests/${requestId}`)
    .then(({ data }) => data)
    .catch(handleServerError)
    .catch(catchHandler);
}

export function getOrganizationalObjectives(params) {
  return apiInstance.get(`api/objectives`, { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getSummaryGoals(data) {
  return apiInstance.get('api/v2/goals/summary', { params: data })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getAssignedGoals(data) {
  return apiInstance.get('api/goals/assigned', { params: data })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getGoalFinalScore(userId, dueDateFrom, dueDateTo) {
  const params = {
    userId,
    dueDateFrom,
    dueDateTo,
  };

  return apiInstance.get('api/goals/assigned/closed/score', { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getTeamGoals(data) {
  return apiInstance.get('api/v2/goals/team', { params: data })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getAssignedGoalDetail(id, data) {
  return apiInstance.get(`api/goals/${id}`, { params: data })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getTeamGoalDetail(id, data) {
  return apiInstance.get(`api/goals/${id}`, { params: data })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function createGoalAssessment(id, data) {
  return apiInstance.post(`api/goals/${id}/assessment`, data)
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function createGoal(data) {
  return apiInstance.post('api/goals', data)
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getPeriods(params) {
  return apiInstance.get(`api/periods`, { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function updateGoal(goalId, data) {
  return apiInstance.put(`api/goals/${goalId}`, data)
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function updateApprovalGoal(goalId) {
  return apiInstance.post(`api/goals/${goalId}/approval`)
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function deleteGoal(id) {
  return apiInstance.delete(`api/goals/${id}`)
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function disapprovalGoal(id) {
  return apiInstance.post(`api/goals/${id}/disapproval`)
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function closeGoal(id, data) {
  return apiInstance.post(`api/goals/${id}/closure`, data)
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getDashboardFeedback(params) {
  return apiInstance.get('api/dashboard/feedback', { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getRatedTopics(params) {
  return apiInstance.get(`api/dashboard/topics/rated`, { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getRatedSkills(params) {
  return apiInstance.get(`api/dashboard/skills/rated`, { params })
    .then(({ data }) => data)
    .catch(handleServerError);
}

export function getDashboardGoalsInfo(userId, filters) {
  const params = { userId, ...filters };
  return apiInstance.get(`api/v2/dashboard/goals`, { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getDashboardTopicsOpportunities(params) {
  return apiInstance.get('api/dashboard/topics/opportunities', { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getDashboardTopicsStrengths(params) {
  return apiInstance.get('api/dashboard/topics/strengths', { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getTeamDashboardTopicsStrengths(params) {
  return apiInstance.get('api/dashboard/topics/team/strengths', { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getTeamDashboardTopicsOpportunities(params) {
  return apiInstance.get('api/dashboard/topics/team/opportunities', { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getTeamDashboardEvaluations(params) {
  return apiInstance.get('api/dashboard/team/evaluations', { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getDashboardSuggestions({ userId, creationTimeFrom, creationTimeTo }) {
  const params = {
    userId,
    creationTimeFrom,
    creationTimeTo
  };

  return apiInstance.get('api/dashboard/suggestions', { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getFeedbacksReport(params) {
  return dataServiceApiInstance
    .get('v1/bme/reports/myFeedbacks/export', { params, responseType: 'arraybuffer' })
    .then(({ data }) => data)
    .catch(handleServerError);
}

export function getComunityEvaluations(params) {
  return apiInstance.get('api/evaluations/detail', { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getEvaluations(params) {
  return apiInstance.get('api/evaluations/assigned', { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getLastEvaluation(params) {
  return apiInstance.get('api/evaluations/last-evaluation', { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getSelfEvaluations(params) {
  return apiInstance.get('api/evaluations/self-evaluations', { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getAllEvaluations(params) {
  return apiInstance.get('api/evaluations/team', { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getEvaluationDetail(evaluationId) {
  return apiInstance.get(`api/evaluations/${evaluationId}`)
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function createEvaluation(data) {
  return apiInstance.post('api/evaluations', data)
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function createAnswerEvaluation(evaluationId, data) {
  return apiInstance.post(`api/evaluations/${evaluationId}/answer`, data)
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function createDraftEvaluation(data) {
  return apiInstance.post('api/evaluations/drafts', data)
    .then(({ data }) => data)
    .catch(handleServerError);
}

export function updateDraftEvaluation(evaluationId, data) {
  return apiInstance.put(`api/evaluations/${evaluationId}`, data)
    .then(({ data }) => data)
    .catch(handleServerError);
}

export function deleteEvaluation(evaluationId) {
  return apiInstance.delete(`api/evaluations/${evaluationId}`)
    .then(({ data }) => data)
    .catch(handleServerError);
}

export function submitDraftEvaluation(evaluationId, data) {
  return apiInstance.post(`api/evaluations/drafts/${evaluationId}/submit`, data)
    .then(({ data }) => data)
    .catch(handleServerError);
}

export function updateUserInfo(data) {
  return apiInstance.patch('api/users', data)
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function addLeaderAction({ collaborator, leader }) {
  return apiInstance
    .post(`api/users/leaders/${leader.id}/collaborators/${collaborator.id}`)
    .then(({ data }) => data)
    .catch(handleServerError);
}

export function requestLeader(leaderId, requestId, data) {
  return apiInstance
    .put(`api/users/leaders/${leaderId}/requests/${requestId}`, data)
    .then(({ data }) => data)
    .catch(handleServerError);
}

export function getFeedbackForLeaders(params) {
  return apiInstance
    .get('api/feedbacks/collaborator', { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getFeedbackForLeadersMessages(identification, params) {
  return apiInstance
    .get(`api/feedbacks/collaborator/${identification}/messages`, { params })
    .then(({ data }) => data)
    .catch(handleServerError);
}

export function getPromotionsLevel(params) {
  return apiInstance
    .get('api/promotions/levels', { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function uploadFile(file) {
  const formData = new FormData();
  formData.append('file', file, file.name);

  return apiInstance
    .post('/api/files', formData)
    .then(({ data }) => data)
    .catch(handleServerError);
}

export function deleteFile(fileId) {
  return apiInstance.delete(`/api/files/${fileId}`)
    .then(({ data }) => data)
    .catch(handleServerError);
}

export function createPromotionRequest(data) {
  return apiInstance.post('api/promotions/requests', data)
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getPositionsHistory(params) {
  return dataServiceApiInstance
    .get('/promotions/seniority-history', { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getTeamPromotionsRequests(params) {
  return apiInstance
    .get('api/promotions/requests/team', { params })
    .then(({ data }) => data)
    .catch(handleServerError);
}

export function getUserPromotionRequests(params) {
  return apiInstance
    .get('api/promotions/requests', { params })
    .then(({ data }) => data)
    .catch(handleServerError);
}

export function getPromotionSuggestedValidators(id) {
  return apiInstance
    .get(`/api/promotions/managers/suggestion/${id}`)
    .then(({ data }) => data)
    .catch(handleServerError);
}

export function getFile(fileId) {
  return apiInstance
    .get(`api/files/download/${fileId}`)
    .then(({ data }) => data)
    .catch(handleServerError);
}

export function getPromotionRequestsHistory(params) {
  return apiInstance
    .get('api/promotions/requests/history', { params })
    .then(({ data }) => data)
    .catch(handleServerError);
}

export function getUserPromotionRequestsByPromotionGroup(params) {
  return apiInstance
    .get('api/promotions/requests', { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getLeadersByPeriod() {
  return apiInstance
    .get('api/feedbacks/leadersForFeedback')
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getPromotionsReport(params) {
  return dataServiceApiInstance
    .get(`/v1/bme/reports/promotions/export`, { params, responseType: 'arraybuffer' })
    .then(({ data }) => data)
    .catch(handleServerError);
}

export function getFeedbackForLeaderReport(params) {
  return dataServiceApiInstance
    .get(`v1/reports/feedback/feedbackForLeaders/downloadReport`, { params, responseType: 'arraybuffer' })
    .then(({ data }) => data)
}

export function getFeedbackForLeadersReceived(params) {
  return dataServiceApiInstance
    .get('v1/bme/api/feedbacks/feedbackForLeaders/results', { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getAwatingResultCounter(params) {
  return apiInstance
    .get('/api/feedbacks/feedbackForLeaders/resultCount', { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getQuestions(params) {
  return apiInstance
    .get('api/questions', { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function createFeedbackForLeaders(data) {
  return apiInstance
    .post('api/feedbacks/feedbackForLeaders', data)
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

// SMU API
export function getKudosInformation() {
  return apiInstanceSMU.get('v2/stellar/organizationvalues/1?typeStar=K')
    .then(({ data }) => data)
    .catch(({ data }) => data.error);
}

export function getLocales(params) {
  return apiInstanceSMU.get('v2/stellar/resources/locale', { params })
    .then(({ data }) => data)
    .catch(catchHandler);
}

export function getStarsSummary({
  filterBy,
  fromDate,
  toDate,
  userId,
}) {
  const params = {
    filterBy: filterBy || 'RECEIVED',
    fromDate,
    toDate,
    userId,
  };

  return apiInstanceSMU.get('v2/stellar/stars/summary', { params })
    .then(({ data }) => data)
    .catch(({ data }) => data.error);
}

export function putImageToSMU(image, name) {
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  let formData = new FormData();
  formData.append('file', image);
  formData.append('fileName', name);
  formData.append('token', getItem('token'));

  return apiInstanceSMU.post('v2/image/insert/image', formData, config)
    .then(({ data }) => {
      if (data.status === 'OK') {
        return data.result;
      } else {
        return data.message;
      }
    })
    .catch(({ data }) => data.error);
}

export function reportBug(data) {
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
  };
  const parsedData = stringify(data);
  return apiInstanceSMU.post('v2/stellar/reportBug', parsedData, config)
    .then(({ data }) => data)
    .catch(({ data }) => data.error);
}

// OS Member API
export function getProfileEngagementIndicator(memberId, params) {
  return apiInstanceOsMember
    .get(`api/v1/community/members/${memberId}`, { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getTeamEngagementIndicator(data, params) {
  return apiInstanceOsMember
    .post('api/v1/community/members/metrics', data, { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getLivingValuesTexts(params) {
  return apiInstanceSMU({
    url: '/v2/stellar/resources',
    params,
  })
    .then(({ data }) => data.result)
}

export function getCommunityMember(params) {
  return apiInstanceOsMember
    .get('/api/v1/community/member', { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}

export function getDashboardPerformanceChart({ params }) {
  return dataServiceApiInstance.get('/v1/bme/charts/performance', { params })
    .then(({ data }) => data.result)
    .catch(handleServerError);
}
