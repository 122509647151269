// @package
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @app
import SuggestedAction from 'components/atoms/SuggestedAction';

// @own
import './styles.scss';

const SuggestedActionsList = ({
  className,
  disabled,
  onChange,
  selected,
  suggestedActions,
}) => (
  <div className={cn('suggested-actions-list', className)}>
    <ul className="suggested-actions-list__list">
      {suggestedActions.map(suggestedAction =>
        <li className="suggested-actions-list__list-wrapper" key={suggestedAction.id}>
          <SuggestedAction
            className="suggested-actions-list__list-actions"
            {...suggestedAction}
            disabled={disabled}
            onChange={onChange}
          />
        </li>
      )}
    </ul>
  </div>
);

SuggestedActionsList.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  suggestedActions: PropTypes.array.isRequired,
};

SuggestedActionsList.propTypes = {
  suggestedActions: [],
};

export default SuggestedActionsList;
