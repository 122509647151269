// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import InputRadio from 'smu-ui-components/InputRadio';
import Icon from 'smu-ui-components/IconV2';

// @own
import InputArea from 'smu-ui-components/InputArea';
import messages from './messages';

// @app
import DiscreteProgressBar from 'smu-ui-components/DiscreteProgressBar';

const AssessmentResultCards = ({
  formatMessage,
  handleChangeRadio,
  handleResultAnswers,
  headerData: {
    disclaimer,
    evaluationDate,
  },
  modifyScore,
  resultAnswers: {
    comment,
    scoreRanges,
    selectedScore,
    suggestedScore,
    validScoreRanges,
  },
}) => {
  return (
    <div className="flex flex-col w-[940px]">
      <div className="bg-white rounded-lg flex flex-col mb-8 shadow-base">
        <div className="w-full bg-blue-light rounded-t-lg p-6 text-black border-b-gray-light border-b">
          <span className="text-lg font-montserrat font-bold block mb-2 uppercase">
            {formatMessage(messages?.AssessmentResultCardTitle)}
          </span>
          <span className="block font-openSans text-base">
            {formatMessage(messages?.AssessmentResultCardTitleDescription)}
          </span>
        </div>

        <div className="px-6 py-7">
          <p className="mb-3 text-base text-black">
            {formatMessage(messages?.AssessmentResultCardFirstBarDescription)}
          </p>
          <DiscreteProgressBar
            backgroundColor="#FFFFFF"
            className="mt-7 w-full"
            emptyColor="#F0F2F5"
            highlightSelected
            isBarFilled
            messageDescription
            progress={suggestedScore}
            scores={scoreRanges}
            topPointer
          />
          <div className="my-7">
            <p className="text-base text-black mb-4">
              {formatMessage(messages?.AssessmentResultCardRadioButtonsDescription)}
            </p>
            <InputRadio
              checked={modifyScore === false}
              idName="LeaveAsIs"
              label={formatMessage(messages.AssessmentResultCardLeaveAsIs)}
              name="LeaveAsIs"
              onChange={(event) => handleChangeRadio(event)}
              value={false}
            />
            <InputRadio
              checked={modifyScore === true}
              idName="Modify"
              label={formatMessage(messages.AssessmentResultCardModify)}
              name="Modify"
              onChange={(event) => handleChangeRadio(event)}
              value={true}
            />
          </div>
          {modifyScore &&
            <DiscreteProgressBar
              backgroundColor="#FFFFFF"
              className="mt-7 w-full"
              editing
              emptyColor="#F0F2F5"
              hasPopUps={false}
              highlightSelected
              isBarFilled
              messageDescription
              onChange={newValue => handleResultAnswers('selectedScore', newValue)}
              progress={selectedScore}
              scores={validScoreRanges}
              topPointer
            />
          }
        </div>
      </div>
      <div className="bg-white rounded-lg flex flex-col mb-8 shadow-base" >
        <div className="flex flex-col p-5">
          <span className="text-base pb-6 text-black">{formatMessage(messages?.AssessmentResultCardDetailsDescription)}</span>
          <InputArea
            maxLength={1200}
            onChange={event => handleResultAnswers('comment', event.target.value)}
            placeholder={formatMessage(messages.AssessmentResultCardDetailsPlaceholder)}
            rows={4}
            value={comment}
          />
        </div>
      </div>
      <div className={cn('flex items-center w-full',
        'bg-white p-5 justify-between shadow-base rounded-lg')}
      >
        <span className="font-roboto text-sm text-black block">
          <div className="flex items-center">
            <Icon
              className="mr-[6px]"
              icon="alert"
              size={21}
            />
            {disclaimer}
          </div>
        </span>
        <span className="font-roboto text-sm text-black block">
          <div className="flex items-center">
            <Icon
              className="mr-2"
              icon="calendar"
              size={20}
            />
            {evaluationDate}
          </div>
        </span>
      </div >
    </div >
  )
}

AssessmentResultCards.propTypes = {
  className: PropTypes.string,
  formatMessage: PropTypes.func,
  handleChangeRadio: PropTypes.func,
  handleResultAnswers: PropTypes.func,
  headerData: PropTypes.object,
  modifyScore: PropTypes.bool,
  resultAnswers: PropTypes.object,
  title: PropTypes.string,
  userInfo: PropTypes.object,
  validScoreRanges: PropTypes.array,
};

export default AssessmentResultCards;
