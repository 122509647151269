// @packages
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'smu-ui-components/Icon';

// @own
import './styles.scss';

/**
 * A step shows the completion status of an activity in a series of activities.
 */
const StepV2 = ({
  active,
  className,
  completed,
  description,
  direction,
  disabled,
  finished,
  icon,
  isFirst,
  isLast,
  onClick,
  order,
  title,
  vertical,
  childSteps,
  currentStep,
}) => {
  const pointerContainerRef = useRef(null);
  const offsetTopActivePointer = pointerContainerRef?.current?.children[currentStep]?.offsetTop;

  function renderPointers() {
    let content = [];
    for (let index = 0; index < childSteps; index++) {
      const isActive = index === currentStep && !finished;
      content.push(
        <div className={cn('suite-stepper-step-v2__pointer', {
          'suite-stepper-step-v2__pointer--active': isActive
        })} />
      )
    }

    return content;
  }

  return (
    <div
      className={cn('suite-stepper-step-v2', className, {
        'suite-stepper-step-v2--active': active,
        'suite-stepper-step-v2--completed': completed,
        'suite-stepper-step-v2--direction-column': direction === 'column',
        'suite-stepper-step-v2--disabled': disabled,
        'suite-stepper-step-v2--finished': finished,
        'suite-stepper-step-v2--first': isFirst,
        'suite-stepper-step-v2--last': isLast,
        'suite-stepper-step-v2--vertical': vertical,
      })}
      onClick={!disabled ? onClick : undefined}
    >
      {(vertical && !isLast) && (
        <>
          <div
            style={{ height: finished ? 'calc(100% - 30px)' : offsetTopActivePointer }}
            className="suite-stepper-step-v2__vertical-line"
          />
          <div className="suite-stepper-step-v2__vertical-line--dashed" />
        </>
      )}
      <div
        className={cn('suite-stepper-step-v2__order', {
          'suite-stepper-step-v2__order--icon': icon || completed
        })}
      >
        {vertical && (
          <div className={cn('suite-stepper-step-v2__order-triangle', {
            'suite-stepper-step-v2__order-triangle-first': isFirst,
            'suite-stepper-step-v2__order-triangle-last': isLast,
            'suite-stepper-step-v2__order-triangle-last--finished': isLast && finished,
          })} />
        )}
        {icon || completed ? (
          <Icon icon={completed ? 'check_icon' : icon} />
        ) : (
          order || ''
        )}
        {!isLast && (
          <div
            ref={pointerContainerRef}
            className="suite-stepper-step-v2__pointer-container">
            {renderPointers()}
          </div>
        )}

      </div>
      <div className="suite-stepper-step-v2__text">
        <label className="suite-stepper-step-v2__title">{title}</label>
        <label className="suite-stepper-step-v2__description">{description}</label>
      </div>
    </div>
  );
};

StepV2.propTypes = {
  /**
   * A step can be highlighted as active.
   */
  active: PropTypes.bool,
  /**
   * Additional classes.
   */
  className: PropTypes.string,
  /**
   * A step can show that a user has completed it.
   */
  completed: PropTypes.bool,
  /**
   * A step can be displayed column or row.
   */
  direction: PropTypes.oneOf(['column', 'row']),
  /**
   * Disable interaction with the step
   */
  disabled: PropTypes.bool,
  /**
   * Indicates that it is the first step
   */
  isFirst: PropTypes.bool,
  /**
   * Indicates that it is the last step
   */
  isLast: PropTypes.bool,
  /**
   * Called on click.
   */
  onClick: PropTypes.func,
  /**
   * Step icon
   */
  icon: PropTypes.string,
  /**
   * Step order
   */
  order: PropTypes.number,
  /**
   * Step title
   */
  title: PropTypes.string,
  /**
   * A step can be displayed stacked vertically.
   */
  vertical: PropTypes.bool
};

StepV2.defaultProps = {
  active: false,
  className: "",
  completed: false,
  disabled: false,
  isFirst: false,
  isLast: false,
  onClick: () => { },
  order: 0,
  title: ""
};

export default StepV2;
