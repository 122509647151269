// @packages
import React from 'react';
import ExternalUrl from 'smu-app-components/ExternalUrl';
import PropTypes from 'prop-types';

const RedirectLayout = ({
  location: {
    query: { link },
  },
}) => (
  <div className="w-screen h-screen flex items-center justify-center">
    <ExternalUrl className="max-w-lg w-full" continueUrl={link} />
  </div>
);

RedirectLayout.propTypes = {
  location: PropTypes.shape({
    query: PropTypes.shape({
      link: PropTypes.string,
    }),
  }).isRequired,
};

export default RedirectLayout;
