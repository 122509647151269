// @packages
import React from 'react';
import PropTypes from 'prop-types';
import WriteForMe from 'smu-labs/AI/containers/WriteForMe';
import cn from 'classnames';
import { injectIntl } from 'react-intl';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import { connect } from 'react-redux';

// @own
import './styles.scss';
import messages from './messages';
import {
  getDescription,
  onClickConfirmationActionNo,
  onClickConfirmationActionYes,
  onClickFirstStepAction,
  onClickFirstStepText,
  onClickMainButton,
  onClickSecondStepActionDiscard,
  onClickSecondStepActionInsert,
  onClickSecondStepActionSave,
  onClickSecondStepActionSetting,
  onClickSecondStepActionSettings,
  onClickSecondStepActionUpdate,
} from './helpers';

const WriteForMeApp = ({
  addToastMessage,
  className,
  disabled,
  intl: { formatMessage },
  onChange,
  suggestionParams,
  username,
  value,
}) => {
  const competencies = suggestionParams?.additionalInformation?.competencies;
  const handleInsert = (text) => {
    onChange({ target: { value: text } });
  };

  const handleError = (error) => {
    addToastMessage({
      message: formatMessage(messages.error),
    });
  };

  const firstStepDescription = getDescription(
    formatMessage,
    competencies,
    username
  );

  return (
    <WriteForMe
      className={cn('!absolute right-4 top-4 z-[1]', className)}
      disabled={disabled}
      events={{
        onClickConfirmationActionNo,
        onClickConfirmationActionYes,
        onClickFirstStepAction,
        onClickFirstStepText,
        onClickMainButton,
        onClickSecondStepActionDiscard,
        onClickSecondStepActionInsert,
        onClickSecondStepActionSave,
        onClickSecondStepActionSetting,
        onClickSecondStepActionSettings,
        onClickSecondStepActionUpdate,
      }}
      messages={{
        confirmationActionNo: formatMessage(messages.confirmationActionNo),
        confirmationActionYes: formatMessage(messages.confirmationActionYes),
        confirmationTitle: formatMessage(messages.confirmationTitle),
        firstStepAction: formatMessage(messages.firstStepAction),
        firstStepActionLoading: formatMessage(messages.firstStepActionLoading),
        firstStepDescription: firstStepDescription,
        firstStepPlaceholder: formatMessage(messages.firstStepPlaceholder),
        firstStepTitle: formatMessage(messages.firstStepTitle),
        mainButtonTooltip: formatMessage(messages.mainButtonTooltip),
        secondStepActionDiscard: formatMessage(
          messages.secondStepActionDiscard
        ),
        secondStepActionInsert: formatMessage(messages.secondStepActionInsert),
        secondStepActionSave: formatMessage(messages.secondStepActionSave),
        secondStepActionSettings: formatMessage(
          messages.secondStepActionSettings
        ),
        secondStepActionUpdate: formatMessage(messages.secondStepActionUpdate),
        secondStepActionUpdateLoading: formatMessage(
          messages.secondStepActionUpdateLoading
        ),
        secondStepDescription: formatMessage(messages.secondStepDescription),
        secondStepTitle: formatMessage(messages.secondStepTitle),
      }}
      maxHeight={404}
      menuProps={{
        style: {
          zIndex: 11,
        },
      }}
      onError={handleError}
      onInsert={handleInsert}
      params={suggestionParams}
      placement="left-start"
      text={value}
      width={572}
    />
  );
};

WriteForMeApp.propTypes = {
  className: PropTypes.string,
  intl: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  suggestionParams: PropTypes.object.isRequired,
  username: PropTypes.string,
  value: PropTypes.string,
};

export default connect(null, { addToastMessage })(injectIntl(WriteForMeApp));
