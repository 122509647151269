import { defineMessages } from 'react-intl';

export default defineMessages({
  SendEvaluation: {
    id: 'Betterme.SendEvaluationConfirmationModal.SendEvaluation',
    defaultMessage: 'Send evaluation',
  },
  AreYouSure: {
    id: 'Betterme.SendEvaluationConfirmationModal.AreYouSure',
    defaultMessage: 'Are you sure you want to send the evaluation without saving it as a draft first?',
  },
  coCreatedAreYouSure: {
    id: 'Betterme.SendEvaluationConfirmationModal.coCreatedAreYouSure',
    defaultMessage: 'Are you sure you want to submit this evaluation and send notifications?',
  },
  coCreatedHasNotBeenAddedTitle: {
    id: 'Betterme.SendEvaluationConfirmationModal.coCreatedHasNotBeenAddedTitle',
    defaultMessage: 'Co-evaluator hasn’t been added yet',
  },
  coCreatedHasNotBeenAdded: {
    id: 'Betterme.SendEvaluationConfirmationModal.coCreatedHasNotBeenAdded',
    defaultMessage: 'It seems you tried to add a Technical Evaluator. Are you sure you want to send the evaluation without a technical input?',
  },
  SendPerformanceRetrosPective: {
    id: 'Betterme.SendEvaluationConfirmationModal.SendPerformanceRetrosPective',
    defaultMessage: 'Send Performance Retrospective',
  },
  AreYouSurePR: {
    id: 'Betterme.SendEvaluationConfirmationModal.AreYouSurePR',
    defaultMessage: 'Are you sure you want to send this Performance Retrospective without saving it as a draft first?',
  },
  SendSelfRetrosPective: {
    id: 'Betterme.SendEvaluationConfirmationModal.SendSelfRetrosPective',
    defaultMessage: 'Send <br/> Self-Retrospective',
  },
  AreYouSureSR: {
    id: 'Betterme.SendEvaluationConfirmationModal.AreYouSureSR',
    defaultMessage: 'Are you sure you want to send this Self-Retrospective without saving it as a draft first?',
  },
  coCreatedPRAreYouSure: {
    id: 'Betterme.SendEvaluationConfirmationModal.coCreatedAreYouSure',
    defaultMessage: 'Are you sure you want to submit the Performance Retrospective and send notifications?',
  },
  coCreatedPRHasNotBeenAddedTitle: {
    id: 'Betterme.SendEvaluationConfirmationModal.coCreatedHasNotBeenAddedTitle',
    defaultMessage: 'Attention',
  },
  coCreatedPRHasNotBeenAdded: {
    id: 'Betterme.SendEvaluationConfirmationModal.coCreatedHasNotBeenAdded',
    defaultMessage: 'It seems you tried to add a Technical Co-Creator but the invitation wasn’t sent.  You can go back and save it as a draft to send it or continue without the technical input.',
  },
  SendIt: {
    id: 'Betterme.SendEvaluationConfirmationModal.SendIt',
    defaultMessage: 'Yes, send it',
  },
  SendPotentialAssessment: {
    id: 'Betterme.SendEvaluationConfirmationModal.SendPotentialAssessment',
    defaultMessage: 'Send result',
  },
  AreYouSurePotentialAssessment: {
    id: 'Betterme.SendEvaluationConfirmationModal.AreYouSurePotentialAssessment',
    defaultMessage: 'Are you sure you want to submit the assessment with the <strong>{type}</strong> score?',
  },
});
