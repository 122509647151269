// @packages
import React from 'react';
import PropTypes from 'prop-types';

// @own
import StarmeUpValuesBox from '../StarmeUpValuesBox';
import './styles.scss';

const StarmeUpValuesList = ({
  emptyMessage,
  orderedSummary,
  totalStars,
}) => {
  return totalStars > 0 ? (
    <ol className="starmeup-values-list__list">
      {orderedSummary.map(value =>
        <li className="starmeup-values__value-item" key={value.id}>
          <StarmeUpValuesBox
            {...value}
          />
        </li>
      )}
    </ol>
  ) : (
    <div className="starmeup-values-list__empty-state">
      {emptyMessage}
    </div>
  );
};

StarmeUpValuesList.propTypes = {
  emptyMessage: PropTypes.string.isRequired,
  orderedSummary: PropTypes.array.isRequired,
  totalStars: PropTypes.number.isRequired,
};

export default StarmeUpValuesList;
