// @packages
import React from 'react';
import PropTypes from 'prop-types';

// @app
import FeedbackForLeadersReceived from 'betterme-components/FeedbackForLeadersReceived';
import { getProfileLocation } from 'betterme-components/routes/urls';

const ProfileFeedbackForLeadersReceived = ({
  location,
  params: { year, half },
  user,
}) => {
  const {
    firstName: userFirstName,
    identification: userIdentification,
    lastName: userLastName,
  } = user;
  const userFullName = `${userFirstName} ${userLastName}`;
  const userDashboardUrl = getProfileLocation(userIdentification)

  return (
    <FeedbackForLeadersReceived
      half={half}
      year={year}
      isCollaborator
      location={location}
      navigateBackLabel={userFullName}
      navigateBackTo={userDashboardUrl}
      user={user}
    />
  );
};

ProfileFeedbackForLeadersReceived.propTypes = {
  intl: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
}

export default ProfileFeedbackForLeadersReceived;
