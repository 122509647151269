// @packages
import React, { useEffect, useState } from 'react';
import Link from 'smu-ui-components/Link';
import LottieSpinner from 'smu-ui-components/LottieSpinner';
import PropTypes from 'prop-types';
import Segment from 'smu-ui-components/Segment';
import isEmpty from 'lodash/isEmpty';
import { actionRequestInit, actionRequestDestroy } from 'smu-utils/reduxRequests/actions';
import { add as showToast } from 'smu-app-components/ToastNotifications/actions';
import { base64Decode, base64Encode } from 'smu-utils/base64';
import { connect } from 'react-redux';
import { getUserLanguage } from 'smu-utils/language';
import { injectIntl, FormattedMessage } from 'react-intl';
import { openModal } from 'smu-app-components/RootModal/actions';
import { push } from 'react-router-redux';
import { selectRequesting, selectResult } from 'smu-utils/reduxRequests/selectors';

// @app
import HeadingSection from 'components/templates/HeadingSection';
import ContentSection from 'components/templates/ContentSection';
import SearchBar from 'components/containers/SearchBar';
import ChangeLeaderConfirmationModal from 'components/containers/ChangeLeaderConfirmationModal';
import LeadershipAdminUserInfo from 'components/organisms/LeadershipAdminUserInfo';
import UserSelectCard from 'components/molecules/UserSelectCard';
import SuggestedLeaderCard from 'components/organisms/SuggestedLeaderCard';
import Title from 'components/atoms/Title';
import { CHANGE_LEADER_CONFIRMATION_MODAL } from 'betterme-components/constants/modalTypes';
import {
  ACCOUNT_LEADER_ANALYTICS,
  CAREER_LEADER_ANALYTICS,
  MAIN_LEADER,
  REMOVE_LEADER_REQUEST_AS_MAIN,
} from 'components/common/constants';
import { actionsMessages, userMessages } from 'common/messages';
import { getManageLeadersAdminLocation, getManageLeadersLocation } from 'betterme-components/routes/urls';
import { getUserInfo } from 'betterme-components/services/UserInformation/actions';
import { selectuserInfo, selectuserInfoLoading } from 'betterme-components/services/UserInformation/selectors';
import { updateUserLeader, resetChangeLeaderRequestState } from 'betterme-components/services/ChangeLeaderRequest/actions';
import {
  CHANGE_LEADER_REQUEST_AS_MAIN,
  CHANGE_LEADER_REQUEST_AS_SECONDARY,
} from 'components/pages/LeadershipAdmin/constants';
import { SIZE_SIXTEEN } from 'components/common/constants';
import {
  apiGetSuggestedLeaders,
  patchLeadershipAdminLeaders,
} from 'betterme-components/services/LeadershipSubstitution/api';
import { selectConfigurations } from 'betterme-components/Authorization/selectors';
import leaderSuggestEmptyState from 'betterme-components/assets/empty-states/suggested-leaders-emptystate.svg';
import EmptyState from 'components/molecules/EmptyState';

// @own
import './styles.scss';
import messages from './messages';
import {
  ADD_TO,
  FOCALS,
  LEADER_MATCHES_STRUCTURE,
  MAIN,
  MANAGE_LEADERS_URL,
  REQUEST_ID_GET_SUGGESTED_LEADERS,
  REQUEST_ID_PATCH_FOCAL_LEADER,
  SECONDARY,
} from './constants';

const ManageLeadersSubstitution = ({
  actionRequestDestroy,
  actionRequestInit,
  allowsGlbFeatures,
  allowsSuggestAccountLeaders,
  getUserInfo,
  goToManageLeaders,
  intl: { formatMessage },
  location,
  navigateBack,
  openModal,
  params: { identification },
  requestingSuggestions,
  resetChangeLeaderRequestState,
  session: { user },
  showToast,
  suggestions,
  userInfo,
  userInfoLoading,
}) => {
  const [requested, setRequested] = useState(false);
  const {
    account,
    area,
    job,
    office,
    project,
    seniority,
    studio,
    tdc,
  } = userInfo;
  const { access: { newWordingForLeaders } } = user;
  const locale = getUserLanguage();
  const breadcrumbMessage = locale === 'es'
    ? `${formatMessage(userMessages.UserProfile)} de ${userInfo?.firstName}`
    : `${userInfo?.firstName}'s ${formatMessage(userMessages.UserProfile)}`;
  const isMainLeader =
    location?.state?.leaderType === MAIN_LEADER
    || location?.state?.leaderType === REMOVE_LEADER_REQUEST_AS_MAIN;
  const leaderType = isMainLeader ? MAIN : SECONDARY;
  const mainLeaderTitle = isMainLeader
    ? formatMessage(messages.LeaderSubstitutionSuggestedMain)
    : formatMessage(messages.LeaderSubstitutionSuggestedSecondary);
  const careerLeaderTitle = isMainLeader
    ? formatMessage(messages.LeaderSubstitutionSuggestedCareer)
    : formatMessage(messages.LeaderSubstitutionSuggestedAccount);
  const suggestedLeaderTitle = allowsGlbFeatures ? careerLeaderTitle : mainLeaderTitle;
  const isFocal = location?.state?.prevSection === FOCALS;
  const isAdd = location?.state?.prevSection === ADD_TO;
  const oldLeader = location?.state?.oldLeader;
  const showSuggestedLeadersSection = (
    (allowsGlbFeatures && isMainLeader) || allowsSuggestAccountLeaders
  );
  const showSearchBar = (isMainLeader || !allowsSuggestAccountLeaders);
  const encodedUserId = base64Encode(userInfo?.id);
  let fields;
  const emptyStateTitle = isMainLeader
    ? formatMessage(messages.LeaderSubstitutionEmptyStateTitle)
    : formatMessage(messages.LeaderSubstitutionAccountEmptyStateTitle);
  const emptyStateDescription = isMainLeader
    ? formatMessage(messages.LeaderSubstitutionEmptyStateDescription)
    : (
      <FormattedMessage
        {...messages.FocalsAdminAccountEmptyStateDescriptionText}
        values={{
          link:
            <Link
              className="leader-substitution__empty-state-link"
              rel="noopener noreferrer"
              to={`${MANAGE_LEADERS_URL}${encodedUserId}`}
            >
              {formatMessage(messages.FocalsAdminAccountEmptyStateDescriptionLink)}
            </Link>
        }}
      />
    );

  const mainLeaderMessages = [
    {
      title: formatMessage(userMessages.UserStudio),
      description: studio,
    },
    {
      title: formatMessage(userMessages.UserPosition),
      description: job,
    },
    {
      title: formatMessage(userMessages.UserTDC),
      description: tdc,
    },
    {
      title: formatMessage(userMessages.UserOffice),
      description: office,
    },
    {
      title: formatMessage(userMessages.UserSeniority),
      description: seniority,
    },
  ];

  const secondaryLeaderMessages = [
    {
      title: formatMessage(userMessages.UserStudio),
      description: studio,
    },
    {
      title: formatMessage(userMessages.UserAccount),
      description: account,
    },
    {
      title: formatMessage(userMessages.UserProject),
      description: project,
    },
    {
      title: formatMessage(userMessages.UserArea),
      description: area,
    },
    {
      title: formatMessage(userMessages.UserPosition),
      description: job,
    },
  ];

  const otherClientsMessages = [
    {
      title: formatMessage(userMessages.UserProject),
      description: project,
    },
    {
      title: formatMessage(userMessages.UserAccount),
      description: account,
    },
    {
      title: formatMessage(userMessages.UserArea),
      description: area,
    },
    {
      title: formatMessage(userMessages.UserOffice),
      description: office,
    },
  ];

  const typeOfLeaderMessage = isMainLeader ? mainLeaderMessages : secondaryLeaderMessages;
  const userInfoMessages = newWordingForLeaders ? typeOfLeaderMessage : otherClientsMessages;

  function getLeaderMatches(matches) {
    fields = matches.map(({ match, name, value }) => ({
      affinity: match,
      description: value,
      name: LEADER_MATCHES_STRUCTURE[name]?.name
        ? formatMessage(LEADER_MATCHES_STRUCTURE[name]?.name)
        : '',
    }));

    return fields;
  };

  function handleAddLeader(user, selectedLeader, type) {
    const data = [{
      created: true,
      mainLeader: type === 'main',
      memberId: selectedLeader?.id,
      suggestionId: suggestions?.suggestionId,
    }];

    actionRequestInit({
      api: patchLeadershipAdminLeaders,
      id: REQUEST_ID_PATCH_FOCAL_LEADER,
      params: {
        communityId: user?.communityId,
        memberId: user?.id,
        data,
      },
      onSuccess: () => showToast({
        message: formatMessage(messages.FocalsAdminAddLeaderToastSuccessMessage),
        timeout: 5000,
      }),
      onError: (error) => showToast({
        message: error.message,
        timeout: 5000,
      }),
    });

    goToManageLeaders();
  }

  function handleReplaceLeader(user, newLeader, type, oldLeader) {
    const data = [
      {
        created: false,
        mainLeader: type === 'main',
        memberId: oldLeader,
      },
      {
        created: true,
        mainLeader: type === 'main',
        memberId: newLeader?.id,
        suggestionId: suggestions?.suggestionId,
      }
    ];

    actionRequestInit({
      api: patchLeadershipAdminLeaders,
      id: REQUEST_ID_PATCH_FOCAL_LEADER,
      params: {
        communityId: user?.communityId,
        memberId: user?.id,
        data,
      },
      onSuccess: () => showToast({
        message: formatMessage(messages.FocalsAdminReplaceLeaderToastSuccessMessage),
        timeout: 5000,
      }),
      onError: (error) => showToast({
        message: error.message,
        timeout: 5000,
      })
    });

    goToManageLeaders();
  };

  function handleChangeLeaderRequest(event, selectedLeader) {
    event.stopPropagation();
    const leaderFullName = `${selectedLeader?.firstName} ${selectedLeader?.lastName}`;
    const type = isMainLeader
      ? CHANGE_LEADER_REQUEST_AS_MAIN
      : CHANGE_LEADER_REQUEST_AS_SECONDARY;

    openModal({
      modalType: CHANGE_LEADER_CONFIRMATION_MODAL,
      modalProps: {
        allowsGlbFeatures,
        collaborator: userInfo,
        isAdd,
        isFocal,
        isMainLeader,
        leader: leaderFullName,
        leaderId: selectedLeader?.id,
        onAccept: isAdd
          ? () => handleAddLeader(userInfo, selectedLeader, leaderType)
          : () => handleReplaceLeader(userInfo, selectedLeader, leaderType, oldLeader),
        type,
        user: user,
      },
    });
  }

  function handleNavigateBack() {
    navigateBack(userInfo?.id);
    resetChangeLeaderRequestState();
  }

  function getSuggestedLeaders() {
    actionRequestInit({
      api: apiGetSuggestedLeaders,
      onSuccess: () => setRequested(true),
      onError: (error) => showToast({
        message: error.message,
        timeout: 5000,
      }),
      id: REQUEST_ID_GET_SUGGESTED_LEADERS,
      params: {
        leaderType: isMainLeader ? 'MAIN' : 'SECONDARY',
        memberId: userInfo?.id,
      },
    })
  };

  function buildContent() {
    if (requestingSuggestions) {
      return (
        <LottieSpinner loadingType="betterme" />
      );
    };

    if ((requested && isEmpty(suggestions?.leaderSuggestions))) {
      return (
        <div className="leader-substitution__empty-state">
          <EmptyState
            description={emptyStateDescription}
            direction="column"
            image={leaderSuggestEmptyState}
            title={emptyStateTitle}
          />
        </div>
      );
    };

    if (requested && !isEmpty(suggestions?.leaderSuggestions) && isMainLeader) {
      return suggestions?.leaderSuggestions?.map(suggestion => {
        const {
          affinity: {
            affinityLevel,
            matches,
          },
          collaboratorsAsMain,
          collaboratorsAsSecondary,
          index,
          leader,
        } = suggestion;

        const userCollaborators = [
          {
            number: collaboratorsAsSecondary,
            text: formatMessage(messages.LeaderSubstitutionCollaboratorsAsSecondary),
          },
          {
            number: collaboratorsAsMain,
            text: formatMessage(messages.LeaderSubstitutionCollaboratorsAsMain),
          },
        ];
        const high = affinityLevel === 'HIGH';
        const low = affinityLevel === 'LOW';
        const medium = affinityLevel === 'MEDIUM';
        const affinityOptions = {
          HIGH: {
            label: 'high affinity',
            tag: 'tag-high-affinity',
          },
          MEDIUM: {
            label: 'medium affinity',
            tag: 'tag-medium-affinity',
          },
          LOW: {
            label: 'low affinity',
            tag: 'tag-low-affinity',
          },
        };
        const labelText = affinityOptions[affinityLevel]?.label;
        const tagColor = affinityOptions[affinityLevel]?.tag;

        return (
          <SuggestedLeaderCard
            affinityType={{
              high,
              low,
              medium,
            }}
            className="leader-substitution__suggested-leaders"
            fields={getLeaderMatches(matches)}
            key={index}
            user={leader}
            userAffinity={{
              affinityType: { high, low, medium },
              buttonText: formatMessage(actionsMessages.ActionsAssign),
              color: tagColor,
              handleSendRequest: (event) => handleChangeLeaderRequest(event, leader),
              labelText,
            }}
            userCollaborators={userCollaborators}
            userHasText={formatMessage(messages.LeaderSubstitutionCollaboratorsLeaderHas)}
          />
        )
      });
    };

    if (requested && !isEmpty(suggestions?.leaderSuggestions) && !isMainLeader) {
      return suggestions?.leaderSuggestions?.map(({ leader }) => {
        return (
          <UserSelectCard
            buttonText={formatMessage(messages.LeaderSubstitutionAccountAssignButton)}
            job={leader?.job}
            onSendRequest={(event) => handleChangeLeaderRequest(event, leader)}
            user={leader}
            withActions
          />
        );
      });
    };
  };

  useEffect(() => {
    getUserInfo(base64Decode(identification));
    return () => resetChangeLeaderRequestState();
  }, []);

  useEffect(() => {
    if (userInfo?.identification === base64Decode(identification)) {
      getSuggestedLeaders();
      return () => actionRequestDestroy(REQUEST_ID_GET_SUGGESTED_LEADERS);
    };
  }, [userInfo?.identification, identification]);

  return (
    <>
      <HeadingSection
        className="leader-substitution"
        headerValues={[
          breadcrumbMessage,
          formatMessage(messages.LeaderSubstitutionBreadcrumbTitle)
        ]}
        navigateBack={handleNavigateBack}
        noMargin
      >
        <Segment loading={userInfoLoading} loadingType="betterme">
          <LeadershipAdminUserInfo
            className="leader-substitution__header"
            items={userInfoMessages}
            noJob
            user={userInfo}
          />
        </Segment>
      </HeadingSection>
      <ContentSection>
        {showSuggestedLeadersSection && (
          <>
            <Title
              className="leader-substitution__title text-black"
              size={SIZE_SIXTEEN}
            >
              {suggestedLeaderTitle}
            </Title>
            {buildContent()}
          </>
        )}
        {showSearchBar && (
          <div className="leader-substitution__searching">
            <Title
              className="text-black"
              size={SIZE_SIXTEEN}
            >
              {formatMessage(messages.LeaderSubstitutionSearchingTitle)}
            </Title>
            <SearchBar
              buttonText={formatMessage(actionsMessages.ActionsAssign)}
              onSendRequest={handleChangeLeaderRequest}
              placeholder={formatMessage(messages.LeaderSubstitutionSearchingPlaceholder)}
            />
          </div>
        )}
        <ChangeLeaderConfirmationModal />
      </ContentSection>
    </>
  );
};

ManageLeadersSubstitution.propTypes = {
  actionRequestDestroy: PropTypes.func.isRequired,
  actionRequestInit: PropTypes.func.isRequired,
  allowsGlbFeatures: PropTypes.bool,
  getUserInfo: PropTypes.func.isRequired,
  injectIntl: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  navigateBack: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  params: PropTypes.string.isRequired,
  session: PropTypes.object.isRequired,
  suggestions: PropTypes.array.isRequired,
  userInfo: PropTypes.object.isRequired,
  userInfoLoading: PropTypes.bool.isRequired,
};

const goToManageLeaders = () => push(getManageLeadersLocation());

const mapDispatchToProps = {
  actionRequestDestroy,
  actionRequestInit,
  getUserInfo,
  goToManageLeaders,
  navigateBack: (id) => push(getManageLeadersAdminLocation(id)),
  openModal,
  resetChangeLeaderRequestState,
  showToast,
  updateUserLeader,
};

const mapSateToProps = (state) => ({
  allowsGlbFeatures: selectConfigurations('allowsGlbFeatures')(state),
  allowsSuggestAccountLeaders: selectConfigurations('allowsSuggestAccountLeaders')(state),
  requestingSuggestions: selectRequesting(state, REQUEST_ID_GET_SUGGESTED_LEADERS),
  suggestions: selectResult(state, REQUEST_ID_GET_SUGGESTED_LEADERS),
  userInfo: selectuserInfo(state),
  userInfoLoading: selectuserInfoLoading(state),
});

export default connect(
  mapSateToProps,
  mapDispatchToProps
)(injectIntl(ManageLeadersSubstitution));
