import { defineMessages } from 'react-intl';

export default defineMessages({
  EvaluationRequired: {
    id: 'Betterme.EvaluationRequiredModal.EvaluationRequired',
    defaultMessage: 'Evaluation Required',
  },
  SendEvaluation: {
    id: 'Betterme.EvaluationRequiredModal.SendEvaluation',
    defaultMessage: 'In order to remove yourself from this leadership you must have to send an evaluation for {collaborator}.',
  },
  SendContinuousEvaluation: {
    id: 'Betterme.EvaluationRequiredModal.SendContinuousEvaluation',
    defaultMessage: 'In order to remove yourself from this leadership you must have to send a continuous evaluation for {collaborator}.',
  },
  CreateEvaluation: {
    id: 'Betterme.EvaluationRequiredModal.CreateEvaluation',
    defaultMessage: 'Create Evaluation',
  },
});
