import { defineMessages } from 'react-intl';

export default defineMessages({
  PotentialAssessmentBreadcrumb: {
    id: 'Betterme.PotentialAssessment.Breadcrumb',
    defaultMessage: 'Potential Assessment',
  },
  PotentialAssessmentSection: {
    id: 'Betterme.PotentialAssessment.Section',
    defaultMessage: 'Assessment',
  },
  PotentialAssessmentGoBackButton: {
    id: 'Betterme.PotentialAssessment.GoBackButton',
    defaultMessage: 'Go Back',
  },
  PotentialAssessmentNextButton: {
    id: 'Betterme.PotentialAssessment.NextButton',
    defaultMessage: 'Next',
  },
  PotentialAssessmentSummary: {
    id: 'Betterme.PotentialAssessment.Summary',
    defaultMessage: 'Summary',
  },
  PotentialAssessmentBehaviours: {
    id: 'Betterme.PotentialAssessment.Behaviours',
    defaultMessage: 'Behaviors',
  },
  PotentialAssessmentReview: {
    id: 'Betterme.PotentialAssessment.Review',
    defaultMessage: 'You can review questions and answers to make sure to provide a meaningful insight.',
  },
  PotentialAssessmentAnswer: {
    id: 'Betterme.PotentialAssessment.Answer',
    defaultMessage: 'Answer',
  },
  PotentialAssessmentExtraDetails: {
    id: 'Betterme.PotentialAssessment.ExtraDetails',
    defaultMessage: 'Would you like to give some extra details about your answer?',
  },
  PotentialAssessmentOptional: {
    id: 'Betterme.PotentialAssessment.Optional',
    defaultMessage: 'Optional',
  },
  PotentialAssessmentAdditionalThoughts: {
    id: 'Betterme.PotentialAssessment.AdditionalThoughts',
    defaultMessage: 'You can enter any additional thoughts in this area.',
  },
  PotentialAssessmentSendAssessment: {
    id: 'Betterme.PotentialAssessment.SendAssessment',
    defaultMessage: 'Submit Answers',
  },
  PotentialAssessmentSummaryDescription: {
    id: 'Betterme.PotentialAssessment.SummaryDescription',
    defaultMessage: 'Carefully review the answers.',
  },
  PotentialAssessmentBehavioursDescription: {
    id: 'Betterme.PotentialAssessment.BehavioursDescription',
    defaultMessage: 'Choose how often you have evidenced these behaviors in the Glober.',
  },
  PotentialAssessmentEmptyTitle: {
    id: 'Betterme.PotentialAssessment.EmptyTitle',
    defaultMessage: 'Congratulations: This team member’s got top potential!',
  },
  PotentialAssessmentEmptyDescription: {
    id: 'Betterme.PotentialAssessment.EmptyDescription',
    defaultMessage: 'That is why there is no assessment to access. You can <a href={goTo}>go back to the previous page.<a>',
  },
  PotentialAssessmentPermissionErrorTitle: {
    id: 'Betterme.PotentialAssessment.PermissionErrorTitle',
    defaultMessage: 'It doesn’t seem you have <br /> access to this page.',
  },
  PotentialAssessmentPermissionErrorDescription: {
    id: 'Betterme.PotentialAssessment.PermissionErrorDescription',
    defaultMessage: 'If you believe you should have permission, <br /> please contact your Administrator.',
  },
  PotentialAssessmentCompletedStateTitle: {
    id: 'Betterme.PotentialAssessment.CompletedStateTitle',
    defaultMessage: 'You already sent this Potential Assessment.',
  },
  PotentialAssessmentCompletedStateDescription: {
    id: 'Betterme.PotentialAssessment.CompletedStateDescription',
    defaultMessage: 'Upon completing the Potential Assessment stage, you will be able to view the results in the Glober´s profile.',
  },
  PotentialAssessmentConfirmationModalDescription: {
    id: 'Betterme.PotentialAssessment.ConfirmationModalDescription',
    defaultMessage: 'Potential Assessment for {assessmentUserName} was sent successfully. Once available, you can see the result in your Team Member\'s Dashboard',
  },
  PotentialAssessmentSummaryWarningDescription: {
    id: 'Betterme.PotentialAssessment.SummaryWarningDescription',
    defaultMessage: 'Attention! Remember that once you confirm, you won’t be able to modify your answers.',
  },
  PotentialAssessmentResultWarningDescription: {
    id: 'Betterme.PotentialAssessment.ResultWarningDescription',
    defaultMessage: 'Remember this is a result built upon your answers but not the final result. It will be determined by averaging all completed assessments by leaders.',
  },
  PotentialAssessmentResultsDescription: {
    id: 'Betterme.PotentialAssessment.ResultsDescription',
    defaultMessage: 'Review the result and send the assessment.',
  },
  PotentialAssessmentResults: {
    id: 'Betterme.PotentialAssessment.Results',
    defaultMessage: 'Results',
  },
  PotentialAssessmentFinishButton: {
    id: 'Betterme.PotentialAssessment.FinishButton',
    defaultMessage: 'Finish',
  },
  PotentialAssessmentSuccessMessage: {
    id: 'Betterme.PotentialAssessment.SuccessMessage',
    defaultMessage: 'The assesment was successfully sent',
  },
  PotentialAssessmentWasCapturedBy: {
    id: 'Betterme.PotentialAssessment.CapturedBy',
    defaultMessage: 'Captured by',
  },
  PotentialAssessmentDisclaimerForLeaders: {
    id: 'Betterme.PotentialAssessment.DisclaimerForLeaders',
    defaultMessage: 'Result is only visible for leaders',
  },
  PotentialAssessmentMe: {
    id: 'Betterme.Messages.Common.Me',
    defaultMessage: 'Me',
  },
  PotentialAssessmentTitle: {
    id: 'Betterme.PotentialAssessment.AssessmentTitle',
    defaultMessage: 'Potential Result',
  },
  PotentialAssessmentScore1: {
    id: 'Betterme.PotentialAssessment.3498.FinalScore1',
    defaultMessage: 'Foundational',
  },
  PotentialAssessmentScore2: {
    id: 'Betterme.PotentialAssessment.3498.FinalScore2',
    defaultMessage: 'Intermediate',
  },
  PotentialAssessmentScore3: {
    id: 'Betterme.PotentialAssessment.3498.FinalScore3',
    defaultMessage: 'Advanced',
  },
  PotentialAssessmentScore4: {
    id: 'Betterme.PotentialAssessment.3498.FinalScore4',
    defaultMessage: 'High',
  },
});
