// @Evaluation Types
export const EVALUATION = 'EVALUATION';
export const NH_EVALUATION = 'NH-EVALUATION';
export const NH_SELF_EVALUATION = 'NH-SELF-EVALUATION';
export const PERFORMANCE_RETROSPECTIVE = 'PERFORMANCE_RETROSPECTIVE';
export const PERIOD_EVALUATION = 'PERIOD-EVALUATION';
export const SELF_EVALUATION = 'SELF-EVALUATION';
export const SELF_RETROSPECTIVE = 'SELF_RETROSPECTIVE';

// @Evaluation Status
export const DRAFT = 'DRAFT';
export const SUBMITTED = 'SUBMITTED';

// @Evaluation External Flows
export const REMOVE_LEADER_SEND_EVALUATION = 'REMOVE_LEADER_SEND_EVALUATION';
