// @packages
import { get } from 'lodash';

const reducerDomain = 'myFeedbacks';

export const selectMyFeedbacks = (state) =>
  get(state, [reducerDomain]);

export const selectLastRequest = (state) =>
  get(state, [reducerDomain, 'request']);
