// @packages
import React from 'react';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import PropTypes from 'prop-types';
import RootModal from 'smu-app-components/RootModal';

// @app
import GenericModal from 'components/templates/GenericModalTemplate';
import InfoCard from 'components/molecules/InfoCard';
import { centerModalClass } from 'betterme-components/ConfirmationModal';
import { actionsMessages } from 'common/messages';
import { SEND_EVALUATION_CONFIRMATION_MODAL } from 'betterme-components/constants/modalTypes';
import { SIZE_FORTY, SIZE_THIRTY_SIX } from 'components/common/constants';

// @own
import messages from './messages';
import { get } from 'underscore';

const SendEvaluationConfirmationModal = ({
  close,
  closeModal,
  intl: { formatMessage },
  isPerformanceRetrospective,
  isPotentialAssessment,
  isSelfRetrospective,
  isTechnicalEvaluator,
  sendEvaluation,
  type,
}) => {
  const actionButtons = [
    {
      label: formatMessage(actionsMessages.ActionsCancel),
      onClick: closeModal,
      variant: 'outline',
    },
    {
      label: formatMessage(messages.SendIt),
      onClick: () => handleSend(),
    },
  ];

  const performanceRetrospectiveTitle = isPerformanceRetrospective
    ? messages.SendPerformanceRetrosPective
    : messages.SendEvaluation;

  const performanceRetrospectiveTecnicalTitle = isPerformanceRetrospective
    ? messages.coCreatedPRHasNotBeenAddedTitle
    : messages.coCreatedHasNotBeenAddedTitle;

  const performanceRetrospectiveNotBeenAdded = isPerformanceRetrospective
    ? messages.coCreatedPRHasNotBeenAdded
    : messages.coCreatedHasNotBeenAdded;

  const performanceRetrospectiveAreYouSure = isPerformanceRetrospective
    ? messages.AreYouSurePR
    : messages.AreYouSure;

  const selfRetrospectiveTitle = isSelfRetrospective
    ? messages.SendSelfRetrosPective
    : messages.SendEvaluation;

  const selfRetrospectiveAreYouSure = isSelfRetrospective
    ? messages.AreYouSureSR
    : messages.AreYouSure;

  const potentialAssessmentTitle = messages.SendPotentialAssessment;

  const potentialAssessmentAreYouSure = {
    ...messages.AreYouSurePotentialAssessment,
    values: { type: type },
  };

  const titleText = getTitle();
  const messageText = getMessage();

  const titleTextNode = (
    <FormattedHTMLMessage
      defaultMessage={titleText.defaultMessage}
      id={titleText.id}
    />
  );

  const messageTextNode = (
    <FormattedHTMLMessage
      defaultMessage={messageText.defaultMessage}
      id={messageText.id}
      values={messageText?.values}
    />
  );

  const modalInfo = {
    title: titleTextNode,
    message: messageTextNode,
  };

  function handleSend() {
    closeModal();
    sendEvaluation('SUBMITTED');
  }

  function getTitle() {
    if (isTechnicalEvaluator) {
      return performanceRetrospectiveTecnicalTitle;
    } else if (isPotentialAssessment) {
      return potentialAssessmentTitle;
    } else if (isSelfRetrospective) {
      return selfRetrospectiveTitle;
    } else {
      return performanceRetrospectiveTitle;
    }
  }

  function getMessage() {
    if (isTechnicalEvaluator) {
      return performanceRetrospectiveNotBeenAdded;
    } else if (isPotentialAssessment) {
      return potentialAssessmentAreYouSure;
    } else if (isSelfRetrospective) {
      return selfRetrospectiveAreYouSure;
    } else {
      return performanceRetrospectiveAreYouSure;
    }
  }

  return (
    <GenericModal
      actionButtons={actionButtons}
      header={
        <InfoCard
          icon="info"
          iconSize={SIZE_FORTY}
          message={modalInfo.message}
          title={modalInfo.title}
          titleSize={SIZE_THIRTY_SIX}
        />
      }
      success
      onClose={close}
    />
  )
};

SendEvaluationConfirmationModal.propTypes = {
  close: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  enableSuggestPromotion: PropTypes.bool,
  intl: PropTypes.object,
  isSelfRetrospective: PropTypes.bool,
  sendEvaluation: PropTypes.func.isRequired,
  type: PropTypes.string,
};

const modalStyles = {
  width: '610px',
};

export default RootModal(
  injectIntl(SendEvaluationConfirmationModal),
  SEND_EVALUATION_CONFIRMATION_MODAL,
  modalStyles,
  centerModalClass,
);
