// @packages
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Segment from 'smu-ui-components/Segment';

// @app
import FeedbackReceived from '../../FeedbackReceived';

const ProfileFeedbackReceived = ({ location, user: userReceived }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [userSelected, setUserSelected] = useState({});

  useEffect(() => {
    setUserSelected(userReceived);
  }, []);

  useEffect(() => {
    if (userSelected.id === userReceived.id && !userReceived?.loadingGetProfile)
      setIsLoading(false)
  }, [userReceived, userSelected]);

  const navigateBackTo = location.pathname.replace('/feedbackreceived', '');
  const activeTab = location?.query?.tab;

  return (
    <Segment loading={isLoading} loadingType="betterme" withChildren={!isLoading}>
      {!isLoading && <FeedbackReceived
        activeTab={activeTab}
        location={location}
        navigateBackLabel={`${userSelected?.firstName} ${userSelected?.lastName}`}
        navigateBackTo={navigateBackTo}
        showFeedbackOptions
        user={userSelected}
      />}
    </Segment>
  );
};

ProfileFeedbackReceived.propTypes = {
  user: PropTypes.object,
};

export default ProfileFeedbackReceived;
