// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Button from 'smu-ui-components/ButtonV2';
import Panel from 'smu-ui-components/Panel';
import { connect } from 'react-redux';
import { get, pick } from 'lodash';
import * as actionsRootModal from 'smu-app-components/RootModal/actions';

// @app
import {
  EVALUATION_CREATE,
  EVALUATION_DRAFT_DELETE_MODAL,
  NEW_HIRE_EXPIRED_SELF_VALIDATION,
  PE_OFF_NEW_HIRE_EXPIRED,
} from 'betterme-components/constants/modalTypes';
import { EVALUATIONS } from 'common/FeatureDiscovery/constGroups';
import { SELF_EVALUATION } from 'common/FeatureDiscovery/constTypes';
import FeatureDiscovery from 'common/FeatureDiscovery';
import { evaluationsMessages } from 'common/messages';
import {
  selectUserInfo,
  selectUserDraftEvaluation,
  selectConfigurations,
} from 'betterme-components/Authorization/selectors';

// @own
import { setDataCreateEvaluation } from '../actions';
import messages from '../messages';
import './styles.scss';

const EvaluationCreateSelf = ({
  allowsEvaluationDrafts,
  allowsEvaluations,
  allowsNewHireSelfEvaluationCreation,
  allowsNewHireSelfEvaluationDraft,
  allowsSelfEvaluation,
  allowsSelfEvaluations,
  allowsSelfRetrospectiveCreation,
  allowsSelfRetrospectiveDraft,
  intl: { formatMessage },
  openModal,
  setDataCreateEvaluation,
  userDraftEvaluation,
  userInfo,
}) => {
  const allowsNewHireEvaluation = (allowsNewHireSelfEvaluationCreation || allowsNewHireSelfEvaluationDraft);
  const isActiveSelfEvaluation = allowsEvaluations && (allowsSelfEvaluations || allowsEvaluationDrafts);
  const isActiveSelfRetrospective = allowsEvaluations && (allowsSelfRetrospectiveCreation || allowsSelfRetrospectiveDraft);
  function getLabelSelfEvaluation() {
    if (isActiveSelfRetrospective && !allowsNewHireEvaluation) {
      return userDraftEvaluation
        ? formatMessage(evaluationsMessages.SelfRetrospectiveEdit)
        : formatMessage(evaluationsMessages.SelfRetrospectiveCreate);
    } else {
      return userDraftEvaluation
        ? formatMessage(evaluationsMessages.EvaluationsEditSelfEvaluation)
        : formatMessage(evaluationsMessages.EvaluationsCreateSelfEvaluation);
    }
  }

  const handleDraftDelete = () => {
    const draftId = get(userDraftEvaluation, 'id');
    openModal({
      modalType: EVALUATION_DRAFT_DELETE_MODAL,
      modalProps: { draftId },
    });
  }

  const handleConvertDraft = dataCreateEvaluation => {
    const dataEvaluation = {
      ...dataCreateEvaluation,
      score: null,
      type: 'SELF-EVALUATION',
    }
    setDataCreateEvaluation(dataEvaluation);
    openModal({
      modalType: EVALUATION_CREATE,
      modalProps: {
        draftId: get(userDraftEvaluation, 'id'),
        isNewHire: userInfo.newHireSelfEvalAvailable,
        isSelfEvaluation: true,
      },
    });
  }

  const handleCreateClick = () => {
    setDataCreateEvaluation({
      evaluee: userInfo,
      ...pick(userDraftEvaluation, ['title', 'dateTimeFrom', 'dateTimeTo', 'type', 'attachments']),
    });

    if (userDraftEvaluation?.type === 'NH-SELF-EVALUATION'
      && !allowsSelfEvaluation
      && !userInfo.newHireSelfEvalAvailable
      && allowsNewHireEvaluation) {
      openModal({
        modalType: PE_OFF_NEW_HIRE_EXPIRED,
        modalProps: {
          draftId: get(userDraftEvaluation, 'id'),
          onDelete: handleDraftDelete,
        },
      });
    } else if (userDraftEvaluation?.type === 'NH-SELF-EVALUATION'
      && !userInfo.newHireEvalAvailable
      && !userInfo.newHireSelfEvalAvailable
      && allowsNewHireEvaluation) {
      openModal({
        modalType: NEW_HIRE_EXPIRED_SELF_VALIDATION,
        modalProps: {
          draftId: get(userDraftEvaluation, 'id'),
          isNewHire: userInfo.newHireEvalAvailable,
          isSelfEvaluation: true,
          onConvert: () => handleConvertDraft(userInfo),
          onDelete: handleDraftDelete,
        },
      });
    } else {
      openModal({
        modalType: EVALUATION_CREATE,
        modalProps: {
          draftId: get(userDraftEvaluation, 'id'),
          isNewHire: userInfo.newHireSelfEvalAvailable,
          isSelfEvaluation: isActiveSelfEvaluation,
          isSelfRetrospective: isActiveSelfRetrospective,
        },
      });
    }
  };

  return (
    <FeatureDiscovery
      group={EVALUATIONS}
      position="left"
      text={formatMessage(messages.FeedbackEvaluationsEvaluationCreateSelfDiscovery)}
      type={SELF_EVALUATION}
    >
      <Panel className="evaluation-create-self">
        <p className="evaluation-create-self__description">
          {formatMessage(messages.FeedbackEvaluationsEvaluationCreateSelfText)}
        </p>
        <Button fluid onClick={handleCreateClick}>
          {getLabelSelfEvaluation()}
        </Button>
      </Panel>
    </FeatureDiscovery>
  );
};

const mapStateToProps = (state) => ({
  allowsEvaluationDrafts: selectConfigurations('allowsEvaluationDrafts')(state),
  allowsEvaluations: selectConfigurations('allowsEvaluations')(state),
  allowsNewHireSelfEvaluationCreation: selectConfigurations('allowsNewHireSelfEvaluationCreation')(state),
  allowsNewHireSelfEvaluationDraft: selectConfigurations('allowsNewHireSelfEvaluationDraft')(state),
  allowsSelfEvaluation: selectConfigurations('allowsSelfEvaluation')(state),
  allowsSelfEvaluations: selectConfigurations('allowsSelfEvaluations')(state),
  allowsSelfRetrospectiveCreation: selectConfigurations('allowsSelfRetrospectiveCreation')(state),
  allowsSelfRetrospectiveDraft: selectConfigurations('allowsSelfRetrospectiveDraft')(state),
  userDraftEvaluation: selectUserDraftEvaluation(state),
  userInfo: selectUserInfo(state),
});

const mapDispatchToProps = {
  ...actionsRootModal,
  setDataCreateEvaluation,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(EvaluationCreateSelf));
