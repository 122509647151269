import { trackEvent } from 'smu-utils/gtm';
const FEEDBACK_FEED = 'Feedback_Feed';

export const trackFeedbackReceivedTabClick = () => {
  trackEvent({
    category: FEEDBACK_FEED,
    action: 'Received_Tab_Click',
  });
};

export const trackFeedbackSendTabClick = () => {
  trackEvent({
    category: FEEDBACK_FEED,
    action: 'Send_Tab_Click',
  });
};

export const trackFeedbackCommentIconClick = (typeOf) => {
  trackEvent({
    category: FEEDBACK_FEED,
    action: 'Comment_Icon_Click',
    type: `fdbk_${typeOf.toLowerCase()}`
  });
};

export const trackFeedbackUserAvatarNameClick = (typeOf, feedbackType) => {
  trackEvent({
    category: FEEDBACK_FEED,
    action: 'Profile_Icon',
    label: feedbackType,
    type: `fdbk_${typeOf.toLowerCase()}`
  })
};

export const trackFeedbackCompetenciesHover = (typeOf, { name }) => {
  trackEvent({
    category: FEEDBACK_FEED,
    action: 'Hover_Description',
    type: `fdbk_${typeOf?.toLowerCase()}`,
    label: name,
  })
};

export const trackFeedbackAudioCommentInteractions = (typeOf, status) => {
  trackEvent({
    category: FEEDBACK_FEED,
    action: 'Audio_interactions',
    type: `fdbk_${typeOf.toLowerCase()}`,
    int_type: status?.toLowerCase(),
  });
};

export const trackFeedbackSendCommentClick = (typeOf) => {
  trackEvent({
    category: FEEDBACK_FEED,
    action: 'Send_Comment_Click',
    type: `fdbk_${typeOf.toLowerCase()}`,
  });
};

export const trackFloatButtonHover = () => {
  trackEvent({
    category: FEEDBACK_FEED,
    action: 'Hover_FAB_Feedback',
  });
};

export const trackFloatButtonSend = () => {
  trackEvent({
    category: FEEDBACK_FEED,
    action: 'FAB_Send_Feedback',
  });
};

export const trackFloatButtonRequest = () => {
  trackEvent({
    category: FEEDBACK_FEED,
    action: 'FAB_Request_Feedback',
  });
};
