export const SERIES_CONFIG = {
  showInLegend: false,
  lineWidth: 20,
};

export const CHART_CONFIG = {
  chart: {
    height: 362,
    type: 'bar',
  },
  title: {
    text: '',
  },
  yAxis: {
    title: {
      text: '',
    },
    labels: {
      distance: "10%",
      formatter: function() {
        return `${Math.abs(this.value - 100)}%`;
      },
    },
    reversed: true,
    max: 100,
    min: 0,
    tickInterval: 10,
  },
  tooltip: {
    backgroundColor: '#000',
    borderWidth: 0,
    shadow: false,
    style: {
      color: '#fff',
    },
    shape: 'rect',
    formatter: function() {
      const percentageValue = Math.floor(this.percentage);
      return `<strong>${percentageValue}%</strong> ${this.series.name}`;
    },
  },
  plotOptions: {
    bar: {
      stacking: 'percent',
      pointWidth: 18,
    },
  },
};
