// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Switch from 'smu-ui-components/Switch';

// @app
import Title from 'components/atoms/Title';

// @own
import './styles.scss';

const CoEvaluationSwitch = ({
  className,
  onSwitchChange,
  subtitle,
  switchDisabled,
  switchEnabled,
  switchId,
  switchText,
  title,
}) => (
  <div className={cn('coevaluation-switch', className)}>
    <div className="coevaluation-switch__texts">
      <Title
        className={cn('coevaluation-switch__texts-title', 'text-black')}
        level={3}
        nomargin
        size={14}
      >
        {title}
      </Title>
      {subtitle && (
        <p className="coevaluation-switch__texts-subtitle">
          {subtitle}
        </p>
      )}
    </div>

    <div className="coevaluation-switch__switch">
      <Switch
        checked={switchEnabled}
        className="acoevaluation-switch__switch-toggle"
        disabled={switchDisabled}
        idName={switchId}
        onChange={onSwitchChange}
        size="medium"
        type="betterme"
      />
      <p className="coevaluation-switch__switch-text">
        {switchText}
      </p>
    </div>
  </div>
);

CoEvaluationSwitch.propTypes = {
  className: PropTypes.string,
  onSwitchChange: PropTypes.func,
  subtitle: PropTypes.string,
  switchDisabled: PropTypes.bool,
  switchEnabled: PropTypes.bool,
  switchId: PropTypes.any,
  switchText: PropTypes.string,
  title: PropTypes.string,
};

export default CoEvaluationSwitch;