// @packages
import React from 'react';
import PropTypes from 'prop-types';
import UserAvatar from 'smu-ui-components/UserAvatar';
import cn from 'classnames';

// @app
import Description from 'components/molecules/Description';

// @own
import './styles.scss';

const LeadershipRemovalBody = ({
  actionConfirmation,
  className,
  removalConfirmation,
  user,
}) => (
  <Description
    classname={cn('leadership-removal-body', className)}
    media={
      <UserAvatar
        className="leadership-removal-body__avatar"
        {...user}
      />
    }
  >
    <span className="leadership-removal-body__feedback">
      {actionConfirmation}
    </span>
    <span className="leadership-removal-body__confirmation">
      {removalConfirmation}
    </span>
  </Description>
);

LeadershipRemovalBody.propTypes = {
  actionConfirmation: PropTypes.string.isRequired,
  className: PropTypes.string,
  removalConfirmation: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
}

export default LeadershipRemovalBody;
