// @packages
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import { closeFullscreenModal } from 'smu-app-components/FullScreenModal/actions';
import { push } from 'react-router-redux';
import { takeLatest, call, put } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';

// @app
import { evaluationsTeam } from 'betterme-components/services/TeamEvaluations/actions';
import { getDraftSelfEvaluation } from 'betterme-components/Authorization/actions';
import { getTeamEvaluationsLocation, getEvaluationsLocation } from 'betterme-components/routes/urls';
import { requestEvaluations, resetDataCreateEvaluation } from 'betterme-components/Evaluations/actions';
import { resetEvaluationDetail } from 'betterme-components/EvaluationDetail/actions';
import { resetPromotionsFiles } from 'betterme-components/SendSuggestPromotion/actions';
import { resetPerformanceRetrospectiveDetail } from 'betterme-components/services/PerformanceRetrospectiveDetail/actions';

// @own
import { UPDATE_SUBMIT_EVALUATION } from './actionTypes';
import { updateSubmitEvaluation } from './api';
import { updateSubmitEvaluationFail, updateSubmitEvaluationSuccess } from './actions';
import {
  NH_SELF_EVALUATION,
  PERFORMANCE_RETROSPECTIVE,
  PERIOD_EVALUATION,
  SELF_EVALUATION,
  SELF_RETROSPECTIVE,
} from './constants';
import { groupByRateId } from 'utils/evaluationHelper';

function* updateSubmitEvaluationWorker({ payload }) {
  const {
    data,
    dataEvaluator,
    dataEvaluee,
    evaluationId,
    evaluationPeriod,
    message: successMessage,
  } = payload;

  const selfDraftEvaluationData = {
    evaluator: dataEvaluee?.identification,
    evaluee: dataEvaluee?.identification,
    isCollaborator: false,
    isNewHire: false,
  };

  const ratedTopics = !isEmpty(data?.ratedTopics)
    ? groupByRateId(data?.ratedTopics)
    : null;

  const files = data?.promotion?.files || [];

  const dataEvaluation = {
    ...data,
    dateTimeFrom: data?.dateTimeFrom?.valueOf() || evaluationPeriod?.startTime?.valueOf(),
    dateTimeTo: data?.dateTimeTo?.valueOf() || evaluationPeriod?.endTime?.valueOf(),
    ratedTopics,
    promotion: data?.promotion ? {
      ...data?.promotion,
      files,
    } : null,
  };

  try {
    const response = yield call(updateSubmitEvaluation, evaluationId, dataEvaluation);
    yield put(updateSubmitEvaluationSuccess(response));
    switch (data?.type) {
      case NH_SELF_EVALUATION:
        yield put(closeFullscreenModal());
        yield put(getDraftSelfEvaluation({
          evaluator: dataEvaluee?.identification,
          evaluee: dataEvaluee?.identification,
          isCollaborator: false,
          isNewHire: true,
        }));
        yield put(requestEvaluations(data?.evalueeId));
        break;

      case SELF_EVALUATION:
        yield put(closeFullscreenModal());
        yield put(getDraftSelfEvaluation(selfDraftEvaluationData));
        yield put(requestEvaluations(data?.evalueeId));
        break;

      case SELF_RETROSPECTIVE:
        yield put(push(getEvaluationsLocation()));
        yield put(getDraftSelfEvaluation(selfDraftEvaluationData));
        yield put(requestEvaluations(data?.evalueeId));
        break;

      case PERFORMANCE_RETROSPECTIVE:
      case PERIOD_EVALUATION:
        yield put(push(getTeamEvaluationsLocation()));
        yield put(evaluationsTeam(dataEvaluator?.identification));
        yield put(resetPromotionsFiles());
        break;

      default:
        yield put(closeFullscreenModal());
        yield put(evaluationsTeam(dataEvaluator?.identification));
        break;
    }
    yield put(addToastMessage({ message: successMessage, timeout: 3000 }));
    yield put(resetPerformanceRetrospectiveDetail());
    yield put(resetDataCreateEvaluation());
    yield put(resetEvaluationDetail());
  } catch (error) {
    yield put(updateSubmitEvaluationFail(error));
    yield put(addToastMessage({ message: error.message, timeout: 3000 }));
  }
}

export default function* evaluationWatcher() {
  yield takeLatest(UPDATE_SUBMIT_EVALUATION, updateSubmitEvaluationWorker);
}
