// @packages
import React from 'react';
import PropTypes from 'prop-types';
import RootModal from 'smu-app-components/RootModal';
import SuccessCard from 'smu-ui-components/SuccessCard';

// @app
import { ENGAGEMENT_FORM_SUCCESS_MODAL } from 'betterme-components/constants/modalTypes';

const EngagementFormSuccessModal = ({
  close: closeModal,
  messages,
  onClose,
}) => {
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    closeModal();
  };

  return (
    <SuccessCard
      className="engagement-form-success-modal"
      footerMessage={messages.footerMessage}
      message={messages.message}
      onCloseClick={handleClose}
      solid
    />
  );
};

EngagementFormSuccessModal.propTypes = {
  close: PropTypes.func.isRequired,
  evaluee: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  messages: PropTypes.shape({
    footerMessage: PropTypes.string,
    message: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

const modalStyles = { maxWidth: '600px' };
const withRootModalComponent = RootModal(
  EngagementFormSuccessModal,
  ENGAGEMENT_FORM_SUCCESS_MODAL,
  modalStyles,
  'modal__wrapper',
);

export default withRootModalComponent;
