import moment from 'moment';
import paramsBuilder from 'smu-utils/paramsBuilder';
import { stringify } from 'querystring';
import parseDate from 'utils/parseDate';
import { apiInstanceSMU, notificationsApiInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';
import { isOtherNotification } from 'utils/checkNotificationsType';
import photoSmu from 'betterme-components/assets/img/photo-smu.png';
export const vo = { v: 2, o: 'SMU_FEEDBACK_WEB' };
export const times = () => ({
  timezone: moment().utcOffset(),
  timestamp: moment().valueOf(),
});
export const addParams = (url, params) => [url, '?', paramsBuilder(params)].join('');
export const formData = {
  method: 'post',
  headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
};

const resolved = data => new Promise(resolve => resolve(data));

const transformNotifications = result => ({
  notifications: result,
  numberOfElements: result.length,
  size: result.length,

  // mocked shit.
  first: true,
  last: true,
  number: 0,
  totalElements: result.length,
  totalPages: 1,
});

export default (function APIBooter() {
  let _notifications = [];
  let _firstPull = true;
  let _shouldGetNotifications = false;
  let _lastStar = moment().valueOf();

  const notViewedCount = () => _notifications.filter(n => !n.isView).length;

  const transformFromUserToFrom = notifications =>
    notifications.map(item => ({
      ...item,
      dateNotification: parseDate(item?.dateNotification),
      fromUser:
        item?.from && !isOtherNotification(item?.type)
          ? item?.from
          : {
            profileImageCode: photoSmu,
            firstName: '',
            lastName: '',
          },
      notificationText: item?.notification || item?.title,
    }));

  const APINotifications = () => {
    const baseUrl = '/api/v3/notifications';
    return notificationsApiInstance({
      url: addParams(baseUrl, { ...vo, ...times() }),
      method: 'get',
    })
      .then(({ data }) => {
        _notifications = transformFromUserToFrom(data.result);
        return _notifications;
      })
      .catch(errorHandler);
  };

  const APIPull = () => {
    const baseUrl = '/v2/stellar/notifications/pull';
    const fromDate = _lastStar;
    return apiInstanceSMU({
      url: addParams(baseUrl, { ...vo, ...times(), fromDate }),
      method: 'get',
    })
      .then(({ data }) => {
        _lastStar = moment().valueOf();
        return data.result.starGiven === 'true'; // Yes, a string.
      })
      .catch(errorHandler);
  };

  const APIViews = () => {
    const { timestamp } = times();
    return notificationsApiInstance({
      url: addParams('/api/v3/notifications/views', { ...vo, ...times() }),
      ...formData,
      data: stringify({ timestamp, ...vo }),
    })
      .catch(errorHandler);
  };

  const APIReads = (id) => {
    notificationsApiInstance({
      url: addParams(`api/v3/notifications/reads/${id}`, { ...vo, ...times() }),
      ...formData,
    })
      .catch(errorHandler);
  };

  return {
    getNotifications() {
      if (!_shouldGetNotifications) {
        _shouldGetNotifications = true;
        let tempNotifications = _notifications;
        const result = resolved(transformNotifications(tempNotifications));
        return result;
      }
      return APINotifications().then((notifications) => {
        const result = resolved(transformNotifications(notifications));
        return result;
      });
    },
    getNotificationsCount(/* page (unused) */) {
      if (_firstPull) {
        _firstPull = false;
        return APINotifications().then((notifications) => {
          _notifications = notifications;
          return notViewedCount();
        });
      }
      return APIPull().then((shouldPull) => {
        if (shouldPull) {
          return APINotifications().then((notifications) => {
            _notifications = notifications;
            return notViewedCount();
          });
        }
        return notViewedCount();
      });
    },
    changeNotificationsStatus(status, ids) {
      switch (status) {
        case 'VIEWED':
          return APIViews();
        case 'READ':
          return APIReads(ids[0]);
        default:
          return resolved(null);
      }
    },
  };
}());
