// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';
import Link from 'smu-ui-components/Link';
import UserAvatar from 'smu-ui-components/UserAvatar';

// @app
import { getProfileLocation } from 'betterme-components/routes/urls';

// @own
import './styles.scss';

const UsersListInfo = ({
  firstName,
  identification,
  job,
  lastName,
  position,
  profileImageCode,
  title,
}) => {
  const userAvatarProps = {
    bordered: true,
    height: 36,
    profileImageCode,
    width: 36,
  };
  const trigger = (
    <Link to={getProfileLocation(identification)}>
      <UserAvatar {...userAvatarProps} />
    </Link>
  );

  return (
    <li className="users-list-info" style={{ zIndex: position }}>
      <Popup
        inverted
        key={firstName}
        size="tiny"
        trigger={trigger}
      >
        {title && (
          <h3 className="users-list-info__title">
            {title}
          </h3>
        )}
        <strong className="users-list-info__name">
          {`${firstName} ${lastName}`}
        </strong>
        <span className="users-list-info__job">
          {job}
        </span>
      </Popup>
    </li>
  );
};

UsersListInfo.propTypes = {
  firstName: PropTypes.string,
  identification: PropTypes.string.isRequired,
  job: PropTypes.string,
  lastName: PropTypes.string,
  position: PropTypes.number.isRequired,
  profileImageCode: PropTypes.string,
};

export default UsersListInfo;
