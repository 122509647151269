// @packages
import React from 'react';
import IconV2 from 'smu-ui-components/IconV2';
import PropTypes from 'prop-types';

// @app
import Header from 'betterme-components/Profile/EngagementIndicatorForm/Header';
import { formatBoldText } from 'utils/formatBoldText';

// @own
import './styles.scss';

function Disconnecting({ messages, onClickLink, closeModal }) {
  function handleClickLink() {
    closeModal();
    onClickLink();
  }

  return (
    <div className="engagement-indicator-modal-disconnecting">
      <div className="engagement-indicator-modal-disconnecting__banner">
        <IconV2
          className="engagement-indicator-modal-disconnecting__banner-icon"
          color="white"
          icon="ok-icon"
          size="xlarge"
        />
        <div className="engagement-indicator-modal-disconnecting__banner-text">
          {messages?.title}
        </div>
      </div>
      <div className="engagement-indicator-modal-disconnecting__text-wrapper">
        <Header
          className="engagement-indicator-modal-disconnecting__header"
          hasCollaborator={false}
          engagement={{
            color: 'green',
            description: formatBoldText(messages?.description || ''),
            icon: 'no-engaged',
            text: messages?.noEngagedTitle,
          }}
        />
        <div className="engagement-indicator-modal-disconnecting__text">
          {messages?.followQuestion}
          <div
            className="engagement-indicator-modal-disconnecting__text-link"
            onClick={handleClickLink}
          >
            {messages?.followQuestionLink}
          </div>
        </div>
      </div>
    </div>
  );
}

Disconnecting.defaultProps = {
  messages: {
    description: '',
    followQuestion: '',
    followQuestionLink: '',
    noEngagedTitle: '',
    title: '',
  },
  closeModal: () => undefined,
  onClickLink: () => undefined,
};

Disconnecting.propTypes = {
  messages: PropTypes.object,
};

export default Disconnecting;
