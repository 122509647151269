import {
  GET_SUGGESTED_ACTIONS_LIST,
  GET_SUGGESTED_ACTIONS_LIST_FAIL,
  GET_SUGGESTED_ACTIONS_LIST_SUCCESS,
} from './actionTypes';

const initialState = {
  error: undefined,
  loadingList: false,
  suggestedActionsList: [],
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_SUGGESTED_ACTIONS_LIST:
      return { ...state, loadingList: true, error: undefined };
      case GET_SUGGESTED_ACTIONS_LIST_FAIL:
      return { ...state, loadingList: false, error: payload.error };
    case GET_SUGGESTED_ACTIONS_LIST_SUCCESS:
      return { ...state, loadingList: false, suggestedActionsList: payload.response };
    default:
      return state;
  }
}
