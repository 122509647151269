// @packages
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import LottieSpinner from 'smu-ui-components/LottieSpinner';
import withRootModal from 'smu-app-components/RootModal/withRootModal';
import Button from 'smu-ui-components/Button'; 
import { actionRequestDestroy, actionRequestInit } from 'smu-utils/reduxRequests/actions';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { keys } from 'lodash';
import { goBack } from 'react-router-redux';
import { selectRequesting, selectResult } from 'smu-utils/reduxRequests/selectors';

// @app
import { ENGAGEMENT_FORM_SUCCESS_MODAL } from 'betterme-components/constants/modalTypes';
import { formatBoldText } from 'utils/formatBoldText';
import useForm from 'betterme-components/hooks/form';

// @own
import {
  ENGAGEMENT_FORM,
  ENGAGEMENT_FORM_SUBMIT,
  TYPE_CHECKBOX,
  TYPE_TEXT_AREA,
  TYPE_TEXT,
} from '../constants';
import { getEngagementForm, putEngagementForm } from './api';
import Header from '../Header';
import messages from './messages';
import Questions from '../Questions';
import SuccessModal from '../SuccessModal';
import { trackEngagementFormSuccess } from '../../analytics';
import './styles.scss';

function EngagementIndicatorForm({
  actionRequestDestroy,
  actionRequestInit,
  className,
  handleClose,
  intl: { formatMessage },
  isRequestingForm,
  location,
  openModal,
  params: { userId },
  result,
}) {
  const { values } = useForm();
  const successModalMessages = {
    message: formatMessage(messages.EngagementIndicatorFormModalSuccessTitle),
    footerMessage: formatMessage(
      messages.EngagementIndicatorFormModalFooterText
    ),
  };
  const collaborator = result?.collaborator;
  const form = result?.form;
  const questions = form?.questions;

  const closeHandler = () => {
    if (handleClose) {
      handleClose();
    } else {
      goBack();
    }
  }

  const handleSuccess = () => {
    openModal({
      modalType: ENGAGEMENT_FORM_SUCCESS_MODAL,
      modalProps: {
        messages: successModalMessages,
        onClose: closeHandler,
      }
    });
  }

  const getSelections = () => {
    const valuesKeys = keys(values);
    const trackResponse = {};
    const selectedValues = valuesKeys.reduce((acc, key) => {
      if (values[key]){
        acc.push({
          questionId: values[key].questionId,
          optionId: key,
          value: values[key].value,
        });
        acc.push()
        Object.assign(trackResponse, { [`question_${values[key].questionId}`]: values[key].text });
      }
      return acc;
    }, []);
    return { selectedValues, trackResponse };
  }

  const handleSubmit = () => {
    const { state: { user: { engagement } } } = location;
    const action = engagement?.status === 'not-engaged' ? 'form_success' : 'new_from_success';
    const { selectedValues, trackResponse } = getSelections();

    const data = {
      form: {
        data: [...selectedValues], 
        collaborator: {
          id: collaborator?.userId,
        },
        templateId: form?.templateId,
      }
    }

    trackEngagementFormSuccess(action, engagement.status, trackResponse);
    actionRequestInit({
      api: putEngagementForm,
      id: ENGAGEMENT_FORM_SUBMIT,
      onSuccess: handleSuccess,
      params: data,
    });
  }

  const isInvalid = (questions) => {
    return questions?.some(question => {
      if (question?.options?.length) {
        const options = question?.options;
        return !options.some(option => {
          if (option?.childQuestions?.length && values[option.id]) {
            return !isInvalid(option.childQuestions);
          } else {
            return values[option.id] && values[option.id]?.value;
          }
        })
      }
      return false;
    });
  }

  const normalizeQuestion = (question, parentType) => {
    let normalizedOptions = [];
    if (question?.options?.length) {
      normalizedOptions = question?.options?.map(option => {
        let normalizedChildQuestions = [];
        const isTypeText = parentType && parentType === TYPE_CHECKBOX && option?.type === TYPE_TEXT_AREA;
        if (option?.childQuestions?.length){
          const { childQuestions, type } = option;
          normalizedChildQuestions = childQuestions.map(child => normalizeQuestion(child, type))
        }
        return ({
          ...option,
          type: isTypeText ? TYPE_TEXT : option?.type,
          childQuestions: normalizedChildQuestions,
        })
      });
    }
    return {
      ...question,
      options: normalizedOptions,
    };
  }

  const interceptResponse = (response) => {
    let normalizedQuestions = [];
    if (response?.form?.questions?.length){
      normalizedQuestions = response?.form?.questions?.map(question => normalizeQuestion(question));
    }
    return ({
      ...response,
      form: {
        ...response?.form,
        questions: normalizedQuestions,
      },
    })
  }

  const getForm = () => {
    actionRequestInit({
      api: getEngagementForm,
      id: ENGAGEMENT_FORM,
      interceptResponse,
      params: { userId },
    });
  };

  useEffect(() => {
    getForm();
    return () => {
      actionRequestDestroy(ENGAGEMENT_FORM);
      actionRequestDestroy(ENGAGEMENT_FORM_SUBMIT);
    }
  }, []);

  return (
    <>
      <div className={cn('engagement-indicator-form', className)}>
        {isRequestingForm ? (
          <LottieSpinner
            className="engagement-indicator-form__spinner"
            loadingType="betterme"
          />
        ) : (
          <div className="engagement-indicator-form__wrapper">
            <Header
              collaborator={collaborator}
              collaboratortitle={formatMessage(
                messages.EngagementIndicatorFormCollaboratorTitle
              )}
              engagement={{
                color: 'pink',
                description: formatBoldText(
                  formatMessage(messages.EngagementIndicatorFormExplanation, {
                    userName: `${collaborator?.firstName} ${collaborator?.lastName}`,
                  })
                ),
                icon: 'no-engaged',
                text: formatMessage(
                  messages.EngagementIndicatorFormDisconnecting
                ),
              }}
            />
            {questions && <Questions questions={questions} />}
            <div className="engagement-indicator-form__actions">
              <Button
                className="engagement-indicator-form__actions-cancel"
                onClick={closeHandler}
                noMargin
              >
                {formatMessage(messages.EngagementIndicatorFormCancel)}
              </Button>
              <Button
                className="engagement-indicator-form__actions-submit"
                color="black"
                disabled={isInvalid(questions)}
                onClick={handleSubmit}
                noMargin
              >
                {formatMessage(messages.EngagementIndicatorFormSubmit)}
              </Button>
            </div>
          </div>
        )}
      </div>
      <SuccessModal />
    </>
  );
}

EngagementIndicatorForm.propTypes = {
  actionRequestDestroy: PropTypes.func.isRequired,
  actionRequestInit: PropTypes.func.isRequired,
  className: PropTypes.string,
  handleClose: PropTypes.func,
  intl: PropTypes.object,
  isRequestingForm: PropTypes.bool,
  location: PropTypes.object,
  openModal: PropTypes.func,
  result: PropTypes.object,
  userId: PropTypes.string,
};

const mapStateToProps = (state) => ({
  isRequestingForm: selectRequesting(state, ENGAGEMENT_FORM),
  result: selectResult(state, ENGAGEMENT_FORM),
});

export default connect(mapStateToProps, {
  actionRequestDestroy,
  actionRequestInit,
})(withRootModal(injectIntl(EngagementIndicatorForm)));
