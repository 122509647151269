import { takeLatest, call, put } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import { getAwatingResultCounter } from 'betterme-components/api';
import { GET_AWAITING_RESULT_COUNTER } from './actionTypes';
import {
  getAwatingResultCounterFail,
  getAwatingResultCounterSuccess,
} from './actions';

function* getAwatingResultCounterWorker({ payload: { params } }) {
  try {
    const result = yield call(getAwatingResultCounter, params);
    yield put(getAwatingResultCounterSuccess(result));
  } catch(error) {
    yield put(getAwatingResultCounterFail(error.message));
    yield put(addToastMessage({ message: error.message, timeout: 3000 }));
  }
}

export default function* getAwatingResultCounterWatcher() {
  yield takeLatest(GET_AWAITING_RESULT_COUNTER, getAwatingResultCounterWorker);
}