// @packages
import React from 'react';
import Icon from 'smu-ui-components/IconV2';
import Image from 'smu-ui-components/Image';
import PropTypes from 'prop-types';
import Tooltip from 'smu-labs/Tooltip';
import cn from 'classnames';
import { DropTarget } from 'react-dnd';

// @own
import { COMPETENCE } from '../constants';
import './styles.scss';

const rateBoxTarget = {
  drop(props) {
    return { rateId: props.id};
  }
};

function collect(connect) {
  return {
    connectDropTarget: connect.dropTarget(),
  };
}

const RateBox = ({
  bigHeader,
  className,
  color,
  connectDropTarget,
  elements,
  name,
  onRateBoxItemClick,
  text,
  value,
}) => {
  const rateBoxClass = cn(className, {
    'rate-box': true,
    'rate-box--no-border': (elements.length > 3),
    'rate-box--big-header': bigHeader,
  });

  const listWrapperClass = cn({
    'rate-box__list-wrapper': true,
  });

  const renderItem = (element, index) => {
    return (
      <li
        className={cn('rate-box__item', {
          'rate-box__item-skill': element.skill,
        })}
        key={index}
        onClick={() => onRateBoxItemClick(element)}
      >
        <Tooltip content={element.description || element.name}>
          <div
            className={cn('rate-box__item-description', {
              'rate-box__item-description-skill': element.skill,
            })}
          >
            {element.name}
          </div>
        </Tooltip>
        <Icon className="ml-1" icon="close" size="xmicro" />
      </li>
    );
  };

  const style = color ? { backgroundColor: color } : undefined;

  return connectDropTarget(
    <div className={rateBoxClass}>
      <div className="rate-box__header" style={style}>
        <span className="rate-box__header-text">{name || `${value} ${text.stars}`}</span>
      </div>
      <div className={listWrapperClass}>
        {elements.length === 0 && (
          <div className="rate-box__empty-box-icon-wrapper">
            <Image
              height={40}
              legacy={false}
              src="/assets/svg/drag.svg"
              width={40}
            />
          </div>
        )}
        {elements.length > 0 && (
          <ul className="rate-box__wrapper flex gap-2 flex-wrap">
            {elements.map((element, index) => renderItem(element, index))}
          </ul>
        )}
      </div>
    </div>
  );
};

RateBox.propTypes = {
  bigHeader: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  connectDropTarget: PropTypes.func.isRequired,
  elements: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
  })),
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  onRateBoxItemClick: PropTypes.func,
  text: PropTypes.object,
  value: PropTypes.number,
};

RateBox.defaultProps = {
  text: {
    stars: 'Stars'
  }
};

export default DropTarget(COMPETENCE, rateBoxTarget, collect)(RateBox);
