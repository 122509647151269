import {
  ADD_COMPETENCE,
  ADD_DELETED_SKILL,
  ADD_FEEDBACK_COLLABORATOR,
  ADD_SKILLS,
  CHANGE_FEEDBACK_SELECTED_COLLABORATORS,
  CHANGE_FEEDBACK_TYPE,
  CLEAN_DELETED_SKILLS,
  CLEAN_RATED_COMPETENCES_STATE,
  CLEAN_SEND_FEEDBACK_REQUEST_STATE,
  CLEAN_SKILLS,
  CLEAN_USER_LIST,
  CREATE_REQUEST_FEEDBACK_ON_BEHALF,
  CREATE_REQUEST_FEEDBACK_ON_BEHALF_FAIL,
  CREATE_REQUEST_FEEDBACK_ON_BEHALF_SUCCESS,
  FEEDBACK_ERROR,
  FEEDBACK_SENT,
  GET_REQUEST_FEEDBACK_INFO,
  GET_REQUEST_FEEDBACK_INFO_ERROR,
  GET_REQUEST_FEEDBACK_INFO_SUCCESS,
  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  INIT_COMPETENCES,
  INIT_MANDATORY_COMPETENCES,
  INIT_REPLY_REQUEST_FEEDBACK_DATA,
  INIT_USER,
  REMOVE_COMPETENCE,
  REMOVE_FEEDBACK_ALL_COLLABORATORS,
  REMOVE_FEEDBACK_COLLABORATOR,
  REPLY_REQUEST_FEEDBACK,
  REQUEST_FEEDBACK,
  RESET_MODAL,
  SELECT_COMPETENCE,
  SELECT_FEEDBACK_USER,
  SEND_FEEDBACK,
  UNSELECT_COMPETENCE,
  UNSELECT_FEEDBACK_USER,
  UPDATE_COMPETENCES,
} from './actionTypes';

export function getRequestFeedbackInfo(requestId) {
  return {
    type: GET_REQUEST_FEEDBACK_INFO,
    payload: { requestId },
  };
}

export function getRequestFeedbackInfoSuccess(response) {
  return {
    type: GET_REQUEST_FEEDBACK_INFO_SUCCESS,
    payload: { response },
  };
}

export function getRequestFeedbackInfoError(error) {
  return {
    type: GET_REQUEST_FEEDBACK_INFO_ERROR,
    payload: { error },
  };
}

export function sendFeedback({ recipientIds, ratedCompetencies, comment, files, actionRequired, user, selfUser, suggestPromotion }) {
  return {
    type: SEND_FEEDBACK,
    payload: { recipientIds, ratedCompetencies, comment, files, actionRequired, user, selfUser, suggestPromotion },
  };
}

export function replyRequestFeedback({ requestId, ratedCompetencies, comment, files, suggestPromotion }) {
  return {
    type: REPLY_REQUEST_FEEDBACK,
    payload: { requestId, ratedCompetencies, comment, files, suggestPromotion },
  };
}

export function requestFeedback({ recipientIds, selectedCompetencies, comment }) {
  return {
    type: REQUEST_FEEDBACK,
    payload: { recipientIds, selectedCompetencies, comment }
  };
}

export function createRequestFeedbackOnBehalf({
  comment,
  evalueeIds,
  recipientIds,
  selectedCompetencies,
}) {
  return {
    type: CREATE_REQUEST_FEEDBACK_ON_BEHALF,
    payload: {
      comment,
      evalueeIds,
      recipientIds,
      selectedCompetencies,
    }
  };
}

export function createRequestFeedbackOnBehalfFail(error) {
  return {
    type: CREATE_REQUEST_FEEDBACK_ON_BEHALF_FAIL,
    payload: { error },
  };
}

export function createRequestFeedbackOnBehalfSuccess(response) {
  return {
    type: CREATE_REQUEST_FEEDBACK_ON_BEHALF_SUCCESS,
    payload: { response },
  };
}

export function reset() {
  return {
    type: RESET_MODAL
  };
}

export function updateCompetencies(competencies) {
  return {
    type: UPDATE_COMPETENCES,
    payload: { competencies },
  };
}

export function addCompetency(competency, rateId) {
  return {
    type: ADD_COMPETENCE,
    payload: { competency, rateId },
  };
}

export function removeCompetency(competency) {
  return {
    type: REMOVE_COMPETENCE,
    payload: { competency },
  };
}

export function initCompetencies(competencies) {
  return {
    type: INIT_COMPETENCES,
    payload: { competencies },
  };
}

export function initMandatoryCompetencies(competencies) {
  return {
    type: INIT_MANDATORY_COMPETENCES,
    payload: { competencies },
  };
}

export function selectCompetency(competency) {
  return {
    type: SELECT_COMPETENCE,
    payload: { competency },
  };
}

export function unselectCompetency(competency) {
  return {
    type: UNSELECT_COMPETENCE,
    payload: { competency },
  };
}

export function initReplyRequestFeedbackData(requestId, requestedBy, comment) {
  return {
    type: INIT_REPLY_REQUEST_FEEDBACK_DATA,
    payload: { requestId, requestedBy, comment },
  };
}

export function initUser(user) {
  return {
    type: INIT_USER,
    payload: { user },
  };
}

export function cleanRatedCompetencies() {
  return {
    type: CLEAN_RATED_COMPETENCES_STATE,
  };
}

export function cleanSendFeedbackRequestState() {
  return {
    type: CLEAN_SEND_FEEDBACK_REQUEST_STATE,
  };
}

export function feedbackSent(type) {
  return {
    type: FEEDBACK_SENT,
    payload: { type }
  };
}

export function feedbackError(error) {
  return {
    type: FEEDBACK_ERROR,
    payload: { error }
  };
}

export function addSkills(skills, type) {
  return {
    type: ADD_SKILLS,
    payload: { skills, type },
  };
}

export function addDeletedSkill(skill) {
  return {
    type: ADD_DELETED_SKILL,
    payload: { skill },
  };
}

export function cleanDeletedSkills() {
  return {
    type: CLEAN_DELETED_SKILLS,
  };
}

export function cleanSkills() {
  return {
    type: CLEAN_SKILLS,
  };
}

export function changeFeedbackType() {
  return {
    type: CHANGE_FEEDBACK_TYPE,
  };
}

export function getUsers(query, selectedUsers, selectedCollaborators) {
  return {
    type: GET_USERS,
    payload: { query, selectedUsers, selectedCollaborators },
  };
}

export function getUsersFail(error) {
  return {
    type: GET_USERS_FAIL,
    payload: { error },
  };
}

export function getUsersSuccess(response) {
  return {
    type: GET_USERS_SUCCESS,
    payload: { response },
  };
}

export function selectFeedbackUser(user, isMultiple) {
  return {
    type: SELECT_FEEDBACK_USER,
    payload: { user, isMultiple },
  };
}

export function addFeedbackCollaborator(collaborator) {
  return {
    type: ADD_FEEDBACK_COLLABORATOR,
    payload: { collaborator },
  };
}

export function removeFeedbackAllCollaborators() {
  return {
    type: REMOVE_FEEDBACK_ALL_COLLABORATORS,
  };
}

export function removeFeedbackCollaborator(collaborator) {
  return {
    type: REMOVE_FEEDBACK_COLLABORATOR,
    payload: { collaborator },
  };
}

export function unselectFeedbackUser(userId) {
  return {
    type: UNSELECT_FEEDBACK_USER,
    payload: { userId },
  };
}

export function cleanUserList() {
  return {
    type: CLEAN_USER_LIST,
  };
}

export function changeFeedbackSelectedCollaborators(selectedCollaborators) {
  return {
    type: CHANGE_FEEDBACK_SELECTED_COLLABORATORS,
    payload: { selectedCollaborators },
  };
}
